import obtainServer from '@framework/server/Server';
import { __ } from '@src/translation';
const URL = 'myProfile';

/** Ezeket kapod vissza ha lekéred a profilt. */
export interface IGetMyProfileResult {
    id : number; // sec_user.id
    login_name: string; // sec_user.login_name
    user_availibility_id: number; // sec_user.user_availibility_id

    email: string; // sec_user.email
    email_confirm_code_requested: string; // timestamp sec_user.email_confirm_code_requested
    email_confirm_code_sent: string; // timestamp sec_user.email_confirm_code_sent
    email_confirmed: string; // timestamp sec_user.email_confirmed

    email2: string|null; // sec_user.email2
    email2_confirm_code_requested: string; // timestamp sec_user.email2_confirm_code_requested
    email2_confirm_code_sent: string|null; // timestamp sec_user.email2_confirm_code_sent
    email2_confirmed: string|null; // timestamp sec_user.email2_confirmed

    person_id : number; // sec_user.person_id
    firstname : string; // person.firstname
    lastname : string; // person.lastname
    name_prefix : string|null; // person.name_prefix
    fullname : string|null; // person.fullname
    nickname : string|null; // person.nickname
    birthday : string; // person.birthday

    identities : IGetMyProfileIdentity[];

    soft_quota: number;
    hard_quota: number;
    disk_space_used: number;
    has_totp : boolean;
    totp_seq : number;
    totp_confirmed: string; /* timestamp */
}

export interface IGetMyProfileIdentity {
    id : number;
    creation_time: string;
    is_active: boolean;
    external_oauth_provider_id : number;
    external_oauth_provider_title : string;
    external_id : string;
    email : string|null;
}

/** Ezeket tudod változtatni put()-tal. */
export interface IChangeMyProfileParams {
    firstname? : string; // person.firstname
    lastname? : string; // person.lastname
    name_prefix? : string|null; // person.name_prefix
    fullname? : string|null; // person.fullname
    nickname? : string|null; // person.nickname
    user_availibility_id ?: number|null;
    birthday ?: string; // person.birthday
    email2 ?: string|null;
}

export interface IUserLoginEvent {
    user_id : number;
    event_date: string; // timestamp
    action : string;
    client_ip ?: string|null;
    message ?: string|null;
    user_agent ?: string|null;
}

export default class MyProfileAPI
 {

    /** Profil adatok lekérdezése. */
    public static get = () : Promise <IGetMyProfileResult> => {
        return obtainServer().get<IGetMyProfileResult>(URL);
    }

    /**
     * Profil adatok változtatása.
     * Ha lehet akkor azt küld be, ami ténylegesen változott.
     */
    public static put = (profile: IChangeMyProfileParams) : Promise < IGetMyProfileResult > => {
        return obtainServer().put(URL, profile);
    }

    public static getInitTOTPUrl = (seq: number) => {
        return "/api/" + URL + "?qrcode=" + seq;
    }

    public static confirmTOTP = async (verificationCode: string) : Promise<{totp_confirmed: string}> /* timestamp */ => {
        return obtainServer().post<{totp_confirmed: string}>(URL, {
            operation: "confirm_totp",
            verification_code: verificationCode
        });                        
    }

    public static turOffTOTP = async () : Promise<boolean>  => {
        return obtainServer().post<boolean>(URL, {operation: "turnoff_totp"});                        
    }    

    /**
     * Elsődleges e-mail címet megerősítő e-mailt küld ki.
     */
    public static requestEmailConfirmation = () : Promise<IGetMyProfileResult> => {
        return obtainServer().post(URL, {operation: "request_email_confirmation"});
    }

    /**
     * Másodlagos e-mail címet megerősítő e-mailt küld ki.
     */
    public static requestEmail2Confirmation = () : Promise<IGetMyProfileResult> => {
        return obtainServer().post(URL, {operation: "request_email2_confirmation"});
    }

    /**
     * Bejelentkezési napló lekérése
     */
    public static getLoginEventLog = () : Promise<IUserLoginEvent[]> => {
        return obtainServer().post(URL, {operation: "get_login_history"});
    }

    public static getFirstLogin = () : Promise<IUserLoginEvent> => {
        return obtainServer().post(URL, {operation: "get_first_login"});
    }

    public static swapEmails = () : Promise<boolean> => {
        return obtainServer().post(URL, {operation: "swap_emails"});
    }

}
