import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IDlSubjectRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Név
     */
    title?: string;
}

/**
 *  doc_pub.dl_subject - Letölthető tananyagok tantárgya
 */

export default class DlSubjectCrud extends Crud<IDlSubjectRecord> {
    public static TABLE_INFO_ID = 558847578;
    public static getSchemaNameForClass() { return 'doc_pub'; }
    public static getTableNameForClass() { return 'dl_subject'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<DlSubjectCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IDlSubjectRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IDlSubjectRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IDlSubjectRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IDlSubjectRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IDlSubjectRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class DlSubjectCrudClassProxy extends CrudClassProxy<IDlSubjectRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const dlSubjectCrudClassProxy = new DlSubjectCrudClassProxy(DlSubjectCrud);
registerCrudClassProxy(dlSubjectCrudClassProxy);
