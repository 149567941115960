
import * as React from 'react';
import ViewItem, { IViewItemRecord } from '@src/framework/view/kb/ViewItem';
import './knowledgeBase.css';
import { Link } from 'react-router-dom';
import { PATH_KNOWLEDGE_BASE_VIEW } from '@src/Routes';
import { __ } from '@src/translation';

const KNOWLEDGEBASE_LOCALSTORAGE_ID = 5;
const KNOWLEDGEBASE_LOCALSTORAGE_KEY = "knowledgeBaseNotificationCache";
interface KnowledgeBaseNotificationProps {

}
class KnowledgeBaseNotificationState {
  needNotificationPopup: boolean;
  lastViewElementRecord?: IViewItemRecord;
}
export default class KnowledgeBaseNotification extends React.Component<KnowledgeBaseNotificationProps, KnowledgeBaseNotificationState> {
  constructor(props: any) {
    super(props);
    this.state = {
      needNotificationPopup: false,
      lastViewElementRecord: undefined
    };
  }

  componentDidMount() {
    this.reloadAsync();
  }
  private reloadAsync = async () => {
    let localstorageElement = localStorage.getItem(KNOWLEDGEBASE_LOCALSTORAGE_KEY);
    let lastInDb = await ViewItem.list({ filter: { type_id: KNOWLEDGEBASE_LOCALSTORAGE_ID }, order_by: [{ name: "creation_time", desc: true }], limit: 1 });
    if (lastInDb.length) {
      let lastViewElementRecord = lastInDb[0];
      if (!localstorageElement || (lastViewElementRecord && lastViewElementRecord.id && localstorageElement != lastViewElementRecord.id + "")) {
        localStorage.setItem(KNOWLEDGEBASE_LOCALSTORAGE_KEY, lastViewElementRecord.id + "")
        this.setState({ needNotificationPopup: localstorageElement ? true : false, lastViewElementRecord });
      }
    }
  }
  render() {
    if (this.state.needNotificationPopup) {

      return <div className="knowledge-base-notification" style={{ position: "absolute", backgroundColor: "green", zIndex: 1000, bottom: "1em", right: "1em", borderRadius: "10px" }}>
        <Link to={PATH_KNOWLEDGE_BASE_VIEW + "/" + this.state.lastViewElementRecord!.id}>{__("Új szoftverfejlesztés elérhető")}: {this.state.lastViewElementRecord!.subject ? this.state.lastViewElementRecord!.subject : ""}</Link></div>
    }
    else {
      return null
    }
  }

}
