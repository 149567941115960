import * as React from 'react';
import { app, history } from '@src/index';
import { match } from 'react-router';
import { __ } from '@src/translation';
import { BubbleLoader } from 'react-css-loaders';
import { Link } from 'react-router-dom';
import SurveyCrud, { ISurveyRecord } from '@src/framework/crud/usr/SurveyCrud';
import { PATH_SURVEY_LIST, PATH_SURVEY_EDIT } from '@src/Routes';
import { Accordion, AccordionActionType, ActionAccordionItem } from '../ui/Accordion';
import { SurveyInputType, RANGE_MAX_VALUE, RANGE_MIN_VALUE, RANGE_DEF_VALUE, ANSWERS_PREDEFINED_TYPES, MULTI_ANSWER_TYPES, Question, QuestionCondition } from './SurveyConstants';
import { hasAnyGroup, Groups, me } from '@src/framework/server/Auth';
import MultiSelect from "@khanacademy/react-multi-select";
import { confirmDialog } from '../Dialog';

const NOPE = "Egyáltalán nem";
const RARELY = "Kevésbé";
const CASUALLY = "Átlagosan";
const USUALLY = "Eléggé";
const MAXIMALLY = "Maximálisan";

type SurveyEditProps = {
    match: match<{ surveyId: string }>;
}

type SurveyEditState = {
    surveyRecord: ISurveyRecord;
    loading: boolean;
    prevPropValue: string;
}

export class SurveyEdit extends React.Component<SurveyEditProps, SurveyEditState> {

    constructor(props: SurveyEditProps) {
        super(props);

        this.state = {
            surveyRecord: {},
            loading: true,
            prevPropValue: ""
        }
    }

    async componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: SurveyEditProps, prevState: SurveyEditState) {

        if ((this.props.match.params.surveyId && this.props.match.params.surveyId !== prevProps.match.params.surveyId)) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {

        try {
            let surveyRecord: ISurveyRecord = { title: "", questions: { questions: [] } };
            if (this.props.match.params.surveyId) {
                const id = Number(this.props.match.params.surveyId);

                surveyRecord = (await SurveyCrud.load(id)).record;
            }
            this.setState({
                surveyRecord,
                loading: false,

            });
        }
        catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onSave() {
        try {
            if (!this.state.surveyRecord) return;
            if (!this.state.surveyRecord.title || this.state.surveyRecord.title.length < 1) {
                app.showError("Hiba", "Kötelező megadni a kérdőív címét!");
                return;
            }
            if (this.state.surveyRecord.questions.questions && this.state.surveyRecord.questions.questions.length > 0) {
                let qIds: number[] = [];
                let condOrderWarning: boolean = false;
                for (const currentQuestion of this.state.surveyRecord.questions.questions) {

                    /*if (!currentQuestion.name || currentQuestion.name.length < 1) {
                        app.showError("Hiba", "Kötelező kitölteni a kérdések címét");
                        return;
                    }*/
                    qIds.push(currentQuestion.id);
                    const cond = currentQuestion.condition;

                    if (cond) {
                        if (!cond.questionId || cond.answerIds.length <= 0) {
                            app.showError("Hiba", "A feltételhez kötelező megadni legalább egy választ!");
                            return;
                        }

                        if (!qIds.includes(cond.questionId)) condOrderWarning = true;
                    }

                    if (currentQuestion.type != SurveyInputType.TEXT && currentQuestion.answers && currentQuestion.answers.length < 1) {
                        app.showError("Hiba", "Kötelező megadni a válaszokat!");
                        return;
                    }

                    if (currentQuestion.type == SurveyInputType.RANGE && (!currentQuestion.rangeSize || currentQuestion.rangeSize < RANGE_MIN_VALUE || currentQuestion.rangeSize > RANGE_MAX_VALUE)) {
                        app.showError("Hiba", __("A skála méretének {MIN} és {MAX} közöttinek kell lennie!", { MIN: RANGE_MIN_VALUE, MAX: RANGE_MAX_VALUE }));
                        return;
                    }

                    if (currentQuestion.type != SurveyInputType.TEXT && currentQuestion.answers && currentQuestion.answers.length > 0) {
                        for (const currentAnswer of currentQuestion.answers) {
                            if (!currentAnswer.name || currentAnswer.name.length < 1) {
                                app.showError("Hiba", "Kötelező megadni a válaszokat!");
                                return;
                            }
                        }
                    }
                }
                if(condOrderWarning && !await confirmDialog(__("Megerősítés"), __("A kérdőív tartalmaz olyan kérdést, amely megjelenítése feltételhet kötött egy később megjelenített kérdéssel. Ez a kitöltőnek nehézségeket okozhat. Így is menti a kérdőívet?"), __("Mentés"), __("Mégsem"))) return;
            }
            else { app.showError("Hiba", "Nem adtál meg egy kérdést sem!"); return; }
            const surveyRecord = (await new SurveyCrud(this.state.surveyRecord).put()).record;
            app.showSuccess(__("Sikeres mentés"), __("Lementve"));

            if (("" + surveyRecord.id) != this.props.match.params.surveyId) {
                history.push(PATH_SURVEY_EDIT + "/" + surveyRecord.id);
            } else if (surveyRecord.id) {
                this.reloadAsync();
            }

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onChangeSurveyTitle(value: any) {
        this.setState({
            surveyRecord: { ...this.state.surveyRecord, title: value }
        });
    }

    /*Answer manipulation functions */
    private onDeleteAnswer(QuestionIndex: number, AnswerIndex: number) {

        this.state.surveyRecord.questions.questions[QuestionIndex].answers.splice(AnswerIndex, 1);
        this.setState({
            surveyRecord: this.state.surveyRecord
        })
    }
    private onAddAnswer(QuestionIndex: number) {
        this.state.surveyRecord.questions.questions[QuestionIndex].answers.push({
            id: this.NextIdGenerator(this.state.surveyRecord.questions.questions[QuestionIndex].answers),
            name: ""

        });
        this.setState({
            surveyRecord: this.state.surveyRecord
        })
    }

    private onChangeAnswer(QuestionIndex: number, AnswerIndex: number, value: any) {
        this.state.surveyRecord.questions.questions[QuestionIndex].answers[AnswerIndex].name = value;

        this.setState({
            surveyRecord: this.state.surveyRecord
        });
    }
    /*End of answer manipulation functions */
    /*Array of questions manipulation functions */

    private onSetQuestionFieldValue(index: number, propName: string, value: string) {

        if (propName != "name") {
            if (this.state.prevPropValue != value) {
                this.state.surveyRecord.questions.questions[index].answers = [
                    {
                        id: 1,
                        name: "",
                    },
                ];
            }
        }

        if (propName == "type" && value == SurveyInputType.RADIO_SMILEY) {
            this.state.surveyRecord.questions.questions[index].type = value;
            this.state.surveyRecord.questions.questions[index].answers = [
                {
                    id: 1,
                    name: NOPE,
                },
                {
                    id: 2,
                    name: RARELY,
                },
                {
                    id: 3,
                    name: CASUALLY,
                },
                {
                    id: 4,
                    name: USUALLY,
                },
                {
                    id: 5,
                    name: MAXIMALLY,
                }
            ];
        }
        if (propName == "type" && value == SurveyInputType.TEXT) {
            this.state.surveyRecord.questions.questions[index].type = value;
            this.state.surveyRecord.questions.questions[index].answers = [
                {
                    id: 1,
                    name: "",
                },
            ];
        }
        if (propName == "type" && value == SurveyInputType.RANGE) {
            this.state.surveyRecord.questions.questions[index].type = value;
            this.state.surveyRecord.questions.questions[index].rangeSize = RANGE_DEF_VALUE;
            this.state.surveyRecord.questions.questions[index].answers = [
                {
                    id: 1,
                    name: "",
                },
                {
                    id: 2,
                    name: "",
                },
            ];
        }
        else {
            this.state.surveyRecord.questions.questions[index][propName] = value;
        }

        this.setState({
            surveyRecord: this.state.surveyRecord, prevPropValue: value
        });

    }

    private onSetQuestionOptional(index: number, value: boolean) {
        this.state.surveyRecord.questions.questions[index].optional = value;
        this.setState({
            surveyRecord: this.state.surveyRecord
        });
    }

    private onChangeConditionCheckbox(index: number, value: boolean) {
        this.state.surveyRecord.questions.questions[index].condition = value ? { answerIds: [] } : undefined;
        this.setState({
            surveyRecord: this.state.surveyRecord
        });
    }

    private onChangeConditionQuestion(question: Question, value: number) {
        if (question && question.condition) {
            question.condition.questionId = !isNaN(value) ? value : undefined;
            question.condition.answerIds = [];
            question.condition.type = QuestionCondition.AT_LEAST_ONE;
        }

        this.setState({
            surveyRecord: this.state.surveyRecord
        });
    }

    private onSetQuestionRangeSize(index: number, value: number) {
        this.state.surveyRecord.questions.questions[index].rangeSize = value;
        this.setState({
            surveyRecord: this.state.surveyRecord
        });
    }

    private onSetQuestionAllowCustom(index: number, value: boolean) {
        this.state.surveyRecord.questions.questions[index].allowCustomAnswer = value;
        this.setState({
            surveyRecord: this.state.surveyRecord
        });
    }

    private onDeleteQuestion(index: number) {
        const id = this.state.surveyRecord.questions.questions[index].id;
        // Ha a törölt kérdés egy másik kérdés feltétele, azt törölni kell...
        this.state.surveyRecord.questions.questions.map((q: Question) => {
            if (q.condition && q.condition.questionId && q.condition.questionId == id) {
                q.condition = undefined;
            }
        });
        this.state.surveyRecord.questions.questions.splice(index, 1);
        this.setState({
            surveyRecord: this.state.surveyRecord
        });
    }

    private onAddQuestion() {
        this.state.surveyRecord.questions.questions.push({
            id: this.NextIdGenerator(this.state.surveyRecord.questions.questions),
            name: "",
            type: SurveyInputType.RADIO,
            answers: [{ id: 1, name: "" }]
        });
        this.setState({
            surveyRecord: this.state.surveyRecord
        });
    }

    private copyQuestion(index: number) {
        // deep copy
        let copy = JSON.parse(JSON.stringify(this.state.surveyRecord.questions.questions[index]));
        // set id of the copy
        copy.id = this.NextIdGenerator(this.state.surveyRecord.questions.questions);
        // push to state
        this.state.surveyRecord.questions.questions.push(copy);
        this.setState({
            surveyRecord: this.state.surveyRecord
        })
    }

    private moveUpQuestion(propname: string, index: number) {
        let surveyRecord = this.state.surveyRecord
        this.changeArray(propname.split("."), surveyRecord.questions, this.moveUpInArray, index);
        this.setState({ surveyRecord: surveyRecord });
    }

    private moveUpInArray(arrayToChange: any[], index: number) {
        if (index <= 0) return;
        let temp_el = arrayToChange[index];
        arrayToChange.splice(index, 1);
        arrayToChange.splice(index - 1, 0, temp_el);
    }
    moveDownQuestion(propname: string, index: number) {
        let surveyRecord = this.state.surveyRecord;
        this.changeArray(propname.split("."), surveyRecord.questions, this.moveDownInArray, index);
        this.setState({ surveyRecord: surveyRecord });
    }

    private moveDownInArray(arrayToChange: any[], index: number) {
        if (index >= arrayToChange.length - 1) return;
        let temp_el = arrayToChange[index];
        arrayToChange.splice(index, 1);
        arrayToChange.splice(index + 1, 0, temp_el);
    }
    private changeArray(propertyNameList: string[], parentObject: any, methodOfChange: (arrayToChange: any[], index: number) => void, index: number) {

        let arrayParams = propertyNameList[0].split("#");

        if (propertyNameList.length == 1) {
            try {
                if (arrayParams.length > 1) {
                    methodOfChange(parentObject[arrayParams[0]][Number(arrayParams[1])], index);
                } else methodOfChange(parentObject[propertyNameList[0]], index);
            } catch (exeption) {
                console.log("The property does not exist on the element.", exeption);
            }

        } else {
            let newPropnameList = propertyNameList.slice(1, propertyNameList.length);

            if (arrayParams.length == 2) {
                this.changeArray(newPropnameList, parentObject[arrayParams[0]][Number(arrayParams[1])], methodOfChange, index);
            } else if (arrayParams.length == 3) {
                this.changeArray(newPropnameList, parentObject[arrayParams[0]][Number(arrayParams[1])][Number(arrayParams[2])], methodOfChange, index);
            } else {
                this.changeArray(newPropnameList, parentObject[propertyNameList[0]], methodOfChange, index);
            }
        }
    }
    /*End of Array of questions manipulation functions */

    private NextIdGenerator(array: any[]) {
        let newId = 1;
        for (const element of array) {
            if (element && element.id && element.id >= newId) newId = element.id + 1;
        }
        return newId;
    }

    private getConditionQuestions(questionId: number): JSX.Element[] {
        let items = [];
        // Ha nincsenek kérdések, üres array
        if (!this.state.surveyRecord.questions) return [];
        items.push(<option value={undefined} key={-1}>{__("Kérem válasszon...")}</option>);
        for (let i = 0; i < this.state.surveyRecord.questions.questions.length; i++) {
            const question = this.state.surveyRecord.questions.questions[i];
            // A kérdéshez nem lehet saját maga feltétel
            if (question && question.id !== questionId && question.type != SurveyInputType.TEXT) items.push(<option key={i} value={question.id}>{question.id + ". " + question.name}</option>);
        }
        return items;
    }

    private getConditionAnswers(questionId: number): { label: string, value: number }[] {
        let items = [];
        const question = this.state.surveyRecord.questions.questions.find((q: Question) => q.id == questionId);
        if (!question) return [];
        for (let i = 0; i < question.answers.length; i++) {
            const answer = question.answers[i];
            // Csak előre definiált válaszok szerepelhetnek itt
            if (answer && answer.name) items.push({ label: answer.name, value: answer.id });
        }
        return items;
    }

    private onMultiSelect(selected: number[], question: Question) {
        if (question.condition) question.condition.answerIds = selected;
        this.setState({ surveyRecord: this.state.surveyRecord });
    }

    private onConditionTypeChanged(e: any, cond: any) {
        cond.type = e.target.value;
        this.setState({ surveyRecord: this.state.surveyRecord });
    }

    private canSetQuestionConditional(questionId: number): boolean {
        if (!this.state.surveyRecord.questions) return false;
        for (let i = 0; i < this.state.surveyRecord.questions.questions.length; i++) {
            const question = this.state.surveyRecord.questions.questions[i];
            if (question.type != SurveyInputType.TEXT && question.id != questionId && question.answers && question.answers.length > 0) return true;
        }
        return false;
    }

    render() {

        if (this.state.loading) {
            return <BubbleLoader />;
        }

        return <div className="content-wrapper">

            <div className="row">
                <div className="column small-12">
                    <h4><Link to={PATH_SURVEY_LIST}>Lista</Link> / {this.state.surveyRecord.title}</h4>
                </div>

                <div className="column small-12">
                    <label>
                        {__("Cím")}
                        <input type="text" value={this.state.surveyRecord.title || ""} onChange={e => this.onChangeSurveyTitle(e.target.value)} />
                    </label>
                </div>
                <div className="column small-12">
                    {this.state.surveyRecord && this.state.surveyRecord.questions ? this.state.surveyRecord.questions.questions.map((currentQuestion: any, questionIndex: number) => {

                        const answerTextBase = currentQuestion.type == SurveyInputType.RANGE ? __("Érték") : __("Válasz");
                        const cond = currentQuestion.condition;
                        const cond_q = cond && cond.questionId ? this.state.surveyRecord.questions.questions.find((q: Question) => q.id == cond.questionId) : null;

                        return <Accordion key={questionIndex}>
                            <ActionAccordionItem actions={[
                                {
                                    title: __("Törlés"),
                                    type: AccordionActionType.Trash,
                                    action: this.onDeleteQuestion.bind(this, questionIndex)
                                },
                                {
                                    title: __("Fel"),
                                    type: AccordionActionType.Up,
                                    action: this.moveUpQuestion.bind(this, "questions", questionIndex)
                                },
                                {
                                    title: __("Le"),
                                    type: AccordionActionType.Down,
                                    action: this.moveDownQuestion.bind(this, "questions", questionIndex)
                                },
                                {
                                    title: __("Duplikálás"),
                                    type: AccordionActionType.Copy,
                                    action: this.copyQuestion.bind(this, questionIndex)
                                }

                            ]}
                                title={questionIndex + 1 + ". Kérdés: " + currentQuestion.name || ""}>
                                <div>
                                    <div className="row expanded">
                                        <div className="small-6 column">
                                            <label className="lms-pages-typeLabel">{questionIndex + 1 + ". Kérdés címe"}</label>
                                            <input type="text" value={currentQuestion.name || ""} onChange={e => this.onSetQuestionFieldValue(questionIndex, "name", e.target.value)} />
                                        </div>
                                        <div className="small-6 column">
                                            <label className="lms-pages-typeLabel">Kérdés típusa</label>
                                            <select value={currentQuestion.type} onChange={e => this.onSetQuestionFieldValue(questionIndex, "type", e.target.value)}>
                                                <option value={SurveyInputType.TEXT}>Szabad szöveges mező</option>
                                                <option value={SurveyInputType.CHECKBOX}>Pipa (checkboxok)</option>
                                                <option value={SurveyInputType.RADIO}>Rádió gombok</option>
                                                <option value={SurveyInputType.RADIO_SMILEY}>Smiley gombok</option>
                                                <option value={SurveyInputType.RANGE}>Skála</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row expanded">
                                        <div className="small-6 column">
                                            <input type="checkbox" checked={currentQuestion.optional} onChange={e => this.onSetQuestionOptional(questionIndex, e.target.checked)} /> {__("A kérdés kitöltése opcionális")}
                                        </div>
                                        <div className="small-6 column">
                                            <input type="checkbox" disabled={!this.canSetQuestionConditional(currentQuestion.id)} checked={cond != undefined} onChange={e => this.onChangeConditionCheckbox(questionIndex, e.target.checked)} /> {__("A kérdés megjelenítése feltételhez kötött")}

                                        </div>
                                    </div>

                                    {cond && <div className="row">
                                        <div className="small-12 column">
                                            <h4>{__("Feltétel megadása")}</h4>
                                        </div>
                                        <div className="small-6 column">
                                            <label className="lms-pages-typeLabel">{__("Kérdés kiválasztása")}</label>
                                            <select value={cond.questionId} onChange={e => { this.onChangeConditionQuestion(currentQuestion, Number(e.target.value)) }}>
                                                {this.getConditionQuestions(currentQuestion.id)}
                                            </select>
                                        </div>
                                        {cond.questionId &&
                                            <div className="small-6 column">
                                                <label className="lms-pages-typeLabel">{__("Válaszok megadása")}</label>
                                                <MultiSelect
                                                    options={this.getConditionAnswers(cond.questionId)}
                                                    selected={cond.answerIds}
                                                    disableSearch
                                                    hasSelectAll={false}
                                                    overrideStrings={{
                                                        selectSomeItems: "Válassza ki a válaszokat...",
                                                        allItemsAreSelected: "Az összes válasz lehetséges",
                                                        selectAll: "Összes válasz kijelölése",
                                                        search: "Keresés",
                                                    }}
                                                    onSelectedChanged={(selected: number[]) => this.onMultiSelect(selected, currentQuestion)}
                                                />
                                            </div>}
                                        {
                                            cond && cond.answerIds.length > 0 && cond_q && cond_q.type == SurveyInputType.CHECKBOX && <div className="small-12 column">
                                                <label className="lms-pages-typeLabel">{__("Feltétel típusa")}</label>
                                                <label>
                                                    <input type="radio" value={QuestionCondition.EXACT_MATCH} checked={cond.type == QuestionCondition.EXACT_MATCH} onChange={e => this.onConditionTypeChanged(e, cond)} />
                                                    {__("pontosan a megadott válaszok legyenek megjelölve")}
                                                </label>
                                                <label>
                                                    <input type="radio" value={QuestionCondition.AT_LEAST_ONE} checked={cond.type == QuestionCondition.AT_LEAST_ONE} onChange={e => this.onConditionTypeChanged(e, cond)} />
                                                    {__("legalább az egyik válasz legyen megjelölve")}
                                                </label>
                                            </div>
                                        }
                                    </div>}

                                    {/*Range size number input for RANGE type*/
                                        currentQuestion.type == SurveyInputType.RANGE &&
                                        <div className="row">
                                            <div className="small-5 column">
                                                <label className="lms-pages-typeLabel">{__("Skála mérete")}</label>
                                                <input type="number" min={RANGE_MIN_VALUE} max={RANGE_MAX_VALUE} value={currentQuestion.rangeSize || RANGE_DEF_VALUE} onChange={e => this.onSetQuestionRangeSize(questionIndex, Number(e.target.value))} />
                                            </div>
                                        </div>
                                    }

                                    {currentQuestion.answers && currentQuestion.answers.length > 0 && !ANSWERS_PREDEFINED_TYPES.includes(currentQuestion.type) &&
                                        <div>
                                            <div className="small-12 column">
                                                <h4>{__("Válaszok")}</h4>

                                                {/*allowCustomAnswer input checkbox  */
                                                   MULTI_ANSWER_TYPES.includes(currentQuestion.type) &&
                                                    <div className="small-12 column">
                                                        <input type="checkbox" checked={currentQuestion.allowCustomAnswer || false} onChange={e => this.onSetQuestionAllowCustom(questionIndex, e.target.checked)} /> {__("A kitöltő megadhat saját választ is! (Szöveges mezőben)")}
                                                    </div>
                                                }

                                            </div>

                                            {currentQuestion.answers.map((currentAnswer: any, answerIndex: number) => {
                                                return <div className="small-8 column" key={answerIndex}>
                                                    <div className="row">
                                                        <div className="small-10 column">
                                                            <label>
                                                                {answerIndex + 1 + ". " + answerTextBase}
                                                                <input type="text" value={currentAnswer.name || ""} onChange={e => this.onChangeAnswer(questionIndex, answerIndex, e.target.value)} />
                                                            </label>
                                                        </div>
                                                        {currentQuestion.type != SurveyInputType.RANGE && <div className="small-2 column">
                                                            <button style={{ marginTop: "2em" }} className="button alert small" onClick={this.onDeleteAnswer.bind(this, questionIndex, answerIndex)} >
                                                                <i className="fa fa-trash" />
                                                            </button>
                                                        </div>}
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    }

                                    {
                                        /* We need the plus button for the question types which can have multiple answers */
                                        MULTI_ANSWER_TYPES.includes(currentQuestion.type) &&
                                        <div className="small-12 column">
                                            <button className="button small" onClick={this.onAddAnswer.bind(this, questionIndex)} >
                                                <i className="fa fa-plus" />
                                            </button>
                                        </div>

                                    }

                                    {currentQuestion.type == SurveyInputType.TEXT && <p>Ennél az esetnél a kérdőívet kitöltő személy írja be a szövegdoboz tartalmát és nincsenek előre megadott válaszok.</p>}
                                    {currentQuestion.type == SurveyInputType.RADIO_SMILEY && <p>Ennél az esetnél a kérdőívet kitöltő személynek fix 1-5 ig skálázható tetszési index jelenik meg.</p>}

                                </div>
                            </ActionAccordionItem>
                        </Accordion>

                    })
                        : ""
                    }

                    <button className="button" onClick={this.onAddQuestion.bind(this)} >
                        <i className="fa fa-plus" /> {__("Új kérdés hozzáadása")}
                    </button>
                </div>
                <div className="column text-right">
                    <br />
                    <button className="button success" onClick={this.onSave.bind(this)}>
                        <i className="fa fa-save" /> {__("Mentés")}
                    </button>
                </div>
            </div>
        </div>;
    }
}
