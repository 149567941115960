import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSecItemTypePermRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Tudásbázis bejegyzés típus
     */
    item_type_id?: number;
    /**
     * Engedély birtokos
     */
    sec_perm_owner_id?: number;
    /**
     * Létrehozás
     *
     * Új rekord létrehozása
     */
    can_create?: boolean;
    /**
     * Módosítás
     *
     * Létező rekord módosítása
     */
    can_update?: boolean;
    /**
     * Törlés
     *
     * Létező rekordok törlése
     */
    can_delete?: boolean;
    /**
     * Olvasás
     *
     * Létező rekordok lekérdezése, szűrése
     */
    can_read?: boolean;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Bejegyzés típus név
     *
     * Bejegyzés típus név
     */
    item_type_title?: string|null;
    /**
     * Bejegyzés típus leírás
     *
     * Bejegyzés típus leírás
     */
    item_type_description?: string|null;
    /**
     * Felhasználó
     *
     * Felhasználó, aki jogosult látni az ilyen állapotú folyamatokat
     */
    user_id?: number|null;
    /**
     * Felhasználó
     *
     * Felhasználó, aki jogosult látni az ilyen állapotú folyamatokat
     */
    login_name?: string|null;
    /**
     * Személy
     *
     * Aki jogosult látni az ilyen állapotú folyamatokat
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * A jogosult természetes személy neve
     */
    fullname?: string|null;
    /**
     * Csoport név
     *
     * A csoport neve
     */
    group_title?: string|null;
    /**
     * Csoport típus
     *
     * A csoport típus azonosítója
     */
    sec_group_type_id?: number|null;
    /**
     * Csoport típus
     *
     * A csoport típus neve
     */
    sec_group_type_title?: string|null;
    /**
     * A jogosult neve
     *
     * A csoport vagy felhasználó humán azonosítására szolgáló szöveg
     */
    perm_owner_label?: string|null;
}

/**
 *  kb.view_sec_item_type_perm - Típus függő tudásbázis bejegyzés jog
 *
 *  Azt mondja meg, hogy az adott típusú tudásbázis bejegyzést ki tud létrehozni.
 */

export default class ViewSecItemTypePerm extends View<IViewSecItemTypePermRecord> {
    public static VIEW_INFO_ID = 2018111502;

    public static getSchemaNameForClass(): string { return 'kb'; }
    public static getViewNameForClass(): string { return 'view_sec_item_type_perm'; }
    public static getTableNameForClass(): string|null { return 'sec_item_type_perm'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSecItemTypePerm>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSecItemTypePermRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSecItemTypePermClassProxy extends ViewClassProxy<IViewSecItemTypePermRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSecItemTypePermClassProxy = new ViewSecItemTypePermClassProxy(ViewSecItemTypePerm);
registerViewClassProxy(viewSecItemTypePermClassProxy);

