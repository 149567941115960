import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewUsrFavouriteRecord {
    /**
     * Azonosító
     *
     * Azonosító
     */
    id?: number;
    /**
     * Felhasználó azonosító
     *
     * Felhasználó azonosító
     */
    sec_user_id?: number|null;
    /**
     * Táblázat azonosító
     *
     * Táblázat azonosító
     */
    table_info_id?: number|null;
    /**
     * Rekord azonosító
     *
     * Rekord azonosító
     */
    rec_id?: number|null;
    /**
     * Létrehozva
     *
     * Létrehozva
     */
    creation_time?: string /*timestamp?*/|null;
    /**
     * Felhasználó bejelentkezési neve
     *
     * Felhasználó bejelentkezési neve
     */
    login_name?: string|null;
    /**
     * Felhasználó teljes neve
     *
     * Felhasználó teljes neve
     */
    fullname?: string|null;
    /**
     * A hivatkozott kedvenc táblázat sémája
     *
     * A hivatkozott kedvenc táblázat sémája
     */
    schema_name?: string|null;
    /**
     * A hivatkozott kedvenc táblázat neve
     *
     * A hivatkozott kedvenc táblázat neve
     */
    table_name?: string|null;
    /**
     * A hivatkozott kedvenc táblázat magyar nyelvű neve
     *
     * A hivatkozott kedvenc táblázat magyar nyelvű neve
     */
    table_display_name?: string|null;
    /**
     * A hivatkozott kedvenc táblázat magyar nyelvű leírása
     *
     * A hivatkozott kedvenc táblázat magyar nyelvű leírása
     */
    table_description?: string|null;
    /**
     * Objektum név
     *
     * Objektum név
     */
    object_name?: string|null;
    /**
     * File kiterjesztés
     *
     * File kiterjesztés
     */
    file_ext?: string|null;
    /**
     * File név
     *
     * File név
     */
    file_title?: string|null;
    /**
     * File kijelzett név
     *
     * File kijelzett név
     */
    file_displayname?: string|null;
    /**
     * File content-type
     *
     * File content-type
     */
    file_content_type?: string|null;
    /**
     * File relatív út
     *
     * File relatív út
     */
    file_relpath?: string|null;
    /**
     * File sha1
     *
     * File sha1
     */
    file_sha1?: string|null;
    /**
     * File média típus azonosító
     *
     * File média típus azonosító
     */
    file_media_type_id?: number|null;
    /**
     * Feladat illusztráció
     *
     * Feladat illusztráció
     */
    exc_illustration?: string|null;
    /**
     * Feladat kép basepath
     *
     * Feladat kép basepath
     */
    exc_imagebasepath?: string|null;
    /**
     * Leírás
     *
     * A hivatkozott kedvenc tartalom egysoros szöveges reprezentációja
     */
    displayvalue?: string|null;
}

/**
 *  usr.view_usr_favourite - Kedvenc
 *
 *  Kedvenc
 */

export default class ViewUsrFavourite extends View<IViewUsrFavouriteRecord> {
    public static VIEW_INFO_ID = 2018061401;

    public static getSchemaNameForClass(): string { return 'usr'; }
    public static getViewNameForClass(): string { return 'view_usr_favourite'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewUsrFavourite>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewUsrFavouriteRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewUsrFavouriteClassProxy extends ViewClassProxy<IViewUsrFavouriteRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewUsrFavouriteClassProxy = new ViewUsrFavouriteClassProxy(ViewUsrFavourite);
registerViewClassProxy(viewUsrFavouriteClassProxy);

