import * as React from "react";
import './LearningPath.css';
import { Card } from "../ui/Panel";
import { __ } from "@src/translation";
import { ValidationResponse } from "../exercise/models/AExerciseTypeConverter";
import { ILpConditionRecord } from "@src/framework/crud/exc/LpConditionCrud";
import { ILpEdgeRecord } from "@src/framework/crud/exc/LpEdgeCrud";
import { ILpNodeRecord } from "@src/framework/crud/exc/LpNodeCrud";
import MultiSelect from "@khanacademy/react-multi-select";
import { RecordType, LearningPathContainer } from "./LearningPathContainer";

export enum ConditionTypes {
    unconditional = 1,
    time,
    point,
    //visitedNodes
}

export enum ConditionOperator {
    less = 1,
    greater,
    equals
}

export enum ConditionLogicalRelation {
    OR = 1,
    AND
}

export type LpEdgeCondition = {
    logicalRelation?: ConditionLogicalRelation;
    leftSide?: LpEdgeCondition | LpCondition;
    rightSide?: LpEdgeCondition | LpCondition;
    hierarchicalIndex?: number;
    pairIndex?: number;
}

export type LpCondition = {
    id?: number;
    type?: ConditionTypes;
    operator?: ConditionOperator;
    value?: number;
    NodesIds?: number[];
}

export type TempLpConditionRecord = {
    condition: any,
    lp_edge: ILpEdgeRecord,
}

export type LpConditionViewState = {
    lpCondition?: LpEdgeCondition | undefined;
    depthIndex?: number;
    nodeRecordType?: RecordType | undefined;
}

export type LpConditionViewProps = {
    condition?: any;
    lpEdge: ILpEdgeRecord;
    nodeList: ILpNodeRecord[];
    nodeTableInfoId?: number;
    depthIndex?: number;
    onSave?: (condition: TempLpConditionRecord) => void;
}

export class LPConditionView extends React.Component<LpConditionViewProps, LpConditionViewState> {
    private index = 1;
    private pairIndex = 1;
    private depthLimit = 10;
    private maxHierarchIndex = 0;
    constructor(props: LpConditionViewProps) {
        super(props);
        let lpCondition = undefined;
        if (props.condition && props.condition.condition && props.condition.condition) { 
            let conditionObj = typeof props.condition.condition === 'object' ? props.condition.condition : JSON.parse(props.condition.condition);
            if (conditionObj.condition)
                lpCondition = this.convertConditionToList(conditionObj.condition);
        }
        this.state = {
            lpCondition,
            depthIndex: props.depthIndex || 0,
            nodeRecordType: LearningPathContainer.getRecordType(props.nodeTableInfoId),
        };

        this.reload();
    }

    private reload() {
        
    }


    componentWillUnmount() {
        if (this.state.lpCondition) {
            let expressObj = { condition: this.convertLpEdgeConditionToList(this.state.lpCondition) }; //DB expects JSON object, not JSON array
            let sExpression = JSON.stringify(expressObj);
            let conditionRecord: TempLpConditionRecord = {
                lp_edge: this.props.lpEdge,
                condition: sExpression,
            };

            if (this.props.onSave) {
                this.props.onSave(conditionRecord);
            }

            this.setState({
                lpCondition: {},
                depthIndex: 0,
            })
        }
    }

    //todo: Implement this! 
    makeValidBeforeSetState(oldConditionState?: LpEdgeCondition, newConditionState?: LpEdgeCondition): ValidationResponse | void {
        if (newConditionState) {
            console.log(newConditionState);
        }
        //only one text/number input which is value of condition
        //check for points to be from 0 to 100 i guess
        //and time limits as well!
    }

    getValidValueAtSetProperty(condition: LpCondition, value: number) { 
        let validatedValue = value;
        if (condition.type) {
            //in case we will have different limits, we have separate if checks
            if (condition.type == ConditionTypes.point) {
                if (value < 0) validatedValue = 0;
                if (value > 100) validatedValue = 100;
            }

            if (condition.type == ConditionTypes.time) {
                if (value < 0) validatedValue = 0;
                if (value > 100) validatedValue = 100;
            }
        }
        return validatedValue;
    }

    /*-------------------------------------------*/
    /* ----------- Converter Methods ------------*/
    /*-------------------------------------------*/

    /**
     * Convertion from frontend to back/db
     * @param lpCondition condition from state
     * @returns condition converted to valid JSON
     */
    private convertLpEdgeConditionToList(lpCondition: LpEdgeCondition) : any {
        let sExpression: any[] = [];
        if (lpCondition) {
            if (lpCondition.logicalRelation) {
                let operator = lpCondition.logicalRelation == ConditionLogicalRelation.AND ? 'and' : 'or';
                sExpression.push(
                    {
                        operator: operator
                    }
                );
            }

            if (lpCondition.rightSide && (lpCondition.rightSide as LpCondition).type) {
                let condition = this.getCondition(lpCondition.rightSide as LpCondition);
                sExpression.push(condition);
            }
            if (lpCondition.leftSide && (lpCondition.leftSide as LpCondition).type) {
                let condition = this.getCondition(lpCondition.leftSide as LpCondition);
                sExpression.push(condition);
            }

            if (lpCondition.rightSide && (lpCondition.rightSide as LpEdgeCondition).hierarchicalIndex) {
                let newElement = this.convertLpEdgeConditionToList(lpCondition.rightSide as LpEdgeCondition);
                if (newElement && newElement.length == 1)
                    sExpression.push(newElement[0]);
                else
                    sExpression.push(newElement);

            }
            if (lpCondition.leftSide && (lpCondition.leftSide as LpEdgeCondition).hierarchicalIndex) {
                let newElement = this.convertLpEdgeConditionToList(lpCondition.leftSide as LpEdgeCondition);
                if (newElement && newElement.length == 1)
                    sExpression.push(newElement[0]);
                else
                    sExpression.push(newElement)
            }
        }
        return sExpression;
    }

    private getCondition(LpCondition: LpCondition) : any {
        let condition = '{"condition" : "';
        if (LpCondition) {
            switch (LpCondition.type) {
                case ConditionTypes.point:
                    condition += 'p';
                    break;
                case ConditionTypes.time:
                    condition += 't';
                    break;
                case ConditionTypes.unconditional:
                    condition += 'u';
                    break;
                //visitedNodes
                /*case ConditionTypes.visitedNodes:
                    condition += 'n';
                    break;*/
                default:
                    break;
            }

            switch (LpCondition.operator) {
                case ConditionOperator.equals:
                    condition += '=';
                    break;
                case ConditionOperator.greater:
                    condition += '>';
                    break;
                case ConditionOperator.less:
                    condition += '<';
                    break;
                default:
                    break;
            }
            //visitedNodes
            /*if (LpCondition.type == ConditionTypes.visitedNodes)
                condition += LpCondition.NodesIds ? JSON.stringify(LpCondition.NodesIds) : "[]";
            else 
                condition += LpCondition.value ? LpCondition.value : 0;
            */
            condition += LpCondition.value ? LpCondition.value : 0;
            condition += '" }';
        }
        return JSON.parse(condition);
    }

    /**
     * Converts condition from db to frontend
     * @param condition condition from db in array type
     */
    private convertConditionToList(condition: any[], hierarchicalIndex?: number) : LpEdgeCondition {
        let tempEdgeCondition: LpEdgeCondition = {};
        if (!hierarchicalIndex) {
            hierarchicalIndex = 1;
        }
        try {
            tempEdgeCondition.hierarchicalIndex = hierarchicalIndex;
            if (condition[2] && condition[2].condition) {
                tempEdgeCondition.leftSide = this.getConditionFromString(condition[2].condition);
                tempEdgeCondition.leftSide.id = this.index;
                this.index += 1;
            }
            if (condition[1] && condition[1].condition) {
                tempEdgeCondition.rightSide = this.getConditionFromString(condition[1].condition);
                tempEdgeCondition.rightSide.id = this.index;
                this.index += 1;
            }
            if (condition[0] && condition[0].operator) {
                tempEdgeCondition.logicalRelation = condition[0].operator == "and" ? ConditionLogicalRelation.AND : ConditionLogicalRelation.OR;
                tempEdgeCondition.pairIndex = this.pairIndex;
                this.pairIndex += 1;
            }
            //in case of 1 condition
            else if (condition[0] && condition[0].condition) {
                tempEdgeCondition.leftSide = this.getConditionFromString(condition[0].condition);
                tempEdgeCondition.leftSide.id = this.index;
                this.index += 1;
            }

            if (condition[2] && Array.isArray(condition[2])) {
                tempEdgeCondition.leftSide = this.convertConditionToList(condition[2], hierarchicalIndex += 1);
            }
            if (condition[1] && Array.isArray(condition[1])) {
                tempEdgeCondition.rightSide = this.convertConditionToList(condition[1], hierarchicalIndex += 1);
            }
        }
        catch(exception) {
            console.log("Some error occured:", exception);
        }
        return tempEdgeCondition;
    }

    private getConditionFromString(condString: string): LpCondition {
        let tempCondition: LpCondition = {};
        if (condString[0]) {
            switch (condString[0]) {
                case "u":
                    tempCondition.type = ConditionTypes.unconditional;
                    break;
                case "t":
                    tempCondition.type = ConditionTypes.time;
                    break;
                case "p":
                    tempCondition.type = ConditionTypes.point;
                    break;
                //visitedNodes
                /*
                case "n":
                    tempCondition.type = ConditionTypes.visitedNodes;
                    break;
                */
                default:
                    break;
            }
        }
        if (condString[1]) {
            switch (condString[1]) {
                case ">":
                    tempCondition.operator = ConditionOperator.greater;
                    break;
                case "<":
                    tempCondition.operator = ConditionOperator.less;
                    break;
                case "=":
                    tempCondition.operator = ConditionOperator.equals;
                    break;
                default:
                    break;
            }
        }
        /*if (tempCondition.type != ConditionTypes.visitedNodes && !isNaN(parseInt(condString.substring(2, condString.length)))) {
            tempCondition.value = parseInt(condString.substring(2, condString.length));
        }
        else if (tempCondition.type == ConditionTypes.visitedNodes) {
            let nodesIdsList = JSON.parse(condString.substring(2, condString.length));
            tempCondition.NodesIds = nodesIdsList;
        }*/
        tempCondition.value = parseInt(condString.substring(2, condString.length));

        return tempCondition;
    }

    private handleInputChange(event: any) {
        const target = event.target;
        var value = target.value;

        if (target.type === 'checkbox') {
            value = target.checked;
        } else if (target.type == 'select-one' || target.type == "number" || target.type == "coordinate") {
            value = Number(target.value);
            if (isNaN(value)) value = target.value;
        }

        let newLpCondition = JSON.parse(JSON.stringify(this.state.lpCondition));
        let propertyNameList = target.name.split(".");
        let arrayParams = propertyNameList[0].split("#");
        let indexOfChangingObject = arrayParams[arrayParams.length - 1];

        this.setProperty(indexOfChangingObject, propertyNameList[propertyNameList.length - 1], newLpCondition, value);
        
        this.makeValidBeforeSetState(this.state.lpCondition, newLpCondition); 

        this.setState({
            lpCondition: newLpCondition
        });
    }

    private setProperty(indexOfChangingObject: string, propertyToBeChanged: string, parentObject: any, value: any) {
        if (parentObject && indexOfChangingObject && propertyToBeChanged) {
            if (propertyToBeChanged == "logicalRelation") {
                if (parentObject.pairIndex && parentObject.pairIndex == Number(indexOfChangingObject)) {
                    parentObject[propertyToBeChanged] = Number(value);
                }
                else {
                    if (parentObject.leftSide && parentObject.leftSide.pairIndex) {
                        this.setProperty(indexOfChangingObject, propertyToBeChanged, parentObject.leftSide, value);
                    }
                    if (parentObject && parentObject.rightSide.pairIndex) {
                        this.setProperty(indexOfChangingObject, propertyToBeChanged, parentObject.rightSide, value);
                    }
                }
            }
            else{
                if (parentObject.leftSide && parentObject.leftSide.id && parentObject.leftSide.id == Number(indexOfChangingObject)) {
                    if (propertyToBeChanged == "value") {
                        value = this.getValidValueAtSetProperty(parentObject.leftSide, value);
                    }
                    parentObject.leftSide[propertyToBeChanged] = value;
                }
                else if (parentObject.rightSide && parentObject.rightSide.id && parentObject.rightSide.id == Number(indexOfChangingObject)) {
                    if (propertyToBeChanged == "value") {
                        value = this.getValidValueAtSetProperty(parentObject.rightSide, value);
                    }
                    parentObject.rightSide[propertyToBeChanged] = value;
                }
                else {
                    if (parentObject.leftSide && (parentObject.leftSide as LpEdgeCondition).hierarchicalIndex) {
                        this.setProperty(indexOfChangingObject, propertyToBeChanged, parentObject.leftSide, value);
                    }
                    if (parentObject.rightSide && (parentObject.rightSide as LpEdgeCondition).hierarchicalIndex) {
                        this.setProperty(indexOfChangingObject, propertyToBeChanged, parentObject.rightSide, value);
                    }
                }
            }
        }
    }

    onMultiSelect(selected: any, index: number) {
        let newLpCondition = JSON.parse(JSON.stringify(this.state.lpCondition));
        this.setProperty(index.toString(), "NodesIds", newLpCondition, selected);
        this.makeValidBeforeSetState(this.state.lpCondition, newLpCondition); 
        this.setState({
            lpCondition: newLpCondition
        });
    }

    /*-------------------------------------------*/
    /* ------------ onClick Methods -------------*/
    /*-------------------------------------------*/

    private onAddNewCondition() {
        let newLpEdgeCond = this.recursiveConditionAdd(this.state.lpCondition);
        this.setState({
            lpCondition: newLpEdgeCond
        });
    }

    private removeCondition(condition_id: number) {
        if (this.state.lpCondition) {
            let temp_edge_cond = this.recursiveRemoveCondition(condition_id, this.state.lpCondition);
            this.setState({
                lpCondition: temp_edge_cond,
            })
        }
    }

    /*-------------------------------------------*/
    /* ----------- onClick handlers -------------*/
    /*-------------------------------------------*/


    private recursiveConditionAdd(LpEdgeCondition: LpEdgeCondition | undefined, depthLevel?: number) : LpEdgeCondition {
        let tempLP = Object.assign({}, LpEdgeCondition);
        let depthLimit = 2;
        //TODO: depth level to not go over 4 conditions

        let newCondition: LpCondition = {
            type: ConditionTypes.point,
            operator: ConditionOperator.equals,
            value: 0,
            id: this.index,
        };
        //In case when we have no conditions
        //need to check depth as well here
        if (!LpEdgeCondition || (LpEdgeCondition.leftSide == undefined && LpEdgeCondition.rightSide == undefined)) {
            let lpCondition: LpEdgeCondition = {
                hierarchicalIndex: 1,
                leftSide: newCondition,
                rightSide: undefined,
            }
            if (!depthLevel) this.index++;
            return lpCondition;
        }

        //means that left side is a condition and we can add a new one there!
        //todo redundant code, clean it a bit!!
        let tempOldCondition;
        let newLpEdgeCondition: LpEdgeCondition = {};

        if (LpEdgeCondition.leftSide && (LpEdgeCondition.leftSide as LpCondition).type && !LpEdgeCondition.rightSide) {
            //replace LpEdgeCondition.leftSide to LpEdgeCondition
            tempOldCondition = LpEdgeCondition.leftSide;
            LpEdgeCondition.leftSide = newLpEdgeCondition;
            LpEdgeCondition.leftSide.leftSide = tempOldCondition;
            LpEdgeCondition.leftSide.hierarchicalIndex = LpEdgeCondition.hierarchicalIndex ? LpEdgeCondition.hierarchicalIndex + 1 : 1;
            //new condition added to right side
            LpEdgeCondition.leftSide.rightSide = newCondition;
            LpEdgeCondition.leftSide.pairIndex = this.pairIndex;
            this.pairIndex++;
            LpEdgeCondition.leftSide.logicalRelation = ConditionLogicalRelation.AND;
        }
        else if (LpEdgeCondition.rightSide && (LpEdgeCondition.rightSide as LpCondition).type && !LpEdgeCondition.leftSide) {
            //replace LpEdgeCondition.leftSide to LpEdgeCondition
            tempOldCondition = LpEdgeCondition.rightSide;
            LpEdgeCondition.rightSide = newLpEdgeCondition;
            LpEdgeCondition.rightSide.rightSide = tempOldCondition;
            LpEdgeCondition.rightSide.hierarchicalIndex = LpEdgeCondition.hierarchicalIndex ? LpEdgeCondition.hierarchicalIndex + 1 : 1;
            //new condition added to right side
            LpEdgeCondition.rightSide.leftSide = newCondition;
            LpEdgeCondition.rightSide.pairIndex = this.pairIndex;
            this.pairIndex++;
            LpEdgeCondition.rightSide.logicalRelation = ConditionLogicalRelation.AND;
        }
        else {
            //TODO DEPTH CHECK!!
            if ((LpEdgeCondition.leftSide as LpEdgeCondition).hierarchicalIndex) {
                let leftEdgeCond = LpEdgeCondition.leftSide as LpEdgeCondition;
                if (!leftEdgeCond.leftSide) {
                    LpEdgeCondition.leftSide = this.recursiveConditionAdd(leftEdgeCond);
                }
                else if (!leftEdgeCond.rightSide) {
                    LpEdgeCondition.leftSide = this.recursiveConditionAdd(leftEdgeCond);
                }
                newLpEdgeCondition = LpEdgeCondition;
            }            
            if ((LpEdgeCondition.rightSide as LpEdgeCondition).hierarchicalIndex) {
                let rightEdgeCond = LpEdgeCondition.rightSide as LpEdgeCondition;
                if (!rightEdgeCond.rightSide) {
                    LpEdgeCondition.rightSide = this.recursiveConditionAdd(rightEdgeCond, depthLevel ? depthLevel + 1 : 1);
                }
                else if (!rightEdgeCond.rightSide) {
                    LpEdgeCondition.rightSide = this.recursiveConditionAdd(rightEdgeCond, depthLevel ? depthLevel + 1 : 1);
                }
                newLpEdgeCondition = LpEdgeCondition;
            }
            else if ((LpEdgeCondition.leftSide as LpCondition).type && (LpEdgeCondition.rightSide as LpCondition).type) {
                let tempLp = LpEdgeCondition;
                newLpEdgeCondition.leftSide = tempLp;
                newLpEdgeCondition.hierarchicalIndex = LpEdgeCondition.hierarchicalIndex;
                newLpEdgeCondition.rightSide = undefined;
                let tempRightSide = this.recursiveConditionAdd(newLpEdgeCondition.rightSide, depthLevel ? depthLevel + 1 : 1);
                newLpEdgeCondition.rightSide = tempRightSide;
                newLpEdgeCondition.pairIndex = this.pairIndex;
                this.pairIndex++;
            }
            newLpEdgeCondition.logicalRelation = ConditionLogicalRelation.AND;
        }
        if (!depthLevel) this.index++;
        return newLpEdgeCondition;
    }

    //TODO:: Additionally update index of conditions after removal, so it wont look like 1, 2, 6, 9,
    recursiveRemoveCondition(condition_id: number, lpEdgeCondition: LpEdgeCondition): LpEdgeCondition {
        if (lpEdgeCondition) {
            let temp_edge_cond: LpEdgeCondition = lpEdgeCondition;
            if (temp_edge_cond.leftSide && (temp_edge_cond.leftSide as LpCondition).id && (temp_edge_cond.leftSide as LpCondition).id == condition_id) {
                temp_edge_cond.leftSide = undefined;
                return temp_edge_cond
            }
            else if (temp_edge_cond.rightSide && (temp_edge_cond.rightSide as LpCondition).id && (temp_edge_cond.rightSide as LpCondition).id == condition_id) { 
                temp_edge_cond.rightSide = undefined;
                return temp_edge_cond
            }
            else {
                if (temp_edge_cond.leftSide && (temp_edge_cond.leftSide as LpEdgeCondition).hierarchicalIndex) {
                    let leftSide = this.recursiveRemoveCondition(condition_id, temp_edge_cond.leftSide as LpEdgeCondition);
                    temp_edge_cond.leftSide = leftSide;
                }
                if (temp_edge_cond.rightSide && (temp_edge_cond.rightSide as LpEdgeCondition).hierarchicalIndex) {
                    let rightSide = this.recursiveRemoveCondition(condition_id, temp_edge_cond.rightSide as LpEdgeCondition);
                    temp_edge_cond.rightSide = rightSide;
                }
                return temp_edge_cond;
            }
        }
        return lpEdgeCondition;
    }

    /*-------------------------------------------*/
    /* ------------ Render methods --------------*/
    /*-------------------------------------------*/

    private getLpCondition(lpCondition: LpCondition) {
        let multiselect_options: any[] = [];
        for (let i = 0; i < this.props.nodeList.length; i++) {
            let node = this.props.nodeList[i];
            multiselect_options.push({ label: node.title, value: i });
        }
        /*if (lpCondition.type == ConditionTypes.visitedNodes) {
            if (!lpCondition.NodesIds) {
                lpCondition.NodesIds = [];    
            }
        }*/
        return (
            <Card className="lp-condtion-card" header={
                <div>
                    <label className="exe-editor-fieldlabel-1">{__("Condition ") + lpCondition.id}</label>
                    <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeCondition.bind(this, lpCondition.id)}><i className="fa fa-trash"></i></button>
                </div>
                }>
                <div className="row">
                    <div className="large-4 columns">
                        <label>{__("Type:")}</label>
                        <label>
                            <select value={lpCondition.type} onChange={this.handleInputChange.bind(this)} name={"condition#" + lpCondition.id + ".type"}>
                                <option value={ConditionTypes.unconditional}>{__("Unconditional")}</option>
                                <option value={ConditionTypes.time}>{__("Time condition")}</option>
                                {
                                this.state.nodeRecordType && (this.state.nodeRecordType == RecordType.EXERCISE || this.state.nodeRecordType == RecordType.EXERCISESERIES) && 
                                <option value={ConditionTypes.point}>{__("Point condition")}</option>
                                }
                                {//<option value={ConditionTypes.visitedNodes}>{__("Visited nodes")}</option>
                                }
                            </select>
                        </label>
                    </div>
                    { //lpCondition.type != ConditionTypes.visitedNodes ?
                        <div className="large-8 columns">
                            <div className="row"> 
                                <div className="large-6 columns">
                                    <label>{__("Condition:")}</label>
                                    <label>
                                        <select value={lpCondition.operator} onChange={this.handleInputChange.bind(this)} name={"condition#" + lpCondition.id + ".operator"}>
                                            <option value={ConditionOperator.equals}>{__("Equals") + (lpCondition.type == ConditionTypes.point ? __("(points)") : __("(minutes)"))}</option>
                                            <option value={ConditionOperator.less}>{__("Less than") + (lpCondition.type == ConditionTypes.point ? __("(points)") : __("(minutes)"))}</option>
                                            <option value={ConditionOperator.greater}>{__("Greater than") + (lpCondition.type == ConditionTypes.point ? __("(points)") : __("(minutes)"))}</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="large-6 columns">
                                    <label>{__("Value:")}
                                        <input type="number" name={"condition#" + lpCondition.id + ".condition.value"} value={lpCondition.value}
                                            onChange={this.handleInputChange.bind(this)}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        /*:
                        <div className="large-8 columns">
                            <label>{__("Select nodes:")}</label>
                            <MultiSelect
                                options={multiselect_options}
                                selected={lpCondition.NodesIds!}
                                onSelectedChanged={(selected: number[]) => this.onMultiSelect(selected, lpCondition.id!)}
                            />
                        </div>*/
                    }
                </div>
            </Card>
        );
    }

    private handleRecursiveConditionView(LpCondition: LpEdgeCondition | undefined) {
        let lpCondition = (LpCondition as LpEdgeCondition).hierarchicalIndex ? LpCondition as LpEdgeCondition : undefined;
        return (
            <div>
                {lpCondition ?
                <div>
                    {lpCondition.leftSide && (lpCondition.leftSide as LpCondition).type ?
                        this.getLpCondition(lpCondition.leftSide as LpCondition)
                        : lpCondition.leftSide ?
                            this.handleRecursiveConditionView(lpCondition.leftSide as LpEdgeCondition)
                            :
                            ""
                    }
                    {
                    lpCondition.logicalRelation && lpCondition.hierarchicalIndex && lpCondition.pairIndex ?
                        <div className="row">
                            <div className="radio">
                                <label>
                                    <input type="radio" value={ConditionLogicalRelation.AND} name={"condition#" + lpCondition.pairIndex + ".logicalRelation"} checked={lpCondition.logicalRelation == ConditionLogicalRelation.AND} onChange={this.handleInputChange.bind(this)} />
                                    <strong>{__("AND")}</strong>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" value={ConditionLogicalRelation.OR} name={"condition#" + lpCondition.pairIndex + ".logicalRelation"} checked={lpCondition.logicalRelation == ConditionLogicalRelation.OR} onChange={this.handleInputChange.bind(this)} />
                                    <strong>{__("OR")}</strong>
                                </label>
                            </div>
                        </div>
                    : "" 
                    }
                    {lpCondition.rightSide && (lpCondition.rightSide as LpCondition).type ?
                        this.getLpCondition(lpCondition.rightSide as LpCondition)
                        : lpCondition.rightSide ?
                            this.handleRecursiveConditionView(lpCondition.rightSide as LpEdgeCondition)
                            :
                            ""
                    }
                </div>
                : ""}
            </div>
        );
    }

    render() {
        let tempLpEdgeCondition = this.state.lpCondition;
        return (
            <div>
                {(tempLpEdgeCondition) && (tempLpEdgeCondition as LpEdgeCondition).hierarchicalIndex ? this.handleRecursiveConditionView(tempLpEdgeCondition as LpEdgeCondition)
                : ""
                }
                <div className="row">
                    <button className="button small" onClick={this.onAddNewCondition.bind(this)}><i className="fa fa-plus"></i> { __("Add new condition") }</button>
                </div>
            </div>
        );
    }
}