import * as React from "react";
import { __ } from "@src/translation";
import { IViewKapCourseRecord } from "@src/framework/view/kap/ViewKapCourse";
import { app } from '@src/index';
import ViewKapCourseUser, { IViewKapCourseUserRecord } from "@src/framework/view/kap/ViewKapCourseUser";
import NewMessagePopUp from "@src/module/messengerModule/components/NewMessagePopUp";
import CourseCrud from "@src/framework/crud/kap/CourseCrud";
import { BubbleLoader } from 'react-css-loaders';
import ButtonGroup, { ButtonAlign, ButtonSize } from "@src/component/ui/ButtonGroup";

type CourseDetailsUsersPageProps = {
    courseRecord: IViewKapCourseRecord;
}

type CourseDetailsUsersPageState = {
    courseUsers: IViewKapCourseUserRecord[];
    selectedMemberIds: number[];
    messageDialogOpen: boolean;
    loading: boolean;
}

/**Résztvevők - Kurzus hallgató nézet */

export class CourseDetailsUsersPage extends React.Component<CourseDetailsUsersPageProps, CourseDetailsUsersPageState> {

    constructor(props: CourseDetailsUsersPageProps) {
        super(props);
        this.state = {
            courseUsers: [],
            selectedMemberIds: [],
            messageDialogOpen: false,
            loading: true
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {
        try {

            const courseId = this.props.courseRecord.id;
            const courseUsers: IViewKapCourseUserRecord[] = (await ViewKapCourseUser.list({ filter: { course_id: courseId } }));

            this.setState({
                courseUsers,
                loading: false
            })

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private toggleChecked = (recordID: number): void => {
        let selectedMemberIds = this.state.selectedMemberIds;
        let idx = selectedMemberIds.findIndex((el) => { return el == recordID })
        if (idx > -1) {
            selectedMemberIds.splice(idx, 1);
        } else {
            selectedMemberIds.push(recordID);
        }
        this.setState({ selectedMemberIds });
    }

    render() {

        if (this.state.loading) {
            return <BubbleLoader />;
        }

        const courseRecord = this.props.courseRecord;

        let allID = this.state.courseUsers.map((u) => u.sec_user_id!);
        if (courseRecord.teacher_id) allID.push(courseRecord.teacher_id);
        const allSelected = allID.length === this.state.selectedMemberIds.length;
        let selectClass: string = allSelected ? " alert" : " primary";

        return <div className="row">
            <ButtonGroup align={ButtonAlign.Left} size={ButtonSize.Normal}>
                <button className="button"
                    disabled={this.state.selectedMemberIds.length === 0}
                    onClick={() => this.setState({ messageDialogOpen: true })}>
                    <i className="fa fa-envelope" /> &nbsp;{__("Üzenet küldése a kiválasztott felhasználóknak")}
                </button>

                <button className={"button" + selectClass}
                    disabled={allID.length === 0}
                    onClick={() => this.setState({ selectedMemberIds: allSelected ? [] : allID })}>
                    <i className="fa fa-check-double" /> &nbsp;{allSelected ? __("Kijelölések megszüntetése") : __("Mindenki kijelölése")}
                </button>
            </ButtonGroup>

            <div className="column small-10" style={{ marginTop: "1em" }}>
                <h5>
                    {__("Képző")}
                </h5>
            </div>
            <div className="column small-12">
                <hr style={{ marginLeft: 0, maxWidth: "100%" }} />
            </div>
            <div className="column medium-offset-1 medium-7 small-12" style={{ marginTop: "0.5em", alignSelf: "center" }}>
                {courseRecord.teacher_id ? <label>
                    <input type="checkbox"
                        checked={this.state.selectedMemberIds.includes(courseRecord.teacher_id!)}
                        onChange={e => this.toggleChecked(courseRecord.teacher_id!)}
                    /> {courseRecord.teacher_fullname}
                </label> : __("A kurzushoz nem tartozik képző.")}
            </div>

            <div className="column small-10" style={{ marginTop: "1em" }}>
                <h5>
                    {__("Kurzus hallgatói")}
                </h5>
            </div>

            <div className="column small-12">
                <hr style={{ marginLeft: 0, maxWidth: "100%" }} />
            </div>

            {
                this.state.courseUsers.length > 0 ? this.state.courseUsers.map(courseUser => {
                    return <div key={courseUser.sec_user_id} className="column medium-offset-1 medium-7 small-12" style={{ marginTop: "0.5em", alignSelf: "center" }}>
                        <label>
                            <input type="checkbox"
                                checked={this.state.selectedMemberIds.includes(courseUser.sec_user_id!)}
                                onChange={e => this.toggleChecked(courseUser.sec_user_id!)}
                            /> {courseUser.participant_fullname}
                        </label>
                    </div>
                }) : <div className="column medium-offset-1 medium-7 small-12" style={{ marginTop: "0.5em", alignSelf: "center" }}> {__("A kurzusnak nincs hallgatója.")} </div>
            }

            <NewMessagePopUp recipientIds={this.state.selectedMemberIds}
                memberCard={false}
                isOpen={this.state.messageDialogOpen}
                tableInfoId={CourseCrud.TABLE_INFO_ID}
                recordId={courseRecord.id}
                onClose={() => this.setState({ messageDialogOpen: false })}
            />

        </div>
    }

}