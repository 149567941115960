import './style.css';
import { AExerciseEngine } from '../../models/AExerciseEngine';

type SetsWithIntersectionData = {
    title: string;
    description: string;
    imagebasepath: string;
    illustration: string;
    illustration_alt: string;
    imageanswers: boolean;
    outerset: boolean;
    outersetheader: string;
    setheaders: string[];
    answers: string[];
}

type SetsWithIntersectionSolution = {
    answer: number[][];
    fullmatch: boolean;
}

export class EKESetsWithIntersectionExerciseEngine extends AExerciseEngine {

    initExercise(params: ExerciseParams): void {
        params.element.innerHTML = "";
        
        this.root.appendChild(document.createElement("div")).innerHTML = params.exercise.description;
        this.root.classList.add("eke-sets-with-intersection");
    }
    getUserSolution(): SetsWithIntersectionSolution {
        throw new Error("Method not implemented.");
    }
    receiveEvaluation(evaluated: Evaluated): void {
        throw new Error("Method not implemented.");
    }
    showCorrectSolution(solution: any): void {
        throw new Error("Method not implemented.");
    }
    isUserReady(): boolean {
        throw new Error("Method not implemented.");
    }
    showHelp(solution: any): void {
        throw new Error("Method not implemented.");
    }
}