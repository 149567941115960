import * as React from 'react'
import * as PublicServer from '@src/server/PublicServer';
import VimeoCrud, { IVimeoUploadRecord } from '@src/framework/crud/media/VimeoUploadCrud';
import * as OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import * as OoFileCrudPub from '@src/framework/crud/media_pub/OoFileCrud';

import { MEDIA_TYPE_IMAGES, MEDIA_TYPE_IFRAMES, MEDIA_TYPE_VIDEO, MEDIA_TYPE_SOUND } from '@src/framework/file/FileActions';
import { NotForWeak } from '../book/viewer/page_addition/BookNotForWeak';
import { saveVimeoUserEvents } from '@src/framework/vimeo/Vimeo';
import { AudioPlayer } from '../ui/AudioPlayer';
import { __ } from '@src/translation';
import { app } from '@src/index';


type MediaViewerProps = {
    ooFileId: number;
    isPublished: boolean;

    notForWeak: boolean;
    alt?: string;
}
type MediaViewerState = {
    loading: boolean;

    file?: IOoFileRecord;
    vimeo?: IVimeoUploadRecord;
}

export class MediaViewer extends React.Component<MediaViewerProps, MediaViewerState> {

    private vimeoIframeAdded: HTMLIFrameElement;

    constructor(prop: any) {
        super(prop);

        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: MediaViewerProps) {
        if (this.props.ooFileId !== prevProps.ooFileId) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {
        try {
            var file;
            if (this.props.isPublished) {
                file = (await OoFileCrudPub.default.load(this.props.ooFileId)).record;
            } else {
                file = (await OoFileCrud.default.load(this.props.ooFileId)).record;
            }

            var vimeo: IVimeoUploadRecord | undefined;

            if (file) {
                const response = await VimeoCrud.list({ filter: { sha1: file.sha1 }, limit: 1 });
                if (response.length > 0) {
                    vimeo = response[0];
                }
            }

            this.setState({
                file,
                vimeo,
            }, this.saveUserEvents.bind(this));

        }
        catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private saveUserEvents() {
        if (this.refs.video && this.refs.video != this.vimeoIframeAdded && this.state.file) {

            saveVimeoUserEvents(this.refs.video as HTMLIFrameElement, this.state.file.displayname || this.state.file.title || "", this.state.file.id!, this.state.file.sha1!, this.state.file.media_type_id!);
            this.vimeoIframeAdded = this.refs.video as HTMLIFrameElement;
        }
    }

    private audioPlayed(e: Event) {
        if (this.state.file) {
            let data: PublicServer.IViewEventData = {
                eventTarget: PublicServer.UserEventTargets.SOUND,
                eventType: PublicServer.UserEventTypes.PLAY,
                sha1: this.state.file.sha1,
                mediaTypeId: this.state.file.media_type_id || undefined
            }
            PublicServer.sendUserViewEvent({
                tableInfoId: OoFileCrudPub.default.TABLE_INFO_ID,
                recordId: this.state.file.id!,
                name: this.state.file.displayname ? this.state.file.displayname : this.state.file.title!,
                data: data
            });
        }
    }
    private audioEnded(e: Event) {
        if (this.state.file) {
            let data: PublicServer.IViewEventData = {
                eventTarget: PublicServer.UserEventTargets.SOUND,
                eventType: PublicServer.UserEventTypes.ENDED,
                sha1: this.state.file.sha1,
                mediaTypeId: this.state.file.media_type_id || undefined
            }
            PublicServer.sendUserViewEvent({
                tableInfoId: OoFileCrudPub.default.TABLE_INFO_ID,
                recordId: this.state.file.id!,
                name: this.state.file.displayname ? this.state.file.displayname : this.state.file.title!,
                data: data
            });
        }
    }
    private imageShown(e: Event) {
        if (this.state.file) {
            let data: PublicServer.IViewEventData = {
                eventTarget: PublicServer.UserEventTargets.IMAGE,
                eventType: PublicServer.UserEventTypes.VIEWED,
                sha1: this.state.file.sha1,
                mediaTypeId: this.state.file.media_type_id || undefined
            }
            PublicServer.sendUserViewEvent({
                tableInfoId: OoFileCrudPub.default.TABLE_INFO_ID,
                recordId: this.state.file.id!,
                name: this.state.file.displayname ? this.state.file.displayname : this.state.file.title!,
                data: data
            });
        }
    }

    render() {
        if (!this.state.file) return null;

        const filePath = "/api/media/file/" + this.state.file.sha1;
        let returncontent;
        const mediaTypeID = this.state.file.media_type_id;
        let path = "/api/media/relpath/" + this.state.file!.relpath;

        //For Iframes
        if ((mediaTypeID && MEDIA_TYPE_IFRAMES.includes(mediaTypeID)) || this.state.file.ext == ".html") {
            returncontent = <div>
                <div className="flex-video widescreen">
                    <iframe width="100%"
                        height="100%"
                        className="loaded"
                        src={path ? path : ""}
                        style={{ border: "0px" }}>
                    </iframe>
                </div>
            </div>
        }
        //For Images
        else if (mediaTypeID && MEDIA_TYPE_IMAGES.includes(mediaTypeID)) {
            this.imageShown.bind(this);
            returncontent = <div onLoad={this.imageShown.bind(this)}>
                <div className="widescreen">
                    <img src={filePath} alt={this.props.alt || this.state.file.displayname || ""} />

                </div>
            </div>
        }
        //For Videos
        else if (mediaTypeID == MEDIA_TYPE_VIDEO) {
            returncontent = this.state.vimeo
                ?
                <div className="flex-video widescreen">
                    <iframe ref="video" key={this.state.file.id} allowFullScreen={true} src={"https://player.vimeo.com/video/" + this.state.vimeo.vimeo_id} style={{ border: "0px" }} />
                    {this.props.notForWeak ? <NotForWeak /> : null}
                </div>
                :
                <div style={{ position: "relative" }}>
                    <video ref="video" key={this.state.file.id} width="100%" controls={true} controlsList="nodownload">
                        <source src={filePath} />
                    </video>
                    {this.props.notForWeak ? <NotForWeak /> : null}
                </div>
        }
        //For Sound
        else if (mediaTypeID == MEDIA_TYPE_SOUND) {

            returncontent = <div className="widescreen">

                <AudioPlayer audioEnded={this.audioEnded.bind(this)} audioPlayed={this.audioPlayed.bind(this)} audios={[{ src: filePath, title: this.state.file.displayname || this.state.file.title! }]}>
                </AudioPlayer>
            </div>
        }
        //For PDF
        else if (this.state.file.ext && this.state.file.ext == ".pdf") {
            returncontent = <div className="flex-video widescreen">
                <iframe
                    src={"/api/media/relpath/" + this.state.file.relpath}
                    width="100%"
                    height="100%"

                />
            </div>
        }
        //Downloadable Content
        else {
            returncontent = <div>
                <a href={path} download>
                    <div>
                        <div style={{ height: "60px" }}>
                            <img src="/img/IKON_SET/document_icon.png" style={{ width: "60px" }}></img>
                        </div>
                    </div>
                    <p>{__("Letölthető dokumentum. Kattints az ikonra, hogy elinduljon a letöltés.")}</p>
                </a>
            </div>
        }
        return returncontent;
    }
}
