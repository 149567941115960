import * as React from 'react';
import { match } from 'react-router';
import { app } from '@src/index';
import InstituteGroupCrud, { IInstituteGroupRecord } from '@src/framework/crud/sys/InstituteGroupCrud';
import ViewInstituteGroupMember, { IViewInstituteGroupMemberRecord } from '@src/framework/view/sys/ViewInstituteGroupMember';
import { BubbleLoader } from 'react-css-loaders';
import SecUserSelector, { SecUserInput } from '@src/framework/forms/SecUserSelector';
import { __ } from '@src/translation';
import InstituteGroupMemberCrud from '@src/framework/crud/sys/InstituteGroupMemberCrud';
import { me } from '@src/framework/server/Auth';
import { alertDialog, confirmDialog, Dialog } from '@src/component/Dialog';
import { Popover } from '@src/component/ui/Popover';
import { InstituteGroupMemberStates } from '@src/component/institute/InstituteGroupMemberList';
import NewMessagePopUp from '../messengerModule/components/NewMessagePopUp';
import { History } from 'history';
import { classroomModule } from './classroomModule';

export const INSTITUTE_SITE_STATUS_INVITATION_SENT = 1;
export const INSTITUTE_SITE_STATUS_ACTIVE = 2;
export const INSTITUTE_SITE_STATUS_INVITATION_DENIED = 6;

type ClassroomUsersPageProps = {
    match: match<{ instituteGroupId: string }>;
    history: History;
}

type ClassroomUsersPageState = {
    group?: IInstituteGroupRecord;
    members: IViewInstituteGroupMemberRecord[];
    invitedUserId?: number;
    showMemberMenu?: number;

    messageDialogOpen: boolean;
    selectedMemberIds: number[];

    addUserDialogOpen: boolean;
    inviteAsAdmin: boolean;

    showInactive: boolean;
}

export class ClassroomUsersPage extends React.Component<ClassroomUsersPageProps, ClassroomUsersPageState> {

    private menuIcons: {} = {};

    constructor(props: ClassroomUsersPageProps) {
        super(props);

        this.state = {
            members: [],
            messageDialogOpen: false,
            selectedMemberIds: [],

            addUserDialogOpen: false,
            inviteAsAdmin: false,

            showInactive: false,
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        try {
            const instituteGroupId = Number(this.props.match.params.instituteGroupId);

            const group = (await InstituteGroupCrud.load(instituteGroupId)).record;
            const members = await ViewInstituteGroupMember.list({
                filter: {
                    institute_group_id: instituteGroupId,
                    is_active: true,
                },
                order_by: [{ name: "status_id" }, { name: "member_name" }]
            });

            this.setState({
                group,
                members
            })

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onInviteClick() {
        if (!this.state.invitedUserId) {
            alertDialog(__("Hiba"), this.state.inviteAsAdmin ? __("Kérem, adja meg a tanárt!") : __("Kérem, adja meg a diákot!"));
            return;
        }

        try {
            const instituteGroupId = Number(this.props.match.params.instituteGroupId);
            console.log(this.state.members)
            const exists = this.state.members.find(m => m.sec_user_id === this.state.invitedUserId);

            if (exists && this.state.invitedUserId == me!.id) {
                app.showError(__("Hiba"), __("Önmagát nem hívhatja meg!"));
            } else {
                if (exists && exists.status_id && [InstituteGroupMemberStates.LEFT_ID, InstituteGroupMemberStates.TILTED_ID, InstituteGroupMemberStates.INVITATION_REVOKED_ID, InstituteGroupMemberStates.INVITATION_REFUSED_ID].includes(exists.status_id)) {
                    await new InstituteGroupMemberCrud({
                        id: exists!.id,
                        status_id: INSTITUTE_SITE_STATUS_INVITATION_SENT
                    }).put();
                }
                else if (exists && exists!.status_id == INSTITUTE_SITE_STATUS_ACTIVE) {
                    app.showError(__("Hiba"), __("Ez a felhasználó már tagja a csoportnak!"));
                    return;
                } else {
                    await new InstituteGroupMemberCrud({
                        institute_group_id: instituteGroupId,
                        sec_user_id: this.state.invitedUserId,
                        status_id: INSTITUTE_SITE_STATUS_INVITATION_SENT,
                        is_admin: this.state.inviteAsAdmin
                    }).put();
                }

                app.showSuccess(
                    __("Sikeres meghívás"),
                    __("A meghívó kiküldése sikeres volt.")
                );
                this.setState({ invitedUserId: undefined }, this.reloadAsync);
            }

        } catch (e) {
            console.log(e);
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (!this.state.group) {
            return <BubbleLoader />;
        }

        const userIsGroupAdmin = this.state.members.some(m => me && m.sec_user_id === me.id && m.is_admin);

        const hasInactive = this.state.members.some(m => [InstituteGroupMemberStates.INVITATION_SENT_ID, InstituteGroupMemberStates.ACTIVE_ID].includes(m.status_id!));

        return <div className="row">

            <div className="column small-12 medium-6">
                <button className="button small"
                    disabled={this.state.selectedMemberIds.length === 0}
                    onClick={() => this.setState({ messageDialogOpen: true })}>
                    <i className="fa fa-envelope" /> Üzenet küldése a kiválasztott felhasználóknak
                </button>
            </div>

            <div className="column small-6 text-right">
                {
                    userIsGroupAdmin && hasInactive &&
                    <label>
                        <input type="checkbox"
                            checked={this.state.showInactive}
                            onChange={e => this.setState({ showInactive: e.target.checked })}
                        /> Inaktív tagok megjelenítése
                    </label>
                }
            </div>

            {this.renderMembers(__("Tanár"), true, userIsGroupAdmin)}

            <div className="column small-12" style={{ height: "1em" }}></div>

            {this.renderMembers(__("Diákok"), false, userIsGroupAdmin)}

            {
                this.state.addUserDialogOpen &&
                <Dialog title={(this.state.inviteAsAdmin ? "Tanár" : "Diák") + " meghívása (e-mail címmel vagy bejelentkezési névvel)"} onClose={() => this.setState({ addUserDialogOpen: false, invitedUserId: undefined })} width={600} height={350} maxWidth={700} maxHeight={400}>
                    <div className="row">
                        {
                            classroomModule.multipleTeachersEnabled &&
                            <>
                                <div className="column shrink">
                                    <label>
                                        <input type="radio" checked={!this.state.inviteAsAdmin} onChange={e => this.setState({ inviteAsAdmin: false })} />
                                        {__("Meghívás diáknak")}
                                    </label>
                                </div>
                                <div className="column shrink">
                                    <label>
                                        <input type="radio" checked={this.state.inviteAsAdmin} onChange={e => this.setState({ inviteAsAdmin: true })} />
                                        {__("Meghívás tanárnak")}
                                    </label>
                                </div>
                            </>
                        }

                        <div className="column small-12">
                            {__("E-mail cím vagy név")}:
                            </div>
                        <div className="column medium-8 small-12">
                            <SecUserInput
                                value={this.state.invitedUserId || null}
                                clearable={false}
                                onChange={e => this.setState({ invitedUserId: Number(e) })}
                                medium={true}
                                resultContainer={"userinputResult"}
                            />
                        </div>
                        <div className="column medium-4 small-12 text-right">
                            <button type="button" className="button small"
                                onClick={this.onInviteClick.bind(this)}>
                                <i className="fa fa-plus" />
                                    &nbsp;
                                    {__("Meghívó kiküldése")}
                            </button>
                        </div>
                        <div className="column small-12" id="userinputResult"></div>
                    </div>
                </Dialog>
            }

            <NewMessagePopUp recipientIds={this.state.selectedMemberIds} isOpen={this.state.messageDialogOpen} tableInfoId={InstituteGroupCrud.TABLE_INFO_ID} recordId={Number(this.props.match.params.instituteGroupId)} onClose={() => this.setState({ messageDialogOpen: false })} />

        </div>
    }

    private async setMemberStatus(member: IViewInstituteGroupMemberRecord, newStatusId: number, title: string, confirmText: string, gotoListPage = false) {
        this.setState({ showMemberMenu: undefined });

        if (this.isMemberTheLastAdmin(member)) {
            alertDialog(__("Figyelmeztetés"), __("Az utolsó tanárt nem lehet törölni!"));
            return;
        }

        if (!await confirmDialog(title, confirmText)) return;

        try {
            await new InstituteGroupMemberCrud({
                id: member.id,
                status_id: newStatusId,
            }).put();

            if (gotoListPage) {
                this.props.history.push(classroomModule.PATH_CLASSROOM_LIST);
            } else {
                this.reloadAsync();
            }

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async setMemberAdmin(member: IViewInstituteGroupMemberRecord, isAdmin: boolean, title: string, confirmText: string) {
        this.setState({ showMemberMenu: undefined });

        if (this.isMemberTheLastAdmin(member)) {
            alertDialog(__("Figyelmeztetés"), __("Az utolsó tanárt nem lehet törölni!"));
            return;
        }

        if (!await confirmDialog(title, confirmText)) return;

        try {
            await new InstituteGroupMemberCrud({
                id: member.id,
                is_admin: isAdmin,
            }).put();

            this.reloadAsync();

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private isMemberTheLastAdmin(member: IViewInstituteGroupMemberRecord) {
        return member.is_admin && (this.state.members.filter(m => m.is_admin).length === 1);
    }

    private renderMembers(title: string, isAdmin: boolean, userIsGroupAdmin: boolean) {
        let members = this.state.members.filter(member => member.is_admin === isAdmin);

        if (!this.state.showInactive) {
            members = members.filter(m => [InstituteGroupMemberStates.INVITATION_SENT_ID, InstituteGroupMemberStates.ACTIVE_ID].includes(m.status_id!));
        }

        return <>
            <div className="column small-10" style={{ marginTop: "1em" }}>
                <h5>
                    {title}
                </h5>
            </div>
            <div className="column small-2 text-right" style={{ marginTop: "1em" }}>
                {userIsGroupAdmin && (classroomModule.multipleTeachersEnabled || !isAdmin) &&
                    <button className="button small" style={{ marginBottom: 0 }} onClick={() => this.setState({ addUserDialogOpen: true, inviteAsAdmin: isAdmin })}>
                        <i /*style={{cursor: "pointer"}}*/ className="fa fa-user-plus" />
                    </button>
                }
            </div>
            <div className="column small-12">
                <hr style={{ marginLeft: 0, maxWidth: "100%" }} />
            </div>

            {
                members.map(member => {
                    let actions: { text: string, onAction: () => void }[] = [];

                    if (me && member.sec_user_id === me.id && !member.is_admin && member.status_id === InstituteGroupMemberStates.ACTIVE_ID) {
                        actions.push({
                            text: __("Távozás a csoportból"),
                            onAction: () => this.setMemberStatus(member, InstituteGroupMemberStates.LEFT_ID, __("Megerősítés"), __("Biztos, hogy távozik a csoportból?"), true)
                        })
                    }
                    if (userIsGroupAdmin && me && member.sec_user_id !== me.id && member.status_id === InstituteGroupMemberStates.ACTIVE_ID) {
                        actions.push({
                            text: __("Törlés a csoportból"),
                            onAction: () => this.setMemberStatus(member, InstituteGroupMemberStates.TILTED_ID, __("Megerősítés"), __("Biztos, hogy törli a csoportból?"))
                        })
                    }
                    if (userIsGroupAdmin && me && member.sec_user_id !== me.id && member.status_id === InstituteGroupMemberStates.TILTED_ID) {
                        actions.push({
                            text: __("Visszaállítás a csoportba"),
                            onAction: () => this.setMemberStatus(member, InstituteGroupMemberStates.INVITATION_SENT_ID, __("Megerősítés"), __("Biztos, hogy visszaállítja a csoportba?"))
                        })
                    }
                    if (userIsGroupAdmin && me && member.sec_user_id !== me.id && member.status_id === InstituteGroupMemberStates.INVITATION_SENT_ID) {
                        actions.push({
                            text: __("Meghívó visszavonása"),
                            onAction: () => this.setMemberStatus(member, InstituteGroupMemberStates.INVITATION_REVOKED_ID, __("Megerősítés"), __("Biztos, hogy visszavonja a meghívót?"))
                        })
                    }
                    if (userIsGroupAdmin && me && member.sec_user_id !== me.id && member.status_id === InstituteGroupMemberStates.INVITATION_REVOKED_ID) {
                        actions.push({
                            text: __("Meghívó újraküldése"),
                            onAction: () => this.setMemberStatus(member, InstituteGroupMemberStates.INVITATION_SENT_ID, __("Megerősítés"), __("Biztos, hogy újraküldi a meghívót?"))
                        })
                    }

                    if (classroomModule.multipleTeachersEnabled && userIsGroupAdmin && me && member.sec_user_id !== me.id && member.status_id === InstituteGroupMemberStates.ACTIVE_ID && !member.is_admin) {
                        actions.push({
                            text: __("Beállítás tanárként"),
                            onAction: () => this.setMemberAdmin(member, true, __("Megerősítés"), __("Biztos, hogy beállítja tanárként?"))
                        })
                    }
                    if (classroomModule.multipleTeachersEnabled && userIsGroupAdmin && me && member.sec_user_id !== me.id && member.status_id === InstituteGroupMemberStates.ACTIVE_ID && member.is_admin) {
                        actions.push({
                            text: __("Beállítás diákként"),
                            onAction: () => this.setMemberAdmin(member, false, __("Megerősítés"), __("Biztos, hogy beállítja diákként?"))
                        })
                    }

                    return <React.Fragment key={member.id}>
                        <div className="column medium-offset-1 medium-7 small-12" style={{ marginTop: "0.5em", alignSelf: "center" }}>
                            <label>
                                <input type="checkbox"
                                    checked={this.state.selectedMemberIds.includes(member.sec_user_id!)}
                                    onChange={e => this.toggleChecked(member.sec_user_id!)}

                                /> {member.member_name} <small>{member.member_email}</small>
                            </label>
                        </div>
                        <div className="column medium-3 small-12 text-right" style={{ alignSelf: "center" }}>
                            {
                                actions.length > 0
                                    ?
                                    <span ref={ref => this.menuIcons[member.id!] = ref}
                                        onClick={(e) => { this.setState({ showMemberMenu: member.id }); e.stopPropagation(); }}
                                        style={{ cursor: "pointer", position: "relative" }} >
                                        {member.status_title}
                                        <i className="fa fa-fw fa-ellipsis-v" style={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }} />
                                    </span>
                                    :
                                    member.status_title
                            }
                            <Popover open={this.state.showMemberMenu === member.id} parentElement={() => this.menuIcons[member.id!] as HTMLElement} onClose={() => this.setState({ showMemberMenu: undefined })} >
                                <div className="content-item-icon__menu-item">
                                    {
                                        actions.map((action, index) => {
                                            return <div key={index} onClick={action.onAction}>{action.text}</div>;
                                        })
                                    }
                                </div>
                            </Popover>
                        </div>

                    </React.Fragment>
                })
            }
        </>;

    }

    private toggleChecked = (recordID: number): void => {
        let selectedMemberIds = this.state.selectedMemberIds;
        let idx = selectedMemberIds.findIndex((el) => { return el == recordID })
        if (idx > -1) {
            selectedMemberIds.splice(idx, 1);
        } else {
            selectedMemberIds.push(recordID);
        }
        this.setState({ selectedMemberIds });
    }

}
