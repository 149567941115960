import { callApiThrowOnError } from "@src/framework/api/Api";

export type GetConfigParams = any;

export type GetConfigResult = {
    authServer: {
        baseUrl: string,
        clientId: string|null,
        headers: any,
    } | null,
    mainServer: {
        apiBaseUrl: string,
        headerBorderOverrideColor: string | null,
        headerNameOverride: string | null,
        headerNameOverrideColor: string | null,
        headerUpdatedOverride: string | null,
        headers: any,
        logoutUrl: string,
        mainPageShowEditorLink: boolean,
        showBooks: boolean,
        protectFrontend: boolean,
        siteId: number|null;

        trackerUrl: string | null,
        trackerSiteId: number | null,

        notifierUrls: string[] | null
    },
    searchServer: {
        apiBaseUrl: string,
        headers: any,
    } | null,
};

export async function getConfig(parameters: GetConfigParams): Promise<GetConfigResult> {
    return callApiThrowOnError<GetConfigParams, GetConfigResult>("/config/getConfig", parameters);
}
