import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ILangRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * ISO 639-1 kód
     */
    iso_code?: string;
    /**
     * Sorrend
     */
    ord?: number /*integer*/;
}

/**
 *  sys.lang - Nyelv
 *
 *  Az alkalmazásban használható nyelvek vannak itt, ISO kóddal.
 */

export default class LangCrud extends Crud<ILangRecord> {
    public static TABLE_INFO_ID = 3708250518;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'lang'; }

    public static load: (id: number, aServer ?: Server) => Promise<LangCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ILangRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ILangRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ILangRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ILangRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ILangRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class LangCrudClassProxy extends CrudClassProxy<ILangRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const langCrudClassProxy = new LangCrudClassProxy(LangCrud);
registerCrudClassProxy(langCrudClassProxy);
