import * as React from 'react';

import { ISecUserRecord, SecUserCrudClassProxy, secUserCrudClassProxy } from '@src/framework/crud/sys/SecUserCrud';
import RecordEditor, { IFieldEditorProp } from '@framework/forms/recordeditor';
import { SecUserGroupListViewForUser } from '@src/component/sys/sys_sec_user_group_listview';
import { IFieldInfo } from '@src/framework/crud/Meta';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { SecGroupLeaderListViewForLeader } from './sys_sec_group_leader_listview';
import { __ } from '@src/translation';

export default class SecUserEditor extends RecordEditor<ISecUserRecord> {

    public getCrudClassProxy(): SecUserCrudClassProxy { return secUserCrudClassProxy; }

    protected getFieldEditor(fProps: IFieldEditorProp): JSX.Element | null {
        if (fProps.fieldName == 'password_hash')
            return null;
        if (fProps.fieldName == 'totp')
            return null;
        if (fProps.fieldName == 'totp_confirmed')
            return null;
        if (fProps.fieldName == 'oo_folder_id')
            return null;
        if (fProps.fieldName == 'login_attempt') {
            fProps.disabled = true;
        }
        if (fProps.fieldName == 'disk_space_used') {
            fProps.disabled = true;
        }
        return super.getFieldEditor(fProps);
    }

    /**
     * Ezzel felül tudod bírálni a not null constraint-eket.
     * Ha pl. nem akarod beküldeni, mert tudod hogy egy trigger fog
     * neki értéket adni.
     * 
     */
    protected ignoreRequired(fieldInfo: IFieldInfo): boolean {
        if (fieldInfo.field_name == 'oo_folder_id') {
            return true;
        } else {
            return super.ignoreRequired(fieldInfo);
        }
    }


    protected getTopButtons(): JSX.Element[] {
        return [
            <div className="small-4 medium-4 column" key="recedit_list">
                <button className="button" onClick={this.onList}>
                    <i className="fa fa-step-backward" />&nbsp;Lista</button>
            </div>
        ];
    }    

    protected getDetailPanels(): any[] {
        let result = super.getDetailPanels();
        if (this.state.rec_id) {

            result = result.concat(
                <div className="row">
                    <div className="column small-12">
                        <Tabs>
                            <TabList>
                                <Tab>{__("Csoport tagság")}</Tab>
                                <Tab>{__("Melyik csoportok vezetője")}</Tab>
                            </TabList>
                            <TabPanel>
                                <SecUserGroupListViewForUser key="groups_editor" user_id={this.state.rec_id!} />
                            </TabPanel>
                            <TabPanel>
                                <SecGroupLeaderListViewForLeader key="groups_editor" leader_id={this.state.rec_id!} />
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            );
        }
        return result;
    }

}