import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewDocSectionFileRefRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Szekció
     */
    section_id?: number;
    /**
     * OnlyOffice file
     */
    oo_file_id?: number;
    /**
     * Alt tag értéke
     */
    alt?: string|null;
    /**
     * Title tag értéke
     */
    title?: string|null;
    /**
     * Szekció értékek
     *
     * Szekció értékek
     */
    section_values?: any|null;
    /**
     * Lecke azonosító
     *
     * Lecke azonosító
     */
    lesson_id?: number|null;
    /**
     * Lecke név
     *
     * Lecke név
     */
    lesson_name?: string|null;
    /**
     * Lecke URI szegmens
     *
     * Lecke URI szegmens
     */
    lesson_uri_segment?: string|null;
    /**
     * Fejezet azonosító
     *
     * Fejezet azonosító
     */
    chapter_id?: number|null;
    /**
     * Fejezet név
     *
     * Fejezet név
     */
    chapter_name?: string|null;
    /**
     * Könyv azonosító
     *
     * Könyv azonosító
     */
    book_id?: number|null;
    /**
     * Könyv név
     *
     * Könyv név
     */
    book_name?: string|null;
    /**
     * Könyv URI szegmens
     *
     * Könyv URI szegmens
     */
    book_uri_segment?: string|null;
    /**
     * File publikált neve
     *
     * Ezen a néven jelenik meg a file a médiatárban - ez NEM a file neve
     */
    file_displayname?: string|null;
    /**
     * File neve
     *
     * Ez a file neve az állományrendszerben. NEM a pulbikált neve.
     */
    file_title?: string|null;
    /**
     * File SHA1
     *
     * File SHA1
     */
    file_sha1?: string|null;
}

/**
 *  doc.view_doc_section_file_ref - Szekció file hivatkozás
 */

export default class ViewDocSectionFileRef extends View<IViewDocSectionFileRefRecord> {
    public static VIEW_INFO_ID = 2018052302;

    public static getSchemaNameForClass(): string { return 'doc'; }
    public static getViewNameForClass(): string { return 'view_doc_section_file_ref'; }
    public static getTableNameForClass(): string|null { return 'section_file_ref'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewDocSectionFileRef>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewDocSectionFileRefRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewDocSectionFileRefClassProxy extends ViewClassProxy<IViewDocSectionFileRefRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewDocSectionFileRefClassProxy = new ViewDocSectionFileRefClassProxy(ViewDocSectionFileRef);
registerViewClassProxy(viewDocSectionFileRefClassProxy);

