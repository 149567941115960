import * as React from 'react';
import ModalImage from 'react-modal-image';
import { app } from '@src/index';
import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import MediaTypeCrud, { IMediaTypeRecord } from '@src/framework/crud/media/MediaTypeCrud';
import { fetchKeywords, MetaKeywordAssignerPlugin, setKeywords } from '@src/component/meta/meta_keyword_assigner_plugin';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import { originCrudClassProxy } from '@src/framework/crud/media/OriginCrud';
import { suboriginCrudClassProxy } from '@src/framework/crud/media/SuboriginCrud';
import { __ } from '@src/translation';
import { hasGroup, Groups, me } from '@src/framework/server/Auth';
import { getKeywordsAi } from '@src/server/PublicServer';
import { confirmDialog, alertDialog } from '@src/component/Dialog';
import { timeoutAsync } from '@src/Util';
import { BubbleLoader } from 'react-css-loaders';

const SHUTTERSTOCK_ORIGIN_ID = 507111100;
const OFI_ORIGIN_ID = 507110900;
const NEW_GENERATION_BOOK_PICTURE = 507111200;
const OFI_SNI_ORIGIN_ID = 25085256601;

// SubOrigin ids
const RFTAMOP = 1;
const ISTOCK = 2;
const THINKSTOCK = 3;
const GETTYIMAGES = 4;
const SHUTTERSTOCK = 5;
const MTIAMOP = 6;
const MTI = 7;
const CULTIRISTAMOP = 8;
const CULTIRIS = 9;

const KEYWORD_AI_MIN_SCORE = 10;

interface FileBaseDataProps {
    file: IOoFileRecord;
    onCloseDialog?: () => void;
    onFileSaved: (file: IOoFileRecord, keywords: string[]) => void;
}

interface FileBaseDataState {
    displayname: string | null;
    origin: string | null;
    origin_id: number | null;
    sub_origin_id: number | null;
    origin_url: string | null;
    origin_author: string | null;
    origin_display: string | null;
    mediaTypeId: number | null;
    keywords: string[];
    showExtendedSourceList: boolean;
    isPublicSearchable: number;

    keywordsLoading: boolean;
}

let mediaTypes: IMediaTypeRecord[] | null = null;
export default class FileBaseData extends React.Component<FileBaseDataProps, FileBaseDataState> {
    constructor(props: FileBaseDataProps) {
        super(props);

        let pubSearchVal = -1;

        this.state = {
            keywords: [],
            displayname: props.file.displayname || null,
            // 
            isPublicSearchable: -1,
            origin: props.file.origin || null,
            origin_id: props.file.origin_id || null,
            sub_origin_id: props.file.suborigin_id || null,
            origin_url: props.file.origin_url || null,
            origin_author: props.file.origin_author || null,
            origin_display: props.file.origin_display || null,
            mediaTypeId: props.file.media_type_id || null,
            showExtendedSourceList: props.file.origin_id == NEW_GENERATION_BOOK_PICTURE ||OFI_SNI_ORIGIN_ID ? true : false,

            keywordsLoading: false,
        }
    }

    async componentDidMount() {
        this.asnycLoad();
    }

    async asnycLoad() {
        try {
            let isPublicSearchable;
            if (this.props.file.searchable==true) { isPublicSearchable = 0; }
            else if (this.props.file.searchable==false) { isPublicSearchable = 1; }
            else { isPublicSearchable = -1; }

            if (!mediaTypes) {
                mediaTypes = await MediaTypeCrud.list({ order_by: "title" });
                this.forceUpdate();
            }

            await this.loadKeywords();
            this.setState({ isPublicSearchable });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private loadKeywords = async () => {
        try {
            if (this.props.file.id) {
                const keywords = await fetchKeywords(OoFileCrud.TABLE_INFO_ID, this.props.file.id);
                this.setState({ keywords });
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private saveKeywords = async (): Promise<string[]> => {
        try {
            if (this.props.file.id) {
                await setKeywords(
                    OoFileCrud.TABLE_INFO_ID, this.props.file.id,
                    this.state.keywords || []
                );
                return Promise.resolve(this.state.keywords!);
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
        return Promise.resolve([]);
    }

    private onAppendKeywords = async (keywords: string[]) => {
        this.setState({ keywords: this.state.keywords!.concat(keywords) });
    }

    private onDeleteKeyword = async (index: number) => {
        const keywords = this.state.keywords!.concat([]);
        keywords.splice(index, 1);
        this.setState({ keywords });
    }

    private onSave = async (data: any) => {
        try {
            let IspubSearch: boolean | null;
            if (this.state.isPublicSearchable == -1) { IspubSearch = null; }
            else if (this.state.isPublicSearchable == 0) { IspubSearch = true }
            else { IspubSearch = false }

            let crud = new OoFileCrud({
                id: this.props.file!.id,
                displayname: this.state.displayname,
                origin: this.state.origin,
                origin_id: this.state.origin_id,
                suborigin_id: this.state.sub_origin_id,
                origin_url: this.state.origin_url,
                origin_author: this.state.origin_author,
                origin_display: this.state.origin_display,
                media_type_id: this.state.mediaTypeId,
                searchable: IspubSearch
            });
            const savedFile = await crud.put();
            const keywords = await this.saveKeywords();
            this.onFileSaved(savedFile.record, keywords);
            this.onCloseDialog();
            app.showSuccess(__("Változtatások mentése"), __("Sikeres"));
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private onFileSaved = (record: IOoFileRecord, keywords: string[]) => {
        if (this.props.onFileSaved)
            this.props.onFileSaved(record, keywords);
    }

    private onCloseDialog = () => {
        if (this.props.onCloseDialog)
            this.props.onCloseDialog();
    }

    private onDisplaynameChange(event: any) {
        this.setState({
            displayname: event.target.value || null
        });
    }
    private onOriginChange(event: any) {

        this.setState({
            origin: event.target.value || null

        });
    }
    private onOriginUrlChange(event: any) {
        let originurl: string = event.target.value;
        let tempDisplayName = "";
        if (!this.state.origin_display) {
            if (originurl && (originurl.indexOf("wikipedia.org") !== -1 || originurl.indexOf("wikimedia.org") !== -1)) {
                tempDisplayName = "Wikipedia";
            }
            else if (originurl && (originurl.indexOf("pixabay.com") !== -1)) {
                tempDisplayName = "Pixabay";
            }

            this.setState({
                origin_url: originurl || null,
                origin_display: tempDisplayName ? tempDisplayName : ""
            });
        }
        else {
            this.setState({
                origin_url: originurl || null

            });
        }
    }
    private onOriginDisplayChange(event: any) {
        this.setState({
            origin_display: event.target.value || null
        });
    }
    private onOriginAuthorChange(event: any) {
        this.setState({
            origin_author: event.target.value || null
        });
    }
    private onMediaTypeChange(event: any) {
        this.setState({
            mediaTypeId: event.target.value ? Number(event.target.value) : null
        });
    }
    private onOriginIdSelect = (sender: CrudSelectComponent, newOriginId: number | null): void => {
        let temporiginDisplay = "";
        if (!this.state.origin_display && newOriginId && (newOriginId == SHUTTERSTOCK_ORIGIN_ID || newOriginId == OFI_ORIGIN_ID)) {

            if (newOriginId && newOriginId == OFI_ORIGIN_ID) {
                let authorString = this.state.origin_author ? " / " + this.state.origin_author : "";
                temporiginDisplay = "OH (és jogelődei)" + authorString;
            }
            if (newOriginId && newOriginId == SHUTTERSTOCK_ORIGIN_ID) {
                temporiginDisplay = "Shutterstock";
            }

        }
        if (newOriginId && newOriginId == NEW_GENERATION_BOOK_PICTURE) {
            this.setState({ showExtendedSourceList: true })
        }
        else { this.setState({ sub_origin_id: null }) }

        this.setState({
            origin_id: (newOriginId && newOriginId) || null,
            origin_display: !this.state.origin_display ? temporiginDisplay : this.state.origin_display,
            showExtendedSourceList: (newOriginId && (newOriginId == NEW_GENERATION_BOOK_PICTURE || newOriginId == OFI_SNI_ORIGIN_ID)) ? true : false
        });
    }
    private isPublicSearchableChange(event: any) {
        let tempValue = event.target.value;
        this.setState({ isPublicSearchable: tempValue });
    }

    private onSuboriginIdSelect = (sender: CrudSelectComponent, newSubOriginId: any): void => {
        let temporiginDisplay = "";
       
        if (!this.state.origin_display && newSubOriginId &&
            (newSubOriginId == RFTAMOP
                || newSubOriginId == ISTOCK
                || newSubOriginId == THINKSTOCK
                || newSubOriginId == GETTYIMAGES
                || newSubOriginId == SHUTTERSTOCK
                || newSubOriginId == MTIAMOP
                || newSubOriginId == MTI
                || newSubOriginId == CULTIRISTAMOP
                || newSubOriginId == CULTIRIS)) {
            if (newSubOriginId == RFTAMOP) { temporiginDisplay = "123RF.com" }
            if (newSubOriginId == ISTOCK) { temporiginDisplay = "iStock" }
            if (newSubOriginId == GETTYIMAGES) { temporiginDisplay = "Getty Images" }
            if (newSubOriginId == THINKSTOCK) { temporiginDisplay = "Thinkstock" }
            if (newSubOriginId == SHUTTERSTOCK) { temporiginDisplay = "Shutterstock" }
            if (newSubOriginId == MTIAMOP || newSubOriginId == MTI) { temporiginDisplay = __("MTI Fotó") }
            if (newSubOriginId == CULTIRISTAMOP || newSubOriginId == CULTIRIS) { temporiginDisplay = __("CULTIRIS Kulturális Képügynökség") }
            this.setState
        }
        this.setState({
            sub_origin_id: (newSubOriginId && parseInt(newSubOriginId)) || null,
            origin_display: !this.state.origin_display ? temporiginDisplay : this.state.origin_display
        })
    }

    private async getKeywordsAi() {
        this.setState({
            keywordsLoading: true
        });
        try {
            for(let i=0; i<30; i++) {
                const keywordsResult = await getKeywordsAi(this.props.file.sha1!);
                if (keywordsResult.keywords_ai && keywordsResult.sentences_ai && keywordsResult.sentences_ai.length > 0) {
                    this.setState({keywordsLoading: false});

                    let keywords: string[] = [];

                    keywordsResult.keywords_ai.filter(k => k.score > KEYWORD_AI_MIN_SCORE).forEach(k => keywords = keywords.concat(k.original.split(",")));

                    if (await confirmDialog("Keywords and description found", <div><b>Keywords</b>: {keywords.join(", ")}<br/><b>Description</b>: {keywordsResult.sentences_ai[0].original}</div>, "Use keywords and description", "Cancel")) {
                        this.setState({
                            keywords: this.state.keywords.concat(keywords),
                            displayname: keywordsResult.sentences_ai[0].original
                        });
                    }
                    return;
                }

                await timeoutAsync(1000);
            }

            alertDialog("Keywords not found", "Keywords not found");

        } catch(e) {
            app.showErrorFromJsonResult(e);
            
        }

        this.setState({
            keywordsLoading: false
        });

    }

    render() {
        if (!mediaTypes) return null;
        const file = this.props.file!;
        let kind: string = __('Állomány');
        if (file.ext && [".png", ".jpg", ".jpeg", ".tiff", ".psd", ".xcf"].includes(file.ext)) {
            kind = __('Kép');
        }
        let preview = null;
        if (file.has_thumb) {
            preview =
                <ModalImage
                    small={`/api/media/thumb/200/${file.sha1}`}
                    large={`/api/media/file/${file.sha1}`}
                    alt={file.title}
                />
                ;
        }

        return <div>
            <br />
            <div className="row expanded">
                <div className="shrink columns" >
                    {preview}
                    <h6 className="fileManager-bolderTitle">{__("tulajdonságai")}:</h6>
                    <p className="fileManger-infotitle"><strong>{__("Méret")}: </strong>{file.size} {__("Byte")}</p>
                    {(file.width_px) ? <p className="fileManger-infotitle"><strong>{__("Felbontás")}: </strong>{file.width_px} x {file.height_px} px</p> : ""}

                    {
                        me && hasGroup(me, Groups.Developer) && 
                        <button disabled={this.state.keywordsLoading} className="button" onClick={this.getKeywordsAi.bind(this)}>
                            {this.state.keywordsLoading ? "Keywords loading..." : "Load keywords"}
                        </button>
                    }
                    {this.state.keywordsLoading && <BubbleLoader />}

                </div>

                <div className="columns">
                    <label>
                        {__("Elérési Útvonal")}:
                        <input type="text" readOnly value={file.relpath} />
                    </label>

                    <label>
                        {__("Publikált név")}
                        <input type="text" value={this.state.displayname || ""} onChange={this.onDisplaynameChange.bind(this)} />
                    </label>

                    <label>
                        {__("Fájl típusa")}
                        <select value={this.state.mediaTypeId || ""} onChange={this.onMediaTypeChange.bind(this)}>
                            <option value="">{__("Kérem válasszon")}</option>
                            {
                                mediaTypes.map(m => {
                                    return <option key={m.id} value={m.id}>{m.title}</option>
                                })
                            }
                        </select>
                    </label>

                    <label>
                        {__("Kulcsszavak")}
                        <MetaKeywordAssignerPlugin
                            keywords={this.state.keywords}
                            onAppendKeywords={this.onAppendKeywords}
                            onDeleteKeyword={this.onDeleteKeyword}
                        />
                    </label>

                    <label>
                        {__("Forrás")}
                        <CrudSelectComponent
                            value={this.state.origin_id}
                            onSelect={this.onOriginIdSelect}
                            displayFieldName="title"
                            orderByFieldName="title"
                            key="id"
                            crudClassProxy={originCrudClassProxy}
                            filter={{ is_active: true }}
                        />

                    </label>

                    {this.state.showExtendedSourceList
                        ? <label>

                            {this.state.origin_id == OFI_SNI_ORIGIN_ID ? __("Tankönyvi alforrás") : __("Újgenerációs alforrás")}
                            <CrudSelectComponent
                                value={this.state.sub_origin_id}
                                onSelect={this.onSuboriginIdSelect}
                                displayFieldName="title"
                                orderByFieldName="title"
                                key={"suborigin_" + this.state.origin_id}
                                crudClassProxy={suboriginCrudClassProxy}
                                filter={{ is_active: true, origin_id: this.state.origin_id }}
                            />

                        </label>
                        : null
                    }
                    <label>
                        {__("Származási hely/URL")}
                        <input
                            type="text"
                            list="origins"
                            value={this.state.origin_url || ""} onChange={this.onOriginUrlChange.bind(this)}
                        />
                    </label>
                    <label>
                        {__("Szerző")}
                        <input
                            type="text"
                            list="origins"
                            value={this.state.origin_author || ""} onChange={this.onOriginAuthorChange.bind(this)}
                        />
                    </label>

                    <label>
                        {__("Megjelenítendő forrás")}
                        <input
                            type="text"
                            list="origins"
                            value={this.state.origin_display || ""} onChange={this.onOriginDisplayChange.bind(this)}
                        />
                    </label>

                    <label>
                        {__("Származási hely megjegyzés")}
                        <input
                            type="text"
                            list="origins"
                            value={this.state.origin || ""} onChange={this.onOriginChange.bind(this)}
                        />
                    </label>
                    <label>
                        {__("Nyilvánosan kereshető-e")}
                        <select value={this.state.isPublicSearchable} style={{ margin: 0 }} onChange={this.isPublicSearchableChange.bind(this)} >
                            <option value={-1}>[ {__("Nincs beállítva")} ]</option>
                            <option value={0}>[ {__("Igen")} ]</option>
                            <option value={1}>[ {__("Nem")} ]</option>
                        </select>
                    </label>
                </div>
            </div>
            <div className="row expanded">
                <div className="large-6 small-12 columns">
                    <a onClick={() => this.onCloseDialog()} className="button alert filemamanger-buttonPadder"><i className="fa fa-times" /> &nbsp;{__("Mégse")}</a>
                </div>
                <div className="large-6 small-12 columns text-right">
                    <a onClick={this.onSave} className="button success filemamanger-buttonPadder"><i className="fa fa-save" /> &nbsp;{__("Mentés")}</a>
                </div>
            </div>
        </div>;
    }
}