import * as React from 'react';
import './MediaFileChooser.css'
import { app } from '@src/index';
import { IOoFileRecord } from '@framework/crud/media//OoFileCrud';
import OoFolderCrud, { IOoFolderRecord } from '@framework/crud/media//OoFolderCrud';

import ViewMediaOoFile, { IViewMediaOoFileRecord } from '@framework/view/media/ViewMediaOoFile';
import ViewMediaOoFolder, { IViewMediaOoFolderRecord } from '@framework/view/media/ViewMediaOoFolder';

import * as Server from '@src/server/PublicServer'
import { BubbleLoader } from 'react-css-loaders';
import Dropzone from 'react-dropzone';
import * as superagent from 'superagent';
import { ContentItem, ItemStyle, ContentListContainer, MenuItem } from '@src/component/filemanager/ContentItem';
import { FileTypeIcon } from '@src/component/filemanager/ContentUtils';
import LazyLoad from 'vanilla-lazyload';
import { getScrollParent } from '@src/Util';
import FolderTree from '@src/component/filemanager/FolderTree';
import { Dialog, confirmDialog, DialogContent, DialogActions } from '@src/component/Dialog';
import { FileDataTabs } from '@src/component/filemanager/data/FileDataTabs';
import { moveFolders, moveFiles, deleteFolders, deleteFiles, copyFolders, copyFiles, renameFile, renameFolder, makeFolders, ifFilesUsedShowErrorAndThrow, makeFolderTree, MakeFolderTreeResult, IMakeFolderTreeResultItem } from '@src/framework/file/FileActions';
import { IExerciseRecord } from '@src/framework/crud/exc/ExerciseCrud';
import * as queryString from 'query-string';
import { history } from '@src/index';
import { PATH_EXERCISE_VIEW, PATH_OO_EDITOR, getPath } from '@src/Routes';
import FolderPermsDialog from '@src/component/filemanager/perm/FolderPermsDialog';
import FilePermsDialog from '@src/component/filemanager/perm/FilePermsDialog';
import ButtonGroup, { ButtonSize, ButtonAlign } from '@src/component/ui/ButtonGroup';

import { me, hasGroup, Groups } from '@framework/server/Auth';
import { Link } from 'react-router-dom';
import UsrShareDialog from '../usr/UsrShareDialog';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { ISharedContentRecord } from '@src/framework/crud/usr/SharedContentCrud';
import { __ } from '@src/translation';
import { config } from '@src/framework/server/Server';
import { debounce } from 'lodash';
import { TFilterDict } from '@src/framework/crud/Crud';
import { Popover } from '@src/component/ui/Popover';
import { exerciseModule } from '@src/module/exerciseModule/exerciseModule';

const DEBOUNCE_WAIT_BEFORE_SEARCH = 1000;

enum FileAction {
    COPY,
    MOVE
}

export type FolderSelectedEvent = (folderId: number | null) => void;
export type MediaFileSelectedEvent = (fileId: number) => void;
export type FolderContentChangedEvent = (action: "upload" | "del") => void;

//TODO Laci-nak: ha lesz jogosultságrendszer, ezt ki lehet szedni
export const MAX_SYSTEM_FOLDER_ID = 98;

//var collaboarUrls: Server.CollaboraUrl | null | undefined;

export const OO_EDITABLE_FILE_EXTS = ["xlsx", "docx", "pptx"];

export enum ContentActionType {
    FILE_DOWNLOAD,
    FILE_UPLOAD,
    FILE_RENAME,
    FILE_DELETE,
    FILE_SHARE,
    FILE_MOVE,
    FILE_COPY,
    FILE_MODIFY,
    FILE_PERMS,
    FOLDER_CREATE,
    FOLDER_RENAME,
    FOLDER_DELETE,
    FOLDER_SHARE,
    FOLDER_PERMS,
}

export const FILE_ACTIONS = [
    ContentActionType.FILE_DOWNLOAD,
    ContentActionType.FILE_UPLOAD,
    ContentActionType.FILE_RENAME,
    ContentActionType.FILE_DELETE,
    ContentActionType.FILE_SHARE,
    ContentActionType.FILE_MOVE,
    ContentActionType.FILE_COPY,
    ContentActionType.FILE_MODIFY,
    ContentActionType.FILE_PERMS,
];

export const ALL_ACTIONS = [
    ContentActionType.FILE_DOWNLOAD,
    ContentActionType.FILE_UPLOAD,
    ContentActionType.FILE_RENAME,
    ContentActionType.FILE_DELETE,
    ContentActionType.FILE_SHARE,
    ContentActionType.FILE_MOVE,
    ContentActionType.FILE_COPY,
    ContentActionType.FILE_MODIFY,
    ContentActionType.FILE_PERMS,
    ContentActionType.FOLDER_CREATE,
    ContentActionType.FOLDER_RENAME,
    ContentActionType.FOLDER_DELETE,
    ContentActionType.FOLDER_SHARE,
    ContentActionType.FOLDER_PERMS,
];

export const ALL_LESSON_EDITOR_ACTIONS = [
    ContentActionType.FILE_DOWNLOAD,
    ContentActionType.FILE_UPLOAD,
    ContentActionType.FILE_RENAME,
    ContentActionType.FILE_DELETE,
    ContentActionType.FILE_SHARE,
    ContentActionType.FILE_MOVE,
    ContentActionType.FILE_COPY,
    ContentActionType.FILE_MODIFY,
    ContentActionType.FILE_PERMS,
    ContentActionType.FOLDER_CREATE,
    ContentActionType.FOLDER_RENAME,
    ContentActionType.FOLDER_DELETE,
    ContentActionType.FOLDER_SHARE,
    ContentActionType.FOLDER_PERMS,
];

interface IFolderPathItem {
    id?: number;
    title: string;
}

type MediaFileChooserProps = {
    /**
     * Az a gyökér mappa, ami fölé nem tud lépni.
     * A felső "hol vagyok" sáv (breadcrumbs) nem jeleníti meg azokat a szülő mappákat,
     * amik efölött vannak. Ezt a property értéket a következő esetekben használjuk:
     * 
     * - Könyv szerkesztő esetén ezzel biztosítjuk, hogy a könyvbe csak olyan tartalmat választ,
     *   ami a könyv saját mappájában van
     * - A "saját fájlok" menüpontban ezzel biztosítjuk, hogy abban a nézetben csak a saját
     *   személyes mappája alatti dolgok jelennek meg.
     * - A "normál" FileManager ide null-t ad át. Ezzel lehetővé válik a valódi gyökérmappákban
     *   való keresés.
     * 
     */
    rootFolderId: number | null;
    /**
     * 
     * Ez a az a mappa, amit aktuálisan megjelenít.
     * 
     */
    currentFolderId: number | null;

    /**
     * Amikor rákattint egy mappára.
     * Figyelem! Ha azt akarod hogy be tudjon lépni egy mappába, akkor ezt implementálnod KELL, és
     * egy módosított currentFolderId-t kell átadnod a props-ba.
     */
    onFolderSelected?: FolderSelectedEvent;
    /**
     * Amikor rákattint egy állományra.
     */
    onFileSelected?: MediaFileSelectedEvent;

    onFolderContentChanged?: FolderContentChangedEvent;

    /**
     * Ide megadhatsz egy tömböt, amiben felsorolod azokat a kiterjesztéseket, amiket a felhasználó
     * kiválaszthat. A nem kiválasztható állományok eltérően jelennek meg.
     */
    selectableExtensions?: string[];

    /**
     * Ha az egeret egy kép fölé húzod, akkor mutasson egy "lebegő" előképet.
     */
    showHoverPreview?: boolean;

    /**
     * Ha ez hamisra van állítva, akkor nem lehet látni a "hol vagyok" breadcrumbs részt.
     * @default: true
     */
    showLocation?: boolean;

    /**
     * Ha ez hamisra van állítva, akkor nem lehet látni a "Keresés mappában:" részt.
     * @default: true
     */
    showFilter?: boolean;

    /**
     * Ha hamis, akkor nem tölti le a mappákat
     * @default: true
     */
    showFolders?: boolean;

    defaultStyle?: ItemStyle;

    /**
     * Engedélyezett műveletek
     */
    enabledActions: ContentActionType[];
}

type FileToUpload = {
    folderId: number;
    file: File
}

type MediaFileChooserState = {
    folders?: IViewMediaOoFolderRecord[];
    files?: IViewMediaOoFileRecord[];
    exercises?: IExerciseRecord[];

    currentFolderPath: IFolderPathItem[];
    style: ItemStyle;

    loading: boolean;
    searchText: string;

    /**
     * Drag and drop-os file feltöltés állapota
     */
    totalUploadFileCount: number,    // Összesen hány file lett feltöltésre sorbaállítva azóta hogy nemüres lett a feltöltési sor
    uploadedFileCount: number; // Összesen hány file lett feltöltve azóta, hogy nemüres lett a feltöltési sor
    uploadingFiles: FileToUpload[];     // Feltöltési sor - azok az állományok, amik még nem lettek elküldve a szerver oldalra.
    uploadingFileName: string | null; // Az éppen feltöltés alatt levő egyetlen file neve
    uploadingFileProgressPercent: number | null; // Az éppen feltöltés alatt levő egyetlen állomány feltöltési % értéke
    processedFileCount: number; // Összesen hány file lett feltöltve azóta, hogy nemüres lett a feltöltési sor
    uploadSkipped: boolean;

    totalFoldersToMake: number | null;
    foldersMade: number | null;

    checkedFileIds: number[];
    checkedFolderIds: number[];

    /*Kiválasztott fájlokkal végzendő művelet (már csak a cél mappát kell kiválasztani) */
    fileAction?: FileAction;
    selectedOrder: string;
    newFolderDialogIsOpen?: boolean;
    newFolderName?: string;
}

export default class MediaFileChooser extends React.Component<MediaFileChooserProps, MediaFileChooserState> {

    private _debouncedReloadAsync: () => void;
    private lazyLoad: any;

    constructor(props: any) {
        super(props);
        this._debouncedReloadAsync = debounce(this.reloadAsync, DEBOUNCE_WAIT_BEFORE_SEARCH);

        this.state = {
            style: this.props.defaultStyle || ItemStyle.SMALL_ICON,
            searchText: '',
            currentFolderPath: [],

            uploadingFiles: [],
            uploadingFileName: null,
            uploadingFileProgressPercent: null,
            totalUploadFileCount: 0,
            uploadedFileCount: 0,

            processedFileCount: 0,

            uploadSkipped: false,

            totalFoldersToMake: null,
            foldersMade: null,

            loading: false,
            selectedOrder: "title",

            checkedFileIds: [],
            checkedFolderIds: [],
            newFolderDialogIsOpen: false,

        }
    }

    async componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: MediaFileChooserProps) {
        if (prevProps.currentFolderId !== this.props.currentFolderId) {
            this.reloadAsync();
        }

        if (this.lazyLoad) this.lazyLoad.update();

        if (!this.lazyLoad && this.refs.items) {
            const scrollParent = getScrollParent(this.refs.items as HTMLElement);

            if (scrollParent == document.body) {
                this.lazyLoad = new LazyLoad();
            } else {
                this.lazyLoad = new LazyLoad({
                    container: getScrollParent(this.refs.items as HTMLElement)
                });
            }
        }
    }

    async reloadAsync() {
        try {
            this.setState({
                loading: true,
                checkedFileIds: [],
                checkedFolderIds: [],
                fileAction: undefined
            });

            let currentFolderPath: IFolderPathItem[] = [];
            if (this.props.showLocation) {                
                if (this.props.currentFolderId) {
                    let info = (await OoFolderCrud.load(this.props.currentFolderId)).record;
                    currentFolderPath = info.relpath_items["items"];
                }
                currentFolderPath.unshift({ id: undefined, title: __('Gyökér mappák') });
            }

            let currentFolderId = this.props.currentFolderId || null;

            var folders: IViewMediaOoFolderRecord[] = [];

            var orderBy = { name: "", desc: false };
            if (this.state.selectedOrder.indexOf("desc") > -1) {
                orderBy.name = "creation_time";
                orderBy.desc = true;
            }
            else { orderBy.name = this.state.selectedOrder; orderBy.desc = false; }

            if (typeof this.props.showFolders === 'undefined' || this.props.showFolders) {
                let filter: TFilterDict = { is_active: true, parent_id: currentFolderId, };
                if (this.state.searchText && this.state.searchText.trim()) {
                    filter["$text"] = {
                        fieldNames: ["title"],
                        expression: this.state.searchText
                    }
                }
                folders = await ViewMediaOoFolder.list({ filter, order_by: [orderBy], });
            }

            if (!me || !hasGroup(me, Groups.Admin)) {
                folders = folders.filter(f => f.id && f.id > MAX_SYSTEM_FOLDER_ID);
            }

            let files: IViewMediaOoFileRecord[] = [];
            let exercises: IExerciseRecord[] = [];

            if (this.props.currentFolderId) {
                let filter: TFilterDict = { is_active: true, oo_folder_id: currentFolderId };
                if (this.state.searchText && this.state.searchText.trim()) {
                    filter["$text"] = {
                        fieldNames: ["title"],
                        expression: this.state.searchText
                    }
                }
                files = await ViewMediaOoFile.list({ filter, order_by: [orderBy], });
            }

            this.setState({
                currentFolderPath: currentFolderPath,
                folders: folders,
                files: files,
                exercises: exercises,
                loading: false
            });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    // Beír egy szöveget a filter-be, de még nem dolgozzuk föl
    onFilterChange(e: any) {
        this.setState({ searchText: e.target.value, loading: true }, this._debouncedReloadAsync);
    }

    private doFileSelected(e: IOoFileRecord) {
        if (this.props.onFileSelected) {
            this.props.onFileSelected(e!.id!);
        }
    }

    private doFolderSelected = (folder_id: number | null) => {
        if (this.props.onFolderSelected) {
            this.props.onFolderSelected(folder_id);
        }
    }

    async onCreateFolder() {
        const title = this.state.newFolderName;
        if (!title) {
            app.showError("Hiba", "Kérem, adjon nevet a mappának!");
            return;
        }

        try {
            this.setState({ newFolderDialogIsOpen: false });
            const exists = await OoFolderCrud.list({ filter: { is_active: true, parent_id: this.props.currentFolderId!, title: title } });
            if (exists.length > 0) throw __("A mappa már létezik");

            await makeFolders([title], this.props.currentFolderId!);

            this.reloadAsync();
            app.showSuccess(__("Mappa létrehozva"), title + " " + __("létrehozva"));
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async getFiles(fileSystemEntry: any) {
        let ret: any[] = [];

        if (fileSystemEntry.isDirectory) {
            const reader = fileSystemEntry.createReader();
            const entries: any = await new Promise((resolve, reject) => reader.readEntries(resolve, reject));
            for (const entry of entries) {
                ret = ret.concat(await this.getFiles(entry));
            }
        } else {
            const file = await new Promise((resolve, reject) => fileSystemEntry.file(resolve, reject));
            ret.push({ file, fileSystemEntry });
        }

        return ret;
    }

    /**
     * Amikor állományokat dobnak ide, akkor sorba állítjuk őket
     * és elindítjuk a sor feldolgozását, ha még nem volt elindítva.
     */
    private onDrop = async (acceptedFiles: File[], rejectedFiles: any[], event: any) => {

        // Default. ha a böngésző nem támogatja a mappák feltöltését
        let filesToUpload: FileToUpload[] = acceptedFiles.map(f => { return { file: f, folderId: this.props.currentFolderId! } });

        if (event && event.dataTransfer && event.dataTransfer.items) {
            const items = event.dataTransfer.items;

            try {
                let files: { file: File, fileSystemEntry: any }[] = [];

                const webkitEntries: any[] = [];
                for (const item of items) {
                    webkitEntries.push(item.webkitGetAsEntry()); // Az összes webkitGetAsEntry-t a legelső await előtt kell meghívni, különben Chrome-ban csak az első filet kapjuk meg!
                }

                for (const webkitEntry of webkitEntries) {
                    files = files.concat(await this.getFiles(webkitEntry));
                }

                filesToUpload = [];

                // Megszámoljuk hogy hány könyvtárat kellene létrehozni ha egy se lenne.
                let allFolderPaths: string[] = [];
                for (const f of files) {
                    const path = f.fileSystemEntry.fullPath.substring(1);
                    const pathItems = path.split("/");
                    pathItems.pop(); // leading slash
                    if (pathItems.length > 0) {
                        allFolderPaths.push(pathItems.join("/"));
                    }
                }
                let tree: MakeFolderTreeResult = await makeFolderTree(
                    allFolderPaths, this.props.currentFolderId,
                    (total: number, current: number, item: IMakeFolderTreeResultItem) => {
                        let newState = {
                            totalFoldersToMake: total,
                            foldersMade: current
                        };
                        if (item.wasCreated) {
                            if (item.record.parent_id == this.props.currentFolderId) {
                                let newFolders = this.state.folders!.concat();
                                newFolders.push(item.record);
                                newState['folders'] = newFolders;
                            }
                        }
                        this.setState(newState);
                    }
                );
                this.setState({ totalFoldersToMake: null, foldersMade: null });
                for (const f of files) {
                    const file = f.file;
                    const path = f.fileSystemEntry.fullPath.substring(1);
                    let pathItems = path.split("/");
                    pathItems.pop(); // leading slash
                    let folderId: number = this.props.currentFolderId!;
                    if (pathItems.length > 0) {
                        const container = pathItems.join("/");
                        folderId = tree[container].record.id!
                    }
                    filesToUpload.push({ file, folderId });
                }
            } catch (e) {
                console.log("Error", e);
                app.showErrorFromJsonResult(e);
                // A böngésző nem támogatja a mappák feltöltését
            }

        }

        if ($("#dropzone").hasClass("html-editor-embed-dropzone-drag-over"))
            $("#dropzone").removeClass("html-editor-embed-dropzone-drag-over");

        if (!this.props.currentFolderId) {
            alert(__('Csak egy mappán belülre lehet állományokat feltölteni, a gyökérbe nem.'));
        } else {
            // Akkor szükséges elindítani a feldolgozást, ha üres volt a sor.
            const needToStartProcessor = this.state.uploadingFiles.length == 0;
            this.setState(
                // Hozzáadjuk őket a sorhoz
                {
                    uploadingFiles: this.state.uploadingFiles.concat(filesToUpload),
                    totalUploadFileCount: this.state.totalUploadFileCount + filesToUpload.length
                },
                // Majd elindítjuk a sor feldolgozását, ha még nem volt elindítva.
                () => { if (needToStartProcessor) this.uploadNextFileFromQueue(); }
            );
        }
    }

    /**
     * Feltölti a következő állományt a sorból, majd újrahívja magát (ha még van valami a sorban).
     */
    private uploadNextFileFromQueue = () => {
        // Üres a sor?
        if (this.state.uploadingFiles.length) {
            // Nem üres, kivesszük a feldolgozni kívánt állományt a sorból
            const fileToUpload: FileToUpload = this.state.uploadingFiles[this.state.uploadingFiles.length - 1];

            this.setState({ uploadingFiles: this.state.uploadingFiles.slice(0, this.state.uploadingFiles.length - 1) });
            // És feltöltjük. A feltöltés végén újra...
            this.uploadFile(fileToUpload).then(this.uploadNextFileFromQueue);
        } else {
            this.setState({
                totalUploadFileCount: 0,
                uploadedFileCount: 0,
                uploadingFileName: null,
                uploadingFileProgressPercent: null,
                processedFileCount: 0
            });

            if (this.props.onFolderContentChanged) {
                this.props.onFolderContentChanged("upload");
            }
            /* Itt most már nem frissítünk, a files state-et on-the-fly
               bővítettük!

            // Csak az állomány listát töltjük újra a végén egyszer.
            // Elvileg más nem változhatott. Valójában ezt is ki lehetne
            // hagyni akkor, ha 
            ViewMediaOoFile.list({ filter: { is_active: true, oo_folder_id: this.props.currentFolderId }, 
                order_by: "title", })
                .then( (files) => {
                    this.setState({ totalUploadFileCount: 0, files } );
                })
                .catch( (error) => { app.showErrorFromJsonResult(error) } );
            */
        }
    }

    /**
     * Ez egy konkrét állomány feltöltését végzi el.
     * Ha sikerült, akkor átrakja a második sorba, ahol a szerver oldalon levő
     * feldolgozásra váró állományok vannak.
     */

    private uploadFile = async (fileToUpload: FileToUpload): Promise<boolean> => {
        const file = fileToUpload.file;

        this.setState({
            uploadingFileName: file.name,
            uploadingFileProgressPercent: 0
        });

        var ooFileList: IOoFileRecord[] = this.state.files!;
        var fileNameList: string[] = [];

        ooFileList.forEach((f) => {
            fileNameList.push(f.title!);
        });

        if (fileNameList.includes(file.name)) {
            if (!await confirmDialog(__("Figyelmeztetés!"), __(`Már létezik ilyen nevű állomány ({file_name}) ebben a mappában! Kívánja kicserélni?`, { file_name: file.name }), __("Kicserélem"))) {
                this.setState({ totalUploadFileCount: this.state.totalUploadFileCount - 1, uploadingFileProgressPercent: null });
                return Promise.resolve(false);
            }
        }

        const onProgress = (event: superagent.ProgressEvent) => {
            let newPercent: number | null;
            if (event.percent !== undefined) {
                newPercent = event.percent;
            } else if (this.state.uploadingFiles.length > 1) {
                newPercent = 100.0;
            } else {
                newPercent = null;
            }
            this.setState({ uploadingFileProgressPercent: newPercent });
        }

        try {

            // Ez tölti föl a bináris adatot a szerverre.
            let uploadedFiles = await Server.uploadFilesToOoFolder({
                oo_folder_id: fileToUpload.folderId,
                files: [file]
            }, onProgress);
            let newState = { uploadedFileCount: this.state.uploadedFileCount + 1 };
            let uploadedFile = uploadedFiles[0];
            // Csak akkor tesszük be a file listába ha abba a könyvtárba
            // töltötte föl, amelyikben vagyunk.
            if (uploadedFile.oo_folder_id == this.props.currentFolderId) {
                let newFiles = this.state.files!.concat();
                let found = false;
                for (let i = 0; i < newFiles.length; i++) {
                    if (newFiles[i].title == uploadedFile.title) {
                        newFiles[i] = uploadedFile;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    newFiles.push(uploadedFile);
                }
                newState['files'] = newFiles;

            }
            this.setState(newState);
            return Promise.resolve(true);
        } catch (error) {
            app.showErrorFromJsonResult(error);
            $('#progressbar')
            return Promise.resolve(false);
        }
    }


    private onDragOver = async function (event: any) {
        event.preventDefault();
        if (!$("#dropzone").hasClass("html-editor-embed-dropzone-drag-over"))
            $("#dropzone").addClass("html-editor-embed-dropzone-drag-over");
    }

    private onDragLeave = async function (event: any) {
        event.preventDefault();
        $("#dropzone").removeClass("html-editor-embed-dropzone-drag-over");
    }

    private getProgressBar(percentile: number | null, text: string | null): JSX.Element | null {
        if (percentile === null) {
            return null;
        } else {
            let swidth = "" + percentile + "%";
            return <div className="progress" role="progressbar"
                tabIndex={0}
                aria-valuenow={percentile || 0}
                aria-valuemin={0}
                aria-valuemax={100}>
                <div className="progress-meter" style={{ width: swidth }}>
                    <p className="progress-meter-text">{text}</p>
                </div>
            </div>;
        }
    }

    private uploadProgressArea(): JSX.Element {
        /* Könyvtár elő-létrehozás progress bar-ja */
        let makeFoldersProgressBar;
        if (this.state.totalFoldersToMake !== null && this.state.foldersMade !== null) {
            const percent = this.state.foldersMade * 100.0 / this.state.totalFoldersToMake;
            makeFoldersProgressBar = this.getProgressBar(
                percent,
                `${this.state.foldersMade} / ${this.state.totalFoldersToMake}`
            );

        } else {
            makeFoldersProgressBar = null;
        }

        let uploadCountProgressBar;
        if (this.state.totalUploadFileCount > 1 && (this.state.uploadedFileCount * 100.0 / this.state.totalUploadFileCount !== 100.0)) {
            const percent =
                this.state.uploadedFileCount * 100.0 / this.state.totalUploadFileCount;
            uploadCountProgressBar = this.getProgressBar(
                percent,
                `${this.state.uploadedFileCount} / ${this.state.totalUploadFileCount}`
            );

        } else {
            uploadCountProgressBar = null;
        }

        /* Aktuális feltöltés alatt álló állomány  */
        let uploadProgressBar;
        if (this.state.uploadingFileProgressPercent == null) {
            uploadProgressBar = null;
        } else {
            uploadProgressBar = this.getProgressBar(
                this.state.uploadingFileProgressPercent,
                this.state.uploadingFileName + " - " +
                this.state.uploadingFileProgressPercent.toFixed(1) + "%"
            );
        }

        return (
            <div>
                {makeFoldersProgressBar}
                {uploadCountProgressBar}
                {uploadProgressBar}
            </div>
        );
    }

    private doFolderChecked(folder: IOoFolderRecord) {
        const index = this.state.checkedFolderIds.indexOf(folder.id!);
        if (index !== -1) {
            this.state.checkedFolderIds.splice(index, 1);
        } else {
            this.state.checkedFolderIds.push(folder.id!);
        }
        this.setState(this.state);
    }

    private doFileChecked(file: IOoFileRecord) {
        const index = this.state.checkedFileIds.indexOf(file.id!);
        if (index !== -1) {
            this.state.checkedFileIds.splice(index, 1);
        } else {
            this.state.checkedFileIds.push(file.id!);
        }
        this.setState(this.state);
    }

    private hasItems = (): boolean => {
        return (
            this.state.files && this.state.folders &&
            (this.state.files.length > 0 || this.state.folders.length > 0)
        ) || false;
    }

    private isAllChecked = (): boolean => {
        if (this.hasItems()) {
            return this.state.files!.length == this.state.checkedFileIds.length && this.state.folders!.length == this.state.checkedFolderIds.length
        } else {
            return true;
        }
    }

    private unCheckAll = () => {
        this.setState({ checkedFileIds: [], checkedFolderIds: [] });
    }

    private checkAll = () => {
        if (this.state.files && this.state.folders) {
            this.setState({
                checkedFileIds: this.state.files.map((file) => file.id!),
                checkedFolderIds: this.state.folders.map((file) => file.id!),
            });
        } else {
            this.unCheckAll();
        }
    }

    private toggleCheckAll = () => {
        this.isAllChecked() ? this.unCheckAll() : this.checkAll();
    }

    private async onTargetFolderSelected(targetFolderId: number) {
        try {
            if (this.state.fileAction == FileAction.MOVE) {

                await moveFolders(this.state.checkedFolderIds, targetFolderId);
                await moveFiles(this.state.checkedFileIds, targetFolderId);

                app.showSuccess(__("Mozgatás"), this.state.checkedFileIds.length + " " + __("fájl,") + " " + this.state.checkedFolderIds.length + " " + __("mappa átmozgatva"));

                this.setState({
                    checkedFileIds: [],
                    checkedFolderIds: [],
                    fileAction: undefined
                }, this.reloadAsync.bind(this));

            } else if (this.state.fileAction == FileAction.COPY) {

                await copyFolders(this.state.checkedFolderIds, targetFolderId);
                await copyFiles(this.state.checkedFileIds, targetFolderId);

                app.showSuccess(__("Másolás"), this.state.checkedFileIds.length + " " + __("fájl,") + " " + this.state.checkedFolderIds.length + " " + __("mappa átmásolva"));

                this.setState({
                    checkedFileIds: [],
                    checkedFolderIds: [],
                    fileAction: undefined
                }, this.reloadAsync.bind(this));

            } else {

                app.showError(__("Hibás művelet"), "" + this.state.fileAction);
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
            this.setState({ fileAction: undefined });
        }
    }

    private async doDeleteAction() {
        try {
            if (!await confirmDialog(__("Törlés"), __("Biztosan törölni akarja a kiválasztott fájlokat?"))) {
                return;
            }

            await deleteFolders(this.state.checkedFolderIds);
            await deleteFiles(this.state.checkedFileIds);

            this.setState({
                checkedFileIds: [],
                checkedFolderIds: [],
                fileAction: undefined
            }, this.reloadAsync.bind(this));

            if (this.props.onFolderContentChanged) {
                this.props.onFolderContentChanged("del");
            }

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onFileVersionRestored = async (newFile: IOoFileRecord) => {
        this.reloadAsync();
    }
    private async onOrderBySelect(event: any) {
        this.setState({ selectedOrder: event.target.value }, this.reloadAsync);
    }


    render() {
        let breadcrumbs = null;
        let pathItems;

        /* beágyazott file választó */
        let embeddedFolderList = null;
        let exerciseList = null;
        let embeddedFileList = null;
        var show = false;

        if (this.props.showLocation === undefined || this.props.showLocation) {
            pathItems = this.state.currentFolderPath.map((item: IOoFolderRecord, index: number) => {
                let folderTitle = item.title;
                if (item.id == this.props.rootFolderId) {
                    folderTitle = __("Gyökér mappa"); // Ha a gyökér mappa neve generált szám, akkor az nem néz ki jól a felületen
                    show = true;
                }
                if (!show) return "";

                return <li key={item.id} className="image-select__breadcrumb-item">
                    <a onClick={() => { this.doFolderSelected(item.id || null) }} >
                        {folderTitle}
                    </a>
                </li>;
            });
            breadcrumbs = <ul className="breadcrumbs" style={{ margin: "1em" }}>{pathItems}</ul>;
        }

        const buttons = <ButtonGroup align={ButtonAlign.Left} size={ButtonSize.Small}>
            {

                this.props.enabledActions.includes(ContentActionType.FILE_UPLOAD)
                    ?

                    <button className="button upload-button" onClick={() => (this.refs.upload as HTMLInputElement).click()}>
                        <i className="fa fa-upload" /> &nbsp;{__("Új fájl feltöltése")}
                    </button>

                    :
                    null
            }
            {
                this.props.enabledActions.includes(ContentActionType.FOLDER_CREATE)
                    ?

                    <button className="button new-folder-button" onClick={() => this.setState({ newFolderDialogIsOpen: true })}>
                        <i className="fa fa-folder" /> &nbsp;{__("Új mappa létrehozása")}
                    </button>

                    :
                    null
            }

        </ButtonGroup>;

        let filterForEmbeddedFolder = null;
        if (this.props.showFilter === undefined || this.props.showFilter) {
            filterForEmbeddedFolder = <div className="file-name-filter-block">
                <div className="file-name-filter-container">
                    <div className="file-name-filter" >
                        <div className="input-group">
                            <input className="input-group-field" placeholder={__("Keresés fájlnévre")} type="text" id="folder-select-filter" value={this.state.searchText}
                                onChange={this.onFilterChange.bind(this)} />
                            <div className="input-group-button">
                                <button className="button small middle" onClick={this.reloadAsync.bind(this)} disabled={this.state.loading} title={__("Frissítés")}>
                                    <i className="fa fa-sync" /></button>
                            </div>
                        </div>
                    </div>
                    <div className="file-sort-container" >
                        <div style={{ display: "flex" }}>
                            <label style={{ marginRight: "0.5em", paddingTop: "0.5em" }}>{__("Rendezés")}:</label>
                            <select style={{ marginRight: "0.5em" }} name="orderBySelect" id="orderBySelect" value={this.state.selectedOrder} onChange={this.onOrderBySelect.bind(this)}>
                                <option selected value="title">{__("Név")}</option>
                                <option value="creation_time">{__("Létrehozás dátuma szerint legrégebbi")}</option>
                                <option value="creation_time desc">{__("Létrehozás dátuma szerint legújabb")}</option>
                            </select>
                            <button className={"button small " + (this.state.style == ItemStyle.SMALL_ICON ? "primary" : "primary hollow")} onClick={() => this.setState({ style: ItemStyle.SMALL_ICON })} title={__("Kis ikonok")}>
                                <i className="fa fa-th" />
                            </button>
                            <button className={"button small " + (this.state.style == ItemStyle.LARGE_ICON ? "primary" : "primary hollow")} onClick={() => this.setState({ style: ItemStyle.LARGE_ICON })} title={__("Nagy ikonok")}>
                                <i className="fa fa-th-large" />
                            </button>
                            <button className={"button small " + (this.state.style == ItemStyle.LIST ? "primary" : "primary hollow")} onClick={() => this.setState({ style: ItemStyle.LIST })} title={__("Részletes lista")}>
                                <i className="fa fa-list" />
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        }


        if (!this.state.loading) {

            if (this.state.folders) {
                embeddedFolderList = this.state.folders.map((folder) => {
                    return <FolderItem
                        folder={folder}
                        doFolderSelected={this.doFolderSelected.bind(this)}
                        key={folder.id}
                        style={this.state.style}
                        onReload={this.reloadAsync.bind(this)}
                        enabledActions={this.props.enabledActions}

                        checked={this.state.checkedFolderIds.includes(folder.id!)}
                        doFolderChecked={(this.doFolderChecked.bind(this))}
                    />;
                });
            }

            if (this.state.exercises) {
                exerciseList = this.state.exercises.map(e => {
                    const name = <span>{e.name || ""} <span className="label">{__("Okosfeladat")}</span></span>;

                    return <ContentItem
                        key={e.id}
                        id={e.id}
                        icon={""}
                        name={name}
                        selectable={true}
                        onItemClicked={() => history.push(getPath({PATH_EXERCISE_VIEW}, exerciseModule) + "/" + e.id)}
                        style={this.state.style}
                    />;
                });

            }

            if (this.state.files) {

                embeddedFileList = this.state.files.map((file) => {
                    return <FileItem
                        file={file}
                        doFileSelected={this.props.onFileSelected ? this.doFileSelected.bind(this) : undefined}
                        key={(file.sha1 || '') + "-" + file.id}
                        style={this.state.style}
                        selectableExtensions={this.props.selectableExtensions}
                        showHoverPreview={this.props.showHoverPreview}
                        onReload={this.reloadAsync.bind(this)}
                        enabledActions={this.props.enabledActions}

                        checked={this.state.checkedFileIds.includes(file.id!)}
                        doFileChecked={this.doFileChecked.bind(this)}

                        doFileCopy={(fileId) => this.setState({ checkedFileIds: [file.id!], checkedFolderIds: [], fileAction: FileAction.COPY })}
                        doFileMove={(fileId) => this.setState({ checkedFileIds: [file.id!], checkedFolderIds: [], fileAction: FileAction.MOVE })}
                        doFileDelete={(action) => {
                            if (this.props.onFolderContentChanged) this.props.onFolderContentChanged!(action);
                        }}
                        onFileVersionRestored={this.onFileVersionRestored}
                    />;
                })
            }
        }

        const isEmpty = ((embeddedFolderList === null && embeddedFileList === null) || (embeddedFolderList!.length === 0 && embeddedFileList!.length === 0));


        const checkedFileActionsEnabled = this.state.checkedFileIds.length > 0 || this.state.checkedFolderIds.length > 0;

        var checkedFileActions;

        if (this.state.style == ItemStyle.LIST) {

            const zipHref = "/api/media/zip?" + queryString.stringify({ fileIds: JSON.stringify(this.state.checkedFileIds), folderIds: JSON.stringify(this.state.checkedFolderIds) });

            checkedFileActions = <ButtonGroup align={ButtonAlign.Left} size={ButtonSize.Tiny}>

                <button className="button tiny" onClick={this.toggleCheckAll} title={__("Mindent kijelöl")}
                    disabled={!this.hasItems()}
                >
                    <i className={this.isAllChecked() ? "fa fa-square" : "fa fa-check-double"} />
                </button>

                {this.props.enabledActions.includes(ContentActionType.FILE_COPY) && <button className="button" disabled={!checkedFileActionsEnabled} onClick={() => this.setState({ fileAction: this.state.fileAction === FileAction.COPY ? undefined : FileAction.COPY })}>
                    <i className="fa fa-copy" /> &nbsp;{__("Másolás")}
                </button>}

                {this.props.enabledActions.includes(ContentActionType.FILE_MOVE) && <button className="button" disabled={!checkedFileActionsEnabled} onClick={() => this.setState({ fileAction: this.state.fileAction === FileAction.MOVE ? undefined : FileAction.MOVE })}>
                    <i className="fa fa-arrows-alt" /> &nbsp;{__("Mozgatás")}
                </button>}

                {this.props.enabledActions.includes(ContentActionType.FILE_DELETE) && <button className="button alert" disabled={!checkedFileActionsEnabled} onClick={this.doDeleteAction.bind(this)}>
                    <i className="fa fa-trash" /> &nbsp;{__("Törlés")}
                </button>}

                {this.props.enabledActions.includes(ContentActionType.FILE_DOWNLOAD) && <a target="_blank" download="letoltes.zip" href={checkedFileActionsEnabled ? zipHref : undefined} className={"button " + (checkedFileActionsEnabled ? "" : "disabled")}>
                    <i className="fa fa-download" /> &nbsp;{__("Letöltés")} (zip)
                    </a>}
            </ButtonGroup>

        }

        var folderSelector =
            <div ref="foldertree">
                <Popover
                    open={this.state.fileAction !== undefined}
                    parentElement={() => this.refs.foldertree as HTMLElement}
                    onClose={() => this.setState({ fileAction: undefined })}
                    childAlignHorizontal="left"
                    parentAlignHorizontal="left"
                    parentAlignVertical="bottom">
                    <div style={{ margin: "0.5em" }}>
                        {__("Válasszon cél mappát")}
                        <FolderTree
                            rootFolderId={this.props.rootFolderId}
                            onFolderSelected={this.onTargetFolderSelected.bind(this)}
                        />
                    </div>
                </Popover>
            </div>;

        var fileContainer = this.props.enabledActions.includes(ContentActionType.FILE_UPLOAD)
            ?
            <Dropzone id="dropzone" className={isEmpty ? "dropzone-empty" : "html-editor-embed-dropzone"} onDrop={this.onDrop.bind(this)} disableClick onDragOver={this.onDragOver} onDragLeave={this.onDragLeave}>
                {
                    isEmpty
                        ?
                        <p className="empty-dropzone-content">{__("Ez a mappa üres. Húzzon ide fájlokat a feltöltéshez!")}</p>
                        :
                        <div ref="items">
                            <ContentListContainer style={this.state.style}>
                                {embeddedFolderList}
                                {exerciseList}
                                {embeddedFileList}
                            </ContentListContainer>
                        </div>
                }
            </Dropzone>
            :

            isEmpty
                ?
                <p className="empty-dropzone-content">{__("Ez a mappa üres.")}</p>
                :
                <div ref="items">
                    <ContentListContainer style={this.state.style}>
                        {embeddedFolderList}
                        {exerciseList}
                        {embeddedFileList}
                    </ContentListContainer>
                </div>


        /*
        if (this.state.loading) {
            return <div style={{ width: "100%" }}>
                {filterForEmbeddedFolder}
                {breadcrumbs}
                <BubbleLoader />
            </div>;
        }*/

        return <div style={{ width: "100%", position: "relative" }}>
            {buttons}
            {filterForEmbeddedFolder}
            {this.uploadProgressArea()}
            {breadcrumbs}

            {this.state.loading ? <BubbleLoader /> :
                <>
                    {checkedFileActions}
                    {folderSelector}
                    {fileContainer}
                    <br />

                    <input ref="upload" type="file" style={{ display: "none" }} onChange={(event) => {
                        if (event.target.files) {
                            this.onDrop(Array.from(event.target.files), [], null);
                        }
                    }} />
                </>
            }
            <Dialog open={this.state.newFolderDialogIsOpen} title={__("Új mappa létrehozása")} onClose={() => this.setState({ newFolderDialogIsOpen: false })}>
                <DialogContent>
                    <label>{__("Új mappa neve")}:</label>
                    <input type="text" value={this.state.newFolderName} onChange={e => this.setState({ newFolderName: e.target.value })}></input>
                </DialogContent>
                <DialogActions>
                    <button className="button alert" onClick={() => this.setState({ newFolderDialogIsOpen: false })}>{__("Mégse")}</button>
                    <button className="button success" onClick={this.onCreateFolder.bind(this)}>{__("Mentés")}</button>
                </DialogActions>
            </Dialog>
        </div>

        /*end filter inside folder*/

    }
}


type FolderItemProps = {
    folder: IViewMediaOoFolderRecord,
    doFolderSelected: (folderId: number | null) => void,
    style: ItemStyle,
    onReload: () => void,
    enabledActions: ContentActionType[],
    checked: boolean,
    doFolderChecked: (folderId: number | null) => void,
}

interface IFolderItemState {
    showShareDialog: boolean;
    showPermsDialog: boolean;
    sharedContentId: number | null | undefined;
    showRenameDialog: boolean;
    newName?: string;
}
class FolderItem extends React.Component<FolderItemProps, IFolderItemState>{
    constructor(props: FolderItemProps) {
        super(props);
        this.state = { showShareDialog: false, showPermsDialog: false, sharedContentId: undefined, showRenameDialog: false, newName: this.props.folder.title }
    }

    render() {
        const folder = this.props.folder;
        const title = folder.title || "";

        const menuItems: MenuItem[] = [];

        if (this.props.enabledActions.includes(ContentActionType.FILE_DOWNLOAD)) {
            const paramString = queryString.stringify({ fileIds: [], folderIds: JSON.stringify([folder.id]) });
            menuItems.push({
                name: <a style={{ width: "100%", display: "block", color: "black" }} target="_blank" download="letoltes.zip" href={"/api/media/zip?" + paramString}><span><i className="fa fa-fw fa-download" />&nbsp;{__("Letöltés")}</span></a>,
                action: () => { }
            });
        }
        if (me && me.id == folder.owner_id && this.props.enabledActions.includes(ContentActionType.FOLDER_SHARE)) {
            menuItems.push({ name: <span><i className="fa fa-fw fa-share-alt" />&nbsp;{__("Megosztás")}</span>, action: () => this.setState({ showShareDialog: true }) });
        }
        if (this.props.enabledActions.includes(ContentActionType.FOLDER_RENAME)) {
            menuItems.push({ name: <span><i className="fa fa-fw fa-font" />&nbsp;{__("Átnevezés")}</span>, action: () => this.setState({ showRenameDialog: true }) });
        }
        if (this.props.enabledActions.includes(ContentActionType.FOLDER_DELETE)) {
            menuItems.push({ name: <span><i className="fa fa-fw fa-trash" />&nbsp;{__("Törlés")}</span>, action: this.onDeleteFolder.bind(this, folder) });
        }
        if (this.props.enabledActions.includes(ContentActionType.FOLDER_PERMS)) {
            menuItems.push({ name: <span><i className="fa fa-fw fa-lock" />&nbsp;{__("Engedélyek")}</span>, action: this.onFolderPerms.bind(this, folder) });
        }

        return <ContentItem
            id={folder.id}
            key={folder.id}
            onItemClicked={(id) => this.props.doFolderSelected(id)}
            name={title}
            created={folder.creation_time}
            creator={folder.creator!}
            modified={folder.modification_time}
            modifier={folder.modifier}
            icon={<img src={FileTypeIcon.FOLDER} style={{ height: "2.5em", width: "2.5em" }} />}
            style={this.props.style}
            menuItems={menuItems}
            selectable={true}
            checked={this.props.checked}
            onItemChecked={this.props.doFolderChecked.bind(this, folder)}
        >
            {this.state.showShareDialog ?
                <Dialog
                    title={__("Mappa megosztás")} onClose={() => this.setState({ showShareDialog: false, sharedContentId: undefined })} width={1000} height={700}
                >
                    <UsrShareDialog
                        sharedContentId={this.state.sharedContentId}
                        fileName={folder.title}
                        tableInfoId={OoFolderCrud.TABLE_INFO_ID}
                        recordId={folder.id!}
                        onCancel={() => this.setState({ showShareDialog: false })}
                        onCreated={(record: ISharedContentRecord) => {
                            this.setState({ showShareDialog: false, sharedContentId: undefined });
                            app.showSuccess(__(
                                "Sikeres művelet"), __(
                                    "Az állomány megosztása sikeresen befejeződött."));
                        }}
                        onSaved={(record: ISharedContentRecord) => {
                            this.setState({ showShareDialog: false, sharedContentId: undefined });
                            app.showSuccess(__(
                                "Sikeres művelet"), __(
                                    "Megosztás módosítva."));
                        }}
                        onSharedContentSelected={(sharedContentId: number | null) => {
                            this.setState({ sharedContentId });
                        }}
                        onSharedContentDeleted={(sharedContentId: number | null) => {
                            this.setState({ showShareDialog: false, sharedContentId: undefined });
                            app.showWarning(
                                __("Sikeres művelet"),
                                __("Megosztás törölve."));
                        }}
                    />
                </Dialog>
                : null
            }
            {
                this.state.showPermsDialog ?
                    <Dialog title={__("Mappa jogosultságok")} onClose={() => this.setState({ showPermsDialog: false })} width={1000} height={700}>
                        <FolderPermsDialog
                            ooFolderId={folder.id!}
                            onClose={() => { this.setState({ showPermsDialog: false }) }}
                        />
                    </Dialog>
                    :
                    null
            }
            {
                this.state.showRenameDialog ?
                    <Dialog title={__("Mappa átnevezése")} onClose={() => this.setState({ showRenameDialog: false })}>
                        <DialogContent>
                            <label>{__("Mappa neve")}:</label>
                            <input type="text" value={this.state.newName} onChange={e => this.setState({ newName: e.target.value })}></input>
                        </DialogContent>
                        <DialogActions>
                            <button className="button alert" onClick={() => this.setState({ showRenameDialog: false, newName: this.props.folder.title })}>{__("Mégse")}</button>
                            <button className="button success" onClick={this.onRenameFolder.bind(this)}>{__("Mentés")}</button>
                        </DialogActions>
                    </Dialog>
                    :
                    null
            }

        </ContentItem>;
    }

    private async onRenameFolder() {
        if (!this.state.newName || this.props.folder.title == this.state.newName) {
            return;
        }

        try {
            await renameFolder(this.props.folder.id!, this.state.newName);

            this.props.onReload();
            app.showSuccess(__("Átnevezés sikeres"), this.props.folder.title + " " + __("átnevezve"));
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onDeleteFolder(folder: IOoFolderRecord) {
        if (!await confirmDialog(__("Törlés"), __("Biztosan törölni akarja a mappát? {folder}", { folder: folder.title }))) {
            return;
        }

        try {
            await new OoFolderCrud(folder).del();
            this.props.onReload();
            app.showSuccess(__("Törlés sikeres"), folder.title + " " + __("törölve"));
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onShareFolder(folder: IOoFolderRecord) {
        alert('TODO');
    }


    private async onFolderPerms(folder: IOoFolderRecord) {
        this.setState({ showPermsDialog: true });
    }
}


type FileItemProps = {
    file: IViewMediaOoFileRecord,
    selectableExtensions?: string[],
    doFileSelected?: (file: IOoFileRecord) => void,
    style: ItemStyle,
    showHoverPreview?: boolean,
    onReload: () => void,
    enabledActions: ContentActionType[],
    checked: boolean,
    doFileChecked: (file: IOoFileRecord) => void,

    doFileCopy: (file: IOoFileRecord) => void,
    doFileMove: (file: IOoFileRecord) => void,
    doFileDelete: (action: "upload" | "del") => void,

    onFileVersionRestored?: (newFile: IOoFileRecord) => void,
}

var previewImg: HTMLElement;
previewImg = document.createElement("img");
previewImg.style.position = "fixed";
previewImg.style.zIndex = "1000000";
document.getElementsByTagName("body")[0].appendChild(previewImg);

interface IFileItemState {
    showDetailsDialog: boolean;
    showShareDialog: boolean;
    showPermsDialog: boolean;
    showRenameDialog: boolean;

    newName?: string;
    sharedContentId: number | null | undefined;
    sharedContentName: string | undefined;
}
class FileItem extends React.Component<FileItemProps, IFileItemState> {
    constructor(props: FileItemProps) {
        super(props);

        this.state = {
            showDetailsDialog: false,
            showShareDialog: false,
            showPermsDialog: false,
            showRenameDialog: false,
            newName: this.props.file.title,
            sharedContentId: undefined,
            sharedContentName: ""
        }
    }

    private onFileVersionRestored = (newFile: IOoFileRecord) => {
        if (this.props.onFileVersionRestored) {
            this.props.onFileVersionRestored(newFile);
        }
    }


    render() {
        const file = this.props.file;
        const greactIcon = this.props.style == ItemStyle.LARGE_ICON;

        const extension = file.title!.toLowerCase().split(".").pop() || "";

        var selectable = true;
        if (this.props.selectableExtensions && !this.props.selectableExtensions.includes(extension)) {
            selectable = false;
        }

        const thumbnail = getFileThumbnailComponent(file.sha1!, file.has_thumb || false, file.ext!, selectable, greactIcon, {
            onMouseEnter: this.onShowHoverPreview.bind(this, file.sha1),
            onMouseMove: this.onShowHoverPreview.bind(this, file.sha1),
            onMouseLeave: this.onHideHoverPreview.bind(this),
        });

        const menuItems: MenuItem[] = [];

        if (this.props.enabledActions.includes(ContentActionType.FILE_DOWNLOAD)) {
            menuItems.push({
                name: <a style={{ width: "100%", display: "block", color: "black" }} target="_blank" download={file.title} href={"/api/media/file/" + file.sha1}><span><i className="fa fa-fw fa-download" />&nbsp;{__("Letöltés")}</span></a>,
                action: () => {
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        var xhr = new XMLHttpRequest();
                        xhr.onloadstart = function () {
                            xhr.responseType = 'blob';
                        };
                        xhr.onload = function () {
                            navigator.msSaveOrOpenBlob(xhr.response, file.title);
                        };
                        xhr.open("GET", "/api/media/file/" + file.sha1, true);
                        xhr.send();

                    }

                }
            });
        }
        if (me && me.id == file.owner_id && this.props.enabledActions.includes(ContentActionType.FILE_SHARE)) {
            menuItems.push({ name: <span><i className="fa fa-fw fa-share-alt" />&nbsp;{__("Megosztás")}</span>, action: () => this.setState({ showShareDialog: true }) });
        }
        /*
        const collaboraExt = file.ext ? file.ext.replace(".", "").toLowerCase() : "";
        if (collaboarUrls && collaboarUrls.urls[collaboraExt] && this.props.enabledActions.includes(ContentActionType.FILE_MODIFY)) {
            menuItems.push({name: <Link style={{color: "black"}} to={PATH_CONTENT + "/" + file.id}><i className="fa fa-fw fa-edit" />&nbsp;Megnyitás</Link>, action: () => {}});
        }
        */

        const fileExt = file.ext ? file.ext.replace(".", "").toLowerCase() : "";
        /*
        if (OO_EDITABLE_FILE_EXTS.includes(fileExt)) {
            if (config.mainServer.ooDocBaseUrl && this.props.enabledActions.includes(ContentActionType.FILE_MODIFY)) {
                menuItems.push({ name: <Link style={{ color: "black" }} to={PATH_OO_EDITOR + "/" + file.id}><i className="fa fa-fw fa-edit" />&nbsp;{__("Megnyitás")}</Link>, action: () => { } });
            }
        }
        */

        if (this.props.enabledActions.includes(ContentActionType.FILE_MOVE)) {
            menuItems.push({ name: <span><i className="fa fa-fw fa-arrows-alt" />&nbsp;{__("Mozgatás")}</span>, action: this.props.doFileMove.bind(this, file) });
        }
        if (this.props.enabledActions.includes(ContentActionType.FILE_COPY)) {
            menuItems.push({ name: <span><i className="fa fa-fw fa-copy" />&nbsp;{__("Másolás")}</span>, action: this.props.doFileCopy.bind(this, file) });
        }
        if (this.props.enabledActions.includes(ContentActionType.FILE_RENAME)) {
            menuItems.push({ name: <span><i className="fa fa-fw fa-font" />&nbsp;{__("Átnevezés")}</span>, action: () => this.setState({ showRenameDialog: true }) });
        }
        if (this.props.enabledActions.includes(ContentActionType.FILE_DELETE)) {
            menuItems.push({ name: <span><i className="fa fa-fw fa-trash" />&nbsp;{__("Törlés")}</span>, action: this.onDeleteFile.bind(this, file) });
        }
        menuItems.push({ name: <span><i className="fa fa-fw fa-cog" />&nbsp;{__("Részletek")}</span>, action: () => this.setState({ showDetailsDialog: true }) });

        if (this.props.enabledActions.includes(ContentActionType.FILE_PERMS)) {
            menuItems.push({ name: <span><i className="fa fa-fw fa-lock" />&nbsp;{__("Engedélyek")}</span>, action: this.onFilePerms.bind(this, file) });
        }


        var label = null;
        if (file.media_type_title) {
            label = <span className="label">{file.media_type_title}</span>;
        }

        var name: JSX.Element | string = "";

        if (file.title) {
            name = <span>{file.title} {label}</span>;
        }
        if (file.displayname) {
            name = <span>{file.displayname} <small>({file.title})</small> {label}</span>;
        }

        return <ContentItem
            id={file}
            key={file.id}
            onItemClicked={this.props.doFileSelected ? this.props.doFileSelected.bind(this, file) : () => this.setState({ showDetailsDialog: true })}
            icon={thumbnail}
            name={name}
            created={file.creation_time}
            creator={file.creator!}
            modified={file.modification_time}
            modifier={file.modifier}
            size={file.size}
            style={this.props.style}
            selectable={selectable}
            menuItems={menuItems}
            checked={this.props.checked}
            onItemChecked={this.props.doFileChecked.bind(this, file)}
            mouseOverTitle={file.title}
        >
            {
                this.state.showDetailsDialog
                    ?
                    <Dialog title={__("Állomány részletei - {details}", { details: file.title })} onClose={() => this.setState({ showDetailsDialog: false })} width={1000} height={700}>
                        <FileDataTabs
                            file={file}
                            allowRestoreVersion={true}
                            allowModifications={this.props.enabledActions.includes(ContentActionType.FILE_MODIFY)}
                            onFileVersionRestored={this.onFileVersionRestored}
                            onClose={() => this.setState({ showDetailsDialog: false }, this.props.onReload)}
                        />
                    </Dialog>
                    :
                    null
            }
            {this.state.showShareDialog ?
                <Dialog
                    title={__("Állomány megosztás")} onClose={() => this.setState({ showShareDialog: false, sharedContentId: undefined })} width={1000} height={700}
                >
                    <UsrShareDialog
                        sharedContentId={this.state.sharedContentId}
                        fileName={file.displayname || file.title}
                        tableInfoId={OoFileCrud.TABLE_INFO_ID}
                        recordId={file.id!}
                        onCancel={() => this.setState({ showShareDialog: false })}
                        onCreated={(record: ISharedContentRecord) => {
                            this.setState({ showShareDialog: false, sharedContentId: undefined });
                            app.showSuccess(__(
                                "Sikeres művelet"), __(
                                    "Az állomány megosztása sikeresen befejeződött."));
                        }}
                        onSaved={(record: ISharedContentRecord) => {
                            this.setState({ showShareDialog: false, sharedContentId: undefined });
                            app.showSuccess(__(
                                "Sikeres művelet"), __(
                                    "Megosztás módosítva."));
                        }}
                        onSharedContentSelected={(sharedContentId: number | null) => {
                            this.setState({ sharedContentId });
                        }}
                        onSharedContentDeleted={(sharedContentId: number | null) => {
                            this.setState({ showShareDialog: false, sharedContentId: undefined });
                            app.showWarning(__(
                                "Sikeres művelet"), __(
                                    "Megosztás törölve."));
                        }}
                    />
                </Dialog>
                : null
            }
            {
                this.state.showPermsDialog ?
                    <Dialog title={__("Állomány jogosultságok")} onClose={() => this.setState({ showPermsDialog: false })} width={1000} height={700}>
                        <FilePermsDialog
                            ooFileId={file.id!}
                            onClose={() => { this.setState({ showPermsDialog: false }) }}
                        />
                    </Dialog>
                    :
                    null
            }
            {
                this.state.showRenameDialog ?
                    <Dialog title={__("Fájl átnevezése")} onClose={() => this.setState({ showRenameDialog: false })}>
                        <DialogContent>
                            <label>{__("Fájl neve")}:</label>
                            <input type="text" value={this.state.newName} onChange={e => this.setState({ newName: e.target.value })}></input>
                        </DialogContent>
                        <DialogActions>
                            <button className="button alert" onClick={() => this.setState({ showRenameDialog: false, newName: this.props.file.title })}>{__("Mégse")}</button>
                            <button className="button success" onClick={this.onRenameFile.bind(this)}>{__("Mentés")}</button>
                        </DialogActions>
                    </Dialog>
                    :
                    null
            }

        </ContentItem>
    }

    private async onDeleteFile(file: IOoFileRecord) {
        if (!await confirmDialog(__("Törlés"), __("Biztosan törölni akarja a fájlt? {file}", { file: file.title }))) {
            return;
        }

        try {
            await deleteFiles([file.id!]);
            this.props.onReload();
            app.showSuccess(__("Törlés sikeres"), file.title + " " + __("törölve"));
            if (this.props.doFileDelete) {
                this.props.doFileDelete("del");
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onFilePerms(file: IOoFileRecord) {
        this.setState({ showPermsDialog: true });
    }

    private async onRenameFile() {
        try {
            await ifFilesUsedShowErrorAndThrow([this.props.file.id!], __("Nem lehet átnevezni, mert már használatban van!"));

            if (!this.state.newName || this.props.file.title == this.state.newName) {
                return;
            }

            await renameFile(this.props.file.id!, this.state.newName);

            this.props.onReload();
            app.showSuccess(__("Átnevezés sikeres"), this.props.file.title + " " + __("átnevezve"));
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onShowHoverPreview(CurrentImgSha1: string, event: any) {
        if (this.props.showHoverPreview || this.props.showHoverPreview === undefined) {
            previewImg.style.display = "block";
            previewImg.setAttribute("src", "/api/media/thumb/500/" + CurrentImgSha1);
            previewImg.style.left = (event.clientX + 3) + "px";
            previewImg.style.top = (event.clientY + 3) + "px";
        }
    }

    private onHideHoverPreview() {
        if (this.props.showHoverPreview || this.props.showHoverPreview === undefined) {
            setTimeout(() => {
                previewImg.style.display = "none";
                previewImg.removeAttribute("src");
            }, 1);
        }
    }

    componentWillUnmount() {
        this.onHideHoverPreview();
    }
}

export function getFileThumbnailComponent(sha1: string, hasThumb: boolean, extension: string, selectable = true, large = false, extraImgAttributes: any = undefined, lazyLoad: boolean = true) {
    let src = "";

    if (large) {
        src = "/api/media/thumb/200/" + sha1;
    } else {
        src = "/api/media/thumb/100/" + sha1;
    }

    const thumbnailColor = selectable ? "greeb" : "lightgray";

    var thumbnail = <span style={{ color: thumbnailColor }}><i className="fa fa-file fa-2x" /></span>
    if (hasThumb) {

        thumbnail = <img draggable={false} className="image-selector__thumbnail" data-src={src} src={lazyLoad ? undefined : src} {...extraImgAttributes} />

    }
    else if (extension == '.pdf') {
        thumbnail = <span>{<img src={FileTypeIcon.FILE_PDF} style={{ height: "3em", width: "3em" }} />}</span>
    }
    else if (extension == '.zip') {
        thumbnail = <span>{<img src={FileTypeIcon.FILE_ZIP} style={{ height: "3em", width: "3em" }} />}</span>
    }
    else if (extension == '.txt') {
        thumbnail = <span>{<img src={FileTypeIcon.FILE_TEXT} style={{ height: "3em", width: "3em" }} />}</span>
    }
    else if (extension == '.mp4') {
        thumbnail = <span>{<img src="/img/IKON_SET/FILE/mp4.svg" style={{ height: "3em", width: "3em" }} />}</span>
    }
    else if (extension == '.mp3') {
        thumbnail = <span>{<img src={FileTypeIcon.FILE_SOUND} style={{ height: "3em", width: "3em" }} />}</span>
    }
    else if (extension == '.ppt' || extension == '.pptx') {
        thumbnail = <span>{<img src={FileTypeIcon.FILE_PPT} style={{ height: "3em", width: "3em" }} />}</span>
    }
    else if (extension == '.docx' || extension == '.doc') {
        thumbnail = <span>{<img src={FileTypeIcon.FILE_WORD} style={{ height: "3em", width: "3em" }} />}</span>
    }
    else if (extension == '.xls' || extension == '.xlsx') {
        thumbnail = <span>{<img src={FileTypeIcon.FILE_EXCEL} style={{ height: "3em", width: "3em" }} />}</span>
    }
    else if (extension == 'html' || extension == 'htm' || extension == 'xhtmlx') {

        thumbnail = <span>{<img src={FileTypeIcon.FILE_CODE} style={{ height: "3em", width: "3em" }} />}</span>
    }

    return thumbnail;
}
