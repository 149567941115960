import * as React from 'react';
import { loadVimeoPlayerApiAsync } from "@src/Util";
import { __ } from '@src/translation';

declare var Vimeo: any;

type playerState = {
    title: string,
    isPlaying: boolean,
    tracks: {},
    currTime : any,
    durTime: any,
    intervalId: any,
}

type playerProps = {
    src: string, 
}

export class VimeoControlPanel extends React.Component<playerProps,playerState> {
    static defaultProps = {
        src: "https://player.vimeo.com/video/76979871"
    }

    defaultTime = "00:00";

    constructor(props: any) {
        super(props);

        this.state = {
            title: "",
            isPlaying: false,
            tracks: {},
            currTime: this.defaultTime,
            durTime: this.defaultTime,
            intervalId: null,
        }
    }

    player : any;

    async LoadApi(){
        await loadVimeoPlayerApiAsync();
        this.player = new Vimeo.Player((this.refs.vimeoPlayer as HTMLIFrameElement));
        this.setState({tracks : this.player.getTextTracks()});
        this.LoadedData();
    }

    LoadedData() {
        const that=this;
        this.player.getDuration().then(function(duration:number) {
            that.setState({ durTime: that.ConvertTime(Math.round(duration)) });
        });
    }

    componentDidMount(){
        this.LoadApi();
    }

    Play(){
        if (this.state.isPlaying) {
            this.setState({ isPlaying: false});
            clearInterval(this.state.intervalId);
            this.player.pause();
        } else {
            var interval = setInterval(this.CurrentTime.bind(this), 1000);
            this.setState({ isPlaying: true, intervalId: interval });
            this.player.play();
        }
        this.CurrentTime();
    }

    CurrentTime(){
        const that=this;
        this.player.getCurrentTime().then(function(seconds:number) {
            that.setState({currTime: that.ConvertTime((Math.round(seconds)))});
          });
    }

    ConvertTime(secs: any) {
        let min = Math.floor(secs / 60);
        let sec = secs % 60;
        let mintext = min < 10 ? "0" + min : min;
        let sectext = sec < 10 ? "0" + sec : sec;
        return (mintext + ":" + sectext);
    }

    SetVolume(amount : number){
        const that=this;
        this.player.getVolume().then(function(volume:number) {
            if(volume + amount >= 0 && volume + amount <= 1){
            that.player.setVolume(volume+amount);
            }
            console.log(volume)
        });
    }
    
    render(){
        return <React.Fragment>
            <iframe ref="vimeoPlayer" src={this.props.src} allow="autoplay;" aria-hidden="true" width="640px" height="360px" style={{ border: "0px" }}></iframe>
            
            <div className="controller-panel show-for-sr">
                <div className="button-container">
                    <button className="play" onClick={this.Play.bind(this)}><i className={"fa "+ (!this.state.isPlaying ? "fa-play" : "fa-pause")}/>{!this.state.isPlaying ? <label className="show-for-sr">{ __("Lejátszás") }</label> : <label className="show-for-sr">{ __("Szüneteltetés") }</label>}</button>
                </div>
                <div>
                    <span className="current-time" title={ __("Videoanyag aktuális ideje") }>{this.state.currTime}</span><br/>
                    <span className="duration-time" title={ __("Videoanyag hossza") }>{this.state.durTime}</span>
                </div>
                <div>
                    <button onClick={() => this.SetVolume(0.1)}><i className="fas fa-volume-up"></i><label className="show-for-sr">{ __("Hangerő nővelése") }</label></button><br/>
                    <button onClick={() => this.SetVolume(-0.1)}><i className="fas fa-volume-down"></i><label className="show-for-sr">{ __("Hangerő csökkentése") }</label></button>
                </div>
            </div>
            </React.Fragment>
    }
}