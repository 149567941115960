import * as React from 'react';
import { Link, NavLink, Route } from 'react-router-dom';

import { me, hasGroup, Groups } from '@src/framework/server/Auth';
import { config, auth, authConfig, onAuthArrived } from '@framework/server/Server';
import { startLogin } from '@framework/util/MainPageTemplate';
import LocationUtil from '@framework/util/LocationUtil';
import {
    PATH_MAIN, PATH_HTR_CALENDAR, PATH_TICKETING_PAGE
}
    from '@src/Routes';
import { Z_INDEX_HEADER_MENU_DROPDOWN } from '@src/Const';
import HeaderNotification from '@src/component/HeaderNotification';
import FunctionsMenu from "@src/component/FunctionsMenu";
import { Menu, MenuItem, SubMenu } from "@src/component/ui/Menu";
import { getLanguage, __, setLanguage } from '@src/translation';
import { ToggleSelect, SelectOption } from '@src/component/ui/ToggleSelect';
import { SiteNameRibbon } from "@src/component/SiteNameRibbon";

export type MenuAction = {
    title: string;
    linkTo?: string;
    imgSrc?: string;
    className?: string;
    isActive?: boolean;
    subActions?: MenuAction[];
    hoverTitle?: string;
    no?: number;
}

type HeaderProps = {
    history?: any;
    moreContrast?: () => void;

    logoUrl?: string;
    logoHoverUrl?: string;
    logoLink?: string;
    topMenuActions: MenuAction[];
    functionMenuActions: MenuAction[];
    profileMenuActions: MenuAction[];
    menuLeft?: JSX.Element;
    menuRight?: JSX.Element;
    mobileMenu?: JSX.Element[];
    menuSwitch?: JSX.Element;
    miniLanguageSelect?: boolean;

    calendar?: boolean;
    ticketing?: boolean;
    notifications?: boolean;

    forceUpdate: () => void;
    logoutCallback?: () => void;

    className?: string;
}

type HeaderState = {
    loginInProgress: boolean;
    moreContrast: boolean;
    isOpen: boolean;
}

export default class Header extends React.Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
        super(props);

        this.state = {
            loginInProgress: false,
            moreContrast: false,
            isOpen: false
        };
    }

    componentDidMount() {

        if (this.refs.headerElement) {
            ($(this.refs.headerElement) as any).foundation();
        }
        const _this = this;
        $(function () {
            var startPosX = 0;
            var endPosX = 0;
            var moveLeft = true;

            document.addEventListener('touchstart', _this.touchStart, false);
            document.addEventListener('touchmove', _this.touchMove, false);
            document.addEventListener('touchend', _this.touchEnd, false);
            // document.addEventListener('touchstart', function (e) {
            //     var touch = e.touches[0];
            //     moveLeft = true;
            //     startPosX = touch.pageX;
            //     endPosX = touch.pageX;
            // }, false);

            // document.addEventListener('touchmove', function (e) {
            //     var touch = e.touches[0];
            //     endPosX = touch.pageX;
            //     if (startPosX <= touch.pageX) {
            //         moveLeft = false;
            //     }
            // }, false);

            // document.addEventListener('touchend', function (e) {
            //     if (moveLeft && (document.body.clientWidth * 0.33) < startPosX - endPosX) {
            //         $('.main-menu-bar').removeClass('open');
            //         $('.main-menu-wrapper').removeClass('open');
            //         $('body').css('overflow', '');
            //     }
            // }, false);
        });

        // document.addEventListener("mousemove", this.showMenuBar.bind(this));
    }

    // showMenuBar(e: MouseEvent) {
    //     if ((this.refs.mainMenuBar as HTMLElement)) {
    //         if (e.clientY < 5) {
    //             (this.refs.mainMenuBar as HTMLElement).classList.add("show");
    //         }
    //         else {
    //             (this.refs.mainMenuBar as HTMLElement).classList.remove("show");
    //         }
    //     }
    // }

    startPosX = 0;
    endPosX = 0;
    moveLeft = true;
    touchStart(e: any) {
        var touch = e.touches[0];
        this.moveLeft = true;
        this.startPosX = touch.pageX;
        this.endPosX = touch.pageX;
    };

    touchMove(e: any) {
        var touch = e.touches[0];
        this.endPosX = touch.pageX;
        if (this.startPosX <= touch.pageX) {
            this.moveLeft = false;
        }
    }
    touchEnd(e: any) {
        if (this.moveLeft && (document.body.clientWidth * 0.33) < this.startPosX - this.endPosX) {
            $('.main-menu-bar').removeClass('open');
            $('.main-menu-wrapper').removeClass('open');
            $('body').css('overflow', '');
        }
    }

    componentWillUnmount() {
        document.removeEventListener("touchstart", this.touchStart);
        document.removeEventListener("touchmove", this.touchMove);
        document.removeEventListener("touchend", this.touchEnd);
    }

    componentDidUpdate() {
    }

    private onLogin() {
        LocationUtil.save();
        startLogin(this.props.history)
            .then(async (authLocal) => {
                onAuthArrived(authLocal);
                this.props.history.push(window.location.pathname);
            })
            .catch(reason => {
                console.error(reason);
            });
    }

    private onLogout(callback?: () => void) {
        auth!.logout(authConfig)
            .then(() => {
                callback ? callback() : null;
                const logoutUrl = config.mainServer.logoutUrl;
                if (logoutUrl.toLocaleLowerCase().startsWith("http")) {
                    window.location.replace(logoutUrl);
                } else {
                    window.location.href = logoutUrl;
                }
            });
    }

    private menuClick(id: string) {
        ($("#" + id) as any).foundation('close');
    }

    moreContrast() {

        if (this.props.moreContrast) {
            this.props.moreContrast();
            (this.refs.moreContrast as HTMLElement).classList.toggle('active');
        }
        this.setState({ moreContrast: !this.state.moreContrast });

    }

    private async onLanguageChange(language: "hu" | "en" | "sk" | "ro" | "est") {
        await setLanguage(language);
        this.props.forceUpdate();
    }

    mobileMenuOpen(e: MouseEvent) {
        e.stopPropagation();
        let body = document.getElementsByTagName("body")[0];
        body.hasAttribute("style") ? body.removeAttribute("style") : body.setAttribute("style", "overflow: hidden");

        this.setState({ isOpen: !this.state.isOpen });
    }

    mobileMenuClose() {
        let body = document.getElementsByTagName("body")[0];
        body.removeAttribute("style");

        this.setState({ isOpen: false });
    }

    render() {
        let text: string;
        let title: string;
        let icon: any;

        let onClick: () => void = () => { };

        if (this.state.loginInProgress) {
            title = __("Bejelentkezés...");
            text = title;
            icon = <i className="fa fa-hourglass" />;
        } else if (me) {
            if (me.person && me.person.full_name) {
                text = me.person.full_name;
                title = me.person.full_name + " (" + me.login_name + ")";
            } else {
                text = me.login_name;
                title = me.login_name;
            }

            icon = <i className="fa fa-sign-out-alt" />;
            onClick = this.onLogout.bind(this, this.props.logoutCallback);

        } else {
            title = __("Bejelentkezés");
            text = title;
            icon = <i className="fa fa-sign-in-alt" />;
            onClick = this.onLogin.bind(this);
        }
        var siteName = config.mainServer.headerNameOverride || undefined;
        var siteUpdated = config.mainServer.headerUpdatedOverride || undefined;
        var siteNameColor = config.mainServer.headerNameOverrideColor || "red";
        var backgroundColor = config.mainServer.headerBorderOverrideColor || undefined;

        const languageSelect = <ToggleSelect className="lang-selector" selected={getLanguage()} onChange={this.onLanguageChange.bind(this)}>
            <SelectOption value="hu"><a className="lang hu">{__("Magyar")}</a></SelectOption>
            <SelectOption value="en"><a className="lang en">{__("Angol")}</a></SelectOption>
        </ToggleSelect>;

        const miniLanguageSelect = <ToggleSelect className="lang-selector mini" selected={getLanguage()} onChange={this.onLanguageChange.bind(this)}>
            <SelectOption value="hu"><a className="lang hu">HU</a></SelectOption>
            <SelectOption value="en"><a className="lang en">EN</a></SelectOption>
            <SelectOption value="sk"><a className="lang sk">SK</a></SelectOption>
            <SelectOption value="ro"><a className="lang ro">RO</a></SelectOption>
            <SelectOption value="est"><a className="lang est">EST</a></SelectOption>
        </ToggleSelect>;

        return <div ref="headerElement">
            <div className="show-for-small-only mobile-bar">
                <div className={"mobile-menu-icon " + (this.state.isOpen ? "open" : "")} onClick={this.mobileMenuOpen.bind(this)}><i className="fa fa-bars"></i></div>
                {this.props.logoUrl &&
                    <img src={this.props.logoUrl} />
                }
                {me && this.props.notifications != false && <HeaderNotification isMobile={true} />}
                {me && <div className="menu-item" data-toggle={"functionsMenuMobile"} >
                    <i className="fas fa-th"></i>
                </div>}
                <div className="functions-menu dropdown-pane" data-v-offset="6" data-h-offset="-15" id="functionsMenuMobile" data-position="bottom" data-alignment="right" data-dropdown data-auto-focus="true" >
                    <FunctionsMenu actions={sortMenuActions(this.props.functionMenuActions)} onClick={() => this.menuClick("functionsMenuMobile")} /></div>

            </div>
            <div className={(this.props.className ? this.props.className + "-wrapper " : "main-menu-wrapper ") + (this.state.isOpen ? "open" : "")} onClick={this.mobileMenuClose.bind(this)}>

                {
                    siteName &&
                    <SiteNameRibbon siteNameColor={siteNameColor} backgroundColor={backgroundColor} siteName={siteName} siteUpdated={siteUpdated}></SiteNameRibbon>
                }
                <div className={(this.props.className ? this.props.className + "-bar " : "main-menu-bar ") + (this.state.isOpen ? "open" : "")} id="mainMenuBar" ref="mainMenuBar">
                    <div className="top-bar eke-top-bar" >
                        <div className="top-bar-left">

                            {
                                this.props.menuLeft
                            }

                            <Menu>
                                <MenuItem className="eke-top-bar__menu-logo">

                                    <Link to={this.props.logoLink ? this.props.logoLink : PATH_MAIN} title={__("Főoldal")} style={{ display: "flex", alignItems: "center", lineHeight: 1.2, flex: "1", height: '100%' }}>


                                        {
                                            this.props.logoUrl &&
                                            <img
                                                alt="Logo"
                                                aria-hidden="true"
                                                src={this.props.logoUrl}
                                                onMouseOver={(e) => e.currentTarget.src = this.props.logoHoverUrl || this.props.logoUrl!}
                                                onMouseOut={(e) => e.currentTarget.src = this.props.logoUrl!} />
                                        }
                                    </Link>

                                </MenuItem>
                                {this.props.menuSwitch ? <MenuItem key="menuswitch">{this.props.menuSwitch}</MenuItem> : null}
                                {
                                    this.props.topMenuActions.map((action, index) => {
                                        if (action.subActions) {
                                            return <SubMenu isActive={action.isActive} key={index} className={action.className} linkTo={action.linkTo} title={action.hoverTitle ? action.hoverTitle : action.title} imgSrc={action.imgSrc} ariaHidden>
                                                {
                                                    action.subActions.map((subAction, subIndex) => {
                                                        return <a key={subIndex} href={subAction.linkTo || ""}>{subAction.title}</a>
                                                    })
                                                }
                                            </SubMenu>
                                        } else {
                                            return <MenuItem key={index}>
                                                <a key={"link-" + index} title={action.hoverTitle ? action.hoverTitle : action.title} className={"top-bar__link " + (action.className ? action.className : "") + (action.isActive ? " is-active" : "")} href={action.linkTo || ""}>
                                                    {action.imgSrc &&
                                                        <img aria-hidden src={action.imgSrc} className="eke-header-icons" />
                                                    }
                                                    <span className={"hide-for-medium-only"}> {action.title}</span>
                                                </a>
                                            </MenuItem>
                                        }
                                    })
                                }
                            </Menu>

                            {
                                this.props.menuRight
                            }
                        </div>
                        {/*
                        <div className="eke-user-menu top-bar-right">
                            {
                                this.props.profileMenuActions.length || me ?
                                    <>
                                        {
                                            this.props.calendar ? <Link className="hide-for-small-only menu-item calendar-menu-item" to={PATH_HTR_CALENDAR} title={__("Naptár")}><i className="fas fa-calendar-alt"></i></Link>
                                                : ""
                                        }
                                        {
                                            this.props.ticketing ? <Link className="hide-for-small-only menu-item ticketing-menu-item" to={PATH_TICKETING_PAGE} title={__("Üzenő")}><i className="fas fa-envelope"></i></Link>
                                                : ""
                                        }
                                        {
                                            this.props.moreContrast &&
                                            <button ref="moreContrast" title={__("Több kontraszt")} onClick={this.moreContrast.bind(this)} tabIndex={0} className="menu-item hide-for-small-only more-contrast">
                                                <span className="show-for-sr">{this.state.moreContrast ? __("Több kontraszt bekapcsolva") : __("Több kontraszt kikapcsolva")}</span>
                                                <i className="fas fa-adjust"></i>
                                            </button>
                                        }
                                        {
                                            this.props.notifications != false
                                                ?
                                                <HeaderNotification className="menu-item" isMobile={false} onClick={() => this.menuClick("otkNotifications")} />
                                                : null
                                        }
                                        <button title={__("Modulok")} tabIndex={0} className="menu-item hide-for-small-only" data-toggle={"functionsMenu"} >
                                            <i className="fas fa-th"></i>     <span className="show-for-sr">{__("Modulok")}</span>
                                        </button>
                                        <span tabIndex={0} className="functions-menu dropdown-pane hide-for-small-only" id="functionsMenu" data-position="bottom" data-alignment="right" data-dropdown data-auto-focus="true" data-close-on-click="true">
                                            <FunctionsMenu actions={sortMenuActions(this.props.functionMenuActions)} onClick={() => this.menuClick("functionsMenu")} />
                                        </span >

                                        <Menu className="profile-menu">
                                            <SubMenu title={text}>
                                                {
                                                    me && hasGroup(me, Groups.Developer) && !this.props.miniLanguageSelect &&
                                                    <MenuItem>
                                                        {languageSelect}
                                                    </MenuItem>
                                                }
                                                {
                                                    this.props.profileMenuActions.map((action, index) => {
                                                        return <MenuItem key={index}>
                                                            <Link to={action.linkTo || ""} >
                                                                <i className={action.className} />&nbsp; {action.title}
                                                            </Link>
                                                        </MenuItem>
                                                    })
                                                }

                                                <MenuItem key="logout"><a href="#" id="HEADER_LOGOUT" onClick={onClick}>{icon}&nbsp; {__("Kilépés")}</a></MenuItem>

                                            </SubMenu>
                                        </Menu>
                                    </>
                                    :
                                    <>
                                        <ul className="menu">
                                            <li className="menu-text" title={title}>
                                                <a id="HEADER_LOGIN" href="#" className="top-bar__link" onClick={onClick}>
                                                    {icon} &nbsp;<h1 style={{ fontSize: "0.9rem", fontWeight: "bold", margin: "0", padding: "0" }}>{text}</h1>
                                                </a></li>
                                        </ul>
                                        {/* <Menu>{miniLanguageSelect}</Menu> * /}
                                    </>
                            }
                            { this.props.miniLanguageSelect  && <Menu>{miniLanguageSelect}</Menu>}
                            <div className={"mobile-menu-icon show-for-medium-only " + (this.state.isOpen ? "open" : "")} onClick={this.mobileMenuOpen.bind(this)}><i className="fa fa-bars"></i></div>

                        </div>
                        */}
                        {this.props.mobileMenu && this.state.isOpen ? <div className="show-for-small-only mobile-menu">
                            {this.props.mobileMenu}
                        </div> : ""}
                    </div>
                </div>
            </div>
        </div >
    }
}

export function sortMenuActions(menuActions: MenuAction[]) {
    const ret = menuActions.sort((a, b) => {
        const _a = a.no ? a.no : 0;
        const _b = b.no ? b.no : 0;

        return _a - _b;

    });
    return ret;
}
