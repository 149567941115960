import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, GetAllIndexes, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import * as React from "react";
import { WMDndAreaData } from "@src/component/exercise/engine/WMItemToImageDnDExerciseEngine/WMItemToImageDnDExerciseEngine";
import { Shapes } from "@src/component/exercise/Editor/ExerciseImageCoordinatePick";
import ExerciseImageCoordinatePick from "@src/component/exercise/Editor/ExerciseImageCoordinatePick";
import ExerciseFileSelect, { ExerciseFileTypes } from '@src/component/exercise/Editor/ExerciseFileSelect';
import { uploadFilesToOoFolder } from "@src/server/PublicServer";
import { IViewMediaOoFileRecord } from "@src/framework/view/media/ViewMediaOoFile";
import { AnswerElement } from "@src/component/exercise/models/ExerciseBaseClass";
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import { Panel } from '@src/component/ui/Panel';
import { AExerciseEngine } from "../../models/AExerciseEngine";
import { __ } from '@src/translation';
import { ExerciseEngineHelper } from "../ExerciseEngineHelper";

export type ItemToImageAnswerElement = {
    answers: any[],
    odd_one_outs: AnswerElement[],
    show_radius: boolean,
    illustration?: string,
    isFit: boolean,
    isScaled: boolean
}

export type ItemToImageCircleAnswerType = {
    answerElements: AnswerElement[],
    x: number,
    y: number,
    radius: number,
    shape: Shapes
}

export type ItemToImagePolygonAnswerType = {
    points: any[],
    shape: Shapes
}
export class WMItemToImageConverter extends AExerciseTypeConverter {

    public hasTextAnswer = false;
    public hasImageAnswer = true;
    public hasTextImageAnswer = false;
    static MAX_AREA_NUM: number = 20;
    static MAX_ANSWER_ELEM_BY_AREA_NUM: number = 20;
    static MAX_TOTAL_ANSWERS_NUM: number = 20;


    public convertToEditorAnswer(exercise: any): ItemToImageAnswerElement | undefined {

        if (exercise && exercise.solution && exercise.options) {
            var elements = [];
            var ooo_answers = exercise.options.slice(0);
            for (let i = 0; i < exercise.solution.length; i++) {
                var answers = [];
                for (let j = 0; j < exercise.solution[i].length; j++) {
                    var element = exercise.options[exercise.solution[i][j]];
                    if (element.type == "image" && answers.map((x: any) => x.image).indexOf(element.image) == -1) {
                        answers.push({ type: "image", image: element.image, text: element.text });
                    } else if (element.type == "text" && answers.map((x: any) => x.text).indexOf(element.text) == -1) {
                        answers.push({ type: "text", image: "", text: element.text });
                    }

                    if (element.type == "image" && ooo_answers.map((x: any) => x.image).indexOf(element.image) > -1)
                        ooo_answers = ooo_answers.filter((x: any) => x.image != element.image);       //deleting all iamge items from odd one outs that has been set as an element of a set
                    else (ooo_answers.map((x: any) => x.text).indexOf(element.text) > -1)
                    ooo_answers = ooo_answers.filter((x: any) => x.text != element.text);       //deleting all text items from odd one outs that has been set as an element of a set

                }

                var newElement: any;

                if (exercise.areas[i].points) {
                    newElement = {
                        answerElements: answers,
                        points: exercise.areas[i].points,
                        shape: exercise.areas[i].shape == Shapes.Rectangle ? Shapes.Rectangle : Shapes.Polygon
                    }
                }

                else {
                    newElement = {
                        answerElements: answers,
                        x: Math.round(exercise.areas[i].x),
                        y: Math.round(exercise.areas[i].y),
                        radius: Math.round(exercise.areas[i].radius),
                        shape: Shapes.Circle
                    }
                }
                elements.push(newElement);
            }

            let new_oo_answers = [];
            for (let _i = 0; _i < ooo_answers.length; _i++) {
                if (ooo_answers[_i].type == "image" && new_oo_answers.map((x: any) => x.image).indexOf(ooo_answers[_i].image) == -1) {
                    new_oo_answers.push({ type: "image", image: ooo_answers[_i].image, text: ooo_answers[_i].text });
                } else if (ooo_answers[_i].type == "text" && new_oo_answers.indexOf(ooo_answers[_i].text) == -1) {
                    new_oo_answers.push({ type: "text", image: "", text: ooo_answers[_i].text });
                }
            }

            var answer: ItemToImageAnswerElement = {
                answers: elements,
                show_radius: exercise.show_radius,
                odd_one_outs: new_oo_answers,
                isFit: exercise.isFit,
                isScaled: exercise.isScaled
            };
            return answer;
        }
        return undefined;
    }

    public convertToJson(exerciseDetails: ItemToImageAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: ItemToImageAnswerElement): WMDndAreaData | undefined {
        var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
        if (exerciseDetails && exerciseDetails.answers) {
            var answerList = [];
            var areas = [];
            var solution: any = [];
            for (let i = 0; i < exerciseDetails.answers.length; i++) {
                var actualAnswer = exerciseDetails.answers[i];
                var actualExercise: string[] = [];

                for (let j = 0; j < exerciseDetails.answers[i].answerElements.length; j++) {
                    var actualElement = exerciseDetails.answers[i].answerElements[j];

                    let newElement = {
                        type: actualElement.image != "" ? "image" : "text",
                        image: actualElement.image != "" ? actualElement.image : "",
                        text: actualElement.text.trim()
                    }


                    if (actualExercise.indexOf(newElement.type == "image" ? newElement.image : newElement.text) == -1)
                        answerList.push(newElement);

                    actualExercise.push(newElement.type == "image" ? newElement.image : newElement.text);
                }

                let newArea;

                if (actualAnswer.shape == Shapes.Circle) {
                    newArea = {
                        x: actualAnswer.x ? actualAnswer.x : null,
                        y: actualAnswer.y ? actualAnswer.y : null,
                        radius: actualAnswer.radius ? actualAnswer.radius : null,
                        shape: Shapes.Circle
                    }
                }
                else if (actualAnswer.shape == Shapes.Polygon) {
                    newArea = {
                        points: actualAnswer.points ? actualAnswer.points : null,
                        shape: Shapes.Polygon
                    }
                }
                else if (actualAnswer.shape == Shapes.Rectangle) {
                    newArea = {
                        points: actualAnswer.points ? actualAnswer.points : null,
                        shape: Shapes.Rectangle
                    }
                }
                areas.push(newArea);
            }

            if (exerciseDetails.odd_one_outs) {
                for (let index = 0; index < exerciseDetails.odd_one_outs.length; index++) {
                    if (exerciseDetails.odd_one_outs[index].image != "")
                        exerciseDetails.odd_one_outs[index].type = "image";
                    else
                        exerciseDetails.odd_one_outs[index].type = "text";
                }
            }

            var shuffleAnswers = undefined;
            if (answerList.length > 0 || exerciseDetails.odd_one_outs) {
                shuffleAnswers = exerciseDetails.odd_one_outs ? AExerciseEngine.shuffle(answerList.concat(exerciseDetails.odd_one_outs)) : AExerciseEngine.shuffle(answerList);

                for (let i = 0; i < exerciseDetails.answers.length; i++) {          //iterate through tasks
                    var actualAnswer = exerciseDetails.answers[i];
                    var cur_solution: any = [];
                    for (let j = 0; j < actualAnswer.answerElements.length; j++) {      //iterate through answer elements on each task 
                        var actualElement = actualAnswer.answerElements[j];
                        if (actualElement.image != "")
                            GetAllIndexes(shuffleAnswers.map(x => x.image), actualElement.image).forEach(x => cur_solution.push(x)) //collecting all the indexes of the element occurance

                        else if ((actualElement.text != ""))
                            GetAllIndexes(shuffleAnswers.map(x => x.text), actualElement.text.trim()).forEach(x => cur_solution.push(x)) //collecting all the indexes of the element occurance
                    }
                    solution.push(cur_solution);
                }
            }

            var answer = {
                ...cur_exercise,
                options: shuffleAnswers ? shuffleAnswers : undefined,
                areas: areas,
                solution: solution,
                show_radius: exerciseDetails.show_radius,
                isFit: exerciseDetails.isFit,
                isScaled: exerciseDetails.isScaled
            }
            return answer;

        }
        return cur_exercise;
    }

    async convertOldNKPToJson(data: any, folderId?: number): Promise<any | undefined> {
        let isImage = false;

        if (data && data.PictureAnswerItems) {
            var answerList: any[] = [];
            var areas: any[] = [];
            var solution: any[] = [];

            //Onlyofficeból megkapjuk az adott feladat illusztrációját
            var illustration = await OoFileCrud.list({ filter: { nkp1_id: data.ContentVersionReferences[0].Id } })
            var illustrationUrl = AExerciseTypeConverter.fileBasePath + illustration[0].sha1;

            var illustrWidth = illustration[0].width_px;
            var illustrHeight = illustration[0].height_px;

            if (!illustration || !illustrWidth || !illustrHeight) {
                return null;
            }  //means that there is no main component of the task

            //Csinálunk egy canvast, megjelenítjük az illusztrációt
            var mainImg = new Image();
            var mainCanvas = document.createElement('canvas');
            mainCanvas.width = illustrWidth!;
            mainCanvas.height = illustrHeight!;
            var mainContext = mainCanvas.getContext('2d');

            let wait: any;
            const mainImageLoad = new Promise<number>((resolve) => {
                wait = resolve;
            });

            mainImg.onload = function () {
                mainContext!.drawImage(mainImg, 0, 0);
                wait(0);
            }
            mainImg.src = illustrationUrl;
            const s = await mainImageLoad;

            //Végig iterálunk a válaszokon
            for (let index = 0; index < data.PictureAnswerItems.length; index++) {
                let item = data.PictureAnswerItems[index];

                // draw cropped image
                let sourceX = item.Radius ? item.MiddleX - item.Radius : item.TopX;
                let sourceY = item.Radius ? item.MiddleY - item.Radius : item.TopY;
                let sourceWidth = item.Radius ? (item.MiddleX + item.Radius) - (item.MiddleX - item.Radius) : item.BottomX - item.TopX;
                let sourceHeight = item.Radius ? (item.MiddleY + item.Radius) - (item.MiddleY - item.Radius) : item.BottomY - item.TopY;
                let destX = 0;
                let destY = 0;

                mainContext!.beginPath();
                mainContext!.rect(sourceX, sourceY, sourceWidth - 5, sourceHeight - 5);
                mainContext!.fillStyle = 'white';
                mainContext!.fill();

                let canvas = document.createElement('canvas');
                canvas.width = sourceWidth;
                canvas.height = sourceHeight;
                let imageObj = new Image();

                let r: any;
                const imageLoad = new Promise<IViewMediaOoFileRecord>((resolve, reject) => {
                    r = resolve;
                });

                imageObj.onload = function () {
                    let context = canvas.getContext('2d');
                    context!.drawImage(imageObj, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, sourceWidth, sourceHeight);

                    canvas.toBlob(async function (blob) {
                        if (blob == null) return;
                        var response;
                        if (folderId) {
                            response = await uploadFilesToOoFolder({
                                oo_folder_id: folderId,
                                files: [new File([blob], index + ".png", { type: "image/png" })]
                            });
                            r(response[0]);
                        }
                    });
                };

                imageObj.src = illustrationUrl;

                const response = await imageLoad;

                let newElement = {
                    type: item.AnswerText == "Empty" ? "image" : "text",
                    image: response ? "api/media/file/" + response.sha1 : "",
                    text: item.AnswerText == "Empty" ? "" : item.AnswerText
                }

                answerList.push(newElement);
                solution.push([index]);

                let newArea;
                if (item.Radius && item.MiddleX && item.MiddleY) {
                    newArea = {
                        points: ExerciseEngineHelper.getRectangleCoordinates(Math.round((sourceX / illustrWidth) * 100).toString() + "," + Math.round((sourceY / illustrHeight) * 100).toString() + " " + Math.round(((sourceX + sourceWidth) / illustrWidth) * 100).toString() + "," + Math.round(((sourceY + sourceHeight) / illustrHeight) * 100).toString()),
                        shape: Shapes.Polygon
                    }

                } else if (item.BottomX && item.TopX) {
                    newArea = {
                        points: ExerciseEngineHelper.getRectangleCoordinates(Math.round((sourceX / illustrWidth) * 100).toString() + "," + Math.round((sourceY / illustrHeight) * 100).toString() + " " + Math.round(((sourceX + sourceWidth) / illustrWidth) * 100).toString() + "," + Math.round(((sourceY + sourceHeight) / illustrHeight) * 100).toString()),
                        shape: Shapes.Polygon
                    }
                }
                else {
                    console.log("Feldolgozhatatlan feladat...");
                }
                areas.push(newArea);
            }

            let illustwait: any;
            const mainIllustrationwait = new Promise<IViewMediaOoFileRecord>((resolve) => {
                illustwait = resolve;
            });

            mainCanvas.toBlob(async function (blob) {
                if (blob == null) return;
                var response;
                if (folderId) {
                    response = await uploadFilesToOoFolder({
                        oo_folder_id: folderId,
                        files: [new File([blob], "mainIllustration.png", { type: "image/png" })]
                    });
                    illustwait(response[0]);
                }
            });

            const illustrationrespone = await mainIllustrationwait;

            let newExercise = {
                title: data.Title,
                description: data.QuestionText,
                backgroundStyle: { is_custom_background: false, backgroundImage: "", backgroundColor: "" },
                illustration: "api/media/file/" + illustrationrespone.sha1,
                level: data.QuestionDifficulty == 1 ? 1 : data.QuestionDifficulty == 2 ? 3 : 5,
                demo_path: "",
                imageanswers: isImage,
                imagebasepath: "/",
                options: answerList,
                areas: areas,
                solution: solution,
                isFit: true,
                show_radius: true
            };
            return newExercise;
        }
        return null;
    }

    componentDidUpdate(prevprops: any) {
        /*if (prevprops.exerciseDetails.imageBasePath != this.props.exerciseDetails.imageBasePath && this.state.exercise.answers) {
            var newExercise = this.state.exercise;
            newExercise.answers.length = 0;

            this.setState({
                exercise: newExercise
            })
        }*/
    }


    render() {
        let curr_ex: ItemToImageAnswerElement = this.state.exercise as ItemToImageAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4} >
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="show_radius" checked={curr_ex.show_radius || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Ejtési területek jelzése")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label><input type="checkbox" name="isFit" checked={curr_ex.isFit || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Elemek feszüljenek bele az ejtési területbe")}</label>
                        <label className="exe-editor-label-description">{__("Figyelem! Az elemek területbe való feszítése csak akkor lehetséges, ha minden egyes területhez maximum egy válasz tartozik.")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label><input type="checkbox" name="isScaled" checked={curr_ex.isScaled || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("A válaszelemeket az eredeti képről és méretarányosan vágtam ki")}</label>
                    </div>
                </div>

                <Panel header={__("Területek")} headingLevel={5} >
                    {
                        curr_ex.answers ?
                            curr_ex.answers.map((curr_task: any, index) => {
                                let actions: IAccordionAction[];
                                actions = [
                                    {
                                        title: __("Törlés"),
                                        type: AccordionActionType.Trash,
                                        action: this.removeElement.bind(this, "answers", index)
                                    },
                                    {
                                        title: __("Fel"),
                                        type: AccordionActionType.Up,
                                        action: this.moveUp.bind(this, "answers", index)
                                    },
                                    {
                                        title: __("Le"),
                                        type: AccordionActionType.Down,
                                        action: this.moveDown.bind(this, "answers", index)
                                    }
                                ];

                                return (
                                    <Accordion key={"answer_" + index}>
                                        <ActionAccordionItem defaultClosed={false} key={"answers_" + index} actions={actions} title={__("{n}. terület", { n: index + 1 })}>
                                            <Panel>                                                

                                                <label>{__("Típus")}</label>
                                                <select value={curr_task.shape} name={"answers#" + index + ".shape"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)} onSelect={this.handleInputChange.bind(this)}>
                                                    <option value={Shapes.Circle}>{__("Kör")}</option>
                                                    <option value={Shapes.Polygon}>{__("Alakzat")}</option>
                                                    <option value={Shapes.Rectangle}>{__("Téglalap")}</option>
                                                </select>

                                                {
                                                    curr_task.shape == Shapes.Circle ?

                                                        <div className="row">
                                                            <div className="large-4 small-12 columns">
                                                                <label>{__("X koordináta")}
                                                                    <input type="text" name={"answers#" + index + ".x"} value={curr_task.x ? curr_task.x : 0} disabled
                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                </label>

                                                            </div>
                                                            <div className="large-4 small-12 columns">
                                                                <label>{__("Y koordináta")}
                                                                    <input type="text" name={"answers#" + index + ".y"} value={curr_task.y ? curr_task.y : 0} disabled
                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                </label>
                                                            </div>
                                                            <div className="large-4 small-12 columns">
                                                                <label>{__("Rádiusz")}
                                                                    <input type="text" name={"answers#" + index + ".radius"} value={curr_task.radius ? curr_task.radius : 0} disabled
                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        : ""
                                                }

                                                <ExerciseImageCoordinatePick
                                                    imagesrc={this.props.exerciseDetails.imageBasePath}
                                                    x={curr_task.x}
                                                    y={curr_task.y}
                                                    radius={curr_task.radius}
                                                    shape={curr_task.shape}
                                                    points={curr_task.points}
                                                    otherCircles={curr_ex.answers.filter((item: any) => (item.points == undefined && (item.x != curr_task.x || item.y != curr_task.y)))}
                                                    otherPolygons={curr_ex.answers.filter((item: any) => (item.points != undefined && item.points != curr_task.points))}
                                                    taskValues={
                                                        curr_task.shape == Shapes.Circle ?
                                                            [
                                                                { key: "answers#" + index + ".x", value: curr_task.x ? curr_task.x : 0 },
                                                                { key: "answers#" + index + ".y", value: curr_task.y ? curr_task.y : 0 },
                                                                { key: "answers#" + index + ".radius", value: curr_task.radius ? curr_task.radius : 0 }
                                                            ]
                                                            : [{ key: "answers#" + index + ".points", value: curr_task.points ? curr_task.points : null }]
                                                    }
                                                    onChange={this.handleInputChangeAdditionalArray.bind(this)}
                                                />
                                                <Panel header={__("Válaszok")} headingLevel={6}>
                                                    {
                                                        curr_task.answerElements.map((curr_answer: any, _j: any) => {
                                                            return (<Panel>
                                                                <legend>
                                                                <label className="exe-editor-fieldlabel-1">Elem {_j + 1}</label>
                                                                <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "answers#" + index + ".answerElements", _j)}><i className="fa fa-trash"></i></button>
                                                                </legend>
                                                                <div className="large-12 columns">
                                                                    <label className="exe-image-select-label">{__("Kép (opcionális)")}
                                                                        {<ExerciseFileSelect
                                                                            imagebasepath={this.props.imagebasepath}
                                                                            value={curr_answer.image || ""}
                                                                            onChange={this.handleImageChange.bind(this, "answers#" + index + ".answerElements#" + _j + ".image")}
                                                                            getFolderId={this.getFolderId.bind(this)}
                                                                            fileType={ExerciseFileTypes.Image}
                                                                        />}
                                                                    </label>
                                                                </div>

                                                                <div className="large-12 columns">
                                                                    <label>{__("Válasz (képaláírás)")}
                                                                        <input type="text" name={"answers#" + index + ".answerElements#" + _j + ".text"} data-parent-index={index} data-index={index} value={curr_answer.text}
                                                                            onBlur={this.onBlurEvent.bind(this)}
                                                                            onChange={this.handleInputChange.bind(this)} />
                                                                    </label>

                                                                </div>
                                                            </Panel>
                                                            )
                                                        })}
                                                    <div className="row">
                                                        <button className="button small" onClick={this.onAddNewAnswer.bind(this, index)}><i className="fa fa-plus"></i>{" " + __("Új elem")}</button>
                                                        <label className="exe-editor-label-description columns">{__("Területenként max. {max} elem vehető fel!", {max:WMItemToImageConverter.MAX_ANSWER_ELEM_BY_AREA_NUM} )} {" " + __("Globálisan (összesen) {n} elem vehető fel.",{n:WMItemToImageConverter.MAX_TOTAL_ANSWERS_NUM})}</label>
                                                    </div>
                                                </Panel>
                                            </Panel>
                                        </ActionAccordionItem>
                                    </Accordion>)
                            }
                            )
                            : ""
                    }
                    <div className="row">
                        <button className="button small" onClick={this.onAddNewTask.bind(this)}><i className="fa fa-plus"></i>{" " + __("Új terület")}</button>
                        <label className="exe-editor-label-description columns">{__("Maximum {max} terület vehető fel!", { max: WMItemToImageConverter.MAX_AREA_NUM })}</label>
                    </div>
                </Panel>
                <Panel header={__("Kakukktojás elemek")} headingLevel={5} >
                    {
                        curr_ex.odd_one_outs ?
                            curr_ex.odd_one_outs.map((curr_odd_one_out: any, _j: any) => {
                                return (<Panel>
                                    <legend>                                        
                                    <label className="exe-editor-fieldlabel-1">{__("Elem")} {_j + 1}</label>
                                    <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "odd_one_outs", _j)}><i className="fa fa-trash"></i></button>
                                    </legend>
                                    <div className="large-12 columns">
                                        <label className="exe-image-select-label">{__("Kép (opcionális)")}
                                            {<ExerciseFileSelect
                                                imagebasepath={this.props.imagebasepath}
                                                value={curr_odd_one_out.image || ""}
                                                onChange={this.handleImageChange.bind(this, "odd_one_outs#" + _j + ".image")}
                                                getFolderId={this.getFolderId.bind(this)}
                                                fileType={ExerciseFileTypes.Image}
                                            />}
                                        </label>
                                    </div>

                                    <div className="large-12 columns">
                                        <label>{__("Válasz (képaláírás)")}
                                            <input type="text" name={"odd_one_outs#" + _j + ".text"} data-parent-index={_j} data-index={_j} value={curr_odd_one_out.text}
                                                onBlur={this.onBlurEvent.bind(this)}
                                                onChange={this.handleInputChange.bind(this)} />
                                        </label>

                                    </div>
                                </Panel>
                                )
                            })
                            : ""
                    }
                    <button className="button small" onClick={this.onAddNewOddOneOut.bind(this)}><i className="fa fa-plus"></i>{__("Új kakukktojás")}</button>
                </Panel>
            </Panel>
        );
    }

    onAddNewTask() {
        let temp_exercise = this.state.exercise as ItemToImageAnswerElement;
        if (!temp_exercise.answers) temp_exercise.answers = [];
        temp_exercise.answers.push(this.getNewTask());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    onAddNewAnswer(index: number) {
        let temp_exercise = this.state.exercise as ItemToImageAnswerElement;
        if (!temp_exercise.answers) temp_exercise.answers = [];
        temp_exercise.answers[index].answerElements.push(this.getNewAnswer());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    onAddNewOddOneOut() {
        let temp_exercise = this.state.exercise as ItemToImageAnswerElement;
        if (!temp_exercise.odd_one_outs) temp_exercise.odd_one_outs = [];
        temp_exercise.odd_one_outs.push(this.getNewAnswer());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    getNewTask(): ItemToImageCircleAnswerType {
        let newElement: ItemToImageCircleAnswerType = {
            answerElements: [],
            x: 0,
            y: 0,
            radius: 5,
            shape: Shapes.Circle
        };
        return newElement;
    }

    getNewAnswer(): AnswerElement {
        let newanswer: AnswerElement = {
            type: "",
            image: "",
            text: ""
        };
        return newanswer;
    }
    public validate(editorAnswer: ItemToImageAnswerElement, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData, validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;

        let elementsCount = 0;
        let errorMsg = "";
        let OFIErrors: string[] = [];

        if (!baseData.illustration) {
            errorMsg = __("Az illusztráció megadása kötelező!");
            return { valid: false, message: errorMsg };
        }

        if (editorAnswer.answers.length > WMItemToImageConverter.MAX_AREA_NUM) {
            errorMsg = __("Maximum {max} terület vehető fel!", { max: WMItemToImageConverter.MAX_AREA_NUM });
            if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg };
        }

        for (let i = 0; i < editorAnswer.answers.length; i++) {
            elementsCount += editorAnswer.answers[i].answerElements.length;
            if (editorAnswer.answers[i].answerElements.length > WMItemToImageConverter.MAX_ANSWER_ELEM_BY_AREA_NUM) {
                errorMsg = __("Egy területhez maximum {n} válaszelem tartozhat!", { n: WMItemToImageConverter.MAX_ANSWER_ELEM_BY_AREA_NUM });
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                validationMap!.set("answers[" + i + "]", errorMsg);
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
            }

            if (elementsCount > WMItemToImageConverter.MAX_TOTAL_ANSWERS_NUM) {
                errorMsg = __("Maximum {max} behúzandó elem vehető fel!", { max: WMItemToImageConverter.MAX_TOTAL_ANSWERS_NUM });
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                validationMap!.set("answers[" + i + "]", errorMsg);
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
            }
        }
        if (editorAnswer.odd_one_outs && editorAnswer.odd_one_outs.length > WMItemToImageConverter.MAX_TOTAL_ANSWERS_NUM) {
            errorMsg = __("Maximum {max} kakukktojás elem vehető fel!", { max: WMItemToImageConverter.MAX_TOTAL_ANSWERS_NUM });
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            return { valid: false, message: errorMsg };
        }
        if (AExerciseTypeConverter.isOfiEditor) {
            return { valid: true, message: OFIErrors.join(' , ') }
        }

        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: ItemToImageAnswerElement | null, newState: ItemToImageAnswerElement): ValidationResponse {

        //we shouldn't let fit mode if some of the areas has multiple elements
        if (newState.isFit && newState.answers) {
            for (let i = 0; i < newState.answers.length; i++) {
                if (newState.answers[i].answerElements.length > 1) {
                    newState.isFit = false;
                    break;
                }
            }
        }

        if (AExerciseTypeConverter.isOfiEditor) return { valid: true };
        let errorMsg = "";
        if (newState.answers.length > WMItemToImageConverter.MAX_AREA_NUM) {
            newState.answers.splice(WMItemToImageConverter.MAX_AREA_NUM, newState.answers.length - WMItemToImageConverter.MAX_AREA_NUM);
            errorMsg = __("Maximum {max} terület vehető fel!", { max: WMItemToImageConverter.MAX_AREA_NUM });
            return { valid: false, message: errorMsg };
        }
        let elementsCount = 0;
        for (let i = 0; i < newState.answers.length; i++) {
            elementsCount += newState.answers[i].answerElements.length;
            if (newState.answers[i].answerElements.length > WMItemToImageConverter.MAX_ANSWER_ELEM_BY_AREA_NUM) {
                let elementsToRemove = newState.answers[i].answerElements.length - WMItemToImageConverter.MAX_ANSWER_ELEM_BY_AREA_NUM;
                newState.answers[i].answerElements.splice(WMItemToImageConverter.MAX_ANSWER_ELEM_BY_AREA_NUM);
                elementsCount = elementsCount - elementsToRemove;
                errorMsg = __("Egy területhez maximum {n} válaszelem tartozhat!");
                return { valid: false, message: errorMsg };
            }
            if (elementsCount > WMItemToImageConverter.MAX_TOTAL_ANSWERS_NUM) {
                let elementsToRemove = elementsCount - WMItemToImageConverter.MAX_TOTAL_ANSWERS_NUM;
                newState.answers[i].answerElements.splice(newState.answers[i].answerElements.length - elementsToRemove, elementsToRemove);
                elementsCount = elementsCount - elementsToRemove;
                errorMsg = __("Maximum {max} behúzandó elem vehető fel!", { max: WMItemToImageConverter.MAX_TOTAL_ANSWERS_NUM });
                return { valid: false, message: errorMsg };
            }
        }

        /*if (newState.odd_one_outs && newState.odd_one_outs.length > elementsCount)
        {
            newState.odd_one_outs.splice(elementsCount, newState.odd_one_outs.length - elementsCount);
            errorMsg = __("Nem lehet több kakukktojás elem, mint normál válaszelem.");
            return { valid: false, message: errorMsg }
        }*/

        return { valid: true }
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        let questionStat, answerStatArea, answerStatTotal: StatisticsResponse;
        statisticsResponses.push({ name: __("limit - területek max. száma: {n}", { n: WMItemToImageConverter.MAX_AREA_NUM }), count: undefined });
        statisticsResponses.push({ name: __("limit - max. elemszám terülenként: {n}", { n: WMItemToImageConverter.MAX_ANSWER_ELEM_BY_AREA_NUM }), count: undefined });
        statisticsResponses.push({ name: __("limit - max. elemszám összesen: {n}", { n: WMItemToImageConverter.MAX_TOTAL_ANSWERS_NUM }), count: undefined });
        questionStat = { name: __("Területek száma"), count: new Map() }
        answerStatArea = { name: __("Behúzandó elemek száma területenként"), count: new Map() }
        answerStatTotal = { name: __("Behúzandó elemek száma összesen"), count: new Map() }
        //loop exercises
        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const currentExc = exerciseList[i];
            let question_count = questionStat.count.has(currentExc.answers.length) ? questionStat.count.get(currentExc.answers.length) : 0;
            questionStat.count.set(currentExc.answers.length, question_count! + 1);
            //loop answers array
            let counter = 0;
            for (let j = 0; j < currentExc.answers.length; j++) {
                const curr_answer = currentExc.answers[j];
                if (curr_answer.answerElements) {
                    counter += curr_answer.answerElements.length;
                    let answer_count = answerStatArea.count.has(curr_answer.answerElements.length) ? answerStatArea.count.get(curr_answer.answerElements.length) : 0;
                    answerStatArea.count.set(curr_answer.answerElements.length, answer_count! + 1);
                }
            }
            let answer_count = answerStatTotal.count!.has(counter) ? answerStatTotal.count!.get(counter) : 0;
            answerStatTotal.count!.set(counter, answer_count! + 1);
        }
        statisticsResponses.push(questionStat, answerStatTotal, answerStatArea);
        return statisticsResponses;
    }
}



