import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewExcExerciseRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Feladat motor
     */
    engine_id?: number;
    /**
     * Évfolyam
     */
    grade_id?: number|null;
    /**
     * Tantárgy
     */
    subject_id?: number|null;
    /**
     * Könyvtár
     */
    library_id?: number;
    /**
     * A feladat adatai
     */
    exercise?: any;
    /**
     * A feladat helyes megoldása
     */
    solution?: any;
    /**
     * Utasítás
     */
    task_html?: string|null;
    /**
     * Visszajelzés helyes válaszra
     */
    correct_answer_feedback_html?: string|null;
    /**
     * Visszajelzés helytelen válaszra
     */
    wrong_answer_feedback_html?: string|null;
    /**
     * Tároló
     *
     * Az a mappa, ami a feladathoz tartozó médiákat tárolja.
     */
    oo_folder_id?: number|null;
    /**
     * Megjelenés helye
     *
     * Mappa, ahol a feladat a virtuális állományrendszerben megjelenik.
     */
    show_in_folder_id?: number|null;
    /**
     * Név
     *
     * Kulcsszavak
     */
    keywords?: string|null;
    /**
     * A feladat mentésekor ide bekerül az összes olyan szöveg, ami értelmes kereshető szöveget tartalmaz. Ez feladatmotoronként eltérő helyekről/mezőkből származik.
     *
     * Kereső szöveg
     */
    search_text?: string|null;
    /**
     * SNI
     *
     * Sajátos nevelési igényűek számára készített feladat
     */
    is_sni?: boolean;
    /**
     * Akadálymentesített
     *
     * Látássérültek számára akadálymentesített feladat
     */
    is_accessible?: boolean|null;
    /**
     * Egyszerû megjelenítés
     *
     * Egyszerű stílusban megjelenítendő feladat
     */
    simple_style?: boolean|null;
    /**
     * Site
     */
    site_id?: number;
    /**
     * Nyelv
     */
    lang_id?: number;
    /**
     * A feladat szinte
     *
     * A feladat szinte
     */
    level?: number /* float8 */|null;
    /**
     * A feladat leírása
     *
     * A feladat leírása
     */
    description?: string|null;
    /**
     * Feladat motor
     *
     * Feladat motor név
     */
    engine_name?: string|null;
    /**
     * Évfolyam
     *
     * Évfolyam azonosító
     */
    grade_name?: string|null;
    /**
     * Tantárgy
     *
     * Tantárgy név
     */
    subject_name?: string|null;
    /**
     * Feladatsorban
     *
     * Feladatsorban szerepel
     */
    in_series?: boolean|null;
}

/**
 *  exc_pub.view_exc_exercise - Feladat
 *
 *  A feladat egy konkrét feladvány, amit egy előre meghatározott feladat motor jelenít meg.
 */

export default class ViewExcExercise extends View<IViewExcExerciseRecord> {
    public static VIEW_INFO_ID = 2018073003;

    public static getSchemaNameForClass(): string { return 'exc_pub'; }
    public static getViewNameForClass(): string { return 'view_exc_exercise'; }
    public static getTableNameForClass(): string|null { return 'exercise'; }

    public static canCache(): boolean {
        return true;
    }

    public static load: (id: number, aServer ?: Server) => Promise<ViewExcExercise>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewExcExerciseRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewExcExerciseClassProxy extends ViewClassProxy<IViewExcExerciseRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewExcExerciseClassProxy = new ViewExcExerciseClassProxy(ViewExcExercise);
registerViewClassProxy(viewExcExerciseClassProxy);

