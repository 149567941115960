import * as React from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';

import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { getReactTableLabels } from '@src/framework/i18n';


import { __ } from '@src/translation';
import { app } from '@src/index';
import { observable, runInAction, action } from 'mobx';
import ViewKapCourse, { IViewKapCourseRecord } from '@src/framework/view/kap/ViewKapCourse';
import { formatDateWithSeconds } from '@src/Util';

import { WF_COURSE_STATION_CLOSED_ID } from '../CourseDetails';
import GeneratedDocumentTypeCrud, { IGeneratedDocumentTypeRecord } from '@src/framework/crud/doc/GeneratedDocumentTypeCrud';
import { courseMassDocumentDownloadUrl } from '@src/server/PublicServer';


export interface ICourseMassDownloadProps {

}

/*
select 
		'export const GENERATED_DOCUMENT_TYPE_' || upper(code) || '_ID = ' || id || '; // ' || title
from doc.generated_document_type gdt 
order by id asc;
*/
export const GENERATED_DOCUMENT_TYPE_KEPZO_JEGYZOKONYV_ZAROERTEKELESROL_ID = 1; // Képző jegyzőkönyv záróértékelésről
export const GENERATED_DOCUMENT_TYPE_KEPZO_JELENLETIIV_ID = 2; // Képző jelenléti ív
export const GENERATED_DOCUMENT_TYPE_KEPZO_KEPZ_HANDOUT_ATV_ID = 3; // Résztvevői segédanyag átvételéről szóló dokumentum
export const GENERATED_DOCUMENT_TYPE_KEPZO_KEPZ_TAJEK_ATV_ID = 4; // Résztvevői tájékoztató a továbbképzés feltételeiről szóló átvételi dokumentum
export const GENERATED_DOCUMENT_TYPE_KEPZO_TOVABBKEPZESI_NAPLO_ID = 5; // Képző továbbképzési napló
export const GENERATED_DOCUMENT_TYPE_PEDAG_JELENTKEZESI_LAP_ID = 6; // Pedagógus jelentkezési lap
export const GENERATED_DOCUMENT_TYPE_PEDAG_KEPZESI_TAJEKOZTATO_ID = 7; // Pedagógus képzési tájékoztató
export const GENERATED_DOCUMENT_TYPE_PEDAG_KERDOIV_ERTEKELO_ID = 8; // Pedagógus kérdőív értékelő
export const GENERATED_DOCUMENT_TYPE_PEDAG_TANUSITVANY_KIADAS_NYILVANTARTAS_ID = 9; // Pedagógus tanúsítvány kiadás nyilvántartás
export const GENERATED_DOCUMENT_TYPE_PEDAG_TOVABBKEPZESI_SZERZODES_ID = 10; // Pedagógus továbbképzési szerződés
export const GENERATED_DOCUMENT_TYPE_CERTIFICATE_ID = 11; // Tanúsítvány
export const GENERATED_DOCUMENT_TYPE_CERTIFICATE_SIGNED_ID = 12; // Aláírt tanúsítvány
export const GENERATED_DOCUMENT_TYPE_QUESTIONNAIRE_ID = 13; // Bemeneti kérdőív
export const GENERATED_DOCUMENT_TYPE_QUESTIONNAIRE_OUT_ID = 14; // Kimeneti kérdőív
export const GENERATED_DOCUMENT_TYPE_HTML_PREVIEW_ID = 100; // HTML előnézeti kép

// TODO: ezt is le lehetne kérdezni a generated_document_type.table_info_id alapján!
export const COURSE_DOCUMENT_TYPE_IDS: number[] = [
    GENERATED_DOCUMENT_TYPE_KEPZO_JEGYZOKONYV_ZAROERTEKELESROL_ID,
    GENERATED_DOCUMENT_TYPE_KEPZO_JELENLETIIV_ID,
    GENERATED_DOCUMENT_TYPE_KEPZO_KEPZ_HANDOUT_ATV_ID,
    GENERATED_DOCUMENT_TYPE_KEPZO_KEPZ_TAJEK_ATV_ID,
    GENERATED_DOCUMENT_TYPE_KEPZO_TOVABBKEPZESI_NAPLO_ID,
    GENERATED_DOCUMENT_TYPE_PEDAG_JELENTKEZESI_LAP_ID,
    GENERATED_DOCUMENT_TYPE_PEDAG_KEPZESI_TAJEKOZTATO_ID,
    GENERATED_DOCUMENT_TYPE_PEDAG_KERDOIV_ERTEKELO_ID,
    GENERATED_DOCUMENT_TYPE_PEDAG_TANUSITVANY_KIADAS_NYILVANTARTAS_ID,
    GENERATED_DOCUMENT_TYPE_PEDAG_TOVABBKEPZESI_SZERZODES_ID,
    GENERATED_DOCUMENT_TYPE_CERTIFICATE_ID,
    GENERATED_DOCUMENT_TYPE_CERTIFICATE_SIGNED_ID,
];

@observer
export class CourseMassDownload extends React.Component<ICourseMassDownloadProps, {}> {
    private debouncedAsyncReload: () => void;

    @observable private changed: boolean = false;
    @observable private regnos: string = "";
    @observable private regnoDuplicates: number = 0;
    @observable private regnoArray: number[] = [];
    @observable private closedOnly: boolean = true;
    @observable private courses: IViewKapCourseRecord[] = [];
    @observable private notices: string[] = [];
    @observable private warnings: string[] = [];
    @observable private docTypes: IGeneratedDocumentTypeRecord[] = [];
    @observable private selectedDocTypeIds: Set<number> = new Set(COURSE_DOCUMENT_TYPE_IDS);


    constructor(props: ICourseMassDownloadProps) {
        super(props);
        this.state = {}
        this.debouncedAsyncReload = debounce(() => this.asyncReload(), 500);
    }

    componentDidMount() {
        this.debouncedAsyncReload();
    }

    private asyncReload = async () => {
        const regnoArray = this.regnos.split(/[^\d]+/).filter(item => item.match(/\d+/)).map(item => parseInt(item));
        const notices: string[] = [];
        const warnings: string[] = [];

        runInAction(() => {
            this.regnoArray = Array.from(new Set(regnoArray));

            if (this.regnoArray.length == 0) {
                warnings.push(__("Kérem adjon meg érvényes iktatószámokat!"));
            }
            this.regnoDuplicates = regnoArray.length - this.regnoArray.length;
            if (this.regnoDuplicates > 0) {
                warnings.push(__("{count} db iktatószámot többször adott meg!", {count: this.regnoDuplicates}));
            }

        });
        try {
            const docTypes = await GeneratedDocumentTypeCrud.list({
                filter: { id: COURSE_DOCUMENT_TYPE_IDS, is_active: true },
                order_by: "id"
            });
            const courses: IViewKapCourseRecord[] = await ViewKapCourse.list({
                filter: {
                    regno: this.regnoArray,
                    is_active: true
                },
                columns: [
                    'id', 'start_date', 'end_date',
                    'certs_created', 'address',
                    'closed_at', 'regno',
                    'wf_station_id', 'wf_station_name', 'wf_station_style',
                    'training_type_code', 'institute_site_name',
                ],
                order_by: 'regno'
            });
            // Statisztikák gyűjtése
            if (courses.length != this.regnoArray.length) {
                warnings.push(this.regnoArray.length + " képzést kerestem, " + courses.length +
                    " darabot találtam");
            } else {
                notices.push("Az összes megadott iktatószámhoz találtam képzést.");
            }
            // Megnézzük hogy mennyi az inaktív
            if (this.closedOnly && courses.length > 0) {
                const closedCount = courses.filter(course => course.wf_station_id == WF_COURSE_STATION_CLOSED_ID).length;
                if (closedCount != courses.length) {
                    warnings.push("A lekért " + courses.length + " képzésből csak " + closedCount + "van lezárva.");
                } else {
                    notices.push("Az összes képzés le van zárva.");
                }
            }

            runInAction(() => {
                this.docTypes = docTypes;
                this.courses = courses;
                this.warnings = warnings;
                this.notices = notices;
            })
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
        runInAction(() => {
            this.changed = false;
        });
    }

    @action.bound invalidate() {
        this.changed = true;
        this.debouncedAsyncReload();
    }

    private regnoSearchSummary() {
        if (this.regnoArray.length) {
            return <p className="callout primary">
                <b>Összesen {this.regnoArray.length} iktatószámot keresek:</b>&nbsp;
                    {this.regnoArray.map((regno, index) => <span className="label secondary" key={index}>{regno}</span>)}
            </p>
        } else {
            return null;
        }
    }

    private searchFlags() {
        return <label>
            <input type="checkbox" checked={this.closedOnly}
                onChange={() => { runInAction(() => { this.closedOnly = !this.closedOnly; this.invalidate(); }) }}
            />
            {__("Csak a lezárt képzéseket.")}
        </label>
    }

    private renderMessages() {
        if (this.changed || this.regnoArray.length == 0) {
            return null;
        }

        return <>
            {this.warnings.length > 0 ?
                <p className="callout warning">
                    <ul>
                        {this.warnings.map((warning, index) =>
                            <li key={index}>{warning}</li>)}
                    </ul>
                </p>
                : null}
            {this.notices.length > 0 ?
                <p className="callout success">
                    <ul>
                        {this.notices.map((notice, index) =>
                            <li key={index}>{notice}</li>)}
                    </ul>
                </p>
                : null}
        </>;

    }

    private downloadDocs() {
        if (this.warnings.length>0) {
            if (!confirm(__("Nem lehet maradéktalanul letölteni az összes kért dokumentumot. Folytatja?"))) {
                app.showWarning("Figyelmeztetés", "Letöltés megszakítva.");
            }
        }
        const url = courseMassDocumentDownloadUrl({
            course_ids: this.courses.map(course => course.id!),
            document_type_ids: Array.from(this.selectedDocTypeIds)
        })
        console.log(url);
        let link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    private renderDoctypes() {
        return <fieldset className="fieldset">
            <legend>{__("Milyen dokumentumokat szeretne letölteni?")}</legend>
            <div className="row expanded">
                {this.docTypes.map(docType => {

                    return <div className="column small-6 medium-4" key={docType.id!}>
                        <label>
                            <input type="checkbox"
                                checked={this.selectedDocTypeIds.has(docType.id!)}
                                onChange={() => {
                                    runInAction(() => {
                                        if (this.selectedDocTypeIds.has(docType.id!)) {
                                            this.selectedDocTypeIds.delete(docType.id!);
                                        } else {
                                            this.selectedDocTypeIds.add(docType.id!);
                                        }
                                    })
                                }}
                            />
                            {docType.title}
                        </label>
                    </div>

                })}
            </div>
            <div className="column small-12 medium-12">
                <button className="button primary"
                    disabled={this.selectedDocTypeIds.size==0 || this.courses.length==0}
                    onClick={this.downloadDocs.bind(this)}
                >
                    <i className="fa fa-download"/>
                        {__("Letöltés")}
                </button>
            </div>
        </fieldset>
    }

    private renderTable() {
        if (this.changed) {
            return <span>Betöltés...</span>;
        }
        if (this.regnoArray.length == 0) {
            return null;
        }
        /*

                    'id', 'is_active', 'start_date', 'end_date',
                    'certs_created', 'address',
                    'closed_at', 'regno',
                    'wf_station_id', 'wf_station_name', 'wf_station_style',
                    'training_type_code', 'institute_site_name',

        */
        return <ReactTable
            columns={[
                { Header: __("ID"), accessor: "regno", maxWidth: 100 },
                { Header: __("Ellátási hely"), accessor: "institute_site_name" },
                { Header: __("Típus"), accessor: "training_type_code", maxWidth: 100 },
                {
                    Header: __("Lezárva"), accessor: "closed_at",
                    Cell: (data: any, column: any) => formatDateWithSeconds(data.original.closed_at)
                },
            ]}
            data={this.courses}
            filterable={false}
            className="-striped -highlight "
            {...getReactTableLabels()}
        />
    }

    public render() {
        return <div className="row">
            <div className="column small-12 large-12">
                <p className="callout secondary">
                    <small>
                        {__("Ezzel az eszközzel egyszerre sok képzés dokumentumait lehet összegyűjteni és letölteni.")}
                    </small>
                </p>
            </div>


            <div className="column small-12">
                <label>
                    {__("Képzések kiválasztása iktatószám ('id szám') alapján")}
                    <input type="text"
                        placeholder="Írjon be ID számokat szóközzel elválasztva."
                        value={this.regnos}
                        onChange={(e) => { runInAction(() => { this.regnos = e.target.value; this.invalidate(); }) }}
                    />
                </label>
            </div>
            <div className="column small-12">
                {this.regnoSearchSummary()}
            </div>
            <div className="column small-12">
                {this.searchFlags()}
            </div>
            <div className="column small-12">
                {this.renderMessages()}
            </div>
            <div className="column small-12">
                {this.renderDoctypes()}
            </div>
            <div className="column small-12">
                {this.renderTable()}
            </div>
        </div>
    }
}
