import * as React from 'react';
import { debounce } from 'lodash';
import { app } from '@src/index';
import 'react-table/react-table.css'
import SecUserSelector from '@src/framework/forms/SecUserSelector';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import PermissionAuditCrudByUser from './sys_perm_audit_crud_by_user';
import { Groups } from '@src/framework/server/Auth';
import ViewSecUserGroup, { IViewSecUserGroupRecord } from '@src/framework/view/sys/ViewSecUserGroup';
import PermissionAuditViewByUser from './sys_perm_audit_view_by_user';
import { __ } from '@src/translation';
 
interface IPermissionAuditByUserProps {

}

interface IPermissionAuditByUserState {
    loading: boolean,
    userId : number|null;
    userIsSuperAdmin : boolean;
    userIsNkpAdmin : boolean;
    userGroups : IViewSecUserGroupRecord[];
}

export default class PermissionAuditByUser extends React.Component<IPermissionAuditByUserProps, IPermissionAuditByUserState> {
    private debouncedAsyncReload: () => void;

    constructor(props: IPermissionAuditByUserProps) {
        super(props);
        this.state = {
            loading: true,
            userId: null,
            userIsSuperAdmin: false,
            userIsNkpAdmin: false,
            userGroups: []
        };
        this.debouncedAsyncReload = debounce(() => this.asyncReload(), 300);
    }

    componentDidMount() {
        this.debouncedAsyncReload();
    }

    private asyncReload = async () => {
        try {
            this.setState({ loading: true });
            let userIsSuperAdmin : boolean = false;
            let userIsNkpAdmin : boolean = false;
            let userGroups : IViewSecUserGroupRecord[] = [];
            if (this.state.userId) {                
                userGroups = await ViewSecUserGroup.list({
                        filter: {user_id: this.state.userId}
                });
                userGroups.forEach( (ug) => {
                    if (ug.id! ==Groups.Admin) {
                        userIsSuperAdmin = true;
                    }
                    if (ug.id! ==Groups.NkpAdmin) {
                        userIsNkpAdmin = true;
                    }
                })
            }
            this.setState({ userGroups, userIsSuperAdmin, userIsNkpAdmin,
                loading: false });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private onUserIdChange = (userId: number | null) => {
        this.setState({ userId }, this.debouncedAsyncReload);
    }
    
    private getReport = () => {
        return <Tabs defaultIndex={0}  forceRenderTabPanel={true}>
            <TabList>
                <Tab><i className="fa fa-table" /> CRUD</Tab>
                <Tab><i className="fa fa-eye" /> View</Tab>
            </TabList>
            <TabPanel>
                <PermissionAuditCrudByUser userId={this.state.userId!} />
            </TabPanel>
            <TabPanel>
                <PermissionAuditViewByUser userId={this.state.userId!} />
            </TabPanel>
        </Tabs>;
    }

    render() {
        return <div className="row expanded">
            <div className="column small-12 large-12">
                <p className="callout primary">
                    {__("Ezen a lapon egy adott felhasználó összes jogát tudja összegyűjteni")}
                    {__("és elemezni.")}
                </p>
            </div>
            <div className="column small-12 large-12">
                <label>{__("Felhasználó (e-mail cím vagy bejelentkezési név)")}
                        <SecUserSelector
                            value={this.state.userId}
                            clearable={false}
                            onChange={this.onUserIdChange}
                        />
                </label>
            </div>
            {(this.state.userIsSuperAdmin || this.state.userIsNkpAdmin)
            ?
            <div className="column small-12 large-12">
                <p className="callout alert">
                    {__("Figyelem! A kiválasztott felhasználó egy ")}{this.state.userIsSuperAdmin?__("szuper admin"):__("nkp adminisztrátor")}.                    
                    {__("Az alábbi riportok csak az explicit módon megadott engedélyeket tartalmazzák, de egy admin")}
                    {__("felhasználó olyan dolgokat is megtehet, amire nem kapott explicit módon engedélyt.")}
                </p>
            </div>:null
            }
            <div className="column small-12 large-12">
                {this.state.userId
                    ?this.getReport()
                    :<p className="callout alert">
                        {__("Válasszon ki egy felhasználót az adatok elemzéséhez.")}
                    </p>
                }
            </div>
        </div>
    }

}