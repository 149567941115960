import * as React from 'react';
import WfAPI, { IViewTransitionTypeRecordWithPerm, WfPublicationInfo, SecStationPermissions } from '@src/framework/wf/WfAPI';
import ViewWfHeadTable from '@src/framework/view/wf/ViewWfHeadTable';
import ViewWfWorkflow, { IViewWfWorkflowRecord } from '@src/framework/view/wf/ViewWfWorkflow';
import obtainServer from '@src/framework/server/Server';

import { app } from '@src/index';
import { Dialog, alertDialog, promptDialog, DialogActions, DialogContent, confirmDialog } from '@src/component/Dialog';
import WfPlugin from './wf_plugin';
import { hasGroup, me, Groups } from '@src/framework/server/Auth';
import StationCrud, { IStationRecord } from '@src/framework/crud/wf/StationCrud';
import { __ } from '@src/translation';
import { DisplayMode } from './wf_head_plugin';
import ButtonGroup, { ButtonSize, ButtonAlign } from '../ui/ButtonGroup';

import { Toggle } from '../ui/Panel';
interface WorkflowDialogProps {
    open: boolean;
    onClose: () => void;
    onTransition: (transition: IViewTransitionTypeRecordWithPerm) => void;
    onBeforeTransition?: (transition: IViewTransitionTypeRecordWithPerm) => boolean;

    tableInfoId: number;
    recId: number;

    displayName: string;

    autoPublishOnClosedStation: boolean;
    showHistory?: boolean;
}

interface WorkflowDialogState {
    workflow?: IViewWfWorkflowRecord;
    station?: IStationRecord;
    transitions: IViewTransitionTypeRecordWithPerm[];
    publicationInfo?: WfPublicationInfo;
    stationPermissions?: SecStationPermissions;
    advancedDialogOpen: boolean;
    workflowDialogOpen: boolean;
    historyDialogOpen: boolean;
    loading: boolean;
    message: string;
}

export class WorkflowDialog extends React.Component<WorkflowDialogProps, WorkflowDialogState> {

    private wfApi: WfAPI;

    state: WorkflowDialogState = {
        transitions: [],
        advancedDialogOpen: false,
        workflowDialogOpen: false,
        historyDialogOpen: false,
        loading: true,
        message: ""
    }

    componentDidMount() {
        this.wfApi = new WfAPI(obtainServer());
        if (this.props.open) {
            this.reloadAsync();
        }

    }

    componentDidUpdate(prevProps: WorkflowDialogProps) {
        if (this.props.open && (this.props.recId !== prevProps.recId || this.props.open !== prevProps.open)) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {
        try {
            const heads = await ViewWfHeadTable.list({
                filter: {
                    table_id: this.props.tableInfoId,
                    is_active: true,
                }
            });
            const workflows = await ViewWfWorkflow.list({
                filter: { head_table_id: heads.filter(h => h.id).map(h => h.id!), rec_id: this.props.recId }
            });

            if (workflows.length === 1) {
                const workflow = workflows[0];

                const station = (await StationCrud.load(workflow.station_id!)).record;
                const transitions = await this.wfApi.listPossibleTransitions(workflow.id!);
                const publicationInfo = await this.wfApi.getPublicationInfo(workflow.id!);
                const stationPermissions = await this.wfApi.getStationPermissions(workflow.wf_type_id!);

                this.setState({
                    workflow,
                    station,
                    transitions,
                    publicationInfo,
                    stationPermissions
                })

            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }

        this.setState({
            loading: false
        })
    }

    private async onTransition(transition: IViewTransitionTypeRecordWithPerm) {
        if (!transition.has_perm) {
            await alertDialog(__("Nincs joga elvégezni ezt az átmenetet"));
            return;
        }
        if (!await confirmDialog(__("Megerősítés"), __(`Biztos, hogy átküldi {transition_station_name} állapotba?`, { transition_station_name: transition.dst_station_name && transition.dst_station_name!.toLowerCase() }))) {
            return;
        }

        if (this.props.onBeforeTransition) {
            if (!this.props.onBeforeTransition(transition)) return;
        }

        try {
            await this.wfApi.makeTransition(
                this.state.workflow!.id!,
                transition.id!,
                this.state.message || undefined
            );

            const isAdmin = me && hasGroup(me, Groups.Admin);

            if (this.props.autoPublishOnClosedStation
                && this.state.publicationInfo
                && this.state.publicationInfo.publication_supported
                && transition.dst_is_closed
                && !transition.dst_is_deleted
                && transition.dst_is_eligible
                && this.state.stationPermissions
                && this.state.workflow
                && (isAdmin || (this.state.stationPermissions[this.state.workflow.station_id!] && this.state.stationPermissions[this.state.workflow.station_id!].can_publish))) {

                await this.wfApi.publish(this.state.workflow!.id!);
            }

            app.showSuccess(this.props.displayName + " " + __("állapotváltozás sikeres."), "");

            this.props.onClose();

            this.props.onTransition(transition);

            this.setState({
                message: ""
            })

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }

        await this.reloadAsync();

    }

    async onPublish() {
        if (!await confirmDialog(__("Megerősítés"), __(`Biztos publikálja?`))) {
            return;
        }

        try {
            await this.wfApi.publish(this.state.workflow!.id!);
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
        await this.reloadAsync();
    }

    async onUnpublish() {
        if (!await confirmDialog(__("Megerősítés"), __(`Biztos, hogy törli a publikált verziót (a szerkesztés alatti állapot megmarad)?`))) {
            return;
        }

        try {
            await this.wfApi.unpublish(this.state.workflow!.id!);
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
        await this.reloadAsync();
    }

    onClose() {
        this.setState({ advancedDialogOpen: false });
        this.props.onClose();
    }

    render() {
        if (!this.props.open) return null;

        if (!this.state.workflow || !this.state.stationPermissions || !this.state.publicationInfo || !this.state.station) {
            return null;
        }

        const isAdmin = me && hasGroup(me, Groups.Admin);
        const possibleTransitions = this.state.transitions.filter(t => (t.has_perm));

        return <>
            <Dialog title={this.props.displayName + " " + __("állapot változtatása")} open={this.props.open} onClose={this.onClose.bind(this)} width={800}>
                <DialogContent>
                    <p>{__("Jelenlegi állapot:")} <strong>{this.state.workflow.station_name}</strong></p>
                    {
                        this.state.publicationInfo && this.state.publicationInfo.is_published &&
                        <span className="label success" style={{ marginBottom: "1em" }}>{__("Ez a tartalom publikálva van.")}</span>
                    }
                    {
                        this.state.transitions.length > 0 && possibleTransitions.length > 0 &&
                        <label>
                            {__("Állapotváltoztatás indoklása:")}
                            <textarea rows={4} value={this.state.message} onChange={(e) => this.setState({ message: e.currentTarget.value })} />
                        </label>
                    }

                    <ButtonGroup size={ButtonSize.Small} align={ButtonAlign.Right} >
                        { (this.props.showHistory===undefined || this.props.showHistory) ? 
                            <button className="button small float-right" onClick={() => this.setState({ historyDialogOpen: true })} >
                                <i className="fas fa-history" />&nbsp;{__("Előzmény...")}
                            </button>
                            : null
                        }

                        <button className="button small float-right" onClick={() => this.setState({ workflowDialogOpen: true })} >
                            <i className="fa fa-project-diagram" />&nbsp;{__("Folyamat...")}
                        </button>

                        <button className="button small float-right" onClick={() => this.setState({ advancedDialogOpen: !this.state.advancedDialogOpen })} >
                            <i className="fa fa-bars" />&nbsp;{__("Részletek...")}
                        </button>
                    </ButtonGroup>

                    {!this.props.showHistory ? "" :
                        <WfPlugin
                            tableInfoId={this.props.tableInfoId}
                            recId={this.props.recId}
                            afterTransition={this.reloadAsync.bind(this)}
                            displayMode={DisplayMode.History}
                        />
                    }

                    <Toggle key={"toggle-advanced"} isOpen={this.state.advancedDialogOpen} >
                        <WfPlugin
                            tableInfoId={this.props.tableInfoId}
                            recId={this.props.recId}
                            afterTransition={this.reloadAsync.bind(this)}
                            displayMode={DisplayMode.Details}
                        />
                    </Toggle>

                </DialogContent>
                <DialogActions>
                    {
                        possibleTransitions.filter(t => (t.must_justify)).map(t =>
                            <button title={__("Visszaküldés")} key={t.id} onClick={this.onTransition.bind(this, t)} className="button" style={t.dst_station_style}>
                                <i className="fa fa-backward" />&nbsp; {t.description || t.dst_station_name}
                            </button>
                        )
                    }

                    <div style={{ flex: 1 }}></div>

                    {

                        possibleTransitions.filter(t => (!t.must_justify)).map(t =>
                            <button title={__("Továbbküldés")} key={t.id} onClick={this.onTransition.bind(this, t)} className="button" style={t.dst_station_style}>
                                <i className="fa fa-forward" />&nbsp; {t.description || t.dst_station_name}
                            </button>
                        )
                    }

                    {
                        this.state.publicationInfo.publication_supported && this.state.station.is_eligible && this.state.station.is_closed && !this.state.station.is_deleted && (isAdmin || (this.state.stationPermissions[this.state.workflow.station_id!] && this.state.stationPermissions[this.state.workflow.station_id!].can_publish))
                            ?
                            this.state.publicationInfo.is_published
                                ?
                                <button title={__("Publikált verzió törlése")} className="button alert" onClick={this.onUnpublish.bind(this)}>
                                    <i className="fa fa-eye-slash" />&nbsp; {__("Publikált verzió törlése")}
                                </button>
                                :
                                <button title={__("Publikálás")} className="button success" onClick={this.onPublish.bind(this)}>
                                    <i className="fa fa-eye" />&nbsp;{__("Publikálás")}
                                </button>
                            :
                            null
                    }
                </DialogActions>
            </Dialog>
            <Dialog title={__("Folyamat")} width={1000} height={800} open={this.state.workflowDialogOpen} onClose={() => this.setState({ workflowDialogOpen: false })}>
                <WfPlugin
                    tableInfoId={this.props.tableInfoId}
                    recId={this.props.recId}
                    afterTransition={this.reloadAsync.bind(this)}
                    displayMode={DisplayMode.WorkflowChart}
                />

            </Dialog>

            <Dialog title={__("Folyamat")} width={1000} height={800} open={this.state.historyDialogOpen} onClose={() => this.setState({ historyDialogOpen: false })}>
                <WfPlugin
                    tableInfoId={this.props.tableInfoId}
                    recId={this.props.recId}
                    afterTransition={this.reloadAsync.bind(this)}
                    displayMode={DisplayMode.History}
                />
            </Dialog>


        </>;
    }

}
