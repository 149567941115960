import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewCollectionItemRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Sorrend
     */
    no?: number /*int4*/|null;
    /**
     * Gyűjtemény
     */
    collection_id?: number;
    /**
     * Amit kedvel - Táblázat infó
     *
     * Ez mondja meg, hogy a gyűjtemény elem melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number;
    /**
     * Amit kedvel - rekord azonosító
     *
     * Ez mondja meg, hogy a gyűjtemény elem melyik rekordhoz tartozik.
     */
    rec_id?: number;
    /**
     * Gyűjtemény név
     *
     * Gyűjtemény név
     */
    collection_title?: string|null;
    /**
     * Tulajdonos
     *
     * Tulajdonos
     */
    owner_id?: number|null;
    /**
     * Tartalom (táblázat név)
     *
     * Tartalom (táblázat név)
     */
    table_display_name?: string|null;
}

/**
 *  usr.view_collection_item - Gyűjtemény elem
 *
 *  Ez egy heterogén gyűjtő, amibe több különböző típusú tartalmat lehet adott sorrendben belepakolni.
 */

export default class ViewCollectionItem extends View<IViewCollectionItemRecord> {
    public static VIEW_INFO_ID = 2019013101;

    public static getSchemaNameForClass(): string { return 'usr'; }
    public static getViewNameForClass(): string { return 'view_collection_item'; }
    public static getTableNameForClass(): string|null { return 'collection_item'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewCollectionItem>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewCollectionItemRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewCollectionItemClassProxy extends ViewClassProxy<IViewCollectionItemRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewCollectionItemClassProxy = new ViewCollectionItemClassProxy(ViewCollectionItem);
registerViewClassProxy(viewCollectionItemClassProxy);

