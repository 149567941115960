import * as React from 'react';

import { app } from '@src/index';
import ViewSharedByMe, { IViewSharedByMeRecord } from '@framework/view/usr/ViewSharedByMe';
import { SHARE_MEGOSZTAS_ID } from '@src/Const';
import { ListItemImage, List, ListItem, ListItemText, ListItemMenu } from '../ui/List';
import { Link } from 'react-router-dom';
import { formatDate } from '@src/Util';
import { getContentDetailsList, ContentDetails } from './ContentUtils';
import { BubbleLoader } from 'react-css-loaders';
import { PATH_SHARED_CONTENT_BY_ME } from '@src/Routes';
import UsrShareDialog from '../usr/UsrShareDialog';
import { Dialog, confirmDialog } from '../Dialog';
import SharedContentCrud from '@src/framework/crud/usr/SharedContentCrud';
import { __ } from '@src/translation';

interface ISharedByMePageProps {

}

interface ISharedByMePageState {
    loading: boolean;
    items: IViewSharedByMeRecord[];
    details: (ContentDetails | undefined)[];
    shareDialogOpen: boolean;
    selectedTableInfoId?: number;
    selectedRecordId?: number;
    selectedSharedContentId?: number | null;
}

export default class SharedByMePage extends React.Component<ISharedByMePageProps, ISharedByMePageState> {
    constructor(props: ISharedByMePageProps) {
        super(props);
        this.state = { items: [], details: [], loading: true, shareDialogOpen: false };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {
        this.setState({ loading: true });
        try {
            const items = await ViewSharedByMe.list({ filter: { is_active: true }, limit: 100, order_by: [{ name: "creation_time", desc: true }] });

            const details = await getContentDetailsList(items.map(i => ({ tableOrViewId: i.table_info_id!, recordId: i.rec_id! })));

            this.setState({ items, details, loading: false });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    async onDelete(sharedContentId: number) {
        if (!await confirmDialog(__("Törlés"),
            __("Ez a művelet törli a kiválasztott megosztást. A törlés után a tartalom elérhetetlen lesz a megosztáson keresztül. A művelet nem vonható vissza. Biztos benne, hogy törölni akarja?"),
            __("Törlés"))) {
            return;
        }

        try {
            await SharedContentCrud.deleteById(sharedContentId);
            this.reloadAsync();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        }

        return <>
            {
                this.state.shareDialogOpen &&
                <Dialog title={__("Megosztás")} onClose={() => this.setState({ shareDialogOpen: false })} width={1000} height={700}>
                    <UsrShareDialog
                        sharedContentId={this.state.selectedSharedContentId}
                        tableInfoId={this.state.selectedTableInfoId}
                        recordId={this.state.selectedRecordId}
                        fileName=""
                        onCancel={() => this.setState({ shareDialogOpen: false })}
                        onCreated={this.reloadAsync.bind(this)}
                        onSaved={() => this.setState({ shareDialogOpen: false }, this.reloadAsync.bind(this))}
                        onSharedContentDeleted={() => this.setState({ shareDialogOpen: false }, this.reloadAsync.bind(this))}
                        onSharedContentSelected={(selectedSharedContentId) => this.setState({ selectedSharedContentId: selectedSharedContentId })}
                    />
                </Dialog>
            }
            <h4>{__("Általam megosztott tartalmak")}:</h4>
            {
                this.state.items.length === 0 &&
                <div className="callout">
                    <h5>{__("Jelenleg nincsenek általad megosztott tartalmak.")}</h5>
                    <p>
                        {__("Megosztani a saját feladat / saját feladatsor / saját fájl listákban lehet a megosztás")} (<i className="fa fa-share-alt" />) {__("gombra kattintva.")}
                    </p>
                </div>
            }
            <List>
                {
                    this.state.items.map((item, index) => {

                        var url = (item.share_mode_id == SHARE_MEGOSZTAS_ID) ? (this.state.details[index] && this.state.details[index]!.viewUrl) : PATH_SHARED_CONTENT_BY_ME + "/" + item.id;
                        var title = "" + ((this.state.details[index] && this.state.details[index]!.type ? (this.state.details[index]!.type == "Nyílt végű feladat" ? "Szöveges feladat " : (this.state.details[index]!.type + " ")) : "") + (this.state.details[index] && this.state.details[index]!.type ? ("(" + item.share_mode_title!.toLowerCase() + ")") : item.share_mode_title!) + ": ");
                        var exeTitle = item.title;
                        return <ListItem key={item.id}>
                            <ListItemImage src={this.state.details[index] && this.state.details[index]!.thumbnailUrl} />

                            <ListItemText>
                                <Link to={url || ""} title={title + item.title}>
                                    {title}<strong>{exeTitle}</strong>
                                </Link>
                                <div>{this.state.details[index] && this.state.details[index]!.displayName}</div>
                            </ListItemText>

                            <ListItemText>
                                <label className="label secondary" title={__("Kiosztás ideje")}>
                                    {__("Megosztás ideje")}: {formatDate(item.start_date)} {item.end_date && " - " + formatDate(item.end_date)}
                                </label>
                                <br />
                                {item.due_date && <label className="label warning">{__("Leadás határideje")}: {formatDate(item.due_date)}</label>}
                            </ListItemText>

                            <ListItemMenu
                                mainItems={[
                                    {
                                        icon: <i className="fa fa-fw fa-share-alt" />,
                                        type: "primary",
                                        onClick: () => this.setState({
                                            shareDialogOpen: true,
                                            selectedTableInfoId: item.table_info_id,
                                            selectedRecordId: item.rec_id,
                                            selectedSharedContentId: item.id,
                                        })
                                    },
                                    {
                                        icon: <i className="fa fa-fw fa-trash" />,
                                        type: "alert",
                                        onClick: this.onDelete.bind(this, item.id)
                                    }
                                ]}
                            />

                        </ListItem>
                    })
                }
            </List>
        </>

    }


}

