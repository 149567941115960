import * as React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


import { IInstituteSiteRecord, InstituteSiteCrudClassProxy, instituteSiteCrudClassProxy } from '@src/framework/crud/sys/InstituteSiteCrud';
import RecordEditor from '@framework/forms/recordeditor';
import InplaceListEditor from '@src/framework/forms/inplace_listeditor';
import InstituteGroupEditor from './sys_institute_group_editor';
import { InstituteGroupListViewInner } from './sys_institute_group_listview';
import InstituteSiteAdminEditor from './sys_institute_site_admin_editor';
import { InstituteSiteAdminListViewInner } from './sys_institute_site_admin_listview';
import { __ } from '@src/translation';

import '@src/framework/crud/sys/CityCrud';
import { InstituteSiteContactListViewInner } from './sys_institute_site_contact_listview';
import InstituteSiteContactEditor from './sys_institute_site_contact_editor';

export default class InstituteSiteEditor extends RecordEditor<IInstituteSiteRecord> {

    public getCrudClassProxy(): InstituteSiteCrudClassProxy { return instituteSiteCrudClassProxy; }


    private onGetInstituteGroupListView = (inplaceListEditor: InplaceListEditor) => {
        return <InstituteGroupListViewInner
            inplaceListEditor={inplaceListEditor}
            match={this.props.match}
            filter={{ institute_site_id: this.state.rec_id! }}
            viewColumns={[           
                { accessor: "name" },
                { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
            ]}
        />
    }

    private onGetInstituteGroupEditor = (inplaceListEditor: InplaceListEditor, record_id: number | null) => {
        return <InstituteGroupEditor
            match={this.props.match}
            record_id={record_id}
            defaultValues={{ institute_site_id: this.state.rec_id! }}
            inplaceListEditor={inplaceListEditor}
        />;
    }


    private onGetInstituteSiteAdminListView = (inplaceListEditor: InplaceListEditor) => {
        return <InstituteSiteAdminListViewInner
            inplaceListEditor={inplaceListEditor}
            match={this.props.match}
            filter={{ institute_site_id: this.state.rec_id! }}
            viewColumns={[          
                { accessor: "admin_displayname" },            
                { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
            ]}
        />
    }

    private onGetInstituteSiteAdminEditor = (inplaceListEditor: InplaceListEditor, record_id: number | null) => {
        return <InstituteSiteAdminEditor
            record_id={record_id}
            defaultValues={{ institute_site_id: this.state.rec_id! }}
            inplaceListEditor={inplaceListEditor}
        />;
    }

    
    private onGetInstituteSiteContactListView = (inplaceListEditor: InplaceListEditor) => {
        return <InstituteSiteContactListViewInner
            inplaceListEditor={inplaceListEditor}
            match={this.props.match}
            filter={{ institute_site_id: this.state.rec_id! }}
            viewColumns={[          
                { accessor: "fullname" },            
                { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
            ]}
        />
    }

    private onGetInstituteSiteContactEditor = (inplaceListEditor: InplaceListEditor, record_id: number | null) => {
        return <InstituteSiteContactEditor
            record_id={record_id}
            defaultValues={{ institute_site_id: this.state.rec_id! }}
            inplaceListEditor={inplaceListEditor}
        />;
    }

    protected getDetailPanels(): any[] {
        if (this.state.rec) {
            return [
                <Tabs key="tabs">
                    <TabList>
                        <Tab>{__("Intézményi csoportok (osztályok)")}</Tab>
                        <Tab>{__("Intézményi adminisztrátorok")}</Tab>
                        <Tab>{__("Intézményi kapcsolattartók")}</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="row">
                            <div className="column">
                                <InplaceListEditor
                                    onGetListView={this.onGetInstituteGroupListView}
                                    onGetRecordEditor={this.onGetInstituteGroupEditor}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="row">
                            <div className="column">
                                <InplaceListEditor
                                    onGetListView={this.onGetInstituteSiteAdminListView}
                                    onGetRecordEditor={this.onGetInstituteSiteAdminEditor}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="row">
                            <div className="column">
                                <InplaceListEditor
                                    onGetListView={this.onGetInstituteSiteContactListView}
                                    onGetRecordEditor={this.onGetInstituteSiteContactEditor}
                                />
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            ];
        } else {
            return [
                <div className="row">
                    <div className="column">
                        <div className="callout warning">
                            <b>{__("Intézményi csoportok és adminisztrátorok:")}</b>
                            {__("A részlet adatok megadása előtt rögzítse le a fő tételt!")}
                        </div>
                    </div>
                </div>
            ];
        }
    }
}