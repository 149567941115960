import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IItemRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Tudásbázis bejegyzés típus
     *
     * NOT NULL -> kb.item_type ON DELETE noaction
     *
     */
    type_id?: number;
    /**
     * Tárgy
     */
    subject?: string;
    /**
     * Kulcsszavak
     */
    keywords?: string;
    /**
     * Leírás
     */
    description?: string;
    /**
     * Tároló
     *
     * -> media.oo_folder ON DELETE noaction
     *
     *
     * Az a mappa, ami a bejegyzéshez tartozó médiákat tárolja.
     */
    oo_folder_id?: number|null;
}

/**
 *  kb.item - Tudásbázis bejegyzés
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ItemCrud extends Crud<IItemRecord> {
    public static TABLE_INFO_ID = 290390615;
    public static getSchemaNameForClass() { return 'kb'; }
    public static getTableNameForClass() { return 'item'; }

    public static load: (id: number, aServer ?: Server) => Promise<ItemCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IItemRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IItemRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IItemRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IItemRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IItemRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ItemCrudClassProxy extends CrudClassProxy<IItemRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const itemCrudClassProxy = new ItemCrudClassProxy(ItemCrud);
registerCrudClassProxy(itemCrudClassProxy);
