import * as React from 'react';

import { Dialog } from '@src/component/Dialog';
import ExerciseSeriesList from '@src/component/exercise/ExerciseSeries/ExerciseSeriesList';
import { Section } from '@src/server/EditorServer';
import LessonEditor from './LessonEditor';
import { app } from '@src/index';
import * as ExerciseContainerComponent from '@src/component/exercise/ExerciseContainerComponent';
import { ExerciseServer } from '@src/component/exercise/ExerciseServer';
import ExerciseSeriesView, { IViewExcSeriesRecord } from '@src/framework/view/exc/ViewExcSeries';
import { IExerciseSeriesRecord } from '@src/framework/crud/exc/ExerciseSeriesCrud';
import { PATH_EXERCISE_SERIES_EDIT, PATH_EXERCISE_SERIES_VIEW } from '@src/Routes';
import { Link } from 'react-router-dom';
import { SectionOptionsEditor } from '@src/component/book/editor/SectionOptionsEditor';
import { __ } from '@src/translation';

type SectionExerciseEditorProps = {
  lessonEditor: LessonEditor;
  templateVariableId: string;
  section: Section;
  gradeId?: number;
  subjectId?: number;
}

type SectionExerciseEditorState = {
  dialogOpen: boolean;
  exerciseSeriesRecord?: IViewExcSeriesRecord;
}

type SectionExerciseSeriesEditorConfig = {
  disableExerciseSeriesFilter?: boolean
}

let pageConfig: SectionExerciseSeriesEditorConfig = {};

export function configureSectionExerciseSeriesEditor(newConfig: SectionExerciseSeriesEditorConfig) {
  pageConfig = newConfig;
}

export default class SectionExerciseEditor extends React.Component<SectionExerciseEditorProps, SectionExerciseEditorState> {

    constructor(props: SectionExerciseEditorProps) {
      super(props);
  
      this.state = {
        dialogOpen: false
      };

    }

    componentDidMount() {
      this.reloadAsync();
    }

    componentDidUpdate() {
      this.reloadAsync();
    }

    async reloadAsync() {
      const exerciseSeriesId = this.props.section.values[this.props.templateVariableId];

      if (exerciseSeriesId && (!this.state.exerciseSeriesRecord || this.state.exerciseSeriesRecord.id != exerciseSeriesId)) {
        
        const exerciseSeriesRecord = (await ExerciseSeriesView.load(exerciseSeriesId)).record;

        this.setState({exerciseSeriesRecord});
      }
    }

    onOpenExerciseDialog() {
      this.setState({dialogOpen: !this.state.dialogOpen});
    }
  
    onExerciseSeriesSelected(exerciseSeriesRecord: IExerciseSeriesRecord) {
      
      if (exerciseSeriesRecord.id) {
        this.props.lessonEditor.handleEditorChange(this.props.section.id, this.props.templateVariableId, exerciseSeriesRecord.id.toString(), null, exerciseSeriesRecord.id);
      } else {
        app.showError(__("Hiba!"), __("A rekordnak nincs azonosítója!"));
        return;
      }

      this.setState({dialogOpen: false}, this.reloadAsync);
    }

    render() {
      const exerciseSeriesId = Number(this.props.section.values[this.props.templateVariableId]);

      const server = new ExerciseServer(false);
      server.setExerciseSeriesId(exerciseSeriesId);

      const exerciseSeries = this.state.exerciseSeriesRecord;

      return <div className="row">
          <div className="column" >
            <button className="button expanded" onClick={this.onOpenExerciseDialog.bind(this)}><i className="fa fa-check-square-o"/> {__("Feladatsor kiválasztása")}</button>
            { this.state.dialogOpen ?
              <Dialog title={__("Feladatsor kiválasztása")} onClose={this.onOpenExerciseDialog.bind(this)}>
                <ExerciseSeriesList 
                  onExerciseSeriesSelected={this.onExerciseSeriesSelected.bind(this)} 
                  grade_id={ pageConfig.disableExerciseSeriesFilter ? undefined : this.props.gradeId} 
                  subject_id={ pageConfig.disableExerciseSeriesFilter ? undefined : this.props.subjectId }
                />
              </Dialog>
              : ""
            }
            {
              exerciseSeriesId
              ? 
                <div>
                  {
                    exerciseSeries
                    ?
                    <div>
                      <span> #{exerciseSeries.id} 
                          &nbsp;
                          <Link title={__("Feladatsor szerkesztő megnyitása (szerkesztőségi verzió)")} to={PATH_EXERCISE_SERIES_EDIT + "/" + exerciseSeries.id}><i className="fa fa-edit"/></Link> 
                          &nbsp;
                          <Link title={__("Feladatsor megnyitása (szerkesztőségi verzió)")} to={PATH_EXERCISE_SERIES_VIEW + "/" + exerciseSeries.id}><i className="fa fa-eye"/></Link> 
                          &nbsp;
                      
                      </span>


                      
                      / <span> {exerciseSeries.title} </span>
                      / <span> {exerciseSeries.grade_name} </span>
                      / <span> {exerciseSeries.subject_name} </span>
                      / <span> {exerciseSeries.owner_person_fullname} </span>
                      / <span> {exerciseSeries.creation_time} </span>
                    </div>
                    :
                    ""
                  }
                  <ExerciseContainerComponent.default ref="container" key={exerciseSeriesId} server={server} />
                </div>
              :
              <div className="callout alert">{__("Kérem válasszon feladatsort")}</div>
            }
          </div>

          <SectionOptionsEditor
            lessonEditor={this.props.lessonEditor}
            section={this.props.section}
            templateVariableId={this.props.templateVariableId + "-options"}
            enableIcons={false}
            enableAspectRatio={false}
            enableExerciseCheckboxes={true}
          />

        </div>
    }
}

