import * as React from 'react';
import { Link } from 'react-router-dom';
import { PATH_PUBLISHED_BOOK_SELECT, PATH_PUBLISHED_SEARCH, PATH_PUBLISHED_MEDIA, PATH_EXERCISE_PUB_LIST, PATH_DL_MATERIAL_VIEW, PATH_PUBLISHED_BOOK, PATH_COURSE_ORGANIZER_SURVEY } from '@src/Routes';
import AutoCompleteEdit from '@src/framework/forms/autocompleteedit';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import * as PublicServer from '@src/server/PublicServer';
import { config } from '@framework/server/Server';
import './MainComponent.css';
import { History } from 'history';
import OwlCarousel from 'react-owl-carousel';
import { BookSelector } from '@src/component/book/viewer/BookSelector';
import ViewDlMaterial, { IViewDlMaterialRecord } from '@src/framework/view/doc_pub/ViewDlMaterial';
import { __ } from '@src/translation';
import { hasAnyGroup, hasGroup, Groups, me } from '@src/framework/server/Auth';

type MainComponentProps = {
    history: History
}

type MainComponentState = {
    keywords: string;
    playVideo: boolean;
    mainStats?: PublicServer.MainPageStats;
}

export default class MainComponent extends React.Component<MainComponentProps, MainComponentState> {

    constructor(props: any) {
        super(props);

        this.state = { keywords: "", playVideo: false };
    }

    componentDidMount() {
        ($(this.refs.mainRoot) as any).foundation();

    }
    componentDidUpdate() {
        ($(this.refs.mainRoot) as any).foundation();
    }

    private onSearchItemSelected = (selectedItem: string) => {
        this.setState({ keywords: selectedItem || "" }, this.setCurrentSearch);
    }
    private onKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.setCurrentSearch();
        }
    }
    setCurrentSearch() {
        this.props.history.push(PATH_PUBLISHED_SEARCH + "/" + encodeURIComponent(this.state.keywords));
    }

    async reloadAsync() {
        let landIngPageStats = await PublicServer.getMainPageStats();

        this.setState({
            mainStats: landIngPageStats
        });
    }

    private onValueChange = (value: string) => {
        this.setState({ keywords: value });
    }


    private startVideo() {

        this.setState({ playVideo: true });
    }

    render() {
        let autocomplete;
        autocomplete = <AutoCompleteEdit placeholder={__("Keresés a portálon")} value={this.state.keywords} onValueChange={this.onValueChange} onItemSelected={this.onSearchItemSelected} onKeyPress={this.onKeyPress.bind(this)} />

        return <div ref="mainRoot">
            <section className="main main-landing">
                <span className="show-for-sr">{__("Felolvasó szoftver használata esetén a következő alkalmazáskombinációk ajánlottak:")}</span>

                <span className="show-for-sr">{__("Mozilla Firefox böngészőhöz JAWS felolvasószoftver")}</span>
                <span className="show-for-sr">{__("Google Chrome böngészőhöz NVDA felolvasószoftver")}</span>

                <div className="text-center">

                    {config.mainServer.mainPageShowEditorLink
                        ?
                        <div style={{ color: "red" }}>
                            <h4>{__("Fejlesztői gép - tesztelésre, teszt adatokkal")}</h4>
                            <h4>{__("A szerkesztőségi gép itt található:")} <a href="https://okostankonyv.ofi.nkp.uni-eszterhazy.hu">https://okostankonyv.ofi.nkp.uni-eszterhazy.hu</a></h4>
                        </div>
                        :
                        ""
                    }

                    <h3>{__("Üdvözöljük a Nemzeti Köznevelési Portálon!")}</h3>
                    <h3 style={{width: "40em", margin: "auto"}}>Tisztelt Felhasználó! Felhívjuk figyelmét, hogy ezen az oldalon a 2012-es Nemzeti alaptantervhez készült okostankönyvek találhatók. Itt az NKP további szolgáltatásai nem érhetőek el.</h3>
                </div>

                <div className="column small-12 medium-12">
                    <div className="search">

                        <div className="row align-center">
                            <div className="column hide-for-small-only medium-3 large-2">
                                <img src="/img/LANDING/NKP_logo_shadow.svg" />
                            </div>
                        </div>

                        <div className="row align-center">

                            <div className="column small-12 large-10">

                                <div className="input-group eke-search-field main-search">

                                    <div className="eke-search__input">
                                        {autocomplete}
                                    </div>
                                    <div className="input-group-button">
                                        <div className="button-group eke-search__button">
                                            <button className="button primary" type="button" onClick={this.setCurrentSearch.bind(this)} >
                                                <i className="fa fa-search"></i><label className="show-for-sr">{__("Keresés")}</label>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    config.mainServer.showBooks 
                    ?

                    <div className="row">

                        <div className="column small-12 medium-12">

                            <BookSelector />

                        </div>
                    </div>

                    :
                    null
                }

                

                <a href="#video" className="lead view-video" data-smooth-scroll="" data-offset="45">
                    <h3>{__("Ha még nem ismeri az összes portálfunkciót, fussa át az alábbi ismertetőt!")}</h3>
                    <i className="fas fa-chevron-down"></i>
                </a>

                <div className={"slide1" + (config.mainServer.showBooks ? " show-books" : "")}></div>
            </section>

            <section className="main main-video" id="video" data-magellan-target="video" >
                <div className="row collapse align-center">
                    <div className="column">

                        <div ref="promoVideoContainer" className="flex-video widescreen">

                            {
                                this.state.playVideo
                                    ?
                                    <iframe src="https://player.vimeo.com/video/295976929?title=0&byline=0&portrait=0&autoplay=1" width="640" height="480" frameBorder="0" allowFullScreen></iframe>
                                    :
                                    <a href="javascript:void(0)" onClick={this.startVideo.bind(this)} className="video-link">
                                        <img src="img/LANDING/video-center.jpg" alt="" className="" />
                                        <i className="icons icons-play-orange-o"></i>
                                        <span className="show-for-sr">{__("Kattints ide a lejátszáshoz!")}</span>
                                    </a>
                            }

                        </div>
                    </div>
                </div>
            </section>

            {this.state.mainStats &&
                <section className="main main-numbers">

                    <div className="row">
                        <div className="column small-6 medium-6 large-3 stat-block">
                            <div className="row collapse">
                                <div className="column shrink">
                                    <img src="img/LANDING/01_felhasznalok.png" alt="" />
                                </div>
                                <div className="column">
                                    <strong className="counter">{this.state.mainStats.userCount}</strong>
                                    {__("felhasználó")}
                                    </div>
                            </div>
                        </div>

                        <div className="column small-6 medium-6 large-3 stat-block">
                            <div className="row collapse">
                                <div className="column shrink">
                                    <img src="img/LANDING/02_okosfeladat.png" alt="" />
                                </div>
                                <div className="column">
                                    <strong className="counter">{this.state.mainStats.exerciseCount}</strong>
                                    {__("okosfeladat")}
                                    </div>
                            </div>
                        </div>

                        <div className="column small-6 medium-6 large-3 stat-block">
                            <div className="row collapse">
                                <div className="column shrink">
                                    <img src="img/LANDING/03_3D_animacio.png" alt="" />
                                </div>
                                <div className="column">
                                    <strong className="counter">{this.state.mainStats.animationCount}</strong>
                                    {__("3D animáció")}
                                    </div>
                            </div>
                        </div>

                        <div className="column small-6 medium-6 large-3 stat-block">
                            <div className="row collapse">
                                <div className="column shrink">
                                    <img src="img/LANDING/04_video.png" alt="" />
                                </div>
                                <div className="column">
                                    <strong className="counter">{this.state.mainStats.videoCount}</strong>
                                    {__("")}Videó
                                    </div>
                            </div>
                        </div>


                    </div>

                </section>
            }

            {
                config.mainServer.showBooks
                ?
                <section className="main main-books">
                    <div className="row">
                        <div className="column small-12 medium-6">
                            <div className="row align-middle head">
                                <div className="column shrink">
                                    <div className="icon-tile">
                                        {/* <i className="icons icons-smartbook"></i> */}
                                    </div>
                                </div>
                                <div className="column title">
                                    <h2>{__("Az okostankönyvek")}</h2>
                                </div>
                            </div>
                            <div className="row collapse">
                                <div className="column">
                                    <p>
                                        {__("Az okostankönyvek a weben megszokott élményt kínálják tanárnak és diáknak egyaránt. Ezek a tankönyvek nem pusztán a papírtankönyvek digitalizált változatai, hanem sok ezer kiegészítő digitális taneszközt tartalmaznak: videókat, képeket, hanganyagokat és interaktív feladatokat.")}
                                    </p>
                                    <p>{__("Soha nem tapasztalt lehetőségeket biztosítanak a tanárok számára: interaktív feladatokat készíthetnek a diákjaiknak, és megoszthatják azokat velük, sőt, ha regisztrálnak, igényeik szerint alakíthatják át a tankönyveket.")}</p>
                                    <Link className="button" to={PATH_PUBLISHED_BOOK_SELECT}>{__("Tovább")}</Link>
                                </div>
                            </div>
                        </div>
                        <div className="column small-12 medium-6">
                            <img src="img/LANDING/04_jobb_kep.png" alt="" className="" />
                        </div>
                    </div>
                </section>

                : <section></section>
            }

            
            <section className="main main-media">
                <div className="row align-middle">
                    <div className="column small-12 medium-6">
                        <div className="row align-middle head">
                            <div className="column shrink">
                                <div className="icon-tile">
                                    {/* <i className="icons icons-task-calendar"></i> */}
                                </div>
                            </div>
                            <div className="column title">
                                <h2>{__("A médiatár")}</h2>
                            </div>
                        </div>
                        <div className="row collapse">
                            <div className="column">
                                <p>
                                    {__("A weben mindannyian sokféle médiummal találkozunk, és nem pusztán szövegekből, hanem képekből, filmekből és animációkból is tájékozódunk a világ dolgairól. Ezért mi is szükségét éreztük, hogy az okostankönyveket más médiumokkal is gazdagítsuk.")}
                                </p>
                                <p>{__("A médiatár a portál tartalmát e médiumok szerinti bontásban tartalmazza. Egy-egy téma kapcsán rákereshetünk külön a videókra, a térképekre, a képekre és a hanganyagokra.")}</p>

                                <Link className="button" to={PATH_PUBLISHED_MEDIA}>{__("Tovább")}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="column small-12 medium-6">
                        <img src="img/LANDING/05_jobb_kep.png" alt="" />
                    </div>
                </div>
            </section>

            <section className="main main-exercise">
                <div className="row align-middle">
                    <div className="column small-12 medium-6">
                        <div className="row align-middle head">
                            <div className="column shrink">
                                <div className="icon-tile">
                                    {/* <i className="icons icons-task-calendar"></i> */}
                                </div>
                            </div>
                            <div className="column title">
                                <h2>{__("A feladattár")}</h2>
                            </div>
                        </div>
                        <div className="row collapse">
                            <div className="column">
                                <p>
                                    {__("Aktív tanulás, differenciálás, egyéni munka – az új portál ebben is segíteni kíván. Ezekre a pedagógiai kihívásokra elsősorban az interaktív feladatok, illetve feladatsorok jelentik a választ.")}
                                           </p>
                                <p>{__("A feladattár az összes interaktív feladatot tartalmazza, amelyek az okostankönyvekben találhatók, azonkívül azokat is, amelyeket a felhasználók készítettek, és osztottak meg egymással.")}</p>
                                <Link className="button" to={PATH_EXERCISE_PUB_LIST}>{__("Tovább")}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="column small-12 medium-6">
                        <img src="img/LANDING/06_jobb_kep.png" alt="" />
                    </div>
                </div>
            </section>

            <section className="main main-search">
                <div className="row align-middle">
                    <div className="column small-12 medium-6">
                        <div className="row align-middle head">
                            <div className="column shrink">
                                <div className="icon-tile">
                                    {/* <i className="icons icons-search"></i> */}
                                </div>
                            </div>
                            <div className="column title">
                                <h2>{__("A kereső")}</h2>
                            </div>
                        </div>
                        <div className="row collapse">
                            <div className="column">
                                <p>
                                    {__("Az NKP új portálja biztonságos digitális tér a diákok és a tanárok számára. Olyan feladatokat, videókat, animációkat és linkeket tartalmaz, amelyeket folyamatosan ellenőrzünk.")}
                                           </p>
                                <p>
                                    {__("A kereső nem csupán a portálon belüli keresésre képes, hanem – ellenőrzött – külső tartalmakban is, például a MúzeumDigitárban. Csak ebben az adatbázisban 68 magyar közgyűjtemény 205.000 műtárgyának képe és leírása található. A Nemzeti Audiovizuális Archívumban (NAVA) hozzáférhetők a magyarországi közszolgálati csatornák és a legnagyobb lefedettségű kereskedelmi televíziók 2006 óta digitalizált, magyar gyártású műsorai.")}
                                    </p>

                                <Link className="button" to={PATH_PUBLISHED_SEARCH}>{__("Tovább")}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="column small-12 medium-6">
                        <img src="img/LANDING/08_jobb_kep.png" alt="" />
                    </div>
                </div>
            </section>

            <section className="main main-dashboard">
                <div className="row align-middle">
                    <div className="column small-12 medium-6">
                        <div className="row align-middle head">
                            <div className="column shrink">
                                <div className="icon-tile">
                                    {/* <i className="icons icons-dashboard"></i> */}
                                </div>
                            </div>
                            <div className="column title">
                                <h2>{__("A személyre szabható irányítópult") + " "}</h2>
                            </div>
                        </div>
                        <div className="row collapse">
                            <div className="column">
                                <p>
                                    {__("Aki regisztrál, saját profilhoz, tartalomtárhoz jut, nyomon követheti a tennivalóit, közösséget alkothat más, regisztrált felhasználókkal. Mindezt az irányítópulton keresztül intézheti, amelyet a személyes igényeinek megfelelően alakíthat.")}
                                </p>
                                <p>
                                    {__("A regisztrált tanárok pedagógiai céljaikhoz igazítva átszerkeszthetik a tankönyveket, és megoszthatják őket regisztrált diákjaikkal. Mi több, megoszthatják őket egyazon intézményből való, regisztrált kollégáikkal is.")}
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="column small-12 medium-6">
                        <img src="img/LANDING/07_jobb_kep.png" alt="" />
                    </div>
                </div>
            </section>

            {
                config.mainServer.showBooks
                ?
                <section className="main main-good-practice">
                    <div className="row align-middle">
                        <div className="column small-12 medium-6">
                            <div className="row align-middle head">
                                <div className="column shrink">
                                    <div className="icon-tile">
                                        {/* <i className="icons icons-good-exercise"></i> */}
                                    </div>
                                </div>
                                <div className="column title">
                                    <h2>{__("A jó gyakorlatok")}</h2>
                                </div>
                            </div>
                            <div className="row collapse">
                                <div className="column">
                                    <p>
                                        {__("A jó gyakorlatok a pedagógusok által megosztott módszerek, tapasztalatok és jó tanácsok a tanuláshoz és a tanításhoz. A portál olyan felülete, ahol az innovatív pedagógusok tehetik közkinccsé ötleteiket. Tanárok és diákok egyaránt meríthetnek az itt felhalmozott gondolatokból, összegyűlt anyagokból.")}
                                    </p>
                                    <Link to={PATH_PUBLISHED_BOOK + "/jo_gyakorlatok/"} className="button">{__("Tovább")}</Link>
                                </div>
                            </div>
                        </div>
                        <div className="column small-12 medium-6">
                            <img src="img/LANDING/09_jobb_kep.png" alt="" />
                        </div>
                    </div>
                </section>
            :
            null
            }

            {/* <section className="main main-lesson-plan">
                <div className="row align-middle">
                    <div className="column small-12 medium-6">
                        <div className="row align-middle head">
                            <div className="column shrink">
                                <div className="icon-tile">
                                    <i className="icons icons-lesson-plan"></i>
                                </div>
                            </div>
                            <div className="column title">
                                <h2>Óravázlat szerkesztő</h2>
                            </div>
                        </div>
                        <div className="row collapse">
                            <div className="column">
                                <div className="lead">
                                    Regisztrálj te is, <span>és fedezd fel óravázlat szerkesztőnket! Szerkeszd meg és használd ki te is, kapj értesítést, vagy küldj automatikusan és oszd meg órarended egy felületen, amit minden diákod egyszerre egy helyen láthat.</span>
                                </div>
                                <p>Ha gondod van a napi feladatok listázásával és a sok papírmunkával, használd ki az online óravázlat szerkesztő sokszínűségét, és oszd meg és küldj értesítést rajta keresztül a diákjaidnak a közelgő vizsgákról és feladatokról.</p>

                                <a href="javascript:void(0)" onClick={this.onLogin} className="button">Bejelentkezés</a>
                            </div>
                        </div>
                    </div>
                    <div className="column small-12 medium-6">
                        <img src="img/LANDING/10_jobb_kep.png" alt="" />
                    </div>
                </div>
            </section> */}

            <DownloadableMaterials />

        </div>
    }

}

type DownloadableMaterialsState = {
    loading: boolean;
    materials: IViewDlMaterialRecord[];
    offset: number;
}

class DownloadableMaterials extends React.Component<{}, DownloadableMaterialsState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            loading: true,
            materials: [],
            offset: 0
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }
    
    haveAllBooks = false;

    private async reloadAsync() {
        const materials = await ViewDlMaterial.list({ filter: { $notnull: "material_file_id" }, limit: this.limit, offset: this.state.offset });
        if(materials.length == 0){
            this.haveAllBooks = true;
        }
        const freshMaterial = [...this.state.materials, ...materials];
        this.setState({
            loading: false,
            materials : freshMaterial
        })
        //console.log("reload")
        //console.log(this.state.materials)
    }

    limit = 10;
    startPoz = 0;
    goingBackwards = false;


    owlOnTranslate(event: any) {
        //console.log("before","Eindex",event.item.index, "count", event.item.count,"back",this.goingBackwards,"allBook",this.haveAllBooks)
        if(!this.haveAllBooks){
            if(event.item.index >= 5 && !this.goingBackwards){
                if(event.item.index >= (event.item.count - 1)){
                    this.startPoz = event.item.count-7;
                    this.setState({ 
                        offset: this.state.offset + this.limit
                    });
                    this.reloadAsync();
                }
            }
            else{
                this.goingBackwards = true;
                if(event.item.index < event.item.count-2 && event.item.index > 4){
                    this.goingBackwards = false;
                }
            }
        }        
        //console.log("after","Eindex",event.item.index, "count", event.item.count,"back",this.goingBackwards,"allBook",this.haveAllBooks)
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        return <section className="main">
            <div className="row">
                <div className="column small-12 large-12">

                    <OwlCarousel
                        className="owl-theme dl-carousel"
                        onTranslate={this.owlOnTranslate.bind(this)}
                        startPosition ={this.startPoz}
                        rewind = {true}//if this is not enabled then the idexing will be rubbish, so keep this true.
                        loop 
                        margin={10}
                        nav
                        navText={['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>']}
                        dots={false}
                        items={5} //5 items above 1000px browser width
                        responsive={{
                            // breakpoint from 0 up
                            0: {
                                items: 1
                            },
                            // breakpoint from 400 up
                            400: {
                                items: 2
                            },
                            // breakpoint from 480 up
                            480: {
                                items: 3

                            },
                            // breakpoint from 768 up
                            768: {
                                items: 5
                            }
                        }}

                    >
                        {
                            this.state.materials.map(m => {

                                return <div key={m.id} className="item">
                                    <div className="book-item inner">
                                        <Link tabIndex={0} to={PATH_DL_MATERIAL_VIEW + "/" + m.id} >
                                            {m.thumbnail_file_id &&
                                                <figure>
                                                    <img src={"/api/media/thumb/200/" + m.thumbnail_file_sha1} />

                                                </figure>
                                            }
                                            <p className="item-title">{m.name}</p>
                                        </Link>
                                    </div>
                                </div>;
                            })
                        }

                    </OwlCarousel>


                </div>
            </div>
        </section>;
    }

}
