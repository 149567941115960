import { Module, ModuleMenu, BaseMenu, ModuleRoute } from "../Module";
import { me, hasGroup, Groups } from "@src/framework/server/Auth";
import { __ } from "@src/translation";
import DeveloperPages from "@src/module/developerModule/DeveloperPages";
import { TemplateTypeEditPage } from "@src/module/developerModule/TemplateTypeEditPage";
import { TemplateStylesPage } from "@src/module/developerModule/TemplateStylesPage";
import SystemCheckerComponent from "@src/module/developerModule/SystemCheckerComponent";
import GoodWordMarker from "@src/module/developerModule/GoodWordMarker";
import ArgoGoodWordMarker from "@src/module/developerModule/ArgoGoodWordMarker";
import ExerciseSearchResults from "@src/module/developerModule/ExerciseSearchResults";
import TanarXxTPwdListView from "@src/module/developerModule/TestTanarTpwd";
import { UIGuidelinesPage } from "@src/component/ui/guidelines/UIGuidelinesPage";
import TestResultsPage from "@src/module/developerModule/TestResultsPage";
import TemplateUpdatePage from "./TemplateUpdate";
import { UntranslatedTextInCode } from "./UntranslatedTextInCode";

export let developerModule: DeveloperModule<BaseMenu>;

export type DeveloperModuleConfig = {
    
}

export class DeveloperModule<T extends BaseMenu> extends Module<T> {

    private config: DeveloperModuleConfig;
    
    public PATH_DEVELOPER_PAGES = "/fejlesztoi";
    public PATH_TEMPLATE_TYPE_UPDATE = "/templateTypeUpdate";
    public PATH_TEST_SEARCH = "/testSearch";
    public PATH_TEMPLATE_UPDATE = "/templateUpdate";
    public PATH_SPELL_GOODWORD_MAKER = "/spell/goodwordMarker";
    public PATH_ARGO_GOOWORD_MAKER = "/argo/goodwordMarker";
    public PATH_EXERCISE_SEARCH_RESULT = "/exerciseSearchResults";
    public PATH_BACKGROUND_TASKS = "/backgroundTasks";
    public PATH_UI = "/test/ui";
    public PATH_TEST_TEACHER_PWD = "/testTanarTpwd";
    public PATH_TEST_RESULTS = "/testresults";
    public PATH_TRANSLATION_UNTRANSLATED = "/untranslated";

    constructor(config: DeveloperModuleConfig) {
        super();

        this.config = config;

        ///
    }

    public static initGlobal<T extends BaseMenu>(config: DeveloperModuleConfig) {
        developerModule = new DeveloperModule<T>(config);

    }

    public async getRoutesAsync(): Promise<Record<string, ModuleRoute<T>>> {
        return this.getRoutes();
    }
    public  getRoutes(): Record<string, ModuleRoute<T>> {
        
        let ret: Record<string, ModuleRoute<T>> = {
            'ROUTE_DEVELOPER_PAGES' : { path: this.PATH_DEVELOPER_PAGES, component: DeveloperPages, hasSidebar: true, menuCode: "developer-sidebar" },
            'ROUTE_TEMPLATE_TYPE_UPDATE' : { path: this.PATH_TEMPLATE_TYPE_UPDATE, component: TemplateTypeEditPage, hasSidebar: true, menuCode: "developer-sidebar" },
            'ROUTE_TEMPLATE_STYLES' : { path: this.PATH_TEMPLATE_UPDATE, component: TemplateStylesPage, exact: true, hasSidebar: true, menuCode: "developer-sidebar" },
            'ROUTE_TEMPLATE_UPDATE' : { path: this.PATH_TEMPLATE_UPDATE + "/:styleId?", component: TemplateUpdatePage, exact: true, hasSidebar: true, menuCode: "developer-sidebar" },
            'ROUTE_BACKGROUND_TASKS' : { path: this.PATH_BACKGROUND_TASKS, component: SystemCheckerComponent, exact: true, hasSidebar: true, menuCode: "developer-sidebar" },
            'ROUTE_SPELL_GOODWORD_MAKER' : { path: this.PATH_SPELL_GOODWORD_MAKER, component: GoodWordMarker, hasSidebar: true, menuCode: "developer-sidebar" },
            'ROUTE_ARGO_GOOWORD_MAKER' : { path: this.PATH_ARGO_GOOWORD_MAKER, component: ArgoGoodWordMarker, hasSidebar: true, menuCode: "developer-sidebar" },
            'ROUTE_EXERCISE_SEARCH_RESULT' : { path: this.PATH_EXERCISE_SEARCH_RESULT, component: ExerciseSearchResults, hasSidebar: true, menuCode: "developer-sidebar" },
            'ROUTE_TEST_TEACHER_PWD' : { path: this.PATH_TEST_TEACHER_PWD, component: TanarXxTPwdListView, hasSidebar: true, menuCode: "developer-sidebar" },
            'ROUTE_UI' : { path: this.PATH_UI, component: UIGuidelinesPage, hasSidebar: true, menuCode: "developer-sidebar" },
            'ROUTE_TEST_RESULTS' : { path: this.PATH_TEST_RESULTS + "/:id?/:subid?", component: TestResultsPage, hasSidebar: true, menuCode: "developer-sidebar" },
            'PATH_TRANSLATION_UNTRANSLATED' : { path: this.PATH_TRANSLATION_UNTRANSLATED, component: UntranslatedTextInCode, hasSidebar: true, menuCode: "developer-sidebar" },
        }

        return ret;
    }

    public getRecordRoute(tableInfoId: number, recordId: number, edit?:boolean): string|null {
        // TODO!
        return null;
    }

    public getMenus() {
        let ret: ModuleMenu<T>[] = [];

        if (me && hasGroup(me, Groups.Developer)) {

            ret = ret.concat(
            [
                {
                    title: __("Fejlesztői"),
                    path: this.PATH_DEVELOPER_PAGES,
                    iconClass: "fas fa-cat",
                    no: 100,
                    code: "header-function"
                },
                {
                    title: __("Szekció template típusok"),
                    path: this.PATH_TEMPLATE_TYPE_UPDATE,
                    iconClass: "",
                    no: 100,
                    code: "developer-sidebar"
                },
                {
                    title: __("Szekció típusok"),
                    path: this.PATH_TEMPLATE_UPDATE,
                    iconClass: "",
                    no: 100,
                    code: "developer-sidebar"
                },
                {
                    title: __("Háttér feladatok"),
                    path: this.PATH_BACKGROUND_TASKS,
                    iconClass: "",
                    no: 100,
                    code: "developer-sidebar"
                },
                // {
                //     title: __("Webpack statisztika"),
                //     path: this,
                //     iconClass: "",
                //     no: 100,
                //     code: "developer-sidebar"
                // },
                {
                    title: "spell/goodwordMarker",
                    path: this.PATH_SPELL_GOODWORD_MAKER,
                    iconClass: "",
                    no: 100,
                    code: "developer-sidebar"
                },
                {
                    title: "argo/goodwordMarker",
                    path: this.PATH_ARGO_GOOWORD_MAKER,
                    iconClass: "",
                    no: 100,
                    code: "developer-sidebar"
                },
                {
                    title: "exerciseSearchResults",
                    path: this.PATH_EXERCISE_SEARCH_RESULT,
                    iconClass: "",
                    no: 100,
                    code: "developer-sidebar"
                },
                {
                    title: "UI helper",
                    path: this.PATH_UI,
                    iconClass: "",
                    no: 100,
                    code: "developer-sidebar"
                },
                {
                    title: __("Teszt tanári jelszó"),
                    path: this.PATH_TEST_TEACHER_PWD,
                    iconClass: "",
                    no: 100,
                    code: "developer-sidebar"
                },
                {
                    title: __("Teszt eredmények"),
                    path: this.PATH_TEST_RESULTS,
                    iconClass: "",
                    no: 100,
                    code: "developer-sidebar"
                },
                {
                    title: __("Kódban hiányzó fordítás függvény"),
                    path: this.PATH_TRANSLATION_UNTRANSLATED,
                    iconClass: "",
                    no: 100,
                    code: "developer-sidebar"
                },
            ]);
        }

        return ret;
    }

}
