import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IActivityLogRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Aktivitás hónap
     *
     * NOT NULL -> usr.activity_month ON DELETE cascade
     *
     */
    activity_month_id?: number;
    /**
     * Nap
     */
    day?: string /*date?*/;
    /**
     * Aktivitás típus
     *
     * NOT NULL -> usr.activity_type ON DELETE noaction
     *
     */
    activity_type_id?: number;
    /**
     * Aktivitás típus
     *
     * Aktivitás típus szabad szöveggel megadva
     */
    activity_type?: string|null;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Órák száma
     */
    hours?: number /* double precision */;
}

/**
 *  usr.activity_log - Aktivitás napló
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ActivityLogCrud extends Crud<IActivityLogRecord> {
    public static TABLE_INFO_ID = 2599392522;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'activity_log'; }

    public static load: (id: number, aServer ?: Server) => Promise<ActivityLogCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IActivityLogRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IActivityLogRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IActivityLogRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IActivityLogRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IActivityLogRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ActivityLogCrudClassProxy extends CrudClassProxy<IActivityLogRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const activityLogCrudClassProxy = new ActivityLogCrudClassProxy(ActivityLogCrud);
registerCrudClassProxy(activityLogCrudClassProxy);
