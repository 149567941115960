import * as React from 'react';
import { app } from '@src/index';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import { __ } from '@src/translation';
import ReactTable from 'react-table';
import { getReactTableLabels } from '@src/framework/i18n';
import { getBookStructureForId, BookStructure, getBookLessoncontent } from '@src/server/PublicServer';
import { ISupplementaryMaterialRecord } from '@src/framework/crud/doc/SupplementaryMaterialCrud';
import ExerciseCrudPub from '@src/framework/crud/exc_pub/ExerciseCrud';
import ExerciseCrud from '@src/framework/crud/exc/ExerciseCrud';
import ExerciseSeriesCrud from '@src/framework/crud/exc/ExerciseSeriesCrud';
import ExerciseSeriesCrudPub from '@src/framework/crud/exc_pub/ExerciseSeriesCrud';
import OoFileCrudPub from '@src/framework/crud/media_pub/OoFileCrud';
import { PATH_EXERCISE_PUB_VIEW, PATH_EXERCISE_VIEW, PATH_EXERCISE_SERIES_PUB_VIEW, PATH_PUBLISHED_MEDIA_VIEW, PATH_EXERCISE_SERIES_VIEW } from '@src/Routes';


interface OFIConvertedLinksPageProps {

}

interface OFIConvertedLinksPageState {

    loading: boolean;
    books: IBookRecord[];
    selectedBookId?: number;
    count: number;
    links: ISupplementaryMaterialRecord[];
    bookStructure?: BookStructure;
}

export class OFIConvertedLinksPage extends React.Component<OFIConvertedLinksPageProps, OFIConvertedLinksPageState> {
    constructor(props: OFIConvertedLinksPageProps) {
        super(props);
        this.state = {
            count: 0,
            loading: false,
            books: [],
            links: [],

        };
    }

    async componentDidMount() {

        await this.reload();
        await this.reloadUntaggedAsync();
    }

    async reload() {
        const books = await BookCrud.list({ filter: { is_active: true, library_id: LIBRARY_OFI_OFFICIAL_ID }, order_by: "name" });
        this.setState({
            books,
            selectedBookId: books[0].id
        });
    }

    async reloadUntaggedAsync() {
        try {

            this.setState({ loading: true });
            let bookStructure = await getBookStructureForId(this.state.selectedBookId!);
            const templinks: ISupplementaryMaterialRecord[] = [];
            if (bookStructure) {
                for (const lesson of bookStructure.lessons) {
                    let booklessonContent = await getBookLessoncontent(lesson.lesson_id, false);
                    if (booklessonContent && booklessonContent.supplementaryMaterials.length > 0) {
                        for (const supMat of booklessonContent.supplementaryMaterials) {
                            if (supMat.url && supMat.url.indexOf("player.nkp.hu") > -1) {
                                templinks.push(supMat);
                            }
                        }
                    }

                }
            }
            this.setState({ links: templinks, loading: false,count:templinks.length>0?templinks.length:0 })

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onBookChanged(event: any) {
        this.setState({ selectedBookId: event.target.value ? Number(event.target.value) : undefined }, this.reloadUntaggedAsync)
    }

    render() {
        return <div>

            <div className="row">
                <div className="columns large-6 small-12">
                    <label htmlFor="bookIdSelector">{__("Csak ebben a könyvben")}</label>
                    <select disabled={this.state.loading} name="bookIdSelector" onChange={this.onBookChanged.bind(this)}>
                        {
                            this.state.books.map(book => {
                                if (!book.name) return;
                                return <option key={book.id} value={book.id}>{book.name}</option>
                            })
                        }
                    </select>
                </div>

            </div>
            {this.state.links
                ? <span style={{ fontWeight: "bold", fontSize: "15px" }}>{__("Összesen")} {__("{n} találat", {n: this.state.count})}</span>
                : ""
            }

            <ReactTable
                columns={[
                    { Header: __("Név"), accessor: "name", },
                    { Header: __("Régi URL"), filterable: false,
                    Cell: (data: any, column: any) => {
                        const row = data.original;
                            return <a target="_blank" href={row.url}>{row.url} </a>
                    },
                    },
                    {
                        Header: __("Új URL"), accessor: "Új", filterable: false,
                        Cell: (data: any, column: any) => {
                            const row = data.original;
                            if (row.table_info_id && row.new_id) {

                                //pub
                                if (row.table_info_id == ExerciseCrudPub.TABLE_INFO_ID) { return <a target="_blank" href={PATH_EXERCISE_PUB_VIEW+"/"+row.new_id}>{PATH_EXERCISE_PUB_VIEW+"/"+row.new_id} </a>}
                                else if (row.table_info_id == ExerciseSeriesCrudPub.TABLE_INFO_ID) {return  <a target="_blank" href={PATH_EXERCISE_SERIES_PUB_VIEW+"/"+row.new_id  }>{PATH_EXERCISE_SERIES_PUB_VIEW+"/"+row.new_id  } </a> }
                                else if (row.table_info_id == OoFileCrudPub.TABLE_INFO_ID) { return  <a target="_blank" href={PATH_PUBLISHED_MEDIA_VIEW+"/"+row.new_id}>{PATH_PUBLISHED_MEDIA_VIEW+"/"+row.new_id} </a>}

                                //not pub
                                else if (row.table_info_id == ExerciseCrud.TABLE_INFO_ID) {return  <a target="_blank" href={PATH_EXERCISE_VIEW+"/"+row.new_id }>{PATH_EXERCISE_VIEW+"/"+row.new_id }</a>  }
                                else if (row.table_info_id == ExerciseSeriesCrud.TABLE_INFO_ID) {return  <a target="_blank" href={PATH_EXERCISE_SERIES_VIEW+"/"+row.new_id}>{PATH_EXERCISE_SERIES_VIEW+"/"+row.new_id} </a> }

                                //(row.table_info_id == OoFileCrud.TABLE_INFO_ID)
                                else  { return  <a target="_blank" href={PATH_PUBLISHED_MEDIA_VIEW+"/"+row.new_id}>{PATH_PUBLISHED_MEDIA_VIEW+"/"+row.new_id} </a>  }
                            }
                            else {
                                return <a target="_blank" href={row.url}>{row.url} </a>
                            }
                        }
                    },
                               
                ]}
                data={this.state.links}
                filterable={true}
                className="-striped -highlight "
                {...getReactTableLabels()}
                onFetchData={this.reload.bind(this)}
                loading={this.state.loading}
            />
        </div>;
    }
}

