import * as React from 'react';
import { Link } from 'react-router-dom';
import { app } from '@src/index';
import { PATH_OCCUPATION_DRAFT_EDIT } from './OccupationDraftEdit';
import { PATH_LESSON_DRAFT_EDIT, DRAFT_TYPE_DFHT, DRAFT_TYPE_KOMPLEX } from './LessonDraft';
import { PATH_KIP_DRAFT_EDIT } from './KipDraft';
import ReactTable, { RowInfo, Column, TableProps } from 'react-table';
import ViewKapDraft, { IViewKapDraftRecord } from '@src/framework/view/kap/ViewKapDraft';
import { TFilterDict, StringSearchKind, IListParams, IListOrderBy } from '@src/framework/crud/Crud';
import { debounce } from 'lodash';
import { __ } from '@src/translation';
import KipDraftCrud from '@src/framework/crud/kap/KipDraftCrud';
import LessonDraftCrud from '@src/framework/crud/kap/LessonDraftCrud';
import OccupationDraftCrud from '@src/framework/crud/kap/OccupationDraftCrud';
import { formatDate } from '@src/Util';
import { getReactTableLabels } from '@src/framework/i18n';
import WfAPI, { SecStationPermissions } from '@src/framework/wf/WfAPI';
import obtainServer from '@src/framework/server/Server';
import { WF_TYPE_KAP_ORAVAZLAT_ID, LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import { me, hasGroup, Groups } from '@src/framework/server/Auth';
import StationCrud, { IStationRecord } from '@src/framework/crud/wf/StationCrud';
import { getWfStateCell } from '@src/component/wf/wf_util';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';

//export const PATH_DRAFT_EDIT_LIST = "/oravazlat/lista"; DUPLIKÁLT! 

export const KAP_META_KOMPETENCIA = 25346136500;

const WF_STATUS_DELETED_ID = 25346122651;

export const TYPE_FILTER_DFHT_KIP = "kip";
export const TYPE_FILTER_DFHT = "dfht";
export const TYPE_FILTER_KOMPLEX = "komplex";
export const TYPE_FILTER_OCCUPATION = "occupation";


var saved = {

    filters: [],
    sorted: [],
    pageSize: 10,
    count: 0,
    page: 0,
    myLessonPlans: false,
    onlyPublishedContent: false
}

type DraftEditListProps = {
    onSelect?: (selected: IViewKapDraftRecord) => IViewKapDraftRecord[];
    onSaveChoosenElements?: () =>void;
    filters?: any;
    grade_id?: number;
    subject_id?: number;
    showAdditonalSyllabusContent?: boolean;
    selectedDrafts?: IViewKapDraftRecord[];
    syllabusTopicId?: number;
    seletectedSubTopicId?:number
}

type DraftEditListState = {
    permissions: SecStationPermissions;
    drafts: IViewKapDraftRecord[];
    selectedDrafts: any[];
    count: number;
    page: number,
    filters: any[],
    pageSize: number;
    loading: boolean;
    myLessonPlans: boolean;
    onlyPublishedContent: boolean;
    selected: any;
    stations: IStationRecord[];
    selectedGradeId?: number;
    selectedSubjectId?: number;
    sorted: any[];
}

export class DraftEditList extends React.Component<DraftEditListProps, DraftEditListState> {

    constructor(props: DraftEditListProps) {
        super(props);

        this.state = {
            permissions: {},
            count: saved.count,
            pageSize: saved.pageSize,
            drafts: [],
            selectedDrafts: this.props.selectedDrafts || [],
            stations: [],
            loading: false,
            selected: null,
            filters: saved.filters,
            page: saved.page,
            sorted: saved.sorted,
            selectedGradeId: this.props.grade_id || undefined,
            selectedSubjectId: this.props.subject_id || undefined,
            myLessonPlans: saved.myLessonPlans,
            onlyPublishedContent: saved.onlyPublishedContent

        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync(tablestate?: { page: number, pageSize: number, sorted: any, filtered: any[] }) {

        this.setState({ loading: true });

        const showDeleted = this.state.filters.find(userFilter => userFilter.id === "wf_station_name" && userFilter.value === WF_STATUS_DELETED_ID);

        try {
            let filter: TFilterDict = {
                is_active: showDeleted ? false : true,
                library_id: LIBRARY_OFI_OFFICIAL_ID
            };

            for (const userFilter of this.state.filters) {

                if (userFilter.id == "table_info_id") {
                    if (userFilter.value == TYPE_FILTER_DFHT_KIP) filter["table_info_id"] = KipDraftCrud.TABLE_INFO_ID;
                    if (userFilter.value == TYPE_FILTER_DFHT) filter["lesson_draft_type_id"] = DRAFT_TYPE_DFHT;
                    if (userFilter.value == TYPE_FILTER_KOMPLEX) filter["lesson_draft_type_id"] = DRAFT_TYPE_KOMPLEX;
                    if (userFilter.value == TYPE_FILTER_OCCUPATION) filter["table_info_id"] = OccupationDraftCrud.TABLE_INFO_ID;
                }

                else if (userFilter.id == "wf_station_name") {
                    if (userFilter.value == -1) { filter["wf_station_id"] = undefined; }
                    else { filter["wf_station_id"] = userFilter.value; }

                }
                else filter[userFilter.id] = { kind: StringSearchKind.Contains, case_sensitive: false, expr: userFilter.value }

            }
            let orderBy: undefined | string | IListOrderBy[] = undefined;
            if (this.state.sorted.length > 0) {
                const userOrder = this.state.sorted[0];

                if (userOrder.desc) {
                    orderBy = [{ name: userOrder.id, desc: true }];
                } else {
                    orderBy = userOrder.id
                }
            }
            if (this.state.myLessonPlans && me) {
                saved.myLessonPlans = me.id as any;
                filter.creation_user_id = me.id;
            }
            if (this.state.onlyPublishedContent && me) {
                filter.is_published = true;
            }

            if (!orderBy) { orderBy = [{ name: "creation_time", desc: true }]; }
            if (this.state.selectedGradeId) {
                filter.grade_id = this.state.selectedGradeId;
            }
            if (this.state.selectedSubjectId) {
                filter.subject_id = this.state.selectedSubjectId;
            }
            if (this.state.selectedGradeId) {
                filter.grade_id = this.state.selectedGradeId;
            }

            if (this.props.filters) {
                filter = Object.assign(filter, this.props.filters);
            }
            let listParameters: IListParams = {
                filter: filter,
                order_by: orderBy,
                limit: this.state.pageSize,
                offset: this.state.page * this.state.pageSize
            };

            const permissions = await (new WfAPI(obtainServer()).getStationPermissions(WF_TYPE_KAP_ORAVAZLAT_ID));
            let stations = await StationCrud.list({ filter: { is_active: true, wf_type_id: WF_TYPE_KAP_ORAVAZLAT_ID }, order_by: "no" });

            const drafts = await ViewKapDraft.list(listParameters);
            const count = await ViewKapDraft.count(listParameters);

            saved.count = count
            this.setState({
                permissions,
                drafts,
                count: count,
                loading: false,
                stations
            });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }
    onFetchData(tablestate: any) {
        if (this.state.filters != tablestate.filtered) {
            this.debouncedSetTableState(tablestate);
        } else {
            this.setTableState(tablestate);
        }
    }
    onMyLessonPlansChanged(event: any) {
        const myLessonPlans = event.target.checked;
        saved.myLessonPlans = myLessonPlans;
        this.setState({ myLessonPlans: myLessonPlans }, this.reloadAsync);
    }
    ononlyPublishedContentChanged(event: any) {
        const onlyPublishedContent = event.target.checked;
        saved.onlyPublishedContent = onlyPublishedContent;
        this.setState({ onlyPublishedContent: onlyPublishedContent }, this.reloadAsync);
    }
    private onDraftSelect(selected: IViewKapDraftRecord) { 
        let selectedDrafts = this.props.onSelect!(selected);
        this.setState({ selectedDrafts });
    }
    private setSubjectId(newValue: number | null) {
        let selectedSubjectId = newValue || undefined;
        this.setState({ selectedSubjectId }, this.reloadAsync)
    }

    private setGradeId(newValue: number | null) {
        let selectedGradeId = newValue || undefined;
        this.setState({ selectedGradeId }, this.reloadAsync)
    }

    debouncedSetTableState = debounce((tablestate) => this.setTableState(tablestate), 300);

    private setTableState(tablestate: any) {

        saved.page = tablestate.page;
        saved.pageSize = tablestate.pageSize;
        saved.sorted = tablestate.sorted;
        saved.filters = tablestate.filtered;

        this.setState({
            page: tablestate.page,
            pageSize: tablestate.pageSize,
            sorted: tablestate.sorted,
            filters: tablestate.filtered,
        }, this.reloadAsync);
    }

    render() {
        const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};
            if (column && rowInfo && rowInfo.row) {
                let row = rowInfo.row._original;
                if (row && column.id == "wf_station_name") {
                    props.style = row.wf_station_style || {};
                }
            }
            return props;
        }
        const typeFilter = (filter: any, onChange: any) => {

            return (
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                >
                    <option value={""}>Mutasd mindet</option>

                    <option key={TYPE_FILTER_DFHT_KIP} value={TYPE_FILTER_DFHT_KIP}>DFHT-KIP</option>
                    <option key={TYPE_FILTER_DFHT} value={TYPE_FILTER_DFHT}>DFHT</option>
                    <option key={TYPE_FILTER_KOMPLEX} value={TYPE_FILTER_KOMPLEX}>Komplex</option>
                    <option key={TYPE_FILTER_OCCUPATION} value={TYPE_FILTER_OCCUPATION}>Foglalkozásterv</option>

                </select>
            );
        };
        const wfFilter = (filter: any, onChange: any) => {
            return (
                <select
                    onChange={event => onChange(Number(event.target.value))}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : -1}
                >
                    <option value={-1}>Mutasd mindet</option>
                    {this.state.stations.map((stationItem, index) => {
                        return (
                            <option style={stationItem.style} key={index} value={stationItem.id}>{stationItem.name || ""}</option>
                        );
                    })}
                </select>
            );
        };

        const canStartStationExists = Object.values(this.state.permissions).find(perm => perm.can_start);
        let draftselectString = this.props.seletectedSubTopicId?"A tanóra témához kiválasztott óravázlatok: ":"A témakörhöz kiválasztott óravázlatok: ";
        return <div>
            <div className="row expanded">
                {
                    ((me && hasGroup(me, Groups.Admin)) || canStartStationExists !== undefined) &&
                    <div className="column small-12">
                        <br />
                        <Link to={PATH_OCCUPATION_DRAFT_EDIT} className="button"><i className="fa fa-plus" /> Új alprogrami foglalkozásterv létrehozása</Link>
                        <Link to={PATH_LESSON_DRAFT_EDIT} className="button"><i className="fa fa-plus" /> Új DFHT vagy komplex óravázlat létrehozása</Link>
                        <Link to={PATH_KIP_DRAFT_EDIT} className="button"><i className="fa fa-plus" /> Új DHFT-KIP óravázlat létrehozása</Link>
                    </div>
                }

                <div className="column small-12">
                    <h4>Szerkesztés alatti óravázlatok</h4>
                    <div className="shrink column">
                        <input type="checkbox" name="myexerciseLists" id="myexerciseLists" checked={this.state.myLessonPlans} onChange={this.onMyLessonPlansChanged.bind(this)} />
                        <label htmlFor="myexerciseLists">{__("Saját óravázlataim")}</label>
                        <input type="checkbox" name="pipa" id="pipa" checked={this.state.onlyPublishedContent} onChange={this.ononlyPublishedContentChanged.bind(this)} />
                        <label htmlFor="pipa">{__("Csak publikált tartalom")}</label>
                        {this.props.showAdditonalSyllabusContent ?
                        <div style={{ float: "right"}}>
                            <b>{draftselectString} {this.state.selectedDrafts ? this.state.selectedDrafts.length : ""}</b>                      
                        </div>
                        :""
    }                   
                    </div>
                    {this.props.showAdditonalSyllabusContent ?
                        <div className="row expanded">
                            <div className="small-4 column">
                                <CrudSelectComponent
                                    crudClassProxy={gradeCrudClassProxy}
                                    value={this.state.selectedGradeId || null}
                                    filter={{ is_active: true }}
                                    onSelect={(sender, newValue) => this.setGradeId(newValue)}
                                    displayFieldName="name"
                                    emptyTitle={__("Minden osztály")}
                                    orderByFieldName="id"
                                    key="id"
                                    sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                                />
                            </div>
                            <div className="small-4 column">
                                <CrudSelectComponent
                                    crudClassProxy={subjectCrudClassProxy}
                                    value={this.state.selectedSubjectId || null}
                                    displayFieldName="name"
                                    orderByFieldName="name"
                                    onSelect={(sender, newValue) => this.setSubjectId(newValue)}
                                    filter={{ is_active: true }}
                                    key="id"
                                    emptyTitle={"Válasszon tantárgyat!"}

                                />
                            </div>
                            <div className="small-3 column text-right">
                            <button disabled={this.state.selectedDrafts &&this.state.selectedDrafts.length<1 ||this.state.loading} className="button success" onClick={this.props.onSaveChoosenElements}>
                                    <i className="fa fa-save" />{this.props.seletectedSubTopicId?__("A kiválasztott óravázlatok mentése a tanóra témához"):__("A kiválasztott óravázlatok mentése a témakörhöz")}
                                </button>
                                </div>
                        </div>
                        : ""
                    }
                    <span></span>
                    {this.state.loading ? __("Betöltés") : this.state.count + " " + __("találat")}
                    <ReactTable
                        columns={[
                            {
                                Header: __("Típus"), accessor: "table_info_id", sortable: true, width: 130, filterable: true,

                                filterMethod: (filter: any, row: RowInfo) => {
                                    return row[filter.id] === filter.value;
                                },
                                Filter: params => typeFilter(params.filter, params.onChange),

                                Cell: (data: { original: IViewKapDraftRecord }, column: any) => {
                                    switch (data.original.table_info_id) {
                                        case KipDraftCrud.TABLE_INFO_ID: return "DFHT-KIP";
                                        case LessonDraftCrud.TABLE_INFO_ID: {
                                            if (data.original.lesson_draft_type_id === DRAFT_TYPE_DFHT) { return "DFHT"; }
                                            else if (data.original.lesson_draft_type_id === DRAFT_TYPE_KOMPLEX) { return "Komplex"; }
                                            else return "Ismeretlen";
                                        }
                                        case OccupationDraftCrud.TABLE_INFO_ID: return "Foglalkozásterv";
                                        default: return "";
                                    };
                                },
                            },
                            { Header: __("Szerző(k)"), accessor: "authors", filterable: true, sortable: true, },
                            { Header: __("Téma"), accessor: "topic", filterable: true, sortable: true, width: 250 },
                            { Header: __("Alprogram"), accessor: "subprogram_title", filterable: true, sortable: true },
                            { Header: __("Osztály"), accessor: "grade_name", filterable: true, sortable: true, maxWidth: 80 },
                            { Header: __("Tantárgy"), accessor: "subject_name", filterable: true, sortable: true },
                            { Header: __("Létrehozó"), accessor: "creator", filterable: true, sortable: false },
                            {
                                Header: __("Létrehozva"), filterable: false, sortable: false, width: 180,
                                Cell: (data: { original: IViewKapDraftRecord }, column: any) => formatDate(data.original.creation_time)
                            },
                            {
                                Header: __("Státusz"), accessor: "wf_station_name", filterable: true, sortable: false, style: { justifyContent: "center" }, width: 140, maxWidth: 240,

                                Cell: (data: any, column: any) => {
                                    const row: IViewKapDraftRecord = data.original;
                                    return getWfStateCell(data, column, row.is_published ?
                                        <>
                                            <br />
                                            <span className="label primary"
                                                title={formatDate(row.last_published) + " " + row.last_publisher}
                                            >
                                                <small>{__("Publikálva")}
                                                </small>
                                            </span>
                                        </>
                                        : null
                                    )
                                },
                                filterMethod: (filter: any, row: RowInfo) => {
                                    return row[filter.id] === filter.value;
                                },
                                Filter: params => wfFilter(params.filter, params.onChange),

                            },
                            {
                                Header: __("Műveletek"), filterable: false, sortable: false, className: "action-cell", width: 180,
                                Cell: (data: { original: IViewKapDraftRecord }, column: any) => {
                                    if (this.props.onSelect) {
                                        let checked =false;
                                        if(this.props.seletectedSubTopicId)
                                        {
                                            checked = this.state.selectedDrafts.find(x => x.rec_id == data.original.id && x.syllabus_subtopic_id == this.props.seletectedSubTopicId && x.is_active)
                                        }
                                        else
                                        {
                                            checked = this.state.selectedDrafts.find(x => x.rec_id == data.original.id && x.syllabus_topic_id == this.props.syllabusTopicId && x.is_active)
                                        }
                                     
                                        return <div>
                                            <input type="checkbox" name={"draftChecker" + data.original.id} id={"draftChecker" + data.original.id} checked={checked} onChange={this.onDraftSelect.bind(this, data.original)} />
                                            <label htmlFor={"draftChecker" + data.original.id}>{__("Kiválasztva")}</label>
                                        </div>;
                                        // onChange={() => this.props.onSelect!(data.original)}
                                        // <button className="button" onClick={() => this.props.onSelect!(data.original)}>
                                        //     <i className="fa fa-check" /> {__("Kiválaszt")}
                                        // </button>;

                                    }

                                    let link = "";
                                    if (data.original.table_info_id == KipDraftCrud.TABLE_INFO_ID) {
                                        link = PATH_KIP_DRAFT_EDIT + "/" + data.original.id;
                                    } else if (data.original.table_info_id == LessonDraftCrud.TABLE_INFO_ID) {
                                        link = PATH_LESSON_DRAFT_EDIT + "/" + data.original.id;
                                    } else if (data.original.table_info_id == OccupationDraftCrud.TABLE_INFO_ID) {
                                        link = PATH_OCCUPATION_DRAFT_EDIT + "/" + data.original.id;
                                    } else {
                                        return null;
                                    }

                                    return <>
                                        <Link className="button small" to={link}>
                                            <i className="fa fa-edit"></i>
                                        </Link>
                                    </>
                                }
                            }
                        ]}
                        manual
                        filterable={true}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        data={this.state.drafts}
                        defaultPageSize={this.state.pageSize}
                        defaultFiltered={this.state.filters}
                        defaultSorted={this.state.sorted}
                        pages={Math.ceil(this.state.count / this.state.pageSize)}
                        onFetchData={this.onFetchData.bind(this)}
                        loading={this.state.loading}
                        getTdProps={getTdProps}
                        {...getReactTableLabels()}
                    />
                </div>
            </div>
        </div>
    }
}
