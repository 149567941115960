import * as React from 'react';
import TinyMCE from 'react-tinymce-input';
import { __ } from '@src/translation';

const TINYMCE_CONTENT_STYLE = `
    .row {border: 1px dashed green;}
    .small-2, .small-3, .small-4, .small-5, .small-6, .small-7, .small-8, .small-9, .small-10, .small-11 {border: 1px dashed brown;} 
    .medium-2, .medium-3, .medium-4, .medium-5, .medium-6, .medium-7, .medium-8, .medium-9, .medium-10, .medium-11 {border: 1px dashed brown;} 
    figure:empty{padding:30px !important;}
    figure.float-right, figure.float-left {border: 1px dashed purple;}
    div.mxgraph:before {border: 3px dashed red; color: red; content: 'draw.io vektorgrafikus kép';}
    body:after {content: ''; display: block; clear: both;} /* or else the float elements will overflow vertically, because the autoresize plugin*/
`;

type HTMLTextareaProps = {
    value: string;
    onChange: (newValue: string) => void;
    onBlur?: () => void;
    height?: number;
    autoFocus?: boolean;
    tools?: "minimal" | "full";

    onAddImage?: (tinyMCE: any) => void
}

type HTMLTextareaState = {
    
}

export class HTMLTextarea extends React.Component<HTMLTextareaProps, HTMLTextareaState> {

    private tinyMCE: any;

    setupEditor() {
        const editorComponent = this;

        return (tinyMCE: any) => {
            
            this.tinyMCE = tinyMCE;

            if (editorComponent.props.onAddImage) {
                tinyMCE.addButton("image-add", {
                    text: __("Kép beillesztése"),
                    tooltip: __("Kép beillesztése"),
                    onclick: function () {
                        if (editorComponent.props.onAddImage) {
                            editorComponent.props.onAddImage(tinyMCE);
                        }
                    }
                });
            }

        };
    }

    onBlur() {
        /* react-tinymce-input-ban (lásd alul) be van állítva, hogy ignoreUpdatesWhenFocused
           De ez viszont elrontja a kiválasztást, ami kell a képek beillesztéséhez.
           Ezért itt visszaállítjuk a kiválasztást.
         */
        const bookmark = this.tinyMCE.selection.getBookmark(2, true);
        setTimeout(() => {
            this.tinyMCE.selection.moveToBookmark(bookmark);
        }, 1);

        if (this.props.onBlur) this.props.onBlur();
    }



    render() {


        const minimalTools = 'wrap_box_div removeformat | undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | forecolor backcolor';
        const fullTools = 'styleselect wrap_box_div removeformat | undo redo | bold italic | alignleft aligncenter alignright alignjustify blockquote | bullist numlist | forecolor backcolor | link table | visualblocks code | template | image-add';

        return <TinyMCE style={{marginBottom: "1rem"}} tinymceConfig={{
            auto_focus:this.props.autoFocus ? true : false,
            menubar: false,
            plugins: 'link code image lists template table textcolor visualblocks advlist noneditable paste', // autoresize nem működik jól, mindig maxra áll
            //toolbar: 'bold italic image-add',
            toolbar: (this.props.tools && this.props.tools == "minimal" ? minimalTools : fullTools),
            link_context_toolbar: true,

            removed_menuitems: "newdocument",
            
            language: 'hu_HU',
            //here

            templates: [ // for template plugin
                { title: __('Két oszlop (1/2 - 1/2)'), description: __('Két oszlopos elrendezés'), url: '/templates/2_oszlop_fel_fel.html' },
                { title: __('Két oszlop (1/4 - 3/4)'), description: __('Két oszlopos elrendezés'), url: '/templates/2_oszlop_negyed_haromnegyed.html' },
                { title: __('Két oszlop (3/4 - 1/4)'), description: __('Két oszlopos elrendezés'), url: '/templates/2_oszlop_haromnegyed_negyed.html' },
                { title: __('Két oszlop (1/3 - 2/3)'), description: __('Két oszlopos elrendezés'), url: '/templates/2_oszlop_harmad_ketharmad.html' },
                { title: __('Két oszlop (2/3 - 1/3)'), description: __('Két oszlopos elrendezés'), url: '/templates/2_oszlop_ketharmad_harmad.html' },
                { title: __('Három oszlop (1/3 - 1/3 - 1/3)'), description: __('Három oszlopos elrendezés'), url: '/templates/3_oszlop_harmadok.html' },
                { title: __('Három oszlop (1/2 - 1/4 - 1/4)'), description: __('Három oszlopos elrendezés'), url: '/templates/3_oszlop_fel_negyed_negyed.html' },
                { title: __('Három oszlop (1/4 - 1/2 - 1/4)'), description: __('Három oszlopos elrendezés'), url: '/templates/3_oszlop_negyed_fel_negyed.html' },
                { title: __('Három oszlop (1/4 - 1/4 - 1/2)'), description: __('Három oszlopos elrendezés'), url: '/templates/3_oszlop_negyed_negyed_fel.html' },
                { title: __('Négy oszlop'), description: __('Négy oszlopos elrendezés'), url: '/templates/4_oszlop_negyedek.html' },
                { title: __('Negyed kép jobbra, körbefutó szöveg'), description: __('Negyed kép jobbra igazítása, körbefutó szöveggel'), url: '/templates/negyed_kep_jobbra_float.html' },
                { title: __('Negyed kép balra, körbefutó szöveg'), description: __('Negyed kép balra igazítása, körbefutó szöveggel'), url: '/templates/negyed_kep_balra_float.html' },
                { title: __('Harmad kép jobbra, körbefutó szöveg'), description: __('Harmad kép jobbra igazítása, körbefutó szöveggel'), url: '/templates/harmad_kep_jobbra_float.html' },
                { title: __('Harmad kép balra, körbefutó szöveg'), description: __('Harmad kép balra igazítása, körbefutó szöveggel'), url: '/templates/harmad_kep_balra_float.html' },
                { title: __('Fél kép jobbra, körbefutó szöveg'), description: __('Fél kép jobbra igazítása, körbefutó szöveggel'), url: '/templates/fel_kep_jobbra_float.html' },
                { title: __('Fél kép balra, körbefutó szöveg'), description: __('Fél kép balra igazítása, körbefutó szöveggel'), url: '/templates/fel_kep_balra_float.html' },
                { title: __('Számozott feladatok'), description: __('Számozott feladatok'), url: '/templates/szamozott_feladatok.html' },
              ],
            entity_encoding: "raw",
            
            convert_urls: false, // or else the image links are broken
            height: this.props.height || "100",
            content_style: TINYMCE_CONTENT_STYLE,
            content_css: [
                '/lib/foundation-6.5.1/foundation.css',
                '/css/htmlEditor.css',
            ],
            paste_as_text: true
        }}
            ignoreUpdatesWhenFocused /* Ha ez nincs, akkor levágja a szóközöket a <p> végén, amikor gépelsz! */
            pollInterval={100} /* Default 1000-nél ha gyorsan kikattintasz, eltűnik az utolsó 1 másodperc változtatása */
            value={this.props.value}
            onChange={(newValue: string) => this.props.onChange(newValue)}
            onSetupEditor={this.setupEditor()}
            onBlur={this.onBlur.bind(this)}
            
            
        />;
    }
}
