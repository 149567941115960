import * as React from 'react';
import { TableProps, Column } from 'react-table';
import { app, history } from '@src/index';

import { IViewWfWorkflowRecord, ViewWfWorkflowClassProxy, viewWfWorkflowClassProxy } from '@src/framework/view/wf/ViewWfWorkflow';
import ListView from '@framework/forms/listview';

import '@framework/crud/wf/WorkflowCrud'; // Ezt ne távolítsd el mert akkor a hülye webpack kioptimizálja!
import CrudSelectComponent from '@src/framework/forms/crudselect';
import { wfTypeCrudClassProxy } from '@src/framework/crud/wf/WfTypeCrud';
import ExerciseCrud from '@src/framework/crud/exc/ExerciseCrud';
import { PATH_EXERCISE_EDIT, PATH_EXERCISE_SERIES_EDIT, PATH_EDIT_BOOK_SECTIONS } from '@src/Routes';
import ExerciseSeriesCrud from '@src/framework/crud/exc/ExerciseSeriesCrud';
import LessonCrud from '@src/framework/crud/doc/LessonCrud';
import ChapterCrud from '@src/framework/crud/doc/ChapterCrud';
import { __ } from '@src/translation';
import KipDraftCrud from '@src/framework/crud/kap/KipDraftCrud';
import { PATH_KIP_DRAFT_EDIT } from '@src/project/kap/draft/KipDraft';
import LessonDraftCrud from '@src/framework/crud/kap/LessonDraftCrud';
import { PATH_LESSON_DRAFT_EDIT } from '@src/project/kap/draft/LessonDraft';
import OccupationDraftCrud from '@src/framework/crud/kap/OccupationDraftCrud';
import { PATH_OCCUPATION_DRAFT_EDIT } from '@src/project/kap/draft/OccupationDraftEdit';

const getRecordRoute = async (tableInfoId: number, recordId: number): Promise<string | null> => {
    try {
        if (tableInfoId == ExerciseCrud.TABLE_INFO_ID) {
            return Promise.resolve(PATH_EXERCISE_EDIT + '/' + recordId);
        } else if (tableInfoId == ExerciseSeriesCrud.TABLE_INFO_ID) {
            return Promise.resolve(PATH_EXERCISE_SERIES_EDIT + '/' + recordId);
        } else if (tableInfoId == LessonCrud.TABLE_INFO_ID) {
            // PATH_EDIT_BOOK_SECTIONS + "/:bookId/:lessonId?"                
            const lesson = (await LessonCrud.load(recordId)).record;
            const chapter = (await ChapterCrud.load(lesson.chapter_id!)).record;
            return Promise.resolve(PATH_EDIT_BOOK_SECTIONS + "/" + chapter.book_id + "/" + recordId);
        } else if (tableInfoId == KipDraftCrud.TABLE_INFO_ID) {
            return PATH_KIP_DRAFT_EDIT + "/" + recordId;
        } else if (tableInfoId == LessonDraftCrud.TABLE_INFO_ID) {
            return PATH_LESSON_DRAFT_EDIT + "/" + recordId;
        } else if (tableInfoId == OccupationDraftCrud.TABLE_INFO_ID) {
            return PATH_OCCUPATION_DRAFT_EDIT + "/" + recordId;
        } else {
            return Promise.resolve(null);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

class WfWorkflowListViewInner extends ListView<IViewWfWorkflowRecord> {

    private getSpecFlag = (name: string): boolean => {
        if (this.state.spec === undefined) {
            return true;
        } else if (this.state.spec[name]) {
            return true;
        } else {
            return false;
        }
    }

    private toggleSpecFlag = (name: string) => {
        const flag = this.getSpecFlag(name);
        let spec = Object.assign(this.state.spec || {}, { [name]: !flag });
        this.setState({ spec }, this.asyncReload);
    }

    protected getSpecFilter(): any {
        return {
            only_mine: this.getSpecFlag("only_mine"),
            can_change_station: this.getSpecFlag("can_change_station"),
        }
    }

    //protected getHeader = () : JSX.Element|null => { return <WfMenu /> }
    public getViewClassProxy(): ViewWfWorkflowClassProxy { return viewWfWorkflowClassProxy; }



    protected getSpecialEditorButton = (record: IViewWfWorkflowRecord): JSX.Element => {        
        const onClick = async () => {
            try {
                const route = await getRecordRoute(record.table_info_id!, record.rec_id!);
                if (route) {
                    history.push(route);
                } else {
                    app.showError(__("Hiba"), __("Nem található a szerkesztőlap, vegye föl a kapcsolatot a fejlesztőkkel!"))
                }
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        };

        return <a key="editor-button" className="listview-button" title={__("Szerkesztés")}
            onClick={onClick}
        ><i className="fa fa-edit"></i></a>;
    }

    protected getRecordButtons(record: IViewWfWorkflowRecord): JSX.Element[] {
        return [
            this.getSpecialEditorButton(record)
            // this.getEditorButton(record)
        ];
    }

    protected getExtraTableProps(): Partial<TableProps> {
        let oldProps = super.getExtraTableProps();
        let inheritedGetTdProps: any = oldProps["getTdProps"];
        const getTdProps = (finalState: any, rowInfo: any, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};
            if (inheritedGetTdProps !== undefined) {
                props = inheritedGetTdProps(finalState, rowInfo, column, instance);
            }
            if (column && rowInfo && rowInfo.row) {
                let row: any = rowInfo.row._original;
                if (row) {
                    if (column["id"] == "station_name") {
                        props["style"] = row.station_style;
                    }
                }
            }
            return props;
        }
        return { ...oldProps, getTdProps }
    }

    private onSelectWfTypeId = async (sender: CrudSelectComponent, wf_type_id: string | number | null) => {
        if ((typeof wf_type_id === 'string')) {
            wf_type_id = Number(wf_type_id); // Should not happen
        }
        this.onFilterChange(this.columnInfoByName.get("wf_type_id")!, wf_type_id);
    }


    protected getFilterControls(): JSX.Element | null {
        return <div>
            {super.getFilterControls()}
            <fieldset className="large-6 cell">
                <CrudSelectComponent
                    value={(this.state.filterValues || {}).wf_type_id as any}
                    onSelect={this.onSelectWfTypeId}
                    displayFieldName="name"
                    crudClassProxy={wfTypeCrudClassProxy}
                    emptyTitle={ __("Szűrés folyamat típusra...") }
                />

                <legend>{__("Gyors szűrés")}</legend>
                <input type="checkbox"
                    value="1"
                    id="only_mine"
                    checked={this.getSpecFlag("only_mine")}
                    onChange={() => this.toggleSpecFlag("only_mine")}
                /><label htmlFor="only_mine">
                    { __("Én vagyok a felelős") }</label>

                <input type="checkbox"
                    value="1"
                    id="can_change_station"
                    checked={this.getSpecFlag("can_change_station")}
                    onChange={() => this.toggleSpecFlag("can_change_station")}
                /><label htmlFor="can_change_station">
                    {__("Tovább tudom küldeni")}</label>
            </fieldset>
        </div>
    }
}

export default function WfWorkflowListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <WfWorkflowListViewInner
        defaultPageSize={10}
        match={props.match}
        viewColumns={[
            { accessor: "id", maxWidth: 100 },
            { accessor: "station_name" },
            { accessor: "displayvalue" },
            { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
        ]}
    />
}
