import * as React from 'react';
import { CalendarSource } from '../../store/CalendarSource';

import { DAY_OF_WEEK_SHORT_NAMES, DAY_OF_WEEK_ORDER, DAY_OF_WEEK_LONG_NAMES, DAY_OF_WEEK_MINIMAL_NAMES, IDayItem } from './CalendarAPI';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { ToolTipDiv } from '../ToolTipDiv';
import { history } from "@src/index";
import { PATH_HTR_CALENDAR } from '@src/Routes';

export type DayItemRenderer = (item: IDayItem) => JSX.Element | string[] | JSX.Element[] | null;

export interface IMonthCalendarProps {
    calendarMonthSource: CalendarSource;
    dayItemRenderer: DayItemRenderer;
    forceSize?: string;
    isSmall?: boolean;
}


@observer
export class MonthCalendar extends React.PureComponent<IMonthCalendarProps> {


    private get calendar() {
        return this.props.calendarMonthSource;
    }

    private onItemClick = (event: React.MouseEvent, item: IDayItem, index: number) => {
        runInAction(() => { this.calendar.selectItem(item) });
    }

    public render() {
        const ms = this.props.calendarMonthSource;
        return <div className="eke-calendar-container">
            {DAY_OF_WEEK_ORDER.map((dow: number) =>
                <div key={"dow-" + dow} className="eke-calendar-day-item eke-calendar-day-name">
                    {(() => {
                        switch (this.props.forceSize) {
                            case "1": return <span>{DAY_OF_WEEK_LONG_NAMES[dow]}</span>;
                            case "2": case "3": return <span>{DAY_OF_WEEK_SHORT_NAMES[dow]}</span>;
                            case "4": return <span>{DAY_OF_WEEK_MINIMAL_NAMES[dow]}</span>;
                            default: return <><span className="show-for-large"> {DAY_OF_WEEK_LONG_NAMES[dow]}</span>
                                <span className="show-for-medium-only">{DAY_OF_WEEK_SHORT_NAMES[dow]}</span>
                                <span className="show-for-small-only">{DAY_OF_WEEK_MINIMAL_NAMES[dow]}</span></>
                        }
                    })()}
                </div>
            )}
            {
                ms.items.map((item: IDayItem, index: number) => {
                    let className = "eke-calendar-day-item eke-calendar-day";
                    if (item === ms.selectedItem) {
                        className += " eke-calendar-day-selected"
                    }
                    if (item.date.getMonth() !== ms.month) {
                        className += " eke-calendar-day-of-other-month"
                    }
                    const summary = this.props.dayItemRenderer(item);
                    const tooltipText = (this.props.isSmall && item.day.description ? item.day.description : "") + (item.day.description && summary ? "\n" : "") + (this.props.isSmall && summary ? String(summary).replace(",", "\n") : "");

                    return <div className={className} key={"item-" + item.date.toISOString()}
                        //onDoubleClick={() => { history.push(PATH_HTR_CALENDAR) }}
                        onClick={(event: React.MouseEvent) => { this.onItemClick(event, item, index) }}>

                        {this.props.isSmall ? (item.day.description || summary ?
                            <ToolTipDiv key={item.date.toString()} className={"has-tip eke-calendar-summary-day eke-calendar-day-of-month-text eke-calendar-day-type-" + item.day.day_type_id} title={String(tooltipText)}>{item.date.getDate()}</ToolTipDiv> : <div className={"eke-calendar-day-of-month-text eke-calendar-day-type-" + item.day.day_type_id}>{item.date.getDate()}</div>) :
                            <div
                                key={item.date.toISOString()}
                                className={"eke-calendar-day-of-month-text eke-calendar-day-type-" + item.day.day_type_id}>{item.date.getDate()}</div>
                        }

                        {this.props.isSmall ? "" : <small className={"eke-calendar-day-desc eke-calendar-day-type-" + item.day.day_type_id}>{item.day.description}</small>}
                        {this.props.isSmall ? "" : summary}
                    </div>

                })
            }
        </div>;
    }
}