import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ICourseUserRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Képzés
     *
     * NOT NULL -> kap.course ON DELETE cascade
     *
     */
    course_id?: number;
    /**
     * Alkalmazás szintű felhasználó
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     */
    sec_user_id?: number;
    /**
     * Sikeres
     */
    passed?: boolean|null;
    /**
     * Tanúsítvány sorszám
     *
     * Ez nem a teljes tanúsítvány szám, csak az éven és a típuson belüli szám.
     */
    cert_serial?: number /*integer*/|null;
    /**
     * Tanúsítvány szám
     *
     * Ez a teljes tanúsítvány szám.
     */
    cert_num?: string|null;
    /**
     * OnlyOffice mappa
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number;
    /**
     * Beadott DFHT KIP óraterv
     *
     * -> kap.kip_draft ON DELETE noaction
     *
     */
    kip_draft_id?: number|null;
    /**
     * Beadott DFHT alap/komplex óraterv
     *
     * -> kap.lesson_draft ON DELETE noaction
     *
     */
    lesson_draft_id?: number|null;
    /**
     * Beadott foglalkozásterv
     *
     * -> kap.occupation_draft ON DELETE noaction
     *
     */
    occupation_draft_id?: number|null;
    /**
     * Igazolás sorszám
     *
     * Ez nem a teljes igazolás szám, csak az éven és a típuson belüli szám.
     */
    ver_serial?: number /*integer*/|null;
    /**
     * Igazolás szám
     *
     * Ez a teljes igazolás szám.
     */
    ver_num?: string|null;
    /**
     * Egyéb értékek
     *
     * 'Melyik kontaktnapon volt jelen' és hasonlók
     */
    other_jsonb?: any|null;
}

/**
 *  kap.course_user - Képzés részvevő
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class CourseUserCrud extends Crud<ICourseUserRecord> {
    public static TABLE_INFO_ID = 1065959524;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'course_user'; }

    public static load: (id: number, aServer ?: Server) => Promise<CourseUserCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ICourseUserRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ICourseUserRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ICourseUserRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ICourseUserRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ICourseUserRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class CourseUserCrudClassProxy extends CrudClassProxy<ICourseUserRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const courseUserCrudClassProxy = new CourseUserCrudClassProxy(CourseUserCrud);
registerCrudClassProxy(courseUserCrudClassProxy);
