import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ILikeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Alkalmazás szintű felhasználó
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     *
     * Aki a kedveli
     */
    sec_user_id?: number;
    /**
     * Amit kedvel - Táblázat infó
     *
     * NOT NULL -> meta.table_info ON DELETE noaction
     *
     *
     * Ez mondja meg, hogy a folyamat melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number;
    /**
     * Amit kedvel - rekord azonosító
     *
     * Ez mondja meg, hogy a folyamat melyik rekordhoz tartozik.
     */
    rec_id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
}

/**
 *  usr.like - Kedvelés
 *
 *  Kedvelt tartalom
 */

export default class LikeCrud extends Crud<ILikeRecord> {
    public static TABLE_INFO_ID = 3912116267;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'like'; }

    public static load: (id: number, aServer ?: Server) => Promise<LikeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ILikeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ILikeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ILikeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ILikeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ILikeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class LikeCrudClassProxy extends CrudClassProxy<ILikeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const likeCrudClassProxy = new LikeCrudClassProxy(LikeCrud);
registerCrudClassProxy(likeCrudClassProxy);
