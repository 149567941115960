import * as React from 'react';
import { Link, match } from 'react-router-dom';
import 'react-table/react-table.css'
import { me } from '@src/framework/server/Auth';
import { history } from '@src/index';
import { app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import { Accordion, AccordionItem } from '@src/component/ui/Accordion';
import ViewItem, { IViewItemRecord } from '@src/framework/view/kb/ViewItem';
import { MetaKeywordAssignerPlugin, fetchKeywords, setKeywords } from '../meta/meta_keyword_assigner_plugin';
import ItemCrud, { IItemRecord } from '@src/framework/crud/kb/ItemCrud';
import { PATH_KNOWLEDGE_BASE_LIST, PATH_KNOWLEDGE_BASE_EDIT } from '@src/Routes';
import MediaFileChooser, { FILE_ACTIONS } from '../filemanager/MediaFileChooser';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import { HTMLTextarea } from '@src/component/HtmlTextarea';
import ViewKbItemType, { IViewKbItemTypeRecord } from '@src/framework/view/kb/ViewKbItemType';
import { formatDate, formatUserName } from '@src/Util';
import { __ } from '@src/translation';

const KB_ROOT_PARENT_FOLDER = 5;

class KnowledgeBaseEditState {
    itemTypes?:IViewKbItemTypeRecord [];
    keywords: string[];
    isDataChanged: boolean;
    saving: boolean;
    knowledgeBaseRecord?: IViewItemRecord;
    selectedImagePath: string;
    isExistingKbItem:boolean;
}

export type KnowledgeBaseEditProps = {
    match: match<{ kbItemId: string }>
}

export default class KnowledgeBaseEditPage extends React.Component<KnowledgeBaseEditProps, KnowledgeBaseEditState> {
    constructor(props: any) {
        super(props);
        this.state = {
            itemTypes: undefined,
            isDataChanged: false,
            saving: false,
            selectedImagePath: "",
            keywords: [],
            isExistingKbItem:false,
        };
    }

    private getKbItemId = (): number => {
        return parseInt(this.props.match.params.kbItemId!);
    }

    componentDidMount() {
        this.asyncReload();
    }
    componentDidUpdate(prevProps: KnowledgeBaseEditProps) {
        if (this.props.match.params.kbItemId !== prevProps.match.params.kbItemId) {
            this.asyncReload();
        }
    }

    private asyncReload = async () => {
        try {
            this.loadKeywords();

            var knowledgeBaseRecord: IViewItemRecord = this.props.match.params.kbItemId ? knowledgeBaseRecord = (await ViewItem.load(this.getKbItemId())).record : {};
            const isExistingKbItem=this.props.match.params.kbItemId ?true:false;
            var itemTypes= await (ViewKbItemType.list({filter:{is_active:true}}));
            this.setState({
                knowledgeBaseRecord,
                itemTypes,
                isExistingKbItem
            });

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private loadKeywords = async () => {
        try {
            const keywords = await fetchKeywords(ItemCrud.TABLE_INFO_ID, this.getKbItemId());
            this.setState({ keywords });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }
    private onAppendKeywords = async (keywords: string[]) => {
        this.setState({ isDataChanged: true, keywords: this.state.keywords!.concat(keywords) });
    }

    private onDeleteKeyword = async (index: number) => {
        const keywords = this.state.keywords!.concat([]);
        keywords.splice(index, 1);
        this.setState({ isDataChanged: true, keywords });
    }

    render() {
        
        if (this.state.knowledgeBaseRecord) {       
            return (<div ref="root_div">
                <div className="row">         
                    <Accordion>
                        <AccordionItem title={__("Alapadatok")}>
                            <div className="row">
                                <div className="column small-12">
                                    <fieldset className="fieldset">
                                        <legend>{__("*Típus")}:</legend>
                                        <div className="small-12 column">
                                            {
                                                this.state.itemTypes!.map((currentType: IViewKbItemTypeRecord) => {
                                                    return <div key={currentType.id} style={{display:"inline"}}>
                                                      <input disabled={this.state.isExistingKbItem} type="radio" name="command" id={currentType.id+""} checked={this.state.knowledgeBaseRecord!.type_id == currentType.id} onChange={this.onCommandChanged.bind(this)} />
                                                        <label htmlFor={currentType.id+""}>{currentType.title}</label>
                                                    </div>
                                                })
                                            }                                      
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            
                            <div className="row" >
                            <div className="column large-8 medium-6 small-12">
                                    <label htmlFor="subject">{__("*Tárgy")}:</label>
                                    <input placeholder={__("Kötelező (max. 500 karakter)")} id="subject" type="text" value={this.state.knowledgeBaseRecord.subject || ""} onChange={this.onSubjectChange.bind(this)} />
                            </div>
                                <div className="column large-4 medium-6 small-12"> 
                                    {this.state.knowledgeBaseRecord.creation_time ? <label style={{ whiteSpace: "nowrap", paddingTop: "2em" }}>{__("Létrehozva")}: {formatDate(this.state.knowledgeBaseRecord.creation_time)}</label> : ""}
                                </div>           
                            </div>
                            <div className="row" >
                                <div className="column large-8 medium-6 small-12">
                                    <label htmlFor="keywords">{__("*Kulcsszavak")}:</label>
                                    <MetaKeywordAssignerPlugin
                                        keywords={this.state.keywords}
                                        onAppendKeywords={this.onAppendKeywords}
                                        onDeleteKeyword={this.onDeleteKeyword}
                                    />
                                </div>
                                <div className="column large-4 medium-6 small-12 ">
                                    {this.state.knowledgeBaseRecord.creator ? <label style={{ whiteSpace: "nowrap", paddingTop: "4em" }}>{__("Létrehozó")}: {formatUserName(this.state.knowledgeBaseRecord.creator)}</label> : ""}
                                </div>
                            </div>
                            <div className="row">
                            <div className="column large-4 medium-6 small-12 large-offset-8 medium-offset-6">
                                    {this.state.knowledgeBaseRecord.modification_time ? <label style={{ whiteSpace: "nowrap", paddingTop: "4em" }}>{__("Módosítva")}: {formatDate(this.state.knowledgeBaseRecord.modification_time)} </label> : ""}
                                </div>
                            </div>
                            <div className="row" >                        
                                <div className="column large-4 medium-6 small-12 large-offset-8 medium-offset-6">
                                    {this.state.knowledgeBaseRecord.modifier ? <label style={{ whiteSpace: "nowrap", paddingTop: "4em" }}>{__("Módosító")}: {formatUserName(this.state.knowledgeBaseRecord.modifier)} </label> : ""}
                                </div>
                            </div>
                        </AccordionItem>
                        <AccordionItem title={__("Bejegyzés")}>
                            <div className="row" >
                                <div className="column large-12 medium-12 small-12">
                                    <label htmlFor="longdescription">{__("*Szöveg")}:</label>

                                    <HTMLTextarea
                                        value={this.state.knowledgeBaseRecord.description || ""}
                                        onChange={this.handleIntroChange.bind(this)}
                                    />
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="column large-12 medium-12 small-12">
                                    <label style={{ whiteSpace: "nowrap", paddingTop: "2em" }}>{__("Csatolmányok")}: </label>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <div className="column small-12" style={{ paddingTop: "1em" }}>
                                        {
                                            this.state.knowledgeBaseRecord.oo_folder_id
                                                ?
                                                <MediaFileChooser
                                                    currentFolderId={this.state.knowledgeBaseRecord.oo_folder_id}
                                                    rootFolderId={this.state.knowledgeBaseRecord.oo_folder_id}
                                                    enabledActions={FILE_ACTIONS}
                                                    showLocation={false}
                                                    showFolders={false}                                                 
                                                    onFolderContentChanged={() => this.setState({ isDataChanged: true })}                                                  
                                                />
                                                :
                                                <div className="column small-12 ">
                                                    <h4>{__("Melléklet feltöltéséhez először le kell menteni a bejegyzést!")}</h4>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </AccordionItem>
                    </Accordion>
                </div>
                <div className="row">
                    <div className="large-6 small-12 columns">
                        <Link className="button alert" to={PATH_KNOWLEDGE_BASE_LIST}><i className="fa fa-times" /> &nbsp;{__("Mégse")}</Link>
                    </div>
                    <div className="large-6 small-12 columns text-right">
                        <button className="button success" disabled={!this.state.isDataChanged} onClick={this.onSave.bind(this)}>
                            <i className="fa fa-save" /> &nbsp;{__("Mentés")}
                    </button>
                    </div>
                </div>
            </div>
            );

        } else {
            return <div><BubbleLoader /></div>
        }
    }

    private async onSave() {

        if (!this.state.knowledgeBaseRecord) {
            app.showError(__("Nem sikerült lementeni"), "");
            return;
        }

       if(!this.state.knowledgeBaseRecord.type_id)
       {
           app.showError(__("Hiba"),__("Kötelező kiválasztani a a bejegyzés típusát!"));
           return;
       }

       if(!this.state.knowledgeBaseRecord.subject)
       {
           app.showError(__("Hiba"),__("Kötelező megadni a bejegyzés tárgyát!"));
           return;
       }
       let currentkeywords = "";
        if (this.state.keywords && this.state.keywords.length > 0)

            for (let i = 0; i < this.state.keywords.length; i++) {
                i == 0 ? currentkeywords += this.state.keywords[i] : currentkeywords += "," + this.state.keywords[i]
            }

        else { app.showError(__("Hiba"), __("Legalább 1 kulcszót kötelező megadni!")); return }
       if(!this.state.knowledgeBaseRecord.description)
       {
           app.showError(__("Hiba"),__("Kötelező megadni a bejegyzés szövegét!"));
           return;
       }
     
        

        try {

            this.setState({ saving: true });

            if (!this.state.knowledgeBaseRecord.id) {
                this.state.knowledgeBaseRecord.creation_user_id = me!.id;
                this.state.knowledgeBaseRecord.modification_user_id = me!.id;
            } else {
                this.state.knowledgeBaseRecord.creation_user_id = undefined;
                this.state.knowledgeBaseRecord.modification_user_id = me!.id;
            }

           
            this.state.knowledgeBaseRecord.is_active = undefined;
            let kbItemToSave: IItemRecord =
            {
                id: this.state.knowledgeBaseRecord.id,
                description: this.state.knowledgeBaseRecord.description,
                subject: this.state.knowledgeBaseRecord.subject,
                type_id: this.state.knowledgeBaseRecord.type_id,
                oo_folder_id: this.state.knowledgeBaseRecord.oo_folder_id,
                keywords: currentkeywords
                
            }
            let newKbItemOnServer: IItemRecord = {};
            if (!kbItemToSave.id) {

                kbItemToSave.id = undefined;
                const newFolder = (await new OoFolderCrud({ parent_id: KB_ROOT_PARENT_FOLDER }).put()).record;
                kbItemToSave.oo_folder_id = newFolder.id;

            }
            newKbItemOnServer = (await new ItemCrud(kbItemToSave).put()).record;

            await setKeywords(
                ItemCrud.TABLE_INFO_ID, newKbItemOnServer.id!,
                this.state.keywords
            );

            app.showSuccess(__("bejegyzést lementve"), __("bejegyzést lementve"));

            if (newKbItemOnServer.id != this.getKbItemId()) {
                history.push(PATH_KNOWLEDGE_BASE_EDIT + "/" + newKbItemOnServer.id);
                this.setState({isExistingKbItem:true})
            } else if (newKbItemOnServer.id) {
                this.asyncReload();
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);

            this.setState({
                saving: false        
            })
        }
    }

    onSubjectChange(event: any) {

        let subject = event.target.value;
        this.setState({ isDataChanged: true, knowledgeBaseRecord: { ...this.state.knowledgeBaseRecord, subject: subject } });
    }

    handleIntroChange(text: any) {

        this.setState({ isDataChanged: true, knowledgeBaseRecord: { ...this.state.knowledgeBaseRecord, description: text } });
    }
    onCommandChanged(event: any) {

        let knowledgeBaseTypeId = event.target.id;
        this.setState({ isDataChanged: true, knowledgeBaseRecord: { ...this.state.knowledgeBaseRecord, type_id: Number(knowledgeBaseTypeId) } });
    }
};
