import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewUserSite1FirstLoginRecord {
    /**
     * Felhasználó azonosítója
     *
     * Felhasználó azonosítója
     */
    id?: number;
    /**
     * Bejelentkezési név
     *
     * Bejelentkezési név
     */
    login_name?: string|null;
    /**
     * E-mail cím
     *
     * E-mail cím
     */
    email?: string|null;
    /**
     * Aktív
     *
     * Aktív
     */
    is_active?: boolean|null;
    /**
     * Személy azonosító
     *
     * Személy azonosító
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * Teljes név
     */
    fullname?: string|null;
    /**
     * Első bejelentkezés időpontja
     *
     * Első bejelentkezés időpontja
     */
    first_login?: string /*timestamp?*/|null;
}

/**
 *  sys.view_user_site1_first_login - Elsődleges site-ra regisztrált felhasználók
 *
 *  Elsődleges site-ra regisztrált felhasználók
 */

export default class ViewUserSite1FirstLogin extends View<IViewUserSite1FirstLoginRecord> {
    public static VIEW_INFO_ID = 2019111801;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_user_site1_first_login'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewUserSite1FirstLogin>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewUserSite1FirstLoginRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewUserSite1FirstLoginClassProxy extends ViewClassProxy<IViewUserSite1FirstLoginRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewUserSite1FirstLoginClassProxy = new ViewUserSite1FirstLoginClassProxy(ViewUserSite1FirstLogin);
registerViewClassProxy(viewUserSite1FirstLoginClassProxy);

