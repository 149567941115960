import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IInstituteGroupRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Intézmény feladat ellátási hely
     *
     * NOT NULL -> sys.institute_site ON DELETE noaction
     *
     */
    institute_site_id?: number;
    /**
     * Tanosztály
     *
     * -> sys.institute_site_class ON DELETE noaction
     *
     */
    institute_site_class_id?: number|null;
    /**
     * Tanév
     *
     * -> doc.learning_year ON DELETE noaction
     *
     */
    learning_year_id?: number|null;
    /**
     * Osztályfőnök
     *
     * -> sys.sec_user ON DELETE noaction
     *
     */
    head_teacher_id?: number|null;
    /**
     * Név
     */
    name?: string;
    /**
     * Aktuális évfolyam
     *
     * -> doc.grade ON DELETE noaction
     *
     *
     * Az intézményi csoport évfolyama.
     */
    grade_id?: number|null;
    /**
     * Csoport mappa
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     *
     * A csoport saját mappája
     */
    oo_folder_id?: number;
}

/**
 *  sys.institute_group - Intézményi csoport
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class InstituteGroupCrud extends Crud<IInstituteGroupRecord> {
    public static TABLE_INFO_ID = 2863769223;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'institute_group'; }

    public static load: (id: number, aServer ?: Server) => Promise<InstituteGroupCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IInstituteGroupRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IInstituteGroupRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IInstituteGroupRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IInstituteGroupRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IInstituteGroupRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class InstituteGroupCrudClassProxy extends CrudClassProxy<IInstituteGroupRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const instituteGroupCrudClassProxy = new InstituteGroupCrudClassProxy(InstituteGroupCrud);
registerCrudClassProxy(instituteGroupCrudClassProxy);
