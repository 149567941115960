import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { QuizSeriesData } from "@src/component/exercise/engine/EKEQuizSeriesExerciseEngine/EKEQuizSeriesExerciseEngine";
import { CheckableMultiTypeAnswerItem, AnswerTypes } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseTypeConverter, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import * as React from "react";
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type QuizSeriesAnswerElement = {
    tasks: any[],
    num_of_questions: number,
    ordered: boolean,
    vertical_display: boolean,
    ordered_answers: boolean
}

export class EKEQuizSeriesConverter extends AExerciseTypeConverter {
    public hasTextAnswer = false;
    public hasImageAnswer = true;
    public hasTextImageAnswer = false;
    static MAX_LIMITED_NUM: number = 20;
    static MIN_ANSWERS_NUM: number = 2;
    static MAX_ANSWERS_NUM: number = 20;
    static MAX_TASKS_NUM: number = 30;
    static MAX_TASK_TEXT: number = 3000;
    constructor(props: any) {
        super(props);
        if (this.state.exercise === undefined) {
            this.state = { ...this.state, exercise: {} };
        }
    }

    render() {
        let curr_ex: QuizSeriesAnswerElement = this.state.exercise as QuizSeriesAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                <div className="row">
                    <div className="large-12 columns">
                        <label>{__("Megjelenített kérdések száma (0 = összes)")}
                            <input type="number" min="0" max="100" name="num_of_questions" value={curr_ex.num_of_questions || 0}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="ordered" checked={curr_ex.ordered || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Kérdések sorrendje eredeti")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="ordered_answers" checked={curr_ex.ordered_answers || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Válaszok sorrendje eredeti")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="vertical_display" checked={curr_ex.vertical_display || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Vertikális megjelenítés")}</label>
                    </div>
                </div>
                <Panel header={__("Kérdések")} headingLevel={5}>
                    {
                        curr_ex.tasks ?
                            curr_ex.tasks.map((curr_task: any, index) => {

                                let actions: IAccordionAction[];
                                actions = [
                                    {
                                        title: __("Törlés"),
                                        type: AccordionActionType.Trash,
                                        action: () => this.removeElement("tasks", index)
                                    },
                                    {
                                        title: __("Fel"),
                                        type: AccordionActionType.Up,
                                        action: () => this.moveUp("tasks", index)
                                    },
                                    {
                                        title: __("Le"),
                                        type: AccordionActionType.Down,
                                        action: () => this.moveDown("tasks", index)
                                    }
                                ];


                                return (
                                    <Accordion>
                                        <ActionAccordionItem defaultClosed={false} key={"tasks_" + index} actions={actions} title={__("{n}. kérdés", { n: index + 1 })}>
                                            <Panel>
                                                <div className="row">
                                                    <div className="large-12 columns">
                                                        <label><h4>{__("Kérdés")}</h4> <span className="exe-editor-validation-msg">{this.props.validationMessages.get("tasks[" + index + "].question")}</span>
                                                            <textarea name={"tasks#" + index + ".question"} rows={3} data-parent-index={index} data-index={index} value={curr_task.question}
                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                        </label>
                                                    </div>

                                                    <div className="small-12 columns">
                                                        <div className="row">
                                                            <div className="small-12 large-5 medium-12 columns">
                                                                <label className="exe-image-select-label">{__("Kérdés illusztráció")}
                                                                    {<ExerciseFileSelect
                                                                        imagebasepath={this.props.imagebasepath}
                                                                        value={curr_task.question_illustration || ""}
                                                                        onChange={this.handleImageChange.bind(this, "tasks#" + index + ".question_illustration")}
                                                                        getFolderId={this.getFolderId.bind(this)}
                                                                        fileType={ExerciseFileTypes.Image}
                                                                    />}
                                                                </label>
                                                            </div>
                                                            <div className="small-12 large-6 medium-12 columns">
                                                                <label>{__("Illusztráció képaláírása")}
                                                                    <input type="text" name={"tasks#" + index + ".question_illustration_alt"}
                                                                        value={curr_task.question_illustration_alt || ""}
                                                                        onChange={this.handleInputChange.bind(this)}
                                                                        onBlur={this.onBlurEvent.bind(this)} />
                                                                </label>
                                                            </div>
                                                            <div className="small-12 large-12 medium-12 columns">
                                                                <label className="exe-image-select-label">{__("Kérdés hang")}
                                                                    {<ExerciseFileSelect
                                                                        imagebasepath={this.props.imagebasepath}
                                                                        value={curr_task.question_sound || ""}
                                                                        onChange={this.handleFileChanged.bind(this, "tasks#" + index + ".question_sound")}
                                                                        getFolderId={this.getFolderId.bind(this)}
                                                                        fileType={ExerciseFileTypes.Sound}
                                                                    />}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <legend style={{ paddingTop: "10px" }}><h4>{__("Válaszok")}</h4></legend>
                                                    <div className="small-12 columns">
                                                        <label ><input type="checkbox" name={"tasks#" + index + ".multiple_answers"} checked={curr_task.multiple_answers || false}
                                                            onBlur={this.onBlurEvent.bind(this)}
                                                            onChange={this.handleInputChange.bind(this)} />
                                                            {__("Több helyes válasz")}</label>
                                                    </div>
                                                    {
                                                        curr_task.answers ?
                                                            curr_task.answers.map((curr_answer: CheckableMultiTypeAnswerItem, index_j: number) => {
                                                                return (<Panel>
                                                                    <legend>
                                                                        <label className="exe-editor-fieldlabel-1">{__("Válasz")} {index_j + 1}</label>
                                                                        <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "tasks#" + index + ".answers", index_j)}><i className="fa fa-trash"></i></button>
                                                                        <button className="button small exercise-series-small-btn" title="Fel" onClick={this.moveUp.bind(this, "tasks#" + index + ".answers", index_j)} ><i className="fa fa-arrow-up"></i></button>
                                                                        <button className="button small exercise-series-small-btn" title="Le" onClick={this.moveDown.bind(this, "tasks#" + index + ".answers", index_j)}><i className="fa fa-arrow-down"></i></button>
                                                                    </legend>
                                                                    <span className="exe-editor-validation-msg">{this.props.validationMessages.get("tasks[" + index + "].answers[" + index_j + "].type")}</span>
                                                                    <div className="row">
                                                                        <div className="large-4 small-12 columns">
                                                                            <label ><input type="checkbox" name={"tasks#" + index + ".answers#" + index_j + ".is_answer"} checked={curr_answer.is_answer || false}
                                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                                onChange={this.handleInputChange.bind(this)} />
                                                                                {__("Helyes?")}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="large-4 small-12 columns">
                                                                            <label> {__("Válasz típusa")}
                                                                                <select value={curr_answer.type} name={"tasks#" + index + ".answers#" + index_j + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                                                    <option value={AnswerTypes.text}>{__("Szöveg")}</option>
                                                                                    <option value={AnswerTypes.image}>{__("Kép")}</option>
                                                                                    <option value={AnswerTypes.sound}>{__("Hang")}</option>
                                                                                </select></label>
                                                                        </div>
                                                                        <div className="large-8 small-12 columns">
                                                                            <label>{curr_answer.type == AnswerTypes.text ? __("Válasz") : __("Leírás")}
                                                                                <input type="text" name={"tasks# " + index + ".answers#" + index_j + ".text"} data-parent-index={index} data-index={index} value={curr_answer.text}
                                                                                    onBlur={this.onBlurEvent.bind(this)}
                                                                                    onChange={this.handleInputChange.bind(this)} />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        {curr_answer.type != AnswerTypes.text ? <div className="large-12 columns">
                                                                            <label className="exe-image-select-label">{curr_answer.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                                                                {<ExerciseFileSelect
                                                                                    imagebasepath={this.props.imagebasepath}
                                                                                    value={curr_answer.url || ""}
                                                                                    onChange={this.handleImageChange.bind(this, "tasks# " + index + ".answers#" + index_j + ".url")}
                                                                                    getFolderId={this.getFolderId.bind(this)}
                                                                                    fileType={curr_answer.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                                                />}
                                                                            </label>
                                                                        </div> : ""}
                                                                    </div>
                                                                </Panel>
                                                                )
                                                            })
                                                            : ""
                                                    }
                                                </div>
                                            </Panel>
                                            <div className="row">
                                                <button className="button small" name={"tasks#" + index + ".btn-add-answer"} onClick={this.onAddNewAnswer.bind(this, index)}><i className="fa fa-plus"></i> {__("Válasz hozzáadása")}</button>
                                                <label className="exe-editor-label-description columns">{__("Egy kérdéshez minimum {min}, maximum {max} válaszelem tartozhat!", { min: EKEQuizSeriesConverter.MIN_ANSWERS_NUM, max: EKEQuizSeriesConverter.MAX_ANSWERS_NUM })}</label>
                                            </div>
                                        </ActionAccordionItem>
                                    </Accordion>)
                            })
                            : ""
                    }
                    <div className="row">
                        <button className="button small" onClick={this.onAddNewQuestion.bind(this)}><i className="fa fa-plus"></i> {__("Kérdés hozzáadása")}</button>
                        <label className="exe-editor-label-description columns">{__("A kérdések száma minimum {min} maximum {max} lehet!",{min: EKEQuizSeriesConverter.MIN_ANSWERS_NUM, max: EKEQuizSeriesConverter.MAX_TASKS_NUM})}</label>
                    </div>
                </Panel>
            </Panel>
        );
    }

    public convertToEditorAnswer(exercise: QuizSeriesData): QuizSeriesAnswerElement | undefined {
        if (exercise) {
            var questionList = [];
            var cur_exercise = exercise;
            if (cur_exercise.questions)
                for (var _i = 0; _i < cur_exercise.questions.length; _i++) {
                    if (cur_exercise.questions[_i].options) {
                        var answerList = [];
                        for (var _j = 0; _j < cur_exercise.questions[_i].options.length; _j++) {
                            var ans: CheckableMultiTypeAnswerItem = { is_answer: false, type: AnswerTypes.text };
                            var isAns = (cur_exercise.solution && cur_exercise.solution[_i]) ? cur_exercise.solution[_i].value.indexOf(_j) > -1 : false;
                            if (cur_exercise.questions[_i].options[_j].type == "image") {
                                ans =
                                    {
                                        url: cur_exercise.questions[_i].options[_j].image,
                                        text: cur_exercise.questions[_i].options[_j].text,
                                        is_answer: isAns,
                                        type: AnswerTypes.image
                                    };
                            }
                            else if (cur_exercise.questions[_i].options[_j].type == "sound") {
                                ans =
                                    {
                                        url: cur_exercise.questions[_i].options[_j].url,
                                        text: cur_exercise.questions[_i].options[_j].text,
                                        is_answer: isAns,
                                        type: AnswerTypes.sound
                                    };
                            }

                            else {
                                ans =
                                    {
                                        text: cur_exercise.questions[_i].options[_j].text,
                                        is_answer: isAns,
                                        type: AnswerTypes.text
                                    };
                            }
                            answerList.push(ans);
                        }

                        let curr_ans = {
                            question: cur_exercise.questions[_i].task,
                            question_illustration: cur_exercise.questions[_i].question_illustration,
                            question_sound: cur_exercise.questions[_i].question_sound,
                            question_illustration_alt: cur_exercise.questions[_i].question_illustration_alt,
                            answers: answerList,
                            multiple_answers: cur_exercise.questions[_i].multiple_answers
                        };
                        questionList.push(curr_ans);
                    }
                }
            var response: QuizSeriesAnswerElement = {
                tasks: questionList,
                num_of_questions: cur_exercise.num_of_questions,
                ordered: cur_exercise.ordered,
                vertical_display: cur_exercise.vertical_display,
                ordered_answers: cur_exercise.ordered_answers
            }
            return response;
        }
        else return undefined;
    }

    public convertToJson(answerList: any, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: QuizSeriesAnswerElement): QuizSeriesData | undefined {

        if (answerList) {
            var que_list = [];
            var sol_list = [];
            if (answerList.tasks) {
                for (var _i = 0; _i < answerList.tasks.length; _i++) {
                    var ans_list = [];
                    var sol_index_list = [];
                    var modified = false;

                    if (prevJSON != undefined && prevJSON.tasks != undefined && prevJSON.tasks[_i] != undefined && prevJSON.tasks[_i].multiple_answers != undefined
                        && prevJSON.tasks[_i].multiple_answers && !answerList.tasks[_i].multiple_answers) {
                        for (let index = 0; index < answerList.tasks[_i].answers.length; index++) {
                            answerList.tasks[_i].answers[index].is_answer = false;
                        }
                    }

                    for (var _j = 0; _j < answerList.tasks[_i].answers.length; _j++) {
                        if (answerList.tasks[_i].answers[_j].type == AnswerTypes.image) {
                            let curr_ans = {
                                type: "image",
                                text: answerList.tasks[_i].answers[_j].text,
                                image: answerList.tasks[_i].answers[_j].url
                            }
                            ans_list.push(curr_ans);
                        }
                        else if (answerList.tasks[_i].answers[_j].type == AnswerTypes.sound) {
                            let curr_ans = {
                                type: "sound",
                                text: answerList.tasks[_i].answers[_j].text,
                                url: answerList.tasks[_i].answers[_j].url
                            }
                            ans_list.push(curr_ans);
                        }

                        else {
                            let curr_ans = {
                                type: "text",
                                text: answerList.tasks[_i].answers[_j].text,
                                image: ""
                            }
                            ans_list.push(curr_ans);
                        }
                        if (!answerList.tasks[_i].multiple_answers && prevJSON != undefined && prevJSON.tasks != undefined &&  //not multipleanswers, and previously it was not answer
                            prevJSON.tasks[_i].answers[_j] != undefined && !prevJSON.tasks[_i].answers[_j].is_answer &&
                            answerList.tasks[_i].answers[_j].is_answer) {

                            sol_index_list = [];                //then deleting all the existing answers
                            modified = true;                    //remember that it was modified
                            sol_index_list.push(_j);            //and storing this from now
                        }
                        else if (!answerList.tasks[_i].multiple_answers && !modified && answerList.tasks[_i].answers[_j].is_answer) {
                            sol_index_list.push(_j)            //not multipleanswer, was not modified than this is the answer
                        }
                        else if (!answerList.tasks[_i].multiple_answers && modified && answerList.tasks[_i].answers[_j].is_answer) {
                            answerList.tasks[_i].answers[_j].is_answer = false;            //if modification happened, delete the previously set answers' booleans
                        }
                        else if (answerList.tasks[_i].multiple_answers && answerList.tasks[_i].answers[_j].is_answer)
                            sol_index_list.push(_j);                        //in case of multiple answers we store all the correct solutions
                    }

                    var solution = {
                        key: _i,
                        value: sol_index_list
                    };
                    sol_list.push(solution);

                    var question = {
                        task: answerList.tasks[_i].question,
                        question_illustration: answerList.tasks[_i].question_illustration,
                        question_illustration_alt: answerList.tasks[_i].question_illustration_alt,
                        question_sound: answerList.tasks[_i].question_sound,
                        options: ans_list,
                        multiple_answers: answerList.tasks[_i].multiple_answers
                    };
                    que_list.push(question);
                }
            }
            var num_of_questions = answerList.num_of_questions;
            if (answerList.num_of_questions <= 0 || answerList.num_of_questions > que_list.length) {
                num_of_questions = que_list.length;
            }

            var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
            cur_exercise = {
                ...cur_exercise,
                questions: que_list,
                solution: sol_list,
                ordered: answerList.ordered,
                vertical_display: answerList.vertical_display,
                ordered_answers: answerList.ordered_answers,
                num_of_questions: num_of_questions
            };
            return cur_exercise;
        }
        else
            return undefined;
    }
    public validate(editorAnswer: QuizSeriesAnswerElement, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData, validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        let errorMsg = "";
        let OFIErrors: string[] = [];
        if (editorAnswer.tasks.length > EKEQuizSeriesConverter.MAX_TASKS_NUM) {
            errorMsg = __("A kérdések száma maximum {max} lehet!", { max: EKEQuizSeriesConverter.MAX_TASKS_NUM });
            if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
        }
        if (editorAnswer.tasks.length < 2) {
            errorMsg = __("Minimum 2 kérdés megadása kötelező!");
            if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            return { valid: false, message: errorMsg }
        }
        if (editorAnswer.num_of_questions > EKEQuizSeriesConverter.MAX_LIMITED_NUM || (!AExerciseTypeConverter.isOfiEditor && editorAnswer.num_of_questions < 0 || editorAnswer.num_of_questions == 1)) {
            errorMsg = __("A megjelenített elemszám min. {min}, max. {max} lehet!", {min:2, max: EKEQuizSeriesConverter.MAX_LIMITED_NUM });
            if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            return { valid: false, message: errorMsg }
        }

        for (let i = 0; i < editorAnswer.tasks.length; i++) {
            let curr_task = editorAnswer.tasks[i];
            if (curr_task.answers && (curr_task.answers.length < EKEQuizSeriesConverter.MIN_ANSWERS_NUM || (curr_task.answers.length > EKEQuizSeriesConverter.MAX_ANSWERS_NUM))) {
                errorMsg = __("Egy kérdéshez minimum {min}, maximum {max} válaszelem tartozhat!", { min: EKEQuizSeriesConverter.MIN_ANSWERS_NUM, max: EKEQuizSeriesConverter.MAX_ANSWERS_NUM });
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
            }
            if (curr_task.question && curr_task.question.length > EKEQuizSeriesConverter.MAX_TASK_TEXT) {
                errorMsg = __("A kérdések karakterszáma maximum {max} lehet!", { max: EKEQuizSeriesConverter.MAX_TASK_TEXT });
                validationMap!.set("tasks[" + i + "].question", errorMsg);
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
            }
        }
        if (AExerciseTypeConverter.isOfiEditor) {
            return { valid: true, message: OFIErrors.join(' , ') }
        }

        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: QuizSeriesAnswerElement, newState: QuizSeriesAnswerElement): ValidationResponse {
        if (newState.tasks) {
            if (!AExerciseTypeConverter.isOfiEditor && newState.tasks.length > EKEQuizSeriesConverter.MAX_TASKS_NUM) {
                newState.tasks.splice(EKEQuizSeriesConverter.MAX_TASKS_NUM, newState.tasks.length - EKEQuizSeriesConverter.MAX_TASKS_NUM);
                return { valid: false, message: __("A kérdések száma maximum {max} lehet!", { max: EKEQuizSeriesConverter.MAX_TASKS_NUM }) }
            }
            for (var _i = 0; _i < newState.tasks.length; _i++) {
                var modified = false;
                if (!newState.tasks[_i].multiple_answers) {
                    for (var _j = 0; _j < newState.tasks[_i].answers.length; _j++) {
                        //if there is a previous state we check if the solution was there false and now it has changed to true then this is the new solution
                        if (oldState != undefined && oldState.tasks != undefined && oldState.tasks[_i].answers[_j] != undefined &&
                            !oldState.tasks[_i].answers[_j].is_answer && newState.tasks[_i].answers[_j].is_answer) {

                            for (let index = 0; index < newState.tasks[_i].answers.length; index++) {
                                if (index != _j) newState.tasks[_i].answers[index].is_answer = false;
                            }
                            modified = true;
                            break;
                        }
                        if (modified) break;
                        //if number of answers is not in range of EKEQuizSeriesConverter.MIN_ANSWERS_NUM to EKEQuizSeriesConverter.MAX_ANSWERS_NUM we add or remove answers
                    }
                }
                if (!AExerciseTypeConverter.isOfiEditor && newState.tasks[_i].answers) {
                    if (newState.tasks[_i].answers.length > EKEQuizSeriesConverter.MAX_ANSWERS_NUM) {
                        newState.tasks[_i].answers.splice(EKEQuizSeriesConverter.MAX_ANSWERS_NUM, newState.tasks[_i].answers.length - EKEQuizSeriesConverter.MAX_ANSWERS_NUM);
                        return { valid: false, message: __("Egy kérdéshez minimum {min}, maximum {max} válaszelem tartozhat!", { min: EKEQuizSeriesConverter.MIN_ANSWERS_NUM, max: EKEQuizSeriesConverter.MAX_ANSWERS_NUM }) }
                    }
                }
            }
        }
        return { valid: true }
    }

    public isSolutionAvailable(exerciseJson: ExerciseBaseTypes.ExerciseBaseClass): boolean {
        if (!super.isSolutionAvailable(exerciseJson))
            return false;

        if (exerciseJson.questions.length < 2) return false;
        for (let index = 0; index < exerciseJson.questions.length; index++) {
            if (exerciseJson.questions[index].options.length < 2) return false;
        }
        var missingSolution = true;
        let curr_solution = exerciseJson.solution;

        for (let index = 0; index < curr_solution.length; index++) {
            if (curr_solution[index] != undefined) {

                missingSolution = false;
                if (curr_solution[index].value.length == 0) return false;
            }
        }
        return !missingSolution;
    }

    onAddNewQuestion() {
        let temp_exercise = this.state.exercise as QuizSeriesAnswerElement;
        if (!temp_exercise.tasks) temp_exercise.tasks = [];
        temp_exercise.tasks.push(this.getNewQuestion());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    getNewQuestion(): ExerciseBaseTypes.QuestionAnswerElement {
        let newElement: ExerciseBaseTypes.QuestionAnswerElement = {
            question: "",
            answers: []
        };
        return newElement;
    }

    onAddNewAnswer(index: number) {
        let temp_exercise = this.state.exercise as QuizSeriesAnswerElement;
        if (!temp_exercise.tasks[index].answers) temp_exercise.tasks[index].answers = [];
        temp_exercise.tasks[index].answers.push(this.getNewAnswer());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    getNewAnswer(): ExerciseBaseTypes.CheckableAnswerElement {
        let newElement: ExerciseBaseTypes.CheckableMultiTypeAnswerItem = {
            is_answer: false,
            type: AnswerTypes.text,
        };
        return newElement;
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];

        statisticsResponses.push({ name: __("limit - kérdések max. száma: {n}", { n: EKEQuizSeriesConverter.MAX_TASKS_NUM }), count: undefined });
        statisticsResponses.push({ name: __("limit - kérdések min. száma: {n}", { n: EKEQuizSeriesConverter.MIN_ANSWERS_NUM }), count: undefined });
        statisticsResponses.push({ name: __("limit - válaszok max. száma: {n}", { n: EKEQuizSeriesConverter.MAX_ANSWERS_NUM }), count: undefined });
        statisticsResponses.push({ name: __("limit - válaszok min. száma: {n}", { n: EKEQuizSeriesConverter.MIN_ANSWERS_NUM }), count: undefined });
        statisticsResponses.push({ name: __("limit - limitált elemszám: {n}", { n: EKEQuizSeriesConverter.MAX_LIMITED_NUM }), count: undefined });
        statisticsResponses.push({ name: __("limit - kérdés karakterszám: {n}", { n: EKEQuizSeriesConverter.MAX_TASK_TEXT }), count: undefined });

        let taskStat, answersStat, answerStatTotal, limitedNumberStat, questionTextStat: StatisticsResponse;
        taskStat = { name: __("Kérdések száma"), count: new Map() }
        answersStat = { name: __("Válaszok száma"), count: new Map() }
        answerStatTotal = { name: __("A válaszok teljes száma"), count: new Map() }
        limitedNumberStat = { name: __("Megjelenített kérdések száma"), count: new Map() }
        questionTextStat = { name: __("kérdés hossz"), count: new Map() }
        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const currentExc = exerciseList[i];
            let task_count = taskStat.count.has(currentExc.tasks.length) ? taskStat.count.get(currentExc.tasks.length) : 0;
            taskStat.count.set(currentExc.tasks.length, task_count! + 1);

            let ln_number = currentExc.num_of_questions;
            let lim_num_count = limitedNumberStat.count.has(ln_number) ? taskStat.count.get(ln_number) : 0;
            limitedNumberStat.count.set(ln_number, lim_num_count! + 1);

            let counter = 0;
            for (let j = 0; j < currentExc.tasks.length; j++) {
                const curr_answer = currentExc.tasks[j];
                if (curr_answer.answers) {
                    let nearHundred = Math.ceil(curr_answer.question.length / 100) * 100;
                    let quest_char_count = questionTextStat.count!.has(nearHundred) ? questionTextStat.count!.get(nearHundred) : 0;
                    questionTextStat.count!.set(nearHundred, quest_char_count! + 1);

                    counter += curr_answer.answers.length;
                    let answer_count = answersStat.count.has(curr_answer.answers.length) ? answersStat.count.get(curr_answer.answers.length) : 0;
                    answersStat.count.set(curr_answer.answers.length, answer_count! + 1);
                }
            }
            let total_answer_count = answerStatTotal.count.has(counter) ? answerStatTotal.count.get(counter) : 0;
            answerStatTotal.count.set(counter, total_answer_count! + 1);
        }
        statisticsResponses.push(taskStat, answersStat, answerStatTotal, limitedNumberStat, questionTextStat);
        return statisticsResponses;
    }

}