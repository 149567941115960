import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewTransitionTypeRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Folyamat típus
     */
    type_id?: number;
    /**
     * Start állomás
     */
    src_station_id?: number;
    /**
     * Cél állomás
     */
    dst_station_id?: number;
    /**
     * Indoklás kötelező
     */
    must_justify?: boolean;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Folyamat típus kód
     *
     * A folyamat típus kódja
     */
    wf_type_code?: string|null;
    /**
     * Folyamat típus név
     *
     * A folyamat típus neve
     */
    wf_type_name?: string|null;
    /**
     * Kiinduló állomás neve
     *
     * Az átmenet ebből az állomásból indul
     */
    src_station_name?: string|null;
    /**
     * Forrás állapot leírása
     */
    src_station_description?: string|null;
    /**
     * Kiinduló állomás stílusa
     *
     * Az átmenet ebből az állomásból indul
     */
    src_station_style?: any|null;
    /**
     * Kiinduló állomás aktív
     *
     * Kiinduló állomás aktív
     */
    src_is_active?: boolean|null;
    /**
     * Kiinduló állomás kezdő
     *
     * Kiinduló állomás kezdő
     */
    src_is_start?: boolean|null;
    /**
     * Kiinduló állomás zárt
     *
     * Kiinduló állomás zárt
     */
    src_is_closed?: boolean|null;
    /**
     * Kiinduló állomás elszámolható
     *
     * Kiinduló állomás elszámolható
     */
    src_is_eligible?: boolean|null;
    /**
     * Kiinduló állomás törölt
     *
     * Kiinduló állomás törölt
     */
    src_is_deleted?: boolean|null;
    /**
     * Cél állomás neve
     *
     * Az átmenet ebben az állomásban végződik
     */
    dst_station_name?: string|null;
    /**
     * Cél állapot leírása
     */
    dst_station_description?: string|null;
    /**
     * Cél állomás stílusa
     *
     * Az átmenet ebben az állomásban végződik
     */
    dst_station_style?: any|null;
    /**
     * Cél állomás aktív
     *
     * Cél állomás aktív
     */
    dst_is_active?: boolean|null;
    /**
     * Cél állomás kezdő
     *
     * Cél állomás kezdő
     */
    dst_is_start?: boolean|null;
    /**
     * Cél állomás zárt
     *
     * Cél állomás zárt
     */
    dst_is_closed?: boolean|null;
    /**
     * Cél állomás elszámolható
     *
     * Cél állomás elszámolható
     */
    dst_is_eligible?: boolean|null;
    /**
     * Cél állomás törölt
     *
     * Cél állomás törölt
     */
    dst_is_deleted?: boolean|null;
}

/**
 *  wf.view_transition_type - Folyamat átmenet típus
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewTransitionType extends View<IViewTransitionTypeRecord> {
    public static VIEW_INFO_ID = 2018040504;

    public static getSchemaNameForClass(): string { return 'wf'; }
    public static getViewNameForClass(): string { return 'view_transition_type'; }
    public static getTableNameForClass(): string|null { return 'transition_type'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewTransitionType>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewTransitionTypeRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewTransitionTypeClassProxy extends ViewClassProxy<IViewTransitionTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewTransitionTypeClassProxy = new ViewTransitionTypeClassProxy(ViewTransitionType);
registerViewClassProxy(viewTransitionTypeClassProxy);

