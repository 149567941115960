import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IFavouriteRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Alkalmazás szintű felhasználó
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     *
     * Aki a kedveli
     */
    sec_user_id?: number;
    /**
     * Kedvenc tartalom - Táblázat infó
     *
     * NOT NULL -> meta.table_info ON DELETE noaction
     *
     *
     * Ez mondja meg, hogy a folyamat melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number;
    /**
     * Kedvenc tartalom - rekord azonosító
     *
     * Ez mondja meg, hogy a folyamat melyik rekordhoz tartozik.
     */
    rec_id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
}

/**
 *  usr.favourite - Kedvenc
 *
 *  Kedvenc tartalom
 */

export default class FavouriteCrud extends Crud<IFavouriteRecord> {
    public static TABLE_INFO_ID = 2282413303;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'favourite'; }

    public static load: (id: number, aServer ?: Server) => Promise<FavouriteCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IFavouriteRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IFavouriteRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IFavouriteRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IFavouriteRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IFavouriteRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class FavouriteCrudClassProxy extends CrudClassProxy<IFavouriteRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const favouriteCrudClassProxy = new FavouriteCrudClassProxy(FavouriteCrud);
registerCrudClassProxy(favouriteCrudClassProxy);
