import * as React from "react";
import { __ } from "@src/translation";
import AccessDeniedPage from "@src/framework/pages/AccessDeniedPage";
import { hasAnyGroup, me, Groups } from "@src/framework/server/Auth";
import ViewKapCourseUser, { IViewKapCourseUserRecord } from "@src/framework/view/kap/ViewKapCourseUser";
import { app } from "@src/index";
import * as _ from 'lodash';
import { TFilterDict } from "@src/framework/crud/Crud";
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { Link } from "react-router-dom";
import { Module } from "@src/module/Module";
import CourseCrud from "@src/framework/crud/kap/CourseCrud";

type CourseUserCertificateFinderProps = {}

type CourseUserCertificateFinderState = {
    loading: boolean;
    items: IViewKapCourseUserRecord[];
    cert_num: string;
    ver_num: string;
    participant_fullname: string;
}

const ASYNC_RELOAD_DEBOUNCE_MSEC = 500;

export default class CourseUserCertificateFinder extends React.Component<CourseUserCertificateFinderProps, CourseUserCertificateFinderState> {
    private debouncedAsyncReload: () => void;

    constructor(props: any) {
        super(props);
        this.debouncedAsyncReload = _.debounce(
            this.asyncReload, ASYNC_RELOAD_DEBOUNCE_MSEC
        )
        this.state = { loading: true, items: [], cert_num: "", ver_num: "", participant_fullname: "" };
    }

    async asyncReload() {
        this.setState({ loading: true });
        try {
            const filter : TFilterDict[] = [{is_active: true}];
            ["cert_num","ver_num","participant_fullname"].forEach( (fieldName) => {
                const expression = this.state[fieldName];
                if (expression) {
                    filter.push({"$text": {fieldNames: [fieldName], expression}})
                }    
            });
            const items = await (ViewKapCourseUser.list({filter: {"$and": filter}}));
            this.setState({ items, loading: false });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onFilterChange = (fieldName: string, e:any) => {
        const value = e.target.value;
        let state = this.state as any;
        state[fieldName] = value;
        state.loading = true;
        this.setState(state, this.debouncedAsyncReload)
    }


    render() {

        if (!me || !hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapKszrCourseCoordinator]))
            return <AccessDeniedPage />

        return <>
            <div key="filter-container">
                <fieldset className="large-12 row">
                    <legend>Gyors szűrés</legend>
                    <div className={"column small-12 large-3"}>
                        <label htmlFor="cert_num">{__("Tanúsítvány szám")} </label>
                        <input type="text" id="cert_num" value={this.state.cert_num}
                            onChange={(e) => this.onFilterChange('cert_num', e)}
                        />
                    </div>
                    <div className={"column small-12 large-3"}>
                        <label htmlFor="ver_num">{__("Igazolás szám")} </label>
                        <input type="text" id="ver_num" value={this.state.ver_num}
                            onChange={(e) => this.onFilterChange('ver_num', e)}
                        />
                    </div>
                    <div className={"column small-12 large-3"}>
                        <label htmlFor="participant_fullname">{__("Résztvevő neve")} </label>
                        <input type="text" id="participant_fullname" value={this.state.participant_fullname}
                            onChange={(e) => this.onFilterChange('participant_fullname', e)}
                        />
                    </div>
                </fieldset>
            </div>

            <ReactTable
                columns={[
                    {Header: "Képzés ID", accessor: "regno", maxWidth: 100,
                    Cell: (data) => {
                        const row : IViewKapCourseUserRecord = data.row;
                        const url = Module.getDefaultRecordRoute(CourseCrud.TABLE_INFO_ID, row.course_id!, true);
                        if (url) {
                            return <Link to={url.route}>{row.regno}</Link>;
                        } else {
                            return <span>{row.regno}</span>
                        }
                    }},
                    {Header: "Képzés típus", accessor: "training_type_code", maxWidth: 100,},
                    {Header: "Résztvevő", accessor: "participant_fullname"},
                    {Header: "Tanúsítvány/Igazolás", accessor: "cert_num",
                        Cell: (data) => {
                            const row : IViewKapCourseUserRecord = data.row;
                            if (row.cert_num) {
                                return <b>{row.cert_num}</b>
                            } else if (row.ver_num) {
                                return <span>{row.ver_num}</span>
                            } else {
                                return <div></div>;
                            }
                        }        
                    },
                ]}
                data={this.state.items}
                filterable={false}
                className="-striped -highlight"
            />            

        </>;



    }
}