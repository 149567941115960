import { Section } from "../server/EditorServer";

export type TemplateVariable = {
    type: "text" | "html" | "iframe" | "video" | "link" | "exercise" | "exercise-series" | "file";
    id: string;
    title: string;
}

export default class SectionTemplate {

    html: string;

    element: HTMLElement;

    variables: TemplateVariable[];

    constructor(templateHTML: string) {
        this.html = templateHTML;

        this.variables = [];
        
        this.element = document.createElement("div");
        this.element.innerHTML = templateHTML;

        const elements = Array.from(this.element.querySelectorAll("*"));
        
        for(var element of elements) {
            const tagName = element.tagName.toLocaleLowerCase();

            if (tagName.startsWith("x-")) {
                const type = tagName.substr(2);
                const id = element.getAttribute("data-id");
                const title = element.getAttribute("data-title");

                if ((type == "text" || type == "html" || type == "iframe" || type == "video" || type == "link" || type == "exercise" || type == "exercise-series"  || type == "file") && id && title !== null) {
                    this.variables.push({
                        type: type,
                        id: id,
                        title: title,
                    });
                } else {
                    console.log("Could not parse template tag: " , element);
                }
            }
        }
    }

    fill(section: Section) {
        const e: HTMLElement = <HTMLElement>this.element.cloneNode(true);

        for(var element of Array.from(e.querySelectorAll("*"))) {
            if (!element.tagName.startsWith("X-")) continue;

            const key = element.getAttribute("data-id") || "";

            const value = section.values[key];
            if (!value) {
                element.remove();
                continue;
            }
        
            const valueElement = document.createElement("div");
            if (element.nodeName == "X-VIDEO") {
                var posterAttr = "";
                if (value.indexOf(".mp4") !== -1) {
                    posterAttr = "poster=\"" + value.replace(".mp4", ".png") + "\"";
                }
                valueElement.innerHTML = `<video src="${value}" ${posterAttr} controls=true width="100%" preload="none"/>`;

                const options = section.values[key + "-options"];
                if (options && options.icons) {
                    for(const iconKey of Object.keys(options.icons)) {
                        if (options.icons[iconKey]) {
                            valueElement.insertBefore(document.createElement("span"), valueElement.firstChild).className="section-options-icon " + iconKey;
                        }
                    }
                }
                
            } else if (element.nodeName == "X-IFRAME") {
                valueElement.innerHTML = `<iframe src="${value}" width="100%" height="100%" scrolling="no"/>`;

                const options = section.values[key + "-options"];
                if (options && options.aspectRatioClassName) {
                    element.parentElement!.classList.add(options.aspectRatioClassName);
                }

            } else if (element.nodeName == "X-EXERCISE") {
                valueElement.innerHTML = `<div data-exercise-id="${value}"/>`;
            } else if (element.nodeName == "X-EXERCISE-SERIES") {
                valueElement.innerHTML = `<div data-exercise-series-id="${value}"/>`;
            } else if(element.nodeName == "X-LINK") {
                const link = element.closest('a');
                if(link && link.getAttribute("data-x-link"))
                {
                    (link as  HTMLAnchorElement).href = value;
                }
            } else {
                valueElement.innerHTML = value;
            }
            element.parentElement!.replaceChild(valueElement, element);

            
            var parent = valueElement.parentNode as Element;
            while( valueElement.firstChild ) {
                parent.insertBefore(  valueElement.firstChild, valueElement );
            }
            parent.removeChild( valueElement );

        }



        return e.innerHTML.replace(/\[\[id\]\]/g, ""+section.id);
    }
    
}

export function isRevealSection(html: string) {
    return html.indexOf("data-reveal") !== -1;
}

export function getRevealIdAndName(html: string) {
    if (html.indexOf("data-reveal") !== -1 && html.indexOf("data-menu-name") !== -1) {
        const div = document.createElement("div");
        div.innerHTML = html;
        const modal = div.querySelector("[data-reveal]");
        const name =  modal!.getAttribute("data-menu-name");
        if (modal) {
            return {id: modal.id || "", name: name || ""};
        }
    }

    return null;
}
