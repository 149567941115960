import * as React from 'react';
import { __ } from '@src/translation';

export class Impressum extends React.Component {
    render() {
        return <div className="impressum-wrapper">
         {/* <div className="szechenyi"></div> */}
            <div className="row align-center text-center" style={{maxWidth: "650px"}}>
                <h1>{__("Impresszum")}</h1>
                <div>
                    <div className="block">
                        <p className="title" style={{fontSize: "1.3rem", fontFamily: "'BloggerSans', 'OpenSans', sans-serif"}}>A Nemzeti Köznevelési Portálon található valamennyi tartalom jogtulajdonosa, kezelője és kiadója:</p>
                        <p>Oktatási Hivatal</p>
                        <img src="/img/impressum/oh_logo_HU.svg" style={{ height: "130px" }}></img>
                    </div>
                    <div className="row">
                        <div className="large-6 medium-6 small-12 column">
                            <p className="title align-right">Székhelye:</p>
                        </div>
                        <div className="large-6 medium-6 small-12 column">
                            <p className="align-left">1055 Budapest, Szalay utca 10-14.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="large-6 medium-6 small-12 column">
                            <p className="title align-right">Államháztartási nyilvántartási száma:</p>
                        </div>
                        <div className="large-6 medium-6 small-12 column">
                            <p className="align-left">229726</p>
                        </div>
                        <div className="large-6 medium-6 small-12 column">
                            <p className="title align-right">Kincstári nyilvántartási száma:</p>
                        </div>
                        <div className="large-6 medium-6 small-12 column">
                            <p className="align-left">329727</p>
                        </div>
                        <div className="large-6 medium-6 small-12 column">
                            <p className="title align-right">Adószáma:</p>
                        </div>
                        <div className="large-6 medium-6 small-12 column">
                            <p className="align-left">15329729-2-41</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="large-6 medium-6 small-12 column">
                            <p className="title align-right">Honlap címe:</p>
                        </div>
                        <div className="large-6 medium-6 small-12 column">
                            <p className="align-left"><a href="https://www.nkp.hu/" target="_blank">www.nkp.hu</a></p>
                        </div>
                        <div className="large-6 medium-6 small-12 column">
                            <p className="title align-right">Elektronikus levelezési címe:</p>
                        </div>
                        <div className="large-6 medium-6 small-12 column">
                            <p className="align-left"><a href="mailto:tamogatas@nkp.hu">tamogatas@nkp.hu</a></p>
                        </div>
                    </div>
                    <div className="block">
                        <p className="title larger">Alkalmazásüzemeltetést, rendszerüzemeltetést ellátó:</p>
                        <p>Oktatási Hivatal<br />(1055 Budapest, Szalay utca 10-14.)</p>
                        <img src="/img/impressum/oh_logo_HU.svg"></img>
                    </div>
                    <div className="block">
                        <p className="title larger">Szerverkapacitás biztosítását, részleges rendszerüzemeltetést ellátó:</p>
                        <p>Kormányzati Informatikai Fejlesztési Ügynökség<br />(1134 Budapest, Váci út 335.)</p>
                        <img src="/img/impressum/kifu_logo.png"></img>
                    </div>
                    <div className="block">
                        <p className="title larger">Fejlesztést, alkalmazásüzemeltetést és részleges rendszerüzemeltetést ellátó:</p>
                        <p>Eszterházy Károly Egyetem<br />(3300 Eger, Eszterházy tér 1.)</p>
                        <img src="/img/impressum/eke-logo.svg"></img>
                    </div>
                </div>
            </div>
        </div>
    }
}