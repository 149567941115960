import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISectionRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Lecke
     *
     * NOT NULL -> doc_pub.lesson ON DELETE cascade
     *
     */
    lesson_id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Blokk sablon
     *
     * NOT NULL -> doc.block_template ON DELETE cascade
     *
     */
    block_template_id?: number;
    /**
     * Értékek
     */
    values?: any;
    /**
     * Feladat
     *
     * -> exc_pub.exercise ON DELETE noaction
     *
     */
    exercise_id?: number|null;
    /**
     * Feladatlista
     *
     * -> exc_pub.exercise_series ON DELETE noaction
     *
     */
    exercise_series_id?: number|null;
    /**
     * Segítő szöveg
     *
     * Segítő szöveg, elsősorban alsós koncepcióhoz.
     */
    help_text?: string|null;
    /**
     * Feladat alapból elrejtve jelenik meg
     */
    exercise_is_hidden_by_default?: boolean;
    /**
     * Tanári segédanyagok között jelenik meg
     */
    is_teachers_guide?: boolean;
}

/**
 *  doc_pub.section - Szekció
 */

export default class SectionCrud extends Crud<ISectionRecord> {
    public static TABLE_INFO_ID = 4164553645;
    public static getSchemaNameForClass() { return 'doc_pub'; }
    public static getTableNameForClass() { return 'section'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<SectionCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISectionRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISectionRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISectionRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISectionRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISectionRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SectionCrudClassProxy extends CrudClassProxy<ISectionRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const sectionCrudClassProxy = new SectionCrudClassProxy(SectionCrud);
registerCrudClassProxy(sectionCrudClassProxy);
