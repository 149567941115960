export class EKEQuizServer{

    static evaluateOnServer(exercise: any, correctSolution: any[], userSolution: number[]): Evaluated {
        var success = true;
        var successPercent = 0;
        var correctAnsNum = 0;

        for (let i = 0; i < correctSolution.length; i++) {
            if (userSolution.indexOf(correctSolution[i]) == -1)
                success = false;
            else
                correctAnsNum++;
        }

        if (userSolution.length > correctSolution.length) {
            correctAnsNum = correctAnsNum - (userSolution.length - correctSolution.length);
            success = false;
        }
        if (correctAnsNum > 0)  // the percentage cannot be negative
            successPercent = Number((correctAnsNum / correctSolution.length).toFixed(2)); // 2 digits

        return { "success": success, "solution": correctSolution, "successPercent": successPercent };
    }
}