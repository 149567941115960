import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewDlMaterialRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Kiadói kód
     */
    publisher_code?: string;
    /**
     * Leírás
     */
    description_html?: string|null;
    /**
     * A letölthető anyagok gyökér könyvtára.
     */
    oo_folder_id?: number;
    /**
     * Borítókép
     */
    thumbnail_file_id?: number|null;
    /**
     * Tananyag
     */
    material_file_id?: number|null;
    /**
     * Kiegészítő adatok
     */
    props?: any|null;
    /**
     * Site
     */
    site_id?: number;
    /**
     * Thumbnail
     *
     * Thumbnail SHA1 hash érték
     */
    thumbnail_file_sha1?: string|null;
    /**
     * Tantárgyak
     *
     * Tantárgyak (tankönyvkatalógusos
     */
    subject_ids?: number[]|null;
}

/**
 *  doc_pub.view_dl_material - Letölthető tananyag
 */

export default class ViewDlMaterial extends View<IViewDlMaterialRecord> {
    public static VIEW_INFO_ID = 2019032601;

    public static getSchemaNameForClass(): string { return 'doc_pub'; }
    public static getViewNameForClass(): string { return 'view_dl_material'; }
    public static getTableNameForClass(): string|null { return 'dl_material'; }

    public static canCache(): boolean {
        return true;
    }

    public static load: (id: number, aServer ?: Server) => Promise<ViewDlMaterial>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewDlMaterialRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewDlMaterialClassProxy extends ViewClassProxy<IViewDlMaterialRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewDlMaterialClassProxy = new ViewDlMaterialClassProxy(ViewDlMaterial);
registerViewClassProxy(viewDlMaterialClassProxy);

