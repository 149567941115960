import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewExcSeriesRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Sorrend
     */
    no?: number /*int4*/|null;
    /**
     * Cím
     */
    title?: string;
    /**
     * Bevezető szöveg
     */
    introduction?: string|null;
    /**
     * Évfolyam
     */
    grade_id?: number|null;
    /**
     * Tantárgy
     */
    subject_id?: number|null;
    /**
     * Könyvtár
     */
    library_id?: number;
    /**
     * Szint
     *
     * A feladatsor nehézségi foka, 1=könnyű 2=közepes 3=nehéz
     */
    level?: number /*int4*/;
    /**
     * Fix sorrend
     *
     * A feladatok pontosan a meghatározott sorrendben követik egymást.
     */
    fix_order?: boolean;
    /**
     * Feladat kiválasztás módja
     */
    exc_selection_mode_id?: number;
    /**
     * Feladatok darabszáma
     *
     * Csak akkor, ha a kiválasztás módja: megadott darabszámú kérdés.
     */
    exc_count?: number /*int4*/|null;
    /**
     * Feladatok min. összpontszáma
     *
     * Csak akkor, ha a kiválasztás módja: fix összpontszámú feladatsor.
     */
    minpoints?: number /*int4*/|null;
    /**
     * Elérhető pontszám kijelzése
     */
    show_max_points?: boolean;
    /**
     * Feladat üzemmód
     */
    exc_display_mode_id?: number;
    /**
     * Max. megoldási idő
     *
     * A megoldásra használható maximális idő percben.
     */
    max_solution_time_min?: number /*int4*/|null;
    /**
     * Megszakítható
     *
     * A feladatsor megoldása megszakítható.
     */
    can_interrupt?: boolean;
    /**
     * Sikeresség határa (százalék)
     */
    success_limit_percent?: number /* float8 */;
    /**
     * Megjelenés helye
     *
     * Mappa, ahol a feladatsor a virtuális állományrendszerben megjelenik.
     */
    show_in_folder_id?: number|null;
    /**
     * Adaptív
     *
     * Az adaptív feladatsorok témakörökre és nehézségi kategóriákra osztják a feladatokat.
     */
    is_adaptive?: boolean;
    /**
     * Név
     *
     * Kulcsszavak
     */
    keywords?: string|null;
    /**
     * Site
     */
    site_id?: number;
    /**
     * Nyelv
     */
    lang_id?: number;
    /**
     * Évfolyam
     *
     * Évfolyam azonosító
     */
    grade_name?: string|null;
    /**
     * Tantárgy
     *
     * Tantárgy név
     */
    subject_name?: string|null;
    /**
     * Kiválasztás módja
     *
     * A feladatsoron belül a feladatok kiválasztása milyen elv alapján történik.
     */
    exc_selection_mode_name?: string|null;
    /**
     * Megjelenítés módja
     *
     * A feladatsor hogyan jelenik meg.
     */
    exc_display_mode_name?: string|null;
    /**
     * Feladatok száma
     *
     * A feladatsorban található aktív feladatok száma.
     */
    item_count?: number|null;
}

/**
 *  exc_pub.view_exc_series - Feladatlista
 *
 *  Feladványok listája
 */

export default class ViewExcSeries extends View<IViewExcSeriesRecord> {
    public static VIEW_INFO_ID = 2019032201;

    public static getSchemaNameForClass(): string { return 'exc_pub'; }
    public static getViewNameForClass(): string { return 'view_exc_series'; }
    public static getTableNameForClass(): string|null { return 'exercise_series'; }

    public static canCache(): boolean {
        return true;
    }

    public static load: (id: number, aServer ?: Server) => Promise<ViewExcSeries>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewExcSeriesRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewExcSeriesClassProxy extends ViewClassProxy<IViewExcSeriesRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewExcSeriesClassProxy = new ViewExcSeriesClassProxy(ViewExcSeries);
registerViewClassProxy(viewExcSeriesClassProxy);

