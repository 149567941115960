/** Nyers szöveg
*/

import * as React from 'react';
import axios from 'axios';
import './OriginalTextSelect.css'

import { INDESIGN_FOLDER_NAME } from '@src/Const'
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import { BubbleLoader } from 'react-css-loaders';
import { __ } from '@src/translation';

type OriginalTextSelectProps = {
    onTextSelected: (text: string) => void;
    folderId: number;
}

type OriginalTextSelectState = {
    filesAndFolders?: IOoFileRecord[]; // onlyoffice files and folders response https://api.onlyoffice.com/portals/method/files/get/api/2.0/files/%7bfolderid%7d
    originalFilenames: string[],
    content?: string;
    currentFile?: IOoFileRecord;
    errorMessage?: string;
}

export default class OriginalTextSelect extends React.Component<OriginalTextSelectProps, OriginalTextSelectState> {

    constructor(props: OriginalTextSelectProps) {
        super(props);

        this.state = {
            originalFilenames: []
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate() {
        this.reloadAsync();
    }

    async reloadAsync() {
        if (this.state.errorMessage) {
            return;
        }

        try {

            if (!this.state.filesAndFolders) {

                const bookFolders = await OoFolderCrud.list({filter: {parent_id: this.props.folderId, is_active: true}});
                
                const folder = bookFolders.find(f => f.title == INDESIGN_FOLDER_NAME);
                if (!folder) throw __("Hiányzó mappa:") + " " + INDESIGN_FOLDER_NAME;

                var indesignFiles = await OoFileCrud.list({filter: {oo_folder_id: folder.id, is_active: true}});

                indesignFiles = indesignFiles.filter((file) => file.title && file.title.indexOf(".html") != -1);

                const originalFilenames = indesignFiles.map((file) => file.title || "");

                this.setState({
                    filesAndFolders: indesignFiles, 
                    originalFilenames: originalFilenames,
                    currentFile: indesignFiles[0]
                });

            } else {

                if (!this.state.content && this.state.currentFile) {
                    const content = await axios.get("/api/media/file/" + this.state.currentFile.sha1!);

                    var newHTMLDocument = document.implementation.createHTMLDocument('just_for_parsing_raw_html');
                    const div = newHTMLDocument.createElement("div");
                    div.innerHTML = content.data;
                    
                    const imgs = div.querySelectorAll("img, link");
                    for (var i = 0; i < imgs.length; ++i) {
                        imgs[i].remove();
                    }

                    const elements = div.querySelectorAll("*");
                    for (var i = 0; i < elements.length; ++i) {
                        elements[i].removeAttribute("id");    // Teljesen haszontalan id-k vannak az indesign-ból exportált HTML-ben
                        elements[i].removeAttribute("class"); // Teljesen haszontalan class-k vannak az indesign-ból exportált HTML-ben: "keny-r", "Basic-Text-Frame", ...
                        elements[i].removeAttribute("lang");

                        if (elements[i].nodeName == 'SPAN' && elements[i].attributes.length == 0) {
                            elements[i].outerHTML = elements[i].innerHTML;
                        }
                    }

                    this.setState({content: div.innerHTML});
                }
            }
        } catch(e) {
            console.error(e);
            this.setState({errorMessage: __("Hiányzó mappa:") + " " + INDESIGN_FOLDER_NAME});
            //this.setState({errorMessage: "Fejlesztés alatt"});
            //app.showError("Nem sikerült betölteni a nyers szöveget", e);
        }
    }

    onClick(event: any) {
        var current: HTMLElement | null = event.target;
        var element: HTMLElement | null = null;

        while(current) {
            if (current.nodeName=="P"
                || current.nodeName=="UL"
                || current.nodeName=="OL"
                || current.nodeName=="TABLE"
                ) {
                element = current;
            }
            current = current.parentElement;
        }

        if (element) {
            this.props.onTextSelected(element.outerHTML);

            element.classList.add("original-text--added");
        }

        //this.props.on(this.state.folder, file);
    }

    onSelectFile(event: any) {
        if (this.state.filesAndFolders) {
            this.setState({content: undefined, currentFile: this.state.filesAndFolders[event.target.selectedIndex]});
        }
    }

    render() {
        const loading = !this.state.filesAndFolders;

        const breadcrumbs = <ul className="breadcrumbs" style={{margin: "1em"}}>
            <li className="image-select__breadcrumb-item">{INDESIGN_FOLDER_NAME}</li>
        </ul>

        if (this.state.errorMessage) {
            return <div className="callout alert">
                    {this.state.errorMessage}
                </div>;
        }
        
        if (!this.state.currentFile) {
            return <div className="callout alert">
                {__("Nincsenek nyers .html fájlok feltöltve")}
            </div>;
        }

        if (loading) {
            return <div>
                    {breadcrumbs}
                    <div><BubbleLoader /></div>
                </div>;
        }

        const selector = <select onChange={this.onSelectFile.bind(this)} value={this.state.currentFile!.title || ""}>
            {
                this.state.originalFilenames.map((file: string) => {
                    return <option key={file}>{file}</option>
                })
            }
        </select>

        if (!this.state.content) {

            return <div>
                    {breadcrumbs}
                    {selector}
                    <div><BubbleLoader /></div>
                </div>;

        } else {
            return <div className="original-text" onClick={this.onClick.bind(this)}>
                {breadcrumbs}
                {selector}
                <div dangerouslySetInnerHTML={{__html: this.state.content}}></div>

            </div>
        }
    }

}
