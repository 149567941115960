import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { viewTicketClaimClassProxy } from '@src/framework/view/ticket/ViewTicketClaim';

import { viewTicketClaimTypeClassProxy } from '@src/framework/view/ticket/ViewTicketClaimType';

import { me, hasGroup, Groups } from '@framework/server/Auth';
import { __ } from '@src/translation';

export default class TicketMenu extends React.Component<any, any> {

  componentDidMount()
  {

    if((this.refs.menu as HTMLUListElement).querySelector(".active"))
    {
      (this.refs.menu as HTMLUListElement).classList.add("is-active");
    }

  }

  public render() {
    if (me) {
      return <ul ref="menu" className="menu vertical nested">
                  <li className="menu-item"><NavLink activeClassName="active" to={viewTicketClaimClassProxy.getListUrl({})}>{__("Szerkesztőségi folyamatok")}</NavLink></li>
                  {hasGroup(me, Groups.Admin) ? <li className="menu-item"><NavLink activeClassName="active" to={viewTicketClaimTypeClassProxy.getListUrl({})}>{__("Szerkesztőségi folyamatok típusai")}</NavLink></li> : null}
               </ul>;
    }
    else {
      return null;
    }
  }
}
