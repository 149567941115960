import{ IInstituteSiteTeacherRecord, InstituteSiteTeacherCrudClassProxy, instituteSiteTeacherCrudClassProxy } from '@src/framework/crud/sys/InstituteSiteTeacherCrud';
import RecordEditor, { IFieldEditorProp } from '@framework/forms/recordeditor';
import { IFieldInfo } from '@src/framework/crud/Meta';

export default class InstituteSiteTeacherEditor extends RecordEditor<IInstituteSiteTeacherRecord> {

    /*
    protected getNewRecordDefaults(): IInstituteSiteTeacherRecord {
        return {
            ...this.getNewRecordDefaultsRaw(this.state.defaultValues),
            status_id: 1
        };
    }
    */

    protected ignoreRequired(fieldInfo: IFieldInfo) : boolean {
        if (fieldInfo.field_name=='status_id') {
            return true;
        } else {
            return super.ignoreRequired(fieldInfo);
        }
    }    

    protected getFieldEditor(fProps: IFieldEditorProp) : JSX.Element | null {
        if (fProps.fieldName=='status_id') {
            return null;        
        }
        return super.getFieldEditor(fProps);
    }    

    public getCrudClassProxy() : InstituteSiteTeacherCrudClassProxy { return instituteSiteTeacherCrudClassProxy;  }
}