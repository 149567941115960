
import * as React from 'react';
import { SurveyInputType, Question } from './SurveyConstants';

/**
 * ===========================
 * DO NOT TRANSLATE THIS FILE!
 * ===========================
 */


const NOPE="Egyáltalán nem";
const RARELY="Kevésbé";
const CASUALLY="Átlagosan";
const USUALLY="Eléggé";
const MAXIMALLY="Maximálisan";
export const questions: Question[] = [
    {
        id: 1,
        name: <><strong>Mennyire volt könnyű tanulni</strong> az okoskönyvből?</>,
        type: SurveyInputType.RADIO_SMILEY,
        answers: [
            {
                id: 1,
                name: NOPE,
            },
            {
                id: 2,
                name: RARELY,
            },
            {
                id: 3,
                name: CASUALLY,
            },
            {
                id: 4,
                name: USUALLY,
            },
            {
                id: 5,
                name: MAXIMALLY,
            }
        ],
    },
    {
        id: 2,
        name: <>Mennyire tartottad <strong>érdekesnek, izgalmasnak</strong> a digitális tananyagot? </>,
        type: SurveyInputType.RADIO_SMILEY,
        answers: [
            {
                id: 1,
                name: NOPE,
            },
            {
                id: 2,
                name: RARELY,
            },
            {
                id: 3,
                name: CASUALLY,
            },
            {
                id: 4,
                name: USUALLY,
            },
            {
                id: 5,
                name: MAXIMALLY,
            }
        ],
    },
    {
        id: 3,
        name: <><strong>Mennyire érezted jól magad</strong> a tanórán? </>,
        type: SurveyInputType.RADIO_SMILEY,
        answers: [
            {
                id: 1,
                name: NOPE,
            },
            {
                id: 2,
                name: RARELY,
            },
            {
                id: 3,
                name: CASUALLY,
            },
            {
                id: 4,
                name: USUALLY,
            },
            {
                id: 5,
                name: MAXIMALLY,
            }
        ],
    },
    {
        id: 4,
        name: <><strong>Mennyire szívesen használnád otthon is</strong> a portált?</>,
        type: SurveyInputType.RADIO_SMILEY,
        answers: [
            {
                id: 1,
                name: NOPE,
            },
            {
                id: 2,
                name: RARELY,
            },
            {
                id: 3,
                name: CASUALLY,
            },
            {
                id: 4,
                name: USUALLY,
            },
            {
                id: 5,
                name: MAXIMALLY,
            }
        ],
    },
    {
        id: 5,
        name: <><strong>Jobban tudtál</strong> a leckékre és a tananyagra <strong>koncentrálni,</strong> mint a hagyományos órán? </>,
        type: SurveyInputType.RADIO_SMILEY,
        answers: [
            {
                id: 1,
                name: NOPE,
            },
            {
                id: 2,
                name: RARELY,
            },
            {
                id: 3,
                name: CASUALLY,
            },
            {
                id: 4,
                name: USUALLY,
            },
            {
                id: 5,
                name: MAXIMALLY,
            }
        ],
    },
    {
        id: 6,
        name: <><strong>Jobb hangulatúak</strong> voltak ezek a tanórák, mint a hagyományosak? </>,
        type: SurveyInputType.RADIO_SMILEY,
        answers: [
            {
                id: 1,
                name: NOPE,
            },
            {
                id: 2,
                name: RARELY,
            },
            {
                id: 3,
                name: CASUALLY,
            },
            {
                id: 4,
                name: USUALLY,
            },
            {
                id: 5,
                name: MAXIMALLY,
            }
        ],
    },
    {
        id: 7,
        name: <><strong>Motiváltabb</strong> és aktívabb voltál az okostankönyves órákon?</>,
        type: SurveyInputType.RADIO_SMILEY,
        answers: [
            {
                id: 1,
                name: NOPE,
            },
            {
                id: 2,
                name: RARELY,
            },
            {
                id: 3,
                name: CASUALLY,
            },
            {
                id: 4,
                name: USUALLY,
            },
            {
                id: 5,
                name: MAXIMALLY,
            }
        ],
    },
    {
        id: 8,
        name: <><strong>Jobban megértetted a tananyagot</strong> így, mint a nyomtatott könyvekkel?</>,
        type: SurveyInputType.RADIO_SMILEY,
        answers: [
            {
                id: 1,
                name: NOPE,
            },
            {
                id: 2,
                name: RARELY,
            },
            {
                id: 3,
                name: CASUALLY,
            },
            {
                id: 4,
                name: USUALLY,
            },
            {
                id: 5,
                name: MAXIMALLY,
            }
        ],
    },
    {
        id: 9,
        name: <>Milyen könnyen <strong>tudtad figyelemmel követni,</strong> hogy hol tartotok? </>,
        type: SurveyInputType.RADIO_SMILEY,
        answers: [
            {
                id: 1,
                name: NOPE,
            },
            {
                id: 2,
                name: RARELY,
            },
            {
                id: 3,
                name: CASUALLY,
            },
            {
                id: 4,
                name: USUALLY,
            },
            {
                id: 5,
                name: MAXIMALLY,
            }
        ],
    },
    {
        id: 10,
        name: <><strong>Mennyire könnyen kezelted</strong> az okostankönyveket?</>,
        type: SurveyInputType.RADIO_SMILEY,
        answers: [
            {
                id: 1,
                name: NOPE,
            },
            {
                id: 2,
                name: RARELY,
            },
            {
                id: 3,
                name: CASUALLY,
            },
            {
                id: 4,
                name: USUALLY,
            },
            {
                id: 5,
                name: MAXIMALLY,
            }
        ],
    },
    {
        id: 11,
        name: <><strong>Mennyire könnyen találtad meg</strong> a portálon azt, <strong>amit kerestél?</strong> Mennyire könnyen tudtál oda jutni, ahová akartál?</>,
        type: SurveyInputType.RADIO_SMILEY,
        answers: [
            {
                id: 1,
                name: NOPE,
            },
            {
                id: 2,
                name: RARELY,
            },
            {
                id: 3,
                name: CASUALLY,
            },
            {
                id: 4,
                name: USUALLY,
            },
            {
                id: 5,
                name: MAXIMALLY,
            }
        ],
    },
    {
        id: 12,
        name: <><strong>Többet beszélgettél társaiddal</strong> mint a hagyományos órákon? </>,
        type: SurveyInputType.RADIO,

        answers: [
            {
                id: 1,
                name: "Nem",
            },
            {
                id: 2,
                name: "Ugyanannyit",
            },
            {
                id: 3,
                name: "Sokkal többet",
            }      
        ],
    },
    {
        id: 13,
        name: <><strong>Unalmasabb</strong> volt ez az óra a többinél?</>,
        type: SurveyInputType.RADIO,

        answers: [
            {
                id: 1,
                name: "Nem",
            },
            {
                id: 2,
                name: "Ugyanolyan volt",
            },
            {
                id: 3,
                name: "Igen",
            }      
        ],
    },
    {
        id: 14,
        name: <>Az általad ismert digitális órai kiegészítőkhöz képest,<strong> jobbnak találtad az okostankönyveket?</strong></>,
        type: SurveyInputType.RADIO,

        answers: [
            {
                id: 1,
                name: "Nem",
            },
            {
                id: 2,
                name: "Ugyanolyan volt",
            },
            {
                id: 3,
                name: "Jobb volt",
            }      
        ],
    },
]
