import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewInstituteGroupRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Intézmény feladat ellátási hely
     */
    institute_site_id?: number;
    /**
     * Tanév
     */
    learning_year_id?: number|null;
    /**
     * Osztályfőnök
     */
    head_teacher_id?: number|null;
    /**
     * Név
     */
    name?: string;
    /**
     * Tanosztály
     */
    institute_site_class_id?: number|null;
    /**
     * Aktuális évfolyam
     *
     * Az intézményi csoport évfolyama.
     */
    grade_id?: number|null;
    /**
     * Csoport mappa
     *
     * A csoport saját mappája
     */
    oo_folder_id?: number;
    /**
     * Létrehozó
     *
     * Aki a jogot kiosztotta
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Aki a jog kiosztását módosította
     */
    modifier?: string|null;
    /**
     * Feladatellátási hely neve
     *
     * Feladatellátási hely neve
     */
    institute_site_name?: string|null;
    /**
     * Feladatellátási hely azonosító
     *
     * Feladatellátási hely azonosító
     */
    site_identifier?: string|null;
    /**
     * Város azonosító
     *
     * Város azonosító
     */
    city_id?: number|null;
    /**
     * Város név
     *
     * Város név
     */
    city_name?: string|null;
    /**
     * Feladatellátási hely cím
     *
     * Feladatellátási hely cím
     */
    institute_site_address?: string|null;
    /**
     * Intézmény belső azonosító
     *
     * Intézmény belső azonosító
     */
    institute_id?: number|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    institute_name?: string|null;
    /**
     * Intézmény OM azonosító
     *
     * Intézmény OM azonosító
     */
    om_identifier?: string|null;
    /**
     * Kezdő év
     *
     * Kezdő év
     */
    begin_year?: number /*int4*/|null;
    /**
     * Befejező év
     *
     * Befejező év
     */
    end_year?: number /*int4*/|null;
    /**
     * Kezdő évfolyam
     *
     * Kezdő évfolyam
     */
    begin_grade_name?: string|null;
    /**
     * Befejező évfolyam
     *
     * Befejező évfolyam
     */
    end_grade_name?: string|null;
    /**
     * Osztályfőnök/vezető tanár
     *
     * Osztályfőnök/vezető tanár
     */
    head_teacher_name?: string|null;
    /**
     * Kijelzett név
     *
     * Egysoros szöveges reprezentáció
     */
    displayvalue?: string|null;
}

/**
 *  sys.view_institute_group - Intézményi csoport
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewInstituteGroup extends View<IViewInstituteGroupRecord> {
    public static VIEW_INFO_ID = 2018081701;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_institute_group'; }
    public static getTableNameForClass(): string|null { return 'institute_group'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewInstituteGroup>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewInstituteGroupRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewInstituteGroupClassProxy extends ViewClassProxy<IViewInstituteGroupRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewInstituteGroupClassProxy = new ViewInstituteGroupClassProxy(ViewInstituteGroup);
registerViewClassProxy(viewInstituteGroupClassProxy);

