import * as React from 'react';
import { TFilterDict } from '../crud/Crud';
import { __ } from '@src/translation';

interface LevelSelectComponentProps { 
    value: number|null;
    onSelect ?: React.FormEventHandler<HTMLSelectElement>;
    onBlur?: () => void;
    name:string;
    emptyTitle ?: string;
    allowEmpty:boolean;
    disabled ?: boolean;
    filter?: TFilterDict;
} 
export default class LevelSelectComponent  extends React.Component<LevelSelectComponentProps, any> { 

    constructor(props:LevelSelectComponentProps) {
        super(props);
        this.state = {};        
    }
    private _onSelect = (event: any) => {
        if (this.props.onSelect!==undefined) {
            this.props.onSelect(event)
        }
    }
    render() { 
       
            const value = this.props.value? this.props.value.toString() : '' ;
            const emptyTitle = this.props.emptyTitle || __("Minden szint");
            return (<select name={this.props.name} className="subject-select" value={value} onChange={this._onSelect} disabled={this.props.disabled || false} onBlur={this.props.onBlur}>
                      {this.props.allowEmpty?<option key="_EMPTY_" value="">{emptyTitle}</option>:""}
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option> 
            </select>)
        
    }
}
