import * as React from 'react';
import { app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import ReactTable from 'react-table';
import { __, getLanguageId } from '@src/translation';
import { getReactTableLabels } from '@src/framework/i18n';
import { getTranslations, GetTranslationsResult } from '@src/framework/api/translation/getTranslations';
import { LANG_ID_HU, LANG_ID_EN } from '@src/Const';
import { Dialog, DialogContent } from '@src/component/Dialog';
import { addTranslations } from '@src/framework/api/translation/addTranslations';
import { me } from '@src/framework/server/Auth';
import LookupEdit from '@src/framework/forms/lookupedit';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import LangCrud, { langCrudClassProxy, ILangRecord } from '@src/framework/crud/sys/LangCrud';
import LangTranslationCrud, { ILangTranslationRecord } from '@src/framework/crud/sys/LangTranslationCrud';

export const TRANSLATION_RESOURCE_FILE_PATH = "/__translation_resources.json";

export const MODULES = {
    "Project/KAP": ["/project/kap/"],
    "Project/NKP": ["/project/nkp/", "/component/filemanager/ofi/"],
    "Project/LELLE": ["/project/lelle/"],
    "Project/DPMK": ["/project/dpmk/"],
    "Project/EKE": ["/project/eke/"],
    "Project/HTR": ["/project/htr/"],
    "Project/Pegazus": ["/project/htr/"],

    "Module/Classroom": ["/module/classroomModule/"],
    "Module/CMS": ["/module/cmsModule/", "/component/cms/"],
    "Module/Content Store": ["/module/contentStoreModule/", "/component/filemanager/"],
    "Module/User": ["/component/usr/"],
    "Module/System": ["/component/sys/", "/component/institute/"],
    "Module/Learning Path": ["/component/learningpath/"],
    "Module/Exercise": ["/component/exercise/"],
    "Module/Book": ["/component/book/"],
    "Module/Search": ["/component/search/", "/component/elastic"],
    "Module/Developer": ["/component/ui/", "/module/developerModule/"],
    "Module/Metadata": ["/component/meta/"],
    "Module/Share": ["/component/share/"],
    "Module/Workflow": ["/component/wf/"],
    "Module/Profile": ["/component/profile/"],
    "Module/Media": ["/component/media/"],
    "Module/Lessonplan": ["/component/lessonplan/"],
    "Module/Translation": ["/module/translationModule/"],
    "Module/Messenger": ["/module/messengerModule/"],
    "Module/E-learning": ["/module/elearningModule/"],
    "Module/Syllabus": ["/module/syllabusModule/"],
    "Module/Dashboard": ["/component/dashboard"],
    "Module/Survey": ["/component/survey"],

    "Other": [],
}

export function getModules(sources: string[]) {
    const modules: string[] = [];
    for(const source of sources) {
        moduleLoop:
        for (const m of Object.entries(MODULES)) {
            for(const modulePrefix of m[1]) {
                if (source.startsWith(modulePrefix)) {
                    if (!modules.includes(m[0])) modules.push(m[0]);
                    break moduleLoop;
                }
            }
        }
    }

    if (modules.length === 0) modules.push("Other");
    return modules;
}

export type Resource = {
    date: string,
    resourceKeys: {[key: string]: {sources: {path: string}[], modules: string[]}},
    untranslatedTexts: {[key: string]: {sources: {path: string}[], modules: string[]}},
}

type TranslationState = {
    resources?: Resource,

    langFromId: number;
    langToId: number;

    translationsFrom?: GetTranslationsResult;
    translationsTo?: GetTranslationsResult;

    translationsFromByKey: {[key: string]: string};
    translationsToByKey: {[key: string]: string};

    userTranslations: {[key: string]: string};

    showDetailsDialogKey?: string;

    selectedModule?: string;

    langs: ILangRecord[];
    langTranslations: ILangTranslationRecord[];

    loading: boolean;

    showOnlyUntranslated: boolean;
}

export class Translation extends React.Component<any, TranslationState> {

    constructor(props: any) {
        super(props);

        this.state = {
            langFromId: LANG_ID_HU,
            langToId: LANG_ID_EN,
            translationsFromByKey: {},
            translationsToByKey: {},
            userTranslations: {},
            langs: [],
            langTranslations: [],
            loading: false,
            showOnlyUntranslated: false,
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        this.setState({loading: true});
        try {
            const langs = await LangCrud.list({});
            const langTranslations = await LangTranslationCrud.list({});

            const response = await fetch(TRANSLATION_RESOURCE_FILE_PATH);
            const resources = await response.json() as Resource;

            for(const res of Object.values(resources.resourceKeys)) {
                res.modules = getModules(res.sources.map(s => s.path));
            }

            const translationsFrom = await getTranslations({langId: this.state.langFromId, details: true});
            const translationsTo = await getTranslations({langId: this.state.langToId, details: true});

            let translationsFromByKey = {};
            let translationsToByKey = {};
    
            for(const entry of translationsFrom.translations) {
                if (entry.value) translationsFromByKey[entry.key] = entry.value;
            }
            for(const entry of translationsTo.translations) {
                if (entry.value) translationsToByKey[entry.key] = entry.value;
            }
    
            this.setState({
                langs,
                langTranslations,
                resources,
                translationsFrom,
                translationsTo,


                translationsFromByKey,
                translationsToByKey,
                userTranslations: {},
                loading: false
            })
        } catch(e) {
            app.showErrorDebounced(e);
        }
    }

    private onSetTranslation(key: string, event: any) {
        const translation = event.target.value;
        if ((this.state.translationsToByKey[key] || "") == translation) {
            delete this.state.userTranslations[key];
        } else {
            this.state.userTranslations[key] = translation;
        }

        this.setState({userTranslations: this.state.userTranslations});
    }

    private async onSave() {
        if (!me) return;

        try {
            await addTranslations(
                {translations: Object.entries(this.state.userTranslations).map(t => {
                    return {
                        key: t[0],
                        langId: this.state.langToId,
                        history: [{
                            userId: me!.id,
                            userName: me!.person ? me!.person.full_name : me!.login_name,
                            created: new Date().toISOString(),
                            value: t[1]
                        }]
                    };
                })
            });

            await this.reloadAsync();
            app.showSuccess(__("Mentés sikeres"), __("Mentés sikeres"));
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onFileUploaded(fileEvent: any) {
        var reader = new FileReader();
        const content = reader.readAsText(fileEvent.target.files[0]);
        reader.onload = async (evt) => {
            const json = JSON.parse((evt as any).target!.result);
            json.translations.map((t: any) => delete t["id"]);
            await addTranslations(json);

            this.reloadAsync();
            app.showSuccess(__("Mentés sikeres"), __("Mentés sikeres"));
        }
    }

    render() {
        if (!this.state.resources || !this.state.translationsFrom || !this.state.translationsTo)
            return <BubbleLoader/>
        ;
        let resourceKeys = this.state.resources.resourceKeys;

        if (this.state.selectedModule) {
            resourceKeys = Object.assign({}, resourceKeys);

            for(const resourceKey in resourceKeys) {
                if (!resourceKeys[resourceKey].modules.includes(this.state.selectedModule)) {
                    delete resourceKeys[resourceKey];
                }
            }
        }

        let data = [];
        for(const resourceKey in resourceKeys) {
            if (!resourceKey) continue;
            
            if (this.state.showOnlyUntranslated && this.state.translationsToByKey[resourceKey]) continue;

            const resource = resourceKeys[resourceKey];

            let from = this.state.translationsFromByKey[resourceKey];
            if (!from && this.state.langFromId === LANG_ID_HU) from = resourceKey;

            let to = this.state.translationsToByKey[resourceKey];

            if (this.state.userTranslations[resourceKey]) {
                to = this.state.userTranslations[resourceKey];
            }


            data.push({
                key: resourceKey,
                context: "" + resource.sources.length,
                module: (resource.modules.length > 1 ? `[${resource.modules.length}] `: "") + resource.modules.join(", "),
                "from": from || "",
                "to": to,
            });
        }
        data = data.sort((a, b) => a.key.localeCompare(b.key));

        const keys = Object.keys(resourceKeys);
        const totalCount = keys.length;
        const translatedCount = Object.keys(this.state.translationsToByKey).filter(k => keys.includes(k)).length;
        const userCount = Object.keys(this.state.userTranslations).length;


        let dialogFrom = "";
        let dialogTo = "";
        if (this.state.showDetailsDialogKey) {
            dialogFrom = this.state.translationsFromByKey[this.state.showDetailsDialogKey];
            if (!dialogFrom && this.state.langFromId === LANG_ID_HU) dialogFrom = this.state.showDetailsDialogKey;

            dialogTo = this.state.translationsToByKey[this.state.showDetailsDialogKey];
            if (this.state.userTranslations[this.state.showDetailsDialogKey]) {
                dialogTo = this.state.userTranslations[this.state.showDetailsDialogKey];
            }
        }

        const langId = getLanguageId();

        return <div>
            <div className="row expanded">
            <div className="column small-12">
                <br />
                    <select value={this.state.langFromId} 
                            style={{width: "8em", margin: "0.5em 1em"}} 
                            disabled={Object.keys(this.state.userTranslations).length > 0}
                            onChange={e => this.setState({langFromId: Number(e.target.value)}, this.reloadAsync.bind(this))}>
                        {this.state.langs.map(l => {
                            const trans = this.state.langTranslations.find(t => t.lang_id === l.id && t.translated_to_id === langId);
                            return <option value={l.id}>{trans ? trans.name : ""}</option>}
                            )}
                    </select>
                    -
                    <select value={this.state.langToId} 
                            style={{width: "8em", margin: "0.5em 1em"}}
                            disabled={Object.keys(this.state.userTranslations).length > 0}
                            onChange={e => this.setState({langToId: Number(e.target.value)}, this.reloadAsync.bind(this))}>
                        {this.state.langs.map(l => {
                            const trans = this.state.langTranslations.find(t => t.lang_id === l.id && t.translated_to_id === langId);
                            return <option value={l.id}>{trans ? trans.name : ""}</option>}
                            )}
                    </select>
                    {__("Modul")}: 
                    <select 
                        value={this.state.selectedModule} 
                        onChange={e => this.setState({selectedModule: e.target.value})}
                        style={{width: "14em", margin: "0.5em 1em"}}
                        >
                        <option value="">All</option>
                        {
                            Object.keys(MODULES).sort().map(m => {
                                const {total, translated} = this.getTotal(m);
                                return <option value={m}>{m} [{translated} / {total}]</option>;
                            })
                        }
                    </select>
                        {__("Összes szöveg: {count} db, eddig lefordítva: {translated}, hiányzik: {missing}",
                        {count: totalCount, translated: translatedCount, missing: totalCount - translatedCount})}
                <br />

                <div>
                    <label style={{display: "inline-block"}}>
                        <input style={{margin: "0.5em 1em"}} 
                            type="checkbox" 
                            checked={this.state.showOnlyUntranslated} 
                            onChange={e =>this.setState({showOnlyUntranslated: e.target.checked})}
                        /> {__("Hiányzó fordítások")}
                    </label>
                </div>

                <button 
                    className="button small success"
                    onClick={this.onSave.bind(this)}
                    >
                    <i className="fa fa-save"/> {__("{count} db fordítás mentése", {count: userCount})}
                </button>

                <a 
                    className="button small"
                    href={"data:application/json;base64," +  btoa(unescape(encodeURIComponent(JSON.stringify(this.state.translationsTo, null, 2))))}
                    download={"translation.json"}
                    >
                    <i className="fa fa-file-download"/> {__("Fordítások exportálása")}
                </a>

                <button 
                    className="button small"
                    onClick={() => (this.refs.file as any).click()}
                    >
                    <i className="fa fa-upload"/> {__("Fordítások importálása")}
                </button>
                <input ref="file" type="file" style={{display: "none"}} onChange={this.onFileUploaded.bind(this)} />

                <ReactTable 
                    columns={[
                        {
                            Header: this.state.langTranslations.find(t => t.lang_id === this.state.langFromId && t.translated_to_id === langId)!.name,
                            accessor: "from",
                            Cell: row => {
                                return <>
                                    {row.value.startsWith(" ") ? <i style={{color: "red"}} className="fa fa-fw fa-exclamation" title={__("A szöveg szóközzel kezdődik!")} />: ""}
                                    <input title={row.value} value={row.value || ""} type="text" readOnly style={{width: "100%"}} />
                                    {row.value.endsWith(" ") ? <i style={{color: "red"}} className="fa fa-fw fa-exclamation" title={__("A szöveg szóközzel végződik!")} />: ""}
                                    </>;
                            }
                        },
                        {
                            Header: this.state.langTranslations.find(t => t.lang_id === this.state.langToId && t.translated_to_id === langId)!.name,
                            accessor: "to",
                            Cell: row => {
                                const key = row.original.key;
                                const style = {width: "100%"};
                                if (!row.value) style["backgroundColor"] = "#fdd";
                                if (this.state.userTranslations[key] !== undefined) style["backgroundColor"] = "#dfd";

                                return <input 
                                    value={row.value || ""} 
                                    type="text" 
                                    onChange={this.onSetTranslation.bind(this, key)} style={style} 
                                    />;
                            }
                        },
                        {
                            Header: __("Modul"),
                            width: 150,
                            accessor: "module",
                        },
                        {
                            Header: __("Részletek..."),
                            width: 150,
                            Cell: row => {
                                return <button onClick={() => this.setState({showDetailsDialogKey: row.original.key})} className="button">
                                    {__("Részletek...")}
                                </button>
                            }
                        },
                    ]}
                    loading={this.state.loading}
                    data={data}
                    filterable
                    defaultPageSize={10}
                    defaultFilterMethod={(filter, row, column) => row[filter.id] && row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) != -1}
                    {...getReactTableLabels()}
                />
                <small>({this.state.resources.date})</small>
                </div>
            </div>

            <Dialog width={800} open={this.state.showDetailsDialogKey !== undefined} title={__("Részletek...")} onClose={() => this.setState({showDetailsDialogKey: undefined})}>
                <DialogContent>
                    <div className="row">
                        <div className="column small-4">
                            {__("Érték kulcs")}:
                        </div>
                        <div className="column small-8">
                            {this.state.showDetailsDialogKey}
                        </div>

                        <div className="column small-4">
                            {__("Eredeti szöveg")}:
                        </div>
                        <div className="column small-8">
                            {dialogFrom}
                        </div>

                        <div className="column small-4">
                            {__("Fordítás")}:
                        </div>
                        <div className="column small-8">
                            {dialogTo}
                        </div>

                        <div className="column small-4">
                            {__("Modul")}:
                        </div>
                        <div className="column small-8">
                            {this.state.showDetailsDialogKey && resourceKeys[this.state.showDetailsDialogKey].modules.join(", ")}
                        </div>

                        <div className="column small-12">
                            Files (Admin)
                            <small>
                            {this.state.showDetailsDialogKey && 
                              resourceKeys[this.state.showDetailsDialogKey].sources.map(s => <div>{s.path}</div>)}
                            </small>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            </div>;
    }

    private getTotal(module: string) {
        if (!this.state.resources) return {total: 0, translated: 0};
        let total = 0;
        let translated = 0;

        for(const resource of Object.entries(this.state.resources.resourceKeys)) {
            if (resource[1].modules.includes(module)) {
                total++;
                if (this.state.translationsToByKey[resource[0]]) translated++;
            }
        }
        return {total, translated};
    }

}
