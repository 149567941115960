import * as React from 'react';
import './Viewer/ExerciseShow.css';
import { Link } from 'react-router-dom';
import { PATH_EXERCISE_PUB_VIEW, PATH_PUBLISHED_MEDIA_VIEW, PATH_PUBLISHED_BOOK, PATH_DL_MATERIAL_VIEW } from '@src/Routes';
import { SearchResult } from '@src/server/PublicServer';
import * as Server from '@src/server/PublicServer'
import { getDetailForFile, ContentType } from '../filemanager/ContentUtils';
import { ElasticContentType, getTypeFromItemId } from '../search/SearchComponent';
import { getTypes } from '../media/MediaSearchBar';
import { CollectionMenu, ENABLED_COLLECTION_TABLE_INFO_IDS } from '../collection/CollectionMenu';
import BookSelectorSubjectIcon from '../book/viewer/BookSelectorSubjectIcon';
import { me } from '@src/framework/server/Auth';
import { config } from '@src/framework/server/Server';
import { __ } from '@src/translation';


export class SearchResultComponent extends React.Component<{ result: SearchResult }, any> {

    async onSearchHit(result: SearchResult) {
        await Server.onSearchHit(result);
    }

    render() {
        var result: SearchResult = this.props.result;
        var image: JSX.Element | undefined = undefined;
        var details = undefined;


        if (result.sha1 && typeof result.id == "number") {
            details = getDetailForFile(result.id, result.sha1, result.media_type_id);
            if (details.contentType != ContentType.FILE_UNKNOWN) {
                image = <img src={details.thumbnailUrl} title={result.alt_text} alt={result.alt_text} onError={(e) => e.currentTarget.src = "/img/placeholder/play_200.png"} />
            }
        }

        var title = "";
        if (result.grade_name) {
            title += result.grade_name + " / ";
        }
        if (result.subject_name) {
            title += result.subject_name + " / ";
        }
        if (result.chapter_name) {
            title += result.chapter_name + " / ";
        }
        if (result.lesson_name) {
            title += result.lesson_name;
        }

        if (result.exercise_title) {
            title += result.exercise_title + " / ";
        }
        if (result.exercise_name) {
            title += result.exercise_name;
        }
        if (((title == "") && result.displayname) || (getTypeFromItemId(result) == ElasticContentType.DlMaterial)) {
            title += result.displayname;
        }

        var path = PATH_PUBLISHED_MEDIA_VIEW + "/" + result.id;

        if (getTypeFromItemId(result) == ElasticContentType.Exercise) {
            path = PATH_EXERCISE_PUB_VIEW + "/" + result.id;
        } else if (result.book_uri_segment) {
            path = PATH_PUBLISHED_BOOK + "/" + result.book_uri_segment + "/" + result.lesson_uri_segment + "#section-" + result.section_id;
        } else if (getTypeFromItemId(result) == ElasticContentType.DlMaterial) {
            path = PATH_DL_MATERIAL_VIEW + "/" + result.id;
        }

        const type = getTypes().find(type => type.elasticType.includes(getTypeFromItemId(result)!));

        let text: string = "";

        if (this.props.result.highlight)
            text = this.props.result.highlight
        else if (this.props.result.html)
            text = this.props.result.html
        else if (this.props.result.text)
            text = this.props.result.text
        else if (this.props.result.exercise_description)
            text = this.props.result.exercise_description;

        return <div className="row eke-searchresult">
            {details && details.contentType == ContentType.FILE_UNKNOWN ?
                <div>
                    <div style={{ height: "60px"}}>
                        <img src="/img/IKON_SET/document_icon.png" style={{ width: "60px" }}></img>
                    </div>
                </div>
                : <BookSelectorSubjectIcon sizePx={60} uriSegment={this.props.result.subject_uri_segment || ""} extension={".svg"} aria-hidden="true" needAnimation={false} />
            }
            <div className="column">
                <Link target={config.mainServer.showBooks ? "_parent" : "_blank"} to={path} onMouseDown={this.onSearchHit.bind(this, this.props.result)} key={this.props.result.id}>
                    <h3>{title}</h3>
                    {
                        image ?
                            <div className="row">
                                <div className="hide-for-small-only medium-2 column text-center float-left" style={{ width: "120px" }}>
                                    {image}
                                </div>
                                <div className="columns small-4 medium-6 large-10">
                                    {result.alt_text}
                                </div>
                            </div>
                            :
                            ""
                    }
                    <span className="eke-searchresult__text" dangerouslySetInnerHTML={{ __html: text }} />
                </Link>
            </div>
            <div className="shrink column text-right">


                {
                    type ?
                        <div style={{ marginBottom: "0.5em" }}>
                            <i className={"icon " + type!.iconClassName}></i>&nbsp;
                                {type!.name}
                        </div>
                        :
                        ""
                }

                {
                    me && this.props.result.table_info_id && ENABLED_COLLECTION_TABLE_INFO_IDS.includes(this.props.result.table_info_id)
                        ?
                        <CollectionMenu tableInfoId={this.props.result.table_info_id} recId={this.props.result.id} />
                        :
                        null
                }

            </div>
        </div>;
    }
}