import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IInstituteGroupNewsfeedRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Tanulócsoport
     *
     * -> sys.institute_group ON DELETE cascade
     *
     */
    institute_group_id?: number|null;
    /**
     * Törzs
     *
     * Az üzenet törzse
     */
    body_html?: string;
}

/**
 *  usr.institute_group_newsfeed - Tanulócsoport hírfolyam
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class InstituteGroupNewsfeedCrud extends Crud<IInstituteGroupNewsfeedRecord> {
    public static TABLE_INFO_ID = 1358219987;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'institute_group_newsfeed'; }

    public static load: (id: number, aServer ?: Server) => Promise<InstituteGroupNewsfeedCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IInstituteGroupNewsfeedRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IInstituteGroupNewsfeedRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IInstituteGroupNewsfeedRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IInstituteGroupNewsfeedRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IInstituteGroupNewsfeedRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class InstituteGroupNewsfeedCrudClassProxy extends CrudClassProxy<IInstituteGroupNewsfeedRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const instituteGroupNewsfeedCrudClassProxy = new InstituteGroupNewsfeedCrudClassProxy(InstituteGroupNewsfeedCrud);
registerCrudClassProxy(instituteGroupNewsfeedCrudClassProxy);
