import * as React from 'react';
import ListView from '@framework/forms/listview';
import { ViewDpmkProjectClassProxy, viewDpmkProjectClassProxy, IViewDpmkProjectRecord } from '@src/framework/view/doc/ViewDpmkProject';
import { __ } from '@src/translation';


class DpmkProjectListViewInner extends ListView<IViewDpmkProjectRecord> {
    public getViewClassProxy() : ViewDpmkProjectClassProxy { return viewDpmkProjectClassProxy; }
}

export default function DpmkProjectListView(props: any) {
    return <DpmkProjectListViewInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "title"},
            {accessor: "code"},
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
