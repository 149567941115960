import * as React from 'react';
import ViewWfType, { IViewWfTypeRecord } from '@src/framework/view/wf/ViewWfType';
import StationCrud, { IStationRecord } from '@src/framework/crud/wf/StationCrud';
import { BubbleLoader } from 'react-css-loaders';
import TransitionTypeCrud, { ITransitionTypeRecord } from '@src/framework/crud/wf/TransitionTypeCrud'

import WfTypeCrud from '@src/framework/crud/wf/WfTypeCrud';
import { app } from '../..';
import { __ } from '@src/translation';
import { me, hasGroup, Groups, hasAnyGroup } from '@src/framework/server/Auth';


interface IWfTypeGraphProps {
    match?: any;
    wfTypeId?: number
}

interface IWfTypeGraphState {
    wfType?: IViewWfTypeRecord;
    stations?: IStationRecord[];
    transitions?: ITransitionTypeRecord[];
    actgraph?: any;
    physics: boolean;
    nodeCoords?: any[];
    network?: any;
    showBackTransitions: boolean;
    selectedStateOnly: boolean;
}

var Graph: any;

export default class WfTypeGraph extends React.Component<IWfTypeGraphProps, IWfTypeGraphState> {
    constructor(props: IWfTypeGraphProps) {
        super(props);
        this.state = { showBackTransitions: true, physics: false, selectedStateOnly: false };
        this.reload();

    }

    protected getMatchParam(name: string): any {
        const match: any = this.props.match;
        return (match && match.params) ? match.params[name] : null;
    }

    private reload = async () => {
        Graph = (await import("react-graph-vis")).default;

        const wfTypeId = this.props.wfTypeId ? this.props.wfTypeId : parseFloat(this.getMatchParam('wfTypeId'));

        const wfType = await ViewWfType.load(wfTypeId);

        const stations = await StationCrud.list({ filter: { wf_type_id: wfTypeId, is_active: true } });

        const transitions = await TransitionTypeCrud.list({ filter: { type_id: wfTypeId, is_active: true } });

        const physics = false;

        const nodeCoords = wfType.record.graph ? wfType.record.graph.coords : null;

        this.setState({
            wfType: wfType.record,
            stations: stations,
            transitions: transitions,
            actgraph: this.calcGraph(stations, transitions, nodeCoords),
            physics: physics,
            nodeCoords: nodeCoords
        });

    }

    protected getNetwork(network: any) {

        this.setState({ network: network });

    }

    protected getGraphCoords(network: any): any[] {
        var nodeCoords: any[] = [];
        var nodeList = network.body.nodes;
        this.state.stations!.forEach(s => {
            nodeCoords.push({ "x": nodeList[s.id!].x, "y": nodeList[s.id!].y });
        });
        this.setState({ nodeCoords: nodeCoords });

        return nodeCoords;
    }

    protected async saveNodePositions() {
        var coordList = this.getGraphCoords(this.state.network);
        new WfTypeCrud({
            id: this.state.wfType!.id,
            graph: { 'coords': coordList }
        }).put()
            .then((response) => { app.showSuccess(__("Sikeres mentés!"), __("A gráf mentésre került")) })
            .catch((error) => { app.showErrorFromJsonResult(error) });
    }

    protected reloadGraph() {
        this.setState({ actgraph: this.calcGraph(this.state.stations!, this.state.transitions!, this.getGraphCoords(this.state.network)) });
        this.reload();
    }

    render() {
        if (!this.state.wfType) {
            return <div><BubbleLoader /></div>;
        } else {
            return (
                <div>
                    {this.state.wfType.name}
                    <div ref="graphdiv" />
                    <div>
                        {
                            (me && hasAnyGroup(me, [Groups.Admin, Groups.Developer]))
                                ?
                                <>
                                    &nbsp;
                            <button className="button" onClick={this.saveNodePositions.bind(this)} > {__("Mentés")}
                                    </button>
                                </>
                                : null
                        }
                        &nbsp;
                        <button className="button" onClick={this.reloadGraph.bind(this)} > {__("Újra")}
                        </button>
                        <br />

                        <input type="checkbox" checked={this.state.showBackTransitions} onChange={() => { this.setState({ showBackTransitions: !this.state.showBackTransitions }, this.reload); }} />
                        {__("Visszaküldések megjelenítése")}
                        
                        {/*
                        <button   className="button secondary" 
                            onClick={() => { this.setState({selectedStateOnly: !this.state.selectedStateOnly}, this.reload); }}>
                            
                            <input type="checkbox" checked={this.state.selectedStateOnly} />
                            { __("Csak a kijelölt állapot átmeneteit mutassa") }                        
                        </button>
                        <button className="button secondary" 
                            onClick={() => { this.setState({physics: !this.state.physics}, this.reload); }}
                        >
                            <input type="checkbox" checked={this.state.physics} />
                            { __("Rendezés") }                            
                        </button>
                        */}
                    </div>
                    <div ref="visgraphdiv">
                        {/* Amíg nem is használja ezt senki, addig ne rakjuk be build-be, mert csak növeli a build időt és méretet*/}
                        <Graph
                            identifier="workflow-graph"
                            graph={this.state.actgraph}
                            style={{ height: "1000px" }}
                            options={{
                                physics: { enabled: this.state.physics }
                            }}
                            getNetwork={this.getNetwork.bind(this)}
                        />
                        {/**/}
                    </div>
                </div>
            );
        }
    }

    protected static getVisNode(station: IStationRecord, x: number, y: number): any {
        const id: number = (station.id!);
        const label: string = (station.name!);
        const color: string = (station.style['backgroundColor']);
        const shape: string = (station.is_start || station.is_closed ? "ellipse" : "box");
        const size: number = 30;
        const fontColor: string = (station.style['color']);
        if (x != null || y != null) {
            const x_coord: number = x;
            const y_coord: number = y;
            return {
                id: id,
                label: label,
                color: color,
                shape: shape,
                font: { size: size, color: fontColor },
                x: x_coord,
                y: y_coord
            }
        } else {
            return {
                id: id,
                label: label,
                color: color,
                shape: shape,
                font: { size: size, color: fontColor }
            }
        }

    }

    protected static getVisNodeRandom(station: IStationRecord): any {
        const id: number = (station.id!);
        const label: string = (station.name!);
        const color: string = (station.style['backgroundColor']);
        const shape: string = (station.is_start || station.is_closed ? "ellipse" : "box");
        const size: number = 30;
        const fontColor: string = (station.style['color']);
        return {
            id: id,
            label: label,
            color: color,
            shape: shape,
            font: { size: size, color: fontColor }
        }
    }

    protected static getVisEdge(transition: ITransitionTypeRecord, hasBack: boolean): any {
        const from: number = (transition.src_station_id!);
        const to: number = (transition.dst_station_id!);
        const label: string = (transition.description!);
        const color: string = (transition.must_justify ? "#E7000C" : "#00A500");
        const fontColor: string = (transition.must_justify ? "#E7000C" : "#00A500");
        const width: number = (transition.must_justify ? 2 : 1);

        // http://visjs.org/docs/network/edges.html#
        return {
            from: from,
            to: to,
            label: label,
            font: { color: fontColor, opacity: 0.5 },
            // length: 200,
            width: width,
            color: { color: color, opacity: 1, highlight: color },
            smooth: {
                enabled: hasBack,
                type: (transition.must_justify ? 'curvedCW' : 'cubicBezier'),
                // forceDirection: (transition.must_justify ? 'vertical' : 'horizontal'),
                roundness: 0.3,
            }
        }
    }

    public calcGraph(stations: IStationRecord[], transitions: ITransitionTypeRecord[], coords: any[]): any {
        if (stations && transitions) {
            var nodes: any[] = [];
            var redEdges: any[] = [];
            var greenEdges: any[] = [];
            var edges: any[] = [];

            var i: number = 0;


            stations!.forEach((s) => {
                if (coords == null || coords == [] || !coords[i])
                    nodes.push(WfTypeGraph.getVisNodeRandom(s));
                else
                    nodes.push(WfTypeGraph.getVisNode(s, coords[i]['x'], coords[i]['y']));
                i++;
            });

            let pairs = {};
            transitions!.forEach((t) => {
                const key = '' + t.src_station_id! + '-' + t.dst_station_id!;
                pairs[key] = true;
            });

            transitions!.forEach((t) => {
                const key = '' + t.dst_station_id! + '-' + t.src_station_id!;
                const hasBack = pairs[key];
                if (t.must_justify) {
                    if (this.state.showBackTransitions) {
                        redEdges.push(WfTypeGraph.getVisEdge(t, hasBack || false));
                    }
                } else {
                    greenEdges.push(WfTypeGraph.getVisEdge(t, hasBack || false));
                }
            });

            redEdges.forEach((e) => {
                edges.push(e);
            });

            greenEdges.forEach((e) => {
                edges.push(e);
            });

            console.log(edges);

            var visgraph = {
                nodes: nodes,
                edges: edges
            }

            return visgraph;
        } else {
            return null;
        }
    }

}