import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, GetAllIndexes, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import * as React from "react";
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';
import ExerciseFileSelect, { ExerciseFileTypes } from "../../Editor/ExerciseFileSelect";

export type PuzzleExercise = {
    illustration: string,
    hard_mode: boolean,
    num_of_rows: number,
    num_of_columns: number,
    alternative_image?: string
}

export class PuzzleGameConverter extends AExerciseTypeConverter {

    public hasTextAnswer = false;
    public hasImageAnswer = true;
    public hasTextImageAnswer = false;
    public isGame = true;
    static MAX_COLUMN_NUM = 8;
    static MAX_ROW_NUM = 8;

    public convertToEditorAnswer(exercise: PuzzleExercise): PuzzleExercise | undefined {
        if (exercise) {
            let answer: PuzzleExercise = {
                illustration: exercise.illustration,
                hard_mode: exercise.hard_mode,
                num_of_columns: exercise.num_of_columns,
                num_of_rows: exercise.num_of_rows,
                alternative_image: exercise.alternative_image
            };
            return answer;
        }
        return undefined;
    }

    public convertToJson(exerciseDetails: PuzzleExercise, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: PuzzleExercise): PuzzleExercise | undefined {
        var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
        if (exerciseDetails) {
            let answer = {
                ...cur_exercise,
                illustration: baseData.illustration,
                hard_mode: exerciseDetails.hard_mode,
                num_of_columns: exerciseDetails.num_of_columns ? exerciseDetails.num_of_columns : 1,
                num_of_rows: exerciseDetails.num_of_rows ? exerciseDetails.num_of_rows : 1,
                alternative_image: exerciseDetails.alternative_image
            }
            return answer;

        }
        return cur_exercise;
    }
    render() {
        let curr_ex: PuzzleExercise = this.state.exercise as PuzzleExercise;
        return (
            <Panel header={__("Részletek")} headingLevel={4} >
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox"
                            name="hard_mode"
                            checked={curr_ex.hard_mode || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Nehezített mód")}</label>
                    </div>

                    <div className="large-12 small-12 columns">
                        <label className="exe-image-select-label"> {__("Alternatív háttérkép") + " " + __("(opcionális)")}
                            {<ExerciseFileSelect
                                imagebasepath={this.props.imagebasepath}
                                value={curr_ex.alternative_image || ""}
                                onChange={this.handleImageChange.bind(this, "alternative_image")}
                                getFolderId={this.getFolderId.bind(this)}
                                fileType={ExerciseFileTypes.Image}
                            />}
                        </label>
                    </div> <label className="exe-editor-label-description columns">A méretének pontosan egyeznie kell az illusztrációéval! </label>
                </div>
                <div className="row">
                    <div className="large-6 small-12 columns">
                        <label>{__("Sorok száma")}</label>
                        <input type="number" min={1} max={PuzzleGameConverter.MAX_ROW_NUM} name="num_of_rows"
                            value={curr_ex.num_of_rows || 1}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                    </div>
                    <div className="large-6 small-12 columns">
                        <label>{__("Oszlopok száma")}</label>
                        <input type="number" min={1} max={PuzzleGameConverter.MAX_COLUMN_NUM} name="num_of_columns"
                            value={curr_ex.num_of_columns || 1}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                    </div>
                </div>
            </Panel>
        );
    }

    public validate(editorAnswer: PuzzleExercise, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {

        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData, validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;

        if (!baseData.illustration) {
            let errorMsg = __("Az illusztráció megadása kötelező!");
            return { valid: false, message: errorMsg };
        }
        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: PuzzleExercise | null, newState: PuzzleExercise): ValidationResponse {
        return { valid: true }
    }
}
