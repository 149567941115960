import * as React from 'react';

import { IViewTableInfoRecord, ViewTableInfoClassProxy, viewTableInfoClassProxy } from '@src/framework/view/meta/ViewTableInfo';
import ListView from '@framework/forms/listview';
import { __ } from '@src/translation';
 
class TableInfoInner extends ListView<IViewTableInfoRecord> {
    //protected getHeader = () : JSX.Element|null => { return <SideMenu /> }
    public getViewClassProxy() : ViewTableInfoClassProxy { return viewTableInfoClassProxy; }
    protected getDeleteUndeleteButton(record: IViewTableInfoRecord): JSX.Element | null { return null; }    
    protected getQuickFilterFieldNames(): string[] {
        return ['schema_name', 'table_name', 'display_name'];
    }
}



export default function TableInfoListView(props: any) {
    return     <TableInfoInner 
                        defaultPageSize={10}
                        match={props.match}
                        viewColumns={[
                            {accessor: "id"},
                            {accessor: "schema_name"},
                            {accessor: "table_name"},
                            {accessor: "display_name"},
                            {accessor: "description"},
                            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
                        ]}
                    />
                  
}
