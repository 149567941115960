import * as React from 'react';

import { IStationRecord, StationCrudClassProxy, stationCrudClassProxy } from '@src/framework/crud/wf/StationCrud';
import RecordEditor from '@framework/forms/recordeditor';
import { IFieldInfo } from '@src/framework/crud/Meta';
import { IFieldEditorProp } from '@src/framework/forms/recordeditor';
import { SecStationPermListViewForStation } from '@src/component/wf/wf_sec_station_perm_listview';
import { __ } from '@src/translation';


export default class StationEditor extends RecordEditor<IStationRecord> {
    public getCrudClassProxy() : StationCrudClassProxy { return stationCrudClassProxy;  }
    protected getNewRecordDefaults(): Partial<IStationRecord> {
        let result = super.getNewRecordDefaults();
        return Object.assign(result, {
            is_active: true,
            is_start: false,
            is_closed: false,
            is_eligible : false,
            head_mod: true,
            head_del: true,
            det_ins: true,
            det_mod: true,
            det_del: true,
            notify: false,
            warning_timeout : null,
            error_timeout: null,
            style: result["style"] || {color:"black", backgroundColor:"white"},
        });
    }
    protected getFieldEditorProps(fieldInfo: IFieldInfo) : IFieldEditorProp {
        let result : IFieldEditorProp = super.getFieldEditorProps(fieldInfo);
        if (fieldInfo.field_name=="wf_type_id")
            result.disabled = true;
        return result;
    }

    protected getFieldEditors(fieldEditorProps: IFieldEditorProp[]): JSX.Element[] {
        let result : JSX.Element[] = super.getFieldEditors(fieldEditorProps);
        if (this.state.rec && this.state.rec.style) {
            let style = null;
            if (typeof this.state.rec.style == 'string' ) {
                try {
                    style = JSON.parse(this.state.rec.style);
                } catch (error) {
                    return result;
                }
            } else if (typeof this.state.rec.style == 'object' ) {
                style = this.state.rec.style;
            }
            if (style) {
                result.push(<br/>);
                result.push(
                    <div className="column small-12 medium-12 large-12">
                        <label id="label_style_test" htmlFor="styletest">
                            <span>{ __("Stílus teszt") }</span>
                        </label>
                        <div id="styletest" style={style}>
                           {__("Stílus teszt")}  {this.state.rec.name}
                        </div>
                    </div>
                );
                result.push(<br/>);
            }
        }
        return result;
    }    

    protected getDetailPanels() : any[] {
        let result = super.getDetailPanels();        
        if (this.state.rec_id) {
            result = result.concat(
                <SecStationPermListViewForStation
                    station_id={this.state.rec_id!}
                />
            );  
        }
        return result;
    }    
}
