import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewOauthClientRedirectUriRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Kliens (oauth2)
     */
    oauth_client_id?: number;
    /**
     * URI
     *
     * A protokol a domain név és a query path benne van, a query string nem. http//example.com/oauth/callback
     */
    uri?: string;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Kliens neve
     *
     * OAuth2 kliens neve
     */
    oauth2_client_name?: string|null;
    /**
     * Kliens leírása
     *
     * OAUth2 kliens leírása
     */
    oauth2_client_description?: string|null;
}

/**
 *  sys.view_oauth_client_redirect_uri - Oauth kliens redirect URI
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewOauthClientRedirectUri extends View<IViewOauthClientRedirectUriRecord> {
    public static VIEW_INFO_ID = 201804171121;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_oauth_client_redirect_uri'; }
    public static getTableNameForClass(): string|null { return 'oauth_client_redirect_uri'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewOauthClientRedirectUri>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewOauthClientRedirectUriRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewOauthClientRedirectUriClassProxy extends ViewClassProxy<IViewOauthClientRedirectUriRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewOauthClientRedirectUriClassProxy = new ViewOauthClientRedirectUriClassProxy(ViewOauthClientRedirectUri);
registerViewClassProxy(viewOauthClientRedirectUriClassProxy);

