export enum attendanceUserType {
    koordinator = 1, //koordinátor
    szakmaitamogato = 2, //szakmai támogató
    pedagogus = 3, //padegógus
    kapcsolattarto = 4, //intézményi kapcsolattartó
}
export enum attendanceCalendarType {
    Alprogram=1, //Alprogrami csoportszintű hospitálás
    DFHT=2, //DFHT-KIP és Komplax óra hospitálás
}

export enum attendanceSubroutinType {
    MA = 1, //Művészetalapú alprogrami foglalkozás
    TA = 2, //Testmozgásalapú alprogrami foglalkozás
    EA = 3, //Életgyakorlat-alapú alprogrami foglalkozás
    LA = 4, //Logikaalapú alprogrami foglalkozás 
    DA = 5, //Digitálisalapú alprogrami foglalkozás
}

export type attendanceSelectValueType = {
    value: string;
    label: string;
    color?: string;
    isFixed?: boolean;
    isDisabled?: boolean;
}