import * as React from 'react';
import { Link } from 'react-router-dom';
import { __ } from '@src/translation';

type MenuProps = {
    className?: string;
}
export class Menu extends React.Component<MenuProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
        };
    }

    render() {
        const childrenWithProps = React.Children.map(this.props.children, (child: any) =>{
            
            return (child && (child.type == MenuItem || child.type == SubMenu)) ?
                child
                : <li role="menuitem" className="">{child}</li>
        }

        );

        return <ul role="menubar" className={"dropdown vertical medium-horizontal menu " + (this.props.className ? this.props.className : "")}>
            {childrenWithProps}
        </ul>
    }
}

type MenuItemProps = {
    className?: string;
    imgSrc?: string;
}
export class MenuItem extends React.Component<MenuItemProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <li role="menuitem" className={"menuitem " + (this.props.className ? this.props.className : "")}>
            {
                this.props.imgSrc
                    ?
                    <>
                        <img src={this.props.imgSrc} className="eke-header-icons" />
                        <span className="hide-for-medium-only menu-title">{this.props.children}</span>
                    </>
                    : this.props.children
            }

        </li>
    }
}

type SubMenuProps = {
    title: string | JSX.Element;
    linkTo?: string;
    className?: string;
    isActive?: boolean;
    imgSrc?: string;
    imgActiveSrc?: string;
    ariaHidden?: boolean;
    showTitle?: boolean;
}
export class SubMenu extends React.Component<SubMenuProps, { expanded: boolean, lastActive?: Element }> {

    constructor(props: any) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyDown.bind(this), true);
    }

    componentDidUpdate(prevProps: SubMenuProps, prevState: { expanded: boolean }) {
        var is_touch_device = 'ontouchstart' in document.documentElement;
        if (is_touch_device && this.state.expanded && prevState.expanded == this.state.expanded) {

        }
    }

    keyDown(e: any) {
        if ((e.key == 'Escape' || e.key == 'Esc' || e.keyCode == 27)) {
            e.preventDefault();
            this.setState({ expanded: false });
            if (this.state.lastActive) {
                (this.state.lastActive as HTMLElement).focus();
            }
        }
    }

    onClick(event: any) {

        this.setState({ expanded: !this.state.expanded, lastActive: document.activeElement! });
        event.preventDefault();
        event.stopPropagation();
    }
    onHover(event: any) {
        // console.log("hover")
        var is_touch_device = 'ontouchstart' in document.documentElement;
        //  console.log("is_touch_device", is_touch_device)
        if (!is_touch_device) {
            this.setState({ expanded: true });
        }
    }
    onLeave(event: any) {
        var is_touch_device = 'ontouchstart' in document.documentElement;
        if (!is_touch_device) {
            this.setState({ expanded: false });
        }
    }
    onNavClick(event: any) {
        //  console.log("onNavClick") 
        var is_touch_device = 'ontouchstart' in document.documentElement;
        if (is_touch_device) {
            if (!this.state.expanded) {

                event.preventDefault();
                event.stopPropagation();
                this.setState({ expanded: true });
            }
            else {
                this.setState({ expanded: false });
            }
        }
    }

    render() {
        const childrenWithProps = React.Children.map(this.props.children, (child: any) =>

            (child && child.type != MenuItem)
                ?
                <li role="menuitem" onClick={() => this.setState({ expanded: false })}>
                    {child}
                </li>
                : child

        );

        var imgSrc = this.props.isActive ? this.props.imgActiveSrc : this.props.imgSrc;
        return <li role="menu" key="" className={"has-submenu is-dropdown menuitem " + (this.props.className ? this.props.className : "")} aria-haspopup={true} onMouseOver={this.onHover.bind(this)} onMouseOut={this.onLeave.bind(this)}>
            <div className="submenu-title">
                {
                    this.props.linkTo
                        ?
                        <Link onFocus={this.onHover.bind(this)} className={"top-bar__link" + (this.props.isActive ? " is-active" : "")} to={this.props.linkTo} onClick={this.onNavClick.bind(this)}>
                            {
                                imgSrc ? <img aria-hidden={this.props.ariaHidden} src={imgSrc} className="eke-header-icons" /> : null
                            }
                            <span className="hide-for-medium-only menu-title">
                                {this.props.title}
                            </span>
                        </Link>

                        : <a onFocus={this.onHover.bind(this)} href="#" aria-hidden={true} className={"top-bar__link" + (this.props.isActive ? " is-active" : "")} onClick={this.onNavClick.bind(this)}>
                            {imgSrc ? <img src={imgSrc} className="eke-header-icons" aria-hidden={this.props.ariaHidden} /> : null}
                            <span className="hide-for-medium-only menu-title">{this.props.title}</span>
                        </a>
                }
                {
                    childrenWithProps
                        ?
                        <button tabIndex={-1} className="submenu-btn" aria-expanded={this.state.expanded ? true : false} onClick={this.onClick.bind(this)}><span className="show-for-sr">{this.props.title}</span></button>
                        :
                        null
                }
            </div>
            {
                childrenWithProps
                    ?
                    <div className="eke-submenu" aria-expanded={this.state.expanded ? true : false} style={{ height: (this.state.expanded ? "" : "0"), display: (this.state.expanded ? "" : "none") }}>
                        {
                        this.props.showTitle
                        ?
                            <h4>{this.props.title}</h4>
                            : null
                        }
                        <ul role="menu" className="menu vertical eke-submenu_" >
                            {childrenWithProps}
                        </ul>
                    </div>
                    : null
            }

        </li>

    }
}

type MenuBarProps = {
    // className?: string;
    menuActions: MenuAction[];
    showSubmenuTitle?: boolean;
}
export class MenuBar extends React.Component<MenuBarProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
        };
    }

    renderMenuItems(menuActions: MenuAction[])
    {
        return (
        
            this.props.menuActions.map((action, index) => {
                if (action.subActions) {

                    return <SubMenu showTitle={this.props.showSubmenuTitle} isActive={action.isActive} key={index} className={action.className} linkTo={action.linkTo} title={action.hoverTitle ? action.hoverTitle : action.title} imgSrc={action.imgSrc} ariaHidden>
                        {
                            action.subActions.map((subAction, subIndex) => {

                                if(subAction.subActions)
                                {
                                    return this.renderMenuItems(subAction.subActions)
                                }
                                else
                                {
                                    return <a key={subIndex} href={subAction.linkTo || ""}>{subAction.title}</a>
                                }
                            })
                        }
                    </SubMenu>
                } else {
                    return <MenuItem key={index}>
                        <a key={"link-" + index} title={action.hoverTitle ? action.hoverTitle : action.title} className={"top-bar__link " + (action.className ? action.className : "") + (action.isActive ? " is-active" : "")} href={action.linkTo || ""}>
                            {action.imgSrc &&
                                <img aria-hidden src={action.imgSrc} className="eke-header-icons" />
                            }
                            <span className={"hide-for-medium-only"}> {action.title}</span>
                        </a>
                    </MenuItem>
                }
            })
        
        )
    }

    render() {


        return <Menu>                                                               
                        {this.renderMenuItems(this.props.menuActions)}
                </Menu>

            
    }
}


export type MenuAction = {
    title: string;
    linkTo?: string;
    imgSrc?: string;
    className?: string;
    isActive?: boolean;
    subActions?: MenuAction[];
    hoverTitle?: string;
    no?: number;
}

export function sortMenuActions(menuActions: MenuAction[]) {
    const ret = menuActions.sort((a, b) => {
        const _a = a.no ? a.no : 0;
        const _b = b.no ? b.no : 0;

        return _a - _b;

    });
    return ret;
}


