import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ShuffleResult, shuffle, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { PairingData } from "@src/component/exercise/engine/EKEPairingExerciseEngine/EKEPairingExerciseEngine";
import { AnswerTypes } from '@src/component/exercise/models/ExerciseBaseClass';
import * as React from "react";
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type PairingAElement = {
    pair1: PairingMultyAnswerType,
    pair2: PairingMultyAnswerType,
}

export type PairingMultyAnswerType = {
    type: AnswerTypes,
    url: string,
    text: string,
}

export type PairingAnswerElement = {
    num_of_questions: number,
    is_horizontal: boolean,
    pair1odd: any[],
    pair2odd: any[],
    answers: PairingAElement[]
}

function extractOldNkpPairingAnswers(data: any, answerArray: any, pair1List: any, pair2List: any, solution: any, left: boolean) {
    for (let index = 0; index < answerArray.length; index++) {
        if (answerArray[index]["$id"]) {
            let new_element = ExerciseBaseTypes.convertOldNKPToAnswerElement(answerArray[index], data);

            if (left)
                pair1List.push({
                    id: answerArray[index]["$id"],
                    element: new_element,
                    answers: answerArray[index].CorrectAnswers
                });
            else
                pair2List.push({
                    id: answerArray[index]["$id"],
                    element: new_element,
                    answers: answerArray[index].CorrectAnswers
                });

            extractOldNkpPairingAnswers(data, answerArray[index].CorrectAnswers, pair1List, pair2List, solution, !left);

        }
    }
}

export class EKEPairingConverter extends AExerciseTypeConverter {
    public hasTextAnswer = false;
    public hasImageAnswer = true;
    public hasTextImageAnswer = true;

    public needAlingmentInput = false;
    public pair2FixedType = true;

    static MIN_PAIR_NUM: number = 2;
    static MAX_PAIR_NUM: number = 30;
    static MAX_LIMITED_PAIR_NUM: number = 15;
    static MAX_1PAIR_ODD_NUM: number = 3;
    static MAX_2PAIR_ODD_NUM: number = 6;

    render() {
        let curr_ex: PairingAnswerElement = this.state.exercise as PairingAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                {this.needAlingmentInput ?
                    <div className="row">
                        <div className="large-12 columns">
                            <label ><input type="checkbox" name="is_horizontal" checked={curr_ex.is_horizontal || false}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                                { __("Horizontális megjelenítés") }</label>
                        </div>
                    </div>
                    : ""}
                <div className="row">
                    <div className="large-12 columns">
                        <label>{ __("Megjelenített kérdések száma (0 = összes)") }
                                    <input type="number" min="0" max="100" name="num_of_questions" value={curr_ex.num_of_questions || 0}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                        </label>
                    </div>
                </div>

                <Panel header={__("Párok")} headingLevel={5}>
                    {
                        curr_ex.answers ?
                            curr_ex.answers.map((curr_task: PairingAElement, index) => {

                                let actions: IAccordionAction[];
                                actions = [
                                    {
                                        title: __("Törlés"),
                                        type: AccordionActionType.Trash,
                                        action: this.removeElement.bind(this, "answers", index)
                                    },
                                    {
                                        title: __("Fel"),
                                        type: AccordionActionType.Up,
                                        action: this.moveUp.bind(this, "answers", index)
                                    },
                                    {
                                        title: __("Le"),
                                        type: AccordionActionType.Down,
                                        action: this.moveDown.bind(this, "answers", index)
                                    }
                                ];
                                return (
                                    <Accordion>
                                        <ActionAccordionItem defaultClosed={false} key={"tasks_" + index} actions={actions} title={__("Pár {n}", {n: index + 1})}>
                                            <Panel>
                                                <div className="row">
                                                    <h5>{ __("Első tag") } </h5>
                                                    <span className="exe-editor-validation-msg">{this.props.validationMessages.get("answers[" + index + "].pair1.type")}</span>
                                                </div>
                                                <div className="row">
                                                    <div className="large-4 columns">
                                                        <label> { __("Típus") }
                                            <select value={curr_task.pair1.type} name={"answers#" + index + ".pair1.type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                                <option value={AnswerTypes.text}>{ __("Szöveg") }</option>
                                                                <option value={AnswerTypes.image}>{ __("Kép") }</option>
                                                                <option value={AnswerTypes.sound}>{ __("Hang") }</option>
                                                            </select></label>
                                                    </div>
                                                    <div className="large-8 columns">
                                                        <label>{curr_task.pair1.type == AnswerTypes.text ? __("Válasz") : __("Leírás")}
                                                            <input type="text" name={"answers#" + index + ".pair1.text"} data-parent-index={index} data-index={index} value={curr_task.pair1.text}
                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                        </label>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="large-12 columns">
                                                        {curr_task.pair1.type != AnswerTypes.text ?
                                                            <label className="exe-image-select-label">{curr_task.pair1.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                                                {<ExerciseFileSelect
                                                                    imagebasepath={this.props.imagebasepath}
                                                                    value={curr_task.pair1.url || ""}
                                                                    onChange={this.handleImageChange.bind(this, "answers#" + index + ".pair1.url")}
                                                                    getFolderId={this.getFolderId.bind(this)}
                                                                    fileType={curr_task.pair1.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                                />}
                                                            </label>
                                                            : ""}
                                                    </div>
                                                </div>

                                            </Panel>
                                            {/* MÁSODIK TAG*/}
                                            <Panel>
                                                <div className="row">
                                                    <h5>{ __("Második tag") }</h5>
                                                    <span className="exe-editor-validation-msg">{this.props.validationMessages.get("answers[" + index + "].pair2.type")}</span>
                                                </div>
                                                <div className="row">
                                                    {this.pair2FixedType ? "" :
                                                        <div className="large-4 columns">
                                                            <label> { __("Típus") }
                                            <select value={curr_task.pair2.type} name={"answers#" + index + ".pair2.type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                                    <option value={AnswerTypes.text}>{ __("Szöveg") }</option>
                                                                    <option value={AnswerTypes.image}>{ __("Kép") }</option>
                                                                    <option value={AnswerTypes.sound}>{ __("Hang") }</option>
                                                                </select></label>
                                                        </div>
                                                    }

                                                    <div className="large-8 columns">
                                                        <label>{curr_task.pair2.type == AnswerTypes.text ? __("Válasz") : __("Leírás")}
                                                            <input type="text" name={"answers#" + index + ".pair2.text"} data-parent-index={index} data-index={index} value={curr_task.pair2.text}
                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                        </label>
                                                    </div>
                                                </div>
                                                {this.pair2FixedType ? "" :
                                                    <div className="row">
                                                        <div className="large-12 columns">
                                                            {curr_task.pair2.type != AnswerTypes.text ?
                                                                <label className="exe-image-select-label">{curr_task.pair2.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                                                    {<ExerciseFileSelect
                                                                        imagebasepath={this.props.imagebasepath}
                                                                        value={curr_task.pair2.url || ""}
                                                                        onChange={this.handleImageChange.bind(this, "answers#" + index + ".pair2.url")}
                                                                        getFolderId={this.getFolderId.bind(this)}
                                                                        fileType={curr_task.pair2.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                                    />}
                                                                </label>
                                                                : ""}
                                                        </div></div>
                                                }
                                            </Panel>
                                        </ActionAccordionItem>
                                    </Accordion>)
                            })
                            : ""
                    }
                    <div className="row">
                        <button className="button small" onClick={this.onAddNewPair.bind(this)}><i className="fa fa-plus"></i> { __("Új pár létrehozása") }</button>
                        <label className="exe-editor-label-description columns">{ __("Maximum {MAX_PAIR_NUM}  pár vehető fel.", {MAX_PAIR_NUM:EKEPairingConverter.MAX_PAIR_NUM}) }</label>
                    </div>
                </Panel>

                <Panel header={__("Pár 1 - Kakukktojás elemek")} headingLevel={5}>
                    {
                        !this.pair2FixedType ?
                            curr_ex.pair1odd ?
                                curr_ex.pair1odd.map((curr_task: any, index) => {
                                    return this.getOddOneElements(index, "pair1odd", curr_task);
                                })
                                : ""
                            : __("Párosítás kvíznél csak a második tag lehet kakukktojás.")
                    }
                    {!this.pair2FixedType ?
                        <div className="row"> 
                            <button className="button small" name="pair1.btn-add-odd-element" onClick={this.onAddNewOddonePair1.bind(this)}><i className="fa fa-plus"></i> { __("Új kakukktojás") }</button> 
                            <label className="exe-editor-label-description columns">{ __("Maximum {MAX_1PAIR_ODD_NUM} kakukktojás vehető fel.", {MAX_1PAIR_ODD_NUM:EKEPairingConverter.MAX_1PAIR_ODD_NUM}) }</label>
                        </div>
                        : ""}

                </Panel>

                <Panel header={__("Pár 2 - Kakukktojás elemek")} headingLevel={5}>
                    {
                        curr_ex.pair2odd ?
                            curr_ex.pair2odd.map((curr_task: any, index) => {
                                return this.getOddOneElements(index, "pair2odd", curr_task);
                            })
                            : ""
                    }
                    <div className="row"> 
                        <button className="button small" name="pair2.btn-add-odd-element" onClick={this.onAddNewOddonePair2.bind(this)}><i className="fa fa-plus"></i> { __("Új kakukktojás") }</button>
                        <label className="exe-editor-label-description columns">{ __("Maximum {MAX_2PAIR_ODD_NUM} kakukktojás vehető fel.", {MAX_2PAIR_ODD_NUM:EKEPairingConverter.MAX_2PAIR_ODD_NUM}) }</label>
                    </div>
                </Panel>

            </Panel>
        );
    }

    public convertToEditorAnswer(exercise: PairingData): PairingAnswerElement | undefined {
        if (exercise) {
            var answerList: PairingAElement[] = [];
            let pair1_Odd: any[] = [];
            let pair2_Odd: any[] = [];

            if (exercise.solution) {
                for (var _i = 0; _i < exercise.solution.length; _i++) {

                    var p2Index = exercise.solution[_i].value;
                    var curr_pair1: PairingMultyAnswerType;
                    curr_pair1 = {
                        type: AnswerTypes.text,
                        url: "",
                        text: exercise.options.pair1[_i] ? exercise.options.pair1[_i].text : ""
                    }
                    var curr_pair2: PairingMultyAnswerType;
                    curr_pair2 = {
                        type: AnswerTypes.text,
                        url: "",
                        text: exercise.options.pair2[p2Index] ? exercise.options.pair2[p2Index].text : ""
                    }
                    //Pair1
                    if (exercise.options.pair1[_i] && exercise.options.pair1[_i].type == "image") {
                        curr_pair1.type = AnswerTypes.image;
                        curr_pair1.url = exercise.options.pair1[_i] ? exercise.options.pair1[_i].image : "";
                    }
                    else if (exercise.options.pair1[_i] && exercise.options.pair1[_i].type == "sound") {
                        curr_pair1.type = AnswerTypes.sound;
                        curr_pair1.url = exercise.options.pair1[_i] ? exercise.options.pair1[_i].url : "";
                    }

                    //Pair2
                    if (exercise.options.pair2[p2Index] && exercise.options.pair2[p2Index].type == "image" && !this.pair2FixedType) {
                        curr_pair2.type = AnswerTypes.image;
                        curr_pair2.url = exercise.options.pair2[p2Index] ? exercise.options.pair2[p2Index].image : "";
                    }
                    else if (exercise.options.pair2[p2Index] && exercise.options.pair2[p2Index].type == "sound" && !this.pair2FixedType) {
                        curr_pair2.type = AnswerTypes.sound;
                        curr_pair2.url = exercise.options.pair2[p2Index] ? exercise.options.pair2[p2Index].url : "";
                    }

                    let curr_answer = {
                        pair1: curr_pair1,
                        pair2: curr_pair2
                    };
                    if (curr_pair2.text == "" && curr_pair2.url == "") pair1_Odd.push(curr_pair1)
                    else if (curr_pair1.text == "" && curr_pair1.url == "") pair2_Odd.push(curr_pair2)
                    else answerList.push(curr_answer);
                }
            }

            var answerElement: PairingAnswerElement = {
                answers: answerList,
                pair1odd: pair1_Odd,
                pair2odd: pair2_Odd,
                num_of_questions: exercise.num_of_questions,
                is_horizontal: exercise.is_horizontal,
            }
            return answerElement;
        }
        else return undefined;
    }

    public convertToJson(exerciseDetails: PairingAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: PairingAnswerElement): PairingData | undefined {

        if (exerciseDetails && exerciseDetails.answers) {
            var pair1List: any[] = [];
            var pair2List: any[] = [];

            if( exerciseDetails.pair1odd)
            exerciseDetails.pair1odd.forEach(el => {
                if (el.type == AnswerTypes.image) {
                    pair1List.push({ type: "image", text: el.text, image: el.url });
                }
                else if (el.type == AnswerTypes.sound) {
                    pair1List.push({ type: "sound", text: el.text, url: el.url });
                }
                else {
                    pair1List.push({ type: "text", text: el.text, image: "" });
                }
                pair2List.push({ type: "text", text: "", image: "" });
            });

            if(exerciseDetails.pair2odd)
            exerciseDetails.pair2odd.forEach(el => {
                if (el.type == AnswerTypes.image) {
                    pair2List.push({ type: "image", text: el.text, image: el.url });
                }
                else if (el.type == AnswerTypes.sound) {
                    pair2List.push({ type: "sound", text: el.text, url: el.url });
                }
                else {
                    pair2List.push({ type: "text", text: el.text, image: "" });
                }
                pair1List.push({ type: "text", text: "", image: "" });
            });

            for (var _i = 0; _i < exerciseDetails.answers.length; _i++) {
                //Pair1
                if (exerciseDetails.answers[_i].pair1.type == AnswerTypes.image) {
                    pair1List.push({ type: "image", text: exerciseDetails.answers[_i].pair1.text, image: exerciseDetails.answers[_i].pair1.url });
                }
                else if (exerciseDetails.answers[_i].pair1.type == AnswerTypes.sound) {
                    pair1List.push({ type: "sound", text: exerciseDetails.answers[_i].pair1.text, url: exerciseDetails.answers[_i].pair1.url });
                }

                else {
                    pair1List.push({ type: "text", text: exerciseDetails.answers[_i].pair1.text, image: "" });
                }
                // Pair2
                if (exerciseDetails.answers[_i].pair2.type == AnswerTypes.image) {
                    pair2List.push({ type: "image", text: exerciseDetails.answers[_i].pair2.text, image: exerciseDetails.answers[_i].pair2.url });
                }
                else if (exerciseDetails.answers[_i].pair2.type == AnswerTypes.sound) {
                    pair2List.push({ type: "sound", text: exerciseDetails.answers[_i].pair2.text, url: exerciseDetails.answers[_i].pair2.url });
                }

                else {
                    pair2List.push({ type: "text", text: exerciseDetails.answers[_i].pair2.text, image: "" });
                }

            }

            var shuffleResPair2: ShuffleResult = shuffle(pair2List, null);

            var solution = [];
            for (var _i = 0; _i < shuffleResPair2.indexes.length; _i++) {
                solution.push(
                    {
                        key: _i,
                        value: shuffleResPair2.indexes[_i]
                    }
                )
            }

            var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
            cur_exercise = {
                ...cur_exercise,
                options: { pair1: pair1List, pair2: shuffleResPair2.answers },
                pair2images: baseData.imagequestion,
                solution: solution,
                num_of_questions: exerciseDetails.num_of_questions,
                is_horizontal: exerciseDetails.is_horizontal
            };
            return cur_exercise;
        }
        else return undefined;
    }

    public convertOldNKPToJson(data: any): any | undefined {
        if (data && data.AnswersLeft && data.AnswersRight) {

            var pair1List: any = [];
            var pair2List: any = [];
            var solution: any = [];
            var elements: any = [];

            if (data.AnswersRight.length > data.AnswersLeft.length) {
                var temp = data.AnswersLeft;
                data.AnswersLeft = data.AnswersRight;
                data.AnswersRight = temp;
            }

            extractOldNkpPairingAnswers(data, data.AnswersLeft, pair1List, pair2List, solution, true);
            extractOldNkpPairingAnswers(data, data.AnswersRight, pair1List, pair2List, solution, false);

            elements = pair1List.concat(pair2List);

            for (let index = 0; index < pair1List.length; index++) {
                for (let curIdx = 0; curIdx < pair1List[index].answers.length; curIdx++) {
                    if (pair1List[index].answers[curIdx]["$id"])
                        solution.push(elements.find((x: any) => x.id == pair1List[index].answers[curIdx]["$id"]).element)
                    else if (pair1List[index].answers[curIdx]["$ref"])
                        solution.push(elements.find((x: any) => x.id == pair1List[index].answers[curIdx]["$ref"]).element)
                }
            }

            pair1List = pair1List.map((x: any) => x.element)
            pair2List = pair2List.map((x: any) => x.element)

            var realSolution = [];
            var shuffleResPair2: ShuffleResult = shuffle(solution, null);

            for (var _i = 0; _i < shuffleResPair2.indexes.length; _i++) {
                realSolution.push(
                    {
                        key: _i,
                        value: shuffleResPair2.indexes[_i]
                    }
                )
            }

            let newExercise = {
                title: data.Title,
                internal_code: "",
                description: data.QuestionText,
                backgroundStyle: { is_custom_background: false, backgroundImage: "", backgroundColor: "" },
                illustration: (ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image != "" && ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image != undefined) ?
                    ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image : null,
                level: data.QuestionDifficulty == 1 ? 1 : data.QuestionDifficulty == 2 ? 3 : 5,
                demo_path: "",
                imagebasepath: "", //await EditorServer.getExerciseNewImageBasePath()
                comment: data.Description,
                pair1images: pair1List[0].type == "image",
                pair2images: pair2List[0].type == "image",
                options: { pair1: pair1List, pair2: shuffleResPair2.answers },
                solution: realSolution,
                is_horizontal: true
            };

            return newExercise;
        } else
            return undefined
    }

    public validate(editorAnswer: PairingAnswerElement, baseData: any, validationMap?:Map<string,string>, is_accessible?:boolean|null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData,validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;

        let curr_ans_list = editorAnswer.answers;
        let errorMsg="";
        let OFIErrors: string[] = [];
        if (curr_ans_list && (curr_ans_list.length < EKEPairingConverter.MIN_PAIR_NUM || (!AExerciseTypeConverter.isOfiEditor  && curr_ans_list.length > EKEPairingConverter.MAX_PAIR_NUM))) {
            errorMsg = __("Minimum {MIN_PAIR_NUM}, maximum {MAX_PAIR_NUM} pár adható meg!", {MIN_PAIR_NUM:EKEPairingConverter.MIN_PAIR_NUM, MAX_PAIR_NUM:EKEPairingConverter.MAX_PAIR_NUM});
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            return { valid: false, message: errorMsg }
        }

        if(editorAnswer.num_of_questions == 1 || (editorAnswer.num_of_questions > EKEPairingConverter.MAX_LIMITED_PAIR_NUM)) {
            errorMsg = __("A megjelenített elemszám min. {min}, max. {max} lehet!", {min:2, max:EKEPairingConverter.MAX_LIMITED_PAIR_NUM});
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if(!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
        }

        let is_empty_pair2 = false;
        for (let i = 0; i < curr_ans_list.length; i++) {
            if (curr_ans_list[i].pair2.text == "" && (curr_ans_list[i].pair2.url == ""))
                is_empty_pair2 = true;
            if(curr_ans_list[i].pair1.type == AnswerTypes.text && curr_ans_list[i].pair1.text.length > EKEPairingConverter.MAX_ALT_TEXT_LENGTH)
            {
                errorMsg=__("A pár1 szövegének hossza maximum {MAX_ALT_TEXT_LENGTH} lehet!", {MAX_ALT_TEXT_LENGTH:EKEPairingConverter.MAX_ALT_TEXT_LENGTH});
                if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                validationMap!.set("answers["+i+"].pair1.text",errorMsg);
                if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg}
            }
            if(curr_ans_list[i].pair2.type == AnswerTypes.text && curr_ans_list[i].pair2.text.length > EKEPairingConverter.MAX_ALT_TEXT_LENGTH)
            {
                errorMsg=__("A pár2 szövegének hossza maximum {MAX_ALT_TEXT_LENGTH} lehet!", {MAX_ALT_TEXT_LENGTH:EKEPairingConverter.MAX_ALT_TEXT_LENGTH} );
                if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                validationMap!.set("answers["+i+"].pair2.text",errorMsg);
                if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg}
            }
        }

        if(curr_ans_list.length < editorAnswer.pair1odd.length + editorAnswer.pair2odd.length) {
            errorMsg = __("Nem lehet több kakukktojás elem, mint normál válasz.");
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            return { valid: false, message: errorMsg };
        }

        if (editorAnswer.pair1odd)
        {
            for (let i = 0; i < editorAnswer.pair1odd.length; i++) {
                let curr1PairElem = editorAnswer.pair1odd[i];
                if (curr1PairElem.type == AnswerTypes.text) {
                    if (curr1PairElem.text.length == 0) {
                        errorMsg = __("Szöveg típusú kakukktojásnál kötelező szöveget megadni!");
                        if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                        return { valid: false, message: errorMsg } 
                    }
                }
                else {
                    if (curr1PairElem.url.length == 0) {
                        errorMsg = __("Adjon meg képet vagy hangfájlt!");
                        if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                        return { valid: false, message: errorMsg } 
                    }
                }
            }
        }

        if (editorAnswer.pair2odd)
        {
            for (let i = 0; i < editorAnswer.pair2odd.length; i++) {
                let curr2PairElem = editorAnswer.pair2odd[i];
                if (curr2PairElem.type == AnswerTypes.text) {
                    if (curr2PairElem.text.length == 0) {
                        errorMsg = __("Szöveg típusú kakukktojásnál kötelező szöveget megadni!");
                        if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                        return { valid: false, message: errorMsg }
                    }
                }
                else {
                    if (curr2PairElem.url.length == 0) {
                        errorMsg = __("Adjon meg képet vagy hangfájlt!");
                        if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                        return { valid: false, message: errorMsg }
                    }
                }
            }
        }

        if (this.pair2FixedType && is_empty_pair2) {
            errorMsg = __("Minden Pár2 típusú elemenek adni kell értéket!");
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            return { valid: false, message: errorMsg };
        }

        if (AExerciseTypeConverter.isOfiEditor){
            return { valid: true, message: OFIErrors.join(' , ') }
        }

        return{valid:true};
    }

    public makeValidBeforeSetState(oldState: PairingAnswerElement | null, newState: PairingAnswerElement): ValidationResponse {
        if(AExerciseTypeConverter.isOfiEditor ) return {valid:true};
        if (newState.answers && newState.answers.length > EKEPairingConverter.MAX_PAIR_NUM) {
            newState.answers.splice(EKEPairingConverter.MAX_PAIR_NUM, newState.answers.length - EKEPairingConverter.MAX_PAIR_NUM);
            return { valid: false, message: __("Maximum {MAX_PAIR_NUM} pár vehető fel.", {MAX_PAIR_NUM:EKEPairingConverter.MAX_PAIR_NUM}) }
        }

        if (newState.pair1odd && newState.pair1odd.length > EKEPairingConverter.MAX_1PAIR_ODD_NUM){
            newState.pair1odd.splice(EKEPairingConverter.MAX_1PAIR_ODD_NUM, newState.pair1odd.length - EKEPairingConverter.MAX_1PAIR_ODD_NUM);
            return { valid: false, message: __("Maximum {MAX_1PAIR_ODD_NUM} pár1 kakukktojás vehető fel.", {MAX_1PAIR_ODD_NUM:EKEPairingConverter.MAX_1PAIR_ODD_NUM}) }
        }

        if (newState.pair2odd && newState.pair2odd.length > EKEPairingConverter.MAX_2PAIR_ODD_NUM){
            newState.pair2odd.splice(EKEPairingConverter.MAX_2PAIR_ODD_NUM, newState.pair2odd.length - EKEPairingConverter.MAX_2PAIR_ODD_NUM);
            return { valid: false, message: __("Maximum {MAX_2PAIR_ODD_NUM} pár2 kakukktojás vehető fel.", {MAX_2PAIR_ODD_NUM:EKEPairingConverter.MAX_2PAIR_ODD_NUM}) }
        }

        return { valid: true }
    }

    public isSolutionAvailable(exerciseJson: ExerciseBaseTypes.ExerciseBaseClass): boolean {
        if (!super.isSolutionAvailable(exerciseJson))
            return false;
        if (exerciseJson.solution.length < 2) return false;
        return true;
    }

    onAddNewPair() {
        let temp_exercise = this.state.exercise as PairingAnswerElement;
        if (!temp_exercise.answers) temp_exercise.answers = [];
        temp_exercise.answers.push(this.getNewPair());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    onAddNewOddonePair1() {
        let temp_exercise = this.state.exercise as PairingAnswerElement;
        if (!temp_exercise.pair1odd) temp_exercise.pair1odd = [];
        temp_exercise.pair1odd.push({ type: AnswerTypes.text, text: "", url: "" });
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    onAddNewOddonePair2() {
        let temp_exercise = this.state.exercise as PairingAnswerElement;
        if (!temp_exercise.pair2odd) temp_exercise.pair2odd = [];
        temp_exercise.pair2odd.push({ type: AnswerTypes.text, text: "", url: "" });
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    getNewPair(): PairingAElement {
        let pair1: PairingMultyAnswerType = {
            url: "",
            text: "",
            type: AnswerTypes.text
        };
        let pair2: PairingMultyAnswerType = {
            url: "",
            text: "",
            type: AnswerTypes.text
        };
        return { pair1, pair2 };
    }

    getOddOneElements(index: number, name: string, curr_task: any) {
        let actions: IAccordionAction[];
        actions = [
            {
                title: __("Törlés"),
                type: AccordionActionType.Trash,
                action: this.removeElement.bind(this, name, index)
            },
            {
                title: __("Fel"),
                type: AccordionActionType.Up,
                action: this.moveUp.bind(this, name, index)
            },
            {
                title: __("Le"),
                type: AccordionActionType.Down,
                action: this.moveDown.bind(this, name, index)
            }
        ];
        return (
            <Accordion>
                <ActionAccordionItem defaultClosed={false} key={name + "_" + index} className={name+"-" + index} actions={actions} title={__("{n}. elem", {n: index + 1})}>
                    <Panel>
                        <div className="row">
                            <h5>{ __("Elem") }</h5> <span className="exe-editor-validation-msg">{this.props.validationMessages.get(name+"[" + index + "].type")}</span>
                        </div>
                        <div className="row">
                            {(this.pair2FixedType && name == "pair2odd") ? "" : <div className="large-6 columns">
                                <label> { __("Típus") }
                    <select value={curr_task.type} name={name + "#" + index + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                        <option value={AnswerTypes.text}>{ __("Szöveg") }</option>
                                        <option value={AnswerTypes.image}>{ __("Kép") }</option>
                                        <option value={AnswerTypes.sound}>{ __("Hang") }</option>
                                    </select></label>
                            </div>}
                            <div className="large-6 columns">
                                <label>{curr_task.type == AnswerTypes.text ? __("Válasz") : __("Leírás")}
                                    <input type="text" name={name + "#" + index + ".text"} data-parent-index={index} data-index={index} value={curr_task.text}
                                        onBlur={this.onBlurEvent.bind(this)}
                                        onChange={this.handleInputChange.bind(this)} />
                                </label>
                            </div>

                        </div>
                        {(this.pair2FixedType && name == "pair2odd") ? "" : <div className="row">
                            <div className="large-6 columns">
                                {curr_task.type != AnswerTypes.text ?
                                    <label className="exe-image-select-label">{curr_task.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                        {<ExerciseFileSelect
                                            imagebasepath={this.props.imagebasepath}
                                            value={curr_task.url || ""}
                                            onChange={this.handleImageChange.bind(this, name + "#" + index + ".url")}
                                            getFolderId={this.getFolderId.bind(this)}
                                            fileType={curr_task.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                        />}
                                    </label>
                                    : ""}
                            </div>
                        </div>}

                    </Panel>
                </ActionAccordionItem>
            </Accordion>)
    }


    extractOldNkpPairingAnswers(data: any, answerArray: any, pair1List: any, pair2List: any, solution: any, left: boolean) {
        for (let index = 0; index < answerArray.length; index++) {
            if (answerArray[index]["$id"]) {
                let new_element = ExerciseBaseTypes.convertOldNKPToAnswerElement(answerArray[index], data);

                if (left)
                    pair1List.push({
                        id: answerArray[index]["$id"],
                        element: new_element,
                        answers: answerArray[index].CorrectAnswers
                    });
                else
                    pair2List.push({
                        id: answerArray[index]["$id"],
                        element: new_element,
                        answers: answerArray[index].CorrectAnswers
                    });

                extractOldNkpPairingAnswers(data, answerArray[index].CorrectAnswers, pair1List, pair2List, solution, !left);

            }
        }
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        let answersStat: StatisticsResponse;
        let firstOddStat: StatisticsResponse;
        let secondOddStat: StatisticsResponse;
        statisticsResponses.push({name:__("limit - limitált elemszám: {n}", {n: EKEPairingConverter.MAX_LIMITED_PAIR_NUM}), count:undefined});
        statisticsResponses.push({name:__("limit - párok min. száma: {n}", {n: EKEPairingConverter.MIN_PAIR_NUM}), count:undefined});
        statisticsResponses.push({name:__("limit - párok max. száma: {n}", {n: EKEPairingConverter.MAX_PAIR_NUM}), count:undefined});
        answersStat = { name: __("Párok száma"), count: new Map() }
        firstOddStat = { name: __("Pár 1 - kakukktojás elemek száma") + " ", count: new Map() }
        secondOddStat = { name: __("Pár 2 - kakukktojás elemek száma") + " ", count: new Map() }
        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const currentExc = exerciseList[i];
            let answer_count = answersStat.count!.has(currentExc.answers.length) ? answersStat.count!.get(currentExc.answers.length) : 0;
            answersStat.count!.set(currentExc.answers.length, answer_count! + 1);

            const pair1_odd_one_out = currentExc.pair1odd;
            if (pair1_odd_one_out) {
                let ooo_count = firstOddStat.count!.has(pair1_odd_one_out.length) ? firstOddStat.count!.get(pair1_odd_one_out.length) : 0;
                firstOddStat.count!.set(pair1_odd_one_out.length, ooo_count! + 1);
            }
            const pair2_odd_one_out = currentExc.pair2odd;
            if (pair2_odd_one_out) {
                let ooo_count = secondOddStat.count!.has(pair2_odd_one_out.length) ? secondOddStat.count!.get(pair2_odd_one_out.length) : 0;
                secondOddStat.count!.set(pair2_odd_one_out.length, ooo_count! + 1);
            }
        }
        statisticsResponses.push(answersStat, firstOddStat, secondOddStat);
        return statisticsResponses;
    }


}