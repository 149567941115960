import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISecGroupTypeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     */
    title?: string;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Tagok megosztása
     *
     * A csoport tagjai látják egymást, ki tudják egymást választani olyan helyeken, ahol felhasználót lehet választani.
     */
    share_members_list?: boolean;
}

/**
 *  sys.sec_group_type - Felhasználói csoport típus
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class SecGroupTypeCrud extends Crud<ISecGroupTypeRecord> {
    public static TABLE_INFO_ID = 2780422447;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'sec_group_type'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<SecGroupTypeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISecGroupTypeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISecGroupTypeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISecGroupTypeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISecGroupTypeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISecGroupTypeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SecGroupTypeCrudClassProxy extends CrudClassProxy<ISecGroupTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const secGroupTypeCrudClassProxy = new SecGroupTypeCrudClassProxy(SecGroupTypeCrud);
registerCrudClassProxy(secGroupTypeCrudClassProxy);
