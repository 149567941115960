import * as React from 'react';
import { Link } from 'react-router-dom';
import { __ } from '@src/translation';
import { me } from '../server/Auth';

/**
 * Közös "hozzáférés megtagadva" lap.
 *
 * TODO: sokkal szebbre megírni.
 */
export default function AccessDeniedPage() {
    return (
        <div className="row">
            <div className="column">
                <br/>
                <h2>{__("Hiba")}</h2>
                <h3>
                {
                    me
                    ?
                    __("Hozzáférés megtagadva - nincs elegendő jogosultsága")
                    :
                    __("Hozzáférés megtagadva - nincs bejelentkezve")
                }
                </h3>
                {
                    !me &&
                    <p>
                        A funkció eléréséhez kérem jelentkezzen be.
                    </p>
                }
                <p>
                    <Link to="/">{__("Vissza a főlapra")}</Link>
                </p>
            </div>
        </div>
    );
}
