import obtainServer from '@framework/server/Server';
import { LpPlayStateNode } from './LearningPathContainer';

const URL = 'learning_path_play';

export default class LearningPathAPI {

    public static createOrload = (learningPathId: number): Promise<LpPlayStateNode> => {
        return obtainServer().get<LpPlayStateNode>("learning_path_play?learning_path_id=" + learningPathId);
        //return obtainServer().post<ILearningPath>(URL, {operation:"load",  });
    }

    public static evaluateNode = (learningPathId: number, userResult: any): Promise<LpPlayStateNode> => {
        return obtainServer().get<LpPlayStateNode>("learning_path_play?learning_path_id=" + learningPathId + 
            "&minutes=" + userResult.minutes + "&points=" + userResult.points + "&node_id=" + userResult.node_id);
    }

    public static goToNextNode = (learningPathId: number, goToNodeId: number): Promise<LpPlayStateNode> => {
        return obtainServer().get<LpPlayStateNode>("learning_path_play?learning_path_id=" + learningPathId + "&goto_node_id=" + goToNodeId);
    }

    public static reloadFromBeginning = (learningPathId: number): Promise<LpPlayStateNode> => {
        return obtainServer().get<LpPlayStateNode>("learning_path_play?learning_path_id=" + learningPathId + "&reset=" + "true");
    }
}

