import * as React from 'react';
import { History } from 'history';
import ReactTable, { TableProps, Column, RowInfo } from 'react-table';
import 'react-table/react-table.css';
import { getReactTableLabels } from '@src/framework/i18n';
import { debounce } from 'lodash';
import './knowledgeBase.css';
import { me, Groups, hasGroup } from '@src/framework/server/Auth';
import { app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';;
import { PATH_KNOWLEDGE_BASE_EDIT, PATH_KNOWLEDGE_BASE_VIEW } from '@src/Routes';
import { Link, match } from 'react-router-dom';
import ViewItem, { IViewItemRecord } from '@src/framework/view/kb/ViewItem';
import ItemCrud from '@src/framework/crud/kb/ItemCrud';
import { formatDate, formatUserName } from '@src/Util';
import { confirmDialog } from '../Dialog';
import { __ } from '@src/translation';

var saved = {
  filters: [],
  sorted: [],
  pageSize: 10,
  page: 0,
}
interface KnowledgeBaseListProps {

  history: History;
  onKnowledgeBaseItemSelected?: (kbItemRecord: IViewItemRecord) => void;
  match: match<{ keywords: string }>;
  location: Location;
  currentMediaType: string

}
class KnowledgeBaseListState {

  instructionRecords?: IViewItemRecord[];
  count: number;
  loading: boolean;
  pageSize: number;
  keywords: string;
  page: number;
  sorted: any[];
  filters: any[];
  prevSearch?: string;
  prevType?: string;
}

export default class KnowledgeBaseList extends React.Component<KnowledgeBaseListProps, KnowledgeBaseListState> {
  constructor(props: any) {
    super(props);
    this.state = {
      instructionRecords: [],
      keywords: '',
      loading: false,
      pageSize: saved.pageSize,
      page: saved.page,
      count: 0,
      sorted: saved.sorted,
      filters: saved.filters,
      prevSearch: undefined,
    };
    this.asyncReload();
  }

  componentDidMount() {
    ($(document) as any).foundation();
  }

  private asyncReload = async () => {
    try {

      this.setState({
        loading: true
      })

      var filter: any = {};

      if (this.state.keywords && this.state.keywords.length > 0 && this.state.keywords != "") {

        filter = { ...filter, search_field: { kind: "word_search", case_sensitive: false, expr: this.state.keywords } };

      }
      filter = { ...filter, is_active: true };
      var orderBy: any = undefined;

      if (this.state.sorted.length > 0) {
        const userOrder = this.state.sorted[0];

        if (userOrder.desc) {
          orderBy = [{ name: userOrder.id, desc: true }];
        } else {
          orderBy = userOrder.id
        }
      }
      if (!orderBy) { orderBy = [{ name: "creation_time", desc: true }]; }

      var listParameters = {
        filter: filter,
        order_by: orderBy,
        limit: this.state.pageSize,
        offset: this.state.page * this.state.pageSize
      };

      var knowledgeBaseRecords = await ViewItem.list(listParameters);
      for (var knowledgeBaseRecord of knowledgeBaseRecords) {
        const descriptionDiv = document.createElement("div");
        descriptionDiv.innerHTML = knowledgeBaseRecord.description || "";

        knowledgeBaseRecord["item_type_title"] = knowledgeBaseRecord.item_type_title;
        knowledgeBaseRecord["subject"] = knowledgeBaseRecord.subject;
        knowledgeBaseRecord["keywords"] = knowledgeBaseRecord.keywords;
        knowledgeBaseRecord["description"] = <Link to={PATH_KNOWLEDGE_BASE_VIEW + '/' + knowledgeBaseRecord.id}>{descriptionDiv.innerText}</Link> as any;

        knowledgeBaseRecord["last_modified"] = formatDate(knowledgeBaseRecord.creation_time);
        knowledgeBaseRecord["last_modifier"] = formatUserName(knowledgeBaseRecord.creator);
        if (knowledgeBaseRecord.modification_time) {
          knowledgeBaseRecord["last_modified"] = formatDate(knowledgeBaseRecord.modification_time);
          knowledgeBaseRecord["last_modifier"] = formatUserName(knowledgeBaseRecord.modifier);
        }

        var editBtn = null;
        var showBtn = null;
        var delBtn = null;

        if (this.props.onKnowledgeBaseItemSelected) {
          editBtn = <button className="button small warning" onClick={this.onKnowledgeBaseItemSelect.bind(this, knowledgeBaseRecord)}><i className="fa fa-plus"></i></button>;
        }
        else {

          if (!this.props.onKnowledgeBaseItemSelected &&
            me &&
             (knowledgeBaseRecord.creation_user_id==me.id||knowledgeBaseRecord.modification_user_id==me.id)||
            (me&& hasGroup(me, Groups.Admin)))
             {
            editBtn = <Link className="button small warning" title={__("Szerkesztés")} to={PATH_KNOWLEDGE_BASE_EDIT + `/${knowledgeBaseRecord.id}/`}><i className="fa fa-edit"></i></Link>;
            delBtn = <button className="button small alert" title={__("Törlés")} onClick={this.deleteKnowledgeBaseItem.bind(this, knowledgeBaseRecord)}><i className="fa fa-trash"></i></button>;
          }
        }
        showBtn = <Link className="button small" title={__("Megtekintés")} to={PATH_KNOWLEDGE_BASE_VIEW + `/${knowledgeBaseRecord.id}`}><i className="fa fa-eye"></i></Link>;
        knowledgeBaseRecord["link"] = <div>{showBtn} {editBtn} {delBtn} </div>;
      }

      this.setState({
        instructionRecords: knowledgeBaseRecords,
        loading: false,
        count: knowledgeBaseRecords.length

      });
    }
    catch (error) {
      app.showErrorFromJsonResult(error);
    }
  }

  onFetchData(tablestate: any) {
    if (this.state.filters != tablestate.filtered) {
      this.debouncedSetTableState(tablestate);
    } else {
      this.setTableState(tablestate);
    }
  }
  debouncedSetTableState = debounce((tablestate) => this.setTableState(tablestate), 300);

  private setTableState(tablestate: any) {

    saved.page = tablestate.page;
    saved.pageSize = tablestate.pageSize;
    saved.sorted = tablestate.sorted;
    saved.filters = tablestate.filtered;

    this.setState({
      page: tablestate.page,
      pageSize: tablestate.pageSize,
      sorted: tablestate.sorted,
      filters: tablestate.filtered,
    }, this.asyncReload);
  }
  onKnowledgeBaseItemSelect(KnowledgeBaseRecord: IViewItemRecord) {

    if (this.props.onKnowledgeBaseItemSelected) {
      this.props.onKnowledgeBaseItemSelected(KnowledgeBaseRecord);
    } else {
      app.showError(__("Hiba!"), __("Nem lehet bejegyzést választani!"));
    }
  }

  setCurrentSearch() {
    this.asyncReload();
  }

  onKeyPress(e: any) {
    if (e.key === 'Enter') {
      this.setCurrentSearch();
    }
  }
  onInputChange(e: any) {
    this.setState(
      { keywords: e.target.value }
    );
  }
  deleteKnowledgeBaseItem = async (kbItem:IViewItemRecord) => {
    if (!await confirmDialog(__("Megerősítés!"),__(`Biztosan törölni szeretné az alábbi kiválasztott elemet?`),__("Igen"))) {
      return;
    }
    try {
        if (kbItem) {
            await ItemCrud.deleteById(Number(kbItem.id));
            this.asyncReload();
            app.showSuccess(__("Bejegyzés törlése"), __("Sikeres"))
          return;
        } else {
          app.showError(__("Hiba"), __("Nem sikerült törölni"));
        }
      
    }
    catch (e) {
      app.showErrorFromJsonResult(e);
    }
  }
  render() {
    if (this.state.instructionRecords === undefined) {
      return <BubbleLoader />
    }
    var instructionRecords = this.state.instructionRecords;

    const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
      let props: Partial<TableProps> = {};

      if (column && rowInfo && rowInfo.row) {
        let row = rowInfo.row._original;
        if (row && column.id == "wf_station_name") {
          props.style = row.wf_station_style || {};
        }
      }
      return props;
    }

    const tableProps = {
      columns: [
        { Header: __("Típus"), accessor: "item_type_title", filterable: false, width: 120 },
        { Header: __("Tárgy"), accessor: "subject", filterable: false, width: 180},
        { Header: __("Kulcsszavak"), accessor: "keywords", filterable: false, width: 180},
        { Header: __("Szöveg"), accessor: "description", filterable: false },
        { Header: __("Készítő"), accessor: "last_modifier", filterable: false, width: 150, sortable: false},
        { Header: __("Dátum"), accessor: "last_modified", filterable: false, width: 150, sortable: false},
        { Header: __("Művelet"), accessor: "link", filterable: false, sortable: false, width: 180, className: "action-cell" },
      ],

      data: instructionRecords,
      defaultPageSize: this.state.pageSize,
      defaultFiltered: this.state.filters,
      defaultSorted: this.state.sorted,
      pages: Math.ceil(this.state.count / this.state.pageSize),
      filterable: false,
      className: "-striped -highlight",
      ...getReactTableLabels(),
      onFetchData: this.onFetchData.bind(this),
      loading: this.state.loading,
      getTdProps,
      manual: true
    };

    if (this.state.instructionRecords) {
      return (
        <div className="Knowledgebase-maincomponent Knowledgebase-list-page row">
          <div className="medium-offset-3 medium-6 small-12 row">
            <div className="column">
              <div className="input-group eke-search-field">
                <input onKeyPress={this.onKeyPress.bind(this)} onChange={this.onInputChange.bind(this)} className="input-group-field eke-search__input" placeholder={__("Keresés")} />
                <div className="input-group-button">
                  <button onClick={this.setCurrentSearch.bind(this)} className="shrink column button eke-search__button">{__("Keresés")}</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row large-12 medium-12 small-12">
            <div className="small-12 medium-12 column clearfix">
              {
                (me && (!hasGroup(me, Groups.Student) || (hasGroup(me, Groups.OFIEditor || Groups.Developer)))) ?

                  <Link className="button small eke-general-buttons float-left" to={PATH_KNOWLEDGE_BASE_EDIT}><i className="fa fa-plus" /> {__("Új bejegyzés létrehozása")}</Link>
                  : ""
              }
            </div>
          </div>
          <div className="small-12 medium-12 large-12" id="modaldiv">
            <label className="result-count">&nbsp;{this.state.count ? __("{n} találat", {n: this.state.count}) : __("0 találat")}</label>
            <ReactTable {...tableProps} />        
          </div>
        </div>
      );

    } else {
      return <div><BubbleLoader /></div>
    }
  }
};
