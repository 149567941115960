export class EKECrossWordServer{
    
    static evaluateOnServer(exercise: any, correctSolution: any, userSolution: any): Evaluated {
        let success = true;
        let correctAnswers = 0;
        let successPercent = 0;
        let totalQuests = 0;
        for (let i = 0; i < correctSolution.length; i++) {
            totalQuests++;
            if (correctSolution[i] && (!userSolution[i] || correctSolution[i].toUpperCase() != userSolution[i].toUpperCase())) {
                success = false;
            }
            else {
                correctAnswers++;
            }
        }
        if (correctAnswers > 0) {
            successPercent = Number((correctAnswers / totalQuests).toFixed(2));
        }
        return { "success": success, "solution": correctSolution, "successPercent": successPercent };

    }

    static filterExerciseOnServer(exercise: any, solution: any): any {

        if (!exercise || !solution || JSON.stringify(solution) == "{}") return null;
        if ((exercise.isHintTextVisible != null && !exercise.isHintTextVisible) || !exercise.words)
            return exercise;

        for (let i = 0; i < exercise.words.length; i++) {
            if (exercise.words[i].hint_index instanceof Array) {
                var hints: any[] = [];
                for (let k = 0; k < exercise.words[i].hint_index.length; k++) {
                    let j = Number(exercise.words[i].hint_index[k]) > 0 ? Number(exercise.words[i].hint_index[k]) - 1 : Math.floor(Math.random() * (exercise.words[i].letters));
                    exercise.words[i].hint_index[k] = j;
                    hints.push(solution[i][j]);
                }
                exercise.words[i].hint_letter = hints;
            } else if (exercise.words[i].hint_index instanceof Array == false) {
                let j = exercise.words[i].hint_index > 0 ? exercise.words[i].hint_index - 1 : Math.floor(Math.random() * (exercise.words[i].letters));
                exercise.words[i].hint_index = j;
                exercise.words[i].hint_letter = solution[i][j];
            }

        }
        exercise.isHintTextVisible = true;
        return exercise;
    }


}