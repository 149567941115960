import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import * as React from "react";
import { AExerciseTypeConverter, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import ExerciseImageCoordinatePick, { Shapes } from "@src/component/exercise/Editor/ExerciseImageCoordinatePick";
import ExerciseFileSelect, { ExerciseFileTypes } from '@src/component/exercise/Editor/ExerciseFileSelect';
import { AnswerElement } from "@src/component/exercise/models/ExerciseBaseClass";
import { AnswerTypes } from '@src/component/exercise/models/ExerciseBaseClass';
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type AssignPointToQuestionAnswerElement = {
    answers: AssignPointToQuestionAnswerType[],
    num_of_questions: number
}

export type AssignPointToQuestionAnswerType = {
    answerElement: AnswerElement,
    areas: Area[]
}

export type Area = {
    x?: number,
    y?: number,
    radius?: number,
    shape?: Shapes,
    points?: string
}

export class WMAssignPointToQuestionConverter extends AExerciseTypeConverter {

    public hasTextAnswer = false;
    public hasImageAnswer = true;
    public hasTextImageAnswer = false;
    static MAX_ANSWER_NUM: number = 10;
    static MAX_LIMITED_QUESTION_NUM: number = 10;
    static MAX_AREA_NUM: number = 15;


    public convertToEditorAnswer(exercise: any): AssignPointToQuestionAnswerElement | undefined {
        if (exercise && exercise.solution && exercise.answers) {
            var answers = [];
            for (let i = 0; i < exercise.solution.length; i++) {
                let areas: any[] = [];
                for (let j = 0; j < exercise.solution[i].points.length; j++) {
                    let item = exercise.solution[i].points[j];

                    let newArea: any;

                    if (item.points) {
                        newArea = {
                            points: item.points,
                            shape: item.shape == Shapes.Rectangle ? Shapes.Rectangle : Shapes.Polygon
                        }
                    }
                    else {
                        newArea = {
                            x: item.x,
                            y: item.y,
                            radius: item.radius,
                            shape: Shapes.Circle
                        }
                    }
                    areas.push(newArea);
                }

                let answerElement: any;
                if (exercise.answers[i].answerElement.type == "image") {
                    answerElement =
                        {
                            url: exercise.answers[i].answerElement.image,
                            text: exercise.answers[i].answerElement.text,
                            type: AnswerTypes.image
                        };
                }

                else if (exercise.answers[i].answerElement.type == "sound") {
                    answerElement =
                        {
                            url: exercise.answers[i].answerElement.url,
                            text: exercise.answers[i].answerElement.text,
                            type: AnswerTypes.sound
                        };
                }

                else {
                    answerElement =
                        {
                            text: exercise.answers[i].answerElement.text,
                            type: AnswerTypes.text
                        };
                }

                let element: AssignPointToQuestionAnswerType = {
                    answerElement: answerElement,
                    areas: areas
                }

                answers.push(element);
            }


            var answer: AssignPointToQuestionAnswerElement = {
                answers: answers,
                num_of_questions: exercise.num_of_questions
            };
            return answer;
        }
        return undefined;
    }

    public convertToJson(exerciseDetails: any, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: any): any | undefined {
        var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);

        if (exerciseDetails && exerciseDetails.answers) {
            var ansList = [];
            var solution = [];

            for (let index = 0; index < exerciseDetails.answers.length; index++) {
                let actualAnswer = exerciseDetails.answers[index].answerElement;
                let element: any;

                if (actualAnswer.type == AnswerTypes.image) {
                    element = {
                        type: "image",
                        text: actualAnswer.text.trim(),
                        image: actualAnswer.url
                    }
                }

                else if (actualAnswer.type == AnswerTypes.sound) {
                    element = {
                        type: "sound",
                        text: actualAnswer.text.trim(),
                        url: actualAnswer.url
                    }
                }

                else {
                    element = {
                        type: "text",
                        text: actualAnswer.text.trim(),
                        image: ""
                    }
                }

                let newElement = {
                    answerElement: element,
                    pins: exerciseDetails.answers[index].areas.length
                }

                ansList.push(newElement);

                let solElement = {
                    answerindex: index,
                    points: exerciseDetails.answers[index].areas,
                }

                solution.push(solElement);
            }

            var answer = {
                ...cur_exercise,
                answers: ansList,
                solution: solution,
                num_of_questions: exerciseDetails.num_of_questions
            }
            return answer;

        }
        return cur_exercise;
    }

    render() {
        let curr_ex: AssignPointToQuestionAnswerElement = this.state.exercise as AssignPointToQuestionAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4} >
                <div className="row">
                    <div className="large-12 columns">
                        <label>{__("Megjelenített kérdések száma (0 = összes)")}
                            <input type="number" min="0" max={curr_ex.answers ? curr_ex.answers.length : 0} name="num_of_questions"
                                value={curr_ex.num_of_questions || 0}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                        </label>
                    </div>
                </div>
                <Panel header={__("Feladatok")} headingLevel={5}>
                    {
                        curr_ex.answers ?
                            curr_ex.answers.map((curr_task: any, index) => {

                                let actions: IAccordionAction[];
                                actions = [
                                    {
                                        title: __("Törlés"),
                                        type: AccordionActionType.Trash,
                                        action: this.removeElement.bind(this, "answers", index)
                                    },
                                    {
                                        title: __("Fel"),
                                        type: AccordionActionType.Up,
                                        action: this.moveUp.bind(this, "answers", index)
                                    },
                                    {
                                        title: __("Le"),
                                        type: AccordionActionType.Down,
                                        action: this.moveDown.bind(this, "answers", index)
                                    }
                                ];
                                return (
                                    <Accordion key={"answer_" + index}>
                                        <ActionAccordionItem defaultClosed key={"answers_" + index} actions={actions} title={__("{n}. feladat", { n: index + 1 })}>
                                            <Panel>
                                                <legend>
                                                    <label className="exe-editor-fieldlabel-1">{__("Feladat")} {index + 1}</label>
                                                </legend>
                                                <div className="row">
                                                    <div className="large-4 small-12 columns">
                                                        <label> {__("Feladattípus")}
                                                            <select value={curr_task.answerElement.type} name={"answers#" + index + ".answerElement.type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                                <option value={AnswerTypes.text}>{__("Szöveg")}</option>
                                                                <option value={AnswerTypes.image}>{__("Kép")}</option>
                                                                <option value={AnswerTypes.sound}>{__("Hang")}</option>
                                                            </select></label>
                                                    </div>
                                                    <div className="large-8 small-12 columns">
                                                        <label>{curr_task.answerElement.type == AnswerTypes.text ? __("Válasz") : __("Leírás")}
                                                            <input type="text" name={"answers#" + index + ".answerElement.text"}
                                                                value={curr_task.answerElement.text || ""}
                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                        </label>
                                                    </div>
                                                    <div className="large-12 columns">
                                                        {curr_task.answerElement.type != AnswerTypes.text ?
                                                            <label className="exe-image-select-label">{curr_task.answerElement.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                                                {<ExerciseFileSelect
                                                                    imagebasepath={this.props.imagebasepath}
                                                                    value={curr_task.answerElement.url || ""}
                                                                    onChange={this.handleImageChange.bind(this, "answers#" + index + ".answerElement.url")}
                                                                    getFolderId={this.getFolderId.bind(this)}
                                                                    fileType={curr_task.answerElement.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                                />}</label> : ""}
                                                    </div>
                                                </div>
                                                <Panel header={__("Választerületek")} headingLevel={6}>
                                                    {
                                                        curr_task.areas.map((curr_answer: any, _j: any) => {
                                                            return (<Panel>
                                                                <legend>
                                                                    <label className="exe-editor-fieldlabel-1">{__("Terület")} {_j + 1}</label>
                                                                    <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "answers#" + index + ".areas", _j)}><i className="fa fa-trash"></i></button>
                                                                </legend>
                                                                <div className="row">
                                                                <label>{__("Típus")}
                                                                    <select value={curr_answer.shape} name={"answers#" + index + ".areas#" + _j + ".shape"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)} onSelect={this.handleInputChange.bind(this)}>
                                                                        <option value={Shapes.Circle}>{__("Kör")}</option>
                                                                        <option value={Shapes.Polygon}>{__("Alakzat")}</option>
                                                                        <option value={Shapes.Rectangle}>{__("Téglalap")}</option>
                                                                    </select>
                                                                </label>
                                                                </div>
                                                                {
                                                                    curr_answer.shape == Shapes.Circle ?
                                                                        <div className="row">
                                                                            <div className="large-4 small-12 columns">
                                                                                <label>{__("X koordináta")}
                                                                                    <input type="text" name={"answers#" + index + ".areas#" + _j + ".x"} value={curr_answer.x ? curr_answer.x : 0} disabled
                                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                                </label>
                                                                            </div>
                                                                            <div className="large-4 small-12 columns">
                                                                                <label>{__("Y koordináta")}
                                                                                    <input type="text" name={"answers#" + index + ".areas#" + _j + ".y"} value={curr_answer.y ? curr_answer.y : 0} disabled
                                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                                </label>
                                                                            </div>
                                                                            <div className="large-4 small-12 columns">
                                                                                <label>{__("Rádiusz")}
                                                                                    <input type="text" name={"answers#" + index + ".areas#" + _j + ".radius"} value={curr_answer.radius ? curr_answer.radius : 0} disabled
                                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        : ""}
                                                                <ExerciseImageCoordinatePick
                                                                    imagesrc={this.props.exerciseDetails.imageBasePath}
                                                                    x={curr_answer.x}
                                                                    y={curr_answer.y}
                                                                    radius={curr_answer.radius}
                                                                    shape={curr_answer.shape}
                                                                    points={curr_answer.points}
                                                                    otherCircles={curr_task.areas.filter((item: any) => item.x != curr_answer.x || item.y != curr_answer.y)} //iterate through answer by the map function, then collect the coordinates that are not the actual ones. filter returns array, so map returns array of an array. that's why we need the concat at the beginning
                                                                    otherPolygons={curr_task.areas.filter((item: any) => (item.points != undefined && item.points != curr_answer.points))}
                                                                    taskValues={
                                                                        curr_answer.shape == Shapes.Circle ?
                                                                            [{ key: "answers#" + index + ".areas#" + _j + ".x", value: curr_answer.x ? curr_answer.x : 0 },
                                                                            { key: "answers#" + index + ".areas#" + _j + ".y", value: curr_answer.y ? curr_answer.y : 0 },
                                                                            { key: "answers#" + index + ".areas#" + _j + ".radius", value: curr_answer.radius ? curr_answer.radius : 0 }]

                                                                            : [{ key: "answers#" + index + ".areas#" + _j + ".points", value: curr_answer.points ? curr_answer.points : null }]
                                                                    }
                                                                    onChange={this.handleInputChangeAdditionalArray.bind(this)}
                                                                />
                                                            </Panel>
                                                            )
                                                        })}
                                                    <div className="row">
                                                        <button className="button small" onClick={this.onAddNewAnswer.bind(this, index)}><i className="fa fa-plus"></i> {__("Új válasz hozzáadása")}</button>
                                                        <label className="exe-editor-label-description columns">{__("Egy kérdéshez minimum {min}, maximum {max} válaszelem tartozhat!", {min:1, max: WMAssignPointToQuestionConverter.MAX_AREA_NUM })}</label>
                                                    </div>
                                                </Panel>
                                            </Panel>
                                        </ActionAccordionItem>
                                    </Accordion>)
                            })
                            : ""
                    }
                    <div className="row">
                        <button className="button small" onClick={this.onAddNewTask.bind(this)}><i className="fa fa-plus"></i> {__("Feladat hozzáadása")}</button>
                        <label className="exe-editor-label-description columns">Maximum {WMAssignPointToQuestionConverter.MAX_ANSWER_NUM} {__("kérdés adható meg.")}</label>
                    </div>
                </Panel>
            </Panel>
        );
    }

    public validate(editorAnswer: AssignPointToQuestionAnswerElement, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData, validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        let errorMsg = "";
        let OFIErrors: string[] = [];

        if (!baseData.illustration) {
            let errorMsg = __("Az illusztráció megadása kötelező!");
            return { valid: false, message: errorMsg };
        }

        if (editorAnswer.num_of_questions && editorAnswer.num_of_questions > WMAssignPointToQuestionConverter.MAX_LIMITED_QUESTION_NUM) {
            errorMsg = __("Maximum {max} kérdés jeleníthető meg.", { max: WMAssignPointToQuestionConverter.MAX_LIMITED_QUESTION_NUM });
            if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            return { valid: false, message: errorMsg };
        }

        let rowsValid = this.makeValidBeforeSetState(null, editorAnswer);
        if (!rowsValid.valid) return rowsValid;


        for (let i = 0; i < editorAnswer.answers.length; i++) {
            if (editorAnswer.answers[i].areas.length < 1 || (editorAnswer.answers[i].areas.length > WMAssignPointToQuestionConverter.MAX_AREA_NUM)) {
                errorMsg = __("Egy kérdéshez minimum {min}, maximum {max} válaszelem tartozhat!", {min:1, max: WMAssignPointToQuestionConverter.MAX_AREA_NUM });
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                validationMap!.set("answers[" + i + "].areas", errorMsg); // todo: make the editor show the error!
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
            }
        }
        if (AExerciseTypeConverter.isOfiEditor) {
            return { valid: true, message: OFIErrors.join(' , ') }
        }
        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: AssignPointToQuestionAnswerElement | null, newState: AssignPointToQuestionAnswerElement): ValidationResponse {
        if (AExerciseTypeConverter.isOfiEditor) return { valid: true };
        if (newState.answers.length > WMAssignPointToQuestionConverter.MAX_ANSWER_NUM) {
            newState.answers.splice(WMAssignPointToQuestionConverter.MAX_ANSWER_NUM, newState.answers.length - WMAssignPointToQuestionConverter.MAX_ANSWER_NUM);
            return { valid: false, message: __("Maximum {max} kérdés adható meg.", { max: WMAssignPointToQuestionConverter.MAX_ANSWER_NUM }) };
        }

        for (let i = 0; i < newState.answers.length; i++) {
            if (newState.answers[i].areas.length > WMAssignPointToQuestionConverter.MAX_AREA_NUM) {
                newState.answers[i].areas.splice(WMAssignPointToQuestionConverter.MAX_AREA_NUM, newState.answers[i].areas.length - WMAssignPointToQuestionConverter.MAX_AREA_NUM);
                return { valid: false, message: __("Egy kérdéshez minimum {min}, maximum {max} válaszelem tartozhat!", { min:1, max: WMAssignPointToQuestionConverter.MAX_AREA_NUM }) };
            }
        }
        return { valid: true }
    }

    onAddNewTask() {
        let temp_exercise = this.state.exercise as AssignPointToQuestionAnswerElement;
        if (!temp_exercise.answers) temp_exercise.answers = [];
        temp_exercise.answers.push(this.getNewTask());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    onAddNewAnswer(index: number) {
        let temp_exercise = this.state.exercise as AssignPointToQuestionAnswerElement;
        if (!temp_exercise.answers) temp_exercise.answers = [];
        temp_exercise.answers[index].areas.push(this.getNewArea());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    getNewTask(): AssignPointToQuestionAnswerType {
        let answerElem: AnswerElement = this.getNewAnswerElement();
        let newElement: AssignPointToQuestionAnswerType = {
            answerElement: answerElem,
            areas: []
        };
        return newElement;
    }

    getNewAnswerElement(): AnswerElement {
        let element: AnswerElement = {
            type: "",
            image: "",
            text: ""
        }

        return element;
    }

    getNewArea(): Area {
        let newanswer: Area = {
            x: 0,
            y: 0,
            radius: 5,
            shape: Shapes.Circle
        };
        return newanswer;
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        let questionStat, areaStat, areaStatTotal: StatisticsResponse;
        statisticsResponses.push({ name: __("limitált elemszám: ") + WMAssignPointToQuestionConverter.MAX_LIMITED_QUESTION_NUM, count: undefined });
        statisticsResponses.push({ name: __("max. kérdés: ") + WMAssignPointToQuestionConverter.MAX_ANSWER_NUM, count: undefined });
        statisticsResponses.push({ name: __("max. terület kérdésenként ") + WMAssignPointToQuestionConverter.MAX_AREA_NUM, count: undefined });
        questionStat = { name: __("Kérdések száma"), count: new Map() }
        areaStat = { name: __("Pinek száma területenként"), count: new Map() }
        areaStatTotal = { name: __("Pinek száma összesen"), count: new Map() }
        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const currentExc = exerciseList[i];
            let question_count = questionStat.count.has(currentExc.answers.length) ? questionStat.count.get(currentExc.answers.length) : 0;
            questionStat.count.set(currentExc.answers.length, question_count! + 1);
            let counter = 0;
            for (let j = 0; j < currentExc.answers.length; j++) {
                const curr_answer = currentExc.answers[j];
                if (curr_answer.areas) {
                    counter += curr_answer.areas.length;
                    let answer_count = areaStat.count.has(curr_answer.areas.length) ? areaStat.count.get(curr_answer.areas.length) : 0;
                    areaStat.count.set(curr_answer.areas.length, answer_count! + 1);
                }
            }
            let answer_count = areaStatTotal.count!.has(counter) ? areaStatTotal.count!.get(counter) : 0;
            areaStatTotal.count!.set(counter, answer_count! + 1);
        }
        statisticsResponses.push(questionStat, areaStatTotal, areaStat);
        return statisticsResponses;
    }
}