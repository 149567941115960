import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapBeadandoStatsRecord {
    /**
     * Azonosító
     *
     * Azonosító
     */
    id?: number;
    /**
     * Feltöltve
     *
     * Feltöltve
     */
    feltoltve?: string /*timestamp?*/|null;
    /**
     * Feltöltve (év)
     *
     * Feltöltve (év)
     */
    feltoltve_ev?: number /* float8 */|null;
    /**
     * Feltöltve (hó)
     *
     * Feltöltve (hó)
     */
    feltoltve_ho?: number /* float8 */|null;
    /**
     * Tantárgy
     *
     * Tantárgy
     */
    tantargy?: string|null;
    /**
     * Osztály
     *
     * Osztály
     */
    osztaly?: string|null;
    /**
     * Tematikus egység
     *
     * Tematikus egység
     */
    thematic_unit?: string|null;
    /**
     * Téma
     *
     * Téma
     */
    topic?: string|null;
    /**
     * Cél
     *
     * Cél
     */
    goal?: string|null;
    /**
     * Feltöltő e-mail
     *
     * Feltöltő e-mail
     */
    feltolto_email?: string|null;
    /**
     * Feltöltő teljes név
     *
     * Feltöltő teljes név
     */
    feltolto_teljes_nev?: string|null;
    /**
     * Szerzők
     *
     * Szerzők
     */
    szerzok?: string|null;
    /**
     * OM azonosító
     *
     * OM azonosító
     */
    om_azonosit?: string|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    intezmeny_nev?: string|null;
    /**
     * Ellátási hely azonosító
     *
     * Ellátási hely azonosító
     */
    ellatasi_hely_azonosito?: string|null;
    /**
     * Ellátási hely név
     *
     * Ellátási hely név
     */
    ellatasi_hely_nev?: string|null;
    /**
     * Állapot
     *
     * Állapot
     */
    allapot?: string|null;
    /**
     * Lezárva
     *
     * Lezárva
     */
    lezarva?: boolean|null;
    /**
     * Szakmai támogató
     *
     * Szakmai támogató
     */
    szakmai_tamogato?: string|null;
    /**
     * Publikáláshoz hozzájárult
     *
     * Publikáláshoz hozzájárult
     */
    hozzajarult?: boolean|null;
    /**
     * Kiválónak jelölve
     *
     * Kiválónak jelölve
     */
    kivalonak_jelolt?: boolean|null;
}

/**
 *  kap.view_kap_beadando_stats - DFHT-KIP beadandó
 *
 *  DFHT-KIP beadandó
 */

export default class ViewKapBeadandoStats extends View<IViewKapBeadandoStatsRecord> {
    public static VIEW_INFO_ID = 2020013101;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_beadando_stats'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapBeadandoStats>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapBeadandoStatsRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapBeadandoStatsClassProxy extends ViewClassProxy<IViewKapBeadandoStatsRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapBeadandoStatsClassProxy = new ViewKapBeadandoStatsClassProxy(ViewKapBeadandoStats);
registerViewClassProxy(viewKapBeadandoStatsClassProxy);

