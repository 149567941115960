import * as React from 'react';
import ChapterCrud from '@crud/doc/ChapterCrud';
import { Link, match } from 'react-router-dom';
import { BookBreadcrumbs } from '@component/Breadcrumbs';
import * as PublicServer from '@src/server/PublicServer';
import { app, history } from '@src/index';
import LessonCrud from '@src/framework/crud/doc/LessonCrud';
import { Dialog, DialogContent, DialogActions, confirmDialog } from '@src/component/Dialog';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import PermissionPage from '@src/framework/PermissionPage';
import { CrudPermissionType, PermissionCache, WfDeleteTransitionInfo } from '@src/framework/security/PermissionCache';
import { MetaCategorization, setCategoryIds } from '@src/component/meta/meta_category_assigner_api';
import MetaCategoryAssignerPlugin from '@src/component/meta/meta_category_assigner_plugin';
import { ChapterLessons } from '@src/server/PublicServer';
import { IViewChapterRecord } from '@src/framework/view/doc/ViewChapter';
import { IViewLessonRecord } from '@src/framework/view/doc/ViewLesson';
import { IViewWfWorkflowRecord } from '@src/framework/view/wf/ViewWfWorkflow';
import { IViewTransitionTypeRecord } from '@src/framework/view/wf/ViewTransitionType';
import { WF_TYPE_OKOSTANKONYV_FEJEZET_ID, WF_TYPE_OKOSTANKONYV_LECKE_ID, LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import WfAPI from '@src/framework/wf/WfAPI';
import obtainServer from '@src/framework/server/Server';
import { PATH_EDIT_BOOK_SECTIONS, PATH_EDIT_BOOK_TABLE_OF_CONTENTS } from '@src/Routes';
import { getWfStateTitle, getWfStateText } from '../wf/wf_util';
import { WorkflowDialog } from '@src/component/wf/WorkflowDialog';
import { __ } from '@src/translation';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import { displayModeCrudClassProxy } from '@src/framework/crud/doc/DisplayModeCrud';
import { me, hasAnyGroup, Groups } from '@src/framework/server/Auth';
import { instituteTypeCrudClassProxy } from '@src/framework/crud/sys/InstituteTypeCrud';

export default class BookStructureEditorProtected extends React.Component<IBookStructureEditorProps, any> {
    /**
     * 
     * Ha ezt a Book-ba teszed, akkor meghívja az asnycReload-ot és mindenféle
     * olyan dolgot lekérdez a szerverről, amihez hozzá se kellene kezdenie...
     * 
     */
    render() {
        return (<PermissionPage requirements={{
            crud: { tableInfoId: ChapterCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U }
        }}
        ><BookStructureEditor {...this.props} /></PermissionPage>
        );

    }
}


interface IBookStructureEditorProps {
    match: match<{ bookId: string }>;
}

interface IBookStructureEditorState {
    book?: IBookRecord;
    chaptersLessons: ChapterLessons[] | null;
    newChapterName: string;
    newChapterHeadno: string;
    saving: boolean;
    showInactive: boolean;
    showBookDataModal: boolean;

    chapterDc?: WfDeleteTransitionInfo;
    lessonDc?: WfDeleteTransitionInfo;
}

class BookStructureEditor extends React.Component<IBookStructureEditorProps, IBookStructureEditorState> {

    constructor(prop: any) {
        super(prop);
        this.state = {
            chaptersLessons: null,
            newChapterName: "",
            newChapterHeadno: "",
            saving: false,
            showInactive: false,
            showBookDataModal: false
        }

        this.reloadAsync();
    }



    async reloadAsync() {
        const bookId = Number(this.props.match.params.bookId);
        try {
            const chaptersLessons = await PublicServer.getChapterLessonsWithInactive(bookId);
            const book = (await BookCrud.load(bookId)).record;

            const chapterDc = await PermissionCache.loadWfDelTrans(WF_TYPE_OKOSTANKONYV_FEJEZET_ID);
            const lessonDc = await PermissionCache.loadWfDelTrans(WF_TYPE_OKOSTANKONYV_LECKE_ID);

            this.setState({ book, chaptersLessons, chapterDc, lessonDc });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    onChapterNameChange = (e: any) => {
        this.setState({ newChapterName: e.target.value });
    }

    private onChapterNameKeyPress(e: any) {
        if (e.key === 'Enter' && !this.state.saving && !this.state.saving) {
            this.onAddChapter();
        }
    }

    onAddChapter = async () => {

        var regexp = new RegExp('^[0-9]+$');
        if (this.state.newChapterHeadno && !regexp.test(this.state.newChapterHeadno)) {
            alert(__("A fejezet csak számot tartalmazhat!"))
            return;
        }
        const bookId = this.props.match.params.bookId;
        const chapterName = this.state.newChapterName;
        const newChapterHeadno = this.state.newChapterHeadno;

        var no = 1;

        var existsId = undefined;
        for (var chapter of this.state.chaptersLessons!) {
            if (chapter.no! >= no) no = chapter.no! + 1;

            if (!chapter.is_active && (("" + chapter.headno) == newChapterHeadno || chapter.name == chapterName)) {
                existsId = chapter.id
            }
        }

        this.setState({ saving: true });

        try {
            await new ChapterCrud({
                id: existsId,
                book_id: Number(bookId),
                name: chapterName,
                headno: (newChapterHeadno != "") ? Number(newChapterHeadno) : null,
                no: no,
                is_active: true
            }).put();

            app.showSuccess(__("Sikeres hozzáadás"), existsId ? __("Létező fejezet visszaállítva") : __("Új fejezet hozzáadva"));
            this.reloadAsync();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }

        this.setState({
            newChapterName: "",
            newChapterHeadno: "",
            saving: false
        });
    }

    async onChapterUp(chapterIndex: number) {
        this.setState({ saving: true });
        try {
            const chapters = this.state.chaptersLessons!;
            if (chapterIndex > 0) {
                const prevChapter = chapters[chapterIndex - 1];
                const currChapter = chapters[chapterIndex];

                await new ChapterCrud({ id: prevChapter.id, no: null }).put();
                await new ChapterCrud({ id: currChapter.id, no: prevChapter.no }).put();
                await new ChapterCrud({ id: prevChapter.id, no: currChapter.no }).put();

                await this.reloadAsync();
                app.showSuccess(__("Sikeres mentés"), __("Fejezetek felcserélve"));
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
        this.setState({ saving: false });
    }

    async onChapterDown(chapterIndex: number) {
        this.setState({ saving: true });
        try {
            const chapterLessons = this.state.chaptersLessons!;

            if (chapterIndex < chapterLessons.length - 1) {
                const nextChapter = chapterLessons[chapterIndex + 1];
                const currChapter = chapterLessons[chapterIndex];

                await new ChapterCrud({ id: nextChapter.id, no: null }).put();
                await new ChapterCrud({ id: currChapter.id, no: nextChapter.no }).put();
                await new ChapterCrud({ id: nextChapter.id, no: currChapter.no }).put();

                await this.reloadAsync();
                app.showSuccess(__("Sikeres mentés"), __("Fejezetek felcserélve"));
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
        this.setState({ saving: false });
    }

    onCheckShowInactive = () => {
        this.setState({ showInactive: !this.state.showInactive });
    }

    private afterChapterTransition(
        chapterIndex: number,
        transitionType: IViewTransitionTypeRecord
    ) {
        const oldChapterLessons = this.state.chaptersLessons![chapterIndex];
        let newChapterLessons: ChapterLessons = Object.assign(
            {},
            oldChapterLessons,
            {
                wf_station_id: transitionType.dst_station_id,
                wf_station_name: transitionType.dst_station_name,
                wf_station_description: transitionType.dst_station_description,
                wf_station_style: transitionType.dst_station_style
            }
        );
        let newChaptersLessons = this.state.chaptersLessons!.concat();
        newChaptersLessons[chapterIndex] = newChapterLessons;
        this.setState({ chaptersLessons: newChaptersLessons }, this.forceUpdate);
    }
    private async onSaveBookBaseData() {
        try {
            if (this.state.book) {
                this.setState({ saving: true });
                if (!me) throw __("Nincs hozzáférése");
                if (!this.state.book.name) {
                    app.showError(__("Hiba!"), __("A könyv nevét kötelező kitölteni"));
                    return;
                }
                if (!this.state.book.uri_segment) {
                    app.showError(__("Hiba!"), __("A könyv URL-t kötelező kitölteni"));
                    return;
                }
                let newbookRecord = this.state.book;
                newbookRecord.is_active = undefined;

                const newBook = (await new BookCrud(newbookRecord).put()).record;

                history.push(PATH_EDIT_BOOK_TABLE_OF_CONTENTS + "/" + newBook.id);
                app.showSuccess(__("Siker"), __("A könyv törzsadatai sikeresen módosultak!"));
                this.setState({ showBookDataModal: false, saving: false });
                this.reloadAsync();
            }
            else {
                app.showError(__("Hiba!"), __("Könyv adatok nem menthetőek"));
                return;
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }

    }
    private afterLessonTransition(
        chapterIndex: number,
        lessonIndex: number,
        transitionType: IViewTransitionTypeRecord
    ) {
        /* Na ezért kellene reduxot haszálni. Hogy néz ez ki??? */
        const oldChapterLessons = this.state.chaptersLessons![chapterIndex];
        const oldLesson: IViewLessonRecord = oldChapterLessons.lessons[lessonIndex];
        let newLesson: IViewLessonRecord = Object.assign(
            {},
            oldLesson,
            {
                wf_station_id: transitionType.dst_station_id,
                wf_station_name: transitionType.dst_station_name,
                wf_station_description: transitionType.dst_station_description,
                wf_station_style: transitionType.dst_station_style
            }
        );
        let newLessons = oldChapterLessons.lessons.concat();
        newLessons[lessonIndex] = newLesson;
        let newChapterLessons = Object.assign(
            {},
            oldChapterLessons,
            { lessons: newLessons }
        );
        let newChaptersLessons = this.state.chaptersLessons!.concat();
        newChaptersLessons[chapterIndex] = newChapterLessons;
        this.setState({ chaptersLessons: newChaptersLessons }, this.forceUpdate);
    }
    private onChangeBaseBookData(name: keyof IBookRecord, value: any) {
        this.state.book![name] = value;

        this.setState({ book: this.state.book });
    }

    render() {

        if (!this.state.book || !this.state.chaptersLessons) {
            return <div className="row">
                <BookBreadcrumbs links={[{ name: __("Betöltés...") }]} />
            </div>
        }

        const bookId = this.props.match.params.bookId;
        const showInactive = this.state.showInactive;
        const lessonUriArray: lessonIdAndUri[] = [];
        {
            this.state.chaptersLessons.map((chapter: ChapterLessons, index: number) => {
                chapter.lessons.map((lesson, index: number) => {
                    if (lesson.id && lesson.uri_segment && lesson.is_active) {
                        lessonUriArray.push({ id: lesson.id, uri_segment: lesson.uri_segment });
                    }
                }
                )

            })
        }

        return (
            <div className="row" style={{ minWidth: "1200px" }}>
                {
                    this.state.book && this.state.showBookDataModal
                        ?
                        <Dialog title="Könyv törzsadatainak szerkesztése" width={1024} onClose={() => this.setState({ showBookDataModal: false })}>
                            <DialogContent>
                                <div className="row">

                                    <div className="column small-6">
                                        <label>
                                            {__("Osztály")}
                                            <CrudSelectComponent
                                                crudClassProxy={gradeCrudClassProxy}
                                                displayFieldName="name"
                                                orderByFieldName="name"
                                                value={this.state.book.grade_id!}
                                                onSelect={(sender, newValue) => this.onChangeBaseBookData("grade_id", Number(newValue))}
                                                sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                                                filter={{ is_active: true }}
                                            />
                                        </label>
                                    </div>
                                    <div className="column small-6">
                                        <label>
                                            {__("Tantárgy")}
                                            <CrudSelectComponent
                                                crudClassProxy={subjectCrudClassProxy}
                                                displayFieldName="name"
                                                orderByFieldName="name"
                                                value={this.state.book.subject_id!}
                                                onSelect={(sender, newValue) => this.onChangeBaseBookData("subject_id", Number(newValue))}
                                                filter={{ is_active: true }}

                                            />
                                        </label>
                                    </div>
                                    <div className="column small-6">
                                        <label>
                                            {__("Iskola típus")}
                                            <CrudSelectComponent
                                                crudClassProxy={instituteTypeCrudClassProxy}
                                                limit={200}
                                                displayFieldName="title"
                                                orderByFieldName="title"
                                                value={this.state.book.institute_type_id!}
                                                onSelect={(sender, newValue) => this.onChangeBaseBookData("institute_type_id", Number(newValue))}                                         
                                            />
                                        </label>
                                    </div>

                                    <div className="column small-6">
                                        <label>
                                            {__("Új könyv neve")}
                                            <input type="text" value={this.state.book.name || ""} onChange={(e) => this.onChangeBaseBookData("name", e.currentTarget.value)} />
                                        </label>
                                    </div>
                                    <div className="column small-6">
                                        <label>
                                            {__("Nézet")}
                                            <CrudSelectComponent
                                                crudClassProxy={displayModeCrudClassProxy}
                                                displayFieldName="title"
                                                orderByFieldName="id"
                                                value={this.state.book.display_mode_id!}
                                                onSelect={(sender, newValue) => this.onChangeBaseBookData("display_mode_id", Number(newValue))}
                                                filter={{ is_active: true }}
                                            />
                                        </label>
                                    </div>
                                    <div className="column small-12">
                                        <label>
                                            {__("Új könyv URL-je")}
                                            <input type="text" value={this.state.book.uri_segment || ""} onChange={(e) => this.onChangeBaseBookData("uri_segment", e.currentTarget.value)} />
                                        </label>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <button className="button small" onClick={this.onSaveBookBaseData.bind(this)}>
                                    <i className="fa fa-save" /> {__("Mentés")}
                </button>
                            </DialogActions>
                        </Dialog>
                        : ""
                }
                <div className="small-12 column">
                    <BookBreadcrumbs links={[{ name: this.state.book.name }]} />
                </div>

                <div className="chapterLesson-list">

                    <div className="chapterLesson-chapter-inner align-right">
                        {me && hasAnyGroup(me, [Groups.Admin, Groups.Developer])
                            ?
                            <button className="button success small chapterLesson-lesson-button" style={{ marginLeft: "1rem" }} onClick={() => { this.setState({ showBookDataModal: true }) }}>
                                <i className="fa fa-book" /> {__("Könyv törzsadatainak szerkesztése")}

                            </button>
                            : ""
                        }

                        <div className="chapterLesson-chapter-name"></div>
                        <div>
                            <label>
                                <input type="checkbox" checked={showInactive}
                                    onClick={this.onCheckShowInactive}
                                /> {__("Mutassa a törölteket is")}
                            </label>
                        </div>
                    </div>
                    {
                        this.state.chaptersLessons.map((chapter, chapterIndex: number) => {
                            return <ChapterStructureEditor
                                key={chapterIndex}
                                index={chapterIndex}
                                bookListComponent={this}
                                chapter={chapter}
                                bookId={Number(bookId)}
                                showInactive={showInactive}
                                lessonUriArray={lessonUriArray}

                                isOfiOfficial={this.state.book!.library_id == LIBRARY_OFI_OFFICIAL_ID}

                                chapterDc={this.state.chapterDc!}
                                lessonDc={this.state.lessonDc!}

                                afterChapterTransition={(transitionType: IViewTransitionTypeRecord) => this.afterChapterTransition(chapterIndex, transitionType)}
                                afterLessonTransition={(
                                    lessonIndex: number,
                                    transitionType: IViewTransitionTypeRecord) => this.afterLessonTransition(chapterIndex, lessonIndex, transitionType)}
                            />
                        })
                    }

                    {
                        this.state.chaptersLessons.length == 0
                            ?
                            <div className="callout small alert">
                                <i className="fa fa-arrow-down" /> {__("Ez a tankönyv üres. Kérem adjon hozzá új fejezetet az alábbi mező kitöltésével.")}
                            </div>
                            :
                            ""
                    }

                </div>

                <div className="column" style={{ marginLeft: "1.5em" }}>
                    <label>
                        {__("Új fejezet neve")}:
                        <input type="text"
                            placeholder={__("Írja be az új fejezet nevét")}
                            value={this.state.newChapterName}
                            onChange={this.onChapterNameChange}
                            onKeyPress={this.onChapterNameKeyPress.bind(this)}
                            disabled={this.state.saving}
                        />
                    </label>
                </div>
                <div className="shrink column" style={{ marginLeft: "1.5em" }}>
                    <label>
                        {__("Új fejezet száma")}:
                        <input type="number"
                            placeholder={__("x. fejezet")}
                            value={this.state.newChapterHeadno}
                            onChange={(e) => this.setState({ newChapterHeadno: e.target.value })}
                            disabled={this.state.saving}
                        />
                    </label>
                </div>
                <div className="shrink column">
                    <br />
                    <button className="button small" onClick={this.onAddChapter}
                        disabled={!this.state.newChapterName || this.state.saving}
                    >
                        <i className="fa fa-plus" /> {__("Új fejezet hozzáadása")}
                    </button>
                </div>

            </div>
        );
    }
}


interface IChapterStructureEditorProps {
    index: number;
    bookListComponent: BookStructureEditor;
    chapter: ChapterLessons;
    bookId: number;
    showInactive: boolean;
    lessonUriArray: lessonIdAndUri[];
    afterChapterTransition: (transitionType: IViewTransitionTypeRecord) => void;
    afterLessonTransition: (lessonIndex: number, transitionType: IViewTransitionTypeRecord) => void;

    isOfiOfficial: boolean;

    chapterDc: WfDeleteTransitionInfo;
    lessonDc: WfDeleteTransitionInfo;

}

interface IChapterStructureEditorState {
    saving: boolean;
    newLessonName?: string;
    newLessonURI: string;
    newLessonHeadno: string;
    showProperitesDialog: boolean;
    dialogTabIndex: number;
    workflowDialogOpen: boolean;
}

class ChapterStructureEditor extends React.Component<IChapterStructureEditorProps, IChapterStructureEditorState> {
    constructor(prop: IChapterStructureEditorProps) {
        super(prop);
        this.state = {
            saving: false,
            newLessonURI: "",
            newLessonHeadno: "",
            showProperitesDialog: false,
            dialogTabIndex: 0,
            workflowDialogOpen: false
        };
    }

    onChapterDeleteOrRestore = async (canDelete: boolean, canRestore: boolean) => {
        const chapter = this.props.chapter;

        // Kikeressük a törlő transition type-okat.
        let possibleTransitions = [];
        const ctt = this.props.chapterDc!.activeTransitionTypes;
        for (let i = 0; i < ctt.length; i++) {
            let transitionType = ctt[i];
            if (transitionType.src_station_id == chapter.wf_station_id!) {
                if (canDelete) {
                    if (transitionType.dst_is_deleted!) {
                        possibleTransitions.push(transitionType);
                    }
                } else if (canRestore) {
                    if (!transitionType.dst_is_deleted!) {
                        possibleTransitions.push(transitionType);
                    }
                }
            }
        }

        const action = canDelete 
            ? __('Törlés végrehajtásához nyissa meg a folyamat dialógus ablakot.') 
            : __('Visszaállítás végrehajtásához nyissa meg a folyamat dialógus ablakot.');

        if (possibleTransitions.length > 1) {
            app.showError(
                __("Hiba"),
                __('Egynél több célállapot elérhető.') + " " + action
            );
            return;
        }
        const transitionType: IViewTransitionTypeRecord = possibleTransitions[0];

        if (await confirmDialog(action, __('Biztos benne, hogy {descriptionPart} ezt a fejezetet?\n{chapterName}', { descriptionPart: canDelete ? __('törli') : __('visszaállítja'), chapterName: chapter.name }), action)) {
            let justification = prompt(__('Kérem írja be az indoklást'), '');
            this.setState({ saving: true });
            let wfApi = new WfAPI(obtainServer());
            try {
                let workflowAfter: IViewWfWorkflowRecord = await wfApi.makeTransition(
                    chapter.wf_workflow_id!,
                    transitionType.id!,
                    justification
                );
                // Ez itt egy kis hack, nincs meg a sender, de az nincs is használva semmire...
                this.props.afterChapterTransition(transitionType);
            } catch (error) {
                app.showErrorFromJsonResult(error);
                this.setState({ saving: false });
            };
        }
    }

    onLessonNameChange(event: any) {
        this.setState({ newLessonName: event.target.value }); // , newLessonURI: event.target.value.toLowerCase().replace(/\W+/g, "_")
    }

    onLessonNameKeyPress(event: any) {
        if (this.state.newLessonName && this.state.newLessonURI && this.state.newLessonURI !== "lecke_0X_00X" && !this.state.saving && event.key === 'Enter') {
            this.onAddChapter();
        }
    }

    onLessonHeadnoChange(event: any) {
        const value = event.target.value;

        if (this.props.chapter.headno && value) {
            this.setState({
                newLessonHeadno: value,
                newLessonURI: "lecke_" + ("0" + this.props.chapter.headno).slice(-2) + "_" + ("00" + value).slice(-3)
            })
        } else {
            this.setState({
                newLessonHeadno: value
            });
        }
    }

    onLessonURIKeyPress(event: any) {
        if (this.state.newLessonName && this.state.newLessonURI && this.state.newLessonURI !== "lecke_0X_00X" && !this.state.saving && event.key === 'Enter') {
            this.onAddChapter();
        }
    }

    onLessonURIChange(event: any) {
        this.setState({ newLessonURI: event.target.value });
    }

    async onAddChapter() {

        var regexp = new RegExp(/^[a-zA-Z0-9_]+$/);
        if (!regexp.test(this.state.newLessonURI)) {
            alert(__("Az azonosító csak betűt, számot és aláhúzásjelet tartalmazhat!"))
            return;
        }
        var regexpnum = new RegExp('^[0-9]+$');
        if (this.state.newLessonHeadno && !regexpnum.test(this.state.newLessonHeadno)) {
            alert(__("Az lecke száma csak szám lehet!"))
            return;
        }
        const exists = this.props.lessonUriArray.some((x: lessonIdAndUri) => x.uri_segment == this.state.newLessonURI);
        if (exists) {
            app.showError(__("Sikertelen művelet"), __("Már létezik ilyen lecke azonosító! ({newLessonURI})", { newLessonURI: this.state.newLessonURI }));
            return;
        }
        const newLessoname = this.state.newLessonName;
        this.setState({ saving: true });
        try {
            var no = 1;

            for (var lesson of this.props.chapter.lessons) {
                if (lesson.no! >= no) no = lesson.no! + 1;
            }

            await new LessonCrud({
                chapter_id: this.props.chapter.id,
                name: this.state.newLessonName || "",
                uri_segment: this.state.newLessonURI,
                headno: (this.state.newLessonHeadno != "") ? Number(this.state.newLessonHeadno) : null,
                no: no
            }).put();
            this.props.bookListComponent.reloadAsync();
            this.setState({ newLessonName: undefined, newLessonHeadno: "" });
            app.showSuccess(__("Sikeres mentés"), __("Új lecke hozzáadva"));
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
        this.setState({ saving: false });
    }

    async onLessonUp(lessonIndex: number) {
        this.setState({ saving: true });
        try {
            const lessons = this.props.chapter.lessons;
            if (lessonIndex > 0) {
                const prevLesson = lessons[lessonIndex - 1];
                const currLesson = lessons[lessonIndex];

                await new LessonCrud({ id: prevLesson.id, no: null }).put();
                await new LessonCrud({ id: currLesson.id, no: prevLesson.no }).put();
                await new LessonCrud({ id: prevLesson.id, no: currLesson.no }).put();

                await this.props.bookListComponent.reloadAsync();
                app.showSuccess(__("Sikeres mentés"), __("Leckék felcserélve"));
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
        this.setState({ saving: false });
    }

    async onLessonDown(lessonIndex: number) {
        this.setState({ saving: true });
        try {
            const lessons = this.props.chapter.lessons;

            if (lessonIndex < this.props.chapter.lessons.length - 1) {
                const nextLesson = lessons[lessonIndex + 1];
                const currLesson = lessons[lessonIndex];

                await new LessonCrud({ id: nextLesson.id, no: null }).put();
                await new LessonCrud({ id: currLesson.id, no: nextLesson.no }).put();
                await new LessonCrud({ id: nextLesson.id, no: currLesson.no }).put();

                await this.props.bookListComponent.reloadAsync();
                app.showSuccess(__("Sikeres mentés"), __("Leckék felcserélve"));
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
        this.setState({ saving: false });
    }

    onUp() {
        this.props.bookListComponent.onChapterUp(this.props.index);
    }

    onDown() {
        this.props.bookListComponent.onChapterDown(this.props.index);
    }

    render() {
        const bookId = this.props.bookId;
        const chapter = this.props.chapter;
        const saving = this.state.saving;
        const showInactive = this.props.showInactive;
        const canDelete = this.props.chapterDc!.deleteFromStationIds!.has(chapter.wf_station_id!);
        const canRestore = this.props.chapterDc!.undeleteFromStationIds!.has(chapter.wf_station_id!);


        if (chapter.is_active || showInactive) {
            return (
                <div className="chapterLesson-chapter">
                    {
                        this.state.showProperitesDialog
                            ?
                            <ChapterDialog
                                onClose={() => this.setState({ showProperitesDialog: false })}
                                onRefresh={() => this.props.bookListComponent.reloadAsync()}
                                chapter={chapter}
                                defaultTabIndex={this.state.dialogTabIndex}
                            />
                            :
                            ""
                    }
                    <WorkflowDialog
                        open={this.state.workflowDialogOpen}
                        displayName={__("Fejezet")}
                        tableInfoId={ChapterCrud.TABLE_INFO_ID}
                        recId={this.props.chapter.id!}
                        onClose={() => this.setState({ workflowDialogOpen: false })}
                        onTransition={t => this.props.afterChapterTransition(t)}
                        autoPublishOnClosedStation={true}
                    />
                    <div className="chapterLesson-chapter-inner">
                        <button className="button tiny secondary" style={{ marginRight: "1em" }}
                                onClick={this.onUp.bind(this)}
                                disabled={saving || !chapter.is_active}
                        >
                            <i className="fa fa-arrow-up" />
                        </button>

                        <button className="button tiny secondary" style={{ marginRight: "1em" }}
                                onClick={this.onDown.bind(this)}
                                disabled={saving || !chapter.is_active}
                        >
                            <i className="fa fa-arrow-down" />
                        </button>

                        <div className="chapterLesson-chapter-name">
                            <strong>{chapter.name}</strong> {chapter.headno ? <i><small>({chapter.headno}. {__("fejezet")})</small></i> : ""}
                            <button className="clear button small" style={{ marginRight: "1em" }}
                                    onClick={() => this.setState({ dialogTabIndex: 0, showProperitesDialog: true })}
                                    disabled={saving || !chapter.is_active}
                            >
                                <i className="fa fa-pen"></i>
                            </button>
                        </div>

                        {chapter.wf_workflow_id ?
                            <button className="label small" style={Object.assign({ marginRight: "1em" }, chapter.wf_station_style!)}
                                title={getWfStateTitle(chapter)}
                                onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}
                            >
                                <i className="fa fa-arrow-circle-o-right" /> {getWfStateText(chapter)}
                            </button>
                            : ""
                        }

                        <button className="button small alert"
                            onClick={() => { this.onChapterDeleteOrRestore(canDelete, canRestore); }}
                            disabled={saving || (!canDelete && !canRestore)}
                        >
                            <i className={canRestore ? "fa fa-recycle" : "fa fa-trash"} />
                        </button>


                    </div>

                    {
                        chapter.lessons.map((lesson, lessonIndex: number) => {
                            return <LessonStructureEditor
                                key={lessonIndex}
                                index={lessonIndex}
                                lesson={lesson}
                                bookId={bookId}
                                showInactive={showInactive}
                                saving={this.state.saving}
                                chapterComponent={this}
                                bookListComponent={this.props.bookListComponent}
                                lessonUriArray={this.props.lessonUriArray}
                                lessonDc={this.props.lessonDc}
                                afterTransition={
                                    transitionType => { this.props.afterLessonTransition(lessonIndex, transitionType) }
                                }
                            />
                        })
                    }

                    {
                        chapter.lessons.length == 0
                            ?
                            <div className="callout small alert " style={{ marginLeft: "3em" }}>
                                <i className="fa fa-arrow-down" /> {__("Ez a fejezet üres. Kérem adjon hozzá új leckét.")}
                            </div>
                            :
                            ""
                    }

                    {
                        this.state.newLessonName === undefined
                            ?
                            <div className="row">
                                <div className="column" style={{ marginLeft: "3em" }}>
                                    <button onClick={() => this.setState({ newLessonName: "", newLessonURI: "lecke_0X_00X" })} className="button clear"><i className="fa fa-plus" /> {__("Új lecke hozzáadása")}</button>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="column" style={{ marginLeft: "3em" }}>
                                    <label>
                                        {__("Új lecke címe")}:
                                        <input type="text"
                                            placeholder={__("Írja be az új lecke nevét")}
                                            value={this.state.newLessonName}
                                            onChange={this.onLessonNameChange.bind(this)}
                                            onKeyPress={this.onLessonNameKeyPress.bind(this)}
                                            disabled={this.state.saving}
                                        />
                                    </label>
                                </div>

                                <div className="column" style={{ marginLeft: "3em" }}>
                                    <label>
                                        {__("Új lecke száma")}:
                                        <input type="number"
                                            placeholder="x. lecke"
                                            value={this.state.newLessonHeadno}
                                            onChange={this.onLessonHeadnoChange.bind(this)}
                                            disabled={this.state.saving}
                                        />
                                    </label>
                                </div>

                                <div className="column" style={{ marginLeft: "3em" }}>
                                    <label>
                                        {__("Új lecke azonosítója")}:
                                        <input type="text"
                                            placeholder="lecke_0X_00X"
                                            value={this.state.newLessonURI}
                                            onChange={this.onLessonURIChange.bind(this)}
                                            onKeyPress={this.onLessonURIKeyPress.bind(this)}
                                            disabled={this.state.saving}
                                        />
                                    </label>
                                </div>

                                <div className="shrink column align-self-middle">
                                    <button className="button small" onClick={this.onAddChapter.bind(this)}
                                        disabled={!this.state.newLessonName || !this.state.newLessonURI || this.state.newLessonURI == "lecke_0X_00X" || this.state.saving}
                                    >
                                        <i className="fa fa-plus" /> {__("Új lecke hozzáadása")}
                                    </button>
                                </div>

                                <div className="shrink column align-self-middle">
                                    <button className="button alert small" onClick={() => this.setState({ newLessonName: undefined })}>
                                        <i className="fa fa-trash" />
                                    </button>
                                </div>
                            </div>
                    }

                </div>
            )

        } else {
            return null;
        }
    }
}
type lessonIdAndUri = {
    id: number,
    uri_segment: string
};
type ChapterDialogProps = {
    chapter: IViewChapterRecord,
    defaultTabIndex: number;
    onRefresh: () => void,
    onClose: () => void,
};

interface IChapterDialogState {
    name: string;
    headno: string;
    exercise_background_color: string;
}

class ChapterDialog extends React.Component<ChapterDialogProps, IChapterDialogState> {
    constructor(props: ChapterDialogProps) {
        super(props);
        this.state = {
            name: this.props.chapter.name || "",
            headno: (this.props.chapter.headno != null) ? "" + (this.props.chapter.headno) : "",
            exercise_background_color: this.props.chapter.exercise_background_color || ""
        }
    }

    async onSave() {
        var regexp = new RegExp('^[0-9]+$');
        if (this.state.headno && !regexp.test(this.state.headno)) {
            alert(__("A fejezet csak számot tartalmazhat!"))
            return;
        }
        try {
            await new ChapterCrud({
                id: this.props.chapter.id,
                name: this.state.name,
                headno: (this.state.headno != "") ? Number(this.state.headno) : null,
                exercise_background_color: (this.state.exercise_background_color != "") ? this.state.exercise_background_color : null
            }).put();

            this.props.onRefresh();
            this.props.onClose();

            app.showSuccess(__("Sikeres mentés"), __("Fejezet megváltoztatva"));
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        return <Dialog width={800} height={600} title={__("Adatok - {chapterName}", { chapterName: this.props.chapter.name || "" })} onClose={this.props.onClose}>
            <DialogContent>
                <label>
                    {__("Név")}
                    <input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                </label>

                <label>
                    {__("Fejezet száma")}
                    <input type="number" value={this.state.headno} onChange={(e) => this.setState({ headno: e.target.value })} />
                </label>

                <label>
                    {__("Okosfeladatok háttérszíne")}
                    <div style={{ marginLeft: "1em" }}>
                        <input type="radio" checked={this.state.exercise_background_color == "" ? true : false} onChange={(e) => this.setState({ exercise_background_color: "" })} /> {__("Nincs megadva")}
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                        <input
                            type="radio"
                            style={{ verticalAlign: "middle" }}
                            checked={this.state.exercise_background_color != "" ? true : false}
                            onChange={(e) => { if (this.state.exercise_background_color == "") this.setState({ exercise_background_color: "#ffffff" }); }} />

                        &nbsp;<input
                            type="color"
                            style={{ width: "100px", display: "inline-block" }}
                            value={this.state.exercise_background_color ? this.state.exercise_background_color : "#ffffff"}
                            onChange={(e) => this.setState({ exercise_background_color: e.target.value })} />

                        &nbsp;<input
                            type="text"
                            style={{ width: "200px", display: "inline-block", verticalAlign: "middle" }}
                            value={this.state.exercise_background_color}
                            onChange={(e) => this.setState({ exercise_background_color: e.target.value })} />

                    </div>
                </label>
            </DialogContent>
            <DialogActions>
                <button className="button success" onClick={this.onSave.bind(this)}>
                    <i className="fa fa-save" /> {__("Mentés")}
                </button>
            </DialogActions>
        </Dialog>;
    }
}

interface ILessonStructureEditorProps {
    index: number;
    lesson: IViewLessonRecord;
    bookId: number;
    showInactive: boolean;
    saving: boolean;
    chapterComponent: ChapterStructureEditor;
    bookListComponent: BookStructureEditor;
    lessonUriArray: lessonIdAndUri[];
    afterTransition: (transitionType: IViewTransitionTypeRecord) => void;
    lessonDc: WfDeleteTransitionInfo;
}

interface ILessonStructureEditorState {
    saving: boolean;
    showPropertiesDialog: boolean;
    defaultTabIndex: number;
    workflowDialogOpen: boolean;
}

class LessonStructureEditor extends React.Component<ILessonStructureEditorProps, ILessonStructureEditorState> {
    constructor(props: ILessonStructureEditorProps) {
        super(props);
        this.state = {
            saving: false,
            showPropertiesDialog: false,
            defaultTabIndex: 0,
            workflowDialogOpen: false
        };
    }

    onLessonDeleteOrRestore = async (canDelete: boolean, canRestore: boolean) => {
        const lesson = this.props.lesson;

        // Kikeressük a törlő transition type-okat.
        let possibleTransitions = [];
        const ltt = this.props.lessonDc.activeTransitionTypes;
        for (let i = 0; i < ltt.length; i++) {
            let transitionType = ltt[i];
            if (transitionType.src_station_id == lesson.wf_station_id!) {
                if (canDelete) {
                    if (transitionType.dst_is_deleted!) {
                        possibleTransitions.push(transitionType);
                    }
                } else if (canRestore) {
                    if (!transitionType.dst_is_deleted!) {
                        possibleTransitions.push(transitionType);
                    }
                }
            }
        }

        const action = canDelete 
            ? __('Törlés végrehajtásához nyissa meg a folyamat dialógus ablakot.') 
            : __('Visszaállítás végrehajtásához nyissa meg a folyamat dialógus ablakot.');

        if (possibleTransitions.length > 1) {
            app.showError(
                __("Hiba"),
                __('Egynél több célállapot elérhető.') + " " + action
            );
            return;
        }
        const transitionType: IViewTransitionTypeRecord = possibleTransitions[0];

        if (await confirmDialog(action, __('Biztos benne, hogy {descriptionPart} ezt a leckét?\n{lesson}', { descriptionPart: canDelete ? __('törli') : __('visszaállítja'), lesson: lesson.name }), action)) {
            let justification = prompt(__('Kérem írja be az indoklást'), '');
            this.setState({ saving: true });
            let wfApi = new WfAPI(obtainServer());
            try {
                let workflowAfter: IViewWfWorkflowRecord = await wfApi.makeTransition(
                    lesson.wf_workflow_id!,
                    transitionType.id!,
                    justification
                );
                // Ez itt egy kis hack, nincs meg a sender, de az nincs is használva semmire...
                this.props.afterTransition(transitionType);
            } catch (error) {
                app.showErrorFromJsonResult(error);
                this.setState({ saving: false });
            };
        }
    }

    onUp() {
        this.props.chapterComponent.onLessonUp(this.props.index);
    }

    onDown() {
        this.props.chapterComponent.onLessonDown(this.props.index);
    }

    render() {
        let bookId = this.props.bookId;
        let lesson = this.props.lesson;
        const showInactive = this.props.showInactive;
        const saving = this.state.saving || this.props.saving;
        const isActive = lesson.is_active;

        const canDelete = this.props.lessonDc.deleteFromStationIds!.has(lesson.wf_station_id!);
        const canRestore = this.props.lessonDc.undeleteFromStationIds!.has(lesson.wf_station_id!);
        if (lesson.is_active || showInactive) {
            return (
                <div className="chapterLesson-lesson-inner">
                    {
                        this.state.showPropertiesDialog
                            ?
                            <LessonDialog
                                onClose={() => this.setState({ showPropertiesDialog: false })}
                                onRefresh={() => this.props.bookListComponent.reloadAsync()}
                                defaultTabIndex={this.state.defaultTabIndex}
                                lesson={lesson}
                                lessonsAndUri={this.props.lessonUriArray}
                            />
                            :
                            ""
                    }
                    <WorkflowDialog
                        open={this.state.workflowDialogOpen}
                        displayName="Lecke"
                        tableInfoId={LessonCrud.TABLE_INFO_ID}
                        recId={this.props.lesson.id!}
                        onClose={() => this.setState({ workflowDialogOpen: false })}
                        onTransition={t => this.props.afterTransition(t)}
                        autoPublishOnClosedStation={true}
                    />

                    <button className="button tiny secondary" style={{ marginRight: "1em" }}
                            onClick={this.onUp.bind(this)}
                            disabled={saving || !isActive}
                    >
                        <i className="fa fa-arrow-up" />
                    </button>

                    <button className="button tiny secondary" style={{ marginRight: "1em" }}
                            onClick={this.onDown.bind(this)}
                            disabled={saving || !isActive}
                    >
                        <i className="fa fa-arrow-down" />
                    </button>

                    <div className="chapterLesson-lesson-name">
                        {lesson.name} &nbsp;
                        <span className="nowrap">
                            <i style={{ fontSize: "small" }}>({lesson.headno ? lesson.headno + ". lecke, " : ""}{lesson.uri_segment})</i>
                            <button className="clear button small" style={{ marginRight: "1em" }}
                                    onClick={() => this.setState({ defaultTabIndex: 0, showPropertiesDialog: true })}
                                    disabled={saving || !isActive}
                            >
                                <i className="fa fa-pen" />
                            </button>
                        </span>
                    </div>

                    {lesson.wf_workflow_id ?
                        <button className="label small" style={Object.assign({ marginRight: "1em" }, lesson.wf_station_style!)}
                            title={getWfStateTitle(lesson)}
                                onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}
                        >
                            <i className="fa fa-arrow-circle-o-right" /> {getWfStateText(lesson)}
                        </button>
                        : ""
                    }

                    {isActive ?
                        <Link className="button small chapterLesson-lesson-button" style={{ marginRight: "1em" }}
                            to={PATH_EDIT_BOOK_SECTIONS + "/" + bookId + "/" + lesson.id + "/"} title={__("Szerkesztés")}
                        >
                            <i className="fa fa-edit" /> {/*__("Szerkesztés") */}
                        </Link>
                        : <button className="button small chapterLesson-lesson-button" style={{ marginRight: "1em" }}
                            disabled={true}>
                            <i className="fa fa-edit" /> {/*__("Szerkesztés") */}
                        </button>
                    }

                    <button className="button small alert"
                        onClick={() => { this.onLessonDeleteOrRestore(canDelete, canRestore); }}
                        disabled={saving || (!canDelete && !canRestore)}
                    >
                        <i className={canRestore ? "fa fa-recycle" : "fa fa-trash"} />
                    </button>
                </div>
            );
        } else {
            return null;
        }
    }
}

type LessonDialogProps = {
    lessonsAndUri: lessonIdAndUri[],
    lesson: IViewLessonRecord,
    defaultTabIndex: number;
    onRefresh: () => void,
    onClose: () => void,
};

type LessonDialogState = {
    name: string,
    uri_segment: string,
    headno: string,
    categorization: MetaCategorization,
    subject_ids: { [category_type_id: string]: number | null }
}

class LessonDialog extends React.Component<LessonDialogProps, LessonDialogState> {
    constructor(props: LessonDialogProps) {
        super(props);
        this.state = {
            name: this.props.lesson.name || "",
            uri_segment: this.props.lesson.uri_segment || "",
            headno: (this.props.lesson.headno != null) ? "" + (this.props.lesson.headno) : "",
            categorization: {},
            subject_ids: {},
        }
    }

    async onSave() {

        var regexp = new RegExp(/^[a-z0-9_]+$/);
        if (!regexp.test(this.state.uri_segment)) {
            alert(__("Az azonosító csak betűt, számot és aláhúzásjelet tartalmazhat!"))
            return;
        }

        const exists = this.props.lessonsAndUri.filter(x => x.id != this.props.lesson.id).some(x => x.uri_segment == this.state.uri_segment);
        if (exists) {
            app.showError(__("Sikertelen művelet"), __("Már létezik ilyen lecke azonosító! ({uri_segment})", { uri_segment: this.state.uri_segment }));
            return;
        }

        var regexpnum = new RegExp('^[0-9]+$');
        if (this.state.headno && !regexpnum.test(this.state.headno)) {
            alert(__("A lecke száma csak szám lehet!"))
            return;
        }
        try {
            await new LessonCrud({
                id: this.props.lesson.id,
                name: this.state.name,
                uri_segment: this.state.uri_segment,
                headno: (this.state.headno != "") ? Number(this.state.headno) : null,
            }).put();

            await this.saveCategories();

            this.props.onRefresh();
            this.props.onClose();

            app.showSuccess(__("Sikeres mentés"), __("Lecke megváltoztatva"));
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        return <Dialog width={1000} height={600} title={__("Adatok - {lessonName}", { lessonName: this.props.lesson.name || "" })} onClose={this.props.onClose}>
            <DialogContent>
                <label>
                    {__("Név")}
                    <input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                </label>
                <label>
                    {__("Lecke száma")}
                    <input type="number" value={this.state.headno} onChange={(e) => this.setState({ headno: e.target.value })} />
                </label>
                <label>
                    {__("Azonosító")}
                    <input type="text" value={this.state.uri_segment} onChange={(e) => this.setState({ uri_segment: e.target.value })} />
                </label>
                <MetaCategoryAssignerPlugin
                    tableInfoId={LessonCrud.TABLE_INFO_ID}
                    recId={this.props.lesson.id || null}
                    categorization={this.state.categorization}
                    subject_ids={this.state.subject_ids}
                    onSubjectSelected={this.onSubjectSelected}
                    onCategoryIdsLoaded={this.onCategoryIdsLoaded}
                    onSetCategoryIds={this.onSetCategoryIds}
                    onRemoveCategoryIds={this.onRemoveCategoryIds}
                />
            </DialogContent>
            <DialogActions>
                <button className="button success" onClick={this.onSave.bind(this)}>
                    <i className="fa fa-save" /> {__("Mentés")}
                </button>
            </DialogActions>
        </Dialog>;
    }

    private onSubjectSelected = (category_type_id: number, subject_id: number | null) => {
        this.setState({
            subject_ids: Object.assign(
                {},
                this.state.subject_ids,
                { [category_type_id.toString()]: subject_id })
        });
    }

    private onCategoryIdsLoaded = (category_type_id: number, category_ids: number[]) => {
        this.onSetCategoryIds(category_type_id, category_ids);
    }

    private onSetCategoryIds = (category_type_id: number, category_ids: number[]) => {
        let categorization = Object.assign(
            {},
            this.state.categorization,
            { [category_type_id.toString()]: category_ids }
        );
        this.setState({ categorization });
    }

    private onRemoveCategoryIds = (category_type_id: number, remove_category_ids: number[]) => {
        let category_ids = (this.state.categorization[category_type_id] || []).filter(
            (category_id: number) => { return remove_category_ids.indexOf(category_id) < 0 }
        );
        this.setState({
            categorization:
                Object.assign(
                    {},
                    this.state.categorization,
                    { [category_type_id]: category_ids }
                )
        });
    }

    private saveCategories = async () => {
        try {
            for (let category_type_id in this.state.categorization) {
                const category_ids = this.state.categorization[category_type_id];

                await setCategoryIds(
                    LessonCrud.TABLE_INFO_ID, this.props.lesson.id!,
                    category_ids!,
                    parseFloat(category_type_id)
                );
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }
}
