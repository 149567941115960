import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { MenuAction } from './Header';

type FunctionsMenuProps = {
    onClick?: () => void;
    actions: MenuAction[];
}

export default class FunctionsMenu extends React.Component<FunctionsMenuProps> {

    render() {
        return <div className="functions-tile">

            <div className="functions-list">

                {
                    this.props.actions.map((action,index) => {
                        return <div className="function" key={"fm_"+index}>
                            <NavLink onClick={this.props.onClick} className="" to={action.linkTo || ""}><i className={action.className} />{action.title}</NavLink>
                        </div>;
                    })
                }

                </div>
            </div>
    }

}


