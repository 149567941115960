import * as React from 'react';
import UserConfigStore from '@src/component/usr/usr_config_store_api';

interface ISmileTileConfig {
    smile : string;
}

export const FA_CODES = {
    tired: "tired",
    surprise: "surprise",
    smileWink: "smile-wink",
    smileBeam: "smile-beam",
    sadTear: "sad-tear",
    sadCry: "sad-cry",
    mehRollingEyes: "meh-rolling-eyes",
    mehBlank: "meh-blank",
    meh: "meh",
    grinWink: "grin-wink",
    grinStars: "grin-stars",
    grinSquintTears: "grin-squint-tears",
    //grinSquint: "grin-squint",
    grinHearts: "grin-hearts",
    grinBeamSweat: "grin-beam-sweat",
    grinBeam: "grin-beam",
    grinAlt: "grin-alt",
    grin: "grin",
    smile: "smile",
    laughWink: "laugh-wink",
    laughSquint: "laugh-squint",
    laughBeam: "laugh-beam",
    laugh: "laugh",
    kissWinkHeart: "kiss-wink-heart",
    kissBeam: "kiss-beam",
    kiss: "kiss",
    grinTongueWink: "grin-tongue-wink",
    grinTongueSquint: "grin-tongue-squint",
    grinTongue: "grin-tongue",
    grinTears: "grin-tears",
    grinmace: "grimace",
    frownOpen: "frown-open",
    frown: "frown",
    flushed: "flushed",
    angry: "angry",
    dizzy: "dizzy",
    heart: "heart",
}

export default class SmileTile extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            smileSet: {
                icons8:
                {
                    cool: "icons8-cool.png",
                    question: "icons8-emoji-with-a-question-mark.png",
                    angel: "icons8-emoticon-smile.png",
                    evile: "icons8-evil-smile.png",
                    happy: "icons8-happy-smile.png",
                    natural: "icons8-neutral-face-emoji.png",
                    sad: "icons8-sad-smile.png",
                    sad2: "icons8-sad-smile-2.png",
                    sad3: "icons8-sad-smile-3.png",
                    shocked: "icons8-shocked-smiley.png",
                    smile: "icons8-smile-emoticon.png",
                    teeth: "icons8-smiley-face-with-teeth.png",
                    heart: "icons8-smiling-face-with-heart.png",
                    tongue: "icons8-tongue-emoticon.png",
                    virus: "icons8-virus.png",
                    vinking: "icons8-winking-smiley.png"
                },
                fa: FA_CODES
            }
        };

        this.loadTile();
    }

    componentDidMount() {

        this.setState({smile:this.props.smile});

        ($(this.refs.setSmile) as any).foundation();
    }
    componentDidUpdate()
    {
        const tile = (this.refs.smileTile as HTMLElement);
        const size = tile.clientHeight > tile.clientWidth ? tile.clientWidth : tile.clientHeight;
        tile.style.fontSize = (size*0.8)+"px";
    }

    private loadTile = async () => {
        try {
            let value : ISmileTileConfig|null = await UserConfigStore.get<ISmileTileConfig>("dashboard.smile-tile");
            if (value===null) {
                //this.setState({smile: value});
            } else {
                this.setState({smile: value.smile}); 
            }
        } catch (error) {
            //app.showErrorFromJsonResult(error);
        }
    }

    private storeTile = async () => {
        let valueToStore : ISmileTileConfig= { smile: this.state.smile} ;
        try {
            await UserConfigStore.put("dashboard.smile-tile", valueToStore);
        } catch (error) {
            //app.showErrorFromJsonResult(error);
        }
    }


    handleOptionChange(smile: any) {
        this.setState({smile: smile},this.storeTile); 
        
    }
    openSettings() {
        ($(this.refs.setSmile) as any).foundation('open');
    }

    render() {
        return <div ref="smileTile" className="smile-tile">

                    {/* <img onClick={this.openSettings.bind(this)} src={"/img/IKON_SET/DASHBOARD/smile_set/" + this.state.smileSet["icons8"][this.state.smile]}/>   */}
                    {
                        this.state.smileSet["fa"][this.state.smile] ? <i onClick={this.openSettings.bind(this)} className={"far fa-" + this.state.smileSet["fa"][this.state.smile]}></i> :
                        <i onClick={this.openSettings.bind(this)} className={"far fa-smile"}></i>
                    }
                    
                    <div ref="setSmile" key={"smileTileModal"} className="reveal row smile-tile-modal" data-reveal="" id={"smileTileModal"}> 

                        {
                            Object.entries(this.state.smileSet["fa"]).map(([key,value]) => 
                            {
                                return <label key={key}>
                                            <input value={key} checked={this.state.smile == key} type="radio" onChange={()=> {return}} onClick={this.handleOptionChange.bind(this, key)}/>
                                            <i className={"far fa-" + value}></i>
                                        </label>
                            })
                        }
                        <button className="close-button" data-close="" aria-label="Close modal"  type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>                                   
            </div>
    }

}