import * as React from 'react';
import { __ } from '@src/translation';
import ViewKapKszrCourseUserStats from '@src/framework/view/kap/ViewKapKszrCourseUserStats';
import { TFilterDict } from '@crud/Crud';

type CourseExportPageState = {
    fromDate?: string;
    toDate?: string;
}

export class CourseExportPage extends React.Component<unknown, CourseExportPageState> {

    constructor(props: unknown) {
        super(props);

        this.state = {

        }
    }

    render() {
        const filter: TFilterDict = {};

        if (this.state.fromDate) {
            filter[">="] = {tovabbkezes_ideje_tol: this.state.fromDate}
        }
        if (this.state.toDate) {
            filter["<="] = {tovabbkezes_ideje_ig: this.state.toDate}
        }

        const url = ViewKapKszrCourseUserStats.getExcelDownloadUrl({
            filter: filter,
            limit: 30000,
            order_by: [{name: "kepzes_azon"}, {name: "resztvevo_neve"}]
        }, {
            filename: ""
        });

        return <div>

            Dátum tól:
            <input type="date" value={this.state.fromDate} onChange={e => this.setState({fromDate: e.target.value})} />
            Dátum ig:
            <input type="date" value={this.state.toDate} onChange={e => this.setState({toDate: e.target.value})} />

            <a className="button" href={url} >
                {__("Export")}
            </a>

        </div>;
    }

}
