import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IChapterRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Név
     */
    name?: string;
    /**
     * Könyv
     *
     * NOT NULL -> doc_pub.book ON DELETE cascade
     *
     */
    book_id?: number;
    /**
     * Sorszám
     *
     * A fejezet sorszáma, ahogy az a könyv lapok tetején megjelenik.
     */
    headno?: number /*integer*/|null;
    /**
     * Feladat háttér színe ebben a fejezetben
     */
    exercise_background_color?: string|null;
}

/**
 *  doc_pub.chapter - Fejezet
 */

export default class ChapterCrud extends Crud<IChapterRecord> {
    public static TABLE_INFO_ID = 751354988;
    public static getSchemaNameForClass() { return 'doc_pub'; }
    public static getTableNameForClass() { return 'chapter'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<ChapterCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IChapterRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IChapterRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IChapterRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IChapterRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IChapterRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ChapterCrudClassProxy extends CrudClassProxy<IChapterRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const chapterCrudClassProxy = new ChapterCrudClassProxy(ChapterCrud);
registerCrudClassProxy(chapterCrudClassProxy);
