import { Server } from '@framework/server/Server';


export interface AuditLogRecord {
    event_id: number;
    action_tstamp_tx: string /*timestamp*/;
    user_id: number;
    session_id: number;
    action: string; /*I/U/D*/
    row_data: any;
    changed_fields: any;
    login_name: string;
    client_ip: string;
    login_time: string;
    user_agent: string;
}

/**
 * Audit API
 * 
 */
export default class AuditLogAPI {
    server: Server;

    constructor(server: Server) {
        this.server = server;
    }

    /**
     * Új workflow indítása azonosítók megadásával.
     * 
     * @param headTableId a fejtáblázat azonosítója
     * @param recordId a rekord azonosítója
     * @param stationId Keződállapot. Ha nincs megadva, akkor a workflow típushoz
     *  csak egyetlen egy aktív kezdőállapot lehet, és ez lesz használva.
     */
    public async getHistory(tableInfoId: number, recordId: number): Promise<AuditLogRecord[]> {
        return this.server.post<any[]>(
            'auditlog',
            {
                operation: 'get_history',
                table_info_id: tableInfoId,
                rec_id: recordId
            }
        );
    }

}