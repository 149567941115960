import { ExerciseServerInterface, ExerciseSeriesInfo, DataToEvaluate } from "@src/component/exercise/ExerciseContainerComponent";
import { IExerciseRecord } from "@src/framework/crud/exc/ExerciseCrud";
import { searchExercise, ExerciseWithMeta } from '@src/server/PublicServer';
import { exerciseServerClasses } from "./models/ExerciseServerClasses";

export const EXERCISE_ENGINES_SRC_FOLDER = "/ts/ExerciseEngines";
export const EXERCISE_ENGINES_COMPILED_FOLDER = "/js/ExerciseEngines";


type EvaluateOnServerFuncType = (exercise: IExerciseRecord, correctSolution: any, userSolution: any) => Evaluated | boolean;

export class ExerciseServerSimulator implements ExerciseServerInterface {

    private exerciseRecord: IExerciseRecord;

    private exerciseEngineClassName: string;

    setExerciseRecord(exerciseEngineClassName: string, exerciseRecord: IExerciseRecord) {
        this.exerciseEngineClassName = exerciseEngineClassName;
        this.exerciseRecord = exerciseRecord;
    }

    async startSeries() {
        return undefined;
    }

    getSeriesInfo(): ExerciseSeriesInfo {
        return {
            totalExerciseCount: 1,
            currentExerciseCount: 1,
            successCount: 0,
            totalPoints: 0,
            earnedPoints: 0,
            isFinished: false,
        };
    }

    getExercisebyIndex() {
        return this.getCurrentExercise();
    }

    skipCurrentExercise() {
        return this.getCurrentExercise();
    }

    async getCurrentExercise(): Promise<ExerciseWithMeta> {

        var exerciseRecordCopy: IExerciseRecord = JSON.parse(JSON.stringify(this.exerciseRecord));

        const engineClass = exerciseServerClasses[this.exerciseEngineClassName];
        
        if(engineClass){
            const filterExerciseOnServer: any = engineClass["filterExerciseOnServer"];
            if (filterExerciseOnServer) {
                exerciseRecordCopy.exercise = filterExerciseOnServer(exerciseRecordCopy.exercise, exerciseRecordCopy.solution.solution);
            }
        }

        exerciseRecordCopy.solution = undefined;
        
        return { 
            exerciseCount: 1,
            currentExerciseIndex: 0,
            exercise: exerciseRecordCopy, 
            exerciseEngineName: this.exerciseEngineClassName,
            userSolution: undefined,
            totalPoints: 1
        };
    }

    async evaluate( exercise: IExerciseRecord, dataToEvaluate: DataToEvaluate): Promise<Evaluated> {
        var userSolution = dataToEvaluate.answer;
        let correctSolution = this.exerciseRecord.solution.solution;

        if(!(userSolution instanceof Array) && Object.keys(userSolution).length === 0) return {success:false, solution:correctSolution};


        const engineName = this.exerciseEngineClassName;

        const engine = exerciseServerClasses[engineName];

        const evaluateOnServerFunc: EvaluateOnServerFuncType | undefined = engine["evaluateOnServer"];

        if (evaluateOnServerFunc) {

            const evaluationResult = evaluateOnServerFunc(exercise, correctSolution, userSolution);
            if(exercise.exercise.all_correct) return {success:true, solution:[], successPercent: 0};

            if (typeof evaluationResult == "boolean") {
                if (evaluationResult) {
                    return { success: true, solution: [] }
                } else {
                    return { success: false, solution: correctSolution }
                }
            } else {
                return evaluationResult;
            }

        } else {

            var success;

            if (dataToEvaluate.fullmatch) { 
                success = JSON.stringify(correctSolution) == JSON.stringify(userSolution); 
            } else {
                success = JSON.stringify(correctSolution).indexOf(JSON.stringify(userSolution[0])) >= 0;
            }

            if (success) {
                return { success: success, solution: [] }
            } else {
                return { success: success, solution: correctSolution }
            }
        }
    }

    async getCorrectSolution( exerciseId: number): Promise<any[]> {

        var correctSolution = this.exerciseRecord.solution.solution;

        return correctSolution;
    }

    async searchExercise(exercise_id: number): Promise<any> {

        //TODO Ha könyvben van a feladat, csak az adott könyvben keressen
        return searchExercise(exercise_id, false);
    }      

    isPublished() {
        return false;
    }

}
