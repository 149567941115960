import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';

import StationConstraintMatrixCrud, { IStationConstraintMatrixRecord, stationConstraintMatrixCrudClassProxy } from '@framework/crud/wf/StationConstraintMatrixCrud'
import StationConstraintMatrixItemCrud, { IStationConstraintMatrixItemRecord } from '@framework/crud/wf/StationConstraintMatrixItemCrud'

import { app, history } from '@src/index';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import StationCrud, { IStationRecord } from '@src/framework/crud/wf/StationCrud';

import './wf_station_constraint_matrix_editor.css';
import WfTypeCrud, { IWfTypeRecord } from '@src/framework/crud/wf/WfTypeCrud';
import { __ } from '@src/translation';

interface IWfStationConstraintMatrixEditorProps {
    constraintId?: number;
    match: any;
}

interface ICheckboxState {
    masterStationId: number;
    detailStationId: number;
    enabled: boolean;
    itemIndex?: number;
}

type CheckboxStates = { [pairKey: string]: ICheckboxState };

interface IWfStationConstraintMatrixEditorState {
    loading: boolean;
    saving: boolean;
    constraintId: number | null;
    constraint: IStationConstraintMatrixRecord | null;
    items: IStationConstraintMatrixItemRecord[];
    masterStations: IStationRecord[];
    detailStations: IStationRecord[];
    masterType: IWfTypeRecord | null;
    detailType: IWfTypeRecord | null;
    extraLegend: JSX.Element | null;

    orig: CheckboxStates;
    changed: CheckboxStates;
}

const EMPTY_STATE: IWfStationConstraintMatrixEditorState = {
    loading: false,
    saving: false,
    constraintId: null,
    constraint: null,
    items: [],
    masterStations: [],
    detailStations: [],
    masterType: null,
    detailType: null,
    extraLegend: null,
    orig: {},
    changed: {},
}


export default class WfStationConstraintMatrixEditor
    extends React.Component<IWfStationConstraintMatrixEditorProps,
    IWfStationConstraintMatrixEditorState> {

    constructor(props: IWfStationConstraintMatrixEditorProps) {
        super(props);
        this.state = { ...EMPTY_STATE, loading: true };
    }

    protected getMatchParam(name: string): any {
        const match: any = this.props.match;
        return (match && match.params) ? match.params[name] : null;
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        try {
            let constraintId = this.state.constraintId;
            if (constraintId) {
                constraintId = parseInt("" + constraintId);

                // A fő constraint rekord.
                const constraint = (await StationConstraintMatrixCrud.load(constraintId)).record;
                // Lekérdezzük a tárolt item-eket.
                const items = await StationConstraintMatrixItemCrud.list({
                    filter: {
                        station_constraint_matrix_id: constraintId
                    }
                });
                // Előállítjuk az eredeti elemeket tartalmazó dict-et.
                let orig: CheckboxStates = {};
                items.forEach((item, index) => {
                    const key = this.getKey(item.master_station_id!, item.detail_station_id!);
                    orig[key] = {
                        masterStationId: item.master_station_id!,
                        detailStationId: item.detail_station_id!,
                        itemIndex: index,
                        enabled: item.enabled!
                    }
                })

                // Lekérdezzük a master és detail típust.
                const masterType = (await WfTypeCrud.load(constraint.master_wf_type_id!)).record;
                const detailType = (await WfTypeCrud.load(constraint.detail_wf_type_id!)).record;
                // Lekérdezzük a fő- és alfolyamat állapotait.
                const masterStations = await StationCrud.list({ filter: { wf_type_id: constraint.master_wf_type_id, is_active: true }, order_by: "no" });
                const detailStations = await StationCrud.list({ filter: { wf_type_id: constraint.detail_wf_type_id, is_active: true }, order_by: "no" });

                this.setState({
                    loading: false,
                    saving: false,
                    constraintId,
                    constraint, items,
                    masterStations, detailStations,
                    masterType, detailType,
                    orig, changed: {}
                });
            } else {
                this.setState({ ...EMPTY_STATE, loading: false });
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private onSelectConstraint = (sender: CrudSelectComponent, constraintId: number | null) => {
        this.setState({ constraintId, loading: true }, ()=> {
            if (constraintId) {
                history.replace('/wf/station_constraint_matrix/'+ constraintId);
            }
            this.asyncReload();
        });
    }

    private getKey = (masterStationId: number, detailStationId: number): string => {
        return "" + masterStationId + "_" + detailStationId;
    }


    private isAllowed = (masterStationId: number, detailStationId: number): boolean => {
        const key = this.getKey(masterStationId, detailStationId);
        if (this.state.changed[key]) {
            return this.state.changed[key].enabled;
        } else if (this.state.orig[key]) {
            return this.state.orig[key].enabled;
        } else {
            return false;
        }
    }

    private isChanged = (masterStationId: number, detailStationId: number): boolean => {
        const key = this.getKey(masterStationId, detailStationId);
        return this.state.changed[key] ? true : false;
    }

    private getAuditInfo = (masterStationId: number, detailStationId: number): string => {
        const key = this.getKey(masterStationId, detailStationId);
        const orig = this.state.orig[key];
        const changed = this.state.changed[key];
        if (orig) {
            const item = this.state.items[orig.itemIndex!];
            // TODO: itt user id helyett a neve kell, de ahhoz kell egy view...
            return "" + item.creation_time + " " + item.creation_user_id +
                " " + (changed ? __("MEGVÁLTOZTATVA") : "");
        } else if (changed) {
            return __("ÚJ");
        } else {
            return "";
        }
    }

    private toggleAllowed = (masterStation: IStationRecord, detailStation: IStationRecord): void => {
        const masterStationId = masterStation.id!;
        const detailStationId = detailStation.id!;
        const key = this.getKey(masterStationId, detailStationId);
        const orig = this.state.orig[key];
        const changed = this.state.changed[key];
        let oldValue: boolean;
        if (changed === undefined) {
            oldValue = orig ? orig.enabled : false;
        } else {
            oldValue = changed.enabled;
        }
        const newValue = !oldValue;
        if ((orig && (newValue == orig.enabled)) || (!orig && !newValue)) {
            delete this.state.changed[key];
        } else {
            this.state.changed[key] = {
                masterStationId, detailStationId, enabled: newValue
            }
        }
        this.setState({ changed: this.state.changed }, ()=> {
            this.setExtraLegend(masterStation, detailStation);
        });        
    }

    private setExtraLegend = (masterStation: IStationRecord, detailStation: IStationRecord): void => {
        if (this.state.masterType && this.state.detailType) {            
            this.setState({
                extraLegend:
                    <span>
                        <span style={masterStation.style!}>{masterStation.name!}</span>
                        &nbsp; {__("állapotú") }  &nbsp; <b>{this.state.masterType!.name}</b>
                        &nbsp; { __("főfolyamatnak") } &nbsp;
                        {this.isAllowed(masterStation.id!, detailStation.id!)
                            ?<span className="label success">{ __("lehet") }</span>
                            :<span className="label alert">{ __("nem lehet") }</span>} &nbsp;
                        <span style={detailStation.style!}>
                            {detailStation.name!}
                        </span> &nbsp; { __("állapotú") } &nbsp; <b>{this.state.detailType!.name}</b> &nbsp; { __("alfolyamata.") }
                </span>
            });
        } else {
            this.setState({ extraLegend: null });
        }
    }

    private clearExtraLegend = () => {
        this.setState({ extraLegend: null });
    }

    private onSave = async () => {
        this.setState({ saving: true });
        try {
            const changed = this.state.changed;
            for (var key in changed) {
                if (changed.hasOwnProperty(key)) {
                    const item = changed[key];
                    const crud = new StationConstraintMatrixItemCrud({
                        station_constraint_matrix_id: this.state.constraintId!,
                        master_station_id: item.masterStationId,
                        detail_station_id: item.detailStationId,
                        enabled: item.enabled
                    });
                    await crud.upsert([
                        'station_constraint_matrix_id',
                        'master_station_id', 'detail_station_id'
                    ]);
                }
            }
            await this.asyncReload();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    render() {
        const constraintSelector = <div className="small-12 column">
            <CrudSelectComponent
                value={this.state.constraintId}
                onSelect={this.onSelectConstraint}
                displayFieldName="title"
                crudClassProxy={stationConstraintMatrixCrudClassProxy}
                emptyTitle={__("Kérem válasszon egy mátrixot...")}
            />
        </div>;
        let description = null;
        let editor;
        let buttons = null;
        let legend = null;


        if (this.state.loading) {
            editor = <BubbleLoader />;
        } else if (!this.state.constraintId) {
            editor = null;
        } else {
            if (this.state.constraint!.description) {
                description = <div className="small-12 column">
                    <p className="callout primary">
                        {this.state.constraint!.description}
                    </p>
                </div>;
            }

            const { masterStations, detailStations } = this.state;

            let inner = <table>
                <thead>
                    <tr className="wf-station-constraint-matrix-table-head-row">
                        <td></td>
                        {masterStations!.map((station) =>
                            <td key={station.id!}
                                style={station.style!}
                                className="wf-station-constraint-matrix-table-master-station"
                                title={station.description}
                            >
                                {station.name}
                            </td>
                        )}
                    </tr>
                    {detailStations!.map((dStation) =>
                        <tr key={dStation.id}>
                            <td key={dStation.id!}
                                style={dStation.style!}
                                title={dStation.description}
                                className="wf-station-constraint-matrix-table-detail-station"
                            >
                                {dStation.name}
                            </td>
                            {masterStations!.map((mStation) =>
                                <td key={this.getKey(mStation.id!, dStation.id!)}
                                    className={
                                        "wf-station-constraint-matrix-table-checkbox-cell" + (
                                            this.isChanged(mStation.id!, dStation.id!)
                                                ? "wf-station-constraint-matrix-table-checkbox-cell-changed"
                                                : ""
                                        )}
                                    onMouseEnter={() => {
                                        this.setExtraLegend(mStation, dStation)
                                    }}
                                    onMouseLeave={this.clearExtraLegend}
                                >
                                    <input type="checkbox"
                                        disabled={this.state.saving}
                                        title={this.getAuditInfo(mStation.id!, dStation.id!)}
                                        checked={this.isAllowed(mStation.id!, dStation.id!)}
                                        onChange={() => { this.toggleAllowed(mStation, dStation) }}
                                    />
                                </td>
                            )}

                        </tr>
                    )}
                </thead>
            </table>

            editor =
                <div className="small-12 column">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th
                                    className="wf-station-constraint-matrix-table-master-head"
                                    colSpan={masterStations!.length}>
                                    {this.state.masterType!.name!} ({__("főfolyamat")})
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className="wf-station-constraint-matrix-table-detail-head"
                                >
                                    {this.state.detailType!.name!} ({__("alfolyamat") })
                                </th>
                                <td>
                                    {inner}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>;


            legend = <div className="small-12 columns">
                {this.state.extraLegend?
                <p className="callout primary">
                    {this.state.extraLegend}
                </p>:""}

                <p className="callout secondary">
                   {__("Csak a pipált elemekhez tartzó fő- és alfolyamat állapot kombinációk engedélyezettek. Minden olyan átmenet tiltott, ami tiltott állapot kombinációba vezet.")} 
                </p>
            </div>;

            const nChanges = Object.keys(this.state.changed).length;
            const unchanged = nChanges === 0;
            buttons = <div className="small-12 columns">
                <a
                    className={"button success " +
                        (unchanged || this.state.saving ? "disabled" : "")}
                    onClick={this.onSave}
                >
                    <i className={"fa " +
                        this.state.saving ? "fa-hourglass" : "fa-save"}
                    /> &nbsp;
                    {this.state.saving ? __("Mentés folyamatban, kérem várjon...") :
                        (unchanged?__("Nincs változtatás"):
                            __(`Mentés ({n_changes} változtatás)`,{n_changes:nChanges}))
                    }
                </a>
            </div>;
        }

        return <div className="row">
            {constraintSelector}
            {description}
            {editor}
            {buttons}
            {legend}
        </div>;
    }
}
