import * as React from 'react';
import { app } from '@src/index';
import { __ } from '@src/translation';
import { BubbleLoader } from 'react-css-loaders';
import { HTMLTextarea } from '../HtmlTextarea';
import { emailAddressChecker } from '@src/Util';
import { sendErrorReport } from '@src/server/PublicServer';
type KAPEmailSenderProps = {
    onSendComplete : () => void;
}

type KAPEmailSenderState = {
    subject: string;
    SenderName: string;
    emailAddress: string;
    ProblemDescription: string;
    sending: boolean;
}

export class KAPErrorReport extends React.Component<KAPEmailSenderProps, KAPEmailSenderState> {

    constructor(props: KAPEmailSenderProps) {
        super(props);

        this.state = {
            subject: "Technikai probléma",
            SenderName: "",
            emailAddress: "",
            ProblemDescription: "",
            sending: false


        }
    }


    private async onSubmit() {
        this.setState({ sending: true }, async () => {

            try {
                if (!this.state.subject || this.state.subject.length < 1) { app.showError("Hiba", "Kötelező megadni a tárgyat!"); return; }
                if (!this.state.SenderName || this.state.SenderName.length < 1) { app.showError("Hiba", "Kötelező megadni a nevet!"); return; }

                if (!this.state.emailAddress || !emailAddressChecker(this.state.emailAddress)) { app.showError("Hiba", "Kötelező érvényes e-mail címet megadni!"); return; }
                if (!this.state.ProblemDescription || this.state.ProblemDescription.length < 1) { app.showError("Hiba", "Kötelező megadni a probléma leírását"); return; }

                await sendErrorReport(this.state.subject, this.state.SenderName, this.state.emailAddress, this.state.ProblemDescription);
                app.showSuccess("Sikeres beküldés", "A probléma beküldésre került, köszönjük.")
                this.props.onSendComplete();

            } catch (e) {
                app.showErrorFromJsonResult(e);
            }
        }

        );
    }


    private onSubjectChange(event: any) {

        let subject = event.target.value;
        this.setState({
            subject
        })
    }
    private onEmailChange(event: any) {

        let emailAddress = event.target.value;
        this.setState({
            emailAddress
        })
    }
    private onSenderChange(event: any) {

        let SenderName = event.target.value;
        this.setState({
            SenderName
        })
    }
    private onDescriptionChanged(htmlBody: string) {
        let ProblemDescrtiption = htmlBody;
        this.setState({
            ProblemDescription: ProblemDescrtiption
        })
    }

    render() {
        return <div>
            <div className="row">
                <div className="column small-12">
                    <br />
                    <h4>{__("Probléma bejelentése")}</h4>
                </div>
                <div className="column small-12">
                    <label>
                        {__("Tárgy")}
                        <input type="text" value={this.state.subject || ""} onChange={this.onSubjectChange.bind(this)} />
                    </label>
                </div>
                <div className="column small-12">
                    <label>
                        {__("Név")}
                        <input type="text" value={this.state.SenderName || ""} onChange={this.onSenderChange.bind(this)} />
                    </label>
                </div>
                <div className="column small-12">
                    <label>
                        {__("E-mail cím")}
                        <input type="text" value={this.state.emailAddress || ""} onChange={this.onEmailChange.bind(this)} />
                    </label>
                </div>
                <div className="column small-12">
                    <label>
                        {__("Probléma Leírása")}
                    </label>
                    <HTMLTextarea
                        value={this.state.ProblemDescription || ""}
                        onChange={e => this.onDescriptionChanged(e)}
                        height={300}
                    />
                </div>
                <div className="column text-right">
                    <br />
                    <button className="button success" onClick={this.onSubmit.bind(this)}
                        disabled={this.state.sending}
                    >
                        <i className="fa fa-envelope" /> {__("Küldés")}
                    </button>
                </div>

            </div>
        </div>;
    }
}
