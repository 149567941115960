import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IActivityTypeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Név
     */
    title?: string;
}

/**
 *  usr.activity_type - Aktivitás típus
 */

export default class ActivityTypeCrud extends Crud<IActivityTypeRecord> {
    public static TABLE_INFO_ID = 2265995509;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'activity_type'; }

    public static load: (id: number, aServer ?: Server) => Promise<ActivityTypeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IActivityTypeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IActivityTypeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IActivityTypeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IActivityTypeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IActivityTypeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ActivityTypeCrudClassProxy extends CrudClassProxy<IActivityTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const activityTypeCrudClassProxy = new ActivityTypeCrudClassProxy(ActivityTypeCrud);
registerCrudClassProxy(activityTypeCrudClassProxy);
