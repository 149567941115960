import * as React from 'react';
import { LessonPlanEdit, LessonPlanEditProps, LessonPlanEditState, AsyncStatus, EMPTY_TECHICAL } from '@src/component/lessonplan/LessonPlanEdit';
import OccupationDraftCrud, { IOccupationDraftRecord } from '@src/framework/crud/kap/OccupationDraftCrud';
import OccupationDraftBlockCrud, { IOccupationDraftBlockRecord } from '@src/framework/crud/kap/OccupationDraftBlockCrud';
import { __ } from '@src/translation';

import CrudSelect from '@framework/forms/crudselect';
import { subprogramCrudClassProxy } from '@src/framework/crud/kap/SubprogramCrud';
import { app, history } from '@src/index';
import { LIBRARY_OFI_OFFICIAL_ID, LIBRARY_JTR_BEADANDO_ID } from '@src/Const';
import { HTMLTextarea } from '@src/component/HtmlTextarea';
import OccupationDraftSubjectCrud, { IOccupationDraftSubjectRecord, occupationDraftSubjectCrudClassProxy } from '@src/framework/crud/kap/OccupationDraftSubjectCrud';
import OccupationDraftGradeCrud, { IOccupationDraftGradeRecord, occupationDraftGradeCrudClassProxy } from '@src/framework/crud/kap/OccupationDraftGradeCrud';
import { CrudMultiSelect } from '@src/framework/forms/crudmultiselect';
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import OccupationDraftSubprogramCrud, { IOccupationDraftSubprogramRecord, occupationDraftSubprogramCrudClassProxy } from '@src/framework/crud/kap/OccupationDraftSubprogramCrud';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import { eduMethodCrudClassProxy } from '@src/framework/crud/kap/EduMethodCrud';
import { eduWorkFormCrudClassProxy } from '@src/framework/crud/kap/EduWorkFormCrud';
import OccupationDraftBlockEduMethodCrud, { IOccupationDraftBlockEduMethodRecord, occupationDraftBlockEduMethodCrudClassProxy } from '@src/framework/crud/kap/OccupationDraftBlockEduMethodCrud';
import OccupationDraftBlockEduWorkFormCrud, { occupationDraftBlockEduWorkFormCrudClassProxy, IOccupationDraftBlockEduWorkFormRecord } from '@src/framework/crud/kap/OccupationDraftBlockEduWorkFormCrud';
import { getMissingFields } from '@src/Util';
import ViewKapDraft from '@src/framework/view/kap/ViewKapDraft';
import { fetchKeywords, setKeywords } from '@src/component/meta/meta_keyword_assigner_plugin';
import { groupTypeCrudClassProxy } from '@src/framework/crud/kap/GroupTypeCrud';
import { IViewTransitionTypeRecordWithPerm } from '@src/framework/wf/WfAPI';
import { getMetaCategoryTree } from '@src/component/meta/meta_category_assigner_api';
import { KAP_META_KOMPETENCIA } from './DraftEditList';
import { getDraftComment, setDraftComment } from '@src/server/PublicServer';
import { Comment } from '@src/component/ui/Comment';
import { updateSubrecords } from '@src/component/filemanager/UpdateSubrecords';
import { DownloadAsPdf } from './DownloadAdPdf';
import { match } from 'react-router';
import { IOoFileRecord } from '@src/framework/crud/media_pub/OoFileCrud';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { me } from '@src/framework/server/Auth';

export const PATH_OCCUPATION_DRAFT_EDIT = "/oravazlat/foglalkozasterv";

export class OccupationDraftEditPage extends React.Component<{ match: match<{ id?: string }> }> {

    render() {
        return <OccupationDraftEdit
            id={this.props.match.params.id ? Number(this.props.match.params.id) : undefined}
            onSave={newId => history.push(PATH_OCCUPATION_DRAFT_EDIT + "/" + newId)}
        />;
    }

}

type OccupationDraftEditState = {

    subjects: IOccupationDraftSubjectRecord[];
    grades: IOccupationDraftGradeRecord[];
    subprograms: IOccupationDraftSubprogramRecord[];

    subjectIds: number[];
    gradeIds: number[];
    subprogramIds: number[];
    occupationDraftFiles: IOoFileRecord[];

    comments: any;
    publicationConfirmed: string;
} & LessonPlanEditState<IOccupationDraftRecord, IOccupationDraftBlockRecord>;

export class OccupationDraftEdit extends LessonPlanEdit<LessonPlanEditProps, OccupationDraftEditState> {

    constructor(props: LessonPlanEditProps) {
        super(props);

        this.state = {
            lessonPlan: { library_id: this.props.defaultLibraryId || LIBRARY_OFI_OFFICIAL_ID },
            lessonPlanBlocks: [] as any,
            folders: [],
            occupationDraftFiles: [],
            deletedBlocks: [],
            status: AsyncStatus.LOADING,
            ownerName: "",

            categorization: {},
            subject_ids: {},

            workflowDialogOpen: false,

            subjects: [],
            grades: [],
            subprograms: [],

            subjectIds: [],
            gradeIds: [],
            subprogramIds: [],

            keywords: [],

            comments: undefined,
            publicationConfirmed: ""
        }
    }

    componentDidMount() {
        if (this.props.id) {
            this.reloadAsync();
        } else {
            this.setState({ status: AsyncStatus.LOADED });
        }
    }

    componentDidUpdate(prevProps: LessonPlanEditProps) {
        if (this.props.id && this.props.id !== prevProps.id) {
            this.reloadAsync();
        }

        $('.accordion-title input').click(function (e) {
            e.stopPropagation();
        });
    }

    getTitles() {
        return {
            mainTitle: __("Alprogrami foglalkozásterv"),
            mainPanelTitle: __("Adatlap"),
            blockPanelTitle: __("Az óra menete"),
            secondPanelTitle: __("Mellékletek"),
            attachmentPanelTitle: __("Csatolt állományok"),
            blockTitle: __("Sor"),
            tableInfoId: OccupationDraftCrud.TABLE_INFO_ID,
            disableCheckforNodesWithLeaf: true,
        }
    }

    async reloadAsync() {
        const id = this.props.id;
        if (!id) return;

        try {
            const lessonPlan = (await OccupationDraftCrud.load(id)).record;
            let occupationDraftFiles = await OoFileCrud.list({ filter: { is_active: true, oo_folder_id: lessonPlan.oo_folder_id }, order_by: "title" });

            const comments = await getDraftComment("occupation_draft_" + id);

            const listParams = {
                filter: {
                    is_active: true,
                    occupation_draft_id: lessonPlan.id,
                },
                order_by: "id"
            };

            const lessonPlanBlocks = await OccupationDraftBlockCrud.list({
                filter: {
                    is_active: true,
                    occupation_draft_id: lessonPlan.id,
                },
                order_by: "no"
            });

            const subjects = await OccupationDraftSubjectCrud.list(listParams);
            const grades = await OccupationDraftGradeCrud.list(listParams);
            const subprograms = await OccupationDraftSubprogramCrud.list(listParams);

            for (const block of lessonPlanBlocks) {
                const methods = await OccupationDraftBlockEduMethodCrud.list({ filter: { is_active: true, occupation_draft_block_id: block.id } });
                block["eduMethods"] = methods;
                block["eduMethodIds"] = methods.map(m => m.edu_method_id);

                const workForms = await OccupationDraftBlockEduWorkFormCrud.list({ filter: { is_active: true, occupation_draft_block_id: block.id } });
                block["eduWorkFroms"] = workForms;
                block["eduWorkFromIds"] = workForms.map(w => w.edu_work_form_id);
            }

            const viewLessonPlan = (await ViewKapDraft.load(lessonPlan.id!)).record;

            const keywords = await fetchKeywords(OccupationDraftCrud.TABLE_INFO_ID, lessonPlan.id!);
            let pub;
            if (lessonPlan.consent_publication && lessonPlan.consent_publication != null) {
                pub = "pub_confirm";
            } else if (lessonPlan.consent_publication == null) {
                pub = "pub_not_confirm";
            } else {
                pub = "";
            }
            this.setState({
                status: AsyncStatus.LOADED,
                occupationDraftFiles,
                lessonPlan: lessonPlan,
                lessonPlanBlocks,
                deletedBlocks: [],
                grades,
                subjects,
                subprograms,
                subjectIds: subjects.map(s => s.subject_id!),
                gradeIds: grades.map(g => g.grade_id!),
                subprogramIds: subprograms.map(s => s.subprogram_id!),
                workflowStateName: viewLessonPlan.wf_station_name || "",
                keywords,
                comments,
                publicationConfirmed: pub


            });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    protected onBeforeTransition(transition: IViewTransitionTypeRecordWithPerm) {
        const timeMinutes = this.state.lessonPlanBlocks.map(l => l.require_time_min).reduce((a, b) => (a || 0) + (b || 0), 0);

        if (!timeMinutes || ![45, 60, 90].includes(timeMinutes) && !transition.dst_is_deleted) {
            app.showError("Hiba", __("A foglalkozás 45 vagy 60 vagy 90 perces lehet."));
            return false;
        }

        if (this.state.keywords.length == 0 && transition.dst_is_deleted) {
            app.showError("Hiba", __("Kérem adjon meg kulcsszavakat!"));
            return false;
        }

        if (this.state.categorization[KAP_META_KOMPETENCIA] && this.state.lessonPlan.library_id == LIBRARY_JTR_BEADANDO_ID && this.state.categorization[KAP_META_KOMPETENCIA]!.length < 1 && !transition.dst_is_deleted) {
            app.showError("Hiba", __("Legalább egy metaadat megadása kötelező!"));
            return false;
        }

        return true;
    }



    async onSave() {
        try {

            let toomuchcatTitles: string[] = [];
            const treeRoot = await getMetaCategoryTree(KAP_META_KOMPETENCIA);

            const selectedIds = this.state.categorization[KAP_META_KOMPETENCIA];

            if (this.state.lessonPlan.library_id == LIBRARY_JTR_BEADANDO_ID && this.state.publicationConfirmed == "") {
                app.showError("Hiba", __("Kérem jelezze, hogy hozzájárul-e az esetleges publikus megjelenéshez!"));
                return;
            }
            if (selectedIds) {
                let count = 0;
                for (let i = 0; i < treeRoot.children.length; i++) {
                    count = 0;
                    for (let k = 0; k < treeRoot.children[i].children.length; k++) {
                        if (selectedIds.includes(treeRoot.children[i].children[k].id)) {
                            count++;
                        }
                    }
                    if (count > 4) {
                        toomuchcatTitles.push(treeRoot.children[i].title);
                    }
                }
            }
            if (toomuchcatTitles.length > 0) {
                app.showError(__("Sikertelen mentés"), __(`Egyszerre maximum 4 kompetencia adható meg! 4-nél több lett megadva a következőkben: `) + toomuchcatTitles.join(", "));
                return;
            }


            let missingFields = await getMissingFields(OccupationDraftCrud as any, this.state.lessonPlan, ["oo_folder_id"]);

            for (const lessonPlanBlock of this.state.lessonPlanBlocks) {

                if (!lessonPlanBlock.require_time_min || lessonPlanBlock.require_time_min <= 0) {
                    app.showError(__("Hiba"), __("Az időkorlátnak nullánál nagyobb számnak kell lennie!"));
                    return;
                }

                missingFields = missingFields.concat(await getMissingFields(OccupationDraftBlockCrud as any, lessonPlanBlock, ["occupation_draft_id"]));
            }

            if (missingFields.length) {
                app.showError(__("Sikertelen mentés"), __("Töltse ki az összes kötelező mezőt: {details}", { details: missingFields.join(", ") }));
                return;
            }

            if (this.state.gradeIds.length == 0) {
                app.showError(__("Hiba"), __("Legalább egy évfolyamot meg kell adni!"));
                return;
            }

            this.setState({ status: AsyncStatus.SAVING });

            const lp = this.state.lessonPlan;
            if (this.state.publicationConfirmed == "pub_confirm") {
                lp.consent_publication = "now";
            } else if (this.state.publicationConfirmed == "pub_not_confirm") {
                lp.consent_publication = null;
            } else {
                lp.consent_publication = undefined;
            }
            let toSave = { ...lp, ...EMPTY_TECHICAL };

            const lessonPlan = (await new OccupationDraftCrud(toSave).put()).record;

            if (this.state.comments) {
                await setDraftComment("occupation_draft_" + lessonPlan.id, this.state.comments);
            }

            await setKeywords(
                OccupationDraftCrud.TABLE_INFO_ID, lessonPlan.id!,
                this.state.keywords
            );

            await updateSubrecords<IOccupationDraftSubjectRecord>(
                this.state.subjects, this.state.subjectIds, occupationDraftSubjectCrudClassProxy, "occupation_draft_id", lessonPlan.id!, "subject_id"
            );
            await updateSubrecords<IOccupationDraftGradeRecord>(
                this.state.grades, this.state.gradeIds, occupationDraftGradeCrudClassProxy, "occupation_draft_id", lessonPlan.id!, "grade_id"
            );
            await updateSubrecords<IOccupationDraftSubprogramRecord>(
                this.state.subprograms, this.state.subprogramIds, occupationDraftSubprogramCrudClassProxy, "occupation_draft_id", lessonPlan.id!, "subprogram_id"
            );

            var index = 1;
            for (const lessonPlanBlock of this.state.lessonPlanBlocks) {

                if (lessonPlanBlock.id) {
                    lessonPlanBlock.occupation_draft_id = undefined;
                } else {
                    lessonPlanBlock.occupation_draft_id = lessonPlan.id;
                }

                lessonPlanBlock.no = index;
                index++;
                const newBlock = (await new OccupationDraftBlockCrud(lessonPlanBlock).put()).record;

                await updateSubrecords<IOccupationDraftBlockEduMethodRecord>(
                    lessonPlanBlock["eduMethods"] || [], lessonPlanBlock["eduMethodIds"] || [], occupationDraftBlockEduMethodCrudClassProxy, "occupation_draft_block_id", newBlock.id!, "edu_method_id"
                );
                await updateSubrecords<IOccupationDraftBlockEduWorkFormRecord>(
                    lessonPlanBlock["eduWorkFroms"] || [], lessonPlanBlock["eduWorkFromIds"] || [], occupationDraftBlockEduWorkFormCrudClassProxy, "occupation_draft_block_id", newBlock.id!, "edu_work_form_id"
                );


            }
            for (const deletedId of this.state.deletedBlocks) {
                await new OccupationDraftBlockCrud({ id: deletedId }).del();
            }

            await this.saveCategories(lessonPlan.id!);

            app.showSuccess(__("Óravázlat lementve"), __("Óravázlat lementve"));

            if (lessonPlan.id != this.props.id) {
                this.props.onSave(lessonPlan.id!);
            } else if (lessonPlan.id) {
                this.reloadAsync();
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }


        this.setState({
            status: AsyncStatus.LOADED
        })
    }

    private onChangeComment(propName: string, value: any) {
        this.setState({ isDataChanged: true, comments: { ...this.state.comments, [propName]: value } });
    }

    protected getExtraButtons() {
        if (!this.state.lessonPlan.id) return null;

        return <>
            <br />
            <DownloadAsPdf
                id={this.state.lessonPlan.id}
                type="occupation_draft"
                published={false}
            />
            {/*
                me && hasGroup(me, Groups.Developer) &&
                <DownloadAsPdf 
                    id={this.state.lessonPlan.id}
                    type="occupation_draft"
                    filenameForDebugging={"occupation_draft.html"}
                    published={false}
                />
            */}
        </>
    }

    changeConfirmation(e: any) {
        const target = e!.target;
        let value = target.value;
        this.setState({ publicationConfirmed: value, isDataChanged: true });
    }

    protected getConfirm() {
        return this.state.lessonPlan.library_id == LIBRARY_JTR_BEADANDO_ID ?
            <div className="row callout confirm-box">
                <div className="column">
                    <fieldset>
                        <label><p><input type="radio" checked={this.state.publicationConfirmed == "pub_confirm"} value="pub_confirm" name="confirm" onChange={this.changeConfirmation.bind(this)} disabled={!this.state.lessonPlan.owner_id || (me!.id == this.state.lessonPlan.owner_id && this.state.publicationConfirmed != "pub_confirm") ? false : true}></input> {__("Hozzájárulok, hogy az SZTR online felületére feltöltött, általam készített, az EFOP-3.1.216-2016-00001 azonosítószámú projekt keretében megbízott Szakmai Támogatás Munkacsoport szakemberei által kiválónak vélt óratervek a Komplex Alapprogram Tudástárába feltöltésre kerüljenek a nevem feltüntetése mellett. A feltöltés tényéről értesítést kapok.")} <strong>{__("(A hozzájárulást később nem lehet visszavonni!)")}</strong></p></label>
                        <label><p><input type="radio" checked={this.state.publicationConfirmed == "pub_not_confirm"} value="pub_not_confirm" name="confirm" onChange={this.changeConfirmation.bind(this)} disabled={!this.state.lessonPlan.owner_id || (me!.id == this.state.lessonPlan.owner_id && this.state.publicationConfirmed != "pub_confirm") ? false : true}></input>{__("Nem járulok hozzá.")}</p></label>
                    </fieldset>
                </div>

            </div> : <></>;
    }

    getFormBeforeBlocks() {
        return <div className="row">
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.authors}
                    onChange={e => this.onChangeComment("authors", e)}
                />
                <label>
                    {__("Szerző(k)")}*:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.authors || ""} onChange={e => this.onChange("authors", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.subprogram_id}
                    onChange={e => this.onChangeComment("subprogram_id", e)}
                />
                <label>
                    {__("Alprogram")}*:
                </label>
                <CrudSelect
                    crudClassProxy={subprogramCrudClassProxy}
                    value={this.state.lessonPlan.subprogram_id || null}
                    onSelect={(_, id) => this.onChange("subprogram_id", id)}
                    displayFieldName="title"
                />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.goal}
                    onChange={e => this.onChangeComment("goal", e)}
                />
                <label>
                    {__("A foglalkozás célja")}*:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.goal || ""} onChange={e => this.onChange("goal", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.gradeIds}
                    onChange={e => this.onChangeComment("gradeIds", e)}
                />
                <label>
                    {__("Évfolyam(ok)")}*:
                </label>
                <CrudMultiSelect
                    crudClassProxy={gradeCrudClassProxy}
                    displayFieldName="name"
                    orderByFieldName="id"
                    key="id"
                    values={this.state.gradeIds}
                    filter={{ is_active: true }}
                    onChange={values => this.setState({ gradeIds: values, isDataChanged: true })}
                    sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments.group_type_id}
                    onChange={e => this.onChangeComment("group_type_id", e)}
                />
                <label>
                    {__("Csoport / osztály típusa")}:
                </label>
                <CrudSelect
                    crudClassProxy={groupTypeCrudClassProxy}
                    value={this.state.lessonPlan.group_type_id || null}
                    onSelect={(_, id) => this.onChange("group_type_id", id)}
                    displayFieldName="title"
                    orderByFieldName="id"
                />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments.group_type_other}
                    onChange={e => this.onChangeComment("group_type_other", e)}
                />
                <label>
                    {__("Egyéb")}:
                </label>
                <input type="text" value={this.state.lessonPlan.group_type_other || ""} onChange={e => this.onChange("group_type_other", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.thematic_unit}
                    onChange={e => this.onChangeComment("thematic_unit", e)}
                />
                <label>
                    {__("Tematikus egység")}*:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.thematic_unit || ""} onChange={e => this.onChange("thematic_unit", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.topic}
                    onChange={e => this.onChangeComment("topic", e)}
                />
                <label>
                    {__("A foglalkozás témája")}*:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.topic || ""} onChange={e => this.onChange("topic", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.subjectIds}
                    onChange={e => this.onChangeComment("subjectIds", e)}
                />
                <label>
                    {__("Tantárgyi kapcsolat")}:
                </label>
                <CrudMultiSelect
                    crudClassProxy={subjectCrudClassProxy}
                    displayFieldName="name"
                    orderByFieldName="name"
                    values={this.state.subjectIds}
                    filter={{ is_active: true }}
                    onChange={values => this.setState({ subjectIds: values, isDataChanged: true })}
                />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.subprogramIds}
                    onChange={e => this.onChangeComment("subprogramIds", e)}
                />
                <label>
                    {__("További alprogrami kapcsolat")}:
                </label>
                <CrudMultiSelect
                    crudClassProxy={subprogramCrudClassProxy}
                    displayFieldName="title"
                    orderByFieldName="title"
                    values={this.state.subprogramIds}
                    filter={{ is_active: true }}
                    onChange={values => this.setState({ isDataChanged: true, subprogramIds: values })}
                />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.used_sources}
                    onChange={e => this.onChangeComment("used_sources", e)}
                />
                <label>
                    {__("Felhasznált források")}:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.used_sources || ""} onChange={e => this.onChange("used_sources", e.target.value)} />
            </div>
        </div>
            ;
    }

    getFormAfterBlocks() {
        return <div className="row">
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.html_body}
                    onChange={e => this.onChangeComment("html_body", e)}
                />
                <label>
                    {__("Mellékletek")}:
                </label>
                <HTMLTextarea
                    key={this.state.lessonPlan.id || ""}
                    value={this.state.lessonPlan.html_body || ""}
                    onChange={(text) => this.onChange("html_body", text)}
                    onAddImage={this.state.lessonPlan.id ? this.onAddImage.bind(this) : undefined}
                />
                <br />
                {(this.state.occupationDraftFiles && this.state.occupationDraftFiles.length > 0) ? this.state.occupationDraftFiles.map((file: IOoFileRecord, index: number) => {
                    return <div key={index} className="small-12 large-12 column">
                        {
                            <a href={"/api/media/file/" + file.sha1} target="_blank" download={file.title}>
                                <i className="fa fa-download" /> &nbsp;
                            {file.title ? file.title : file.displayname}
                            </a>
                        }
                    </div>
                })
                    : ""
                }
            </div>
        </div>;
    }

    protected getExtraForm() {
        return null;
    }

    private onChangeBlock(index: number, propName: string, value: any) {
        const block = this.state.lessonPlanBlocks[index];
        block[propName] = value;
        this.setState({
            lessonPlanBlocks: this.state.lessonPlanBlocks, isDataChanged: true
        });
    }

    getBlockForm(block: IOccupationDraftBlockRecord, index: number) {
        const eduMethodIds = block["eduMethodIds"] || [];
        const eduWorkFromIds = block["eduWorkFromIds"] || [];

        return <div className="row">
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments["block_teacher_activity_" + index]}
                    onChange={e => this.onChangeComment("block_teacher_activity_" + index, e)}
                />
                <label>
                    {__("A pedagógus tevékenysége")}*:
                </label>
                <textarea rows={2} value={block.teacher_activity || ""} onChange={e => this.onChangeBlock(index, "teacher_activity", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments["block_student_activity_" + index]}
                    onChange={e => this.onChangeComment("block_student_activity_" + index, e)}
                />
                <label>
                    {__("A tanulók tevékenysége")}*:
                </label>
                <textarea rows={2} value={block.student_activity || ""} onChange={e => this.onChangeBlock(index, "student_activity", e.target.value)} />
            </div>

            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments["block_eduMethodIds_" + index]}
                    onChange={e => this.onChangeComment("block_eduMethodIds_" + index, e)}
                />
                <label>
                    {__("Módszer")}:
                </label>
                <CrudMultiSelect
                    crudClassProxy={eduMethodCrudClassProxy}
                    displayFieldName="title"
                    orderByFieldName="title"
                    values={eduMethodIds}
                    filter={{ is_active: true }}
                    onChange={values => this.onChangeBlock(index, "eduMethodIds", values)} />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments["block_dfht_method_other_" + index]}
                    onChange={e => this.onChangeComment("block_dfht_method_other_" + index, e)}
                />
                <label>
                    {__("Egyéb módszer")}:
                </label>
                <input type="text" value={block.dfht_method_other || ""} onChange={e => this.onChangeBlock(index, "dfht_method_other", e.target.value)} />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments["block_eduWorkFromIds_" + index]}
                    onChange={e => this.onChangeComment("block_eduWorkFromIds_" + index, e)}
                />
                <label>
                    {__("Munkaforma")}:
                </label>
                <CrudMultiSelect
                    crudClassProxy={eduWorkFormCrudClassProxy}
                    displayFieldName="title"
                    orderByFieldName="title"
                    values={eduWorkFromIds}
                    filter={{ is_active: true }}
                    onChange={values => this.onChangeBlock(index, "eduWorkFromIds", values)} />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments["block_dfht_work_form_other_" + index]}
                    onChange={e => this.onChangeComment("block_dfht_work_form_other_" + index, e)}
                />
                <label>
                    {__("Egyéb munkaforma")}:
                </label>
                <input type="text" value={block.dfht_work_form_other || ""} onChange={e => this.onChangeBlock(index, "dfht_work_form_other", e.target.value)} />
            </div>

            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments["block_tools_" + index]}
                    onChange={e => this.onChangeComment("block_tools_" + index, e)}
                />
                <label>
                    {__("Eszköz")}:
                </label>
                <textarea rows={2} value={block.tools || ""} onChange={e => this.onChangeBlock(index, "tools", e.target.value)} />
            </div>
        </div>;
    }

}
