import * as React from 'react';
import { Link } from 'react-router-dom';
import { PATH_PUBLISHED_BOOK_SELECT, PATH_PUBLISHED_BOOK } from '@src/Routes';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import PubBookCrud from '@src/framework/crud/doc_pub/BookCrud';
import GradeCrud, { IGradeRecord } from '@src/framework/crud/doc/GradeCrud';
import SubjectCrud, { ISubjectRecord } from '@src/framework/crud/doc/SubjectCrud';
import { showUnpublished } from '@src/component/book/viewer/PublishedSelector';
import BookSelectorSubjectIcon from '@src/component/book/viewer/BookSelectorSubjectIcon';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import { hasAnyGroup, me, Groups } from '@src/framework/server/Auth';
import { __ } from '@src/translation';
import Checkbox from '@src/framework/forms/checkbox';
import ViewBook, { IViewBookRecord } from '@src/framework/view/doc_pub/ViewBook';
import InstituteTypeCrud, { IInstituteTypeRecord } from '@src/framework/crud/sys/InstituteTypeCrud';
import { PRIMARY_SCHOOL_GRADE_IDS, SECONDARY_SCHOOL_GRADE_IDS } from '@src/Const';

const GRADE_DEFAULT_SELECTED_ID = 7200;
const SUBJECT_JO_GYAKORLATOK_ID = 180366800;

var grades: IGradeRecord[];
var sortedGrades: IGradeRecord[];
var filteredGrades: IGradeRecord[] = [];
var books: IBookRecord[];
var subjects: ISubjectRecord[];
var bookViewList: IViewBookRecord[];
var instituteTypes: IInstituteTypeRecord[] = [];

type BookSelectorState = {
    loading: boolean;
    gradeId: number;
    instituteId?: number;
    instituteType?: string;
    isSNI: boolean;
}

export class BookSelector extends React.Component<{}, BookSelectorState> {

    constructor(props: any) {
        super(props);

        if (books) {
            this.state = { loading: false, gradeId: GRADE_DEFAULT_SELECTED_ID, isSNI: false };
        } else {
            this.state = { loading: true, gradeId: GRADE_DEFAULT_SELECTED_ID, isSNI: false };
            this.reloadAsync();
        }
    }
    async componentDidMount() {

    }

    async reloadAsync() {
        grades = await GradeCrud.list({ filter: { is_active: true, is_low_grade: false } });
        sortedGrades = grades.sort((a: IGradeRecord, b: IGradeRecord) => a.name!.localeCompare(b.name!, undefined, { numeric: true, sensitivity: 'base' }));
        subjects = await SubjectCrud.list({ filter: { is_active: true }, order_by: "name" });
        if (showUnpublished()) {
            books = await BookCrud.list({ filter: { is_active: true, library_id: LIBRARY_OFI_OFFICIAL_ID } });
        } else {
            books = await PubBookCrud.list({ filter: { library_id: LIBRARY_OFI_OFFICIAL_ID } });
        }

        bookViewList = await ViewBook.list({ columns: ["subject_id", "grade_id", "institute_type_id", "is_sni"], distinct: true });

        instituteTypes = await InstituteTypeCrud.list({ order_by: "title" });

        this.setState({
            loading: false
        });
    }

    selectClass(gradeId: number) {
        this.setState({ gradeId: gradeId });
    }

    selectInstitute(id: number) {
        const instituteId = id;
        const firstGradeId = instituteId ? this.bookList.filter(b => b.institute_type_id === instituteId)[0].grade_id! : GRADE_DEFAULT_SELECTED_ID;
        this.setState({ instituteId: instituteId, gradeId: firstGradeId });
    }

    bookList: IViewBookRecord[];

    selectPrimarySchool() {
        filteredGrades = sortedGrades.filter((g) => { return PRIMARY_SCHOOL_GRADE_IDS.includes(g.id!) });
        let gradeId = this.state.gradeId;
        if (!filteredGrades.find(g => g.id === gradeId) && filteredGrades.length > 0 && filteredGrades[0].id) {
            gradeId = filteredGrades[0].id;
        }
        this.setState({ isSNI: false, instituteType: "primary", gradeId });
    }

    selectSecondarySchool() {
        filteredGrades = sortedGrades.filter((g) => { return SECONDARY_SCHOOL_GRADE_IDS.includes(g.id!) });
        let gradeId = this.state.gradeId;
        if (!filteredGrades.find(g => g.id === gradeId) && filteredGrades.length > 0 && filteredGrades[0].id) {
            gradeId = filteredGrades[0].id;
        }
        this.setState({ isSNI: false, instituteType: "secondary", gradeId });
    }

    selectSNI() {
        filteredGrades = [];
        let gradeId = this.state.gradeId;
        const sniBooks = bookViewList.filter(book => book.is_sni).sort((a, b) => a.grade_id! - b.grade_id!);
        if (!sniBooks.find(b => b.grade_id === gradeId) && sniBooks.length > 0 && sniBooks[0].grade_id) {
            gradeId = sniBooks[0].grade_id!;
        }
        this.setState({ isSNI: true, instituteType: "sni", gradeId });
    }

    render() {
        if (!books || !bookViewList) return null;

        this.bookList = this.state.isSNI ? bookViewList.filter(book => book.is_sni) : bookViewList.filter(book => !book.is_sni);


        return <>
            <div className="choose-book">

                <div className="class-row">
                    <div className="row type-chooser">
                        <div className="column large-12">
                            <div className="button-group no-gaps expanded">
                                <button className={"button " + (this.state.instituteType == "primary" ? "select" : "")} key="primaryschool" value="primary" onClick={this.selectPrimarySchool.bind(this)}>{__("Általános iskola")}</button>

                                <button className={"button " + (this.state.instituteType == "secondary" ? "select" : "")} key="secondaryschool" value="secondary" onClick={this.selectSecondarySchool.bind(this)}>{__("Középiskolák")}</button>

                                <button className={"button " + (this.state.instituteType == "sni" ? "select" : "")} key="sni" value="sni" onClick={this.selectSNI.bind(this)}>{__("SNI")}</button>

                            </div>
                        </div>
                    </div>
                    <div className="grade-container table-scroll">
                        <div className="row">

                            <span className="column shrink">{__("Évfolyam")}:</span>
                            <div ref="classSelectContainer" className="column class-select-container">
                                <ul ref="classSelect" className="class-select">
                                    {
                                        sortedGrades.map(grade => {
                                            let disabled = false;

                                            if (this.state.isSNI && !this.bookList.find(b => b.is_sni === this.state.isSNI && b.grade_id === grade.id)) {
                                                disabled = true;
                                            }
                                            if (filteredGrades.length > 0 && !this.bookList.find(b => (filteredGrades.find(g => b.grade_id === g.id)) && b.grade_id === grade.id)) {
                                                disabled = true;
                                            }
                                            return <li key={grade.id}>
                                                {
                                                    disabled
                                                        ?
                                                        <a href="#" className="disabled" style={{ opacity: 0.5 }}>
                                                            {grade.name!.split(" ")[0]}
                                                        </a>
                                                        :
                                                        <a href="#" className={this.state.gradeId == grade.id ? "select" : ""} onClick={this.selectClass.bind(this, grade.id)}
                                                             data-class={grade.id}>
                                                            {grade.name!.split(" ")[0]}
                                                        </a>
                                                }

                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="subject-select">

                    {

                        subjects.map(subject => {

                            if (subject.id == SUBJECT_JO_GYAKORLATOK_ID) {
                                return null;
                            }

                            if (this.bookList.find(b => b.subject_id === subject.id && (this.state.isSNI ? b.is_sni === this.state.isSNI : b.is_sni === false))) {

                                var show = true;
                                if (this.state.gradeId) {
                                    show = (books.filter(b => b.grade_id == this.state.gradeId && b.subject_id == subject.id && (this.state.isSNI ? b.is_sni === this.state.isSNI : b.is_sni === false) ).length > 0);
                                }
                                if (show && this.state.instituteId) {
                                    show = (books.filter(b => b.institute_type_id == this.state.instituteId && b.subject_id == subject.id && (this.state.isSNI ? b.is_sni === this.state.isSNI : b.is_sni === false)).length > 0);
                                }

                                if (subject.name == __("Anyanyelv és kommunikáció") || subject.name == __("Környezetismeret") || subject.name == __("Jó gyakorlatok, innovációk")) {
                                    show = false;
                                }

                                if (show) {
                                    return <div key={subject.id} className="item text-center">
                                        <Subject isSNI={this.state.isSNI} books={books} instituteId={this.state.instituteId} gradeId={this.state.gradeId} key={subject.id} sizePx={0} subject={subject} extension=".svg" />
                                    </div>
                                }
                                else {
                                    return null;
                                }

                            } else {
                                return null;
                            }
                        })
                    }

                </div>
            </div>
        </>
    }
}

export class Subject extends React.Component<{ books: IBookRecord[], subject: any, gradeId: number, instituteId?: number, isSNI?: boolean, sizePx: number, extension: ".svg" | ".png" }, any> {

    async reloadAsync() {
        grades = await GradeCrud.list({ filter: { is_active: true } });
    }

    subBookClick(e: any) {
        e.stopPropagation();
        this.openSubBooks();
    }
    openSubBooks() {
        (this.refs.subBook as HTMLElement).classList.add('show');
        (this.refs.bookWrapper as HTMLElement).classList.add('show');
    }
    closeSubBooks(e: any) {
        e.stopPropagation();
        (this.refs.subBook as HTMLElement).classList.remove('show');
        (this.refs.bookWrapper as HTMLElement).classList.remove('show');
    }
    onSubjectBlur(e: any) {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            this.closeSubBooks(e);
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyDown.bind(this), true);
    }
    keyDown(e: any) {

        if ((e.key == 'Escape' || e.key == 'Esc' || e.keyCode == 27)) {
            e.preventDefault();
            this.closeSubBooks(e);
        }
    }

    render() {

        var url = PATH_PUBLISHED_BOOK_SELECT + "/" + this.props.subject.uri_segment;

        var subBook = null;

        var isSNI = this.props.isSNI;

        if (this.props.gradeId) {
            if (this.props.books) {
                books = this.props.books;
            }
            const filteredBooks = this.props.instituteId
                ? books.filter(b => b.subject_id == this.props.subject.id && b.grade_id == this.props.gradeId && b.institute_type_id == this.props.instituteId && (isSNI ? b.is_sni === isSNI : b.is_sni === false))
                : books.filter(b => b.subject_id == this.props.subject.id && b.grade_id == this.props.gradeId && (isSNI ? b.is_sni === isSNI : b.is_sni === false));

            if (filteredBooks.length == 1) {
                url = PATH_PUBLISHED_BOOK + "/" + filteredBooks[0].uri_segment + "/";

                if (!grades) {
                    this.reloadAsync();
                }
                const grade = grades.filter(g => g.id == filteredBooks[0].grade_id)[0];

                if (grade.is_low_grade) {
                    url = "/alsostankonyv/" + filteredBooks[0].uri_segment;
                }

                if (filteredBooks[0].is_sni) {
                    isSNI = true;
                } else isSNI = false;

                subBook = null;
            }
            else {
                subBook = filteredBooks.map(book => {
                    return <Link key={book.id} to={PATH_PUBLISHED_BOOK + "/" + book.uri_segment!}>
                        <div>
                            <BookSelectorSubjectIcon sizePx={this.props.sizePx} uriSegment={this.props.subject.uri_segment || ""} extension={this.props.extension} aria-hidden="true" needAnimation={true} />
                        </div>
                        <span className={"subject-name"}>{book.name}</span>
                    </Link>
                });
            }
        }

        return <div>
            {
                subBook
                    ?
                    <>
                        <a href="#" onFocus={this.openSubBooks.bind(this)} onBlur={this.onSubjectBlur.bind(this)} onClick={this.openSubBooks.bind(this)} >
                            <div style={{ position: "relative", cursor: "pointer" }} >
                                <BookSelectorSubjectIcon sizePx={this.props.sizePx} uriSegment={this.props.subject.uri_segment || ""} extension={this.props.extension} needAnimation={true} />
                                <span className="sub-count" aria-hidden="true">{subBook.length}</span>
                            </div>
                            <span className={"subject-name"}>{this.props.subject.name}</span>
                            <div ref="bookWrapper" className="book-wrapper" onClick={this.closeSubBooks.bind(this)}>
                                <div ref="subBook" className="sub-book">{subBook}</div>
                            </div>
                        </a>
                    </>
                    :
                    <Link title={this.props.subject.name} to={url}>
                        <div>
                            <BookSelectorSubjectIcon sizePx={this.props.sizePx} uriSegment={isSNI ? (this.props.subject.uri_segment + "_sni") : this.props.subject.uri_segment} extension={this.props.extension} needAnimation={true} />
                        </div>
                        <span className={"subject-name"}>{this.props.subject.name}</span>
                    </Link>
            }
        </div>

    }
}
