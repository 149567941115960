import * as React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import ClaimCrud, { IClaimRecord, ClaimCrudClassProxy, claimCrudClassProxy } from '@src/framework/crud/ticket/ClaimCrud';
import RecordEditor, { IFieldEditorProp } from '@framework/forms/recordeditor';

import WfPlugin from '@component/wf/wf_plugin';
import MediaFileChooser, { ContentActionType } from '@component/filemanager/MediaFileChooser';

import InplaceListEditor from '@framework/forms/inplace_listeditor';

import ClaimLinkListView from '@component/ticket/claim_link_listview';
import ClaimLinkEditor from '@component/ticket/claim_link_editor';
import { __ } from '@src/translation';
export default class ClaimEditor extends RecordEditor<IClaimRecord> {

    public getCrudClassProxy(): ClaimCrudClassProxy { return claimCrudClassProxy; }


    protected getFieldEditor(fProps: IFieldEditorProp): JSX.Element | null {
        if (fProps.fieldName == 'oo_folder_id')
            return null;
        return super.getFieldEditor(fProps);
    }

    private onGetClaimLinkListView = (inplaceListEditor: InplaceListEditor) => {
        return <ClaimLinkListView
            inplaceListEditor={inplaceListEditor}
            match={this.props.match}
            filter={{ claim_id: this.state.rec_id! }}
            viewColumns={[
                { accessor: "id", maxWidth: 100 },
                {
                    accessor: "url",
                    Cell: ((data: any, column: any) => {
                        const url = data.original.url;
                        return <a href={url} target="_new">{url}</a>
                    })
                },
                { accessor: "description" },
                { accessor: "creation_time" },
                { accessor: "creator" },
                { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
            ]}
        />
    }

    private onGetClaimLinkEditor = (inplaceListEditor: InplaceListEditor, record_id: number | null) => {
        return <ClaimLinkEditor
            match={this.props.match}
            record_id={record_id}
            defaultValues={{ claim_id: this.state.rec_id! }}
            inplaceListEditor={inplaceListEditor}
        />;
    }

    protected getDetailPanels(): any[] {
        if (this.state.rec && this.state.rec.oo_folder_id) {
            return [
                <Tabs key="tabs">
                    <TabList>
                        <Tab>{__("Folyamat")}</Tab>
                        <Tab>{__("Csatolt állományok")}</Tab>
                        <Tab>{__("Linkek")}</Tab>
                    </TabList>
                    <TabPanel>
                        <WfPlugin
                            tableInfoId={ClaimCrud.TABLE_INFO_ID}
                            recId={this.state.rec_id!}
                        />
                    </TabPanel>
                    <TabPanel>
                        <MediaFileChooser
                            rootFolderId={this.state.rec!.oo_folder_id!}
                            currentFolderId={this.state.rec!.oo_folder_id!}
                            showHoverPreview={false}
                            showLocation={false}
                            showFilter={false}
                            enabledActions={[ContentActionType.FILE_UPLOAD]}
                        />
                    </TabPanel>
                    <TabPanel>
                        <div className="row">
                            <div className="column">
                                <InplaceListEditor
                                    onGetListView={this.onGetClaimLinkListView}
                                    onGetRecordEditor={this.onGetClaimLinkEditor}
                                />
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            ];
        } else {
            return [
                <div className="row">
                    <div className="column">
                        <div className="callout warning">
                            <b>{__("Folyamat, File kezelő, Linkek:")}</b>
                            {__("A részlet adatok megadása előtt rögzítse le a fő tételt!")}
                        </div>
                    </div>
                </div>
            ];
        }
    }

}