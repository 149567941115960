import * as React from 'react';
import { match } from 'react-router';
import ViewOoFileInBook, { IViewOoFileInBookRecord } from '@src/framework/view/doc/ViewOoFileInBook';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import { Link } from 'react-router-dom';
import { PATH_CONTENT_STORE, PATH_PUBLISHED_BOOK } from '@src/Routes';
import { app } from '@src/index';
import ViewDocSectionFileRef, { IViewDocSectionFileRefRecord } from '@src/framework/view/doc/ViewDocSectionFileRef';
import { BubbleLoader } from 'react-css-loaders';
import { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import { OriginTable } from './OFIOriginTablePage';
import OriginCrud, { IOriginRecord } from '@src/framework/crud/media/OriginCrud';
import { __ } from '@src/translation';
import { MetaKeywordAssignerPlugin, fetchKeywords, setKeywords } from '@src/component/meta/meta_keyword_assigner_plugin';
import { IMediaTypeRecord, mediaTypeCrudClassProxy } from '@src/framework/crud/media/MediaTypeCrud';
import CrudSelectComponent from '@src/framework/forms/crudselect';

type OFIEmbeddedListPageProps = {
    match: match<{ bookId: string, index?: string }>;
}

type OFIEmbeddedListPageState = {
    books: IBookRecord[];
    files: IViewOoFileInBookRecord[];
}
let mediaTypes: IMediaTypeRecord[] | null = null;
export class OFIEmbeddedListPage extends React.Component<OFIEmbeddedListPageProps, OFIEmbeddedListPageState> {

    constructor(props: OFIEmbeddedListPageProps) {
        super(props);

        this.state = {
            books: [],
            files: [],
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {

        try {
            const books = await BookCrud.list({ filter: { is_active: true, library_id: LIBRARY_OFI_OFFICIAL_ID }, order_by: "name" });

            const files = await ViewOoFileInBook.list({ filter: { is_active: true, ext: ".html" }, order_by: [{ name: "book_name" }, { name: "id" }] });

            this.setState({
                books,
                files,
            })
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (this.state.books.length == 0) {
            return <BubbleLoader />;
        }

        if (this.props.match.params.bookId) {
            return this.renderIframes();
        } else {
            return this.renderList();
        }
    }

    private renderList() {
        return <div className="row expanded">
            <div className="column small-12">
                <table>
                    <thead>
                        <tr>
                            <th>{__("Tankönyv")}</th>
                            <th>{__("Beágyazott SCORM csomagok száma")}</th>
                            <th>{__("Összes mutatása egy lapon")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.books.map((b, i) => {
                                const count = this.state.files.filter(f => f.book_id == b.id).length;
                                if (count == 0) return;

                                return <tr key={i}>
                                    <td>
                                        <Link to={PATH_CONTENT_STORE + "/beagyazott/" + b.id + "/0"}>{b.name}</Link>
                                    </td>
                                    <td>
                                        <Link to={PATH_CONTENT_STORE + "/beagyazott/" + b.id + "/0"}>
                                            {count} db.
                                        </Link>
                                    </td>

                                    <td>
                                        <Link to={PATH_CONTENT_STORE + "/beagyazott/" + b.id}>
                                            {__("Összes")}
                                        </Link>
                                    </td>
                                </tr>
                            })
                        }
                        <tr>
                            <td>{__("Összes mutatása egy lapon")}</td>
                            <td>{this.state.files.length} db.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }



    private renderIframes() {

        const book = this.state.books.find(f => f.id == Number(this.props.match.params.bookId));
        var files = this.state.files.filter(f => f.book_id == Number(this.props.match.params.bookId));

        var numbers: JSX.Element[] = [];
        for (var i = 0; i < files.length; i++) {
            numbers.push(
                <Link
                    style={{ marginRight: "1em" }}
                    key={i}
                    to={PATH_CONTENT_STORE + "/beagyazott/" + this.props.match.params.bookId + "/" + i}
                    className={"button " + (this.props.match.params.index == "" + i ? " primary" : "secondary")}>
                    {i + 1}
                </Link>
            );
        }

        if (this.props.match.params.index !== undefined) {
            files = [files[Number(this.props.match.params.index)]];
        }

        return <div className="row expanded">
            <h4>{book && book.name}</h4>
            <div className="column small-12">
                {numbers}
            </div>
            {
                files.map((f, i) => {
                    return <div key={f.id} className="column small-12">
                        <br />

                        #{f.id} {f.relpath} <br />
                        <LinksToSection ooFileId={f.id!} bookId={f.book_id!} />
                        <div className="flex-video widescreen">
                            <iframe
                                src={"/api/media/relpath/" + f.relpath}
                                width="100%"
                                height="100%"
                            />
                        </div>
                        <ScormSetter 
                            file={f}
                        />
                        <br />
                        <RecursiveOriginTable
                            rootFolderId={f.oo_folder_id!}
                        />
                         <div>
                
            </div>
                    </div>
                })
            }
           
        </div>;
    }
}

type ScormSetterState = {
    keywords: string[];
    mediaTypeId: number | null;
}

class ScormSetter extends React.Component<{file: IViewOoFileInBookRecord}, ScormSetterState> {
    
    constructor(props: {file: IViewOoFileInBookRecord}) {
        super(props);

        this.state = {
            keywords: [],
            mediaTypeId: this.props.file.media_type_id || null,
        }
    }

    async componentDidMount() {
        await this.loadKeywords();
    }

    private onAppendKeyword = async (keywords: string[]) => {
        this.setState({ keywords: this.state.keywords!.concat(keywords) });
    }

    private onDeleteKeyword = async (index: number) => {
        this.state.keywords.splice(index, 1);
        this.setState({ keywords: this.state.keywords });
    }
    private loadKeywords = async () => {
        try {
            if (this.props.file.id) {
                const keywords = await fetchKeywords(OoFileCrud.TABLE_INFO_ID, this.props.file.id);
                this.setState({ keywords });
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }
    private onMediaTypeChange(mediaTypeId: number) {
        this.setState({
            mediaTypeId
        });
    }
    private async onSave()
    {
        if (this.props.file.id) {
            try {
            await new OoFileCrud({
                id: this.props.file.id,
                media_type_id: this.state.mediaTypeId
            }).put();
    
            await setKeywords(OoFileCrud.TABLE_INFO_ID, this.props.file.id, this.state.keywords);
            app.showSuccess(__("Változtatások mentése"), "Sikeres");
        }
        catch(error)
        {
            app.showErrorFromJsonResult(error);
        }
        }
    }

    render() {
            return <>
                <div className="large-6 columns">
                        <label>
                            {__("Fájl típusa")}
                            <CrudSelectComponent 
                                crudClassProxy={mediaTypeCrudClassProxy}
                                displayFieldName="title"
                                orderByFieldName="title"
                                value={this.state.mediaTypeId}
                                onSelect={(source, mediaTypeId) => this.onMediaTypeChange(Number(mediaTypeId))}
                            />
                        </label>
                    </div>
                    <br/>
                    <div className="large-6 columns">
                        <label>{__("Kulcsszavak")} *</label>
                        <MetaKeywordAssignerPlugin
                            keywords={this.state.keywords}
                            onAppendKeywords={this.onAppendKeyword}
                            onDeleteKeyword={this.onDeleteKeyword}
                        />
                    </div>
                    <div className="large-12 small-12 columns">
                        <a onClick={this.onSave.bind(this)} className="button success filemamanger-buttonPadder"><i className="fa fa-save" /> &nbsp;{__("Mentés")}</a>
                    </div>
            </>
    }
}

class LinksToSection extends React.Component<{ ooFileId: number, bookId: number }, { files: IViewDocSectionFileRefRecord[] }> {
    constructor(props: any) {
        super(props);

        this.state = {
            files: []
        }
    }
    async componentDidMount() {
        const files = await ViewDocSectionFileRef.list({
            filter: { oo_file_id: this.props.ooFileId, book_id: this.props.bookId }
        });
        this.setState({ files });
    }

    render() {
        return <div>
            {
                this.state.files.map((f, i) => {
                    return <Link key={i} to={PATH_PUBLISHED_BOOK + "/" + f.book_uri_segment + "/" + f.lesson_uri_segment}>{f.book_name + " / " + f.chapter_name + " / " + f.lesson_name}</Link>
                })
            }
        </div>
    }
}

type RecursiveOriginTableProps = {
    rootFolderId: number
}

type RecursiveOriginTableState = {
    origins: IOriginRecord[];
    files: IOoFileRecord[];
}

class RecursiveOriginTable extends React.Component<RecursiveOriginTableProps, RecursiveOriginTableState> {

    state: RecursiveOriginTableState = {
        origins: [],
        files: []
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: RecursiveOriginTableProps) {
        if (prevProps.rootFolderId !== this.props.rootFolderId) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {
        const origins = await OriginCrud.list({ filter: { is_active: true }, order_by: "title" });

        this.setState({
            origins,
            files: await this.loadFiles(this.props.rootFolderId)
        });
    }

    private async loadFiles(folderId: number) {
        var files = (await OoFileCrud.list({ filter: { is_active: true, oo_folder_id: folderId } }))
            .filter(s => s.ext && [".jpg", ".png", ".svg"].includes(s.ext.toLowerCase()));

        const subFolders = await OoFolderCrud.list({ filter: { is_active: true, parent_id: folderId } });

        for (const subFolder of subFolders) {
            files = files.concat(await this.loadFiles(subFolder.id!));
        }

        return files;
    }

    render() {
        return <>
            <h5>{__("A SCORM csomagban használt képek")}</h5>
            <OriginTable
                files={this.state.files}
                origins={this.state.origins}
                onReload={() => { }}
            />
        </>
            ;
    }
}
