import * as React from 'react';

import { IViewSecTransitionPermRecord, ViewSecTransitionPermClassProxy, viewSecTransitionPermClassProxy } from '@src/framework/view/wf/ViewSecTransitionPerm';
import ListView from '@framework/forms/listview';
import { app } from '@src/index';

import LookupEdit from '@framework/forms/lookupedit';
import SecPermOwnerCrud from '@src/framework/crud/sys/SecPermOwnerCrud';
import SecTransitionPermCrud from '@src/framework/crud/wf/SecTransitionPermCrud';
import { HeaderNotificationStore } from '../HeaderNotification';
import { __ } from '@src/translation';


class SecTransitionPermInner extends ListView<IViewSecTransitionPermRecord> {
    public getViewClassProxy(): ViewSecTransitionPermClassProxy { return viewSecTransitionPermClassProxy; }
    protected getFilterControls(): JSX.Element | null { return null; }

    private onSetIsTask = async(recordId: number, isTask: boolean) => {
        try {
            await new SecTransitionPermCrud({id:recordId, is_task:isTask}).put();
            // Lehet, hogy ez hatássan van a saját feladat listára...
            HeaderNotificationStore.changed();
            this.asyncReload();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    protected getToggleTaskButton(record: IViewSecTransitionPermRecord): JSX.Element | null {
        const isTask: boolean = record.is_task!;
        let title;
        let cls = "listview-button";
        let iconClass = "fa";
        if (isTask) {
            title = __('Ez egy feladat, megjelenik a tennivalók listában. Ha nem szeretné, hogy feladat legyen, nyomjon rá erre a gombra.');
            // cls += " listview-button-warning";
            iconClass += " fa-eye";
        } else {
            title = __('Ez nem egy feladat, nem jelenik meg a tennivalók listában. Ha azt szeretné, hogy feladat legyen, nyomjon rá erre a gombra.');
            // cls += " listview-button-alert";
            iconClass += " fa-eye-slash";
        }
        const rec_id = record.id!;
        return (
            <button
                key="toggleTask"
                className={cls} title={title}
                onClick={() => this.onSetIsTask(rec_id, !isTask) }
            ><i className={iconClass} /></button>);
    }


    protected getRecordButtons(record: IViewSecTransitionPermRecord): JSX.Element[] {
        return [
            this.getToggleTaskButton(record)!,
            this.getDeleteUndeleteButton(record)!
        ];
    }
}

export interface ISecTransitionPermListViewForTransitionTypeProps {
    transition_type_id: number;
}

export interface ISecTransitionPermListViewForTransitionTypeState {
    permOwnerIdToAdd: number | null;
    isTask : boolean;
    refreshKey: number
}

export class SecTransitionPermListViewForTransitionType extends React.Component<ISecTransitionPermListViewForTransitionTypeProps, ISecTransitionPermListViewForTransitionTypeState> {

    constructor(props: ISecTransitionPermListViewForTransitionTypeProps) {
        super(props);
        this.state = { permOwnerIdToAdd: null, refreshKey: 0, isTask :true };
    }

    private onPermOwnerIdChange = (newValue: any) => {
        if ((typeof newValue === 'string'))
            if (newValue.trim() === '')
                newValue = '';
        if (newValue === '')
            newValue = null;
        let newState = {
            permOwnerIdToAdd: newValue
        };
        this.setState(newState);
    }

    private onAddPermOwner = () => {
        new SecTransitionPermCrud({
            transition_type_id: this.props.transition_type_id,
            sec_perm_owner_id: this.state.permOwnerIdToAdd!,
            is_task: this.state.isTask
        }).put()
            .then((crud) => {
                app.showSuccess(__("Sikeres módosítás"), __("Jogosultság kiosztva."));
                this.setState({
                    permOwnerIdToAdd: null, refreshKey: this.state.refreshKey + 1
                }, this.forceUpdate)
            })
            .catch((error) => { app.showErrorFromJsonResult(error) })
    }


    public render() {
        const value: any = this.state.permOwnerIdToAdd;
        return (
            <div>
                <div className="row">
                    <div className="column small-7 medium-7">
                        <LookupEdit
                            key={"permOwnerIdToAdd_" + (this.state.permOwnerIdToAdd || 'null')}
                            name="permOwnerIdToAdd"
                            fk_table_info_id={SecPermOwnerCrud.TABLE_INFO_ID}
                            clearable={true}
                            value={value}
                            onChange={(newValue) => this.onPermOwnerIdChange(newValue)}
                        />
                    </div>
                    <div className="column small-3 medium-3">
                        <label 
                            title={__("Ha a jogot feladatként kapja, akkor megjelenik a tennivaló listájában.")}
                        >
                            <input type="checkbox" checked={this.state.isTask}
                                onChange={() => {
                                    this.setState({isTask: !this.state.isTask})
                                }}
                            />
                            {__("Feladat")}
                        </label>
                    </div>
                    <div className="column small-2 medium-2">
                        <button type="button" className="button primary"
                            disabled={!this.state.permOwnerIdToAdd}
                            onClick={this.onAddPermOwner}
                        >
                            <i className="fa fa-plus" />
                            { __("Hozzáad") }
                    </button>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 medium-12">
                        <SecTransitionPermInner
                            key={this.state.refreshKey}
                            allowInsertNew={false}
                            defaultPageSize={10}
                            filter={{ transition_type_id: this.props.transition_type_id }}
                            viewColumns={[
                                { accessor: "id", maxWidth: 100 },
                                { accessor: "perm_owner_label" },
                                /*
                                {accessor: "creation_time"},
                                {accessor: "creator"},
                                {accessor: "modification_time"},
                                {accessor: "modifier"},
                                */
                                { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
                            ]} />
                    </div>
                </div>
            </div>);
    }

}

