
import { IClaimLinkRecord, ClaimLinkCrudClassProxy, claimLinkCrudClassProxy } from '@src/framework/crud/ticket/ClaimLinkCrud';
import RecordEditor, { IFieldEditorProp } from '@framework/forms/recordeditor';
export default class ClaimLinkEditor extends RecordEditor<IClaimLinkRecord> {

    public getCrudClassProxy() : ClaimLinkCrudClassProxy { return claimLinkCrudClassProxy;  }  

    protected getFieldEditor(fProps: IFieldEditorProp) : JSX.Element | null {
        // Csak beágyazott editor-ként jelenik meg, mindig egy konkrét claim-en belül.
        if (fProps.fieldName=='claim_id') {
            return null;        
        } else {
            return super.getFieldEditor(fProps);
        }
    }    
}