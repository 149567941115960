import * as React from 'react';
import UserConfigStore from '@src/component/usr/usr_config_store_api';
import { formatDate } from '@src/Util';

interface IStickyTileConfig {
    text: string;
    date: string | null;
}

type StickyTileState = {
    text: string;
    date: string | null;
}

export default class StickyTile extends React.Component<{ tileKey: number }, StickyTileState> {

    constructor(props: any) {
        super(props);
        this.state = {
            text: "",
            date: null
        };

        this.loadTile();
    }

    private loadTile = async () => {
        try {
            let value: IStickyTileConfig | null = await UserConfigStore.get<IStickyTileConfig>("dashboard.sticky-tile-" + this.props.tileKey);
            if (value === null) {
                this.setState({ date: null });
            } else {
                this.setState({ text: value.text, date: value.date });
            }
        } catch (error) {
            //app.showErrorFromJsonResult(error);
        }
    }

    private storeTile = async () => {
        let date = new Date();
        let formattedDate = formatDate(date);
        let valueToStore: IStickyTileConfig = { text: this.state.text, date: formattedDate };
        try {
            await UserConfigStore.put("dashboard.sticky-tile-" + this.props.tileKey, valueToStore);
        } catch (error) {
            //app.showErrorFromJsonResult(error);
        }
    }


    valueChange(e: any) {
        this.setState({ text: e.target.value }, this.storeTile);

    }


    render() {
        return <div ref="stickyTile" className="sticky-tile">
            <div className="sticky-title">
                <div>
                    <h5>Jegyzet</h5>
                    <small>{this.state.date && this.state.date}</small>
                </div>
            </div>
            <div className="sticky-inner">
                <textarea value={this.state.text} onChange={this.valueChange.bind(this)} style={{ color: "inherit" }} />
            </div>
        </div>
    }

}