import './style.css';
import { AnswerElement } from '@src/component/exercise/models/ExerciseBaseClass';
import { ExerciseBaseClass } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { WMNumberLineServer } from './WMNumberLineServer';

export interface WMDndImageToBarData extends ExerciseBaseClass {
    sets: string[];
    cards: AnswerElement[];
    ordered: boolean;
    numberline: numberLineItem
    answer_type: NumberLineTypes;
    decimal_pointer: number;
    display_position: boolean;
    num_of_questions: number;
}

export enum NumberLineTypes {
    number = "number",
    float = "float"
}

type numberLineItem = {
    lower: number;
    upper: number;
    writescale: number;
    scale: number;
}

type WMD2BSolution = {
    key: number;
    value: number[];
}

type WMDndToBarSolution = {
    fullmatch: boolean;
    answer: WMD2BSolution[];
}

export class WMNumberLineExerciseEngine extends AExerciseEngine {

    private answerDivList: HTMLElement[] = [];
    private answerDivBase: string = '';
    private scale: number = 0;
    private minValue: any;
    private maxValue: any;
    private answer_type: NumberLineTypes;
    private decimal_pointer: any;
    private arrayOfValues: any;
    private shuffOrderList: any[] = [];
    private display_position: boolean;

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        let exercise: WMDndImageToBarData = params.exercise;
        if (!exercise || !exercise.numberline || exercise.cards.length == 0)
            return

        this.answerDivList = [];
        this.arrayOfValues = {};
        let lower: any = exercise.numberline.lower;
        let upper: any = exercise.numberline.upper;
        let writescale: any = exercise.numberline.writescale;
        this.scale = Number(exercise.numberline.scale) == 0 ? 1 : Number(exercise.numberline.scale);
        this.minValue = lower;
        this.maxValue = upper;
        this.answer_type = exercise.answer_type;
        this.decimal_pointer = exercise.decimal_pointer;
        this.display_position = exercise.display_position != undefined ? exercise.display_position : true;
        
        this.root.classList.add("wm-dnd-item-to-bar");

        this.setIllustration(exercise,this.root);

        let gridDiv = this.root.appendChild(document.createElement("div"));
        gridDiv.classList.add("row");
        gridDiv.classList.add("exercise-wrapper");

        //<DropArea>
        let solution_container = gridDiv.appendChild(document.createElement("div"));
        solution_container.classList.add("area-container");

        solution_container.addEventListener('drop', this.drop.bind(this), false);
        window.onresize = this.resize.bind(this);
        //<./DropArea>

        //<Bar>
        let barContainerWrapper: HTMLDivElement = solution_container.appendChild(document.createElement("div"));
        barContainerWrapper.classList.add("bar-wrapper");

        let barContainer: HTMLElement = barContainerWrapper.appendChild(document.createElement("div"));

        barContainer.classList.add("bar-container");
        barContainer.setAttribute("id", "bar-container");
        barContainer.style.position = "relative";

        let barContainerWidth = barContainer.offsetWidth; //Lekérjük a szélességét a divnek
        let integers = Math.floor(Math.abs(upper - lower) / writescale); //Megnézzük, hányszor van meg a writescale a távolságban (mar. nélkül)
        let integerPartWidth = integers * writescale; //Kiszámoljuk az egész részt
        let modulus = Math.abs(upper - lower) - integerPartWidth; //Kiszámoljuk, hogy mennyi a maradék rész
        let integerPart = (writescale / (Math.abs(upper - lower)) * 100); //Kiszámoljuk, mennyi egy egész bar szélessége %-ban
        let modulusPart = (modulus / Math.abs(upper - lower)) * 100; //Kiszámoljuk, mennyi a maradék bar szélessége %-ban

        //Kirajzoljuk az egészrészeket
        for (let i = 0; i < integers; i++) {
            let barELement: HTMLElement = this.createBarElement(barContainer);
            barELement.style.left = (i * integerPart) + "%".toString();
            barELement.style.width = integerPart + "%".toString();
        }
        //Ha a writescale nem osztható a távolsággal maradék nélkül, akkor volt maradék, ez az utolso bar
        if (modulus != 0) {
            let barELement: HTMLElement = this.createBarElement(barContainer);
            barELement.style.left = integers * integerPart + "%".toString();
            barELement.style.width = modulusPart + "%".toString();
        }

        //Kiirjuk az értékeket egészeknél
        for (let i = 0; i <= integers; i++) {
            let barTextlement: HTMLElement = this.createBarTextElement(barContainer);
            barTextlement.style.top = "30px";
            barTextlement.innerText = ((lower + writescale * i).toFixed(this.decimal_pointer)).toString();
            let leftPos = (i * integerPart) - (((barTextlement.offsetWidth / barContainerWidth) * 100) / 2);
            //Az első elem
            if (i == 0) {
                leftPos = 0;
                barTextlement.style.top = "12px";
            }
            //Ha nincs maradék, akkor ez az utolsó elem
            if (modulus == 0 && i == integers) {
                barTextlement.style.right = "0%";
                barTextlement.style.top = "12px";
                break;
            }
            barTextlement.style.left = leftPos.toString() + "%";

        }
        //Ha volt maradék, kiírjuk az utolsó értéket
        if (modulus != 0) {
            let barTextlement: HTMLElement = this.createBarTextElement(barContainer);
            barTextlement.style.right = "0%";
            barTextlement.style.top = "12px";
            barTextlement.innerText = upper.toString();
        }
        //<./Bar>


        //<Húzható megoldások>
        this.answerDivBase = "bar-answer-div-";
        let originalAnswerList = exercise.cards;
        let copyOriginal = originalAnswerList.slice(); //Eltároljuk az eredeti válaszokat
        let answer_wrap: HTMLElement = barContainer.appendChild(document.createElement("div"));
        answer_wrap.classList.add("answer-wrap");

        /* Válasz scrollozó */
        let answer_controls: HTMLElement = answer_wrap.appendChild(document.createElement("div"));
        answer_controls.classList.add("answer-controls");

        /* Gombok */
        let answerLeftButton: HTMLElement = answer_controls.appendChild(document.createElement("button"));
        answerLeftButton.classList.add("control", "answer-control-left");

        let answerRightButton: HTMLElement = answer_controls.appendChild(document.createElement("button"));
        answerRightButton.classList.add("control", "answer-control-right");

        /* Konténer */
        let answer_container: HTMLElement = answer_wrap.appendChild(document.createElement("div"));
        answer_container.classList.add("answer-container");
        let shuffAnswerList = exercise.ordered_answers ? originalAnswerList : AExerciseEngine.shuffle(originalAnswerList); //Megkverjük a válaszokat

        /* Gomb esemenyek */
        answerRightButton.addEventListener("click", this.xMove.bind(this, answer_container, 'right'), false);
        answerLeftButton.addEventListener("click", this.xMove.bind(this, answer_container, 'left'), false);

        for (let index = 0; index < shuffAnswerList.length; index++) {
          this.shuffOrderList.push(copyOriginal.indexOf(shuffAnswerList[index])); //Eltároljuk mely elemek hogyan cserélődtek (indexek)
        }
        
        for (let i = 0; i < shuffAnswerList.length; i++) {
            if(shuffAnswerList[i].type == "") continue;
            let answerdiv: HTMLDivElement;
            answerdiv = answer_container.appendChild(document.createElement("div"));

            /* Vonalka tisztán CSS-el */
            //let answerArrow = answerdiv.appendChild(document.createElement("div"));
            //answerArrow.classList.add("answer-arrow");

            AExerciseEngine.displayAnswer(answerdiv, shuffAnswerList[i], this.is_accessible, [], this);

            answerdiv.classList.add("answer-div", "answer-div-margin");
            answerdiv.setAttribute("draggable", "true");
            answerdiv.setAttribute("id", this.answerDivBase + i);
            answerdiv.setAttribute("data-index", String(i));
            answerdiv.setAttribute("data-answer", i.toString());
            //answerdiv.onmousedown = this.onmousedown.bind(this);
            //answerdiv.onmouseup = this.onmouseup.bind(this);

            /* Create draggable elements */
            if (!this.isReplay) {
                ($(answerdiv) as any).draggable({
                    start: this.drag.bind(this),
                    stop: this.drop.bind(this),
                    helper: 'clone',
                    //containment: this.root
                });
            }

            let buttonWrapper = answerdiv.appendChild(document.createElement("div"));
            buttonWrapper.classList.add("button-wrapper");

            //Mozgatáshoz szükséges - gomb
            let valueButtonsMinus = buttonWrapper.appendChild(document.createElement("div"));
            valueButtonsMinus.classList.add("bar-answer-div-value-button");
            valueButtonsMinus.classList.add("button-minus");
            valueButtonsMinus.innerText = "-";
            valueButtonsMinus.addEventListener("click", this.onMoveCard.bind(this, false, answerdiv), false);
            //A kártya aktuális értéke (szám)
            let valueLabel = buttonWrapper.appendChild(document.createElement("div"));
            valueLabel.classList.add("bar-answer-div-value-label");
            if (!this.display_position) valueLabel.style.display = "none";
            //Mozgatáshoz szükséges + gomb
            let valueButtonPlus = buttonWrapper.appendChild(document.createElement("div"));
            valueButtonPlus.classList.add("bar-answer-div-value-button");
            valueButtonPlus.classList.add("button-plus");
            valueButtonPlus.innerText = "+";
            valueButtonPlus.addEventListener("click", this.onMoveCard.bind(this, true, answerdiv), false);
            this.answerDivList.push(answerdiv);
        }

        /* Click-to-click simulation */
        if (!this.isReplay) {
            AExerciseEngine.simulateDrag({
                draggableItems: this.answerDivList,
                clickArea: this.root,
                excludedItemClasses: ['bar-answer-div-value-button']
            });
        }

        //<./Húzható megoldások>

        let scaleMin = exercise.answer_type == NumberLineTypes.float ? lower * 10 * exercise.decimal_pointer : lower;
        let scaleMax = exercise.answer_type == NumberLineTypes.float ? upper * 10 * exercise.decimal_pointer : upper;
        let offset: any = this.percentageFromPosition(this.calcOffset(this.answerDivList[0]));

        for (let i = 0; i <= (scaleMax - scaleMin); i++) {
            if (exercise.answer_type == NumberLineTypes.float) {
                // @ts-ignore
                this.arrayOfValues[(i + scaleMin) / 10 * exercise.decimal_pointer] = this.calculatePosition((i + scaleMin) / 10 * exercise.decimal_pointer).toFixed(2) - offset;
            }
            else
                // @ts-ignore
                this.arrayOfValues[i + scaleMin] = this.calculatePosition(i + lower).toFixed(2) - offset;
        }

        setTimeout(() => {
            let cords = answer_container.getBoundingClientRect();
            //answer_container.style.minHeight = cords.bottom - cords.top + 40 + 'px';
        }, 100);

        if (params.reloadResources) params.reloadResources();

    }
    createBarTextElement(barContainer: HTMLElement) {
        let barTextlement: HTMLElement = barContainer.appendChild(document.createElement("div"));
        barTextlement.classList.add("bar-text-element");
        barTextlement.style.position = "absolute";
        return barTextlement;
    }

    createBarElement(barContainer: HTMLElement) {
        let barELement: HTMLElement = barContainer.appendChild(document.createElement("div"));
        barELement.classList.add("bar-element");
        barELement.style.position = "absolute";
        return barELement;
    }

    //Kártya mozgatásának kezelése + illetve - gombokkal
    onMoveCard(incraise: boolean, answerdiv: HTMLDivElement) {
        //this.setZindexBack();
        this.simDragReset();
        this.onMoveCardReset();
        answerdiv.classList.add('card-moving');
        //answerdiv.style.zIndex = "3";
        let label: any = answerdiv.querySelector('.bar-answer-div-value-label');
        let value: any = parseFloat(label.innerText);
        if (this.exercise.answer_type == NumberLineTypes.float) {
            value = incraise ? +value + +this.scale : value - this.scale;
            value = value.toFixed(this.exercise.decimal_pointer);
        }
        else
            value = incraise ? parseInt(label.innerText) + +this.scale : parseInt(label.innerText) - this.scale;
        answerdiv.style.left = this.arrayOfValues[value] + "%";
        if (value > this.maxValue || value < this.minValue)
            return;

        answerdiv.style.left = this.calculatePosition(value, answerdiv) + "%".toString();
        label.innerText = value.toString();
        if (this.isSNIexc) this.SNIEvaluation( WMNumberLineServer);
    }

    getUserSolution(): WMDndToBarSolution {
        let result: any[] = [];
        for (let i = 0; i < this.answerDivList.length; i++) {
            //Visszakeressük a diveket, a keverés miatt
            let dataIndex:number = Number(this.answerDivList[i].getAttribute("data-index"));
            let solution: any = this.answerDivList[i].querySelector(".bar-answer-div-value-label");
            result[this.shuffOrderList[dataIndex]] = solution.innerText;
        }
        return { answer: result, fullmatch: true };
    }


    receiveEvaluation(evaluated: Evaluated): void {
        if (evaluated.success
        ) {
            for (let index = 0; index < this.answerDivList.length; index++) {
                let item = this.answerDivList[index];
                AExerciseEngine.removeEvalStyle(item);
                this.answerDivList[index].classList.add("exe-engine-check-correct");
            }
        }
        if (!evaluated.success) {
            if (evaluated.solution != null) {
                let userSolution: any[] = this.getUserSolution().answer;
                let correctSolution = evaluated.solution;
                for (let i = 0; i < correctSolution.length; i++) {
                    try {
                        let item = this.root.querySelector("#bar-answer-div-" + this.shuffOrderList.indexOf(i));
                        let ansCont: HTMLElement|null = this.root.querySelector(".answer-container");
                        if (item) {
                            AExerciseEngine.removeEvalStyle(item as HTMLElement);
                            if (Number(userSolution[i]) == Number(correctSolution[i])) {
                                item.classList.add("exe-engine-check-correct");
                            }
                            else {
                                if (!this.isSNIexc) item.classList.add("exe-engine-check-wrong");
                                //else AExerciseEngine.moveElementBack(item as HTMLElement, ansCont!, this.root);
                            }
                        }
                    }
                    catch (ex) {
                    }
                }
            }
        }

    }

    showCorrectSolution(solution: any): void {
        this.removePlaceHolders(this.root);
        let posValues: any = this.root.querySelector(".bar-container");
        if (posValues)
            posValues = posValues.getBoundingClientRect();
        let ansValues: any = this.root.querySelector(".answer-container");
        if (ansValues)
            ansValues = ansValues.getBoundingClientRect();

        for (let i = 0; i < this.answerDivList.length; i++) {
            let item: any = this.answerDivList[this.shuffOrderList.indexOf(i)];
            AExerciseEngine.removeEvalStyle(item);
            let buttonWrapper: HTMLElement = item.querySelector(".button-wrapper");
            //let line = item.querySelector(".answer-arrow");
            //line.style.height = "0px";
            //Ha behúzandó elem
            if (solution[i] != "") {
                item.querySelector(".bar-answer-div-value-label").innerText = solution[i];
                item.classList.add("dropped");
                buttonWrapper.style.display = "block";
                item.style.left = this.calculatePosition(solution[i], item) + "%".toString();
                /* A top random generátorral átláthatóbbnak tűnik */
                //let topstyle = (posValues.height / (i + 2)).toString() + "px";
                let topstyle = (Math.floor(Math.random() * (posValues.height - 160)) + 60).toString() + "px";
                item.style.top = topstyle;
                //this.calcLine(item, 0);
            }
            //Kakukktojás elem
            else {
                item.style = null;
                item.classList.remove("dropped");
                buttonWrapper.style.display = "none";
                let cardValue = buttonWrapper.querySelector(".bar-answer-div-value-label");
                if (cardValue) cardValue.innerHTML = "";
            }
            item.setAttribute("draggable", "false");
            item.setAttribute("droppable", "false");
            item.classList.remove("exe-engine-wrong-bg");
            item.classList.add("eke-engine-show-correct-bg");
        }

    }

    isUserReady(): boolean {
        var result: any = this.getUserSolution();

        for (let index = 0; index < result.answer.length; index++) {
            if (result.answer[index] != "")
                return true
        }

        return false;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

    private removeEvalStyle(element: JQuery<HTMLElement>): void {
        element.removeClass('exe-engine-correct-bg');
        element.removeClass('exe-engine-wrong-bg');
        element.removeClass("exe-engine-check-wrong");
        element.removeClass("exe-engine-check-correct");
    }

    /* Scrollozo fuggveny */
    xMove(div_container: HTMLDivElement, move_direction: string) {

        let container = div_container!;
        let direction = move_direction;
        let childs = container.childElementCount;
        let iwidth = container.scrollWidth / childs / 2;
        let styleLeft = container.style.marginLeft;
        if (styleLeft == '') {
            styleLeft = '0px';
        }
        let maxLeft = (container.parentNode! as any).offsetWidth - container.scrollWidth;
        if (maxLeft > 0) {
            maxLeft = 0;
        }
        let mathLeft = parseInt(styleLeft!, 10);

        switch (direction) {
            case 'left':
                mathLeft = mathLeft + iwidth;
                if (mathLeft > 0) {
                    mathLeft = 0;
                }
                break;
            case 'right':
                mathLeft = mathLeft - iwidth;
                if (mathLeft < maxLeft) {
                    mathLeft = maxLeft;
                }
                break;
            default:
                break;
        }
        container.style.marginLeft = mathLeft + 'px';
    }

    drop(ev: any, ui: any) {
        ev.target.classList.remove('ui-draggable-cloned');
        let dropDiv = ev.target;
        dropDiv.classList.add('dropped');
        dropDiv.style.left = ui.position.left + 'px';
        dropDiv.style.top = ui.position.top + 'px';

        let answer_container: any = this.root.querySelector(".answer-container");
        answer_container!.childNodes.forEach((element: HTMLElement) => {
            element.classList.remove("item-hidden");
        });
        let bar_container: any = this.root.querySelector(".bar-container");
        //let line = dropDiv.querySelector(".answer-arrow");

        //Megvizsgáljuk, hogy az elemet épp visszahúztuk -e
        let overlap = AExerciseEngine.hitTest(ev, [answer_container]);

        //Számegyenesre helyezés esetén
        if (bar_container && !overlap.overlap) {
            let newX: any = parseInt($(dropDiv).css('left')); //Függőleges
            newX = this.percentageFromPosition(newX) + "%";
            $(dropDiv).css('left', newX);
            //line.style.display = "block";
            let buttonWrapper = $(dropDiv).find(".button-wrapper");
            buttonWrapper.css('display', "block");
            //this.display_position != false ? buttonWrapper.css('display', "block") : buttonWrapper.css('display', "none");  //needed this strange condition because variable can be undefined as well
            //this.calcLine(ev.target, 0);
            this.checkValueBoundaries(dropDiv);

            /* Y érték ellenőrzése a kikapcsolt containment paraméter miatt */
            let newY: any = parseInt($(dropDiv).css('top')); //Függőleges
            if (newY < 60) {
                newY = '60px';
                $(dropDiv).css('top', newY);
            } else if (newY > (bar_container.offsetHeight - 50)) {
                overlap.overlap = true;
            }

            dropDiv.classList.add('dropped');
        }
        let buttonWrapper: HTMLElement = dropDiv.querySelector(".button-wrapper");

        //Az elemet visszahúztuk a kiinduló helyzetbe
        if (overlap.overlap) {
            dropDiv.style = null;
            dropDiv.classList.remove("dropped");
            //line.style.display = "none";
            buttonWrapper.style.display = "none";
            let cardValue = buttonWrapper.querySelector(".bar-answer-div-value-label");
            if (cardValue) cardValue.innerHTML = "";
            AExerciseEngine.moveElementBack(dropDiv, answer_container, this.root);
        }

        /* Klón törlése, biztos, ami biztos */
        let tempAnswer = answer_container.querySelector(".tempanswer");
        if (tempAnswer != null) answer_container.removeChild(tempAnswer);
        if (this.isSNIexc) this.SNIEvaluation( WMNumberLineServer);
    }

    checkValueBoundaries(dropDiv: HTMLDivElement) {
        let value = this.getValue(dropDiv);
        let valueLabel: any = $(dropDiv).find(".bar-answer-div-value-label");
        if (value <= this.minValue) {
            value = this.minValue;
            dropDiv.style.left = (this.calculatePosition(this.minValue, dropDiv) + "%");
        }
        else if (value >= this.maxValue) {
            value = this.maxValue;
            dropDiv.style.left = (this.calculatePosition(this.maxValue, dropDiv)) + "%";
        }
        valueLabel.text(value);
    }

    drag(ev: any) {
        //this.calcLine(ev.target, 1);
        //ev.target.classList.add('dropped');
        ev.target.classList.add('ui-draggable-cloned');
        let parentDiv: HTMLElement = (ev.target.parentElement as HTMLElement);
            if (parentDiv.classList.contains("answer-container") && !ev.target.classList.contains("dropped")) {
                if (this.simple_style || this.isSNIexc) {
                    this.cloneAnswerElement(ev.target, parentDiv);
                }
            }
    }

    onmousedown(ev: any) {
        let target = ev.target;
        if (!target.classList.contains("answer-div")) {
            target = target.closest('.answer-div');
        }
        //We make an invisible temporary clone until the drag/mouseup
        let clone = target.cloneNode(true);
        clone.style.visibility = "hidden";
        clone.classList.add("tempanswer");
        target.parentNode.insertBefore(clone, target.nextSibling);

        if (!target.classList.contains('dropped')) {
            target.style.left = target.offsetLeft;
            target.style.position = 'absolute';
        }
        this.setZindexBack();
        target.closest(".answer-div").style.zIndex = "3";
    }

    onmouseup(ev: any) {
        let target = ev.target;
        if (!target.classList.contains("answer-div")) {
            target = target.closest('.answer-div');
        }
        //We delete the temporary element, we made in the onmousedown event before
        let answer_container = this.root.querySelector(".answer-container") as HTMLDivElement;
        let tempAnswer = answer_container.querySelector(".tempanswer");
        if (tempAnswer != null) answer_container.removeChild(tempAnswer);
        if (!target.classList.contains('dropped')) {
            target.style.position = 'relative';
        }
    }

    resize(ev: any) {
        for (let i = 0; i < this.answerDivList.length; i++) {
            let item: HTMLDivElement = this.answerDivList[i] as HTMLDivElement;
            if (item.classList.contains('dropped')) {
                let tmp: any = item.querySelector('.bar-answer-div-value-label');
                let value = tmp ? tmp.innerHTML : '';
                let newPos = this.calculatePosition(value, item);
                let valOld = this.valueFromPercentage(item.style.left);
                let valRefference = this.valueFromPercentage(newPos);

                if (this.answer_type != NumberLineTypes.float) {
                    valOld = Math.floor(valOld);
                    valRefference = Math.floor(valRefference);
                }
                else {
                    valOld = parseFloat(valOld).toFixed(this.decimal_pointer);
                    valRefference = parseFloat(valRefference).toFixed(this.decimal_pointer);
                }
                if (valOld != valRefference)
                    item.style.left = newPos + "%".toString();
                //this.calcLine(item, 0)
            }
        }

    }

    private getValue(dropDiv: any): number {
        let offset = this.calcOffset(dropDiv);
        let correct_position = parseFloat(dropDiv.style.left) + this.percentageFromPosition(offset);
        return this.getValue_without_offset(correct_position);
    }

    private getValue_without_offset(newX: any): number {
        let dropValue: any;
        dropValue = this.valueFromPercentage(newX);
        let decimalPointers: number = 2;
        if (this.answer_type == NumberLineTypes.number) {
            dropValue = Math.floor(dropValue);
            decimalPointers = 0; // Csak egész érték után lesz nagyobb pl 14-15 között 14
        }
        if (this.answer_type == NumberLineTypes.float) {
            decimalPointers = this.decimal_pointer;
        }

        dropValue = parseFloat(dropValue).toFixed(decimalPointers); //Dátumnál és törtnél még csak véletlenül sem!!
        return dropValue;
    }

    private calculatePosition(ertek: number, dropDiv: any = undefined): number {  //Értékből koordináta számítás, showHelphez
        let offset = 0;
        if (dropDiv) {
            offset = this.percentageFromPosition(this.calcOffset(dropDiv));
        }
        let position = 0;
        position = this.percentageFromValue(ertek);
        return position - offset;
    }

    private percentageFromPosition(pos: number): any {
        let bar_container: any = this.root.querySelector(".bar-container");
        return (pos / bar_container.offsetWidth * 100);
    }

    private valueFromPercentage(newX: any): any {
        let newX_;
        if (typeof newX == "string") {
            newX_ = parseFloat(newX);
        }
        else newX_ = newX;
        return ((Math.abs(this.maxValue - this.minValue) / 100 * newX_ + this.minValue));
    }

    private percentageFromValue(newX: any): any {
        let newX_;
        if (typeof newX == "string") {
            newX_ = parseFloat(newX);
        }
        else newX_ = newX;
        return (newX_ - this.minValue) / (Math.abs(this.maxValue - this.minValue) / 100);
    }

    private calcLine(dropDiv: any, nul: any): void {
        let barElement: any = this.root.querySelector(".bar-element");
        let posTop = parseFloat(dropDiv.style.top + "");
        if (nul == 0) {
            posTop = dropDiv.getBoundingClientRect().top - barElement.getBoundingClientRect().bottom;
        }
        else {
            posTop = -3;
        }
        let line: any = dropDiv.querySelector(".answer-arrow");
        line.style.height = Math.abs(posTop).toString() + "px";

    }

    private calcOffset(dropDiv: any): number {
        let margin = parseInt(window.getComputedStyle(dropDiv, "").getPropertyValue('margin-left'));
        let width = parseInt(window.getComputedStyle(dropDiv, "").getPropertyValue('width'));
        return Math.ceil(width / 2 + margin);
    }

    private setZindexBack() {
        $(".answer-div").css("z-index", "");
    }

    private onMoveCardReset() {
        $(".answer-div").removeClass('card-moving');
    }

    private simDragReset() {
        $(".answer-div").removeClass('ui-draggable-dragging');
    }
}