import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IDayRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Dátum
     */
    day?: string /*date?*/;
    /**
     * Nap típus
     *
     * NOT NULL -> sys.day_type ON DELETE noaction
     *
     */
    day_type_id?: number;
    /**
     * Leírás
     */
    description?: string;
}

/**
 *  sys.day - Nap
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class DayCrud extends Crud<IDayRecord> {
    public static TABLE_INFO_ID = 2294283089;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'day'; }

    public static load: (id: number, aServer ?: Server) => Promise<DayCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IDayRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IDayRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IDayRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IDayRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IDayRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class DayCrudClassProxy extends CrudClassProxy<IDayRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const dayCrudClassProxy = new DayCrudClassProxy(DayCrud);
registerCrudClassProxy(dayCrudClassProxy);
