import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapDfhtVisitRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * KAP szakmai támogató
     */
    kap_supporter_id?: number|null;
    /**
     * Intézmény
     */
    institute_id?: number|null;
    /**
     * Legkorábbi kezdő időpont
     *
     * Ezt a szakmai támogató adja meg. Ez a legkorábbi időpont ahonnan igénybe lehet venni.
     */
    min_start_date?: string /*timestamp?*/;
    /**
     * Legkésőbbi befejező időpont
     *
     * Ezt a szakmai támogató adja meg. Ez a legkésőbbi időpont ahonnan igénybe lehet venni.
     */
    max_end_date?: string /*timestamp?*/;
    /**
     * Támogató jóváhagyta
     *
     * Az az időpont, amikor a támogató jóváhagyta az időpontot és a feladatellátási helyet.
     */
    supporter_approved?: string /*timestamp?*/|null;
    /**
     * Kapcsolattartó jóváhagyta
     *
     * Az az időpont, amikor a feladatellátási hely kapcsolattartója jóváhagyta az időpontot és a szakmai támogatót.
     */
    site_approved?: string /*timestamp?*/|null;
    /**
     * Az intézmény látogatás gyökér könyvtára.
     */
    oo_folder_id?: number;
    /**
     * Szakmai támogató e-mail cím
     *
     * Szakmai támogató e-mail cím
     */
    kap_supporter_email?: string|null;
    /**
     * Szakmai támogató bejelentkezési név
     *
     * Szakmai támogató bejelentkezési név
     */
    kap_supporter_login_name?: string|null;
    /**
     * Szakmai támogató e-mail cím
     *
     * Szakmai támogató e-mail cím
     */
    kap_supporter_fullname?: string|null;
    /**
     * Intézmény OM azonosító
     *
     * Intézmény OM azonosító
     */
    institute_om_identifier?: string|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    institute_name?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    wf_workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Folyamat állapot leírása
     *
     * Folyamat állapot leírása
     */
    wf_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Folyamat állapot stílusa, jsonb/css
     */
    wf_station_style?: any|null;
    /**
     * Folyamat al-státusz azonosító
     *
     * Folyamat al-státusz azonosító
     */
    wf_substation_id?: number|null;
    /**
     * Folyamat al-státusz
     *
     * Folyamat al-státusz
     */
    wf_substation_name?: string|null;
    /**
     * Folyamat al-státusz leírása
     *
     * Folyamat al-státusz leírása
     */
    wf_substation_description?: string|null;
    /**
     * Folyamat szöveges állapot kiegészítés
     *
     * Folyamat szöveges állapot kiegészítés
     */
    wf_status_text?: string|null;
}

/**
 *  kap.view_kap_dfht_visit - KIP/Komplex intézmény látogatás
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewKapDfhtVisit extends View<IViewKapDfhtVisitRecord> {
    public static VIEW_INFO_ID = 2019082601;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_dfht_visit'; }
    public static getTableNameForClass(): string|null { return 'dfht_visit'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapDfhtVisit>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapDfhtVisitRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapDfhtVisitClassProxy extends ViewClassProxy<IViewKapDfhtVisitRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapDfhtVisitClassProxy = new ViewKapDfhtVisitClassProxy(ViewKapDfhtVisit);
registerViewClassProxy(viewKapDfhtVisitClassProxy);

