import * as React from 'react';
import { History, Location } from 'history';
import CrudSelect from '@src/framework/forms/crudselect';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import { SearchResult } from '@src/server/PublicServer';
import * as queryString from 'query-string';
import { PATH_PUBLISHED_MEDIA_SEARCH } from '@src/Routes';
import AutoCompleteEdit from '@src/framework/forms/autocompleteedit';
import { __ } from '@src/translation';

type MediaSearchBarProps = {
    match: any;
    location: Location;
    history: History;
}

type MediaSearchBarState = {
    keywords: string;
    selectedSubject: number | null;
    selectedGrade: number | null;
    selectedElasticType?: string;
    results: SearchResult[];
    origkeywords: string;
}


export function getTypes() {
    return [
        { elasticType: "media", name: __("Minden"), iconClassName: "nkp-icon-filter-all" },
        { elasticType: "video", name: __("Videó"), iconClassName: "nkp-icon-filter-video" },
        { elasticType: "map", name: __("Térkép"), iconClassName: "nkp-icon-filter-map" },
        //{ elasticType: "image", name: __("Kép"), iconClassName: "nkp-icon-filter-photo" },
        { elasticType: "audio", name: __("Hang"), iconClassName: "nkp-icon-filter-audio" },
    ]
};

export class MediaSearchBar extends React.Component<MediaSearchBarProps, MediaSearchBarState> {

    constructor(props: MediaSearchBarProps) {
        super(props);
        const keywords = this.props.match.params.keywords ? this.props.match.params.keywords : "";

        var defaultKeywords = "";
        if (this.props.location.pathname.startsWith(PATH_PUBLISHED_MEDIA_SEARCH)) {
            defaultKeywords = this.props.location.pathname.replace(PATH_PUBLISHED_MEDIA_SEARCH + "/", "");
        }

        const params = queryString.parse(this.props.location.search);

        this.state = {
            keywords: defaultKeywords,
            origkeywords: keywords,
            selectedSubject: null,
            selectedGrade: null,
            selectedElasticType: params.type || "media",
            results: [],
        }
    }

    private onValueChange = (value: string) => {
        this.setState({ keywords: value });
    }
    setCurrentSearch() {
        const params = {
            type: this.state.selectedElasticType || "media",
            grade_id: this.state.selectedGrade ? this.state.selectedGrade : undefined,
            subject_id: this.state.selectedSubject ? this.state.selectedSubject : undefined
        };

        if (this.state.keywords) {
            this.props.history.push(PATH_PUBLISHED_MEDIA_SEARCH + "/" + encodeURIComponent(this.state.keywords) + "?" + queryString.stringify(params));
        } else {
            this.props.history.push(this.props.location.pathname + "?" + queryString.stringify(params));
        }
    }

    onKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.setCurrentSearch();
        }
    }

    private onGradeSelect = (sender: CrudSelect, newGrade: number | null): void => {
        this.setState({ selectedGrade: newGrade }, this.setCurrentSearch);
    }

    private onSubjectSelect = (sender: CrudSelect, newSubject: number | null): void => {
        this.setState({ selectedSubject: newSubject }, this.setCurrentSearch);
    }
    private onItemSelected = (selectedItem: string) => {
        this.setState({ keywords: selectedItem || "" }, this.setCurrentSearch);
    }
    onSelectType(selectedElasticType: string) {
        this.setState({ selectedElasticType }, this.setCurrentSearch);
    }

    render() {
        const searchIcons = (
            <div className="eke-media-rightcontainer">
                <div className="row align-center">
                    <div className="column small-12 medium-10" style={{ position: "relative", paddingRight: "0" }}>
                        <div className="back-glow gray"></div>
                        <div className="buttons-row" data-dropdown-menu ref="menu">
                            <div style={{ flex: "1 0 auto", width: "max-content", display:"flex" }}>
                                {
                                    getTypes().map(type => {

                                        let isActiveClass = "";
                                        if (type.elasticType == this.state.selectedElasticType) {
                                            isActiveClass = "active";
                                        }

                                        return <button
                                            key={type.elasticType}
                                            onClick={this.onSelectType.bind(this, type.elasticType)}
                                            className={"button primary hollow eke-search__filter-button " + isActiveClass}
                                        >
                                            <i className={"icon " + type.iconClassName}></i> {type.name}
                                        </button>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        const filterComponent =
            <div className="">
                <div className="eke-media-rightcontainer">
                    <div className="row align-center">
                        <div className="search-filters column small-12 medium-11 large-11">
                            <CrudSelect
                                value={this.state.selectedGrade}
                                onSelect={this.onGradeSelect}
                                displayFieldName="name"
                                orderByFieldName="id"
                                emptyTitle={__("Minden osztály")}
                                crudClassProxy={gradeCrudClassProxy}
                                filter={{ is_active: true }}
                                sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                            />
                            <CrudSelect
                                value={this.state.selectedSubject}
                                onSelect={this.onSubjectSelect}
                                displayFieldName="name"
                                emptyTitle={__("Minden tantárgy")}
                                crudClassProxy={subjectCrudClassProxy}
                                filter={{ is_active: true }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        let autocomplete;
        autocomplete = <AutoCompleteEdit value={this.state.keywords} onItemSelected={this.onItemSelected} onValueChange={this.onValueChange} onKeyPress={this.onKeyPress.bind(this)} />
        const searchbar = <div className="secondary-bar">
            <div className="row align-center">
                <div className="small-12 medium-10 column ">
                    <div className="eke-media-rightcontainer">
                        <div className="input-group eke-search-field">
                            <div className="eke-search__input">{autocomplete}</div>
                            <div className="input-group-button">
                                <div className="button-group shrink column eke-search__button">
                                    <button className="button" onClick={this.setCurrentSearch.bind(this)}>{__("Keresés")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        return <div>            
            {searchbar}
            {searchIcons}
            {filterComponent}            
        </div>;
    }
}
