import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSharedWithMeRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Ki osztotta meg
     */
    shared_by_id?: number;
    /**
     * Mit osztott meg- Táblázat infó
     *
     * Ez mondja meg, hogy a megosztott tartalom melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number;
    /**
     * Mit osztott meg - rekord azonosító
     *
     * Ez mondja meg, hogy a megosztott tartalom melyik rekordhoz tartozik.
     */
    rec_id?: number;
    /**
     * Megosztás módja
     */
    share_mode_id?: number;
    /**
     * Megosztás kezdő időpontja
     */
    start_date?: string /*timestamp?*/;
    /**
     * Megosztás befejező időpontja
     */
    end_date?: string /*timestamp?*/|null;
    /**
     * Leadás határideje
     *
     * A leadás határideje csak határidős kiosztásoknál van értelmezve.
     */
    due_date?: string /*timestamp?*/|null;
    /**
     * Futtatások maximális száma
     *
     * Ha meg van adva, akkor a megosztott feladat/feladatsor maximum ennyiszer futtatható. Ennyiszer lehet beküldeni megoldást.
     */
    max_run_count?: number /*int4*/|null;
    /**
     * Beküldés határidő után
     *
     * Lehessen-e beküldeni megoldást a határidő lejárta után.
     */
    send_overdue?: boolean;
    /**
     * Csoportos megoldás
     *
     * A megoldást teljes csoportok küldik be, nem egyének.
     */
    group_solution?: boolean;
    /**
     * Név
     */
    title?: string;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Melyik csoportból indították a megosztást
     *
     * Ez csak annyit mutat, hogy a megosztást egy adott tanulócsoportból indították. Semmi köze nincs ahhoz, hogy kikkel van megosztva.
     */
    shared_from_institute_group_id?: number|null;
    /**
     * Max. pontszám
     */
    max_points?: number /*int4*/;
    /**
     * Séma név
     *
     * Séma név
     */
    schema_name?: string|null;
    /**
     * Táblázat név
     *
     * Táblázat név
     */
    table_name?: string|null;
    /**
     * Típus
     *
     * A megosztott tartalom típusa
     */
    table_display_name?: string|null;
    /**
     * Megosztó teljes név
     *
     * Annak a felhasználónak a teljes természetes neve, aki a megosztást végezte
     */
    shared_by_fullname?: string|null;
    /**
     * Megosztó e-mail
     *
     * Annak a felhasználónak az e-mail címe, aki a megosztást végezte
     */
    shared_by_email?: string|null;
    /**
     * Annak a felhasználónak a bejelentkezési neve, aki a megosztást végezte
     *
     * Annak a felhasználónak a bejelentkezési neve, aki a megosztást végezte
     */
    shared_by_login_name?: string|null;
    /**
     * Megosztás módja
     *
     * Megosztás módja
     */
    share_mode_title?: string|null;
    /**
     * Értesítési azonosító
     *
     * Értesítési azonosító
     */
    shared_content_member_id?: number|null;
    /**
     * Értesítés létrejött
     *
     * Értesítés létrejött
     */
    notification_created?: string /*timestamp?*/|null;
    /**
     * Értesítés nyugtázva
     *
     * Értesítés nyugtázva
     */
    notification_acknowledged?: string /*timestamp?*/|null;
    /**
     * Saját legjobb eredményem %
     *
     * Saját legjobb eredményem %
     */
    success_percent?: number /* float8 */|null;
    /**
     * Melyik csoportból indították a megosztást
     *
     * Ennek nincs köze semmiféle jog ellenőrzéshez!
     */
    shared_from_institute_group_name?: string|null;
    /**
     * Név
     *
     * A megosztott tartalom szöveges reprezentációja
     */
    displayvalue?: string|null;
}

/**
 *  usr.view_shared_with_me - Megosztott tartalom
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewSharedWithMe extends View<IViewSharedWithMeRecord> {
    public static VIEW_INFO_ID = 2018121002;

    public static getSchemaNameForClass(): string { return 'usr'; }
    public static getViewNameForClass(): string { return 'view_shared_with_me'; }
    public static getTableNameForClass(): string|null { return 'shared_content'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSharedWithMe>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSharedWithMeRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSharedWithMeClassProxy extends ViewClassProxy<IViewSharedWithMeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSharedWithMeClassProxy = new ViewSharedWithMeClassProxy(ViewSharedWithMe);
registerViewClassProxy(viewSharedWithMeClassProxy);

