import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IExcDisplayModeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Címke
     */
    label?: string;
}

/**
 *  exc.exc_display_mode - Feladat üzemmód
 */

export default class ExcDisplayModeCrud extends Crud<IExcDisplayModeRecord> {
    public static TABLE_INFO_ID = 721468139;
    public static getSchemaNameForClass() { return 'exc'; }
    public static getTableNameForClass() { return 'exc_display_mode'; }

    public static load: (id: number, aServer ?: Server) => Promise<ExcDisplayModeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IExcDisplayModeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IExcDisplayModeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IExcDisplayModeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IExcDisplayModeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IExcDisplayModeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ExcDisplayModeCrudClassProxy extends CrudClassProxy<IExcDisplayModeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const excDisplayModeCrudClassProxy = new ExcDisplayModeCrudClassProxy(ExcDisplayModeCrud);
registerCrudClassProxy(excDisplayModeCrudClassProxy);
