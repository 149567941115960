import { Module, ModuleMenu, BaseMenu, ModuleRoute } from "../Module";
import { __ } from "@src/translation";
import ExerciseList from "@src/component/exercise/Viewer/ExerciseList";
import LearningPathList from "@src/component/learningpath/LerningPathList";
import ExerciseSeriesList from "@src/component/exercise/ExerciseSeries/ExerciseSeriesList";
import ExerciseShow from "@src/component/exercise/Viewer/ExerciseShow";
import ExerciseListPublicComponent from "@src/component/exercise/Viewer/ExerciseListPublicComponent";


export let exerciseModule: ExerciseModule<BaseMenu>;
export type ExerciseModuleConfig = {
    enableExercises?: boolean;
    enableLearningPath?: boolean;
    enableOwnExercises?: boolean;
    enableOfficialExercises?: boolean;
    enableExercisesColleciton?: boolean;
    enableFavouriteExercises?: boolean;
    enableExerciseInBook?:boolean;
    enableLangSelector?:boolean;
    paths?: any;
}

export class ExerciseModule<T extends BaseMenu> extends Module<T> {

    private config: ExerciseModuleConfig;

    //FELADATOK
    public PATH_EXERCISE = "/feladat";
    public PATH_EXERCISE_SERIES = "/feladatsor";
    public PATH_EXERCISE_LIST =  this.PATH_EXERCISE + "/szerkesztoiLista";
    public PATH_EXERCISE_SERIES_LIST =  this.PATH_EXERCISE_SERIES + "/szerkesztoiLista";
    public PATH_LEARNING_PATH = "/tanulasiut";

    public PATH_EXERCISE_PUB_LIST = this.PATH_EXERCISE + "/feladattar";
    public PATH_EXERCISE_PUB_VIEW = this.PATH_EXERCISE + "/megjelenites";

    public PATH_EXERCISE_MORE_HELP_PUB = this.PATH_EXERCISE_PUB_VIEW + "/tobbsegitseg";
    public PATH_EXERCISE_SERIES_PUB_VIEW = this.PATH_EXERCISE_SERIES + "/megjelenites";
    
    public getConfig()
    {
        return this.config;
    }

    constructor(config: ExerciseModuleConfig) {
        super();

        this.config = config;

        console.log("constructor config", config)

        this.PATH_EXERCISE = (config.paths && config.paths["PATH_EXERCISE"]) || "/feladat";
        this.PATH_EXERCISE_SERIES = (config.paths && config.paths["PATH_EXERCISE_SERIES"]) || "/feladatsor";
        this.PATH_EXERCISE_LIST = (config.paths && config.paths["PATH_EXERCISE_LIST"]) || this.PATH_EXERCISE + "/szerkesztoiLista";
        this.PATH_EXERCISE_SERIES_LIST = (config.paths && config.paths["PATH_EXERCISE_SERIES_LIST"]) || this.PATH_EXERCISE_SERIES + "/szerkesztoiLista";
        this.PATH_LEARNING_PATH = (config.paths && config.paths["PATH_LEARNING_PATH"]) || "/tanulasiut";

        this.PATH_EXERCISE_PUB_LIST = (config.paths && config.paths["PATH_EXERCISE_PUB_LIST"]) || this.PATH_EXERCISE + "/feladattar";
        this.PATH_EXERCISE_PUB_VIEW = (config.paths && config.paths["PATH_EXERCISE_PUB_VIEW"]) || this.PATH_EXERCISE + "/megjelenites";

        this.PATH_EXERCISE_MORE_HELP_PUB = (config.paths && config.paths["PATH_EXERCISE_MORE_HELP_PUB"]) || this.PATH_EXERCISE_PUB_VIEW + "/tobbsegitseg";
        this.PATH_EXERCISE_SERIES_PUB_VIEW = (config.paths && config.paths["PATH_EXERCISE_SERIES_PUB_VIEW"]) || this.PATH_EXERCISE_SERIES + "/megjelenites";
        ///
    }

    public async getRoutesAsync(): Promise<Record<string, ModuleRoute<T>>> {
        return this.getRoutes();
    }

    public getRoutes(): Record<string, ModuleRoute<T>> {

        console.log("getroutes", this.PATH_EXERCISE)
        let ret: Record<string, ModuleRoute<T>> = {
            'ROUTE_EXERCISE_PUB_VIEW' : { path: this.PATH_EXERCISE_PUB_VIEW + "/:exerciseId", component: ExerciseShow, exact:true, title:__("Feladat megtekintés"), layoutKey: "public"},
            'ROUTE_EXERCISE_PUB_LIST' : { path: this.PATH_EXERCISE_PUB_LIST, component: ExerciseListPublicComponent, exact:true, title:__("Feladattár"), layoutKey: "public" },
            'ROUTE_EXERCISE_LIST' : { path: this.PATH_EXERCISE_LIST, component: ExerciseList, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },
            'ROUTE_EXERCISE_SERIES_LIST' : { path: this.PATH_EXERCISE_SERIES_LIST, component: ExerciseSeriesList, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },
            'ROUTE_LEARNING_PATH' : { path: this.PATH_LEARNING_PATH, component: LearningPathList, hasSidebar: true, menuCode: "contentstore-sidebar", sidebarHeader: __("Tartalomtár") },                        
        };
            
        return ret;
    }

    public getMenus() {
        let ret: ModuleMenu<T>[] = [
            
             {
                enabled: this.config.enableExercises,
                title: __("Feladatlista"),
                path: this.PATH_EXERCISE_LIST,
                iconClass: "fa fa-fw ico nkp-icon-my-lessons-repo",
                code: "contentstore-sidebar",
                no: 100,
            },   
            {
                enabled: this.config.enableExercises,
                title: __("Feladatsorlista"),
                path: this.PATH_EXERCISE_SERIES_LIST,
                iconClass: "fa fa-fw ico nkp-icon-excersises-repo",
                code: "contentstore-sidebar",
                no: 110,
            },
            {
                enabled: this.config.enableLearningPath,
                title: __("Tanulási útvonalaim"),
                path: this.PATH_LEARNING_PATH,
                iconClass: "fas fa-fw fa-bezier-curve",
                code: "contentstore-sidebar",
                no: 240,
            },
        ];
        return ret;
    }

    public getRecordRoute(tableInfoId: number, recordId: number, edit?: boolean): string | null {
        return null;
    }
    public static initGlobal<T extends BaseMenu>(config: ExerciseModuleConfig) {
        exerciseModule = new ExerciseModule<T>(config);
    }

    
}
