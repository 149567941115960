import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IKipDraftBlockTypeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Minimum idő (perc)
     */
    min_time?: number /*integer*/;
    /**
     * Maximum idő (perc)
     */
    max_time?: number /*integer*/;
    /**
     * Az óra menete
     */
    title?: string;
    /**
     * Pedagógus tevékenysége
     */
    teacher_activity?: string;
    /**
     * Tanuló tevékenysége
     */
    student_activity?: string|null;
    /**
     * Munkaformák
     */
    work_forms?: string|null;
}

/**
 *  kap.kip_draft_block_type - DFHT KIP óraterv blokk típus
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class KipDraftBlockTypeCrud extends Crud<IKipDraftBlockTypeRecord> {
    public static TABLE_INFO_ID = 3066002964;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'kip_draft_block_type'; }

    public static load: (id: number, aServer ?: Server) => Promise<KipDraftBlockTypeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IKipDraftBlockTypeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IKipDraftBlockTypeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IKipDraftBlockTypeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IKipDraftBlockTypeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IKipDraftBlockTypeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class KipDraftBlockTypeCrudClassProxy extends CrudClassProxy<IKipDraftBlockTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const kipDraftBlockTypeCrudClassProxy = new KipDraftBlockTypeCrudClassProxy(KipDraftBlockTypeCrud);
registerCrudClassProxy(kipDraftBlockTypeCrudClassProxy);
