import * as React from "react";
import HtrTicketCategoryCrud, { htrTicketCategoryCrudClassProxy } from "@src/framework/crud/ticket/HtrTicketCategoryCrud";
import { BubbleLoader } from 'react-css-loaders';
import { __ } from "@src/translation";
import HtrTicketCrud, { IHtrTicketRecord } from "@src/framework/crud/ticket/HtrTicketCrud";
import HtrTicketMessageCrud, { IHtrTicketMessageRecord, htrTicketMessageCrudClassProxy } from "@src/framework/crud/ticket/HtrTicketMessageCrud";
import { app, history } from '@src/index';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import { me } from "@src/framework/server/Auth";
import { PATH_TICKETING_PAGE, PATH_TICKETING_MESSAGE, PATH_TICKETING_MESSAGES, Routes } from "@src/Routes";
import { Route, Switch } from "react-router-dom";
import Sidebar, { SidebarMenuItem } from "../Sidebar";
import SplitPane from "react-split-pane";
import { debounce } from 'lodash';
import ReactTable from "react-table";
import { getReactTableLabels } from '@src/framework/i18n';
import { formatDate, databaseDateTimeToTimestamp } from '@src/Util';
import { match } from "react-router";
import { TicketCategoryList } from '@src/component/ticket/htr_ticket_category_list';
import ViewHtrTicketMessage, { IViewHtrTicketMessageRecord } from "@src/framework/view/ticket/ViewHtrTicketMessage";
import { ToolTipDiv } from "../ToolTipDiv";
import ViewHtrTicket, { IViewHtrTicketRecord } from "@src/framework/view/ticket/ViewHtrTicket";
import ViewHtrTicketAgent from "@src/framework/view/ticket/ViewHtrTicketAgent";
import { Dialog, confirmDialog } from "../Dialog";
import TicketCategoryTreeList from "./htr_ticket_category_tree_list";
import MediaFileChooser, { FILE_ACTIONS } from '@src/component/filemanager/MediaFileChooser';
import OoFileCrud from "@src/framework/crud/media/OoFileCrud";
import ViewMediaOoFile, { IViewMediaOoFileRecord } from "@src/framework/view/media/ViewMediaOoFile";
import HtrTicketUserReadCrud, { htrTicketUserReadCrudClassProxy } from "@src/framework/crud/ticket/HtrTicketUserReadCrud";

type TicketMesssageProps = {
    match?: match<{ no: string, categoryId?: string, filter?: string }>;
    category_id?: number;
    //no?:string;
}
type TicketMesssageState = {
    loading: boolean;
    ticket: IViewHtrTicketRecord;
    ticket_message: IHtrTicketMessageRecord;
    messages: IViewHtrTicketMessageRecord[];
    showSelectDialog: boolean;
    attachments: IViewMediaOoFileRecord[];
}

export class TicketMessage extends React.Component<TicketMesssageProps, TicketMesssageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            ticket: { htr_ticket_category_id: this.props.category_id },
            ticket_message: {},
            messages: [],
            showSelectDialog: false,
            attachments: []
        };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: TicketMesssageProps, prevState: TicketMesssageState) {
        if (prevProps !== this.props) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {
        var id = this.state.ticket.id;
        try {
            var ticket;
            if (this.props.match && this.props.match.params.no) {
                ticket = (await ViewHtrTicket.list({ filter: { is_active: true, no: this.props.match.params.no } }))[0];
            }
            else if (this.props.match && this.props.match.params.categoryId) {
                id = undefined;
                this.setState({ messages: [], ticket_message: {}, ticket: { htr_ticket_category_id: Number(this.props.match.params.categoryId), id: undefined } })
            }
            if (id) {
                ticket = (await ViewHtrTicket.load(id!)).record;
            }
            let files: IViewMediaOoFileRecord[] = [];
            if (this.state.ticket.oo_folder_id != null) {
                files = await ViewMediaOoFile.list({ filter: { is_active: true, oo_folder_id: this.state.ticket.oo_folder_id }, order_by: 'creation_time', });
            }

            if (ticket) {
                const messages = await ViewHtrTicketMessage.list({ filter: { is_active: true, htr_ticket_id: ticket.id! }, order_by: [{ name: "creation_time", desc: true }] });
                this.setState({
                    ticket,
                    messages,
                    loading: false,
                    ticket_message: { htr_ticket_id: ticket.id },
                    attachments: files
                })
            }
            else {
                this.setState({
                    loading: false,
                    messages: [],
                    ticket_message: {}
                })
            }
            this.handleTicketUserRead();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onSave() {
        try {
            if (!this.state.ticket.sec_user_id && me) this.state.ticket.sec_user_id = me.id;
            if (!this.state.ticket.id) {
                const ticketRecord = (await new HtrTicketCrud(this.state.ticket).put()).record as IHtrTicketRecord;
                this.state.ticket.id = ticketRecord.id;
                this.state.ticket.no = ticketRecord.no;
                if (!this.state.ticket_message.htr_ticket_id) this.state.ticket_message.htr_ticket_id = ticketRecord.id;
            }
            const ticketMessageRecord = (await new HtrTicketMessageCrud(this.state.ticket_message).put()).record;
            app.showSuccess(__("Sikeres mentés"), __("Lementve"));
            history.push(PATH_TICKETING_PAGE + "/levelezes/" + this.state.ticket.no);
            this.reloadAsync();

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onTicketChange(propName: string, value: any) {
        this.setState(
            {
                ticket: { ...this.state.ticket, [propName]: value }
            }
        );
    }

    private onTicketMessageChange(propName: string, value: any) {
        this.setState(
            {
                ticket_message: { ...this.state.ticket_message, [propName]: value }
            }
        );
    }

    private onTicketCategorySelect(value: any) {
        this.setState({ ticket: { ...this.state.ticket, htr_ticket_category_id: value } });
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        }

        return <div className="content-wrapper">
            <div className="row">
                <div className="column small-12">
                    <label>
                        {__("Tárgy")}
                        <input type="text" disabled={this.state.ticket.id != undefined} value={this.state.ticket.subject || ""} onChange={e => this.onTicketChange("subject", e.target.value)} />
                    </label>
                </div>
                <div className="column small-12">
                    <label>
                        {__("Témakör")}
                        <CrudSelectComponent
                            displayFieldName="title"
                            crudClassProxy={htrTicketCategoryCrudClassProxy}
                            onSelect={(sender, newValue) => this.onTicketCategorySelect(Number(newValue))}
                            value={this.state.ticket.htr_ticket_category_id!}
                            filter={{ is_active: true, selectable: true }}
                            disabled={this.state.ticket.id != undefined}
                        />
                    </label>
                </div>
                <div className="column small-12">
                    <label>
                        {__("Felelős: ")}
                        <span>{this.state.ticket.default_agent ? this.state.ticket.default_agent : __("Nincs hozzárendelve felelős.")}</span>
                    </label>
                </div>
                <div className="column small-12">
                    <label>
                        {__("Üzenet")}
                        <textarea rows={5} value={this.state.ticket_message.message_html || ""} onChange={e => this.onTicketMessageChange("message_html", e.target.value)} />
                    </label>
                </div>
                {
                    this.state.ticket.oo_folder_id
                    ?
                    <>
                    <button className="button small" onClick={() => this.setState({ showSelectDialog: true })}>
                    <i className="fa fa-external-link" /> {__("Ticket csatolmányai")}
                    </button>
                    <span>{__("{n} db csatolmány.", {n: this.state.attachments.length || 0})}</span>
                    </>
                    :
                    <p>{__("Csak az első üzenet elküldése után lehet csatolmányt adni a tickethez!")}</p>
                }
                {
                    this.state.showSelectDialog
                    ?
                    <Dialog title={__("Csatolmányok")} width={1050} onClose={() => this.setState({ showSelectDialog: false })} >
                        <MediaFileChooser
                            rootFolderId={this.state.ticket.oo_folder_id!}
                            currentFolderId={this.state.ticket.oo_folder_id!}
                            selectableExtensions={["jpg", "jpeg", "png", "pdf", "doc", "docx"]}
                            onFileSelected={(fileId) => { this.setState({ showSelectDialog: false }); this.onFileSelected(fileId); }}
                            enabledActions={FILE_ACTIONS}
                        />
                    </Dialog>
                    :
                    null
                }
                <div className="column text-right">
                    <br />
                    <button className="button success" onClick={this.onSave.bind(this)}>
                        <i className="fa fa-save" /> {__("Üzenet küldése")}
                    </button>
                </div>
                {this.state.messages && this.state.messages.length > 0 ?
                    <div className="column small-12 messages-show">
                        <h4>{__("Ügyiratszám: ")} {this.state.ticket.no}</h4>
                        <h5>{__("Üzenetek: ")}</h5>
                        {this.state.messages.map((m, i) => {
                            let messageClass: string = (me && me.id == m.creation_user_id) ? "my-message" : "other-message";
                            return <div className={"callout " + messageClass} key={m.id}>
                                <div className="row">
                                    <div className="column"><b>{m.fullname}</b></div>
                                    <div className="column text-right">{formatDate(m.creation_time)}</div>
                                </div>
                                <p>{m.message_html}</p>
                            </div>
                        })}
                    </div>
                    : ""}
            </div>
        </div>;
    }

    private async handleTicketUserRead() {
        let mostRecentMessageTimestamp = databaseDateTimeToTimestamp(this.state.messages[0].creation_time!);
        const read = await HtrTicketUserReadCrud.list({filter: {htr_ticket_id: this.state.ticket.id, sec_user_id: me!.id}});
        let mostRecentUserReadTimestamp = databaseDateTimeToTimestamp(read[read.length - 1].read_date!);
        if (mostRecentMessageTimestamp >= mostRecentUserReadTimestamp)
            htrTicketUserReadCrudClassProxy.put({htr_ticket_id: this.state.ticket.id, sec_user_id: me!.id, read_date: new Date().getTime().toString()});
    }
    
    

    async onFileSelected(fileId: number) {
        const file = (await OoFileCrud.load(fileId)).record;
    }
}

type TicketingPageProps = {
    match: match<{ no: string }>;
}
type TicketingPageState = {
    loading: boolean,
    sidebarIsOpen: boolean,
    messageDialogIsOpen: boolean;
    messageCount: MessageCount;
}

type MessageCount = {
    all: number;
    started: number;
    responsible: number;
    trash: number;
}
export class TicketingPage extends React.Component<TicketingPageProps, TicketingPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            sidebarIsOpen: true,
            messageDialogIsOpen: false,
            messageCount: { all: 0, started: 0, responsible: 0, trash: 0 }
        };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: TicketingPageProps) {
        if (prevProps !== this.props) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {
        try {
            const all = await ViewHtrTicketAgent.count({
                filter: {
                    is_active: true,
                    $or: {
                        agent_user_id: me!.id,
                        sec_user_id: me!.id
                    }
                },
                distinct: true,
                columns: ["creation_time", "no", "subject", "title"],
            });
            const trash = await ViewHtrTicketAgent.count({
                filter: {
                    is_active: false,
                    $or: {
                        agent_user_id: me!.id,
                        sec_user_id: me!.id
                    }
                },
                distinct: true,
                columns: ["creation_time", "no", "subject", "title"],
            });
            const responsible = await ViewHtrTicketAgent.count({ filter: { is_active: true, agent_user_id: me!.id } });
            const started = await ViewHtrTicket.count({ filter: { is_active: true, sec_user_id: me!.id } });
            this.setState({
                loading: false,
                messageCount: { all, started, responsible, trash }
            })

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    onSidebarToggle(isOpen: boolean) {
        this.setState({ sidebarIsOpen: isOpen });
    }

    showMessageCategories() {
        this.setState({ messageDialogIsOpen: true });
    }

    render() {


        const menuItems: SidebarMenuItem[] = [];
        const menuTitle: JSX.Element = <button className="button primary" style={{ margin: 0 }} onClick={this.showMessageCategories.bind(this)}>{__("Új üzenet indítása")}</button>;

        menuItems.push({ name: `Összes levelezés (${this.state.messageCount.all})`, iconClass: "fas fa-envelope-square", linkTo: "/uzeno/levelezes" });
        menuItems.push({ name: `Általam indított (${this.state.messageCount.started})`, iconClass: "fas fa-arrow-alt-circle-right", linkTo: "/uzeno/levelezes/altalam" });
        menuItems.push({ name: `Beérkező jegyek (${this.state.messageCount.responsible})`, iconClass: "fas fa-inbox", linkTo: "/uzeno/levelezes/beerkezo" });
        menuItems.push({ name: `Kuka (${this.state.messageCount.trash})`, iconClass: "fas fa-trash", linkTo: "/uzeno/levelezes/kuka" });

        let messageCategoriesDialog = <Dialog open={this.state.messageDialogIsOpen} title={__("Új üzenet kategória választó")} onClose={() => this.setState({ messageDialogIsOpen: false })}>
            <div>
                <TicketCategoryTreeList dialogClose={() => this.setState({ messageDialogIsOpen: false })} />
            </div>
        </Dialog>

        return <>

            {messageCategoriesDialog}

            <SplitPane ref="splitPane" split="vertical" allowResize={this.state.sidebarIsOpen} maxSize={-150} size={this.state.sidebarIsOpen ? 350 : 50} defaultSize={350} style={{ position: "relative", minHeight: "100vh" }}>

                <Sidebar closable={true} title={menuTitle} menuItems={menuItems} onToggle={this.onSidebarToggle.bind(this)} />

                <SplitPane split="vertical" defaultSize={250} primary="second">
                    <div>
                        <Switch>
                            <Route exact path={PATH_TICKETING_PAGE} component={UserTickets} />
                            <Route exact path={PATH_TICKETING_MESSAGES} component={UserTickets} />
                            <Route exact path={PATH_TICKETING_MESSAGES + "/altalam"} component={() => <UserTickets messageFilter={MessageFilters.STARTED_BY_ME} />} />
                            <Route exact path={PATH_TICKETING_MESSAGES + "/beerkezo"} component={() => <UserTickets messageFilter={MessageFilters.INCOMING_TICKET} />} />
                            <Route exact path={PATH_TICKETING_MESSAGES + "/kuka"} component={() => <UserTickets messageFilter={MessageFilters.DELETED} />} />
                            <Route exact path={PATH_TICKETING_MESSAGES + "/:no"} component={TicketMessage} />
                            <Route exact path={PATH_TICKETING_MESSAGE + "/:categoryId"} component={TicketMessage} />
                        </Switch>
                    </div>

                    <div style={{ padding: "0.5rem" }}>
                        <TicketCategoryList className="" title="Üzenetküldés" />
                    </div>
                </SplitPane>



            </SplitPane>



        </>
    }
}

export enum MessageFilters {
    STARTED_BY_ME = 1,
    INCOMING_TICKET = 2,
    DELETED = 3
}

type UserTicketsProps = {
    messageFilter: MessageFilters
}
type UserTicketsState = {
    tickets: IViewHtrTicketRecord[],
    loading: boolean,
    count: number,
    itemsPerPage: number,
    rowEdit: any,
    onTrashPage: boolean
    unreadMessagesList: any[]
}
export default class UserTickets extends React.Component<UserTicketsProps, UserTicketsState> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            tickets: [],
            count: 0,
            itemsPerPage: 20,
            rowEdit: null,
            onTrashPage: false,
            unreadMessagesList: []
        };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private debouncedSetTableState = debounce((tablestate) => this.reloadAsync(tablestate), 200);

    private async reloadAsync(tablestate?: { page: number, pageSize: number, sorted: any, filtered: any[] }) {

        try {

            let page = tablestate ? tablestate.page : 0;
            let pageSize = tablestate ? tablestate.pageSize : this.state.itemsPerPage;

            let tickets: any = [];
            let count: number = 0;
            let onTrashPage: boolean = false;

            //Show all tickets (we started or we are responsible for it)
            if (!this.props.messageFilter || this.props.messageFilter == MessageFilters.DELETED) {
                onTrashPage = this.props.messageFilter ? true : false;
                tickets = (await ViewHtrTicketAgent.list({
                    filter: {
                        is_active: !onTrashPage,
                        $or: {
                            agent_user_id: me!.id,
                            sec_user_id: me!.id
                        },
                    },
                    distinct: true,
                    columns: ["id", "creation_time", "no", "subject", "title", "message_count"],
                    order_by: [{ name: "creation_time", desc: true }],
                    offset: page * pageSize,
                    limit: pageSize,
                }));
                count = await ViewHtrTicketAgent.count({
                    filter: {
                        is_active: !onTrashPage,
                        $or: {
                            agent_user_id: me!.id,
                            sec_user_id: me!.id
                        }
                    },
                    distinct: true,
                    columns: ["creation_time", "no", "subject", "title"],
                });
            }
            //Show incoming tickets (we are responsible for it) 
            else if (this.props.messageFilter == MessageFilters.INCOMING_TICKET) {
                tickets = (await ViewHtrTicketAgent.list({
                    filter: { is_active: true, agent_user_id: me!.id },
                    order_by: [{ name: "creation_time", desc: true }],
                    offset: page * pageSize,
                    limit: pageSize,
                }));
                count = await ViewHtrTicketAgent.count({ filter: { is_active: true, agent_user_id: me!.id } });
            }
            //Show the tickets we started
            else if (this.props.messageFilter == MessageFilters.STARTED_BY_ME) {
                tickets = (await ViewHtrTicket.list({
                    filter: { is_active: true, sec_user_id: me!.id },
                    order_by: [{ name: "creation_time", desc: true }],
                    offset: page * pageSize,
                    limit: pageSize,
                }));
                count = await ViewHtrTicket.count({ filter: { is_active: true, sec_user_id: me!.id } });
            }

            this.setState({
                tickets,
                loading: false,
                count: count,
                itemsPerPage: pageSize,
                onTrashPage,
                unreadMessagesList: await this.listUnreadMessages()
            })

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    async onDeleteTicket(id: number, e: any) {
        e.stopPropagation();
        if (!await confirmDialog(__("Törlés"), __("Biztosan törölni akarja a listából?"), __("Törlés"))) return;
        try {
            await HtrTicketCrud.deleteById(id);
            app.showSuccess(__("Üzenetváltás törlése"), __("Sikeres"))
            this.reloadAsync();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    async onRestoreTicket(id: number, e: any) {
        e.stopPropagation();
        if (!await confirmDialog(__("Visszaállítás"), __("Biztosan visszaállítja a jegyet?"), __("Visszaállítás"))) return;
        try {
            await HtrTicketCrud.unDeleteById(id);
            const messages = (await HtrTicketMessageCrud.list({ filter: { is_active: false, htr_ticket_id: id } }));
            messages.forEach(m => {
                HtrTicketMessageCrud.unDeleteById(m.id!);
            });
            app.showSuccess(__("Üzenetváltás visszaállítása"), __("Sikeres"))
            this.reloadAsync();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async listUnreadMessages() {
        let list: number[] = [];
        for (let i = 0; i < this.state.tickets.length; i++) {
            const ticket = this.state.tickets[i];
            const messages = await htrTicketMessageCrudClassProxy.list({ filter: { is_active: true,  htr_ticket_id: ticket.id } });
            if (messages[messages.length - 1].creation_user_id != me!.id) {
                let mostRecentMessageTimestamp = databaseDateTimeToTimestamp(messages[messages.length - 1].creation_time!);
                const read = await HtrTicketUserReadCrud.list({filter: {htr_ticket_id: ticket.id, sec_user_id: me!.id}});
                let mostRecentUserReadTimestamp = read.length > 0 ? databaseDateTimeToTimestamp(read[read.length - 1].read_date!) : 0;
                if (mostRecentMessageTimestamp > mostRecentUserReadTimestamp) list.push(ticket.id!)
            }
        }
        return list;
    }

    render() {

        if (this.state.loading) {
            return <BubbleLoader />
        }
        return <>

            <ReactTable className="message-list"
                columns={[
                    {
                        Header: __("Dátum"), accessor: "creation_time", sortable: true, filterable: false, className: "time", width: 180,
                        Cell: (data: { original: IViewHtrTicketRecord }, column: any) => {
                            return formatDate(data.original.creation_time || "")
                        }
                    },
                    {
                        Header: __("Felelős"), accessor: "default_agent", sortable: true, filterable: false, className: "default_agent", width: 80,
                        Cell: (data: { original: IViewHtrTicketRecord }, column: any) => {
                            return <ToolTipDiv title={data.original.default_agent ? data.original.default_agent : __("Nincs hozzárendelve felelős")} className=""><div style={{ textAlign: "center" }}><i className="fas fa-user-friends" ></i></div></ToolTipDiv>
                        }
                    },
                    { Header: __("Ügyszám"), accessor: "no", sortable: true, filterable: true, className: "no", width: 120, },
                    { Header: __("Tárgy"), accessor: "subject", sortable: true, filterable: true, className: "subject" , Cell: (data: { original: IViewHtrTicketRecord }, column: any) => {
                        return data.original.subject + ` (${data.original.message_count})` + (this.state.unreadMessagesList.includes(data.original.id!)? `🎈` : ``)
                    }},
                    { Header: __("Üzenet típusa"), accessor: "title", sortable: true, filterable: true, className: "title" },
                    {
                        Header: __("Művelet"), accessor: "actions", sortable: false, filterable: false, className: "time", width: 80,
                        Cell: (data: { original: IViewHtrTicketRecord }, column: any) => {
                            const deleteButton = <button className="button small alert" title={__("Törlés")} onClick={this.onDeleteTicket.bind(this, data.original.id)}><i className="fa fa-trash"></i></button>;
                            const restoreButton = <button className="button small success" title={__("Visszaállítás")} onClick={this.onRestoreTicket.bind(this, data.original.id)}><i className="fas fa-redo"></i></button>;
                            return this.state.onTrashPage ? restoreButton : deleteButton;
                        }
                    }

                ]}
                manual
                filterable
                sortable
                data={this.state.tickets}
                defaultSorted={[
                    {
                        id: "creation_time",
                        desc: true
                    }
                ]}
                defaultPageSize={this.state.itemsPerPage}
                pages={Math.ceil(this.state.count / this.state.itemsPerPage)}
                onFetchData={this.debouncedSetTableState}
                loading={this.state.loading}
                {...getReactTableLabels()}
                getTrProps={(state: any, rowInfo: any, column: any) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            onClick: () => {
                                if (rowInfo.index != this.state.rowEdit) {
                                    this.setState({
                                        rowEdit: rowInfo.index
                                    });
                                    history.push(PATH_TICKETING_PAGE + "/levelezes/" + rowInfo.original.no)
                                } else {
                                    this.setState({
                                        rowEdit: null
                                    });
                                }
                            },
                            className: rowInfo.index === this.state.rowEdit ? "selected" : "",
                        };
                    } else {
                        return {};
                    }
                }}
            />
        </>
    }
}


type InboxProps = {

}
type InboxState = {
    tickets: IHtrTicketRecord[],
    loading: boolean,
    count: number,
    itemsPerPage: number,
    rowEdit: any
}
export class Inbox extends React.Component<InboxProps, InboxState> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            tickets: [],
            count: 0,
            itemsPerPage: 10,
            rowEdit: null
        };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private debouncedSetTableState = debounce((tablestate) => this.reloadAsync(tablestate), 200);

    private async reloadAsync(tablestate?: { page: number, pageSize: number, sorted: any, filtered: any[] }) {

        try {

            let page = tablestate ? tablestate.page : 0;
            let pageSize = tablestate ? tablestate.pageSize : this.state.itemsPerPage;

            const tickets = (await HtrTicketCrud.list({
                filter: { is_active: true, sec_user_id: me!.id },
                order_by: [{ name: "creation_time", desc: true }],
                offset: page * pageSize,
                limit: pageSize,
            }));

            const count = await HtrTicketCrud.count({ filter: { is_active: true, sec_user_id: me!.id } });

            this.setState({
                tickets,
                loading: false,
                count: count,
                itemsPerPage: pageSize,
            })

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {

        if (this.state.loading) {
            return <BubbleLoader />
        }
        return <>

            <ReactTable className="message-list"
                columns={[
                    {
                        Header: __("Dátum"), accessor: "creation_time", sortable: true, filterable: false, className: "time", width: 250,
                        Cell: (data: { original: IHtrTicketRecord }, column: any) => {
                            return formatDate(data.original.creation_time || "")
                        }
                    },
                    { Header: __("Tárgy"), accessor: "subject", sortable: true, filterable: true, className: "subject" },

                ]}
                manual
                filterable
                data={this.state.tickets}
                defaultPageSize={this.state.itemsPerPage}
                pages={Math.ceil(this.state.count / this.state.itemsPerPage)}
                onFetchData={this.debouncedSetTableState}
                loading={this.state.loading}
                {...getReactTableLabels()}
                getTrProps={(state: any, rowInfo: any) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            onClick: () => {

                                if (rowInfo.index != this.state.rowEdit) {
                                    this.setState({
                                        rowEdit: rowInfo.index
                                    });
                                    history.push(PATH_TICKETING_PAGE + "/levelezes/" + rowInfo.original.no)
                                } else {
                                    this.setState({
                                        rowEdit: null
                                    });
                                }
                            },
                            className: rowInfo.index === this.state.rowEdit ? "selected" : "",
                        };
                    } else {
                        return {};
                    }
                }}
            />
        </>
    }
}