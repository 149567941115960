import * as React from 'react';
import { Switch, Route, match } from 'react-router';
import { NavLink } from 'react-router-dom';
import { History } from 'history';
import SplitPane from 'react-split-pane';
import { me, hasAnyGroup, Groups, hasGroup } from '@src/framework/server/Auth';
import MediaFileChooser, { ALL_ACTIONS } from '@src/component/filemanager/MediaFileChooser';
import { ItemStyle } from '@src/component/filemanager/ContentItem';
import PermissionPage from '@src/framework/PermissionPage';
import Sidebar from '@src/component/Sidebar';
import { PATH_CONTENT_STORE, PATH_CONTENT_STORE_SHARED_WITH_ME, PATH_CONTENT_STORE_SHARED_BY_ME, PATH_EXERCISE_LIST, PATH_EXERCISE_SERIES_LIST, PATH_LEARNING_PATH, getPath } from '@src/Routes';
import { ContentHistoryPage } from '@src/component/filemanager/ContentHistoryPage';
import SharedByMePage from './SharedByMePage';
import SharedWithMePage from './SharedWithMePage';
import ExerciseList from '../exercise/Viewer/ExerciseList';
import ExerciseSeriesList from '../exercise/ExerciseSeries/ExerciseSeriesList';
import CollectionList from '../collection/CollectionList';
import { Collection } from '../collection/Collection';

import { OFIAltTaggerPage } from './ofi/OFIAltTaggerPage';
import OFIBrokenLinksPage from './ofi/OFIBrokenLinksPage';
import OFIQuickTaggerPage from './ofi/OFIQuickTaggerPage';
import { OFISearchPage } from './ofi/OFISearchPage';
import { OFIOriginTablePage } from '@src/component/filemanager/ofi/OFIOriginTablePage';
import { OFIBookHeadersPage } from './ofi/OFIBookHeadersPage';
import { OFIEmbeddedListPage } from './ofi/OFIEmbeddedListPage';
import RenderIfHasPermission from '@src/framework/RenderIfHasPermission';
import ExerciseCrud from '@src/framework/crud/exc/ExerciseCrud';
import ExerciseSeriesCrud from '@src/framework/crud/exc/ExerciseSeriesCrud';
import { CrudPermissionType } from '@src/framework/security/PermissionCache';
import { FavoriteList } from './FavouriteComponent';
import { OFIInvalidExercisesList } from '../exercise/Viewer/ExerciseValidationResults';
import { __ } from '@src/translation';
import { OFIUnlinkedPicturesPage } from './ofi/OFIUnlinkedPicturesPage';
import { OFIConvertedLinksPage } from './ofi/OFIConvertedLinks';
import LearningPathList from '../learningpath/LerningPathList';
import MyProfileAPI, { IGetMyProfileResult } from '../usr/usr_profile_api';
import { exerciseModule } from '@src/module/exerciseModule/exerciseModule';
const filesize = require('filesize');

export type ContentStoreExtraComponent = {
    name: string;
    iconClass: string;
    linkTo: string;
    routeKey: string;
    routePath: string;
    component: React.ComponentType<any>;
}

export type ContentStoreConfig = {
    enableFavourites?: boolean;
    enableExercises?: boolean;
    enableSharing?: boolean;
    enableCollections?: boolean;
    enableLearningPath?: boolean;
    enableHistory?: boolean;
    commonFolderId?: number | null;
    extraComponents?: ContentStoreExtraComponent[]
}

let config: ContentStoreConfig = {};

export function configureContentStore(newConfig: ContentStoreConfig) {
    config = newConfig;
}

type ContentStoreProps = {
    match: match<{}>,
    history: History
}
type ContentStoreState = {
}

export default class ContentStore extends React.Component<ContentStoreProps, ContentStoreState> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    render() {
        const path = this.props.history.location.pathname;

        return <PermissionPage requirements={{

        }}>
           
                <SplitPane split="vertical" minSize={150} maxSize={-150} defaultSize={350} style={{ position: "relative", minHeight: "100vh" }}>

                    <Sidebar>
                        <ul>
                            <li>
                                <NavLink to={PATH_CONTENT_STORE + "/sajat"}><i className="fa fa-fw fa-file" /> {__("Saját fájlok")}</NavLink>
                            </li>

                            <li className="separator"></li>

                            {
                                config.enableExercises &&
                                <>
                                <RenderIfHasPermission requirements={{ crud: { permissions: CrudPermissionType.U, tableInfoId: ExerciseCrud.TABLE_INFO_ID } }}>
                                    <li>
                                        <NavLink to={getPath({PATH_EXERCISE_LIST}, exerciseModule)}><i className="fa fa-fw ico nkp-icon-my-lessons-repo" /> {__("Feladatlista")}</NavLink>
                                    </li>
                                </RenderIfHasPermission>
                                <RenderIfHasPermission requirements={{ crud: { permissions: CrudPermissionType.U, tableInfoId: ExerciseSeriesCrud.TABLE_INFO_ID } }}>
                                    <li>
                                        <NavLink to={getPath({PATH_EXERCISE_SERIES_LIST}, exerciseModule)}><i className="fa fa-fw ico nkp-icon-excersises-repo" /> {__("Feladatsorlista")}</NavLink>
                                    </li>
                                </RenderIfHasPermission>
                                <li className="separator"></li>
                                </>
                            }


                            {
                                config.enableSharing &&
                                <>
                                <li>
                                    <NavLink to={PATH_CONTENT_STORE_SHARED_WITH_ME}><i className="fa fa-fw fa-share-alt" /> {__("Velem megosztott tartalmak")}</NavLink>
                                </li>
                                <li>
                                    <NavLink to={PATH_CONTENT_STORE_SHARED_BY_ME}><i className="fa fa-fw fa-share-alt-square" /> {__("Általam megosztott tartalmak")}</NavLink>
                                </li>
                                <li className="separator"></li>
                                </>
                            }

                            {
                                config.enableFavourites &&
                                <li>
                                    <NavLink to={PATH_CONTENT_STORE + "/kedvencek"}><i className="fas fa-star" /> {__("Kedvenceim")}</NavLink>
                                </li>
                            }

                            {
                                config.enableCollections &&
                                <li>
                                    <NavLink to={PATH_CONTENT_STORE + "/gyujtemeny"}><i className="fa fa-fw fa-clipboard-list" /> {__("Gyűjteményeim")}</NavLink>
                                </li>
                            }

                            {config.enableLearningPath && me && hasAnyGroup(me,[Groups.Developer, Groups.Admin]) ?
                                <li>
                                    <NavLink to={PATH_LEARNING_PATH}><i className="fa fa-fw fa-clipboard-list" /> {__("Tanulási útvonalaim")}</NavLink>
                                </li> : ""
                            }

                            {
                                config.enableHistory &&
                                <li>
                                    <NavLink to={PATH_CONTENT_STORE + "/elozmenyek"}><i className="fa fa-fw fa-arrow-circle-left" /> {__("Előzmények")}</NavLink>
                                </li>
                            }

                            {
                                config.commonFolderId !== undefined &&
                                <>
                                <li className="separator"></li>
                                <li className="separator"></li>
                                <li>
                                    <NavLink to={PATH_CONTENT_STORE + "/kozos" + (config.commonFolderId ? "/" + config.commonFolderId : "")}>
                                        <i className="fa fa-fw fa-archive" /> {__("Közös fájlok")}
                                    </NavLink>
                                </li>
                                </>
                            }

                            {
                                config.extraComponents && config.extraComponents.map((c, index) => {
                                    return <li key={index}>
                                        <NavLink to={c.linkTo}><i className={c.iconClass} /> {c.name}</NavLink>
                                    </li>;
                                })
                            }

                        </ul>
                    </Sidebar>

                    <div className="column" style={{ marginTop: "1em" }}>
                        <Switch>
                            <Route path={PATH_CONTENT_STORE + "/sajat/:folderId?"} component={ContentStoreMyFiles} />


                            <Route path={PATH_CONTENT_STORE_SHARED_WITH_ME} component={SharedWithMePage} />

                            <Route path={PATH_CONTENT_STORE_SHARED_BY_ME} component={SharedByMePage} />

                            <Route path={PATH_CONTENT_STORE + "/kedvencek"} component={FavoriteList} />

                            <Route path={PATH_CONTENT_STORE + "/gyujtemeny/:collectionId"} component={Collection} />

                            <Route path={PATH_CONTENT_STORE + "/gyujtemeny"} component={CollectionList} />

                            <Route path={PATH_CONTENT_STORE + "/elozmenyek"} component={ContentHistoryPage} />

                            <Route path={getPath({PATH_EXERCISE_LIST}, exerciseModule)} component={ExerciseList} />

                            <Route path={getPath({PATH_EXERCISE_SERIES_LIST}, exerciseModule)} component={ExerciseSeriesList} />

                            <Route path={PATH_LEARNING_PATH} component={LearningPathList} />


                            {/* Project */}
                            <Route path={PATH_CONTENT_STORE + "/kozos/:folderId?"} component={ContentStoreCommonFiles} />

                            {
                                config.extraComponents && config.extraComponents.map((c, index) => {
                                    return <Route key={index} path={c.routePath} component={c.component} />;
                                })
                            }
                            
                        </Switch>
                    </div>
                </SplitPane>
        </PermissionPage>
            ;
    }

}

type ContentStoreMyFilesProps = {
    match: match<{ folderId: string }>;
    history: History
}

type ContentStoreMyFilesState = {
    profile?: IGetMyProfileResult
}

export class ContentStoreMyFiles extends React.Component<ContentStoreMyFilesProps, ContentStoreMyFilesState> {

    constructor(props: ContentStoreMyFilesProps) {
        super(props);
        this.state = {
            profile: undefined
        }
    }

    componentDidMount() {
        this.asyncReload();
    }

    componentDidUpdate(prevprops: ContentStoreMyFilesProps ,prevstate: ContentStoreMyFilesState) {
        if(this.state.profile == undefined) {
            this.asyncReload();
        }
    }

    onFolderSelected(folderId: number | null) {
        this.props.history.push(PATH_CONTENT_STORE + "/sajat/" + (folderId ? folderId : ""));//question
    }

    async asyncReload() {
        try {
            const profile: IGetMyProfileResult = await MyProfileAPI.get();
            this.setState({profile});
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        if (!me || !me.oo_folder_id) {
            return null;
        }


        const profile = this.state.profile!;
        let storagePercent = profile && (profile.disk_space_used / profile.hard_quota) * 100;
        storagePercent = storagePercent > 100 ? 100 : storagePercent;
        let progressBg = "#2ba6cb";
        if (profile && profile.disk_space_used < profile.soft_quota && profile.disk_space_used < profile.hard_quota) {
            progressBg = "#2ba6cb";
        }
        else if (profile && profile.disk_space_used > profile.soft_quota && profile.disk_space_used < profile.hard_quota) {
            progressBg = "#f4b342";
        }
        else {
            progressBg = "#7a031b";
        }


        return <>
          {profile && 
                <div className="column small-12">               
                    <strong>{__("Felhasznált tárterület a megadott {quota}-ból: {used}", { quota: filesize(profile.hard_quota), used: filesize(profile.disk_space_used) })}</strong>
                    <br />
                    <div style={{ width: '100%' }} className="progress" role="progressbar" tabIndex={1} aria-valuenow={profile.disk_space_used} aria-valuemin={0} aria-valuemax={profile.hard_quota}>
                        <span className="progress-meter" style={{ width: storagePercent + "%", backgroundColor: progressBg }}>
                            <p className="progress-meter-text"></p>
                        </span>
                    </div>
                </div>
            }
            <MediaFileChooser
                defaultStyle={ItemStyle.LIST}
                rootFolderId={me.oo_folder_id}
                currentFolderId={this.props.match.params.folderId ? Number(this.props.match.params.folderId) : me.oo_folder_id}
                onFolderSelected={this.onFolderSelected.bind(this)}
                enabledActions={ALL_ACTIONS}
            />
          
        </>
    }
}

type ContentStoreCommonFilesProps = {
    match: match<{ folderId: string }>;
    history: History
}

export class ContentStoreCommonFiles extends React.Component<ContentStoreCommonFilesProps, {}> {

    onFolderSelected(folderId: number | null) {
        this.props.history.push(PATH_CONTENT_STORE + "/kozos/" + (folderId ? folderId : "")); //question
    }

    render() {
        if (!me || !me.oo_folder_id) {
            return null;
        }

        return <MediaFileChooser
            defaultStyle={ItemStyle.LIST}
            rootFolderId={config.commonFolderId|| null}
            currentFolderId={this.props.match.params.folderId ? Number(this.props.match.params.folderId) : null}
            onFolderSelected={this.onFolderSelected.bind(this)}
            enabledActions={ALL_ACTIONS}
        />
            ;
    }
}

