import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapCourseSchoolInvitationRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Képzés
     */
    course_id?: number;
    /**
     * Létrehozó
     *
     * Létrehozó
     */
    creator?: string|null;
}

/**
 *  kap.view_kap_course_school_invitation - Képzés meghívó
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewKapCourseSchoolInvitation extends View<IViewKapCourseSchoolInvitationRecord> {
    public static VIEW_INFO_ID = 2020061101;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_course_school_invitation'; }
    public static getTableNameForClass(): string|null { return 'course_school_invitation'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapCourseSchoolInvitation>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapCourseSchoolInvitationRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapCourseSchoolInvitationClassProxy extends ViewClassProxy<IViewKapCourseSchoolInvitationRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapCourseSchoolInvitationClassProxy = new ViewKapCourseSchoolInvitationClassProxy(ViewKapCourseSchoolInvitation);
registerViewClassProxy(viewKapCourseSchoolInvitationClassProxy);

