// select id,title from usr.forum_permtype order by id asc

export enum ForumPermTypes {
    TOPIC_CREATE_ID = 201, // Topic létrehozás
    TOPIC_MODIFY_ID = 202, // Topic módosítás
    TOPIC_DELETE_ID = 203, // Topic törlés
    MESSAGE_SEND_ID = 301, // Hozzászólás beküdés
    MESSAGE_REMOVE_MINE_ID = 302, // Hozzászólás törlés (saját)
    MESSAGE_MODIFY_MINE_ID = 303, // Hozzászólás módosítás (saját)
    MESSAGE_MODIFY_OTHER_ID = 311, // Hozzászólás módosítás (másik tag)
    MESSAGE_REMOVE_OTHER_ID = 312, // Hozzászólás törlés (másik tag)
    MEMBER_ADD_ID = 501, // Tag hozzáadás
    MEMBER_REMOVE_ID = 502 // Tag törlés/kirúgás}
}
