import { callApiThrowOnError } from "@src/framework/api/Api";

export type AddTranslationsParams = {
    translations: {
        key: string,
        value?: string,

        // Details:
        status?: number,
        history?: {
            created: string,
            userId: number,
            userName: string,
            value: string
        }[]
    }[],
};

export type AddTranslationsResult = any;

export async function addTranslations(parameters: AddTranslationsParams): Promise<AddTranslationsResult> {
    return callApiThrowOnError<AddTranslationsParams, AddTranslationsResult>("/translation/addTranslations", parameters);
}
