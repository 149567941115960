import * as React from 'react';
import { IRecord as ICrudRecord } from '@src/framework/crud/Crud';
import View, { IRecord as IViewRecord, ViewClassProxy } from '@framework/view/View';

import ListView from './listview';
import RecordEditor from './recordeditor';

interface InplaceListEditorProps {
    onGetRecordEditor : (inplaceListEditor: InplaceListEditor, record_id: number|null) => JSX.Element|null; // RecordEditor<ICrudRecord>;
    onGetListView : (inplaceListEditor: InplaceListEditor) => JSX.Element|null; // ListView<IViewRecord>;
}

enum InplaceListEditorMode {
    List=1, Edit=2
}

interface InplaceListEditorState {
    mode: InplaceListEditorMode;
    record_id : number|null;
}

export default class InplaceListEditor extends React.Component<InplaceListEditorProps, InplaceListEditorState> {
    constructor(props: InplaceListEditorProps) {
        super(props);
        this.state = {mode: InplaceListEditorMode.List, record_id: null};
    }

    public onEditRecord = (record_id: number|null) => {
        this.setState({mode: InplaceListEditorMode.Edit, record_id: record_id})
    }

    public onList = () => {
        this.setState({mode: InplaceListEditorMode.List})
    }

    render() {
        if (this.state.mode==InplaceListEditorMode.Edit) {
            return this.props.onGetRecordEditor(this, this.state.record_id);
        } else {
            return this.props.onGetListView(this);
        }
    }
}