import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IGeneratedDocumentTypeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Kód
     */
    code?: string;
    /**
     * Név
     */
    title?: string;
    /**
     * Dokumentum formátum
     *
     * -> doc.document_format ON DELETE noaction
     *
     */
    document_format_id?: number|null;
    /**
     * Mihez tartozik - Táblázat infó
     *
     * -> meta.table_info ON DELETE noaction
     *
     *
     * Csak akkor van értelmezve, ha a dokumentum egyetlen konkrét táblázathoz kötődik.
     */
    table_info_id?: number|null;
    /**
     * Állománynév prefix
     */
    filename_prefix?: string|null;
    /**
     * Állománynév postfix
     */
    filename_postfix?: string|null;
    /**
     * Alkönyvtár
     *
     * Tömeges letöltés esetén dokumentum típusonkénti csoportosításhoz
     */
    subfolder?: string|null;
}

/**
 *  doc.generated_document_type - Generált dokumentum típus
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class GeneratedDocumentTypeCrud extends Crud<IGeneratedDocumentTypeRecord> {
    public static TABLE_INFO_ID = 12491634;
    public static getSchemaNameForClass() { return 'doc'; }
    public static getTableNameForClass() { return 'generated_document_type'; }

    public static load: (id: number, aServer ?: Server) => Promise<GeneratedDocumentTypeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IGeneratedDocumentTypeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IGeneratedDocumentTypeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IGeneratedDocumentTypeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IGeneratedDocumentTypeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IGeneratedDocumentTypeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class GeneratedDocumentTypeCrudClassProxy extends CrudClassProxy<IGeneratedDocumentTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const generatedDocumentTypeCrudClassProxy = new GeneratedDocumentTypeCrudClassProxy(GeneratedDocumentTypeCrud);
registerCrudClassProxy(generatedDocumentTypeCrudClassProxy);
