import * as React from 'react';
import Downshift, { ControllerStateAndHelpers } from 'downshift';
import { debounce } from 'lodash';


import './autocompleteedit.css';
import { AutoCompleteSearchResult, searchAutoComplete } from '@src/server/PublicServer';
import { app } from '@src/index';
import { element } from 'prop-types';
import { __ } from '@src/translation';

export type AutoCompleteItemSelected = (selectedValue: string) => void;

export interface IAutoCompleteEditProps {
    placeholder?: string;
    value: string;
    onValueChange: (value: string) => void;
    onKeyPress?: (value: string) => void;
    onItemSelected?: AutoCompleteItemSelected;
    filterFunction?:(value:string)=>Promise<string[]>;
}

export interface IAutoCompleteEditState {
    items: string[];
    searchTextChanged: boolean;
}


export const DEFAULT_PLACEHOLDER: string = "Kezdjen el gépelni...";
const ASYNC_RELOAD_DEBOUNCE_MSEC = 300;



export default class AutoCompleteEdit extends React.Component<IAutoCompleteEditProps, IAutoCompleteEditState> {
    private debouncedDownshiftInputValueChange: any = undefined;
    private willUnmount: boolean;

    constructor(props: IAutoCompleteEditProps) {
        super(props);
        this.state = { items: [], searchTextChanged: false };
        // Ez csak arra kell, hogy ne küldjön új request-et minden egyes billentyű leütéshez. 
        this.debouncedDownshiftInputValueChange = debounce(this.onInputValueChange, ASYNC_RELOAD_DEBOUNCE_MSEC)
        this.willUnmount = true;
    }


    componentDidMount() {
        this.willUnmount = false;
    }

    componentWillMount() {
        this.willUnmount = true;
    }

    private onSelect = (selectedItem: string, stateAndHelpers: ControllerStateAndHelpers<string>) => {
        if (this.props.onItemSelected) {
            this.props.onItemSelected(selectedItem);
        }
    }

    private onInputValueChange = async (inputValue: string, stateAndHelpers: ControllerStateAndHelpers<string>) => {
        try {
            if(!this.props.filterFunction){
                let hits: AutoCompleteSearchResult[] = [];
                if (inputValue && inputValue.trim()!="") {
                    hits = await searchAutoComplete(inputValue);
                }
                let items: string[] = hits.map((hit) => hit.text);
                this.setState({ items, searchTextChanged: false });
            }else{
                //let items: string[] = this.props.itemList.filter(element => {return element.includes(inputValue)});
                let items = await this.props.filterFunction(inputValue);
                this.setState({ items, searchTextChanged: false });
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    // Kereső kifejezés beírásakor hívódik meg, debounce-oljuk.
    private onDownshiftInputValueChange = async (inputValue: string, stateAndHelpers: ControllerStateAndHelpers<string>) => {
        if (!stateAndHelpers.isOpen) {
            return;
        }

        this.props.onValueChange(inputValue);
       
        if (!this.willUnmount    // Ez megakadályozza, hogy a debounced függvény olyan komponensre hívódjon meg, ami már nincs mount-olva.
            /* && !this.state.loading  // Ez kikapcsolja a szöveges keresést arra az időre, amíg az új kiválasztott értéket beállítjuk. */
        ) {
            this.setState({ searchTextChanged: true }, () => { this.debouncedDownshiftInputValueChange(inputValue, stateAndHelpers) });
        }
    }    

    render() {

        return <Downshift
            defaultSelectedItem={this.props.value}
            defaultHighlightedIndex={0}
            itemToString={(item) => { return item || '' }}
            onSelect={this.onSelect}
            inputValue={this.props.value ? this.props.value : ""}
            onInputValueChange={this.onDownshiftInputValueChange}
        >
            {({
                getInputProps,
                getItemProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
            }) => {
                let hits : string[] = this.state.items.concat();
                if (inputValue && !hits.includes(inputValue)) {
                    hits.unshift(inputValue);
                }

                return (
                    <div className="input-field" role="" aria-haspopup="false">
                        <input {...getInputProps()} onKeyPress={this.props.onKeyPress} type="text" placeholder={this.props.placeholder || __(DEFAULT_PLACEHOLDER)} aria-haspopup="false" aria-autocomplete="none"/>
                        <ul {...getMenuProps()} className="autocompleteedit" aria-hidden="true">  
                            {isOpen
                                ? hits.map((item, index) => (
                                    <li
                                        {...getItemProps({
                                            key: "" + index,
                                            index,
                                            item,
                                            style: {
                                                backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',                                       
                                            },
                                        })}
                                    >
                                        {item}
                                    </li>
                                ))
                                : null}
                        </ul>
                    </div>
                );
                }
            }
        </Downshift>;
    }
}