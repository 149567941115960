import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewOhInspectorRecord {
    /**
     * Felhasználó azonosítója
     *
     * Felhasználó azonosítója
     */
    id?: number;
    /**
     * Bejelentkezési név
     *
     * Bejelentkezési név
     */
    login_name?: string|null;
    /**
     * E-mail cím
     *
     * E-mail cím
     */
    email?: string|null;
    /**
     * Aktív
     *
     * Aktív
     */
    is_active?: boolean|null;
    /**
     * Személy azonosító
     *
     * Személy azonosító
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * Teljes név
     */
    fullname?: string|null;
}

/**
 *  sys.view_oh_inspector - OH monitoring
 */

export default class ViewOhInspector extends View<IViewOhInspectorRecord> {
    public static VIEW_INFO_ID = 2020010801;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_oh_inspector'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewOhInspector>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewOhInspectorRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewOhInspectorClassProxy extends ViewClassProxy<IViewOhInspectorRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewOhInspectorClassProxy = new ViewOhInspectorClassProxy(ViewOhInspector);
registerViewClassProxy(viewOhInspectorClassProxy);

