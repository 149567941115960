import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ILpLearningPathRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Cím
     */
    title?: string;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Könyvtár
     *
     * NOT NULL -> doc.library ON DELETE cascade
     *
     */
    library_id?: number;
    /**
     * Tulajdonos/felelős
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     */
    owner_id?: number;
    /**
     * Évfolyam
     *
     * -> doc.grade ON DELETE cascade
     *
     */
    grade_id?: number|null;
    /**
     * Tantárgy
     *
     * -> doc.subject ON DELETE cascade
     *
     */
    subject_id?: number|null;
    /**
     * Tároló
     *
     * -> media.oo_folder ON DELETE noaction
     *
     *
     * Az a mappa, ami a tanulási útvonalhoz tartozó médiákat tárolja.
     */
    oo_folder_id?: number|null;
    /**
     * Kezdő csomópont
     *
     * -> exc.lp_node ON DELETE cascade
     *
     */
    start_node_id?: number|null;
}

/**
 *  exc.lp_learning_path - Tanulási útvonal
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class LpLearningPathCrud extends Crud<ILpLearningPathRecord> {
    public static TABLE_INFO_ID = 3006178047;
    public static getSchemaNameForClass() { return 'exc'; }
    public static getTableNameForClass() { return 'lp_learning_path'; }

    public static load: (id: number, aServer ?: Server) => Promise<LpLearningPathCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ILpLearningPathRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ILpLearningPathRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ILpLearningPathRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ILpLearningPathRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ILpLearningPathRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class LpLearningPathCrudClassProxy extends CrudClassProxy<ILpLearningPathRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const lpLearningPathCrudClassProxy = new LpLearningPathCrudClassProxy(LpLearningPathCrud);
registerCrudClassProxy(lpLearningPathCrudClassProxy);
