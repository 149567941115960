import * as React from 'react';
import { match } from 'react-router';
import ExerciseContainerComponent from '../exercise/ExerciseContainerComponent';
import { ExerciseServer } from '../exercise/ExerciseServer';

import { history, app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import { Breadcrumbs } from '../Breadcrumbs';
import { PATH_CONTENT_STORE, PATH_SHARED_CONTENT_WITH_ME, PATH_CONTENT_STORE_SHARED_WITH_ME, PATH_SHARED_CONTENT_RUN_CHECK_MINE } from '@src/Routes';
import SharedContentRunCrud, { ISharedContentRunRecord } from '@src/framework/crud/usr/SharedContentRunCrud';
import ViewSharedWithMe, { IViewSharedWithMeRecord } from '@src/framework/view/usr/ViewSharedWithMe';
import { formatDate } from '@src/Util';
import { ContentDetails, getContentDetails } from '../filemanager/ContentUtils';
import ExerciseCrudPub from '@src/framework/crud/exc_pub/ExerciseCrud';
import ExerciseSeriesCrudPub from '@src/framework/crud/exc_pub/ExerciseSeriesCrud';
import { __ } from '@src/translation';
import OpenEndedQuestionCrud, { IOpenEndedQuestionRecord } from '@src/framework/crud/usr/OpenEndedQuestionCrud';
import { OpenEndedExerciseAnswer } from '../exercise/Editor/OpenEndedExerciseAnswer';
import { Link } from 'react-router-dom';
import { ClassroomModule } from '@src/module/classroomModule/classroomModule';

const SHARE_MODE_EXAM_ID = 3;

type RunSharedWithMeProps = {
    match: match<{ sharedContentRunId: string }>
}

type RunSharedWithMeState = {
    exerciseServer?: ExerciseServer;
    sharedContentRun?: ISharedContentRunRecord;
    sharedContent?: IViewSharedWithMeRecord;
    contentDetails?: ContentDetails;
    isFinished: boolean;
    isExamMode: boolean
}

export class RunSharedWithMe extends React.Component<RunSharedWithMeProps, RunSharedWithMeState> {

    constructor(props: RunSharedWithMeProps) {
        super(props);

        this.state = { isFinished: false, isExamMode: false }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: RunSharedWithMeProps) {
        if (prevProps.match.params.sharedContentRunId !== this.props.match.params.sharedContentRunId) {
            this.reloadAsync();
        }
    }

    async reloadAsync() {
        try {
            const sharedContentRunId = Number(this.props.match.params.sharedContentRunId);

            const sharedContentRun = (await SharedContentRunCrud.load(sharedContentRunId)).record;
            const sharedContent = (await ViewSharedWithMe.load(sharedContentRun.shared_content_id!)).record;

            let isExam = false;
            const contentDetails = await getContentDetails(sharedContent.table_info_id!, sharedContent.rec_id!);
            let exerciseServer = undefined;
            let openEndedQuestion = undefined;

            if (sharedContent.table_info_id != OpenEndedQuestionCrud.TABLE_INFO_ID) {
                exerciseServer = new ExerciseServer([ExerciseCrudPub.TABLE_INFO_ID, ExerciseSeriesCrudPub.TABLE_INFO_ID].includes(sharedContent.table_info_id!));
                exerciseServer.setShareContentRunId(Number(this.props.match.params.sharedContentRunId));

                isExam = sharedContent.share_mode_id == SHARE_MODE_EXAM_ID;
            }

            this.setState({
                exerciseServer,
                sharedContentRun,
                sharedContent,
                contentDetails,
                isExamMode: isExam
            });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }

    }

    exerciseFinished() {
        const sharedContentRun = this.state.sharedContentRun;
        const isExam = this.state.isExamMode;
        if (isExam && sharedContentRun) {
            history.push(PATH_SHARED_CONTENT_RUN_CHECK_MINE + "/" + sharedContentRun.id);
        }
        this.setState({ isFinished: true });
    }

    render() {
        const sharedContent = this.state.sharedContent;
        const sharedContentRun = this.state.sharedContentRun;
        let links: any[] = [];
        if (sharedContent && sharedContentRun && this.state.contentDetails) {
            //If the component received from links to the Breadcrumbs we use them
            if (sharedContent.shared_from_institute_group_id) {
                links = ClassroomModule.getTaskBreadCrumbs(sharedContent.shared_from_institute_group_id, sharedContent.shared_from_institute_group_name || "");
                links.push({ name: sharedContent.title, path: PATH_SHARED_CONTENT_WITH_ME + "/" + sharedContent.id });
            }
            else {
                links = [
                    { name: __("Tartalomtár"), path: PATH_CONTENT_STORE },
                    { name: __("Nekem kiosztott feladatok"), path: PATH_CONTENT_STORE_SHARED_WITH_ME },
                    { name: sharedContent.title, path: PATH_SHARED_CONTENT_WITH_ME + "/" + sharedContent.id },
                ];
            }
            let returnURL = links[links.length - 1].path;
            links.push({ name: formatDate(sharedContentRun.creation_time) });

            if (sharedContentRun.finished != null && sharedContentRun.finished != undefined) {
                return <div><h3>{__("A feladat már nem folytatható!")}</h3></div>;
            }

            return <div className="row expanded">
                <div className="column small-12">
                    <Breadcrumbs links={links} />

                    {sharedContent.due_date && <p>{__("Leadás határideje")}: {formatDate(sharedContent.due_date)}</p>}

                    {this.state.exerciseServer && <div>
                        {this.state.isFinished && this.state.isExamMode ?
                            <h3>{__("Sikeres feladat megoldás!")}</h3>
                            :
                            <ExerciseContainerComponent
                                server={this.state.exerciseServer}
                                examMode={sharedContent.share_mode_id === SHARE_MODE_EXAM_ID}
                                finished={() => this.exerciseFinished()}
                            />}
                        <div>
                            {this.state.isFinished && <Link to={PATH_SHARED_CONTENT_RUN_CHECK_MINE + "/" + sharedContentRun.id} className="button large success float-right">{__("Értékelés megtekintése")} &nbsp;<i className="fas fa-arrow-circle-right"></i></Link>}
                        </div>
                    </div> 
                    }
                    {sharedContent.table_info_id == OpenEndedQuestionCrud.TABLE_INFO_ID &&
                        <OpenEndedExerciseAnswer sharedContentId={sharedContent.id!} sharedContentRunId={sharedContentRun.id!}
                            recordId={sharedContent.rec_id!}
                            returnUrl={returnURL}
                            isTeacher={false}
                            isEvaluated={false}
                        />
                    }
                </div>
            </div>
        } else {
            return <BubbleLoader />;
        } 
    }

}
