import * as React from 'react';

import { CrudClassProxy, IRecord as ICrudRecord } from '@framework/crud/Crud';
import { ViewClassProxy, IRecord as IViewRecord } from '@framework/view/View';
import LookupEdit from '@framework/forms/lookupedit';

import { app } from '@src/index';
import SecPermOwnerCrud from '@src/framework/crud/sys/SecPermOwnerCrud';

import './permlisteditor.css';

type PermItemType = ICrudRecord | IViewRecord;
type PermItemToLabelEvent = (item: PermItemType, lastAddedOwnerId: number | null) => JSX.Element;
type PermOwnerEvent = (permOwnerId: number) => void;

interface IPermListEditorProps {
    connectorCrud: CrudClassProxy<ICrudRecord>;
    connectorView: CrudClassProxy<ICrudRecord> | ViewClassProxy<IViewRecord>;
    masterFieldName: string;
    permRecordFieldName?: string;
    masterRecordId: number | null;
    itemToLabel?: PermItemToLabelEvent;
    title: string;
    orderBy?: string;
    selectorPlaceholder ?: string;

    onPermOwnerAdded?: PermOwnerEvent;
    onPermOwnerRemoved?: PermOwnerEvent;
}

interface IPermListEditorState {
    items: PermItemType[];
    perm_owner_id: number | null;
    last_added_owner_id: number | null;
}

export default class PermListEditor
    extends React.Component<IPermListEditorProps, IPermListEditorState> {
    constructor(props: IPermListEditorProps) {
        super(props);
        this.state = { items: [], perm_owner_id: null, last_added_owner_id: null };
        this.asyncInit();
    }

    private async asyncInit(): Promise<any> {
        return this.asyncReload();
    }

    private async asyncReload(): Promise<any> {
        this.props.connectorView.list(
            {
                filter: { [this.props.masterFieldName]: this.props.masterRecordId },
                order_by: this.props.orderBy || 'perm_owner_label'
            }
        )
            .then((items) => { this.setState({ items }) })
            .catch((error) => { app.showErrorFromJsonResult(error); })
    }

    private itemToLabel(item: PermItemType): JSX.Element {
        let inner: JSX.Element;
        if (this.props.itemToLabel) {
            inner = this.props.itemToLabel(item, this.state.last_added_owner_id);
        } else {
            const itemTitle: string =
                'Hozzáadva: ' + item["creator"] + " @ " + item["creation_time"];
            inner = <span title={itemTitle}>{item["perm_owner_label"]}</span>;
            if (item[this.getPermRecordFieldName()] == this.state.last_added_owner_id) {
                inner = <b>{inner}</b>;
            }
        }
        return <li key={item.id!}>
            <button
                className="permlisteditor-button permlisteditor-button-secondary"
                title="Eltávolít"
                onClick={() => this.onRemovePermOwner(item.id!)}
            ><i className="fa fa-times" />
            </button>
            {inner}
        </li>;
    }

    private onPermOwnerIdChange = (newValue: any) => {
        this.setState({ perm_owner_id: newValue }, () => {
            if (this.state.perm_owner_id) {
                this.onAddPermOwner(this.state.perm_owner_id);
            }
        });
    }

    private getPermRecordFieldName = (): string => {
        return this.props.permRecordFieldName || 'sec_perm_owner_id';
    }

    private onAddPermOwner = (permOwnerId: number) => {
        const crud = this.props.connectorCrud;
        crud.upsert({
            [this.props.masterFieldName]: this.props.masterRecordId,
            [this.getPermRecordFieldName()]: permOwnerId
        }, [this.props.masterFieldName, this.getPermRecordFieldName()])
            .then((crud) => {
                this.setState({ perm_owner_id: null, last_added_owner_id: permOwnerId },
                    this.asyncReload);
                if (this.props.onPermOwnerAdded) {
                    this.props.onPermOwnerAdded(permOwnerId)
                }
            })
            .catch((error) => { app.showErrorFromJsonResult(error); })
    }

    private onRemovePermOwner(permOwnerId: number) {
        this.props.connectorCrud.deleteById(permOwnerId)
            .then(() => {
                this.asyncReload();
                if (this.props.onPermOwnerRemoved) {
                    this.props.onPermOwnerRemoved(permOwnerId);
                }
            })
            .catch((error) => { app.showErrorFromJsonResult(error); })
    }

    render() {
        return (
            <div>
                <div className="row expanded">
                    <div className="column expand">
                        <LookupEdit
                            fk_table_info_id={SecPermOwnerCrud.TABLE_INFO_ID}
                            value={this.state.perm_owner_id || undefined}
                            clearable={true}
                            onChange={this.onPermOwnerIdChange}
                            placeholder={this.props.selectorPlaceholder}
                        />
                    </div>
                </div>
                <div className="row expanded">
                    <div className="column">
                        <small>
                            <b>{this.props.title}:</b>
                        </small>
                        <ul className="permlisteditor-permownerlist">
                            {this.state.items.map((item) => this.itemToLabel(item))}
                        </ul>
                    </div>
                </div>
            </div>);

    }
}