import * as React from 'react'
import { match } from 'react-router';
import ClassRoomHeader from './classRoomSideHeader';


type ClassRoomProps = {
    match: match<{instituteGroupId: string}>;
}
type ClassRoomState = {
    instituteGroupId: number
}

export default class ClassRoom extends React.Component<ClassRoomProps, ClassRoomState> {

    constructor(props: any) {
        super(props);

        this.state = { 
            instituteGroupId: Number(this.props.match.params.instituteGroupId),
         };
    }



    render()
    {
    return <div className="classroom">
                <ClassRoomHeader key="classroom-header" instituteGroupId={this.state.instituteGroupId}></ClassRoomHeader>        
                {this.props.children}
            </div>
    }
}