import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ILessonplanGradeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Óravázlat
     *
     * -> doc_pub.lessonplan ON DELETE cascade
     *
     */
    lessonplan_id?: number|null;
    /**
     * Évfolyam
     *
     * -> doc.grade ON DELETE noaction
     *
     */
    grade_id?: number|null;
}

/**
 *  doc_pub.lessonplan_grade - Óravázlat évfolyama
 */

export default class LessonplanGradeCrud extends Crud<ILessonplanGradeRecord> {
    public static TABLE_INFO_ID = 549298918;
    public static getSchemaNameForClass() { return 'doc_pub'; }
    public static getTableNameForClass() { return 'lessonplan_grade'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<LessonplanGradeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ILessonplanGradeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ILessonplanGradeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ILessonplanGradeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ILessonplanGradeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ILessonplanGradeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class LessonplanGradeCrudClassProxy extends CrudClassProxy<ILessonplanGradeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const lessonplanGradeCrudClassProxy = new LessonplanGradeCrudClassProxy(LessonplanGradeCrud);
registerCrudClassProxy(lessonplanGradeCrudClassProxy);
