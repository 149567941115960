import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISupplementaryMaterialRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     */
    name?: string;
    /**
     * Lecke
     *
     * NOT NULL -> doc.lesson ON DELETE cascade
     *
     */
    lesson_id?: number;
    /**
     * URL
     *
     * A hivatkozott kiegészítő anyag URL-je.
     */
    url?: string;
}

/**
 *  doc.supplementary_material - Kiegészítő anyag
 *
 *  OFI által megadott kiegészítő anyag, leckéhez. Ez NEM a külső felek által feltöltött hivatkozás!
 */

export default class SupplementaryMaterialCrud extends Crud<ISupplementaryMaterialRecord> {
    public static TABLE_INFO_ID = 591607557;
    public static getSchemaNameForClass() { return 'doc'; }
    public static getTableNameForClass() { return 'supplementary_material'; }

    public static load: (id: number, aServer ?: Server) => Promise<SupplementaryMaterialCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISupplementaryMaterialRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISupplementaryMaterialRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISupplementaryMaterialRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISupplementaryMaterialRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISupplementaryMaterialRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SupplementaryMaterialCrudClassProxy extends CrudClassProxy<ISupplementaryMaterialRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const supplementaryMaterialCrudClassProxy = new SupplementaryMaterialCrudClassProxy(SupplementaryMaterialCrud);
registerCrudClassProxy(supplementaryMaterialCrudClassProxy);
