import * as React from 'react';
import { ContentHistoryPage } from '../filemanager/ContentHistoryPage';

interface HistoryTileProps {
    settings: any;
}

export default class HistoryTile extends React.Component<HistoryTileProps, any> {

    render() {
        return <ContentHistoryPage limit={this.props.settings.limit} hideHeader={true}/>
    }

}
