import * as React from 'react';

import { IViewSecUserGroupRecord, ViewSecUserGroupClassProxy, viewSecUserGroupClassProxy } from '@src/framework/view/sys/ViewSecUserGroup';
import ListView from '@framework/forms/listview';
import { Groups, me } from '@src/framework/server/Auth';

import { app } from '@src/index';

import LookupEdit from '@framework/forms/lookupedit';
import SecUserCrud from '@src/framework/crud/sys/SecUserCrud';
import SecGroupCrud from '@src/framework/crud/sys/SecGroupCrud';
import SecUserGroupCrud from '@src/framework/crud/sys/SecUserGroupCrud';
import { __ } from '@src/translation';


class SecUserGroupInner extends ListView<IViewSecUserGroupRecord> {
    public getViewClassProxy(): ViewSecUserGroupClassProxy { return viewSecUserGroupClassProxy; }
    protected getFilterControls(): JSX.Element | null { return null; }
    protected getRecordButtons(record: IViewSecUserGroupRecord): JSX.Element[] {
        let result: JSX.Element[] = [];
        // Saját maga csoportjait senki nem szerkesztheti, ezzel ki tudná
        // zárni magát a rendszerből.
        if (record.user_id != me!.id) {
            // A mindenki csoportból nem lehet eltávolítani máshogy, csak
            // a felhasználó inaktiválásával.
            if (record.group_id != Groups.Everyone) {
                result.push(this.getDeleteUndeleteButton(record)!);
            }
        }
        return result;
    }
}

export interface ISecUserGroupListViewForUserProps {
    user_id: number;
}

export interface ISecUserGroupListViewForUserState {
    groupIdToAdd: number | null;
    refreshKey: number
}

export class SecUserGroupListViewForUser extends React.Component<ISecUserGroupListViewForUserProps, ISecUserGroupListViewForUserState> {

    constructor(props: ISecUserGroupListViewForUserProps) {
        super(props);
        this.state = { groupIdToAdd: null, refreshKey: 0 };
    }

    private onGroupIdChange = (newValue: any) => {
        if ((typeof newValue === 'string'))
            if (newValue.trim() === '')
                newValue = '';
        if (newValue === '')
            newValue = null;
        let newState = {
            groupIdToAdd: newValue
        };
        this.setState(newState);
    }

    private onAddGroup = () => {
        new SecUserGroupCrud({
            user_id: this.props.user_id,
            group_id: this.state.groupIdToAdd!
        }).put()
            .then((crud) => {
                this.setState({
                    groupIdToAdd: null, refreshKey: this.state.refreshKey + 1
                }, this.forceUpdate)
            })
            .catch((error) => { app.showErrorFromJsonResult(error) })
    }


    public render() {
        const value: any = this.state.groupIdToAdd;
        return (
            <div>
                <div className="row">
                    <div className="column small-8 medium-8">
                        <LookupEdit
                            key={"groupIdToAdd_" + (this.state.groupIdToAdd || 'null')}
                            name="groupIdToAdd"
                            fk_table_info_id={SecGroupCrud.TABLE_INFO_ID}
                            clearable={true}
                            value={value}
                            onChange={(newValue) => this.onGroupIdChange(newValue)}
                        />
                    </div>
                    <div className="column small-4 medium-4">
                        <button type="button" className="button primary"
                            disabled={!this.state.groupIdToAdd}
                            onClick={this.onAddGroup}
                        >
                            <i className="fa fa-plus" />
                            {__("Hozzáad")}
                    </button>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 medium-12">
                        <SecUserGroupInner
                            key={this.state.refreshKey}
                            allowInsertNew={false}
                            defaultPageSize={10}
                            filter={{ user_id: this.props.user_id }}
                            viewColumns={[
                                { accessor: "id", maxWidth: 100 },
                                { accessor: "group_id", maxWidth: 100 },
                                { accessor: "group_title" },
                                { accessor: "group_type_title" },
                                /*
                                {accessor: "creation_time"},
                                {accessor: "creator"},
                                {accessor: "modification_time"},
                                {accessor: "modifier"},
                                */
                                { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
                            ]} />
                    </div>
                </div>
            </div>);
    }

}



export interface ISecUserGroupListViewForGroupProps {
    group_id: number;
    title ?: string;
}

export interface ISecUserGroupListViewForGroupState {
    userIdToAdd: number | null;
    refreshKey: number
}

export class SecUserGroupListViewForGroup extends React.Component<ISecUserGroupListViewForGroupProps, ISecUserGroupListViewForGroupState> {

    constructor(props: ISecUserGroupListViewForGroupProps) {
        super(props);
        this.state = { userIdToAdd: null, refreshKey: 0 };
    }

    private onUserIdChange = (newValue: any) => {
        if ((typeof newValue === 'string'))
            if (newValue.trim() === '')
                newValue = '';
        if (newValue === '')
            newValue = null;
        let newState = {
            userIdToAdd: newValue
        };
        this.setState(newState);
    }

    private onAddUser = () => {
        new SecUserGroupCrud({
            group_id: this.props.group_id,
            user_id: this.state.userIdToAdd!
        }).put()
            .then((crud) => {
                this.setState({
                    userIdToAdd: null, refreshKey: this.state.refreshKey + 1
                }, this.forceUpdate)
            })
            .catch((error) => { app.showErrorFromJsonResult(error) })
    }


    public render() {
        const value: any = this.state.userIdToAdd;
        return (
            <div>
                <div className="row">
                    <div className="column small-8 medium-8">
                        <LookupEdit
                            key={"userIdToAdd" + (this.state.userIdToAdd || 'null')}
                            name="userIdToAdd"
                            fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                            clearable={true}
                            value={value}
                            onChange={(newValue) => this.onUserIdChange(newValue)}
                        />
                    </div>
                    <div className="column small-4 medium-4">
                        <button type="button" className="button primary"
                            disabled={!this.state.userIdToAdd}
                            onClick={this.onAddUser}
                        >
                            <i className="fa fa-plus" />
                            {__("Hozzáad")}
                    </button>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 medium-12">
                        <SecUserGroupInner
                            key={this.state.refreshKey}
                            allowInsertNew={false}
                            defaultPageSize={10}
                            filter={{ group_id: this.props.group_id }}
                            viewColumns={[
                                { accessor: "id", maxWidth: 100 },
                                { accessor: "user_id", maxWidth: 100 },
                                { accessor: "login_name" },
                                { accessor: "fullname" },
                                /*
                                {accessor: "creation_time"},
                                {accessor: "creator"},
                                {accessor: "modification_time"},
                                {accessor: "modifier"},
                                */
                                { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
                            ]} 
                            title={this.props.title}
                        />
                    </div>
                </div>
            </div>);
    }

}
