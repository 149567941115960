import * as React from 'react';
import { debounce } from 'lodash';
import { app } from '@src/index';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { getReactTableLabels } from '@src/framework/i18n';
import { IListOrderBy, TFilterDict } from '@src/framework/crud/Crud';
import DebouncedInput from '@src/framework/forms/debouncedinput';
import ViewSecUserCrudAuditByUser, { IViewSecUserCrudAuditByUserRecord } from '@src/framework/view/meta/ViewSecUserCrudAuditByUser';
import { Dialog } from '@src/component/Dialog';
import ViewSecUserCrudAudit, { IViewSecUserCrudAuditRecord } from '@src/framework/view/meta/ViewSecUserCrudAudit';
import MemberCard from '@src/framework/forms/MemberCard';
import { __ } from '@src/translation';

interface IPermissionAuditCrudByTableProps {
    tableInfoId: number;
}

interface IPermissionAuditCrudByTableState {
    loading: boolean,
    items: IViewSecUserCrudAuditByUserRecord[];
    orderBy: IListOrderBy[];
    filter1: string;
    secUserId : number | null;
    userReport: IViewSecUserCrudAuditRecord[] | null;
    tooManyRows: boolean;
}

const ORDER_BY_1_ASC = [
    { name: "login_name" },
];
const ORDER_BY_1_DESC = [
    { name: "login_name", desc: true },
];


export default class PermissionAuditCrudByTable extends React.Component<IPermissionAuditCrudByTableProps, IPermissionAuditCrudByTableState> {
    private debouncedAsyncReload: () => void;

    constructor(props: IPermissionAuditCrudByTableProps) {
        super(props);
        this.state = {
            loading: true,
            items: [],
            orderBy: ORDER_BY_1_ASC,
            filter1: "",
            secUserId: null,
            userReport: null,
            tooManyRows: false
        };
        this.debouncedAsyncReload = debounce(() => this.asyncReload(), 300);
    }

    componentDidMount() {
        this.debouncedAsyncReload();
    }

    private asyncReload = async () => {
        try {
            this.setState({ loading: true, items: [] });
            let filters: TFilterDict[] = [
                // Azok a sorok nem jelennek meg, ahol egyik sem igaz.
                {
                    $or: {
                        can_create: true,
                        can_read: true,
                        can_update: true,
                        can_delete: true
                    }
                }
            ];
            if (this.state.filter1) {
                filters.push({
                    $text: {
                        fieldNames: ["login_name", "email", "fullname"],
                        expression: this.state.filter1
                    }
                });
            }
            const items = await ViewSecUserCrudAuditByUser.list({
                filter: {
                    id: this.props.tableInfoId,
                    $and: filters
                },
                limit: 101,
                order_by: this.state.orderBy
            });
            let tooManyRows: boolean = false;
            if (items.length > 100) {
                tooManyRows = true;
                items.splice(99, 1);
            }
            this.setState({ loading: false, tooManyRows, items });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private setOrderBy = async (orderBy: IListOrderBy[]) => {
        console.log("setOrderBy", orderBy);
        this.setState({ orderBy, loading: true }, this.debouncedAsyncReload);
    }

    private filter1ValueChanged = (filter1: string) => {
        this.setState({ filter1, loading: true }, this.debouncedAsyncReload);
    }

    private analyzeTableInfo = async (secUserId: number) => {
        try {
            const tableInfoReport = await ViewSecUserCrudAudit.list({
                filter: {
                    table_info_id: this.props.tableInfoId,
                    sec_user_id: secUserId
                }
            })
            this.setState({ secUserId, userReport: tableInfoReport });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private getTableInfoReport = () => {
        return <ReactTable
            columns={[
                {
                    Header: __("Jog forrása"),
                    Cell: (data: any, column: any) => {
                        const row: IViewSecUserCrudAuditRecord = data.original;
                        if (row.src_group_id) {
                            return <p
                                title={row.src_group_description || ""}
                            ><small>
                                    <i className="fa fa-users" /> {row.src_group_title}
                                    <br />
                                    <label className="label success">{row.src_group_type}</label>
                                </small>
                            </p>
                        } else {
                            return <label className="label secondary">
                                &gt; {__("Saját felhasználónak közvetlenül kiosztva")} &lt;
                            </label>
                        }
                    }
                },
                {
                    Header: "Jogok",
                    filterable: false,
                    maxWidth: 100,
                    Cell: (data: any, column: any) => {
                        const row: IViewSecUserCrudAuditRecord = data.original;
                        return <code style={{ width: "4em" }}>
                            {row.can_create ? "C" : ""}
                            {row.can_read ? "R" : ""}
                            {row.can_update ? "U" : ""}
                            {row.can_delete ? "D" : ""}
                        </code>
                    }
                },
            ]}
            data={this.state.userReport!}
            filterable={false}
            className="-striped -highlight "
            {...getReactTableLabels()}
        />
    }

    render() {
        const orderBy = this.state.orderBy[0]!;

        let sortIcon1 = null;
        let desc1: boolean = true;
        if (orderBy.name == ORDER_BY_1_ASC[0].name) {
            desc1 = orderBy.desc ? true : false;
            sortIcon1 = <i className={"fa " +
                (desc1 ? "fa-sort-alpha-up" : "fa-sort-alpha-down")} />;
        }
        let onClick1 = () => { this.setOrderBy(desc1 ? ORDER_BY_1_ASC : ORDER_BY_1_DESC); }

        return <>
            {this.state.secUserId ?
                <Dialog width={800} height={600}
                    title={__("Jog források megjelenítése")}
                    onClose={() => { this.setState({ secUserId: null }) }}
                >
                    {this.state.userReport
                        ? this.getTableInfoReport()
                        : <p className="callout secondary">{__("Kérem várjon...")}</p>
                    }
                </Dialog>
                : null
            }

            <div className="row expanded">
                <div className="column small-12 large-12">
                    <p className="callout secondary">
                        <small>
                            {__("Az alábbi jogok össze vannak gyűjtve a felhasználók összes csoportjából, ")}
                            {__("itt már csak az jelenik meg, hogy a szerver milyen műveleteket engedélyez a ")}
                            {__("felhasználóknak közvetlenül, vagy valamely csoportjukon keresztül. ")}
                            {__("Vegye figyelembe, hogy egy adott műveleti jog megléte nem jelenti azt, hogy ")}
                            {__("a műveletet bármely rekordra el lehet végezni. A szerver sok esetben ")}
                            {__("speciális szabályokkal tovább korlátozza a műveleteket. ")}
                    </small>
                    </p>
                </div>
                <div className="column small-12 large-12">
                    {this.state.loading
                            ?<p className="callout warning">{__("Betöltés, kérem várjon...")}</p>
                            :this.state.tooManyRows ?
                            (
                                <p className="callout alert">
                                    {__("Túl sok találat, csak az első 100 jelenik meg.")}
                                </p>
                            ):(
                                this.state.items.length
                                    ? <label className="label success">{this.state.items.length} {__("találat")}</label>
                                    : <label className="label warning">{__("Nincs találat")}</label>
                            )  
                    }
                    <ReactTable
                        columns={[
                            {
                                Header: <div onClick={onClick1}>
                                    {__("Jogosult")} &nbsp;{sortIcon1}
                                </div>,
                                Cell: (data: any, column: any) => {
                                    const row: IViewSecUserCrudAuditByUserRecord = data.original;
                                    return <div style={{ width: "100%" }}>
                                        <MemberCard {...row} id={row.sec_user_id!} />
                                    </div>
                                },
                                Filter: () => {
                                    return <DebouncedInput
                                        type="text"
                                        placeholder={__("Csoport/login/email/név")}
                                        value={this.state.filter1}
                                        onValueChanged={this.filter1ValueChanged}
                                    />
                                }
                            },
                            {
                                Header: __("Jogok"),
                                filterable: false,
                                maxWidth: 110,
                                Cell: (data: any, column: any) => {
                                    const row: IViewSecUserCrudAuditByUserRecord = data.original;
                                    return <>
                                        <code style={{ width: "4em" }}>
                                            {row.can_create ? "C" : ""}
                                            {row.can_read ? "R" : ""}
                                            {row.can_update ? "U" : ""}
                                            {row.can_delete ? "D" : ""}
                                        </code>
                                        <button className="button primary"
                                            title={__("A jogok származási helyének megjelenítése")}
                                            onClick={() => { this.analyzeTableInfo(row.sec_user_id!) }}
                                        >
                                            <i className="fa fa-question-circle" />
                                        </button>
                                    </>
                                }
                            }
                        ]}
                        manual
                        data={this.state.items}
                        defaultPageSize={100}
                        pages={1}
                        filterable={true}
                        className="-striped -highlight "
                        {...getReactTableLabels()}
                        onFetchData={(state: any, instance: any) => this.debouncedAsyncReload()}
                        loading={this.state.loading}
                    />
                </div>
            </div>
        </>
    }

}