import * as React from 'react';

import { IViewSecStationPermRecord, ViewSecStationPermClassProxy, viewSecStationPermClassProxy } from '@src/framework/view/wf/ViewSecStationPerm';
import ListView from '@framework/forms/listview';

import { app } from '@src/index';

import LookupEdit from '@framework/forms/lookupedit';
import SecPermOwnerCrud from '@src/framework/crud/sys/SecPermOwnerCrud';
import SecStationPermCrud from '@src/framework/crud/wf/SecStationPermCrud';
import { __ } from '@src/translation';

import './wf_sec_station_perm_listview.css';

class SecStationPermInner extends ListView<IViewSecStationPermRecord> {
    public getViewClassProxy(): ViewSecStationPermClassProxy { return viewSecStationPermClassProxy; }
    protected getFilterControls(): JSX.Element | null { return null; }


    private onSetFlag = async(recordId: number, fieldName: string, value: boolean) => {
        try {
            await new SecStationPermCrud({id:recordId, [fieldName]: value}).put();
            app.showInfo(__("Sikeres művelet"),__("A jogosultság beállítása sikeres volt."));
            this.asyncReload();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    protected getToggleFlagButton(record: IViewSecStationPermRecord, fieldName: string, icon:string, description: string): JSX.Element  {
        const value: boolean = record[fieldName]!;
        let title = description;
        let cls = "wf-sec-station-perm-listview-button";
        let iconClass = "fa " + icon; 
        if (value) {
            title += " " + __("- a jog megvonásához kattintson az ikonra.");
            iconClass += " wf-sec-station-perm-listview-perm-active";
        } else {
            title += " " + __("- a jog megadásához kattintson az ikonra.");
            iconClass += " wf-sec-station-perm-listview-perm-inactive";
        }
        const rec_id = record.id!;
        return (
            <span
                className={cls} title={title}
                onClick={() => this.onSetFlag(rec_id, fieldName, !value) }
            ><i className={iconClass} />
            </span>);
    }



    protected getRecordButtons(record: IViewSecStationPermRecord): JSX.Element[] {
        return [
            this.getToggleFlagButton(record, "can_start",  "fa-play-circle", __("Folyamat indítása")),
            this.getToggleFlagButton(record, "can_update_master",  "fa-edit", __("Fő rekord módosítása")),
            this.getToggleFlagButton(record, "can_create_detail",  "fa-plus-square", __("Tétel hozzáadása")),
            this.getToggleFlagButton(record, "can_update_detail",  "fa-pen-square", __("Tétel módosítása")),
            this.getToggleFlagButton(record, "can_delete_detail",  "fa-minus-square", __("Tétel törlése")),
            this.getToggleFlagButton(record, "can_comment",  "fa-comment", __("Megjegyzés beküldése")),
            this.getToggleFlagButton(record, "can_update_substation",  "fa-tag", __("Al-státusz módosítása")),
            this.getToggleFlagButton(record, "can_update_status_text",  "fa-comment-alt", __("Állapot megjegyzés módosítása")),

            this.getToggleFlagButton(record, "can_publish",  "fa-eye", __("Publikálás")),
            this.getToggleFlagButton(record, "can_unpublish",  "fa-eye-slash", __("Publikálás visszavonása (elrejtés)")),


            this.getDeleteUndeleteButton(record)!
        ];
    }

    protected getHeader = (): JSX.Element | null => {
        if (this.state.struct) {
            const displayName = __("Állapot függő jogosultságok kiosztása");
            return <div className="row expanded">            
                    <div id="listHeader" className="column small-12 large-12">
                        <h4>{displayName}</h4>
                    </div>
                </div>;
        } else {
            return null;
        }
    }

}

export interface ISecStationPermListViewForStationProps {
    station_id: number;
}

export interface ISecStationPermListViewForStationState {
    permOwnerIdToAdd: number | null;
    refreshKey: number
}

export class SecStationPermListViewForStation extends React.Component<ISecStationPermListViewForStationProps, ISecStationPermListViewForStationState> {

    constructor(props: ISecStationPermListViewForStationProps) {
        super(props);
        this.state = { permOwnerIdToAdd: null, refreshKey: 0 };
    }

    private onPermOwnerIdChange = (newValue: any) => {
        if ((typeof newValue === 'string'))
            if (newValue.trim() === '')
                newValue = '';
        if (newValue === '')
            newValue = null;
        let newState = {
            permOwnerIdToAdd: newValue
        };
        this.setState(newState);
    }

    private onAddPermOwner = () => {
        new SecStationPermCrud({
            station_id: this.props.station_id,
            sec_perm_owner_id: this.state.permOwnerIdToAdd!
        }).put()
            .then((crud) => {
                this.setState({
                    permOwnerIdToAdd: null, refreshKey: this.state.refreshKey + 1
                }, this.forceUpdate)
            })
            .catch((error) => { app.showErrorFromJsonResult(error) })
    }


    public render() {
        const value: any = this.state.permOwnerIdToAdd;
        return (
            <div>
                <div className="row">
                    <div className="column small-8 medium-8">
                        <LookupEdit
                            key={"permOwnerIdToAdd_" + (this.state.permOwnerIdToAdd || 'null')}
                            name="permOwnerIdToAdd"
                            fk_table_info_id={SecPermOwnerCrud.TABLE_INFO_ID}
                            clearable={true}
                            value={value}
                            onChange={(newValue) => this.onPermOwnerIdChange(newValue)}
                            placeholder={__("Jogosultság megadásához írjon be egy csoportot, szerepkört vagy felhasználót")}
                        />
                    </div>
                    <div className="column small-4 medium-4">
                        <button type="button" className="button primary"
                            disabled={!this.state.permOwnerIdToAdd}
                            onClick={this.onAddPermOwner}
                        >
                            <i className="fa fa-plus" />
                            {__("Hozzáad")}
                    </button>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 medium-12">
                        <p className="callout warning">
                            { __("Figyelem! Alább minden kattintás azonnal menti a változtatásokat!") }
                        </p>
                        <SecStationPermInner
                            key={this.state.refreshKey}
                            allowInsertNew={false}
                            defaultPageSize={10}
                            filter={{ station_id: this.props.station_id }}
                            viewColumns={[
                                { accessor: "id", maxWidth: 100 },
                                { accessor: "perm_owner_label", maxWidth: 300 },
                                /*
                                {accessor: "creation_time"},
                                {accessor: "creator"},
                                {accessor: "modification_time"},
                                {accessor: "modifier"},
                                */
                                { accessor: "link", Header: __("Műveletek")},
                            ]} />
                    </div>
                </div>
            </div>);
    }

}

