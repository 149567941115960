import * as React from 'react';

import LessonEditor from './LessonEditor'
import { Section } from '@src/server/EditorServer';

type SectionTextEditorProps = {
  lessonEditor: LessonEditor;
  section: Section;
  templateVariableId: string;
  templateName: string;
}

export default class SectionTextEditor extends React.Component<SectionTextEditorProps, {}> {
  
    render() {
      const section = this.props.section;
      const chapterEditor = this.props.lessonEditor;
  
      return <input 
        type="text" 
        value={this.props.section.values[this.props.templateVariableId]}
        onChange={chapterEditor.handleEditorChange.bind(chapterEditor, section.id, this.props.templateVariableId)}
        />;
    }
  }
  