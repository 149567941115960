import { AnnotationMode } from "@src/component/book/viewer/page_addition/BookAnnotations";
// Copy from https://github.com/openannotation/annotator/blob/master/src/ui/main.js
// 
// Keress rá ebben a fájlban, hogy "CHANGE", hogy mit változtattam
// ===============================================================


/*package annotator.ui */
"use strict";

var util:any;
var adder:any;
var editor:any;
var highlighter:any;
var textselector:any;
var viewer:any;


// trim strips whitespace from either end of a string.
//
// This usually exists in native code, but not in IE8.
function trim(s: string) {
    if (typeof String.prototype.trim === 'function') {
        return String.prototype.trim.call(s);
    } else {
        return s.replace(/^[\s\xA0]+|[\s\xA0]+$/g, '');
    }
}


// annotationFactory returns a function that can be used to construct an
// annotation from a list of selected ranges.
function annotationFactory(contextEl: Element, ignoreSelector: string) {
    return function (ranges: any) {
        var text = [],
            serializedRanges = [];

        for (var i = 0, len = ranges.length; i < len; i++) {
            var r = ranges[i];
            text.push(trim(r.text()));
            serializedRanges.push(r.serialize(contextEl, ignoreSelector));
        }

        return {
            quote: text.join(' / '),
            ranges: serializedRanges
        };
    };
}

// Helper function to inject CSS into the page that ensures Annotator elements
// are displayed with the highest z-index.
function injectDynamicStyle() {
    util.$('#annotator-dynamic-style').remove();

    var sel = '*' +
              ':not(annotator-adder)' +
              ':not(annotator-outer)' +
              ':not(annotator-notice)' +
              ':not(annotator-filter)';

    // use the maximum z-index in the page

    // CHANGE felesleges z-index-et számolni
    //var max = maxZIndex(util.$(document.body).find(sel).get());
    var max = 10000;

    // but don't go smaller than 1010, because this isn't bulletproof --
    // dynamic elements in the page (notifications, dialogs, etc.) may well
    // have high z-indices that we can't catch using the above method.
    // max = Math.max(max, 1000);

    var rules = [
        ".annotator-adder, .annotator-outer, .annotator-notice {",
        "  z-index: " + (max + 20) + ";",
        "}",
        ".annotator-filter {",
        "  z-index: " + (max + 10) + ";",
        "}"
    ].join("\n");

    util.$('<style>' + rules + '</style>')
        .attr('id', 'annotator-dynamic-style')
        .attr('type', 'text/css')
        .appendTo('head');
}


// Helper function to remove dynamic stylesheets
function removeDynamicStyle() {
    util.$('#annotator-dynamic-style').remove();
}


/**
 * function:: main([options])
 *
 * A module that provides a default user interface for Annotator that allows
 * users to create annotations by selecting text within (a part of) the
 * document.
 *
 * Example::
 *
 *     app.include(annotator.ui.main);
 *
 * :param Object options:
 *
 *   .. attribute:: options.element
 *
 *      A DOM element to which event listeners are bound. Defaults to
 *      ``document.body``, allowing annotation of the whole document.
 *
 *   .. attribute:: options.editorExtensions
 *
 *      An array of editor extensions. See the
 *      :class:`~annotator.ui.editor.Editor` documentation for details of editor
 *      extensions.
 *
 *   .. attribute:: options.viewerExtensions
 *
 *      An array of viewer extensions. See the
 *      :class:`~annotator.ui.viewer.Viewer` documentation for details of viewer
 *      extensions.
 *
 */
export function annotatorMain(options: any) {
    var w = window;
    if (options.window) w = options.window;

    util = w["annotator"].util;
    adder = w["annotator"].ui.adder;
    editor = w["annotator"].ui.editor;
    highlighter = w["annotator"].ui.highlighter;
    textselector = w["annotator"].ui.textselector;
    viewer = w["annotator"].ui.viewer;


    if (typeof options === 'undefined' || options === null) {
        options = {};
    }

    options.element = options.element || document.body;
    options.editorExtensions = options.editorExtensions || [];
    options.viewerExtensions = options.viewerExtensions || [];

    // Local helpers
    var makeAnnotation = annotationFactory(options.element, '.annotator-hl');

    // Object to hold local state
    var s: any = {
        interactionPoint: null
    };

    function start(app: any) {
        var ident = app.registry.getUtility('identityPolicy');
        var authz = app.registry.getUtility('authorizationPolicy');

        s.highlighter = new highlighter.Highlighter(options.element);


        // CHANGE - Ha megtekintés módban vagyunk, akkor ne lehessen kijelölni / módosítani szöveget
        if (options.annotationMode != AnnotationMode.NONE) {

            s.adder = new adder.Adder({
                onCreate: function (ann: any) {
                    app.annotations.create(ann);
                }
            });
            s.adder.attach();
    
            s.editor = new editor.Editor({
                extensions: options.editorExtensions
            });
            s.editor.attach();
    
            s.textselector = new textselector.TextSelector(options.element, {
                onSelection: function (ranges: any, event: any) {
                    if (ranges.length > 0) {
                        var annotation = makeAnnotation(ranges);

                        // CHANGE - Ha a aláhúzás módban vagyunk, akkor nem kell az edit gomb, rögtön létrehozzuk

                        if (options.annotationMode == AnnotationMode.USER_MARKING) {
                            app.annotations.create(annotation);
                        } else {
                            s.interactionPoint = util.mousePosition(event);
                            s.adder.load(annotation, s.interactionPoint);
                        }

                    } else {
                        s.adder.hide();
                    }
                }
            });
            
            
            /* Mobilra így kellene: 

            this.??? = document.addEventListener("selectionchange", function(e) {
                const clientX = e.???
                const clientY = e.???
                s.textselector._checkForEndSelection(new MouseEvent("", {clientX: clientX, clientY: clientY})); 
            });
            + alul destroy()-ban document.removeEventListener("selectionchange", this.???)
            */

        }

        
        // CHANGE - Ha megtekintés / aláhúzás módban vagyunk, akkor nem kell megtekintés, mert csak bezavar mindenhol
        if (options.annotationMode != AnnotationMode.NONE && options.annotationMode != AnnotationMode.USER_MARKING) {
            s.viewer = new viewer.Viewer({
                onEdit: function (ann: any) {
                    // Copy the interaction point from the shown viewer:
                    s.interactionPoint = util.$(s.viewer.element)
                                            .css(['top', 'left']);

                    app.annotations.update(ann);
                },
                onDelete: function (ann: any) {
                    app.annotations['delete'](ann);
                },
                permitEdit: function (ann: any) {
                    return authz.permits('update', ann, ident.who());
                },
                permitDelete: function (ann: any) {
                    return authz.permits('delete', ann, ident.who());
                },
                autoViewHighlights: options.element,
                extensions: options.viewerExtensions
            });
            s.viewer.attach();
        }

        injectDynamicStyle();
    }

    return {
        start: start,

        destroy: function () {
            if (s.adder) s.adder.destroy();
            if (s.editor) s.editor.destroy();
            if (s.highlighter) s.highlighter.destroy();
            if (s.textselector) s.textselector.destroy();
            if (s.viewer) s.viewer.destroy();
            removeDynamicStyle();
        },

        annotationsLoaded: function (anns: any) { s.highlighter.drawAll(anns); },
        annotationCreated: function (ann: any) { s.highlighter.draw(ann); },
        annotationDeleted: function (ann: any) { s.highlighter.undraw(ann); },
        annotationUpdated: function (ann: any) { s.highlighter.redraw(ann); },

        beforeAnnotationCreated: function (annotation: any) {
            // Editor#load returns a promise that is resolved if editing
            // completes, and rejected if editing is cancelled. We return it
            // here to "stall" the annotation process until the editing is
            // done.

            // CHANGE - Ha a aláhúzás módban vagyunk, akkor nem kell az edit ablak
            if (options.annotationMode == AnnotationMode.USER_MARKING) {
                annotation.text = "";
                return Promise.resolve(annotation);
            } else {
                return s.editor.load(annotation, s.interactionPoint);
            }
            
        },

        beforeAnnotationUpdated: function (annotation: any) {
            return s.editor.load(annotation, s.interactionPoint);
        }
    };
}
