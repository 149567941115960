import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapCourseTrainerInvitationAnswerRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * KSZR képző felkérés
     */
    course_trainer_invitation_id?: number;
    /**
     * Képző
     */
    trainer_id?: number;
    /**
     * Elvállalja
     */
    accept?: boolean|null;
    /**
     * Képző bejelentkezési név
     *
     * Képző bejelentkezési név
     */
    trainer_login_name?: string|null;
    /**
     * Képző e-mail
     *
     * Képző e-mail
     */
    trainer_email?: string|null;
    /**
     * Képző teljes név
     *
     * Képző teljes név
     */
    trainer_fullname?: string|null;
    /**
     * Felkérés válaszadási határideje
     *
     * Felkérés válaszadási határideje
     */
    answer_deadline?: string /*timestamp?*/|null;
    /**
     * Kurzus azonosító
     *
     * Kurzus azonosító
     */
    course_id?: number|null;
    /**
     * Képzés típus belső azonosító
     *
     * Képzés típus belső azonosító
     */
    training_type_id?: number|null;
    /**
     * Képzés típus kód
     *
     * Képzés típus kód
     */
    training_type_code?: string|null;
    /**
     * Képzés típus neve
     *
     * Képzés típus neve
     */
    training_type_title?: string|null;
    /**
     * Kurzus képző azonosító
     *
     * Kurzus képző azonosító
     */
    teacher_id?: number|null;
    /**
     * Kurzus kezdete
     *
     * Kurzus kezdete
     */
    start_date?: string /*date?*/|null;
    /**
     * Kurzus vége
     *
     * Kurzus vége
     */
    end_date?: string /*date?*/|null;
    /**
     * Kurzus címe
     *
     * Kurzus címe
     */
    course_address?: string|null;
    /**
     * Feladatellátási hely neve
     *
     * Feladatellátási hely neve
     */
    institute_site_name?: string|null;
    /**
     * Feladatellátási hely azonosítója
     *
     * Feladatellátási hely azonosítója
     */
    institute_site_identifier?: string|null;
    /**
     * Feladatellátási hely címe
     *
     * Feladatellátási hely címe
     */
    institute_site_address?: string|null;
    /**
     * Feladatellátási hely irányítószám
     *
     * Feladatellátási hely irányítószám
     */
    institute_site_zipcode?: string|null;
    /**
     * Feladatellátási hely város azonosító
     *
     * Feladatellátási hely város azonosító
     */
    institute_site_city_id?: number|null;
    /**
     * Feladatellátási hely város neve
     *
     * Feladatellátási hely város neve
     */
    institute_site_city?: string|null;
    /**
     * Intézmény neve
     *
     * Intézmény neve
     */
    institute_name?: string|null;
    /**
     * OM azonosító
     *
     * OM azonosító
     */
    om_identifier?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    wf_workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Folyamat állapot leírása
     *
     * Folyamat állapot leírása
     */
    wf_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Folyamat állapot stílusa, jsonb/css
     */
    wf_station_style?: any|null;
    /**
     * Folyamat al-státusz azonosító
     *
     * Folyamat al-státusz azonosító
     */
    wf_substation_id?: number|null;
    /**
     * Folyamat al-státusz
     *
     * Folyamat al-státusz
     */
    wf_substation_name?: string|null;
    /**
     * Folyamat al-státusz leírása
     *
     * Folyamat al-státusz leírása
     */
    wf_substation_description?: string|null;
    /**
     * Folyamat szöveges állapot kiegészítés
     *
     * Folyamat szöveges állapot kiegészítés
     */
    wf_status_text?: string|null;
    /**
     * Utolsó átmenet
     *
     * Utolsó átmenet
     */
    last_transition?: string /*timestamp?*/|null;
    /**
     * Megjegyzésdek száma
     *
     * Megjegyzésdek száma
     */
    message_cnt?: number|null;
}

/**
 *  kap.view_kap_course_trainer_invitation_answer - KSZR képző felkérésre adott válasz
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewKapCourseTrainerInvitationAnswer extends View<IViewKapCourseTrainerInvitationAnswerRecord> {
    public static VIEW_INFO_ID = 2019112502;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_course_trainer_invitation_answer'; }
    public static getTableNameForClass(): string|null { return 'course_trainer_invitation_answer'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapCourseTrainerInvitationAnswer>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapCourseTrainerInvitationAnswerRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapCourseTrainerInvitationAnswerClassProxy extends ViewClassProxy<IViewKapCourseTrainerInvitationAnswerRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapCourseTrainerInvitationAnswerClassProxy = new ViewKapCourseTrainerInvitationAnswerClassProxy(ViewKapCourseTrainerInvitationAnswer);
registerViewClassProxy(viewKapCourseTrainerInvitationAnswerClassProxy);

