import * as React from 'react';
import { __ } from '@src/translation';
import ViewKapOccupationVisit, { IViewKapOccupationVisitRecord} from '@src/framework/view/kap/ViewKapOccupationVisit';
import { Dialog, DialogContent, DialogActions, alertDialog } from '@src/component/Dialog';
import { BubbleLoader } from 'react-css-loaders';
import { app } from '@src/index';
import { WorkflowDialog } from '@src/component/wf/WorkflowDialog';
import OccupationVisitCrud from '@src/framework/crud/kap/OccupationVisitCrud';
import SecUserCrud from '@src/framework/crud/sys/SecUserCrud';
import LookupEdit from '@src/framework/forms/lookupedit';

import SubprogramCrud, { ISubprogramRecord } from '@src/framework/crud/kap/SubprogramCrud';
import { DateTimePicker, DateTimeGeneralType } from '@src/component/dashboard/datetimepicker/DateTimePicker';
import { viewKapInstituteSiteTeacherClassProxy } from '@src/framework/view/sys/ViewKapInstituteSiteTeacher';
import OccupationVisitReservationCrud from '@src/framework/crud/kap/OccupationVisitReservationCrud';
import { me, hasAnyGroup, Groups, hasGroup } from '@src/framework/server/Auth';
import MediaFileChooser, { FILE_ACTIONS } from '@src/component/filemanager/MediaFileChooser';
import SubprogramAspectCrud, { ISubprogramAspectRecord } from '@src/framework/crud/kap/SubprogramAspectCrud';
import SubprogramAspectValueCrud, { ISubprogramAspectValueRecord } from '@src/framework/crud/kap/SubprogramAspectValueCrud';
import SubprogramAspectAnswerCrud, { ISubprogramAspectAnswerRecord } from '@src/framework/crud/kap/SubprogramAspectAnswerCrud';
import InstituteSiteCrud from '@src/framework/crud/sys/InstituteSiteCrud';
import { viewKapSubprogramSupporterSiteClassProxy } from '@src/framework/view/kap/ViewKapSubprogramSupporterSite';
import { viewKapJtrSupporterClassProxy } from '@src/framework/view/kap/ViewKapJtrSupporter';
import ViewKapOccupationVisitReservation, { IViewKapOccupationVisitReservationRecord } from '@src/framework/view/kap/ViewKapOccupationVisitReservation';
import { ApInstituteTime, ApSupportTime, ApTimeBusy, ApApplyClosed, ApWaitingAttendance, ApClosed, ApDeleted, ApAnnouncedWaitingApply } from './AttendanceCalendarProps';
import { viewKapInstituteSiteContactClassProxy } from '@src/framework/view/sys/ViewKapInstituteSiteContact';
import { formatDateWithoutTime } from '@src/Util';
import { viewKapUserSubprogramClassProxy } from '@src/framework/view/kap/ViewKapUserSubprogram';
import WfAPI, { IViewTransitionTypeRecordWithPerm } from '@src/framework/wf/WfAPI';
import obtainServer from '@src/framework/server/Server';
import { OccupationDraftEdit } from '../draft/OccupationDraftEdit';
import { LIBRARY_JTR_BEADANDO_ID } from '@src/Const';
import { viewInstituteSiteClassProxy } from '@src/framework/view/sys/ViewInstituteSite';

const WORKFLOW_OCCUPATION_VISIT_ID = 25346333725;

type OccupationVisitEditorProps = {
    id: number | null;
    onNewId: (newId: number) => void;
    onClose: () => void;

    eventDay: string,
}

type OccupationVisitEditorState = {
    visit?: IViewKapOccupationVisitRecord;
    reserved: boolean;
    reservations: IViewKapOccupationVisitReservationRecord[];
    subprograms: ISubprogramRecord[];
    workflowDialogOpen: boolean;
    aspectDialogOpen: boolean;
    canSave: boolean;
    canTransition: boolean;
    isDataChanged: boolean;
    visitDraftOpen: boolean;
}

export class OccupationVisitEditor extends React.Component<OccupationVisitEditorProps, OccupationVisitEditorState> {

    constructor(props: OccupationVisitEditorProps) {
        super(props);

        this.state = {
            subprograms: [],
            reservations: [],
            reserved: false,
            workflowDialogOpen: false,
            aspectDialogOpen: false,
            canSave: true,
            canTransition: false,
            isDataChanged: false,
            visitDraftOpen: false,
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: OccupationVisitEditorProps) {
        if (prevProps.id !== this.props.id) {
            this.reloadAsync();
        }
    }

    private async reloadAsync() {
        if (!this.props.id) {
            
            const fixedKapSupporterId = me && (!hasGroup(me, Groups.KapJtrCoordinator) && hasGroup(me, Groups.KapJtrSupport)) ? me.id : undefined;

            let minStartDate = this.props.eventDay + " 08:00";
            let maxEndDate = this.props.eventDay + " 16:00";
            if (me && hasGroup(me, Groups.KapJtrInstituteContact)) {
                minStartDate = this.props.eventDay + " 01:00";
                maxEndDate = this.props.eventDay + " 23:00";
            }

            this.setState({
                visit: {
                    min_start_date: minStartDate,
                    max_end_date: maxEndDate,
                    min_visitors: 1,
                    // max_visitors: 10,
                    kap_supporter_id: fixedKapSupporterId,
                }
            })
            return;
        }

        try {
            
            const subprograms = await SubprogramCrud.list({
                filter: {is_active: true},
                order_by: "code"
            });

            const visit = (await ViewKapOccupationVisit.load(this.props.id)).record;

            const reserved = (await ViewKapOccupationVisitReservation.list({
                filter: {
                    is_active: true,
                    occupation_visit_id: visit.id,
                    sec_user_id: me && me.id
                }
            })).length > 0;


            let reservations: IViewKapOccupationVisitReservationRecord[] = [];
            
            if (me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrSupport, Groups.KapJtrInstituteContact])) {
                reservations = await ViewKapOccupationVisitReservation.list({
                    filter: {
                        is_active: true,
                        occupation_visit_id: visit.id
                    }
                });
            }

            const wfApi = new WfAPI(obtainServer());
            const stations = await wfApi.getStationPermissions(WORKFLOW_OCCUPATION_VISIT_ID);
            let canSave = ((visit.wf_station_id && stations[visit.wf_station_id] && stations[visit.wf_station_id].can_update_master) || (me && hasGroup(me, Groups.Admin))) ? true : false;
            
            if (me && !hasAnyGroup(me, [Groups.KapJtrCoordinator, Groups.KapJtrSupport, Groups.KapJtrInstituteContact]) && hasGroup(me, Groups.KapKszrCertifiedTeacher)) {
                canSave = false;
            }

            let canTransition = false;
            if (visit.wf_workflow_id) {
                const transitions = await wfApi.listPossibleTransitions(visit.wf_workflow_id);
                canTransition = transitions.find(t => t.has_perm) ? true : false;
            }

            this.setState({
                visit,
                reservations,
                reserved,
                subprograms,
                canSave,
                canTransition,
                isDataChanged: false,
            })
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }
    
    private onChange(propName: string, value: any, callback?: () => void) {
        this.setState({
            visit: {...this.state.visit, [propName]: value},
            isDataChanged: true
        }, callback)
    }

    private async onSave() {
        if (!this.state.visit) return;


        if(me && hasGroup(me, Groups.KapJtrInstituteContact))
        {
            if (!this.state.visit.start_date || !this.state.visit.end_date) {
                await alertDialog("Időpont hiányzik", "Az időpontot kötelező kitölteni!");
                return;
            }
        }
        const teacherEditable = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrInstituteContact]) && (this.state.visit.wf_station_id == ApTimeBusy);

        const dataEditable = me && (hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrInstituteContact]) || (me.id == this.state.visit.teacher_id && hasGroup(me, Groups.KapKszrCertifiedTeacher))) && (this.state.visit.wf_station_id == ApTimeBusy);
        try {

            if ((this.state.visit.start_date && this.state.visit.min_start_date && new Date(this.state.visit.start_date) < new Date(this.state.visit.min_start_date)) ||
                this.state.visit.end_date && this.state.visit.max_end_date && new Date(this.state.visit.end_date) > new Date(this.state.visit.max_end_date)
                ) {
                await alertDialog("Hiba", "Az időpontnak a legkorábbi / legkésőbbi időn belülre kell esnie");
                return;
            }
            if(this.state.visit.min_visitors &&this.state.visit.max_visitors)
            {
                if(this.state.visit.min_visitors<1){await alertDialog("Hiba", "A minimum létszám 1!");return;}
                if(this.state.visit.max_visitors>100){await alertDialog("Hiba", "A maximum létszám 100!");return;}
            }
            else
            {
                await alertDialog("Hiba", "Kötelező kitölteni a minimum és maximum létszámot!");
                return;
            }
            if(teacherEditable && !this.state.visit.teacher_id)
            {
                await alertDialog("Hiba", "Kötelező óratartó pedagógust választani!");
                return;
            }
            if(dataEditable&& !this.state.visit.topic)
            {
                await alertDialog("Hiba", "Kötelező témakört választain!");
                return;
            }
            if(dataEditable&& !this.state.visit.group_type_other)
            {
                await alertDialog("Hiba", "Kötelező osztályt választain!");
                return;
            }

            const visit = this.state.visit;
            visit.is_active = undefined; // Szerver: Ehhez a rekordhoz van folyamat. Ilyenkor a rekord nem törölhető, és nem állítható vissza...

            const newRecord = (await new OccupationVisitCrud(visit).put()).record;

            app.showSuccess("Látogatás lementve", "Látogatás lementve");

            if (this.props.id !== newRecord.id && newRecord.id) {
                this.props.onNewId(newRecord.id);
            } else {
                this.reloadAsync();
            }

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onReserve() {
        if (!me) return;

        try {

            await new OccupationVisitReservationCrud({
                occupation_visit_id: Number(this.props.id),
                sec_user_id: me.id,
                is_active: this.state.reserved ? false : true
            }).upsert(["occupation_visit_id", "sec_user_id"]);

            app.showSuccess("Jelentkezés", "Óralátogatás jelentkezés " + (this.state.reserved ? "lemondva" : "rögzítve"));

            this.reloadAsync();

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onSetOccupationDraft(occupationDraftId: number) {
        try {
            if (!this.state.visit || !this.state.visit.id) return;

            await new OccupationVisitCrud({
                id: this.state.visit.id,
                occupation_draft_id: occupationDraftId
            }).put();

            app.showSuccess("Óravázlat csatolva", "Óravázlat csatolva az óralátogatáshoz");
            
            this.reloadAsync();

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    
    private onBeforeTransition(transition: IViewTransitionTypeRecordWithPerm) {
        if (!this.state.visit) return false;

        if (transition.dst_station_id == 25346333748) {
            if (!this.state.visit.supporter_approved || !this.state.visit.site_approved) {
                alertDialog("Jóváhagyás hiányzik", "A szakmai támogatónak és az intézményi kapcsolattartónak jóvá kell hagynia az időpontot!");
                return false;
            }

            if (!this.state.visit.institute_id) {
                alertDialog("Intézmény hiányzik", "Az intézményi feladatellátási helyet kötelező kitölteni!");
                return false;
            }
            if (!this.state.visit.kap_supporter_id) {
                alertDialog("Szakmai támogató hiányzik", "A szakmai támogatót kötelező kitölteni!");
                return false;
            }
            if (!this.state.visit.start_date || !this.state.visit.end_date) {
                alertDialog("Időpont hiányzik", "Az időpontot kötelező kitölteni!");
                return false;
            }           
        }
        if (transition.dst_station_id == 25346333750) {
            if (!this.state.visit.teacher_id) {
                alertDialog("Pedagógus", "A pedagógust kötelező kitölteni!");
                return false;
            }
        }
        
        return true;
    }


    private removeCheck() {
        if (me && hasGroup(me, Groups.KapJtrCoordinator)) return;

        if (me && hasGroup(me, Groups.KapJtrInstituteContact) && this.state.visit && this.state.visit.wf_station_id == ApInstituteTime) {
            this.onChange("supporter_approved", null);
        }
    }

    render() {
        if (!this.state.visit) {
            return <Dialog title="Betöltés" width={1200} onClose={this.props.onClose}><BubbleLoader /></Dialog>;
        }

        const defaultDate = this.state.visit.min_start_date ? this.state.visit.min_start_date.substring(0, 10) : "";

        const stationId = this.state.visit.wf_station_id;

        const subprogramEditable = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrSupport, Groups.KapJtrInstituteContact]) && (!stationId || stationId == ApInstituteTime || stationId == ApSupportTime);
        const instituteSiteEditable = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrInstituteContact]) && (!stationId || stationId == ApInstituteTime || stationId == ApSupportTime);
        const supportEditable = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrSupport]) && (!stationId || stationId == ApInstituteTime || stationId == ApSupportTime);

        const startEndDateEditable = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrSupport]) && (!stationId || stationId == ApInstituteTime || stationId == ApSupportTime);
        const isInternalEditable = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrInstituteContact]) && (!stationId || stationId == ApInstituteTime || stationId == ApSupportTime || stationId == ApTimeBusy);

        const isMinMaxDateVisible = (!stationId && me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrSupport])) || stationId == ApSupportTime;

        const dateEditable = me && ((hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator]) && (!stationId || stationId == ApInstituteTime || stationId == ApSupportTime || stationId == ApTimeBusy))
                                 || (hasGroup(me, Groups.KapJtrInstituteContact) && (!stationId || stationId == ApInstituteTime || stationId == ApSupportTime)));

        const minMaxVisitorsEditable = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrInstituteContact]) && (stationId == ApTimeBusy);

        const teacherEditable = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrInstituteContact]) && (stationId == ApTimeBusy);

        const dataEditable = me && (hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrInstituteContact]) || (me.id == this.state.visit.teacher_id && hasGroup(me, Groups.KapKszrCertifiedTeacher))) && (stationId == ApTimeBusy);

        const canShowReservationns = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrSupport, Groups.KapJtrInstituteContact]) && (stationId && [ApAnnouncedWaitingApply, ApApplyClosed, ApWaitingAttendance, ApClosed, ApDeleted].includes(stationId));

        const canReserve = me && hasAnyGroup(me, [Groups.Admin, Groups.KapKszrCertifiedTeacher]) && me.id != this.state.visit.teacher_id && (stationId == ApAnnouncedWaitingApply);

        const canUploadFile = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrSupport]) && (stationId && [ApApplyClosed, ApWaitingAttendance, ApClosed, ApDeleted].includes(stationId));

        const canShowAspects = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrCoordinator, Groups.KapJtrSupport]) && (stationId && [ApApplyClosed, ApWaitingAttendance, ApClosed, ApDeleted].includes(stationId));

        const fixedKapSupporterId = me && (!hasGroup(me, Groups.KapJtrCoordinator) && hasGroup(me, Groups.KapJtrSupport)) ? me.id : undefined;

        const supporterClearable = (me && (!hasGroup(me, Groups.KapJtrCoordinator) && hasGroup(me, Groups.KapJtrSupport))) ? false : true;

        const dateString = formatDateWithoutTime(this.state.visit.min_start_date);

        const visitDraftEditable = me && hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapKszrCertifiedTeacher]) && (stationId == ApTimeBusy) && me.id == this.state.visit.teacher_id;

        const siteCheckboxEnabled = me && hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapJtrCoordinator, Groups.KapJtrInstituteContact]) && (!stationId || stationId == ApSupportTime || stationId == ApInstituteTime);

        const supporterCheckboxEnabled = me && hasAnyGroup(me, [Groups.Admin, Groups.Developer, Groups.KapJtrCoordinator, Groups.KapJtrSupport]) && (!stationId || stationId == ApSupportTime || stationId == ApInstituteTime);

        let downloadLink = null;
        if (this.state.visit.occupation_draft_id) {
            downloadLink = <a className="button tiny" target="_blank" download="Óravázlat.pdf" href={`/api/kap/draft?id=${this.state.visit.occupation_draft_id}&format=pdf&type=occupation_draft&published=false`}>
                <i className="fa fa-download"/>&nbsp; Óravázlat
            </a>;
        }

        return <>
            <Dialog title={dateString + " - Csoportszintű alprogrami foglalkozás látogatás"} width={1200} onClose={this.props.onClose}>

            <DialogContent>
                <div className="row expanded">
                    <div className="column small-4">
                        <label>
                        Alprogram
                    </label>
                        <LookupEdit
                            key={this.state.visit.subprogram_id}
                            fk_table_info_id={SubprogramCrud.TABLE_INFO_ID}
                            emptyLoad={true}
                            isDisabled={!subprogramEditable}
                            viewClassProxy={viewKapUserSubprogramClassProxy}
                            searchColumnNames={["subprogram_title"]}
                            selectColumnNames={["subprogram_id", "subprogram_title"]}
                            displayColumnNames={["subprogram_title"]}
                            orderByColumnNames={[{name:"subprogram_title"}]}
                            valueColumn={"subprogram_id"}
                            distinct={true}

                            filter={{
                                sec_user_id: this.state.visit.kap_supporter_id || fixedKapSupporterId
                            }}
                            
                            clearable={true}
                            value={this.state.visit.subprogram_id}
                            onChange={e => this.onChange("subprogram_id", e)}

                            placeholder="Kérem válasszon alprogramot..."
                        />
                    </div>

                    <div className="column small-4">
                        <label>
                            Jelentkezők számának korlátozása
                        </label>
                        <div style={{display:"flex", alignItems: "center"}}>
                            <input  type="number" min={0} value={this.state.visit.min_visitors || ""} onChange={e => this.onChange("min_visitors", Number(e.target.value))} style={{margin: 0, minWidth: "3em"}} />
                            &nbsp;-&nbsp;
                            <input type="number" min={0} value={this.state.visit.max_visitors || ""} onChange={e => this.onChange("max_visitors", Number(e.target.value))} style={{margin: 0, minWidth: "3em"}} />
                        </div>
                    </div>

                    <div className="column small-4">
                        <label>
                            Státusz
                        </label>
                        <div style={{...this.state.visit.wf_station_style, padding: "0.5em"}}>
                            {this.state.visit.wf_station_name || "Új létrehozása"}
                        </div>
                    </div>

                    <div className="column small-4">
                    {
                        isMinMaxDateVisible &&
                        <>
                            <label>
                                Legkorábbi / legkésőbbi időpont *
                            </label>

                            <div style={{display:"flex", alignItems: "center"}}>
                                <DateTimePicker 
                                    disabled={startEndDateEditable ? undefined : DateTimeGeneralType.all}
                                    value={this.state.visit.min_start_date ? this.state.visit.min_start_date.substring(0, 16) : ""} 
                                    onChange={(date, time) => this.onChange("min_start_date", date + " " + time)}
                                    visibled={DateTimeGeneralType.time} className="end" maxTime="22:00"
                                />
                                -
                                <DateTimePicker 
                                    disabled={startEndDateEditable ? undefined : DateTimeGeneralType.all}
                                    value={this.state.visit.max_end_date ? this.state.visit.max_end_date.substring(0, 16) : ""} 
                                    onChange={(date, time) => this.onChange("max_end_date", date + " " + time)}
                                    visibled={DateTimeGeneralType.time} className="end" maxTime="22:00"
                                />
                            </div>
                        </>
                    }
                    </div>

                    <div className="column small-4">
                        <label>
                            Időpont
                        </label>

                        <div style={{display:"flex", alignItems: "center"}}>
                            <DateTimePicker
                                disabled={dateEditable ? undefined : DateTimeGeneralType.all}
                                value={this.state.visit.start_date ? this.state.visit.start_date.substring(0, 16) : ""} 
                                onChange={(date, time) => this.onChange("start_date", (date || defaultDate) + " " + time, this.removeCheck.bind(this))}
                                visibled={DateTimeGeneralType.time} className="end" maxTime="22:00"
                            />
                            -
                            <DateTimePicker 
                                disabled={dateEditable ? undefined : DateTimeGeneralType.all}
                                value={this.state.visit.end_date ? this.state.visit.end_date.substring(0, 16) : ""} 
                                onChange={(date, time) => this.onChange("end_date", (date || defaultDate) + " " + time, this.removeCheck.bind(this))}
                                visibled={DateTimeGeneralType.time} className="end" maxTime="22:00"
                            />
                        </div>
                    </div>

                    <div className="column small-4">
                        <label>
                            Zárt<br/>
                            <input disabled={!isInternalEditable} type="checkbox" checked={this.state.visit.is_internal} onChange={e => this.onChange("is_internal", e.target.checked)} />
                            &nbsp; Csak intézményen belül látogatható
                        </label>
                    </div>

                    <div className="column small-8">
                        <label>
                            Szakmai támogató
                        </label>
                        <LookupEdit
                            key={this.state.visit.kap_supporter_id || ""}
                            fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                            emptyLoad={true}
                            isDisabled={!supportEditable}
                            viewClassProxy={viewKapSubprogramSupporterSiteClassProxy}
                            searchColumnNames={["supporter_full_name"]}
                            selectColumnNames={["kap_supporter_id", "supporter_full_name","supporter_email"]}
                            displayColumnNames={["supporter_full_name","supporter_email"]}
                            orderByColumnNames={[{name:"supporter_email"}]}
                            valueColumn={"kap_supporter_id"}
                            distinct={true}

                            filter={{
                                kap_supporter_id: (this.state.visit.kap_supporter_id && this.state.visit.kap_supporter_id != fixedKapSupporterId) ? undefined : fixedKapSupporterId,
                                institute_id: this.state.visit.institute_id || undefined,

                                "$notnull": "kap_introduction_date"
                            }}

                            spec={{
                                subprogram_id: this.state.visit.subprogram_id || undefined
                            }}

                            asTextViewClassProxy={viewKapJtrSupporterClassProxy}
                            asTextColumnNames={["fullname", "email"]}
                            asTextFilter={{

                            }}
                            asTextKeyColumn="id"

                            clearable={supporterClearable} 
                            value={this.state.visit.kap_supporter_id}
                            onChange={e => this.onChange("kap_supporter_id", e)}

                            placeholder="Kérem válasszon szakmai támogatót..."
                            styles={{singleValue: base => ({...base, whiteSpace:"pre-wrap"}), menuPortal: base => ({...base, zIndex: 19999}) }}
                            noOptionsMessage={() => "Nincs választható szakmai támogató"}
                        />
                    </div>

                    <div className="column small-4">
                        <label style={{marginTop: "2.5em"}}>
                            <input 
                                disabled={!supporterCheckboxEnabled} 
                                type="checkbox" 
                                checked={!!this.state.visit.supporter_approved} 
                                onChange={event => this.onChange("supporter_approved", event.target.checked ? new Date().toISOString() : null)} />
                            Időpont a szakmai támogató által jóváhagyva
                        </label>
                    </div>

                    <div className="column small-8">
                        <label>
                            Intézményi feladatellátási hely
                        </label>
                        {
                            me && hasGroup(me, Groups.KapJtrInstituteContact)
                            ?
                            <LookupEdit
                                key={this.state.visit.institute_site_id || ""}
                                fk_table_info_id={InstituteSiteCrud.TABLE_INFO_ID}
                                emptyLoad={true}
                                isDisabled={!instituteSiteEditable}
                                viewClassProxy={viewKapInstituteSiteContactClassProxy}
                                searchColumnNames={["institute_site_name"]}
                                selectColumnNames={["institute_site_id", "institute_site_name"]}
                                displayColumnNames={["institute_site_name"]}
                                orderByColumnNames={[{name:"institute_site_name"}]}
                                valueColumn={"institute_site_id"}
                                distinct={true}

                                filter={{
                                    //"$notnull": "kap_introduction_date"
                                    sec_user_id: me.id
                                }}

                                clearable={false}
                                value={this.state.visit.institute_site_id}
                                onChange={e => {console.log("institute_site_id", e);this.onChange("institute_site_id", e)}}

                                placeholder="Kérem válasszon feladatellátási helyet..."
                                styles={{singleValue: base => ({...base, whiteSpace:"pre-wrap"}), menuPortal: base => ({...base, zIndex: 19999}) }}
                                noOptionsMessage={() => "Nincs választható feladatellátási hely"}
                            />
                            :
                            <LookupEdit
                                key={this.state.visit.institute_site_id || ""}
                                fk_table_info_id={InstituteSiteCrud.TABLE_INFO_ID}
                                emptyLoad={true}
                                isDisabled={!instituteSiteEditable}
                                viewClassProxy={viewKapSubprogramSupporterSiteClassProxy}
                                searchColumnNames={["institute_site_name"]}
                                selectColumnNames={["institute_site_id", "institute_site_name"]}
                                displayColumnNames={["institute_site_name"]}
                                orderByColumnNames={[{name:"institute_site_name"}]}
                                valueColumn={"institute_site_id"}
                                distinct={true}

                                filter={{
                                    kap_supporter_id: this.state.visit.kap_supporter_id || fixedKapSupporterId,

                                    "$notnull": "kap_introduction_date"
                                }}

                                clearable={true}
                                value={this.state.visit.institute_site_id}
                                onChange={e => this.onChange("institute_site_id", e)}

                                placeholder="Kérem válasszon feladatellátási helyet..."
                                styles={{singleValue: base => ({...base, whiteSpace:"pre-wrap"}), menuPortal: base => ({...base, zIndex: 19999}) }}
                                noOptionsMessage={() => "Nincs választható feladatellátási hely"}
                            />
                        }
                    </div>

                    <div className="column small-4">
                        <label style={{marginTop: "2.5em"}}>
                            <input 
                                disabled={!siteCheckboxEnabled}
                                type="checkbox" 
                                checked={!!this.state.visit.site_approved} 
                                onChange={event => this.onChange("site_approved", event.target.checked ? new Date().toISOString() : null)} />
                            Időpont az intézmény által jóváhagyva
                        </label>
                    </div>

                    <div className="column small-12">
                        <label>
                            A kiválasztott intézmény feladatellátási helyének címe
                        </label>
                        <LookupEdit
                            key={this.state.visit.institute_site_id || ""}
                            fk_table_info_id={InstituteSiteCrud.TABLE_INFO_ID}
                            emptyLoad={true}
                            isDisabled={true}
                            viewClassProxy={viewInstituteSiteClassProxy}
                            searchColumnNames={["name"]}
                            selectColumnNames={["name"]}
                            displayColumnNames={["city", "zipcode", "address"]}
                            orderByColumnNames={[{name:"name"}]}
                            valueColumn={"id"}
                            distinct={true}
                            clearable={true}

                            value={this.state.visit.institute_site_id}

                            placeholder="Kérem válasszon feladatellátási helyet..."
                        />
                    </div>
                    
                    <div className="column small-12">
                        <label>
                            Óratartó pedagógus
                        </label>
                        <LookupEdit
                            key={this.state.visit.teacher_id || ""}
                            fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                            isDisabled={!teacherEditable}
                            emptyLoad={true}
                            viewClassProxy={viewKapInstituteSiteTeacherClassProxy}
                            distinct={true}
                            searchColumnNames={["teacher_full_name", "teacher_email"]}
                            displayColumnNames={["teacher_full_name", "teacher_email"]}
                            orderByColumnNames={"teacher_full_name"}
                            valueColumn={"sec_user_id"}
                            filter={{is_active: true, institute_site_id: this.state.visit.institute_site_id}}
                            clearable={true}
                            value={this.state.visit.teacher_id}
                            onChange={value => this.onChange("teacher_id", value)}
                            placeholder="Kérem válasszon pedagógust..."
                            styles={{singleValue: base => ({...base, whiteSpace:"pre-wrap"}), menuPortal: base => ({...base, zIndex: 19999}) }}
                            noOptionsMessage={() => "Nincs megfelelő pedagógus"}
                        />
                    </div>
                    
                    <div className="column small-4">
                        <label>
                            Teremszám
                        </label>
                        <input disabled={!dataEditable} type="text" value={this.state.visit.room_no || ""} onChange={e => this.onChange("room_no", e.target.value)} style={{margin: 0}} />
                    </div>
                    <div className="column small-4">
                        <label>
                            Osztály
                        </label>
                        <input disabled={!dataEditable} type="text" value={this.state.visit.group_type_other || ""} onChange={e => this.onChange("group_type_other", e.target.value)} style={{margin: 0}} />
                    </div>
                    <div className="column small-4">
                        {/*
                        <label>
                            Évfolyam
                        </label>
                        <CrudSelectComponent
                            crudClassProxy={gradeCrudClassProxy}
                            value={this.state.visit.grade_id || null}
                            filter={{is_active: true}}
                            onSelect={(_, id) => this.onChange("grade_id", id)}
                            displayFieldName="name"
                            emptyTitle={__("Minden osztály")}
                            orderByFieldName="id"
                            key="id"
                            sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                        />
                        }*/}
                    </div>


                    <div className="column small-4">
                        <label>
                            A foglalkozás célja
                        </label>
                        <input disabled={!dataEditable} type="text" value={this.state.visit.goal || ""} onChange={e => this.onChange("goal", e.target.value)} style={{margin: 0}} />
                    </div>
                    <div className="column small-4">
                        <label>
                            Tematikus egység
                        </label>
                        <input disabled={!dataEditable} type="text" value={this.state.visit.thematic_unit || ""} onChange={e => this.onChange("thematic_unit", e.target.value)} style={{margin: 0}} />
                    </div>
                    <div className="column small-4">
                        <label>
                            Témakör
                        </label>
                        <input disabled={!dataEditable} type="text" value={this.state.visit.topic || ""} onChange={e => this.onChange("topic", e.target.value)} style={{margin: 0}} />
                    </div>

                    {
                        canShowReservationns &&
                        <div className="column small-12">
                            <label>
                                Jelentkezők ({this.state.reservations.length}): 
                            </label>
                            { 
                                this.state.reservations.length === 0 
                                ?
                                <em>Még nincs jelentkező</em>
                                :
                                this.state.reservations.map(r => r.fullname).join(", ") 
                            }
                        </div>
                    }
                    

                    {
                        canUploadFile && this.state.visit.oo_folder_id &&
                        <div className="column small-12">
                            <label>
                                Fényképek feltöltése
                            </label>
                            <MediaFileChooser
                                rootFolderId={this.state.visit.oo_folder_id}
                                currentFolderId={this.state.visit.oo_folder_id}
                                enabledActions={FILE_ACTIONS}
                                showFilter={false}
                                showLocation={false}
                            />
                        </div>
                    }

                </div>

            </DialogContent>

            <DialogActions>
                {
                    this.state.reserved && <span style={{color: "green"}}>Ön jelentkezett az órára</span>
                }
                {
                    canReserve && this.props.id &&
                    <button className={"button " + (this.state.reserved ? "alert" : "success")} onClick={this.onReserve.bind(this)}>
                        <i className={"fa fa-" + (this.state.reserved ? "minus-square": "check-square")}/>
                        &nbsp; {this.state.reserved ? "Lemondom a jelentkezést" : "Jelentkezem az órára"}
                    </button>
                }

                {
                    canShowAspects && this.state.visit.id && this.state.visit.subprogram_id &&
                    <button className="button" onClick={() => this.setState({aspectDialogOpen: !this.state.aspectDialogOpen})}>
                        <i className={"fa fa-check-square"}/>
                        &nbsp; Szempontsor
                    </button>
                }

                {
                    (visitDraftEditable && this.state.visit.id && this.state.visit.subprogram_id) ?
                    <button className="button" title="Óravázlat" onClick={() => this.setState({visitDraftOpen: true})}>
                        <i className="fa fa-file-alt"/> Óravázlat
                    </button>
                    :
                    downloadLink
                }


                {
                    this.state.canTransition && this.props.id &&
                    <>
                        <button disabled={this.state.isDataChanged} className="button" onClick={() => this.setState({workflowDialogOpen: !this.state.workflowDialogOpen})}>
                            <i className="fa fa-project-diagram"/>&nbsp; {__("Folyamat...")}
                        </button>
                    </>
                }
                {
                    this.state.canSave &&
                    <button className="button" onClick={this.onSave.bind(this)}>
                        <i className="fa fa-save"/> Mentés
                    </button>
                }
            </DialogActions>


        </Dialog>
        {
            this.state.visit.id && this.state.visit.subprogram_id && this.state.aspectDialogOpen &&
            <SubprogramAspectDialog 
                occupationVisitId={this.state.visit.id}
                stationId={this.state.visit.wf_station_id}
                subprogramId={this.state.visit.subprogram_id}
                subprograms={this.state.subprograms}
                onClose={() => this.setState({aspectDialogOpen: false})}
            />
        }

        {
            this.state.visitDraftOpen &&
            <Dialog title="Óravázlat" width={1100} height={800} onClose={() => this.setState({visitDraftOpen: false})}>
                <OccupationDraftEdit
                    id={this.state.visit.occupation_draft_id || undefined}
                    onSave={this.onSetOccupationDraft.bind(this)}
                    defaultLibraryId={LIBRARY_JTR_BEADANDO_ID}
                    hideBackLink
                />
            </Dialog>
        }
        
        <WorkflowDialog 
            open={this.state.workflowDialogOpen} 
            displayName={__("Látogatás")} 
            tableInfoId={OccupationVisitCrud.TABLE_INFO_ID} 
            recId={this.props.id!} 
            onClose={() => this.setState({workflowDialogOpen: false})} 
            onBeforeTransition={this.onBeforeTransition.bind(this)}
            onTransition={this.reloadAsync.bind(this)}
            autoPublishOnClosedStation={true}
        />
        </>
        ;
    }

}


type SubprogramAspectDialogProps = {
    occupationVisitId: number;
    stationId?: number | null;
    subprograms: ISubprogramRecord[];
    subprogramId: number;
    onClose: () => void;
}

type SubprogramAspectDialogState = {
    aspects: ISubprogramAspectRecord[],
    values: ISubprogramAspectValueRecord[],
    answers: ISubprogramAspectAnswerRecord[],
}

class SubprogramAspectDialog extends React.Component<SubprogramAspectDialogProps, SubprogramAspectDialogState> {
    
    constructor(props: SubprogramAspectDialogProps) {
        super(props);

        this.state = {
            aspects: [],
            values: [],
            answers: []
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        try {

            const aspects = await SubprogramAspectCrud.list({
                filter: {is_active: true},
                order_by: "no"
            });

            const values = await SubprogramAspectValueCrud.list({
                filter: {is_active: true},
                order_by: "id"
            });

            const answers = await SubprogramAspectAnswerCrud.list({
                filter: {
                    is_active: true,
                    occupation_visit_id: this.props.occupationVisitId
                }
            })

            this.setState({
                aspects,
                values,
                answers
            })

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onSetValue(aspectId: number, property: string, value: string | number) {
        const answer = this.state.answers.find(ans => aspectId == ans.subprogram_aspect_id);
        if (answer) {
            answer[property] = value;
        } else {
            this.state.answers.push({
                occupation_visit_id: this.props.occupationVisitId,
                subprogram_aspect_id: aspectId,
                [property]: value,

            });
        }

        this.setState({answers: this.state.answers});
    }

    private async onSave() {
        try {
            
            for(const answer of this.state.answers) {

                if (answer.id) {
                    answer.occupation_visit_id = undefined; // Szerver: tétel rekordok áthelyezése másik folyamat alá nem támogatott
                }

                await new SubprogramAspectAnswerCrud(answer).put();
            }

            app.showSuccess("Szempontsor lementve", "Szempontsor lementve");

            this.props.onClose();

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        const canEdit = me && hasAnyGroup(me, [Groups.Admin, Groups.KapJtrSupport]) && (this.props.stationId == ApApplyClosed);

        return <Dialog title="Szempontsor" width={1000} onClose={this.props.onClose}>
            <DialogContent>
                <div className="row">
                    <div className="column small-12">
                        <h5>Általános</h5>
                    </div>
                    {this.getAspectPart(null, !!canEdit)}
                    {
                        this.props.subprograms.filter(s => this.props.subprogramId == s.id).map((s, i) => {
                            return <React.Fragment key={i}>
                                    <div className="column small-12">
                                        <h5>{s.title}</h5>
                                    </div>
                                    {this.getAspectPart(s.id!, !!canEdit)}
                                </React.Fragment>
                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                {
                    canEdit &&
                    <button className="button" onClick={this.onSave.bind(this)}>
                        <i className="fa fa-save"/> Mentés
                    </button>
                }
            </DialogActions>
        </Dialog>;
    }

    private getAspectPart(subprogramId: number | null, canEdit: boolean) {
        const aspects = this.state.aspects.filter(a => a.subprogram_id === subprogramId);

        return aspects.map((aspect, index) => {
            const answer = this.state.answers.find(ans => aspect.id == ans.subprogram_aspect_id);

            return <React.Fragment key={index}>
                <div className="column small-12">
                    {aspect.title}
                </div>
                <div className="column small-7" style={{display: "flex", alignItems: "center"}}>
                    {
                        this.state.values.map((v, i) => {
                            return <label key={i} style={{margin: "0 0.5em"}}>
                                    <input disabled={!canEdit} type="radio" checked={answer && answer.subprogram_aspect_value_id === v.id} onChange={() => this.onSetValue(aspect.id!, "subprogram_aspect_value_id", v.id!)} /> {v.title}
                                </label>;
                        })
                    }
                </div>
                <div className="column small-5">
                    <input 
                        type="text" 
                        disabled={!answer || !canEdit}
                        value={answer && answer.comments ? answer.comments : ""} 
                        onChange={e => this.onSetValue(aspect.id!, "comments", e.target.value)} />
                </div>
            </React.Fragment>;
        })
    }
    
}
