import * as React from 'react'
import { BookSelector } from './BookSelector';
import { DownloadableMaterials } from './DownloadableMaterials';
import { __ } from '@src/translation';

export class BookSelectorPage extends React.Component<{}, {}> {

    render() {
        return <>

                <BookPage />

                <DownloadableMaterials />
                
            </>
    }
}



export class BookPage extends React.Component<{}, {}> {

    render() {
        return <div className="main main-landing section" style={{minHeight: "80vh", background: "url('/img/LANDING/landing_bg.png')no-repeat center", backgroundAttachment: "fixed", backgroundSize: "cover"}}>
                    <div className="row">
                        <div className="column small-12">
                            <h3 className="title text-center">{__("Válassz tankönyvet!")}</h3>
                            <BookSelector />
                        </div>
                    </div>
                </div>

    }
}
