import * as React from 'react';
import { ContentType, FileTypeIcon, getContetnTypeIcon } from "@src/component/filemanager/ContentUtils";


export default class TypeIcon extends React.Component<{ contentType: ContentType }, any> {

    render(){

        var src=getContetnTypeIcon(this.props.contentType) ? getContetnTypeIcon(this.props.contentType) : FileTypeIcon.FILE_UNKNOWN;

        return <img className="icon" src={src} title={this.props.contentType} alt={this.props.contentType}/>

    }
}