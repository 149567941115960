import * as React from 'react';
import { match } from 'react-router';
import { __ } from '@src/translation';
import { app } from '@src/index';
import { getContentDetailsList, ContentDetails } from '@src/component/filemanager/ContentUtils';
import { List, ListItem, ListItemImage, ListItemText, ListItemMenu, ListItemInfo } from '@src/component/ui/List';
import { BubbleLoader } from 'react-css-loaders';
import { Link } from 'react-router-dom';
import { SHARE_MEGOSZTAS_ID, SHARE_GYAKORLO_ID, SHARE_VIZSGA_ID } from '@src/Const';
import { PATH_SHARED_CONTENT_WITH_ME, PATH_SHARED_CONTENT_BY_ME, PATH_EXERCISE_ENGINE_SELECT, PATH_EXERCISE_SERIES_LIST } from '@src/Routes';
import { formatDate, addToDate } from '@src/Util';
import { Dialog, confirmDialog } from '@src/component/Dialog';
import ViewInstituteGroupMember, { IViewInstituteGroupMemberRecord } from '@src/framework/view/sys/ViewInstituteGroupMember';
import { me } from '@src/framework/server/Auth';
import UsrShareInClassroomDialog from '@src/component/usr/UsrShareInClassroomDialog';
import SharedContentCrud, { ISharedContentRecord } from '@src/framework/crud/usr/SharedContentCrud';
import { TFilterDict } from '@src/framework/crud/Crud';
import { DateToString, DTSMode } from '@src/component/dashboard/eventcalendar/EventCalendarAPI';
import { Popover } from "@src/component/ui/Popover";
import OpenEndedQuestionCrud from '@src/framework/crud/usr/OpenEndedQuestionCrud';
import ExerciseCrud from '@src/framework/crud/exc/ExerciseCrud';
import ExerciseSeriesCrud, { exerciseSeriesCrudClassProxy } from '@src/framework/crud/exc/ExerciseSeriesCrud';
import ExercisePubCrud from '@src/framework/crud/exc_pub/ExerciseCrud';
import ExerciseSeriesPubCrud from '@src/framework/crud/exc_pub/ExerciseSeriesCrud';
import { ExerciseEvaluationHelper, ExerciseSubmissionStates } from '@src/component/exercise/ExerciseEvaluationHelper';
import ViewSharedWithMe, { IViewSharedWithMeRecord } from '@src/framework/view/usr/ViewSharedWithMe';
import SharedWithCrud from '@src/framework/crud/usr/SharedWithCrud';
import ViewSharedFromMyGroup, { IViewSharedFromMyGroupRecord } from '@src/framework/view/usr/ViewSharedFromMyGroup';
import { InstituteGroupMemberStates } from '@src/component/institute/InstituteGroupMemberList';
import { Toggle } from '@src/component/ui/Panel';
import { ClassroomTasksListItem } from './component/ClassroomTaskListItem';

type ClassroomTasksPageProps = {
    match: match<{ instituteGroupId: string }>;
}

enum DateFilter {
    NO_FILTER,
    TODAY,
    LAST_3_DAYS,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM
}
enum TaskFilter {
    NO_FILTER,
    NOT_STARTED,
    SHARE,
    PRACTICE,
    EXAM,
    EXERCISE,
    EXERCISE_SIRIES,
    TASK
}
type SubmissionDetails = {
    id: number,
    subbmitted: number,
    total: number
}

type ClassroomTasksPageState = {
    showMenu: boolean,
    loading: boolean,
    isGroupAdmin?: boolean,
    sharedWithMe: IViewSharedWithMeRecord[],
    sharedByMe: IViewSharedFromMyGroupRecord[],
    filteredSharedWithMe: IViewSharedWithMeRecord[],
    filteredSharedByMe: IViewSharedFromMyGroupRecord[],
    details?: (ContentDetails | undefined)[],
    shareDialogOpen: boolean,
    selectedSharedContentId?: number | null,
    sharedContentIndex: number,
    classRoomId: number,
    fromDate?: string,
    toDate?: string,
    filterShareModeId?: number[],
    filterTableInfoId?: number[],
    selectedFilter?: TaskFilter[],
    showCustomDateFilter: boolean,
    selectedTableInfoId?: number,
    members: IViewInstituteGroupMemberRecord[],
    studentCount:number,
    sharedByMeDetails: SubmissionDetails[],
    sharedWithMeDetails: Map<number, number>,
    filtersOpen: boolean,
    filterNotStarted?: boolean,
    currNumOfSubmissions?:number
}

export class ClassroomTasksPage extends React.Component<ClassroomTasksPageProps, ClassroomTasksPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            showMenu: false,
            details: [],
            sharedByMe: [],
            sharedWithMe: [],
            filteredSharedByMe: [],
            filteredSharedWithMe: [],
            shareDialogOpen: false,
            sharedContentIndex: -1,
            classRoomId: 0,
            fromDate: DateToString(addToDate(new Date(), 0, -1, 0), DTSMode.dateFull, "-"), //By default we set fromDate last 30 days
            toDate: DateToString(addToDate(new Date(), 0, 0, 7), DTSMode.dateFull, "-"), //And one week extra
            selectedFilter: [],
            showCustomDateFilter: false,
            members: [],
            studentCount:0,
            sharedByMeDetails: [],
            sharedWithMeDetails: new Map<number, number>(),
            filtersOpen: false
        }

        this.asyncReload();
    }

    private getFilters(group?: number) {
        switch (group) {
            case 1:
                {
                    return [

                        { group: 1, name: __("Nincs elkezdve"), iconClassName: "", value: TaskFilter.NOT_STARTED, onlyStudent: true },
                    ]
                }
            case 2:
                {
                    return [
                        { group: 2, name: __("Egyszerű megosztás"), iconClassName: "", value: TaskFilter.SHARE },
                        { group: 2, name: __("Gyakorló kiosztása"), iconClassName: "", value: TaskFilter.PRACTICE },
                        { group: 2, name: __("Vizsga kiosztása"), iconClassName: "", value: TaskFilter.EXAM },
                    ]
                }
            case 3:
                {
                    return [
                        { group: 3, name: __("Okosfeladat"), iconClassName: "", value: TaskFilter.EXERCISE },
                        { group: 3, name: __("Okosfeladatsor"), iconClassName: "", value: TaskFilter.EXERCISE_SIRIES },
                        { group: 3, name: __("Szöveges feladat"), iconClassName: "", value: TaskFilter.TASK },
                    ]
                }
        }

        return [

            { group: 1, name: __("Nincs elkezdve"), iconClassName: "", value: TaskFilter.NOT_STARTED, onlyStudent: true },
            { group: 2, name: __("Egyszerű megosztás"), iconClassName: "", value: TaskFilter.SHARE },
            { group: 2, name: __("Gyakorló kiosztása"), iconClassName: "", value: TaskFilter.PRACTICE },
            { group: 2, name: __("Vizsga kiosztása"), iconClassName: "", value: TaskFilter.EXAM },
            { group: 3, name: __("Okosfeladat"), iconClassName: "", value: TaskFilter.EXERCISE },
            { group: 3, name: __("Okosfeladatsor"), iconClassName: "", value: TaskFilter.EXERCISE_SIRIES },
            { group: 3, name: __("Szöveges feladat"), iconClassName: "", value: TaskFilter.TASK },

        ]
    };

    private asyncReload = async () => {
        this.setState({ loading: true });
        if (!me) return;
        // todo: same page for course loading, but different url. 
        try {
            let classRoomId = Number(this.props.match.params.instituteGroupId);
            if (!classRoomId) return;

            let details: (ContentDetails | undefined)[] = [];

            let userIsGroupAdmin = false;

            const members: IViewInstituteGroupMemberRecord[] = await ViewInstituteGroupMember.list({
                filter: {
                    institute_group_id: classRoomId,
                    is_active: true,
                    status_id: InstituteGroupMemberStates.ACTIVE_ID
                }
            });
            const admins = (members.filter((elem) => { return elem.is_admin }));
            userIsGroupAdmin = (admins.findIndex((el) => { return (el.sec_user_id == me!.id) }) > -1);
            const studentCount = members.length - admins.length;

            let sharedWithMe: IViewSharedWithMeRecord[] = [];
            let sharedByMe: IViewSharedFromMyGroupRecord[] = [];
            let sharedByMeDetails: SubmissionDetails[] = [];
            let sharedWithMeDetails = new Map<number, number>();

            //Közös filterek
            let filter: TFilterDict = { is_active: true, share_mode_id: this.state.filterShareModeId, table_info_id: this.state.filterTableInfoId };
            
            if (this.state.fromDate) {
                let from = new Date(this.state.fromDate);
                from.setHours(0, 0, 0, 0);
                filter[">="] = { start_date: from.getTime() }
            }
            if (this.state.toDate) {
                let to = new Date(this.state.toDate);
                to.setHours(23, 59, 59, 999);
                filter["<="] = { start_date: to.getTime() }
            }
            filter.shared_from_institute_group_id = classRoomId;

            if (userIsGroupAdmin) {
                //let sharedByIds:number[] = admins.map((el)=> el.id!);
                //console.log("admins", admins, sharedByIds);
                //filter.shared_by_id = sharedByIds;
                sharedByMe = await ViewSharedFromMyGroup.list({
                    filter, limit: 1000, order_by: [{ name: "start_date", desc: true }]
                });

                details = await getContentDetailsList(sharedByMe.map(i => ({ tableOrViewId: i.table_info_id!, recordId: i.rec_id! })));

               /* for (let index = 0; index < sharedByMe.length; index++) {
                    const elementId = sharedByMe[index].id!;
                    // getting the individually shared submissions
                    let swList = (await SharedWithCrud.list({ filter: { shared_content_id: elementId, institute_group_id: null } }));
                    let count = await ExerciseEvaluationHelper.getNumberOfSubmissions(members, sharedByMe[index]);
                    let subD: SubmissionDetails = { id: elementId, subbmitted: count, total: swList.length > 0 ? swList.length : studentCount };
                    sharedByMeDetails.push(subD);
                }*/
            } else {
                sharedWithMe = await ViewSharedWithMe.list({ filter, limit: 1000, order_by: [{ name: "start_date", desc: true }] });
                details = await getContentDetailsList(sharedWithMe.map(i => ({ tableOrViewId: i.table_info_id!, recordId: i.rec_id! })));
                /*for (let index = 0; index < sharedWithMe.length; index++) {
                    const elementId = sharedWithMe[index].id!;
                    let count = (await ExerciseEvaluationHelper.getUserSuccessPercent(me!.id, elementId));
                    if (count != null) sharedWithMeDetails.set(elementId, count);
                }*/
            }

            this.setState({
                loading: false,
                details,
                sharedWithMe,
                sharedByMe,
                filteredSharedByMe: sharedByMe.map(i => {
                    i.start_date = formatDate(i.start_date);
                    i.end_date = formatDate(i.end_date);
                    i.due_date = formatDate(i.due_date);
                    return i;
                }),
                filteredSharedWithMe: sharedWithMe.map(i => {
                    i.start_date = formatDate(i.start_date);
                    i.end_date = formatDate(i.end_date);
                    i.due_date = formatDate(i.due_date);
                    return i;
                }),
                isGroupAdmin: userIsGroupAdmin,
                classRoomId,
                studentCount,
                members: userIsGroupAdmin ? members : [],
                sharedByMeDetails,
                sharedWithMeDetails
            });
        } catch (error) {
            this.setState({ loading: false });
            app.showErrorFromJsonResult(error);
        }
    }

    async onDeleteSharedByMe(itemId: number) {
        if (!await confirmDialog(__("Törlés"),
            __("Ez a művelet törli a kiválasztott megosztást. A törlés után a tartalom elérhetetlen lesz a megosztáson keresztül. A művelet nem vonható vissza. Biztos benne, hogy törölni akarja?"),
            __("Törlés"))) {
            return;
        }
        try {
            await SharedContentCrud.deleteById(itemId);
            this.asyncReload();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    onChangeDateFilter(propName: string, ev: any) {
        this.setState({ ...this.state, [propName]: ev.target.value }, this.asyncReload);
    }

    arrayRemove(arr: any[], value: any) {
        arr = arr.filter(function (ele) { return ele != value; });
        return arr.length ? arr : undefined;
    }

    onSelectFilter(value: TaskFilter) {
        let from: string = "";
        let to: string = "";

        let shareModeId: number[] | undefined = this.state.filterShareModeId ? this.state.filterShareModeId : [];
        let tableInfoId: number[] | undefined = this.state.filterTableInfoId ? this.state.filterTableInfoId : [];


        let filters: TaskFilter[] | undefined = this.state.selectedFilter ? this.state.selectedFilter : [];

        if (filters && filters.indexOf(value) == -1) {
            filters.push(value);
        }
        else if (filters) {
            filters = this.arrayRemove(filters, value);
        }

        switch (value) {

            case TaskFilter.PRACTICE:
                if (shareModeId!.indexOf(SHARE_GYAKORLO_ID) == -1) {
                    shareModeId!.push(SHARE_GYAKORLO_ID);
                }
                else {
                    shareModeId = this.arrayRemove(shareModeId!, SHARE_GYAKORLO_ID);
                }

                this.setState({ filterShareModeId: shareModeId, selectedFilter: filters }, this.asyncReload);
                break;
            case TaskFilter.SHARE:
                if (shareModeId!.indexOf(SHARE_MEGOSZTAS_ID) == -1) {
                    shareModeId!.push(SHARE_MEGOSZTAS_ID);
                }
                else {
                    shareModeId = this.arrayRemove(shareModeId!, SHARE_MEGOSZTAS_ID);
                }
                this.setState({ filterShareModeId: shareModeId, selectedFilter: filters }, this.asyncReload);
                break;
            case TaskFilter.EXAM:
                if (shareModeId!.indexOf(SHARE_VIZSGA_ID) == -1) {
                    shareModeId!.push(SHARE_VIZSGA_ID);
                }
                else {
                    shareModeId = this.arrayRemove(shareModeId!, SHARE_VIZSGA_ID);
                }
                this.setState({ filterShareModeId: shareModeId, selectedFilter: filters }, this.asyncReload);
                break;

            case TaskFilter.EXERCISE:
                if (tableInfoId!.indexOf(ExerciseCrud.TABLE_INFO_ID) == -1) {
                    tableInfoId!.push(ExerciseCrud.TABLE_INFO_ID);
                    tableInfoId!.push(ExercisePubCrud.TABLE_INFO_ID);
                }
                else {
                    tableInfoId = this.arrayRemove(tableInfoId!, ExerciseCrud.TABLE_INFO_ID);
                    tableInfoId = this.arrayRemove(tableInfoId!, ExercisePubCrud.TABLE_INFO_ID);
                }
                this.setState({ filterTableInfoId: tableInfoId, selectedFilter: filters }, this.asyncReload);
                break;
            case TaskFilter.EXERCISE_SIRIES:
                if (tableInfoId!.indexOf(ExerciseSeriesCrud.TABLE_INFO_ID) == -1) {
                    tableInfoId!.push(ExerciseSeriesCrud.TABLE_INFO_ID);
                    tableInfoId!.push(ExerciseSeriesPubCrud.TABLE_INFO_ID);
                }
                else {
                    tableInfoId = this.arrayRemove(tableInfoId!, ExerciseSeriesCrud.TABLE_INFO_ID);
                    tableInfoId = this.arrayRemove(tableInfoId!, ExerciseSeriesPubCrud.TABLE_INFO_ID);
                }
                this.setState({ filterTableInfoId: tableInfoId, selectedFilter: filters }, this.asyncReload);
                break;
            case TaskFilter.TASK:
                if (tableInfoId!.indexOf(OpenEndedQuestionCrud.TABLE_INFO_ID) == -1) {
                    tableInfoId!.push(OpenEndedQuestionCrud.TABLE_INFO_ID);
                }
                else {
                    tableInfoId = this.arrayRemove(tableInfoId!, OpenEndedQuestionCrud.TABLE_INFO_ID);
                }
                this.setState({ filterTableInfoId: tableInfoId, selectedFilter: filters }, this.asyncReload);
                break;


            case TaskFilter.NOT_STARTED:

                this.setState({ filterNotStarted: !this.state.filterNotStarted, selectedFilter: filters }, this.asyncReload);
                break;

            default:
                break;

        }

    }

    filterByValue(value: string) {

        const filteredSharedWithMe = this.state.sharedWithMe.filter((data: any) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
        const filteredSharedByMe = this.state.sharedByMe.filter((data: any) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
        this.setState({ filteredSharedByMe: filteredSharedByMe, filteredSharedWithMe: filteredSharedWithMe });
    }

    async onCancel() {
        this.onOpenCloseDialog(undefined, null);
    }

    render() {
        /*if (this.state.loading) {
            return <BubbleLoader />;
        }*/

        if (!me) return "";

        let lastDateByme: string = "";
        let lastDateWithMe: string = "";

        return <>
            <div className="row expanded">
                <div className="column">
                    {this.state.isGroupAdmin &&
                        <>
                            <button ref="newTaskBtn" className="button column" onClick={() => { this.setState({ showMenu: true }) }}><i className="fa fa-plus"></i> <strong>{__("Létrehozás")}</strong></button>

                            <Popover open={this.state.showMenu} parentElement={() => this.refs.newTaskBtn as HTMLElement} onClose={() => this.setState({ showMenu: false })} >
                                <div className="content-item-icon__menu-item">
                                    <div onClick={this.onOpenCloseDialog.bind(this, ExerciseCrud.TABLE_INFO_ID)}><i className="fa fa-plus"></i> {__("Okosfeladat kiválasztás")} </div>
                                    <div onClick={this.onOpenCloseDialog.bind(this, ExerciseSeriesCrud.TABLE_INFO_ID)}><i className="fa fa-plus"></i> {__("Okosfeladatsor kiválasztás")} </div>
                                    <div className="profile_form_separator"></div>
                                    <div onClick={this.onOpenCloseDialog.bind(this, OpenEndedQuestionCrud.TABLE_INFO_ID)}><i className="fa fa-plus"></i> {__("Új szöveges feladat")} </div>
                                    <div onClick={this.onOpenInNewTab.bind(this, PATH_EXERCISE_ENGINE_SELECT)}><i className="fa fa-plus" /> {__("Új okosfeladat")}</div>
                                    <div onClick={this.onOpenInNewTab.bind(this, PATH_EXERCISE_SERIES_LIST)}><i className="fa fa-plus" /> {__("Új okosfeladatsor")}</div>
                                </div>
                            </Popover></>

                    }
                </div>
                <div className="column shrink">
                    {
                        this.state.selectedFilter && this.state.selectedFilter.length > 0
                            ?
                            <button style={{ marginRight: "1rem" }} onClick={() => this.setState({ selectedFilter: undefined })}>
                                <i className="fas fa-ban"></i>&nbsp;{"Szűrők megszüntetése"}</button>
                            : null
                    }
                    <button onClick={() => this.setState({ filtersOpen: !this.state.filtersOpen })}>
                        <i className={"fas fa-filter"} style={{ color: (this.state.selectedFilter && this.state.selectedFilter.length > 0 ? "#000" : "#999") }}></i>
                                &nbsp;
                                {__("Feladat szűrők")}
                                &nbsp;
                                {this.state.filtersOpen ? <i className="fas fa-caret-up"></i> : <i className="fas fa-caret-down"></i>}
                    </button>
                </div>
            </div>
            {this.state.selectedTableInfoId &&
                <Dialog hasFullScreenButton={true} title={this.state.selectedTableInfoId == ExerciseSeriesCrud.TABLE_INFO_ID ? __("Feladatsor kiosztása") : __("Feladat kiosztása")} onClose={this.onOpenCloseDialog.bind(this, undefined)} width={1000} height={700}>
                    <UsrShareInClassroomDialog
                        instituteGroupId={this.state.classRoomId}
                        onClose={this.onOpenCloseDialog.bind(this, undefined)}
                        table_info_id={this.state.selectedTableInfoId}
                    />
                </Dialog>
            }
            <div>
                {
                    this.state.shareDialogOpen && this.state.sharedContentIndex != null && this.state.selectedSharedContentId &&
                    <Dialog title={__("Feladat kiosztása")} hasFullScreenButton={true} onClose={() => this.setState({ shareDialogOpen: false, currNumOfSubmissions:0 })} width={1000} height={700}>
                        <UsrShareInClassroomDialog
                            instituteGroupId={this.state.classRoomId}
                            onClose={() => this.setState({ shareDialogOpen: false, currNumOfSubmissions:0 }, ()=>this.asyncReload())}
                            sharedContentId={this.state.selectedSharedContentId}
                            solutionCount={this.state.currNumOfSubmissions || 0}
                        />
                    </Dialog>
                }

                <Toggle isOpen={this.state.filtersOpen}>

                    <div className="row align-center">
                        <div className="large-10 medium-12 small-12 columns">

                            <div>
                                {
                                    this.getFilters(1).map(filter => {

                                        if (this.state.isGroupAdmin && filter.onlyStudent) return null;
                                        let isActiveClass = "";

                                        if (this.state.selectedFilter && this.state.selectedFilter!.indexOf(filter.value) > -1) {
                                            isActiveClass = "active";
                                        }
                                        return <button
                                            key={filter.value}
                                            disabled={this.state.loading}
                                            onClick={this.onSelectFilter.bind(this, filter.value)}
                                            className={"button small primary hollow eke-search__filter-button eke-filter-button " + isActiveClass}
                                        >
                                            <i className={filter.iconClassName}></i> {filter.name}
                                        </button>
                                    })
                                }
                            </div>
                            <div>
                                {
                                    this.getFilters(2).map(filter => {

                                        if (this.state.isGroupAdmin && filter.onlyStudent) return null;
                                        let isActiveClass = "";

                                        if (this.state.selectedFilter && this.state.selectedFilter!.indexOf(filter.value) > -1) {
                                            isActiveClass = "active";
                                        }
                                        return <button
                                            key={filter.value}
                                            disabled={this.state.loading}
                                            onClick={this.onSelectFilter.bind(this, filter.value)}
                                            className={"button small primary hollow eke-search__filter-button eke-filter-button " + isActiveClass}
                                        >
                                            <i className={filter.iconClassName}></i> {filter.name}
                                        </button>
                                    })
                                }<div>
                                    {
                                        this.getFilters(3).map(filter => {

                                            if (this.state.isGroupAdmin && filter.onlyStudent) return null;
                                            let isActiveClass = "";

                                            if (this.state.selectedFilter && this.state.selectedFilter!.indexOf(filter.value) > -1) {
                                                isActiveClass = "active";
                                            }
                                            return <button
                                                key={filter.value}
                                                disabled={this.state.loading}
                                                onClick={this.onSelectFilter.bind(this, filter.value)}
                                                className={"button small primary hollow eke-search__filter-button eke-filter-button " + isActiveClass}
                                            >
                                                <i className={filter.iconClassName}></i> {filter.name}
                                            </button>
                                        })
                                    }
                                </div>
                            </div>

                        </div>

                        <>
                            <div className="large-5 medium-6 small-12 columns">
                                <label>{__("Szűrés kezdete")}
                                    <input type="date" value={this.state.fromDate ? this.state.fromDate.substr(0, 10) : ""}
                                        onChange={this.onChangeDateFilter.bind(this, "fromDate")}
                                    />
                                </label>
                            </div>
                            <div className="large-5 medium-6 small-12 columns">
                                <label>{__("Szűrés vége")}
                                    <input type="date" min={this.state.fromDate ? this.state.fromDate : undefined} value={this.state.toDate ? this.state.toDate.substr(0, 10) : ""}
                                        onChange={this.onChangeDateFilter.bind(this, "toDate")}
                                    />
                                </label>
                            </div>
                        </>
                    </div>

                </Toggle>



                <div className="row align-center">
                    <div className="column small-12 large-10">
                        <div className="input-group eke-search-field main-search">
                            <div className="eke-search__input" style={{ borderRadius: "10px" }}>
                                <input placeholder={__("Keresés...")} onKeyUp={e => this.filterByValue(e.currentTarget.value)} type="text"></input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{position: "absolute", right: "15px"}}>
                    <i title={__("Új feladatok betöltése")} className={"fas fa-sync-alt post-refresh-btn" + (this.state.loading ? " sync" : "")} onClick={this.asyncReload}></i>
                </div>
                {(this.state.loading) ?
                    <BubbleLoader />
                    :
                    <>
                        {this.state.filteredSharedByMe.length == 0 && this.state.filteredSharedWithMe.length == 0 &&
                            <><h4 style={{ display: "flex", alignItems: "center" }}><img style={{ height: "45px", marginRight: "10px" }} src="/img/noResultSearch/thinking2.png"></img>{__("A fenti időszakra nincs találat...")}</h4></>
                        }
                        <List>

                            {
                                this.state.isGroupAdmin ?
                                    this.state.filteredSharedByMe.map((item, index) => {
                                        let seperator = false;
                                        if (item.start_date && item.start_date.substr(0, 12) != lastDateByme) {
                                            lastDateByme = item.start_date.substr(0, 12);
                                            seperator = true;
                                        }
                                        if (!this.state.details || !this.state.details[index]) return "";
                                       
                                        return <>
                                            {seperator && <h5 style={{ marginTop: "1rem" }}>{(lastDateByme.replace(/ +/g, ""))}</h5>}
                                            <ClassroomTasksListItem
                                            isGroupAdmin={this.state.isGroupAdmin || false}
                                            members = {this.state.members}
                                            sharedByMe ={item}
                                            studentCount ={this.state.studentCount}
                                            itemDetail = {this.state.details[index]}
                                            filterNotStarted={this.state.filterNotStarted || false}
                                            shareDialogOpenCallBack={(currNumOfSubmissions) => {  this.setState({
                                                shareDialogOpen: true,
                                                sharedContentIndex: index,
                                                selectedSharedContentId: item.id,
                                                currNumOfSubmissions
                                            })}}
                                            deleteSharedContentCallBack={(currNumOfSubmissions) =>{
                                                this.onDeleteSharedByMe(item.id!)
                                            }}
                                            />                                            
                                        </>
                                    })
                                    :
                                    this.state.filteredSharedWithMe.map((item, index) => {

                                        if (!this.state.details || !this.state.details[index]) return "";
                                        let seperator = false;

                                        if (item.start_date && item.start_date.substr(0, 12) != lastDateWithMe) {
                                            lastDateWithMe = item.start_date.substr(0, 12);
                                            seperator = true;
                                        }
                                     
                                        return <>
                                            {seperator && <h5 style={{ marginTop: "1rem" }}>{(lastDateWithMe.replace(/ +/g, ""))}</h5>}
                                            <ClassroomTasksListItem
                                            isGroupAdmin={this.state.isGroupAdmin || false}
                                            members = {this.state.members}
                                            sharedWithMe ={item}
                                            itemDetail = {this.state.details[index]}
                                            filterNotStarted={this.state.filterNotStarted || false}
                                            />
                                        </>
                                    })
                            }
                        </List>
                    </>}
            </div>
        </>
    }

    onOpenCloseDialog(tableInfoId: number | undefined, event: any) {
        this.setState({ selectedTableInfoId: tableInfoId, showMenu: false }, this.asyncReload);
    }

    onOpenInNewTab(location: string) {
        //<Link className="button small" to={PATH_EXERCISE_ENGINE_SELECT}><i className="fa fa-plus" /> {__("Új feladat hozzáadása")}</Link>
        window.open(location, "_blank");
    }

}