import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ShuffleResult, shuffle, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { FillGapData, EKEFillGapDnDExerciseEngine } from "@src/component/exercise/engine/EKEFillGapDnDExerciseEngine/EKEFillGapDnDExerciseEngine";
import * as React from "react";
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type FillGapAnswerElement = {
    gap_text: string,
    answers: string[],
    infinite_elements: boolean
}

export class EKEFillGapAnswerConverter extends AExerciseTypeConverter {
    public hasTextAnswer = true;
    public hasImageAnswer = false;
    public hasTextImageAnswer = false;
    static MIN_UNIQUE_OPTIONS_NUMBER: number = 2;
    static MAX_OPTIONS_NUMBER: number = 25;
    static MAX_ODDS_NUMBER: number = 15;

    public convertToEditorAnswer(exercise: FillGapData): FillGapAnswerElement | undefined {
        if (exercise) {
            var pattern = /#\d+#/g;
            var result = null;
            let newGapText = exercise.gap_text;
            var deletableItems = [];
            var numOfGaps = 0;
            if (exercise.gap_text) {
                while ((result = pattern.exec(exercise.gap_text))) {
                    newGapText = newGapText.substr(0, result.index) + (newGapText.substr(result.index, newGapText.length)).replace(result.toString(), "#" + (exercise.answers[exercise.solution[numOfGaps]]) + "#");
                    deletableItems.push(exercise.answers[exercise.solution[numOfGaps]]);        //collecting these elements
                    numOfGaps++;
                }
            }

            for (let index = 0; index < deletableItems.length; index++) {                   //delete the elements that are written into the text, because the answers_list should contain only the odd one out-s
                if (exercise.answers.indexOf(deletableItems[index]) != -1) {
                    exercise.answers.splice(exercise.answers.indexOf(deletableItems[index]), 1);
                }
            }

            var answerElement: FillGapAnswerElement = {
                gap_text: newGapText,
                answers: exercise.answers,
                infinite_elements: exercise.infinite_elements,
            }

            return answerElement;
        }
        else return undefined;
    }

    public convertToJson(exerciseDetails: FillGapAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: FillGapAnswerElement): FillGapData | undefined {
        let solution_list = [];
        let ansList = [];
        let solutionShuff = [];
        let gapTxt = "";

        if (exerciseDetails.gap_text) {
            var pattern = /#.*?#/g;
            var numOfGaps = 0;
            var result = null;
            var answer_list: any[] = [];
            var text = exerciseDetails.gap_text;
            if (exerciseDetails.infinite_elements) {
                while ((result = pattern.exec(exerciseDetails.gap_text))) {
                    if (answer_list.includes(result.toString().slice(1, -1))) { //if duplicated solution occurs storing only the index of it to solution_list
                        solution_list.push(answer_list.indexOf(result.toString().slice(1, -1)));
                    } else {                                                    //else store the answeritem itself and the index as well
                        answer_list.push(result.toString().slice(1, -1));
                        solution_list.push(answer_list.indexOf(result.toString().slice(1, -1)));
                    }

                    text = text.replace(result.toString(), '#' + numOfGaps + '#');                      //replacing gaptext element to incremental numbers
                    numOfGaps++;
                }
            } else {
                while ((result = pattern.exec(exerciseDetails.gap_text))) {                                                    //else store the answeritem itself and the index as well
                    answer_list.push(result.toString().slice(1, -1));
                    solution_list.push(answer_list.indexOf(result.toString().slice(1, -1)));


                    text = text.replace(result.toString(), '#' + numOfGaps + '#');                      //replacing gaptext element to incremental numbers
                    numOfGaps++;
                }
            }

            if (exerciseDetails.answers) {
                for (let index = 0; index < exerciseDetails.answers.length; index++) {                  //strogin odd one out elements to answer_list too
                    answer_list.push(exerciseDetails.answers[index]);
                }
            }

            var shuff_solution_list: any[] = [];
            var shuffleRes: ShuffleResult = shuffle(answer_list, null);
            var tempShuffle = shuffleRes.answers.slice();

            //shuffle solution list too
            if (!exerciseDetails.infinite_elements) {
                for (var _i = 0; _i < solution_list.length; _i++) {
                    var index = tempShuffle.indexOf(answer_list[solution_list[_i]])
                    shuff_solution_list.push(index);
                    tempShuffle[index] = "adsad";
                }
            } else {
                for (let index = 0; index < solution_list.length; index++) {
                    shuff_solution_list.push(shuffleRes.answers.indexOf(answer_list[solution_list[index]]));
                }
            }

            ansList = shuffleRes.answers;
            solution_list = shuff_solution_list;
            gapTxt = text;

        }

        var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
        cur_exercise = {
            ...cur_exercise,
            answers: ansList,
            solution: solution_list,
            gap_text: gapTxt,
            infinite_elements: exerciseDetails.infinite_elements
        };

        return cur_exercise;
    }


    public convertOldNKPToJson(data: any): any | undefined {
        var answer_list: string[] = [];
        var solution_list: any[] = [];
        let isImage = false;
        var oddOneOutElements: string[] = [];
        if (data.BaseText) {
            var text = data.BaseText.slice();
            var startingIndexes: number[] = [];

            for (let index = 0; index < data.TextAnswerItems.length; index++) {
                if (data.TextAnswerItems[index].Position != -1 && answer_list.indexOf(data.TextAnswerItems[index].TextFragment) != -1) {  //store elements
                    solution_list.push(answer_list.indexOf(data.TextAnswerItems[index].TextFragment))
                    startingIndexes.push(data.TextAnswerItems[index].Position);
                } else if (data.TextAnswerItems[index].Position != -1) {                        //in case of duplication we only store that in the solution
                    answer_list.push(data.TextAnswerItems[index].TextFragment);
                    solution_list.push(answer_list.indexOf(data.TextAnswerItems[index].TextFragment));
                    startingIndexes.push(data.TextAnswerItems[index].Position);
                } else {
                    oddOneOutElements.push(data.TextAnswerItems[index].TextFragment);           //storing odd one out elements too (those have position -1)
                }
            }

            for (let index = startingIndexes.length - 1; index >= 0; index--) {                //replacing the elements to incremental numbers at the text (here decremental, because we substitue a long word with e.g. #0# so we have to start from the end because indexes would be misleading)
                text = text.replace(text.substring(startingIndexes[index], startingIndexes[index] + answer_list[solution_list[index]].length), '#' + index + '#');
            }

            for (let index = 0; index < oddOneOutElements.length; index++) {                        //adding to answerlist the odd ones
                answer_list.push(oddOneOutElements[index]);
            }

            var shuff_solution_list: any[] = [];                                                    //shuffling
            var shuffleRes: ShuffleResult = shuffle(answer_list, null);
            var tempShuffle = shuffleRes.answers.slice();

            for (var _i = 0; _i < solution_list.length; _i++) {                                     //creating shuffled solution list too
                var index = tempShuffle.indexOf(answer_list[solution_list[_i]])
                shuff_solution_list.push(index);
                tempShuffle[index] = "adsad";
            }

            let newExercise = {
                title: data.Title,
                description: data.QuestionText,
                backgroundStyle: { is_custom_background: false, backgroundImage: "", backgroundColor: "" },
                illustration: (ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image != "" && ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image != undefined) ?
                    ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image : null,
                level: data.QuestionDifficulty == 1 ? 1 : data.QuestionDifficulty == 2 ? 3 : 5,
                demo_path: "",
                imageanswers: isImage,
                imagebasepath: "", //await EditorServer.getExerciseNewImageBasePath()
                comment: data.Description,
                options: shuffleRes.answers,
                solution: shuff_solution_list,
                answers: shuffleRes.answers,
                gap_text: text
            };
            return newExercise;
        }
        return null;
    }

    public validate(editorAnswer: FillGapAnswerElement, baseData: any, validationMap?:Map<string,string>, is_accessible?:boolean|null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData,validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        let errorMsg="";
        let OFIErrors: string[] = [];
        if (editorAnswer.gap_text.length > EKEFillGapAnswerConverter.FILLGAP_GAP_TEXT_LENGTH){
            errorMsg = __("A szöveg hossza maximum {max} karakter lehet.", {max: EKEFillGapAnswerConverter.FILLGAP_GAP_TEXT_LENGTH});
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
        }

        let optionsArray = editorAnswer.gap_text.match(/\#(.*?)\#/g);
        if (optionsArray) {
            const unique = (v:string, i:number, self:string[]) => {
                return self.indexOf(v) === i;
            }
            const uniqueOptions = optionsArray.filter(unique);
            if(uniqueOptions.length + (editorAnswer.answers? editorAnswer.answers.length:0) < EKEFillGapAnswerConverter.MIN_UNIQUE_OPTIONS_NUMBER)  {
                errorMsg = __("Minimum {min} egyedi elem megadása kötelező!", {min: EKEFillGapAnswerConverter.MIN_UNIQUE_OPTIONS_NUMBER});
                if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                return { valid: false, message: errorMsg }
            }
            if (optionsArray.length > EKEFillGapAnswerConverter.MAX_OPTIONS_NUMBER) {
                errorMsg = __("Maximum {max} elem megengedett.", {max: EKEFillGapAnswerConverter.MAX_OPTIONS_NUMBER});
                if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
            }
            if (editorAnswer.answers) {
                if (optionsArray.length < editorAnswer.answers.length) {
                    errorMsg = __("Nem lehet több kakukktojás elem, mint normál válasz.");
                    if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                    return { valid: false, message: errorMsg }
                }
                if (editorAnswer.answers.length > EKEFillGapAnswerConverter.MAX_ODDS_NUMBER) {
                    errorMsg = __("A kakukktojás elemek száma maximum {n} lehet.", {n: EKEFillGapAnswerConverter.MAX_ODDS_NUMBER});
                    if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                    if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
                }
                /*for (let i = 0; i < editorAnswer.answers.length; i++) {
                    if (editorAnswer.gap_text.indexOf("#" + editorAnswer.answers[i] + "#") != -1) return { valid: false, message: __("Olyan kakukktojás elem van, ami egyébként megoldás is!") };
                }*/
            }
        }
        if (AExerciseTypeConverter.isOfiEditor) return { valid: true, message: OFIErrors.join(' , ')}
        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: FillGapAnswerElement | null, newState: FillGapAnswerElement): ValidationResponse {
        if (!AExerciseTypeConverter.isOfiEditor && newState.answers && newState.answers.length > EKEFillGapAnswerConverter.MAX_ODDS_NUMBER) {
            newState.answers.splice(EKEFillGapAnswerConverter.MAX_ODDS_NUMBER, newState.answers.length - EKEFillGapAnswerConverter.MAX_ODDS_NUMBER);
            return { valid: false, message: __("A kakukktojás elemek száma maximum {n} lehet.", {n: EKEFillGapAnswerConverter.MAX_ODDS_NUMBER}) }
        }
        return { valid: true }
    }

    render() {
        let curr_ex: FillGapAnswerElement = this.state.exercise as FillGapAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                <div className="large-12 columns">
                    <div className="row">
                        <label ><input type="checkbox" name="infinite_elements" checked={curr_ex.infinite_elements || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            { __("Végtelen számú behelyettesítés") }</label>
                    </div>
                </div>
                <div className="large-12 columns">
                    <label>
                        { __("A kiegészítendő szöveg") }
                        <textarea name={"gap_text"} rows={12} value={curr_ex.gap_text}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)}>
                        </textarea>
                        <label>
                            { __("A hiányzó szöveget # # szimbólumok között kell megadni pl: \"A #víz# folyékony halmazállapotú, míg a #jég# szilárd. Továbbá a #víz# forráspontja 100°C. \"") }
                        </label>
                    </label>
                </div>
                <Panel>
                    <legend>
                        <h3>{__("Kakukktojás elemek")}</h3>
                    </legend>
                    <div>
                        {
                            curr_ex.answers ?
                                curr_ex.answers.map((curr_odd_one_out: any, i: any) => {
                                    return (
                                        <div key={"Elem" + i} className="row">
                                            <div className="columns small-6 large-2">
                                                <label className="exe-editor-fieldlabel-1">{ __("Szó") } {i + 1}</label>
                                            </div>
                                            <div className="columns small-6 large-8">
                                                <input type="text" name={"answers#" + i} data-index={i} value={curr_odd_one_out}
                                                    onBlur={this.onBlurEvent.bind(this)}
                                                    onChange={this.handleInputChange.bind(this)} />
                                            </div>
                                            <div className="columns small-12 large-2">
                                                <button className="button small alert exercise-series-small-btn" title={ __("Törlés") } onClick={this.removeElement.bind(this, "answers", i)}><i className="fa fa-trash"></i></button>
                                            </div>
                                        </div>
                                    );
                                })
                                : ""
                        }
                        <div className="row">
                            <button className="button small" onClick={this.onAddNewOddOneOut.bind(this)}><i className="fa fa-plus"></i>{ __("Új kakukktojás") }</button>
                            <label className="exe-editor-label-description columns">{ __("A kakukktojás elemek száma maximum") } {EKEFillGapAnswerConverter.MAX_ODDS_NUMBER} { __("lehet.") }</label>
                        </div>
                    </div>
                </Panel>
            </Panel>
        )
    }

    onAddNewOddOneOut() {
        let temp_exercise = this.state.exercise as FillGapAnswerElement;
        if (!temp_exercise.answers)
            temp_exercise.answers = [];
        temp_exercise.answers.push("");
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        statisticsResponses.push({ name: __("limit - szöveg hossza: {n}", {n: EKEFillGapAnswerConverter.FILLGAP_GAP_TEXT_LENGTH}), count: undefined });
        statisticsResponses.push({ name: __("limit - behúzandó elemek száma: {n}", {n: EKEFillGapAnswerConverter.MAX_OPTIONS_NUMBER}), count: undefined });
        statisticsResponses.push({ name: __("limit - kakukktojás elemek: {n}", {n: EKEFillGapAnswerConverter.MAX_ODDS_NUMBER}), count: undefined });

        let answersStat, oddStat, gapTextStat: StatisticsResponse;
        answersStat = { name: __("Elemek száma"), count: new Map() }
        oddStat = { name: __("Kakukktojás elemek száma"), count: new Map() }
        gapTextStat = { name: __("Szöveg hossz"), count: new Map() }
        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            let baseData: ExerciseBaseTypes.ExerciseBaseClass = ExerciseBaseTypes.convertToBaseClass(exerciseList[i])!;
            const currentExc = this.convertToJson(exerciseList[i], baseData);
            if (!currentExc) continue;
            let answer_count = answersStat.count.has(currentExc.answers.length) ? answersStat.count.get(currentExc.answers.length) : 0;
            answersStat.count.set(currentExc.answers.length, answer_count! + 1);
            if (currentExc.answers.length - currentExc.solution.length < 0) continue;
            let odd_count = oddStat.count.has(currentExc.answers.length - currentExc.solution.length) ? answersStat.count.get(currentExc.answers.length - currentExc.solution.length) : 0;
            oddStat.count.set(currentExc.answers.length - currentExc.solution.length, odd_count);
            let nearHundred = Math.ceil(currentExc.gap_text.length / 100) * 100;
            let gap_text_count = gapTextStat.count!.has(nearHundred) ? gapTextStat.count!.get(nearHundred) : 0;
            gapTextStat.count!.set(nearHundred, gap_text_count! + 1);
        }
        statisticsResponses.push(answersStat, oddStat, gapTextStat);
        return statisticsResponses;
    }
}
