import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewWfTransitionLogRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Folyamat
     */
    workflow_id?: number;
    /**
     * Átmenet ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre az átmenet létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki végezte el
     *
     * Trigger állítja be automatikusan az átmenet létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan az átmenet létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Folyamat átmenet típus
     *
     * A kezdő állapot létrehozásánál nincs töltve (de napló rekord arról is van).
     */
    transition_type_id?: number|null;
    /**
     * Start állomás
     *
     * A legelső napló rekordnál nincs töltve.
     */
    src_station_id?: number|null;
    /**
     * Cél állomás
     */
    dst_station_id?: number|null;
    /**
     * Automatikus
     *
     * Akkor van igazra állítva, ha az átmenet automatikus volt (nem közvetlenül felhasználó kezdeményezte).
     */
    is_auto?: boolean;
    /**
     * Indoklás
     */
    justification?: string|null;
    /**
     * Cél al-státusz
     */
    dst_substation_id?: number|null;
    /**
     * Állapot megjegyzés
     */
    status_text?: string|null;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Állapot
     *
     * Cél állapot neve
     */
    station_name?: string|null;
    /**
     * Állapot leírás
     *
     * Cél állapot leírása
     */
    station_description?: string|null;
    /**
     * Stílus
     *
     * Cél állapot stílusa (CSS)
     */
    station_style?: any|null;
    /**
     * Al-státusz neve
     *
     * Al-státusz neve
     */
    substation_name?: string|null;
}

/**
 *  wf.view_wf_transition_log - Folyamat
 */

export default class ViewWfTransitionLog extends View<IViewWfTransitionLogRecord> {
    public static VIEW_INFO_ID = 2018050801;

    public static getSchemaNameForClass(): string { return 'wf'; }
    public static getViewNameForClass(): string { return 'view_wf_transition_log'; }
    public static getTableNameForClass(): string|null { return 'transition_log'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewWfTransitionLog>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewWfTransitionLogRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewWfTransitionLogClassProxy extends ViewClassProxy<IViewWfTransitionLogRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewWfTransitionLogClassProxy = new ViewWfTransitionLogClassProxy(ViewWfTransitionLog);
registerViewClassProxy(viewWfTransitionLogClassProxy);

