import * as React from 'react';

import { IViewOauthClientRedirectUriRecord, ViewOauthClientRedirectUriClassProxy, viewOauthClientRedirectUriClassProxy } from '@src/framework/view/sys/ViewOauthClientRedirectUri';
import ListView from '@framework/forms/listview';
import { TableProps, Column } from 'react-table';
import { __ } from '@src/translation';


class OauthClientRedirectUriInner extends ListView<IViewOauthClientRedirectUriRecord> {

    public getViewClassProxy() : ViewOauthClientRedirectUriClassProxy { return viewOauthClientRedirectUriClassProxy; }

    protected getOrderBy() : string|undefined {
        return 'uri';
    }

    protected getExtraTableProps(): Partial<TableProps> {
        let oldProps = super.getExtraTableProps();
        let inheritedGetTdProps : any = oldProps["getTdProps"];
        const getTdProps = (finalState: any, rowInfo: any, column?: Column, instance?: any) => {
            let props : Partial<TableProps> = {};
            if (inheritedGetTdProps!==undefined) {
                props = inheritedGetTdProps(finalState, rowInfo, column, instance);
            }
            if (column && rowInfo && rowInfo.row) {
                let row : any = rowInfo.row._original;
                if (row) {
                    if (column["id"]=="name") {
                        props["style"] = row.style;
                    }
                }                    
            }
            return props;
        }
        return { ...oldProps, getTdProps }
    }

    protected getQuickFilterFieldNames(): string[] {
        return ['uri'];
    }

}

export default function OauthClientRedirectUriListView(props: any) {
    return <OauthClientRedirectUriInner 
        defaultPageSize={10}
        match={props.match}
        viewColumns={[
            {accessor: "uri", maxWidth: 400},
            {accessor: "creation_time"},
            {accessor: "creator"},
            {accessor: "modification_time"},
            {accessor: "modifier"},
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
