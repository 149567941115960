import * as React from 'react';
import './LessonPlan.css';
import PermissionPage from '@src/framework/PermissionPage';
import { BubbleLoader } from 'react-css-loaders';
import { Accordion, AccordionItem } from '@src/component/ui/Accordion';
import { app } from '@src/index';
import { match } from 'react-router';
import SubjectCrud, { ISubjectRecord } from '@src/framework/crud/doc/SubjectCrud';
import GradeCrud, { IGradeRecord } from '@src/framework/crud/doc/GradeCrud';
import { getFileThumbnailComponent } from '@src/component/filemanager/MediaFileChooser';
import DpmkMethodologyPackageCrud from '@src/framework/crud/doc/DpmkMethodologyPackageCrud';

import OoFolderCrud, { IOoFolderRecord } from '@src/framework/crud/media/OoFolderCrud';
import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import LessonplanBlockCrud, { ILessonplanBlockRecord } from '@src/framework/crud/doc/LessonplanBlockCrud';
import ViewLessonplan, { IViewLessonplanRecord } from '@src/framework/view/doc/ViewLessonplan';
import LessonplanSubjectCrud from '@src/framework/crud/doc/LessonplanSubjectCrud';
import LessonplanGradeCrud from '@src/framework/crud/doc/LessonplanGradeCrud';

import OoFolderCrudPub from '@src/framework/crud/media_pub/OoFolderCrud';
import OoFileCrudPub from '@src/framework/crud/media_pub/OoFileCrud';
import LessonplanBlockCrudPub from '@src/framework/crud/doc_pub/LessonplanBlockCrud';
import ViewLessonplanPub from '@src/framework/view/doc_pub/ViewLessonplan';
import LessonplanSubjectCrudPub from '@src/framework/crud/doc_pub/LessonplanSubjectCrud';
import LessonplanGradeCrudPub from '@src/framework/crud/doc_pub/LessonplanGradeCrud';
import { __ } from '@src/translation';

import { Link } from 'react-router-dom';
import { PATH_LESSONPLAN_PUB_VIEW, PATH_LESSONPLAN_EDIT_LIST, PATH_LESSONPLAN_PUB_EDIT_LIST } from './lessonplan_routes';

export class LessonPlanViewPage extends React.Component<LessonPlanViewProps, LessonPlanViewState> {

    render() {
        return (<PermissionPage requirements={{
            /*crud: {tableInfoId: LessonplanCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U }*/
        }}
        ><LessonPlanView {...this.props} /></PermissionPage>
        );
    }

}

declare var MathJax: any;
type LessonPlanBlockProps = {
    lessonPlanBlock: ILessonplanBlockRecord;
    folderId?: number;
    published: boolean;
}
class LessonPlanBlock extends React.Component<LessonPlanBlockProps, any> {
    render() {
        return <div className="row">
            <div className="column small-12">
                <h5>
                    {__("Feladat leírása")}:
                </h5>
            </div>
            <div className="column small-12">
                <div dangerouslySetInnerHTML={{ __html: this.props.lessonPlanBlock.description || "" }} />
            </div>
            <div className="column small-12">
                <h5>
                    {__("Módszerek/Munkaformák")}:
                </h5>
            </div>
            <div className="column small-12">
                <div dangerouslySetInnerHTML={{ __html: this.props.lessonPlanBlock.methods || "" }} />
            </div>

            <div className="column small-12">
                <h5>
                    {__("Eszközök")}:
                </h5>
            </div>
            <div className="column small-12">
                <div dangerouslySetInnerHTML={{ __html: this.props.lessonPlanBlock.tools || "" }} />
            </div>
            <div className="column small-12">
                <h5>
                {__("Értékelés")}:
                </h5>
            </div>
            <div className="column small-12">
                <div dangerouslySetInnerHTML={{ __html: this.props.lessonPlanBlock.evaluation || "" }} />
            </div>



            {this.props.lessonPlanBlock.links && this.props.lessonPlanBlock.links.links
                ?
                <div className="column small-12">
                    <div >
                        <h5>
                            {__("Linkek")}:
                        </h5>
                    </div>
                    <div className="column small-12">
                        {this.props.lessonPlanBlock.links.links.map((currentlink: any) => {
                            return <div><a target="_blank" href={currentlink}>{currentlink}</a></div>

                        })}
                    </div>
                </div>
                : null
            }
            <br />

            {
                this.props.folderId
                    ?
                    <FileList published={this.props.published} folderId={this.props.folderId} needTitle={true} />
                    : null
            }
        </div>;
    }
}
type LessonPlanViewProps = {
    match: match<{ id?: string }>;
}
type LessonPlanViewState = {
    lessonPlan: IViewLessonplanRecord;
    lessonPlanBlocks: ILessonplanBlockRecord[];
    subjectsInLessonPlan: ISubjectRecord[];
    gradesInLessonPlan: IGradeRecord[];
    folders: IOoFolderRecord[];
    methodology?: string;
    loading: boolean;
}
export class LessonPlanView extends React.Component<LessonPlanViewProps, LessonPlanViewState> {

    constructor(props: LessonPlanViewProps) {
        super(props);

        this.state = {
            lessonPlan: {},
            lessonPlanBlocks: [],
            folders: [],
            subjectsInLessonPlan: [],
            gradesInLessonPlan: [],
            loading: true,
        }
    }

    componentDidMount() {
        this.reloadAsync(Number(this.props.match.params.id));
    }

    componentDidUpdate(prevProps: LessonPlanViewProps, prevState: LessonPlanViewState) {
        if (this.props.match.params.id && this.props.match.params.id !== prevProps.match.params.id) {
            this.reloadAsync(Number(this.props.match.params.id));
        }
        try {
            //Matematikai képletek kiértékelése a találatokra
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, this.refs.root_div]);
        } catch (e) {
            console.log(e);
        }
    }

    private async reloadAsync(id: number) {
        try {
            this.setState({
                loading: true
            });

            var lessonPlan: IViewLessonplanRecord;
            var lessonPlanBlocks: ILessonplanBlockRecord[] = [];
            var folders: IOoFolderRecord[] = [];
            var lessonPlanSubjectsIds: (number | null | undefined)[];
            var lessonPlanGradeIds: (number | null | undefined)[];

            const published = this.isPublished();

            if (published) {
                lessonPlan = (await ViewLessonplanPub.load(id)).record;
                //lessonpub-ba is bele kell rakni az order by-t
                lessonPlanBlocks = (await LessonplanBlockCrudPub.list({ filter: {  lessonplan_id: id }, order_by: "no" }));
                folders = await OoFolderCrudPub.list({ filter: {  parent_id: lessonPlan.oo_folder_id } });
                lessonPlanSubjectsIds = (await LessonplanSubjectCrudPub.list({ filter: {  lessonplan_id: id } })).map(s => s.subject_id);
                lessonPlanGradeIds = (await LessonplanGradeCrudPub.list({ filter: {  lessonplan_id: id } })).map(s => s.grade_id);
            } else {
                lessonPlan = (await ViewLessonplan.load(id)).record;
                lessonPlanBlocks = (await LessonplanBlockCrud.list({ filter: { is_active: true, lessonplan_id: id }, order_by: "no" }));
                folders = await OoFolderCrud.list({ filter: { is_active: true, parent_id: lessonPlan.oo_folder_id } });
                lessonPlanSubjectsIds = (await LessonplanSubjectCrud.list({ filter: { is_active: true, lessonplan_id: id } })).map(s => s.subject_id);
                lessonPlanGradeIds = (await LessonplanGradeCrud.list({ filter: { is_active: true, lessonplan_id: id } })).map(s => s.grade_id);
            }
            
            var methodology: string | undefined = "";
            if (lessonPlan.dpmk_methodology_package_id) {
                const m = (await DpmkMethodologyPackageCrud.load(lessonPlan.dpmk_methodology_package_id)).record;
                methodology = m.title;
            }
            const subjectsInLessonPlan = (await SubjectCrud.list({ filter: { is_active: true } })).filter(s => lessonPlanSubjectsIds.includes(s.id));

            const gradesInLessonPlan = (await GradeCrud.list({ filter: { is_active: true } })).filter(s => lessonPlanGradeIds.includes(s.id));

            this.setState({
                subjectsInLessonPlan,
                gradesInLessonPlan,
                lessonPlan,
                lessonPlanBlocks,
                folders,
                methodology,
                loading: false
            });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private isPublished() {
        return this.props.match.path.startsWith(PATH_LESSONPLAN_PUB_VIEW);
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        }
        const timeMinutes = this.state.lessonPlanBlocks.map(l => l.require_time_min).reduce((a, b) => (a || 0) + (b || 0), 0);

        return <div ref="root_div">
         <br/>        
            <div className="row">   
            {this.isPublished()?
                                <Link className="button" title={__("Vissza az óravázlattárhoz")} to={PATH_LESSONPLAN_PUB_EDIT_LIST}>
                                    <i className="fa fa-arrow-left"></i>&nbsp; {__("Vissza az óravázlattárhoz")}
                                </Link>
                                :
                                <Link className="button" title={__("Vissza az óravázlat listához")} to={PATH_LESSONPLAN_EDIT_LIST}>
                                    <i className="fa fa-arrow-left"></i>&nbsp; {__("Vissza az óravázlat listához")}
                                </Link>
            }
                <Accordion>
                    <AccordionItem title="Adatlap">
                        <div>
                            <div className="row">
                                <div className="column small-4">
                                    <h6>
                                       {__("Projektazonosító")}:
                                    </h6>
                                </div>
                                <div className="column small-8">
                                    {this.state.lessonPlan.project_title}
                                </div>
                            </div>
                            <div className="row">
                                <div className=" column small-4">
                                    <h6>
                                       {__("Intézmény")}:
                                    </h6>
                                </div>
                                <div className="column small-8">
                                    {this.state.lessonPlan.institute_name}
                                </div>
                            </div>

                            <div className="row">
                                <div className=" column small-4">
                                    <h6>
                                        {__("Iskolatípus")}:
                                    </h6>
                                </div>
                                <div className="column small-8">
                                    {this.state.lessonPlan.institute_type_title}
                                </div>
                            </div>

                            <div className="row">
                                <div className=" column small-4">
                                    <h6>
                                        {__("OM azonosító")}:
                                </h6>
                                </div>
                                <div className="column small-8">
                                    {this.state.lessonPlan.institute_om_identifier}
                                </div>
                            </div>

                            <div className="row">
                                <div className=" column small-4">
                                    <h6>
                                       {__("Feladatellátási hely")}:
                                    </h6>
                                </div>
                                <div className="column small-8">
                                    {this.state.lessonPlan.institute_site_name}
                                </div>
                            </div>

                            <div className="row">
                                <div className=" column small-4">
                                    <h6>
                                        {__("Pedagógus")}:
                                    </h6>
                                </div>
                                <div className="column small-8">
                                    {this.state.lessonPlan.owner_name}
                                </div>
                            </div>

                            <div className="row">
                                <div className=" column small-4">
                                    <h6>
                                     {__("Műveltségterület")}:
                                    </h6>
                                </div>
                                <div className="column small-8">
                                    {this.state.lessonPlan.literacy_field_name}
                                </div>
                            </div>
                            <br/>
                            <div className="row expanded" style={{backgroundColor:"#ccffcc",marginLeft:"-0.9375rem",marginRight:"-0.9375rem",borderStyle:"ridge"}}>
                            <div className="row column small-4" style={{marginTop:"0.5rem"}}>
                                <div className="column small-4 ">
                                    <h5>
                                        {__("Évfolyam")}{this.state.gradesInLessonPlan.length > 1 ? "ok" : ""}:
                                </h5>
                                </div>
                                <div className="column small-8">
                                    <ul>
                                        {
                                            this.state.gradesInLessonPlan.map((grade, index) => {
                                                return <li key={index}><span>{grade.name}</span></li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="column small-4 row" style={{marginTop:"0.5rem"}}>
                                <div className="column small-4">
                                    <h5>
                                        {__("Tantárgy")}{this.state.subjectsInLessonPlan.length > 1 ? "ak" : ""}:
                                     </h5>
                                </div>
                                <div className="column small-8">
                                    <ul>
                                        {
                                            this.state.subjectsInLessonPlan.map((subject, index) => {
                                                return <li key={index}><label>{subject.name}</label></li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                     
                            <div className="column small-12 row">
                                <div className=" column small-6">
                                    <h5>
                                        {__("Téma, témakör")}:
                                </h5>
                                </div>
                                <div className="column small-12">
                                    <div style={{overflow:"auto"}} dangerouslySetInnerHTML={{ __html: this.state.lessonPlan.topic || "" }} />
                                </div>
                            </div>

                            <div className="column small-12 row">
                                <div className="column small-12">
                                    <h5>
                                        {__("Kereszttantervi kapcsolódás")}:
                                </h5>
                                </div>
                                <div className="column small-12">
                                    <div style={{overflow:"auto"}} dangerouslySetInnerHTML={{ __html: this.state.lessonPlan.cross_curriculum_link || "" }} />
                                </div>
                            </div>
                            </div>
                            <div className="row">
                                <div className=" column small-12">
                                    <h5>
                                        {__("A tanóra célja")}:
                                    </h5>
                                </div>
                                <div className="column small-12">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.lessonPlan.goal || "" }} />
                                </div>
                            </div>


                            <div className="row">
                                <div className=" column small-12">
                                    <h5>
                                        {__("Fejlesztendő készség, képesség, kompetencia")}:
                                    </h5>
                                </div>
                                <div className="column small-12">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.lessonPlan.facility_to_develop || "" }} />
                                </div>
                            </div>

                            <div className="row">
                                <div className=" column small-12">
                                    <h5>
                                        {__("A pályázatban választott digitális pedagógiai módszertani csomag")}:
                                </h5>
                                </div>
                                <div className="column small-12">
                                    <p>
                                        {this.state.lessonPlan.dpmk_methodology_package_text}
                                        {this.state.methodology}
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className=" column small-12">
                                    <h5>
                                        {__("Keretek (tanulás szervezési feltételek)")}:
                                </h5>
                                </div>
                                <div className="column small-12">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.lessonPlan.frame_study_conditions || "" }} />
                                </div>
                            </div>

                            <div className="row">
                                <div className=" column small-12">
                                    <h5>
                                        {__("Keretek (közreműködő szakemberek)")}:
                                    </h5>
                                </div>
                                <div className="column small-12">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.lessonPlan.frame_contributor_experts || "" }} />
                                </div>
                            </div>

                            <div className="row">
                                <div className=" column small-12">
                                    <h5 >
                                        {__("Elsajátítandó fogalmak jegyzéke")}:
                                </h5>
                                </div>
                                <div className="column small-12">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.lessonPlan.concepts_to_pick || "" }} />
                                </div>
                            </div>
                        </div>
                    </AccordionItem>
                    
                    <AccordionItem title={__("Az óra menete {timeDisplay}", {timeDisplay: timeMinutes ? __("(Összes idő eddig: {time} perc)", {time:timeMinutes}) : ""})}>
                        <div className="row">
                            <div className="column small-12">
                                <Accordion>
                                    {
                                        this.state.lessonPlanBlocks.map((lpb, index) => {
                                            const title = <div style={{ display: "flex", marginRight: "2em", alignItems: "center" }}>
                                                <span>{__("#{n}. Feladat", {n: (index + 1)})}</span>

                                                <span style={{ flex: 1, margin: "0 1em", display: "flex", alignItems: "center" }}>
                                                    {__("Időkeret")}: {lpb.require_time_min + " "}

                                                    {__("perc")}
                                                    </span>
                                            </div>;


                                            const folder = this.state.folders.find(f => f.title === "" + lpb.id);

                                            return <AccordionItem key={index} title={title}>
                                                <LessonPlanBlock
                                                    lessonPlanBlock={lpb}
                                                    folderId={folder ? folder.id : undefined}
                                                    published={this.isPublished()}
                                                />
                                            </AccordionItem>
                                        })
                                    }
                                </Accordion>

                            </div>
                        </div>
                    </AccordionItem>

                    <AccordionItem title={__("Tapasztalatok")}>
                        <div className="row">
                            <div className="column small-12">
                                <h5>
                                    {__("A megvalósítás tapasztalatai")}:
                                </h5>
                            </div>
                            <div className="column small-12">
                                <div dangerouslySetInnerHTML={{ __html: this.state.lessonPlan.conclusions || "" }} />
                            </div>

                            <div className="column small-12">
                                <h5>
                                    {__("Javaslatok")}:
                                </h5>
                            </div>
                            <div className="column small-12">
                                <div dangerouslySetInnerHTML={{ __html: this.state.lessonPlan.suggestions || "" }} />
                            </div>
                        </div>
                    </AccordionItem>

                    <AccordionItem title={__("Mellékletek")}>
                        <div className="row">

                            {
                                this.state.lessonPlan.oo_folder_id ?
                                    <FileList folderId={this.state.lessonPlan.oo_folder_id} needTitle={false} published={this.isPublished()} />
                                    : null
                            }

                        </div>
                        <br />
                    </AccordionItem>
                    <br />
                </Accordion>
            </div>
        </div>
            ;
    }
}
class FileList extends React.Component<{ published: boolean, folderId: number, needTitle: boolean }, { files: IOoFileRecord[] }> {

    constructor(props: any) {
        super(props);

        this.state = {
            files: [],
        }
    }

    async componentDidMount() {
        var files: IOoFileRecord[] = [];
        
        if (this.props.published) {
            files = await OoFileCrudPub.list({
               filter: {
                   oo_folder_id: this.props.folderId
               }
           });
        } else {
            files = await OoFileCrud.list({
               filter: {
                   is_active: true,
                   oo_folder_id: this.props.folderId
               }
           });
        }
        this.setState({
            files
        })
    }

    render() {
        return <div>
            {this.props.needTitle && this.state.files && this.state.files.length > 0
                ? <div className="column small-12">
                    <h5>
                       {__("Mellékletek:")} 
                    </h5>
                </div>
                : null
            }
            <div className="small-12 column">

                <div className="lp-wrapper">

                    {
                        this.state.files.map((f, index) => {
                            return <span title={f.title} key={index} className={"extension-unit-wrapper"}>
                                <a data-lightbox={f.has_thumb ? true : undefined} download={f.title} href={"/api/media/file/" + f.sha1}>
                                    {getFileThumbnailComponent(f.sha1!, f.has_thumb!, f.ext!, true, false, undefined, false)}
                                </a>

                                <span className="image-selector__name">{f.title}</span></span>
                        })
                    }
                </div>
            </div>
        </div>
            ;
    }
}
