import * as React from "react";
import { BubbleLoader } from 'react-css-loaders';
import { IPersonRecord, personCrudClassProxy } from "@src/framework/crud/sys/PersonCrud";
import { IViewKapTrainingPermitRecord, viewKapTrainingPermitClassProxy } from "@src/framework/view/kap/ViewKapTrainingPermit";
import MediaFileChooser, { ContentActionType } from "@src/component/filemanager/MediaFileChooser";
import { __ } from "@src/translation";
import { app } from '@src/index';
import TrainingPermitCrud from "@src/framework/crud/kap/TrainingPermitCrud";
import { WorkflowDialog } from "@src/component/wf/WorkflowDialog";
import { formatDateWithoutTime } from "@src/Util";

interface IProfilePageComponentState {
    loading: boolean;
    person?: IPersonRecord;
    currentTrainingPermit?: IViewKapTrainingPermitRecord;
    workflowDialogOpen: boolean;
}


export default class TrainingPermissionEdit extends React.Component<any, IProfilePageComponentState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            person: undefined,
            currentTrainingPermit: undefined,
            workflowDialogOpen: false
        };
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        this.setState({ loading: true, person: undefined, currentTrainingPermit: undefined });
        let recId = this.getMatchParam("recId");
        try {
            const currentTrainingPermit = (await viewKapTrainingPermitClassProxy.load(recId));
            const person = (await personCrudClassProxy.load(currentTrainingPermit.person_id!));

            this.setState({
                loading: false, currentTrainingPermit, person
            });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        };
    }

    protected getMatchParam(name: string): any {
        const match: any = this.props.match;
        return (match && match.params) ? match.params[name] : null;
    }


    render() {
        const person = this.state.person;

        if (!person || !this.state.currentTrainingPermit) return <BubbleLoader />;

        return <div>

            <WorkflowDialog
                open={this.state.workflowDialogOpen}
                displayName="Feladat"
                tableInfoId={TrainingPermitCrud.TABLE_INFO_ID}
                recId={this.state.currentTrainingPermit!.id!}
                onClose={() => this.setState({ workflowDialogOpen: false })}
                onTransition={this.asyncReload.bind(this)}
                autoPublishOnClosedStation={true}
            />
            <div className="row" ref="training_data_card">
                <div className="column large-12 medium-12 small-12">
                    <div className="card">
                        <div className="card-divider">
                            <p><i className="fa fa-graduation-cap" /> {__("Kurzusra való jelentkezéshez szükséges adatok")}</p>
                        </div>
                        <div className="card-section">
                            <div className="large-12 small-12 columns">
                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Teljesnév")}
                                            <label className="profile_disabled_input">{person.fullname || ""}</label>
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Személyi igazolvány száma")}
                                            <label className="profile_disabled_input">{person.id_number || ""}</label>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Társadalombiztosítási azonosító jele (TAJ)")}
                                            <label className="profile_disabled_input">{person.taj || ""}</label>
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Adóazonosító jele")}
                                            <label className="profile_disabled_input">{person.taxid || ""}</label>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Anyja neve")}
                                            <label className="profile_disabled_input">{person.mothers_name || ""}</label>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Születési adatok")}</label>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Születési név")}
                                            <label className="profile_disabled_input">{person.birthname || ""}</label>
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Születési dátum")}
                                            <label className="profile_disabled_input">{person.birthday ? formatDateWithoutTime(person.birthday) : ""}</label>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Születési ország")}
                                            <label className="profile_disabled_input">{person.birth_country || ""}</label>
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Születési település")}
                                            <label className="profile_disabled_input">{person.birth_city || ""}</label>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Állampolgárság")}
                                            <label className="profile_disabled_input">{person.citizenship || ""}</label>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Állandó lakcím")}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-4 medium-6 small-12 columns">
                                        <label>{__("Ország")}
                                            <label className="profile_disabled_input">{person.residence_country || ""}</label>
                                        </label>
                                    </div>
                                    <div className="large-4 medium-6 small-12 columns">
                                        <label>{__("Megye")}
                                            <label className="profile_disabled_input">{person.residence_county || ""}</label>
                                        </label>
                                    </div>
                                    <div className="large-4 medium-6 small-12 columns">
                                        <label>{__("Település")}
                                            <label className="profile_disabled_input">{person.residence_city || ""}</label>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-2 medium-6 small-12 columns">
                                        <label>{__("Irányítószám")}
                                            <label className="profile_disabled_input">{person.residence_zip || ""}</label>
                                        </label>
                                    </div>
                                    <div className="large-10 columns">
                                        <label>{__("Utca, házszám, emelet, ajtó")}
                                            <label className="profile_disabled_input">{person.residence_address || ""}</label>
                                        </label>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Levelezési cím")}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-5 medium-6 small-12 columns">
                                        <label>{__("Ország")}
                                            <label className="profile_disabled_input">{person.postal_country || ""}</label>
                                        </label>
                                    </div>
                                    <div className="large-5 medium-6 small-12 columns">
                                        <label>{__("Település")}
                                            <label className="profile_disabled_input">{person.postal_city || ""}</label>
                                        </label>
                                    </div>
                                    <div className="large-2 medium-6 small-12 columns">
                                        <label>{__("Irányítószám")}
                                            <label className="profile_disabled_input">{person.postal_zip || ""}</label>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-12 columns">
                                        <label>{__("Utca, házszám, emelet, ajtó")}
                                            <label className="profile_disabled_input">{person.postal_address || ""}</label>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Elérhetőségek")}</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-6 columns">
                                        <label>{__("Vezetékes telefonszám")}
                                            <label className="profile_disabled_input">{person.landline_country_code} {person.landline_number}</label>
                                        </label>
                                    </div>

                                    <div className="large-6 columns">
                                        <label>{__("Mobilszám")}
                                            <label className="profile_disabled_input">{person.mobile_country_code} {person.mobile_number}</label>
                                        </label>
                                    </div>

                                    <div className="large-6 columns">
                                        <label>{__("Munkahelyi telefonszám")}
                                            <label className="profile_disabled_input">{person.workplace_tel}</label>
                                        </label>
                                    </div>
                                </div>





                                <div className="row">
                                    <div className="large-12 columns">
                                        <label className="profile_form_separator">{__("Képzettségi adatok")}</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Legmagasabb képesítés")}
                                            <label className="profile_disabled_input">{person.diploma_qualification}</label>
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Diploma száma")}
                                            <label className="profile_disabled_input">{person.diploma_serial}</label>
                                        </label>
                                    </div>
                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Kiállítás dátuma")}
                                            <label className="profile_disabled_input">{person.diploma_created ? person.diploma_created.substr(0, 10) : ""}</label>
                                        </label>
                                    </div>

                                    <div className="large-6 medium-6 small-12 columns">
                                        <label>{__("Kiállító intézmény")}
                                            <label className="profile_disabled_input">{person.diploma_inst}</label>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <MediaFileChooser
                                        rootFolderId={this.state.currentTrainingPermit!.oo_folder_id!}
                                        currentFolderId={this.state.currentTrainingPermit!.oo_folder_id!}
                                        showHoverPreview={false}
                                        showLocation={false}
                                        showFilter={false}
                                        enabledActions={[ContentActionType.FILE_DOWNLOAD]}
                                    />
                                </div>

                                <div className="row">
                                    <label className="label" style={{ alignSelf: "center", margin: "auto", marginBottom: "10px" }}>{__("Állapot")}: {this.state.currentTrainingPermit!.wf_station_name}</label>
                                </div>

                                <div className="row">
                                    <button className="button" style={{ margin: "auto" }} onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}>
                                        <i className="fa fa-project-diagram" />&nbsp; {__("Elbírálás")}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
