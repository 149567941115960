import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IComplexAspectAnswerRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Komplex értékelési szempont
     *
     * NOT NULL -> kap.complex_aspect ON DELETE noaction
     *
     */
    complex_aspect_id?: number;
    /**
     * KIP/Komplex óra látogatás
     *
     * NOT NULL -> kap.class_visit ON DELETE cascade
     *
     */
    class_visit_id?: number;
    /**
     * Komplex értékelési szempont érték
     *
     * NOT NULL -> kap.complex_aspect_value ON DELETE noaction
     *
     */
    complex_aspect_value_id?: number;
    /**
     * Megjegyzés
     */
    comments?: string|null;
}

/**
 *  kap.complex_aspect_answer - Komplex értékelési szempontra adott válasz
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ComplexAspectAnswerCrud extends Crud<IComplexAspectAnswerRecord> {
    public static TABLE_INFO_ID = 2660404729;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'complex_aspect_answer'; }

    public static load: (id: number, aServer ?: Server) => Promise<ComplexAspectAnswerCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IComplexAspectAnswerRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IComplexAspectAnswerRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IComplexAspectAnswerRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IComplexAspectAnswerRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IComplexAspectAnswerRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ComplexAspectAnswerCrudClassProxy extends CrudClassProxy<IComplexAspectAnswerRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const complexAspectAnswerCrudClassProxy = new ComplexAspectAnswerCrudClassProxy(ComplexAspectAnswerCrud);
registerCrudClassProxy(complexAspectAnswerCrudClassProxy);
