import { History } from 'history';

import Auth from '../server/Auth';
import LocationUtil from '../util/LocationUtil';
import { authConfig } from '../server/Server';

/**
 * Bejelentkeztetés magasabb szinten - azokra a site-okra ahol a használathoz mindenképp be kell jelentkezni.
 * Ha sikerül, akkor visszatér a legutolsó beállított location-t és visszatér az Auth object-tel
 * Ha nem sikerül akkor átirányít a bejelentkezésre és sosem tér vissza.
 */
export async function startLogin(history: History): Promise<Auth> {
    return Auth.loginOrRedirect(authConfig, true)
        .then((auth) => {
            LocationUtil.restore(history);
            return Promise.resolve(auth);
        })
        .catch(async (reason) => {
            return Promise.reject(reason);
        });
}

/**
 * 
 * Bejelentkezés befejezése magasabb szinten - ha a felhasználó a bejelentkezési folyamat végén van, akkor
 * visszaállítja az eredeti location-t (ahonnan a a bejelentkezést indította). Ha már be van jelentkezve,
 * akkor még ennyit se csinál, csak visszaadja a bejelentkezését reprezentáló aktuális Auth objektumot.
 * Ha nincs bejelentkezve, és nem kezdte el a bejelentkezési folyamatot, akkor null-t ad.
 * 
 */
export async function completeLogin(history: History,
    callback?: ()=> void,
    allowSessionStorage : boolean = true    
): Promise<Auth|null> {
    return Auth.create(authConfig, allowSessionStorage)
        .then((auth) => {
            if (auth) {
                LocationUtil.restore(history);
                callback?callback():null;
            }
            return Promise.resolve(auth);
        });
}
