import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { WMDPiramidData } from "@src/component/exercise/engine/WMNumberPyramidExerciseEngine/WMNumberPyramidExerciseEngine";
import * as React from "react";
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type WMNumberPyramidAnswerElement = {
    elements: any[]
}

export class WMNumberPyramidConverter extends AExerciseTypeConverter {
    public hasTextAnswer = true;
    public hasImageAnswer = false;
    public hasTextImageAnswer = false;
    static MAX_PYRAMID_ROW: number = 6;
    static MAX_PYRAMID_CELL: number = 6;
    static MAX_PYRAMID_CELL_VALUE_LENGHT: number = 15;

    public convertToEditorAnswer(exercise: WMDPiramidData): WMNumberPyramidAnswerElement | undefined {
        if (exercise) {
            var elementList = [];
            var valueList: any = [];
            if (exercise.elements) {
                for (let i = 0; i < exercise.elements.length; i++) {
                    let sol_num = 0;
                    for (let k = 0; k < exercise.elements[i].length; k++) {
                        if (exercise.elements[i][k] == "") {
                            valueList.push({ is_answer: true, answer: exercise.solution[i][sol_num] });
                            sol_num++;
                        }
                        else valueList.push({ is_answer: false, answer: exercise.elements[i][k] });

                    }
                    elementList.push(valueList);
                    valueList = [];
                }
            }
            var response: WMNumberPyramidAnswerElement = {
                elements: elementList
            };
            return response;
        }
        else return undefined;
    }

    public convertToJson(answerList: WMNumberPyramidAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: WMNumberPyramidAnswerElement): WMDPiramidData | undefined {
        if (answerList) {
            var elementList = [];
            var valueList = [];
            var rowSolutionList = [];
            var solutionList = [];

            if (answerList.elements) {
                for (let i = 0; i < answerList.elements.length; i++) {
                    for (let k = 0; k < answerList.elements[i].length; k++) {
                        if (answerList.elements[i][k].is_answer) {
                            rowSolutionList.push(answerList.elements[i][k].answer);
                            valueList.push("");
                        }
                        else valueList.push(answerList.elements[i][k].answer);
                    }
                    elementList.push(valueList);
                    solutionList.push(rowSolutionList);
                    valueList = [];
                    rowSolutionList = [];
                }
            }
            var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
            cur_exercise = {
                ...cur_exercise,
                elements: elementList,
                solution: solutionList
            };
            return cur_exercise;
        }
        else return undefined;
    }

    render() {
        let curr_ex: WMNumberPyramidAnswerElement = this.state.exercise as WMNumberPyramidAnswerElement;
        return (
            <Panel header={__("Piramis sorai")} headingLevel={4}>
                {
                    curr_ex.elements ?
                        curr_ex.elements.map((curr_line: any, index) => {

                            let actions: IAccordionAction[];
                            actions = [
                                {
                                    title: __("Törlés"),
                                    type: AccordionActionType.Trash,
                                    action: this.removeElement.bind(this, "elements", index)
                                },
                                {
                                    title: __("Fel"),
                                    type: AccordionActionType.Up,
                                    action: this.moveUp.bind(this, "elements", index)
                                },
                                {
                                    title: __("Le"),
                                    type: AccordionActionType.Down,
                                    action: this.moveDown.bind(this, "elements", index)
                                }
                            ];
                            return (
                                <Accordion key={"elements_" + index}>
                                    <ActionAccordionItem defaultClosed key={"elements_" + index} actions={actions} title={(index + 1) + __(". sor")}>
                                        {
                                            curr_line ?
                                                curr_line.map((curr_element: any, index_j: any) => {

                                                    return (
                                                        <Panel key={"elements#" + index + "#" + index_j}>
                                                            <legend><label className="exe-editor-fieldlabel-2">Elem {index_j + 1}</label>
                                                                <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "elements#" + index, index_j)}><i className="fa fa-trash"></i></button>
                                                            </legend>
                                                            <div className="row">
                                                                <div className="large-4 small-12 columns">
                                                                    <label ><input type="checkbox" name={"elements#" + index + "#" + index_j + ".is_answer"} checked={curr_element.is_answer || false}
                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                        {__("Kitöltendő")}</label>
                                                                </div>

                                                                <div className="large-8 small-12 columns">
                                                                    <label className="exe-image-select-label">{__("Érték")} <span className="exe-editor-validation-msg">{this.props.validationMessages.get("elements["+index+"]["+index_j+"].answer")}</span>
                                                                            <input type="text" name={"elements#" + index + "#" + index_j + ".answer"} data-parent-index={index} data-index={index_j} value={curr_element.answer}
                                                                            onBlur={this.onBlurEvent.bind(this)}
                                                                            onChange={this.handleInputChange.bind(this)} />
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </Panel>
                                                    );
                                                })
                                                : ""
                                        }
                                        <div className="row">
                                            <button className="button small" name={"row#" + index + ".btn-add-element"} onClick={this.onAddNewElement.bind(this, index)}><i className="fa fa-plus"></i>{__("Új elem")}</button>
                                            <label className="exe-editor-label-description columns">{__("A piramisnak maximum {max} oszlopa lehet.", {max: WMNumberPyramidConverter.MAX_PYRAMID_CELL})}</label>
                                        </div>
                                    </ActionAccordionItem>
                                </Accordion>)
                        })
                        : ""}
                <div className="row">
                    <button className="button small" onClick={this.onAddNewLine.bind(this)}><i className="fa fa-plus"></i>{__("Új sor")}</button>
                    <label className="exe-editor-label-description columns">{ __("A piramisnak maximum {max} sora lehet.", {max: WMNumberPyramidConverter.MAX_PYRAMID_ROW})}</label>
                </div>
            </Panel>
        );
    }

    public validate(editorAnswer: WMNumberPyramidAnswerElement, baseData: any, validationMap?:Map<string,string>, is_accessible?:boolean|null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData,validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        
        let curr_elements = editorAnswer.elements;
        let isQuestionElement = false;
        let errorMsg = "";
        let OFIErrors: string[] = [];
        if (curr_elements.length > WMNumberPyramidConverter.MAX_PYRAMID_CELL) {
            errorMsg = __("A piramisnak maximum {max} sora lehet.", {max: WMNumberPyramidConverter.MAX_PYRAMID_ROW});
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg}
        }
        for (let i = 0; i < curr_elements.length; i++) {
            if (curr_elements[i].length == 0) return { valid: false, message: __("A piramisnak van olyan sora, aminek nincs eleme.") };
            if (curr_elements[i].length > WMNumberPyramidConverter.MAX_PYRAMID_ROW) {
                errorMsg = __("A piramisnak maximum {max} oszlopa lehet.", {max: WMNumberPyramidConverter.MAX_PYRAMID_CELL});
                if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg}
            }
            for (let j = 0; j < curr_elements[i].length; j++) {
                if (curr_elements[i][j].answer.length > WMNumberPyramidConverter.MAX_PYRAMID_CELL_VALUE_LENGHT) {
                    errorMsg = __("A piramis elemeinek hossza max. ") + WMNumberPyramidConverter.MAX_PYRAMID_CELL_VALUE_LENGHT + " " + __("karakter lehet.");
                    if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                    validationMap!.set("elements["+i+"]["+j+"].answer", errorMsg);
                    return { valid: false, message: errorMsg };
                }
                if (curr_elements[i][j].is_answer) {
                    isQuestionElement = true;
                    if (curr_elements[i][j].answer == "") return { valid: false, message: __("A piramisnak van olyan kitöltendő eleme, aminek nincs válasz értéke." )};
                }
            }
        }
        if (!isQuestionElement) return { valid: false, message: __("A piramisnak nincs kitöltendő eleme.") };

        if (AExerciseTypeConverter.isOfiEditor){
            return { valid: true, message: OFIErrors.join(' , ') }
        }

        return { valid: true };
    }

    public makeValidBeforeSetState(oldState: WMNumberPyramidAnswerElement | null, newState: WMNumberPyramidAnswerElement): ValidationResponse {
        if (newState.elements.length > WMNumberPyramidConverter.MAX_PYRAMID_ROW) {
            newState.elements.splice(WMNumberPyramidConverter.MAX_PYRAMID_ROW, newState.elements.length - WMNumberPyramidConverter.MAX_PYRAMID_ROW);
            return { valid: false, message: __("A piramisnak maximum {max} sora lehet.", {max: WMNumberPyramidConverter.MAX_PYRAMID_ROW})};
        }

        for (let i = 0; i < newState.elements.length; i++) {
            if (newState.elements[i].length > WMNumberPyramidConverter.MAX_PYRAMID_CELL) {
                newState.elements[i].splice(WMNumberPyramidConverter.MAX_PYRAMID_CELL, newState.elements[i].length - WMNumberPyramidConverter.MAX_PYRAMID_CELL);
                return { valid: false, message: __("A piramisnak maximum {max} oszlopa lehet.", {max: WMNumberPyramidConverter.MAX_PYRAMID_CELL})};
            }
        }
        return { valid: true }
    }

    onAddNewLine() {
        let temp_exercise = this.state.exercise as WMNumberPyramidAnswerElement;
        if (!temp_exercise.elements) temp_exercise.elements = [];
        temp_exercise.elements.push([]);
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    getNewElement(): any {
        let newElement: any = {
            is_answer: false,
            answer: "",
        };
        return newElement;
    }

    onAddNewElement(i: number) {
        let temp_exercise = this.state.exercise as WMNumberPyramidAnswerElement;
        temp_exercise.elements[i].push(this.getNewElement());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        let rowStat, elementStat, elementTotalStat: StatisticsResponse;
        statisticsResponses.push({ name: __("limit - max. sor: {n}", {n: WMNumberPyramidConverter.MAX_PYRAMID_ROW}), count: undefined });
        statisticsResponses.push({ name: __("limit - max. cella egy sorban: ") + WMNumberPyramidConverter.MAX_PYRAMID_CELL, count: undefined });
        rowStat = { name: __("Sorok száma"), count: new Map() }
        elementStat = { name: __("Elemek száma"), count: new Map() }
        elementTotalStat = { name: __("Az elemek teljes száma"), count: new Map() }

        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const cur_exc = exerciseList[i];
            let row_count = rowStat.count.has(cur_exc.elements.length) ? rowStat.count.get(cur_exc.elements.length) : 0;
            rowStat.count.set(cur_exc.elements.length, row_count! + 1);

            let counter = 0;
            for (let j = 0; j < cur_exc.elements.length; j++) {
                if (!cur_exc.elements[j]) continue;
                const cur_element = cur_exc.elements[j];
                counter += cur_element.length;
                let element_count = elementStat.count.has(cur_element.length) ? elementStat.count.get(cur_element.length) : 0;
                elementStat.count.set(cur_element.length, element_count! + 1);
            }
            let total_elements_count = elementTotalStat.count!.has(counter) ? elementTotalStat.count!.get(counter) : 0;
            elementTotalStat.count!.set(counter, total_elements_count! + 1);
        }
        statisticsResponses.push(rowStat, elementStat, elementTotalStat);
        return statisticsResponses;
    }

}
