import * as React from 'react';
import { app } from '@src/index';
import ViewSharedByMe, { IViewSharedByMeRecord } from '@framework/view/usr/ViewSharedByMe';
import { IContentRowProps, ContentList } from '../filemanager/ContentList';
import { PATH_SHARED_CONTENT_BY_ME } from '@src/Routes';
import { SHARE_MEGOSZTAS_ID } from '@src/Const';

interface ISharedByMeTileState {
    loading : boolean;
    items : IViewSharedByMeRecord[];
}

export default class SharedByMeTile extends React.Component<any, ISharedByMeTileState> {

    constructor(props: any) {
        super(props);
        this.state = { items : [], loading: true };
    }
  
    async componentDidMount() {
        this.reloadAsync();
    }
    async reloadAsync() {
        this.setState({loading: true});
        try {
            const items = await ViewSharedByMe.list({filter: {is_active: true}, limit:10, order_by: [{name: "creation_time", desc: true}]});
            this.setState({items, loading: false});
        } catch (e) {            
            app.showErrorFromJsonResult(e);
        }
    }
   
    render() {
        const items : IContentRowProps[] = this.state.items.map( (item : IViewSharedByMeRecord ) => {

            var url = (item.share_mode_id == SHARE_MEGOSZTAS_ID) ? undefined : PATH_SHARED_CONTENT_BY_ME + "/" + item.id;

            return {
                table_info_id: item.table_info_id!,
                record_id: item.rec_id!,
                creation_time: item.creation_time,
                start_date: item.start_date!,
                end_date: item.end_date,
                due_date: item.due_date,
                share_mode_title: item.share_mode_title!,
                name : item.displayvalue,
                url: url
            }
        });
      
        return <div className="shared-by-me-tile">
                  <ContentList loading={this.state.loading} items={items}/>
                </div>
    }

}