import * as React from 'react';

import  { IItemTypeRecord, ItemTypeCrudClassProxy, itemTypeCrudClassProxy } from '@src/framework/crud/kb/ItemTypeCrud';
import RecordEditor from '@framework/forms/recordeditor';
import { SecItemTypePermListViewForItemType } from './kb_sec_item_type_perm_listview';

export default class ItemTypeEditor extends RecordEditor<IItemTypeRecord> {
    public getCrudClassProxy() : ItemTypeCrudClassProxy { return itemTypeCrudClassProxy;  }  


    protected getDetailPanels() : any[] {
        let result = super.getDetailPanels();        
        if (this.state.rec_id) {
            result = result.concat(
                <SecItemTypePermListViewForItemType
                    item_type_id={this.state.rec_id!}
                />
            );  
        }
        return result;
    }        
}