import * as React from 'react';
import { Route, Switch } from 'react-router';
import { PATH_INSTITUTE_LEADER_COURSE, PATH_INSTITUTE_WORKSHOP, PATH_PERSONALCOLNSULTING_AFTER, PATH_DFHT, PATH_SUBPROGRAM_OCCUPATION_PERSONAL, PATH_ATTENDANCE_EVENT_TYPES, PATH_SUBPROGRAM_OCCUPATION_GROUP, PATH_MIDYEAR_INTERVIEW, PATH_PERSONALCOLNSULTING_BEFORE, PATH_CLOSING_WORKSHOP } from '../Attendance';
import SplitPane from 'react-split-pane';
import { Link, NavLink } from 'react-router-dom';

export class AttendanceEventTypesPage extends React.Component {

    render() {
        return <SplitPane 
                    ref="splitPane" split="vertical" defaultSize={400} style={{position: "relative", minHeight: "100vh"}}>

                <div className="attendance-event-nav">
                    <NavLink className="attendance-event-nav-1" to={PATH_INSTITUTE_LEADER_COURSE}>{int()} Intézményi felkészítő képzés</NavLink>
                    <NavLink className="attendance-event-nav-2" to={PATH_INSTITUTE_WORKSHOP}>{tan()} Intézményi workshop</NavLink>
                    <NavLink className="attendance-event-nav-3" to={PATH_PERSONALCOLNSULTING_AFTER}>{int()} Személyes tanácsadás</NavLink>
                    <NavLink className="attendance-event-nav-4" to={PATH_DFHT}>{ped()} DFHT-és Komplex óra egyéni hospitálás</NavLink>
                    <NavLink className="attendance-event-nav-5" to={PATH_SUBPROGRAM_OCCUPATION_GROUP}>{ped()} Alprogrami foglalkozások csoportszintű hospitálása</NavLink>
                    <NavLink className="attendance-event-nav-6" to={PATH_SUBPROGRAM_OCCUPATION_PERSONAL}>{ped()} Alprogrami foglalkozások egyéni hospitálása</NavLink>
                    <NavLink className="attendance-event-nav-7" to={PATH_MIDYEAR_INTERVIEW}>{int()} Évközi interjú</NavLink>
                    <NavLink className="attendance-event-nav-8" to={PATH_PERSONALCOLNSULTING_BEFORE}>{int()} Személyes tanácsadás</NavLink>
                    <NavLink className="attendance-event-nav-9" to={PATH_CLOSING_WORKSHOP}>{tan()} Záró workshop</NavLink>
                </div>

                <Switch>
                    <Route path={PATH_ATTENDANCE_EVENT_TYPES} exact component={EventTypes} />
                    
                    <Route path={PATH_INSTITUTE_LEADER_COURSE} component={InstituteLeaderCourse} />
                    <Route path={PATH_INSTITUTE_WORKSHOP} component={InstituteWorkshop} />
                    <Route path={PATH_PERSONALCOLNSULTING_AFTER} component={PersonalConsultationAferInstatiate} />
                    <Route path={PATH_DFHT} component={DFHTComplexAndKIP} />
                    <Route path={PATH_SUBPROGRAM_OCCUPATION_PERSONAL} component={SubProgramOccupationPersonalLevel} />
                    <Route path={PATH_SUBPROGRAM_OCCUPATION_GROUP} component={SubProgramOccupationGroupLevel} />
                    <Route path={PATH_MIDYEAR_INTERVIEW} component={MidYearInterview} />
                    <Route path={PATH_PERSONALCOLNSULTING_BEFORE} component={PersonalConsultationBeforeInstatiate} />
                    <Route path={PATH_CLOSING_WORKSHOP} component={ClosingWorkshop} />
                </Switch>

            </SplitPane>
            
    }

}

function int() {
    return <img src="/img/esem/int.svg" style={{color: "white", height: "1.5em", verticalAlign: "bottom", paddingRight: "0.5em"}}/>;
}
function ped() {
    return <img src="/img/esem/ped.svg" style={{color: "white", height: "1.5em", verticalAlign: "bottom", paddingRight: "0.5em"}}/>;
}
function tan() {
    return <img src="/img/esem/tan.svg" style={{color: "white", height: "1.5em", verticalAlign: "bottom", paddingRight: "0.5em"}}/>;
}

function EventTypes() {
        return <div className="row" style={{minHeight: "70vh"}}>
            <div className="column small-12">
            <br />
            <h4>Jelenléti Támogatórendszer</h4>
            <img width={500} src="/img/jtr/jtr3.jpg" />
            <br/>
            <p>
            A program újszerűsége, hogy nemcsak képzéseket valósít meg, hanem kiterjesztett szolgáltatáscsomaggal is segíti az iskolákat a KAP sikeres intézményi implementációjának érdekében. A Szakmai Támogatórendszer a Komplex Alapprogram megvalósításának segítését, nyomon követését különböző módokon és területeken támogatja, mint például jelenléti támogatási formák biztosításával.
            </p>
            <p>
            Jelenléti támogatás során személyes találkozásokon alapuló segítségnyújtást biztosítunk a Komplex Alapprogramot alkalmazó intézmények vezetőinek, tantestületeinek, pedagógusainak. A fenti ábra a jelenléti támogatás három fő célcsoportja kapcsán mutatja be, hogy melyik célcsoport milyen formában és területen kap segítséget a szakmai támogatás megvalósulásának különböző szakaszaiban.
            </p>
            
            <p>
            A jelenléti támogatás a KAP intézményi bevezetése előtt, a bevezetés tanévében és az azt követő egy tanévben valósul meg az iskola igényeit figyelembe véve. A KAP intézményi bevezetése előtt az alábbi szolgáltatások valósulnak meg minden újonnan belépő intézmény számára: 
                intézményvezetői felkészítő képzés, 
                intézményi workshop, 
                személyes tanácsadás. 
            A KAP intézményi <strong>bevezetésének tanévében</strong>tanévében a jelenléti támogatás alábbi szolgáltatásai valósulnak meg egy tanéven keresztül azokban az intézményekben, ahol bevezetésre kerül a Komplex Alapprogram: 
            </p>
            <p>
                        személyes tanácsadás, 
            évközi interjú, 
            DFHT és Komplex óra egyéni hospitálása, 
            alprogrami foglalkozásokhoz kapcsolódó egyéni és csoportszintű hospitálás, 
            záró workshop. 
            </p>
            <p>Szükség esetén az iskolák a bevezetést követő egy tanév során továbbra is igénybe tudják venni a jelenléti támogatás alábbi szolgáltatásait: 
                személyes tanácsadás igazgatóknak, 
                DFHT-KIP támogatás, 
                alprogrami foglalkozásokhoz kapcsolódó egyéni és csoportszintű hospitálás igénylése.
            </p>
            <p>
            Több mint száz szakmai támogató nyújt szakmai segítséget a KAP pedagógus-továbbképzéseken szerzett tudás pedagógiai gyakorlatban történő sikeres alkalmazásához a hospitálások, konzultációk során.
            </p>
            <p>
            A jelenléti támogatás gördülékeny szervezésének érdekében létrehoztuk a Jelenléti Támogatórendszer (JTR) online felületet, amely a Komplex Alapprogramot alkalmazó intézmények vezetőinek, tantestületeinek, pedagógusainak egyéni és csoportos formában megvalósuló jelenléti támogatásainak igénylésére, szervezésére ad lehetőséget, valamint az ezzel kapcsolatos adminisztratív feladatok elvégzése is ezen a felületen keresztül történik.
            </p>
        </div>
    </div>
}

export default function InstituteLeaderCourse() {
    return <div className="row">
        <div className="column small-12">
            <br />
            
            <h4>Intézményvezetői felkészítő képzés</h4>

            <p>Egy új program bevezetésének sikerét jelentősen meghatározza a vezető(ség) attitűdje, hozzáértése, elkötelezettsége az adott fejlesztéssel kapcsolatban. Ezt figyelembe véve alakítottuk ki a jelenléti támogató tevékenységeket, ahol első helyen az intézményvezetőt támogató formák állnak. A szakmai támogatás nemcsak a képzéseket követően van jelen, hanem már a képzések megkezdése előtt megszólítjuk az igazgatókat és intézményvezetői felkészítő képzésen való részvételi lehetőséget biztosítunk számukra, amely egy vagy kétnapos képzés kereteiben valósul meg. Az esemény célja, hogy a KAP bevezetését, működtetését és támogatását intézményvezetői szemmel áttekinthessék az érintettek. A KAP intézményi implementációját megkönnyíti azzal, hogy az intézményvezetők számára: </p>
            <ul>
                <li>részletesen bemutatja a program célkitűzéseit, felépítését, működését;</li>
                <li>ismerteti a program koncepcióját, tanítási-tanulási stratégiáját; </li>
                <li>elemzi a program innovatív elemeit, az alprogramokat; </li>
                <li>bemutatja, miként befolyásolja a KAP bevezetése a közismereti tárgyak tanítását a délelőtti foglalkozásokon, az értékelést, a tantárgyfelosztást, valamint az iskolai dokumentumok átdolgozásának módját; </li>
                <li>bemutatja, hogyan épül fel a KAP pedagógus-továbbképzési rendszere; </li>
                <li>ismerteti a KAP Szakmai Támogatórendszer felépítését és szolgáltatásait; </li>
            </ul>
            <p>
                Interaktív felkészítést biztosítunk az intézményvezetők számára a KAP bevezetésével kapcsolatos feladatok sikeres megvalósítása érdekében.
            </p>

        </div>

    </div>
}
export function SubProgramOccupationGroupLevel() {
    return <div className="row">
        <div className="column small-12">
            <br />
            
            <h4>Alprogrami foglalkozások csoportszintű hospitálása</h4>

            <p>Az alprogramokat megvalósító pedagógusoknak az alprogrami csoportszintű hospitáláson való részvételre van lehetőségük. A Komplex Alapprogramot bevezető iskoláknak minden hónapban lehetőségük van jelezni a KAPU rendszer Jelenléti Támogatórendszerén (JTR) keresztül, hogy az adott hónapban melyik alprogramhoz kapcsolódóan szeretnének csoportszintű hospitálást igényelni. A csoportszintű hospitálások a helyszínt biztosító intézmény kérése alapján meghirdetésre kerülnek, vagy csak az intézmény pedagógusai számára vagy a környékbeli KAP-ot megvalósító iskolák pedagógusai számára. </p>
            <p>
             Az alkalmakra való jelentkezés önkéntes. A foglalkozáson nemcsak az adott alprogram szakmai támogatója vesz részt, hanem egyik esetben az egy intézményen belüli kollégák vagy másik esetben a különböző partnerintézményekből érkező pedagógusok is. 
            </p>
            <p>A csoportszintű hospitálásból és az azt követő fókuszcsoportos interjúból álló alkalom max. 2 óra időtartamú, ebből 45–60 perc a foglalkozás, amelyet a pedagógus tart; a fókuszcsoportos interjú is kb. 45–60 perc, amelyet az adott alprogrami szakmai támogató tart a hospitálásra érkező pedagógusoknak.</p>
            <p>A csoportszintű hospitálást lezáró konzultáció mélyebb betekintésre ad lehetőséget az alprogrami foglalkozások megvalósításához, az eddigi tapasztalatokhoz. A megbeszélés során nagy szerepet kap a tudásmegosztás, a tapasztalatcsere – hiszen nemcsak a szakmai támogatóval történik beszélgetés, hanem a résztvevők természetesen egymásnak is tehetnek javaslatokat, adhatnak ötleteket. Az információcserén, tudásmegosztáson túl rendkívül fontos közösségszervező ereje is van egy-egy ilyen alkalomnak és elmélyítheti a hasonló kérdéseket megfogalmazó pedagógusok közötti szakmai és személyes kapcsolatokat is. A kialakult kapcsolatok megőrzése érdekében az SZTR a Komplex Alapprogram szerint működő iskolák számára iskolahálózatot tart fenn.</p>
            <p>A csoportszintű alprogrami jelenléti támogatás a KAP bevezetésének tanévében és az azt követő egy tanév során igényelhető.</p>

        </div>

    </div>
}
export function SubProgramOccupationPersonalLevel() {
    return <div className="row">
        <div className="column small-12">
            <br />
            
            <h4>Alprogrami foglalkozások egyéni hospitálása</h4>

            <p>Szükség esetén igényelhető személyre szabott alprogrami jelenléti támogatás konzultációval egybekötött egyéni hospitálás formájában. Ezt a szolgáltatást az intézményi kapcsolattartónak van lehetősége igényelni a Jelenléti Támogatórendszer (JTR) online felületén keresztül.
                Az alkalom során az alábbi tevékenységek valósulnak meg:
            </p>
            <ul>
                <li>A szakmai támogató az adott alprogrami foglalkozásra ellátogat, amelyet egyórás személyes konzultáció követ.</li>
                <li>A konzultáció során a szakmai támogató a megfigyelt foglalkozásról támogató javaslatokat fogalmaz meg. A szakmai támogató és a pedagógus közösen keresnek megoldást a felmerülő kérdésekre, problémahelyzetekre.</li>
            </ul>
            <p>Az egyéni alprogrami jelenléti támogatás a KAP bevezetésének tanévében és az azt követő egy tanév során igényelhető.</p>

        </div>

    </div>
}
export function DFHTComplexAndKIP() {
    return <div className="row">
        <div className="column small-12">
            <br />
            
            <h4>DFHT-KIP és Komplex óra egyéni hospitálása</h4>

            <p>A DFHT tanítási-tanulási stratégia szakmai támogatói a KAP szerint szervezett közismereti órák szakmai támogatásáért felelnek. Minden intézményhez delegálunk egy-egy ilyen szakembert, aki a képzést követően: 
            </p>
            <ul>
                <li>a számára kijelölt intézményekben két alkalommal tesz látogatást az egyéni hospitálások megvalósítása céljából,</li>
                <li>a DFHT-képzést elvégző pedagógusok négy saját DFHT-KIP óravázlatát áttekinti, és fejlesztési javaslatok megfogalmazásával segíti az új módszer megtanulását és magas szintű alkalmazását. </li>
            </ul>
            <p>Alkalmanként összesen öt pedagógus tanítási órája kerül megtekintésre, ebből négy DFHT-KIP módszert alkalmazó, státuszkezelő óra és egy Komplex óra. A szakmai támogató az egyéni hospitálásokhoz kapcsolódóan fejlesztési javaslatokat, szakmai irányokat fogalmaz meg előre meghatározott szempontsor alapján. A szakmai támogató a megtekintett órákat követő konzultáció keretében is visszajelez a tanítási órákkal kapcsolatban. A megbeszélés alkalmával a pedagógusoknak lehetőségük van kérdéseket megfogalmazni és az önfejlesztési tervükhöz tanácsokat, javaslatokat kérni.</p>
            <p>A DFHT képzést elvégzett pedagógusok négy hónapon keresztül (november – február) minden hónap végéig feltöltenek egy saját maguk által készített (más KAP online felületre még fel nem töltött) státuszkezelő óratervet a JTR csempére kattintva a DFHT-KIP óravázlatok menüpontban. Az intézményhez delegált DFHT-s szakmai támogató áttekinti a feltöltött óravázlatokat/feladatsorokat és saját tapasztalatainak, a státuszkezelési ismeretei átadásával, valamint fejlesztési javaslatok megfogalmazásával segíti az új módszer elsajátítását és professzionális alkalmazását. A szakmai támogató javítást kérhet mindaddig, amíg az óraterv nem felel meg a státuszkezelő módszer alapelveinek, szabályainak. Az óraterv elfogadása után a szakmai támogatónak lehetősége van kiválónak jelölni az óratervet. A megjelölt példaértékű óratervek felkerülhetnek a Tudástárba a pedagógus hozzájárulásával. A szakmai támogatás célja a státuszkezelő módszer megtanulásának elősegítése.</p>
            <p>Ha cél a tanulók alulmotiváltságának a megszüntetése és a magatartási problémák kezelése, és ezzel valóban változást szeretnénk elérni az osztálytermekben, akkor szakmailag indokolt, hogy a Komplex Alapprogram képzéseit követően minél hamarabb kezdjék meg a státuszkezelő DFHT-KIP módszer alkalmazását. Javasoljuk, hogy azok a pedagógusok is, akik – bár már elvégezték a KAP képzésit –, de később érintettek annak a bevezetésével, a közismereti tanóráikat a metódus szerint haladéktalanul kezdjék el szervezni.  Ennek egyik indoka, hogy ne vesszen el az a tudás, amit a képzéseken megtanultak, a másik pedig az, hogy a tanulók mielőbb részesüljenek a DFHT-KIP jótékony hatásában. Fontosnak megemlíteni, hogy az új módszer alkalmazása nem igényel intézményi szintű átszervezést.</p>
            <p>A DFHT-KIP módszer támogatását a program bevezetésének tanévét követő egy tanév során igény szerint kérhetik az intézményvezetők.</p>
            


        </div>

    </div>
}
export function MidYearInterview() {
    return <div className="row">
        <div className="column small-12">
            <br />
            
            <h4>Évközi interjú</h4>

            <p>A KAP bevezetésének tanévében szakmai támogatók segítik az intézményvezetők munkáját a személyes találkozások megvalósulása során. Rendszerünkben minden intézményhez intézményvezetőt támogató szakembert delegálunk, segítve a program iskolai bevezetését. A szakmai támogató feladata az intézményvezető támogatása a program iskolához való adaptálásban, figyelembe véve a helyi sajátosságokat, illetve intézményspecifikus tanácsok adása személyes tanácsadás formájában. Ezen kívül az intézményvezetőt támogató szakember a program bevezetésének tanévében legalább egyszer ellátogat az intézménybe az intézményvezetővel történő félig strukturált évközi interjú elkészítésének céljából, ami egyrészt a meghatározott területekkel kapcsolatos adatgyűjtés, másrészt egy kötetlen beszélgetés. Az évközi interjú javasolt időtartama 1-2 óra/alkalom. Az évközi interjú az alábbi területeket érinti:</p>

            <ul>
                <li>KAP-iskolák sajátosságainak megfelelően átdolgozott pedagógiai program, helyi tanterv, házirend, tanmenetek, intézményi pedagógiai dokumentumok;</li>
                <li>intézményben megvalósuló alprogrami foglalkozások;</li>
                <li>Differenciált Fejlesztés Heterogén Tanulócsoportban (DFHT) tanítási-tanulási stratégia alkalmazása;</li>
                <li>a KAP további lényeges elemeinek (Komplex órák, Ráhangolódás, Te órád) bevezetése;</li>
                <li>a KAP implementációjával kapcsolatos tapasztalatok;</li>
                <li>a megoldásra váró feladatok, fejlesztendő területek.</li>
            </ul>
        </div>
    </div>
}
export function InstituteWorkshop() {
    return <div className="row">
        <div className="column small-12">
            <br />
            
            <h4>Intézményi workshop</h4>

            <p>Az intézményvezetői felkészítő képzés utáni időszakban intézményi workshop igénylésére van lehetőség, amivel szeretnénk az igazgatók munkáját megkönnyíteni azáltal, hogy az intézménybe ellátogatott szakmai támogató részletesen bemutatja a KAP elemeit a programba bevonni kívánt partnerintézmények tantestületei számára. Az eseményen mélységeiben megismerik a programot, ahol a pedagógusoknak lehetőségük nyílik feltenni kérdéseiket a program koncepciójával, működésével és intézményi bevezetésével kapcsolatban.</p>
            <p>Az intézményi workshop a tantestület jelenléti támogatását segítő munkaforma a KAP intézményi bevezetését megelőző periódusban. A szakmai támogatók a workshopok alkalmával bemutatják, hogy miként egészítik ki, dúsítják az alprogrami foglalkozások a délelőtti tananyagot, miként lehet a tanulói viselkedést pozitívan befolyásolni, az alulmotiváltságot mérsékelni, a tanulók közötti együttműködést elősegíteni, illetve az egyes alprogrami tartalmaknak (DA, ÉA, LA, MA, TA) mik a céljai, milyen kompetenciák fejlesztésére fókuszálnak, milyen oktatást támogató módszereik vannak. Ugyanekkor tájékoztatják a tantestületet az SZTR felépítéséről, a Komplex Alapprogram iskolahálózatáról, illetve arról, hogyan válhat a program a szülők számára is vonzóvá.</p>
            <p>Az intézményi workshop szolgáltatás igény szerint kérhető a program bevezetése előtt. A Komplex Alapprogramot bemutató intézményi workshop a KAK képzés időpontjától eltérő napon vagy a KAK képzés napján is megvalósulhat. Az intézményi workshop időtartama 2 – 3 óra/alkalom.</p>

        </div>
    </div>
}
export function PersonalConsultationAferInstatiate() {
    return <div className="row">
        <div className="column small-12">
            <br />
            
            <h4>Személyes tanácsadás</h4>

            <p>Minden KAP-ot bevezető intézményhez delegálunk egy intézményvezetőt támogató szakembert, akiktől az igazgatók nemcsak a bevezetés előtt, hanem a bevezetés évében és az azt követő egy tanév során bármikor, akár több alkalommal is kérhetnek szakmai segítséget. Amennyiben összetett és személyes találkozást igénylő kérdés merül fel, úgy az intézményvezetőknek a személyes tanácsadás szolgáltatás igénybevételét ajánljuk fel. A bevezetés előtti tanévben tavasszal tájékoztatjuk az érintett igazgatókat az intézményhez delegált támogató elérhetőségéről.</p>
            <p>A KAP bevezetésének tanévében a program főbb elemeinek (DFHT tanítási-tanulási stratégia, alprogramok, ráhangolódás, Te órád) megvalósításával kapcsolatban jelentős segítséget tudnak nyújtani szakmai támogatóink annak érdekében, hogy az intézmény a helyi sajátosságokat, jó gyakorlatokat figyelembe véve sikeresen adaptálja a programot. A személyes konzultáció javasolt időtartama 1-2 óra/alkalom. </p>
        </div>
    </div>
}
export function PersonalConsultationBeforeInstatiate() {
    return <div className="row">
        <div className="column small-12">
            <br />
            
            <h4>Személyes tanácsadás</h4>

            <p>A program bevezetését megelőzően amennyiben összetett és személyes találkozást igénylő kérdés merül fel, úgy az intézményvezetőknek a személyes tanácsadás szolgáltatás igénybevételét ajánljuk fel. Minden KAP-ot bevezető intézményhez delegálunk egy intézményvezetőt támogató szakembert, akiktől az igazgatók nemcsak a bevezetés előtt, hanem a bevezetés évében és az azt követő egy tanév során bármikor, akár több alkalommal is kérhetnek szakmai segítséget. A bevezetés előtti tanévben tavasszal tájékoztatjuk az érintett igazgatókat az intézményhez delegált támogató elérhetőségéről.</p>
            <p>A KAP bevezetésének szakmai előkészítésében például a tantárgyfelosztás, az órarend elkészítésében és a tanügyi dokumentumok (pl.: pedagógiai program) KAP-hoz való igazításában jelentős segítséget tudnak nyújtani szakmai támogatóink annak érdekében, hogy az intézmény a helyi sajátosságokat, jó gyakorlatokat figyelembe véve sikeresen adaptálja a programot. A tanügyi dokumentumok módosításához központilag küldünk ki mintákat a bevezetés előtti tanévben tavasszal, amit akár mellékletként is be lehet építeni a dokumentumokba. A személyes konzultáció javasolt időtartama 1-2 óra/alkalom.</p>
        </div>
    </div>
}
export function ClosingWorkshop() {
    return <div className="row">
        <div className="column small-12">
            <br />
            
            <h4>Záró workshop</h4>

            <p>A program bevezetését követően a tanév végén valósul meg a záró workshop, melynek célja a teljes tanév tapasztalatainak áttekintése, a működés megvitatása, valamint a további fejlesztési lehetőségek meghatározása. Az esemény interaktív jellegéből fakadóan lehetőség nyílik véleménycserére és a tanév során szerzett, a KAP megvalósításával kapcsolatos ismeretek átadására, az eltelt időszak alatt szerzett tapasztalatok feltárására, fejlesztési javaslatok megfogalmazására. Amennyiben a programba bevont partnerintézmények vezetői igényt tartanak a szolgáltatásra, a szakmai támogatók közül két-három főből álló csoport látogat el az intézménybe egy alkalommal a tanév végén május és június között. Ez esetben a tanév munkarendjének tervezésekor célszerű figyelembe venni, hogy a záró workshop megtartásához 2-3 órás szabad időkeretre van szükség a tantestület részéről.</p>            
        </div>
    </div>
}
