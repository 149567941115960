import * as React from 'react';
import { Popover } from './Popover';
import { formatDate } from '@src/Util';
import { me } from '@src/framework/server/Auth';
import { __ } from '@src/translation';

export type CommentValue = {
    messages: {
        createdBy: number; /* user_id */
        createdAt: string; /* date string */
        createdByName: string; /* user name */
        text: string; /* message */
    }[];
}

export type CommentProps = {
    value?: CommentValue;
    onChange: (value: CommentValue) => void;
}

export type CommentState = {
    messagesOpen: boolean;
    text: string;
}

export class Comment extends React.Component<CommentProps, CommentState> {

    constructor(props: CommentProps) {
        super(props);

        this.state = {
            messagesOpen: false,
            text: ""
        }
    }

    private onAddMessage() {
        if (!me) return;
        if (!this.state.text) return;

        const value = this.props.value || {messages: []};

        value.messages.push({
            createdAt: formatDate(new Date()),
            createdBy: me.id,
            createdByName: (me.person && me.person.full_name) || me.login_name,
            text: this.state.text,
        });

        this.props.onChange(value);
        this.setState({
            text: "",
            messagesOpen: false
        });
    }

    render() {
        if (!me) return null;

        let creatorCounts: {[name: string]: number} = {};
        if (this.props.value && this.props.value.messages) {
            for (const message of this.props.value.messages) {
                if (!creatorCounts[message.createdByName]) creatorCounts[message.createdByName] = 0;
                creatorCounts[message.createdByName]++;
            }
        }

        return <>
            <button className={"kap-comment-button button " + (this.props.value && this.props.value.messages ? "alert clear" : "clear")} ref="button" onClick={() => this.setState({messagesOpen: !this.state.messagesOpen})}>
                <i className="fa fa-comment" />&nbsp; {Object.entries(creatorCounts).map(e => e[0] + " (" + e[1] + ")").join(", ")}
            </button>
            <Popover open={this.state.messagesOpen} parentElement={() => this.refs.button as HTMLButtonElement} onClose={() => this.setState({messagesOpen: false})}>
                <div style={{margin: "0.5em"}}>
                    <h4>{__("Megjegyzés:")}</h4>
                    {
                        this.props.value && this.props.value.messages.map(m => {
                            return <>
                                <hr/>
                                <div>{m.text.split('\n').map(x => <div>{x}</div>)}</div>
                                <small>{m.createdByName} / {m.createdAt}</small>
                            </>;
                        })
                    }
                    <hr/>
                    <textarea cols={50} rows={2} value={this.state.text} onChange={e => this.setState({text: e.target.value})} />
                    <button className="button small" onClick={this.onAddMessage.bind(this)}>
                        <i className="fa fa-plus" /> {__("Megyjegyzés hozzáadása")}
                    </button>
                </div>
            </Popover>
        </>
    }

}
