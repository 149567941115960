export class EKEQuizSeriesServer{
    
    static evaluateOnServer(exercise: any, correctSolution: any[], userSolution: any[]): Evaluated {
        var success = false;
        var successPercent = 0;  // currently this only calculates for every question individually
        var index = 0;
        let correctAnswers = 0;
        let numOfQuestions = exercise.exercise.num_of_questions > 0 ? exercise.exercise.num_of_questions : exercise.exercise.questions.length;
        let currentIdx = userSolution.length - 1;
        for (var j = 0; j < userSolution.length; j++) {
            for (var i = 0; i < correctSolution.length; i++) {
                if (correctSolution[i].key == userSolution[j].key) {
                    index = i;
                    userSolution[j].value = userSolution[j].value.sort();
                    if (JSON.stringify(correctSolution[i].value) === JSON.stringify(userSolution[j].value)) {
                        correctAnswers++;

                        // the success value only tells if the last submitted answer is good or not
                        if (j == currentIdx) // if it is the currently evaluated user solution
                            success = true;
                    }
                }
            }
        }
        if (correctAnswers)
            successPercent = Number((correctAnswers / numOfQuestions).toFixed(2));
        //return { "success": success, "solution": correctSolution[index].value, "successPercent": successPercent };
        return { "success": success, "solution": correctSolution, "successPercent": successPercent };
    }

    static filterExerciseOnServer(exercise: any, solution: any): any {

        if (!exercise.num_of_questions || exercise.num_of_questions < 1 || JSON.stringify(solution) === JSON.stringify({}) || solution.length == 0)
            return exercise;

        if (!exercise.ordered) {            //if not ordered, pick random question to make it empty
            var delIdx: any[] = [];
            let elementsToRemove = solution.length - exercise.num_of_questions;

            while (delIdx.length != elementsToRemove) {             //generating deletable indexes
                let j = Math.floor(Math.random() * (exercise.questions.length));
                if (delIdx.find(x => x == j) == undefined)
                    delIdx.push(j);
            }
            for (let index = 0; index < delIdx.length; index++) { //delete questions that are not needed because of limited question number
                exercise.questions[delIdx[index]].task = "";
                exercise.questions[delIdx[index]].options = [];
            }
        } else {            //if ordered, empty all the tasks above num_of_questions
            for (let index = exercise.num_of_questions; index < exercise.questions.length; index++) {
                exercise.questions[index].task = "";
                exercise.questions[index].options = [];
            }
        }

        return exercise;
    }
}