import * as React from 'react';

import { IViewKapInstituteSiteContactRecord, ViewKapInstituteSiteContactClassProxy, viewKapInstituteSiteContactClassProxy } from '@src/framework/view/sys/ViewKapInstituteSiteContact';
import ListView from '@framework/forms/listview';
import { __ } from '@src/translation';


export class InstituteSiteContactListViewInner extends ListView<IViewKapInstituteSiteContactRecord> {

    public getViewClassProxy() : ViewKapInstituteSiteContactClassProxy { return viewKapInstituteSiteContactClassProxy; }
}

export default function InstituteSiteContactListView(props: any) {
    return <InstituteSiteContactListViewInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "contact_displayname"},
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
