import * as React from "react";
import { BubbleLoader } from 'react-css-loaders';
import CourseCrud, { ICourseRecord, courseCrudClassProxy } from "@src/framework/crud/kap/CourseCrud";
import { app } from '@src/index';
import { me, hasAnyGroup, Groups, hasGroup } from "@src/framework/server/Auth";
import AccessDeniedPage from "@src/framework/pages/AccessDeniedPage";
import PermissionPage from "@src/framework/PermissionPage";
import { CrudPermissionType } from "@src/framework/security/PermissionCache";
import { WorkflowDialog } from "@src/component/wf/WorkflowDialog";
import { __ } from "@src/translation";
import ButtonGroup, { ButtonSize, ButtonAlign } from "@src/component/ui/ButtonGroup";
import LookupEdit from "@src/framework/forms/lookupedit";
import TrainingTypeCrud, { ITrainingTypeRecord } from "@src/framework/crud/kap/TrainingTypeCrud";
import SecUserCrud from "@src/framework/crud/sys/SecUserCrud";
import { HTMLTextarea } from "@src/component/HtmlTextarea";
import ViewKapCourseTrainerInvitationAnswer, { IViewKapCourseTrainerInvitationAnswerRecord } from "@src/framework/view/kap/ViewKapCourseTrainerInvitationAnswer";
import CourseContactDayCrud, { ICourseContactDayRecord } from "@src/framework/crud/kap/CourseContactDayCrud";
import { whiteSpaceChecker } from "@src/component/WhiteSpaceChecker";
import { DateTimePicker, DateTimeGeneralType } from "@src/component/dashboard/datetimepicker/DateTimePicker";
import CourseTrainerInvitationCrud, { ICourseTrainerInvitationRecord } from "@src/framework/crud/kap/CourseTrainerInvitationCrud";
import InstituteSiteCrud from "@src/framework/crud/sys/InstituteSiteCrud";
import ViewKapCourseTrainerInvitation, { IViewKapCourseTrainerInvitationRecord } from "@src/framework/view/kap/ViewKapCourseTrainerInvitation";
import { viewKapKszrTrainerClassProxy } from "@src/framework/view/kap/ViewKapKszrTrainer";
import CourseStudentList from "../CourseStudentList";
import { formatDate } from "@src/Util";
import ViewKapCourse, { viewKapCourseClassProxy } from "@src/framework/view/kap/ViewKapCourse";
import { confirmDialog } from "@src/component/Dialog";
import WfAPI from "@src/framework/wf/WfAPI";
import obtainServer from "@src/framework/server/Server";
import CourseDocumentComponent from "../CourseDocumentsComponent";
import { Accordion, AccordionItem } from "@src/component/ui/Accordion";
import { DateToString, DTSMode } from "@src/component/dashboard/eventcalendar/EventCalendarAPI";
import { viewOhInspectorClassProxy } from "@src/framework/view/sys/ViewOhInspector";
import CourseInstituteSiteCrud, { ICourseInstituteSiteRecord } from "@src/framework/crud/kap/CourseInstituteSiteCrud";
import { viewInstituteSiteClassProxy } from "@src/framework/view/sys/ViewInstituteSite";
import ViewKapCourseInstituteSite from "@src/framework/view/kap/ViewKapCourseInstituteSite";
import { match } from "react-router";
import { History } from "history";
import CourseTrainerInvitationAnswerCommentCrud from "@src/framework/crud/kap/CourseTrainerInvitationAnswerCommentCrud";
import ViewCourseTrainerInvitationAnswerComment, { IViewCourseTrainerInvitationAnswerCommentRecord } from "@src/framework/view/kap/ViewCourseTrainerInvitationAnswerComment";
import { Breadcrumbs } from "@src/component/Breadcrumbs";
import ViewKapCourseUser, { IViewKapCourseUserRecord } from "@src/framework/view/kap/ViewKapCourseUser";
import { courseModule } from "../courseModule";
import { CourseChecklist } from "./CourseChecklist";
import CourseSchoolInvitationCrud, { ICourseSchoolInvitationRecord } from "@src/framework/crud/kap/CourseSchoolInvitationCrud";
import ViewKapCourseSchoolInvitation, { IViewKapCourseSchoolInvitationRecord } from "@src/framework/view/kap/ViewKapCourseSchoolInvitation";

interface ICourseEditComponentProps {
    match: match<{ recId: string }>;
    history: History;
}

interface IMinimalCourseInstituteSiteRecord {
    id?: number;
    institute_site_id: number;
    is_active: boolean;
    is_internal: boolean;
    site_name: string;
    om_identifier: string;
    site_identifier: string;
}

interface ICourseEditComponentState {
    loading: boolean;
    courseRecord?: ICourseRecord;
    workflowDialogOpen: boolean;
    workflowStateName?: string;
    isDataChanged: boolean;
    trainerInvitationAnswers: IViewKapCourseTrainerInvitationAnswerRecord[];
    contactDates: ICourseContactDayRecord[];
    courseInstituteSites: IMinimalCourseInstituteSiteRecord[];
    participant_id: number | null;
    tempSiteId?: number;
    currentTrainerInvitation?: IViewKapCourseTrainerInvitationRecord;
    invitationDeadline?: string;
    validationMessages: Map<string, string>
    showCommentsForAnswerId: number | null;
    comments: IViewCourseTrainerInvitationAnswerCommentRecord[];
    invitationComment: string;
    trainingTypes: ITrainingTypeRecord[];
    isOnline: boolean;
    schoolInvitations: IViewKapCourseSchoolInvitationRecord[];
    schoolInvitationSending : boolean;
}

const WF_STATION_CLOSE_INVITATION_PERIOD = 25408797519; //type id: 25408797512
const WF_STATION_ACTIVE_INVITATION_PERIOD = 25408797513;

export default class CourseEdit extends React.Component<ICourseEditComponentProps, ICourseEditComponentState> {

    private curseStudentList: CourseStudentList | null = null;

    constructor(props: ICourseEditComponentProps) {
        super(props);

        let validationMap: Map<string, string> = new Map<string, string>();
        this.state = {
            loading: true,
            courseRecord: undefined,
            workflowDialogOpen: false,
            workflowStateName: undefined,
            isDataChanged: false,
            trainerInvitationAnswers: [],
            contactDates: [],
            courseInstituteSites: [],
            currentTrainerInvitation: undefined,
            validationMessages: validationMap,
            participant_id: null,
            showCommentsForAnswerId: null,
            comments: [],
            invitationComment: "",
            trainingTypes: [],
            isOnline: false,
            schoolInvitations: [],
            schoolInvitationSending : false
        };
    }

    componentDidMount() {
        this.asyncReload();
    }

    componentDidUpdate(prevProps: ICourseEditComponentProps) {
        if (this.props.match.params.recId != prevProps.match.params.recId) {
            this.asyncReload();
        }
    }

    private asyncReload = async () => {
        this.setState({ loading: true, courseRecord: undefined });
        let recId = this.getMatchParam("recId");
        let trainers: IViewKapCourseTrainerInvitationAnswerRecord[] = [];
        let contactDates: ICourseContactDayRecord[] = [];
        let courseInstituteSites: IMinimalCourseInstituteSiteRecord[] = [];
        let currTrainerInvitation: any = undefined;
        let wfStateName = "";
        let schoolInvitations: IViewKapCourseSchoolInvitationRecord[] = [];
        try {
            let course: ICourseRecord = {};

            if (this.state.courseRecord && this.state.courseRecord.id) {
                recId = this.state.courseRecord.id;
            }

            const trainingTypes = await TrainingTypeCrud.list({ filter: { is_active: true } });
            let trainingType: ITrainingTypeRecord | undefined = undefined;

            if (recId && recId != "null") {
                course = (await courseCrudClassProxy.load(recId!));
                trainingType = trainingTypes.find((el) => { return el.id == course.training_type_id });
                contactDates = (await CourseContactDayCrud.list({ filter: { course_id: Number(recId), is_active: true }, order_by: "no" }));
                let trainerInvitations: IViewKapCourseTrainerInvitationRecord[] = (await ViewKapCourseTrainerInvitation.list({ filter: { course_id: Number(recId) }, order_by: [{ name: "creation_time", desc: true }] }));
                if (trainerInvitations && trainerInvitations.length > 0) {
                    currTrainerInvitation = trainerInvitations[0];
                    trainers = (await ViewKapCourseTrainerInvitationAnswer.list({ filter: { course_trainer_invitation_id: currTrainerInvitation.id }, order_by: "trainer_fullname" }));
                }
                let tmpCourseView = (await ViewKapCourse.load(recId)).record;
                wfStateName = tmpCourseView.wf_station_name ? tmpCourseView.wf_station_name : "";
                courseInstituteSites = (await ViewKapCourseInstituteSite.list({ filter: { course_id: Number(recId) } })) as IMinimalCourseInstituteSiteRecord[];

                schoolInvitations = await ViewKapCourseSchoolInvitation.list({ filter: { course_id: Number(recId), is_active: true }, order_by: "id" })

            } else {
                course = {
                    max_participants: 30,
                }
            }


            this.setState({
                loading: false,
                courseRecord: course,
                trainerInvitationAnswers: trainers,
                contactDates,
                courseInstituteSites,
                currentTrainerInvitation: currTrainerInvitation,
                workflowStateName: wfStateName,
                trainingTypes,
                isOnline: (trainingType != undefined && trainingType.is_online == true),
                schoolInvitations
            });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        };
    }

    validation(course: ICourseRecord, contactDates: ICourseContactDayRecord[], validationMessages: Map<string, string>): boolean {

        const trainingType = this.state.trainingTypes.find(tt => tt.id === course.training_type_id);

        let errorMsg = "";
        // if (!course.title) {
        //     errorMsg = __("A nevet kötelező kitölteni!");
        //     app.showError(__("Hiba"), errorMsg);
        //     validationMessages.set("title", errorMsg);
        // }
        if (!course.training_type_id) {
            errorMsg = __("A típust kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("training_type_id", errorMsg);
        }
        if (!course.institute_site_id) {
            errorMsg = __("Az intézményt kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("institute_site_id", errorMsg);
        }
        /* if (!course.address || !whiteSpaceChecker(course.address)) {
             errorMsg = __("A címet kötelező kitölteni!");
             app.showError(__("Hiba"), errorMsg);
             validationMessages.set("address", errorMsg);
         }*/
        if (!course.title || course.title.length < 2 || !whiteSpaceChecker(course.title)) {
            errorMsg = __("A kurzus címét kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("title", errorMsg);
        }
        if (!course.max_participants || course.max_participants < 2 || course.max_participants > 50) {
            errorMsg = __("A résztvevő szám nem megfelelő (min. 2, max. 50)!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("max_participants", errorMsg);
        }

        if (!course.training_from || !whiteSpaceChecker(course.training_from)) {
            errorMsg = __("A kezdési dátumot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("training_from", errorMsg);
        }
        if (!course.training_to || !whiteSpaceChecker(course.training_to)) {
            errorMsg = __("A befejezési dátumot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("training_to", errorMsg);
        }
        if (course.training_from && course.training_to && Date.parse(course.training_to) < Date.parse(course.training_from)) {
            errorMsg = __("A befejezési dátum nem előzheti meg a kezdési dátumot!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("training_to", errorMsg);
        }
        if (!course.application_deadline || !whiteSpaceChecker(course.application_deadline)) {
            errorMsg = __("A jelentkezési dátumot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("application_deadline", errorMsg);
        }
        if (!course.apply_after_internal || !whiteSpaceChecker(course.apply_after_internal)) {
            errorMsg = __("A jelentkezési dátumot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("apply_after_internal", errorMsg);
        }
        if (!course.apply_after_external || !whiteSpaceChecker(course.apply_after_external)) {
            errorMsg = __("A jelentkezési dátumot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("apply_after_external", errorMsg);
        }
        if (course.training_to) {
            if (course.application_deadline && Date.parse(course.training_to) < Date.parse(course.application_deadline)) {
                errorMsg = __("A jelentkezési dátumok nem lehetnek később mint a befejezési dátum!");
                app.showError(__("Hiba"), errorMsg);
                validationMessages.set("application_deadline", errorMsg);
            }
            if (course.apply_after_internal && Date.parse(course.training_to) < Date.parse(course.apply_after_internal)) {
                errorMsg = __("A jelentkezési dátumok nem lehetnek később mint a befejezési dátum!");
                app.showError(__("Hiba"), errorMsg);
                validationMessages.set("apply_after_internal", errorMsg);
            }
            if (course.apply_after_external && Date.parse(course.training_to) < Date.parse(course.apply_after_external)) {
                errorMsg = __("A jelentkezési dátumok nem lehetnek később mint a befejezési dátum!");
                app.showError(__("Hiba"), errorMsg);
                validationMessages.set("apply_after_external", errorMsg);
            }
        }
        /* if (course.application_deadline) {            
             if (course.apply_after_internal && Date.parse(course.application_deadline) < Date.parse(course.apply_after_internal)) {
                 errorMsg = __("A jelentkezés kezdési dátumok nem lehetnek később mint a jelelentkezési határidő!");
                 app.showError(__("Hiba"), errorMsg);
                 validationMessages.set("apply_after_internal", errorMsg);
             }
             if (course.apply_after_external && Date.parse(course.application_deadline) < Date.parse(course.apply_after_external)) {
                 errorMsg = __("A jelentkezés kezdési dátumok nem lehetnek később mint a jelelentkezési határidő!");
                 app.showError(__("Hiba"), errorMsg);
                 validationMessages.set("apply_after_external", errorMsg);
             }
         }*/
        if (!course.submission_deadline || !whiteSpaceChecker(course.submission_deadline)) {
            errorMsg = __("A beadandó határidőt kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("submission_deadline", errorMsg);
        }
        if (trainingType && !trainingType.is_online && (!course.distance_learning_start || !whiteSpaceChecker(course.distance_learning_start))) {
            errorMsg = __("A távoktatás dátumot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("distance_learning_start", errorMsg);
        }
        if (trainingType && !trainingType.is_online && (!course.distance_learning_end || !whiteSpaceChecker(course.distance_learning_end))) {
            errorMsg = __("A távoktatás dátumot kötelező kitölteni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("distance_learning_end", errorMsg);
        }
        if (trainingType && !trainingType.is_online && (!contactDates || contactDates.length < 2)) {
            errorMsg = __("Legalább két kontaktnapot kötelező megadni!");
            app.showError(__("Hiba"), errorMsg);
            validationMessages.set("contactDates", errorMsg);
        }

        contactDates.forEach(contactDate => {
            if (!contactDate.official_start) {
                errorMsg = __("Töltse ki a kontaktnapok az idő részét is!");
                app.showError(__("Hiba"), errorMsg);
                validationMessages.set("contactDates", errorMsg);
                return;
            }
            /*if (!contactDate.official_end) {
                errorMsg = __("Töltse ki a kontaktnapok az idő részét is!");
           
                validationMessages.set("contactDates", errorMsg);
                return;
            }*/
            if (!contactDate.real_start) {
                errorMsg = __("Töltse ki a kontaktnapok idő részét is!");
                app.showError(__("Hiba"), errorMsg);
                validationMessages.set("contactDates", errorMsg);
                return;
            }
            /*if (!contactDate.real_end) {
                errorMsg = __("Töltse ki a kontaktnapok az idő részét is!");
                app.showError(__("Hiba"), errorMsg);
                validationMessages.set("contactDates", errorMsg);
                return;
            }*/
        });

        if (validationMessages.size > 0) {
            return false;
        }
        return true;
    }

    async onSave(asCopy: boolean) {
        let course = this.state.courseRecord;
        console.log(course);
        let validationMessages = new Map<string, string>();
        if (!course) return;
        if (asCopy) {
            if ((!await confirmDialog(__("Megerősítés"), __("Biztosan készít egy másolatot?"))))
                return;
            // Mindent megtartunk, kivéve a képzőt, iktatószámot és az ellenőrt
            course.id = undefined;
            course.teacher_id = undefined;
            course.regno = undefined;
            course.oh_inspector_id = undefined;
            course.oo_folder_id = undefined;
        }
        this.setState({ loading: true });

        if (!this.validation(course, this.state.contactDates, validationMessages)) {
            this.setState({ loading: false, validationMessages });
            return;
        }

        let dateTimeFields = ["application_deadline", "apply_after_internal", "apply_after_external", "submission_deadline"];
        for (let index = 0; index < dateTimeFields.length; index++) {
            if (course[dateTimeFields[index]].length <= 11) {
                course[dateTimeFields[index]] = course[dateTimeFields[index]] + " 00:00";
            }
        }

        try {
            const isNew = course.id == undefined;
            if (!isNew) {
                course.is_active = undefined;
            }
            course = (await (new CourseCrud(course).put())).record;

            if (!isNew && course.id) {
                let prevContactDates: ICourseContactDayRecord[] = (await CourseContactDayCrud.list({ filter: { course_id: Number(course.id), is_active: true } }));
                prevContactDates.forEach(contactDate => {
                    let currDate = this.state.contactDates.find((el) => { return el.id == contactDate.id });
                    if (currDate == undefined) CourseContactDayCrud.deleteById(contactDate.id!);
                });

            }

            // Rendezzük kezdési dátum szerint
            let newContactDates: ICourseContactDayRecord[] = this.state.contactDates.sort(
                (contactDate1: ICourseContactDayRecord, contactDate2: ICourseContactDayRecord) => {
                    if (contactDate1.official_start && contactDate2.official_start) {
                        if (contactDate1.official_start < contactDate2.official_start) {
                            return -1;
                        } else if (contactDate1.official_start > contactDate2.official_start) {
                            return 1;
                        } else {
                            return 0;
                        }
                    } else {
                        return 0;
                    }
                }
            );
            let savedContactDates: ICourseContactDayRecord[] = [];
            //let maxNo: number = Math.max.apply(Math, this.state.contactDates.map((item) => (item.no || 0))) || 0;
            let maxNo: number = 0;
            for (let index = 0; index < newContactDates.length; index++) {
                const curr_date = newContactDates[index];
                maxNo += 1;
                let baseDate = curr_date.official_start!.substr(0, 11);
                let new_date: Partial<ICourseContactDayRecord> = {
                    official_start: curr_date.official_start,
                    real_start: curr_date.real_start ? (baseDate + curr_date.real_start.substr(11)) : curr_date.official_start,
                    official_end: curr_date.official_end ? baseDate + curr_date.official_end!.substr(11) : undefined,
                    real_end: curr_date.real_end ? (baseDate + curr_date.real_end.substr(11)) : curr_date.official_end,
                    institute_site_id: curr_date.institute_site_id,
                    address: curr_date.address,
                    no: maxNo
                };
                if (curr_date.id && !asCopy) {
                    new_date.id = curr_date.id;
                } else {
                    new_date.course_id = course.id;
                }
                let savedContactDate = (await (new CourseContactDayCrud(new_date).put())).record;
                savedContactDates.push(savedContactDate);
            }


            // Töröltek törlése
            for (let i = 0; i < this.state.courseInstituteSites.length - 1; i++) {
                const courseInstituteSite = this.state.courseInstituteSites[i];
                if (courseInstituteSite.id && !courseInstituteSite.is_active) {
                    await CourseInstituteSiteCrud.deleteById(courseInstituteSite.id!);
                }
            }
            // Újak hozzáadása/módosítása

            for (let i = 0; i < this.state.courseInstituteSites.length; i++) {
                const courseInstituteSite = this.state.courseInstituteSites[i];
                let newItem: ICourseInstituteSiteRecord = {
                    course_id: course.id,
                    institute_site_id: courseInstituteSite.institute_site_id,
                    is_active: courseInstituteSite.is_active!,
                    is_internal: courseInstituteSite.is_internal
                };
                await new CourseInstituteSiteCrud(newItem).upsert(["course_id", "institute_site_id"]);
            }

            // Résztvevő mely napokon vett részt a kontant napokon mentése a CourseStudentList komponensből
            if (this.curseStudentList) this.curseStudentList.saveContactDays();

            app.showSuccess(__("Mentés"), asCopy ? __("Kurzus másolata elkészült és betöltődött!") : __("Sikeres mentés!"));

            this.setState({ loading: false, isDataChanged: false, courseRecord: course, validationMessages, contactDates: savedContactDates },
                () => this.props.history.push(viewKapCourseClassProxy.getEditUrl(course!.id!, undefined))
            );

        } catch (error) {
            app.showErrorFromJsonResult(error);
            this.setState({ loading: false, validationMessages });
        }
    }

    protected getMatchParam(name: string): any {
        const match: any = this.props.match;
        return (match && match.params) ? match.params[name] : null;
    }


    private onCouseFieldChange(e: any, fieldName: string) {
        let courseRecord = Object.assign({}, this.state.courseRecord);
        if (e.type != undefined) {
            if (e.target.getAttribute("data-type") == "number") {
                courseRecord[fieldName] = Number(e.target.value);
            } else if (e.target.type === 'checkbox') {
                courseRecord[fieldName] = e.target.checked;
            } else {
                courseRecord[fieldName] = e.target.value;
            }
        } else {
            courseRecord[fieldName] = e.target.value;
        }
        this.setState({ courseRecord, isDataChanged: true });
    }


    private onInvitationDeadlineChange(dateTime: string) {
        this.setState({ invitationDeadline: dateTime });
    }

    private handleDescriptionChange(fieldName: any, text: string) {
        let courseRecord = Object.assign({}, this.state.courseRecord);
        courseRecord[fieldName] = text;
        this.setState({ courseRecord, isDataChanged: true });
    }

    private onTrainingTypeChanged(value: number) {
        let tt = this.state.trainingTypes.find(el => el.id == value);
        let courseRecord = Object.assign({}, this.state.courseRecord);
        courseRecord.training_type_id = value;
        this.setState({ courseRecord, isOnline: tt != undefined && tt.is_online == true, isDataChanged: true });
    }

    private onCourseFieldChangeWithValue(value: any, fieldName: string) {
        let courseRecord = Object.assign({}, this.state.courseRecord);
        courseRecord[fieldName] = value;
        this.setState({ courseRecord, isDataChanged: true });
    }

    private onTempSiteChanged(id: number | null) {
        this.setState({ tempSiteId: id ? id : undefined });
    }

    private async onAddNewSite() {
        if (this.state.tempSiteId) {
            const courseInstituteSites = this.state.courseInstituteSites;
            const idx = courseInstituteSites.findIndex((courseInstituteSite) => { return courseInstituteSite.institute_site_id == this.state.tempSiteId });
            if (idx >= 0) {
                if (!courseInstituteSites[idx].is_active) {
                    courseInstituteSites[idx].is_active = true;
                    this.setState({ courseInstituteSites, isDataChanged: true });
                }
            } else {
                let instituteSite = (await viewInstituteSiteClassProxy.load(this.state.tempSiteId));
                if (!instituteSite) return;

                const courseInstituteSite: IMinimalCourseInstituteSiteRecord = {
                    institute_site_id: instituteSite.id!,
                    is_internal: false,
                    is_active: true,
                    site_name: instituteSite.name!,
                    om_identifier: instituteSite.om_identifier!,
                    site_identifier: instituteSite.site_identifier!,
                };
                courseInstituteSites.push(courseInstituteSite);
                this.setState({ courseInstituteSites, isDataChanged: true });
            }
        }
    }

    private onDeleteSite(index: number) {
        const courseInstituteSites = this.state.courseInstituteSites;
        courseInstituteSites[index].is_active = false;
        this.setState({ courseInstituteSites, isDataChanged: true });
    }

    private onSiteChange(e: any, index: number) {
        const courseInstituteSites = this.state.courseInstituteSites;
        courseInstituteSites[index].is_internal = e.target.checked;
        this.setState({ courseInstituteSites, isDataChanged: true });
    }

    private async onSendNewSchoolInvitation() {
        if (!confirm(__("Biztosan ki akar küldeni értesítőket az összes ellátási helynek?"))) {
            return;
        }
        try {
            this.setState({schoolInvitationSending: true});
            const crud = await new CourseSchoolInvitationCrud({ course_id: this.state.courseRecord!.id! }).put();
            const view = await ViewKapCourseSchoolInvitation.load(crud.record.id!);
            const schoolInvitations = this.state.schoolInvitations;
            schoolInvitations.push(view.record);
            this.setState({schoolInvitations, schoolInvitationSending: false});
        } catch (exc) {
            this.setState({schoolInvitationSending: false});
            app.showErrorFromJsonResult(exc.message);
        }
    }

    private onContactDateChange(e: any, index: number, fieldName: string) {
        if (e.target.value.length > 14 || fieldName == "address") {
            try {
                let tmpCDList = this.state.contactDates;
                tmpCDList[index][fieldName] = e.target.value;
                this.setState({ contactDates: tmpCDList, isDataChanged: true });
            } catch (exc) {
                app.showErrorFromJsonResult(exc.message);
            }
        }
    }

    private onNewContactDate(e: any) {
        let tmp = new Date();
        let officalDate = DateToString(tmp, DTSMode.dateFull, "-");
        let newCD: ICourseContactDayRecord = {
            address: "",
            official_start: officalDate + " 09:00",
            real_start: officalDate + " 09:00",
            official_end: officalDate + " 17:00",
            real_end: officalDate + " 17:00"
        };
        let tmpCDList = this.state.contactDates;
        tmpCDList.push(newCD);
        this.setState({ contactDates: tmpCDList, isDataChanged: true });
    }

    private onDeleteContactDate(e: any, index: number) {
        let tmpCDList = this.state.contactDates;
        tmpCDList.splice(index, 1);
        this.setState({ contactDates: tmpCDList, isDataChanged: true });
    }

    private async onSetTrainerId(trainerId: number | null) {
        try {
            const courseRecord = Object.assign({}, this.state.courseRecord);
            if (courseRecord.id) {
                await new CourseCrud({ id: courseRecord.id, teacher_id: trainerId }).put();
            }
            courseRecord.teacher_id = trainerId;
            this.setState({ courseRecord, isDataChanged: true });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async onSelectTrainer(trainerId: number) {
        let message = __("Biztosan kiválasztja az adott képzőt?")

        if ((await confirmDialog(__("Megerősítés"), message))) {
            this.onSetTrainerId(trainerId);
        }
    }

    private async closeInvitationPeriod() {
        if (!this.state.courseRecord || !this.state.currentTrainerInvitation) return;
        let message = __("Biztosan lezárja az adott visszajelzési időszakot?")
        if(!this.state.courseRecord.teacher_id){
            message = __("Nem válaszott ki képzőt! Ha most lezárja a meghívásokat, akkor a jelöltek nem kapnak értesítést arról, hogy ki lett kiválasztva képzőnek. Biztos benne, hogy ezt akarja?");
        }

        if ((await confirmDialog(__("Megerősítés"), message))) {

            const wfApi: WfAPI = new WfAPI(obtainServer());
            const transitions = await wfApi.listPossibleTransitions(this.state.currentTrainerInvitation.wf_workflow_id!);
            if (!transitions || transitions.length < 1) {
                return;
            }

            let trainerInvitations: IViewKapCourseTrainerInvitationRecord[] = (await ViewKapCourseTrainerInvitation.list({ filter: { course_id: this.state.courseRecord.id }, order_by: [{ name: "creation_time", desc: true }] }));
            for (let index = 0; index < trainerInvitations.length; index++) {
                const curr_invitation = trainerInvitations[index];
                if (curr_invitation.wf_station_id == WF_STATION_ACTIVE_INVITATION_PERIOD) {
                    try {
                        (await (wfApi.makeTransition(curr_invitation.wf_workflow_id!, WF_STATION_CLOSE_INVITATION_PERIOD)).then(
                            () => {
                                this.asyncReload().then(() => {
                                    app.showSuccess(__("Sikeres művelet"), __("Visszajelzési időszak sikeresen lezárva!"))
                                });
                            }));
                    } catch (ex) {
                        app.showErrorFromJsonResult(ex);
                    }
                }

            }
        }

    }

    private async onInviteTrainers() {
        if (!this.state.courseRecord || !this.state.courseRecord.id || this.state.isDataChanged) {
            app.showError(__("Hiba"), __("Nem hívhat meg képzőket, amíg el nem menti a kurzust!"));
            return;
        }
        if (this.state.courseRecord.teacher_id) {
            app.showError(__("Hiba"), __("Nem hívhat meg képzőket, mert a kurzusnak van képzője! Mentse először a változásokat!"));
            return;
        }
        if (!this.state.isOnline && (!this.state.contactDates || this.state.contactDates.length < 2)) {
            app.showError(__("Hiba"), __("Nem hívhat meg képzőket, amíg nem adott meg kontaktnapokat!"));
            return;
        }

        if (!this.state.invitationDeadline || this.state.invitationDeadline == "") {
            app.showError(__("Hiba"), __("Kérjük adja meg a visszajelzés határidejét!"));
            return;
        }

        if (this.state.invitationDeadline.length < 15) {
            app.showError(__("Hiba"), __("Kérjük adja meg a visszajelzés határidejét (az idő részét is)!"));
            return;
        }

        let invitation: ICourseTrainerInvitationRecord = {
            course_id: this.state.courseRecord.id,
            answer_deadline: this.state.invitationDeadline
        }
        try {

            if (this.state.currentTrainerInvitation && this.state.currentTrainerInvitation.wf_station_id == WF_STATION_ACTIVE_INVITATION_PERIOD) {
                app.showError(__("Hiba"), __("Kérjük előbb zárja le a meghívásokat!"));
                return;
            }

            await (new CourseTrainerInvitationCrud(invitation).put());
            app.showSuccess(__("Képzők meghívása"), __("A képzők meghívása sikeres!"));
            this.setState({ loading: false }, () => this.asyncReload());

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }


    private fetchCommentsFor = async (trainerInvitationAnswerId: number | null) => {
        try {
            let comments: IViewCourseTrainerInvitationAnswerCommentRecord[] = [];
            if (trainerInvitationAnswerId) {
                comments = await ViewCourseTrainerInvitationAnswerComment.list({
                    filter: { course_trainer_invitation_answer_id: trainerInvitationAnswerId },
                    order_by: "id"
                });
            } else {
                comments = [];
            }
            this.setState({
                showCommentsForAnswerId: trainerInvitationAnswerId,
                comments: comments
            });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private appendComment = async () => {
        try {
            const result = await new CourseTrainerInvitationAnswerCommentCrud({
                course_trainer_invitation_answer_id: this.state.showCommentsForAnswerId!,
                message: this.state.invitationComment
            }).put();
            const comment = await (await ViewCourseTrainerInvitationAnswerComment.load(result.record.id!)).record;
            let comments = this.state.comments;
            comments.push(comment);
            this.setState({ comments, invitationComment: "" });

        } catch (e) {
            e.showErrorFromJsonResult(e);
        }
    }

    private showCommentsTable = () => {
        return <div className="trainer-invitation-response-div">
            {this.state.comments.map((comment: IViewCourseTrainerInvitationAnswerCommentRecord) => {
                return <div>
                    <span><strong>{comment.fullname}</strong></span> <span className="answer-comment-time" >{comment.creation_time!.substr(0, 16)}</span>
                    <p>{comment.message}</p>
                </div>
            })}
            <label>Új üzenet beküldése
                        <input type="text" value={this.state.invitationComment}
                    onChange={(e) => { this.setState({ invitationComment: e.target.value }) }}
                    onKeyDown={(e) => {
                        if ((e.key === 'Enter') && this.state.invitationComment.trim()) {
                            this.appendComment();
                        }
                    }}
                />
            </label>
            <button
                className="button primary"
                title={__("Új üzenet beküldése")}
                disabled={this.state.invitationComment.trim().length == 0}
                onClick={this.appendComment}
            >
                <i className="fa fa-paper-plane" />
                {__("Beküld")}
            </button>
        </div>


    }

    private invitationsTable(isInvitationClosed: boolean): JSX.Element {
        if (this.state.currentTrainerInvitation && whiteSpaceChecker(this.state.currentTrainerInvitation.answer_deadline)) {
            return <div className="large-12 small-8 columns">
                <span>{__("Már lettek képzők meghívva a kurzusra.")}</span><br />
                <span ><strong>{__("Visszajelzés határideje: ")}</strong> {formatDate(this.state.currentTrainerInvitation.answer_deadline)}</span><br />
                {
                    isInvitationClosed && <span style={{ color: "red" }}>{__("A visszajelzési időszak lezárult.")}</span>
                }
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>{__("Teljes név")}</th>
                            <th>{__("Visszajelzés")}</th>
                            <th>{__("Művelet")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.trainerInvitationAnswers && this.state.trainerInvitationAnswers.length > 0 ?
                                this.state.trainerInvitationAnswers.map((trainerInvitationAnswer: IViewKapCourseTrainerInvitationAnswerRecord, index) => {
                                    let style = undefined;
                                    let isCurrentTrainer = trainerInvitationAnswer.trainer_id == this.state.courseRecord!.teacher_id;
                                    if (isCurrentTrainer) style = { backgroundColor: "rgb(194, 242, 194)" }
                                    return <>
                                        <tr id={"trainer_invitation_" + index} key={index} style={style}>
                                            <td>
                                                <span>
                                                    {trainerInvitationAnswer.trainer_fullname}<br />
                                                    <small><a href={"mailto:" + trainerInvitationAnswer.trainer_email}>{trainerInvitationAnswer.trainer_email}</a></small>
                                                </span>
                                            </td>
                                            <td>
                                                <p>{trainerInvitationAnswer.accept ? <span style={{ color: "#348034" }}><strong>{__("Időpont megfelelő (képző kiválasztása folyamatban)")}</strong></span> : trainerInvitationAnswer.accept == false ? <span style={{ color: "#b30000" }}>{__("Elutasítva")}</span> : <span style={{ color: "#666" }}>{__("Válaszra vár")}</span>}</p>
                                                <button className={"button " + trainerInvitationAnswer.message_cnt ? "primary" : "secondary"} title={__("Üzenetek")}
                                                    onClick={() => { this.fetchCommentsFor(trainerInvitationAnswer.id!) }}
                                                >
                                                    <i className="fa fa-comment" /> <b>{trainerInvitationAnswer.message_cnt}</b>
                                                </button>
                                            </td>
                                            <td>
                                                <p>{trainerInvitationAnswer.accept && !isCurrentTrainer ? <button className="button small" onClick={this.onSelectTrainer.bind(this, trainerInvitationAnswer.trainer_id)} >{__("Kiválasztás")}</button> : ""}</p>
                                            </td>
                                        </tr>
                                        {trainerInvitationAnswer.id === this.state.showCommentsForAnswerId &&
                                            <tr id={"trainer_invitation_comments" + index} key={"comments_" + index}>
                                                <td colSpan={3}>
                                                    {this.showCommentsTable()}
                                                </td>
                                            </tr>

                                        }
                                    </>
                                })
                                : null
                        }
                    </tbody>
                </table>
            </div>
        } else {
            return <div></div>;
        }
    }

    private onGetAllPossibleRecipientIds = async (): Promise<number[]> => {
        const course = this.state.courseRecord;
        if (!course || !me) { return []; }
        const result: number[] = [];
        if (course.teacher_id && course.teacher_id !== me.id) {
            result.push(course.teacher_id);
        }
        try {
            const records = await ViewKapCourseUser.list({
                filter: { course_id: this.state.courseRecord!.id, is_active: true },
                columns: ["sec_user_id"]
            });
            records.forEach((record: IViewKapCourseUserRecord) => {
                const participantId = record.sec_user_id!;
                if (participantId !== me!.id && !result.includes(participantId)) {
                    result.push(participantId);
                }
            });
            return Promise.resolve(result);
        } catch (e) {
            app.showErrorFromJsonResult(e);
            return Promise.reject(e);
        }
    }

    private schoolInvitations() {
        const items = this.state.schoolInvitations;

        return <div className="row">
            <div className="large-12 columns">
                <label className="profile_form_separator">{__("Iskola meghívók (értesítők)")}</label>
                {items
                    ?<table>
                        <thead>
                            <th>{__("Mikor")}</th>
                            <th>{__("Ki")}</th>
                            <th>{__("Címzettek")}</th>
                            <th>{__("Várakozik/Feldolgozva")}</th>
                        </thead>
                        <tbody>
                            {items.map(item => <tr>
                                <td>{item.creation_time}</td>
                                <td>{item.creator}</td>
                                <td>(TODO)</td>
                                <td>X/Y</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    :__("Még nem lett kiküldve értesítő")
                }
                <button className="button primary" onClick={this.onSendNewSchoolInvitation.bind(this)}
                    disabled={this.state.schoolInvitationSending}
                >
                    <i className="fa fa-plus"></i>{__("Új értesítő kiküldése")}
                </button>
            </div>
        </div>
    }

    render() {
        const course = this.state.courseRecord;

        if (!course) return <BubbleLoader />;
        /*  const breadcrumbs = <ExerciseSeriesBreadcrumbs links={[
              { name: this.state.exerciseSeriesRecord ? this.state.exerciseSeriesRecord.title : <span style={{ color: "red" }}>{__("Betöltés...")}</span> }
          ]} />;*/

        if (!me || (!hasAnyGroup(me, [Groups.KapKszrCourseCoordinator, Groups.Developer, Groups.Admin]))) {
            return AccessDeniedPage();
        }

        let isInvitationActive = (this.state.currentTrainerInvitation && this.state.currentTrainerInvitation.wf_station_id == WF_STATION_ACTIVE_INVITATION_PERIOD);

        return (<PermissionPage requirements={{
            crud: { tableInfoId: CourseCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U }
        }}>
            <div className="row expanded">
                {course.id ?
                    <WorkflowDialog
                        open={this.state.workflowDialogOpen}
                        displayName={__("Kurzus")}
                        tableInfoId={CourseCrud.TABLE_INFO_ID}
                        recId={course.id!}
                        onClose={() => this.setState({ workflowDialogOpen: false })}
                        onTransition={this.asyncReload.bind(this)}
                        autoPublishOnClosedStation={true}
                    /> : ""
                }
                <div className="column small-12">
                    <Breadcrumbs links={[
                        { name: __("KSZR"), path: courseModule.PATH_COURSE_ORGANIZER },
                        { name: __("Kurzus adminisztráció"), path: courseModule.PATH_COURSE_ORGANIZER_COURSE_ADMIN_LIST },
                        { name: __("Szerkesztés") },

                    ]} />
                </div>
                <div className="column" style={{ alignSelf: "center" }}>
                    <ButtonGroup size={ButtonSize.Normal} align={ButtonAlign.Expanded}>

                        <button className="button success expanded eke-general-buttons" onClick={this.onSave.bind(this, false)} disabled={(!this.state.isDataChanged)}>
                            <i className="fa fa-save" /> {course.id ? __("Módosítások mentése") : __("Létrehozás")}
                        </button>

                        {
                            course.id &&
                            <button className="button" onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}>
                                <i className="fa fa-project-diagram" />&nbsp; {__("Folyamat")}  <label className="label" style={{ alignSelf: "center", marginLeft: "0.5rem" }}>{__("Állapot")}: {this.state.workflowStateName}</label>
                            </button>
                        }

                        {
                            course.id &&
                            <button className="button success expanded eke-general-buttons" onClick={this.onSave.bind(this, true)}>
                                <i className="fa fa-copy" /> {__("Másolat készítése")}
                            </button>
                        }

                    </ButtonGroup>
                </div>

                <div className="small-12 large-12 column clearfix">
                    <div className="row">
                        <div className="column large-12 medium-12 small-12">
                            <h4>{__("Kurzus szerkesztése") + (course.regno ? " (" + course.regno + ")" : "")}</h4>
                        </div>
                        <div className="column large-12 medium-12 small-12" ref="course_data_card" id="course_data_card">
                            <Accordion allowAllClosed={true}>
                                <AccordionItem defaultClosed={false} title={<span><i className="fa fa-graduation-cap" /> {__("Alapadatok")}</span>}>
                                    <div className="row" >

                                        <div className="large-12 small-12 columns">
                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label>{__("Kurzus címe")}<span style={{ color: "#b30000" }}>*</span>
                                                        <span className="exe-editor-validation-msg">{this.state.validationMessages.get("title")}</span>
                                                        <input type="text"
                                                            value={course.title || undefined}
                                                            onChange={(e) => this.onCouseFieldChange(e, "title")} />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label>{__("Típus")} <span style={{ color: "#b30000" }}>*</span>
                                                        {this.state.isOnline && <span>{__("(Távoktatás)")}</span>}
                                                        <span className="exe-editor-validation-msg">{this.state.validationMessages.get("training_type_id")}</span>
                                                        <LookupEdit
                                                            key={"trainingType_" + (course.training_type_id || 'null')}
                                                            fk_table_info_id={TrainingTypeCrud.TABLE_INFO_ID}
                                                            clearable={true}
                                                            isDisabled={this.getMatchParam("recId") != null && this.getMatchParam("recId") != "null"}
                                                            valueColumn="id"
                                                            value={course.training_type_id || null}
                                                            selectColumnNames={["training_type_id", "title", "no"]}
                                                            displayColumnNames={["title"]}
                                                            searchColumnNames={["title"]}
                                                            orderByColumnNames={"no"}
                                                            emptyLoad={true}
                                                            onChange={(newValue) => this.onTrainingTypeChanged(Number(newValue))}
                                                        />
                                                    </label>
                                                </div>
                                            </div>

                                            {/* <div className="row">
                                                <div className="large-12 columns">
                                                    <label>{__("Név")}<span style={{ color: "#b30000" }}>*</span>
                                                        <span className="exe-editor-validation-msg">{this.state.validationMessages.get("title")}</span>
                                                        <input type="text"
                                                            value={course.title || ""}
                                                            onChange={(e) => this.onCouseFieldChange(e, "title")} />
                                                    </label>
                                                </div>
                                            </div> */}

                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label>{__("Maximális résztvevő szám")}<span style={{ color: "#b30000" }}>*</span>
                                                        <span className="exe-editor-validation-msg">{this.state.validationMessages.get("max_participants")}</span>
                                                        <input type="number" data-type="number"
                                                            min="0" max="50"
                                                            value={course.max_participants || 0}
                                                            onChange={(e) => this.onCouseFieldChange(e, "max_participants")} />
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label>{__("Megjegyzés oktatóknak")} </label>
                                                    <HTMLTextarea
                                                        value={course.teacher_comment_html || ""}
                                                        onChange={this.handleDescriptionChange.bind(this, "teacher_comment_html")}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label>{__("Megjegyzés résztvevőknek")} </label>
                                                    <HTMLTextarea
                                                        value={course.participants_comment_html || ""}
                                                        onChange={this.handleDescriptionChange.bind(this, "participants_comment_html")}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label className="profile_form_separator">{__("Feladatellátási helyek")}</label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label>{__("Fő feladatellátási hely")} <span style={{ color: "#b30000" }}>*</span>
                                                        <span className="exe-editor-validation-msg">{this.state.validationMessages.get("institute_site_id")}</span>
                                                        <LookupEdit
                                                            key={"institute_site_id_" + (course.institute_site_id || 'null')}
                                                            fk_table_info_id={InstituteSiteCrud.TABLE_INFO_ID}
                                                            clearable={true}
                                                            valueColumn="id"
                                                            value={course.institute_site_id}
                                                            selectColumnNames={["institute_site_id", "name"]}
                                                            displayColumnNames={["name"]}
                                                            searchColumnNames={["name"]}
                                                            distinct={true}
                                                            onChange={(newValue) => this.onCourseFieldChangeWithValue(Number(newValue), "institute_site_id")}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label>{__("Helyszín címe")}{!this.state.isOnline && <span style={{ color: "#b30000" }}>*</span>}
                                                        <span className="exe-editor-validation-msg">{this.state.validationMessages.get("address")}</span>
                                                        <input type="text"
                                                            value={course.address || ""}
                                                            onChange={(e) => this.onCouseFieldChange(e, "address")} />
                                                    </label>
                                                </div>
                                            </div>

                                            <div> <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>{__("Elsődleges")}</th>
                                                        <th>{__("Név")}</th>
                                                        <th>{__("Művelet")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.courseInstituteSites.map((courseInstituteSite: IMinimalCourseInstituteSiteRecord, index: number) => {
                                                            if (courseInstituteSite.is_active) {
                                                                const prefix = "" + index + (courseInstituteSite.id || "_");
                                                                return <tr id={"sites_" + prefix} key={prefix}>
                                                                    <td key={"curr_site_internal_" + prefix}>
                                                                        <input type="checkbox"
                                                                            checked={courseInstituteSite!.is_internal === true}
                                                                            onChange={(e) => this.onSiteChange(e, index)}
                                                                        />
                                                                    </td>
                                                                    <td key={"curr_site_" + prefix}>
                                                                        <span>{courseInstituteSite.om_identifier}/{courseInstituteSite.site_identifier} {courseInstituteSite.site_name} </span>
                                                                    </td>
                                                                    <td key={"site_button_" + prefix}>
                                                                        <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={(e) => this.onDeleteSite(index)}><i className="fa fa-trash"></i></button>
                                                                    </td>
                                                                </tr>;
                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                                <label>{__("Új hozzáadása")} <span style={{ color: "#b30000" }}></span>
                                                    <LookupEdit
                                                        fk_table_info_id={InstituteSiteCrud.TABLE_INFO_ID}
                                                        clearable={true}
                                                        valueColumn="id"
                                                        value={this.state.tempSiteId}
                                                        selectColumnNames={["institute_site_id", "name"]}
                                                        displayColumnNames={["name"]}
                                                        searchColumnNames={["name"]}
                                                        distinct={true}
                                                        onChange={(newValue) => this.onTempSiteChanged(Number(newValue))}
                                                    />
                                                </label>
                                                <button className="button small" onClick={this.onAddNewSite.bind(this)}><i className="fa fa-plus"></i></button>
                                                <span className="exe-editor-validation-msg">{this.state.validationMessages.get("sites")}</span>
                                            </div>

                                            {this.schoolInvitations()}

                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label className="profile_form_separator">{__("Határidők")}</label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-6 medium-6 small-12 columns">
                                                    <label>{__("Továbbképzés kezdete")}<span style={{ color: "#b30000" }}>*</span>
                                                        <span className="exe-editor-validation-msg">{this.state.validationMessages.get("training_from")}</span>
                                                        <input type="date" value={course.training_from ? course.training_from.substr(0, 10) : ""}
                                                            onChange={(e) => this.onCouseFieldChange(e, "training_from")}
                                                        />
                                                    </label>
                                                </div>
                                                <div className="large-6 medium-6 small-12 columns">
                                                    <label>{__("Továbbképzés vége")}<span style={{ color: "#b30000" }}>*</span>
                                                        <span className="exe-editor-validation-msg">{this.state.validationMessages.get("training_to")}</span>
                                                        <input type="date" value={course.training_to ? course.training_to.substr(0, 10) : ""}
                                                            onChange={(e) => this.onCouseFieldChange(e, "training_to")}
                                                        />
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-6 medium-6 small-12 columns">
                                                    <label>{__("Intézményen belüli jelentkezés kezdete")}<span style={{ color: "#b30000" }}>*</span>
                                                        <span className="exe-editor-validation-msg">{this.state.validationMessages.get("apply_after_internal")}</span>
                                                        <DateTimePicker
                                                            key={"apply_after_internal"}
                                                            value={course.apply_after_internal}
                                                            onChange={(date, time) => this.onCouseFieldChange({ target: { value: (date + " " + time) } }, "apply_after_internal")}
                                                        />
                                                    </label>
                                                </div>
                                                <div className="large-6 medium-6 small-12 columns">
                                                    <label>{__("Intézményen kívüli jelentkezés kezdete")}<span style={{ color: "#b30000" }}>*</span>
                                                        <span className="exe-editor-validation-msg">{this.state.validationMessages.get("apply_after_external")}</span>
                                                        <DateTimePicker
                                                            key={"apply_after_external"}
                                                            value={course.apply_after_external}
                                                            onChange={(date, time) => this.onCouseFieldChange({ target: { value: (date + " " + time) } }, "apply_after_external")}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="large-6 medium-6 small-12 columns">
                                                    <label>{__("Jelentkezési határidő")}<span style={{ color: "#b30000" }}>*</span>
                                                        <span className="exe-editor-validation-msg">{this.state.validationMessages.get("application_deadline")}</span>
                                                        <DateTimePicker
                                                            key={"application_deadline"}
                                                            value={course.application_deadline}
                                                            onChange={(date, time) => this.onCouseFieldChange({ target: { value: (date + " " + time) } }, "application_deadline")}
                                                        />
                                                    </label>
                                                </div>
                                            </div>

                                            {!this.state.isOnline && <>
                                                <div className="row">
                                                    <div className="large-6 medium-6 small-12 columns">
                                                        <label>{__("Távoktatás kezdési dátum")}<span style={{ color: "#b30000" }}>*</span>
                                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("distance_learning_start")}</span>
                                                            <input type="date" value={course.distance_learning_start ? course.distance_learning_start.substr(0, 10) : ""}
                                                                onChange={(e) => this.onCouseFieldChange(e, "distance_learning_start")}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div className="large-6 medium-6 small-12 columns">
                                                        <label>{__("Távoktatás befejezési dátum")}<span style={{ color: "#b30000" }}>*</span>
                                                            <span className="exe-editor-validation-msg">{this.state.validationMessages.get("distance_learning_end")}</span>
                                                            <input type="date" value={course.distance_learning_end ? course.distance_learning_end.substr(0, 10) : ""}
                                                                onChange={(e) => this.onCouseFieldChange(e, "distance_learning_end")}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="large-12 columns">
                                                        <label className="profile_form_separator">{__("Kontakt napok")}<span style={{ color: "#b30000" }}>*</span></label>
                                                    </div>
                                                </div>


                                                <div> <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>{__("Hivatalos időpont kezdete")}</th>
                                                            <th>{__("Hivatalos időpont vége")}</th>
                                                            <th>{__("Valós időpont kezdete")}</th>
                                                            <th>{__("Valós időpont vége")}</th>
                                                            <th>{__("Helyszín")}</th>
                                                            <th>{__("Művelet")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            this.state.contactDates.map((contactDate, index) => {
                                                                const prefix = "" + index + (contactDate.id || "_");
                                                                let baseDate = contactDate.official_start ? contactDate.official_start.substr(0, 11) : "";
                                                                return <tr id={"contact_day_" + prefix} key={prefix}>
                                                                    <td> <DateTimePicker
                                                                        key={"cd_official_start_" + prefix}
                                                                        value={contactDate.official_start}
                                                                        onChange={(date, time) => this.onContactDateChange({ target: { value: (date + " " + time) } }, index, "official_start")}
                                                                    />
                                                                    </td>
                                                                    <td> <DateTimePicker
                                                                        visibled={DateTimeGeneralType.time}
                                                                        key={"cd_official_end_" + prefix}
                                                                        value={contactDate.official_end || undefined}
                                                                        onChange={(date, time) => this.onContactDateChange({ target: { value: (baseDate + " " + time) } }, index, "official_end")}
                                                                    />
                                                                    </td>
                                                                    <td> <DateTimePicker
                                                                        visibled={DateTimeGeneralType.time}
                                                                        key={"cd_real_start_" + prefix}
                                                                        value={contactDate.real_start}
                                                                        onChange={(date, time) => this.onContactDateChange({ target: { value: (baseDate + " " + time) } }, index, "real_start")}
                                                                    />
                                                                    </td>
                                                                    <td> <DateTimePicker
                                                                        visibled={DateTimeGeneralType.time}
                                                                        key={"cd_real_end_" + prefix}
                                                                        value={contactDate.real_end || undefined}
                                                                        onChange={(date, time) => this.onContactDateChange({ target: { value: (baseDate + " " + time) } }, index, "real_end")}
                                                                    />
                                                                    </td>
                                                                    <td key={"contact_day_address_" + prefix}>
                                                                        <input className="small-6 columns" type="text" value={contactDate.address || ""} onChange={(e) => this.onContactDateChange(e, index, "address")} />
                                                                    </td>
                                                                    <td key={"contact_day_button_" + prefix}>
                                                                        <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={(e) => this.onDeleteContactDate(e, index)}><i className="fa fa-trash"></i></button>
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                    <button className="button small" onClick={this.onNewContactDate.bind(this)}><i className="fa fa-plus"></i></button>
                                                    <span className="exe-editor-validation-msg">{this.state.validationMessages.get("contactDates")}</span>
                                                </div>
                                            </>}
                                            <div className="row">
                                                <div className="large-12 columns">
                                                    <label className="profile_form_separator">{__("Beadandó")}</label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="large-12 small-12 columns">
                                                    <label>{__("Beadandó határidő")}<span style={{ color: "#b30000" }}>*</span>
                                                        <span className="exe-editor-validation-msg">{this.state.validationMessages.get("submission_deadline")}</span>
                                                        <DateTimePicker
                                                            key={"course_submission_edadline"}
                                                            value={course.submission_deadline}
                                                            onChange={(date, time) => this.onCouseFieldChange({ target: { value: (date + " " + time) } }, "submission_deadline")}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </AccordionItem>

                                <AccordionItem defaultClosed={true} title={<span><i className="fa-fw fas fa-chalkboard-teacher" /> {__("Képző kiválasztása")}</span>}>
                                    {this.state.courseRecord && this.state.courseRecord.id ?
                                        <div className="row">
                                            <div className="large-12 small-12 columns">
                                                <label>{__("Kiválasztott képző")}
                                                    <LookupEdit
                                                        key="trainer_id"
                                                        fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                                                        viewClassProxy={viewKapKszrTrainerClassProxy}
                                                        clearable={true}
                                                        valueColumn="id"
                                                        value={course.teacher_id || null}
                                                        selectColumnNames={["id", "email", "fullname"]}
                                                        displayColumnNames={["fullname", "email"]}
                                                        searchColumnNames={["fullname", "email"]}
                                                        emptyLoad={true}
                                                        onChange={this.onSetTrainerId.bind(this)}
                                                    />

                                                </label>
                                            </div>
                                            <div className="large-12 small-12 columns">
                                                <label>{__("Meghívó határidő")}
                                                    <span className="exe-editor-validation-msg">{this.state.validationMessages.get("invitationDeadline")}</span>
                                                    <DateTimePicker
                                                        key={"invitationDeadline"}
                                                        value={this.state.invitationDeadline}
                                                        onChange={(date, time) => this.onInvitationDeadlineChange(date + " " + time)}
                                                    />
                                                </label>
                                                <div>
                                                    {!isInvitationActive ?
                                                        <button className={"button small warning eke-general-buttons"}
                                                            onClick={this.onInviteTrainers.bind(this)} >
                                                            <i className="fa fa-paper-plane" /> {__("Képzők meghívása")}
                                                        </button>
                                                        :
                                                        <button className={"button small warning eke-general-buttons"}
                                                            onClick={this.closeInvitationPeriod.bind(this, true)} >
                                                            <i className="fas fa-times" /> {__("Meghívások lezárása")}
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                            {this.invitationsTable(!isInvitationActive)}
                                            <div className="large-12 small-12 columns">
                                                <label>{__("Ellenőr")}
                                                    <LookupEdit
                                                        key="oh_inspector_id"
                                                        fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                                                        viewClassProxy={viewOhInspectorClassProxy}
                                                        clearable={true}
                                                        valueColumn="id"
                                                        value={course.oh_inspector_id || null}
                                                        selectColumnNames={["id", "email", "fullname"]}
                                                        displayColumnNames={["fullname", "email"]}
                                                        searchColumnNames={["fullname", "email"]}
                                                        emptyLoad={true}
                                                        onChange={(value: number | null, action: any) => { this.onCourseFieldChangeWithValue(value, "oh_inspector_id") }}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        : <div className="row"><span>{__("Képző kiválasztáshoz először mentse el a kurzust!")}</span></div>

                                    }
                                </AccordionItem>
                                {this.state.courseRecord && this.state.courseRecord.id && <>
                                    <AccordionItem defaultClosed={true} title={<span><i className="fa fa-users" /> {__("Résztvevő hallgatók")}</span>}>
                                        <div className="row">
                                            <CourseStudentList ref={(r) => this.curseStudentList = r} currentCourse={this.state.courseRecord} editable={true} contactDays={this.state.contactDates} />
                                        </div>
                                    </AccordionItem>
                                    <AccordionItem defaultClosed={true} title={<span><i className="fas fa-folder-open" /> {__("Dokumentumok")}</span>}>
                                        <div className="row" >
                                            <CourseDocumentComponent currentCourse={this.state.courseRecord} contactDates={this.state.contactDates} />
                                        </div>
                                    </AccordionItem>
                                    <AccordionItem defaultClosed={true} title={<span><i className="far fa-calendar-check" /> {__("Mappa állapota")}</span>}>
                                        <CourseChecklist isReadOnly={false} courseRecord={this.state.courseRecord} />

                                    </AccordionItem>
                                    {/*(hasGroup(me, Groups.Developer) || me.id == this.state.courseRecord.teacher_id)  && <AccordionItem defaultClosed={true} title={<span><i className="fa fa-chart-bar" /> {__("Kurzus statisztikák")}</span>}>
                                        <CourseStats courseRec={this.state.courseRecord} />
                                    </AccordionItem>
                                */}
                                </>}

                            </Accordion>

                        </div>
                    </div>

                </div>
            </div>
        </PermissionPage>);
    }
}
