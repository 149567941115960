import * as React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FileBaseData from '@src/component/filemanager/data/FileBaseData';
import { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import FileWfEditor from '@src/component/filemanager/FileWfEditor';
import FileMetaCategorization from '@src/component/filemanager/data/FileMetaCategorization';
import { MetaCategorization } from '@src/component/meta/meta_category_assigner_api';
import { FilePreview } from '@src/component/filemanager/data/FilePreview';
import { FileBookReferences } from '@src/component/filemanager/data/FileBookReferences';
import FileVersions from '@src/component/filemanager/data/FileVersions';
import FileDublinCoreEditor from '@src/component/filemanager/data/FileDublinCoreEditor';
import { hasGroup, Groups, me } from '@src/framework/server/Auth';
import { __ } from '@src/translation';

type FileDataTabsProps = {
    file: IOoFileRecord;
    onClose: () => void;
    onFileVersionRestored?: (newFile: IOoFileRecord) => void;
    allowRestoreVersion : boolean;
    allowModifications: boolean;
}

export class FileDataTabs extends React.Component<FileDataTabsProps, {}> {
    constructor (props: FileDataTabsProps) {
        super(props);
    }

    private onFileVersionRestored = (newFile: IOoFileRecord) => {
        if (this.props.onFileVersionRestored) {
            this.props.onFileVersionRestored(newFile);
        }
    }

    render() {
        return <div className="fileManagerModalContent">
            <Tabs>
                <TabList>
                    {this.props.allowModifications && <Tab>{__("Alapadatok")}</Tab>}
                    
                    <Tab>{__("Előnézet")}</Tab>

                    {this.props.allowModifications && <Tab>{__("Verziók")}</Tab>}
                    {this.props.allowModifications && <Tab>{__("Meta kategóriák")}</Tab>}
                    {this.props.allowModifications && <Tab>{__("Dublin Core")}</Tab>}

                    {
                        this.props.allowModifications && me && hasGroup(me, Groups.OFIEditor) &&
                        <>
                            <Tab>{__("Felhasználva tankönyvben")}</Tab>
                            <Tab>{__("Folyamat")}</Tab>
                        </>
                    }
                    
                </TabList>
                {this.props.allowModifications && 
                    <TabPanel forceRender={true}>
                        <FileBaseData
                            file={this.props.file}
                            onCloseDialog={this.props.onClose}
                            onFileSaved={(file: IOoFileRecord, keywords: string[]) => { this.setState({ file }) }}
                        />
                    </TabPanel>
                }
                <TabPanel>
                    <FilePreview
                            file={this.props.file}
                        />
                </TabPanel>

                {this.props.allowModifications && 
                    <TabPanel>
                        <FileVersions ooFileId={this.props.file.id}
                            onFileVersionRestored={this.onFileVersionRestored}
                            allowRestoreVersion={this.props.allowRestoreVersion}
                        />
                    </TabPanel>
                }

                {this.props.allowModifications && 
                    <TabPanel forceRender={true}>
                        <FileMetaCategorization
                                file={this.props.file}
                                onCloseDialog={this.props.onClose}
                                onFileSaved={(file: IOoFileRecord, categorization: MetaCategorization) => { this.setState({ file }) }}
                            />
                    </TabPanel>
                }

                {this.props.allowModifications && 
                    <TabPanel forceRender={true}>
                        <FileDublinCoreEditor
                                file={this.props.file}
                                onCloseDialog={this.props.onClose}
                                onFileSaved={(file: IOoFileRecord) => { this.setState({ file }) }}
                            />
                    </TabPanel>
                }
                {
                        me && hasGroup(me, Groups.OFIEditor) &&
                    <>      
                        <TabPanel>
                            <FileBookReferences
                                    file={this.props.file}
                                />
                        </TabPanel>       
                        <TabPanel>
                            <FileWfEditor
                                file={this.props.file}
                                onCloseDialog={this.props.onClose}
                            />
                        </TabPanel>
                    </>
                }
            </Tabs>
        </div>;
    }

}
