import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSecTransitionPermRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Folyamat átmenet típus
     */
    transition_type_id?: number;
    /**
     * Engedély birtokos
     */
    sec_perm_owner_id?: number;
    /**
     * Feladat
     *
     * Ha ez hamisra van állítva, akkor nem okozza a folyamat megjelenését a tennivalók listában.
     */
    is_task?: boolean;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Folyamat típus azonosító
     *
     * Folyamat típus azonosító
     */
    type_id?: number|null;
    /**
     * Forrás állapot név
     *
     * Forrás állapot név
     */
    src_station_name?: string|null;
    /**
     * Forrás állapot leírás
     *
     * Forrás állapot leírás
     */
    src_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Forrás állapot stílus (CSS JSON)
     */
    src_style?: any|null;
    /**
     * Cél állapot név
     *
     * Cél állapot név
     */
    dst_station_name?: string|null;
    /**
     * Cél állapot leírás
     *
     * Cél állapot leírás
     */
    dst_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Cél állapot stílus (CSS JSON)
     */
    dst_style?: any|null;
    /**
     * Felhasználó
     *
     * Felhasználó, aki jogosult látni az ilyen állapotú folyamatokat
     */
    user_id?: number|null;
    /**
     * Felhasználó
     *
     * Felhasználó, aki jogosult látni az ilyen állapotú folyamatokat
     */
    login_name?: string|null;
    /**
     * Személy
     *
     * Aki jogosult látni az ilyen állapotú folyamatokat
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * A jogosult természetes személy neve
     */
    fullname?: string|null;
    /**
     * Csoport név
     *
     * A csoport neve
     */
    group_title?: string|null;
    /**
     * Csoport típus
     *
     * A csoport típus azonosítója
     */
    sec_group_type_id?: number|null;
    /**
     * Csoport típus
     *
     * A csoport típus neve
     */
    sec_group_type_title?: string|null;
    /**
     * A jogosult neve
     *
     * A csoport vagy felhasználó humán azonosítására szolgáló szöveg
     */
    perm_owner_label?: string|null;
}

/**
 *  wf.view_sec_transition_perm - Folyamat átmenet jog
 *
 *  Azt mondja meg, hogy kik végezhetnek el egy átmenet típust.
 */

export default class ViewSecTransitionPerm extends View<IViewSecTransitionPermRecord> {
    public static VIEW_INFO_ID = 2018050404;

    public static getSchemaNameForClass(): string { return 'wf'; }
    public static getViewNameForClass(): string { return 'view_sec_transition_perm'; }
    public static getTableNameForClass(): string|null { return 'sec_transition_perm'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSecTransitionPerm>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSecTransitionPermRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSecTransitionPermClassProxy extends ViewClassProxy<IViewSecTransitionPermRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSecTransitionPermClassProxy = new ViewSecTransitionPermClassProxy(ViewSecTransitionPerm);
registerViewClassProxy(viewSecTransitionPermClassProxy);

