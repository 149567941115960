import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapBeadandoSummaryStatsRecord {
    /**
     * Fake id
     *
     * Fake id
     */
    id?: number /*int4*/;
    /**
     * Feltöltve (év)
     *
     * Feltöltve (év)
     */
    feltoltve_ev?: number /* float8 */|null;
    /**
     * Feltöltve (hó)
     *
     * Feltöltve (hó)
     */
    feltoltve_ho?: number /* float8 */|null;
    /**
     * OM azonosító
     *
     * OM azonosító
     */
    om_azonosit?: string|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    intezmeny_nev?: string|null;
    /**
     * Ellátási hely azonosító
     *
     * Ellátási hely azonosító
     */
    ellatasi_hely_azonosito?: string|null;
    /**
     * Ellátási hely név
     *
     * Ellátási hely név
     */
    ellatasi_hely_nev?: string|null;
    /**
     * Állapot
     *
     * Állapot
     */
    allapot?: string|null;
    /**
     * Lezárva
     *
     * Lezárva
     */
    lezarva?: boolean|null;
    /**
     * Darabszám
     *
     * Darabszám
     */
    darabszam?: number|null;
}

/**
 *  kap.view_kap_beadando_summary_stats - DFHT-KIP beadandó
 *
 *  DFHT-KIP beadandó
 */

export default class ViewKapBeadandoSummaryStats extends View<IViewKapBeadandoSummaryStatsRecord> {
    public static VIEW_INFO_ID = 2020013102;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_beadando_summary_stats'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapBeadandoSummaryStats>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapBeadandoSummaryStatsRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapBeadandoSummaryStatsClassProxy extends ViewClassProxy<IViewKapBeadandoSummaryStatsRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapBeadandoSummaryStatsClassProxy = new ViewKapBeadandoSummaryStatsClassProxy(ViewKapBeadandoSummaryStats);
registerViewClassProxy(viewKapBeadandoSummaryStatsClassProxy);

