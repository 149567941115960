import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewOccupationVisitAspectAnswerRecord {
    /**
     * Foglalkozás látogatás azonosító
     *
     * Foglalkozás látogatás azonosító
     */
    id?: number;
    /**
     * Látogatás kezdete
     *
     * Látogatás kezdete
     */
    min_start_date?: string /*timestamp?*/|null;
    /**
     * Feladat ellátási hely neve
     *
     * Feladat ellátási hely neve
     */
    institute_site_name?: string|null;
    /**
     * Ellátási hely azonosító
     *
     * Ellátási hely azonosító
     */
    site_identifier?: string|null;
    /**
     * Intézmény azonosító
     *
     * Intézmény azonosító
     */
    institute_id?: number|null;
    /**
     * Intézmény OM azonosító
     *
     * Intézmény OM azonosító
     */
    om_identifier?: string|null;
    /**
     * Intézmény neve
     *
     * Intézmény neve
     */
    institute_name?: string|null;
    /**
     * Tanár felhasználó azonosító
     *
     * Tanár felhasználó azonosító
     */
    teacher_id?: number|null;
    /**
     * Tanár személy azonosító
     *
     * Tanár személy azonosító
     */
    teacher_person_id?: number|null;
    /**
     * Tanár teljes neve
     *
     * Tanár teljes neve
     */
    teacher_fullname?: string|null;
    /**
     * Támogató felhasználó azonosító
     *
     * Támogató felhasználó azonosító
     */
    kap_supporter_id?: number|null;
    /**
     * Támogató személy azonosító
     *
     * Támogató személy azonosító
     */
    supporter_person_id?: number|null;
    /**
     * Támogató teljes neve
     *
     * Támogató teljes neve
     */
    supporter_fullname?: string|null;
    /**
     * Sablon azonosítója
     *
     * Sablon azonosítója
     */
    subprogram_aspect_id?: number|null;
    /**
     * Sablon megnevezése
     *
     * Sablon megnevezése
     */
    subprogram_aspect_title?: string|null;
    /**
     * Alprogram azonosítója
     *
     * Alprogram azonosítója
     */
    subprogram_id?: number|null;
    /**
     * Alprogram kódja
     *
     * Alprogram kódja
     */
    subprogram_code?: string|null;
    /**
     * Alprogram neve
     *
     * Alprogram neve
     */
    subprogram_title?: string|null;
    /**
     * Válasz azonoítója
     *
     * Válasz azonoítója
     */
    subprogram_aspect_answer_id?: number|null;
    /**
     * Válasz érték azonosítója
     *
     * Válasz érték azonosítója
     */
    subprogram_aspect_value_id?: number|null;
    /**
     * Válasz érték neve
     *
     * Válasz érték neve
     */
    subprogram_aspect_value_title?: string|null;
    /**
     * Válaszhoz tartozó megjegyzés
     *
     * Válaszhoz tartozó megjegyzés
     */
    subprogram_aspect_answer_comments?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Folyamat állapot név
     *
     * Folyamat állapot név
     */
    wf_station_name?: string|null;
}

/**
 *  kap.view_occupation_visit_aspect_answer - Alprogrami szempontsorokra adott válaszok
 *
 *  Alprogrami szempontsorokra adott válaszok
 */

export default class ViewOccupationVisitAspectAnswer extends View<IViewOccupationVisitAspectAnswerRecord> {
    public static VIEW_INFO_ID = 2019122301;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_occupation_visit_aspect_answer'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewOccupationVisitAspectAnswer>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewOccupationVisitAspectAnswerRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewOccupationVisitAspectAnswerClassProxy extends ViewClassProxy<IViewOccupationVisitAspectAnswerRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewOccupationVisitAspectAnswerClassProxy = new ViewOccupationVisitAspectAnswerClassProxy(ViewOccupationVisitAspectAnswer);
registerViewClassProxy(viewOccupationVisitAspectAnswerClassProxy);

