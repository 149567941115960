import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewTableInfoRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Név
     */
    schema_name?: string;
    /**
     * Táblázat név (adatbázisban)
     */
    table_name?: string;
    /**
     * Idősoros tárolás
     */
    is_journaled?: boolean;
    /**
     * Logikai törlés
     */
    is_logdel?: boolean;
    /**
     * Globálisan egyedi azonosító
     */
    table_guid?: string;
    /**
     * Név
     *
     * A táblázat magyar nyelvű neve
     */
    display_name?: string|null;
    /**
     * Leírás
     *
     * A táblázat leírása
     */
    description?: string|null;
}

/**
 *  meta.view_table_info - Táblázat információ
 */

export default class ViewTableInfo extends View<IViewTableInfoRecord> {
    public static VIEW_INFO_ID = 2018050405;

    public static getSchemaNameForClass(): string { return 'meta'; }
    public static getViewNameForClass(): string { return 'view_table_info'; }
    public static getTableNameForClass(): string|null { return 'table_info'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewTableInfo>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewTableInfoRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewTableInfoClassProxy extends ViewClassProxy<IViewTableInfoRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewTableInfoClassProxy = new ViewTableInfoClassProxy(ViewTableInfo);
registerViewClassProxy(viewTableInfoClassProxy);

