import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, GetAllIndexes, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import * as React from "react";
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import { element } from "prop-types";

export type WordFinderExercise = {
    show_possible_words: boolean,
    dimensions: WordFinderDimensions,
    elements: ExerciseBaseTypes.AnswerElement[]
}
export type WordFinderDimensions = { rows: number, columns: number }

export class WordFinderGameConverter extends AExerciseTypeConverter {
    static MAX_DIMENSION_ROWS_NUMBER: number = 30;
    static MAX_DIMENSION_COLUMNS_NUMBER: number = 30;
    static MAX_ELEMENT_NUM: number = 15;
    static MAX_TEXT_LENGTH: number = 30;

    public convertToEditorAnswer(exercise: WordFinderExercise): WordFinderExercise | undefined {
        if (exercise) {
            let exe_elems: ExerciseBaseTypes.AnswerElement[] = [];
            let exe_dimensions = { rows: 0, columns: 0 };
            if (exercise.elements) {
                for (let i = 0; i < exercise.elements.length; i++) {
                    let curr_elem = exercise.elements[i];
                    if (curr_elem.type == "text") {
                        exe_elems.push({ type: ExerciseBaseTypes.AnswerTypes.text, text: curr_elem.text.toUpperCase(), image: "" });
                    }
                    else if (curr_elem.type == "sound") {
                        exe_elems.push({ type: ExerciseBaseTypes.AnswerTypes.sound, text: curr_elem.text.toUpperCase(), url: curr_elem.url, image: "" });
                    }
                    else if (curr_elem.type == "image") {
                        exe_elems.push({ type: ExerciseBaseTypes.AnswerTypes.image, text: curr_elem.text.toUpperCase(), image: curr_elem.image, url: curr_elem.image });
                    }
                }
            }

            if (exercise.dimensions) {
                exe_dimensions.rows = exercise.dimensions.rows;
                exe_dimensions.columns = exercise.dimensions.columns;
            }

            let answer: WordFinderExercise = {
                show_possible_words: exercise.show_possible_words,
                dimensions: exe_dimensions,
                elements: exe_elems
            };
            return answer;

        } else {
            return undefined;
        }
    }

    public convertToJson(exerciseDetails: WordFinderExercise, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: WordFinderExercise): WordFinderExercise | undefined {
        let cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
        let exe_elems: any[] = [];
        let exe_dimensions = { rows: 0, columns: 0 };

        if (exerciseDetails) {
            if (exerciseDetails.elements) {
                for (let i = 0; i < exerciseDetails.elements.length; i++) {
                    let curr_elem = exerciseDetails.elements[i];
                    if (curr_elem.type == ExerciseBaseTypes.AnswerTypes.text) {
                        exe_elems.push({ type: "text", text: curr_elem.text.toUpperCase(), image: "" });
                    }
                    else if (curr_elem.type == ExerciseBaseTypes.AnswerTypes.image) {
                        exe_elems.push({ type: "image", text: curr_elem.text.toUpperCase(), image: curr_elem.image });
                    }
                    else if (curr_elem.type == ExerciseBaseTypes.AnswerTypes.sound) {
                        exe_elems.push({ type: "sound", text: curr_elem.text.toUpperCase(), url: curr_elem.url });
                    }
                }
            }

            if (exerciseDetails.dimensions) {
                exe_dimensions.rows = exerciseDetails.dimensions.rows;
                exe_dimensions.columns = exerciseDetails.dimensions.columns;
            }

            let answer = {
                ...cur_exercise,
                show_possible_words: exerciseDetails.show_possible_words,
                dimensions: exe_dimensions,
                elements: exe_elems
            }
            return answer;

        }else{
            return cur_exercise;
        }
    }

    render() {
        let curr_ex: WordFinderExercise = this.state.exercise as WordFinderExercise;
        let longest = curr_ex.elements && curr_ex.elements.length > 0 ? curr_ex.elements.reduce((a, b) => { return a.text.length > b.text.length ? a : b }) : undefined;
        return (
            <Panel header={__("Részletek")} headingLevel={4} >
                <div className="row">
                    <div className="large-12 columns">
                        <label><input type="checkbox" name="show_possible_words" checked={curr_ex.show_possible_words || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Keresendő szavak mutatása")}</label>
                    </div>
                    <div className="large-6 columns">
                        <label>{__("Sorok száma")} <span className="exe-editor-validation-msg">{this.props.validationMessages.get("dimensions.rows")}</span></label>
                        <input type="number" min={curr_ex.elements ? curr_ex.elements.length : 1} max={WordFinderGameConverter.MAX_DIMENSION_ROWS_NUMBER} name="dimensions.rows"
                            value={curr_ex.dimensions ? curr_ex.dimensions.rows : 0}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                    </div>
                    <div className="large-6 columns">
                        <label>{__("Oszlopok száma")} <span className="exe-editor-validation-msg">{this.props.validationMessages.get("dimensions.columns")}</span></label>
                        <input type="number" min={longest ? longest.text.length : 0} max={WordFinderGameConverter.MAX_DIMENSION_COLUMNS_NUMBER} name="dimensions.columns"
                            value={curr_ex.dimensions ? curr_ex.dimensions.columns : 0}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                    </div>
                    <div className="large-12 columns">
                        <label>{__("A sorok számnak legalább annyinak kell lenni, mint az elemek száma, az oszlopok számának pedig legalább annyinnak, mint a leghosszabb szó hossza.")}</label>
                    </div>
                </div>
                <Panel header={__("Elemek")} headingLevel={5}>
                    {
                        curr_ex.elements ?
                            curr_ex.elements.map((curr_elem: ExerciseBaseTypes.AnswerElement, index) => {
                                let actions: IAccordionAction[];
                                actions = [
                                    {
                                        title: __("Törlés"),
                                        type: AccordionActionType.Trash,
                                        action: this.removeElement.bind(this, "elements", index)
                                    }
                                ];

                                return (
                                    <Panel key={"elements_" + index}>
                                            <legend>
                                                        <label className="exe-editor-fieldlabel-1">{__("{n}. Elem", { n: index + 1 })}</label>
                                                        <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "elements", index)}><i className="fa fa-trash"></i></button>                                                        
                                                    </legend>
                                                <div className="row">
                                                    <div className="large-6 columns">
                                                        <label> {__("Típus")}
                                                            <select value={curr_elem.type} name={"elements#" + index + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                                <option value={ExerciseBaseTypes.AnswerTypes.text}>{__("Szöveg")}</option>
                                                                <option value={ExerciseBaseTypes.AnswerTypes.image}>{__("Kép")}</option>
                                                                <option value={ExerciseBaseTypes.AnswerTypes.sound}>{__("Hang")}</option>
                                                            </select>
                                                        </label>
                                                    </div>
                                                    <div className="large-6 columns">
                                                        <label>{curr_elem.type == ExerciseBaseTypes.AnswerTypes.text ? __("Válasz") : __("Leírás")} <span className="exe-editor-validation-msg">{this.props.validationMessages.get("elements[" + index + "].text")}</span>
                                                            <input type="text" name={"elements#" + index + ".text"} data-parent-index={index} data-index={index} value={curr_elem.text}
                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="large-6 columns">
                                                        {curr_elem.type != ExerciseBaseTypes.AnswerTypes.text ?
                                                            <label className="exe-image-select-label">{curr_elem.type == ExerciseBaseTypes.AnswerTypes.image ? __("Kép") : __("Hang")} <span className="exe-editor-validation-msg">{this.props.validationMessages.get("elements[" + index + "].url")}</span>
                                                                {<ExerciseFileSelect
                                                                    imagebasepath={this.props.imagebasepath}
                                                                    value={curr_elem.url || ""}
                                                                    onChange={this.handleImageChange.bind(this, "elements#" + index + ".url")}
                                                                    getFolderId={this.getFolderId.bind(this)}
                                                                    fileType={curr_elem.type == ExerciseBaseTypes.AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                                />}
                                                            </label>
                                                            : ""}
                                                    </div>
                                                </div>
                                            </Panel>
                                );
                            }) : ""
                    }
                    <div className="row">
                        <button className="button small" onClick={this.onAddNewElement.bind(this)}><i className="fa fa-plus"></i> {__("Új szó hozzáadása")}</button>
                        {
                            <label className="exe-editor-label-description columns">{__("Maximum {max} elem megengedett.", { max: WordFinderGameConverter.MAX_ELEMENT_NUM })}</label>
                        }
                    </div>
                </Panel>
            </Panel>
        );
    }

    onAddNewElement() {
        let temp_exercise = this.state.exercise as WordFinderExercise;
        if (!temp_exercise.elements) temp_exercise.elements = [];
        temp_exercise.elements.push(this.getNewElement());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise })
    }

    getNewElement(): ExerciseBaseTypes.AnswerElement {
        let newElement: ExerciseBaseTypes.AnswerElement = {
            image: "",
            text: "",
            url:"",
            type: ExerciseBaseTypes.AnswerTypes.text
        };
        return newElement;
    }
    public validate(editorAnswer: WordFinderExercise, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {
        let errorMsg = "";
        if (editorAnswer && editorAnswer.elements) {

            if (editorAnswer.dimensions.rows > WordFinderGameConverter.MAX_DIMENSION_ROWS_NUMBER) {
                errorMsg = __("Maximum {rows} rows allowed!", { rows: WordFinderGameConverter.MAX_DIMENSION_ROWS_NUMBER });
                validationMap!.set("dimensions.rows", errorMsg);
                return { valid: false, message: errorMsg };
            }

            if (editorAnswer.dimensions.columns > WordFinderGameConverter.MAX_DIMENSION_COLUMNS_NUMBER) {
                errorMsg = __("Maximum {columns} columns allowed!", { columns: WordFinderGameConverter.MAX_DIMENSION_COLUMNS_NUMBER });
                validationMap!.set("dimensions.columns", errorMsg);
                return { valid: false, message: errorMsg };
            }

            for (let i = 0; i < editorAnswer.elements.length; i++) {
                let currElement = editorAnswer.elements[i];
                if (currElement.text == "" || currElement.text.length < 2) {
                    errorMsg = __("Lenght of word should be at least 2 characters");
                    validationMap!.set("elements[" + i + "].text", errorMsg);
                    return { valid: false, message: errorMsg };
                }

                if (/\s/.test(currElement.text)) {
                    errorMsg = __("No spaces allowed!");
                    validationMap!.set("elements[" + i + "].text", errorMsg);
                    return { valid: false, message: errorMsg };
                }

                if (currElement.type == ExerciseBaseTypes.AnswerTypes.image && currElement.url == "") {
                    errorMsg = __("Kép típusú válasznál hiányzó kép!");
                    validationMap!.set("elements[" + i + "].url", errorMsg);
                    return { valid: false, message: errorMsg };
                }

                if (currElement.type == ExerciseBaseTypes.AnswerTypes.sound && currElement.url == "") {
                    errorMsg = __("Hang típusú válasznál hiányzó hangfájl!");
                    validationMap!.set("elements[" + i + "].url", errorMsg);
                    return { valid: false, message: errorMsg };
                }

                if (currElement.text.length > WordFinderGameConverter.MAX_TEXT_LENGTH) {
                    errorMsg = __("A válaszelemek karakterszáma maximum {n} lehet!", { n: WordFinderGameConverter.MAX_TEXT_LENGTH });
                    validationMap!.set("elements[" + i + "].url", errorMsg);
                    return { valid: false, message: errorMsg };
                }
            }
        }
        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: WordFinderExercise | null, newState: WordFinderExercise): ValidationResponse {
        let longestText = newState.elements.length > 0 ? newState.elements.reduce((a, b) => { return a.text.length > b.text.length ? a : b }) : undefined;
        let new_dimensions = newState.dimensions?newState.dimensions:{rows:0, columns:0}; 
        if(longestText && (!new_dimensions.columns || new_dimensions.columns < longestText.text.length)){
            new_dimensions.columns = longestText.text.length;
        }

        if(!new_dimensions.rows || new_dimensions.rows < newState.elements.length){
            new_dimensions.rows = newState.elements.length;
        }
        newState.dimensions = new_dimensions;

        //if (AExerciseTypeConverter.isOfiEditor) return { valid: true }

        if (newState.elements.length > WordFinderGameConverter.MAX_ELEMENT_NUM) {
            newState.elements.splice(WordFinderGameConverter.MAX_ELEMENT_NUM, newState.elements.length - WordFinderGameConverter.MAX_ELEMENT_NUM);
            return { valid: false, message: __("Maximum {MAX_ELEMENT_NUM} elements allowed") };
        }

        return { valid: true }
    }
}
