import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewExerciseSeriesItemRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Sorrend
     */
    no?: number /*int4*/|null;
    /**
     * Feladatlista
     */
    exercise_series_id?: number;
    /**
     * Feladat
     */
    exercise_id?: number;
    /**
     * Pontszám
     */
    points?: number /*int4*/;
    /**
     * Szint
     *
     * A feladatsor nehézségi foka, 1=könnyű 2=közepes 3=nehéz
     */
    level?: number /*int4*/|null;
    /**
     * Adaptív feladatsor témakör
     */
    exercise_series_topic_id?: number|null;
    /**
     * Teljes név
     *
     * Egysoros szöveges reprezentáció
     */
    displayvalue?: string|null;
    /**
     * Feladatsor név
     *
     * Feladatsor név
     */
    exercise_series_title?: string|null;
    /**
     * Feladat név
     *
     * Feladat név
     */
    exercise_name?: string|null;
    /**
     * Feladat szint
     *
     * Feladat szint
     */
    exercise_level?: string|null;
}

/**
 *  exc_pub.view_exercise_series_item - Feladatsor elem
 */

export default class ViewExerciseSeriesItem extends View<IViewExerciseSeriesItemRecord> {
    public static VIEW_INFO_ID = 2020031601;

    public static getSchemaNameForClass(): string { return 'exc_pub'; }
    public static getViewNameForClass(): string { return 'view_exercise_series_item'; }
    public static getTableNameForClass(): string|null { return 'exercise_series_item'; }

    public static canCache(): boolean {
        return true;
    }

    public static load: (id: number, aServer ?: Server) => Promise<ViewExerciseSeriesItem>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewExerciseSeriesItemRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewExerciseSeriesItemClassProxy extends ViewClassProxy<IViewExerciseSeriesItemRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewExerciseSeriesItemClassProxy = new ViewExerciseSeriesItemClassProxy(ViewExerciseSeriesItem);
registerViewClassProxy(viewExerciseSeriesItemClassProxy);

