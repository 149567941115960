import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IExerciseRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* double precision */|null;
    /**
     * Név
     */
    name?: string;
    /**
     * Feladat motor
     *
     * NOT NULL -> exc.engine ON DELETE cascade
     *
     */
    engine_id?: number;
    /**
     * Évfolyam
     *
     * -> doc.grade ON DELETE cascade
     *
     */
    grade_id?: number|null;
    /**
     * Tantárgy
     *
     * -> doc.subject ON DELETE cascade
     *
     */
    subject_id?: number|null;
    /**
     * Könyvtár
     *
     * NOT NULL -> doc.library ON DELETE cascade
     *
     */
    library_id?: number;
    /**
     * Tulajdonos/felelős
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     */
    owner_id?: number;
    /**
     * A feladat adatai
     */
    exercise?: any;
    /**
     * A feladat helyes megoldása
     */
    solution?: any;
    /**
     * Utasítás
     */
    task_html?: string|null;
    /**
     * Visszajelzés helyes válaszra
     */
    correct_answer_feedback_html?: string|null;
    /**
     * Visszajelzés helytelen válaszra
     */
    wrong_answer_feedback_html?: string|null;
    /**
     * Tároló
     *
     * -> media.oo_folder ON DELETE noaction
     *
     *
     * Az a mappa, ami a feladathoz tartozó médiákat tárolja.
     */
    oo_folder_id?: number|null;
    /**
     * Megjelenés helye
     *
     * -> media.oo_folder ON DELETE setnull
     *
     *
     * Mappa, ahol a feladat a virtuális állományrendszerben megjelenik.
     */
    show_in_folder_id?: number|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
    /**
     * Név
     *
     * Kulcsszavak
     */
    keywords?: string|null;
    /**
     * Kereső szöveg
     *
     * A feladat mentésekor ide bekerül az összes olyan szöveg, ami értelmes kereshető szöveget tartalmaz. Ez feladatmotoronként eltérő helyekről/mezőkből származik.
     */
    search_text?: string|null;
    /**
     * Hány találatot adott
     *
     * A search_text mezőre keresve hány találatot ad a keresőmotor.
     */
    search_result_count?: number /*integer*/|null;
    /**
     * Találatok száma ellenőrizve
     *
     * Mikor lett utoljára ellenőrizve a találatok száma
     */
    search_result_last_checked?: string /*timestamp?*/|null;
    /**
     * Belső azonosító
     */
    internal_code?: string|null;
    /**
     * SNI
     *
     * Sajátos nevelési igényűek számára készített feladat
     */
    is_sni?: boolean;
    /**
     * Akadálymentesített
     *
     * Látássérültek számára akadálymentesített feladat
     */
    is_accessible?: boolean|null;
    /**
     * Egyszerû megjelenítés
     *
     * Egyszerű stílusban megjelenítendő feladat
     */
    simple_style?: boolean|null;
    /**
     * Site
     *
     * NOT NULL -> cms.site ON DELETE noaction
     *
     */
    site_id?: number;
    /**
     * Nyelv
     *
     * NOT NULL -> sys.lang ON DELETE cascade
     *
     */
    lang_id?: number;
}

/**
 *  exc.exercise - Feladat
 *
 *  A feladat egy konkrét feladvány, amit egy előre meghatározott feladat motor jelenít meg.
 */

export default class ExerciseCrud extends Crud<IExerciseRecord> {
    public static TABLE_INFO_ID = 886997750;
    public static getSchemaNameForClass() { return 'exc'; }
    public static getTableNameForClass() { return 'exercise'; }

    public static load: (id: number, aServer ?: Server) => Promise<ExerciseCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IExerciseRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IExerciseRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IExerciseRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IExerciseRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IExerciseRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ExerciseCrudClassProxy extends CrudClassProxy<IExerciseRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const exerciseCrudClassProxy = new ExerciseCrudClassProxy(ExerciseCrud);
registerCrudClassProxy(exerciseCrudClassProxy);
