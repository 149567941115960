import * as React from 'react'
import { match } from 'react-router';
import { SearchResult } from '@src/server/PublicServer';
import { History, Location } from 'history';
import { BubbleLoader } from 'react-css-loaders';
import * as Server from '@src/server/PublicServer'
import * as queryString from 'query-string';
import { app } from '../..';
import { getOfferDivs } from './GetOffers';
import { __ } from '@src/translation';


type MediaSearchResultsProps = {
    match: match<{keywords: string}>;
    history: History;
    location: Location;
    currentMediaType:string
    extendedList:boolean   
}

type MediaSearchResultsState = {
    searching: boolean;
    pageIndex: number;
    prevmediatype?:string;
    prevSearch?:string;
    Videos: SearchResult[];
    VideosBlock:any;
    Images: SearchResult[];
    ImagesBlock:any;
    Maps: SearchResult[];
    MapsBlock:any;
    soundOffers:SearchResult[];
    soundOffersBlock:any;
    Exercises: SearchResult[];
    IntExercisesBlock:any;
    extendedList:boolean;
}
const ITEMSPERPAGE = 12;
export class MediaSearchResults extends React.Component<MediaSearchResultsProps, MediaSearchResultsState> {

    constructor(props: MediaSearchResultsProps) {
        super(props);

        this.state = {
            searching: false,
            pageIndex:1,
            prevSearch:undefined,
            Videos: [],
            soundOffers:[],
            Images: [],
            Maps: [],
            Exercises: [],
            VideosBlock:undefined,
            ImagesBlock:undefined,
            IntExercisesBlock:undefined,
            MapsBlock:undefined,
            soundOffersBlock:undefined,
            extendedList:false 
        }
    }

    componentDidMount() {
        this.searchAsync();
    }
    componentDidUpdate(prevProps: MediaSearchResultsProps) {
        if (this.props.location != prevProps.location) {
            this.searchAsync();
        }
    }
    private setPageIndex = (pageIndex: number) => {
        if (pageIndex) {
           this.setState({pageIndex:pageIndex}, this.setBlocks);
        }
    }

    async searchAsync() {
       
        const params = queryString.parse(this.props.location.search);
        if((params.type!=undefined &&this.state.prevmediatype!=params.type)
        ||(this.props.match.params.keywords!=undefined &&this.props.match.params.keywords!=this.state.prevSearch))
        {
            this.setState({pageIndex:1,prevmediatype:params.type,prevSearch:this.props.match.params.keywords})
        }

        this.setState({searching: true,extendedList:params.extendedList &&params.extendedList=="true"?true:false});
        const IMAGE_ITEMSPERPAGE = (this.state.extendedList) ? 36 : 12;

        try {           

            let searchParams:Server.SearchParameters ={
                keyword: this.props.match.params.keywords || "",
                type:params.type || this.state.prevmediatype ||"media",  // type
                subject_id: params.subject_id,
                grade_id: params.grade_id,
                exactmatch:"false"
            };

            try {
                searchParams.type = "video";
                var videoOffers = await Server.search(searchParams);
                videoOffers = videoOffers.filter(e => e.displayname);
                this.setState({ Videos:this.state.extendedList?videoOffers:videoOffers.slice(0,ITEMSPERPAGE) });
            } catch (e) {/*Nincs elasticsearch index*/ }

            try {
                searchParams.type = "image";
                var imageOffers = await Server.search(searchParams);
                // imageOffers = imageOffers.filter(e => e.displayname);
                this.setState({ Images:this.state.extendedList?imageOffers:imageOffers.slice(0, IMAGE_ITEMSPERPAGE) });
            } catch (e) {/*Nincs elasticsearch index*/ }

            try {
                searchParams.type = "map";
                var mapOffers = await Server.search(searchParams);
                this.setState({ Maps:this.state.extendedList ? mapOffers : mapOffers.slice(0, ITEMSPERPAGE) });                
            } catch (e) {/*Nincs elasticsearch index*/ }
            
            try {
                searchParams.type ="audio";
                var soundOffers = await Server.search(searchParams);     
                this.setState({soundOffers:this.state.extendedList?soundOffers: soundOffers.slice(0, ITEMSPERPAGE)});
            } catch(e) {/*Nincs elasticsearch index*/}

            try {
                searchParams.type = "interactive_exercise";
                var interactiveExerciseOffers = await Server.search(searchParams);
                interactiveExerciseOffers = interactiveExerciseOffers.filter(e => e.title);
                this.setState({ Exercises:this.state.extendedList?interactiveExerciseOffers:interactiveExerciseOffers.slice(0, 7) })
            } catch (e) {/*Nincs elasticsearch index*/ }  

            this.setBlocks();
            
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async setBlocks() {
        const params = queryString.parse(this.props.location.search);
        const IMAGE_ITEMSPERPAGE = (this.state.extendedList) ? 36 : 12;

        let popularVideosBlock = (!params.type || params.type == "media" || params.type == "video") &&await getOfferDivs(this.props.history, this.props.location, __("Videók"), "video", this.state.Videos.slice((this.state.pageIndex - 1) * ITEMSPERPAGE, this.state.pageIndex * ITEMSPERPAGE),!this.state.extendedList,"video",this.props.match.params.keywords);
        let popularImagesBlock = (!params.type || params.type == "media" || params.type == "image") &&await getOfferDivs(this.props.history, this.props.location, __("Képek"), "image", this.state.Images.slice((this.state.pageIndex - 1) * IMAGE_ITEMSPERPAGE, this.state.pageIndex * IMAGE_ITEMSPERPAGE),!this.state.extendedList,"image",this.props.match.params.keywords);
        let popularMapsBlock= (!params.type || params.type == "media" || params.type == "map") &&await getOfferDivs(this.props.history, this.props.location, __("Térképek"), "map", this.state.Maps.slice((this.state.pageIndex - 1) * ITEMSPERPAGE, this.state.pageIndex * ITEMSPERPAGE),!this.state.extendedList,"map",this.props.match.params.keywords)
        let popularIntExercisesBlock = (!params.type || params.type == "media" || params.type == "interactive_exercise") &&await getOfferDivs( this.props.history, this.props.location, __("Interaktív feladatok"), "video", this.state.Exercises.slice((this.state.pageIndex - 1) * ITEMSPERPAGE, this.state.pageIndex * ITEMSPERPAGE),!this.state.extendedList,"interactive_exercise",this.props.match.params.keywords);
        let soundOffersBlock= (!params.type || params.type == "media" || params.type == "audio") &&await getOfferDivs(this.props.history, this.props.location, __("Hanganyagok"), "audio", this.state.soundOffers.slice((this.state.pageIndex - 1) * ITEMSPERPAGE, this.state.pageIndex * ITEMSPERPAGE),!this.state.extendedList,"audio",this.props.match.params.keywords);
            
        this.setState({

            VideosBlock: popularVideosBlock,
            ImagesBlock: popularImagesBlock,
            MapsBlock: popularMapsBlock,
            IntExercisesBlock: popularIntExercisesBlock,
            soundOffersBlock,
            searching:false,
        });
    }

    render() {
        const params = queryString.parse(this.props.location.search);
        const IMAGE_ITEMSPERPAGE = (this.state.extendedList) ? 36 : 12;
        let hitCount=0;
        if(params.type=="media"||!params.type)
        {
        hitCount =this.state.Images.length+this.state.Exercises.length+this.state.Maps.length+this.state.Videos.length+this.state.soundOffers.length;
        }
        else
        { 

            if(params.type=="video") hitCount=this.state.Videos.length;
            if(params.type=="image") hitCount=this.state.Images.length;
            if(params.type=="map") hitCount=this.state.Maps.length;
            if(params.type=="audio") hitCount=this.state.soundOffers.length;
            if(params.type=="interactive_exercise") hitCount=this.state.Exercises.length;
        }
        if (this.state.searching) {
            return <BubbleLoader />;
        }       
         else if (hitCount  == 0) {
         
            return <div className="row">
                <div className="small-2 large-1 column"  style={{position: "relative"}}>
                    <img src={"/img/noResultSearch/thinking.png"} style={{ width: "13em", color: "black", position: "absolute", top: "30px", paddingRight: "15px"  }} />
                </div>
                <br />
                <div className="small-10 large-11 column">
                    <br />
                    <h4>{__("A keresett kifejezés")} (<strong>{this.props.match.params.keywords || ""}</strong>) {__("nem található meg.")}</h4>
                    <h6>Javaslatok:</h6>
                    <ul className="eke-search-NoresultUli">
                        <li>{__("Győződjön meg arról, hogy valamennyi szót helyesen írta.")}</li>
                        <li>{__("Próbálkozzon más kulcsszavakkal.")}</li>
                        <li>{__("Próbálkozzon általánosabb kulcsszavakkal.")}</li>
                        <li>{__("Változtasson a szűrési feltételeken (pl. osztály).")}</li>
                    </ul>
                </div>
            </div>     
        } else {
     
            let pageCount: number = (params.type=="image") ? Math.ceil(hitCount / IMAGE_ITEMSPERPAGE) : Math.ceil(hitCount / ITEMSPERPAGE);
            let pageIndex = this.state.pageIndex;     
            var pageIndents: JSX.Element[] = [];
            for (var i = 1; i <= pageCount; i++) {
                pageIndents.push(<h2 key={i}>
                        <button className={pageIndex == i ? "active" : undefined} onClick={this.setPageIndex.bind(null, i)} >
                            {i}
                        </button>
                    </h2>);
            }
            
            return <div className="eke-media-search-result">
                    <div className="eke-media-container">
                       {this.state.extendedList?<h2><strong>{__("{n} találat a keresett szavakra:", {n: hitCount})}</strong></h2>:""}
                    </div> 
                    <div className={(this.state.extendedList) ? 'eke-extended-list' : ''}>

                    {(!params.type || params.type == "media" || params.type == "video") &&this.state.VideosBlock}
                    {(!params.type || params.type == "media" || params.type == "image") &&this.state.ImagesBlock}
                    {(!params.type || params.type == "media" || params.type == "map") && this.state.MapsBlock}
                    {(!params.type || params.type == "media" || params.type == "audio") &&this.state.soundOffersBlock}                       
                    </div>
                    <div className="eke-media-search-result-pagination">
                        <div className="pagination columns text-center">
                            {(params.type == "image" && this.state.extendedList) ?
                                hitCount > IMAGE_ITEMSPERPAGE && this.state.extendedList&& pageIndents
                            :
                                hitCount > ITEMSPERPAGE && this.state.extendedList&& pageIndents
                            }
                        </div>
                    </div>
            </div>;
        }
    }
}
