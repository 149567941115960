import * as React from 'react'
import * as Server from '@src/server/PublicServer';
import SearchResultList from './SearchResultList';
import { SearchResult, SearchParameters } from '@src/server/PublicServer';
import CrudSelect from '@framework/forms//crudselect';

import { subjectCrudClassProxy } from '@framework/crud/doc/SubjectCrud';
import { gradeCrudClassProxy } from '@framework/crud/doc/GradeCrud';
import { Link } from 'react-router-dom';
import { PATH_PUBLISHED_SEARCH } from '@src/Routes';
import { __ } from '@src/translation';

declare var MathJax: any;

interface DetailedSearchState {
    textKeyword: string;
    origkeywords: string;
    textNotIn: string;
    textStarts: string;
    textEnds: string;
    selectedType: string;
    exactMatch: boolean;
    selectedSubject: number | null;
    selectedGrade: number | null;
    results: SearchResult[];
    resultNum: number;
    pageIndex: number;
    emptyPage: boolean;
    maxHits: number;
    elementPerPage: number;
}

export default class DetailedSearch extends React.Component<any, DetailedSearchState> {

    constructor(prop: any) {
        super(prop);
        this.state = {
            textKeyword: '',
            origkeywords: '',
            textNotIn: '',
            textStarts: '',
            textEnds: '',
            selectedType: '',
            exactMatch: false,
            results: [],
            resultNum: 0,
            pageIndex: 1,
            emptyPage: true,
            maxHits: 100,
            selectedSubject: null,
            selectedGrade: null,
            elementPerPage: 10
        }
    }

    private onMaxHits = (event: any) => {
        if (event.target.value > 1000)
            this.setState({ maxHits: 1000 });
        else
            this.setState({ maxHits: event.target.value });
    }

    private onTextKeyword = (event: any) => {
        this.setState({
            textKeyword: event.target.value,
            textStarts: '',
            textEnds: ''
        });
    }

    private onKeypress = (event: any) => {
        if (event.charCode == 13) {
            this.onSearch();
        }
    }

    private onTextNotIn = (event: any) => {
        this.setState({
            textNotIn: event.target.value,
            textStarts: '',
            textEnds: ''
        });
    }

    private onTextStarts = (event: any) => {
        this.setState({
            textStarts: event.target.value,
            textEnds: '',
            textKeyword: ''
        });
    }

    private onTextEnds = (event: any) => {
        this.setState({
            textEnds: event.target.value,
            textStarts: '',
            textKeyword: ''
        });
    }

    private onSelectType = (event: any) => {
        this.setState({ selectedType: event.target.value }, this.onSearch);
    }

    private onExactMatch = (event: any) => {
        this.setState({
            exactMatch: event.target.checked
        });
    }

    private onButtonSearch() {
        this.onSearch();
    }

    componentDidUpdate(prevProps: any) {

        if (this.props.location !== prevProps.location) {

            this.setState({
                textKeyword: this.props.match.params.keywords || '',
                results: []
            });

            this.onSearch();
        }

        try {
            //Matematikai képletek kiértékelése a találatokra
            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        } catch (e) {
            console.log(e);
        }
    }

    async onSearch() {

        let keyword: string = '';
        let wildcard: string = 'false';

        if (this.state.textKeyword)
            keyword = this.state.textKeyword;

        if (this.state.textStarts) {
            keyword = this.state.textStarts + '*';
            wildcard = 'true';
        }

        if (this.state.textEnds) {
            keyword = '*' + this.state.textEnds;
            wildcard = 'true';
        }

        const subjectId = this.state.selectedSubject;
        const gradeId = this.state.selectedGrade;

        const resultNumSearch = await Server.search({
            keyword: keyword,
            type: this.state.selectedType,
            maxhits: this.state.maxHits.toString(),
            subject_id: subjectId ? subjectId.toString() : undefined,
            grade_id: gradeId ? gradeId.toString() : undefined,
            wildcard: wildcard,
            notin: this.state.textNotIn,
            exactmatch: String(this.state.exactMatch),
            countonly: true
        });
        const result = await Server.search({
            keyword: keyword,
            type: this.state.selectedType,
            subject_id: subjectId ? subjectId.toString() : undefined,
            grade_id: gradeId ? gradeId.toString() : undefined,
            wildcard: wildcard,
            notin: this.state.textNotIn,
            exactmatch: String(this.state.exactMatch),
            from: (this.state.pageIndex - 1) * this.state.elementPerPage,
            maxhits: (this.state.pageIndex - 1) * this.state.elementPerPage + this.state.elementPerPage > this.state.maxHits ? (this.state.maxHits - (this.state.pageIndex - 1) * this.state.elementPerPage).toString() : this.state.elementPerPage.toString()
        });
        let resultNum = resultNumSearch[0].count!;

        this.setState({
            results: result,
            emptyPage: false,
            origkeywords: keyword,
            resultNum: resultNum
        });
    }

    private setPageIndex = (pageIndex: number) => {
        this.setState({ pageIndex });
    }

    private onGradeSelect = (sender: CrudSelect, newGrade: number | null): void => {
        this.setState({ selectedGrade: newGrade }, this.onSearch);
    }


    private onSubjectSelect = (sender: CrudSelect, newSubject: number | null): void => {
        this.setState({ selectedSubject: newSubject }, this.onSearch

        );
    }

    render() {

        let searchResults;

        if (this.state.emptyPage) {
            searchResults = <br />
        } else {
            searchResults =
                <div className="row">
                    <SearchResultList
                        results={this.state.results}
                        imagesOnly={this.state.selectedType == "image"}
                        keywords={this.state.textKeyword}
                        origkeywords={this.state.origkeywords}
                        pageIndex={this.state.pageIndex}
                        onSetPageIndex={this.setPageIndex}
                        searching={false}
                        exactMatchFound={true}
                        resultNum={this.state.resultNum}
                        elementPerPage={this.state.elementPerPage}
                        doSearch={this.onSearch.bind(this)}
                    />
                </div>
        }

        const subjectId: any = this.state.selectedSubject;

        return <div className="small-12 column">
            <div className="row">

                <div className="columns ">
                    <Link to={PATH_PUBLISHED_SEARCH}>
                        <div className="small-12 columns align-self-bottom text-center">
                            <button className="shrink column button"> <i className="fa fa-arrow-left"></i>{__("Vissza a normál kereséshez")}</button>
                        </div>
                    </Link>
                    <h2 className="sub">{__("Speciális keresés a portálon")}</h2>
                </div>
            </div>
            <div className="row">
                <div className="small-12 large-3 columns">
                    <label className="large-text-right medium-text-left middle">{__("Tartalom típus")}:</label>
                </div>
                <div className="small-12 large-3 columns">
                    <select className="subject-select" value={this.state.selectedType} onChange={this.onSelectType.bind(this)}>
                        <option value="">{__("bármilyen tartalom")}</option>
                        <option value="3d">{__("3D")}</option>
                        <option value="text">{__("Szöveg")}</option>
                        <option value="image">{__("Kép")}</option>
                        <option value="video">{__("Videó")}</option>
                        <option value="audio">{__("Hang")}</option>
                        <option value="map">{__("Térkép")}</option>
                        <option value="pdf">{__("PDF")}</option>
                        <option value="exercise">{__("Okosfeladat")}</option>
                        <option value="exercise_series">{__("Okosfeladatsor")}</option>
                    </select>
                </div>
                <div className="small-size small-12 large-6 columns">
                    <span className="form-info">{__("Csak a kiválasztott tartalom típusban keres.")}</span>
                </div>
            </div>
            <div className="row">
                <div className="small-12 large-3 columns">
                    <label className="large-text-right medium-text-left middle">{__("Osztály")}:</label>
                </div>
                <div className="small-12 large-3 columns">
                    <CrudSelect
                        value={this.state.selectedGrade}
                        onSelect={this.onGradeSelect}
                        displayFieldName="name"
                        orderByFieldName="creation_time"
                        emptyTitle={__("bármelyik osztály")}
                        crudClassProxy={gradeCrudClassProxy}
                    />
                </div>
                <div className="small-size small-12 large-6 columns">
                    <span className="form-info">{__("Csak a kiválasztott évfolyamon keres.")}</span>
                </div>
            </div>
            <div className="row">
                <div className="small-12 large-3 columns">
                    <label className="large-text-right medium-text-left middle">{__("Tantárgy")}:</label>
                </div>
                <div className="small-12 large-3 columns">
                    <CrudSelect
                        value={this.state.selectedSubject}
                        onSelect={this.onSubjectSelect}
                        displayFieldName="name"
                        emptyTitle={__("bármelyik tantárgy")}
                        crudClassProxy={subjectCrudClassProxy}
                    />
                </div>
                <div className="small-size small-12 large-6 columns">
                    <span className="form-info">{__("Csak a kiválasztott tantárgyban keres.")}</span>
                </div>
            </div>
            <div className="row">
                <div className="small-12 large-3 columns">
                    <label className="large-text-right medium-text-left middle">{__("Tartalmazza ezen szavakat")}:</label>
                </div>
                <div className="small-12 large-3 columns">
                    <input type="text" className="detailed-search-text-box" name="textKeyword" value={this.state.textKeyword} onChange={this.onTextKeyword} onKeyPress={this.onKeypress} />
                </div>
                <div className="small-size small-12 large-6 columns">
                    <span className="form-info">{__("Írja be a fontos szavakat:")} <em>{__("Rákóczi-szabadságharc")}</em></span>
                </div>
            </div>
            <div className="row">
                <div className="small-12 large-3 columns">
                    <label className="large-text-right medium-text-left middle"> {__("Ne tartalmazza ezen szavak egyikét sem")}:</label>
                </div>
                <div className="small-12 large-3 columns">
                    <input type="text" className="detailed-search-text-box" name="textNotIn" value={this.state.textNotIn} onChange={this.onTextNotIn} onKeyPress={this.onKeypress} />
                </div>
                <div className="small-size small-12 large-6 columns">
                    <span className="form-info">{__("Írja be az elkerülendő szavakat")}: <em>{__("szabadságharc bukása")}</em></span>
                </div>
            </div>
            <div className="row">
                <div className="small-12 large-3 columns">
                    <label className="large-text-right medium-text-left middle">{__("Pontos egyezés")}:</label>
                </div>
                <div className="small-12 large-3 columns">
                    <label className="container">
                        <input type="checkbox" name="exactmatch" defaultChecked={this.state.exactMatch} onClick={this.onExactMatch.bind(this)} />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="small-size small-12 large-6 columns">
                    <span className="form-info">{__("Bepipálva csak pontos kifejezésre keres.")}</span>
                </div>
            </div>
            <div className="row">
                <div className="small-12 large-3 columns">
                    <label className="large-text-right medium-text-left middle">{__("Így kezdődjön...")} :</label>
                </div>
                <div className="small-12 large-3 columns">
                    <input type="text" className="detailed-search-text-box" name="textStarts" value={this.state.textStarts} onChange={this.onTextStarts} onKeyPress={this.onKeypress} />
                </div>
                <div className="small-size small-12 large-6 columns">
                    <span className="form-info">{__("Ezzel kezdődjön a keresett kifejezés")}: <em> {__("szabadság")} </em></span>
                </div>
            </div>
            <div className="row">
                <div className="small-12 large-3 columns">
                    <label className="large-text-right medium-text-left middle">{__("...így végződjön")}</label>
                </div>
                <div className="small-12 large-3 columns">
                    <input type="text" className="detailed-search-text-box" name="textEnds" value={this.state.textEnds} onChange={this.onTextEnds} onKeyPress={this.onKeypress} />
                </div>
                <div className="small-size small-12 large-6 columns">
                    <span className="form-info">{__("Erre végződjön a keresett kifejezés")} : <em>{__("harc")}</em></span>
                </div>
            </div>
            <div className="row">
                <div className="small-12 large-3 columns">
                    <label className="large-text-right medium-text-left middle">{__("Találatok max. száma")}: </label>
                </div>
                <div className="small-12 large-3 columns">
                    <input className="detailed-search-text-box" name="textMaxHits" type="number" style={{ width: "125px" }} value={this.state.maxHits} onChange={this.onMaxHits} onKeyPress={this.onKeypress} />
                </div>
                <div className="small-size small-12 large-6 columns">
                    <span className="form-info">{__("Maximum ennyi találatot adjon vissza a keresés.")}</span>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="small-12 columns align-self-bottom text-center">
                    <button className="shrink column button" onClick={this.onButtonSearch.bind(this)}>{__("Keresés")}</button>
                </div>
            </div>

            {searchResults}
        </div>
    }
} 