import { Module, ModuleMenu, BaseMenu, ModuleRoute } from "../Module";
import { me } from "@src/framework/server/Auth";
import { __ } from "@src/translation";
import obtainServer, { Server } from "@src/framework/server/Server";
import MessengerAPI, { IOutgoingMessage, IMessengerNotificationInfo, IMessengerSummaryInfo, IMessageTreeNode, IMessageOperationResult, ITrainerMessageEvents } from "./messengerAPI";
import MessageListPage from "./components/MessageListPage";
import MessageViewPage from "./components/MessageViewPage";
import { HeaderNotificationStore } from "@src/component/HeaderNotification";
import MessageCrud from "@src/framework/crud/usr/MessageCrud";

export let messengerModule: MessengerModule<BaseMenu>;

export type MessengerModuleConfig = {

}

export class MessengerModule<T extends BaseMenu> extends Module<T> {

    private config: MessengerModuleConfig;
    private api: MessengerAPI;

    public PATH_MESSAGES = "/uzenetek";
    public PATH_MESSAGES_IN = this.PATH_MESSAGES + "/bejovo";
    public PATH_MESSAGES_OUT = this.PATH_MESSAGES + "/kimeno";
    public PATH_MESSAGES_TRASH = this.PATH_MESSAGES + "/kuka";
    public PATH_MESSAGES_VIEW = this.PATH_MESSAGES + "/megtekint";
    //public PATH_MESSAGES_SETINGS = this.PATH_MESSAGES + "/beallitasok";

    private static VIEW_ROUTES = {
        [MessageCrud.TABLE_INFO_ID]: "/uzenetek/megtekint/"
    }

    constructor(config: MessengerModuleConfig, server?: Server) {
        super();
        this.config = config;
        this.api = new MessengerAPI(server || obtainServer());
    }

    public static initGlobal<T extends BaseMenu>(config: MessengerModuleConfig, server?: Server) {
        messengerModule = new MessengerModule<T>(config, server);

    }

    public async getRoutesAsync(): Promise<Record<string, ModuleRoute<T>>> {
        return this.getRoutes();
    }

    public getRoutes(): Record<string, ModuleRoute<T>> {
        return {
            'ROUTE_MESSENGER': { path: this.PATH_MESSAGES, component: MessageListPage, hasSidebar: true, exact: true, menuCode: "messages-sidebar", sidebarHeader: __("Üzenetek") },
            'ROUTE_MESSAGES_IN': { path: this.PATH_MESSAGES_IN, component: MessageListPage, hasSidebar: true, exact: true, menuCode: "messages-sidebar", sidebarHeader: __("Üzenetek") },
            'ROUTE_MESSAGES_OUT': { path: this.PATH_MESSAGES_OUT, component: MessageListPage, hasSidebar: true, exact: true, menuCode: "messages-sidebar", sidebarHeader: __("Üzenetek") },
            'ROUTE_MESSAGES_TRASH': { path: this.PATH_MESSAGES_TRASH, component: MessageListPage, hasSidebar: true, exact: true, menuCode: "messages-sidebar", sidebarHeader: __("Üzenetek") },
            'ROUTE_MESSAGES_VIEW': { path: this.PATH_MESSAGES_VIEW + "/:messageId", component: MessageViewPage, hasSidebar: true, menuCode: "messages-sidebar", sidebarHeader: __("Üzenetek") },
        };
    }

    public getRecordRoute(tableInfoId: number, recordId: number, edit?: boolean): string | null {
        if (edit) return null; //Nincs message edit route
        let route = MessengerModule.VIEW_ROUTES[tableInfoId];
        if (route !== undefined) {
            return route + recordId;
        }
        return null;
    }

    public getMenus() {
        let ret: ModuleMenu<T>[] = [];

        if (me) {
            ret = ret.concat(
                [
                    {
                        enabled: true,
                        title: __("Üzenetek") /*+ this.getUnreadedMessageString()*/,
                        path: this.PATH_MESSAGES,
                        iconClass: "far fa-comments",
                        no: 100,
                        code: "header-function"
                    },
                    {
                        enabled: true,
                        title: __("Bejövő üzenetek"),
                        path: this.PATH_MESSAGES_IN,
                        iconClass: "fas fa-share",
                        no: 100,
                        code: "messages-sidebar"
                    },
                    {
                        enabled: true,
                        title: __("Kimenő üzenetek"),
                        path: this.PATH_MESSAGES_OUT,
                        iconClass: "fas fa-reply",
                        no: 100,
                        code: "messages-sidebar"
                    },
                    {
                        enabled: true,
                        title: __("Kuka"),
                        path: this.PATH_MESSAGES_TRASH,
                        iconClass: "fas fa-trash",
                        no: 100,
                        code: "messages-sidebar"
                    },
                    /*{
                        enabled: true,
                        title: __("Beállítások"),
                        path: this.PATH_MESSAGES_SETINGS,
                        iconClass: "fas fa-cogs",
                        no: 100,
                        code: "messages-sidebar"
                    },*/
                ]);
        }

        return ret;
    }

    private getUnreadedMessageString(): string {
        let store = HeaderNotificationStore.get();
        let unseen_messages = "";
        if (store && store.messengerNotificationInfo && store.messengerNotificationInfo.unseen_cnt > 0) {
            unseen_messages = ` (${store.messengerNotificationInfo.unseen_cnt})`;
        }
        return unseen_messages;
    }
    
    /* TODO: any-t átírni IViewUsrMessage -re */
    public async pushMessage(tableInfoId: number | null, recordId: number | null, replyToId: number | null, message: IOutgoingMessage): Promise<any> {
        return this.api.pushMessage(tableInfoId, recordId, replyToId, message);
    }

    /** Fogadó oldalon */

    public async setSeen(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.api.setSeen(messageIds);
    }

    public async clearSeen(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.api.clearSeen(messageIds);
    }

    public async setDeleted(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.api.setDeleted(messageIds);
    }

    public async clearDeleted(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.api.clearDeleted(messageIds);
    }

    public async setPurged(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.api.setPurged(messageIds);
    }

    /* Küldő oldalon */

    public async setSenderSeen(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.api.setSenderSeen(messageIds);
    }

    public async clearSenderSeen(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.api.clearSenderSeen(messageIds);
    }

    public async setSenderDeleted(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.api.setSenderDeleted(messageIds);
    }

    public async clearSenderDeleted(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.api.clearSenderDeleted(messageIds);
    }

    public async setSenderPurged(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.api.setSenderPurged(messageIds);
    }

    
    public async getMessageTree(messageId: number, includeBody: boolean): Promise<IMessageTreeNode> {
        return this.api.getMessageTree(messageId, includeBody);
    }

    public async getMessagesSummary(tableInfoId: number, recordId: number): Promise<IMessengerSummaryInfo> {
        return this.api.getMessagesSummary(tableInfoId, recordId);
    }

    public async getNotificationInfo(siteId?: number): Promise<IMessengerNotificationInfo> {
        return this.api.getNotificationInfo(siteId);
    }

    public async getTrainerMessageEvents(userId: number, courseIds ?: number[], messageFromDate ?: Date, messageToDate ?: Date) : Promise<ITrainerMessageEvents> {
        return this.api.getTrainerMessageEvents(userId, courseIds, messageFromDate, messageToDate);
    }


}

