import * as React from 'react';
import { app } from '@src/index';
import { me } from '@framework/server/Auth';
import { __ } from '@src/translation';
import FavouriteCrud, { IFavouriteRecord } from '@framework/crud/usr/FavouriteCrud';
import LikeCrud, { ILikeRecord } from '@framework/crud/usr/LikeCrud';
import './usr_like_plugin.css';
import { CollectionMenu } from '../collection/CollectionMenu';
import VisibilitySensor from 'react-visibility-sensor';

export interface IUsrLikePluginProps {
    tableInfoId: number;
    recId: number | null;
    maxAge ?: number;
}

interface IUsrLikePluginState {
    favourite?: IFavouriteRecord | null;
    like?: ILikeRecord | null;
    //rating?: IRatingRecord | null;
    isVisible : boolean;
    loaded: boolean;
    loading: boolean;
    likeCount?:number;
}

/**
 * Like/Favourite/Rating plugin
 */
export default class UsrLikePlugin extends React.Component<IUsrLikePluginProps, IUsrLikePluginState> {
    private DEFAULT_MAX_AGE : number = 30;

    constructor(props: IUsrLikePluginProps) {
        super(props);

        this.state = { loaded: false, loading: false, likeCount:0, isVisible: false };
    }

    componentDidMount() {
        // this.asyncReload();
    }

    componentDidUpdate(prevProps: IUsrLikePluginProps) {
        if (prevProps.recId != this.props.recId || prevProps.tableInfoId != this.props.tableInfoId) {
            if (this.state.isVisible) {
                this.setState({loaded: false, loading: true}, this.asyncReload);
            } else {
                this.setState({loaded: false, loading: false});
            }
        }
    }

    private onVisiblityChange = (isVisible: boolean) => {
        this.setState({isVisible}, () => {
            if (this.state.isVisible && !this.state.loaded) {
                this.asyncReload();
            }
        });

    }

    private asyncReload = async () => {
        // Ide kellene: a rating és like és favourite adatok betöltése.
        let newState: Partial<IUsrLikePluginState> = { loading: false, loaded: true };
        if (this.props.tableInfoId !== undefined && me && me.id) {
            const max_age : number|undefined = this.props.maxAge===undefined?this.DEFAULT_MAX_AGE:this.props.maxAge;

            try {
                let items = await FavouriteCrud.list({
                    filter: {
                        table_info_id: this.props.tableInfoId,
                        rec_id: this.props.recId,
                        sec_user_id: me.id
                    },
                    max_age
                });
                let likeCount = await LikeCrud.count({
                    filter: {
                        table_info_id: this.props.tableInfoId,
                        rec_id: this.props.recId,
                    },
                    max_age
                });
                if (items && items.length) {
                    newState.favourite = items[0];
                } else {
                    newState.favourite = null;
                }

                items = await LikeCrud.list({
                    filter: {
                        table_info_id: this.props.tableInfoId,
                        rec_id: this.props.recId,
                        sec_user_id: me.id
                    },
                    max_age
                });
                if(likeCount &&likeCount>0)
                {
                    newState.likeCount=likeCount;
                }
                if (items && items.length) {
                    newState.like = items[0];
                } else {
                    newState.like = null;
                    
                }
                this.setState(newState as any);

            } catch (error) {
                app.showErrorFromJsonResult(error)
            }
        }
    }

    public getMyUniqueId(): string {
        return `usr_like_plugin_${this.props.tableInfoId}_${this.props.recId || 0}`;
    }

    private toggleLike = async () => {
        if (this.state.like == null) {
            this.setState({ loading: true });
            new LikeCrud({
                table_info_id: this.props.tableInfoId,
                rec_id: this.props.recId!,
                sec_user_id: me!.id!
            }).put()
                .then((crud) => { this.setState({ like: crud.record, loading: false,likeCount:this.state.likeCount?this.state.likeCount+1:1 }) })
                .catch((error) => { app.showErrorFromJsonResult(error) });
        } else {
            this.setState({ loading: true });
            new LikeCrud({ id: this.state.like.id! }).del()
                .then((crud) => { this.setState({ like: null, loading: false,likeCount:this.state.likeCount!-1 }) })
                .catch((error) => { app.showErrorFromJsonResult(error) });
        }
    }

    private toggleFavourite = async () => {
        if (this.state.favourite == null) {
            this.setState({ loading: true });
            new FavouriteCrud({
                table_info_id: this.props.tableInfoId,
                rec_id: this.props.recId!,
                sec_user_id: me!.id!
            }).put()
                .then((crud) => { this.setState({ favourite: crud.record, loading: false }); })
                .catch((error) => { app.showErrorFromJsonResult(error) });
        } else {
            this.setState({ loading: true });
            new FavouriteCrud({ id: this.state.favourite.id! }).del()
                .then((crud) => { this.setState({ favourite: null, loading: false }); })
                .catch((error) => { app.showErrorFromJsonResult(error) });

        }
    }
    render() {
        if (!me) {
            return null;
        } else if (!this.props.recId) {
            // Nincs megadva rekord azonosító, nem lehet like-olni...
            return null;
        } else {

            /*Béla azt mondta hogy like lesz :)*/

            let likeClass: string = "usr-like like";
            let likeTitle: string = __("Nyomja meg a kedveléshez");
            let likeFa: string = "far fa-thumbs-up";
            let likeCountString = this.state.likeCount&&this.state.likeCount>0?'('+this.state.likeCount.toString()+')':""
            let favShortTitle: string = "";
            let likeShortTitle: string = "";
            if (this.state.like) {
                likeClass += " usr-like-activated";
                likeTitle = __("Ön kedvelte ekkor: {time}", {time: this.state.like.creation_time});
                likeShortTitle = __("Ön kedvelte ekkor: {time}", {time: this.state.like.creation_time!.slice(0, -7)});
                likeFa = "fa fa-thumbs-up";
            }

            let favouriteClass: string = "usr-like favourite";
            let favouriteTitle: string = __("Nyomja meg, hogy hozzáadja a kedvencekhez");
            let favouriteFa: string = "far fa-star";
            if (this.state.favourite) {
                favouriteClass += " usr-like-activated";
                favouriteTitle = __("Kedvencekhez adva ekkor: {time}", {time: this.state.favourite.creation_time});
                favShortTitle = __("Kedvencekhez adva ekkor: {time}", {time: this.state.favourite.creation_time!.slice(0, -7)});
                favouriteFa = "fa fa-star";
            }

            return <VisibilitySensor onChange={this.onVisiblityChange}>
                    <div className="like-block">

                    {/* Béla azt mondta hogy Like legyen */}
                
                        <button className={favouriteClass} disabled={this.state.loading}
                            title={favShortTitle}
                            onClick={this.toggleFavourite} ><i className={favouriteFa}></i> <span className="button-title">{__("Kedvenc")}</span> <span className="show-for-sr">{favShortTitle}</span></button>
                
                        <button className={likeClass} disabled={this.state.loading}
                            title={likeShortTitle}
                            onClick={this.toggleLike}><i className={likeFa}></i> <span className="button-title">{__("Tetszik")}</span> <span className="button-count">{likeCountString}</span> <span className="show-for-sr">{likeShortTitle}</span></button>
                    
                        <CollectionMenu
                            tableInfoId={this.props.tableInfoId}
                            recId={this.props.recId}                        
                        />
                </div>
                </VisibilitySensor>;
        }
    }
}