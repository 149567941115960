import * as React from 'react';
import { Link } from 'react-router-dom';
import { ExerciseBreadcrumbs } from '../../Breadcrumbs';
import { app } from '../../../index';
import EngineCrud, { IEngineRecord } from '@src/framework/crud/exc/EngineCrud';
import { PATH_EXERCISE_NEW } from '@src/Routes';
import * as ExerciseTypeConverter from "../models/ExerciseTypeConverter";
import { __, getLanguageId } from '@src/translation';
import { hasGroup, Groups, me, hasAnyGroup } from '@src/framework/server/Auth';
import EngineTranslationCrud, { IEngineTranslationRecord } from '@src/framework/crud/exc/EngineTranslationCrud';


export type ExerciseSelectorPageState={
    exerciseEngines: IEngineRecord[], 
    engineTranslations:IEngineTranslationRecord[],
    engineLists:{
        answerChooseBased:IEngineRecord[], 
        sortingBased:IEngineRecord[],
        textBased:IEngineRecord[],
        games:IEngineRecord[]
    } 

}
export default class ExerciseList extends React.Component<any,ExerciseSelectorPageState > {

    constructor(props: any) {
        super(props);

        this.reloadAsync();
    }

    async reloadAsync() {
        let exerciseEngines = await EngineCrud.list({ filter: { is_active: true }, order_by: "name" });
        let engineTranslations = await EngineTranslationCrud.list({ filter: { is_active: true, lang_id:getLanguageId() } });
        let ansBased:IEngineRecord[] = [];
        let sortBased:IEngineRecord[] = [];
        let textBased:IEngineRecord[] = [];
        let games:IEngineRecord[] = [];

        for (let index = 0; index < exerciseEngines.length; index++) {
            if(ExerciseTypeConverter.ExerciseEngineTypeCheck.AnswerChooseEngines.indexOf(exerciseEngines[index]!.class_name!) > -1){
                ansBased.push(exerciseEngines[index]);
            }else if(ExerciseTypeConverter.ExerciseEngineTypeCheck.SortingBasedEngines.indexOf(exerciseEngines[index]!.class_name!) > -1){
                sortBased.push(exerciseEngines[index]);
            }else if(ExerciseTypeConverter.ExerciseEngineTypeCheck.TextBasedEngines.indexOf(exerciseEngines[index]!.class_name!) > -1){
                textBased.push(exerciseEngines[index]);
            }else{
                games.push(exerciseEngines[index]);
            }            
        }

        try {
            this.setState({
                exerciseEngines,
                engineTranslations,
                engineLists:{
                    answerChooseBased:ansBased,
                    sortingBased:sortBased,
                    textBased:textBased,
                    games,
                }
            });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    getEngineTranslatedName(engine_id:number){
        let engineName = "";
        const engine = this.state.engineTranslations.find((engine) => engine.engine_id == engine_id);
        if(engine) engineName = engine.name || "";
        return engineName;
    }

    render() {

        return (
            <div className="row expanded exercise-selector-page">
                <div className="small-12 column">
                    <ExerciseBreadcrumbs links={[{ name: __("Feladattípus választása") }]} />
                    <h3 className="exercise-selector-h3">{ __("Válassz feladatot!") }</h3>
                </div>

                <div className="small-12 column">
                    <div className="row expanded">
                        <h4 className="exercise-selector-h4">{ __("Feleletválasztós") }</h4>
                    </div>
                    <div className="row expanded">
                    {
                            this.state && this.state.engineLists.answerChooseBased ?
                                this.state.engineLists.answerChooseBased.map((engine) => {
                                    return <div className="large-2 medium-4 small-12 column" key={engine.id}>
                                        <Link to={PATH_EXERCISE_NEW + `/${engine.id}/`} className="button expanded exercise-selector-button" >
                                            <div style={{ display:"flex", flexDirection: "column" }} className="text-center">
                                                <img style={{ flex:1 }} src={ExerciseTypeConverter.getIconOfEngine(engine.class_name!, true)} />
                                                <div className="show-for-medium">
                                                    <span> {this.getEngineTranslatedName(engine.id!)}</span>
                                                </div>
                                                
                                            </div>
                                            
                                        </Link><br />
                                    </div>
                                })
                                :
                                ""
                        }                   

                    </div>
                </div>

                <div className="small-12 column">
                    <div className="row expanded">
                        <h4 className="exercise-selector-h4">{ __("Rendezéses") }</h4>
                    </div>
                    <div className="row expanded">
                    {
                            this.state && this.state.engineLists.sortingBased ?
                                this.state.engineLists.sortingBased.map((engine) => {
                                    return <div className="large-2 medium-4 small-12 column" key={engine.id}>
                                        <Link to={PATH_EXERCISE_NEW + `/${engine.id}/`} className="button expanded exercise-selector-button" >
                                            <div style={{ display:"flex", flexDirection: "column" }} className="text-center">
                                                <img style={{ flex:1 }} src={ExerciseTypeConverter.getIconOfEngine(engine.class_name!, true)} />
                                                <div className="show-for-medium">
                                                    <span> {this.getEngineTranslatedName(engine.id!)}</span>
                                                </div>
                                               
                                            </div>
                                          
                                        </Link><br />
                                    </div>
                                })
                                :
                                ""
                        }

                    </div>
                </div>

                <div className="small-12 column">
                    <div className="row expanded">
                        <h4 className="exercise-selector-h4">{ __("Szöveges") }</h4>
                    </div>
                    <div className="row expanded">
                    {
                            this.state && this.state.engineLists.textBased ?
                                this.state.engineLists.textBased.map((engine) => {
                                    return <div className="large-2 medium-4 small-12 column" key={engine.id}>
                                        <Link to={PATH_EXERCISE_NEW + `/${engine.id}/`} className="button expanded exercise-selector-button" >
                                            <div style={{ display:"flex", flexDirection: "column" }} className="text-center">
                                                <img style={{ flex:1 }} src={ExerciseTypeConverter.getIconOfEngine(engine.class_name!, true)} />
                                                <div className="show-for-medium">
                                                    <span> {this.getEngineTranslatedName(engine.id!)}</span>
                                                </div>
                                                
                                            </div>
                                            
                                        </Link><br />
                                    </div>
                                })
                                :
                                ""
                        }

                    </div>
                </div>

                <div className="small-12 column">
                    <div className="row expanded">
                        <h4 className="exercise-selector-h4">{ __("Játékok") }</h4>
                    </div>
                    <div className="row expanded">
                    {this.state && this.state.engineLists.games ?
                                this.state.engineLists.games.map((engine) => { if((me && hasAnyGroup(me,[Groups.Developer])) || (engine.name != "ColoringGame"))
                                    return <div className="large-2 medium-4 small-12 column" key={engine.id}>
                                        <Link to={PATH_EXERCISE_NEW + `/${engine.id}/`} className="button expanded exercise-selector-button" >
                                            <div style={{ display:"flex", flexDirection: "column" }} className="text-center">
                                                <img style={{ flex:1 }} src={ExerciseTypeConverter.getIconOfEngine(engine.class_name!, true)} />
                                                <div className="show-for-medium">
                                                    <span>  {this.getEngineTranslatedName(engine.id!)}</span>
                                                </div>
                                               
                                            </div>
                                          
                                        </Link><br />
                                    </div>
                                return "" }
                                )
                                :
                                ""
                        }

                    </div>
                </div>

                
                <div className="small-12 column">
                    <div className="row">
                        {/*
                            this.state && this.state.exercisesEngines ?
                                this.state.exercisesEngines.map((engine) => {
                                    return <div className="large-2 medium-4 small-12 column" key={engine.id}>
                                        <Link to={PATH_EXERCISE_NEW + `/${engine.id}/`} className="button expanded exercise-selector-button" >
                                            <div style={{ height: "10rem" }} className="text-center">
                                                <img style={{ height: "95%" }} src={ExerciseTypeConverter.getIconOfEngine(engine.class_name!, true)} />
                                                <div className="show-for-medium">
                                                    <br />
                                                    <span> {engine.name}</span>
                                                </div>
                                                <br />
                                            </div>
                                            <br /><br />
                                        </Link><br />
                                    </div>
                                })
                                :
                                ""
                            */}
                    </div>
                </div>

            </div>
        );
    }

}

