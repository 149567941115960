type CmsConfig = {
    siteId: number;
}

export let cmsConfig: CmsConfig = {
    siteId: 1
};

export function configureCms(newConfig: CmsConfig) {
    cmsConfig = newConfig;
}
