import * as React from 'react';
import * as ReactDOM from 'react-dom';
import LazyLoad from 'vanilla-lazyload';
import { RenderedSection } from '@src/server/PublicServer';
import { ExerciseServer } from '@src/component/exercise/ExerciseServer';
import * as ExerciseContainerComponent from '@src/component/exercise/ExerciseContainerComponent';
import { searchNotForWeakSpansAndAddLayer } from '@src/component/book/viewer/page_addition/BookNotForWeak';
import { loadMXGraphScriptAsync } from '@src/Util';
import { SectionCollectionMenu } from './page_addition/SectionCollectionMenu';
import { me, hasGroup, Groups } from '@src/framework/server/Auth';
import SectionCrud from '@src/framework/crud/doc/SectionCrud';
import SectionPubCrud from '@src/framework/crud/doc_pub/SectionCrud';
import { BookAudio } from './BookAudio';
import UsrLikePlugin from '@src/component/usr/usr_like_plugin';
import OoFileCrud from '@src/framework/crud/media_pub/OoFileCrud';
import { AudioPlayer } from '@src/component/ui/AudioPlayer';
import { __ } from '@src/translation';
import * as queryString from 'query-string';
import YouTubeVideo from '@src/component/ui/YoutubePlayer';

declare var domtoimage: any;

export enum SectionType {
    TEXT,
    EXERCISE,
    EXERCISE_SERIES,
    VIDEO,
    IFRAME
}

export const SECTION_TYPES_ALL = [SectionType.TEXT, SectionType.EXERCISE, SectionType.EXERCISE_SERIES, SectionType.VIDEO, SectionType.IFRAME];
export const SECTION_TYPES_TEXT = [SectionType.TEXT];
export const SECTION_TYPES_VIDEO = [SectionType.VIDEO];
export const SECTION_TYPES_EXERCISE_IFRAME = [SectionType.EXERCISE, SectionType.EXERCISE_SERIES, SectionType.IFRAME];
export const SECTION_TYPES_EXTENSION = [SectionType.EXERCISE, SectionType.EXERCISE_SERIES, SectionType.VIDEO, SectionType.IFRAME];

export function containsSection(sectionTypes: SectionType[], sections: { blockTemplateName: string }[]) {
    return sections.some(section => {
        if (section.blockTemplateName == __("Okosfeladat")) {
            return sectionTypes.includes(SectionType.EXERCISE);
        } else if (section.blockTemplateName == __("Okosfeladatsor")) {
            return sectionTypes.includes(SectionType.EXERCISE_SERIES);
        } else if (section.blockTemplateName == __("Videó")) {
            return sectionTypes.includes(SectionType.VIDEO);
        } else if (section.blockTemplateName == __("Beágyazott tartalom")) {
            return sectionTypes.includes(SectionType.IFRAME);
        } else {
            return sectionTypes.includes(SectionType.TEXT);
        }
    });
}

export function containsSectionType(sectionTypes1: SectionType[], sectionTypes2: SectionType[]) {
    return sectionTypes1.some(s => sectionTypes2.includes(s));
}

type BookSectionsProps = {
    renderedSections: RenderedSection[];
    chapterId: number;
    sectionTypes?: SectionType[];
    published: boolean;
    showCollectionMenu: boolean;
    lessonName?: string;
    isSNIview?: boolean;
}

type BookSectionsState = {

}

declare var GraphViewer: any;

export class BookSections extends React.Component<BookSectionsProps, BookSectionsState> {

    private lazyLoad: any = null;

    private oldContentElement?: HTMLElement;

    private reveals: HTMLElement[] = [];

    async componentDidMount() {
        this.lazyLoad = new LazyLoad({
            elements_selector: 'img, iframe, video',
            callback_enter: (element: HTMLElement) => {
                const videoPosterAttribute = element.getAttribute("data-poster");
                if (videoPosterAttribute) element.setAttribute("poster", videoPosterAttribute);
            }
        });
        this.applyScripts();

        //DOM TO IMAGE sni szekció előnézetekhez. majd.
        // domtoimage.toPng(document.getElementById('test')).then(function (dataUrl: any) {
        //     var img = new Image();
        //     img.src = dataUrl;
        //     (document.getElementById("test-2") as any).appendChild(img);
        // });
    }

    componentWillUnmount() {
        this.oldContentElement = undefined;

        this.cleanupAfterFoundation();

        if (this.lazyLoad) {
            this.lazyLoad.destroy();
        }
    }

    componentDidUpdate() {
        this.applyScripts();
    }


    private cleanupAfterFoundation() {
        // A foundation előző futásakor kiszedett dolgokat el kell tüntetni, mert a foundation nem tünteti el maga!
        // Ezek a biológia / kémia felugró kislexikon lapjai
        for (const reveal of this.reveals) {
            reveal.remove();
        };
    }

    async applyScripts() {
        try {
            if (this.refs.content && this.refs.content != this.oldContentElement) {
                //this.oldContentElement = this.refs.content as HTMLDivElement;

                this.lazyLoad.update();

                //this.cleanupAfterFoundation();

                const contentDiv = this.refs.content as HTMLDivElement;

                let jquery: any = contentDiv.ownerDocument!.defaultView!["$"];

                (jquery(contentDiv) as any).foundation();

                var options = { disable_for_touch: true };
                jquery('[data-modal-control] [data-tooltip]').options = options;

                //lo nagyítás
                jquery(".lo .responsive-embed").append('<span><i class="fa fa-search-plus"></i></span>').off("click").click(function (event: any) {
                    jquery(event.currentTarget).toggleClass("fullscreen");
                });

                this.reveals = Array.from(document.querySelectorAll(".reveal-overlay"));

                var MathJax = contentDiv.ownerDocument!.defaultView!["MathJax"];
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, contentDiv]);


                // Feladat
                const exerciseDivs = Array.from(contentDiv.ownerDocument!.querySelectorAll("div[data-exercise-id]"));

                for (const exerciseDiv of exerciseDivs) {
                    const exerciseId = exerciseDiv.getAttribute("data-exercise-id") as string;
                    if (!exerciseId) continue;

                    var section = null;
                    var sectionDiv = jquery(exerciseDiv).closest("section");
                    const sectionId = sectionDiv.attr("id");
                    if (sectionId && this.props.renderedSections) {
                        section = this.props.renderedSections.find((s: any) => s.id == sectionId.replace("section-", ""));

                        (sectionDiv[0] as HTMLElement).classList.add("exercise");
                    }
                    var defaultHidden = section ? section.exerciseIsHiddenByDefault : undefined;
                    var exerciseInPopup = section ? section.exerciseInPopup : undefined;

                    if (this.showOnlyExercises()) {
                        defaultHidden = false;
                    }

                    const server = new ExerciseServer(this.props.published);
                    server.setExerciseId(Number(exerciseId));

                    ReactDOM.render(<ExerciseContainerComponent.default
                        server={server}
                        isInBook={true}
                        chapterId={this.props.chapterId}
                        enableToggle={true}
                        defaultHidden={defaultHidden}
                        exerciseInPopup={exerciseInPopup}
                        sectionId={sectionId}
                    />,
                        exerciseDiv);
                }

                // Feladatsor
                const exerciseSeriesDivs = Array.from(contentDiv.ownerDocument!.querySelectorAll("div[data-exercise-series-id]"));

                for (const exerciseSeriesDiv of exerciseSeriesDivs) {
                    const exerciseSeriesId = exerciseSeriesDiv.getAttribute("data-exercise-series-id") as string;
                    if (!exerciseSeriesId) continue;

                    var section: any = null;
                    const sectionId = jquery(exerciseSeriesDiv).closest("section").attr("id");
                    if (sectionId && this.props.renderedSections) {
                        section = this.props.renderedSections.find((s: any) => s.id == sectionId.replace("section-", ""));
                    }

                    const server = new ExerciseServer(this.props.published);
                    server.setExerciseSeriesId(Number(exerciseSeriesId));
                    var defaultHidden = section ? section.exerciseIsHiddenByDefault : undefined;
                    var exerciseInPopup = section ? section.exerciseInPopup : undefined;

                    if (this.showOnlyExercises()) {
                        defaultHidden = false;
                    }

                    ReactDOM.render(<ExerciseContainerComponent.default
                        server={server}
                        isInBook={true}
                        chapterId={this.props.chapterId}
                        enableToggle={true}
                        defaultHidden={defaultHidden}
                        exerciseInPopup={exerciseInPopup}
                        sectionId={sectionId}
                    />,
                        exerciseSeriesDiv);
                }

                const iframes = Array.from(contentDiv.ownerDocument!.querySelectorAll("iframe[data-table-info-id]"));
                for (const iframe of iframes) {
                    const tableInfoId = iframe.getAttribute("data-table-info-id");
                    const recId = iframe.getAttribute("data-rec-id");
                    const src = iframe.getAttribute("data-src") || iframe.getAttribute("src");
                    if (me && src && tableInfoId && recId) {
                        var social = <div className="exe-socials-buttons">
                            <UsrLikePlugin
                                tableInfoId={Number(tableInfoId) || OoFileCrud.TABLE_INFO_ID}
                                recId={Number(recId)}
                            />
                        </div>;
                        var parent = iframe.closest('.lo')!.parentNode!;
                        var socialDiv = document.createElement("div");
                        if (parent.querySelector('.exe-socials')) {
                            socialDiv = (parent.querySelector('.exe-socials') as HTMLDivElement);
                        }

                        socialDiv.classList.add('exe-socials');
                        iframe.closest('.lo')!.parentNode!.insertBefore(socialDiv, iframe.closest('.lo')!.nextSibling);
                        ReactDOM.render(social, socialDiv);
                    }
                }

                //youtube
                //TODO:szükséged template
                // <section data-section-type="youtube"> 
                //     <span class="show-for-sr">Youtube videó</span>
                //         <div class="row column">
                // <h3><x-text data-id="title" data-title="Videó címe">Youtube</x-text></h3>
                //             <youtube id="[[id]]"><x-text data-id="src" data-title="Youtube link"></x-text></youtube>
                //         </div>
                //     <span class="show-for-sr">Youtube videó vége</span>
                // </section>
                const youtubelinks = Array.from(contentDiv.ownerDocument!.querySelectorAll("youtube"));
                for (const yt of youtubelinks) {

                    var youtubeDiv = document.createElement("div");
                    var src = yt.innerHTML; 
                    yt.parentNode!.insertBefore(youtubeDiv, yt);             
                    var ytLink = new URLSearchParams(src.split('?')[1]);    
                    const player = <YouTubeVideo id={ytLink.get("v")}></YouTubeVideo>
          
                    ReactDOM.render(player, youtubeDiv);
                    yt.remove();                  
                }//

                for (const renderedSection of this.props.renderedSections) {
                    const sectionElement = contentDiv.ownerDocument!.getElementById("section-" + renderedSection.id);
                    if (sectionElement && renderedSection.attachedAudios) {

                        const audioDiv = sectionElement.getElementsByClassName("audio-div").length > 0 ? sectionElement.getElementsByClassName("audio-div")[0] : contentDiv.ownerDocument!.createElement("div");
                        audioDiv.classList.add("audio-div");

                        const xaudio = sectionElement.querySelector("callout-audio");
                        const firstH3 = sectionElement.querySelector("h3");
                        if (xaudio) {
                            xaudio.parentNode!.insertBefore(audioDiv, xaudio.previousSibling);
                            setTimeout(() => xaudio.remove(), 1500);
                        }
                        else if (firstH3) {
                            firstH3.parentNode!.insertBefore(audioDiv, firstH3.previousSibling);
                        }
                        else {
                            audioDiv.classList.add("row", "column");
                            sectionElement.appendChild(audioDiv);
                        }
                        if (this.props.isSNIview) {
                            ReactDOM.render(<BookAudio audios={renderedSection.attachedAudios} />, audioDiv);
                        } else {
                            ReactDOM.render(<AudioPlayer audios={renderedSection.attachedAudios} id={renderedSection.id + ""} />, audioDiv);

                        }
                    }

                    // console.log("sectionElement!.querySelector", sectionElement!.querySelector("[data-file]"))
                    if (sectionElement!.querySelector("[data-file]")) {
                        const fileDiv = sectionElement!.querySelector("[data-file]")!;
                        const fileSrc = fileDiv.getAttribute("data-file");

                        ReactDOM.render(<a href={fileSrc!}><i className="fa fa-download"></i>&nbsp;{fileSrc}</a>, fileDiv);
                    }
                }

                searchNotForWeakSpansAndAddLayer(contentDiv as HTMLElement);


                await loadMXGraphScriptAsync();
                //mxgraph
                if (GraphViewer && GraphViewer.processElements) GraphViewer.processElements();

                var toggleControl = contentDiv.querySelectorAll('[data-toggle]');
                for (var i = 0; i < toggleControl.length; i++) {

                    toggleControl[i].addEventListener("click", function (e) {
                        jquery(e.target!).toggleClass('open');
                    });
                }

            }
        } catch (e) {
            console.log(e);
        }
    }

    wrapSubstring(sourceString:string, subString:string, tag:string) {
        return sourceString.replace(new RegExp("(" + subString + ")", "g"), "<" + tag + ">$1</" + tag + ">");
    }
    
    highlightWord(html:string, word:string)
    {
           
        if(html.indexOf(word) >-1)
        {
            return this.wrapSubstring(html, word,"match");
        }
        
        return html;
    }

    render() {
        let filteredRenderedSections = this.props.renderedSections;
        if (this.props.sectionTypes) {
            filteredRenderedSections = filteredRenderedSections.filter(s => containsSection(this.props.sectionTypes!, [s]));
        }

        const values = queryString.parse(location.search);

        return <section>
            <span className="show-for-sr">{__("Lecke terület kezdete")}</span>
            <h2 className="show-for-sr">{this.props.lessonName}</h2>
            <div
                id="content"
                className="main-content"
                ref="content"
                dangerouslySetInnerHTML={{ __html: filteredRenderedSections.map((x: any) => (values.w ? this.highlightWord(x.html, values.w) : x.html)).join("") }}
            >

            </div>
            {
                me && this.props.showCollectionMenu
                    ?
                    <>
                        {filteredRenderedSections
                            .filter(s => containsSection(SECTION_TYPES_TEXT, [s]))
                            .map(s => <SectionCollectionMenu
                                key={s.id}
                                tableInfoId={this.props.published ? SectionPubCrud.TABLE_INFO_ID : SectionCrud.TABLE_INFO_ID}
                                recId={s.id}
                                embedded={s.blockTemplateName == __("Beágyazott tartalom")}
                                element={"section-" + s.id} />)
                        }

                    </>
                    :
                    null
            }
            <span className="show-for-sr">{__("Lecke terület vége")}</span>
        </section>
    }

    /**
     * Csak feladatmotorokat mutatunk, ilyenkor a "feladat bezárva jelenik meg"-nek nincs értelme
     */
    private showOnlyExercises() {
        if (!this.props.sectionTypes) return false;

        return this.props.sectionTypes.filter(s => (s != SectionType.EXERCISE && s != SectionType.EXERCISE_SERIES && s != SectionType.IFRAME)).length === 0;
    }

}
