
import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';

/* Engine converters */
import { AExerciseTypeConverter, ShuffleResult, shuffle } from "@src/component/exercise/models/AExerciseTypeConverter";
import { EKEQuizConverter } from "@src/component/exercise/engine/EKEQuizExerciseEngine/EKEQuizConverter";
import { EKEMultiTextAnswerConverter } from "@src/component/exercise/engine/EKEMultiTextAnswerExerciseEngine/EKEMultiTextAnswerConverter";
import { EKEFillGapDropDownAnswerConverter } from "@src/component/exercise/engine/EKEFillGapDropDownExerciseEngine/EKEFillGapDropDownAnswerConverter";
import { EKEOddOneOutAnswerConverter } from "@src/component/exercise/engine/EKEOddOneOutExerciseEngine/EKEOddOneOutAnswerConverter";
import { EKEFillTableAnswerConverter } from "@src/component/exercise/engine/EKEFillTableDnDExerciseEngine/EKEFillTableAnswerConverter";
import { EKEFillGapAnswerConverter } from "@src/component/exercise/engine/EKEFillGapDnDExerciseEngine/EKEFillGapAnswerConverter";
import { WMItemToSetConverter } from "@src/component/exercise/engine/WMItemToSetDnDExerciseEngine/WMItemToSetConverter";
import { EKEQuizSeriesConverter } from "@src/component/exercise/engine/EKEQuizSeriesExerciseEngine/EKEQuizSeriesConverter";
import { WMAssignPointToQuestionConverter } from "@src/component/exercise/engine/WMAssignPointToQuestionExerciseEngine/WMAssignPointToQuestionConverter";
import { WMItemToImageConverter } from "@src/component/exercise/engine/WMItemToImageDnDExerciseEngine/WMItemToImageConverter";
import { WMFillTableTextConverter } from "@src/component/exercise/engine/WMFillTableTextExerciseEngine/WMFillTableTextConverter";
import { WMNumberPyramidConverter } from "@src/component/exercise/engine/WMNumberPyramidExerciseEngine/WMNumberPyramidConverter";
import { EKESortingConverter } from "@src/component/exercise/engine/EKESortingExerciseEngine/EKESortingConverter";
import { WMNumberLineConverter } from "@src/component/exercise/engine/WMNumberLineExerciseEngine/WMNumberLineConverter";
import { EKESudokuConverter } from "@src/component/exercise/engine/EKESudokuExerciseEngine/EKESudokuConverter";
import { EKECrossWordConverter } from "@src/component/exercise/engine/EKECrossWordExerciseEngine/EKECrossWordConverter";
import { EKEPairingConverter } from "@src/component/exercise/engine/EKEPairingExerciseEngine/EKEPairingConverter";
import { EKEPairingDnDConverter } from "@src/component/exercise/engine/EKEPairingDnDExerciseEngine/EKEPairingDnDConverter";
import { WMItemLinkLineConverter } from "@src/component/exercise/engine/WMItemLinkLineExerciseEngine/WMItemLinkLineConverter";
import { PuzzleGameConverter } from "@src/component/exercise/engine/PuzzleGame/PuzzleGameConverter";
import { MemoryCardGameConverter } from "@src/component/exercise/engine/MemoryCardGame/MemoryCardGameConverter";
import { WordFinderGameConverter } from "@src/component/exercise/engine/WordFinderGame/WordFinderGameConverter";
import { ColoringGameConverter } from "../engine/ColoringGame/ColoringGameConverter";
import { UniversalEngineConverter } from "@src/component/exercise/engine/UniversalEngine/UniversalEngineConverter";
import EngineCrud from "@src/framework/crud/exc/EngineCrud";
import { __ } from '@src/translation';
import { FillGapTextConverter } from "../engine/FillGapTextExerciseEngine/FillGapTextConverter";


export type ExerciseEngineInfo = {
    ExerciseConverterClass: new (props: any) => any;
    EngineName: ExerciseBaseTypes.ExerciseEngineTypes;
    TypeConverter: AExerciseTypeConverter;
    Image: string;
}

export class ExerciseEngineTypeCheck {

    static sniSupportingEngines: string[] = ["WMItemToImageDnDExerciseEngine", "WMItemToSetDnDExerciseEngine",
        "EKESortingExerciseEngine", "EKEQuizExerciseEngine",
        "EKEPairingDnDExerciseEngine", "EKEOddOneOutExerciseEngine",
        "EKEFillGapDnDExerciseEngine", /*"EKEFillGapDropDownExerciseEngine",*/
        "EKEFillTableDnDExerciseEngine", "WMNumberLineExerciseEngine",
        "EKESudokuExerciseEngine", "WMItemLinkLineExerciseEngine",
        /*"WMNumberPyramidExerciseEngine", "EKEMultiTextAnswerExerciseEngine", 
        "WMFillTableTextExerciseEngine", "EKECrossWordExerciseEngine",*/ 
        "MemoryCardGame", "PuzzleGame", /*"WordFinderGame",*/ 
        "ColoringGame", "WMAssignPointToQuestionExerciseEngine",
        "EKEQuizSeriesExerciseEngine", "EKEPairingExerciseEngine", "UniversalExerciseEngine"
    ];
    static subseriesEngines: string[] = ["EKEQuizSeriesExerciseEngine", "EKEPairingExerciseEngine", "WMAssignPointToQuestionExerciseEngine"];

    static notAccessibleEngines:string[] = ["WMNumberPyramidExerciseEngine","WMItemToImageDnDExerciseEngine","WMNumberLineExerciseEngine","WMAssignPointToQuestionExerciseEngine","EKESudokuExerciseEngine"];
    static notAccessibleEnginesId: number[] = [922884400, 1, 16102503500, 418405800, 14300];

    static AnswerChooseEngines:string[] = [ "EKEQuizExerciseEngine","EKEQuizSeriesExerciseEngine",
    "EKEPairingDnDExerciseEngine", "EKEPairingExerciseEngine","EKEOddOneOutExerciseEngine",
    "WMItemLinkLineExerciseEngine"];

    static SortingBasedEngines:string[] = ["WMItemToImageDnDExerciseEngine", "WMItemToSetDnDExerciseEngine",
    "EKESortingExerciseEngine",
    "EKEFillGapDnDExerciseEngine", "EKEFillGapDropDownExerciseEngine",
    "EKEFillTableDnDExerciseEngine", "WMNumberLineExerciseEngine",
    "EKESudokuExerciseEngine", "WMAssignPointToQuestionExerciseEngine"];

    static TextBasedEngines:string[] = ["WMNumberPyramidExerciseEngine", "EKEMultiTextAnswerExerciseEngine", 
    "WMFillTableTextExerciseEngine", "EKECrossWordExerciseEngine", "FillGapTextExerciseEngine"];

    static Games:string[] = ["MemoryCardGame", "WordFinderGame", "ColoringGame"];

    static AlwaysCorrectDisabledEngines:string[] = ["MemoryCardGame", "WordFinderGame"];

    static NoCommonShuffleEngines:string[] = ["EKEFillGapDropDownExerciseEngine", "EKEPairingDnDExerciseEngine", "EKEPairingExerciseEngine",
    "EKEQuizExerciseEngine", "EKESortingExerciseEngine", "EKESudokuExerciseEngine", "PuzzleGame", "WMItemLinkLineExerciseEngine",
    "WMItemToImageDnDExerciseEngine", "WMItemToSetDnDExerciseEngine", "WMNumberLineExerciseEngine", "FillGapTextExerciseEngine"];

    static isNoCommonShuffleEngine(engineName: string): boolean {
        if (this.NoCommonShuffleEngines.indexOf(engineName) > -1) return true;
        return false;
    }

    static isAlwaysCorrectDisabledEngine(engineName: string): boolean {
        if (this.AlwaysCorrectDisabledEngines.indexOf(engineName) > -1) return true;
        return false;
    }

    static isSNI(engineName: string): boolean {
        if (this.sniSupportingEngines.indexOf(engineName) > -1) return true;
        return false;
    }

    static isGame(engineName: string): boolean {
        if (this.Games.indexOf(engineName) > -1) return true;
        return false;
    }

    static isSubSeries(engineName: string): boolean {
        if (this.subseriesEngines.indexOf(engineName) > -1) return true;
        return false;
    }

    static isAccessibleEngine(engineName:string):boolean{
        if (this.notAccessibleEngines.indexOf(engineName) == -1) return true;
        return false;
    }
}

export class TypeConverterAssinment {
    //public static iconBaseUrl = "/img/IKON_SET/FELADATMOTOR/Exercise_engine_large_icons/";

    public static Converter: ExerciseEngineInfo[] =
        [
            { ExerciseConverterClass: EKECrossWordConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.CrossWord, TypeConverter: new EKECrossWordConverter(null), Image: "keresztrejtveny.svg" },
            { ExerciseConverterClass: EKEFillGapAnswerConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.FillGapDnD, TypeConverter: new EKEFillGapAnswerConverter(null), Image: "mondatkiegeszites_huzd_es_ejtsd.svg" },
            { ExerciseConverterClass: EKEFillGapDropDownAnswerConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.FillGapDropDown, TypeConverter: new EKEFillGapDropDownAnswerConverter(null), Image: "mondatkiegeszites_lista.svg" },
            { ExerciseConverterClass: EKEFillTableAnswerConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.FillTableDnD, TypeConverter: new EKEFillTableAnswerConverter(null), Image: "tablazat_DND.svg" },
            { ExerciseConverterClass: WMFillTableTextConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.FillTableText, TypeConverter: new WMFillTableTextConverter(null), Image: "tablazat_szabad_szoveg.svg" },
            { ExerciseConverterClass: WMItemToSetConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.ItemToSet, TypeConverter: new WMItemToSetConverter(null), Image: "halmaz.svg" },
            { ExerciseConverterClass: EKEMultiTextAnswerConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.MultiTextAnswer, TypeConverter: new EKEMultiTextAnswerConverter(null), Image: "szoveges_valaszto.svg" },
            { ExerciseConverterClass: EKEOddOneOutAnswerConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.OddOneOut, TypeConverter: new EKEOddOneOutAnswerConverter(null), Image: "kakuktojas.svg" },
            { ExerciseConverterClass: EKEPairingDnDConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.PairingDnD, TypeConverter: new EKEPairingDnDConverter(null), Image: "parositas_dnd.svg" },
            { ExerciseConverterClass: EKEPairingConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.PairingQuiz, TypeConverter: new EKEPairingConverter(null), Image: "parositas_kviz.svg" },
            { ExerciseConverterClass: EKEQuizConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.Quiz, TypeConverter: new EKEQuizConverter(null), Image: "kviz.svg" },
            { ExerciseConverterClass: EKEQuizSeriesConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.QuizSeries, TypeConverter: new EKEQuizSeriesConverter(null), Image: "kviz_sor.svg" },
            { ExerciseConverterClass: EKESortingConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.Sorting, TypeConverter: new EKESortingConverter(null), Image: "sorba.svg" },
            { ExerciseConverterClass: EKESudokuConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.Sudoku, TypeConverter: new EKESudokuConverter(null), Image: "sudoku.svg" },
            { ExerciseConverterClass: WMNumberPyramidConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.NumberPyramid, TypeConverter: new WMNumberPyramidConverter(null), Image: "szampiramis.svg" },
            { ExerciseConverterClass: WMItemToImageConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.ItemToImage, TypeConverter: new WMItemToImageConverter(null), Image: "teruletre_huzas.svg" },
            { ExerciseConverterClass: WMNumberLineConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.NumberLine, TypeConverter: new WMNumberLineConverter(null), Image: "szamegyenes.svg" },
            { ExerciseConverterClass: WMItemLinkLineConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.LinkLine, TypeConverter: new WMItemLinkLineConverter(null), Image: "osszekotos_parositas.svg" },
            { ExerciseConverterClass: WMAssignPointToQuestionConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.AssignPointToQuestion, TypeConverter: new WMAssignPointToQuestionConverter(null), Image: "vakterkep.svg" },
            { ExerciseConverterClass: UniversalEngineConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.Universal, TypeConverter: new UniversalEngineConverter(null), Image: "univerzalis_light.svg" },
            { ExerciseConverterClass: PuzzleGameConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.Puzzle, TypeConverter: new PuzzleGameConverter(null), Image: "kirako.svg" },
            { ExerciseConverterClass: MemoryCardGameConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.Memory, TypeConverter: new MemoryCardGameConverter(null), Image: "memoria.svg" },
            { ExerciseConverterClass: WordFinderGameConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.WordFinder, TypeConverter: new WordFinderGameConverter(null), Image: "szokereso.svg" },
            { ExerciseConverterClass: ColoringGameConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.Coloring, TypeConverter: new ColoringGameConverter(null), Image: "kifesto.svg" },
            { ExerciseConverterClass: FillGapTextConverter, EngineName: ExerciseBaseTypes.ExerciseEngineTypes.FillGapText, TypeConverter: new FillGapTextConverter(null), Image: "mondatkiegeszites_lista.svg"}
        ]
}

export function decodeHtmlEntity(stringToConvert: string): string {
    return stringToConvert.replace(/&#?(\w+);/g, function (match, dec) {
        var a = document.createElement('a'); a.innerHTML = match;
        var deco = a.textContent;
        return deco ? deco : "";
    });

}

export function getIconOfEngine(exeEngineName: string, isLarge: boolean, isLight?: boolean): string {
    let iconBaseUrl: string = isLarge ? "/img/IKON_SET/FELADATMOTOR/Exercise_engine_large_icons/" : isLight ? "/img/IKON_SET/FELADATMOTOR/Exercise_engine_small_icons/light/" : "/img/IKON_SET/FELADATMOTOR/Exercise_engine_small_icons/dark/";

    let img = "kviz.svg";

    if (exeEngineName) {
        var curr_converter = TypeConverterAssinment.Converter.find((element: ExerciseEngineInfo) => element.EngineName == exeEngineName);

        if (curr_converter && curr_converter.Image) {
            img = curr_converter.Image;
        }
    }
    return iconBaseUrl + img;
}

export async function getIconOfEngineByDisplayName(exeEngineDisplayName: string, isLarge: boolean, isLight?: boolean): Promise<string> {
    let list = await EngineCrud.list({ filter: { name: exeEngineDisplayName } });
    let engineName = "";
    if (list && list.length > 0) {
        engineName = list[0].class_name!;
    }
    return getIconOfEngine(engineName, isLarge, isLight);

}

export async function getDisplayNameOfEngine(exeEngineName: string): Promise<string> {
    let list = await EngineCrud.list({ filter: { class_name: exeEngineName } });
    if (list && list.length > 0) {
        return list[0].name!;
    }
    return "";
}


