import * as React from 'react';

import  { IViewTanarXxTpwdRecord, ViewTanarXxTpwdClassProxy, viewTanarXxTpwdClassProxy } from '@src/framework/view/sys/ViewTanarXxTpwd';
import ListView from '@framework/forms/listview';

class TanarXxTPwdInner extends ListView<IViewTanarXxTpwdRecord> {
    public getViewClassProxy() : ViewTanarXxTpwdClassProxy { return viewTanarXxTpwdClassProxy; }
}

export default function TanarXxTPwdListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <TanarXxTPwdInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "id", maxWidth: 100},
            {accessor: "login_name"},
            {accessor: "modification_time"},
            {accessor: "pwd"},
        ]}
    />
}
