import * as React from 'react';

import WfTypeListView from './wf_type_listview';
import WfTypeEditor from './wf_type_editor';
import { viewWfTypeClassProxy } from '@src/framework/view/wf/ViewWfType';

import StationListView from './station_listview';
import StationEditor from './station_editor';
import { viewStationClassProxy } from '@src/framework/view/wf/ViewStation';

import SubstationListView from './substation_listview';
import SubstationEditor from './substation_editor';
import { viewSubstationClassProxy } from '@src/framework/view/wf/ViewSubstation';

import HeadTableListView from './head_table_listview';
import HeadTableEditor from './head_table_editor';
import { viewWfHeadTableClassProxy } from '@framework/view/wf/ViewWfHeadTable';

import WfWorkflowListView from './wf_workflow_listview';
import WorfklowEditor from './wf_workflow_editor';
import { viewWfWorkflowClassProxy } from '@framework/view/wf/ViewWfWorkflow';


import WfTypeGraph from './wf_type_graph';


import WfTeszt from './wf_teszt';

import AdminRoute from '@src/Routes'
import WfStationConstraintMatrixEditor from '@src/component/wf/wf_station_constraint_matrix_editor';
import WfTransitionMatrixEditor from '@src/component/wf/wf_transition_matrix_editor';


export function WfRoutes() {
    return [
        <AdminRoute path={viewWfWorkflowClassProxy.getDefaultListRoute()} component={WfWorkflowListView} key="WfWorkflowListView"/>,
        <AdminRoute path={viewWfWorkflowClassProxy.getDefaultEditRoute()} component={WorfklowEditor} key="WorfklowEditor"/>,

        <AdminRoute path={viewWfTypeClassProxy.getDefaultListRoute()} component={WfTypeListView}  key="WfTypeListView"/>,
        <AdminRoute path={viewWfTypeClassProxy.getDefaultEditRoute()} component={WfTypeEditor}  key="WfTypeEditor"/>,
        
        <AdminRoute path={viewStationClassProxy.getDefaultListRoute()} component={StationListView}  key="StationListView"/>,
        <AdminRoute path={viewStationClassProxy.getDefaultEditRoute()} component={StationEditor}  key="StationEditor"/>,

        <AdminRoute path={viewSubstationClassProxy.getDefaultListRoute()} component={SubstationListView}  key="SubstationListView"/>,
        <AdminRoute path={viewSubstationClassProxy.getDefaultEditRoute()} component={SubstationEditor}  key="SubstationEditor"/>,

        <AdminRoute path={viewWfHeadTableClassProxy.getDefaultListRoute()} component={HeadTableListView}  key="HeadTableListView"/>,
        <AdminRoute path={viewWfHeadTableClassProxy.getDefaultEditRoute()} component={HeadTableEditor} key="HeadTableEditor"/>,

        <AdminRoute path="/wf/station_constraint_matrix/:recId?" component={WfStationConstraintMatrixEditor} key="WfStationMatrixEditor"/>,
        <AdminRoute path="/wf/transition_matrix/:recId?" component={WfTransitionMatrixEditor} key="WfTransitionMatrixEditor"/>,
        

        <AdminRoute path="/wf/graph/:wfTypeId" component={WfTypeGraph} key="WfTypeGraph"/>,

        <AdminRoute path="/wf/teszt" exact={true} component={WfTeszt} key="WfTeszt"/>,
        

    ];
}