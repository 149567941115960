import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISectionRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* double precision */|null;
    /**
     * Lecke
     *
     * NOT NULL -> doc.lesson ON DELETE cascade
     *
     */
    lesson_id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Blokk sablon
     *
     * NOT NULL -> doc.block_template ON DELETE cascade
     *
     */
    block_template_id?: number;
    /**
     * Értékek
     */
    values?: any;
    /**
     * Feladat
     *
     * -> exc.exercise ON DELETE noaction
     *
     */
    exercise_id?: number|null;
    /**
     * Feladatlista
     *
     * -> exc.exercise_series ON DELETE noaction
     *
     */
    exercise_series_id?: number|null;
    /**
     * Segítő szöveg
     *
     * Segítő szöveg, elsősorban alsós koncepcióhoz.
     */
    help_text?: string|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
    /**
     * Feladat alapból elrejtve jelenik meg
     */
    exercise_is_hidden_by_default?: boolean;
    /**
     * Tanári segédanyagok között jelenik meg
     */
    is_teachers_guide?: boolean;
}

/**
 *  doc.section - Szekció
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class SectionCrud extends Crud<ISectionRecord> {
    public static TABLE_INFO_ID = 2456511295;
    public static getSchemaNameForClass() { return 'doc'; }
    public static getTableNameForClass() { return 'section'; }

    public static load: (id: number, aServer ?: Server) => Promise<SectionCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISectionRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISectionRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISectionRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISectionRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISectionRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SectionCrudClassProxy extends CrudClassProxy<ISectionRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const sectionCrudClassProxy = new SectionCrudClassProxy(SectionCrud);
registerCrudClassProxy(sectionCrudClassProxy);
