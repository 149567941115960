import * as React from 'react'
import { OfferBlock } from '@src/component/media/VideoComponent';
import * as Server from '@src/server/PublicServer';
import { __ } from '@src/translation';
import { History, Location } from 'history';
import * as queryString from 'query-string';

function setParams(history: History, location: Location, paramtype?: string) {
    const params = {
        type: paramtype,
        extendedList: true
    }
    history.push(location.pathname + "?" + queryString.stringify(params));
}

export async function getOfferDivs(history: History, location: Location, title: string, mediaTypeId: "video" | "audio" | "image" | "map", collection: any[], needLinkForMoreResults?: boolean, paramtype?: string, keywords?: string) {
    if ((!collection || collection.length == 0)) {
        return null;
    }

    const durationAndSha1: Server.Sha1AndDurationElement[] = await Server.getDurationAndSha1(collection.map(h => h.sha1!).filter(s => s));

    return <div key={title} className="eke-media-container">
        <h3 className="eke-media-offers-title">{title}</h3>
        <div className="eke-media-offers" data-media-type={mediaTypeId}>
            {collection.map((item) => {
                let shaDur = durationAndSha1.find(i => i.sha1 == item.sha1);
                return <OfferBlock mediaType={mediaTypeId} key={item.id} item={item} durationSeconds={shaDur && shaDur.duration} plays={shaDur && shaDur.plays} />
            })}
            {needLinkForMoreResults ?
                <div className="small-12 column" style={{ float: "right" }}>
                    <button onClick={()=>setParams(history, location, paramtype)} style={{color: "#0F669E"}}>
                        <b>{__("További találatok")}</b><br />
                    </button>
                </div>
                : ""
            }

        </div>
    </div>
}