import * as React from 'react';
import { LessonPlanEditState, LessonPlanEditProps, LessonPlanEdit, AsyncStatus, EMPTY_TECHICAL } from '@src/component/lessonplan/LessonPlanEdit';
import KipDraftSubjectCrud, { IKipDraftSubjectRecord, kipDraftSubjectCrudClassProxy } from '@src/framework/crud/kap/KipDraftSubjectCrud';
import KipDraftCrud, { IKipDraftRecord } from '@src/framework/crud/kap/KipDraftCrud';
import KipDraftBlockCrud, { IKipDraftBlockRecord } from '@src/framework/crud/kap/KipDraftBlockCrud';
import { LIBRARY_OFI_OFFICIAL_ID, LIBRARY_JTR_BEADANDO_ID } from '@src/Const';
import { HTMLTextarea } from '@src/component/HtmlTextarea';
import { __, getLanguage } from '@src/translation';
import KipDraftBlockSubprogramCrud, { IKipDraftBlockSubprogramRecord, kipDraftBlockSubprogramCrudClassProxy } from '@src/framework/crud/kap/KipDraftBlockSubprogramCrud';
import { app, history } from '@src/index';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import { literacyFieldCrudClassProxy } from '@src/framework/crud/doc/LiteracyFieldCrud';
import { CrudMultiSelect } from '@src/framework/forms/crudmultiselect';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import { getMissingFields } from '@src/Util';
import KipDraftBlockTypeCrud, { IKipDraftBlockTypeRecord } from '@src/framework/crud/kap/KipDraftBlockTypeCrud';
import KipDraftGroupTaskCrud, { IKipDraftGroupTaskRecord } from '@src/framework/crud/kap/KipDraftGroupTaskCrud';
import { AccordionItem } from '@src/component/ui/Accordion';
import KipDraftGroupTaskItemCrud, { IKipDraftGroupTaskItemRecord } from '@src/framework/crud/kap/KipDraftGroupTaskItemCrud';
import ViewKapDraft from '@src/framework/view/kap/ViewKapDraft';
import { fetchKeywords, setKeywords } from '@src/component/meta/meta_keyword_assigner_plugin';
import { groupTypeCrudClassProxy } from '@src/framework/crud/kap/GroupTypeCrud';
import { lessonTypeCrudClassProxy } from '@src/framework/crud/kap/LessonTypeCrud';
import { IViewTransitionTypeRecordWithPerm } from '@src/framework/wf/WfAPI';
import { getMetaCategoryTree } from '@src/component/meta/meta_category_assigner_api';
import { KAP_META_KOMPETENCIA } from './DraftEditList';
import { Comment } from '@src/component/ui/Comment';
import { getDraftComment, setDraftComment } from '@src/server/PublicServer';
import { updateSubrecords } from '@src/component/filemanager/UpdateSubrecords';
import { DownloadAsPdf } from './DownloadAdPdf';
import { match } from 'react-router';
import * as queryString from 'query-string';
import { PATH_ATTENDANCE_DRAFT_UPLOAD } from '../attendance/Attendance';
import { me } from '@src/framework/server/Auth';

export const PATH_KIP_DRAFT_EDIT = "/oravazlat/kip";

const SUBJECT_PE_ID = 180711000;

export class KipDraftEditPage extends React.Component<{ match: match<{ id?: string }> }> {

    render() {
        const params = queryString.parse(location.search);

        const libraryId = params.library_id ? Number(params.library_id) : undefined;

        return <KipDraftEdit
            id={this.props.match.params.id ? Number(this.props.match.params.id) : undefined}
            onSave={newId => history.push(PATH_KIP_DRAFT_EDIT + "/" + newId + location.search)}
            defaultLibraryId={libraryId}
            backLinkTo={libraryId == LIBRARY_JTR_BEADANDO_ID ? PATH_ATTENDANCE_DRAFT_UPLOAD : undefined}
        />;
    }

}

type KipDraftEditState = {
    subjects: IKipDraftSubjectRecord[];

    subjectIds: number[];

    blockTypes: IKipDraftBlockTypeRecord[];

    groupTasks: IKipDraftGroupTaskRecord[];

    comments: any;
    publicationConfirmed: string;
} & LessonPlanEditState<IKipDraftRecord, IKipDraftBlockRecord>;

export class KipDraftEdit extends LessonPlanEdit<LessonPlanEditProps, KipDraftEditState> {

    constructor(props: LessonPlanEditProps) {
        super(props);

        this.state = {
            lessonPlan: { library_id: this.props.defaultLibraryId || LIBRARY_OFI_OFFICIAL_ID },
            lessonPlanBlocks: [{
                subprograms: [],
            }] as any,
            folders: [],
            deletedBlocks: [],
            status: AsyncStatus.LOADING,
            ownerName: "",

            categorization: {},
            subject_ids: {},

            workflowDialogOpen: false,

            subjects: [],

            subjectIds: [],

            blockTypes: [],
            groupTasks: [],

            keywords: [],

            comments: undefined,
            publicationConfirmed: ""


        }
    }

    async componentDidMount() {
        const blockTypes = await KipDraftBlockTypeCrud.list({ filter: { is_active: true }, order_by: "no" });
        this.setState({
            blockTypes,
            lessonPlanBlocks: blockTypes.map(b => {
                return {
                    kip_draft_block_type_id: b.id,

                }
            })
        }, () => {
            if (this.props.id) {
                this.reloadAsync();
            } else {
                this.setState({ status: AsyncStatus.LOADED });
            }
        })

    }

    componentDidUpdate(prevProps: LessonPlanEditProps) {
        if (this.props.id && this.props.id !== prevProps.id) {
            this.reloadAsync();
        }

        $('.accordion-title input').click(function (e) {
            e.stopPropagation();
        });
    }

    getTitles() {
        return {
            mainTitle: __("DFHT-KIP óravázlat"),
            mainPanelTitle: __("Adatlap"),
            blockPanelTitle: __("Az óra menete"),
            secondPanelTitle: __("Mellékletek"),
            attachmentPanelTitle: __("Csatolt állományok"),
            blockTitle: __("Sor"),
            disableBlockOrder: true,
            tableInfoId: KipDraftCrud.TABLE_INFO_ID,
            disableCheckforNodesWithLeaf: true,
        }
    }

    async reloadAsync() {
        const id = this.props.id;
        if (!id) return;

        try {
            const lessonPlan = (await KipDraftCrud.load(id)).record;

            const comments = await getDraftComment("kip_draft_" + id);

            const listParams = {
                filter: {
                    is_active: true,
                    kip_draft_id: lessonPlan.id,
                },
                order_by: "id"
            };

            const lessonPlanBlocks = await KipDraftBlockCrud.list({
                filter: {
                    is_active: true,
                    kip_draft_id: lessonPlan.id,
                },
                order_by: "no"
            });

            const subjects = await KipDraftSubjectCrud.list(listParams);

            for (const block of lessonPlanBlocks) {
                const subprograms = await KipDraftBlockSubprogramCrud.list({ filter: { is_active: true, kip_draft_block_id: block.id } });
                block["subprograms"] = subprograms;
                block["subprogramIds"] = subprograms.map(s => s.subprogram_id);
            }


            const groupTasks = await KipDraftGroupTaskCrud.list(listParams);

            for (const groupTask of groupTasks) {
                const items = await KipDraftGroupTaskItemCrud.list({ filter: { is_active: true, kip_draft_group_task_id: groupTask.id }, order_by: "id" });
                groupTask["items"] = items;
                groupTask["item_ids"] = items.map(i => i.id);
            }

            const viewLessonPlan = (await ViewKapDraft.load(lessonPlan.id!)).record;


            const keywords = await fetchKeywords(KipDraftCrud.TABLE_INFO_ID, lessonPlan.id!);

            let pub;
            if (lessonPlan.consent_publication && lessonPlan.consent_publication != null) {
                pub = "pub_confirm";
            } else if (lessonPlan.consent_publication == null) {
                pub = "pub_not_confirm";
            } else {
                pub = "";
            }

            this.setState({
                status: AsyncStatus.LOADED,
                lessonPlan: lessonPlan,
                lessonPlanBlocks,
                deletedBlocks: [],
                subjects,
                subjectIds: subjects.map(s => s.subject_id!),
                groupTasks,
                workflowStateName: viewLessonPlan.wf_station_name || viewLessonPlan.jtr_wf_station_name || "",
                keywords,
                comments,
                publicationConfirmed: pub,
            });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }


    protected onBeforeTransition(transition: IViewTransitionTypeRecordWithPerm) {
        if (this.state.keywords.length == 0 && !transition.dst_is_deleted) {
            app.showError("Hiba", __("Kérem adjon meg kulcsszavakat!"));
            return false;
        }
        if (this.state.categorization[KAP_META_KOMPETENCIA] && this.state.lessonPlan.library_id == LIBRARY_JTR_BEADANDO_ID && this.state.categorization[KAP_META_KOMPETENCIA]!.length < 1 && !transition.dst_is_deleted) {
            app.showError("Hiba", __("Legalább egy metaadat megadása kötelező!"));
            return false;
        }


        return true;
    }

    async onSave() {
        try {

            let toomuchcatTitles: string[] = [];
            const treeRoot = await getMetaCategoryTree(KAP_META_KOMPETENCIA);

            const selectedIds = this.state.categorization[KAP_META_KOMPETENCIA];

            if (this.state.lessonPlan.library_id == LIBRARY_JTR_BEADANDO_ID && this.state.publicationConfirmed == "") {
                app.showError("Hiba", __("Kérem jelezze, hogy hozzájárul-e az esetleges publikus megjelenéshez!"));
                return;
            }
            if (selectedIds) {
                let count = 0;
                for (let i = 0; i < treeRoot.children.length; i++) {
                    count = 0;
                    for (let k = 0; k < treeRoot.children[i].children.length; k++) {
                        if (selectedIds.includes(treeRoot.children[i].children[k].id)) {
                            count++;
                        }
                    }
                    if (count > 4) {
                        toomuchcatTitles.push(treeRoot.children[i].title);
                    }
                }
            }
            if (toomuchcatTitles.length > 0) {
                app.showError(__("Sikertelen mentés"), __(`Egyszerre maximum 4 kompetencia adható meg! 4-nél több lett megadva a következőkben: `) + toomuchcatTitles.join(", "));
                return;
            }

            let missingFields = await getMissingFields(KipDraftCrud as any, this.state.lessonPlan, ["oo_folder_id"]);

            for (const lessonPlanBlock of this.state.lessonPlanBlocks) {
                if (!lessonPlanBlock.require_time_min || lessonPlanBlock.require_time_min <= 0) {
                    app.showError(__("Hiba"), __("Az időkorlátnak nullánál nagyobb számnak kell lennie!"));
                    return;
                }

                missingFields = missingFields.concat(await getMissingFields(KipDraftBlockCrud as any, lessonPlanBlock, ["kip_draft_id"]));

                const type = this.state.blockTypes.find(t => t.id == lessonPlanBlock.kip_draft_block_type_id);
                if (type && type.min_time && lessonPlanBlock.require_time_min && type.max_time &&
                    (type.min_time > lessonPlanBlock.require_time_min || type.max_time < lessonPlanBlock.require_time_min)) {
                    app.showError(__("Hiba"), `A megadott idő: ${lessonPlanBlock.require_time_min} perce nem esik bele az időkeretbe: ${type.min_time}-${type.max_time} `);
                    return;
                }
            }

            for (const groupTask of this.state.groupTasks) {
                missingFields = missingFields.concat(await getMissingFields(KipDraftGroupTaskCrud as any, groupTask, ["kip_draft_id"]));

                for (const item of (groupTask as any).items) {
                    missingFields = missingFields.concat(await getMissingFields(KipDraftGroupTaskItemCrud as any, item, ["kip_draft_group_task_id"]));
                }
            }

            if (missingFields.length) {
                app.showError(__("Sikertelen mentés"), __("Töltse ki az összes kötelező mezőt: {details}", { details: missingFields.join(", ") }));
                return;
            }

            this.setState({ status: AsyncStatus.SAVING });

            const lp = this.state.lessonPlan;
            if (this.state.publicationConfirmed == "pub_confirm") {
                lp.consent_publication = "now";
            } else if (this.state.publicationConfirmed == "pub_not_confirm") {
                lp.consent_publication = null;
            } else {
                lp.consent_publication = undefined;
            }
            let toSave = { ...lp, ...EMPTY_TECHICAL };

            const lessonPlan = (await new KipDraftCrud(toSave).put()).record;

            if (this.state.comments) {
                await setDraftComment("kip_draft_" + lessonPlan.id, this.state.comments);
            }

            await setKeywords(
                KipDraftCrud.TABLE_INFO_ID, lessonPlan.id!,
                this.state.keywords
            );

            await updateSubrecords<IKipDraftSubjectRecord>(
                this.state.subjects, this.state.subjectIds, kipDraftSubjectCrudClassProxy, "kip_draft_id", lessonPlan.id!, "subject_id",
            );

            var index = 1;
            for (const lessonPlanBlock of this.state.lessonPlanBlocks) {

                if (lessonPlanBlock.id) {
                    lessonPlanBlock.kip_draft_id = undefined;
                } else {
                    lessonPlanBlock.kip_draft_id = lessonPlan.id;
                }

                lessonPlanBlock.no = index;
                index++;
                const newBlock = (await new KipDraftBlockCrud(lessonPlanBlock).put()).record;

                await updateSubrecords<IKipDraftBlockSubprogramRecord>(
                    lessonPlanBlock["subprograms"] || [], lessonPlanBlock["subprogramIds"] || [], kipDraftBlockSubprogramCrudClassProxy, "kip_draft_id", newBlock.id!, "subprogram_id"
                );


            }
            for (const deletedId of this.state.deletedBlocks) {
                await new KipDraftBlockCrud({ id: deletedId }).del();
            }

            index = 1;
            for (const groupTask of this.state.groupTasks) {
                if (groupTask.id) {
                    groupTask.kip_draft_id = undefined;
                } else {
                    groupTask.kip_draft_id = lessonPlan.id;
                }

                groupTask.no = index;
                index++;

                const savedGroupTask = (await new KipDraftGroupTaskCrud(groupTask).put()).record;

                if (groupTask["items"] !== undefined) {
                    for (const item of groupTask["items"]) {
                        item.kip_draft_group_task_id = savedGroupTask.id;
                        await new KipDraftGroupTaskItemCrud(item).put();
                    }
                }
                if (groupTask["item_ids"] !== undefined) {
                    for (const itemId of groupTask["item_ids"]) {
                        if (groupTask["items"] !== undefined) {
                            if (groupTask["items"].some((item: any) => item.id == itemId)) continue;
                        }

                        await KipDraftGroupTaskItemCrud.deleteById(itemId);
                    }
                }
            }

            await this.saveCategories(lessonPlan.id!);

            app.showSuccess(__("Óravázlat lementve"), __("Óravázlat lementve"));

            if (lessonPlan.id != this.props.id) {
                this.props.onSave(lessonPlan.id!);
            } else if (lessonPlan.id) {
                this.reloadAsync();
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }

        this.setState({
            status: AsyncStatus.LOADED
        })
    }

    private onChangeComment(propName: string, value: any) {
        this.setState({ isDataChanged: true, comments: { ...this.state.comments, [propName]: value } });
    }

    protected getExtraButtons() {
        if (!this.state.lessonPlan.id) return null;

        return <>
            <br />
            <DownloadAsPdf
                id={this.state.lessonPlan.id}
                type="kip_draft"
                published={false}
            />
            {/*
                me && hasGroup(me, Groups.Developer) &&
                <DownloadAsPdf 
                    id={this.state.lessonPlan.id}
                    type="kip_draft"
                    filenameForDebugging={"kip_draft.html"}
                    published={false}
                />
            */}
        </>
    }

    changeConfirmation(e: any) {
        const target = e!.target;
        let value = target.value;
        this.setState({ publicationConfirmed: value, isDataChanged: true });
    }

    protected getConfirm() {
        return this.state.lessonPlan.library_id == LIBRARY_JTR_BEADANDO_ID ?
            <div className="row callout confirm-box">
                <div className="column">
                    <fieldset>
                        <label><p><input type="radio" checked={this.state.publicationConfirmed == "pub_confirm"} value="pub_confirm" name="confirm" onChange={this.changeConfirmation.bind(this)} disabled={!this.state.lessonPlan.owner_id || (me!.id == this.state.lessonPlan.owner_id && this.state.publicationConfirmed != "pub_confirm") ? false : true}></input> {__("Hozzájárulok, hogy az SZTR online felületére feltöltött, általam készített, az EFOP-3.1.216-2016-00001 azonosítószámú projekt keretében megbízott Szakmai Támogatás Munkacsoport szakemberei által kiválónak vélt óratervek a Komplex Alapprogram Tudástárába feltöltésre kerüljenek a nevem feltüntetése mellett. A feltöltés tényéről értesítést kapok.")} <strong>{__("(A hozzájárulást később nem lehet visszavonni!)")}</strong></p></label>
                        <label><p><input type="radio" checked={this.state.publicationConfirmed == "pub_not_confirm"} value="pub_not_confirm" name="confirm" onChange={this.changeConfirmation.bind(this)} disabled={!this.state.lessonPlan.owner_id || (me!.id == this.state.lessonPlan.owner_id && this.state.publicationConfirmed != "pub_confirm") ? false : true}></input>{__("Nem járulok hozzá.")}</p></label>
                    </fieldset>
                </div>

            </div> : <></>;
    }

    getFormBeforeBlocks() {
        return <div className="row">
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.authors}
                    onChange={e => this.onChangeComment("authors", e)}
                />
                <label>
                    {__("Szerző(k)")}*:
                </label>

                <textarea rows={2} value={this.state.lessonPlan.authors || ""} onChange={e => this.onChange("authors", e.target.value)} />

            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.literacy_field_id}
                    onChange={e => this.onChangeComment("literacy_field_id", e)}
                />
                <label>
                    {__("Műveltségi terület")}*:
                </label>
                <CrudSelectComponent
                    crudClassProxy={literacyFieldCrudClassProxy}
                    value={this.state.lessonPlan.literacy_field_id || null}
                    filter={{ is_active: true }}
                    onSelect={(_, id) => this.onChange("literacy_field_id", id)}
                    displayFieldName="name"
                    orderByFieldName="name"
                />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.subject_id}
                    onChange={e => this.onChangeComment("subject_id", e)}
                />
                <label>
                    {__("Tantárgy")}*:
                </label>
                <CrudSelectComponent
                    crudClassProxy={subjectCrudClassProxy}
                    value={this.state.lessonPlan.subject_id || null}
                    filter={{ is_active: true }}
                    onSelect={(_, id) => this.onChange("subject_id", id)}
                    displayFieldName="name"
                    orderByFieldName="name"
                />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.grade_id}
                    onChange={e => this.onChangeComment("grade_id", e)}
                />
                <label>
                    {__("Évfolyam")}*:
                </label>
                <CrudSelectComponent
                    crudClassProxy={gradeCrudClassProxy}
                    value={this.state.lessonPlan.grade_id || null}
                    emptyTitle={__("Minden osztály")}
                    filter={{ is_active: true }}
                    onSelect={(_, id) => this.onChange("grade_id", id)}
                    displayFieldName="name"
                    orderByFieldName="id"
                    key="id"
                    sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments.group_type_id}
                    onChange={e => this.onChangeComment("group_type_id", e)}
                />
                <label>
                    {__("Csoport / osztály típusa")}:
                </label>
                <CrudSelectComponent
                    crudClassProxy={groupTypeCrudClassProxy}
                    value={this.state.lessonPlan.group_type_id || null}
                    onSelect={(_, id) => this.onChange("group_type_id", id)}
                    displayFieldName="title"
                    orderByFieldName="id"
                />
            </div>
            <div className="column small-6">
                <Comment
                    value={this.state.comments && this.state.comments.group_type_other}
                    onChange={e => this.onChangeComment("group_type_other", e)}
                />
                <label>
                    {__("Egyéb")}:
                </label>
                <input type="text" value={this.state.lessonPlan.group_type_other || ""} onChange={e => this.onChange("group_type_other", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.thematic_unit}
                    onChange={e => this.onChangeComment("thematic_unit", e)}
                />
                <label>
                    {__("Téma / modul / tematikus egység")}:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.thematic_unit || ""} onChange={e => this.onChange("thematic_unit", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.topic}
                    onChange={e => this.onChangeComment("topic", e)}
                />
                <label>
                    {__("Az óra témája")}*:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.topic || ""} onChange={e => this.onChange("topic", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.lessonPlan}
                    onChange={e => this.onChangeComment("lessonPlan", e)}
                />
                <label>
                    {__("Az óra cél- és feladatrendszere")}*:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.goal || ""} onChange={e => this.onChange("goal", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.lesson_type_id}
                    onChange={e => this.onChangeComment("lesson_type_id", e)}
                />
                <label>
                    {__("Az óra típusa")}*:
                </label>
                <CrudSelectComponent
                    crudClassProxy={lessonTypeCrudClassProxy}
                    value={this.state.lessonPlan.lesson_type_id || null}
                    onSelect={(_, id) => this.onChange("lesson_type_id", id)}
                    displayFieldName="title"
                    orderByFieldName="id"
                />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.subjectIds}
                    onChange={e => this.onChangeComment("subjectIds", e)}
                />
                <label>
                    {__("Tantárgyi kapcsolatok")}:
                </label>
                <CrudMultiSelect
                    crudClassProxy={subjectCrudClassProxy}
                    displayFieldName="name"
                    orderByFieldName="name"
                    values={this.state.subjectIds}
                    filter={{ is_active: true }}
                    onChange={values => this.setState({ subjectIds: values, isDataChanged: true })}
                />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.cross_curriculum_connections}
                    onChange={e => this.onChangeComment("cross_curriculum_connections", e)}
                />
                <label>
                    {__("Kereszttantervi kapcsolatok")}:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.cross_curriculum_connections || ""} onChange={e => this.onChange("cross_curriculum_connections", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.tools}
                    onChange={e => this.onChangeComment("tools", e)}
                />
                <label>
                    {__("Taneszközök")}*:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.tools || ""} onChange={e => this.onChange("tools", e.target.value)} />
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.used_sources}
                    onChange={e => this.onChangeComment("used_sources", e)}
                />
                <label>
                    {__("Felhasznált források, segédletek")}:
                </label>
                <textarea rows={2} value={this.state.lessonPlan.used_sources || ""} onChange={e => this.onChange("used_sources", e.target.value)} />
            </div>
        </div>;
    }

    getFormAfterBlocks() {
        return <div className="row">
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments.html_body}
                    onChange={e => this.onChangeComment("html_body", e)}
                />
                <label>
                    {__("Mellékletek")}:
                    </label>
                <HTMLTextarea
                    key={this.state.lessonPlan.id || ""}
                    value={this.state.lessonPlan.html_body || ""}
                    onChange={(text) => this.onChange("html_body", text)}
                    onAddImage={this.state.lessonPlan.id ? this.onAddImage.bind(this) : undefined}
                />
            </div>
        </div>;
    }

    protected getExtraForm() {
        return <AccordionItem title={"Csoportmunka"}>
            <div className="row">
                <div className="column small-12">
                    <Comment
                        value={this.state.comments && this.state.comments.big_tought_html}
                        onChange={e => this.onChangeComment("big_tought_html", e)}
                    />
                    <label>
                        {__("Nagy gondolat")}*:
                    </label>
                    <HTMLTextarea
                        value={this.state.lessonPlan.big_tought_html || ""}
                        onChange={(text) => this.onChange("big_tought_html", text)}
                        onAddImage={this.state.lessonPlan.id ? this.onAddImage.bind(this) : undefined}
                    />
                </div>
                <div className="column small-12">
                    <Comment
                        value={this.state.comments && this.state.comments.introduction_html}
                        onChange={e => this.onChangeComment("introduction_html", e)}
                    />
                    <label>
                        {__("Ráhangolás, motiváció felkeltése, motivációs eszközök bemutatása")}:
                    </label>
                    <HTMLTextarea
                        value={this.state.lessonPlan.introduction_html || ""}
                        onChange={(text) => this.onChange("introduction_html", text)}
                        onAddImage={this.state.lessonPlan.id ? this.onAddImage.bind(this) : undefined}
                    />
                </div>

                {
                    this.state.groupTasks.map((g, i) => {
                        const items = g["items"]
                        return <div className="column small-12" style={{ marginTop: "1em" }}>
                            <div>
                                <Comment
                                    value={this.state.comments && this.state.comments["group_description_" + i]}
                                    onChange={e => this.onChangeComment("group_description_" + i, e)}
                                />
                                <label>
                                    {i + 1}. Csoportfeladat:&nbsp;
                                        </label>
                                <button className="button tiny alert" onClick={this.onDelGroup.bind(this, i)} style={{ margin: " 0.5em" }}><i className="fa fa-trash" /></button>
                            </div>
                            <HTMLTextarea
                                value={g.description || ""}
                                onChange={(text) => this.onChangeGroup(i, "description", text)}
                            />
                            <div style={{ marginLeft: "1em" }}>
                                {items.map((item: IKipDraftGroupTaskItemRecord, j: number) => {
                                    return <div>
                                        {j + 1}. tanuló egyéni feladata:&nbsp; <button className="button tiny alert" onClick={() => { items.splice(j, 1); this.forceUpdate(); }} style={{ margin: " 0.5em" }}><i className="fa fa-trash" /></button>
                                        <input type="text" value={item.description || ""} onChange={e => { item.description = e.target.value; this.forceUpdate(); }} />
                                    </div>
                                })}
                                <button className="button" onClick={() => { items.push({}), this.forceUpdate(); }} style={{ marginTop: "1em" }}>
                                    <i className="fa fa-plus" /> Egyéni tanulói feladat hozzáadása
                                        </button>
                            </div>
                        </div>
                    })
                }

                <div className="column small-12">
                    <button className="button" onClick={this.onAddGroup.bind(this)} style={{ marginTop: "1em" }}>
                        <i className="fa fa-plus" /> Csoportfeladat hozzáadása
                    </button>
                </div>

            </div>

        </AccordionItem>;
    }

    private onAddGroup() {
        const groupTasks = this.state.groupTasks;
        groupTasks.push({
            items: []
        } as any);
        this.setState({
            groupTasks: groupTasks, isDataChanged: true
        })
    }

    private onDelGroup(index: number) {
        const groupTasks = this.state.groupTasks;
        groupTasks.splice(index, 1);
        this.setState({
            groupTasks: groupTasks, isDataChanged: true
        })
    }

    private onChangeGroup(index: number, propName: string, value: any) {
        const block = this.state.groupTasks[index];
        block[propName] = value;
        this.setState({
            groupTasks: this.state.groupTasks, isDataChanged: true
        });
    }

    private onChangeBlock(index: number, propName: string, value: any) {
        const block = this.state.lessonPlanBlocks[index];
        block[propName] = value;
        this.setState({
            lessonPlanBlocks: this.state.lessonPlanBlocks, isDataChanged: true
        });
    }

    getBlockForm(block: IKipDraftBlockRecord, index: number) {
        const blockType = this.state.blockTypes.find(t => t.id == block.kip_draft_block_type_id);
        if (!blockType) return <p>Ismeretlen blokk típus</p>;

        return <div className="row">
            <div className="column small-12">
                <label>
                    Időkeret:
                        <input type="text" disabled value={blockType.min_time != blockType.max_time ? `${blockType.min_time} - ${blockType.max_time} perc` : `${blockType.min_time} perc`} />
                </label>
                <label>
                    Az óra menete:
                    {
                        this.state.lessonPlan.subject_id === SUBJECT_PE_ID && index === 0
                            ?
                            <input type="text" value={block.title || ""} onChange={e => this.onChangeBlock(index, "title", e.target.value)} />
                            :
                            <input type="text" disabled value={blockType.title} />
                    }

                </label>
                <label>
                    A pedagógus tevékenysége: <textarea disabled rows={3} value={blockType.teacher_activity} />
                </label>
                <label>
                    A tanuló tevékenysége:  <textarea disabled rows={2} value={blockType.student_activity!} />
                </label>
                <label>
                    Munkaformák:  <textarea disabled rows={3} value={blockType.work_forms!} />
                </label>
            </div>
            <div className="column small-12">
                <Comment
                    value={this.state.comments && this.state.comments["block_tools_" + index]}
                    onChange={e => this.onChangeComment("block_tools_" + index, e)}
                />
                <label>
                    {__("Taneszközök")}:
                </label>
                <textarea rows={2} value={block.tools || ""} onChange={e => this.onChangeBlock(index, "tools", e.target.value)} />
            </div>
        </div>;
    }

}
