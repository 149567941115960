import * as React from 'react';
import { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import { getFileUrlInBook, MEDIA_TYPE_VIDEO, MEDIA_TYPE_SOUND, MEDIA_TYPE_IMAGES } from '@src/framework/file/FileActions';
import { __ } from '@src/translation';

type FilePreviewProps = {
    file: IOoFileRecord;
}

type FilePreviewState = {
    fileURLInBook?: string;
}

export class FilePreview extends React.Component<FilePreviewProps, FilePreviewState> {

    constructor(props: FilePreviewProps) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {
        const fileURLInBook = await getFileUrlInBook(this.props.file);
  
        if (fileURLInBook) {
            this.setState({fileURLInBook});
        }
    }

    render() {
        if (this.props.file.media_type_id && MEDIA_TYPE_IMAGES.includes(this.props.file.media_type_id)) {
            return <div>
                    <img
                        src={"/api/media/file/" + this.props.file.sha1}
                        width="100%"
                        height="100%"
                     />
                </div>;
        } else if (this.props.file.media_type_id == MEDIA_TYPE_VIDEO || this.props.file.ext == ".mp4") {
            return <div className="flex-video widescreen">
                    <video
                        src={"/api/media/file/" + this.props.file.sha1}
                        controls
                        width="100%"
                        height="100%"
                     />
                </div>;
        } else if(this.props.file.media_type_id == MEDIA_TYPE_SOUND || this.props.file.ext == ".mp3") {
            return <div className="widescreen">
            <audio controls src ={"/api/media/file/" + this.props.file.sha1} style={{width:"100%"}}>                              
            </audio>            
        </div>;
        } else if (this.props.file.ext
                && this.props.file.ext == ".pdf") {
            return <div className="flex-video widescreen">
                <iframe
                    src={"/api/media/file/" + this.props.file.sha1}
                    width="100%"
                    height="100%"
                 />
            </div>;
        } else if (this.props.file.content_type 
                && this.props.file.content_type!.indexOf("text/html") === 0
                && this.state.fileURLInBook) {
            return <div className="flex-video widescreen">
                    <iframe
                        src={this.state.fileURLInBook}
                        width="100%"
                        height="100%"
                    />
                </div>;
        } else {
            return <div>
                {__("Ismeretlen tartalom")}
                </div>;
        }
    }
}
