import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSecUserViewAuditByUserRecord {
    /**
     * View infó azonosító
     *
     * View infó azonosító
     */
    id?: number;
    /**
     * Séma név
     *
     * Séma név
     */
    schema_name?: string|null;
    /**
     * View név
     *
     * Nézet táblázat név
     */
    view_name?: string|null;
    /**
     * Táblázat név
     *
     * Táblázat név
     */
    table_name?: string|null;
    /**
     * Kijelzett név
     *
     * A nézettáblázat neve magyar nyelven
     */
    view_display_name?: string|null;
    /**
     * Leírás
     *
     * A nézettáblázat leírása magyar nyelven
     */
    view_description?: string|null;
    /**
     * Felhasználó azonosító
     *
     * Felhasználó azonosító
     */
    sec_user_id?: number|null;
    /**
     * Bejelentkezési név
     *
     * Bejelentkezési név
     */
    login_name?: string|null;
    /**
     * E-mail
     *
     * E-mail
     */
    email?: string|null;
    /**
     * Személy azonosító
     *
     * Személy azonosító
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * Teljes név
     */
    fullname?: string|null;
    /**
     * R
     *
     * R
     */
    can_read?: boolean|null;
}

/**
 *  meta.view_sec_user_view_audit_by_user - Felhasználó View jog audit felhasználó szerint
 */

export default class ViewSecUserViewAuditByUser extends View<IViewSecUserViewAuditByUserRecord> {
    public static VIEW_INFO_ID = 2019101602;

    public static getSchemaNameForClass(): string { return 'meta'; }
    public static getViewNameForClass(): string { return 'view_sec_user_view_audit_by_user'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSecUserViewAuditByUser>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSecUserViewAuditByUserRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSecUserViewAuditByUserClassProxy extends ViewClassProxy<IViewSecUserViewAuditByUserRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSecUserViewAuditByUserClassProxy = new ViewSecUserViewAuditByUserClassProxy(ViewSecUserViewAuditByUser);
registerViewClassProxy(viewSecUserViewAuditByUserClassProxy);

