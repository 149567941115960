import * as React from 'react';
import * as ReactDOM from 'react-dom';

export default class YouTubeVideo extends React.PureComponent<{id?: string|null}> {
  
    componentDidMount(){
      if(!(window as any).callbacks)
      {
        (window as any).callbacks = [];
      }
      if (!(window as any).YT) { 
        console.log("if")
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        (window as any).callbacks.push(this.loadVideo.bind(this));
        (window as any).onYouTubeIframeAPIReady = function () {

          if((window as any).callbacks)
          {

            (window as any).callbacks.forEach(function (callback:any) {
              callback()
            })
            (window as any).callbacks.splice(0)
          }
        }
  
        const firstScriptTag = document.getElementsByTagName('script')[0];
        if(firstScriptTag && firstScriptTag.parentNode)
        {

            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
      } 
      else {
        this.loadVideo.bind(this);
      }
    };
  
    player: any;

    loadVideo()  {
      const { id } = this.props;

      this.player = new (window as any).YT.Player(`youtube-player-${id}`, {
        videoId: id,
        // events: {
        //   onReady: this.onPlayerReady,
        // },
        playerVars: {
          origin: window.location.host,
          autoplay: 0,
      },
      });
    };
  
    // onPlayerReady = (event:any) => {
    //   event.target.playVideo();
    // };
  
    render = () => {
      const { id } = this.props;
      if(!id)
      {
          return null;
      }
      return (          
          <div id={`youtube-player-${id}`}/>
      );
    };
  }