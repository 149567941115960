import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IDlMaterialGradeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Letölthető tananyag
     *
     * NOT NULL -> doc_pub.dl_material ON DELETE cascade
     *
     */
    dl_material_id?: number;
    /**
     * Évfolyam
     *
     * NOT NULL -> doc.grade ON DELETE cascade
     *
     */
    grade_id?: number;
}

/**
 *  doc_pub.dl_material_grade - Tananyag évfolyama
 */

export default class DlMaterialGradeCrud extends Crud<IDlMaterialGradeRecord> {
    public static TABLE_INFO_ID = 3788011022;
    public static getSchemaNameForClass() { return 'doc_pub'; }
    public static getTableNameForClass() { return 'dl_material_grade'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<DlMaterialGradeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IDlMaterialGradeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IDlMaterialGradeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IDlMaterialGradeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IDlMaterialGradeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IDlMaterialGradeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class DlMaterialGradeCrudClassProxy extends CrudClassProxy<IDlMaterialGradeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const dlMaterialGradeCrudClassProxy = new DlMaterialGradeCrudClassProxy(DlMaterialGradeCrud);
registerCrudClassProxy(dlMaterialGradeCrudClassProxy);
