import * as React from 'react';
import { app } from '@src/index';
import { ITableInfo, getTableInfoById } from '@src/framework/crud/Meta';
import { BubbleLoader } from 'react-css-loaders';

import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';

import WfPlugin from '@src/component/wf/wf_plugin';
import { __ } from '@src/translation';

interface FileWfEditorProps {
    file: IOoFileRecord;
    onCloseDialog?: () => void;
}

interface FileWfEditorState {
    tableInfo ?: ITableInfo;
}

export default class FileWfEditor extends React.Component<FileWfEditorProps, FileWfEditorState> {
    constructor(props: FileWfEditorProps) {
        super(props);
        this.state = {};
        this.asyncInit();
    }

    private async asyncInit() {
        getTableInfoById(OoFileCrud.TABLE_INFO_ID).then(
            (tableInfo) => { this.setState({tableInfo}) }
        );
    }

    private saveComplete = () => {
        app.showSuccess(__("A kulcsszavak mentése"), __("Sikeres"))
    }

    private onCloseDialog = () => {
        if (this.props.onCloseDialog)
            this.props.onCloseDialog();
    }

    render() {
        return <div>
                    <div className="row">
                        <div className="large-4 small-12 columns">
                            { this.state.tableInfo?
                                <WfPlugin 
                                    tableInfo={this.state.tableInfo}
                                    recId={this.props.file.id!}
                                />
                              :<BubbleLoader />
                            }
                        </div>
                    </div>
                <div className="row filemanager-footer">
                    <div className="large-6 small-12 columns">
                        <a onClick={() => this.onCloseDialog()} className="button warning filemamanger-buttonPadder">{__("Bezár")}</a>
                    </div>
                </div>
        </div>;
    }
}