import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapJtrSupporterRecord {
    /**
     * Felhasználó azonosító
     *
     * Felhasználó azonosító
     */
    id?: number;
    /**
     * E-mail
     *
     * E-mail
     */
    email?: string|null;
    /**
     * Teljes név
     *
     * Teljes név
     */
    fullname?: string|null;
    /**
     * Aktív felhasználó
     *
     * Aktív felhasználó
     */
    is_active?: boolean|null;
    /**
     * Jelenleg is támogató
     *
     * Jelenleg is támogató
     */
    is_supporter?: boolean|null;
}

/**
 *  kap.view_kap_jtr_supporter - KAP szakmai támogató
 *
 *  CMS post
 */

export default class ViewKapJtrSupporter extends View<IViewKapJtrSupporterRecord> {
    public static VIEW_INFO_ID = 2019100201;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_jtr_supporter'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapJtrSupporter>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapJtrSupporterRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapJtrSupporterClassProxy extends ViewClassProxy<IViewKapJtrSupporterRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapJtrSupporterClassProxy = new ViewKapJtrSupporterClassProxy(ViewKapJtrSupporter);
registerViewClassProxy(viewKapJtrSupporterClassProxy);

