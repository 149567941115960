import * as React from 'react';
import { IForumMessage, IForumMessageModification } from '../forumAPI';
import { observer } from 'mobx-react';
import { __ } from '@src/translation';
import { forumStore, MessageListStore, MemberListStore, ForumContainerStore } from '../forumStore';
import { IViewUsrForumMemberRecord } from '@src/framework/view/usr/ViewUsrForumMember';
import { computed, action, observable, runInAction } from 'mobx';
import { me } from '@src/framework/server/Auth';
import { ForumMessageEditor } from './ForumMessageEditor';
import { formatDateOrReturnOriginal } from '@src/Util';
import { Popover } from '@src/component/ui/Popover';
import { confirmDialog } from '@src/component/Dialog';

export interface IForumMessageCardProps {
    storeId: string;
    forumId : number;
    topicId : number;
    messageId : string;
}

/**
 * Egyetlen fórum üzenet megjelenítésére való komponens.
 */
@observer
 export default class ForumMessageCard extends React.Component<IForumMessageCardProps, {}>{

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    public get containerStore(): ForumContainerStore {
        return forumStore.getContainerStore(this.props.storeId);
    }

    @computed private get messageStore() : MessageListStore {
        return forumStore.getMessageListStore(this.props.forumId, this.props.topicId);
    }

    @computed private get memberStore() : MemberListStore {
        return forumStore.getMemberListStore(this.props.forumId);
    }

    @computed private get message() : IForumMessage {
        return this.messageStore.messageById.get(this.props.messageId)!;
    }

    @computed private get messageModification() : IForumMessageModification|null {
        return this.messageStore.pendingModifications.get(this.props.messageId) || null;
    }


    private get isOperationPending() : boolean {
        return this.messageStore.isOperationPending(this.props.messageId);
    }

    private get isEditing() : boolean {
        return this.messageStore.isEditing(this.props.messageId);
    }

    private getMember(userId : number) : IViewUsrForumMemberRecord|null {
        const memberStore = this.memberStore;
        if (memberStore.memberById.has(userId)) {
            return memberStore.memberById.get(userId)!;
        } else {
            // TODO: a new user might have posted something, do we want to re-load members here?
            console.log("getMember: null user?")
            return null;
        }
    }    

    private indicators() {
        const modification = this.messageModification;
        if (modification) {
            return <span className="indicator">
                    {modification.sending && <i className="fa fa-hourglass" title={__("Küldés folyamatban...")} />}
                    {modification.deleting && <i className="fa fa-hourglass" title={__("Törlés folyamatban...")} />}
                    {modification.failed && <i className="fa fa-bomb" title={__("A művelet sikertelen!")} />}
                </span>;
        } else {
            return null;
        }
    }

    /** Saját üzenetem? */
    private get isMine() : boolean {
        if (me && me.id==this.message.userId) {
            return true;
        } else {
            return false;
        }
    }

    /** Tudom-e módosítani ezt az üzenetet? */
    @computed private get canModify() : boolean {
        if (this.isOperationPending) { return false }
        if (this.isMine) {
            return this.memberStore.canModifyMyMessage;
        } else {
            return this.memberStore.canModifyOtherMessage;
        }
    }

    /** Tudom-e törölni ezt az üzenetet? */
    @computed private get canRemove() : boolean {
        if (this.isOperationPending) { return false }
        if (this.isMine) {
            return this.memberStore.canRemoveMyMessage;
        } else {
            return this.memberStore.canRemoveOtherMessage;
        }
    }

    /** Tudok-e üzenetet írni? */
    @computed private get canSendMessage() : boolean {
        return this.memberStore.canSendMessage;
    }    

    private removeMessage = () => {

        confirmDialog(__("Üzenet törlése"), __(`Biztos benne, hogy törli az üzenetet?`)).then(ret=>
        {
            if (ret)
            {
                this.messageStore.removeMessage(this.props.messageId);
            }
        })

        this.showSettingsPopover(false);
    }

    @action.bound private editMessage() {
        this.messageStore.startEditing(this.props.messageId);
        this.showSettingsPopover(false);
    }

    @action.bound private replyToThis() {
        this.containerStore.replyToId = this.props.messageId;
        this.showSettingsPopover(false);
    }

    @observable settingsPopoverOpen = false;

    @action.bound private showSettingsPopover(open:boolean) {
        runInAction(() => { 
        this.settingsPopoverOpen = open;
        });
    }
    _settingsRef:HTMLElement|null = null;

    render() {
        const message = this.message;
        if (!message) {
            // Ennek nem szabadna előfordulnia.
            return <div>???</div>;
        }
        const member = this.getMember(message.userId);
        let modifierName :string = "";
        if (message.modifiedById) {
            const modifier = this.getMember(message.modifiedById);
            if (modifier) {
                modifierName = modifier.fullname!;
            }
        }

        let repliesTo : IForumMessage|null = null;
        if (message.replyToId) {
            repliesTo = this.messageStore.messageById.get(message.replyToId) || null;
        }
        
        const canDoAnything = this.canSendMessage || this.canModify || this.canRemove;

        return <div className={"post"} id={"post_"+message._id}>
            <div className="header">
                <div className="name-block">
                    <b>{member?member.fullname: this.memberStore.loaded ? __("<Törölt tag>") : null}:</b>
                    <span className="date">
                        {formatDateOrReturnOriginal(message.created)}
                    </span>
                    {message.modified?
                        <span className="date">
                            {__("Módosítva: ")} {formatDateOrReturnOriginal(message.modified)} - {modifierName}
                        </span>
                    :null}
                </div>
                {canDoAnything?
                    <div className="actions">
                     { this.canSendMessage ? <button title={__("Válasz")} onClick={this.replyToThis}><i className="fas fa-reply"></i></button> : null }
                    <div className="settings" style={{ cursor: "pointer" }} onClick={()=>this.showSettingsPopover(true)}  ref={(ref) => this._settingsRef = ref}><i className="fas fa-ellipsis-v"></i></div>            
                    <Popover open={this.settingsPopoverOpen} parentElement={() => this._settingsRef as HTMLElement} onClose={()=>this.showSettingsPopover(false) }>
                            <div className="content-item-icon__menu-item">
                                {/* { this.canSendMessage ? <div onClick={this.replyToThis}><i className="fas fa-reply"></i> {__("Válasz")} </div> : null } */}
                                { this.canModify ? <div onClick={this.editMessage}><i className="fa fa-edit"></i> {__("Szerkesztés")} </div> : null }
                                { this.canRemove ? <div onClick={this.removeMessage}><i className="fa fa-trash"></i> {__("Törlés")} </div> : null }
                            </div> 
                    </Popover>                                     
                    </div>:null}
            </div>
            {repliesTo?
                <div className="reply-to-quote">
                    <a href={"#post_"+repliesTo._id}>{__("Válasz erre")}</a>: {repliesTo.message} 
                    {/* (TODO: valahogy csak az első sort kiszedni?) */}
                </div>
            :null}
            {this.isEditing?
            <ForumMessageEditor {...this.props } />
            :
            <div dangerouslySetInnerHTML={{__html:message.message || ""}}></div>}
            {this.indicators()}
        </div>;
    }

}