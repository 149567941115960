import * as React from 'react';
import { Link } from 'react-router-dom';

type CHapterMenuProps = {
    chapterLessons: any,
    bookPath: string,
    chapter?: { id?: number },
    onChapterHover: (id?: number) => void
}

export default class ChapterMenu extends React.Component<CHapterMenuProps, any> {

    render() {
        return <div>

            {(() => {
                if ((this.props.chapterLessons as any[]).filter(chapter => (chapter.lessons.length > 1 && chapter.chapter_headno)).length > 7) {
                    return <div className="chapter-switch" ref="chapterSwitch" onClick={this.chapterSwitch.bind(this)}><i className="fa fa-chevron-right"></i></div>
                }
                return;
            })()}


            <div className="chapter-select" ref="chapterSelect">
                <div className="dots row">
                    {
                        (this.props.chapterLessons as any[]).filter(chap => (chap.lessons.length > 1 && chap.chapter_headno)).map((chapter: any, index: number) => {
                            const isSelected = this.props.chapter && this.props.chapter.id == chapter.id;

                            if (chapter.lessons.length == 1 || chapter.chapter_headno == undefined) {
                                return;
                            } else {
                                return <div key={"d" + (index + 1)} className={"item d" + (index + 1)}>
                                    <Link to={this.props.bookPath + chapter.lessons ? chapter.lessons[0].uri_segment : ""} rel="dot-menu1" data-title={chapter.name}
                                        onMouseOver={() => this.props.onChapterHover(chapter.id)}
                                        onMouseOut={() => this.props.onChapterHover()}
                                    >
                                        <span className={"dot dot-menu" + (isSelected ? " full" : "")}></span>
                                        <div className="title">
                                            {chapter.name}
                                        </div></Link> 
                                </div>
                            }
                        })
                    }
                </div>
            </div>

        </div>
    }

    chapterSwitch() {
        (this.refs.chapterSwitch as HTMLElement).classList.toggle('checked');
        (this.refs.chapterSelect as HTMLElement).classList.toggle('checked');
    }
}
