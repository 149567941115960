import './style.css';
import { ExerciseEngineHelper } from '@src/component/exercise/engine/ExerciseEngineHelper';
import { ExerciseBaseClass } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { url } from 'inspector';
import { EKEQuizServer } from './EKEQuizServer';
import { __ } from '@src/translation';

export interface QuizData extends ExerciseBaseClass {
    answers: any[];
    options: AnswerElement[];
    keywords: string;
    multiple_answers: boolean;
    vertical_display: boolean;
}

type AnswerElement = {
    type: string,
    text: string,
    image: string,
    url?: string
}

type QuizUserSolution = {
    answer: number[];
    fullmatch: boolean;
}

export class EKEQuizExerciseEngine extends AExerciseEngine {

    private checkableItemsList: HTMLInputElement[];
    private labels: HTMLElement[];
    answerindexes: number[] = [];
    private shuffOrderList: any[] = [];
    private clearWrongAnsTimeout:any;
    answersofexercise: any[];

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        this.exercise = params.exercise;
        let exercise: QuizData = this.exercise;
        if (!exercise.options)
            return;

        this.answerindexes = [];
        for (let i = 0; i < exercise.options.length; i++) {
            this.answerindexes.push(i);
        }

        console.log(exercise);
        var shuffledAnswers = exercise.options.slice();
        if (!exercise.ordered_answers)
            AExerciseEngine.shuffle(shuffledAnswers);
        let copyOriginal = exercise.options.slice();
        for (let index = 0; index < shuffledAnswers.length; index++) {
            this.shuffOrderList.push(copyOriginal.indexOf(shuffledAnswers[index])); //Eltároljuk mely elemek hogyan cserélődtek (indexek)
        }

        this.checkableItemsList = [];
        this.labels = [];
  
        this.root.classList.add("eke-quiz");

        this.setIllustration(exercise,this.root); 
      
        var ansNum = 0;
        let randomId = Math.floor(Math.random() * 1000);
        var answersdiv = this.root.appendChild(document.createElement("div"));
        //answersdiv.classList.add("grid-x");
        answersdiv.classList.add("row");
        answersdiv.classList.add("quiz-img-answer-container");

        for (var answer of shuffledAnswers) {
            var answerdiv = answersdiv.appendChild(document.createElement("div"));
            answerdiv.classList.add("small-12", "large-3", "medium-5", "answer-item");
            if (exercise.vertical_display) answerdiv.classList.add("large-8", "medium-12");
            //answerdiv.classList.add("medium-offset-2");
            answerdiv.classList.add("cell");
            answerdiv.classList.add("columns");

            var checkableItem = answerdiv.appendChild(document.createElement("input"));

            if (exercise.multiple_answers) {
                checkableItem.setAttribute("type", "checkbox");
            } else {
                checkableItem.setAttribute("type", "radio");
                checkableItem.setAttribute("name", "answer");
            }
            if (this.isSNIexc)
                checkableItem.addEventListener('click', this.SNIEvaluation.bind(this, EKEQuizServer));
            // let item_id = (exercise.imageanswers ? answer.image : answer.text) + ansNum;
            let item_id = "ans_" + randomId + ansNum;
            checkableItem.setAttribute("id", item_id);

            if (this.isReplay) {
                checkableItem.setAttribute('disabled','disabled');
            }

            var label = answerdiv.appendChild(document.createElement("label"));
            label.setAttribute("for", item_id);
            label.classList.add("button");
            label.classList.add("clear");
            let elementClassList:string[] = [];
            if (answer.type == "image") {
                elementClassList = ["quiz-img", "exe-large-img"];
            }
            AExerciseEngine.displayAnswer(label, answer, this.is_accessible, elementClassList, this);

            let resizeSettings = { minFontSize: '10px', maxFontSize: '17px' };
            if (exercise.vertical_display) {
                ($(label) as any).fitText(2.65, resizeSettings);
            } else {
                ($(label) as any).fitText(resizeSettings);
            }
            label.addEventListener('click', this.onClicked.bind(this), false);
            this.checkableItemsList.push(checkableItem);

            this.labels.push(label);
            ansNum++;
        }
    }
    getUserSolution() {
        var result: any[] = [];
        for (var index = 0; index < this.checkableItemsList.length; index++) {
            var checkableItem = this.checkableItemsList[index];

            if (checkableItem.checked) {
                result.push(this.shuffOrderList[index]);
            }
        }
        result.sort();
        let solution = { answer: result, fullmatch: true };
        return solution;
    }
    receiveEvaluation(evaluated: Evaluated): void {
        let userSolution = this.getUserSolution().answer;
        /*if (evaluated.success){
            for (let j = 0; j < userSolution.length; j++) {
                let shuffledIndex = this.shuffOrderList.indexOf(userSolution[j]);
                let ans = this.labels[shuffledIndex].innerHTML;
                this.removeStyles(this.labels[shuffledIndex]);
                this.labels[shuffledIndex].classList.add("exe-engine-check-correct");
                if (!this.labels[shuffledIndex].innerHTML.includes("Helyes válasz"))
                    this.labels[shuffledIndex].innerHTML = "<span class='show-for-sr'>Helyes válasz:</span>" + ans;
            }
        }
        else{
            for (let index = 0; index < userSolution.length; index++) {
                let shuffledIndex = this.shuffOrderList.indexOf(userSolution[index]);
                let label = this.labels[shuffledIndex];
                let ans = label.innerHTML;
                this.removeStyles(label);
                if (evaluated.solution) {
                    if (evaluated.solution.indexOf(userSolution[index]) != -1){
                        label.classList.add("exe-engine-check-correct");
                        if (!label.innerHTML.includes("Helyes válasz"))
                            label.innerHTML = "<span class='show-for-sr'>Helyes válasz:</span>" + ans;
                    }
                    else{
                        if(!this.isSNIexc) {
                            label.classList.add("exe-engine-check-wrong");
                            if (!label.innerHTML.includes("Helytelen válasz"))
                                label.innerHTML = "<span class='show-for-sr'>Helytelen válasz:</span>" + ans;
                        }
                        if (this.isSNIexc){
                            label.classList.add("exe-engine-check-wrong");
                            if (!label.innerHTML.includes("Helyes válasz"))
                                label.innerHTML = "<span class='show-for-sr'>Helytelen válasz:</span>" + ans;
                            setTimeout(() => { 
                                this.removeStyles(label);
                                this.checkableItemsList[shuffledIndex].checked = false;
                                 }, 
                                1000);
                            
                        }
                    }
                }
            }
        }*/

        /* delete Clear Wrong Answers timeout */
        clearTimeout(this.clearWrongAnsTimeout);

        for (var index = 0; index < this.checkableItemsList.length; index++) {
            var checkableItem = this.checkableItemsList[index];
            var label = this.labels[index];
            this.removeStyles(label);
            let ans = label.innerHTML;

            if (checkableItem.checked) {
                if (evaluated.success) {
                    label.classList.add("exe-engine-check-correct");
                    this.accessibilityHelper(label, ans, true);
                }
                else if (evaluated.solution) {
                    if (evaluated.solution.indexOf(this.shuffOrderList[index]) != -1) {
                        label.classList.add("exe-engine-check-correct");
                        this.accessibilityHelper(label, ans, true);
                    }
                    else {
                        label.classList.add("exe-engine-check-wrong");
                        this.accessibilityHelper(label, ans, false);
                    }
                }
            } /*else if (evaluated.solution && evaluated.solution.indexOf(this.shuffOrderList[index]) != -1 && !this.isSNIexc) {
                label.classList.add("exe-engine-check-correct");
                this.accessibilityHelper(label, ans, true);
            }*/
        }

        /* IF SNI, clear all wrong answers with delay */
        if (this.isSNIexc) {
            this.clearWrongAnsTimeout = setTimeout(() => {
                for (var index = 0; index < this.checkableItemsList.length; index++) {
                    if (this.labels[index].classList.contains("exe-engine-check-wrong")) {
                        this.removeStyles(this.labels[index]);
                        this.checkableItemsList[index].checked = false;
                    }


                }
            },
            2000);
        }
    }

    showCorrectSolution(solution: any): void {
        for (var index = 0; index < this.checkableItemsList.length; index++) {
            this.checkableItemsList[index].checked = false;
            var label = this.labels[index];
            var ans = label.innerHTML;

            // if (solution.indexOf(this.shuffOrderList[index]) > -1 && !label.classList.contains("exe-engine-check-correct")) {
            //     label.innerHTML = "<span class='show-for-sr'>Helyes válasz:</span>" + ans;
            // }

            if (!label.classList.contains("exe-engine-correct-bg"))
                this.removeStyles(label);

            if (solution.indexOf(this.shuffOrderList[index]) > -1 && !label.classList.contains("exe-engine-correct-bg")) {
                this.checkableItemsList[index].checked = true;
                this.accessibilityHelper(label, ans, true);

                label.classList.add("exe-engine-correct-bg");
            }
        }
    }

    accessibilityHelper(element: HTMLElement, ans: any, correct: boolean) {
        if (correct) {
            if (!element.innerHTML.includes(__("Helyes válasz"))) {
                element.innerHTML = "<span class='show-for-sr'>Helyes válasz:</span>" + ans;
            }
        } else {
            if (!element.innerHTML.includes(__("Helytelen válasz"))) {
                element.innerHTML = "<span class='show-for-sr'>Helytelen válasz:</span>" + ans;
            }
        }
    }

    isUserReady(): boolean {
        for (var index = 0; index < this.checkableItemsList.length; index++) {
            var checkableItem = this.checkableItemsList[index];
            if (checkableItem.checked) {
                return true;
            }
        }
        return false;
    }
    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }
    removeStyles(element: HTMLElement): void {

        element.classList.remove(
            "exe-engine-correct-bg",
            "exe-engine-wrong-bg",
            "eke-engine-show-correct-bg",
            "exe-engine-check-correct",
            "exe-engine-check-wrong"
        );
        //element.classList.toggle("exe-engine-check-wrong", false);
    }


    onClicked(ev: any) {
        var curr_element = ev.target as HTMLElement;
        this.removeStyles(curr_element);
    }
}
