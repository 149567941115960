import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewInstituteRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * OM azonosító
     */
    om_identifier?: string;
    /**
     * Név
     */
    name?: string;
    /**
     * Vezető neve
     */
    leader_name?: string|null;
    /**
     * Vezető telefon
     */
    leader_phone?: string|null;
    /**
     * Vezető e-mail
     */
    leader_email?: string|null;
    /**
     * Irányítószám
     */
    zipcode?: string|null;
    /**
     * Cím
     */
    address?: string|null;
    /**
     * Település
     */
    city_id?: number|null;
    /**
     * Adószám
     */
    taxno?: string|null;
    /**
     * Szerepel a KIR nyilvántartásban
     */
    in_kir?: boolean;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Módosította
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
}

/**
 *  sys.view_institute - Intézmény
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewInstitute extends View<IViewInstituteRecord> {
    public static VIEW_INFO_ID = 2018070501;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_institute'; }
    public static getTableNameForClass(): string|null { return 'institute'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewInstitute>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewInstituteRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewInstituteClassProxy extends ViewClassProxy<IViewInstituteRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewInstituteClassProxy = new ViewInstituteClassProxy(ViewInstitute);
registerViewClassProxy(viewInstituteClassProxy);

