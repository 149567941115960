import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import { app } from '@src/index';
import OoFileCrud, { IOoFileRecord } from '@framework/crud/media//OoFileCrud';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import MediaCrud, { IMediaTypeRecord } from '@src/framework/crud/media/MediaTypeCrud';
import FileManagerPager from './FileManagerPager';
import ViewOoFileInBook, { IViewOoFileInBookRecord } from '@src/framework/view/doc/ViewOoFileInBook';
import OriginCrud, { IOriginRecord } from '@src/framework/crud/media/OriginCrud';
import ViewDocSectionFileRef, { IViewDocSectionFileRefRecord } from '@src/framework/view/doc/ViewDocSectionFileRef';
import { PATH_PUBLISHED_BOOK, PATH_EXERCISE_VIEW, PATH_BOOK_VIEW_EDITING } from '@src/Routes';
import ViewOoFileInExerciseBook, { IViewOoFileInExerciseBookRecord } from '@src/framework/view/exc/ViewOoFileInExerciseBook';
import ExerciseCrud, { IExerciseRecord } from '@src/framework/crud/exc/ExerciseCrud';
import { StringSearchKind, IStringSearch } from '@src/framework/crud/Crud';
import { Dialog } from '@src/component/Dialog';
import { FileDataTabs } from '@src/component/filemanager/data/FileDataTabs';
import ViewOoFileInFolder, { IViewOoFileInFolderRecord } from '@src/framework/view/media/ViewOoFileInFolder';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import CrudSelect from '@framework/forms//crudselect';
import { chapterCrudClassProxy } from '@src/framework/crud/doc/ChapterCrud';
import { lessonCrudClassProxy } from '@src/framework/crud/doc/LessonCrud';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import SuboriginCrud, { ISuboriginRecord } from '@src/framework/crud/media/SuboriginCrud';
import { __ } from '@src/translation';
import ViewBookOfficial, {IViewBookOfficialRecord} from '@src/framework/view/doc/ViewBookOfficial';

const OFI_ORIGIN_ID = 507110900;
interface OFIOriginTablePageProps {

}

interface OFIOriginTablePageState {
    files?: (IViewOoFileInBookRecord & IViewOoFileInExerciseBookRecord & IViewOoFileInFolderRecord)[];
    limit: number;
    offset: number;
    pageSize: number;
    filter: string;
    loading: boolean;
    books: IViewBookOfficialRecord[];
    origins: IOriginRecord[];
    subOrigins: ISuboriginRecord[];

    mediaTypes: IMediaTypeRecord[];
    selectecMediatypeId?: number | null;
    selectedBookId?: number | null;
    selectedChapterId: number | null;
    selectedLessonId: number | null;
    originId?: number | null;
    subOriginId?: number | null;
    originAuthor: string;
    originDisplay: string;
    keywords:string;
    withoutKeywords: boolean;
    withoutDisplayName: boolean;
    count: number;
    type: "book" | "exercise" | "folder";
}

export class OFIOriginTablePage extends React.Component<OFIOriginTablePageProps, OFIOriginTablePageState> {
    constructor(props: OFIOriginTablePageProps) {
        super(props);
        this.state = {
            limit: 50,
            offset: 0,
            pageSize: 25,
            count: 0,
            files: undefined,
            filter: '',
            originAuthor: '',
            originDisplay: '',
            loading: false,
            origins: [],
            subOrigins: [],
            books: [],
            mediaTypes: [],
            type: "book",
            selectedChapterId: null,
            selectedLessonId: null,
            withoutDisplayName: false,
            withoutKeywords: false,
            keywords:""
        };
    }

    async componentDidMount() {

        await this.reload();
        this.reloadUntaggedAsync();
    }

    async reload() {
        const books = await ViewBookOfficial.list({ filter: { is_active: true, library_id: LIBRARY_OFI_OFFICIAL_ID }, order_by: "name" });
        const origins = await OriginCrud.list({ filter: { is_active: true }, order_by: "title" });
        const subOrigins = await SuboriginCrud.list({ filter: { is_active: true }, order_by: "title" });
        const mediaTypes = await MediaCrud.list({ order_by: "title" });

        this.setState({
            books,
            mediaTypes,
            origins,
            subOrigins
        });
    }

    async reloadUntaggedAsync() {
        try {
            this.setState({ files: undefined, loading: true });

            var titleFilter: IStringSearch | undefined = undefined;

            if (this.state.filter) {
                titleFilter = {
                    kind: StringSearchKind.Contains,
                    case_sensitive: false,
                    expr: this.state.filter
                }
            }
            var authorFilter: IStringSearch | undefined = undefined;
            if (this.state.originAuthor) {
                authorFilter = {
                    kind: StringSearchKind.Contains,
                    case_sensitive: false,
                    expr: this.state.originAuthor
                }
            }
            let files;
            let count: number = 0;
            var filter: any = {}
            if (this.state.type == "book" || !this.state.selectedBookId) {
                let keywordsVal =!this.state.keywords || this.state.keywords.length<1?undefined:{kind: StringSearchKind.WordSearch,expr:this.state.keywords}
                filter = {
                    book_is_active: true,
                    origin_id: this.state.originId,
                    suborigin_id: this.state.subOriginId,
                    origin_author: authorFilter,
                    media_type_id: this.state.selectecMediatypeId,
                    is_active: true,
                    book_id: this.state.selectedBookId,
                    title: titleFilter,
                    keywords: this.state.withoutKeywords ? null :keywordsVal,
                    displayname: this.state.withoutDisplayName ? null : undefined,
                    library_id: LIBRARY_OFI_OFFICIAL_ID,
                };

                files = await ViewOoFileInBook.list(this.getListParams(filter));
                count = await ViewOoFileInBook.count(this.getListParams(filter));

            } else if (this.state.type == "exercise") {

                filter = {
                    origin_id: this.state.originId,
                    suborigin_id: this.state.subOriginId,
                    origin_author: authorFilter,
                    media_type_id: this.state.selectecMediatypeId,
                    is_active: true,
                    book_id: this.state.selectedBookId,
                    title: titleFilter,
                    keywords: this.state.withoutKeywords ? null : undefined,
                    displayname: this.state.withoutDisplayName ? null : undefined,
                    library_id: LIBRARY_OFI_OFFICIAL_ID,
                };

                files = await ViewOoFileInExerciseBook.list(this.getListParams(filter));
                count = await ViewOoFileInExerciseBook.count(this.getListParams(filter));

            } else {

                const book = (await BookCrud.load(this.state.selectedBookId)).record;
                const bookFolder = (await OoFolderCrud.load(book.oo_folder_id!)).record;

                filter = {
                    origin_id: this.state.originId,
                    suborigin_id: this.state.subOriginId,
                    origin_author: authorFilter,
                    is_active: true,
                    media_type_id: this.state.selectecMediatypeId,
                    keywords: this.state.withoutKeywords ? null : this.state.keywords,
                    displayname: this.state.withoutDisplayName ? null : undefined,

                    relpath: {
                        kind: StringSearchKind.StartsWith,
                        case_sensitive: true,
                        expr: bookFolder.relpath || "???"
                    },
                    title: titleFilter
                };

                files = await ViewOoFileInFolder.list(this.getListParams(filter));
                count = await ViewOoFileInFolder.count(this.getListParams(filter));
            }
            this.setState({ files: files as any, loading: false, count: count });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    // Entert nyom a filter input-ban, feldolgozzuk
    private onUntaggedAuthorChange(e: any) {
        this.setState({ originAuthor: e.target.value });
    }
    private onUntaggedAuthorKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.reloadUntaggedAsync();
        }
    }
    private getListParams(filter: any) {
        var listParameters = {
            filter: filter,
            spec: {
                lesson_id: this.state.selectedLessonId, // TODO
                chapter_id: this.state.selectedChapterId, // TODO
            },
            limit: this.state.limit,
            offset: this.state.offset,
        };
        return listParameters;
    }
    private onUntaggedFilterChange(e: any) {
        this.setState({ filter: e.target.value });
    }

    private onUntaggedFilterKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.reloadUntaggedAsync();
        }
    }
    private onUntaggedKeywordChange(e: any) {
        this.setState({ keywords: e.target.value });
    }

    private onUntaggedKeywordKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.reloadUntaggedAsync();
        }
    }

    private onChangeLimit = (limit: number) => {
        this.setState({ limit }, this.reloadUntaggedAsync);
    }

    private onChangeOffset = (offset: number) => {
        this.setState({ offset }, this.reloadUntaggedAsync);
    }

    private onBookChanged(event: any) {

        let tempBookId = event.target.value ? Number(event.target.value) : undefined;
        if (event.target.value == "" || tempBookId != this.state.selectedBookId) {
            this.setState({ selectedBookId: tempBookId, selectedChapterId: null, selectedLessonId: null }, this.reloadUntaggedAsync)
        }
        else {
            this.setState({ selectedBookId: tempBookId }, this.reloadUntaggedAsync)
        }
    }
    private onChapterChanged(sender: CrudSelect, chapterId: number | null) {

        this.setState({ selectedChapterId: chapterId ? Number(chapterId) : null }, this.reloadUntaggedAsync)
    }
    private onLessonChanged(sender: CrudSelect, lessonId: number | null) {
        this.setState({ selectedLessonId: lessonId ? Number(lessonId) : null }, this.reloadUntaggedAsync)
    }
    private onHasKeyWordsChanged(event: any) {
        this.setState({ withoutKeywords: event.target.checked }, this.reloadUntaggedAsync)
    }
    private onHasDisplayNameChanged(event: any) {
        this.setState({ withoutDisplayName: event.target.checked }, this.reloadUntaggedAsync)
    }

    private onTypeChanged(event: any) {
        if (event.target.value != "book") {
            this.setState({ type: event.target.value, selectedChapterId: null, selectedLessonId: null }, this.reloadUntaggedAsync);
        }
        else {
            this.setState({ type: event.target.value }, this.reloadUntaggedAsync);
        }
    }

    private onOriginChanged(event: any) {
        var originId: any = undefined;
        if (event.target.value == "null") {
            originId = null;
        } else if (event.target.value) {
            originId = Number(event.target.value);
        }
        this.setState({ originId }, this.reloadUntaggedAsync)
    }
    private onSubOriginChanged(event: any) {
        var subOriginId: any = undefined;
        if (event.target.value == "null") {
            subOriginId = null;
        }
        else if (event.target.value) {
            subOriginId = Number(event.target.value);
        }
        this.setState({ subOriginId }, this.reloadUntaggedAsync)
    }

    private onMediatypeChanged(event: any) {
        var selectecMediatypeId: any = undefined;
        if (event.target.value == "null") {
            selectecMediatypeId = null;
        } else if (event.target.value) {
            selectecMediatypeId = Number(event.target.value);
        }
        this.setState({ selectecMediatypeId }, this.reloadUntaggedAsync)
    }

    render() {
        const header = <div className="row">
            <div className="shrink column">
                <label className="middle" htmlFor="image-select-filter">{__("Fájlnév tartalmazza")}:</label>
            </div>
            <div className="column">
                <input value={this.state.filter} placeholder={__("Keresés fájlnévre")} type="text" id="untaggedImgSearch" onChange={this.onUntaggedFilterChange.bind(this)} onKeyPress={this.onUntaggedFilterKeyPress.bind(this)} />
            </div>
            <div className="shrink column">
                <label className="middle" htmlFor="image-select-filter">{__("Szerző")}:</label>
            </div>
            <div className="column">
                <input value={this.state.originAuthor} placeholder={__("Keresés szerzőre")} type="text" id="untaggedAuthorSearch" onChange={this.onUntaggedAuthorChange.bind(this)} onKeyPress={this.onUntaggedAuthorKeyPress.bind(this)} />
            </div>
            <div className="shrink column"><button className="button small middle" onClick={this.reloadUntaggedAsync.bind(this)}>
                <i className="fa fa-sync" /></button>
            </div>
        </div>;

        return <div>
            <div className="row">
                <div className="columns large-3 small-12">
                    <label htmlFor="bookIdSelector">{__("Csak ilyen forrásból")}</label>
                    <select disabled={this.state.loading} name="bookIdSelector" onChange={this.onOriginChanged.bind(this)}>
                        <option value="">[ {__("Összes")} ]</option>
                        <option value="null">[ {__("Származási hely nélküliek")} ]</option>
                        {           
                            this.state.origins.sort((a, b) => (a.title?a.title:"").localeCompare(b.title?b.title:"")).map((origin) =>{
                                return <option key={origin.id} value={origin.id}>{origin.title}</option>
                            })
                        }
                    </select>
                </div>
                <div className="columns large-3 small-12">
                    <label htmlFor="suboriginSelector">{__("Csak ilyen alforrásból")}</label>
                    <select disabled={this.state.loading} name="suboriginSelector" onChange={this.onSubOriginChanged.bind(this)}>

                        <option value="">[ {__("Összes")} ]</option>
                        <option value={"null"}>[ {__("Alforrás nélküliek")} ]</option>
                        {
                            this.state.subOrigins.map(subOrigin => {
                                return <option key={subOrigin.id} value={subOrigin.id}>{subOrigin.title}</option>
                            })
                        }
                    </select>

                </div>
                <div className="columns large-3 small-12">
                    <label htmlFor="mediaTypeIdSelector">{__("Csak ilyen típus")}</label>
                    <select disabled={this.state.loading} name="mediaTypeIdSelector" id="mediaTypeIdSelector" onChange={this.onMediatypeChanged.bind(this)}>
                        <option value="">[ {__("Összes")} ]</option>
                        <option value="null">[ {__("Típus nélküliek")} ]</option>
                        {
                            this.state.mediaTypes.map((mediaType: IMediaTypeRecord) => {
                                if (!mediaType.id) return;
                                return <option key={mediaType.id} value={mediaType.id}>{mediaType.title}</option>
                            })
                        }
                    </select>
                </div>
                <div className="columns large-3 small-12">
                    <label title={__("Tankönyvbe bekötött fájlok")}>
                        <input disabled={this.state.selectedBookId == null} type="radio" value="book" checked={this.state.type == "book"} onChange={this.onTypeChanged.bind(this)} />
                        {__("Tankönyv fájlok")}
                    </label>
                    <label title={__("Okosfeladatba bekötött fájlok")}>
                        <input disabled={this.state.selectedBookId == null} type="radio" value="exercise" checked={this.state.type == "exercise"} onChange={this.onTypeChanged.bind(this)} />
                        {__("Feladat fájlok")}
                    </label>
                    <label title={__("Okostankönyv mappába bekötött fájlok")}>
                        <input disabled={this.state.selectedBookId == null} type="radio" value="folder" checked={this.state.type == "folder"} onChange={this.onTypeChanged.bind(this)} />
                        {__("Tartalomtár fájlok")}
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="columns large-4 small-12">
                    <label htmlFor="bookIdSelector">{__("Csak ebben a könyvben")}</label>
                    <select disabled={this.state.loading} name="bookIdSelector" onChange={this.onBookChanged.bind(this)}>
                        <option value="">[ {__("Összes")} ]</option>
                        {
                            this.state.books.map(book => {
                                if (!book.name) return;
                                return <option key={book.id} value={book.id}>{book.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className="columns large-4 small-12">
                    <label htmlFor="chapterIdSelector">{__("Csak ebben a fejezetben")}</label>
                    <CrudSelect
                        disabled={this.state.loading || this.state.type != "book" || !this.state.selectedBookId}
                        value={this.state.selectedChapterId}
                        onSelect={this.onChapterChanged.bind(this)}
                        displayFieldName="name"
                        key={this.state.selectedBookId || 0}
                        emptyTitle={__("Minden fejezet")}
                        clearable={true}
                        crudClassProxy={chapterCrudClassProxy}
                        filter={{ book_id: this.state.selectedBookId || 0, is_active: true }}
                        orderByFieldName="no"
                    />
                </div>
                <div className="columns large-4 small-12">
                    <label htmlFor="lessonIdSelector">{__("Csak ebben a leckében")}</label>
                    <CrudSelect
                        disabled={this.state.loading || this.state.type != "book" || !this.state.selectedBookId || !this.state.selectedChapterId}
                        value={this.state.selectedLessonId}
                        onSelect={this.onLessonChanged.bind(this)}
                        displayFieldName="name"
                        key={this.state.selectedChapterId || 0}
                        emptyTitle={__("Minden lecke")}
                        clearable={true}
                        crudClassProxy={lessonCrudClassProxy}
                        filter={{ chapter_id: this.state.selectedChapterId || 0, is_active: true }}
                        orderByFieldName="no"
                    />

                </div>

            </div>
            <div className="row">
            <div className="shrink column">
                <label className="middle" htmlFor="image-select-filter">{__("Kulcsszó")}:</label>
            </div>
            <div className="column">
                <input value={this.state.keywords} disabled={this.state.withoutKeywords} placeholder={__("Keresés kulcsszóra")} type="text" id="untaggedKeywordSearch" onChange={this.onUntaggedKeywordChange.bind(this)} onKeyPress={this.onUntaggedKeywordKeyPress.bind(this)} />
            </div>
                <div className="shrink column">
                    <input type="checkbox" disabled={this.state.loading} id="withoutkeywords" name="withoutkeywords" onChange={this.onHasKeyWordsChanged.bind(this)} /><label htmlFor="withoutkeywords">{__("Kulcsszó nélküliek")}</label>
                    <input type="checkbox" disabled={this.state.loading} id="withoutDisplayName" name="withoutDisplayName" onChange={this.onHasDisplayNameChanged.bind(this)} /><label htmlFor="withoutDisplayName">{__("Publikált név nélküliek")}</label>
                </div>
            </div>
            {header}

            {this.state.files
            ?<span style={{ fontWeight: "bold", fontSize: "15px" }}>{__("Összesen")} {__("{n} találat", {n: this.state.count})}</span>
            :""
            }

            <FileManagerPager
                disabled={this.state.loading}
                limit={this.state.limit}
                offset={this.state.offset}
                onChangeLimit={this.onChangeLimit}
                onChangeOffset={this.onChangeOffset}
            />
            {
                !this.state.files
                    ? <div className="row"><BubbleLoader /></div>
                    : <OriginTable
                        files={this.state.files}
                        origins={this.state.origins}
                        onReload={this.reloadUntaggedAsync.bind(this)}
                        books={this.state.books}
                    />
            }

            <FileManagerPager
                disabled={this.state.loading}
                limit={this.state.limit}
                offset={this.state.offset}
                onChangeLimit={this.onChangeLimit}
                onChangeOffset={this.onChangeOffset}
            />
            {
                !this.state.loading && this.state.files && this.state.files.length == 0
                    ?
                    __("Nincs találat")
                    :
                    null
            }
        </div>;
    }
}

export class OriginTable extends React.Component<{ files: (IViewOoFileInBookRecord & IViewOoFileInExerciseBookRecord)[], origins: IOriginRecord[], onReload: () => void, books?: IViewBookOfficialRecord[] }> {
    render() {
        return <table className="table striped">
            <thead>
                <tr>
                    <td>{__("Kép")}</td>
                    <td style={{ width: "10%" }}>{__("Forrás")}</td>
                    <td style={{ width: "15%" }}>{__("Származási hely/URL")}</td>
                    <td style={{ width: "15%" }}>{__("Szerző")}</td>
                    <td style={{ width: "15%" }}>{__("Megjelenítendő forrás")}</td>
                    <td style={{ width: "15%" }}>{__("Megjegyzés")}</td>
                    <td style={{ width: "15%" }}>{__("Nyilvánosan kereshető")}</td>
                    <td>{__("Mentés")}</td>
                </tr>
            </thead>
            {
                this.props.files.map(f => {

                    return <OriginTableRow key={f.id} file={f} origins={this.props.origins} onReload={this.props.onReload} books={this.props.books}/>;
                })
            }
        </table>;
    }
}

type OriginTableRowProps = {
    file: IViewOoFileInBookRecord & IViewOoFileInExerciseBookRecord;
    origins: IOriginRecord[];
    onReload: () => void;
    books?: IViewBookOfficialRecord[];
}

type OriginTableRowState = {
    savedFile: IViewOoFileInBookRecord;
    file: IViewOoFileInBookRecord;
    saving: boolean;
    exercise?: IExerciseRecord;
    sections: IViewDocSectionFileRefRecord[];
    showSettingsDialog: boolean;
    fileInDialog?: IOoFileRecord;
}
export class OriginTableRow extends React.Component<OriginTableRowProps, OriginTableRowState> {
    constructor(props: OriginTableRowProps) {
        super(props);

        this.state = {
            savedFile: Object.assign({}, this.props.file),
            file: Object.assign({}, this.props.file),
            saving: false,
            sections: [],
            showSettingsDialog: false
        }
    }

    async componentDidMount() {
        const sections = await ViewDocSectionFileRef.list({ filter: { oo_file_id: this.props.file.id } });
        this.setState({ sections });
        if (this.props.file.exercise_id) {
            const exercise = (await ExerciseCrud.load(this.props.file.exercise_id)).record;
            this.setState({ exercise });
        }
    }

    async onSave() {
        this.setState({ saving: true });

        try {
            await new OoFileCrud({
                id: this.state.file.id,
                origin_id: this.state.file.origin_id,
                origin_url: this.state.file.origin_url,
                origin_author: this.state.file.origin_author,
                origin_display: this.state.file.origin_display,
                origin: this.state.file.origin,
                // isPublicSearchAble :this.state.file.isPublicSearchable

            }).put();

            app.showSuccess(__("Származási hely lementve"), "");

            this.setState({
                savedFile: Object.assign({}, this.state.file),
                saving: false
            });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private async showSettingsDialog() {
        const fileInDialog = (await OoFileCrud.load(this.state.file.id!)).record;
        this.setState({
            showSettingsDialog: true,
            fileInDialog
        })
    }
    private onOriginIdChange(event: any) {

        this.state.file.origin_id = event.target.value ? Number(event.target.value) : undefined;
        let temporiginDisplay = "";
        if (this.state.file.origin_id && this.state.file.origin_id == OFI_ORIGIN_ID) {
            let authorString = this.state.file.origin_author ? " / " + this.state.file.origin_author : "";
            temporiginDisplay = "OH (és jogelődei)" + authorString;
            this.state.file.origin_display = temporiginDisplay;
        }
        this.setState({ file: this.state.file });
    }
    private isPublicSearchableChange(event: any) {
        let tempValue = event.target.value;
        let IspubSearch: boolean | null;
        if (tempValue == -1) { IspubSearch = null; }
        else if (tempValue == 0) { IspubSearch = true }
        else { IspubSearch = false }
        // this.setState({ file: { ...this.state.file, isPublicSearchable: IspubSearch } });

    }


    render() {
        const f = this.state.file;

        const src = "/api/media/thumb/200/" + f.sha1;

        const thumbnail = <img draggable={false} src={src} onClick={this.showSettingsDialog.bind(this)} style={{ cursor: "pointer" }} />;

        const saveDisabled = this.state.saving ||
            (
                this.state.savedFile.origin_id == this.state.file.origin_id
                && this.state.savedFile.origin_url == this.state.file.origin_url
                && this.state.savedFile.origin_author == this.state.file.origin_author
                && this.state.savedFile.origin_display == this.state.file.origin_display
                && this.state.savedFile.origin == this.state.file.origin
            )
            || !this.state.file.origin_id
            ;

        return <tbody>
            {
                this.state.showSettingsDialog && this.state.fileInDialog
                    ?
                    <Dialog title={__("Állomány részletei - {details}", {details: this.props.file.title})} onClose={() => this.setState({ showSettingsDialog: false })} width={1000} height={700}>
                        <FileDataTabs
                            file={this.state.fileInDialog}
                            allowRestoreVersion={true}
                            allowModifications={true}
                            onClose={() => this.setState({ showSettingsDialog: false }, this.props.onReload)}
                        />
                    </Dialog>
                    :
                    null
            }
            <tr>
                <td rowSpan={2} style={{ width: "210px" }}>
                    {thumbnail}
                </td>
                <td colSpan={5}>
                    <strong>{f.book_name}</strong> / {f.relpath}
                    {
                        this.state.sections.slice(0, 2).map(s => {
                            let book = this.props.books && this.props.books.find(b=>b.id == s.book_id);
                            let baseUrl = book && book.is_published ? (PATH_PUBLISHED_BOOK + "/" + s.book_uri_segment) : (PATH_BOOK_VIEW_EDITING + "/" + book!.id);
                            
                            const url = baseUrl + "/" + s.lesson_uri_segment + "#section-" + s.section_id;

                            return <div key={s.id}>
                                <a target="_new" href={url}>{s.book_name || ""} / {s.chapter_name || ""} / {s.lesson_name || ""}</a>
                            </div>
                        })
                    }

                    {
                        this.state.exercise
                            ?
                            <div>
                                {__("Feladat")}: <a href={PATH_EXERCISE_VIEW + "/" + this.state.exercise.id} target="_new">
                                    {this.state.exercise.name}
                                </a>
                            </div>
                            :
                            null
                    }
                </td>
                <td>
                    <select disabled={this.state.saving} value={0|| -1} style={{ margin: 0 }} onChange={this.isPublicSearchableChange.bind(this)} >
                        <option value={-1}>[ {__("Nincs beállítva")} ]</option>
                        <option value={0}>[ {__("Igen")} ]</option>
                        <option value={1}>[ {__("Nem")} ]</option>
                    </select>
                </td>
                <td rowSpan={2} style={{ width: "10em" }}>
                    <button disabled={saveDisabled} className="button success" style={{ margin: 0 }} onClick={this.onSave.bind(this)}>
                        <i className="fa fa-save" /> {__("Mentés")}
                    </button>
                </td>
            </tr>

            <tr>
                <td>
                    <select disabled={this.state.saving} value={f.origin_id || ""} style={{ margin: 0 }} onChange={this.onOriginIdChange.bind(this)} >
                        <option value="">[ {__("Nincs beállítva")} ]</option>
                        {
                            this.props.origins.map(origin => {
                                return <option key={origin.id} value={origin.id}>{origin.title}</option>
                            })
                        }
                    </select>
                </td>
                <td>
                    <input type="text" disabled={this.state.saving} value={f.origin_url || ""} style={{ margin: 0 }} onChange={(e) => { this.state.file.origin_url = e.target.value; this.setState({ file: this.state.file }) }} />
                </td>
                <td>
                    <input type="text" disabled={this.state.saving} value={f.origin_author || ""} style={{ margin: 0 }} onChange={(e) => { this.state.file.origin_author = e.target.value; this.setState({ file: this.state.file }) }} />
                </td>
                <td>
                    <input type="text" disabled={this.state.saving} value={f.origin_display || ""} style={{ margin: 0 }} onChange={(e) => { this.state.file.origin_display = e.target.value; this.setState({ file: this.state.file }) }} />
                </td>
                <td>
                    <input type="text" disabled={this.state.saving} value={f.origin || ""} style={{ margin: 0 }} onChange={(e) => { this.state.file.origin = e.target.value; this.setState({ file: this.state.file }) }} />
                </td>

            </tr>
        </tbody>
    }
}
