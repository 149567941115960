import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewOoFileInBookRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/|null;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number|null;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean|null;
    /**
     * OnlyOffice file azonosító.
     */
    oo_fileid?: string|null;
    /**
     * Név
     *
     * Az OnlyOffice rendszerében az állomány 'title' mezőjének értéke. Vagyis a file neve.
     */
    title?: string|null;
    /**
     * Kiterjesztés
     *
     * Az állományt kiterjesztése. Kisbetűs, ponttal kezdődik (vagy üres)
     */
    ext?: string|null;
    /**
     * Content-Type
     */
    content_type?: string|null;
    /**
     * Relatív útvonal
     *
     * A mappa szerkezetben a gyökérhez képest.
     */
    relpath?: string|null;
    /**
     * Relatív útvonal JSON objektumként
     *
     * Egy lista ami (folder_id, folder_title) elemekből áll.
     */
    relpath_items?: any|null;
    /**
     * Név
     *
     * Kulcsszavak
     */
    keywords?: string|null;
    /**
     * Méret
     *
     * A file teljes mérete byte-ban.
     */
    size?: number|null;
    /**
     * Szélesség pixelben
     *
     * Csak akkor, ha raszteres képről van szó.
     */
    width_px?: number|null;
    /**
     * Magasság pixelben
     *
     * Csak akkor, ha raszteres képről van szó.
     */
    height_px?: number|null;
    /**
     * SHA1 hash érték
     *
     * A file tartalmára számítva. Csak képeknél számítjuk!
     */
    sha1?: string|null;
    /**
     * OnlyOffice utolsó ismert file infó.
     *
     * A tartalmazó mappa listázó API hívásából az erre az állományra vonatkozó bejegyzés.
     */
    oo_info?: any|null;
    /**
     * OnlyOffice mappa
     */
    oo_folder_id?: number|null;
    /**
     * Van thumbnail
     *
     * Thumbnail-ek elérhetők hozzá MongoDb-ből
     */
    has_thumb?: boolean|null;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* float8 */|null;
    /**
     * Eredet leírás
     *
     * Az állomány származási helye (nyilvántartás és szerzői jog miatt), leírás
     */
    origin?: string|null;
    /**
     * Média típus
     */
    media_type_id?: number|null;
    /**
     * Publikált név
     *
     * Ahogyan a felületen megjelenik - ez nem a file neve.
     */
    displayname?: string|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     *
     * Ez egy ContentVersionId
     */
    nkp1_id?: number|null;
    /**
     * Tulajdonos
     */
    owner_id?: number|null;
    /**
     * Jogok öröklése
     *
     * A mappa a jogokat a tartalmazó mappától örökli. Az explicit módon beállított jogok hatástalanok.
     */
    inherit_perms?: boolean|null;
    /**
     * Forrás (származási hely)
     */
    origin_id?: number|null;
    /**
     * Eredet URL
     *
     * Az állomány származási helye (nyilvántartás és szerzői jog miatt) URL-ként
     */
    origin_url?: string|null;
    /**
     * Származási szerző
     */
    origin_author?: string|null;
    /**
     * Megjelölendő forrás
     */
    origin_display?: string|null;
    /**
     * Al-forrás (származási hely)
     */
    suborigin_id?: number|null;
    /**
     * Kereshető
     *
     * A kereső szolgáltatás beindexeli, meg lehet találni a keresőben.
     */
    searchable?: boolean|null;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta az állományt
     */
    creator?: string|null;
    /**
     * Módosította
     *
     * Felhasználó, aki utoljára módosította az állományt
     */
    modifier?: string|null;
    /**
     * Könyv azonosító
     *
     * Könyv azonosító
     */
    book_id?: number|null;
    /**
     * Könyv név
     *
     * Könyv név
     */
    book_name?: string|null;
    /**
     * Könyv aktív
     *
     * Könyv aktív
     */
    book_is_active?: boolean|null;
    /**
     * Évfolyam (grade) azonosító
     *
     * Évfolyam (grade) azonosító
     */
    grade_id?: number|null;
    /**
     * Évfolyam név
     *
     * Évfolyam név
     */
    grade_name?: string|null;
    /**
     * Tantárgy azonosító
     *
     * Tantárgy azonosító
     */
    subject_id?: number|null;
    /**
     * Tantárgy név
     *
     * Tantárgy név
     */
    subject_name?: string|null;
    /**
     * Alforrás név
     *
     * Alforrás név
     */
    suborigin_title?: string|null;
    /**
     * Alforrás leírás
     *
     * Alforrás leírás
     */
    suborigin_description?: string|null;
    /**
     * Alforrás aktív
     *
     * Alforrás aktív
     */
    suborigin_is_active?: boolean|null;
    /**
     * Könyvtár azonosító
     *
     * Könyvtár azonosító
     */
    library_id?: number|null;
}

/**
 *  doc.view_oo_file_in_book - Melyik file melyik könyvben szerepel
 */

export default class ViewOoFileInBook extends View<IViewOoFileInBookRecord> {
    public static VIEW_INFO_ID = 2018071201;

    public static getSchemaNameForClass(): string { return 'doc'; }
    public static getViewNameForClass(): string { return 'view_oo_file_in_book'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewOoFileInBook>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewOoFileInBookRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewOoFileInBookClassProxy extends ViewClassProxy<IViewOoFileInBookRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewOoFileInBookClassProxy = new ViewOoFileInBookClassProxy(ViewOoFileInBook);
registerViewClassProxy(viewOoFileInBookClassProxy);

