import * as React from "react";
import ViewKapCourse, { IViewKapCourseRecord } from "@src/framework/view/kap/ViewKapCourse";
import { me, hasGroup, Groups, hasAnyGroup } from "@src/framework/server/Auth";
import { app } from "@src/index";
import { Link } from "react-router-dom";
import { __ } from "@src/translation";
import './CourseOrganizer.css';
import TrainingTypeCrud, { ITrainingTypeRecord } from "@src/framework/crud/kap/TrainingTypeCrud";
import LookupEdit from "@src/framework/forms/lookupedit";
import InstituteSiteCrud from "@src/framework/crud/sys/InstituteSiteCrud";
import { formatDateWithoutTime, formatDate, addToDate } from "@src/Util";
import CourseUserCrud from "@src/framework/crud/kap/CourseUserCrud";
import { PATH_PUBLISHED_BOOK } from "@src/Routes";
import { viewInstituteSiteClassProxy } from "@src/framework/view/sys/ViewInstituteSite";
import { viewKapTrainingTypeClassProxy } from "@src/framework/view/kap/ViewKapTrainingType";
import { TFilterDict } from "@src/framework/crud/Crud";
import { WF_COURSE_STATION_ACTIVE_ID, WF_COURSE_STATION_INACTIVE_ID } from "./CourseDetails";
import { Breadcrumbs } from "@src/component/Breadcrumbs";
import { DateToString, DTSMode } from "@src/component/dashboard/eventcalendar/EventCalendarAPI";
import { courseModule } from "./courseModule";
import KszrQuestionnarieInCrud from "@src/framework/crud/kap/KszrQuestionnarieInCrud";
import CourseQuestionnarieIn from "./CourseQuestionnarieIn";
import { cmsConfig } from "@src/component/cms/CmsModule";


type CourseLisPublictState = {
    searchParams: any,
    courseList: IViewKapCourseRecord[],
    loading: boolean,
    pageIndex: number,
    selectedType?: number | null,
    selectedTrainer?: number | null,
    selectedSiteId?: number | null,
    isOwn: boolean,
    isTaught: boolean,
    isInspector: boolean,
    showTrainernames: boolean,
    selectedSite?: number | null,
    counter: number,
    courseCode?: number,
    fromDate?: string,
    toDate?: string,
    showQuestionnarieIn: boolean;
    trainingTypes: ITrainingTypeRecord[];
}

var saved: CourseLisPublictState = {
    searchParams: {},
    courseList: [],
    loading: true,
    pageIndex: 1,
    selectedType: undefined,
    selectedTrainer: undefined,
    selectedSiteId: undefined,
    isOwn: false,
    isTaught: false,
    isInspector: false,
    showTrainernames: false,
    fromDate: DateToString(new Date(), DTSMode.dateFull, "-"),
    toDate: DateToString(addToDate(new Date(), 0, 2, 0), DTSMode.dateFull, "-"),
    courseCode: undefined,
    counter: 0,
    showQuestionnarieIn: false,
    trainingTypes: []
}

export default class CoursePublicList extends React.Component<any, CourseLisPublictState> {

    constructor(props: any) {
        super(props);

        if (this.props.match) {
            if (this.props.match.path.startsWith(courseModule.PATH_COURSE_ORGANIZER_COURSE_OWN)) { saved.isOwn = true }
            else if (this.props.match.path.startsWith(courseModule.PATH_COURSE_ORGANIZER_COURSE_TAUGHT)) saved.isTaught = true;
        }
        if (me && hasGroup(me, Groups.KapKszrOhInspector)) saved.isInspector = true;
        if (me && hasGroup(me, Groups.KapKszrCourseTeacher) && !saved.isTaught && !saved.isOwn) saved.showTrainernames = false;
        else { saved.showTrainernames = true; }
        this.state = {
            searchParams: saved.searchParams || {},
            courseList: saved.courseList || [],
            loading: true,
            pageIndex: saved.pageIndex || 1,
            selectedType: saved.selectedType,
            selectedTrainer: saved.selectedTrainer,
            selectedSiteId: saved.selectedSiteId,
            isOwn: saved.isOwn,
            isTaught: saved.isTaught,
            isInspector: saved.isInspector,
            showTrainernames: saved.showTrainernames,
            counter: saved.counter || 0,
            fromDate: saved.fromDate,
            toDate: saved.toDate,
            courseCode: saved.courseCode,
            showQuestionnarieIn: false,
            trainingTypes: [],
        }
    }

    componentDidMount() {
        this.asyncReload();
    }
    componentDidUpdate() {

        let isOwn = false;
        let isTaught = false;
        let isInspector = false;
        let showTrainernames = true;

        if (me && hasGroup(me, Groups.KapKszrOhInspector)) isInspector = true;
        if (this.props.match) {
            if (this.props.match.path.startsWith(courseModule.PATH_COURSE_ORGANIZER_COURSE_OWN)) {
                isOwn = true;
                isTaught = false;
            }
            else if (this.props.match.path.startsWith(courseModule.PATH_COURSE_ORGANIZER_COURSE_TAUGHT)) {
                isOwn = false;
                isTaught = true;
            }
        }
        if (me && hasGroup(me, Groups.KapKszrCourseTeacher) && !saved.isTaught) showTrainernames = false;


        if (isOwn != saved.isOwn || isTaught != saved.isTaught) {
            saved.isOwn = isOwn;
            saved.isTaught = isTaught;
            this.setState({ isOwn, isTaught, isInspector, showTrainernames, selectedType: undefined, selectedSiteId: undefined }, () => this.asyncReload());
        }
    }

    private asyncReload = async () => {

        if (!me) return;
        this.setState({ loading: true });
        try {

            let trainingTypes = await TrainingTypeCrud.list({ filter: { is_active: true } });

            let questionarieList = await KszrQuestionnarieInCrud.list({ filter: { sec_user_id: me.id } });
            let showQuestionnarieIn = ((hasAnyGroup(me, [Groups.KapKszrParticipantTeacher])) && localStorage.getItem("skipQuestionnarieIn") != "true" && questionarieList.length < 1);

            let items: IViewKapCourseRecord[] = [];
            let filter: TFilterDict = { is_active: true };
            let counter = 0;

            if (saved.selectedType) {
                filter.training_type_id = this.state.selectedType;
            }
            if (saved.selectedSiteId) {
                filter.institute_site_id = this.state.selectedSiteId;
            }

            if (this.state.courseCode) {
                filter.regno = Number(this.state.courseCode);
            }

            console.log(filter)

            /*if (this.state.isInspector) {
                filter["<="] = {
                    training_from: "now"
                }
            }*/
            let desc = true;
            if (!this.state.isOwn && !this.state.isTaught && this.state.fromDate) {
                filter[">="] = {
                    training_to: this.state.fromDate
                };

                if (this.state.toDate && this.state.fromDate < this.state.toDate) {
                    filter["<="] = {
                        training_from: this.state.toDate
                    };
                }

                desc = false;
            }

            if (this.state.isOwn) {
                if (this.state.isInspector) {
                    filter.oh_inspector_id = me.id;
                } else {
                    let courseUserList = await CourseUserCrud.list({ filter: { sec_user_id: me.id, is_active: true } });
                    if (courseUserList && courseUserList.length > 0) {
                        let idList: number[] = courseUserList.map(element => {
                            return element.course_id!;
                        });
                        filter.id = idList;
                    } else {
                        filter.id = -1;
                    }
                }

            } else if (this.state.isTaught) {
                filter.teacher_id = me.id;
            } else {
                // all courses
                filter.wf_station_id = WF_COURSE_STATION_ACTIVE_ID; // A lezártat ne rakd be, mert akkor a "minden kurzus" lapon a nagyon régi kurzusok fognak megjelenni a rendezés miatt
            }

            filter.site_id = cmsConfig.siteId;

            // filter.wf_station_id = [WF_COURSE_STATION_ACTIVE_ID, WF_COURSE_STATION_CLOSED_ID];
            if (filter.id == -1) items = [];
            else {
                items = await ViewKapCourse.list({
                    //todo: filter based on url:only own courses, or all available
                    filter: filter,
                    order_by: [{ name: "training_from", desc: desc }],
                    limit: 100
                });
                //counter = await CourseCrud.count({ filter });
                counter = items.length;
            }

            this.setState({ loading: false, courseList: items, trainingTypes, pageIndex: 1, counter, showQuestionnarieIn });
        } catch (error) {
            this.setState({ loading: false });
            app.showErrorFromJsonResult(error);
        }
    }

    private setPageIndex(pageIndex: number) {
        saved.pageIndex = pageIndex;
        this.setState({ pageIndex });
    }

    private onSetTrainingTypeId(typeId?: number) {
        saved.selectedType = typeId;
        this.setState({ selectedType: typeId }, () => this.asyncReload());
    }

    private onSetInstituteId(instituteId?: number) {
        saved.selectedSiteId = instituteId;
        this.setState({ selectedSiteId: instituteId }, () => this.asyncReload());
    }

    private onFromDateChanged(ev: any) {
        this.setState({ fromDate: ev.target.value }, () => this.asyncReload());
    }
    private onToDateChanged(ev: any) {
        this.setState({ toDate: ev.target.value }, () => this.asyncReload());
    }
    private courseCodechanged(ev: any) {
        if (ev.target.value.length == 4)
            this.setState({ courseCode: ev.target.value }, () => this.asyncReload());
        if (ev.target.value.length == 0)
            this.setState({ courseCode: undefined }, () => this.asyncReload());
    }

    render() {

        const rowCount = 12;
        let hitCount: number = this.state.courseList.length;
        let pageCount: number = Math.ceil(hitCount / rowCount);
        let pages = [];
        let pageIndex = this.state.pageIndex;
        let filter_count = __("{n} találat:", { n: this.state.courseList.length });
        let title = saved.isOwn ? __("Felvett kurzusaim") : saved.isTaught ? __("Általam tartott kurzusok") : __("Kurzusok");

        for (var i = 0; i < pageCount; i++) {
            pages.push(i + 1);
        }

        const minIndex = (pageIndex - 1) * rowCount;
        const maxIndex = pageIndex * rowCount;

        let ribbonStyle_active = {
            color: "white",
            bgColor: "#089308",
            ribbonText: __("Aktív")
        };

        let ribbonStyle_future = {
            color: "#52460c",
            bgColor: "#ffea83",
            ribbonText: __("Hamarosan")
        };

        let ribbonStyle_future_apply = {
            color: "#52460c",
            bgColor: "#ffea83",
            ribbonText: __("Jelentkezhet")
        };

        let ribbonStyle_closed = {
            color: "white",
            bgColor: "rgb(53, 53, 53)",
            ribbonText: __("Befejeződött")
        };

        let ribbonStyle_inactive = {
            color: "white",
            bgColor: "rgb(53, 53, 53)",
            ribbonText: __("Szervezés alatt")
        };


        var pageIndents = pages.map((i) => {
            if (pageIndex == i)
                return (
                    <h2><button key={"paginate_" + i} className="active" onClick={this.setPageIndex.bind(this, i)} title={i + ". oldal"}>{i}</button></h2>
                )
            else
                return (
                    <h2><button key={"paginate_" + i} onClick={this.setPageIndex.bind(this, i)}>{i}</button></h2>
                )
        });

        if (!this.state.showQuestionnarieIn) return <div>


            <div className="">
                <Breadcrumbs links={[
                    { name: __("KSZR"), path: courseModule.PATH_COURSE_ORGANIZER },
                    { name: saved.isOwn ? __("Felvett kurzusaim") : saved.isTaught ? __("Általam tartott kurzusok") : __("Kurzusok") },
                ]} />
            </div>

            <div className="">

                <div className="row align-center">
                    <div className="search-filters small-12 medium-12 large-12 column">
                        <h2 className="exe-result-count"><strong>{title}</strong></h2>
                        <div className="small-12 column">
                            <label>{__("Intézmény")}:</label>
                            <LookupEdit
                                key={"institute_site_id_" + (this.state.selectedSiteId || 'null')}
                                fk_table_info_id={InstituteSiteCrud.TABLE_INFO_ID}
                                viewClassProxy={viewInstituteSiteClassProxy}
                                clearable={true}
                                emptyLoad={true}
                                valueColumn="id"
                                value={this.state.selectedSiteId}
                                selectColumnNames={["id", "name"]}
                                displayColumnNames={["name"]}
                                searchColumnNames={["name"]}
                                distinct={true}
                                onChange={(newValue) => this.onSetInstituteId(Number(newValue))}
                                filter={{ is_active: true }}
                                placeholder="Kérem válasszon intézményt..."
                            />
                        </div>
                        <div style={{ paddingTop: "1rem" }} className="small-12 column">
                            <label>{__("Kurzus típusa")}:</label>
                            <LookupEdit
                                fk_table_info_id={TrainingTypeCrud.TABLE_INFO_ID}
                                viewClassProxy={viewKapTrainingTypeClassProxy}
                                clearable={true}
                                emptyLoad={true}
                                selectColumnNames={["id", "title", "no"]}
                                searchColumnNames={["title"]}
                                displayColumnNames={["title"]}
                                orderByColumnNames={"no"}
                                valueColumn="id"
                                filter={{
                                    is_active: true,
                                }}
                                placeholder="Kérem válasszon kurzustípust..."
                                value={this.state.selectedType}
                                onChange={id => this.onSetTrainingTypeId(id as number)}
                            />
                        </div>
                        <div style={{ paddingTop: "1rem" }} className="small-12 column">
                            <label>{__("Azonosító")}:</label>
                            <input style={{ width: "100%", border: "1px solid lightgray", borderRadius: "4px", minHeight: "52px", padding: "2px 8px" }} type="number" placeholder="Kurzus azonosítója..." onChange={this.courseCodechanged.bind(this)}></input>
                        </div>
                        {!this.state.isOwn && !this.state.isTaught &&
                            <div style={{ paddingTop: "1rem" }} className="small-12 column">
                                <div className="row">
                                    <div className="column small-12 medium-6">
                                        <label>{__("Kurzus ideje (-tól)")}
                                            <input type="date"
                                                value={this.state.fromDate}
                                                onChange={this.onFromDateChanged.bind(this)}
                                            /></label>
                                    </div>
                                    <div className="small-12 medium-6 column">
                                        <label>{__("Kurzus ideje (-ig)")}
                                            <input type="date"
                                                value={this.state.toDate}
                                                onChange={this.onToDateChanged.bind(this)}
                                            /></label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <h3 className="exe-result-count"><strong>{filter_count}</strong></h3>
                <div ref="resultContainer" className="kap-course-list-div">
                    {
                        this.state.courseList.slice(minIndex, maxIndex).map((courseRecord: IViewKapCourseRecord) => {
                            const trainingType = this.state.trainingTypes.find(t => t.id === courseRecord.training_type_id);
                            let tmpSrc = courseRecord.training_type_code ? courseRecord.training_type_code.toLowerCase().replace("é", "e") : "";
                            tmpSrc = tmpSrc.replace("-t", "");
                            let img = tmpSrc ? "/img/logo/logo_" + tmpSrc + ".png" : "/img/logo/kapu-logo.png";
                            let ribbon_style = ribbonStyle_active;
                            let isOver = courseRecord.training_to && (Date.parse(courseRecord.training_to) < Date.now());
                            if (isOver) {
                                ribbon_style = ribbonStyle_closed;
                            } else if (courseRecord.wf_station_id == WF_COURSE_STATION_INACTIVE_ID) {
                                ribbon_style = ribbonStyle_inactive;
                            } else if (courseRecord.training_from && (Date.parse(courseRecord.training_from) > Date.now())) {
                                if (this.state.isOwn || this.state.isTaught) {
                                    ribbon_style = ribbonStyle_future;
                                } else {
                                    ribbon_style = ribbonStyle_future_apply;
                                }
                            }
                            return (<div key={"course_" + courseRecord.id} >
                                <div className={"kap-course-tile" + (isOver ? " kap-course-closed" : "")}>
                                    <div className="course-tile-inner-div">
                                        <div style={{ width: "100%", textAlign: "left" }} ><small>{courseRecord.regno}</small></div>
                                        <span style={{ color: ribbon_style.color, backgroundColor: ribbon_style.bgColor }} className="kap-course-tile-ribbon">
                                            <span>{ribbon_style.ribbonText}</span>
                                        </span>

                                        <div className="course-icon-wrapper">
                                            <img src={img} alt={__("Kurzus típus")}
                                                title={courseRecord.subprogram_title || courseRecord.training_type_code || ""} className="kap-course-tile-logo" aria-hidden="true" />
                                            {trainingType && trainingType.is_online ? <div className="online-course" title="Távoktatás">T</div> : ""}
                                        </div>
                                        <div className="align-center kap-course-tile-title">
                                            <h3>
                                                {courseRecord.training_type_code}

                                                <br />
                                                {courseRecord.title ? courseRecord.title : courseRecord.institute_name}
                                            </h3>
                                        </div>
                                        <div className="kap-course-tile-div">
                                            {this.state.showTrainernames && <div>
                                                <strong>{__("Képző: ")}</strong>{courseRecord.teacher_fullname}
                                            </div>}
                                            <div >
                                                <strong>{__("Időtartam: ")}</strong>{formatDateWithoutTime(courseRecord.training_from)} - {formatDateWithoutTime(courseRecord.training_to)}
                                            </div>
                                            <div >
                                                <strong>{__("Jelentkezési határidő: ")}</strong>{formatDate(courseRecord.application_deadline)}
                                            </div>
                                            <div >
                                                <strong>{__("Létszám: ")}</strong>{courseRecord.applied_participants}/{courseRecord.max_participants}
                                            </div>
                                            <div className="course_tile_button_div" >
                                                <Link to={courseModule.PATH_COURSE_ORGANIZER_COURSE + `/${courseRecord.id}/`} className="button small">{__("Részletek")}</Link>
                                                {this.state.isOwn || this.state.isTaught ?
                                                    <Link to={PATH_PUBLISHED_BOOK + "/" + courseRecord.book_uri_segment + "/"} className="button small success float-right">{__("Tananyag")}</Link>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>);
                        })
                    }
                </div>
                <div className="pagination columns text-center">
                    {pageIndents}
                </div>
            </div>
        </div>;
        else {
            return <CourseQuestionnarieIn />;
        }
    }
}