import * as React from 'react';

import { IViewStationRecord, ViewStationClassProxy, viewStationClassProxy } from '@src/framework/view/wf/ViewStation';
import { viewSubstationClassProxy } from '@src/framework/view/wf/ViewSubstation';

import ListView from '@framework/forms/listview';
import  { viewWfTypeClassProxy } from '@framework/view/wf/ViewWfType';
import { TableProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { __ } from '@src/translation';


class StationListviewInner extends ListView<IViewStationRecord> {
    //protected getHeader = () : JSX.Element|null => { return <WfMenu /> }
    public getViewClassProxy() : ViewStationClassProxy { return viewStationClassProxy; }

    protected getOrderBy() : string|undefined {
        return 'no';
    }

    protected getExtraTableProps(): Partial<TableProps> {
        let oldProps = super.getExtraTableProps();
        let inheritedGetTdProps : any = oldProps["getTdProps"];
        const getTdProps = (finalState: any, rowInfo: any, column?: Column, instance?: any) => {
            let props : Partial<TableProps> = {};
            if (inheritedGetTdProps!==undefined) {
                props = inheritedGetTdProps(finalState, rowInfo, column, instance);
            }
            if (column && rowInfo && rowInfo.row) {
                let row : any = rowInfo.row._original;
                if (row) {
                    if (column["id"]=="name") {
                        props["style"] = row.style;
                    }
                }                    
            }
            return props;
        }
        return { ...oldProps, getTdProps }
    }


    protected getTopButtons(): JSX.Element[] {
        let result = super.getTopButtons();
        result.unshift(
            <div className="small-4 medium-4 column" key="back_list">
                <Link className="button" to={viewWfTypeClassProxy.getListUrl({is_active: true})}>
                    <i className="fa fa-step-backward" />&nbsp;{__("Vissza a típusokhoz")}
                </Link>
            </div>
        );
        return result;
    }

    protected getQuickFilterFieldNames(): string[] {
        return ['name', 'description'];
    }        


    protected getSubstationListButton(record:IViewStationRecord) : JSX.Element {
        const wf_type_id = this.state.filterValues ? this.state.filterValues.wf_type_id : undefined;
        return (
            <Link 
                key="head-table-list"
                className="listview-button small" title={__("Al-státuszok")} 
                to={ viewSubstationClassProxy.getListUrl(
                    {station_id: record.id!, is_active: true},
                    {wf_type_id}
                )}>
            <i className="fa fa-tag"></i>
            </Link>);
    }       

    protected getRecordButtons(record: IViewStationRecord) : JSX.Element[] {
        /* TODO: automatikus detail list menüt ide? */
        return super.getRecordButtons(record)
            .concat(this.getSubstationListButton(record))
        ;
    }
}

export default function StationListView(props: any) {
    return <StationListviewInner 
        defaultPageSize={10}
        match={props.match}
        viewColumns={[
            {accessor: "no", maxWidth: 80},
            {accessor: "name", maxWidth: 150},
            {accessor: "description", maxWidth:400},
           {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
