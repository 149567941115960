import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IKipAspectAnswerRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * KIP értékelési szempont
     *
     * NOT NULL -> kap.kip_aspect ON DELETE noaction
     *
     */
    kip_aspect_id?: number;
    /**
     * KIP/Komplex óra látogatás
     *
     * NOT NULL -> kap.class_visit ON DELETE cascade
     *
     */
    class_visit_id?: number;
    /**
     * Válasz (logikai)
     */
    boolean_value?: boolean|null;
    /**
     * Válasz (szöveges)
     */
    text_value?: string|null;
}

/**
 *  kap.kip_aspect_answer - KIP értékelési szempontra adott válasz
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class KipAspectAnswerCrud extends Crud<IKipAspectAnswerRecord> {
    public static TABLE_INFO_ID = 3854180002;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'kip_aspect_answer'; }

    public static load: (id: number, aServer ?: Server) => Promise<KipAspectAnswerCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IKipAspectAnswerRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IKipAspectAnswerRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IKipAspectAnswerRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IKipAspectAnswerRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IKipAspectAnswerRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class KipAspectAnswerCrudClassProxy extends CrudClassProxy<IKipAspectAnswerRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const kipAspectAnswerCrudClassProxy = new KipAspectAnswerCrudClassProxy(KipAspectAnswerCrud);
registerCrudClassProxy(kipAspectAnswerCrudClassProxy);
