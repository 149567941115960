import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import ReactTable, { TableProps, Column } from 'react-table'
import 'react-table/react-table.css'

import { getReactTableLabels } from '@framework/i18n';
import { IViewWfTransitionLogRecord } from '@src/framework/view/wf/ViewWfTransitionLog';
import { __ } from '@src/translation';


interface IWfTransitionLogProps {
    workflow_id: number;
    items: IViewWfTransitionLogRecord[];
}

interface IWfTransitionLogState {
}


export default class WfTransitionLog extends React.Component<IWfTransitionLogProps, IWfTransitionLogState> {
    constructor(props: IWfTransitionLogProps) {
        super(props);
        this.state = {};
    }

    protected getExtraTableProps(): Partial<TableProps> {
        let columns: Column[] = [
            { Header: __("Mikor"), accessor: "creation_time", maxWidth: 150, },
            { Header: __("Ki"), accessor: "creator", maxWidth: 150, },
            { Header: __("Mit"), accessor: "station_name", maxWidth: 150, 
                Cell: ((data: any, column: any) => {
                    if (data.original.dst_station_id || data.original.dst_substation_id) {
                        return <span title={data.original.station_description}>
                            <i className="fa fa-arrow-circle-right"/>
                                &nbsp; {data.original.station_name} {data.original.substation_name}
                        </span>;
                    } else if (data.original.is_auto) {
                        return <span>
                            <i className="fa fa-cogs"/> &nbsp;{__("Esemény")}
                            </span>
                    } else if (data.original.status_text) {
                        return <span>
                            <i className="fa fa-comment"/> &nbsp;{__("Állapot kiegészítés")}
                            </span>
                    } else {
                        return <span>
                            <i className="fa fa-comment"/> &nbsp;{__("Megjegyzés")} 
                            </span>
                    }
                })
            },
            {
                Header: __("Indoklás/üzenet"),
                accessor: "justification",
                Cell: ((data: any, column: any) => {
                    const html = data.original.justification || data.original.status_text;
                    return <div dangerouslySetInnerHTML={{ __html: html }}></div>
                }),
                style:{whiteSpace:"unset"}
            },
        ];
        const getTdProps = (finalState: any, rowInfo: any, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};
            if (column && rowInfo && rowInfo.row) {
                let row: any = rowInfo.row._original;
                if (row) {
                    if (column["id"] == "station_name") {
                        const style = row.station_style || {};
                        props["style"] = {
                            color: style["color"],
                            backgroundColor: style["backgroundColor"]
                        };
                        //console.log(column["id"], props);
                    }
                    /*
                    if (column["id"] == "justification") {
                        props["style"] = {whiteSpace:"unset"};
                        console.log(column["id"], props);
                    }
                    */
                }
            }
            return props;
        }
        let result = { columns: columns, getTdProps: getTdProps };
        if (this.props.items && this.props.items.length <= 10) {
            result["pageSize"] = this.props.items.length;
        }
        return result;
    }

    render() {
        if (!this.props.workflow_id) {
            return null;
        }
        let content;
        if (this.props.items === undefined) {
            content = <BubbleLoader />;
        } else {
            let tableProps = Object.assign(
                { className: "-striped -highlight " },
                this.props,
                getReactTableLabels(),
                { data: this.props.items },
                this.getExtraTableProps()
            );
            content = <ReactTable {...tableProps} />
        }


        return <div className="row expanded">
            <div className="column">
                {content}
            </div>
        </div>;
    }
}