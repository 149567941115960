import * as React from 'react';
import { AttendanceWorkerCard } from './AttendanceWorkerCard';

export class AttendanceWorkerListPage extends React.Component {

    render() {
        return <div className="row" style={{ minHeight: "70vh" }}>
            <div className="column small-12">
                <br />
                <h1 className="title">Munkatársaink</h1>
                <AttendanceWorkerCard name="Jobbágy Zsuzsa" title="munkacsoport-vezető" phone="+36307828681" email="jobbagy.zsuzsa@uni-eszterhazy.hu" img="/img/jtr/munkatarsak/jobbagy_zsuzsa.jpg"></AttendanceWorkerCard>
                <AttendanceWorkerCard name="Borsosné Mitru Margit" title="szakmai koordinátor" phone="+3636520400/4226" email="mitru.margit@uni-eszterhazy.hu" img="/img/jtr/munkatarsak/borsosne_mitru_margit.jpg" otherInfo={["KAPOCS-iskolahálózatba való regisztráció", "Élménysuli pályázat", "Komplex óra nyílt napok és alprogrami bemutató foglalkozások"]}></AttendanceWorkerCard>
                <AttendanceWorkerCard name="Hegyi Lili" title="szakmai koordinátor" phone="+3636520400/4226" email="hegyi.lili@uni-eszterhazy.hu" img="/img/jtr/munkatarsak/hegyi_lili.jpg" otherInfo={["e-SZTR", "Szakmai napok és DFHT-KIP nyílt napok"]}></AttendanceWorkerCard>
                <AttendanceWorkerCard name="Ludányi Eszter" title="szakmai koordinátor" phone="+36303585013" email="ludanyi.eszter@uni-eszterhazy.hu" img="/img/jtr/munkatarsak/ludanyi_eszter.jpg" otherInfo={["Intézményi workshopok (IWS) és igazgatói felkészítő képzések", "Személyes tanácsadások és évközi interjúk", "Pedagógiai programok és szülői nyilatkozatok"]}></AttendanceWorkerCard>
                <AttendanceWorkerCard name="Nagyidai Tamás" title="szakmai koordinátor" phone="+36303237551" email="nagyidai.tamas@uni-eszterhazy.hu" img="/img/jtr/munkatarsak/nagyidai_tamas.jpg" otherInfo={["DFHT-KIP és Komplex órák egyéni hospitálása", "Alprogrami egyéni- és csoportszintű hospitálások", "Záró workshopok (ZWS)"]}></AttendanceWorkerCard>
            </div>
        </div>
    }

}
