import * as React from 'react';
import { Switch, Route, match } from 'react-router';
import Sidebar from '@src/component/Sidebar';
import { Link } from 'react-router-dom';
import { MediaSearchBar } from '@src/component/media/MediaSearchBar';
import { History, Location } from 'history';
import { MediaSearchResults } from '@src/component/media/MediaSearchResults';
import { WatchComponent } from '@src/component/media/WatchComponent';
import { Offers } from '@src/component/media/Offers';
import { PATH_PUBLISHED_MEDIA, PATH_PUBLISHED_MEDIA_HISTORY, PATH_PUBLISHED_MEDIA_POPULAR, PATH_PUBLISHED_MEDIA_FAVOURITE, PATH_PUBLISHED_MEDIA_SEARCH, PATH_PUBLISHED_MEDIA_VIEW, PATH_MEDIA_VIEW_EDITING, PATH_PUBLISHED_MEDIA_LIST } from '@src/Routes';
import { MediaFavourites } from '@src/component/media/MediaFavourites';
import { me } from '@src/framework/server/Auth';
import { MediaPopulars } from '@src/component/media/MediaPopular';
import { MediaHistory } from '@src/component/media/MediaHistory';
import { __ } from '@src/translation';
import { MediaListPage } from './MediaListPage';


type MediaPageProps = {
    match: match<{keywords: string}>;
    history: History;
    location: Location;
}
export class MediaPage extends React.Component<MediaPageProps, {}> {
    
    closeMenubar()
    {
        document.querySelector(".eke-media")!.classList.toggle("full-view");
        document.querySelector(".eke-sidebar")!.classList.toggle("closed");
    }

    render() {

        const sidebar = (
            <Sidebar className="eke-media-leftmenubar" closable={true} defaultClose={true} title={__("Médiatár")}>       
           
                {/* <h4 ><span style={{fontWeight:"normal"}} className="text">{ __("Médiatár") }</span><i style={{padding:"5px"}}  tabIndex={0} onClick={() => this.closeMenubar()} className="fa fa-times"></i></h4>                        */}
                <ul>  
                    <li className="separator"></li>                   
                    <li><Link to={PATH_PUBLISHED_MEDIA}><i className="fa fa-film"></i>&nbsp;<span className="text">{__("Kezdőlap")}</span></Link></li>
                    <li className="separator"></li>
                    {me
                        ?
                        <>
                        <li><Link to={PATH_PUBLISHED_MEDIA_HISTORY}><i className="fa fa-fast-backward"></i><span className="text">&nbsp;{__("Előzmények")}</span></Link></li>                   
                        <li className="separator"></li>
                        <li><Link to={PATH_PUBLISHED_MEDIA_FAVOURITE}><i className="fa fa-star"></i><span className="text">&nbsp;{__("Kedvencek")}</span></Link></li>                 
                        <li className="separator"></li>
                        </>
                        :""
                    }
                        <li><Link to={PATH_PUBLISHED_MEDIA_POPULAR}><i className="fas fa-fire"></i><span className="text">&nbsp;{__("Népszerűek")}</span></Link></li>                 
                </ul>                  
                      
        </Sidebar> 
        );
        
        return <div className="eke-media full-view">
            {sidebar}          
            {
                this.props.location.pathname.indexOf(PATH_PUBLISHED_MEDIA_VIEW)>-1?"":<MediaSearchBar 
                match={this.props.match}
                location={this.props.location}
                history={this.props.history}
            />
            }                      
                <div className="eke-media-rightcontainer">
                       
                        <Switch>
                            <Route path={PATH_PUBLISHED_MEDIA_SEARCH + "/:keywords?"} component={MediaSearchResults}/>

                            <Route path={PATH_PUBLISHED_MEDIA_VIEW + "/:ooFileId?"} component={WatchComponent}/>

                            
                            <Route path={PATH_PUBLISHED_MEDIA_LIST} component={MediaListPage}/>


                            <Route path={PATH_MEDIA_VIEW_EDITING + "/:ooFileId?"} component={WatchComponent}/>

                            <Route path={PATH_PUBLISHED_MEDIA_HISTORY} component={MediaHistory}/>
                                           
                            <Route path={PATH_PUBLISHED_MEDIA_POPULAR} component={MediaPopulars}/> 

                            <Route path={PATH_PUBLISHED_MEDIA_FAVOURITE} component={MediaFavourites}/>

                            <Route path={PATH_PUBLISHED_MEDIA} component={Offers}/>                
                        </Switch>            
                </div>
            </div>
    }
}
