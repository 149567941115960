import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IMediaTypeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Név
     */
    title?: string;
    /**
     * Leírás
     */
    description?: string|null;
}

/**
 *  media.media_type - Média típus
 */

export default class MediaTypeCrud extends Crud<IMediaTypeRecord> {
    public static TABLE_INFO_ID = 2047920989;
    public static getSchemaNameForClass() { return 'media'; }
    public static getTableNameForClass() { return 'media_type'; }

    public static load: (id: number, aServer ?: Server) => Promise<MediaTypeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IMediaTypeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IMediaTypeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IMediaTypeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IMediaTypeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IMediaTypeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class MediaTypeCrudClassProxy extends CrudClassProxy<IMediaTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const mediaTypeCrudClassProxy = new MediaTypeCrudClassProxy(MediaTypeCrud);
registerCrudClassProxy(mediaTypeCrudClassProxy);
