import * as React from 'react';
import { Link } from 'react-router-dom';
import { BubbleLoader } from 'react-css-loaders';

import WorkflowCrud, { IWorkflowRecord, WorkflowCrudClassProxy, workflowCrudClassProxy } from '@src/framework/crud/wf/WorkflowCrud';
import RecordEditor, { IFieldEditorProp } from '@framework/forms/recordeditor';
import WfPlugin from './wf_plugin';
import { CrudClassProxy, IRecord, getCrudClassProxyById } from '@src/framework/crud/Crud';
import { getTableInfoById, ITableInfo, ITableInfoTranslation } from '@src/framework/crud/Meta';
import { __ } from '@src/translation';


import { app } from '@src/index';

export default class WfWorkflowEditor extends RecordEditor<IWorkflowRecord> {
    private referencedTableInfo : ITableInfo;
    private referencedCrudClassProxy : CrudClassProxy<IRecord>;
    private title : string;

    public getCrudClassProxy() : WorkflowCrudClassProxy { return workflowCrudClassProxy;  }


    protected getFieldEditors(fieldEditorProps: IFieldEditorProp[]): JSX.Element[] {
        return [];
    }

    protected getTopButtons(): JSX.Element[] {
        return [
            <div key="back-to-list" className="small-4 medium-4 column">
                <button className="button" onClick={this.onList}>
                    <i className="fa fa-step-backward" />&nbsp;{__("Lista")}</button>
            </div>,            
        ];
    }


    protected getBottomButtons(): JSX.Element[] {
        return [];
    }

    protected getDetailPanels() : any[] {
        if (this.referencedTableInfo===undefined) {
            return [];
        }

        const rec = this.state.rec!;
        const translation : ITableInfoTranslation = this.referencedTableInfo.translations[this.getLangId()];
        const edit_url = this.referencedCrudClassProxy.getEditUrl(rec.rec_id!);

        let result = [];
        result.push(<div className="callout primary">
            <h3>{this.title}</h3>
            { __("Ez egy") } <b>{translation.display_name}</b>{__("folyamat.")}
            &nbsp;
            <Link className="button primary" type="button" to={edit_url}>
                    <i className="fa fa-edit"/>
                    {__("Szerkesztés a saját felületén")}
            </Link>
            <WfPlugin
                tableInfo={this.referencedTableInfo}
                recId={rec.rec_id!}
                />
        </div>);
        return result;
    }

    private loadAsync = async () => {
        const rec = this.state.rec!;
        const tableInfoId = rec.table_info_id!;
        this.referencedCrudClassProxy = getCrudClassProxyById(tableInfoId);
        this.referencedTableInfo = await getTableInfoById(tableInfoId);
        try {
            this.title = (await this.referencedCrudClassProxy.asText(rec.rec_id!, false)).text;
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
        this.forceUpdate();
    }

    render() {
        if (this.referencedCrudClassProxy===undefined) {
            if (this.state.rec)
                this.loadAsync();
            return <BubbleLoader />
        } else {
            return super.render();
        }
    }
}