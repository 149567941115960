import * as React from 'react';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import { LIBRARY_OFI_OFFICIAL_ID, LIBRARY_PERSONAL_ID } from '@src/Const';
import { app } from '@src/index';
import { __ } from '@src/translation';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { PATH_PUBLISHED_BOOK } from '@src/Routes';
import ChapterCrud, { IChapterRecord } from '@src/framework/crud/doc/ChapterCrud';
import LessonCrud, { ILessonRecord } from '@src/framework/crud/doc/LessonCrud';
import { RadioTabs, RadioTab } from '@src/component/ui/RadioTab';
import { me, hasAnyGroup, Groups } from '@src/framework/server/Auth';
import LibraryCrud, { ILibraryRecord } from '@src/framework/crud/doc/LibraryCrud';
import './LpBookList.css';
import GradeCrud, { IGradeRecord, gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import SubjectCrud, { ISubjectRecord, subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import { AccordionItem, Accordion } from '@src/component/ui/Accordion';
import CrudSelect from '@framework/forms//crudselect';
import { TFilterDict } from '@src/framework/crud/Crud';
import { getReactTableLabels } from '@src/framework/i18n';

let selectedBook: IBookRecord | undefined;
let grades: IGradeRecord[];
let sortedGrades: IGradeRecord[];
let subjects: ISubjectRecord[];

type LpBookListProps = {
    onBookSelected?: (bookRecord: IBookRecord) => void,
    onChapterSelected?: (chapterRecord: IChapterRecord) => void,
    onLessonSelected?: (lessonRecord: ILessonRecord) => void,
}

type BookSearchParams = {
    gradeId?: number,
    isSNI?: boolean,
    subjectId?: number,
    pageSize: number,
}

interface IBookListState {
    bookRecords: IBookRecord[];
    selectedBook?: IBookRecord;
    chapterRecords?: any[];
    selectedChapter?: IChapterRecord;
    selectedLesson?: ILessonRecord;
    selectedLibraryId?: number;
    libraries: ILibraryRecord[];
    searchParams: BookSearchParams,
    count?: number,
    loading: boolean,
}
export default class LpBookList extends React.Component<LpBookListProps, IBookListState> {
    constructor(props: any) {
        super(props);
        this.state = {
            bookRecords: [],
            libraries: [],
            searchParams: {
                pageSize: 10,
            },
            loading: true,
        }
    }

    async reloadAsync() {
        try {
            this.setState({loading: true});
            let libraries = [];
            if(me && hasAnyGroup(me, [Groups.OFIEditor, Groups.KapELearningEditor])) {
                libraries = await LibraryCrud.list({filter: {is_active: true}, order_by: "id"});
            }
            else {
                libraries = [(await LibraryCrud.load(LIBRARY_PERSONAL_ID)).record];
            }
            grades = await GradeCrud.list({filter: {is_active: true}});
            sortedGrades = grades.sort((a:IGradeRecord, b:IGradeRecord) => a.name!.localeCompare(b.name!, undefined, {numeric: true, sensitivity: 'base'}));
            subjects = await SubjectCrud.list({filter: {is_active: true}, order_by: "name"});

            await this.reloadBooks();

            this.setState({
                loading: false,
                selectedBook: undefined,
                libraries,
            });
        }
        catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    async loadBookStructure() {
        try {
            if (selectedBook && this.state.selectedBook && selectedBook.id == this.state.selectedBook.id) return;
            selectedBook = this.state.selectedBook;
            if (selectedBook && selectedBook.id) {
                let chapters: any[] = await ChapterCrud.list({ filter: { book_id: selectedBook.id } });
                let chapterLessons: any[] = [];
                for (let i = 0; i < chapters.length; i++) {
                    let chapterRecord = chapters[i];
                    let lessons: ILessonRecord[] = await LessonCrud.list({ filter: { is_active: true, chapter_id: chapterRecord.id } });
                    chapterRecord = {
                        ...chapterRecord,
                        lessons: lessons,
                    }
                    chapterLessons.push(chapterRecord);
                }
                this.setState({
                    chapterRecords: chapterLessons,
                    count: chapterLessons.length,
                });
            }
            else return;
        }
        catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    async reloadBooks(library_id?: number) {
        try {
            let filter: TFilterDict = { is_active: true, library_id: library_id ? library_id : LIBRARY_OFI_OFFICIAL_ID };
            if (this.state.searchParams.gradeId) {
                filter.grade_id = Number(this.state.searchParams.gradeId);
            }

            if (this.state.searchParams.subjectId) {
                filter.subject_id = Number(this.state.searchParams.subjectId);
            }

            if (this.state.searchParams.isSNI) {
                filter.is_sni = this.state.searchParams.isSNI;
            }

            let bookRecords: IBookRecord[] = await BookCrud.list({filter});
            
            for (let i = 0; i < bookRecords.length; i++) {
                const bookRecord = bookRecords[i];
                let editBtn = null;
                if (this.props.onBookSelected) {
                    editBtn = <button className="button small" onClick={this.onBookSelect.bind(this, bookRecord)}>{__("Fejezetek")}<i className="fa fa-arrow-right"></i></button>;
                }
                bookRecord["link"] = <div className="exercise-list-page-button-container">{editBtn} </div>;
                bookRecord["subject_record"] = subjects.find((sub: ISubjectRecord) => sub.id == bookRecord.subject_id);
                bookRecord["grade_record"] = grades.find((grade: IGradeRecord) => grade.id == bookRecord.grade_id);
            }
            this.setState({
                bookRecords,
                selectedBook: undefined,
                chapterRecords: undefined,
            })
        }
        catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: LpBookListProps, prevState: IBookListState) {
        if (this.state.selectedLibraryId && this.state.selectedLibraryId != prevState.selectedLibraryId) this.reloadBooks(this.state.selectedLibraryId);
        if (this.state.selectedBook) this.loadBookStructure();
    }

    onBookSelect(bookRecord: IBookRecord) {
        if (this.props.onBookSelected) {
            this.props.onBookSelected(bookRecord);
            this.setState({
                selectedBook: bookRecord,
            })
        }
    }

    onChapterSelect(chapterRecord: IChapterRecord) {
        if (this.props.onChapterSelected) {
            this.props.onChapterSelected(chapterRecord);
            this.setState({
                selectedChapter: chapterRecord,
            })
        }
    }

    onLessonSelect(lessonRecord: ILessonRecord) {
        if (this.props.onLessonSelected) {
            this.props.onLessonSelected(lessonRecord);
            this.setState({
                selectedLesson: lessonRecord,
            })
        }
    }

    resetSelectedBook() {
        if (this.state.selectedBook) this.setState({ selectedBook: undefined });
    }

    onHandleInputChange(event: any) {
        const target = event.target;
    
        var value = target.value;
        if (target && target.type === 'checkbox') {
            value = target.checked;
        }
        else if (target && (target.type == 'select-one' || target.getAttribute("data-type") == "number")) {
            value = Number(target.value);
        }
        let name = target.name;
        this.setState({ searchParams: {...this.state.searchParams,[name]: value}});
        this.reloadAsync();
      }

    private onGradeSelect = (sender: CrudSelect, newGrade: number | null): void => {
        const gradeId: any = newGrade;
        this.setState({ searchParams: { ...this.state.searchParams, gradeId, }});
        this.reloadAsync();
    }

    private onSubjectSelect = (sender: CrudSelect, newSubject: number | null): void => {
        const subjectId: any = newSubject;
        this.setState({ searchParams: { ...this.state.searchParams, subjectId }})
        this.reloadAsync();
    }

    render() {
        let filteredRecords = this.state.bookRecords;
        let filteredChapterRecords = this.state.chapterRecords;
        let bookTableProps = {
            columns: [
                {
                    Header:
                        <div>
                            <b>{__("Tankönyv neve")}</b>
                        </div>,
                    accessor: "name",
                    minWidth: 320,

                    Cell: (data: any, column: any) => {
                        const row: IBookRecord = data.original;
                        const baseUrl = PATH_PUBLISHED_BOOK;
                        return <Link to={baseUrl + `/${row.uri_segment}`} style={{ width: "100%" }}>
                            <b>{row.name}</b>
                        </Link>
                    }
                },
                {
                    Header:
                        __("Évfolyam"),
                    accessor: "book_grade",
                    minWidth: 180,

                    Cell: (data: any, column: any) => {
                        const row = data.original;
                        return <label>{row.grade_record ? row.grade_record.name : "unknown"}</label>;
                    }
                },
                {
                    Header:
                        __("Művelet"),
                    accessor: "link",
                    filterable: false,
                    sortable: false,
                    minWidth: 200,
                    className: "action-cell"
                },
            ],
            data: filteredRecords,
            filterable: true,
            loading: this.state.loading,
            defaultPageSize: 10, 
            className: "-striped -highlight",
            ...getReactTableLabels(),
        }

        return (
            <div>
                <div className="small-12 medium-12">
                    <RadioTabs tabGroup="bookTabs" onChange={(value: any) => this.setState({ selectedLibraryId: value })}>
                        {
                            this.state.libraries.map((l, i) => {
                                return <RadioTab key={l.id} value={l.id!} selected={l.id == this.state.selectedLibraryId}>
                                    {l.name}
                                </RadioTab>
                            })
                        }
                        {
                            (me && !hasAnyGroup(me, [Groups.OFIEditor, Groups.KapELearningEditor])) ?
                                <RadioTab value={0}>
                                    {__("Publikált könyvek")}
                                </RadioTab>
                                : ""
                        }

                    </RadioTabs>
                </div>
                <div className="small-12 medium-12 filters">
                    <Accordion>
                        <AccordionItem defaultClosed key={"filter_accordion"} title={__("Szűrők")}>
                            <div className="medium-12 row">
                                <div className="small-12 medium-6 large-4 column">
                                    <label className="lms-pages-typeLabel">{__("Évfolyam")}</label>
                                    <CrudSelect
                                        value={this.state.searchParams.gradeId || null}
                                        onSelect={this.onGradeSelect}
                                        displayFieldName="name"
                                        orderByFieldName="id"
                                        key="id"
                                        crudClassProxy={gradeCrudClassProxy}
                                        filter={{ is_active: true }}
                                        sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                                    />
                                </div>
                                <div className="small-12 medium-6 large-4 column">
                                    <label className="lms-pages-typeLabel">{__("Tantárgy")}</label>
                                        <CrudSelect
                                            value={this.state.searchParams.subjectId || null}
                                            onSelect={this.onSubjectSelect}
                                            displayFieldName="name"
                                            key="id"
                                            emptyTitle={__("Minden tantárgy")}
                                            clearable={true}
                                            crudClassProxy={subjectCrudClassProxy}
                                            filter={{ is_active: true }}
                                        />
                                </div>
                                <div className="small-12 medium-6 large-4 column">
                                    <input type="checkbox" name="isSNI" id="isSNI" checked={this.state.searchParams.isSNI} onChange={this.onHandleInputChange.bind(this)} />
                                    <label className="lms-pages-typeLabel">{__("SNI")}</label>
                                </div>
                            </div>
                        </AccordionItem>
                    </Accordion>
                </div>
                <div className="small-12 medium-12">
                    {!this.state.selectedBook ? <ReactTable {...bookTableProps}/> : 
                        <div className="lpChapterLesson-list">
                            <div className="lpChapterLesson-header">
                                <div className="lpChapterLesson-chapter-name">
                                    <label>{this.state.selectedBook.name}</label>
                                </div>
                                <div className="text-right">
                                    <button className="button clear" onClick={this.resetSelectedBook.bind(this)}>{__("Vissza a könyvekhez")}</button>
                                </div>
                            </div>
                        {
                            filteredChapterRecords ? filteredChapterRecords.map((chapter, chapterIndex: number) => {
                                return (
                                    <div key={chapterIndex} className="lpChapterLesson-chapter">
                                        <div className="lpChapterLesson-chapter-inner">
                                            <div className="lpChapterLesson-chapter-name">
                                                <strong>{chapter.name}</strong> {chapter.headno ? <i><small>({chapter.headno}. {__("fejezet")}</small></i> : ""})
                                            </div>
                                            <button className="button small secondary" onClick={this.onChapterSelect.bind(this, chapter)}>{__("Egész fejezet hozzáadása")}</button>
                                        </div>
                                        {chapter.lessons ? chapter.lessons.map((lesson: ILessonRecord, lessonIndex: number) => {
                                            return (
                                                <div key={lessonIndex} className="lpChapterLesson-lesson-inner">
                                                    <div className="lpChapterLesson-lesson-name">{lesson.name} <i style={{ fontSize: "small" }}>({lesson.headno ? lesson.headno + ". lecke, " : ""}{lesson.uri_segment})</i></div>
                                                    <button className="button small" onClick={this.onLessonSelect.bind(this, lesson)}><i className="fa fa-plus"/></button>
                                                </div>
                                            )
                                        }) : ""}
                                    </div>
                                );
                            }) : ""
                        }
                        </div>
                    }
                </div>
            </div>
        );
    }
}