import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewPopularVideoRecord {
    /**
     * File azonosító
     *
     * File azonosító
     */
    id?: number;
    /**
     * Lejátszások száma
     *
     * Lejátszások száma
     */
    plays?: number|null;
    /**
     * Nyilvánosan megjelenő név
     *
     * Nyilvánosan megjelenő név
     */
    displayname?: string|null;
    /**
     * Állomány név
     *
     * Állomány név
     */
    title_text?: string|null;
    /**
     * ALT szöveg
     *
     * ALT szöveg
     */
    alt_text?: string|null;
    /**
     * SHA1
     *
     * SHA1
     */
    sha1?: string|null;
}

/**
 *  media_pub.view_popular_video - Népszerű videó
 *
 *  Népszerű videó
 */

export default class ViewPopularVideo extends View<IViewPopularVideoRecord> {
    public static VIEW_INFO_ID = 2018100501;

    public static getSchemaNameForClass(): string { return 'media_pub'; }
    public static getViewNameForClass(): string { return 'view_popular_video'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static canCache(): boolean {
        return true;
    }

    public static load: (id: number, aServer ?: Server) => Promise<ViewPopularVideo>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewPopularVideoRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewPopularVideoClassProxy extends ViewClassProxy<IViewPopularVideoRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewPopularVideoClassProxy = new ViewPopularVideoClassProxy(ViewPopularVideo);
registerViewClassProxy(viewPopularVideoClassProxy);

