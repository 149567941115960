import * as React from 'react';
import ErrorReport from '../../component/error_report/error_report_popup';
import { PATH_KNOWLEDGE_BASE_LIST, PATH_GUIDE_USER, PATH_GUIDE_EDITOR, PATH_PAGE_LEARNING_SOFTWARE, PATH_DEVELOPER_PAGES, PATH_IMPRESSUM } from '@src/Routes';
import { Link } from 'react-router-dom';
import { me, hasGroup, Groups } from '@src/framework/server/Auth';
import { __ } from '@src/translation';
interface IFooterProps {
    showErrorReportDialog:boolean;
    
}

export default class Footer extends React.Component<any, IFooterProps> {
    constructor(props: IFooterProps) {
        super(props);

        this.state = {     
            showErrorReportDialog:false,
        };
    }

    render() {
        return <footer className="eke-footer-wrapper">
              <ErrorReport 
                        onClosedInComponent={() => this.setState({showErrorReportDialog:false})}
                        showErrorDialog={this.state.showErrorReportDialog}                    
                        /> 
                <div className="eke-footer-icons">
                    <div className="row medium-up-4 small-up-2 align-center">
                        <div className="column"><img alt="" className="mt10" src="/img/oh_logo_HU.svg" /></div>
                        {/* <div className="column"><img alt="" className="mt10" src="/img/FOOTER/06_szechenyi_2020.svg" /></div> */}
                        <div className="column"><img alt="" src="/img/FOOTER/03_emberi_forasok_miniszteriuma.svg" /></div>
                    </div>
                </div>

                <div className="footer-bottom">

                    <div className="row">
                        <ul className="menu">
                            <li className="menu-logo"><a  href="/"><img src="/img/NKP_logo.svg"/></a></li>
                            <li>
                                <div className="menu-item">
                                    <span> {__("Kapcsolat:")}</span>&nbsp;<a className="mailto" href="mailto:tamogatas@nkp.hu">tamogatas@nkp.hu</a>
                                </div>
                            </li>
                            <li><a href="/nkp_felhasználási_feltételek.pdf"> {__("Felhasználási Feltételek")}</a></li>
                            <li><a href="/nkp_adatkezelési_tájékoztató.pdf"> {__("Adatvédelmi Szabályzat")}</a></li>
                            <li><a href="/NKP_akadalymentessegi_nyilatkozat.pdf" target="_blank"> {__("Akadálymentesség")}</a></li>
                            <li><Link to={PATH_KNOWLEDGE_BASE_LIST}> {__("GYIK")}</Link></li>
                        </ul>
                        <ul className="menu">
                            {/*<li><Link to={PATH_GUIDE_USER}>{__("Felhasználói Kézikönyv")}</Link></li>*/}
                            {/* <li><Link to={PATH_GUIDE_EDITOR}>{__("Szerkesztői Kézikönyv")}</Link></li> */}
                            <li><Link to={PATH_PAGE_LEARNING_SOFTWARE}>{__("Tanulási Szoftverek")}</Link></li>
                            <li><Link to={PATH_IMPRESSUM}>{__("Impresszum")}</Link></li>
                            { me && 
                                <li><a tabIndex={0} style={{cursor: "pointer"}} onClick={()=>this.setState({showErrorReportDialog:true})}>{__("Hibabejelentés")}</a></li>
                            }
                        </ul>
                        { me && hasGroup(me, Groups.Developer)
                            ?
                            <>
                            <ul className="menu">           
                                <Link to={PATH_DEVELOPER_PAGES}  className="meow" tabIndex={0} title="meow">                
                                <i className="fa fa-2x fa-cat temp-error"><span></span></i>
                                    <i className="fa fa-2x fa-cat iseeyou"><span></span></i>
                                </Link>
                            </ul>
                            </>
                            :
                            null
                        }
                    </div>
                    
                    <div className="row eke-copyright align-center">
                        <p className="large-10 small-10">
                        {__("A portál adatbázisában közzétett tanulmányok, szerzői művek vagy más szerzői jogi védelem alá eső termékek (továbbiakban: művek) jogtulajdonosa az Oktatási Hivatal. ")} 
                        {__("A jogtulajdonos – egyértelmű forrásmegjelölés mellett – engedélyezi a művek tudományos, kulturális és köznevelési intézmény keretében folyó oktatási felhasználását, amennyiben az még közvetett módon sem eredményezi a felhasználói haszonszerzést. ")}
                        {__("A jogtulajdonos kifejezett írásbeli engedélyéhez köti a művek továbbhasznosítását, és kifejezetten megtiltja a művek – akár közvetett módon történő – haszonszerzés érdekében való felhasználását.")}    
                        </p>
                    </div>
                </div>
        </footer>;
    }

}
