import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISecItemTypePermRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Tudásbázis bejegyzés típus
     *
     * NOT NULL -> kb.item_type ON DELETE cascade
     *
     */
    item_type_id?: number;
    /**
     * Engedély birtokos
     *
     * NOT NULL -> sys.sec_perm_owner ON DELETE cascade
     *
     */
    sec_perm_owner_id?: number;
    /**
     * Létrehozás
     *
     * Új rekord létrehozása
     */
    can_create?: boolean;
    /**
     * Módosítás
     *
     * Létező rekord módosítása
     */
    can_update?: boolean;
    /**
     * Törlés
     *
     * Létező rekordok törlése
     */
    can_delete?: boolean;
    /**
     * Olvasás
     *
     * Létező rekordok lekérdezése, szűrése
     */
    can_read?: boolean;
}

/**
 *  kb.sec_item_type_perm - Típus függő tudásbázis bejegyzés jog
 *
 *  Azt mondja meg, hogy az adott típusú tudásbázis bejegyzést ki tud létrehozni.
 */

export default class SecItemTypePermCrud extends Crud<ISecItemTypePermRecord> {
    public static TABLE_INFO_ID = 1707931315;
    public static getSchemaNameForClass() { return 'kb'; }
    public static getTableNameForClass() { return 'sec_item_type_perm'; }

    public static load: (id: number, aServer ?: Server) => Promise<SecItemTypePermCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISecItemTypePermRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISecItemTypePermRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISecItemTypePermRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISecItemTypePermRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISecItemTypePermRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SecItemTypePermCrudClassProxy extends CrudClassProxy<ISecItemTypePermRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const secItemTypePermCrudClassProxy = new SecItemTypePermCrudClassProxy(SecItemTypePermCrud);
registerCrudClassProxy(secItemTypePermCrudClassProxy);
