import * as React from 'react';
import { List, ListItem, ListItemText } from '../ui/List';
import { formatDate } from '@src/Util';
import { Link } from 'react-router-dom';
import { BubbleLoader } from 'react-css-loaders';
import {app} from '@src/index';
import * as Server from '@src/server/PublicServer';
import { PATH_PUBLISHED_SEARCH } from '@src/Routes';

export interface SearchHistoryTileProps{
    settings: any,
}

export interface ISearchHistory{
    keywords: string,
    searchTime: Date,
}

export default class SearchHistoryTile extends React.Component<SearchHistoryTileProps,any> {
    constructor(props: any) {
        super(props);
        this.state = {
            SearchHistory: [],
            loading: true,
        };
    }

    componentDidMount(){
        this.reloadAsync();
    }   

    async reloadAsync() {
        try {
            var SearchHistory = await Server.searchHistory();
            const limit = Number(this.props.settings.limit);
            SearchHistory = SearchHistory.slice(0, limit ? limit : 20);
            this.setState({
                SearchHistory,
                loading: false
            });
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }
    
    render(){
        if (this.state.loading) {
            return <BubbleLoader />
        }

        return<>
        <List>
            {
                this.state.SearchHistory.map((event:any, index:any) => {
                    const history = this.state.SearchHistory[index];
                    if (!history) return;
                    return <ListItem key={index}>
                        <ListItemText>
                            <Link to ={PATH_PUBLISHED_SEARCH +"/"+ history.keywords}>
                                {history.keywords}
                            </Link>
                                <div>
                                    <small className="date">{formatDate(history.searchTime)}</small>
                                </div>
                        </ListItemText>
                    </ListItem>;
                })
            }
        </List>
        <div></div>
        </>
    }
}


   