import './style.css';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { ExerciseBaseClass } from '../../models/ExerciseBaseClass';
import { UniversalElement } from './UniversalEngineConverter';
import { ExerciseEngineHelper } from '../ExerciseEngineHelper';
import polylabel from 'polylabel';
import { UniversalEngineServer } from './UniversalEngineServer';
export interface UniversalData extends ExerciseBaseClass {
    show_areas: boolean;
    show_draggables: boolean;
    illustration_task: string;
    illustration_background: string;
    areas: UniversalElement[],
    draggables: UniversalElement[]
}

type RectangleMinMax = {
    min_x: number,
    min_y: number,
    max_x: number,
    max_y: number,
}

type Point = {
    x: number,
    y: number
}

type ImgDim = {
    width: number,
    height: number
}

export class UniversalExerciseEngine extends AExerciseEngine {
    private divDraggables: HTMLDivElement[] = [];
    private svgAreas: any[] = [];
    private userStarted: boolean = false;
    private oddIndexes: number[] = [];
    private prevImgDim: ImgDim = { width: 0, height: 0 };

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);

        let exercise: UniversalData = params.exercise;

        if (!exercise || !exercise.illustration_task)
            return;

        this.root.classList.add("universal-engine");

        let dropDiv = this.root.appendChild(document.createElement("div"));
        dropDiv.classList.add("drop-div");

        let taskImage = dropDiv.appendChild(document.createElement("img"));
        const taskSrc = exercise.imagebasepath + exercise.illustration_task;
        taskImage.setAttribute("src", taskSrc);
        taskImage.classList.add("exe-img-no-zoom", "task-image");

        //We can only display the areas/draggables once the image has been loaded
        taskImage.onload = this.displayExercise.bind(this, dropDiv, taskImage, exercise);

        //Getting odd one out indexes
        for (let k = 0; k < exercise.draggables.length; k++) {
            let found = false;
            for (let j = 0; j < exercise.areas.length; j++) {
                if (exercise.areas[j].answer_indexes!.indexOf(k) != -1) {
                    found = true;
                    break;
                }
            }
            if (!found) this.oddIndexes.push(k);
        }
        window.onresize = this.resize.bind(this);
    }

    displayExercise(divDropArea: HTMLElement, taskImg: HTMLImageElement, exercise: UniversalData) {
        //Creating canvas for cut from the image
        let mainCanvas = divDropArea.appendChild(document.createElement("canvas"));
        mainCanvas.style.backgroundImage = `url('/${this.exercise.illustration_background}')`;
        mainCanvas.style.backgroundSize="100% 100%";
        mainCanvas.classList.add("task-canvas");
        mainCanvas.width = taskImg.clientWidth;
        mainCanvas.height = taskImg.clientHeight;
        mainCanvas.style.left = String(taskImg.offsetLeft);
        mainCanvas.style.top = String(taskImg.offsetTop);

        let mainContext = mainCanvas.getContext("2d");
        let contSVG = divDropArea.appendChild(document.createElementNS("http://www.w3.org/2000/svg", "svg"));
        let imgLoadRatio = taskImg.clientWidth / taskImg.naturalWidth;

        contSVG.setAttribute("align", "left");
        contSVG.classList.add("cont-svg");
        contSVG.style.height = String(taskImg.clientHeight);
        contSVG.style.width = String(taskImg.clientWidth);
        contSVG.style.left = String(taskImg.offsetLeft);
        contSVG.style.top = String(taskImg.offsetTop);

        if (mainContext) {
            mainContext.drawImage(taskImg, 0, 0, taskImg.naturalWidth, taskImg.naturalHeight, 0, 0, taskImg.clientWidth, taskImg.clientHeight);
        }

        //Displaying exercise areas
        for (let i = 0; i < exercise.areas.length; i++) {
            let curr_area = exercise.areas[i];
            if (!curr_area.points) this.displayCircle(curr_area, contSVG, taskImg, i);
            else this.displayPolygon(curr_area, contSVG, taskImg, i)
        }

        //Displaying exercise draggables and make a transparent cut to the main-canvas
        for (let i = 0; i < exercise.draggables.length; i++) {
            let curr_dr = exercise.draggables[i];

            let draggable_div = divDropArea.appendChild(document.createElement("div"));
            draggable_div.classList.add("draggable-div");
            if (this.exercise.show_draggables) draggable_div.classList.add("outline");

            let draggable_canvas = draggable_div.appendChild(document.createElement("canvas"));
            let ctx = draggable_canvas.getContext('2d');

            let curr_dim = ExerciseEngineHelper.getPolyCoordsImagePixels(curr_dr.points!, taskImg.naturalHeight, taskImg.naturalWidth);
            let arr = ExerciseEngineHelper.getPolyCoordinatePairs(curr_dim);

            let rec = this.getMinMaxRectangle(arr);

            let cut_width = rec.max_x - rec.min_x;
            let cut_height = rec.max_y - rec.min_y;

            if (ctx) {
                ctx.canvas.width = cut_width * imgLoadRatio;
                ctx.canvas.height = cut_height * imgLoadRatio;
                ctx.drawImage(taskImg, rec.min_x, rec.min_y, cut_width, cut_height, 0, 0, cut_width * imgLoadRatio, cut_height * imgLoadRatio);
            }

            //Make the transparent cut
            if (mainContext)
                mainContext.clearRect(rec.min_x * imgLoadRatio, rec.min_y * imgLoadRatio, cut_width * imgLoadRatio, cut_height * imgLoadRatio);

            draggable_div.style.width = (cut_width * imgLoadRatio) + "px";
            draggable_div.style.height = (cut_height * imgLoadRatio) + "px";
            draggable_div.style.top = imgLoadRatio * (taskImg.naturalHeight - (taskImg.naturalHeight - rec.min_y)) + "px";
            draggable_div.style.left = ((rec.min_x * imgLoadRatio)) + "px";
            draggable_div.title = curr_dr.alt ? curr_dr.alt : "";

            ($(draggable_div) as any).draggable({
                start: this.drag.bind(this),
                stop: this.drop.bind(this),
                containment: divDropArea,
                scroll: true
            });
            this.divDraggables.push(draggable_div);
        }

        this.prevImgDim = { width: taskImg.clientWidth, height: taskImg.clientHeight }

    }

    drag(ev: any) {
        ev.target.classList.remove("outline");
    }

    drop(ev: any) {
        this.userStarted = true;
        if (this.exercise.show_draggables) ev.target.classList.add("outline");
        if (this.isSNIexc) this.SNIEvaluation(UniversalEngineServer);
    }

    dropBackElement(index: number) {
        let curr_dr = this.exercise.draggables[index];
        let el: HTMLDivElement = this.divDraggables[index];
        let taskImg: HTMLImageElement | null = this.root.querySelector(".task-image");
        if (taskImg) {
            let imgLoadRatio = taskImg.clientWidth / taskImg.naturalWidth;
            let curr_dim = ExerciseEngineHelper.getPolyCoordsImagePixels(curr_dr.points!, taskImg.naturalHeight, taskImg.naturalWidth);
            let arr = ExerciseEngineHelper.getPolyCoordinatePairs(curr_dim);
            let rec = this.getMinMaxRectangle(arr);
            el.style.top = imgLoadRatio * (taskImg.naturalHeight - (taskImg.naturalHeight - rec.min_y)) + "px";
            el.style.left = ((rec.min_x * imgLoadRatio)) + "px";
        }
    }

    getMinMaxRectangle(arr: number[]): RectangleMinMax {
        let min_x = arr[0][0];
        let min_y = arr[0][1];
        let max_x = arr[0][0];
        let max_y = arr[0][1];
        //We need to get the minimum x,y for starting point of the cut
        //We also need the maximum, to calculate the width and height of the cut
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][0] < min_x) min_x = arr[i][0];
            if (arr[i][1] < min_y) min_y = arr[i][1];
            if (arr[i][0] > max_x) max_x = arr[i][0];
            if (arr[i][1] > max_y) max_y = arr[i][1];
        }
        return { min_x, min_y, max_x, max_y }
    }

    //This method creates an SVGCircleElement area
    displayCircle(curr_el: UniversalElement, parent: SVGElement, img: HTMLImageElement, index: number) {
        let radius: number = (curr_el.radius! / 100) * img.clientWidth;
        let answerItemCircle = parent.appendChild(document.createElementNS("http://www.w3.org/2000/svg", "circle"));
        answerItemCircle.setAttribute("cx", String((curr_el.x! / 100) * img.clientWidth));
        answerItemCircle.setAttribute("cy", String((curr_el.y! / 100) * img.clientHeight));
        answerItemCircle.setAttribute("r", String(radius));
        answerItemCircle.classList.add("svg-element", "poly-area");
        if (!this.exercise.show_areas) answerItemCircle.style.visibility = "hidden";
        this.svgAreas.push(answerItemCircle);
    }
    //This method creates an SVGPolygonElement area
    displayPolygon(curr_el: UniversalElement, parent: SVGElement | HTMLElement, img: HTMLImageElement, index: number) {
        let polyArea = parent.appendChild(document.createElementNS("http://www.w3.org/2000/svg", "polygon"));
        polyArea.setAttribute("points", ExerciseEngineHelper.getPolyCoordsImagePixels(curr_el.points!, img.clientHeight, img.clientWidth));
        polyArea.classList.add("svg-element", "poly-area");
        polyArea.setAttribute("title", "asd");
        if (!this.exercise.show_areas) polyArea.style.visibility = "hidden";
        this.svgAreas.push(polyArea);
    }

    removeOutlines() {
        this.divDraggables.forEach(dr => {
            dr.classList.remove("wrong-answer", "good-answer", "show-correct-answer");
        });
    }

    getRandomPoint(currSvgArea: any): Point {
        let x = 0;
        let y = 0;
        let r: RectangleMinMax = { min_x: 0, min_y: 0, max_x: 0, max_y: 0 };
        let good_point: boolean = false;

        while (!good_point) {
            if (currSvgArea.tagName == "circle") {
                let cx = Number(currSvgArea.getAttribute("cx"));
                let cy = Number(currSvgArea.getAttribute("cy"));
                let rad = Number(currSvgArea.getAttribute("r"));
                r = { min_x: cx - rad, min_y: cy - rad, max_x: cx + rad, max_y: cy + rad }
                x = Math.floor(Math.random() * (r.max_x * 0.9)) + (r.min_x * 1.1);
                y = Math.floor(Math.random() * (r.max_y * 0.9)) + (r.min_y * 1.1);
                let distX: number = Math.abs(x - currSvgArea.getAttribute("cx"));
                let distY: number = Math.abs(y - currSvgArea.getAttribute("cy"));
                let radius: number = currSvgArea.getAttribute("r");
                let dist: number = distX * distX + distY * distY;

                //Check it is inside radius or not
                if (dist <= radius * radius) good_point = true;
            }
            else if (currSvgArea.tagName == "polygon") {
                let points: string = currSvgArea.getAttribute("points");
                let arr: number[] = ExerciseEngineHelper.getPolyCoordinatePairs(points);
                r = this.getMinMaxRectangle(arr);
                x = Math.floor(Math.random() * (r.max_x * 0.9)) + (r.min_x * 1.1);
                y = Math.floor(Math.random() * (r.max_y * 0.9)) + (r.min_y * 1.1);
                if (ExerciseEngineHelper.isPointInsidePolygon([x, y], currSvgArea.getAttribute("points"))) good_point = true;
            }

        }
        return { x, y }
    }

    resize() {
        let ill_img: HTMLImageElement | null = this.root.querySelector(".task-image");
        let task_canvas: HTMLCanvasElement | null = this.root.querySelector(".task-canvas");
        let dropDiv: HTMLDivElement | null = this.root.querySelector(".drop-div");
        let svgCont: SVGElement | null = this.root.querySelector(".cont-svg");

        if (dropDiv && ill_img && task_canvas && svgCont) {
            let scale: number = ill_img.clientWidth / ill_img.naturalWidth;
            let context = task_canvas.getContext("2d");

            if (context) {
                this.redrawMain(context, ill_img);
            }

            this.divDraggables.forEach((div, index) => {
                let curr_dr = this.exercise.draggables[index];
                let canvas = div.querySelector("canvas");

                if (canvas) {
                    let ctx = canvas.getContext("2d");
                    if (context && ctx && ill_img) {
                        this.redrawDraggableCanvas(ctx, ill_img, curr_dr, scale);
                        this.redrawDraggableDiv(ill_img, curr_dr, context, div);
                    }
                }

            });

            svgCont.style.width = String(ill_img.clientWidth);
            svgCont.style.height = String(ill_img.clientHeight);

            this.svgAreas.forEach((svg, i) => {
                let area = this.exercise.areas[i];
                if (svg.tagName == "circle") {
                    svg.setAttribute("cx", String((area.x! / 100) * ill_img!.clientWidth));
                    svg.setAttribute("cy", String((area.y! / 100) * ill_img!.clientHeight));
                    svg.setAttribute("r", String((area.radius / 100) * ill_img!.clientWidth));
                }
                else {
                    svg.setAttribute("points", ExerciseEngineHelper.getPolyCoordsImagePixels(area.points!, ill_img!.clientHeight, ill_img!.clientWidth));
                }
            });
            this.prevImgDim = { width: ill_img.clientWidth, height: ill_img.clientHeight }
        }

    }

    redrawMain(context: any, task_img: HTMLImageElement) {
        context.canvas.width = task_img.clientWidth;
        context.canvas.height = task_img.clientHeight;
        context.drawImage(task_img, 0, 0, task_img.naturalWidth, task_img.naturalHeight, 0, 0, task_img.clientWidth, task_img.clientHeight);
    }

    redrawDraggableCanvas(ctx: any, taskImg: HTMLImageElement, curr_dr: any, scale: number) {
        let curr_dim = ExerciseEngineHelper.getPolyCoordsImagePixels(curr_dr.points!, taskImg.naturalHeight, taskImg.naturalWidth);
        let arr = ExerciseEngineHelper.getPolyCoordinatePairs(curr_dim);
        let minMax = this.getMinMaxRectangle(arr);
        let cut_width = minMax.max_x - minMax.min_x;
        let cut_height = minMax.max_y - minMax.min_y;
        ctx.canvas.width = cut_width;
        ctx.canvas.height = cut_height;
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.drawImage(taskImg, minMax.min_x, minMax.min_y, cut_width, cut_height, 0, 0, cut_width * scale, cut_height * scale);
    }

    redrawDraggableDiv(taskImg: HTMLImageElement, curr_dr: any, mainContext: any, div: HTMLDivElement) {
        let curr_dim_divs = ExerciseEngineHelper.getPolyCoordsImagePixels(curr_dr.points!, taskImg.clientHeight, taskImg.clientWidth);
        let arr_div = ExerciseEngineHelper.getPolyCoordinatePairs(curr_dim_divs);
        let divMinMax = this.getMinMaxRectangle(arr_div);
        let cut_width = divMinMax.max_x - divMinMax.min_x;
        let cut_height = divMinMax.max_y - divMinMax.min_y;
        mainContext.clearRect(divMinMax.min_x, divMinMax.min_y, cut_width, cut_height);
        let cur_top = div.offsetTop;
        let cur_left = div.offsetLeft;
        div.style.width = (cut_width) + "px";
        div.style.height = (cut_height) + "px";
        div.style.top = String(cur_top * (taskImg.clientHeight / this.prevImgDim.height)) + "px";
        div.style.left = String(cur_left * (taskImg.clientWidth / this.prevImgDim.width)) + "px";
    }

    public getUserSolution(): UserSolution {
        let userSol: UserSolution = {
            answer: []
        };
        for (let i = 0; i < this.svgAreas.length; i++) {
            const curr_area = this.svgAreas[i];
            let dr_indexes: number[] = [];
            for (let j = 0; j < this.divDraggables.length; j++) {
                const curr_draggable = this.divDraggables[j];
                let dr_x = parseFloat(curr_draggable.style.left!) + curr_draggable.offsetWidth / 2;
                let dr_y = parseFloat(curr_draggable.style.top!) + curr_draggable.offsetHeight / 2;
                if (curr_area.tagName == "circle") {
                    //Calculate the distance between the circle areas center and the answer item
                    let distX: number = Math.abs(dr_x - curr_area.getAttribute("cx"));
                    let distY: number = Math.abs(dr_y - curr_area.getAttribute("cy"));
                    let radius: number = curr_area.getAttribute("r");
                    let dist: number = distX * distX + distY * distY;

                    //Check it is inside radius or not
                    if (dist <= radius * radius) dr_indexes.push(j);
                    continue;
                }
                if (ExerciseEngineHelper.isPointInsidePolygon([dr_x, dr_y], curr_area.getAttribute("points"))) dr_indexes.push(j);
            }
            userSol.answer.push(dr_indexes);
        }
        return userSol;
    }

    public receiveEvaluation(evaluated: Evaluated): void {

        this.removeOutlines();

        //In case of perfect solution, we just color every element to green
        if (evaluated.success) {
            this.divDraggables.forEach(dr => {
                dr.classList.add("good-answer");
            });
        }

        //If the solution is not perfect...
        else {
            let user_sol = this.getUserSolution().answer;
            let eval_indexes = [];

            //Checking solution
            for (let i = 0; i < evaluated.solution.length; i++) {
                for (let j = 0; j < evaluated.solution[i].length; j++) {
                    let curIndex = evaluated.solution[i][j];
                    //We only want to evaulate the elements once
                    if (eval_indexes.indexOf(curIndex) == -1) {
                        //Correct answer
                        if (user_sol[i].indexOf(curIndex) != -1) {
                            this.divDraggables[curIndex].classList.add("good-answer");
                        }
                        //Bad answer and NOT sni
                        else if (!this.isSNIexc) {
                            this.divDraggables[curIndex].classList.add("wrong-answer");
                        }
                        //SNI and bad answer
                        else {
                            this.dropBackElement(curIndex);
                        }
                    }
                    eval_indexes.push(curIndex);
                }
            }
            //Checking odd one outs which are not part of the correct solution
            for (let i = 0; i < this.oddIndexes.length; i++) {
                for (let j = 0; j < user_sol.length; j++) {
                    //If user submitted an odd one out element to an area
                    if (user_sol[j].indexOf(this.oddIndexes[i]) != -1) {
                        if (this.isSNIexc) this.dropBackElement(this.oddIndexes[i]);
                        else this.divDraggables[this.oddIndexes[i]].classList.add("wrong-answer");
                    }
                }
            }
        }
    }

    public showCorrectSolution(solution: any): void {
        this.removeOutlines();
        for (let i = 0; i < this.exercise.draggables.length; i++) {
            let currDraggable = this.divDraggables[i];
            for (let j = 0; j < solution.length; j++) {
                const currAreaSol = solution[j];
                let areaIndex = currAreaSol.indexOf(i);
                if (areaIndex != -1) {
                    let currSvgArea = this.svgAreas[j];
                    let point: Point = { x: 0, y: 0 };
                    //*When there are more elements in the area, we get random points inside the area
                    if (solution[j].length > 1) {
                        point = this.getRandomPoint(currSvgArea);
                    }
                    //In case when there is only one element in the area, we place it in the middle
                    else {
                        if (currSvgArea.tagName == "circle") {
                            point.y = currSvgArea.getAttribute("cy");
                            point.x = currSvgArea.getAttribute("cx");
                        }
                        else if (currSvgArea.tagName == "polygon") {
                            let polylabelCoords = ExerciseEngineHelper.getPolyCoordinatePairs(currSvgArea.getAttribute("points"));
                            let centerPoint = polylabel([polylabelCoords], 1.0);
                            point.x = centerPoint[0];
                            point.y = centerPoint[1];
                        }
                    }
                    currDraggable.style.top = (point.y - currDraggable.offsetHeight / 2) + "px";
                    currDraggable.style.left = (point.x - currDraggable.offsetWidth / 2) + "px";
                    currDraggable.classList.remove("outline", "poly-area");
                    currDraggable.classList.add("show-correct-answer");
                }
            }
        }
        this.oddIndexes.forEach(i => {
            this.dropBackElement(i);
        });
    }

    public isUserReady(): boolean {
        return this.userStarted;
    }

}
