import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { IUserInfo, hasGroup, Groups } from '@src/framework/server/Auth';

import { viewTableInfoClassProxy } from '@src/framework/view/meta/ViewTableInfo';
import { viewMetaCategoryTypeClassProxy } from '@src/framework/view/meta/ViewMetaCategoryType';
import { viewInstituteClassProxy } from '@src/framework/view/sys/ViewInstitute';
import { viewPartnerClassProxy } from '@src/framework/view/sys/ViewPartner';
import { viewOauthClientClassProxy } from '@src/framework/view/sys/ViewOauthClient';
import { viewPersonClassProxy } from '@src/framework/view/sys/ViewPerson';
import { viewSecGroupClassProxy } from '@src/framework/view/sys/ViewSecGroup';
import { viewSecUserClassProxy } from '@src/framework/view/sys/ViewSecUser';
import { viewMetaViewInfoClassProxy } from '@framework/view/meta/ViewMetaViewInfo';
import { viewStationClassProxy } from '@src/framework/view/wf/ViewStation';
import { viewInstituteSiteClassProxy } from '@src/framework/view/sys/ViewInstituteSite';
import ViewTanarXxTpwd from '@src/framework/view/sys/ViewTanarXxTpwd';
import { me } from '@framework/server/Auth';

import { PATH_TEST_TANAR_TPWD, SYS_ANNOUNCE, SYS_DAY } from '@src/Routes';
import { PATH_SYS_USER_VIEW_SESSIONS, PATH_PERM_AUDIT } from '@src/component/sys/sys_routes';
import { ViewPermissionType } from '@src/framework/security/PermissionCache';
import RenderIfHasPermission from '@src/framework/RenderIfHasPermission';
import { viewKbItemTypeClassProxy } from '@src/framework/view/kb/ViewKbItemType';
import { viewTaskClassProxy } from '@src/framework/view/data_imp/ViewTask';
import { viewDpmkProjectClassProxy } from '@src/framework/view/doc/ViewDpmkProject';
import { viewLessonplanClassProxy } from '@src/framework/view/doc/ViewLessonplan';
import { viewTicketInquiryCategoryClassProxy } from '@src/framework/view/ticket/ViewTicketInquiryCategory';
import { __ } from '@src/translation';
import { PATH_DUP_PERSON } from './sys/sys_dup_person';

const adminMenuItemProviders: AdminMenuItemsProvider[] = [];

export type AdminMenuItemsProvider = () => JSX.Element[];
export const addAdminMenuItemsProvider = (provider: AdminMenuItemsProvider) => {
    adminMenuItemProviders.push(provider);
}

/** Default admin menu. */
addAdminMenuItemsProvider( () => {
    let items: JSX.Element[] = [];

    if (me) {
        const user: IUserInfo = me;


        if (user && user.groups) {
            const isSuperAdmin = hasGroup(user, Groups.Admin);
            const isNkpAdmin = hasGroup(user, Groups.NkpAdmin);
            const isSiteAdmin = hasGroup(user, Groups.InstituteSiteAdmin);

            if (isSuperAdmin) {
                items.push(<li key="0">
                    <a className="menu-group" href="#"><i className="fa fa-desktop" />&nbsp; {__("Rendszer")}</a>

                    <ul className="menu vertical nested">
                        <li className="menu-item"><NavLink activeClassName="active" to={SYS_ANNOUNCE}>
                            <i className="fa fa-bullhorn" />{__("Rendszer hirdetmény")}</NavLink></li>
                    </ul>

                </li>);
            }

            if (isSuperAdmin || isNkpAdmin || isSiteAdmin) {
                items.push(
                    <li key="1">
                        <a className="menu-group" href="#"><i className="fa fa-university" />&nbsp; {__("Intézmény kezelő")}</a>

                        <ul className="menu vertical nested">
                            <li className="menu-item"><NavLink activeClassName="active" to={viewInstituteClassProxy.getListUrl({})}>
                                <i className="ico nkp-icon-administration-institutes" />{__("Intézmények")}</NavLink></li>

                            <li className="menu-item"><NavLink activeClassName="active" to={viewInstituteSiteClassProxy.getListUrl({})}>
                                <i className="ico nkp-icon-administration-institute-handling" />{__("Feladat ellátási helyek")}</NavLink></li>
                                
                                <li className="menu-item"><NavLink activeClassName="active" to={viewLessonplanClassProxy.getListUrl({})}>
                                <i className="fa fa-clock" />{__("DPMK projekt módosító")}</NavLink></li>
                        </ul>
                    </li>
                );
            }

            if (isSuperAdmin) {
                items.push(
                    <li key="2">
                        <a className="menu-group" href="#"><i className="fa fa-database" />&nbsp; {__("Törzsadatok")}</a>

                        <ul className="menu vertical nested">
                            <li className="menu-item">
                                <NavLink activeClassName="active" to={viewMetaCategoryTypeClassProxy.getListUrl({})}>
                                    <i className="ico nkp-icon-tag-label" /> {__("Meta kategória típusok")}</NavLink></li>
                            <li className="separator"></li>
                            <li className="menu-item"><NavLink activeClassName="active" to={viewPartnerClassProxy.getListUrl({})}>
                                <i className="ico nkp-icon-master-data-partners" />{__("Partnerek")}</NavLink></li>
                            <li className="separator"></li>
                            <li className="menu-item">
                                <NavLink activeClassName="active" to={viewKbItemTypeClassProxy.getListUrl({})}>
                                    <i className="ico nkp-icon-tag-label" /> {__("Tudásbázis bejegyzés típusok")}</NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink activeClassName="active" to={viewTaskClassProxy.getListUrl({})}>
                                    <i className="fa fa-file-import" /> {__("Importálási feladatok")}</NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink activeClassName="active" to={viewDpmkProjectClassProxy.getListUrl({})}>
                                    <i className="fa fa-file-import" /> {__("DPMK projektek")}</NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink activeClassName="active" to={SYS_DAY}>
                                    <i className="fa fa-calendar" /> {__("Munkanapok és ünnepnapok")}</NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink activeClassName="active" to={viewTicketInquiryCategoryClassProxy.getListUrl({})}>
                                    <i className="fa fa-file-import" /> {__("e-SZTR Témakörök")}</NavLink>
                            </li>
                        </ul>

                    </li>);
            }

            if (isSuperAdmin || isNkpAdmin) {
                items.push(
                    <li key="3">
                        <a className="menu-group" href="#"><i className="fa fa-lock" />&nbsp; {__("Biztonság és jogosultság")}</a>

                        <ul className="menu vertical nested">
                            <li className="menu-item"><NavLink activeClassName="active" to={viewSecGroupClassProxy.getListUrl({})}>
                                <i className="ico nkp-icon-groups-roles" /> {__("Jogosultság csoportok")}</NavLink></li>
                            <li className="menu-item"><NavLink activeClassName="active" to={viewSecUserClassProxy.getListUrl({})}>
                                <i className="ico nkp-icon-users" /> {__("Felhasználók")}</NavLink></li>
                            <li className="menu-item"><NavLink activeClassName="active" to={viewPersonClassProxy.getListUrl({})}>
                                <i className="ico nkp-icon-security-person" />{__("Személyek")}</NavLink></li>
                            <RenderIfHasPermission requirements={{ view: { viewInfoId: ViewTanarXxTpwd.VIEW_INFO_ID, permissions: ViewPermissionType.R } }}

                            >
                                <li className="menu-item"><NavLink activeClassName="active" to={PATH_TEST_TANAR_TPWD}>
                                    {__("Teszt tanárok napi jelszavai")}</NavLink></li>
                            </RenderIfHasPermission>
                            {isSuperAdmin ? <>
                                <li className="separator"></li>
                                <li className="menu-item"><NavLink activeClassName="active" to={viewTableInfoClassProxy.getListUrl({})}>
                                    <i className="ico nkp-icon-crud" /> {__("CRUD jogok")}</NavLink></li>
                                <li className="menu-item"><NavLink activeClassName="active" to={viewMetaViewInfoClassProxy.getListUrl({})}>
                                    <i className="ico nkp-icon-security-view-rights" /> {__("View jogok")}</NavLink></li>
                                <li className="menu-item"><NavLink activeClassName="active" to={PATH_PERM_AUDIT}>
                                    <i className="ico nkp-icon-security-view-rights" /> {__("Jogosultság audit")}</NavLink></li>
                                <li className="separator"></li>
                                <li className="menu-item"><NavLink activeClassName="active" to={viewOauthClientClassProxy.getListUrl({})}>
                                    <i className="ico nkp-icon-oauth" /> {__("OAUth2 kliensek")}</NavLink></li>
                                <li className="menu-item"><NavLink activeClassName="active" to={PATH_SYS_USER_VIEW_SESSIONS}>
                                    <i className="fa fa-sign-in-alt" /> {__("Aktív bejelentkezések")}</NavLink></li>

                                    
                                <li className="menu-item">
                                    <NavLink activeClassName="active" to={PATH_DUP_PERSON}>
                                        <i className="fa fa-user-friends" /> {__("Duplikált felhasználó kereső")}
                                    </NavLink>
                                </li>
                            </> : null}
                        </ul>

                    </li>);
            }

            if (isSuperAdmin) {
                items.push(
                    <li key="4">
                        <a className="menu-group" href="#"><i className="fa fa-tasks" />&nbsp; {__("Folyamat kezelés")}</a>

                        <ul className="menu vertical nested">
                            <li className="menu-item"><NavLink activeClassName="active" to="/wf/wf_type/list">
                                <i className="ico nkp-icon-process-types" />{__("Folyamat típusok")}</NavLink></li>
                            <li className="menu-item"><NavLink activeClassName="active" to={viewStationClassProxy.getListUrl({})}>
                                <i className="ico nkp-icon-process-states" />{__("Állapotok")} </NavLink></li>
                            <li className="menu-item"><NavLink activeClassName="active" to="/wf/transition_matrix/">
                                <i className="ico nkp-icon-process-transitions" />{__("Átmenet típusok")}</NavLink></li>
                            <li className="menu-item"><NavLink activeClassName="active" to="/wf/station_constraint_matrix/">
                                <i className="ico nkp-icon-process-model" />{__("Folyamat állapot megszorítási mátrix")}</NavLink></li>
                            {/* <li className="menu-item"><NavLink activeClassName="active" to="/wf/graph/:wfTypeId">Átmenet diagram</NavLink></li> */}

                        </ul>
                    </li>
                );

                items.push(
                    <li key="5">
                        <a className="menu-group" href="#"><i className="fa fa-tasks" />&nbsp; {__("Keresőmotor")}</a>

                        <ul className="menu vertical nested">
                            <li className="menu-item"><NavLink activeClassName="active" to="/elastic/indexers">
                                <i className="ico nkp-icon-process-types" />{__("Indexelők")}</NavLink></li>

                        </ul>
                    </li>
                );

            }

        }
    }
    return items;

});

export class AdminMenu extends React.PureComponent<any> {

    componentDidMount() {

        if (this.refs.adminMenu && this.props.type == "sidemenu") {

            const nested = (this.refs.adminMenu as HTMLUListElement).getElementsByClassName("nested");

            for (var i = 0; i < nested.length; i++) {
                if (nested[i].querySelector(".active")) {
                    nested[i].classList.add("is-active");
                }
            }

            ($(this.refs.adminMenu) as any).foundation();
        }

    }

    async componentDidUpdate() {
        if (this.refs.adminMenu && this.props.type == "sidemenu") {

            const nested = (this.refs.adminMenu as HTMLUListElement).getElementsByClassName("nested");

            for (var i = 0; i < nested.length; i++) {
                if (nested[i].querySelector(".active")) {
                    nested[i].classList.add("is-active");
                }
            }

            ($(this.refs.adminMenu) as any).foundation();
        }
    }

    filterMenu(e: any) {
        var input, filter, ul, li, a, i;
        input = e.target;
        filter = input.value.toUpperCase();
        ul = document.querySelector(".admin-menu")!;
        li = ul.querySelectorAll(".menu-item");

        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            if (a && a.text.toUpperCase().indexOf(filter) > -1) {
                li[i]["style"].display = "";
            } else {
                li[i]["style"].display = "none";
            }
        }
        for (i = 0; i < (ul as HTMLUListElement).childNodes.length; i++) {
            if ((ul as HTMLUListElement).childNodes[i].textContent!.toUpperCase().indexOf(filter) > -1) {
                ul.childNodes[i]["style"].display = "";
                if ((ul.childNodes[i]! as any).querySelector(".menu-group").textContent!.toUpperCase().indexOf(filter) > -1) {
                    for (const menuitem of (ul.childNodes[i]! as any).querySelectorAll(".menu-item")) {
                        menuitem.style.display = "";
                    }
                }
            }
            else {
                ul.childNodes[i]["style"].display = "none";
            }
        }
    }

    filterMenu2(e: any) {
        var input, filter, ul, li, a, i;
        input = e.target;
        filter = input.value.toUpperCase();
        ul = document.querySelector(".admin-menu")!;
        li = ul.querySelectorAll(".menu-item");

        var resultList = document.getElementById("resultList") as HTMLUListElement;
        while (resultList.firstChild) {
            resultList.removeChild(resultList.firstChild);
        }
        if (filter) {
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];

                if (a && a.text.toUpperCase().indexOf(filter) > -1) {
                    var child = (li[i] as HTMLUListElement).cloneNode(true);
                    resultList.appendChild(child);
                }
            }

        }

    }

    render() {
        // let url = this.state.history && this.state.history.location ? this.state.history.location.pathname : "";

        let items: JSX.Element[] = [];
        adminMenuItemProviders.forEach( (provider) => {
            items = items.concat(provider());
        })


        return items.length ?

            this.props.type == "sidemenu" ?

                <ul ref="adminMenu" className="vertical menu accordion-menu" data-accordion-menu>
                    {items}
                </ul>

                : <div className="">

                    <div className="row admin-dashboard" style={{ marginTop: "1em" }}>
                        {/* <div className="large-6">
                            <h3>Adminisztráció</h3>
                        </div> */}

                        <div className="callout large-12 text-center">

                            <i style={{ fontSize: "5rem" }} className="fa fa-wrench"></i>

                            <div className="eke-search-field large-12">
                                <input className="eke-search__input" onKeyUp={this.filterMenu2} placeholder={__("Elemek szűrése, kezdjen el gépelni...")} />
                                {/* <div className="input-group-button">
                                        <div className="button-group shrink column eke-search__button">
                                            <button className="button">Szűrés</button>              
                                        </div>
                                    
                                    </div> */}
                            </div>

                        </div>

                        <ul id="resultList" ref="resultList" className="result-list">

                        </ul>

                    </div>

                    <ul ref="adminMenu" className="admin-menu" style={{ display: "none" }}>
                        {items}
                    </ul>
                </div>

            : "";



    }
}

