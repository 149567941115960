import * as React from 'react';

import AdminRoute from '@src/Routes'
import { viewDpmkProjectClassProxy } from '@src/framework/view/doc/ViewDpmkProject';
import DpmkProjectListView from './dpmk_project_listview';
import DpmkProjectEditor from './dpmk_project_editor';

export const PATH_LESSONPLAN_VIEW = "/oravazlat/megtekint";
export const PATH_DRAFT_EDIT_LIST = "/oravazlat/lista";
export const PATH_LESSONPLAN_EDIT_LIST = PATH_DRAFT_EDIT_LIST;
export const PATH_LESSONPLAN_EDIT = "/oravazlat/szerkeszt";

export const PATH_LESSONPLAN_PUB_EDIT_LIST = "/oravazlattar/lista";
export const PATH_LESSONPLAN_PUB_VIEW = "/oravazlattar/megtekint";

export function LessonplanRoutes() {
    return [
        <AdminRoute path={viewDpmkProjectClassProxy.getDefaultListRoute()} component={DpmkProjectListView} key="DpmkProjectListView"/>,
        <AdminRoute path={viewDpmkProjectClassProxy.getDefaultEditRoute()} component={DpmkProjectEditor} key="DpmkProjectEditor"/>,
    ];
}