import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IExcDisplayModeTranslationRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Nyelv
     *
     * NOT NULL -> sys.lang ON DELETE cascade
     *
     */
    lang_id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Feladat üzemmód
     *
     * NOT NULL -> exc.exc_display_mode ON DELETE cascade
     *
     */
    exc_display_mode_id?: number;
}

/**
 *  exc.exc_display_mode_translation - Feladat üzemmód fordítás
 */

export default class ExcDisplayModeTranslationCrud extends Crud<IExcDisplayModeTranslationRecord> {
    public static TABLE_INFO_ID = 1850960398;
    public static getSchemaNameForClass() { return 'exc'; }
    public static getTableNameForClass() { return 'exc_display_mode_translation'; }

    public static load: (id: number, aServer ?: Server) => Promise<ExcDisplayModeTranslationCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IExcDisplayModeTranslationRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IExcDisplayModeTranslationRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IExcDisplayModeTranslationRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IExcDisplayModeTranslationRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IExcDisplayModeTranslationRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ExcDisplayModeTranslationCrudClassProxy extends CrudClassProxy<IExcDisplayModeTranslationRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const excDisplayModeTranslationCrudClassProxy = new ExcDisplayModeTranslationCrudClassProxy(ExcDisplayModeTranslationCrud);
registerCrudClassProxy(excDisplayModeTranslationCrudClassProxy);
