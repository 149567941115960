import * as React from 'react';
import { match } from 'react-router';
import SharedContentRunCrud, { ISharedContentRunRecord } from '@src/framework/crud/usr/SharedContentRunCrud';
import { app, history } from '@src/index';
import ViewSharedWithMe, { IViewSharedWithMeRecord } from '@src/framework/view/usr/ViewSharedWithMe';
import { BubbleLoader } from 'react-css-loaders';
import { startExerciseRun } from '@src/framework/api/exercise/startExerciseRun';
import { PATH_CONTENT_STORE, PATH_CONTENT_STORE_SHARED_WITH_ME, PATH_SHARED_CONTENT_RUN_PLAY, PATH_SHARED_CONTENT_RUN_CHECK_MINE, PATH_SHARED_CONTENT_WITH_ME } from '@src/Routes';
import { Link } from 'react-router-dom';
import { formatDate, roundToDecimals } from '@src/Util';
import { Breadcrumbs } from '../Breadcrumbs';
import { SharedContent } from './SharedContent';
import { me } from '@src/framework/server/Auth';
import { __ } from '@src/translation';
import SharedContentCrud from '@src/framework/crud/usr/SharedContentCrud';
import { ClassroomModule } from '@src/module/classroomModule/classroomModule';
import { Accordion, AccordionItem } from '../ui/Accordion';
import DiscussionCards from '@src/module/messengerModule/components/DiscussionCards';

type ViewSharedWithMePageProps = {
    match: match<{ sharedContentId: string }>
}

type ViewSharedWithMePageState = {
    sharedContent?: IViewSharedWithMeRecord;
    isTimeOver: boolean;
    sharedContentRuns: ISharedContentRunRecord[];
}

export class ViewSharedWithMePage extends React.Component<ViewSharedWithMePageProps, ViewSharedWithMePageState> {

    constructor(props: ViewSharedWithMePageProps) {
        super(props);

        this.state = {
            sharedContentRuns: [],
            isTimeOver: false
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: ViewSharedWithMePageProps) {
        if (prevProps.match.params.sharedContentId !== this.props.match.params.sharedContentId) {
            this.reloadAsync();
        }
        this.doFoundation();
    }

    doFoundation() {
        if (this.refs.offCanvas) {
            ($(this.refs.offCanvas) as any).foundation();
        }
        else {
            setTimeout(this.doFoundation.bind(this), 100);
        }
    }

    private async reloadAsync() {
        try {
            const sharedContentId = Number(this.props.match.params.sharedContentId);

            const sharedContent = (await ViewSharedWithMe.load(sharedContentId)).record;
            const sharedContentRuns = await SharedContentRunCrud.list({ filter: { is_active: true, shared_content_id: sharedContentId, run_by_id: me!.id }, order_by: "id" });

            this.setState({
                sharedContent,
                sharedContentRuns
            });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }

    }

    private async onRun() {
        try {
            const response = await startExerciseRun({
                shareContentId: Number(this.props.match.params.sharedContentId),
                published: false
            });

            let links: any[] | null = null;

            //We pass this to Runsharedwithme component
            if (history.location.state && history.location.state.links) {
                links = history.location.state.links;
                links![links!.length - 1].path = this.props.match.url;
            }

            history.push(PATH_SHARED_CONTENT_RUN_PLAY + "/" + response.sharedContentRunId, { links: links });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        const sharedContent = this.state.sharedContent;

        if (!sharedContent) {
            return <BubbleLoader />
        }


        let links: any[] = [];

        //If the component received from links to the Breadcrumbs we use them
        if (sharedContent.shared_from_institute_group_id) {
            links = ClassroomModule.getTaskBreadCrumbs(sharedContent.shared_from_institute_group_id, sharedContent.shared_from_institute_group_name || "");
            links.push({ name: sharedContent.title, path: PATH_SHARED_CONTENT_WITH_ME + "/" + sharedContent.id });
        }
        else {
            links = [
                { name: __("Tartalomtár"), path: PATH_CONTENT_STORE },
                { name: __("Nekem kiosztott feladatok"), path: PATH_CONTENT_STORE_SHARED_WITH_ME },
                { name: sharedContent.title },
            ];
        }



        let baseEarnedPoints = 0;
        let baseTotalPoints = 0;
        this.state.sharedContentRuns.map(s => {
            s.start_params.exercises.map((e: any) => {
                baseEarnedPoints += (e.totalPoints * e.successPercent);
                baseTotalPoints += e.totalPoints;
            })
        });
        let baseTotalPercent = baseEarnedPoints / baseTotalPoints;
        return <div className="row expanded">
            <div className="column small-12 large-offset-2 large-8">

                <Breadcrumbs links={links} />
                <SharedContent showSharedBy={true} sharedContent={sharedContent} />
                <Accordion allowAllClosed={true}>

                    {
                        this.state.sharedContentRuns.length > 0
                            ?
                            <AccordionItem defaultClosed={false} title={<span><img src={"/img/IKON_SET/FLAT/notepad.svg"} className="exe-editor-icons" alt={__("Ellenőrzés")} /> {__("Ellenőrzés")}</span>}>

                                <div className="table-scroll">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>{__("Megoldás száma")}</th>
                                                <th>{__("Megoldás kezdeti időpontja")}</th>
                                                <th>{__("Értékelés")}</th>
                                                <th>{__("Művelet")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.sharedContentRuns.map(s => {

                                                    let isFinished: boolean = s.finished != null && s.finished != undefined;

                                                    let curr_percent: number = 0;
                                                    //if(!s.finished){
                                                    //todo: remove when it's done in the background
                                                    if (s.start_params && s.start_params.exercises)
                                                        for (let usersol = 0; usersol < s.start_params.exercises.length; usersol++) {
                                                            if (!s.start_params.exercises[usersol].userSolutionJSON) {
                                                                isFinished = false;
                                                                break;
                                                            }
                                                            curr_percent += s.start_params.exercises[usersol].successPercent;
                                                        }
                                                    //}                                                    

                                                    let totalSucPercent: number = roundToDecimals(baseTotalPercent * 100, 2);//s.start_params.exercises.length == 0 ? -1 : roundToDecimals((curr_percent / s.start_params.exercises.length) * 100, 1);

                                                    let succPercent = (s.shared_by_success_percent != undefined && s.shared_by_success_percent != null) ? roundToDecimals(s.shared_by_success_percent * 100, 2) : totalSucPercent;

                                                    return <tr key={s.id}>
                                                        <td>#{s.run_no}</td>
                                                        <td>{formatDate(s.creation_time)}</td>
                                                        <td>{succPercent < 0 ? "" : (sharedContent.max_points ? (roundToDecimals(sharedContent.max_points / 100 * succPercent, 2) + "p" + " (" + roundToDecimals(succPercent, 1) + "%)") : (succPercent + "%"))} </td>
                                                        {!this.state.isTimeOver && !isFinished ?
                                                            <td>
                                                                <Link style={{ margin: 0 }} className="button success" to={{ pathname: (PATH_SHARED_CONTENT_RUN_PLAY + "/" + s.id) || "", state: { links: links } }}>
                                                                    <i className="fa fa-play" />&nbsp; {__("Feladat folytatása")}
                                                                </Link>
                                                            </td>
                                                            : <td>
                                                                <Link style={{ margin: 0 }} className="button" to={{ pathname: PATH_SHARED_CONTENT_RUN_CHECK_MINE + "/" + s.id, state: { links: links } }}>
                                                                    <i className="far fa-clipboard" />&nbsp; {__("Eredmény megtekintése")}
                                                                </Link>
                                                            </td>}
                                                    </tr>;
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </AccordionItem>
                            :
                            null
                    }
                </Accordion>

                <button type="button" className="button hollow" data-toggle="messagesOffCanvas">
                    <i className="fa fa-envelope" />{__("Megjegyzések")}
                </button>

                {
                    !this.state.isTimeOver && (sharedContent.max_run_count === 0 || this.state.sharedContentRuns.length < sharedContent.max_run_count!)
                        ?
                        <button className="button primary" onClick={this.onRun.bind(this)}>
                            <i className="fa fa-play" />&nbsp; {this.state.sharedContentRuns.length == 0 ? __("Feladat indítása") : __("Feladat újraindítása az elejéről")}
                        </button>
                        :
                        null
                }

                <div className="off-canvas-wrapper message-canvas">
                    <div ref="offCanvas" className="off-canvas position-right is-closed" data-close-on-click={false} id="messagesOffCanvas" data-off-canvas>

                        <div className="message-tools">
                            <button onClick={() => (this.refs.messagePanel as DiscussionCards).asyncReload()}><i style={{ float: "right", padding: "10px" }} className="fas fa-sync-alt"></i></button>

                            <button className="close-button" aria-label="Close menu" type="button" data-toggle="messagesOffCanvas">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="message-panel">
                            <DiscussionCards ref="messagePanel"
                                table_info_id={SharedContentCrud.TABLE_INFO_ID}
                                record_id={sharedContent.id!}
                                subject={sharedContent.title!}
                                user_id={sharedContent.shared_by_id!} />
                        </div>
                    </div>
                </div>

            </div>

        </div>;
    }

}
