import * as React from 'react';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { getReactTableLabels } from '@src/framework/i18n';
import GoodwordCrud, { IItemRecord } from '@src/framework/crud/spell/ItemCrud';
import ResultCrud, { IResultRecord } from '@src/framework/crud/spell/ResultCrud';

import { Link, } from 'react-router-dom';
import BookCrud from '@src/framework/crud/doc/BookCrud';
import { PATH_PUBLISHED_BOOK, PATH_EXERCISE } from '@src/Routes';
import SectionCrud from '@src/framework/crud/doc/SectionCrud';
import ChapterCrud from '@src/framework/crud/doc/ChapterCrud';
import LessonCrud from '@src/framework/crud/doc/LessonCrud';
import ExerciseCrud from '@src/framework/crud/exc/ExerciseCrud';
import GoodWordGlobalCrud from '@src/framework/crud/spell/GoodWordGlobalCrud';
import { app } from '../..';
import GoodWordLocalCrud from '@src/framework/crud/spell/GoodWordLocalCrud';
import { Dialog } from '@src/component/Dialog';
import { __ } from '@src/translation';

type GoodWordMarkerProps = {
}

interface IGoodWordMarkerState {
  count:number;
  pageSize: number;
  loading:boolean;
  results:IResultRecord[];
  resultId?:number,
  spellItems:IItemRecord[];
  tableState?: any;
  showConfirmModal:boolean;
  showJsonModal:boolean;
  selectedGlobalSpellitem:IItemRecord|null;
}

export default class GoodWordMarker extends React.Component<GoodWordMarkerProps, IGoodWordMarkerState> {

  constructor(props: any) {
    super(props);

    this.state = {
      count: 0,
      pageSize:20,
      loading:false,
      spellItems:[],
      results:[],
      showConfirmModal:false,
      showJsonModal:false,
      selectedGlobalSpellitem:null
    };

    this.reloadAsync();
  }

  async reloadAsync() {
    let results=await ResultCrud.list({
      filter: {is_active: true},
      order_by:[{name:"creation_time", desc: true}]
    } );
    this.setState({results, resultId: results[0].id}, this.onFetchData.bind(this));
  }

  async onFetchData(tableState: any) {

    if (!tableState) tableState = this.state.tableState;
  
    this.setState({
      loading: true,
      tableState:tableState
    })

    if (! this.state.resultId) return;

    try {
    var spellItems: IItemRecord[];
    const params = {
      filter: {result_id: this.state.resultId, is_active: true}, 
      order_by: "bad_word",
      limit: tableState.pageSize,
      offset: tableState.page * tableState.pageSize
    };

    spellItems = await GoodwordCrud.list(params); 
    for (let spellItem of spellItems) {
      var link = "";
      
      switch(spellItem.table_info_id) {
        case BookCrud.TABLE_INFO_ID:
          let book = await BookCrud.load(spellItem.record_id!);
            link = PATH_PUBLISHED_BOOK + "/" + book.record.uri_segment;
            break;

        case ChapterCrud.TABLE_INFO_ID:
          let chapter = await ChapterCrud.load(spellItem.record_id!);
          book = await BookCrud.load(chapter.record.book_id!);
          link = PATH_PUBLISHED_BOOK + "/" + chapter.record.id;
            break;

        case LessonCrud.TABLE_INFO_ID:
          let lesson = await LessonCrud.load(spellItem.record_id!);
          chapter = await ChapterCrud.load(lesson.record.chapter_id!);
          book = await BookCrud.load(chapter.record.book_id!);
          link = PATH_PUBLISHED_BOOK + "/" + book.record.uri_segment + "/" + lesson.record.uri_segment;
            break;

        case SectionCrud.TABLE_INFO_ID:
          const section = await SectionCrud.load(spellItem.record_id!);
          lesson = await LessonCrud.load(section.record.lesson_id!);
          chapter = await ChapterCrud.load(lesson.record.chapter_id!);
          book = await BookCrud.load(chapter.record.book_id!);
          link = PATH_PUBLISHED_BOOK + "/" + book.record.uri_segment + "/" + lesson.record.uri_segment + "#section-" + section.record.id;
          break;

        case ExerciseCrud.TABLE_INFO_ID:
          const exercise = await ExerciseCrud.load(spellItem.record_id!);
          link = PATH_EXERCISE + "/exerciseShow/" + exercise.record.id;
          break;
      }
      spellItem["bad_word"] = spellItem.bad_word;
      spellItem["suggestions"] = spellItem.suggestions.suggestions.join(", ");
      spellItem["link"]=<Link className="" to={link} target="_blank" title={__("megtekintés a könyvben")}>{link}</Link>;  
      spellItem["global"]=<button  onClick={() => this.setState({ selectedGlobalSpellitem: spellItem,showConfirmModal:true})} className="button small success">{__("globálisan helyes")}</button>;
      spellItem["local"]=<button onClick={this.correctLocal.bind(this,spellItem)} className="button small">{__("lokálisan helyes")}</button>;     
    }

    const count = await GoodwordCrud.count(params);

   this.setState({
    spellItems:spellItems,
    loading: false,
    count,
    pageSize:tableState.pageSize
    });
  }
  catch (e) {
    app.showErrorFromJsonResult(e);
 }
  }

  render() {   
        return (
        <div>
           {this.state.showConfirmModal
                    ?
                    <Dialog title={__("Globálisan helyessé tétel megerősítése")} onClose={() => this.setState({ showConfirmModal: false})} >
                        <div className="large-12 small-12 columns">
                            <div className="callout clearfix" style={{ border: "0px" }}>
                            <p className="row"> <i style={{color:"#c60f13"}} className="fa fa-exclamation-triangle fa-4x" />
                            </p>
                            <p>{__("Véglegesen hozzáadod ezt a szót a szótárhoz: ")}<strong>{this.state.selectedGlobalSpellitem!.bad_word}</strong>?</p>
                            <p style={{fontWeight:"bolder",color:"darkred"}}>{__("Vigyázat: ez a szó a továbbiakban minden tananyagban helyesként lesz elfogadva!")}</p>
                                <button
                                    onClick={() => this.setState({ showConfirmModal: false})}
                                    className="button alert float-left"><i className="fa fa-times" />
                                     {__("Mégse")}
                                    </button>
                                <button onClick={this.correctGlobal.bind(this)}
                                    className="button success float-right"
                                > <i className="fa fa-save" />{__("Mentés")}</button>
                            </div>
                        </div>
                    </Dialog>
                    :
                    ""}
                     {this.state.showJsonModal && this.state.resultId
                    ?
                    <Dialog title={__("Statisztikai adatok")}onClose={() => this.setState({ showJsonModal: false})} width={1000} height={700} >
                        <div className="large-12 small-12 columns">
                            <div className="callout clearfix" style={{ border: "0px" }}>
                              <pre>
                                {JSON.stringify(this.state.results.find(x => x.id == this.state.resultId)!.stats, null, 2)}
                              </pre>
                            </div>
                        </div>
                    </Dialog>
                    :
                    ""}
          <div className="row">

           
            <label style={{margin:"0",fontSize:"1rem",fontWeight:"bold",color:"#6f6f6f"}}>{__("Dátum")}</label>
            <select style={{fontSize:"0.875rem",fontWeight:"normal",color:"#6f6f6f",lineHeight:"1.8"}} onChange={this.onDateSelect.bind(this)} value={this.state.resultId}>
              {this.state.results.map((curr_date: IResultRecord) => {
                return <option key={curr_date.id} value={curr_date.id}>{curr_date.creation_time}</option>
              })
              }
            </select>
         
          </div>
      <div className="row expanded">
           <div className="small-12 medium-12">
           <div style={{display:"inline-flex"}}>
          <label style={{marginRight:"1rem"}} className="result-count">&nbsp;{this.state.count ? __("{n} találat",{n: this.state.count}) : ""}</label>
          <button onClick={() => this.setState({showJsonModal:true})} className="button small secondary">{__("Statisztika")}</button>
          </div>
          <ReactTable
            columns={[
              { Header: __("Szó"), accessor:"bad_word", },
              { Header: __("Javaslatok"), accessor:"suggestions", filterable: false},
              { Header: __("Előfordulás helye"),accessor:"link" , filterable: false},           
              { Header: __("Globálisan helyes"),accessor:"global", filterable: false},
              { Header: __("Lokálisan helyes"),accessor:"local", filterable: false},
            ]}
            manual
            data={this.state.spellItems}
            defaultPageSize={this.state.pageSize}
            pages={Math.ceil(this.state.count / this.state.pageSize)}
            filterable={true}
            className="-striped -highlight "
            {...getReactTableLabels()}
            onFetchData={this.onFetchData.bind(this)}
            loading={this.state.loading}
          />
        </div>
      </div>
      </div>
    );
  }
private async correctGlobal(event:any)
{
this.setState({ showConfirmModal: false });
if(this.state.selectedGlobalSpellitem)
{
await new GoodWordGlobalCrud({word:this.state.selectedGlobalSpellitem.bad_word}).put();
app.showSuccess(__("Hozzáadva"),__("a global táblához"))
}
else
{
app.showError(__("hiba történt"),__("megtörtént de ez van"))
}
}
private async correctLocal(spellitem:IItemRecord)
{
  if(spellitem)
  {
  await new GoodWordLocalCrud({table_info_id:spellitem.table_info_id,
    field_info_id:spellitem.field_info_id,
    record_id:spellitem.record_id,
    word:spellitem.bad_word}).put();
    app.showSuccess(__("Hozzáadva"),__("a local táblához"))
  }
  else
  {
  app.showError(__("hiba történt"),__("megtörtént de ez van"))
  }
}
 private onDateSelect(event: any) {
    const selectedDate = Number(event.target.value);
    this.setState({ resultId:selectedDate }, this.onFetchData.bind(this));   
  }
}
