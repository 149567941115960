import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ShuffleResult, shuffle, StatisticsResponse, ValidationResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { FillGapData } from "@src/component/exercise/engine/EKEFillGapDropDownExerciseEngine/EKEFillGapDropDownExerciseEngine";
import * as React from "react";
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type FillGapDropDownAnswerElement = {
    multiple_lists: boolean,
    gap_text: string,
    answers: string[]
}

export class EKEFillGapDropDownAnswerConverter extends AExerciseTypeConverter {
    public hasTextAnswer = true;
    public hasImageAnswer = false;
    public hasTextImageAnswer = false;
    static MAX_ODDS_NUMBER: number = 15;
    static MAX_OPTIONS_NUMBER: number = 25;

    render() {
        let curr_ex: FillGapDropDownAnswerElement = this.state.exercise as FillGapDropDownAnswerElement;
        return (
            <Panel  header={__("Részletek")} headingLevel={4} >
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="multiple_lists" checked={curr_ex.multiple_lists || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Több listás mód")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label>{__("A kiegészítendő szöveg")}
                            <textarea name={"gap_text"} value={curr_ex.gap_text}
                                rows={5}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                        </label>
                        <label className="exe-editor-label-description">{__("A hiányzó szöveget # # szimbólumok között kell megadni pl: \"A #víz# folyékony halmazállapotú, míg a #jég# szilárd. Továbbá a #víz# forráspontja 100°C. \" Több listás módban az egyes lita elemeit \";\"-vel elválasztva kell megadni, pl: \"A labda #piros;zöld;kék# volt.\" Több listás módban az első szó a helyes.")}</label>
                    </div>
                </div>
                <Panel>
                    <legend>
                        <h3>{__("Kakukktojás elemek")}</h3>
                    </legend>
                    <div className="row">
                        {curr_ex.multiple_lists ? <label>{__("A kakukktojás elemek többlistás módban nem értelmezhetőek.")}</label> : ""}
                        {curr_ex.answers && !curr_ex.multiple_lists ?
                            curr_ex.answers.map((curr_ans: any, i) => {
                                return (
                                    <div key={"curr_ans#" + i} className="small-12 large-4 medium-6 columns">
                                        <legend><label className="exe-editor-fieldlabel-2">Elem {i + 1}</label>
                                            <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "answers", i)}><i className="fa fa-trash"></i></button>
                                        </legend>
                                        <div className="row">
                                            <label className="exe-image-select-label">{__("Szó")}
                                                <input type="text" name={"answers#" + i} data-index={i} value={curr_ans}
                                                    onBlur={this.onBlurEvent.bind(this)}
                                                    onChange={this.handleInputChange.bind(this)} />
                                            </label>
                                        </div>
                                    </div>
                                );
                            })
                            : ""
                        }
                    </div>
                    <div className="row">
                        <button className="button small" onClick={this.onAddNewOddOneOut.bind(this)} disabled={curr_ex.multiple_lists}><i className="fa fa-plus"></i>{__("Új kakukktojás")}</button>
                        <label className="exe-editor-label-description columns">{__("A kakukktojás elemek száma maximum {MAX_ODDS_NUMBER} lehet.", { MAX_ODDS_NUMBER: EKEFillGapDropDownAnswerConverter.MAX_ODDS_NUMBER })}</label>
                    </div>
                </Panel>
            </Panel>
        );
    }

    onAddNewOddOneOut() {
        let temp_exercise = this.state.exercise as FillGapDropDownAnswerElement;
        if(!temp_exercise.answers) temp_exercise.answers = [];
        temp_exercise.answers.push("");
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    public convertToEditorAnswer(exercise: FillGapData): FillGapDropDownAnswerElement | undefined {
        console.log("convertToEditorAnswer", exercise);
        if (exercise) {
            //let exercise = JSON.parse(JSON.stringify(original_exercise));
            var pattern = /#\d+#/g;
            var numOfGaps = 0;
            var result = null;
            var text = exercise.gap_text;
            var deletableItems = [];

            if (exercise.multiple_lists) {

                while ((result = pattern.exec(exercise.gap_text))) {
                    let listShuffled: string[] = exercise.answers[numOfGaps].split(";");
                    let curSol = listShuffled.splice(exercise.solution[numOfGaps], 1);
                    let listOriginal: string[] = [];
                    listOriginal.push(curSol[0]);
                    listOriginal = listOriginal.concat(listShuffled);
                    //replacing incremental numbers to answer elements(string) according to solution_list indexes
                    //text = text.substr(0, result.index) + (text.substr(result.index, text.length)).replace(result.toString(), '#' + listOriginal.join(';') + '#');
                    let true_idx = text.indexOf(result.toString());
                    text = text.substr(0, true_idx) + '#' + listOriginal.join(';') + '#' + (text.substr(true_idx + result.toString().length, text.length));
                    numOfGaps++;
                }

            } else {
                while ((result = pattern.exec(exercise.gap_text))) {                //replacing incremental numbers to answer elements(string) according to solution_list indexes                   
                    //text = text.substr(0, result.index) + (text.substr(result.index, text.length)).replace(result.toString(), '#' + exercise.answers[exercise.solution[numOfGaps]] + '#');
                    let true_idx = text.indexOf(result.toString());
                    text = text.substr(0, true_idx) + '#' + exercise.answers[exercise.solution[numOfGaps]] + '#' + (text.substr(true_idx + result.toString().length, text.length));
                    deletableItems.push(exercise.answers[exercise.solution[numOfGaps]]);        //collecting these elements
                    numOfGaps++;
                }


                for (let index = 0; index < deletableItems.length; index++) {                   //delete the elements that are written into the text, because the answers_list should contain only the odd one out-s
                    if (exercise.answers.indexOf(deletableItems[index]) != -1) {
                        exercise.answers.splice(exercise.answers.indexOf(deletableItems[index]), 1);
                    }
                }

                if (exercise.answers) exercise.answers = exercise.answers.filter((v, i, a) => a.indexOf(v) === i); //if there were more than one odd one out element in the database, we only let one to appear 
            }

            var answerElement: FillGapDropDownAnswerElement = {
                gap_text: text,
                answers: exercise.multiple_lists ? [] : exercise.answers,
                multiple_lists: exercise.multiple_lists
            }
            return answerElement;
        }
        else return undefined;
    }

    public convertToJson(exerciseDetails: FillGapDropDownAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: FillGapDropDownAnswerElement): FillGapData | undefined {
        console.log("convertToJson", exerciseDetails);
        if (exerciseDetails) {
            var pattern = /#.*?#/g;    //get everything that is between #s
            var numOfGaps = 0;
            var result = null;
            var text = exerciseDetails.gap_text;
            var multipleLists = exerciseDetails.multiple_lists;
            var solution_list = [];
            var answer_list: any[] = [];
            var shuffleRes: ShuffleResult = { answers: [], indexes: [] };
            let shuffled_ans_list: any[] = [];
            var shuff_solution_list: number[] = [];

            if (exerciseDetails.multiple_lists) {
                while ((result = pattern.exec(exerciseDetails.gap_text))) {
                    let currentList: string[] = result.toString().slice(1, result.length - 2).split(";");
                    shuffleRes = shuffle(currentList, null);

                    shuffled_ans_list.push(shuffleRes.answers.join(';'));
                    shuff_solution_list.push(shuffleRes.answers.indexOf(currentList[0]));

                    text = text.replace(result.toString(), '#' + numOfGaps + '#'); //replacing gaptext element to incremental numbers
                    numOfGaps++;
                }
            } else {
                while ((result = pattern.exec(exerciseDetails.gap_text))) {
                    if (answer_list.includes(result.toString().slice(1, -1))) { //if duplicated solution occurs storing only the index of it to solution_list
                        solution_list.push(answer_list.indexOf(result.toString().slice(1, -1)));
                    } else {                                                    //else store the answeritem itself and the index as well
                        answer_list.push(result.toString().slice(1, -1));
                        solution_list.push(answer_list.indexOf(result.toString().slice(1, -1)));
                    }

                    if (exerciseDetails.answers)
                        for (let index = 0; index < exerciseDetails.answers.length; index++) {                  //stroing odd one out elements to answer_list too only one occurrence, not empty fields allowed
                            if (!answer_list.includes(exerciseDetails.answers[index]) && exerciseDetails.answers[index] != "")
                                answer_list.push(exerciseDetails.answers[index]);
                        }
                    text = text.replace(result.toString(), '#' + numOfGaps + '#');                      //replacing gaptext element to incremental numbers
                    numOfGaps++;
                }
                shuffleRes = shuffle(answer_list, null);

                for (var _i = 0; _i < solution_list.length; _i++) {                                                 //shuffling answers_list
                    shuff_solution_list.push(shuffleRes.answers.indexOf(answer_list[solution_list[_i]]));
                }
                shuffled_ans_list = shuffleRes.answers;
            }


            var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);

            cur_exercise = {
                ...cur_exercise,
                answers: shuffled_ans_list,
                solution: shuff_solution_list,
                gap_text: text,
                multiple_lists: multipleLists
            };
            return cur_exercise;
        }
        else return undefined;
    }

    public validate(editorAnswer: FillGapDropDownAnswerElement, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData, validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        let errorMsg = "";
        let OFIErrors: string[] = [];
        let convertedExc = this.convertToJson(editorAnswer, baseData);
        if (convertedExc) {
            if (convertedExc.answers.length < 2 && !convertedExc.multiple_lists)
                return { valid: false, message: __("Minimum {min} elem megadása kötelező!",{min:2}) }
            if (convertedExc.multiple_lists) {
                for (let i = 0; i < convertedExc.answers.length; i++) {
                    if (!convertedExc.answers[i].includes(";")) return { valid: false, message: __("Több listás módban minimum 2 elem megadása kötelező listánként!") }
                }
            }
            if (convertedExc.solution.length > EKEFillGapDropDownAnswerConverter.MAX_OPTIONS_NUMBER) {
                errorMsg = __("Maximum {max} elem adható meg!", { max: EKEFillGapDropDownAnswerConverter.MAX_OPTIONS_NUMBER });
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
            }
            let number_of_odds = convertedExc.answers.length - convertedExc.solution.length > 0 ? convertedExc.answers.length - convertedExc.solution.length : 0;
            if (number_of_odds > EKEFillGapDropDownAnswerConverter.MAX_ODDS_NUMBER) {
                errorMsg = __("Maximum {max} kakukktojás elem adható meg!", { max: EKEFillGapDropDownAnswerConverter.MAX_ODDS_NUMBER });
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
            }
            if (convertedExc.gap_text.length > EKEFillGapDropDownAnswerConverter.FILLGAP_GAP_TEXT_LENGTH) {
                errorMsg = __("A szöveg hossza maximum {FILLGAP_GAP_TEXT_LENGTH} karakter lehet.", { FILLGAP_GAP_TEXT_LENGTH: EKEFillGapDropDownAnswerConverter.FILLGAP_GAP_TEXT_LENGTH });
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
            }
        }
        if (AExerciseTypeConverter.isOfiEditor) return { valid: true, message: OFIErrors.join(' , ') }
        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: FillGapDropDownAnswerElement | null, newState: FillGapDropDownAnswerElement): ValidationResponse {
        if (newState.answers && newState.answers.length > EKEFillGapDropDownAnswerConverter.MAX_ODDS_NUMBER && !AExerciseTypeConverter.isOfiEditor) {
            return { valid: false, message: __("A kakukktojás elemek száma maximum {MAX_ODDS_NUMBER} lehet.", { MAX_ODDS_NUMBER: EKEFillGapDropDownAnswerConverter.MAX_ODDS_NUMBER }) }
        }

        return { valid: true }
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        statisticsResponses.push({name:__("limit - szöveg hossza: {n}", {n: EKEFillGapDropDownAnswerConverter.FILLGAP_GAP_TEXT_LENGTH}), count:undefined});
        statisticsResponses.push({name:__("limit - behúzandó elemek száma: {n}", {n: EKEFillGapDropDownAnswerConverter.MAX_OPTIONS_NUMBER}), count:undefined});
        statisticsResponses.push({name:__("limit - kakukktojás elemek: {n}", {n: EKEFillGapDropDownAnswerConverter.MAX_ODDS_NUMBER}), count:undefined});

        let answersStat, oddStat, gapTextStat: StatisticsResponse;
        answersStat = { name: __("Elemek száma"), count: new Map() }
        oddStat = { name: __("Kakukktojás elemek száma"), count: new Map() }
        gapTextStat = { name: __("Szövegek hossza"), count: new Map() }
        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            let baseData: ExerciseBaseTypes.ExerciseBaseClass = ExerciseBaseTypes.convertToBaseClass(exerciseList[i])!;
            const currentExc = this.convertToJson(exerciseList[i], baseData);
            if (!currentExc) continue;
            let answer_count = answersStat.count.has(currentExc.answers.length) ? answersStat.count.get(currentExc.answers.length) : 0;
            answersStat.count.set(currentExc.answers.length, answer_count! + 1);
            if (currentExc.answers.length - currentExc.solution.length < 0) continue;
            let odd_count = oddStat.count.has(currentExc.answers.length - currentExc.solution.length) ? answersStat.count.get(currentExc.answers.length - currentExc.solution.length) : 0;
            let nearHundred = Math.ceil(currentExc.gap_text.length / 100) * 100;
            let gap_text_count = gapTextStat.count!.has(nearHundred) ? gapTextStat.count!.get(nearHundred) : 0;
            gapTextStat.count!.set(nearHundred, gap_text_count! + 1);
        }
        statisticsResponses.push(answersStat, oddStat, gapTextStat);
        return statisticsResponses;
    }

}