import * as React from 'react';
import { debounce } from 'lodash';

export interface IDebouncedInputState {
    value : string;
}

export interface IDebouncedInputProps {
    delay ?:number;
    onValueChanged : (value:string)=>void;
}

const DEFAULT_DELAY = 500; // msec

export default class DebouncedInput extends React.Component<IDebouncedInputProps & React.InputHTMLAttributes<HTMLInputElement>,any> {
    private debouncedApplyChange : any;

    constructor(props: IDebouncedInputProps & React.InputHTMLAttributes<HTMLInputElement>) {
        super(props);
        this.state = { value: props.value };
        this.debouncedApplyChange = debounce(this.applyChange, this.props.delay || DEFAULT_DELAY)
    }

    private applyChange = () => {
        this.props.onValueChanged(this.state.value);
    }

    private onChange = (e: any) => {
        this.setState({value: e.target.value}, this.debouncedApplyChange)
    }

    render() {
        let props : any = {...this.props, value:this.state.value, onChange:this.onChange};
        delete props["onValueChanged"];
        delete props["delay"];
        return <input {...props} />
    }
}
