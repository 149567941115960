import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IVimeoUploadRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* double precision */|null;
    /**
     * SHA1 hash érték
     *
     * A file tartalmára számítva. Csak képeknél számítjuk!
     */
    sha1?: string;
    /**
     * Vimeo azonosító
     *
     * Ahogyan az a vimeo view URL végén megjelenik.
     */
    vimeo_id?: number|null;
    /**
     * Vimeo feltöltés állapota
     *
     * Ahogyan az a vimeo view URL végén megjelenik.
     */
    vimeo_status?: string|null;
    /**
     * Vimeo info
     */
    vimeo_data?: any|null;
    /**
     * Kérelem létrehozva
     */
    created?: string /*timestamp?*/;
    /**
     * Feldolgozva
     */
    processed?: string /*timestamp?*/|null;
    /**
     * Sikertelenné vált
     */
    failed?: string /*timestamp?*/|null;
    /**
     * Hibaüzenet
     */
    errormessage?: string|null;
    /**
     * Lejátszások száma
     */
    plays?: number;
}

/**
 *  media.vimeo_upload - Vimeo feltöltés
 *
 *  Vimeo feltöltések állapota
 */

export default class VimeoUploadCrud extends Crud<IVimeoUploadRecord> {
    public static TABLE_INFO_ID = 3714867861;
    public static getSchemaNameForClass() { return 'media'; }
    public static getTableNameForClass() { return 'vimeo_upload'; }

    public static load: (id: number, aServer ?: Server) => Promise<VimeoUploadCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IVimeoUploadRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IVimeoUploadRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IVimeoUploadRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IVimeoUploadRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IVimeoUploadRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class VimeoUploadCrudClassProxy extends CrudClassProxy<IVimeoUploadRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const vimeoUploadCrudClassProxy = new VimeoUploadCrudClassProxy(VimeoUploadCrud);
registerCrudClassProxy(vimeoUploadCrudClassProxy);
