import * as React from 'react';
import { debounce } from 'lodash';
import { app } from '@src/index';
import ViewSecViewInfoPerm, { IViewSecViewInfoPermRecord } from '@src/framework/view/meta/ViewSecViewInfoPerm';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { getReactTableLabels } from '@src/framework/i18n';
import MemberCard from '@src/framework/forms/MemberCard';
import { IListOrderBy, TFilterDict } from '@src/framework/crud/Crud';
import DebouncedInput from '@src/framework/forms/debouncedinput';
import { __ } from '@src/translation';

interface IPermissionAuditViewFinderRawProps {

}

interface IPermissionAuditViewFinderRawState {
    loading: boolean,
    items: IViewSecViewInfoPermRecord[];
    orderBy: IListOrderBy[];
    filter1: string;
    filter2: string;
}

const ORDER_BY_1_ASC = [
    { name: "schema_name" },
    { name: "view_name" }
];
const ORDER_BY_1_DESC = [
    { name: "schema_name", desc: true },
    { name: "view_name", desc: true }
];
const ORDER_BY_2_ASC = [
    { name: "login_name" },
    { name: "group_title" }
];
const ORDER_BY_2_DESC = [
    { name: "login_name", desc: true },
    { name: "group_title", desc: true }
];


export default class PermissionAuditViewFinderRaw extends React.Component<IPermissionAuditViewFinderRawProps, IPermissionAuditViewFinderRawState> {
    private debouncedAsyncReload: () => void;

    constructor(props: IPermissionAuditViewFinderRawProps) {
        super(props);
        this.state = {
            loading: true,
            items: [],
            orderBy: ORDER_BY_1_ASC,
            filter1: "",
            filter2: "",
        };
        this.debouncedAsyncReload = debounce(() => this.asyncReload(), 300);
    }

    componentDidMount() {
        this.debouncedAsyncReload();
    }

    private asyncReload = async () => {
        try {
            this.setState({ loading: true });
            let filters: TFilterDict[] = [
                // Azok a sorok nem jelennek meg, ahol egyik sem igaz.
                {
                    $or: {
                        can_read: true,
                    }
                }
            ];
            if (this.state.filter1) {
                filters.push({$text:{
                    fieldNames: ["view_name", "schema_name", "display_name"],
                    expression: this.state.filter1
                }});
            }
            if (this.state.filter2) {
                filters.push({$text:{
                    fieldNames: ["group_title", "group_description", "login_name", "email", "fullname"],
                    expression: this.state.filter2
                }});
            }
            const items = await ViewSecViewInfoPerm.list({
                filter: {$and: filters},
                order_by: this.state.orderBy
            });
            this.setState({ loading: false, items });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private setOrderBy = async (orderBy: IListOrderBy[]) => {
        console.log("setOrderBy", orderBy);
        this.setState({ orderBy, loading: true }, this.debouncedAsyncReload);
    }

    private filter1ValueChanged = (filter1: string) => {
        this.setState({ filter1, loading: true }, this.debouncedAsyncReload);
    }

    private filter2ValueChanged = (filter2: string) => {
        this.setState({ filter2, loading: true }, this.debouncedAsyncReload);
    }

    render() {
        const orderBy = this.state.orderBy[0]!;

        let sortIcon1 = null;
        let desc1: boolean = true;
        if (orderBy.name == ORDER_BY_1_ASC[0].name) {
            desc1 = orderBy.desc ? true : false;
            sortIcon1 = <i className={"fa " +
                (desc1 ? "fa-sort-alpha-up" : "fa-sort-alpha-down")} />;
        }
        let onClick1 = () => { this.setOrderBy(desc1 ? ORDER_BY_1_ASC : ORDER_BY_1_DESC); }

        let sortIcon2 = null;
        let desc2: boolean = true;
        if (orderBy.name == ORDER_BY_2_ASC[0].name) {
            desc2 = orderBy.desc ? true : false;
            sortIcon2 = <i className={"fa " +
                (desc2 ? "fa-sort-alpha-up" : "fa-sort-alpha-down")} />;
        }
        let onClick2 = () => { this.setOrderBy(desc2 ? ORDER_BY_2_ASC : ORDER_BY_2_DESC); }



        return <div className="row expanded">
            <div className="column small-12 large-12">
                <ReactTable
                    columns={[
                        {
                            Header: <div onClick={onClick1}>
                                View &nbsp;{sortIcon1}
                            </div>,
                            Cell: (data: any, column: any) => {
                                const row: IViewSecViewInfoPermRecord = data.original;
                                return <div style={{ display: "block" }}>
                                    <label className="label primary" style={{ fontFamily: "monospace" }}>
                                        <i className="fa fa-eye" /> &nbsp;
                                        {row.schema_name}.{row.view_name}
                                    </label><br />
                                    {row.table_name?
                                        <>
                                            <span style={{ fontFamily: "monospace" }}
                                                title={__("Elsődleges táblázat")}
                                            >
                                                <i className="fa fa-table" /> &nbsp;
                                                {row.schema_name}.{row.table_name}
                                            </span><br />
                                        </>:null
                                    }
                                    <label className="label secondary"
                                        title={row.description || ""}
                                    >
                                        {row.display_name}
                                    </label><br />
                                </div>;
                            },
                            Filter: () => {
                                return <DebouncedInput
                                    type="text"
                                    placeholder={__("Séma/táblázat/név")}
                                    value={this.state.filter1}
                                    onValueChanged={this.filter1ValueChanged}
                                />
                            }
                        },
                        {
                            Header: <div onClick={onClick2}>
                                {__("Jogosult")} &nbsp;{sortIcon2}
                            </div>,
                            Cell: (data: any, column: any) => {
                                const row: IViewSecViewInfoPermRecord = data.original;
                                if (row.group_id) {
                                    return <p
                                        title={row.group_description || ""}
                                    ><small>
                                            <i className="fa fa-users" /> {row.group_title}
                                            <br />
                                            <label className="label success">{row.sec_group_type_title}</label>
                                        </small>
                                    </p>
                                } else {
                                    return <MemberCard {...row} id={row.user_id!} />
                                }
                            },
                            Filter: () => {
                                return <DebouncedInput
                                    type="text"
                                    placeholder={__("Csoport/login/email/név")}
                                    value={this.state.filter2}
                                    onValueChanged={this.filter2ValueChanged}
                                />
                            }
                        },
                        {
                            Header: __("Jogok"),
                            filterable: false,
                            maxWidth: 100,
                            Cell: (data: any, column: any) => {
                                const row: IViewSecViewInfoPermRecord = data.original;
                                return <code style={{ width: "4em" }}>
                                    {row.can_read ? "R" : ""}
                                </code>
                            }
                        },
                    ]}
                    manual
                    data={this.state.items}
                    defaultPageSize={100}
                    pages={Math.ceil(this.state.items.length / 100)}
                    filterable={true}
                    className="-striped -highlight "
                    {...getReactTableLabels}
                    onFetchData={(state: any, instance: any) => this.debouncedAsyncReload()}
                    loading={this.state.loading}
                />
            </div>
        </div>
    }

}