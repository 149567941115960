import { IExcelDownloadParams } from './../crud/Crud';
import { utoa } from '@framework/util/StringUtils';

import obtainServer, { Server } from '@framework/server/Server';
import AbstractError from '@framework/util/AbstractError';
import CrudServer from '../crud/CrudServer';
import { IViewInfo, getViewInfoById } from './Meta';
import { IListParams, ICountParams, ICountResult  } from '@framework/crud/Crud';
import { objectToQueryJsonParams } from '../util/UrlUtils';

export interface IRecord {
    id?: number; // Globálisan egyedi rekord azonosító. Ha üres, akkor a rekord még sosem volt mentve.
}

/** Általános View osztály ami adatbázis nézettáblából képes lekérdezni. */
export default abstract class View<TRecord extends IRecord = IRecord> {
    public record: TRecord;

    constructor(record: TRecord) { this.record = record; }


    // ABSTRACT, override in subclass
    public static VIEW_INFO_ID: number;

    // ABSTRACT, override in subclass
    public static getSchemaNameForClass(): string { throw new AbstractError(); }
    
    // ABSTRACT, override in subclass
    public static getViewNameForClass(): string { throw new AbstractError(); }
    
    // ABSTRACT, override in subclass
    public static getTableNameForClass(): string | null { throw new AbstractError(); }
    
    /**
     * Lehet-e a list() és count() hívások eredményét cache-lni.
     * A publikus séma táblázataira ez true-t ad vissza, és ilyenkor
     * a list() és count() hívások GET kérést eredményeznek POST helyett.
     * 
     */
    public static canCache(): boolean {
        return false;
    }

    // TODO lehet hogy nem használja semmi?
    public static getServerForClass(aServer?: Server): CrudServer<IRecord> {
        return new CrudServer<IRecord>(aServer);
    }

    /** CRUD API Endpoint path, ezen keresztül lehet elérni a tábla műveleteit
     */
    public static getAPIPathForClass(): string {
        return `view/${this.getSchemaNameForClass()}/${this.getViewNameForClass()}/`;
    }

    /** Adatszerkezet lekérdezése */
    public static getViewInfoForClass(aServer?: Server): Promise<IViewInfo> {
        return getViewInfoById(this.VIEW_INFO_ID, aServer);
    }

    public static load(id: number, aServer?: Server): Promise<View<IRecord>> { 
        const constructor: any = this;
        return obtainServer(aServer)
            .get<IRecord>(this.getAPIPathForClass() + id.toString())
            .then((record) => new constructor(record));
    }

    public static list(params: IListParams, aServer?: Server): Promise<IRecord[]> {
        let canGet: boolean = this.canCache();        
        let qParams = "";
        if (canGet) {
            qParams = "operation=list&params=" + objectToQueryJsonParams(params);
            if (qParams.length > 250) {
                canGet = false;
            }
        }
        if (canGet) {
            return obtainServer(aServer)
                .get<IRecord[]>(this.getAPIPathForClass() + "?" + qParams);
        } else {
            const fullParams = { operation: 'list', ...{ params } };
            return obtainServer(aServer)
                .post<IRecord[]>(this.getAPIPathForClass(), fullParams);
        }
    }

    public static getExcelDownloadUrl(params: IListParams, dlParams: IExcelDownloadParams): string {
        let qParams = "";
        qParams = "operation=list&format=excel&params=" + objectToQueryJsonParams(params);
        if (dlParams.filename) {
            qParams += "&filename=" + encodeURIComponent(dlParams.filename);
        }
        if (qParams.length > 512) {
            throw new Error("Cannot use GET url to donwload excel - URL would be too long.");
        }
        return '/api/' + this.getAPIPathForClass() + "?" + qParams;
    }

    public static count(params: ICountParams, aServer?: Server): Promise<number> {
        let canGet: boolean = this.canCache();        
        let qParams = "";
        if (canGet) {
            qParams = "operation=count&params=" + objectToQueryJsonParams(params);
            if (qParams.length > 250) {
                canGet = false;
            }
        }
        if (canGet) {
            return obtainServer(aServer)
                .get<ICountResult>(this.getAPIPathForClass() + "?" + qParams)
                .then((result) => { return result.count; });
        } else {
            const fullParams = { operation: 'count', ...{ params } };
            return obtainServer(aServer)
                .post<ICountResult>(this.getAPIPathForClass(), fullParams)
                .then((result) => { return result.count; });
        }
    }

    private getClass() {
        return this.constructor as typeof View & (new (rec: TRecord) => View);
    }

    public getSchemaName() {
        return this.getClass().getSchemaNameForClass();
    }

    public getViewName() {
        return this.getClass().getViewNameForClass();
    }

    public getTableName() {
        return this.getClass().getTableNameForClass();
    }

    public getViewInfoId() {
        return this.getClass().VIEW_INFO_ID;
    }

    public getServer(aServer?: Server) {
        return new CrudServer<TRecord>(aServer);
    }

    public getAPIPath(): string {
        let url = this.getClass().getAPIPathForClass();
        if (this.record.id !== undefined && this.record.id !== null) {
            url += '/' + this.record.id;
        }
        return url;
    }

    public getViewInfo(aServer?: Server): Promise<IViewInfo> {
        return getViewInfoById(this.getViewInfoId(), aServer);
    }

    public post(operation: string, params?: any, aServer ?: Server): Promise<View> {
        const cls = this.getClass();
        return this.getServer(aServer)
            .post(this.getAPIPath(), this.record, operation, params)
            .then((record) => new cls(record));
    }
}


export class ViewClassProxy<TRec extends IRecord> {
    private viewClass: typeof View;
    constructor(crudClass?: any) {
        this.viewClass = crudClass;
        
    }
    public getSchemaNameForClass(): string { 
        return this.viewClass.getSchemaNameForClass(); 
    }
    public getViewNameForClass(): string { 
        return this.viewClass.getViewNameForClass(); 
    }
    public getTableNameForClass(): string | null { 
        return this.viewClass.getTableNameForClass(); 
    }
    public getServerForClass(aServer?: Server): CrudServer<TRec> {
        return this.viewClass.getServerForClass(aServer) as CrudServer<TRec>;
    }
    public getAPIPathForClass():string { 
        return this.viewClass.getAPIPathForClass(); 
    }

    public load(id: number): Promise<TRec> {
        return this.viewClass.load(id).then((crud) => crud.record as TRec);
    }
    public list(params: IListParams, aServer?: Server): Promise<TRec[]> {
        return this.viewClass.list(params, aServer) as Promise<TRec[]>;
    }
    public getExcelDownloadUrl(params: IListParams, dlParams: IExcelDownloadParams): string {
        return this.viewClass.getExcelDownloadUrl(params, dlParams);
    }
    public count(params: ICountParams, aServer?: Server): Promise<number> {
        return this.viewClass.count(params, aServer);
    }
    public getViewInstance(record: Partial<TRec>): View<TRec> {
        return new (this.viewClass as any)(record);
    }

    public getViewInfoForClass(): Promise<IViewInfo> {
        return this.viewClass.getViewInfoForClass();
    }
    public getViewInfoIdForClass(): number {
        return this.viewClass.VIEW_INFO_ID;
    }

    /** React route path pattern készítés listázó felülethez */
    public getDefaultListRoute(): string {
        return `/${this.getSchemaNameForClass()}/${this.getTableNameForClass()}/list/:params?`;
    }
    /** React route path pattern készítés szerkesztő felülethez */
    public getDefaultEditRoute(): string {
        return `/${this.getSchemaNameForClass()}/${this.getTableNameForClass()}/edit/:recId/:params?`;
    }
    /**
     * Konkrét listázó Url készítés a megadott szűrőkhöz.
     * 
     * @param filterValues: alapértelmezett szűrés a listázáshoz
     * 
     * */
    public getListUrl(filterValues?: any, spec?: any): string {
        let encodedParams: string = '';
        if ( (filterValues && Object.keys(filterValues).length !== 0 ) || spec) {
            let params = {};
            if (filterValues!==undefined)
                params["filter"] = filterValues;
            if (spec!==undefined)
                params["spec"] = spec;
            encodedParams = utoa(JSON.stringify(params));
        }
        return `/${this.getSchemaNameForClass()}/${this.getTableNameForClass()}/list/` +
            encodedParams;
    }
    /** 
     * Konkrét szerkesztő Url készítés a megadott rekord azonosítóhoz
     *
     * @param recId: A rekord azonosítója. Ha null, akkor új felvitel lesz belőle.
     * @param defaultValues: opcionálisan azokat alapértelmezett mező értékek
     *   tartalmazza, amiket új rekord létrehozásakor (az új gomb megnyomásakor)
     *   be kell írni.
     */
    public getEditUrl(recId: number | null, defaultValues: any, spec ?: any): string {
        let defaultValuesEncoded: string;
        if (defaultValues || spec) {
            let params = {}
            if (defaultValues) {
                params["defaultValues"] = defaultValues;
            }
            if (spec) {
                params["spec"] = spec;
            }
            defaultValuesEncoded = utoa(JSON.stringify(params));
        } else {
            defaultValuesEncoded = '';
        }        
        return `/${this.getSchemaNameForClass()}/${this.getTableNameForClass()}/edit/` +
            (recId || 'null') +
            '/' + defaultValuesEncoded;
    }
}

type IViewClassProxyCache = { [viewInfoId: number]: ViewClassProxy<IRecord> };
let viewClassProxyCache: IViewClassProxyCache = {}

export const registerViewClassProxy = (viewClassProxy: ViewClassProxy<IRecord>) => {
    viewClassProxyCache[viewClassProxy.getViewInfoIdForClass()] = viewClassProxy;
}

export const getViewClassProxyById = (viewInfoId: number): ViewClassProxy<IRecord> => {
    return viewClassProxyCache[viewInfoId];
}