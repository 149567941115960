import * as React from 'react';
import { app } from '@src/index';
import HtrTicketCategoryCrud, { htrTicketCategoryCrudClassProxy, IHtrTicketCategoryRecord } from '@src/framework/crud/ticket/HtrTicketCategoryCrud';
import Tree, { TreeNode } from 'rc-tree';
import { __ } from '@src/translation';
import { subsiteCrudClassProxy } from '@src/framework/crud/cms/SubsiteCrud';
import HtrTicketCrud from '@src/framework/crud/ticket/HtrTicketCrud';
import LookupEdit from '@src/framework/forms/lookupedit';
import SecPermOwnerCrud from '@src/framework/crud/sys/SecPermOwnerCrud';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import { confirmDialog } from '../Dialog';
import { Dialog } from '@src/component/Dialog';

export const PATH_TICKET_CATEGORY_EDITOR = "/ticket/category";

type TicketCategoryEditorState={
    categories: IHtrTicketCategoryRecord[];
    currentCategory: IHtrTicketCategoryRecord;
    dialogOpen: boolean;
    dialogIsEditor: boolean;
}

export default class TicketCategoryEditor extends React.Component<any, TicketCategoryEditorState> {

    constructor(props:any) {
        super(props);
        this.state={
            categories: [],
            currentCategory: {},
            dialogOpen: false,
            dialogIsEditor: false
        }
    }

    componentDidMount() {
        this.reloadCategories();
    }
    
    /**
     * Reads the categories from the server and saves it to the 'state.categories' array.
     */
    async reloadCategories() {
        const categories = await htrTicketCategoryCrudClassProxy.list({filter: {is_active: true}});
        this.setState({categories});
    }

    /**
     * The general onChange input event handler.
     * @param propName The property that should be changed on the current category.
     * @param value The value that should be put into the property.
     */
    private onChange(propName: string, value: any) {
        this.setState(
            {
                currentCategory: { ...this.state.currentCategory, [propName]: value }
            }
        );
    }

    /**
     * Creates or Updates a ticket category record, based on the dialogIsEditor field in the state. If the field is true, it Updates, if it is false, it Creates.
     */
    private async onSave() {
        try {
            if (this.state.currentCategory.title === "" || this.state.currentCategory.title == null) return;
            this.state.currentCategory.selectable = (document.getElementById('new_selectable') as HTMLInputElement).checked;
            this.state.currentCategory.for_mentor = (document.getElementById('new_for_mentor') as HTMLInputElement).checked;
            if (!this.state.dialogIsEditor)
            {
                this.state.currentCategory.parent_id = this.state.currentCategory.id;
                this.state.currentCategory.id = undefined;
            }
            await new HtrTicketCategoryCrud(this.state.currentCategory).put();
            app.showSuccess(__("Sikeres mentés"), __("Lementve"));
            this.setState({dialogOpen: false});
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
        this.reloadCategories();
    }

    render()
    {
        const customTitle = (i: any) => {
            return <>
                <span>{i.title}</span>
                &nbsp;
                <span onClick={(e) => this.handleEditCategory(i.id)}>{"🛠" + __("Módosítás")}</span>
                &nbsp;
                <span onClick={(e) => this.deleteCategory(i.id)}>{"🗑" + __("Törlés")}</span>
            </>
        }

        const loop = (parentId?: number) => {
            return this.state.categories.filter(i => i.parent_id == parentId).map(i => {
                return <TreeNode title={customTitle(i)} key={i.id}>
                    {loop(i.id)}
                </TreeNode>
            })
        };

        let dialog = <Dialog open={this.state.dialogOpen} title={this.props.title} onClose={() => this.setState({ dialogOpen: false })}>
            <h3>{__("Kategória {isEditor}", {isEditor: this.state.dialogIsEditor ? __("módosítása") : __("felvétele")})}</h3>
            <div className="column small-12">
                <label>
                    {__("Elnevezés")}
                    <input type="text" id="new_title" value={this.state.currentCategory.title} onChange={this.handleFormTextChange.bind(this)}/>
                </label>
            </div>
            <div className="column small-12">
                <label>
                    {__("Választható") + " "}
                    <input type="checkbox" defaultChecked={this.state.currentCategory.selectable} id="new_selectable"/>
                </label>
            </div>
            <div className="column small-12">
                <label>
                    {__("Mentornak") + " "}
                    <input type="checkbox" defaultChecked={this.state.currentCategory.for_mentor} id="new_for_mentor"/>
                </label>
            </div>
            <div className="column small-12">
                <label>{__("Üzenet kategória jogosultja") + " "}</label>
                <LookupEdit
                    fk_table_info_id={SecPermOwnerCrud.TABLE_INFO_ID}
                    onChange={(newValue) => this.onChange("default_agent_id", newValue)}
                    value={this.state.currentCategory.default_agent_id}
                    clearable={true}
                    placeholder={this.props.selectorPlaceholder}
                />
            </div>
            <div className="column small-12">
                <label>{__("Aloldal")}</label>
                <CrudSelectComponent
                    value={this.state.currentCategory.subsite_id!}
                    onSelect={(sender, newValue) => this.onChange("subsite_id", newValue)}
                    displayFieldName="title"
                    key="id"
                    emptyTitle={__("Nincs aloldal")}
                    crudClassProxy={subsiteCrudClassProxy}
                    filter={{ is_active: true }}
                    sortFunc={(a, b) => a.title.localeCompare(b.title, undefined, { numeric: true, sensitivity: 'base' })}
                />
            </div>
            <div className="column text-right">
                <br />
                <button className="button success" onClick={this.handleSaveButton.bind(this)}>
                    <i className="fa fa-save" /> {__("Mentés")}
                </button>
            </div>
        </Dialog>;

        return <>
        <div className="column sm-12">
            <h1>{__("Üzenet kategóriák")}</h1>
            <button onClick={() => {this.openDialog(false)}} className="button">
                {__("Új kategória felvétele")}
            </button>
            <Tree showIcon={false} onSelect={(newValue) => this.handleTreeOnSelect(Number(newValue[0]))}>
                {loop()}
            </Tree>
            {dialog}
        </div>
        </>
    }

    /**
     * Handles the change event for the title input field.
     * @param event The input event. It should be "bind(this)".
     */
    private handleFormTextChange(event: React.FormEvent<HTMLInputElement>) {this.onChange("title", event.currentTarget.value);}

    /**
     * Seraches for a category by id, then saves it to the state.
     * @param id The id of the selected category.
     */
    private handleTreeOnSelect(id: number) {this.setState({currentCategory: this.findCategory(id)});}

    /**
     * Opens the editor modal and sets the save button's behaviour.
     * @param isEditor Tells the system if the save button should be an Updater or Creator.
     */
    private openDialog(isEditor: boolean)
    {
        this.setState({dialogIsEditor: isEditor});
        this.setState({dialogOpen: true});
    }

    private handleSaveButton() {this.onSave();}

    /**
     * Handles the edit button's click event.
     * @param id The id of the category to be edited.
     */
    private handleEditCategory(id: number)
    {
        this.setState({currentCategory: this.findCategory(id)});
        this.openDialog(true);
    }

    /**
     * Deltes a category based on the category's id.
     * @param id The id of the category to be deleted.
     */
    private async deleteCategory(id: number)
    {
        if (!await confirmDialog(__("Törlés"), __("Biztosan törölni akarja a kategóriát?"), __("Törlés"))) return;
        try {
            await htrTicketCategoryCrudClassProxy.deleteById(id);
            app.showSuccess(__("Kategória sikeresen törölve!"), "");
        } catch (e) {
            app.showError(__("A kategória törlése sikertelen volt!"), "");
        }
        this.state.currentCategory.parent_id = null;
        this.reloadCategories();
    }

    /**
     * Searches for a category based on the id.
     * @param id The id of the category to be searched.
     * @returns The category if any exists with that id.
     */
    private findCategory(id: number): IHtrTicketCategoryRecord
    {
        let category: IHtrTicketCategoryRecord = {};
        this.state.categories.forEach(element => {
            if (element.id === id) category = element;
        });
        return category;
    }
}