import * as React from 'react';
import MediaFileChooser, { ContentActionType } from '@src/component/filemanager/MediaFileChooser';
import { IViewKapCourseUserRecord } from '@src/framework/view/kap/ViewKapCourseUser';
import { DownloadAsPdf } from '@src/project/kap/draft/DownloadAdPdf';
import { __ } from '@src/translation';
import { confirmDialog, Dialog } from '@src/component/Dialog';
import WfAPI from '@src/framework/wf/WfAPI';
import obtainServer from '@src/framework/server/Server';
import { transitionTypeCrudClassProxy } from '@src/framework/crud/wf/TransitionTypeCrud';
import { WF_COURSE_USER_STATION_APPLICATION_UNDER_REVIEW_ID } from '../../CourseDetails';
import { app } from "@src/index";
import { WorkflowDialog } from '@src/component/wf/WorkflowDialog';
import CourseCrud from '@src/framework/crud/kap/CourseCrud';
import { LessonDraftEdit } from '@src/project/kap/draft/LessonDraft';
import { LIBRARY_KSZR_BEADANDO_ID } from '@src/Const';
import { KipDraftEdit } from '@src/project/kap/draft/KipDraft';
import { OccupationDraftEdit } from '@src/project/kap/draft/OccupationDraftEdit';
import CourseUserCrud from '@src/framework/crud/kap/CourseUserCrud';
import { Accordion, AccordionItem } from '@src/component/ui/Accordion';
import { ITrainingTypeMilestoneRecord } from '@src/framework/crud/kap/TrainingTypeMilestoneCrud';
import CourseUserMilestoneCrud, { ICourseUserMilestoneRecord } from '@src/framework/crud/kap/CourseUserMilestoneCrud';
import { ITrainingTypeRecord } from '@src/framework/crud/kap/TrainingTypeCrud';
import { me } from '@src/framework/server/Auth';
import { addDays } from 'date-fns';
import { IViewKapCourseRecord } from '@src/framework/view/kap/ViewKapCourse';
import { formatDateWithoutTime } from '@src/Util';
import { whiteSpaceChecker } from '@src/component/WhiteSpaceChecker';

const WF_COURSE_USER_STATION_APPLICATION_REQUESTED_ID = 25406740434; // Kérelem beadva
const WF_COURSE_USER_STATION_APPLICATION_REJECTED_ID = 25406740437; // Kérelem visszautasítva
const WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID = 25406740438; // Képzés engedélyezve
const WF_COURSE_USER_STATION_APPLICATION_CORRECTION_ID = 25503820779; // Beadandó javítása
const WF_COURSE_USER_STATION_STUDENT_REMOVED_ID = 25406740442; // Képzésből törölve

type CourseDetailsTaskPageProps = {
    trainingType: ITrainingTypeRecord;
    courseRecord: IViewKapCourseRecord;
    courseUser: IViewKapCourseUserRecord;
    milestones: ITrainingTypeMilestoneRecord[];
    isTeacher: boolean;
    onReload: () => void;
}

type CourseDetailsTaskPageState = {
    workflowDialogOpen: boolean;

    showLessonPlanEditor: boolean;
    showKipDraftEditor: boolean;
    showOccupationDraftEditor: boolean;

    userMilestones: ICourseUserMilestoneRecord[];
}

export class CourseDetailsTaskPage extends React.Component<CourseDetailsTaskPageProps, CourseDetailsTaskPageState> {

    constructor(props: CourseDetailsTaskPageProps) {
        super(props);

        this.state = {
            workflowDialogOpen: false,
            showLessonPlanEditor: false,
            showKipDraftEditor: false,
            showOccupationDraftEditor: false,
            userMilestones: [],
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        if (!me) return;

        const filter = {
            is_active:true, 
            training_type_milestone_id: this.props.milestones.map(m => m.id!),
            course_user_id: this.props.courseUser.id
        };

        try {
            
            let userMilestones = await CourseUserMilestoneCrud.list({filter});
            let reloadUserMilestones = false;

            for(const milesone of this.props.milestones) {
                if (!userMilestones.find(um => um.training_type_milestone_id === milesone.id)) {
                    await new CourseUserMilestoneCrud({
                        training_type_milestone_id: milesone.id,
                        course_user_id: this.props.courseUser.id
                    }).put();
                    reloadUserMilestones = true;
                }
            }

            if (reloadUserMilestones) {
                userMilestones = await CourseUserMilestoneCrud.list({filter});
            }

            this.setState({
                userMilestones
            });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        };

    }

    private async submitAssignment() {
        if (!this.props.courseUser) return;
        //todo: check if there's any file or lesson plan to submit

        let message = __("Biztosan beküldi a beadandót elbírálásra?")

        if ((await confirmDialog(__("Megerősítés"), message))) {
            const wfApi: WfAPI = new WfAPI(obtainServer());
            try {
                const transitionTypes = await transitionTypeCrudClassProxy.list({
                    filter: {
                        is_active: true,
                        src_station_id: this.props.courseUser.wf_station_id,
                        dst_station_id: WF_COURSE_USER_STATION_APPLICATION_UNDER_REVIEW_ID
                    }
                });
                if (transitionTypes && transitionTypes.length == 1) {
                    const transitionTypeId: number = transitionTypes[0].id!;
                    await wfApi.makeTransition(this.props.courseUser.wf_workflow_id!, transitionTypeId);
                    this.props.onReload();
                } else {
                    if (!transitionTypes || transitionTypes.length != 1) {
                        app.showError("Belső hiba", "Nincs ilyen átmenet.");
                        return;
                    }
                }
            } catch (ex) {
                app.showErrorFromJsonResult(ex);
            }
        }
    }

    private async onSetLessonDraftId(draftId: number) {
        let currCourseUser = this.props.courseUser;

        try {
            await new CourseUserCrud({
                id: currCourseUser.id,
                lesson_draft_id: draftId
            }).put();
            this.props.onReload();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        };
    }

    private async onSetKipDraftId(draftId: number) {
        let currCourseUser = this.props.courseUser;

        try {
            await new CourseUserCrud({
                id: currCourseUser.id,
                kip_draft_id: draftId
            }).put();
            this.props.onReload();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        };
    }

    private async onSetOccupationDraftId(draftId: number) {
        let currCourseUser = this.props.courseUser;

        try {
            await new CourseUserCrud({
                id: currCourseUser.id,
                occupation_draft_id: draftId
            }).put();
            this.props.onReload();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        };
    }

    render() {

        let isStudentDataEditable = this.props.courseUser && (
            this.props.courseUser.wf_station_id == WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID ||
            this.props.courseUser.wf_station_id == WF_COURSE_USER_STATION_APPLICATION_CORRECTION_ID);

        let showStudentData = (this.props.courseUser != undefined
            && this.props.courseUser.wf_station_id != WF_COURSE_USER_STATION_APPLICATION_REQUESTED_ID
            && this.props.courseUser.wf_station_id != WF_COURSE_USER_STATION_APPLICATION_REJECTED_ID
            && this.props.courseUser.wf_station_id != WF_COURSE_USER_STATION_STUDENT_REMOVED_ID);
        
        const downloadPermit = [ContentActionType.FILE_DOWNLOAD];
        let filePermits: number[] = downloadPermit;
        if (isStudentDataEditable)
            filePermits = filePermits.concat([ContentActionType.FILE_MODIFY, ContentActionType.FILE_UPLOAD, ContentActionType.FILE_DELETE]);
    
        let unfinishedmilestone:boolean = -1 < (this.state.userMilestones.findIndex((el)=> !whiteSpaceChecker(el.succeeded)));
        return <>
            <Accordion allowAllClosed={true}>
                {
                    this.props.milestones.map((milestone, index) => {
                        const userMilestone = this.state.userMilestones.find(m => m.training_type_milestone_id === milestone.id);

                        let title = milestone.title || (index + ".");
                        if (this.props.courseRecord.training_from && milestone.deadline_days) {
                            title += ", határidő: " + formatDateWithoutTime(addDays(this.props.courseRecord.training_from, milestone.deadline_days));
                        }

                        return <AccordionItem key={index} title={title}>
                            <div dangerouslySetInnerHTML={{__html: milestone.description_html || ""}} />
                            {
                                userMilestone &&
                                (
                                    <>
                                    <MediaFileChooser
                                        rootFolderId={userMilestone.oo_folder_id!}
                                        currentFolderId={userMilestone.oo_folder_id!}
                                        showHoverPreview={false}
                                        showLocation={false}
                                        showFilter={false}
                                        enabledActions={whiteSpaceChecker(userMilestone.succeeded)?downloadPermit: filePermits}
                                    />
                                    {userMilestone.succeeded && <strong><i className="fa fa-check"/> Teljesítette</strong>}
                                    </>
                                )
                            }
                        </AccordionItem>
                    })
                }
                
                <AccordionItem title="Kurzus záró dolgozat">

                {isStudentDataEditable &&
                    <div>
                        <button className={"button small eke-general-buttons" + (this.props.courseUser!.lesson_draft_id ? " success":"")}
                            onClick={() => this.setState({ showLessonPlanEditor: true })} >
                            <i className="fas fa-file-alt" /> {this.props.courseUser!.lesson_draft_id ? __("Óraterv szerkesztése") : __("Új óraterv")}
                        </button>
                        <button className={"button small eke-general-buttons" + (this.props.courseUser!.kip_draft_id ? " success":"")}
                            onClick={() => this.setState({ showKipDraftEditor: true })} >
                            <i className="fas fa-file-alt" /> {this.props.courseUser!.kip_draft_id ? __("KIP óraterv szerkesztése") : __("Új KIP óraterv")}
                        </button>
                        <button className={"button small eke-general-buttons" + (this.props.courseUser!.occupation_draft_id ? " success":"")}
                            onClick={() => this.setState({ showOccupationDraftEditor: true })} >
                            <i className="fas fa-file-alt" /> {this.props.courseUser!.occupation_draft_id ? __("Foglalkozásterv szerkesztése") : __("Új foglalkozásterv")}
                        </button>
                    </div>
                }
                {
                    (this.props.isTeacher || showStudentData) && <div className="kap_course_download_div">
                        {this.props.courseUser!.lesson_draft_id && <DownloadAsPdf id={this.props.courseUser!.lesson_draft_id} type="lesson_draft" published={false}>{__("Óraterv")}</DownloadAsPdf>}
                        {this.props.courseUser!.kip_draft_id && <DownloadAsPdf id={this.props.courseUser!.kip_draft_id} type="kip_draft" published={false}>{__("KIP Óraterv")}</DownloadAsPdf>}
                        {this.props.courseUser!.occupation_draft_id && <DownloadAsPdf id={this.props.courseUser!.occupation_draft_id} type="occupation_draft" published={false}>{__("Foglalkozásterv")}</DownloadAsPdf>}
                    </div>
                }

                <MediaFileChooser
                    rootFolderId={this.props.courseUser.oo_folder_id!}
                    currentFolderId={this.props.courseUser.oo_folder_id!}
                    showHoverPreview={false}
                    showLocation={false}
                    showFilter={false}
                    enabledActions={filePermits}
                    showFolders={false}
                />

                {(this.props.courseUser.wf_station_id == WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID
                    ||
                    this.props.courseUser.wf_station_id == WF_COURSE_USER_STATION_APPLICATION_CORRECTION_ID
                ) ?
                    <div>
                        <button className={"button small warning eke-general-buttons"} disabled={unfinishedmilestone}
                            onClick={this.submitAssignment.bind(this)} >
                            <i className="fa fa-paper-plane" /> {
                                this.props.courseUser.wf_station_id == WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID
                                    ? __("Beadandó beküldése")
                                    : __("Javított beadandó visszaküldése")
                            }
                        </button></div> : ""
                }
                {unfinishedmilestone && <div><em>{__("Nem lehet beküldeni, amíg nincsenek elfogadva a mérföldkövek!")}</em></div> }
                {
                    this.props.courseUser &&
                    <>
                        <button className="button" onClick={() => this.setState({ workflowDialogOpen: !this.state.workflowDialogOpen })}>
                            <i className="fa fa-project-diagram" />&nbsp; {__("Előzmények/indoklás")}
                        </button>
                        <label className="label" style={{ alignSelf: "center", marginRight: "0.5rem" }}>{__("Állapot")}: {this.props.courseUser.wf_station_name}</label>

                    </>

                }

                <WorkflowDialog
                    open={this.state.workflowDialogOpen}
                    displayName={__("Beadandó")}
                    tableInfoId={CourseUserCrud.TABLE_INFO_ID}
                    recId={this.props.courseUser.id!}
                    onClose={() => this.setState({ workflowDialogOpen: false })}
                    onTransition={this.props.onReload}
                    showHistory={isStudentDataEditable || undefined}
                    autoPublishOnClosedStation={true}
                />

                {this.state.showLessonPlanEditor &&
                    <Dialog title="Óravázlat" width={1100} height={800}
                        onClose={() => this.setState({ showLessonPlanEditor: false })}>
                        <LessonDraftEdit
                            id={this.props.courseUser.lesson_draft_id || undefined}
                            onSave={this.onSetLessonDraftId.bind(this)}
                            defaultLibraryId={LIBRARY_KSZR_BEADANDO_ID}
                            hideBackLink
                        />
                    </Dialog>
                }
                {this.state.showKipDraftEditor &&
                    <Dialog title="KIP Óravázlat" width={1100} height={800}
                        onClose={() => this.setState({ showKipDraftEditor: false })}>
                        <KipDraftEdit
                            id={this.props.courseUser.kip_draft_id || undefined}
                            onSave={this.onSetKipDraftId.bind(this)}
                            defaultLibraryId={LIBRARY_KSZR_BEADANDO_ID}
                            hideBackLink
                        />
                    </Dialog>
                }
                {this.state.showOccupationDraftEditor &&
                    <Dialog title="Óravázlat" width={1100} height={800}
                        onClose={() => this.setState({ showOccupationDraftEditor: false })}>
                        <OccupationDraftEdit
                            id={this.props.courseUser.occupation_draft_id || undefined}
                            onSave={this.onSetOccupationDraftId.bind(this)}
                            defaultLibraryId={LIBRARY_KSZR_BEADANDO_ID}
                            hideBackLink
                        />
                    </Dialog>
                }
                </AccordionItem>
            </Accordion>
        </>
    }
}