import obtainServer, { Server } from '@framework/server/Server';

/** Egy táblázat struktúráját írja le. */
export interface ITableInfo {
    id: number;
    schema_name: string;
    table_name: string;
    is_journaled: boolean;
    is_logdel: boolean;
    fields: IFieldInfo[];
    translations: { [lang_id: number]: ITableInfoTranslation };
    transitivekeys: IFieldInfoTransitiveKey[];
}

export interface ITableInfoTranslation {
    id: number;
    table_info_id: number;
    lang_id: number;
    display_name: string;
    description: string;
}

/**
 * Tranzitív külső kulcs hivatkozási megszorítás.
 * 
 * Ha nem sejted mi ez, kérdezd Lacit...
 */
export interface IFieldInfoTransitiveKey {
    id: number;
    detail_field_info_id: number;
    master_field_info_id: number;
    parent_field_info_id: number;
}

/** 
 * Egy táblázat mezőjének stuktúráját írja le.
 * 
 * Ez nem egyezik meg az IFieldInfoCrud-ban levőkkel. Ez nem az amit
 * a CRUD API-val load() metódussal lehet kérdezni, hanem egy olyan 
 * változat, ami tartalmazza a mezőnév fordításokat is.
 * 
 */
export interface IFieldInfo {
    id: number;
    field_name: string;
    fieldno: number;
    orig_type: string;
    not_null: boolean;
    is_immutable: boolean;
    req_level: string;
    is_technical: boolean;
    fk_name?: string;
    fk_table_info_id?: number;
    fk_delete_rule?: string; // 'noaction' | 'cascade'
    translations: { [lang_id: number]: IFieldInfoTranslation };
}

export interface IFieldInfoTranslation {
    id: number;
    field_info_id: number;
    lang_id: number;
    display_name: string;
    description: string;
}

const fetchTableInfoByName = async (schemaName: string, tableName: string, aServer?: Server): Promise<ITableInfo> => {
    return obtainServer(aServer).post<ITableInfo>(
        `crud/${schemaName}/${tableName}`,
        { operation: 'getstruct' });
}

const fetchTableInfoById = async (tableInfoId: number, aServer?: Server): Promise<ITableInfo> => {
    return obtainServer(aServer).post<ITableInfo>(
        `crud/_tableinfo/${tableInfoId}`,
        { operation: 'getstruct', table_info_id: tableInfoId });
}

const tableInfoByName: { [key: string]: ITableInfo } = {};
const tableInfoById: { [key: number]: ITableInfo } = {};

export const getTableInfoByName = async (schemaName: string, tableName: string, aServer?: Server): Promise<ITableInfo> => {
    const key: string = `${schemaName}.${tableName}`;
    if (!tableInfoByName[key]) {
        let ti: ITableInfo = await fetchTableInfoByName(schemaName, tableName);
        tableInfoByName[key] = ti;
        tableInfoById[ti.id] = ti;
    }
    return tableInfoByName[key];
}

export const getTableInfoById = async (tableInfoId: number, aServer?: Server): Promise<ITableInfo> => {
    if (!tableInfoById[tableInfoId]) {
        let ti = await fetchTableInfoById(tableInfoId);
        const key = ti.schema_name + '.' + ti.table_name;
        tableInfoByName[key] = ti;
        tableInfoById[tableInfoId] = ti;
    }
    return tableInfoById[tableInfoId];
}
