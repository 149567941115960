import Crud, { IRecord } from "@src/framework/crud/Crud";
import { IFieldInfo } from "@src/framework/crud/Meta";
import { LANG_ID_HU } from "./Const";

import * as parse from 'date-fns/parse';


export const HUN_COUNTRY_CODE = "+36";

export async function scriptLoadAsync(scriptElement: HTMLScriptElement | HTMLLinkElement, timeoutMillis = 60000): Promise<any> {

    return new Promise((resolve, reject) => {

        const failedToLoadTimer = setTimeout(() => reject("Timeout loading script: " + scriptElement), timeoutMillis);

        scriptElement.addEventListener("load", () => {
            clearTimeout(failedToLoadTimer);
            resolve();
        });

    });
}

export async function loadAnnotatorScriptAsync(doc: Document) {
    return loadScriptAsync(doc, "/lib/annotator/annotator.min.js");
}

export async function loadMXGraphScriptAsync() {
    return loadScriptAsync(document, "/lib/mxgraph/viewer.min.js");
}

export async function loadBodymovinScriptAsync() {
    return loadScriptAsync(document, "/lib/bodymovin/bodymovin_light.min.js");
}

export async function loadBodymovinScriptAsync2() {
    return loadScriptAsync(document, "/lib/bodymovin/bodymovin_light_2.min.js");
}

export async function loadVimeoPlayerApiAsync() {
    return loadScriptAsync(document, "https://player.vimeo.com/api/player.js");
}

export async function timeoutAsync(millis: number) {
    return new Promise(resolve => setTimeout(resolve, millis));
}


async function loadScriptAsync(document: Document, url: string, timeoutMillis = 10000) {
    const bodyElement = document.getElementsByTagName("body")[0];

    const alreadyLoaded = document.querySelector(`script[src='${url}']`);
    if (alreadyLoaded) {
        return;
    }

    const script = document.createElement("script");
    const scriptElement = bodyElement.appendChild(document.createElement("script"));
    scriptElement.setAttribute("src", url);

    await scriptLoadAsync(scriptElement, timeoutMillis);
}

export function getScrollParent(element: Element, includeHidden: boolean = false) {
    var style = getComputedStyle(element);

    var excludeStaticParent = style.position === "absolute";
    var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    if (style.position === "fixed") return document.body;
    for (var parent = element; (parent = parent.parentElement!);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static") {
            continue;
        }
        if (style && style.overflow && overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
    }

    return document.body;
}

export function zeroPad(number: string, length: number): string {

    var str = Number(number) != NaN ? "" + Number(number) : number;
    while (str.length < length) {
        str = '0' + str;
    }

    return str;
}

export function databaseDateTimeToTimestamp(dbDateTime: string): number {
    let dateFirstPartRaw = dbDateTime.split(' ')[0];
    let dateSecondPartRaw = dbDateTime.split(' ')[1];
    let dateFirstPart = dateFirstPartRaw.split('-');
    let dateSecondPart = dateSecondPartRaw.split(':');

    let year: number = Number(dateFirstPart[0]);
    let month: number = Number(dateFirstPart[1]);
    let day: number = Number(dateFirstPart[2]);
    let hour: number = Number(dateSecondPart[0]);
    let minute: number = Number(dateSecondPart[1]);
    let second: number = Number(dateSecondPart[2].split('.')[0]);
    let milliseconds: number = Number(dateSecondPart[2].split('.')[1]);

    return new Date(Date.UTC(year, month, day, hour, minute, second, milliseconds)).getTime();
}

export function formatDateWithoutTime(date: Date | string | undefined | null): string {
    if (!date)
        return "";

    return formatDate(date).substr(0, 13);
}


export function formatDate(date: Date | string | undefined | null): string {
    if (!date)
        return "";

    let rawDate: Date = (date instanceof Date) ? date : parse(date);

    return new Intl.DateTimeFormat('hu', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    }).format(rawDate);
}

export function formatDateOrReturnOriginal(date: Date | string | undefined | null): string {
    if (!date)
        return "";
    try {
        let rawDate: Date;
        if (date instanceof Date) {
            rawDate = date;
        } else {
            rawDate = parse(date);
        }
        return new Intl.DateTimeFormat('hu', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        }).format(rawDate);
    } catch (e) {
        return "" + date;
    }
}


export function formatDateWithSeconds(date: Date | string | undefined | null): string {
    if (!date)
        return "";

    let rawDate: Date = (date instanceof Date) ? date : parse(date);

    return new Intl.DateTimeFormat('hu', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }).format(rawDate);
}

export function formatHourMinute(date: Date | string | undefined | null): string {
    if (!date)
        return "";

    let rawDate: Date = (date instanceof Date) ? date : parse(date);

    return new Intl.DateTimeFormat('hu', {
        hour: '2-digit',
        minute: '2-digit',
    }).format(rawDate);
}

export function formatDateTime(date: Date | string | undefined | null): string {
    if (!date)
        return "";

    let rawDate: Date = (date instanceof Date) ? date : parse(date);

    return new Intl.DateTimeFormat('hu', {
        minute: '2-digit',
        second: '2-digit',
    }).format(rawDate);
}

export const isSameDate = (date1: Date | string | null, date2: Date | string | null) => {
    if (date1 === "") { date1 = null; }
    if (date2 === "") { date2 = null; }
    if (typeof date1 === "string") { date1 = parse(date1); }
    if (typeof date2 === "string") { date2 = parse(date2); }
    if ((date1 === null) !== (date2 === null)) {
        return false;
    } else if (date1 === null && date2 === null) {
        return true;
    } else {
        date1!.setHours(0,0,0,0);
        date2!.setHours(0,0,0,0);
        return date1!.toISOString() === date2!.toISOString();
    }
}

export function addToDate(originalDate: Date | string, plussYears: number, plussMonths: number, plussDays: number): Date {
    let rawDate: Date = (originalDate instanceof Date) ? originalDate : parse(originalDate);
    let newDate: Date = new Date(rawDate.getFullYear() + plussYears, rawDate.getMonth() + plussMonths, rawDate.getDate() + plussDays, rawDate.getHours(), rawDate.getMinutes());
    return newDate;
}

export function formatUserName(uname: string | undefined | null): string {
    if (!uname || uname == undefined || uname == null || uname.length < 1)
        return "";
    return uname.substring(0, uname.indexOf('('));


}
export function emailAddressChecker(email: string): boolean {

    let regexp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email || (email.length < 1) || !regexp.test(email)) {
        return false;
    }
    else return true;

}
export function phoneNumberCheck(phoneNumber: string, isMobil: boolean): boolean {
    //var regexp = new RegExp(/(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})/);
    //[0-9]{1,4}
    let regexp = undefined;
    if (isMobil) {
        regexp = new RegExp(/^(\d{1,4})(-|\s|\\|\/)?(\d{3,4})(-|\s|\\|\/)?(\d{3,4})$/);
    } else {
        regexp = new RegExp(/^(\d{3,4})(-|\s|\\|\/)?(\d{3,4})$/);
    }
    if (!phoneNumber || (phoneNumber.length < 1) || !regexp.test(phoneNumber)) {
        return false;
    }
    else return true;

}

export function roundToDecimals(original_number: number, decimalPlaces: number): number {
    const scale = Math.pow(10, decimalPlaces);
    return Math.round((original_number) * scale) / scale;
}

export async function getMissingFields<TRecord extends IRecord>(crud: typeof Crud, record: TRecord, ignoreFields?: string[]) {

    let missingFields: string[] = [];

    let tableInfo = await crud.getTableInfoForClass();
    tableInfo.fields.forEach((field: IFieldInfo) => {
        if (ignoreFields && ignoreFields.includes(field.field_name)) return;

        const mustHave = !field.is_technical && field.not_null && field.field_name != "owner_id" && field.field_name != "oo_folder_id";
        if (mustHave) {
            let value: any = record[field.field_name];
            if (value !== null && value !== undefined) {
                if ((typeof value) == "string")
                    value = value.trim();
                if (value == '') {
                    value = null;
                }
            }
            if (value === null || value === undefined) {
                const displayName = field.translations[LANG_ID_HU].display_name;
                missingFields.push(displayName);
            }
        }
    });

    return missingFields;
}

export function tableScrollSideBlur(element: HTMLElement) {

    let target = element;

    /* Check if has moved and set helper attributes */
    let checkScMove = (element: HTMLElement, targetEl: HTMLElement) => {
        let scrollW = element.scrollWidth;
        let offsetW = element.offsetWidth;
        let scrollL = element.scrollLeft;

        if (scrollL > 0 && !targetEl.hasAttribute('data-more-left')) {
            targetEl.setAttribute('data-more-left', '');
        } else if (scrollL == 0) {
            targetEl.removeAttribute('data-more-left');
        }

        if (scrollL < (scrollW - offsetW) && !targetEl.hasAttribute('data-more-right')) {
            targetEl.setAttribute('data-more-right', '');
        } else if (scrollL == (scrollW - offsetW)) {
            targetEl.removeAttribute('data-more-right');
        }
    };

    /* Table scrolls extra fade */
    let tableScrolls = target.querySelectorAll('.table-scroll');

    /* if the target is on element with .table-scroll */
    if (target.classList.contains('.table-scroll')) {
        if (target.parentElement != null) {
            let targetParent = target.parentElement;
            tableScrolls = targetParent.querySelectorAll('.table-scroll');
        }
    }

    /* Add wrapper to every table scrolls */
    Array.from(tableScrolls).forEach(function (tableScroll: HTMLElement) {
        let tableScrollParent = tableScroll.parentNode as HTMLElement;
        if (!tableScrollParent.classList.contains('table-scroll-wrap')) {
            let tableScrollWrap = document.createElement('div');
            tableScrollWrap.classList.add('table-scroll-wrap');

            tableScrollParent.insertBefore(tableScrollWrap, tableScroll);
            tableScrollWrap.appendChild(tableScroll);

            checkScMove(tableScroll, tableScrollWrap);

            tableScroll.addEventListener('scroll', function () {
                checkScMove(tableScroll, tableScrollWrap);
            });
        }

    });

}

export function getEnumKeyByValue(object: any, value: any) {
    return Object.keys(object).find(key => object[key] === value);
}
