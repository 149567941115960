import * as React from 'react';
import { debounce } from 'lodash';
import { app } from '@src/index';
import 'react-table/react-table.css'
import TableInfoCrud from '@src/framework/crud/meta/TableInfoCrud';
import LookupEdit from '@src/framework/forms/lookupedit';
import PermissionAuditCrudByTable from './sys_perm_audit_crud_by_table';
import { __ } from '@src/translation';

interface IPermissionAuditByTableProps {

}

interface IPermissionAuditByTableState {
    loading: boolean,
    tableInfoId : number|null;
}

export default class PermissionAuditByTable extends React.Component<IPermissionAuditByTableProps, IPermissionAuditByTableState> {
    private debouncedAsyncReload: () => void;

    constructor(props: IPermissionAuditByTableProps) {
        super(props);
        this.state = {
            loading: true,
            tableInfoId: null
        };
        this.debouncedAsyncReload = debounce(() => this.asyncReload(), 300);
    }

    componentDidMount() {
        this.debouncedAsyncReload();
    }

    private asyncReload = async () => {
        try {
            this.setState({ loading: true });
            // ???
            this.setState({ loading: false });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private onTableInfoIdChange = (tableInfoId: number | null) => {
        this.setState({ tableInfoId }, this.debouncedAsyncReload);
    }
    
    private getReport = () => {
        return <PermissionAuditCrudByTable tableInfoId={this.state.tableInfoId!} />;
    }

    render() {
        return <div className="row expanded">
            <div className="column small-12 large-12">
                <p className="callout primary">
                    {__("Ezen a lapon egy adott táblázatra jogosult felhasználókat")}
                    {__("tudja elemezni.")} <b>{__("Ez nem tartalmazza a vendég (Guest) csoportnak")}
                        {__("megadott jogokat!")}</b>
                </p>
            </div>
            <div className="column small-12 large-12">

                <label>{__("Táblázat:")}
                            <LookupEdit
                                key={"tableInfoId_" + (this.state.tableInfoId || 'null')}
                                name="tableInfoId"
                                fk_table_info_id={TableInfoCrud.TABLE_INFO_ID}
                                clearable={false}
                                value={this.state.tableInfoId!}
                                onChange={(newValue) => this.onTableInfoIdChange(Number(newValue))}
                            />
                </label>
            </div>
            <div className="column small-12 large-12">
                {this.state.tableInfoId
                    ?this.getReport()
                    :<p className="callout alert">
                        {__("Válasszon ki egy táblázatot az adatok elemzéséhez.")}
                    </p>
                }
            </div>
        </div>
    }

}