import * as React from 'react';
import './emojipicker.css';
import { __ } from '@src/translation';

var emojis: Emoji[] = require('./emoji.json');
var groups = [
    "Hangulatjelek és érzelmek",
    "Emberek és testrészek",
    "Komponensek",
    "Állatok és természet",
    "Ételek és italok",
    "Utazás és helyek",
    "Tevékenységek",
    "Tárgyak",
    "Szimbólumok",
    "Zászlók"
];

var skintones = [
    "világos bőrárnyalat",
    "közepesen-világos bőrárnyalat",
    "közepes bőrárnyalat",
    "közepesen-sötét bőrárnyalat",
    "sötét bőrárnyalat"
];

type Emoji = {
    codes: string,
    char: string,
    name: string,
    category: string,
    group: string,
    subgroup: string
}

type EmojiProps = {
    onSelect: (emoji: string) => void;
}

type EmojiState = {
    search?: string;
}

export class EmojiPicker extends React.Component<EmojiProps, EmojiState> {

    constructor(props: EmojiProps) {
        super(props);

        this.state = {
            search: undefined
        }
    }

    render() {
        return <div className="emoji-picker" onClick={(e) => e.stopPropagation()}>
            <input type="text" placeholder="Csak angol keresés" onChange={v => this.setState({ search: v.target.value })}></input>
            {
                groups.map(g => {
                    return <>
                        <h5>
                            {__(g)}
                        </h5>                    {
                            emojis.filter((e: Emoji) => {
                                return e.group == g;
                            }).map(emoji => {
                                if (this.state.search && emoji.name.includes(this.state.search.toLowerCase())) {
                                    //emoji name-et csak akkor fordítsunk, ha magyar lesz mindnek a neve!!!
                                    return <button className="emoji" title={emoji.name} onClick={() => this.props.onSelect(emoji.char)}>{emoji.char}</button>;
                                }
                                else if (!this.state.search) {
                                    return <button className="emoji" title={emoji.name} onClick={() => this.props.onSelect(emoji.char)}>{emoji.char}</button>;
                                } else return;
                            })
                        }

                    </>
                })}

        </div>

    }

}