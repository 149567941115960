import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISecStationPermRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Folyamat állapot
     *
     * NOT NULL -> wf.station ON DELETE cascade
     *
     */
    station_id?: number;
    /**
     * Engedély birtokos
     *
     * NOT NULL -> sys.sec_perm_owner ON DELETE cascade
     *
     */
    sec_perm_owner_id?: number;
    /**
     * Folyamat indítás
     */
    can_start?: boolean;
    /**
     * Fő rekord módosítása
     */
    can_update_master?: boolean;
    /**
     * Kapcsolódó tétel rekord létrehozása
     */
    can_create_detail?: boolean;
    /**
     * Kapcsolódó tétel rekord módosítása
     */
    can_update_detail?: boolean;
    /**
     * Kapcsolódó tétel rekord törlése
     */
    can_delete_detail?: boolean;
    /**
     * Megjegyzés beküldése
     */
    can_comment?: boolean;
    /**
     * Al-státusz módosítása
     */
    can_update_substation?: boolean;
    /**
     * Állapot kiegészítés módosítása
     */
    can_update_status_text?: boolean;
    /**
     * Publikálás
     */
    can_publish?: boolean;
    /**
     * Publikáció törlése
     */
    can_unpublish?: boolean;
}

/**
 *  wf.sec_station_perm - Naplózott
 *
 *  Azt mondja meg, hogy az adott állomáson levő folyamattal ki milyen műveletet tud végezni.
 */

export default class SecStationPermCrud extends Crud<ISecStationPermRecord> {
    public static TABLE_INFO_ID = 2282911766;
    public static getSchemaNameForClass() { return 'wf'; }
    public static getTableNameForClass() { return 'sec_station_perm'; }

    public static load: (id: number, aServer ?: Server) => Promise<SecStationPermCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISecStationPermRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISecStationPermRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISecStationPermRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISecStationPermRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISecStationPermRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SecStationPermCrudClassProxy extends CrudClassProxy<ISecStationPermRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const secStationPermCrudClassProxy = new SecStationPermCrudClassProxy(SecStationPermCrud);
registerCrudClassProxy(secStationPermCrudClassProxy);
