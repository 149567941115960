import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSecUserGroupRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Felhasználói csoport
     */
    group_id?: number;
    /**
     * Alkalmazás szintű felhasználó
     */
    user_id?: number;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Tag (felhasználó)
     *
     * Felhasználó, aki a csoport tagja
     */
    login_name?: string|null;
    /**
     * Személy
     *
     * A csoport taghoz rendelt természetes személy
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * A csoport taghoz rendelt természetes személy teljes neve
     */
    fullname?: string|null;
    /**
     * Csoport név
     *
     * A csoport neve
     */
    group_title?: string|null;
    /**
     * Csoport típus
     *
     * Jogosultság csoport típusa
     */
    group_type_title?: string|null;
}

/**
 *  sys.view_sec_user_group - Csoport tagság (jogosultság)
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewSecUserGroup extends View<IViewSecUserGroupRecord> {
    public static VIEW_INFO_ID = 2018050402;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_sec_user_group'; }
    public static getTableNameForClass(): string|null { return 'sec_user_group'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSecUserGroup>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSecUserGroupRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSecUserGroupClassProxy extends ViewClassProxy<IViewSecUserGroupRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSecUserGroupClassProxy = new ViewSecUserGroupClassProxy(ViewSecUserGroup);
registerViewClassProxy(viewSecUserGroupClassProxy);

