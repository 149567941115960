import * as React from 'react';
import { match } from 'react-router';
import { BubbleLoader } from 'react-css-loaders';
import { getPlayData } from '@src/server/PublicServer';
import ExerciseContainerComponent from '@src/component/exercise/ExerciseContainerComponent';
import { IExerciseRecord } from '@src/framework/crud/exc/ExerciseCrud';
import EngineCRUD from '@crud/exc/EngineCrud';
import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { Link } from 'react-router-dom';
import {EKEQuizConverter} from '@src/component/exercise/engine/EKEQuizExerciseEngine/EKEQuizConverter';
import { EKEFillTableAnswerConverter } from '@src/component/exercise/engine/EKEFillTableDnDExerciseEngine/EKEFillTableAnswerConverter';
import { WMItemToSetConverter } from '@src/component/exercise/engine/WMItemToSetDnDExerciseEngine/WMItemToSetConverter';
import { EKEFillGapAnswerConverter } from '@src/component/exercise/engine/EKEFillGapDnDExerciseEngine/EKEFillGapAnswerConverter';
import { EKESortingConverter } from '@src/component/exercise/engine/EKESortingExerciseEngine/EKESortingConverter';
import { WMItemToImageConverter } from '@src/component/exercise/engine/WMItemToImageDnDExerciseEngine/WMItemToImageConverter';
import { EKEPairingConverter } from '../../component/exercise/engine/EKEPairingExerciseEngine/EKEPairingConverter';
import { __ } from '@src/translation';

export const PATH_PLAY = "/play";

type OldNkpPlayProps = {
    match: match<{ id: string }>
}

type OldNkpPlayState = {
    nkpData: any;
    error?: string;
    exerciseEngineList?: any[],
    exerciseDetails?: any,
    current_engine?: any
}

export default class OldNkpPlay extends React.Component<OldNkpPlayProps, OldNkpPlayState> {

    constructor(props: OldNkpPlayProps) {
        super(props);

        this.state = {
            nkpData: null,
            exerciseDetails: null,
            current_engine: null
        };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    componentDidUpdate(prevProps: OldNkpPlayProps) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            this.reloadAsync();
        }
    }

    async reloadAsync() {
        try {
            const nkpData = await getPlayData(this.props.match.params.id);
            let exerciseEngineList = await EngineCRUD.list({});

            this.setState({ nkpData, exerciseEngineList });
        } catch (e) {
            this.setState({ error: __("Nem sikerült betölteni a tartalmat") });
        }
    }

    render() {
        if (this.state.error) {
            return <div className="row"><h3>{this.state.error}</h3></div>;
        }

        if (!this.state.nkpData) {
            return <BubbleLoader />;
        }

        const type = this.state.nkpData.$type;

        try {
            switch (type) {
                case "NKP.Domain.AssetNs.SCORMAssetVersion, NKP.Domain":
                    return this.renderScorm();

                case "NKP.Domain.AssetNs.WebAppQuestion, NKP.Domain":
                    return this.rendeWebAppQuestion();

                case "NKP.Domain.SlideNs.PdfSlide.PDFPartSlide, NKP.Domain":
                    return this.rendePDFSlide();

                case "NKP.Domain.AssetNs.DownloadableAssetVersion, NKP.Domain":
                    return this.renderDownloadLink();

                case "NKP.Domain.AssetNs.ImageAssetVersion, NKP.Domain":
                    return this.renderImage();

                case "NKP.Domain.SlideNs.WebAppSlide, NKP.Domain":
                    return this.renderWebAppSlide();

                case "NKP.Domain.AssetNs.LinkAssetVersion, NKP.Domain":
                    return this.renderLink();

                case "NKP.Domain.AssetNs.VideoAssetVersion, NKP.Domain":
                    return this.renderVideo();

                case "NKP.Domain.AssetNs.PdfAssetVersion, NKP.Domain":
                    return this.renderPdf();

                case "NKP.Domain.QuestionNs.QuestionAnswerChooseNs.QuestionAnswerChoose, NKP.Domain":
                    return this.renderQuiz();

                case "NKP.Domain.QuestionNs.QuestionTableCompleteNs.QuestionTableComplete, NKP.Domain":
                    return this.renderFillTable();

                case "NKP.Domain.QuestionNs.QuestionAnswerSetNs.QuestionAnswerSet, NKP.Domain":
                    return this.renderItemToSet();

                case "NKP.Domain.QuestionNs.QuestionTextCompleteNs.QuestionTextComplete, NKP.Domain":
                    return this.renderFillGapDnD();

                case "NKP.Domain.QuestionNs.QuestionOrderedNs.QuestionOrdered, NKP.Domain":
                    return this.renderSorting();

                case "NKP.Domain.QuestionNs.QuestionAnswerJoinNs.QuestionAnswerJoin, NKP.Domain":
                    return this.renderPairingQuiz();

                case "NKP.Domain.QuestionNs.QuestionPictureCompleteNs.QuestionPictureComplete, NKP.Domain": {
                    this.renderItemToImage();
                    var exerciseRecord = this.state.exerciseDetails;
                    var curr_engine = this.state.current_engine;

                    if (exerciseRecord) {
                        return < ExerciseContainerComponent ref="container"
                            exerciseRecord={exerciseRecord} engineName={curr_engine.class_name} />;
                    }
                    return "Something went wrong";
                }
                default:
                    return <div style={{ minHeight: "100vh" }}> {__("Ismeretlen tartalom")}</div >;
            }
        } catch (e) {
            console.error(e);
            return <div style={{ minHeight: "100vh" }}>{__("Nem sikerült megjeleníteni a tartalmat")}</div>;
        }

    }


    renderScorm() {
        const scormPackage = this.state.nkpData.SCORMPackages[0];

        const BlobContainer = scormPackage.SCORMPackageContainer.ContainerName;

        const fileName = scormPackage.SCORMOrganizations[0].SCORMOrganizationItems[0].SCORMResource.DefaultSCORMResourceFile.ResourceFileName;

        const src = "/api/getPlayBlob/" + BlobContainer + "/" + fileName;

        return this.renderIframe(src);
        ;
    }

    rendeWebAppQuestion() {
        const WebAppStartFile = this.state.nkpData.ParameterValues[0].StartFileParameter.WebAppStartFile;

        const BlobContainer = WebAppStartFile.WebAppAssetVersion.BlobContainer.ContainerName;

        const fileName = WebAppStartFile.FileName;

        const src = "/api/getPlayBlob/" + BlobContainer + "/" + fileName +
            "?currenttrack=" + this.state.nkpData.ParameterValues[0].StringValue +
            "&currentgame=" + this.state.nkpData.ParameterValues[1].StringValue +
            "&iframe=playerIframe";

        return this.renderIframe(src);
    }

    rendePDFSlide() {
        const ContentVersionReference = this.state.nkpData.ContentVersionReferences[0];

        const BlobContainer = ContentVersionReference.BlobContainer.ContainerName;

        const fileName = ContentVersionReference.UploadedFileName;

        const src = "/api/getPlayBlob/" + BlobContainer + "/" + fileName;

        return <div style={{ margin: "1em" }}>
            <object width="100%" type="application/pdf" data={src} style={{ height: "calc(100vh - 240px)" }}>
                <p>{__("Nem sikerült megjeleníteni a PDF tartalmat")}</p>
            </object>
        </div>;
    }

    renderPdf() {
        const BlobContainer = this.state.nkpData.BlobContainer.ContainerName;

        const fileName = this.state.nkpData.UploadedFileName;

        const src = "/api/getPlayBlob/" + BlobContainer + "/" + fileName;

        return <div style={{ margin: "1em" }}>
            <object width="100%" type="application/pdf" data={src} style={{ height: "calc(100vh - 240px)" }}>
                <p>{__("Nem sikerült megjeleníteni a PDF tartalmat")}</p>
            </object>
        </div>;
    }

    renderQuiz() {
        const oldJson = this.state.nkpData;
        console.log("old json", oldJson);

        if (!this.state.exerciseEngineList)
            return undefined;

        let curr_engine = this.state.exerciseEngineList.find(function (element) { return element.class_name == ExerciseBaseTypes.ExerciseEngineTypes.Quiz });

        let quizConverter = new EKEQuizConverter(null);
        let newJson = quizConverter.convertOldNKPToJson(oldJson);


        var exerciseRecord: IExerciseRecord = {
            engine_id: Number(curr_engine.id),
            subject_id: undefined,
            grade_id: undefined,
            library_id: 1, // TODO ???
            owner_id: 1,   // TODO ???
            exercise: newJson,
            name: newJson.title,
        };

        return <ExerciseContainerComponent ref="container"
            exerciseRecord={exerciseRecord} engineName={curr_engine.class_name} />
    }

    renderFillTable() {
        const oldJson = this.state.nkpData;
        console.log("old json", oldJson);

        if (!this.state.exerciseEngineList)
            return undefined;

        let curr_engine = this.state.exerciseEngineList.find(function (element) { return element.class_name == ExerciseBaseTypes.ExerciseEngineTypes.FillTableDnD });

        let quizConverter = new EKEFillTableAnswerConverter(null);
        let newJson = quizConverter.convertOldNKPToJson(oldJson);


        var exerciseRecord: IExerciseRecord = {
            engine_id: Number(curr_engine.id),
            subject_id: undefined,
            grade_id: undefined,
            library_id: 1, // TODO ???
            owner_id: 1,   // TODO ???
            exercise: newJson,
            name: newJson.title,
        };

        return <ExerciseContainerComponent ref="container"
            exerciseRecord={exerciseRecord} engineName={curr_engine.class_name} />;
    }

    renderItemToSet() {
        const oldJson = this.state.nkpData;
        console.log("old json", oldJson);

        if (!this.state.exerciseEngineList)
            return undefined;

        let curr_engine = this.state.exerciseEngineList.find(function (element) { return element.class_name == ExerciseBaseTypes.ExerciseEngineTypes.ItemToSet });

        let quizConverter = new WMItemToSetConverter(null);
        let newJson = quizConverter.convertOldNKPToJson(oldJson);


        var exerciseRecord: IExerciseRecord = {
            engine_id: Number(curr_engine.id),
            subject_id: undefined,
            grade_id: undefined,
            library_id: 1, // TODO ???
            owner_id: 1,   // TODO ???
            exercise: newJson,
            name: newJson.title,
        };

        return <ExerciseContainerComponent ref="container"
            exerciseRecord={exerciseRecord} engineName={curr_engine.class_name} />;
    }

    renderFillGapDnD() {
        const oldJson = this.state.nkpData;
        console.log("old json", oldJson);

        if (!this.state.exerciseEngineList)
            return undefined;

        let curr_engine = this.state.exerciseEngineList.find(function (element) { return element.class_name == ExerciseBaseTypes.ExerciseEngineTypes.FillGapDnD });

        let quizConverter = new EKEFillGapAnswerConverter(null);
        let newJson = quizConverter.convertOldNKPToJson(oldJson);

        var exerciseRecord: IExerciseRecord = {
            engine_id: Number(curr_engine.id),
            subject_id: undefined,
            grade_id: undefined,
            library_id: 1, // TODO ???
            owner_id: 1,   // TODO ???
            exercise: newJson,
            name: newJson.title,
        };

        return <ExerciseContainerComponent ref="container"
            exerciseRecord={exerciseRecord} engineName={curr_engine.class_name} />;

    }

    renderSorting() {
        const oldJson = this.state.nkpData;
        console.log("old json", oldJson);

        if (!this.state.exerciseEngineList)
            return undefined;

        let curr_engine = this.state.exerciseEngineList.find(function (element) { return element.class_name == ExerciseBaseTypes.ExerciseEngineTypes.Sorting });

        let quizConverter = new EKESortingConverter(null);
        let newJson = quizConverter.convertOldNKPToJson(oldJson);


        var exerciseRecord: IExerciseRecord = {
            engine_id: Number(curr_engine.id),
            subject_id: undefined,
            grade_id: undefined,
            library_id: 1, // TODO ???
            owner_id: 1,   // TODO ???
            exercise: newJson,
            name: newJson.title,
        };

        return <ExerciseContainerComponent ref="container"
            exerciseRecord={exerciseRecord} engineName={curr_engine.class_name} />;

    }

    renderPairingQuiz() {
        const oldJson = this.state.nkpData;
        console.log("old json", oldJson);

        if (!this.state.exerciseEngineList)
            return undefined;

        let curr_engine = this.state.exerciseEngineList.find(function (element) { return element.class_name == ExerciseBaseTypes.ExerciseEngineTypes.PairingQuiz });

        let quizConverter = new EKEPairingConverter(null);
        let newJson = quizConverter.convertOldNKPToJson(oldJson);


        var exerciseRecord: IExerciseRecord = {
            engine_id: Number(curr_engine.id),
            subject_id: undefined,
            grade_id: undefined,
            library_id: 1, // TODO ???
            owner_id: 1,   // TODO ???
            exercise: newJson,
            name: newJson.title,
        };

        return <ExerciseContainerComponent ref="container"
            exerciseRecord={exerciseRecord} engineName={curr_engine.class_name} />;

    }

    async renderItemToImage() {
        const oldJson = this.state.nkpData;

        if (!this.state.exerciseEngineList)
            return;

        let curr_engine = this.state.exerciseEngineList.find(function (element) { return element.class_name == ExerciseBaseTypes.ExerciseEngineTypes.ItemToImage });

        let quizConverter = new WMItemToImageConverter(null);
        let newJson = await quizConverter.convertOldNKPToJson(oldJson, 25369314614);

        var exerciseRecord: IExerciseRecord = {
            engine_id: Number(curr_engine.id),
            subject_id: undefined,
            grade_id: undefined,
            library_id: 1, // TODO ???
            owner_id: 1,   // TODO ???
            exercise: newJson,
            name: newJson.title,
        };

        if (!this.state.exerciseDetails && !this.state.current_engine) {
            this.setState({
                exerciseDetails: exerciseRecord,
                current_engine: curr_engine
            })
        }

    }

    renderDownloadLink() {
        const BlobContainer = this.state.nkpData.BlobContainer.ContainerName;

        const fileName = this.state.nkpData.UploadedFileName;

        const src = "/api/getPlayBlob/" + BlobContainer + "/" + fileName;

        return <div style={{ margin: "1em", textAlign: "center" }}>
            <Link to={src}>
                <p>{__("Letölthető dokumentum. Kattints az ikonra, hogy elinduljon a letöltés.")} </p>
            </Link>
        </div>;
    }

    renderWebAppSlide() {
        const ContentVersionReference = this.state.nkpData.ContentVersionReferences[0];

        const BlobContainer = ContentVersionReference.BlobContainer.ContainerName;

        const fileName = ContentVersionReference.WebAppStartFiles[0].FileName;

        const currentGame = this.state.nkpData.ParameterValues[0].StringValue;

        const src = "/api/getPlayBlob/" + BlobContainer + "/" + fileName + "?currentgame=" + currentGame + "&iframe=playerIframe";

        return <div style={{ textAlign: "center" }}>{this.renderIframe(src)}</div>;
    }

    renderImage() {
        const BlobContainer = this.state.nkpData.BlobContainer.ContainerName;

        const fileName = this.state.nkpData.UploadedFileName;

        const src = "/api/getPlayBlob/" + BlobContainer + "/" + fileName;

        return <div style={{ margin: "1em", textAlign: "center" }}>
            <img src={src} />
        </div>;
    }

    renderLink() {
        return <div style={{ margin: "1em", textAlign: "center", minHeight: "50vh" }}>
            <Link to={this.state.nkpData.Url}>
                {__("Ez egy külső hivatkozás, kattints az ikonra, hogy egy új ablakban megjelenjen az oldal.")}
                </Link>
        </div>;
    }

    renderVideo() {
        const BlobContainer = this.state.nkpData.BlobContainer.ContainerName;

        const fileName = this.state.nkpData.UploadedFileName;

        const src = "/api/getPlayMediaBlob/" + BlobContainer + "/" + fileName;

        return <div style={{ margin: "1em", textAlign: "center" }}>
            <video src={src}
                controls
            />
        </div>;
    }

    renderIframe(src: string) {
        return <div style={{ margin: "1em" }}>
            <h5 style={{ textAlign: "center", margin: "0.5em" }}>{this.state.nkpData.Title}</h5>
            <iframe
                id="playerIframe"
                src={src}
                width="100%"
                height="100%"
                style={{ border: "none", height: "calc(100vh)" }}
            />
        </div>
    }

}