import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import CrudSelect from '@framework/forms//crudselect';
import { subjectCrudClassProxy } from '@framework/crud/doc/SubjectCrud';
import * as Server from '@src/server/PublicServer';
import { ImageSearchResult } from '@src/server/PublicServer';
import { MediaFileSelectedEvent } from '@component/filemanager/MediaFileChooser';
import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';

import { Dialog } from '@src/component/Dialog';
import { FileDataTabs } from '@src/component/filemanager/data/FileDataTabs';
import { __ } from '@src/translation';

interface FileManagerSearchTabProps {
    onFileSelected?: MediaFileSelectedEvent;
}

interface FileManagerSearchTabState {
    keywords: string;
    isGreaticons: boolean;
    isElasticLoading: boolean;
    result: ImageSearchResult[];
    selectedSubject: number | null;
}

export class OFISearchPage extends React.Component<{}, { selectedFile?: IOoFileRecord }> {
    constructor(props: any) {
        super(props);

        this.state = {};
    }

    private onFileSelected = async (fileId: number) => {
        const file = (await OoFileCrud.load(fileId)).record;
        this.setState({ selectedFile: file });
    }

    render() {
        return <div>
            {
                this.state.selectedFile
                    ?
                    <Dialog title={__("Állomány részletei - {details}", {details: this.state.selectedFile.title})} onClose={() => this.setState({ selectedFile: undefined })} width={1000} height={700}>
                        <FileDataTabs
                            file={this.state.selectedFile}
                            onClose={() => this.setState({ selectedFile: undefined })}
                            allowRestoreVersion={false}
                            allowModifications={true}
                        />
                    </Dialog>
                    :
                    ""
            }
            <FileManagerSearchTab
                onFileSelected={this.onFileSelected}
            />
        </div>;
    }
}


export default class FileManagerSearchTab extends React.Component<FileManagerSearchTabProps, FileManagerSearchTabState> {
    constructor(props: FileManagerSearchTabProps) {
        super(props);
        this.state = { keywords: '', isGreaticons: false, isElasticLoading: false, result: [], selectedSubject: null };
    }

    private async onSearchResultClick(searchResult: ImageSearchResult) {
        var oo_file_id = Number(searchResult.id);
        if (this.props.onFileSelected) {
            this.props.onFileSelected(oo_file_id)
        }
    }

    private onKeywordsChange(e: any) {
        this.setState({ keywords: e.target.value });
    }

    private onKeyKeywordsPress(e: any) {
        if (e.key === 'Enter') {
            this.doSearch();
        }
    }

    private onSubjectSelect = (sender: CrudSelect, newSubject: number | null): void => {
        this.setState({ selectedSubject: newSubject }, this.doSearch.bind(this));
    }
    private async doSearch() {
        //limit of searchresult in image elastoics
        this.setState({ isElasticLoading: true });
        const result = await Server.elasticImageSearchForTagger(this.state.keywords, "100", this.state.selectedSubject!);
        this.setState({ result: result, isElasticLoading: false });

    }

    render() {
        const loading = this.state.isElasticLoading;
        return <div>
            <div className="row">
                <div className="shrink column">
                    <label className="middle" htmlFor="image-select-filter">{__("Keresés kulcsszavakra:")}</label>
                </div>
                <div className="column">
                    <input value={this.state.keywords}
                        placeholder={__("Keresés a rendszer összes képe között")}
                        type="text" id="image-select-filter"
                        onKeyPress={this.onKeyKeywordsPress.bind(this)}
                        onChange={this.onKeywordsChange.bind(this)}
                        disabled={loading}
                    />
                </div>
                <div className="column">
                    <CrudSelect
                        value={this.state.selectedSubject}
                        onSelect={this.onSubjectSelect}
                        displayFieldName="name"
                        key="id"
                        emptyTitle={__("Minden tantárgy")}
                        crudClassProxy={subjectCrudClassProxy}
                        filter={{ is_active: true }}
                    />
                </div>

                <div id="elasticsbtn" className="shrink column"><button className="button small middle" onClick={this.doSearch.bind(this)} disabled={loading}>
                    <i className="fa fa-search" /></button>
                </div>
                <div className="shrink column">
                    <button title={this.state.isGreaticons ? __("Kisebb képek") : __("Nagyobb képek")}
                        className="button small middle"
                        onClick={() => this.setState({ isGreaticons: !this.state.isGreaticons })}
                    >
                        {this.state.isGreaticons ?
                            < i className="fa fa-list" /> : <i className="fa fa-th-large" />}
                    </button>
                </div>
            </div>
            <div className="row">
                {this.state.isElasticLoading ? <BubbleLoader /> : ""}
            </div>
            <div>
                {
                    this.state.result.map((imageSearchResult: ImageSearchResult) => {
                        return <SearchResultImageComponent
                            key={imageSearchResult.id}
                            searchResult={imageSearchResult}
                            onClick={this.onSearchResultClick.bind(this)}
                            isGreaticons={this.state.isGreaticons} />
                    })
                }
            </div></div>;
    }
}

interface ISearchResultImageComponentProps {
    searchResult: ImageSearchResult;
    onClick: any;
    isGreaticons: boolean;
}

class SearchResultImageComponent extends React.Component<ISearchResultImageComponentProps, any> {
    render() {
        const searchResult: ImageSearchResult = this.props.searchResult;
        let src = "";
        if (this.props.isGreaticons) { src = "/api/media/thumb/200/" + searchResult.sha1; }
        else { src = "/api/media/thumb/100/" + searchResult.sha1; }
        let keywordsnum;
        if (searchResult.keywords) { keywordsnum = searchResult.keywords.split(",").length; }
        else { keywordsnum = 0; }

        return <span title={searchResult.title} className={"image-selector__wrapper image-selector__wrapper--is-selectable " + (this.props.isGreaticons ? " image-selector__wrapper--is-large" : "")}
            onClick={() => this.props.onClick(this.props.searchResult)}>
            <img draggable={false} className="image-selector__thumbnail--notembedded" src={src} />
            <span className="image-selector__name">{searchResult.title}</span>
            <span className={"badge " + (keywordsnum ? "" : "alert")}>{keywordsnum}</span>
            <span style={{ float: "right" }}>
                <a onClick={e => e.stopPropagation()} className="button secondary tiny" title={__("Kép letöltése")} download={searchResult.title} href={"/api/media/file/" + searchResult.sha1} >
                    <i className="fa fa-download" />
                </a>
            </span>
        </span>
    }
}