import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewBrokenLinkRecord {
    /**
     * Link azonosító
     *
     * Link azonosító
     */
    id?: number;
    /**
     * Fejezet azonosító
     *
     * Fejezet azonosító
     */
    chapter_id?: number|null;
    /**
     * URL
     *
     * URL
     */
    url?: string|null;
    broken_last_checked?: string /*timestamp?*/|null;
    broken_last_check_response_code?: number /*int4*/|null;
    /**
     * Könyv azonosító
     *
     * Könyv azonosító
     */
    book_id?: number|null;
    /**
     * Könyv név
     *
     * Könyv név
     */
    book_name?: string|null;
    /**
     * Törött
     *
     * Törött
     */
    broken?: boolean|null;
    /**
     * Külső
     *
     * Külső
     */
    external?: boolean|null;
    /**
     * Mennyiség
     *
     * Mennyiség
     */
    cnt?: number|null;
}

/**
 *  doc.view_broken_link - Törött linkek
 *
 *  A leckékben szereplő linkek, és ezek HTTP státusza
 */

export default class ViewBrokenLink extends View<IViewBrokenLinkRecord> {
    public static VIEW_INFO_ID = 2018090501;

    public static getSchemaNameForClass(): string { return 'doc'; }
    public static getViewNameForClass(): string { return 'view_broken_link'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewBrokenLink>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewBrokenLinkRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewBrokenLinkClassProxy extends ViewClassProxy<IViewBrokenLinkRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewBrokenLinkClassProxy = new ViewBrokenLinkClassProxy(ViewBrokenLink);
registerViewClassProxy(viewBrokenLinkClassProxy);

