import * as React from 'react';
import { Link } from 'react-router-dom';

import ListView from '@framework/forms/listview';
import { __ } from '@src/translation';

import { IViewInstituteRecord, ViewInstituteClassProxy, viewInstituteClassProxy } from '@src/framework/view/sys/ViewInstitute';
import { viewInstituteSiteClassProxy } from '@src/framework/view/sys/ViewInstituteSite';


class InstituteInner extends ListView<IViewInstituteRecord> {

    public getViewClassProxy() : ViewInstituteClassProxy { return viewInstituteClassProxy; }


    protected getInstituteSiteListButton(record:IViewInstituteRecord) : JSX.Element {
        return (
            <Link
                className="listview-button small" title= {__("Feladatellátási helyek")} 
                to={ viewInstituteSiteClassProxy.getListUrl(
                    {institute_id: record.id!}
            )}>
                <i className="fa fa-building"></i>
            </Link>);
    }           

    protected getRecordButtons(record: IViewInstituteRecord) : JSX.Element[] {
        /* TODO: automatikus detail list menüt ide? */
        return super.getRecordButtons(record)
            .concat(this.getInstituteSiteListButton(record))
        ;
    }


    protected getQuickFilterFieldNames(): string[] {
        return ['om_identifier', 'name', 'in_kir'];
    }    

    
}

export default function InstituteListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <InstituteInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "om_identifier"},
            {accessor: "name"},
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
