import * as React from 'react';
import { IViewSecViewInfoPermRecord, ViewSecViewInfoPermClassProxy, viewSecViewInfoPermClassProxy } from '@src/framework/view/meta/ViewSecViewInfoPerm';
import ListView from '@framework/forms/listview';

import { app } from '@src/index';

import LookupEdit from '@framework/forms/lookupedit';
import SecViewInfoPermCrud from '@framework/crud/meta/SecViewInfoPermCrud';
import SecPermOwnerCrud from '@src/framework/crud/sys/SecPermOwnerCrud';
import { Column } from 'react-table';
import { __ } from '@src/translation';

const FLAG_SAVING = -1;


class SecViewInfoPermInner extends ListView<IViewSecViewInfoPermRecord> {
    public getViewClassProxy(): ViewSecViewInfoPermClassProxy { return viewSecViewInfoPermClassProxy; }
    protected getFilterControls(): JSX.Element | null { return null; }
    
    protected getRecordButtons(record: IViewSecViewInfoPermRecord): JSX.Element[] {
        return [];
    }

    protected convertRecord = (record: IViewSecViewInfoPermRecord): IViewSecViewInfoPermRecord => {
        return record;
    }

    protected onCellClick(event: any, finalState: any, rowInfo: any, column?: Column, instance?: any) {
        if (column && column.id) {
            if (["can_read"].includes(column.id)) {
                const fname = column.id;
                const idx = rowInfo.index;
                const row = this.state.items![idx];
                const flag = row[fname];

                let items = this.state.items!.slice();
                items[idx] = Object.assign({}, items[idx], { [fname]: FLAG_SAVING });
                this.setState({ items });

                new SecViewInfoPermCrud({ id: row.id, [fname]: !flag })
                    .put()
                    .then((crud) => {
                        let items = this.state.items!.slice();
                        // Ilyet csak azért lehet, mert a convertRecord ebben
                        // a konkrét osztályban nem csinál semmit!
                        items[idx] = Object.assign({}, items[idx], crud.record);
                        this.setState({ items });
                        if (flag) {
                            app.showSuccess(__("Sikeres beállítás"), __("Az engedély visszavonása sikeres volt."));
                        } else {
                            app.showSuccess(__("Sikeres beállítás"), __("Az engedély kiadása sikeres volt."));
                        }
                    })
                    .catch((error) => { app.showErrorFromJsonResult(error); });

            }
        }
    }

}

export interface ISecViewInfoPermListViewForViewInfoProps {
    view_info_id: number;
}

export interface ISecViewInfoPermListViewForViewInfoState {
    permOwnerIdToAdd: number | null;
    refreshKey: number
}

export class SecViewInfoPermListView extends React.Component<ISecViewInfoPermListViewForViewInfoProps, ISecViewInfoPermListViewForViewInfoState> {

    constructor(props: ISecViewInfoPermListViewForViewInfoProps) {
        super(props);
        this.state = { permOwnerIdToAdd: null, refreshKey: 0 };
    }

    private onPermOwnerIdChange = (newValue: any) => {
        if ((typeof newValue === 'string'))
            if (newValue.trim() === '')
                newValue = '';
        if (newValue === '')
            newValue = null;
        let newState = {
            permOwnerIdToAdd: newValue
        };
        this.setState(newState);
    }

    private onAddGroup = () => {
        new SecViewInfoPermCrud({
            view_info_id: this.props.view_info_id,
            sec_perm_owner_id: this.state.permOwnerIdToAdd!
        }).put()
            .then((crud) => {
                this.setState({
                    permOwnerIdToAdd: null, refreshKey: this.state.refreshKey + 1
                }, this.forceUpdate)
            })
            .catch((error) => { app.showErrorFromJsonResult(error) })
    }

    private crudFlag(tblRow: any, colName: string): JSX.Element {
        const row = tblRow.row;
        if (row[colName] == FLAG_SAVING) {
            return <i className="fa fa-hourglass" />;
        } else if (row[colName]) {
            return <i className="fa fa-check-square" />;
        } else {
            return <i className="fa fa-square" />;
        }
    }

    private flagAccessor(colName: string, title: string) {
        return {
            accessor: colName, maxWidth: 50,
            title: title,
            style: { textAlign: "center" },
            Cell: (tblRow: any) => {
                return this.crudFlag(tblRow, colName)
            }
        };
    }

    public render() {
        const value: any = this.state.permOwnerIdToAdd;
        return (
            <div>
                <div className="row">
                    <div className="column small-8 medium-8">
                        <LookupEdit
                            key={"permOwnerIdToAdd" + (this.state.permOwnerIdToAdd || 'null')}
                            name="permOwnerIdToAdd"
                            fk_table_info_id={SecPermOwnerCrud.TABLE_INFO_ID}
                            clearable={true}
                            value={value}
                            onChange={(newValue) => this.onPermOwnerIdChange(newValue)}
                        />
                    </div>
                    <div className="column small-4 medium-4">
                        <button type="button" className="button primary"
                            disabled={!this.state.permOwnerIdToAdd}
                            onClick={this.onAddGroup}
                        >
                            <i className="fa fa-plus" />
                            { __("Hozzáad") }
                    </button>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 medium-12">
                        <SecViewInfoPermInner
                            key={this.state.refreshKey}
                            allowInsertNew={false}
                            defaultPageSize={10}
                            filter={{ view_info_id: this.props.view_info_id }}
                            viewColumns={[
                                { accessor: "id", maxWidth: 100 },
                                { accessor: "perm_owner_label" },
                                this.flagAccessor("can_read", "R"),
                                /*
                                {accessor: "creation_time"},
                                {accessor: "creator"},
                                {accessor: "modification_time"},
                                {accessor: "modifier"},
                                { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
                                */
                            ]
                            }
                        />
                    </div>
                </div>
            </div>);
    }

}

