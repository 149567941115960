import * as React from 'react';
import * as _ from 'lodash';
import { app } from '@src/index';
import WfAPI, { IWorfklowTODOSummaryRecord } from '@src/framework/wf/WfAPI';
import obtainServer from '@src/framework/server/Server';
import { IViewWfWorkflowRecord, viewWfWorkflowClassProxy } from '@src/framework/view/wf/ViewWfWorkflow';
import { Link } from 'react-router-dom';
import { me } from '@src/framework/server/Auth';
import { PATH_PROFILE, PATH_SHARED_CONTENT_WITH_ME, PATH_CONTENT_STORE_SHARED_WITH_ME } from '@src/Routes';
import ViewInstituteSiteTeacher, { IViewInstituteSiteTeacherRecord } from '@src/framework/view/sys/ViewInstituteSiteTeacher';
import { InstituteSiteTeacherStates } from './institute/sys_institute_site_teacher_listview';
import ViewInstituteGroupMember, { IViewInstituteGroupMemberRecord } from '@src/framework/view/sys/ViewInstituteGroupMember';
import { InstituteGroupMemberStates } from './institute/InstituteGroupMemberList';
import ViewSharedWithMe, { IViewSharedWithMeRecord } from '@src/framework/view/usr/ViewSharedWithMe';
import MemberCard from '@src/framework/forms/MemberCard';
import { formatDate } from '@src/Util';
import { SHARE_MEGOSZTAS_ID } from '@src/Const';
import SharedContentRunCrud, { ISharedContentRunRecord } from '@src/framework/crud/usr/SharedContentRunCrud';
import { __ } from '@src/translation';
import { IMessengerNotificationInfo } from '@src/module/messengerModule/messengerAPI';
import { messengerModule } from '@src/module/messengerModule/messengerModule';
import { config } from '@src/framework/server/Server';
import { classroomModule } from '@src/module/classroomModule/classroomModule';
import SharedContentMemberCrud, { ISharedContentMemberRecord } from '@src/framework/crud/usr/SharedContentMemberCrud';
import * as Scrollbar from 'react-smooth-scrollbar';

// 1 percenként frissítjük az értesítéseket.
export const HEADER_NOTIFICATION_REFRESH_INTERVAL_MSEC = 5 * 60 * 1000;
export const RELOAD_DEBOUNCE_MSEC = 3000;

export interface IHeaderNotificationProps {
    isMobile: boolean;
    className?: string;
    onClick?: ()=> void;
}

export interface IHeaderNotificationState {
}

export type HeaderNotificationEventListener = (sender: HeaderNotificationStore) => void;

export interface IAnnounceMessage {
    message: string | null;
    creator: string | null;
    when: string | null;
}

export interface IAnnounceMessages {
    anonymMessage : IAnnounceMessage | null;
    registeredMessage : IAnnounceMessage | null;
}

export const getAnnounceMessages = async () : Promise<IAnnounceMessages> => {
    return obtainServer().get<IAnnounceMessages>('announce');
    // TODO: update notification store here, but first we need to separate fetch and state update parts.
}

export const setAnnounceMessage = async (kind: "anonym"|"registered", message: string|null) : Promise<IAnnounceMessages> => {
    const result = await obtainServer().put<IAnnounceMessages>('announce', {kind, message});
    // TODO: update notification store here, but first we need to separate fetch and state update parts.
    const store = HeaderNotificationStore.get();
    store.registeredAnnounceMessage = result.registeredMessage;
    store.anonymAnnounceMessage = result.anonymMessage;
    HeaderNotificationStore.changed();
    return result;
}

/**
 * Ez tölti le az értesítéseket a szerverről.
 * Egyetlen egy lehet belőle az egész alkalmazásban.
 * 
 */

export class HeaderNotificationStore {
    private static instance?: HeaderNotificationStore = undefined;
    private debouncedAsyncReload: any = null;
    private listeners: HeaderNotificationEventListener[] = [];

    /* Ez a nyilvános rész, a listener-ek ezt használják. */
    wfTodos: IWorfklowTODOSummaryRecord[] = [];
    invitations: IViewInstituteGroupMemberRecord[] = [];
    teacherInvitations: IViewInstituteSiteTeacherRecord[] = [];
    sharedWithMe: IViewSharedWithMeRecord[] = [];
    sharedContentRuns: ISharedContentRunRecord[] = [];
    unseenShareCount: number = 0;
    anonymAnnounceMessage: IAnnounceMessage | null = null;
    registeredAnnounceMessage: IAnnounceMessage | null = null;
    messengerNotificationInfo: IMessengerNotificationInfo | null = null;
    emailConfirmMessage: string | null = null;
    creator: string | null = null;
    when: string | null = null;
    wfTimerId: any = null;
    
    _prevCount: number = 0;

    public static get = () => {
        if (HeaderNotificationStore.instance === undefined) {
            new HeaderNotificationStore();
        }
        return HeaderNotificationStore.instance!;
    }

    private constructor() {
        //here
        if (HeaderNotificationStore.instance !== undefined) {
            throw new Error(__("HeaderNotificationStore: ebből csak egy példányt hozhatsz létre!"));
        }
        HeaderNotificationStore.instance = this;

        WfAPI.addStartListener(this.onWorkflowStarted);
        WfAPI.addTransitionListener(this.afterWorkflowTransition);
        WfAPI.addAgentAddedListener(this.onAgentAdded);
        WfAPI.addAgentRemovedListener(this.onAgentRemoved);



        this.debouncedAsyncReload = _.debounce(this.asyncReload, RELOAD_DEBOUNCE_MSEC);
        this.debouncedAsyncReload();
    }

    public static changed = () => {
        HeaderNotificationStore.get().debouncedAsyncReload();
    }

    private onWorkflowStarted = (workflow: IViewWfWorkflowRecord) => {
        this.debouncedAsyncReload();
    }

    private afterWorkflowTransition = (
        workflowId: number,
        transitonTypeId: number,
        justification: string | null,
        workflowAfterTransition: IViewWfWorkflowRecord
    ) => {
        this.debouncedAsyncReload();
    }

    private onAgentAdded = (workflow: IViewWfWorkflowRecord, permOwnerId: number) => {
        this.debouncedAsyncReload();
    }

    private onAgentRemoved = (workflow: IViewWfWorkflowRecord, permOwnerId: number) => {
        this.debouncedAsyncReload();
    }


    public addListener = (listener: HeaderNotificationEventListener) => {
        this.listeners.push(listener);
    }

    public removeListener = (listener: HeaderNotificationEventListener): boolean => {
        let index = this.listeners.indexOf(listener);
        if (index >= 0) {
            this.listeners.splice(index, 1);
            return true;
        } else {
            return false;
        }
    }

    // Note: you should only call this from outside if you have already changed the store's state,
    // from outside.
    public notifyListeners = () => {
        this.listeners.forEach((listener) => { listener(this); });
    }

    public get count() : number {
        let result = this.wfTodos.length 
            + this.invitations.length 
            + this.teacherInvitations.length
            + this.sharedContentRuns.filter(s => !s.acknowledged).length 
            + this.unseenShareCount;

        if (this.messengerNotificationInfo) {
            result += this.messengerNotificationInfo.unseen_cnt;
        }
        const announceMessage = me ? this.registeredAnnounceMessage : this.anonymAnnounceMessage;
        if (announceMessage && announceMessage.message) {
            result += 1;
        }
        if (this.emailConfirmMessage!==null) {
            result += 1;
        }
        return result;
    }

    private asyncReload = async () => {
        try {
            let wfTodos: IWorfklowTODOSummaryRecord[] = [];
            if (me && me.id) {
                const api = new WfAPI(obtainServer());
                wfTodos = await api.listTODOSummary();
            }

            let invitations: IViewInstituteGroupMemberRecord[] = [];
            if (me && me.id) {
                invitations = await ViewInstituteGroupMember.list({
                    filter: {
                        sec_user_id: me.id,
                        is_active: true,
                        status_id: InstituteGroupMemberStates.INVITATION_SENT_ID
                    },
                    order_by: "group_name"
                });
            }

            let teacherInvitations: IViewInstituteSiteTeacherRecord[] = [];
            if (me && me.id) {
                teacherInvitations = await ViewInstituteSiteTeacher.list({
                    filter: { sec_user_id: me!.id, status_id: InstituteSiteTeacherStates.INVITATION_SENT_ID },
                    order_by: "institute_site_name"
                })
            }

            let sharedContentRuns: ISharedContentRunRecord[] = [];
            if (me && me.id) {
                sharedContentRuns = await SharedContentRunCrud.list({
                    filter: { run_by_id: me!.id, "$notnull": "shared_by_comments_html" },
                    order_by: "creation_time"
                })
                for (var sharedContentRunRec of sharedContentRuns) {

                    const commentsDiv = document.createElement("div");
                    commentsDiv.innerHTML = sharedContentRunRec.shared_by_comments_html || "";
                    sharedContentRunRec["shared_by_comments_html"] = commentsDiv.innerText;
                }
            }

            let messengerNotificationInfo: IMessengerNotificationInfo | null = null;
            if (me && me.id && messengerModule) {
                messengerNotificationInfo = await messengerModule.getNotificationInfo(config.mainServer.siteId || undefined);
            }

            let showSiteNotificationPopup: boolean = false;
            const announceMessages: IAnnounceMessages = await getAnnounceMessages();
            const anonymAnnounceMessage = announceMessages.anonymMessage;
            const registeredAnnounceMessage = announceMessages.registeredMessage;

            const announceMessage = me ? registeredAnnounceMessage : anonymAnnounceMessage;
            const prevAnnounceMessage = me ? this.registeredAnnounceMessage : this.anonymAnnounceMessage;

            if (announceMessage && announceMessage.message) {
                if (!prevAnnounceMessage || (prevAnnounceMessage && prevAnnounceMessage.message != announceMessage.message)) {
                    // Megnézzük, hogy a localStore-ben benne van-e már.
                    // Ha igen, akkor ezt a user már ACK-zta, ezért nem jelenítjük
                    // meg újra.
                    const ackMessage = localStorage.getItem("site.notification.ack");
                    if (!ackMessage || ackMessage != announceMessage.message) {
                        showSiteNotificationPopup = true;
                    }
                }
            }


            let sharedWithMe: IViewSharedWithMeRecord[] = [];
            if (me && me.id) {
                const groups = await ViewInstituteGroupMember.list({
                    filter: {
                        sec_user_id: me!.id,
                        is_active: true,
                        is_admin: true
                    }
                });
                const groupIds = groups.map((el) => el.institute_group_id!);
                sharedWithMe = await ViewSharedWithMe.list({
                    filter: { is_active: true, "$not": { shared_from_institute_group_id: groupIds }, notification_acknowledged: null },
                    order_by: [{ name: "notification_created", desc: true }],
                    limit: 20
                });
            }
            let unseenShareCount = sharedWithMe.length; // sharedWithMe.filter((item)=> !item.notification_acknowledged).length;

            if (me && me.email && !me.email_confirmed) {
                this.emailConfirmMessage = __("Megerősítetlen e-mail cím.");
            } else {
                this.emailConfirmMessage = null;
            }


            const wfTimerId = setTimeout(this.debouncedAsyncReload, HEADER_NOTIFICATION_REFRESH_INTERVAL_MSEC);
            this.wfTodos = wfTodos;
            this.invitations = invitations;
            this.teacherInvitations = teacherInvitations;
            this.sharedContentRuns = sharedContentRuns;
            this.sharedWithMe = sharedWithMe;
            this.unseenShareCount = unseenShareCount;
            this.wfTimerId = wfTimerId;
            this.anonymAnnounceMessage = anonymAnnounceMessage;
            this.registeredAnnounceMessage = registeredAnnounceMessage;
            this.messengerNotificationInfo = messengerNotificationInfo;

            const cnt = this.count;
            const countChanged = this._prevCount != cnt;
            this._prevCount = cnt;
            const playNotification = (cnt > 0) && countChanged;

            if (showSiteNotificationPopup) {
                let audio = new Audio('/audio/site_announce.mp3');
                audio.play();
            } else if (playNotification) {
                // let audio = new Audio('/audio/notify.mp3');
                // audio.play();
            }
            if (showSiteNotificationPopup && announceMessage) {
                app.addNotification({
                    title: __("Hirdetmény"),
                    level: "warning",
                    position: "tc",
                    autoDismiss: 100,
                    dismissible: true,
                    children: <div className="site-announce">
                        <i className="fa fa-bullhorn" />
                        {announceMessage.message}
                        { /*
                                    announceMessage.creator?
                                    <small>
                                        <br/>
                                        {announceMessage.creator} @ {announceMessage.when}
                                    </small>
                                    :null
                                */ }
                    </div>,
                    onRemove: (notification) => {
                        localStorage.setItem(
                            "site.notification.ack",
                            announceMessage!.message!
                        )
                    }
                })
            }
            this.notifyListeners();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

}

export default class HeaderNotification extends React.Component<IHeaderNotificationProps, IHeaderNotificationState> {
    constructor(props: IHeaderNotificationProps) {
        super(props);
        this.state = {};
    }

    private onHeaderNotification = (sender: HeaderNotificationStore) => {
        this.forceUpdate();
    }

    componentDidMount() {
        HeaderNotificationStore.get().addListener(this.onHeaderNotification)
    }

    componentWillUnmount() {
        HeaderNotificationStore.get().removeListener(this.onHeaderNotification)
    }

    private wfClick = () => {
        ($("#otkNotifications") as any).foundation('close');
    }

    private acknowledgeSharedWithMeNotification = async (index: number) => {
        const store = HeaderNotificationStore.get();
        const shared: IViewSharedWithMeRecord = store.sharedWithMe[index];
        if (!shared.notification_acknowledged) {
            store.sharedWithMe[index]["loading"] = true;
            try {
                const cm_id = shared.shared_content_member_id;
                const data = {
                    sec_user_id: me!.id!,
                    shared_content_id: shared.id!,
                    acknowledged: "now"
                }
                const response: ISharedContentMemberRecord = (await new SharedContentMemberCrud(
                    data).upsert(['sec_user_id','shared_content_id'])).record;
                store.sharedWithMe[index]["loading"] = false;
                store.sharedWithMe[index].notification_acknowledged = response.acknowledged;
                store.unseenShareCount -= 1;
                HeaderNotificationStore.get().notifyListeners();
            } catch (e) {
                app.showErrorFromJsonResult(e);
            }
        }
    }


    private acknowledgeSharedContentRunNotification = async (index: number) => {
        const store = HeaderNotificationStore.get();
        const scRun: ISharedContentRunRecord = store.sharedContentRuns[index];
        if (!scRun.acknowledged) {
            store.sharedContentRuns[index]["loading"] = true;
            try {
                let response: ISharedContentRunRecord;
                response = (await new SharedContentRunCrud({ id: scRun.id!, acknowledged: "now" }).put()).record;
                store.sharedContentRuns[index]["loading"] = false;
                store.sharedContentRuns[index].acknowledged = response.acknowledged;
                store.unseenShareCount -= 1;
                HeaderNotificationStore.get().notifyListeners();
            } catch (e) {
                app.showErrorFromJsonResult(e);
            }
        }
    }



    render() {
        const store = HeaderNotificationStore.get();
        const isActive: boolean = store.count > 0;
        const counter = store.count > 99 ? "99+" : store.count;
        const className = "eke-notifications" + (isActive ? " active" : "");
        const dataToggle = this.props.isMobile ? "otkNotificationsMobile" : "otkNotifications";
        let items: JSX.Element[] = [];

        if (store.registeredAnnounceMessage && store.registeredAnnounceMessage.message) {
            items.push(<li key="announce">
                <div className="content announce"
                    title={"" + store.registeredAnnounceMessage.creator + " @ " + store.registeredAnnounceMessage.when}
                >
                    <div>
                        <span className="todo-count" style={{ backgroundColor: "red", color: "white" }}>
                            <i className="fa fa-bullhorn" />
                        </span>
                    </div>
                    <div className="todo">
                        {store.registeredAnnounceMessage.message}
                    </div>
                </div>
            </li>);
        }

        if (store.wfTodos.length > 0) {
            // TODO: mi van akkor ha tényleg 99 eleme van. Kifér az a képernyőre?
            for (let i = 0; i < store.wfTodos.length; i++) {
                const todo = store.wfTodos[i];
                items.push(<li key={todo.wf_type_code + "_" + todo.station_id}>
                    <Link onClick={() => this.wfClick()} to={viewWfWorkflowClassProxy.getListUrl(
                        { wf_type_id: todo.wf_type_id },
                        { only_mine: true, can_change_station: true }
                    )}

                    >

                        <div>
                            <span className="todo-count" style={todo.station_style!}>{todo.cnt}</span>
                        </div>
                        <div className="todo">
                            {todo.wf_type_name} - {todo.station_name}
                        </div>

                    </Link>
                </li>);
            }
        }

        if (store.emailConfirmMessage) {
            items.push(
                <li key="email_not_confirmed"
                    title={__("Ha nincs megerősítve az e-mail címe, és elfelejti a jelszavát, akkor kizárja magát a rendszerből.")}>
                    <Link to={PATH_PROFILE + "#email_confim"}>
                        <div>
                            <span className="todo-count">
                                <i className="fa fa-envelope-open" />
                            </span>
                        </div>
                        {__("Nincs megerősítve az e-mail címe.")}
                    </Link>
                </li>);
        }


        if (classroomModule && store.invitations.length) {
            items.push(
                <li key="group_invitations"
                    title={__("Meghívták egy vagy több csoportba.")}>
                    <Link to={classroomModule.PATH_CLASSROOM_LIST}>
                        <div>
                            <span className="todo-count">
                                {store.invitations.length}
                                &nbsp;
                                <i className="fa fa-user-plus" />
                            </span>
                        </div>
                        {__("Csoport meghívója érkezett")}
                    </Link>
                </li>);
        }

        if (store.teacherInvitations.length) {
            items.push(
                <li key="teacher_invitations"
                    title={__("Meghívták egy vagy több intézmény/feladat ellátási hely tanárai közé.")}>
                    <Link to={PATH_PROFILE + "#teacher_invitation"}>
                        <div>
                            <span className="todo-count">
                                {store.teacherInvitations.length}
                                &nbsp;
                                <i className="fa fa-chalkboard-teacher" />
                            </span>
                        </div>
                        {__("Felkérték tanárnak")}
                    </Link>
                </li>);
        }

        if (messengerModule) {
            if (store.messengerNotificationInfo && store.messengerNotificationInfo.unseen_cnt) {
                items.push(
                    <li key="unseen_messages"
                        title={__("Üzenete érkezett.")}>
                        <Link to={messengerModule.PATH_MESSAGES_IN}>
                            <div>
                                <span className="todo-count">
                                    {store.messengerNotificationInfo.unseen_cnt}
                                    &nbsp;
                                    <i className="fa fa-paperplane" />
                                </span>
                            </div>
                            {__("Üzenete érkezett")}
                        </Link>
                    </li>);
            }
        }

        /*

        Zsoltinak!

        share.creation_time - mikor jött létre a megosztás
        share.notification_created - mikor jött létre az értesítés a megosztásról
        share.notification_acknowledged - a user mikor nézte meg a megosztást,
            ha ez üres akkor még nem nézte meg
        share.shared_by* - ki osztotta meg, de erre van a MemberCard amiben majd a profil képe is benne lesz
        share.share_mode_title - megosztás típusa (megosztás, gyakorló kiosztás, vizsga kiosztás)
        share.table_display_name - a megoszott tartalom típusa (feladat, feladatsor, állomány stb)
        share.title - a megosztáskor megadott cím (a megosztó tölti ki), kötelező
        share.description - a megosztáskor megadott leírás, nem kötelező
        share.displayvalue - a megosztott dolog szöveges neve (például ha feladatot osztott meg, akkor a feladat neve)

        Hogy nézzen ez ki???

        További kérdés, hogy hová mutasson a link innen, de ezt majd még kitaláljuk.

        */
        store.sharedWithMe.forEach((share: IViewSharedWithMeRecord, index: number) => {
            // className={share.notification_acknowledged?"":"new"}
            if (!share.notification_acknowledged) {
                items.push(<li key={share.id} >
                    <div className="row">
                        <div className="small-10 column">
                            <Link className={"content"} to={share.share_mode_id === SHARE_MEGOSZTAS_ID ? PATH_CONTENT_STORE_SHARED_WITH_ME : PATH_SHARED_CONTENT_WITH_ME + "/" + share.id}
                                title={share.table_display_name || ""}>

                                <div>
                                    <span className="todo-count"><i className="fa fa-share-alt" /></span>
                                </div>
                                <div className="todo">

                                    <small>
                                        {share.share_mode_title}&nbsp;
                                    {formatDate(share.creation_time)}
                                    </small>
                                    <br />
                                    <small>
                                        <MemberCard {...share} memberFieldNamePrefix="shared_by_" short={true} /> - {share.title}
                                        {/* {share.notification_acknowledged?null:<>&nbsp;<label className="label primary">Új</label></>} */}
                                    </small>
                                </div>
                            </Link>
                        </div>
                        <div className="small-2 column delete-notification">
                            <i className={
                                /* igen ez nagyon ronda, de ennyi idő alatt mit akarunk? */
                                "fa " + (share["loading"] ? "fa-hourglass" : "fa-times")
                            }
                                title={__("Értesítés elrejtése")}
                                onClick={() => this.acknowledgeSharedWithMeNotification(index)}
                            />

                        </div>
                    </div>
                </li>)
            }
        });

        store.sharedContentRuns.forEach((sharedcontentRun: ISharedContentRunRecord, index: number) => {
            if (!sharedcontentRun.acknowledged) {
                items.push(<li key={sharedcontentRun.id}>
                    <div className="row">
                        <div className="small-10 column">

                            <Link className={"content"} to={PATH_SHARED_CONTENT_WITH_ME + "/" + sharedcontentRun.shared_content_id}
                                title={__("Feladat szöveges értékelése")}>

                                <div>
                                    <span className="todo-count"><i className="fa fa-graduation-cap" /></span>
                                </div>
                                <div className="todo" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>

                                    <small>
                                        {sharedcontentRun.shared_by_comments_html}
                                        <br />
                                        {formatDate(sharedcontentRun.creation_time)}
                                    </small><br />
                                    {
                                        sharedcontentRun.shared_by_success_percent ?
                                            <small>
                                                {__("A megoldásod alapján a tudásod")}
                                                {" " + Math.round(sharedcontentRun.shared_by_success_percent * 100) + __("% ra lett értékelve.")}
                                            </small>
                                            : ""
                                    }

                                    <br />
                                </div>
                            </Link>
                        </div>
                        <div className="small-2 column delete-notification">
                            <i className={
                                /* igen ez nagyon ronda, de ennyi idő alatt mit akarunk? */
                                "fa " + (sharedcontentRun["loading"] ? "fa-hourglass" : "fa-times")
                            }
                                title={__("Értesítés elrejtése")}
                                onClick={() => this.acknowledgeSharedContentRunNotification(index)}
                            />
                        </div>
                    </div>
                </li>)
            }
        });

        if (items.length == 0) {
            items.push(<li key={0}><span className="content">{__("Nincsenek értesítések")}</span></li>)
        }

        return <>
            <button title={__("Értesítések")} className={className + (this.props.className ? (" " + this.props.className) : "") + (this.props.isMobile ? "" : " hide-for-small-only")} data-toggle={dataToggle}>
                <span className="show-for-sr">{__("Értesítések")}</span>
                <i className="fa fa-bell"></i>
                <span className="counter">{counter}</span>
            </button>
            {this.props.isMobile ?
                <div className="off-canvas-wrapper">
                    <div className="off-canvas position-bottom eke-notifications-pane" id="otkNotificationsMobile" data-off-canvas>
                        <Scrollbar style={{ maxHeight: "50vh", width: "100%" }}>
                            <h3 className="notifications-pane-title">{__("Értesítések")}</h3>
                            <ul>{items}</ul>
                        </Scrollbar>
                    </div>
                </div>
                :
                <div onClick={this.props.onClick} className="dropdown-pane eke-notifications-pane" data-position="bottom" data-alignment="right" id="otkNotifications" data-dropdown data-auto-focus="true" data-close-on-click="true">
                    <Scrollbar style={{ maxHeight: "50vh", width: "100%" }}>
                        <h3 className="notifications-pane-title">{__("Értesítések")}</h3>
                        <ul>{items}</ul>
                    </Scrollbar>
                </div>
            }
        </>;
    }
}

// Fejléc 
HeaderNotificationStore.get();
