import * as React from 'react';
import axios from 'axios';
import { loadBodymovinScriptAsync, loadBodymovinScriptAsync2 } from '@src/Util';

type BookSelectorSubjectIconProps = {
    uriSegment: string;
    sizePx?: number;
    needAnimation:boolean;
    extension: ".svg" | ".png";
}

type BookSelectorSubjectIconState = {
    animate: boolean;
    animObject?: any;
}

declare var bodymovin: any;

export default class BookSelectorSubjectIcon extends React.Component<BookSelectorSubjectIconProps, BookSelectorSubjectIconState> {

    private data?: any;

    constructor(props: any) {
        super(props);

        this.state = {
            animate: false,
        }
    }

    async componentDidUpdate() {
        if (this.state.animate) {

            await loadBodymovinScriptAsync();
            await loadBodymovinScriptAsync2();

            if (this.data === undefined) {
                try {

                    const response = await axios.get("/img/IKON_SET/TANKONYVLOGOK/" + this.props.uriSegment + ".json");
                    
                    if (typeof response.data === 'object') {
                        this.data = response.data;
                    } else {
                        this.data = false;
                    }

                } catch(e) {

                    this.data = false;
                }
            }

            if (this.data !== false && this.state.animate && !this.state.animObject &&this.props.needAnimation) {
                
                if (!this.refs.animateDiv) return;

                var params = {
                    container: this.refs.animateDiv,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    animationData: this.data
                };
                if(this.props.extension==".svg")
                {
                    try {
                        const animObject = bodymovin.loadAnimation(params);
                        this.setState({animObject});
                    } catch {
                        this.data = false;
                        return;
                    }
                }     
            }

            if (this.state.animObject && this.state.animObject.isPaused) {
                this.state.animObject.play();
            }

        } else {
            if (this.state.animObject) {
                this.state.animObject.pause();
            }
        }
    }

    onMouseOver() {
        this.setState({animate: true});
    }

    onMouseOut() {
        this.setState({animate: false});
    }

    render() {
        const sizePx = this.props.sizePx || 90;
        const extension = this.props.extension || ".svg";

        return <div onMouseOut={this.onMouseOut.bind(this)} onMouseOver={this.onMouseOver.bind(this)}>
                
                <div ref="animateDiv" style={{height: this.props.sizePx == 0 ? "auto" : sizePx + "px" , display: this.state.animObject ? undefined : "none"}}></div>
                <img style={{width: this.props.sizePx == 0 ? "auto" : sizePx + "px" , display: this.state.animObject ? "none" : undefined}} src={"/img/IKON_SET/TANKONYVLOGOK/" + this.props.uriSegment + extension} onError={(e) => (e.target as any).style.visibility="hidden"}/>
                
            </div>;
    }
}
