import * as React from 'react';

import { viewBadWordGlobalClassProxy } from '@src/framework/view/argo/ViewBadWordGlobal';
import BadWordGlobalListView from '@src/component/argo/bad_word_global_listview';
import BadWordGlobalEditor from '@src/component/argo/bad_word_global_editor';

import AdminRoute from '@src/Routes'

export function ArgoRoutes() {
   
    return [
        
        <AdminRoute path={viewBadWordGlobalClassProxy.getDefaultListRoute()} component={BadWordGlobalListView} key="BadWordGlobalListView"/>,
        <AdminRoute path={viewBadWordGlobalClassProxy.getDefaultEditRoute()} component={BadWordGlobalEditor} key="BadWordGlobalEditor"/>,



    ];
}

