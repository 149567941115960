import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewMetaAssignedKeywordRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Táblázat infó
     *
     * Ez mondja meg, hogy a folyamat melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number;
    /**
     * Rekord azonosító
     *
     * Ez mondja meg, hogy a folyamat melyik rekordhoz tartozik.
     */
    record_id?: number;
    /**
     * Kulcsszó
     */
    keyword_id?: number;
    /**
     * Létrehozta
     *
     * Aki a kulcsszót hozzárendelte
     */
    creator?: string|null;
    /**
     * Érték
     *
     * A kulcsszó értéke
     */
    value?: string|null;
}

/**
 *  meta.view_meta_assigned_keyword - Hozzárendelt kulcsszó
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewMetaAssignedKeyword extends View<IViewMetaAssignedKeywordRecord> {
    public static VIEW_INFO_ID = 2018061403;

    public static getSchemaNameForClass(): string { return 'meta'; }
    public static getViewNameForClass(): string { return 'view_meta_assigned_keyword'; }
    public static getTableNameForClass(): string|null { return 'assigned_keyword'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewMetaAssignedKeyword>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewMetaAssignedKeywordRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewMetaAssignedKeywordClassProxy extends ViewClassProxy<IViewMetaAssignedKeywordRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewMetaAssignedKeywordClassProxy = new ViewMetaAssignedKeywordClassProxy(ViewMetaAssignedKeyword);
registerViewClassProxy(viewMetaAssignedKeywordClassProxy);

