import * as React from 'react';
import { app } from '@src/index';
import { me } from '@src/framework/server/Auth';
import { BubbleLoader } from 'react-css-loaders';
import SurveyAnswerCrud, { ISurveyAnswerRecord } from '@src/framework/crud/usr/SurveyAnswerCrud';
import { __ } from '@src/translation';
import SurveyCrud, { ISurveyRecord } from '@src/framework/crud/usr/SurveyCrud';
import { Question, SurveyInputType } from './SurveyConstants';
import { CSVLink } from "react-csv";
import { AnswerType } from './Survey';
import { Toggle } from '../ui/Panel';

type SurveySummaryProps = {

    survey_id: number;
    recId?: number;
    table_info_id?: number;
}
type SurveySummaryState = {
    loading: boolean,
    showSurvey: boolean,
    answers: ISurveyAnswerRecord[],
    survey?: ISurveyRecord;
    questions?: Question[]
}

export class SurveySummaryComponent extends React.Component<SurveySummaryProps, SurveySummaryState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            showSurvey: true,
            answers: [],
            questions: undefined
        }
    }

    async componentDidMount() {
        try {
            if (me && this.props.survey_id) {
                this.setState({ loading: true })
                const survey = (await SurveyCrud.load(this.props.survey_id)).record;
                const answers = await SurveyAnswerCrud.list({ filter: { survey_id: this.props.survey_id, is_active: true, table_info_id: this.props.table_info_id, rec_id: this.props.recId } });
                this.setState({
                    survey,
                    answers,
                    loading: false
                })
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }

        this.setState({
            loading: false
        })
    }

    private getCSVData(): any[] {

        if (!this.state.survey) return [];

        const questions = this.state.survey.questions.questions as Question[];
        const answers = this.state.answers;
        let csvHeader: string[] = [];

        // Create header of the CSV file
        questions.map(q => {
            typeof q.name == "string" ? csvHeader.push(q.name) : "";
            if ([SurveyInputType.CHECKBOX, SurveyInputType.RADIO].includes(q.type)) {
                q.answers.map(qa => {
                    qa.name ? csvHeader.push(qa.name) : "";
                });
            }
            if (q.allowCustomAnswer) csvHeader.push("Egyéb");
        });


        let csvRows: any[] = [];
        csvRows.push(csvHeader);

        // Create body (answers) of CSV file
        answers.map(a => {
            let row: string[] = [];
            const survey_answers: AnswerType = a.answers;
            //  Go through every question
            questions.map(question => {
                let column = "";
                const q_answer = survey_answers[question.id];
                //Ha a kérdés checkbox vagy radio kell egy üres oszlop, mert megjelenítjük a kérdést is külön a headerben
                if ([SurveyInputType.CHECKBOX, SurveyInputType.RADIO].includes(question.type)) row.push("");

                // Ha nem adott választ a kérdésre (opcionális, vagy elágazás miatt)
                if (!q_answer) {
                    // Meg kell nézni mennyi válasz adható meg, és annyi üres oszlopot beszúrni 
                    for (let i = 0; i < question.answers.length; i++) {
                        row.push("");
                    }
                    if (question.allowCustomAnswer) row.push("");
                }
                // Ha adott választ és SMILEY vagy RANGE
                else if (([SurveyInputType.RADIO_SMILEY, SurveyInputType.RANGE].includes(question.type))) {
                    column = q_answer.ans_id && q_answer.ans_id[0] ? q_answer.ans_id[0].toString() : "";
                    row.push(column);
                }
                // Ha adott választ és TEXT
                else if (question && question.type == SurveyInputType.TEXT) {
                    column = q_answer.value ? q_answer.value : "";
                    row.push(column);
                }
                // Ha adott választ és RADIO vagy CHECKBOX
                else if (question && [SurveyInputType.CHECKBOX, SurveyInputType.RADIO].includes(question.type)) {
                    for (let i = 0; i < question.answers.length; i++) {
                        if (q_answer && q_answer.ans_id && q_answer.ans_id.includes(question.answers[i].id)) row.push("1");
                        else row.push("");
                    }
                    if (question.allowCustomAnswer) {
                        const textAns = q_answer.value ? q_answer.value : "";
                        row.push(textAns);
                    }
                }
            });
            csvRows.push(row);
        });

        return csvRows;
    }

    render() {

        if (this.state.loading) {
            return <BubbleLoader />
        }

        if (this.state.survey && this.state.survey.questions && me) {

            const questions = this.state.survey.questions.questions as Question[];
            const csvData = this.getCSVData();

            return <div className="row">
                <div className="column small-12">
                    <h3>{this.state.survey.title + " című kérdőív eredményei"}</h3>
                </div>
                <div className="column small-12">
                    {
                        this.state.answers && this.state.answers.length > 0
                            ? <div>

                                {csvData.length >= 1 && <CSVLink data={this.getCSVData()}
                                    filename={this.state.survey.title + ".csv"}
                                    className="button success"
                                    target="_blank"
                                    separator={";"}>
                                    {__("Kérdőív válaszok letöltése (CSV)")}
                                </CSVLink>}

                                {questions.map((currentQuestion) => {
                                    return <div key={currentQuestion.id} className="small-12 column">
                                        <div className="row">
                                            <p><b>{currentQuestion.name}</b></p>
                                        </div>
                                        <div className="row">
                                            <SurveyAnswersComponent
                                                currentQuestion={currentQuestion}
                                                answers={this.state.answers}
                                            />
                                        </div>
                                    </div>
                                })}
                            </div>
                            : <p>Ehhez a kérdőívhez nem tartoznak válaszok, még nem töltötte ki senki.</p>
                    }

                </div>
            </div >
        }
        else {
            return <div className="row" style={{ justifyContent: "center", paddingTop: "2em" }}><br /><h4>Nem sikerült hozzáférni a kérdőív eredményeihez.</h4></div>
        }

    }
}
type SummaryAnswersProps =
    {
        currentQuestion: Question;
        answers: ISurveyAnswerRecord[];
    }
type SurveyAnswersState = {
}

class SurveyAnswersComponent extends React.Component<SummaryAnswersProps, SurveyAnswersState> {

    constructor(props: any) {
        super(props);

        this.state = {
        }
    }

    render() {

        const q_id = this.props.currentQuestion.id;

        // Nem szöveges válaszok számossága kérdésenként
        const check_count: {} = {};
        // Adott kérdésre hány darab szöveges válasz érkezett (TEXT típus vagy Egyéb válasz RADIO/CHECKBOXNÁL)
        const text_count: {} = {};

        for (const a of this.props.answers) {
            const answer = a.answers[q_id];
            if (answer) {
                if (answer.ans_id) {
                    // A jelölt válaszokat összeszámoljuk, miből hány darab van
                    for (const index of answer.ans_id) {
                        if (!check_count[index]) check_count[index] = 1;
                        else check_count[index]++;
                    }
                }
                // A szöveges válaszok számát megszámoljuk
                if (answer.value) {
                    if (!text_count[q_id]) text_count[q_id] = 1;
                    else text_count[q_id]++;
                }
            }
        };

        let elements: JSX.Element[] = [];

        switch (this.props.currentQuestion.type) {
            case SurveyInputType.TEXT:
                if (text_count[q_id] > 0) elements.push(
                    <div key={"text_answer_" + q_id}>
                        <Toggle header={<><b style={{ color: "#005083" }}> <i className="fa fa-user-plus" /> {text_count[q_id]} </b> {__("Szöveges válaszok száma")}</>}>
                            <ul>
                                {
                                    this.props.answers.map(a => {
                                        return (a.answers[q_id] && a.answers[q_id].value) ?
                                            <li key={"text_answer_" + q_id + a.id} style={{ listStyleType: "none" }}>{a.answers[q_id].value}</li>
                                            : null;
                                        })
                                }
                            </ul>
                        </Toggle>
                    </div>
                );
                break;
            case SurveyInputType.RANGE:
                if (this.props.currentQuestion.rangeSize) {
                    for (let index = 1; index <= this.props.currentQuestion.rangeSize!; index++) {
                        let name = index + ".";
                        if (index == 1 && this.props.currentQuestion.answers[0]) {
                            name = this.props.currentQuestion.answers[0].name || "";
                        } else if (index == this.props.currentQuestion.rangeSize && this.props.currentQuestion.answers[1]) name = this.props.currentQuestion.answers[1].name || "";
                        elements.push(<div key={"range_item_" + q_id + index}><b style={{ color: "#005083" }}> <i className="fa fa-user-plus" /> {check_count[index] || 0} </b>{name}</div>);
                    }
                }
                break;

            default:
                let tmp = this.props.currentQuestion.answers.sort((a, b) => (check_count[b.id] || 0) - (check_count[a.id] || 0));
                tmp.forEach(b => {
                    elements.push(<div key={"check_item_" + q_id + b.id}><b style={{ color: "#005083" }}> <i className="fa fa-user-plus" /> {check_count[b.id] || 0} </b>{b.name}</div>);
                });
                if (text_count[q_id] > 0) elements.push(<div key={"text_answer_" + q_id}>
                    <Toggle header={<><b style={{ color: "#005083" }}> <i className="fa fa-user-plus" /> {text_count[q_id]} </b> {__("Szöveges válaszok száma")}</>}>
                        <ul>
                            {this.props.answers.map(a => {
                                if (a.answers[q_id] && a.answers[q_id].value) return <li key={"text_answer_" + q_id + a.id} style={{ listStyleType: "none" }}>{a.answers[q_id].value}</li>
                                else return null;
                            })
                            }
                        </ul>
                    </Toggle>
                </div>);
                break;
        }

        return <div className="row">
            <div className="column small-12">
                <ul>
                    {
                        elements
                    }
                </ul>
            </div>
        </div>

    }

}
