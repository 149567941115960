import * as React from 'react';
import  ViewTaskLog, { IViewTaskLogRecord } from '@src/framework/view/data_imp/ViewTaskLog';
import '@framework/crud/kb/ItemTypeCrud'; // Ezt ne távolítsd el mert akkor a hülye webpack kioptimizálja!
import { app } from '@src/index';
import ReactTable from 'react-table';
import { getReactTableLabels } from '@src/framework/i18n';
import { formatDate, formatDateWithSeconds } from '@src/Util';
import { __, getLanguage } from '@src/translation';

interface ITaskLogListViewInnerProps {
    taskId : number|null;
}

interface ITaskLogListViewInnerState {
    records : IViewTaskLogRecord[];
    loading: boolean;
}


export default class TaskLogListView extends React.Component<ITaskLogListViewInnerProps, ITaskLogListViewInnerState> {
    constructor (props: ITaskLogListViewInnerProps) {
        super(props);
        this.state = {loading: true, records: []};
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async() => {
        this.setState({loading: true, records: []});
        try {
            const records = await ViewTaskLog.list({
                filter: {task_id: this.props.taskId},
                order_by: [{name:"id", desc: true}]
            });
            this.setState({loading: false, records});
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {
        return  <ReactTable
        columns={[
            {
                Header: __("Időpont"),
                maxWidth: 110,
                Cell: (data: any, column: any) => {
                    const row: IViewTaskLogRecord = data.original;
                    return <small>{formatDateWithSeconds(row.created)}
                        <br/>
                        {row.severity_name}
                    </small>
                }
            },
            {
                Header: __("Üzenet"),
                Cell: (data: any, column: any) => {
                    const row: IViewTaskLogRecord = data.original;
                    let position = null;
                    if (row.position) {
                        position = row.position["fileName"];
                        if (row.position["row"]) {
                            position += " sor:" + row.position["row"];
                        }
                        if (row.position["col"]) {
                            position += " " + __("oszlop:") + row.position["col"];
                        }
                    }
                    return <div style={row.severity_style}>
                        <div dangerouslySetInnerHTML={{__html:row.message || ""}}></div>
                        <span>{position}</span>
                    </div>
                }
            }
        ]}
        data={this.state.records}
        filterable={false}
        className="-striped -highlight "
        {...getReactTableLabels()}
    />
    }

}
