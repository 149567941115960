import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Z_INDEX_DIALOG_ROOT } from '@src/Const';

export class Modal extends React.Component<any, any> {

    render() {
        return ReactDOM.createPortal(<div key="a" role="dialog"
            style={{position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: Z_INDEX_DIALOG_ROOT}}>
                {this.props.children}
            </div>, document.body);
    }
}
