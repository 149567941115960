import * as React from 'react';
import { sendUserViewEvent, UserViewEvent, touchUserViewEvent } from '@src/server/PublicServer';
import { me } from '@src/framework/server/Auth';


export type UserTracerProps = {
    event: UserViewEvent;
}

export default class UserTracer extends React.Component<UserTracerProps> {

    private timeout: any;

    private id: string;

    async componentDidMount() {
        if (!me) return;

        this.id = await sendUserViewEvent(this.props.event);
        
        this.timeout = setTimeout(this.sendTouch.bind(this), 60 * 1000);
    }

    async componentDidUpdate(prevProps: UserTracerProps) {
        if (!me) return;
        
        if (prevProps.event.tableInfoId !== this.props.event.tableInfoId || prevProps.event.recordId !== this.props.event.recordId) {
            this.sendTouch();

            this.id = await sendUserViewEvent(this.props.event);
        }
    }

    private sendTouch() {
        if (this.id) touchUserViewEvent(this.id);
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout);

        this.sendTouch();
    }

    render() {
        return null;
    }

}
