import * as React from 'react';
import { app } from '@src/index';
import { me } from '@src/framework/server/Auth';
import { BubbleLoader } from 'react-css-loaders';
import SurveyAnswerCrud from '@src/framework/crud/usr/SurveyAnswerCrud';
import { alertDialog } from '../Dialog';
import { __ } from '@src/translation';
import { match } from 'react-router';
import SurveyCrud, { ISurveyRecord } from '@src/framework/crud/usr/SurveyCrud';
import { AnswerType } from './Survey';
import * as queryString from 'query-string';
import { SurveyInputType, Question, NEW_STUDENT_SURVEY_ID, NEW_TEACHER_SURVEY_ID, OTHER_TEXT_ANSWER_ID, QuestionCondition } from './SurveyConstants';
import { NewQuestionsForStudents } from './NewQuestionsForStudents';
import { NewQuestionsForTeachers } from './NewQuestionsForTeachers';

type SurveyModuleProps = {
    match: match<{ survey_id: string, enableSkip: string, recId?: string, table_info_id?: string }>;
    location: Location;
}
export class SurveyModule extends React.Component<SurveyModuleProps, SurveyState> {

    render() {
        const params = queryString.parse(this.props.location.search);

        return <div>
            <SurveyComponent onSurveyFinished={() => ({})}
                survey_id={parseInt(params.survey_id)}
                enableSkip={params.enableSkip == "true"}
                isPreview={params.isPreview == "true"}
                recId={params.recId ? parseInt(params.recId) : undefined}
                table_info_id={params.table_info_id ? parseInt(params.table_info_id) : undefined} />
        </div>;
    }
}

type SurveyProps = {
    onSurveyFinished: () => void;
    survey_id: number;
    enableSkip: boolean;
    recId?: number;
    table_info_id?: number;
    isPreview?: boolean;
}
type SurveyState = {
    loading: boolean,
    showSurvey: boolean,
    answers: AnswerType,
    survey?: ISurveyRecord;
    questions?: Question[];
    extraQuestionIndexes: number[];
}

export class SurveyComponent extends React.Component<SurveyProps, SurveyState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            showSurvey: true,
            answers: {},
            questions: undefined,
            extraQuestionIndexes: []
        }
    }

    async componentDidMount() {
        try {
            if (me && this.props.survey_id) {
                this.setState({ loading: true });
                let personalQuestions: Question[] = [];
                const survey = (await SurveyCrud.load(this.props.survey_id)).record;

                // the surveys that come from the db
                if (this.props.survey_id == 3 || this.props.survey_id > 5) {
                    personalQuestions = survey.questions.questions;
                } else {// surveys that come from files
                    switch (this.props.survey_id) {
                        case NEW_STUDENT_SURVEY_ID:
                            personalQuestions = NewQuestionsForStudents;
                            break;
                        case NEW_TEACHER_SURVEY_ID:
                            personalQuestions = NewQuestionsForTeachers;
                            break;

                        default:
                            break;
                    }
                }


                const answers = await SurveyAnswerCrud.list({ filter: { sec_user_id: me.id, survey_id: this.props.survey_id, is_active: true, table_info_id: this.props.table_info_id, rec_id: this.props.recId } });

                if (answers && answers.length > 0) {
                    personalQuestions = personalQuestions.filter(q => !answers[0].answers[q.id] || answers[0].answers.length == 0);
                }

                if (answers.length === 1 && answers[0].answers && personalQuestions && personalQuestions.length > 0) {
                    this.setState({
                        answers: answers[0].answers,
                    })
                }

                this.setState({
                    questions: personalQuestions,
                    survey
                })

                if (!personalQuestions || personalQuestions.length === 0) {
                    this.props.onSurveyFinished();
                }
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
            this.props.onSurveyFinished();
        }

        this.setState({
            loading: false
        })
    }

    private onChange(questionId: number, answerId: number, event: React.ChangeEvent<HTMLInputElement>) {
        try {
            let question = this.state.questions!.find(q => q.id == questionId);

            if (!question) return;

            const answers = this.state.answers;

            if (answerId == OTHER_TEXT_ANSWER_ID && question.allowCustomAnswer) {
                if (answers[question.id] == undefined) answers[question.id] = { ans_id: [] };
                if (event.target.value != "") answers[question.id].value = event.target.value
                else {
                    answers[question.id].value = undefined;
                }
                // Ha radio gomb van és egyebet tölt ki
                if (question.type == SurveyInputType.RADIO) answers[question.id].ans_id = [];
            }

            if (question.type == SurveyInputType.TEXT) {
                if (answers[question.id] == undefined) answers[question.id] = { ans_id: [] };
                answers[question.id].value = event.target.value;
            }

            if (answerId != OTHER_TEXT_ANSWER_ID && question.type === SurveyInputType.CHECKBOX) {
                if (!answers[question.id]) answers[question.id] = { ans_id: [] };

                const currentAnswer = answers[question.id];

                if (currentAnswer.ans_id) {
                    if (event.target.checked) {
                        currentAnswer.ans_id.push(answerId);
                    } else {
                        let temp = currentAnswer.ans_id.indexOf(answerId);
                        currentAnswer.ans_id.splice(temp, 1)
                    }
                }

            } else if (answerId != OTHER_TEXT_ANSWER_ID && (question.type === SurveyInputType.RADIO || question.type === SurveyInputType.RADIO_SMILEY || question.type == SurveyInputType.RANGE)) {
                if (!answers[question.id]) answers[question.id] = { ans_id: [] };
                answers[question.id].ans_id = [answerId];
                answers[question.id].value = undefined;
                if (question.type == SurveyInputType.RADIO && this.state.extraQuestionIndexes.includes(question.id)) this.onToogleExtraQuestionIndex(question);
            }

            this.recursiveEvalQuestion(question, answers);

            this.setState({
                answers
            })
        }
        catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    /*
      Ha extra elem is megadható a kérdésnél, akkor ez a metódus felelős azért,
      hogy a hozzájuk tartozó checkboxot ki/be pipálja, tárolja state-ben,
      RADIO becsekkolás esetén kiszedje a másik bejelöltet...  
    */
    private onToogleExtraQuestionIndex(question: Question) {
        let q_indexes = this.state.extraQuestionIndexes;
        const answer = this.state.answers[question.id];
        let idx = q_indexes.findIndex((el) => { return el == question.id });
        if (idx > -1) {
            q_indexes.splice(idx, 1);
            //Ha volt megadva válasz törölni kell
            if (answer && answer.value) answer.value = undefined;
        } else {
            q_indexes.push(question.id);
            //RADIO esetén a már bejelöltet törölni kell
            if (answer && question.type == SurveyInputType.RADIO) answer.ans_id = [];
        }
        this.setState({ extraQuestionIndexes: q_indexes });
    }

    private recursiveEvalQuestion(question: Question, answers: AnswerType) {
        // Ha nem teljesül rá a feltétel, töröljük a választ, hiszen meg se jelenik és függhet más kérdés tőle...
        if (!this.evaluateCondition(question)) {
            answers[question.id] = { ans_id: [], value: undefined };
        }
        // Melyik kérdések függnek az adott kérdéstől
        let condQuestions = this.state.questions!.filter((q: Question) => q.condition && q.condition.questionId && q.condition.questionId == question!.id);
        // Kilépő feltétel
        if (!condQuestions.length) return;
        // Menjünk végig ezeken a kérdéseken és értékeljük ki ezeket is rekurzívan
        condQuestions.map(cq => {
            this.recursiveEvalQuestion(cq, answers);
        });
    }

    private async onSave() {
        if (!this.state.questions) {
            return;
        }

        if (this.props.isPreview) {
            app.showInfo(__("Figyelem"), __("Teszt nézetben nem tud menteni!"));
            return;
        }

        for (const personalQ of this.state.questions) {
            const evalRes = this.evaluateCondition(personalQ);
            // Ha nem jelenik meg a kérdés, akkor ha korábban küldött rá választ, akkor azt töröljük
            if (!evalRes) {
                if (this.state.answers[personalQ.id]) this.state.answers[personalQ.id] = { ans_id: [], value: undefined };
                continue;
            }
            // Ha nem opcionális, akkor nem kell nézni, hogy van -e válasz
            if (personalQ.optional) continue;

            if (!this.state.answers[personalQ.id] || (!this.state.answers[personalQ.id].value && (!this.state.answers[personalQ.id].ans_id || this.state.answers[personalQ.id].ans_id!.length == 0))) {
                await alertDialog(__("Kötelező kitölteni a kérdőívet!"), __("Kérem válaszoljon a kérdésekre!"));
                return;
            }
        }

        try {
            if (this.props.table_info_id && this.props.recId) {
                await new SurveyAnswerCrud({
                    sec_user_id: me!.id,
                    survey_id: this.props.survey_id,
                    answers: this.state.answers,
                    is_active: true,
                    table_info_id: this.props.table_info_id,
                    rec_id: this.props.recId
                }).upsert(["sec_user_id", "survey_id", "table_info_id", "rec_id"]);
            } else {
                await new SurveyAnswerCrud({
                    sec_user_id: me!.id,
                    survey_id: this.props.survey_id,
                    answers: this.state.answers,
                    is_active: true,
                }).upsert(["sec_user_id", "survey_id"]);

            }

            app.showSuccess(__("Sikeres mentés"), __("Kérdőív sikeresen elmentve"));

            this.setState({
                questions: []
            });

            this.props.onSurveyFinished();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }
    private async onSkip() {
        this.props.onSurveyFinished();
    }

    private evaluateCondition(question: Question): boolean {
        if (!this.state.questions || !question.condition || !question.condition.questionId) return true;

        // es6 destructuring
        const { questionId, answerIds, type } = question.condition;

        const ans = this.state.answers[questionId];
        const condQ = this.state.questions.find(q => q.id == questionId);

        if (!ans) return false;

        // Ha range kérdés van megadva, akkor a felső határ a range mérete
        // TODO: Range típus átalakítás, hogy ne csak alsó felső értékekre lehessen feltétel stb...
        if (condQ && condQ.type == SurveyInputType.RANGE) {
            const upperInd = answerIds.indexOf(2); 
            if (upperInd > -1) {
                answerIds.splice(upperInd, 1);
                answerIds.push(condQ.rangeSize ? condQ.rangeSize : 1);
            }
        }

        if (type == QuestionCondition.EXACT_MATCH) {
            // A számosság és az elemeknek is egyezniük kell, a sorrend mindegy
            if (ans.ans_id.length != answerIds.length) return false;
            for (let i = 0; i < ans.ans_id.length; i++) {
                if (!answerIds.includes(ans.ans_id[i])) return false;
            }
            return true;
        }
        else if (type == QuestionCondition.AT_LEAST_ONE) {
            // Ha van egy válasz elem, ami benne van a feltétel tömbben
            for (let i = 0; i < ans.ans_id.length; i++) {
                if (answerIds.includes(ans.ans_id[i])) return true;
            }
        }
        return false;
    }

    getRange(question: Question): JSX.Element {
        if (!question.answers || question.answers.length < 2 || !question.rangeSize) return <></>;
        const lenghtR = question.rangeSize;
        let items = [];
        const itemWidth = (Math.floor(100 / (lenghtR))).toString() + "%";
        const currentAnswer = this.state.answers[question.id];

        for (let index = 1; index <= lenghtR; index++) {
            const checked = currentAnswer && currentAnswer.ans_id && currentAnswer.ans_id.length == 1 && currentAnswer.ans_id[0] == index;

            items.push(<input type="radio" style={{ width: itemWidth }}
                checked={checked || false}
                onChange={this.onChange.bind(this, question.id, index)}></input>)
        }

        return <div className="column small-12 large-12">
            <div className="row">
                <div className="column small-4 medium-3 large-3" style={{ textAlign: "right" }}>{question.answers[0].name}</div>
                <div className="column small-12 medium-5 large-3" style={{ textAlign: "center" }}>
                    {items}
                </div>
                <div className="column small-12 medium-4 large-3">{question.answers[question.answers.length - 1].name}</div>
            </div>
        </div>
    }

    render() {

        if (this.state.loading) {
            return <BubbleLoader />
        }

        const questions = this.state.questions;

        if (!this.state.survey || !questions || questions.length === 0) {
            return <div className="row">
                <div className="column small-12">
                    <h3>{this.state.survey!.title}</h3>

                    <div className="row" style={{ justifyContent: "center", paddingTop: "2em" }}><br /><h4>Köszönjük, hogy kitöltötte a kérdőívet!</h4></div>
                </div>
            </div>
        }
        let enableSave = true;
        for (const personalQ of questions) {
            if (personalQ.optional || !this.evaluateCondition(personalQ)) continue;
            if (!this.state.answers[personalQ.id] || (!this.state.answers[personalQ.id].value && (!this.state.answers[personalQ.id].ans_id || this.state.answers[personalQ.id].ans_id!.length == 0))) {
                enableSave = false;
            }
        }

        if (this.state.showSurvey && me) {
            let display_index = 0;
            return <div className="row">
                <div className="column small-12">
                    <h3>{this.state.survey.title}</h3>
                </div>
                <div className="column small-12">
                    {
                        questions.map((question, index) => {

                            if (question.condition && !this.evaluateCondition(question)) {
                                // Ha nem felel meg a feltételnek, akkor nem jelenítjük meg a kérdést
                                return null;
                            }
                            //Csak a megjelenített kérdéseket sorszámozzuk, amelyek megfelelnek a feltételeknek
                            display_index++;

                            const extraVal = this.state.answers[question.id] && this.state.answers[question.id].value ? this.state.answers[question.id].value : "";
                            const inputType = question.type;

                            return <div key={index} style={{ marginTop: "2em", marginBottom: "1em" }}>
                                <div className="row">
                                    {question.name && <div className="column small-12 medium-12 large-12">
                                        <p><b>{display_index + "."} {!question.optional && <span style={{ color: "red" }}>*</span>} {question.name}</b></p>
                                    </div>
                                    }
                                    {
                                        question.type == SurveyInputType.RANGE ?
                                            this.getRange(question)
                                            :
                                            <div style={{ display: question.type === SurveyInputType.RADIO_SMILEY ? "flex" : "block", justifyContent: "space-around" }} className="column small-12 medium-12 large-12">

                                                {
                                                    question.answers.map((answer: any, index2: number) => {
                                                        var checked = false;
                                                        const currentAnswer = this.state.answers[question.id];

                                                        if (currentAnswer && currentAnswer.ans_id) {
                                                            if (question.type === SurveyInputType.CHECKBOX) {
                                                                checked = currentAnswer.ans_id.indexOf(answer.id) !== -1;
                                                            } else if (question.type === SurveyInputType.RADIO || question.type === SurveyInputType.RADIO_SMILEY) {
                                                                checked = currentAnswer.ans_id.length == 1 && currentAnswer.ans_id[0] == answer.id;
                                                            }
                                                        }

                                                        if (question.type == SurveyInputType.RADIO_SMILEY) {
                                                            const normalStyle = {
                                                                maxHeight: "40px",
                                                                opacity: 0.65,
                                                                cursor: "pointer",
                                                                transition: "200ms"
                                                            }
                                                            const checkedStyle = {
                                                                ...normalStyle,
                                                                opacity: 1,
                                                                transform: "scale(1.3)",
                                                                boxShadow: "1px 2px 2px #000000a6",
                                                                borderRadius: "50%",
                                                            }
                                                            return <label key={index2}>
                                                                <input
                                                                    style={{ visibility: "hidden", width: "0", height: "0" }}
                                                                    type={SurveyInputType.RADIO}
                                                                    checked={checked || false}
                                                                    onChange={this.onChange.bind(this, question.id, answer.id)}
                                                                />
                                                                <img style={checked ? checkedStyle : normalStyle} title={answer.name} src={"/img/IKON_SET/SURVEY/" + (index2 + 1) + ".png"} />
                                                            </label>
                                                        }
                                                        else if (question.type == SurveyInputType.TEXT) {
                                                            return <input type="text" key={index2} value={currentAnswer && currentAnswer.value ? currentAnswer.value : ""} onChange={this.onChange.bind(this, question.id, answer.id)} />
                                                        }
                                                        else {
                                                            return <div key={index2} className="row column small-12" style={{ marginLeft: "1em" }}>
                                                                <label>
                                                                    <input
                                                                        type={inputType}
                                                                        checked={checked || false}
                                                                        onChange={this.onChange.bind(this, question.id, answer.id)}
                                                                    />
                                                                    {answer.name}
                                                                </label>
                                                            </div>

                                                        }
                                                    })
                                                }
                                                {
                                                    question.allowCustomAnswer && <div className="row column small-12" style={{ marginLeft: "1em" }}>
                                                        <label>
                                                            <input
                                                                type={inputType}
                                                                checked={this.state.extraQuestionIndexes.includes(question.id)}
                                                                onChange={this.onToogleExtraQuestionIndex.bind(this, question)}
                                                            />
                                                            {__("Egyéb: ")}
                                                        </label>
                                                        <input
                                                            type={"text"}
                                                            disabled={!this.state.extraQuestionIndexes.includes(question.id)}
                                                            style={extraVal != "" ? { border: "2px solid blue" } : undefined}
                                                            onChange={this.onChange.bind(this, question.id, OTHER_TEXT_ANSWER_ID)}
                                                            value={extraVal}
                                                        />
                                                    </div>}
                                            </div>
                                    }
                                </div>
                            </div>
                        })
                    }

                    {
                        !enableSave &&
                        <p><strong>{__("Kérem válaszoljon az összes csillaggal jelölt kérdésre!")}</strong></p>
                    }

                    <button disabled={!enableSave} className="button" onClick={this.onSave.bind(this)}>
                        {__("Elküldés")}
                    </button>


                    {this.props.enableSkip && !enableSave
                        ? <button onClick={this.onSkip.bind(this)} style={{ float: "right" }} className="button">{__("Kitöltöm később")}</button>
                        : ""
                    }
                </div>
            </div>

        }
        else {
            return <div className="row" style={{ justifyContent: "center", paddingTop: "2em" }}><br /><h4>Köszönjük, hogy kitöltötte a kérdőívet!</h4></div>
        }


    }
}
