import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSecStationPermRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Folyamat állapot
     */
    station_id?: number;
    /**
     * Engedély birtokos
     */
    sec_perm_owner_id?: number;
    /**
     * Folyamat indítás
     */
    can_start?: boolean;
    /**
     * Fő rekord módosítása
     */
    can_update_master?: boolean;
    /**
     * Kapcsolódó tétel rekord létrehozása
     */
    can_create_detail?: boolean;
    /**
     * Kapcsolódó tétel rekord módosítása
     */
    can_update_detail?: boolean;
    /**
     * Kapcsolódó tétel rekord törlése
     */
    can_delete_detail?: boolean;
    /**
     * Megjegyzés beküldése
     */
    can_comment?: boolean;
    /**
     * Al-státusz módosítása
     */
    can_update_substation?: boolean;
    /**
     * Státusz megjegyzés módosítása
     */
    can_update_status_text?: boolean;
    /**
     * Publikálás
     */
    can_publish?: boolean;
    /**
     * Publikáció törlése
     */
    can_unpublish?: boolean;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Folyamat típus azonosító
     *
     * Folyamat típus azonosító
     */
    wf_type_id?: number|null;
    /**
     * Állapot név
     *
     * Állapot név
     */
    station_name?: string|null;
    /**
     * Állapot leírás
     *
     * Állapot leírás
     */
    station_description?: string|null;
    /**
     * Kezdőállapot
     *
     * Kezdőállapot
     */
    is_start?: boolean|null;
    /**
     * Végállapot
     *
     * Végállapot
     */
    is_closed?: boolean|null;
    /**
     * Elszámolható
     *
     * Elszámolható
     */
    is_eligible?: boolean|null;
    /**
     * Állapot stílus
     *
     * Állapot stílus (CSS JSON)
     */
    style?: any|null;
    /**
     * Felhasználó
     *
     * Felhasználó, aki jogosult látni az ilyen állapotú folyamatokat
     */
    user_id?: number|null;
    /**
     * Felhasználó
     *
     * Felhasználó, aki jogosult látni az ilyen állapotú folyamatokat
     */
    login_name?: string|null;
    /**
     * Személy
     *
     * Aki jogosult látni az ilyen állapotú folyamatokat
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * A jogosult természetes személy neve
     */
    fullname?: string|null;
    /**
     * Csoport név
     *
     * A csoport neve
     */
    group_title?: string|null;
    /**
     * Csoport típus
     *
     * A csoport típus azonosítója
     */
    sec_group_type_id?: number|null;
    /**
     * Csoport típus
     *
     * A csoport típus neve
     */
    sec_group_type_title?: string|null;
    /**
     * A jogosult neve
     *
     * A csoport vagy felhasználó humán azonosítására szolgáló szöveg
     */
    perm_owner_label?: string|null;
}

/**
 *  wf.view_sec_station_perm - Naplózott
 *
 *  Azt mondja meg, hogy az adott állomáson levő folyamattal ki milyen műveletet tud végezni.
 */

export default class ViewSecStationPerm extends View<IViewSecStationPermRecord> {
    public static VIEW_INFO_ID = 2018050403;

    public static getSchemaNameForClass(): string { return 'wf'; }
    public static getViewNameForClass(): string { return 'view_sec_station_perm'; }
    public static getTableNameForClass(): string|null { return 'sec_station_perm'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSecStationPerm>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSecStationPermRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSecStationPermClassProxy extends ViewClassProxy<IViewSecStationPermRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSecStationPermClassProxy = new ViewSecStationPermClassProxy(ViewSecStationPerm);
registerViewClassProxy(viewSecStationPermClassProxy);

