import * as React from 'react'
import { BubbleLoader } from 'react-css-loaders';
import ViewUsrFavouriteFileRecord, { IViewUsrFavouriteFileRecord } from '@src/framework/view/usr/ViewUsrFavouriteFile';
import { me } from '@src/framework/server/Auth';
import { getOfferDivs } from './GetOffers';
import * as queryString from 'query-string';
import { GetMediaTypeFromId } from './GetMediatypeFromID';
import { __ } from '@src/translation';
import { History, Location } from 'history';

type FavouriteMediaProps = {
    location: Location;
    history: History;
}

type FavouriteMediaState = {
    loading: boolean;
    popularVideosBlock: any;
    popularImagesBlock: any;
    popularMapsBlock: any;
    soundOffersBlock: any;
    popularIntExercisesBlock: any
    Videos: IViewUsrFavouriteFileRecord[];
    Images: IViewUsrFavouriteFileRecord[];
    Maps: IViewUsrFavouriteFileRecord[];
    soundOffers: IViewUsrFavouriteFileRecord[];
    Exercises: IViewUsrFavouriteFileRecord[];
    pageIndex: number;
    extendedList:boolean;
}

const ITEMSPERPAGE = 8;

export class MediaFavourites extends React.Component<FavouriteMediaProps, FavouriteMediaState> {

    constructor(prop: any) {
        super(prop);

        this.state = {
            loading: false,
            extendedList:false,
            pageIndex: 1,
            Videos: [],
            soundOffers: [],
            Images: [],
            Maps: [],
            Exercises: [],
            popularVideosBlock: undefined,
            popularImagesBlock: undefined,
            popularIntExercisesBlock: undefined,
            popularMapsBlock: undefined,
            soundOffersBlock: undefined
        };
    }

    componentDidMount() {
      
        this.reloadOffersAsync();
    }
    componentDidUpdate(prevprops:FavouriteMediaProps)
    {
        if(prevprops.location.search!= this.props.location.search)
        {
            const params = queryString.parse(this.props.location.search);
            this.setState({ loading: true, extendedList:params.extendedList &&params.extendedList=="true"?true:false }, this.reloadOffersAsync.bind(this));
        }
    }
    private setPageIndex = (pageIndex: number) => {
        if (pageIndex) {
            this.setState({ pageIndex: pageIndex },this.reloadOffersAsync.bind(this));
        }
    }

    async reloadOffersAsync() {
        if (me) {
            this.setState({loading:true});
             
            let favContents = await ViewUsrFavouriteFileRecord.list({ filter: { sec_user_id: me.id }, order_by: [{ name: "creation_time", desc: true }], limit: 50 });

            let favVideos: IViewUsrFavouriteFileRecord[] = [];

            let favImages: IViewUsrFavouriteFileRecord[] = [];

            let favMaps: IViewUsrFavouriteFileRecord[] = [];

            let favAudio: IViewUsrFavouriteFileRecord[] = [];

            let favIntExercises: IViewUsrFavouriteFileRecord[] = [];
          
            for (const currentFav of favContents) {
                console.log(currentFav)
                if (GetMediaTypeFromId(currentFav.media_type_id) == "video") favVideos.push(currentFav);
                if (GetMediaTypeFromId(currentFav.media_type_id) == "image") favImages.push(currentFav);
                if (GetMediaTypeFromId(currentFav.media_type_id) == "map") favMaps.push(currentFav);
                if (GetMediaTypeFromId(currentFav.media_type_id) == "audio") favAudio.push(currentFav);
                if (GetMediaTypeFromId(currentFav.media_type_id) == "interactive_exercise") favIntExercises.push(currentFav);
            }
        let popularVideosBlock =await getOfferDivs(this.props.history, this.props.location, __("Videók"), "video", favVideos.slice((this.state.pageIndex - 1) * ITEMSPERPAGE, this.state.pageIndex * ITEMSPERPAGE),!this.state.extendedList,"video");
        let popularImagesBlock = await getOfferDivs(this.props.history, this.props.location, __("Képek"), "image", favImages.slice((this.state.pageIndex - 1) * ITEMSPERPAGE, this.state.pageIndex * ITEMSPERPAGE),!this.state.extendedList,"image");
        let popularMapsBlock= await getOfferDivs(this.props.history, this.props.location, __("Térképek"), "video", favMaps.slice((this.state.pageIndex - 1) * ITEMSPERPAGE, this.state.pageIndex * ITEMSPERPAGE),!this.state.extendedList,"map")
        let popularIntExercisesBlock =await getOfferDivs( this.props.history, this.props.location, __("Interaktív feladatok"),"video", favIntExercises.slice((this.state.pageIndex - 1) * ITEMSPERPAGE, this.state.pageIndex * ITEMSPERPAGE),!this.state.extendedList,"interactive_exercise");
        let soundOffersBlock= await getOfferDivs( this.props.history, this.props.location, __("Hanganyagok"),"audio", favAudio.slice((this.state.pageIndex - 1) * ITEMSPERPAGE, this.state.pageIndex * ITEMSPERPAGE),!this.state.extendedList,"audio");


            this.setState({
                loading: false,
                popularVideosBlock,
                popularImagesBlock,
                popularMapsBlock,
                popularIntExercisesBlock,
                soundOffersBlock,
                Videos:favVideos,
                Images:favImages,
                Maps:favMaps,
                Exercises:favIntExercises,
                soundOffers:favAudio,       
            });
        }
    }


    render() {
        const params = queryString.parse(this.props.location.search);
        if (this.state.loading) {
            return <div className="row"><BubbleLoader /></div>;
        }
        let hitCount=0;
        if(params.type=="media"||!params.type)
        {
        hitCount =this.state.Images.length+this.state.Exercises.length+this.state.Maps.length+this.state.Videos.length+this.state.soundOffers.length;
        }
        else
        { 
            if(params.type=="video") hitCount=this.state.Videos.length;
            if(params.type=="image") hitCount=this.state.Images.length;
            if(params.type=="map") hitCount=this.state.Maps.length;
            if(params.type=="audio") hitCount=this.state.soundOffers.length;
            if(params.type=="interactive_exercise") hitCount=this.state.Exercises.length;
        }
        let pageCount: number = Math.ceil(hitCount / ITEMSPERPAGE);
        let pageIndex = this.state.pageIndex;
        var pageIndents: JSX.Element[] = [];
      
        for (var i = 1; i <= pageCount; i++) {
            pageIndents.push(<h2 key={i}>
                <button className={pageIndex == i ? "active" : undefined} onClick={this.setPageIndex.bind(null, i)} >
                    {i}
                </button>
            </h2>);
        }
        return <div>

            <div className="row">
                <h2 className="shrink column"><strong>{__("{n} találat", {n: hitCount})}</strong></h2>
            </div>
            <div className="column small-12">
                {(!params.type || params.type == "media" || params.type == "video") && this.state.popularVideosBlock}
                {(!params.type || params.type == "media" || params.type == "image") && this.state.popularImagesBlock}
                {(!params.type || params.type == "media" || params.type == "map") && this.state.popularMapsBlock}
                {(!params.type || params.type == "media" || params.type == "audio") && this.state.soundOffersBlock}
                {(!params.type || params.type == "media" || params.type == "audio") && this.state.popularIntExercisesBlock}
            </div>
            <div className="search-result-list">
                        <div className="pagination columns text-center">
                            {hitCount > ITEMSPERPAGE && this.state.extendedList&& pageIndents}
                        </div>
                    </div>
        </div>;
    }
}
