import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapTrainingTypeRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Kód
     */
    code?: string;
    /**
     * Cím
     */
    title?: string;
    /**
     * Engedélyzám
     *
     * Akkreditációs engedély száma
     */
    license_number?: string|null;
    /**
     * A képzés elvégzése melyik csoport tagjává tesz
     */
    sec_group_id?: number|null;
    /**
     * Melyik alprogramhoz tartozik a képzés
     */
    subprogram_id?: number|null;
    /**
     * Könyv
     */
    book_id?: number|null;
    /**
     * Melyik csoport jogosít föl ilyen típusú képzésre
     */
    trainer_group_id?: number|null;
    /**
     * Tanúsítványszám prefix
     */
    certno_prefix?: string;
    /**
     * Leírás
     */
    description_html?: string|null;
    /**
     * Igazolás sorszám prefix
     */
    verno_prefix?: string;
    /**
     * Kimeneti követelmények
     */
    fulfillment_conditions_html?: string|null;
    /**
     * Mappa
     *
     * A képzés típussal kapcsolatos dokumentumok vannak itt tárolva.
     */
    oo_folder_id?: number;
    /**
     * Online képzés
     */
    is_online?: boolean;
    /**
     * Sablon kód
     *
     * PDF generálásokhoz.
     */
    template_code?: string;
    /**
     * Sorrend
     */
    no?: number /*int4*/;
    /**
     * Site
     */
    site_id?: number;
    /**
     * Jogosultság csoport neve
     *
     * Jogosultság csoport neve
     */
    sec_group_title?: string|null;
    /**
     * Alprogram neve
     *
     * Alprogram neve
     */
    subprogram_title?: string|null;
    /**
     * Könyv neve
     *
     * Könyv neve
     */
    book_name?: string|null;
    /**
     * Könyv URI szegmens
     *
     * Könyv URI szegmens
     */
    book_uri_segment?: string|null;
}

/**
 *  kap.view_kap_training_type - Képzés típus
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewKapTrainingType extends View<IViewKapTrainingTypeRecord> {
    public static VIEW_INFO_ID = 2019112501;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_training_type'; }
    public static getTableNameForClass(): string|null { return 'training_type'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapTrainingType>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapTrainingTypeRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapTrainingTypeClassProxy extends ViewClassProxy<IViewKapTrainingTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapTrainingTypeClassProxy = new ViewKapTrainingTypeClassProxy(ViewKapTrainingType);
registerViewClassProxy(viewKapTrainingTypeClassProxy);

