import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IKipAspectRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * KIP értékelési szempont típusa
     *
     * NOT NULL -> kap.kip_aspect_type ON DELETE noaction
     *
     */
    kip_aspect_type_id?: number;
    /**
     * Szülő kérdés
     *
     * -> kap.kip_aspect ON DELETE cascade
     *
     *
     * Az alkérdés csak akkor engedélyezett, ha a szülő kérdésre a válasz logikai igaz.
     */
    parent_id?: number|null;
    /**
     * Megnevezés
     */
    title?: string;
}

/**
 *  kap.kip_aspect - KIP értékelési szempont
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class KipAspectCrud extends Crud<IKipAspectRecord> {
    public static TABLE_INFO_ID = 3503311293;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'kip_aspect'; }

    public static load: (id: number, aServer ?: Server) => Promise<KipAspectCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IKipAspectRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IKipAspectRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IKipAspectRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IKipAspectRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IKipAspectRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class KipAspectCrudClassProxy extends CrudClassProxy<IKipAspectRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const kipAspectCrudClassProxy = new KipAspectCrudClassProxy(KipAspectCrud);
registerCrudClassProxy(kipAspectCrudClassProxy);
