import * as React from 'react';
import { zeroPad, formatDateWithoutTime } from '@src/Util';
import { MonthCalendar } from '@src/component/calendar/MonthCalendar';
import ViewSharedWithMe, { IViewSharedWithMeRecord } from '@src/framework/view/usr/ViewSharedWithMe';
import { app } from '@src/index';
import { CalendarSource } from '@src/store/CalendarSource';
import  { IDayItem } from '@src/component/calendar//CalendarAPI';
import { CalendarNavigator } from '../calendar/CalendarNavigator';
import { IReactionDisposer, reaction, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { BubbleLoader } from 'react-css-loaders';

interface IDateTileState {
    dateTime: any;
    switch: boolean;
    loading: boolean;
    items: IViewSharedWithMeRecord[];
}

const calendarSource = new CalendarSource();

type DueDays = { [date: string]: IViewSharedWithMeRecord[] };

// By specifying "observer", the component will render itself automatically when tracked observable values are changed.
@observer
export default class DateTile extends React.Component<any, IDateTileState> {
    private reloadDisposer: IReactionDisposer | null = null;

    /** SharedWithMe records, indexed by due dates. */
    @observable dueDays: DueDays = {};

    constructor(props: any) {
        super(props);
        this.state = {
            dateTime: new Date(),
            switch: false,
            loading: true,
            items: []
        };
        calendarSource.firstLoad(); // Load the data first, if it has never been loaded yet.
    }

    private intervalID : any;
    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            1000
          );
  
        // Reload due days when the calendar date range is changed.
        this.reloadDisposer = reaction(
            () => [calendarSource.startDate, calendarSource.endDate], // If these properties are changed
            this.reloadAsync, // Then run this method
            { delay: 500 } // Debounce time 500msec
        )
        this.reloadAsync();
    }

    componentWillUnmount() {
        // Stop listening to date range changes
        if (this.reloadDisposer != null) {
            this.reloadDisposer();
            this.reloadDisposer = null;
        }
        clearInterval(this.intervalID);
    }

    reloadAsync = async () => {
        this.setState({ loading: true });
        try {
            const fromDay = new Date(calendarSource.startDate);
            const toDay = new Date(calendarSource.endDate);
            fromDay.setHours(0, 0, 0, 0);
            toDay.setHours(23, 59, 59, 999);
            const filter = { is_active: true, ">=": { due_date: fromDay.getTime() }, "<=": { due_date: toDay.getTime() } };
            const dueTodayRecords = await ViewSharedWithMe.list({ filter });
            const dueDays: DueDays = {};
            dueTodayRecords.forEach((record: IViewSharedWithMeRecord) => {
                const key = formatDateWithoutTime(record.due_date!);
                if (!dueDays[key]) {
                    dueDays[key] = [];
                }
                dueDays[key].push(record);

            });
            runInAction(() => { this.dueDays = dueDays; })
            this.setState({ loading: false });
        } catch (e) {
            this.setState({ loading: false });
            app.showErrorFromJsonResult(e);
        }
    }

    tick() {
        this.setState({ dateTime: new Date() });
    }

    switch() {
        if (this.state.switch) {
            this.setState({ switch: false })
        } else {
            this.setState({ switch: true })
        }

    }

    private renderActivitySummary = (item: IDayItem) => {
        const key = formatDateWithoutTime(item.date);
        const events = this.dueDays[key];
        if (events) {
           // return <div className="eke-calendar-day-activity-summary">{events.length}</div>;
           return [events.length + " leadási határidő"]
        } else {
            return null;
        }
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />
        }
        if (!this.state.switch) {
            return <div key="date-tile" className="date-tile">
                <div className="switch"><button className="switch-button" onClick={this.switch.bind(this)}><i className="far fa-calendar-alt"></i></button></div>
                <div className="time">
                    {this.state.dateTime.getHours()}:
                    {zeroPad(this.state.dateTime.getMinutes(), 2)}:
                    {zeroPad(this.state.dateTime.getSeconds(), 2)}
                </div>
                <div className="date">
                    {this.state.dateTime.getFullYear()}.
                    {zeroPad(this.state.dateTime.getMonth() + 1, 2)}.
                    {zeroPad(this.state.dateTime.getDate(), 2)}.
                </div>
                <div className="due-today">{

                }
                </div>
            </div>
        }
        else {
            return <div key="date-tile" className="date-tile">
                <div className="switch"><button className="switch-button" onClick={this.switch.bind(this)}><i className="far fa-clock"></i></button></div>
                <div className="calendar">
                    {/*<CalendarNavigator calendarMonthSource={calendarSource} />*/}
                    <MonthCalendar isSmall={true} calendarMonthSource={calendarSource} 
                    dayItemRenderer={this.renderActivitySummary} 
                    forceSize={this.props.settings.size} />
                </div>
            </div>
        }
    }
}