import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewBookRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Könyvtár
     */
    library_id?: number;
    /**
     * Évfolyam
     */
    grade_id?: number;
    /**
     * Tantárgy
     */
    subject_id?: number;
    /**
     * Tulajdonos/felelős
     */
    owner_id?: number;
    /**
     * URL rész
     *
     * URL path (útvonal) szegmense
     */
    uri_segment?: string|null;
    /**
     * A könyv gyökér könyvtára.
     */
    oo_folder_id?: number|null;
    /**
     * Szlogen
     *
     * A könyv szlogenje
     */
    slogan?: string|null;
    /**
     * Leckék tervezett száma
     */
    planned_lessons?: number /*int4*/|null;
    /**
     * Műveltségi terület
     */
    literacy_field_id?: number|null;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* float8 */|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
    /**
     * Stílus
     */
    style_id?: number;
    /**
     * SNI
     *
     * Sajátos nevelési igényűek számára készített könyv
     */
    is_sni?: boolean;
    /**
     * Nyelv
     *
     * A könyv nyelve
     */
    lang_id?: number;
    /**
     * Könyv megjelenítési mód
     */
    display_mode_id?: number;
    /**
     * Site
     */
    site_id?: number;
    /**
     * Intézmény típus
     */
    institute_type_id?: number|null;
    /**
     * Könyvtár
     *
     * Könyvtár
     */
    library_name?: string|null;
    /**
     * Évfolyam
     *
     * A feladatsort milyen évfolyamnak szánták
     */
    grade_name?: string|null;
    /**
     * Tantárgy
     *
     * A feladatsort milyen tantárgyhoz tartozik
     */
    subject_name?: string|null;
    /**
     * Tudományterület
     *
     * Tudományterület
     */
    literacy_field_name?: string|null;
    /**
     * Stílus
     *
     * Stílus
     */
    style_title?: string|null;
    /**
     * Kijelzési mód neve
     *
     * Kijelzési mód neve
     */
    display_mode_title?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    wf_workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Folyamat állapot leírása
     *
     * Folyamat állapot leírása
     */
    wf_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Folyamat állapot stílusa, jsonb/css
     */
    wf_station_style?: any|null;
    /**
     * Folyamat al-státusz azonosító
     *
     * Folyamat al-státusz azonosító
     */
    wf_substation_id?: number|null;
    /**
     * Folyamat al-státusz
     *
     * Folyamat al-státusz
     */
    wf_substation_name?: string|null;
    /**
     * Folyamat al-státusz leírása
     *
     * Folyamat al-státusz leírása
     */
    wf_substation_description?: string|null;
    /**
     * Folyamat szöveges állapot kiegészítés
     *
     * Folyamat szöveges állapot kiegészítés
     */
    wf_status_text?: string|null;
    /**
     * Publikálva van
     *
     * Publikálva van
     */
    is_published?: boolean|null;
    /**
     * Utoljára publikálva
     *
     * Utoljára publikálva
     */
    last_published?: string /*timestamp?*/|null;
    /**
     * Utolsó publikáló
     *
     * Utolsó publikáló
     */
    last_publisher_id?: number|null;
    /**
     * Intézmény típus név
     *
     * Intézmény típus név
     */
    institute_type_title?: string|null;
    /**
     * Utolsó publikáló
     */
    last_publisher?: string|null;
}

/**
 *  doc.view_book - Könyv
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewBook extends View<IViewBookRecord> {
    public static VIEW_INFO_ID = 2019010901;

    public static getSchemaNameForClass(): string { return 'doc'; }
    public static getViewNameForClass(): string { return 'view_book'; }
    public static getTableNameForClass(): string|null { return 'book'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewBook>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewBookRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewBookClassProxy extends ViewClassProxy<IViewBookRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewBookClassProxy = new ViewBookClassProxy(ViewBook);
registerViewClassProxy(viewBookClassProxy);

