import * as React from 'react';
import ReactTable, { TableProps, RowInfo, Column } from 'react-table'
import 'react-table/react-table.css'
import { getReactTableLabels } from '@src/framework/i18n';
import { IResultRecord } from '@src/framework/crud/spell/ResultCrud';
import { bookCrudClassProxy } from '@src/framework/crud/doc/BookCrud';
import { app } from '@src/index';
import CrudSelect from '@src/framework/forms/crudselect';
import ViewBrokenLink, { IViewBrokenLinkRecord } from '@src/framework/view/doc/ViewBrokenLink';
import { StringSearchKind, IListParams } from '@src/framework/crud/Crud';
import { PATH_PUBLISHED_BOOK } from '@src/Routes';
import { Dialog } from '@src/component/Dialog';
import ViewSectionLink from '@src/framework/view/doc/ViewSectionLink';
import ViewSupplementaryMaterialLink from '@src/framework/view/doc/ViewSupplementaryMaterialLink';
import { __ } from '@src/translation';

type OFIBrokenLinksPageProps = {
}
interface OFIBrokenLinksPageState {
    count: number;
    pageSize: number;
    loading: boolean;
    results: IResultRecord[];
    resultId?: number,
    selectedBook: number | null,
    brokenLinks: IViewBrokenLinkRecord[];
    tableState?: any;
    isBrokenLink?: boolean;
    isExternalLink?: boolean;
    urlkeywords?: string;
}

export default class OFIBrokenLinksPage extends React.Component<OFIBrokenLinksPageProps, OFIBrokenLinksPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            count: 0,
            pageSize: 20,
            loading: false,
            brokenLinks: [],
            results: [],
            selectedBook: null,
            isBrokenLink: undefined,
            isExternalLink: true,
            tableState: { pageSize: 20, page: 0 }
        };
    }

    componentDidMount() {
        this.onFetchData(null);
    }
    async onFetchData(tableState: any) {
        if (!tableState) tableState = this.state.tableState;

        this.setState({
            loading: true,
            tableState: tableState
        })

        try {
            var brokenLinks: IViewBrokenLinkRecord[];
            const params: IListParams = {
                filter: {
                    broken: this.state.isBrokenLink,
                    external: this.state.isExternalLink,
                    book_id: this.state.selectedBook == null ? undefined : (Number)(this.state.selectedBook),
                    url: this.state.urlkeywords ? { kind: StringSearchKind.Contains, case_sensitive: false, expr: this.state.urlkeywords } : undefined
                },
                limit: tableState.pageSize,
                offset: tableState.page * tableState.pageSize
            };

            if (tableState.sorted && tableState.sorted.length > 0) {
                const sorted = tableState.sorted[0];
                params.order_by = [{ name: sorted.id, desc: sorted.desc }];
            }

            brokenLinks = await ViewBrokenLink.list(params);
            const count = await ViewBrokenLink.count(params);
            this.setState({
                brokenLinks: brokenLinks,
                loading: false,
                pageSize: tableState.pageSize,
                count
            });
        }
        catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        var brokenLinks = this.state.brokenLinks.map(r => {
            return {
                ...r,
                url: <a href={r.url || undefined}>{r.url}</a>,
                broken: r.broken_last_checked != null ? (r.broken ? <span style={{ color: "red" }}>{__("Igen")}</span> : __("Nem")) : "",
                external: r.external ? __("Igen") : __("Nem"),
                bookLinks: <BookLinks key={r.id} linkId={r.id!} />
            }
        }
        );

        const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};
            if (column && rowInfo && rowInfo.row) {
                let row: any = rowInfo.row._original;
            }
            return props;
        }

        const tableProps = {
            columns: [
                { Header: "URL", accessor: "url", filterable: false },
                { Header: __("Könyv neve"), accessor: "book_name", filterable: false, maxWidth: 150, style: { justifyContent: "center" } },
                { Header: __("Hibás"), accessor: "broken", filterable: false, maxWidth: 120, style: { justifyContent: "center" } },
                { Header: __("Külső link"), accessor: "external", filterable: false, maxWidth: 120, style: { justifyContent: "center" } },
                { Header: __("Helye a könyvben"), accessor: "bookLinks", filterable: false, sortable: false },
            ],

            data: brokenLinks,
            defaultPageSize: this.state.pageSize,
            pages: Math.ceil(this.state.count / this.state.pageSize),
            filterable: true,
            className: "-striped -highlight ",
            getTdProps,
            ...getReactTableLabels(),
            onFetchData: this.onFetchData.bind(this),
            loading: this.state.loading,
            manual: true
        }

        const filterComponent =
            <div className="row align-center">
                <div className="column small-6 medium-6 large-3" style={{ paddingRight: "0" }}>
                    <label style={{ margin: "0", fontSize: "1rem", fontWeight: "bold", color: "#6f6f6f" }}>{__("Hibás link")}</label>
                    <select style={{ fontSize: "0.875rem", fontWeight: "normal", color: "#6f6f6f", lineHeight: "1.8" }} onChange={this.onBrokenSelect.bind(this)} value={this.state.isBrokenLink != undefined ? this.state.isBrokenLink.toString() : "undefined"}>
                        <option value={"true"}>{__("Csak hibás link")}</option>
                        <option value={"false"}>{__("Csak működő link")}</option>ggg
            <option value={"undefined"}>{__("Mind")}</option>
                    </select>
                </div>
                <div className="column small-6 medium-6 large-3" style={{ paddingRight: "0" }}>
                    <label style={{ margin: "0", fontSize: "1rem", fontWeight: "bold", color: "#6f6f6f" }}>{__("Külső hivatkozás")}</label>
                    <select style={{ fontSize: "0.875rem", fontWeight: "normal", color: "#6f6f6f", lineHeight: "1.8" }} onChange={this.onExternalSelect.bind(this)} value={this.state.isExternalLink != undefined ? this.state.isExternalLink.toString() : "undefined"}>
                        <option value={"true"}>{__("Csak külső hivatkozás")}</option>
                        <option value={"false"}>{__("Csak belső hivatkozás")}</option>
                        <option value={"undefined"}>{__("Mind")}</option>
                    </select>
                </div>
                <div className="column small-12 medium-12 large-3" style={{ paddingRight: "0" }}>
                    <label style={{ margin: "0", fontSize: "1rem", fontWeight: "bold", color: "#6f6f6f" }}>{__("Könyv")}</label>
                    <CrudSelect
                        value={this.state.selectedBook}
                        onSelect={this.onBookSelect}
                        displayFieldName="name"
                        emptyTitle={__("Minden könyv")}
                        crudClassProxy={bookCrudClassProxy}
                        filter={{ is_active: true }}
                    />
                </div>
            </div>

        const searchbar = <div className="row align-center">
            <div className="small-12 medium-12 large-9 column" style={{ paddingRight: "0" }}>
                <label className="lms-pages-typeLabel">{__("Szabadszavas url")}</label>
                <div className="row">
                    <input onKeyPress={this.onKeyPress.bind(this)} onChange={this.onKeywordChange.bind(this)} value={this.state.urlkeywords ? this.state.urlkeywords : ""} className="exerciseList-option small-10 medium-10 large-11 column" type="text" />
                    <button className="button small  small-2 medium-2 large-1" disabled={this.state.loading} onClick={this.refreshBtnClick.bind(this)}>
                        <i className="fa fa-sync" />
                    </button>      </div>
            </div>
        </div>
        const reacttablecomponent = <div className="row expanded">
            <div className="small-12 medium-12">
                <div style={{ display: "inline-flex" }}>
                    <label style={{ marginRight: "1rem" }} className="result-count">&nbsp;{this.state.count ? __("{n} találat", {n: this.state.count}) : ""}</label>
                </div>
                <ReactTable {...tableProps} />
            </div>
        </div>
        return <div>
            {filterComponent}
            {searchbar}
            {reacttablecomponent}
        </div>;
    }

    private onBookSelect = (sender: CrudSelect, newBook: number | null): void => {
        this.setState({ selectedBook: newBook == 0 ? null : newBook }, this.onFetchData.bind(this));
    }
    private refreshBtnClick(e: any) {
        this.onFetchData(null);
    }
    private onBrokenSelect(event: any) {
        let selectedBroken = event.target.value;
        if (event.target.value == 'true') { selectedBroken = true; }
        else if (event.target.value == 'false') { selectedBroken = false }
        else { selectedBroken = undefined }
        this.setState({ isBrokenLink: selectedBroken }, this.onFetchData.bind(this));
    }
    private onExternalSelect(event: any) {
        let selectedExternal;
        if (event.target.value == 'true') { selectedExternal = true; }
        else if (event.target.value == 'false') { selectedExternal = false }
        else { selectedExternal = undefined }
        this.setState({ isExternalLink: selectedExternal }, this.onFetchData.bind(this))
    }
    private onKeywordChange(e: any) {
        this.setState(
            { urlkeywords: e.target.value }
        );
    }
    private onKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.onFetchData(null);
        }
    }
}

class BookLinks extends React.Component<{ linkId: number }, { links: string[], dialogOpen: boolean }> {

    constructor(props: any) {
        super(props);

        this.state = {
            links: [],
            dialogOpen: false
        };
    }

    async componentDidMount() {
        const links: string[] = [];

        const docviewsections = await ViewSectionLink.list({ filter: { link_id: this.props.linkId } });

        for (const s of docviewsections) {
            const link = PATH_PUBLISHED_BOOK + "/" + s.section_edit_url;
            links.push(link);
        }

        const suppmatlinks = await ViewSupplementaryMaterialLink.list({ filter: { link_id: this.props.linkId } });

        for (const s of suppmatlinks) {
            const link = PATH_PUBLISHED_BOOK + "/" + s.lesson_edit_url;
            links.push(link);
        }
        this.setState({ links });
    }

    render() {
        var dialog = null;

        if (this.state.links.length > 1) {
            dialog = <span>
                <button className="button small" onClick={() => this.setState({ dialogOpen: true })} style={{ marginRight: "1em" }}>{__("További")} {this.state.links.length} db</button>
                {
                    this.state.dialogOpen
                        ?
                        <Dialog title={__("Linkek")} onClose={() => this.setState({ dialogOpen: false })}>
                            <div className="row">
                                <table>
                                    <thead>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.links.map(l => <tr><td><a href={l} target="_new">{l}</a></td></tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Dialog>
                        :
                        null
                }
            </span>
        }

        return <div>
            {dialog}
            {
                this.state.links.length >= 1
                    ?
                    <a href={this.state.links[0]} target="_new">{this.state.links[0]}</a>
                    :
                    null
            }
        </div>;
    }
}
