import * as React from 'react';

import { IRecord, getCrudClassProxyById } from '@src/framework/crud/Crud';
import { IFieldInfo, IFieldInfoTranslation } from '@src/framework/crud/Meta';
import './forms.css';
import getLangId from '@src/framework/i18n/const';

import Checkbox from './checkbox';
import LookupEdit from './lookupedit';
import SecUserSelector from '@framework/forms/SecUserSelector';
import SecUserCrud from '../crud/sys/SecUserCrud';
import DateTimePicker from '@src/framework/forms/datetimepicker';
import CrudSelectComponent from './crudselect';
const NumericInput = require('react-numeric-input');

export interface IFormInputProps<TRecord extends IRecord> {
    value : any;
    fieldInfo : IFieldInfo;
    onFieldChange : (fieldName: string, value:string|number|null) => void;
    emptyIsNull ?: boolean;
    autoTrim ?: boolean;
    isNewRecord : boolean;
    disabled ?: boolean;
    className ?: string;
    forceCrudSelect ?: boolean; // Force CrudSelect instead of LookupEdit
    displayFieldName ?: string; // Csak akkor, ha forceCrudSelect igaz.
}

interface IFormInputState {    
}


/**
 * Ez egy magasabb rendű komponens, ami egy fieldInfo és a érték alapján
 * létrehoz egy form-on használható összetett komponenst, a következőkkel:
 * 
 * - címke
 * - szerkesztő elem (input mező, checkbox, dátum input, lookup editor stb.)
 * 
 */
export class FormInput<TRecord extends IRecord> extends React.Component<IFormInputProps<TRecord>, IFormInputState> {

    constructor(props: IFormInputProps<TRecord>) {
        super(props);
        this.state = {};
    }


    protected getEmptyIsNull() : boolean {
        if (this.props.emptyIsNull === undefined)
            return true;
        else
            return this.props.emptyIsNull;
    }

    protected getAutoTrim() : boolean {
        if (this.props.autoTrim === undefined)
            return true;
        else
            return this.props.autoTrim;
    }

    private onChange = (event: any) => {
        this.onValueChange(event.target.value);
    }

    private onValueChange = (newValue: any) => {
        if (this.getAutoTrim() && (typeof newValue === 'string'))
            if (newValue.trim()==='')
                newValue = '';
        if (this.getEmptyIsNull() && newValue==='')
            newValue = null;
        
        this.props.onFieldChange(
                this.props.fieldInfo.field_name,
                newValue
        );
    }

    private onCrudSelectSelect = (sender: CrudSelectComponent, newValue:number|null) => {
        this.props.onFieldChange(
            this.props.fieldInfo.field_name,
            newValue        
        );
    }


    private getDefClassName(className: string|undefined, defVal:string) : string {
        className = className || defVal;        
        if (className=="full") {
            return "small-12 medium-12 column";
        } else if (className=="half") {
            return "small-12 medium-6 column";
        } else if (className=="onethird" || className=="third") {
            return "small-12 medium-4 column";
        } else {
            return className
        }
    }


    protected calcFlags(newValue: any) : JSX.Element[] {
        const fieldInfo : IFieldInfo = this.props.fieldInfo;
        let result : JSX.Element[] = [];

        let cls = "fa ";
        if (newValue!==null && newValue!==undefined) {
            cls += "fa-check forminput-label-ok";
        } else if (fieldInfo.not_null) {
            cls += "fa-asterisk forminput-label-error"
        } else {
            cls += "fa-times forminput-label-empty"
        }
        result.push(<i key={"req_" + fieldInfo.field_name}
        className={cls}/>);
        /*        
        if (fieldInfo.not_null) {
            let cls = "fa ";
            if (newValue===null) {
                cls += "fa-asterisk forminput-label-error"
            } else {
                cls += "fa-check forminput-label-ok"
            }
            result.push(<i key={"req_" + fieldInfo.field_name}
                className={cls}/>);
        }
        */

        if (fieldInfo.orig_type=='json' || fieldInfo.orig_type=='jsonb') {
            if (typeof newValue == 'string' ) {
                try {
                    newValue = JSON.parse(newValue);
                } catch (e) {
                    result.push(<i 
                        key={"jsonb_format_" + fieldInfo.field_name}
                        className="fa fa-exclamation-triangle forminput-label-warning"/>);
                }            
            }
        }
        return result;
    }    

    render() {
        const fname = this.props.fieldInfo.field_name;
        const value = this.props.value;
        const fieldInfo : IFieldInfo = this.props.fieldInfo;
        const translation : IFieldInfoTranslation = 
            fieldInfo.translations[getLangId()];        
        let type : string;
        let required : boolean;
        let disabled : boolean = this.props.disabled || false;
        let labelClassName : string = '';
        required = fieldInfo.not_null;
        let defClassName = 'full';
        if (!this.props.isNewRecord && fieldInfo.is_immutable) {
            disabled = true;
        }
        let input : JSX.Element|null = null;
        // select distinct orig_type from meta.field_info order by 1;
        // https://foundation.zurb.com/sites/docs/forms.html
        switch (fieldInfo.orig_type) {
            case 'bigint':
            case 'double precision':
            case 'integer':
                type = 'number';
                if (fieldInfo.fk_table_info_id) {
                    if (fieldInfo.fk_table_info_id==SecUserCrud.TABLE_INFO_ID) {
                        input = <SecUserSelector
                            value={value}
                            disabled={disabled}
                            clearable={!fieldInfo.not_null}
                            onChange={this.onValueChange}
                        />
                    } else {                        
                        if (this.props.forceCrudSelect) {
                            if (!this.props.displayFieldName) {
                                throw new Error("Ha forceCrudSelect meg van adva akkor displayFieldName is kell. TODO: lookupresultindex");
                            }
                            const crudClassProxy = getCrudClassProxyById(fieldInfo.fk_table_info_id);
                            if (!crudClassProxy) {
                                throw new Error("getCrudClassProxyById("+fieldInfo.fk_table_info_id+") értéke undefined, importáld be kézzel, ki lett optimizálva. :-(");
                            }
                            input = <CrudSelectComponent
                                value={value}
                                onSelect={this.onCrudSelectSelect}
                                crudClassProxy={crudClassProxy}
                                disabled={disabled}
                                clearable={!fieldInfo.not_null}
                                displayFieldName={this.props.displayFieldName!}
                            />;                       
                        } else {
                            input = <LookupEdit 
                                id={`input_${fname}`}
                                key={`input_${fname}_${value}`}
                                value={value} 
                                disabled={disabled}
                                clearable={!fieldInfo.not_null}
                                onChange={this.onValueChange}
                                fieldInfo={fieldInfo}
                            />
                        }
                    }
                } else {
                    input = <NumericInput
                        id={`input_${fname}`}
                        key={`input_${fname}_${value}`}
                        value={value} 
                        disabled={disabled}
                        onChange={this.onValueChange}
                    />
                }
                break;
            case 'date':
                type = 'datetime-local';                
                break;
            case 'timestamp':
                type = 'text';
                input = <DateTimePicker
                    id={`input_${fname}`}
                    key={`input_${fname}_${value}`}
                    value={value} 
                    disabled={disabled}
                    onChange={this.onValueChange}
                />
                break;
            case 'boolean':
                type = 'checkbox';
                defClassName = 'third';
                input = <Checkbox
                    id={`input_${fname}`}
                    key={fname}
                    value={value} 
                    disabled={disabled}
                    onValueChange={this.onValueChange}
                />
               break;
            case 'json':
            case 'jsonb':
                type='text';
                defClassName='full';
            default:
                type = 'text';
        }        
        const isJson = ['json','jsonb'].includes(fieldInfo.orig_type);
        let inputClassName : string = '';
        if (value) {
            inputClassName += ' forminput-valid';
        } else if (required) {
            inputClassName += ' forminput-missing';
        } else {
            inputClassName += ' forminput-empty';
        }
        if (!input) {
            let svalue;
            if (isJson && (typeof value =='object') ) {
                svalue = JSON.stringify(value);
            }
            else if (value===null || value===undefined) {
                svalue = '';
            } else  {
                svalue = value.toString();
            }
            input = <input 
                id={`input_${fname}`}
                key={fname}
                name={fname} 
                className={inputClassName} 
                type={type} 
                value={svalue} 
                onChange={this.onChange} 
                disabled={disabled}
            />
        }

        const labeltext : string = translation.display_name;
        let labelContent;
        if (translation.description) {
            labelContent = <span
            data-tooltip 
            aria-haspopup={true}
            className="has-tip"
            tabIndex={1}
            data-disable-hover={false}
            title={translation.description}
            >{labeltext}</span>;
        } else {
            labelContent = <span>{labeltext}</span>
        }
        const label = <label id={`label_${fname}`} htmlFor={`input_${fname}`} className={labelClassName}>
            {labelContent} {this.calcFlags(this.props.value)}</label>
        return <div key={fname} className={this.getDefClassName(this.props.className, defClassName)}>{label}{input}</div>
    }
}