import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewWfWorkflowRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Folyamat típus
     */
    wf_type_id?: number;
    /**
     * Folyamat fej táblázat
     */
    head_table_id?: number;
    /**
     * Táblázat infó
     *
     * Ez mondja meg, hogy a folyamat melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number;
    /**
     * Rekord azonosító
     *
     * Ez mondja meg, hogy a folyamat melyik rekordhoz tartozik.
     */
    rec_id?: number;
    /**
     * Állomás
     *
     * A folyamat aktuális állomása.
     */
    station_id?: number;
    /**
     * Utolsó átmenet
     */
    last_transition_id?: number|null;
    /**
     * Utolsó feldolgozott átmenet
     */
    proc_transition_id?: number|null;
    /**
     * Al-státusz
     */
    substation_id?: number|null;
    /**
     * Szöveges állapot kiegészítés
     */
    status_text?: string|null;
    /**
     * Utolsó publikáló
     */
    last_publisher_id?: number|null;
    /**
     * Utoljára publikálva
     */
    last_published?: string /*timestamp?*/|null;
    /**
     * Típuskód
     *
     * A folyamat típus kódja
     */
    wf_type_code?: string|null;
    /**
     * Típusnév
     *
     * A folyamat típus neve
     */
    wf_type_name?: string|null;
    /**
     * Séma neve
     *
     * A fejtábla sémájnak neve
     */
    schema_name?: string|null;
    /**
     * Tábla neve
     *
     * A fejtábla neve
     */
    table_name?: string|null;
    /**
     * Tábla címe
     *
     * A fejtábla (emberi) neve
     */
    table_display_name?: string|null;
    /**
     * Tábla leírása
     *
     * A tábla leírása
     */
    table_description?: string|null;
    /**
     * Állomás aktív
     *
     * Állomás aktív (logikai törlés)
     */
    station_is_active?: boolean|null;
    /**
     * Állomás sorszám
     *
     * Állomás sorszám
     */
    station_no?: number /*int4*/|null;
    /**
     * Állomás név
     *
     * Állomás név
     */
    station_name?: string|null;
    /**
     * Állomás leírás
     *
     * Állomás leírás
     */
    station_description?: string|null;
    /**
     * Állomás stílus
     *
     * Állomás stílus
     */
    station_style?: any|null;
    /**
     * Kezdő
     *
     * Kezdőállapotban van
     */
    is_start?: boolean|null;
    /**
     * Lezárt
     *
     * Lezárt állapotban van
     */
    is_closed?: boolean|null;
    /**
     * Elszámolható
     *
     * Elszámolható állapotban van
     */
    is_eligible?: boolean|null;
    /**
     * Al-státusz kötelező
     *
     * Al-státusz kötelező
     */
    substation_required?: boolean|null;
    /**
     * Al-státusz név
     *
     * Al-státusz név
     */
    substation_name?: string|null;
    /**
     * Al-státusz leírás
     *
     * Al-státusz leírás
     */
    substation_description?: string|null;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Leírás
     *
     * A folyamathoz kapcsolódó adat rövid leírása
     */
    displayvalue?: string|null;
}

/**
 *  wf.view_wf_workflow - Folyamat
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewWfWorkflow extends View<IViewWfWorkflowRecord> {
    public static VIEW_INFO_ID = 2018040508;

    public static getSchemaNameForClass(): string { return 'wf'; }
    public static getViewNameForClass(): string { return 'view_wf_workflow'; }
    public static getTableNameForClass(): string|null { return 'workflow'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewWfWorkflow>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewWfWorkflowRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewWfWorkflowClassProxy extends ViewClassProxy<IViewWfWorkflowRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewWfWorkflowClassProxy = new ViewWfWorkflowClassProxy(ViewWfWorkflow);
registerViewClassProxy(viewWfWorkflowClassProxy);

