import * as React from 'react';
import { IViewKapCourseRecord } from '@src/framework/view/kap/ViewKapCourse';
import CourseStats from '../../adminPages/CourseStats';
import ProgressionPage from '@src/component/progression/ProgressionPage';
import { IViewKapTrainingTypeRecord } from '@src/framework/view/kap/ViewKapTrainingType';

type CourseDetailsStatsPageProps = {
    courseRecord: IViewKapCourseRecord;
    isStudent: boolean;
    trainingType: IViewKapTrainingTypeRecord;
}

export class CourseDetailsStatsPage extends React.Component<CourseDetailsStatsPageProps> {

    render() {
        let bookId = this.props.trainingType.book_id;
        return <div>
            <CourseStats courseRec={this.props.courseRecord} isStudent={this.props.isStudent} />
            {this.props.isStudent && bookId && <ProgressionPage bookId={bookId} />}
            </div>
    }

}
