import * as React from 'react';

import { IViewInstituteGroupRecord, ViewInstituteGroupClassProxy, viewInstituteGroupClassProxy } from '@src/framework/view/sys/ViewInstituteGroup';
import ListView from '@framework/forms/listview';
import { Link } from 'react-router-dom';
import { PATH_PROFILE_INSTITUTE_GROUPS } from '@src/Routes';
import { __ } from '@src/translation';


export class InstituteGroupListViewInner extends ListView<IViewInstituteGroupRecord> {

    public getViewClassProxy() : ViewInstituteGroupClassProxy { return viewInstituteGroupClassProxy; }

    protected getInstituteGroupListButton(record:IViewInstituteGroupRecord) : JSX.Element {
        return (
            <Link className="listview-button small" 
                title={__("Csoport tagság")} 
                to={PATH_PROFILE_INSTITUTE_GROUPS + `/${record.id}`}><i className="fa fa-eye"></i>
            </Link>);
    }
    
    protected getRecordButtons(record: IViewInstituteGroupRecord) : JSX.Element[] {
        return super.getRecordButtons(record).concat([
                this.getInstituteGroupListButton(record),
            ]);
    }
}

export default function InstituteGroupListView(props: any) {
    return <InstituteGroupListViewInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[      
            {accessor: "name"},  
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
