import { IViewMemberNameRecord } from './../view/sys/ViewMemberName';
import ViewMemberName from "../view/sys/ViewMemberName";
import { me } from "../server/Auth";
import { app } from '@src/index';
import obtainServer from '../server/Server';

const URL = 'secUserSelector';

export default class SecUserSelectorAPI
 {

    /*
    public static asText = async (secUserId: number) : Promise<string> => {
        try {
            if (me) {
                const members : IViewMemberNameRecord[] = (await ViewMemberName.list(
                    {filter:{viewer_id: me.id, id: secUserId }}));
                if (members.length==1) {
                    return Promise.resolve(members[0].fullname!);
                }
            }
            return `<id=${secUserId} ismeretlen nevű felhasználó>`;
        } catch (error) {
            app.showErrorFromJsonResult(error);
            return Promise.reject(error);
        }
    }
    */
   
    public static getIdByEmailOrLoginName = (emailOrLoginName: string) : Promise<number|null> => {
        return obtainServer().post<any>(URL, {
            operation: "get_id_by_email_or_login_name",
            email_or_login_name: emailOrLoginName
        });
    }

}
