import * as React from 'react';
import LessonEditor from '@src/component/book/editor/LessonEditor';
import { Section } from '@src/server/EditorServer';
import { __ } from '@src/translation';
export const ICON_NOT_FOR_WEAK_KEY = "not_for_the_weak";

type SectionOptionsEditorProps = {
    lessonEditor: LessonEditor;
    section: Section;
    templateVariableId: string;
    enableIcons: boolean;
    enableAspectRatio: boolean;
    enableExerciseCheckboxes: boolean;
}

type SectionOptionsEditorState = {

}

export class SectionOptionsEditor extends React.Component<SectionOptionsEditorProps, SectionOptionsEditorState> {

    onIconChanged(iconKey: string) {
        const options = this.getOptions();
        options.icons[iconKey] = !options.icons[iconKey];

        const section = this.props.section;
        const chapterEditor = this.props.lessonEditor;

        chapterEditor.handleEditorChange(section.id, this.props.templateVariableId, options, 0, 0);
    }

    onAspectRatioChanged(event: React.FormEvent<HTMLSelectElement>) {
        const options = this.getOptions();
        options.aspectRatioClassName = event.currentTarget.value;

        const section = this.props.section;
        const chapterEditor = this.props.lessonEditor;

        chapterEditor.handleEditorChange(section.id, this.props.templateVariableId, options, 0, 0);
    }
    
    onExerciseInPopupChanged(event: React.FormEvent<HTMLInputElement>) {
        const options = this.getOptions();
        options.exercise_in_popup = event.currentTarget.checked;

        const section = this.props.section;
        const chapterEditor = this.props.lessonEditor;

        chapterEditor.handleEditorChange(section.id, this.props.templateVariableId, options, 0, 0);
    }

    onExerciseHiddenChanged(event: React.FormEvent<HTMLInputElement>) {
        this.props.lessonEditor.setExerciseHiddenByDefault(this.props.section.id, event.currentTarget.checked);
    }
    
    getOptions() {
        var optionsKey = this.props.templateVariableId;
        var options = this.props.section.values[optionsKey];
        
        if (!options) {
            options = {};
        }

        if (!options.icons) {
            options.icons = {};
        }
        return options;
    }

    render() {
        const options = this.getOptions();

        return <div className="small-12 column">
                    {
                        this.props.enableIcons
                        ?
                        <label>
                            <input type="checkbox" checked={options.icons[ICON_NOT_FOR_WEAK_KEY]} onChange={this.onIconChanged.bind(this, ICON_NOT_FOR_WEAK_KEY)}/> 
                            {__("csak erős idegzetűek számára ajánlott")}
                        </label>
                        :
                        null
                    }
                    {
                        this.props.enableAspectRatio
                        ?
                        <label>
                            {__("Képarány")}
                            <select defaultValue={"lo_4-3"} value={options.aspectRatioClassName} onChange={this.onAspectRatioChanged.bind(this)}>
                                <option value="lo_16-9">16:9 {__("szélesvásznú")}</option>
                                <option value="lo_4-3">4:3 {__("normál")}</option>
                                <option value="lo_3-2">3:2 </option>
                                <option value="lo_3-1">3:1</option>
                                <option value="lo_1-1">1:1 {__("négyzet")}</option>
                            </select>
                        </label>
                        :
                        null
                    }

                    {
                        this.props.enableExerciseCheckboxes
                        ?
                        <>
                            <label><input type="checkbox" checked={this.props.section.exercise_is_hidden_by_default} onChange={this.onExerciseHiddenChanged.bind(this)}/> 
                            {__("A feladat alapállapotban bezártan jelenjen meg")}
                            </label>

                            <label>
                                <input type="checkbox" checked={options.exercise_in_popup} onChange={this.onExerciseInPopupChanged.bind(this)}/> 
                                {__("A feladat rejtve legyen, és csak felugró ablakban jelenjen meg (Szövegszerkesztőben: Modális ablak vezérlő gomb)")})
                            </label>
                        </>
                        :
                        null
                    }
                    
                </div>;
    }
}
