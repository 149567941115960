import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IOccupationDraftRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Könyvtár
     *
     * NOT NULL -> doc.library ON DELETE cascade
     *
     */
    library_id?: number;
    /**
     * Tulajdonos/felelős
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     */
    owner_id?: number;
    /**
     * Szerzők
     */
    authors?: string;
    /**
     * Alprogram
     *
     * NOT NULL -> kap.subprogram ON DELETE noaction
     *
     */
    subprogram_id?: number;
    /**
     * A foglalkozás célja
     */
    goal?: string;
    /**
     * Csoport/osztály típusa
     *
     * -> kap.group_type ON DELETE noaction
     *
     */
    group_type_id?: number|null;
    /**
     * Csoport/Osztály típusa (egyéb)
     */
    group_type_other?: string|null;
    /**
     * Tematikus egység
     */
    thematic_unit?: string;
    /**
     * Témakör
     */
    topic?: string;
    /**
     * Felhasznált források
     */
    used_sources?: string|null;
    /**
     * A foglalkozásterv gyökér könyvtára.
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number;
    /**
     * Mellékletek
     */
    html_body?: string|null;
    /**
     * Foglalkozásterv
     *
     * -> kap.occupation_draft ON DELETE noaction
     *
     */
    copied_from_id?: number|null;
    /**
     * Foglalkozásterv
     *
     * -> kap.occupation_draft ON DELETE noaction
     *
     */
    last_copy_id?: number|null;
    /**
     * Publikáláshoz hozzájárult
     */
    consent_publication?: string /*timestamp?*/|null;
    /**
     * Kiválónak jelölve
     */
    marked_excellent?: string /*timestamp?*/|null;
}

/**
 *  kap.occupation_draft - Foglalkozásterv
 *
 *  KAP foglalkozásterv (alprogramos foglalkozás)
 */

export default class OccupationDraftCrud extends Crud<IOccupationDraftRecord> {
    public static TABLE_INFO_ID = 2921042175;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'occupation_draft'; }

    public static load: (id: number, aServer ?: Server) => Promise<OccupationDraftCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IOccupationDraftRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IOccupationDraftRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IOccupationDraftRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IOccupationDraftRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IOccupationDraftRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class OccupationDraftCrudClassProxy extends CrudClassProxy<IOccupationDraftRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const occupationDraftCrudClassProxy = new OccupationDraftCrudClassProxy(OccupationDraftCrud);
registerCrudClassProxy(occupationDraftCrudClassProxy);
