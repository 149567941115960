import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ICourseInstituteSiteRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Képzés
     *
     * NOT NULL -> kap.course ON DELETE cascade
     *
     */
    course_id?: number;
    /**
     * Intézmény feladat ellátási hely
     *
     * NOT NULL -> sys.institute_site ON DELETE cascade
     *
     */
    institute_site_id?: number;
    /**
     * Belsős
     *
     * A belsős feladatellátási helyek jelentkezési ablaka eltérő lehet
     */
    is_internal?: boolean;
}

/**
 *  kap.course_institute_site - Képzés meghirdetési helye
 *
 *  Azt mondja meg, hogy mely feladatellátási helyekről vehetik föl a képzést a potenciális hallgatók.
 */

export default class CourseInstituteSiteCrud extends Crud<ICourseInstituteSiteRecord> {
    public static TABLE_INFO_ID = 2514529071;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'course_institute_site'; }

    public static load: (id: number, aServer ?: Server) => Promise<CourseInstituteSiteCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ICourseInstituteSiteRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ICourseInstituteSiteRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ICourseInstituteSiteRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ICourseInstituteSiteRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ICourseInstituteSiteRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class CourseInstituteSiteCrudClassProxy extends CrudClassProxy<ICourseInstituteSiteRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const courseInstituteSiteCrudClassProxy = new CourseInstituteSiteCrudClassProxy(CourseInstituteSiteCrud);
registerCrudClassProxy(courseInstituteSiteCrudClassProxy);
