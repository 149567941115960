import * as React from 'react';
import { app } from '@src/index';
import { Dialog } from '@src/component/Dialog';
import LoggedTestCrud, { ILoggedTestRecord } from '@src/framework/crud/log/LoggedTestCrud';
import ReactTable from 'react-table';
import { getReactTableLabels } from '@src/framework/i18n';
import 'react-table/react-table.css';
import { formatDateWithSeconds, formatDate } from '@src/Util';
import { match,} from 'react-router';
import { Link } from 'react-router-dom';
import { __ } from '@src/translation';

type TestResultsPageProps = {
    match: match<{id: string, subid:string}>;
    history?: any;

}
interface TestResultsPageState {
    count: number;
    pageSize: number;
    loading: boolean;
    tableState?: any;
    testResultTimesCount?: {creation_time: string, count: number, id:number}[];
    viewResults?: any[];
    failedcount:number;
    selectedTime?: string;
    isSuccess?: boolean;
    messagekeywords?: string;
    methodkeywords?: string;
    namekeywords?: string;
    dialogOpen: boolean;
    dialogViewSteps: string[];
    dialogTitle:string;
    firstload:boolean;
}

export default class TestResultsPage extends React.Component<TestResultsPageProps, TestResultsPageState> {

    constructor(props: TestResultsPageProps) {
        super(props);

        this.state = {
            count: 0,
            pageSize: 20,
            loading: false,
            tableState: { pageSize: 20, page: 0 },
            viewResults: undefined,
            failedcount: 0,
            selectedTime: undefined,
            isSuccess: undefined,
            dialogOpen: false,
            dialogViewSteps: [],
            dialogTitle:"",
            firstload:true,
        };
    };

    componentDidMount() {
        this.onFetchData(null);
    };

    private async onFetchData(tableState: any) {
        if (!tableState) tableState = this.state.tableState;

        this.setState({
            loading: true,
            tableState: tableState,
        });

        try {
            var testResultsData:ILoggedTestRecord[] = [];

            const params: {offset:number, limit:number} = {
                offset: tableState.page * tableState.pageSize,
                limit: tableState.pageSize + (tableState.page * tableState.pageSize)
            };

            let sorted:{id:string,desc:boolean} = {id:"",desc:false};

            if (tableState.sorted && tableState.sorted.length > 0) {
                sorted = tableState.sorted[0];
            }

            testResultsData = await LoggedTestCrud.list({ columns: ["id", "creation_time", "result"] });

            let results: any[] = [];
            let resultsTimesCount: {creation_time: string, count: number, id:number}[] = [];

            let i = 0;
            let j = 0;
            testResultsData.map((l,keyi)=> {
            return (
                <div key={keyi}>
                    {
                        resultsTimesCount[j]={
                            creation_time:l.creation_time!,
                            id:l.id!,
                            count:0,
                        }
                    }
                    {
                        l.result.resArray.map((r: { subid:number; message: string; success: boolean; timestamp: string; methodName:string; steps:any[]; name:string} , keyj:number) => 
                                <div key={keyj}>
                                    {
                                        results[i]={
                                            id: l.id,
                                            subid: r.subid,
                                            creation_time: l.creation_time,
                                            message: r.message,
                                            success: r.success,
                                            timestamp: r.timestamp,
                                            methodName: r.methodName,
                                            steps: r.steps ? r.steps : "",
                                            name: r.name,
                                        }
                                    }
                                    {i++}
                                    {resultsTimesCount[j].count++}
                                </div>
                        )
                    }
                    {j++}
                </div>
                )
            });

            var selectedTime = this.state.selectedTime;
            var firstload:boolean = this.state.firstload;

            if(this.props.match.params.id){
                selectedTime = this.getTimebyID(this.props.match.params.id,resultsTimesCount);
            }
            else if(firstload && testResultsData && resultsTimesCount.length>0){
                selectedTime = resultsTimesCount[resultsTimesCount.length-1].creation_time;
                firstload=false;
                let firstLoadID = this.getIDbyTime(selectedTime,resultsTimesCount);
                this.redirecting(firstLoadID,"");
            }

            var viewResults = results.filter(filteredtimes =>{
                if (selectedTime === undefined || selectedTime === null || selectedTime === "undefined"){
                    return filteredtimes
                }
                else{
                    return filteredtimes.creation_time === selectedTime
                }
            })
                .filter(filteredresults => {
                    if (this.state.isSuccess === undefined){
                        return filteredresults
                    }
                    else{
                        return filteredresults.success === this.state.isSuccess
                    }  
                })
                .filter(filteredmessage => {
                    if (this.state.messagekeywords === undefined){
                        return filteredmessage
                    }
                    else{
                        return filteredmessage.message.toLowerCase().search(this.state.messagekeywords!.toLowerCase()) !== -1
                    }
                })

                .filter(filteredmethod => {
                    if (this.state.methodkeywords === undefined){
                        return filteredmethod
                    }
                    else{
                        return filteredmethod.methodName.toLowerCase().search(this.state.methodkeywords!.toLowerCase()) !== -1
                    }
                })
                
                .filter(filteredname =>{
                    if (this.state.namekeywords === undefined){
                        return filteredname
                    }
                    else{
                        return filteredname.name.toLowerCase().search(this.state.namekeywords!.toLowerCase()) !== -1
                    }
                });

                var failedcount = viewResults.filter(fail => {
                    return !fail.success
                });


            if(this.props.match.params.id && this.props.match.params.subid){

                const dialogsteps = viewResults[this.props.match.params.subid].steps;
                const dialoglabel = formatDateWithSeconds(viewResults[this.props.match.params.subid].timestamp) + ", " + viewResults[this.props.match.params.subid].methodName + " " + __("teszt lépései:")
                this.setState({dialogTitle: dialoglabel ,dialogOpen: true, dialogViewSteps:dialogsteps});
            }
            
            const count = viewResults.length;
            
            if(sorted.id == 'success' || sorted.id == 'steps'){
                viewResults.sort(function(a, b){
                    if(a[sorted.id] < b[sorted.id]) { return sorted.desc? -1 :  1; }
                    if(a[sorted.id] > b[sorted.id]) { return sorted.desc?  1 : -1; }
                    return 0;
                });
            }
            else if(sorted.id !== ""){
                viewResults.sort(function(a, b){
                    if(a[sorted.id].toLowerCase() < b[sorted.id].toLowerCase()) { return sorted.desc? -1 :  1; }
                    if(a[sorted.id].toLowerCase() > b[sorted.id].toLowerCase()) { return sorted.desc?  1 : -1; }
                    return 0;
                });
            }

            viewResults = viewResults.reverse().slice(params.offset,params.limit);

            this.setState({
                testResultTimesCount: resultsTimesCount,
                loading: false,
                pageSize: tableState.pageSize,
                viewResults: viewResults,
                failedcount: failedcount.length,
                selectedTime: selectedTime,
                firstload:firstload,
                count,
            });
        }
        catch (e) {
            app.showErrorFromJsonResult(e);
        };
    }
    

    render(){
        if (!this.state.viewResults) return null;

        var viewResults = this.state.viewResults.map(r => {
            let stepslink:string = "/testresults/"+r.id+"/"+r.subid;
            let issuceess=r.success ? __("Sikeres"):__("Sikertelen");
            return {
                ...r,
                success: r.success ? <span style={{ color: "green" }}>{__("Sikeres")}</span> : <span style={{ color: "red" }}>{__("Sikertelen")}</span>,
                timestamp: formatDateWithSeconds(r.timestamp),
                steps: r.steps ? <Link to={stepslink}> <button className="button" onClick={() => this.setState({dialogTitle: formatDateWithSeconds(r.timestamp) + ", \"" + r.name + "\" ("+issuceess+")"+__("lépései:"),dialogOpen: true, dialogViewSteps:r.steps})}>{__("Lépések")}</button> </Link>: "N/A",
            }
        });

        const tableProps = {
            columns: [
                { Header: __("Időpont"), accessor: "timestamp", maxWidth: 150, style: { justifyContent: "left" }},
                { Header: __("Cím"), accessor: "name", style: { justifyContent: "left" } },
                { Header: __("Metódusnév"), accessor: "methodName", maxWidth: 300, style: { justifyContent: "left" } },
                { Header: __("Üzenet"), accessor: "message", maxWidth: 300, style: { justifyContent: "left" } },
                { Header: __("Eredmény"), accessor: "success", maxWidth: 100, style: { justifyContent: "center" } },
                { Header: __("Lépések"), accessor: "steps", maxWidth: 100, style: { justifyContent: "center" } },
            ],

            data: viewResults,
            defaultPageSize: this.state.pageSize,
            pages: Math.ceil(this.state.count / this.state.pageSize),
            filterable: false,
            className: "-striped -highlight ",
            ...getReactTableLabels(),
            onFetchData: this.onFetchData.bind(this),
            loading: this.state.loading,
            manual: true,
        };


        const filterComponent =
        <div className="row align-center">
            <div className="column small-6 medium-6 large-3">
                <label>{__("Eredmény")}</label>
                <select onChange={this.onResultSelect.bind(this)} value={this.state.isSuccess != undefined ? this.state.isSuccess.toString() : "undefined"}>
                    <option value="undefined">{__("Mutasd az összeset")}</option>
                    <option value="false">{__("Sikertelen")}</option>
                    <option value="true">{__("Sikeres")}</option>
                </select>
            </div>

            <div className="column small-6 medium-6 large-3">
                <label>{__("A tesztek készítésének időpontja")}</label>
                
                <select onChange={this.onTimeSelect.bind(this)} value={this.state.selectedTime != undefined ? this.state.selectedTime.toString() : "undefined"}>
                    
                    <option value="undefined">{__("Mutasd az összeset")}</option>

                    {
                        this.state.testResultTimesCount!.map((times,key) =>{
                            return(
                            <option key={key}value={times.creation_time}>{times.creation_time} ({times.count})</option> 
                            )
                        }).reverse()
                    }
                </select>
            </div>
        </div>

        const searchbar = <div className="row align-center">
            <div className="small-12 medium-12 large-9 column">
                <label>{__("Cím kereső")}</label>
                <div className="row">
                    <input onKeyPress={this.onKeyPress.bind(this)} onChange={this.onKeywordChangeName.bind(this)} value={this.state.namekeywords ? this.state.namekeywords : ""} className="exerciseList-option small-10 medium-10 large-11 column" type="text" />
                </div>
            </div>

            <div className="small-12 medium-12 large-9 column">
                <label>{__("Metódusnév kereső")}</label>
                <div className="row">
                    <input onKeyPress={this.onKeyPress.bind(this)} onChange={this.onKeywordChangeMethod.bind(this)} value={this.state.methodkeywords ? this.state.methodkeywords : ""} className="exerciseList-option small-10 medium-10 large-11 column" type="text" />
                </div>
            </div>

            <div className="small-12 medium-12 large-9 column">
                <label>{__("Üzenet kereső")}</label>
                <div className="row">
                    <input onKeyPress={this.onKeyPress.bind(this)} onChange={this.onKeywordChangeMessage.bind(this)} value={this.state.messagekeywords ? this.state.messagekeywords : ""} className="exerciseList-option small-10 medium-10 large-11 column" type="text" />
                    <button className="button small  small-2 medium-2 large-1" disabled={this.state.loading} onClick={this.refreshBtnClick.bind(this)}>
                        <i className="fa fa-sync" />
                    </button>
                </div>
            </div>
        </div>

        const reacttablecomponent = <div className="row expanded">
            <div className="small-12 medium-12">
                <div style={{ display: "inline-flex" }}>
                    <label style={{ marginRight: "1rem" }} className="result-count">
                        {__("{count} teszt eredmény, ebből {failed} sikertelen", {count: this.state.count, failed: this.state.failedcount})}
                        </label>
                </div>
                <ReactTable {...tableProps} />
            </div>
        </div>

        return(
            <div>
                <h1 className="row align-center">{__("Teszt eredmények")}</h1>
                {filterComponent}
                {searchbar}
                {reacttablecomponent}
                <Dialog width={750} open={this.state.dialogOpen} title={this.state.dialogTitle} onClose={() => {this.setState({dialogOpen: false}); this.redirecting(this.props.match.params.id,"")}}>
                    <ol>
                    {this.state.dialogViewSteps.map((d,i)=>
                        <li key={i}>{d}</li>
                    )}
                    </ol>
                </Dialog>
            </div>
        )
    }


    private refreshBtnClick(e: any) {
        this.onFetchData(null);
    }

    private onResultSelect(event: any) {
        let selectedResult = event.target.value;
        if (event.target.value == 'true') { selectedResult = true; }
        else if (event.target.value == 'false') { selectedResult = false; }
        else { selectedResult = undefined; }

        this.setState({ isSuccess: selectedResult }, this.onFetchData.bind(this));
    }

    private onTimeSelect(event: any) {
        let selectedTime;
        if (event.target.value !== undefined || event.target.value !== null ){
            selectedTime = event.target.value;
        }
        else { selectedTime = undefined; }

        const id = this.getIDbyTime(selectedTime,this.state.testResultTimesCount!);
        this.redirecting(id,"");
        
        this.setState({ selectedTime: selectedTime }, this.onFetchData.bind(this));
        }

    private onKeywordChangeMessage(e: any) {
        this.setState(
            { messagekeywords: e.target.value }
        );
    }

    private onKeywordChangeMethod(e: any) {
        this.setState(
            { methodkeywords: e.target.value }
        );
    }

    private onKeywordChangeName(e: any) {
        this.setState(
            { namekeywords: e.target.value }
        );
    }

    private onKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.onFetchData(null);
        }
    }

    private redirecting(id:string | any, subid:string | any){
        
        if(this.state.selectedTime == "undefined"){
            this.props.history.push('/testresults/');
        }

        else{
            this.props.history.push('/testresults/'+id);
        }
    }

    private getTimebyID(ID:string,array:any[]){
        if(ID=="undefined"){
            return ""
        }
        else{
            return array.filter(t=>{
                return ID == t.id.toString()
            })[0].creation_time
        }
    }

    private getIDbyTime(time:string,array:any[]){
        if(time=="undefined"){
            return ""
        }
        else{
            return array.filter(t=>{
                return time == t.creation_time
            })[0].id.toString()
        }
    }
}