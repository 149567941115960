export class EKEPairingServer{

    static evaluateOnServer(exercise: any, correctSolution: any, userSolution: any): Evaluated {
        var success = false;
        let successPercent = 0;
        let userSol = "";
        let correctAns = 0;
        let numOfQuestions = exercise.exercise.num_of_questions? exercise.exercise.num_of_questions : exercise.exercise.options.pair1.length;

        for (let j = 0; j < userSolution.length; j++) {
            userSol = exercise.exercise.options.pair2[userSolution[j].value].text;

            for (var i = 0; i < correctSolution.length; i++) {
                if (userSolution[j].key == correctSolution[i].key){
                    if(userSol == exercise.exercise.options.pair2[correctSolution[i].value].text){
                        correctAns++;

                        // the success value only tells if the last submitted answer is good or not
                        if(j == userSolution.length-1){ 
                            success = true;
                        }
                    }
                    break;
                }
            }
        }

        successPercent = Number((correctAns / numOfQuestions).toFixed(2));
        return { "success": success, "solution": correctSolution, "successPercent": successPercent };
    }

    static filterExerciseOnServer(exercise: any, solution: any): any {
        if (!exercise.num_of_questions || exercise.num_of_questions < 1)
            return exercise;
        
        let new_exercise = JSON.parse(JSON.stringify(exercise));
        let tempSolution = solution.slice();
        let selected_items: number[] = [];
        let countOfPair1Values = 0;
        for (let i = 0; i < new_exercise.options.pair1.length; i++) {
            if ((new_exercise.options.pair1[i].type == "text" && new_exercise.options.pair1[i].text != "")
                || new_exercise.options.pair1[i].type == "image" && new_exercise.options.pair1[i].image != "")
                countOfPair1Values++;
        }
        if (new_exercise.num_of_questions > countOfPair1Values)
            new_exercise.num_of_questions = countOfPair1Values;
        let elementsToRemove = countOfPair1Values - new_exercise.num_of_questions;
        let i = 0;
        while (i < elementsToRemove) {
            let j = Math.floor(Math.random() * (tempSolution.length));
            if (selected_items.indexOf(tempSolution[j].key) == -1) {
                selected_items.push(tempSolution[j].key);
                if ((new_exercise.options.pair1[tempSolution[j].key].type == "text" && new_exercise.options.pair1[tempSolution[j].key].text == "") 
                    || (new_exercise.options.pair1[tempSolution[j].key].type == "image" &&
                        new_exercise.options.pair1[tempSolution[j].key].image == "")) {
                    //when we "hit" odd_one pairs, we only remove second pair and we dont count it as a removed element
                    new_exercise.options.pair2[tempSolution[j].value].text = "";
                    new_exercise.options.pair2[tempSolution[j].value].image = "";
                    new_exercise.options.pair2[tempSolution[j].value].type = "";
                }
                else{
                    new_exercise.options.pair1[tempSolution[j].key].text = "";
                    new_exercise.options.pair1[tempSolution[j].key].image = "";
                    new_exercise.options.pair1[tempSolution[j].key].type = "";
                    new_exercise.options.pair2[tempSolution[j].value].text = "";
                    new_exercise.options.pair2[tempSolution[j].value].image = "";
                    new_exercise.options.pair2[tempSolution[j].value].type = "";
                    i++;
                    (tempSolution as any[]).splice(j, 1);
                }
            }
        }
        // annyi kérdés-válasz párnak marad értéke, amennyit a limitben megadtunk, többi üres lesz
        // ha kakukktojás elem van a pair1ben, továbblépünk, a pair2-t bennehagyjuk a válaszok listájában
        return new_exercise;
    }

}