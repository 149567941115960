import * as React from 'react';
import { match } from 'react-router';
import SectionCrud, { ISectionRecord } from '@src/framework/crud/doc/SectionCrud';
import AuditLogAPI, { AuditLogRecord } from '@src/framework/audit/AuditLogAPI';
import obtainServer from '@src/framework/server/Server';
import { app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import LessonCrud, { ILessonRecord } from '@src/framework/crud/doc/LessonCrud';
import ChapterCrud from '@src/framework/crud/doc/ChapterCrud';
import { IChapterRecord } from '@src/framework/crud/doc_pub/ChapterCrud';
import { __ } from '@src/translation';
import BlockTemplateCrud, { IBlockTemplateRecord } from '@src/framework/crud/doc/BlockTemplateCrud';


type ISectionHistoryPageProps = {
    match: match<{
        sectionId: string,
    }>;
}

type ISectionHistoryPageState = {
    book ?: IBookRecord;
    chapter ?: IChapterRecord;
    lesson ?: ILessonRecord;
    section ?: ISectionRecord;
    blockTemplates: IBlockTemplateRecord[]

    items: AuditLogRecord[];
    loading: boolean;
}

class SectionHistoryPage extends React.Component<ISectionHistoryPageProps, ISectionHistoryPageState> {

    constructor(props: ISectionHistoryPageProps) {
        super(props);
        this.state = { items: [], loading: true, blockTemplates: [] };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        try {
            let api: AuditLogAPI = new AuditLogAPI(obtainServer());
            let sectionId = parseInt(this.props.match.params['sectionId']);
            let section = (await SectionCrud.load(sectionId)).record;
            let lesson = (await LessonCrud.load(section.lesson_id!)).record;
            let chapter = (await ChapterCrud.load(lesson.chapter_id!)).record;
            let book = (await BookCrud.load(chapter.book_id!)).record;
            const blockTemplates = await BlockTemplateCrud.list({filter: {is_active: true}});
            let items: AuditLogRecord[] = await api.getHistory(SectionCrud.TABLE_INFO_ID, sectionId);
            this.setState({ items, book, chapter, lesson, section, loading: false, blockTemplates });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private printHtmlContent = (newContent: string) => {
        // Itt kellene valami HTML diff, ami mutatja hogy mi változott???
        let relpath = "/tankonyv/" + this.state.book!.uri_segment;
        let html = newContent.replace(/src=["'](.\/){0,1}([^"']+)['"]/g, 'src="'+ relpath +'/$2"');
        return <>
            <p className="callout primary" dangerouslySetInnerHTML={{ __html: html }}></p>
            <textarea value={html} />
            </>
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        } else if (this.state.items.length == 0) {
            return <p className="callout alert">{__("Ehhez a szekcióhoz nincs elérhető módosítási napló.")}</p>;
        } else {
            return <div className="column small-12">
                {this.state.items.map((item: AuditLogRecord) => {

                    var valuesJson;
                    if (item.changed_fields) {
                        valuesJson = item.changed_fields.values;
                    } else {
                        valuesJson = item.row_data.values;
                    }

                    if (valuesJson) {
                        let data = JSON.parse(valuesJson);
                        if (data.content) {

                            const blockTemplate = this.state.blockTemplates.find(t => t.id == item.row_data.block_template_id);

                            return <div className="row" key={item.event_id}>
                                <div className="column small-12">
                                    <ul>
                                        <li><b>{__("Azonosító")}:</b> {item.event_id}</li>
                                        <li><b>{__("Időpont")}:</b> {item.action_tstamp_tx}</li>
                                        <li><b>{__("Felhasználó")}:</b> {item.login_name} {item.user_id}</li>
                                        <li><b>{__("Bejelentkezés")}:</b> {item.login_time} @ {item.client_ip}, session_id={item.session_id}</li>
                                        <li><b>{__("Kliens")}:</b> {item.user_agent}</li>
                                        <li><b>{__("Művelet")}:</b> {item.action}</li>
                                        <li><b>{__("Szekció")}:</b> {blockTemplate && blockTemplate.name}</li>
                                    </ul>
                                </div>
                                <div className="column small-12">
                                    {
                                        Object.keys(data).map(key => {
                                            const value = data[key];
                                            if (typeof value !== "string") return null;

                                            return <div>
                                                    {key}:
                                                    <div style={{marginLeft: "1em"}}>
                                                        {this.printHtmlContent(value)}
                                                    </div>
                                                </div>;
                                        })
                                    }
                                    
                                </div>
                                <hr />
                            </div>;
                        }
                    }
                    return null;
                })}
            </div>;

        }

    }

}

export default class SectionHistoryPageProtected extends React.Component<ISectionHistoryPageProps> {
    render() {
        return <SectionHistoryPage {...this.props} />;
    }
}
