import { IOutgoingMessage } from "../messengerAPI";
import ViewUsrMessage, { IViewUsrMessageRecord } from "@src/framework/view/usr/ViewUsrMessage";
import * as React from "react";
import { me } from "@src/framework/server/Auth";
import { TFilterDict } from "@src/framework/crud/Crud";
import { app } from "@src/index";
import { whiteSpaceChecker } from "@src/component/WhiteSpaceChecker";
import { messengerModule } from "../messengerModule";
import { __ } from "@src/translation";
import * as Scrollbar from "react-smooth-scrollbar";
import { HTMLTextarea } from "@src/component/HtmlTextarea";
import AccessDeniedPage from "@src/framework/pages/AccessDeniedPage";
import { BubbleLoader } from 'react-css-loaders';
import { formatDate } from "@src/Util";
import { AutoTextarea } from "@src/component/ui/AutoTextarea";

type DiscussionCardsProps = {
    table_info_id: number;
    record_id: number;
    user_id: number;
    subject: string;
}

type DiscussionCardsState = {
    loading: boolean;
    messages: IViewUsrMessageRecord[];
    openedMessage: IViewUsrMessageRecord;
    newMessage: IOutgoingMessage;
    validationMessages: Map<string, string>,
    HTMLEditor: boolean,
}

export default class DiscussionCards extends React.Component<DiscussionCardsProps, DiscussionCardsState> {

    constructor(props: DiscussionCardsProps) {
        let validationMap: Map<string, string> = new Map<string, string>();
        let newMessage: IOutgoingMessage = { subject: "", body_html: "", reply_to_id: null, recipient_ids: [] }
        super(props);
        this.state = {
            loading: true,
            openedMessage: {},
            messages: [],
            newMessage,
            validationMessages: validationMap,
            HTMLEditor: false,
        }
    }

    componentDidMount() {
        this.asyncReload();
    }

    componentDidUpdate(prevProps: DiscussionCardsProps) {
        if (prevProps && this.props && prevProps.user_id != this.props.user_id) {
            this.asyncReload();
        }
        if (this.refs && this.refs.scrollbar) {

            const scrollbar = (this.refs.scrollbar as Scrollbar).scrollbar;
            scrollbar.scrollTo(undefined, scrollbar.size.content.height);
        }
    }

    public async asyncReload() {

        let emptyMsg = { subject: "", body_html: "", reply_to_id: null, recipient_ids: [] }
        if (!me) return;

        this.setState({ loading: true });

        try {
            let openedMessage;
            let actualMessages: IViewUsrMessageRecord[] = [];

                let filter: TFilterDict = { is_active: true, table_info_id: this.props.table_info_id, rec_id: this.props.record_id };
                let messages: IViewUsrMessageRecord[] = await ViewUsrMessage.list({
                    filter: filter,
                    order_by: [{ name: "creation_time", desc: false }],
                    spec: { recipient_or_sender_id: this.props.user_id }
                });

                //Ha van üzenet akkor a "megnyitott" a legfrisebb üzenet lesz. Ez küldés előtt onSend()-ban újra csekkoljuk
                if (messages.length != 0) {
                    openedMessage = messages[0];
                    actualMessages = messages;
                }

            //Ha nincs üzenet
            if (!openedMessage) {
                this.setState({ loading: false, messages: actualMessages });
                return;
            }
            this.setState({ messages: actualMessages, openedMessage, loading: false, newMessage: emptyMsg });

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    async onSend() {

        let validationMessages = new Map<string, string>();
        let newMessage = this.state.newMessage;

        try {
            
            if (!newMessage || !me) return;
            let error_message = "";

            if (!whiteSpaceChecker(newMessage.body_html)) {
                error_message = __("Az üzenet szövege kötelező!");
                app.showError(__("Hiba"), error_message);
                validationMessages.set("body_html", error_message);
            }

            if (validationMessages.size > 0) {
                this.setState({ validationMessages });
                return;
            }
            //Ha rekordhoz tartozó discussion üzeneteihez fűzénk hozzá üzenetet...
                newMessage.subject = this.props.subject;
                newMessage.recipient_ids = [this.props.user_id];
                let filter: TFilterDict = { is_active: true, table_info_id: this.props.table_info_id, rec_id: this.props.record_id };

                const actualMessages = await ViewUsrMessage.list({
                    filter: filter,
                    order_by: [{ name: "creation_time", desc: true }],
                    spec: { recipient_or_sender_id: this.props.user_id }
                });

                //Ha nincs üzenet még, akkor reply_to null, hiszen az üzenetváltás első üzenete lesz
                let reply_to = null;

                if (actualMessages.length != 0) {
                    //Ha van üzenet akkor a legfrissebbre állítjuk a reply_to-t
                    reply_to = actualMessages[0].id!;
                }
                await messengerModule.pushMessage(this.props.table_info_id, this.props.record_id, reply_to, newMessage);

            app.showSuccess(__("Elküldve"), __("Az üzenet elküldve"));
            this.asyncReload();

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {

        if (!me || !me.id) {
            return <AccessDeniedPage />;
        }

        if (this.state.loading) {
            return <BubbleLoader />;
        }


        let messages = this.state.messages;
        let newMessage = this.state.newMessage;

        return <div className="message-cards-wrapper">

            <Scrollbar alwaysShowTracks={true} ref="scrollbar" className="message-scroll-wrapper" style={{ flex: 1 }}>
                <div className="message-cards-area">

                    {messages && messages.length > 0
                        ?
                        messages.map((m: IViewUsrMessageRecord) => {
                            let myMessage = (me && me.id == m.creation_user_id);
                            let messageClass: string = myMessage ? "my-message" : "other-message";
                            return (<div className="message-cards" key={m.id}>
                                <div className="messages-show">
                                    <div className={"callout " + messageClass}>
                                        <div className="row expanded">
                                            <div className="column small-9 medium-9 large-9 text-left"><b>{`${m.sender_fullname} <${m.sender_email}>`}</b></div>
                                            <div className="column text-right">{formatDate(m.creation_time)}</div>
                                        </div>
                                        <div className="column" dangerouslySetInnerHTML={{ __html: m.body_html! }}></div>
                                    </div>
                                </div>
                            </div>)
                        })
                        : <div className="column">{__("Ebben az üzenetváltásban még nincs üzenet...")}</div>
                    }
                </div>
            </Scrollbar>

            <div className="messagebox-area">

                {
                    this.state.HTMLEditor
                        ?
                        <>
                            <div className="">
                                <label>{__("Új üzenet szövege")} <span style={{ color: "#b30000" }}>*</span>
                                    <span className="exe-editor-validation-msg">{this.state.validationMessages.get("body_html")}</span>
                                </label>

                                <HTMLTextarea
                                    value={newMessage.body_html || ""}
                                    onChange={this.onNewMessageChanged.bind(this, "body_html")}
                                    tools={"minimal"}
                                />
                            </div>
                            <div className="text-right">
                                <button className="button success eke-general-buttons"
                                    onClick={this.onSend.bind(this)}>
                                    <i className="fas fa-paper-plane"></i> {__("Elküldés")}
                                </button>
                                <button className={"button clear " + (this.state.HTMLEditor && "active")} title={__("Formázás kikapcsolása")} onClick={() => this.setState({ HTMLEditor: !this.state.HTMLEditor })}><i className="fas fa-font"></i></button>
                            </div>
                        </>
                        :
                        <>
                            <AutoTextarea buttons={<button title={__("Formázás")} onClick={() => this.setState({ HTMLEditor: !this.state.HTMLEditor })}><i className="fas fa-font"></i></button>} text={newMessage.body_html || ""} onSubmit={this.onSend.bind(this)} onTextChange={this.onNewMessageChanged.bind(this, "body_html")}></AutoTextarea>
                        </>
                }


            </div>
        </div>
    }

    onNewMessageChanged(propertyName: string, event: any) {
        let newMessage = this.state.newMessage;
        newMessage[propertyName] = event.target ? event.target.value : event;
        this.setState({ newMessage });
    }

}