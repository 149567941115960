import * as React from 'react';

import AdminRoute from '@src/Routes'

import UsrLikeTeszt from './usr_like_teszt';


export function UsrRoutes() {
    return [
        <AdminRoute path="/usr/like_teszt" exact={true} component={UsrLikeTeszt} key="UsrLikeTeszt"/>,
        

    ];
}