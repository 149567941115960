import * as React from 'react';
import "@src/component/dashboard/swiper.css";

export default class Swiper extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = { 
            ulWidth : "auto",
            ItemCount:  this.props.ItemCount ? this.props.ItemCount : 1
        };

        this.handleResize = this.handleResize.bind(this);      
    }

    handleResize(event:any) {
 
        this.setArrows()
        this.forceUpdate(); 
    }

    componentDidMount()
    {
        window.addEventListener("resize", this.handleResize);
   
        var swiper = (this.refs.swiper as HTMLElement);
        this.itemWidth = swiper!.clientWidth / this.state.ItemCount;
        this.setItemCount(this.props.ItemCount);
        this.setArrows();

    }
    componentDidUpdate(prevProps:any, prevState:any)
    {
        var swiper = (this.refs.swiper as HTMLElement);
        this.itemWidth = swiper!.clientWidth / this.state.ItemCount;

        var swiperItems = (this.refs.swiperItems as HTMLElement);

        this.swipe = 0;
        swiperItems.style.transform = "translateX(" + -(this.swipe) + "px)";    
        this.setItemCount(this.props.ItemCount);    
        this.setArrows();
    }

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.handleResize);
    }

    setArrows()
    {    
        var swiper = (this.refs.swiper as HTMLElement);
        this.itemWidth = swiper!.clientWidth / this.state.ItemCount;      
        var swiperItems = (this.refs.swiperItems as HTMLElement);

        if(this.swipe <= 0)
        {
            (this.refs.prevItem as HTMLElement).style.display = "none";
        }
        else{
            (this.refs.prevItem as HTMLElement).style.display = "";
        }

        if(swiperItems.clientWidth-(this.itemWidth*(this.state.ItemCount+1)) <= this.swipe)// + (this.itemWidth*this.state.ItemCount))
        {
            (this.refs.nextItem as HTMLElement).style.display = "none";
        }
        else
        {
            (this.refs.nextItem as HTMLElement).style.display = "";
        }
    }

    private swipe = 0;
    private itemWidth = 0;
    prevItem()
    {
        if(this.swipe > 0)
        {
            this.swipe -= this.itemWidth;
            (this.refs.swiperItems as HTMLElement).style.transform = "translateX(" + -(this.swipe) + "px)";
           
            this.setArrows();
        }        
        
    }

    nextItem()
    { 
        var swiperItems = (this.refs.swiperItems as HTMLElement);

        if(swiperItems.clientWidth - this.itemWidth > this.swipe+this.itemWidth)
        {
            this.swipe += this.itemWidth;
            swiperItems.style.transform = "translateX(" + -(this.swipe) + "px)";

            this.setArrows();
        }
               
    }

    setItemCount(maxCount: number)
    {
        var itemCount = this.props.ItemCount;;
        var wapper =  (this.refs.swiperWrapper as HTMLElement);
        var itemWidth = 0;
        if( wapper.clientWidth > 1440 && this.props.ItemCount == 6)
        {
            itemCount = 6;
        }
        else if( wapper.clientWidth > 1200 && maxCount > 4)
        {
            itemCount = 5;
        }
        else if( wapper.clientWidth > 1024 && maxCount > 3)
        {
            itemCount = 4;
        }
        else if( wapper.clientWidth > 640 && maxCount > 2)
        {
            itemCount = 3;
        }
        else if( wapper.clientWidth > 480 && maxCount > 1)
        {
            itemCount = 2;
        }
        else
        {
            itemCount = 1;
        }

        if(itemCount != this.state.ItemCount)
        {
            this.setState({ItemCount: itemCount});
        }
    }

    render() {
        
        return <div ref="swiper" className="swiper">

                <div ref="prevItem" onClick={()=>this.prevItem()} className="prevItem"><i className="fa fa-angle-left"></i></div>
                <div ref="nextItem" onClick={()=>this.nextItem()} className="nextItem"><i className="fa fa-angle-right"></i></div>

                    <div ref="swiperWrapper"  data-itemcount={this.state.ItemCount} className="swiper-wrapper">

                        <ul ref="swiperItems" data-itemcount={this.state.ItemCount} className={"swiper-items " + (this.props.className ? this.props.className : "") }>                        
                             {this.props.children}
                        </ul> 
                    </div>
                </div>
    }

}

export class SwiperItem extends React.Component<{title: string | JSX.Element, className?: string }, any> {
    
    constructor(props: any) {
        super(props);
        this.state = {
            width : "100%"
         };
         this.handleResize = this.handleResize.bind(this);

    }

    handleResize(event:any) {
        this.initWidth();
        this.forceUpdate(); 
    }

    private width = 0;
    private itemCount = 1;
    componentDidMount()
    {
        window.addEventListener("resize", this.handleResize);

        this.initWidth();
        setTimeout(() => {
            this.initWidth();
        }, 100);
    }
    
    componentDidUpdate(prevProps: {title: string | JSX.Element, className?: string }, prevState:any)
    {
       // console.log('width, state width', this.width, this.state.width);
        this.initWidth();            
    }
    
    componentWillUnmount()
    {
        window.removeEventListener("resize", this.handleResize);
    }

    initWidth()
    {
        if (this.refs.swiperItem as HTMLLIElement)
        {
            var parent =  (this.refs.swiperItem as HTMLLIElement).parentElement!;

            this.itemCount = Number(parent.getAttribute('data-itemcount'));
                
            this.setWidth(this.itemCount);
            //console.log('width, state width', this.width, this.state.width);
             if(this.width+"px" != this.state.width)
             {
                (this.refs.swiperItem as HTMLLIElement).style.width =  this.width+"px";
                // this.setState({
                // // swiperWidth: parent.parentElement!.clientWidth,
                //     width: this.width ? this.width+"px" : "100%"
                // });
             }    
        }          
    }

    setWidth(maxCount: number)
    {
        var parent =  (this.refs.swiperItem as HTMLLIElement).parentElement!;
        var parentWidth = parent.parentElement!.clientWidth;

        var itemWidth = 0;
        if(parentWidth > 1440 && maxCount == 6)
        {
            this.width = Math.round(parentWidth / 6);
            this.itemCount = 6;
        }
        else if(parentWidth > 1200 && maxCount > 4)
        {
            this.width = Math.round(parentWidth / 5);
            this.itemCount = 5;
        }
        else if(parentWidth > 1024 && maxCount > 3)
        {
            this.width = Math.round(parentWidth / 4);
            this.itemCount = 4; 
        }
        else if(parentWidth > 640 && maxCount > 2)
        {
            this.width = Math.round(parentWidth / 3);
            this.itemCount = 3;
        }
        else if(parentWidth > 480 && maxCount > 1)
        {
            this.width = Math.round(parentWidth / 2);
            this.itemCount = 2;
        }
        else
        {
            this.width = parentWidth;
            this.itemCount = 1;
        }
    }

    render() {
        return <li ref="swiperItem" className={"swiper-item "+ (this.props.className ? this.props.className : "")}  style={{width: this.state.width}}>
                <div className="content">    
                           {
                               this.props.title ? <h4 className="title">{this.props.title}</h4> : null
                           }
                    
                    {this.props.children}
                </div>  
            </li>
    }
}