import { observable, action, runInAction, computed } from "mobx";
import { INotifierAckMessage } from "./NotifierMessage";
import { NotifierCluster } from "./NotifierCluster";

/**
 * Kliens -> notifier kapcsolat.
 * 
 * Egy konkrét url-en elérhető notifier példánnyal tartja a kapcsolatot.
 * 
 */
export class NotifierConnection {
    private cluster: NotifierCluster;
    private ws : WebSocket|null;
    private _url: string;
    private _calls : { [msgid: string] : (value?:any)=>void } = {}; // Válaszra váró hívások listája.

    @observable private connecting : boolean = false; // Kapcsolódás folyamatban
    @observable private connected : boolean = false; // Kapcsolódva
    @observable private _initialized : boolean = false; // Inicializálva (és kapcsolódva)

    public get initialized() {
        return this._initialized;
    }

    
    private debug = (...args: any[]) => {
        console.log("NotifierConnection", ...args);
    }

    public get url() {
        return this._url;
    }

    /**
     * Állandó kapcsolat kiépítése egy notifier példánnyal.
     */
    constructor(cluster: NotifierCluster, url : string) {
        this.cluster = cluster;
        this.ws = null;
        this._url = url;
        this.reconnect();
    }

    @action.bound private reconnect() {        
        if (!this.connected && !this.connecting) {            
            this.debug("connecting", this._url);
            this.connecting = true;
            this._initialized = false;
            this.ws = new WebSocket(this._url);
            this.ws.onclose = this.onSocketClosed;
            this.ws.onopen = this.onSocketOpened;
            this.ws.onerror = this.onSocketError;
            this.ws.onmessage = this.onSocketMessage;
        }
    }

    private onSocketOpened = (event: Event) => {
        this.debug("socket opened");
        runInAction(()=> {
            this.connected = true;
            // Üdvözlő üzenet
            // https://gitlab.nkp.uni-eszterhazy.hu/ekernel-core-team/ekernel-notifier/-/wikis/protokoll#%C3%BCdv%C3%B6zl%C5%91-%C3%BCzenet-con
            this.ws!.send(JSON.stringify({ "header": { "op": "con" } }));
        })
    }

    private onSocketClosed = (event: Event) => {
        this.debug("socket closed");
        runInAction(() => { 
            this.connected = false; 
            this.connecting = false;
            this.ws = null;
        })
        this.cluster.onConnectionClosed(this);
    }

    private onSocketError = (event: Event) => {
        this.debug("socket error");
        runInAction(() => {
            this.connected = false; 
            this.connecting = false;
            this.ws = null;
        })
    }

    private onSocketMessage = (event: Event) => {
        let message : Object =JSON.parse((event as any).data);
        if (!this._initialized) {
            const ack = (message as INotifierAckMessage);
            // Nyugtázó üzenet
            // https://gitlab.nkp.uni-eszterhazy.hu/ekernel-core-team/ekernel-notifier/-/wikis/protokoll#%C3%BCdv%C3%B6zl%C5%91-%C3%BCzenet-con
            if (ack && ack.header && ack.header.op && ack.header.op=="ack" && ack.header.url) {
                // TODO: check protocol version?
                const url = ack.header.url;
                this.debug("ack", url);                
                runInAction(()=> {
                    this._initialized = true;
                })
                this.cluster.onConnectionInitialized(this);
            } else {
                this.debug("invalid ack", ack);
                this.ws!.close();
            }
        } else {
            // Are we waiting for this as an answer for a call?
            let handled : boolean = false;
            const rid = message["header"]["rid"];
            if (typeof rid === "string") {
                const resolve = this._calls[rid];
                if (resolve!==undefined) {
                    handled = true;
                    resolve(message);                    
                }
            }
            if (!handled) {
                const channel = message["header"]["ch"];
                const body = message["body"];
                if (typeof channel === "string" && typeof body === "object") {
                    handled = this.cluster._dispatchIncomingChannelMessage(channel, body);
                }
            }
            if (!handled) {
                console.debug("unhandled message", message); // TODO
            }
        }
    }

    private _genId():string {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    // Feliratkozó üzenet küldése egy konkrét csatornára.
    // A felirakozás üzenet azonosítóját adja vissza.
    public _subscribe = async (channel:string, rawToken: string) : Promise<string> => {        
        if (!this._initialized) {
            return Promise.reject("Connection not initialized yet.");
        }

        // sub - feliratkozás
        // https://gitlab.nkp.uni-eszterhazy.hu/ekernel-core-team/ekernel-notifier/-/wikis/protokoll#sub-csatorn%C3%A1k-%C3%A9s-kapcsolatok-%C3%B6sszerendel%C3%A9se
        const mid = this._genId();
        const message = {
            header:{
                op: "sub",
                ch: channel,
                tok: rawToken,
                mid
            }
        }
        this.ws!.send(JSON.stringify(message));
        return Promise.resolve(mid);
    }

    /* 
        
        Elküld egy üzenetet, és vár a válaszra. 
        Ez leginkább kontroll üzenetekre használható, ahol biztosan jön válasz.
    */
    private async _call(message: Object) : Promise<Object> {
        const msgid = this._genId();
        message["header"]["mid"] = msgid;
        this.ws!.send(JSON.stringify(message));
        return new Promise((resolve, reject) => {
            this._calls[msgid] = resolve;
        });        
    }

    public async locate(channel: string): Promise<string[]> {
        const token = await this.cluster.obtainToken(channel);
        try {
            // loc - főcsatorna keresése
            // https://gitlab.nkp.uni-eszterhazy.hu/ekernel-core-team/ekernel-notifier/-/wikis/protokoll#loc-f%C5%91csatorna-keres%C3%A9se
            const msg = {"header": {"op": "loc", "ch": channel, "tok": token}}
            const response = await this._call(msg);
            const urls = response["header"]["urls"];
            if (Array.isArray(urls)) {
                if (urls.length>0) {
                    return Promise.resolve(urls);
                } else {
                    return Promise.reject("Cannot locate "+channel+": empty url list");
                }
            } else {
                console.log(response);
                return Promise.reject("Invalid resposne for locate: " + response)
            }            
        } catch (e) {
            return Promise.reject(e);
        }
    }


}