import{ IInstituteGroupRecord, InstituteGroupCrudClassProxy, instituteGroupCrudClassProxy } from '@src/framework/crud/sys/InstituteGroupCrud';
import RecordEditor, { IFieldEditorProp } from '@framework/forms/recordeditor';

export default class InstituteGroupEditor extends RecordEditor<IInstituteGroupRecord> {

    public getCrudClassProxy() : InstituteGroupCrudClassProxy { return instituteGroupCrudClassProxy;  }

    protected async doGetCurrentRec(): Promise<Partial<IInstituteGroupRecord>> {
        try {
            let result = await super.doGetCurrentRec();
            result["nkp1_id"]=undefined;
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    protected getFieldEditor(fProps: IFieldEditorProp): JSX.Element | null {
        if (fProps.fieldName=='nkp1_id') {
            return null;
        } else {
            return super.getFieldEditor(fProps);
        }
    }
}