import * as React from 'react';
import Axios from 'axios';

export class DownloadAsPdf extends React.Component<{id: number, type: string, label?: string, filenameForDebugging?: string, published: boolean}, any> {

    async componentDidMount() {
        if (this.props.filenameForDebugging) {
            const html = (await Axios.get("/export/" + this.props.filenameForDebugging)).data;
            this.setState({
                html
            });
        }
    }

    render() {
        if (this.props.filenameForDebugging) {
            return <form target="_blank" method="post" action={`/api/kap/draft?id=${this.props.id}&format=pdf&type=${this.props.type}&published=${this.props.published}`} encType="multipart/form-data">
                <input type="hidden" name="html" value={this.state && this.state.html || ""}/>
                <input  className="button" type="submit" value="Letöltés PDF-ben (Fejlesztőknek frontend html-el!)"/>
            </form>
        } else {
            return <a 
                    className="button" 
                    target="_blank" 
                    download="Óravázlat.pdf"
                    href={`/api/kap/draft?id=${this.props.id}&format=pdf&type=${this.props.type}&published=${this.props.published}`}>
                <i className="fa fa-download"/>&nbsp; {this.props.children || "Letöltés PDF-ben"}
            </a>
        }
    }
}

