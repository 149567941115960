import obtainServer from '@framework/server/Server';

const URL = 'vfsPermission';

// Adott jogtulajdonoshoz tartozó összes permission type-ot gyűjti össze.
export interface IPermsForSecPermOwner {
    sec_perm_owner_id: number;
    oo_permtype_ids : number[]
}

export interface IPermTypeItem {
    id: number;
    title: string;
}

export interface IUserItem {
    id: number;
    title: string;
}
export interface IAuditByUserResult {
    everybody: boolean;
    guest: boolean;
    users ?: IUserItem[];
}

export default class VfsPermissionAPI
 {
    /** Mappa saját tulajdonba vétel */
    public static takeFolderOwnership = (
        oo_folder_id: number,
        recursive: boolean,
        set_suid: boolean,
        clear_suid: boolean,
        set_inherit: boolean,
        clear_inherit: boolean,
    ) : Promise <any> => {
        return obtainServer().post<any>(URL, {
            operation: "take_folder_ownership",
            oo_folder_id, recursive,
            set_suid, clear_suid,
            set_inherit, clear_inherit,
        });
    }

    public static takeFileOwnership = (ooFileId: number) : Promise <any> => {
        return obtainServer().post<any>(URL, {
            operation: "take_file_ownership",
            oo_file_id: ooFileId,
        });
    }

    public static setFolderPerms(ooFolderId: number, perms: IPermsForSecPermOwner[]) {
        return obtainServer().post<any>(URL, {
            operation: "set_folder_perms",
            oo_folder_id: ooFolderId,
            perms
        });
    }

    public static setFilePerms(ooFileId: number, perms: IPermsForSecPermOwner[]) {
        return obtainServer().post<any>(URL, {
            operation: "set_file_perms",
            oo_file_id: ooFileId,
            perms
        });
    }


    /** Haladó rekurzív jog állítás, csak admin részére.
     *  Ez egy az egyben a media.oo_folder_set_perms() hívásban végződik.
     */
    public static advancedSetFolderPerms = (
        oo_folder_id: number,
        do_self: boolean,
        do_children: boolean,
        anew_owner_id : number|null,
        set_suid: boolean,
        clear_suid: boolean,
        set_inherit: boolean,
        clear_inherit: boolean,
    ) : Promise <any> => {
        return obtainServer().post<any>(URL, {
            operation: "advanced_set_folder_perms",
            oo_folder_id,
            do_self,
            do_children,
            anew_owner_id,
            set_suid,
            clear_suid,
            set_inherit,
            clear_inherit,
        });
    }    

    /** Adott felhasználóra és mappára megadja hogy milyen jogosultságai vannak.
     *  Figyelembe veszi az összes csoport tagságát. Nem túl gyors, és csak admin
     *  használhatja.
     */
    public static getPermsForFolderAndUser = (oo_folder_id: number, sec_user_id: number) : Promise<IPermTypeItem[]> => {
        return obtainServer().post<any>(URL, {
            operation: "get_perms_for_folder_and_user",
            oo_folder_id, sec_user_id
        });
    }

    /** Adott mappára és jogosultság típusra megmondja, hogy kiknél van meg a jog.
     *  Figyelembe veszi az összes csoport tagságát. Nem túl gyors, és csak admin
     *  használhatja.
     * 
     * Figyelem! Ha a visszatérési értékben everybody=true vagy guest=true jön vissza,
     * akkor az azt jelenti, hogy a mindenki csoportra vagy a vendég csoportra meg
     * van adva a jog. Ilyenkor a users rész hiányzik. Minden más esetben töltve van.
     * 
     */
    public static getUsersForFolderAndPerm = (oo_folder_id: number, oo_permtype_id: number) : Promise<IAuditByUserResult> => {
        return obtainServer().post<any>(URL, {
            operation: "get_users_for_folder_and_perm",
            oo_folder_id, oo_permtype_id
        });
    }


    

    /** Adott felhasználóra és állományra megadja hogy milyen jogosultságai vannak.
     *  Figyelembe veszi az összes csoport tagságát. Nem túl gyors, és csak admin
     *  használhatja.
     */
    public static getPermsForFileAndUser = (oo_file_id: number, sec_user_id: number) : Promise<IPermTypeItem[]> => {
        return obtainServer().post<any>(URL, {
            operation: "get_perms_for_file_and_user",
            oo_file_id, sec_user_id
        });
    }

    

    /** Adott állományra és jogosultság típusra megmondja, hogy kiknél van meg a jog.
     *  Figyelembe veszi az összes csoport tagságát. Nem túl gyors, és csak admin
     *  használhatja.
     * 
     * Figyelem! Ha a visszatérési értékben everybody=true vagy guest=true jön vissza,
     * akkor az azt jelenti, hogy a mindenki csoportra vagy a vendég csoportra meg
     * van adva a jog. Ilyenkor a users rész hiányzik. Minden más esetben töltve van.
     * 
     */
    public static getUsersForFileAndPerm = (oo_file_id: number, oo_permtype_id: number) : Promise<IAuditByUserResult> => {
        return obtainServer().post<any>(URL, {
            operation: "get_users_for_file_and_perm",
            oo_file_id, oo_permtype_id
        });
    }    

    

}
