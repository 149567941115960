import * as React from 'react'
import { BubbleLoader } from 'react-css-loaders';
import { SearchResult } from '@src/server/PublicServer';
import * as Server from '@src/server/PublicServer'
import { getOfferDivs } from '@src/component/media/GetOffers';
import * as queryString from 'query-string';
import { __ } from '@src/translation';
import { History, Location } from 'history';

type OffersProps = {
    location: Location;
    history: History;
}

type OffersState = {
    loading: boolean;
    videoOffers: SearchResult[];
    imageOffers: SearchResult[];
    mapOffers:SearchResult[];
    soundOffers:SearchResult[];
    videoOffersBlock: any;
    imageOffersBlock: any;
    mapOffersBlock:any;
    soundOffersBlock:any;
}

export class Offers extends React.Component<OffersProps, OffersState> {

    constructor(prop: any) {
        super(prop);
        
        this.state = {
            loading: false,
            videoOffers: [],
            imageOffers: [],
            mapOffers:[],
            soundOffers:[],
            videoOffersBlock: undefined,
            imageOffersBlock: undefined,
            mapOffersBlock:undefined,
            soundOffersBlock:undefined
        };     
    }

    componentDidMount() {
        this.reloadOffersAsync();
    }
    async componentDidUpdate(prevProps: OffersProps) {
        if (prevProps.location != this.props.location) {
                  
            await this.reloadOffersAsync();
        }
    }

    async reloadOffersAsync() {
        this.setState({
            loading: true,
        });

        
        let searchParams:Server.SearchParameters ={};
       
        const params = queryString.parse(this.props.location.search);
      
        if(params.grade_id)
        {       
            searchParams.grade_id=params.grade_id;
        }
        if(params.subject_id)
        {
            searchParams.subject_id=params.subject_id;
        }

        try {
            searchParams.type ="video";     
            var videoOffers = await Server.search(searchParams);
            videoOffers = videoOffers.filter(e => e.displayname || (e.title_text &&e.title_text.length>0));
            shuffleArray(videoOffers);
            this.setState({videoOffers: videoOffers.slice(0, 8)});
        } catch(e) {/*Nincs elasticsearch index*/}
      
      /*  try {
          
            searchParams.type ="video";    
            var videoOffers = await Server.search(searchParams);
            videoOffers = videoOffers.filter(e => e.displayname);
            shuffleArray(videoOffers);
            this.setState({videoOffers: videoOffers.slice(0, 8)});
        } catch(e) {/*Nincs elasticsearch index*/

        try {
            searchParams.type ="image";
            var imageOffers = await Server.search(searchParams);
            // imageOffers = imageOffers.filter(e => e.displayname);
            shuffleArray(imageOffers);
            this.setState({imageOffers: imageOffers.slice(0, 8)});
        } catch(e) {/*Nincs elasticsearch index*/}

        try {
            searchParams.type ="map";
            var mapOffers = await Server.search(searchParams);
            //mapOffers = mapOffers.filter(e => e.displayname);
            shuffleArray(mapOffers);
            this.setState({mapOffers: mapOffers.slice(0, 8)});
        } catch(e) {/*Nincs elasticsearch index*/}

        try {
            searchParams.type ="audio";
            var soundOffers = await Server.search(searchParams);
            soundOffers = soundOffers.filter(e => e.displayname);
            shuffleArray(soundOffers);
            this.setState({soundOffers: soundOffers.slice(0, 8)});
        } catch(e) {/*Nincs elasticsearch index*/}

            let videoOffersBlock = (!params.type || params.type == "media" || params.type == "video") &&await getOfferDivs(this.props.history, this.props.location, __("Ajánlott videók"), "video", this.state.videoOffers);
            //let imageOffersBlock = (!params.type || params.type == "media" || params.type == "image") &&await getOfferDivs(this.props.history, this.props.location, __("Ajánlott képek"), "image", this.state.imageOffers);
            let mapOffersBlock= (!params.type || params.type == "media" || params.type == "map") &&await getOfferDivs(this.props.history, this.props.location, __("Ajánlott térképek"), "map", this.state.mapOffers)
            let soundOffersBlock= (!params.type || params.type == "media" || params.type == "audio") &&await getOfferDivs(this.props.history, this.props.location, __("Ajánlott hanganyagok"), "audio", this.state.soundOffers);
        this.setState({
            loading: false,
            videoOffersBlock,
            //imageOffersBlock,
            mapOffersBlock,
            soundOffersBlock
        });
    }

    render() {

        const params = queryString.parse(this.props.location.search);
        if (this.state.loading) {
            return <div className="row"><BubbleLoader /></div>;
        }
        return <div>
                {(!params.type || params.type == "media" || params.type == "video") &&this.state.videoOffersBlock}
                {(!params.type || params.type == "media" || params.type == "image") &&this.state.imageOffersBlock}
                {(!params.type || params.type == "media" || params.type == "map") && this.state.mapOffersBlock}
                {(!params.type || params.type == "media" || params.type == "audio") &&this.state.soundOffersBlock}
        </div>;
    }
}
export function shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}
