import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ILessonRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Utolsó találat időpontja
     */
    search_popularity_last_hit_date?: string /*timestamp?*/|null;
    /**
     * Utolsó népszerűségi index
     */
    search_popularity_last_score?: number /* double precision */|null;
    /**
     * Név
     */
    name?: string;
    /**
     * Fejezet
     *
     * NOT NULL -> doc.chapter ON DELETE cascade
     *
     */
    chapter_id?: number;
    /**
     * URL rész
     *
     * URL path (útvonal) szegmense
     */
    uri_segment?: string;
    /**
     * Sorszám
     *
     * A lecke sorszáma, ahogy az a könyv lapok tetején megjelenik.
     */
    headno?: number /*integer*/|null;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
}

/**
 *  doc.lesson - Lecke
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class LessonCrud extends Crud<ILessonRecord> {
    public static TABLE_INFO_ID = 806138560;
    public static getSchemaNameForClass() { return 'doc'; }
    public static getTableNameForClass() { return 'lesson'; }

    public static load: (id: number, aServer ?: Server) => Promise<LessonCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ILessonRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ILessonRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ILessonRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ILessonRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ILessonRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class LessonCrudClassProxy extends CrudClassProxy<ILessonRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const lessonCrudClassProxy = new LessonCrudClassProxy(LessonCrud);
registerCrudClassProxy(lessonCrudClassProxy);
