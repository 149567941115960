import * as React from 'react';
import ForumMessageCard from './ForumMessageCard';
import { __ } from '@src/translation';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { ForumContainerStore, forumStore, MessageListStore } from '../forumStore';

interface IForumMessagesProps {
    storeId: string;
}

/**
 * Egy adott topic üzenet listájának megjelenítésére használható komponens.
 */
@observer
export class ForumMessages extends React.Component<IForumMessagesProps, {}> {

    public get containerStore(): ForumContainerStore {
        return forumStore.getContainerStore(this.props.storeId);
    }

    @computed public get messageStore(): MessageListStore | null {
        const containerStore = this.containerStore;
        if (containerStore.forumId === null || containerStore.topicId === null) {
            return null;
        } else {
            return forumStore.getMessageListStore(containerStore.forumId, containerStore.topicId);
        }
    }

    constructor(props: IForumMessagesProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount() {
        const messageStore = this.messageStore;
        if (messageStore !== null) {
            if (!messageStore.loaded) {
                messageStore.reload();
            }
        }
    }

    private reloadAll() {
        this.messageStore!.reload();
    }

    render() {
        const messageStore = this.messageStore;
        if (!messageStore) { return null; }
        return <div>
            {/* <button title={__("Összes üzenet újratöltése")} className="button primary" onClick={() => this.reloadAll()}
                disabled={messageStore.loading}
            >
                <i className="fas fa-recycle"></i> {
                    messageStore.loading ? __("Betöltés...") : __("Frissít!")
                }                
            </button> */}
            <div style={{textAlign:"right"}}>
                <button title={__("Összes üzenet újratöltése")} onClick={() => this.reloadAll()}
                    disabled={messageStore.loading}><i className={"fas fa-sync-alt post-refresh-btn" + (messageStore.loading?" sync":"")}></i></button>
            </div>

            <div className="">
                {messageStore.messages.map(message =>
                    <ForumMessageCard key={message._id}
                        storeId={this.props.storeId}
                        forumId={message.forumId}
                        topicId={message.topicId}
                        messageId={message._id}
                    />
                )}
            </div>

            {/* <button title={__("Összes üzenet újratöltése")} className="button primary" onClick={() => this.reloadAll()}
                disabled={messageStore.loading}
            >
                <i className="fas fa-recycle"></i> {
                    messageStore.loading ? __("Betöltés...") : __("Frissít!")
                }
            </button> */}
        </div>;
    }
}
