import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IDetailTableRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Folyamat fej táblázat
     *
     * NOT NULL -> wf.head_table ON DELETE noaction
     *
     */
    head_id?: number;
    /**
     * Tétel táblázat
     *
     * NOT NULL -> meta.table_info ON DELETE noaction
     *
     */
    table_id?: number;
    /**
     * A fejre mutató mező
     *
     * NOT NULL -> meta.field_info ON DELETE noaction
     *
     */
    ref_field_id?: number;
}

/**
 *  wf.detail_table - Folyamat típus tétel táblázat
 *
 *  Ez a táblázat határozza meg, hogy a folyamat fej táblázata alá milyen tételek tartoznak. (Például: a könyv alá a fejezetek vagy a leckék.) Az ilyen master-detail kapcsolatok megadása azért fontos, mert a folyamat állapotától függően megszorításokat lehet megadni a tételekre is. (Pl. jóváhagyás alatti állapotban nem módosítható.)
 */

export default class DetailTableCrud extends Crud<IDetailTableRecord> {
    public static TABLE_INFO_ID = 2265307131;
    public static getSchemaNameForClass() { return 'wf'; }
    public static getTableNameForClass() { return 'detail_table'; }

    public static load: (id: number, aServer ?: Server) => Promise<DetailTableCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IDetailTableRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IDetailTableRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IDetailTableRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IDetailTableRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IDetailTableRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class DetailTableCrudClassProxy extends CrudClassProxy<IDetailTableRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const detailTableCrudClassProxy = new DetailTableCrudClassProxy(DetailTableCrud);
registerCrudClassProxy(detailTableCrudClassProxy);
