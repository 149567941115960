import * as React from 'react';
import './checkbox.css';

export interface ThreeStateRadioGroupProps extends React.HTMLAttributes<HTMLElement> {
    value : any;
    disabled ?: boolean;

    className?: string;
    name :string;

    /** @default true */
    yesvalue ?: any;
    /** @default false */
    novalue ?: any;
    /** @default undefined */
    thirdvalue ?: any;

    /** @default "Igen" */
    yeslabel ?: string;
    /** @default "Nem" */
    nolabel ?: string;
    /** @default "Mindet" */
    thirdlabel ?: string;

    onValueChange: (newValue: boolean|null) => void;
  }

export default class ThreeStateRadioGroup extends React.Component<ThreeStateRadioGroupProps> {
    render() {
        let props : any = Object.assign({}, this.props);
        delete props.onValueChange;
        delete props.onClick;        
        delete props.disabled;
        const id_prefix = props.name+"_";

        const yeslabel =  (this.props.yeslabel===undefined)?"Igen":this.props.yeslabel;
        const nolabel =  (this.props.yeslabel===undefined)?"Nem":this.props.nolabel;
        const thirdlabel =  (this.props.thirdlabel===undefined)?"Mindet":this.props.thirdlabel;

        const yesvalue = (this.props.yesvalue===undefined)?true:this.props.yesvalue;
        const novalue = (this.props.novalue===undefined)?false:this.props.novalue;
        const thirdvalue = this.props.thirdvalue;

        return <div className={this.props.className}>
                
                    <label htmlFor={id_prefix+"true"} className="radio-button-label">
                    <input type="radio"
                    name={props.name}
                    value="true"
                    id={id_prefix+"true"} 
                    required
                    disabled={this.props.disabled}
                    checked={this.props.value===yesvalue}
                    onChange={() => this.props.onValueChange(yesvalue) }
                    /> {yeslabel}</label>
               
                    <label htmlFor={id_prefix+"false"} className="radio-button-label"> 
                    <input type="radio"
                    name={props.name}
                    value="false"
                    id={id_prefix+"false"} 
                    required
                    disabled={this.props.disabled}
                    checked={this.props.value===novalue}
                    onChange={() => this.props.onValueChange(novalue) }
                    /> {nolabel}</label>

               
                    <label htmlFor={id_prefix+"third"} className="radio-button-label"> 
                    <input type="radio"
                    name={props.name}
                    value="third"
                    id={id_prefix+"third"} 
                    required
                    disabled={this.props.disabled}
                    checked={this.props.value===thirdvalue}
                    onChange={() => this.props.onValueChange(thirdvalue) }
                    /> {thirdlabel}</label>
            </div>;
    }
}