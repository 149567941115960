import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewStationRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Sorrend
     */
    no?: number /*int4*/|null;
    /**
     * Folyamat típus
     */
    wf_type_id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Leírás
     */
    description?: string;
    /**
     * Kezdeti állapot
     *
     * A folyamat elindulhat ezzel az állapottal.
     */
    is_start?: boolean;
    /**
     * Lezárt állapot
     *
     * A lezárt állapot azt jelenti, hogy a folyamattal nincs több tennivaló, nem várunk külső vagy belső eseményre.
     */
    is_closed?: boolean;
    /**
     * Elszámolható állapot
     *
     * Mint pl.: számlánál pénzügyileg, raktárnál készlet szempontjából stb.
     */
    is_eligible?: boolean;
    /**
     * Fej módosítható
     */
    head_mod?: boolean;
    /**
     * Fej törölhető
     */
    head_del?: boolean;
    /**
     * Tétel hozzáadható
     */
    det_ins?: boolean;
    /**
     * Tétel módosítható
     */
    det_mod?: boolean;
    /**
     * Tétel törölhető
     */
    det_del?: boolean;
    /**
     * Értesít
     *
     * Értesíti a résztvevőket az állapotba való beérkezéskor.
     */
    notify?: boolean;
    /**
     * Figyelmeztetés lejárat
     */
    warning_timeout?: any /* interval */|null;
    /**
     * Hiba lejárat
     */
    error_timeout?: any /* interval */|null;
    /**
     * Stílus
     *
     * Az állapot színe, betűtípusa stb.
     */
    style?: any|null;
    /**
     * Törölt állapot
     *
     * Olyan állapot, ami a logikai törléssel egyenértékű.
     */
    is_deleted?: boolean;
    /**
     * Alapértelmezett al-státusz
     */
    default_substation_id?: number|null;
    /**
     * Al-státusz megadása kötelező
     */
    substation_required?: boolean;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Folyamat típus kód
     *
     * A folyamat típus kódja
     */
    wf_type_code?: string|null;
    /**
     * Folyamat típus név
     *
     * A folyamat típus neve
     */
    wf_type_name?: string|null;
}

/**
 *  wf.view_station - Folyamat állapot
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewStation extends View<IViewStationRecord> {
    public static VIEW_INFO_ID = 2018040503;

    public static getSchemaNameForClass(): string { return 'wf'; }
    public static getViewNameForClass(): string { return 'view_station'; }
    public static getTableNameForClass(): string|null { return 'station'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewStation>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewStationRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewStationClassProxy extends ViewClassProxy<IViewStationRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewStationClassProxy = new ViewStationClassProxy(ViewStation);
registerViewClassProxy(viewStationClassProxy);

