import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IShareModeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Név
     */
    title?: string;
    /**
     * Leírás
     */
    description?: string|null;
}

/**
 *  usr.share_mode - Megosztás módja
 */

export default class ShareModeCrud extends Crud<IShareModeRecord> {
    public static TABLE_INFO_ID = 1834298715;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'share_mode'; }

    public static load: (id: number, aServer ?: Server) => Promise<ShareModeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IShareModeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IShareModeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IShareModeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IShareModeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IShareModeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ShareModeCrudClassProxy extends CrudClassProxy<IShareModeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const shareModeCrudClassProxy = new ShareModeCrudClassProxy(ShareModeCrud);
registerCrudClassProxy(shareModeCrudClassProxy);
