import * as React from 'react';

import { loadAnnotatorScriptAsync, timeoutAsync } from "@src/Util";
import { me, hasGroup, Groups } from "@src/framework/server/Auth";
import SectionCommentCrud, { ISectionCommentRecord } from "@src/framework/crud/doc/SectionCommentCrud";
import { app } from "@src/index";
import ViewSectionComment, { IViewSectionCommentRecord } from "@src/framework/view/doc/ViewSectionComment";
import { showUnpublished } from '@src/component/book/viewer/PublishedSelector';

import './BookAnnotations.css';
import { annotatorMain } from '@src/component/book/viewer/page_addition/AnnotatorUI';
import { __ } from '@src/translation';
export const SECTION_COMMENT_TYPE_USER_ID = 1;
export const SECTION_COMMENT_TYPE_LECTOR_ID = 2;

export enum AnnotationMode {
    NONE,
    LEKTOR_COMMENTING,
    USER_COMMENTING,
    USER_MARKING,
    USER_DELETING
}

type BookAnnotationProps = {
    lessonId: number;
    annotationMode: AnnotationMode;
}

type BookAnnotationState = {

}

export class BookAnnotations extends React.Component<BookAnnotationProps, BookAnnotationState> {

    constructor(props: BookAnnotationProps) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        this.resetAnnotations();
    }

    componentDidUpdate(prevProps: BookAnnotationProps) {
        if (this.props.lessonId !== prevProps.lessonId || this.props.annotationMode !== prevProps.annotationMode) {
            this.resetAnnotations();
        }
    }

    resetAnnotations() {
        destroyAnnotations();
        
        if (me) {
            
            const root = this.refs.root as HTMLDivElement;
            initAnnotations(root.ownerDocument!, this.props.lessonId, this.props.annotationMode);
        }
    }

    componentWillUnmount() {
        destroyAnnotations();
    }

    render() {
        const className = (this.props.annotationMode == AnnotationMode.LEKTOR_COMMENTING) ? 'eke-annotator-lector' : 'eke-annotator-user';
        return <div ref="root" className={className}>
            {this.props.children}
        </div>;
    }

}


// http://docs.annotatorjs.org/en/latest/module-development.html
// http://docs.annotatorjs.org/en/latest/api/registry.html
// http://docs.annotatorjs.org/en/latest/api/storage.html
class AnnotationOkostankonyvStorage {
    
    private originalHtml: string;

    private sectionId: number;

    private sectionCommentTypeId: number;

    private sectionCommentsForLesson: IViewSectionCommentRecord[];

    annotations: any[] = [];
    constructor(originalHtml: string, sectionId: number, sectionCommentTypeId: number, sectionCommentsForLesson: IViewSectionCommentRecord[]) {
        this.originalHtml = originalHtml;
        this.sectionId = sectionId;

        this.sectionCommentTypeId = sectionCommentTypeId; //(annotationMode == AnnotationMode.LEKTOR_COMMENTING) ? SECTION_COMMENT_TYPE_LECTOR_ID : SECTION_COMMENT_TYPE_USER_ID;

        this.sectionCommentsForLesson = sectionCommentsForLesson;
    }

    configure(registry: any) {
        registry.registerUtility(this, 'storage');
    }

    async create(annotation: any) {

        try {

            const sectionCommentRecord: ISectionCommentRecord = {
                quote: annotation.quote,
                section_id: this.sectionId,
                ranges: { ranges: annotation.ranges },
                comment: annotation.text,
                original_html: this.originalHtml,
                section_comment_type_id: this.sectionCommentTypeId
            };

            const response = await new SectionCommentCrud(sectionCommentRecord).put();

            annotation.id = response.record.id;

            return annotation;
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    async update(annotation: any) {
        try {
            const sectionCommentRecord: ISectionCommentRecord = {
                id: annotation.id,
                comment: annotation.text
            };

            await new SectionCommentCrud(sectionCommentRecord).put();

            return annotation;
        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    async delete(annotation: any) {
        try {
            await new SectionCommentCrud(annotation).del();

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
        
    }

    async query() {
        this.annotations = [];

        try {
            const sectionComments = this.sectionCommentsForLesson.filter(s => s.section_id == this.sectionId);

            //const sectionComments = await SectionCommentCrud.list({filter: { is_active: true, section_id: this.sectionId}});

            for(const sectionComment of sectionComments) {
                if (sectionComment.ranges) {

                    const annotation: any = {
                        id: sectionComment.id,
                        ranges: sectionComment.ranges.ranges,
                        quote: sectionComment.quote,
                        text: sectionComment.comment,

                        creation_fullname: sectionComment.creation_fullname,
                        creation_time: sectionComment.creation_time,
                        creation_user_id: sectionComment.creation_user_id,
                    };

                    if (me == null || sectionComment.creation_user_id != me.id) {
                        annotation.permissions = { update: false, delete: false };
                    }

                    this.annotations.push(annotation);
                }
            }

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }

        return {results: this.annotations};
    }

}

/**
 * Img, Iframe, Video, Okosfeladat element-ekhez megjegyzés
 */
class SpecialElementAnnotator {

    private annotator: any;

    private app: any;

    private section: Element;

    private adders: any[] = [];

    private resizeEventListener: any;

    private timeout: any;

    constructor(annotator:any, section: Element) {
        this.annotator = annotator;
        this.section = section;

    }

    start(app: any) {
        this.app = app;

        for(const element of Array.from(this.section.querySelectorAll("img, iframe, video, div[data-exercise]"))) {
            if (element.tagName == "IMG" &&
                    element.parentElement!.tagName != "A" &&
                    element.parentElement!.tagName != "PICTURE"
                    ) {
                continue;
            }
            this.createAdder(element);
            this.createViewer(element);
        }

        this.resizeEventListener = window.addEventListener('resize', () => {
            for(const adder of this.adders) {
                this.show(adder);
            }
        });

        this.timeout = setTimeout(this.onTimeout.bind(this), 500); // A dinamukisan betöltődő képek és iframe-k miatt kell
    }

    onTimeout() {
        for(const adder of this.adders) {
            this.show(adder);
        }
        this.timeout = setTimeout(this.onTimeout.bind(this), 500);
    }

    destroy() {
        window.removeEventListener('resize', this.resizeEventListener);
        for(const adder of this.adders) {
            adder.destroy();
        }
        clearTimeout(this.timeout);
    }

    private createAdder(element: Element) {
        const path = this.getPath(this.section, element);

        const that = this;

        const store: AnnotationOkostankonyvStorage = that.app.annotations.store;

        const adder = new this.annotator.ui.adder.Adder({
            onCreate: async function() {

                const editor = new that.annotator.ui.editor.Editor();
                editor.attach();
                try {
                    
                    const anns: any[] = store.annotations;

                    var annotation: any = {}; // load

                    for(const ann of anns) {
                        if (me && 
                                ann.creation_user_id == me.id &&
                                ann.ranges &&
                                ann.ranges.start == path
                                ) {
                            annotation = ann;
                        }
                    }

                    annotation.ranges = {start: path, end: path};

                    await editor.load(annotation, that.getTopCenter(element));

                    if (annotation.id) {
                        if (annotation.text) {
                            await store.update(annotation);
                        } else {
                            await store.delete(annotation);
                        }
                    } else if (annotation.text) {
                        await store.create(annotation);
                    }

                    await store.query();

                } finally {
                    editor.destroy();
                    that.show(adder);
                }
                
            }
        });


        adder._element = element;
        adder.attach();
        this.adders.push(adder);

        this.show(adder);


        adder.element.get(0).addEventListener("mouseover", () => {
            this.mouseEvent(adder, 'over_adder', true);
        });
        adder.element.get(0).addEventListener("mouseout", () => {
            this.mouseEvent(adder, 'over_adder', false);
        });
        element.addEventListener("mouseover", () => {
            this.mouseEvent(adder, 'over_element', true);
        });
        element.addEventListener("mouseout", () => {
            this.mouseEvent(adder, 'over_element', false);
        });


        adder.element.hide();
    }

    mouseEvent(adder: any, name: string, over: boolean) {
        adder[name] = over;
        const show = adder["over_element"] || adder["over_adder"];
        adder.element.toggle(show);
    }

    private createViewer(element: Element) {

        const path = this.getPath(this.section, element);

        const store: AnnotationOkostankonyvStorage = this.app.annotations.store;

        element.addEventListener("mouseover", (event: any) => {
            const anns: any[] = store.annotations;

            var annotations: any = []; // load

            for(const ann of anns) {
                if (ann.ranges &&
                        ann.ranges.start == path
                        ) {
                    annotations.push(ann);
                }
            }
            if (annotations.length > 0) {
                const viewer = new this.annotator.ui.viewer.Viewer({
                    onEdit: () => {},
                    onDelete: () => {},
                    permitEdit: () => {return false},
                    permitDelete: () => {return false},
                    extensions: [creatorRenderer],
                });
                viewer.attach();
                viewer.load(annotations, {top: event.pageY - 10, left: event.pageX});

                var mouseOutHandler = () => {
                    if (viewer) {
                        viewer.destroy();
                    }
                    element.removeEventListener('mouseout', mouseOutHandler);
                };
                element.addEventListener("mouseout", mouseOutHandler);
            }
            
        });
    }

    private getPath(from: Element, to: Element): string {
        if (to == from || !to.parentElement) return "";

        const index = 1 + Array.from(to.parentElement.childNodes).filter(x => x.nodeName == to.nodeName).indexOf(to);

        return this.getPath(from, to.parentElement) + "/" + to.tagName.toLowerCase() + "[" + index + "]";
    }

    private show(adder: any) {
        adder.load({}, this.getTopCenter(adder._element));
    }

    private getTopCenter(element: Element) {
        const bounds = element.getBoundingClientRect();
        const top = bounds.top + window.scrollY + 40;
        const left = (bounds.left + bounds.right) / 2 + window.scrollX;
        return {top: top, left: left};
    }
}

/**
 * Show creator and creation time view viewing an annotation
 */
const creatorRenderer = function(viewer: any) {
    viewer.addField({
        load: (field: any, annotation: any) => {
            field.innerText = "";

            if (annotation.creation_fullname) {
                field.innerHTML += "<strong>" + annotation.creation_fullname + "</strong>";
            }

            if (annotation.creation_time) {
                field.innerText += " / " + annotation.creation_time;
            }
        }
    });
};

var annotatorApps: any[] = [];

export async function destroyAnnotations() {
    for(const annotatorApp of annotatorApps) {
        try {
            annotatorApp.destroy();
        } catch(e) {console.error(e)};
    }
    annotatorApps = [];
}

export async function initAnnotations(document: Document, lessonId: number, annotationMode: AnnotationMode) {
    destroyAnnotations();

    const body = document.getElementsByTagName("body")[0];
    const sections = document.querySelectorAll("#content > *");

    if (sections.length > 0) {

        await loadAnnotatorScriptAsync(document);

        const annotator = document.defaultView!["annotator"];
        if (!annotator) {
            console.log("Annotator not loaded");
            return;
        }
    
        const sectionCommentTypeId = (annotationMode == AnnotationMode.LEKTOR_COMMENTING) ? SECTION_COMMENT_TYPE_LECTOR_ID : SECTION_COMMENT_TYPE_USER_ID;

        const filter: any = { is_active: true, lesson_id: lessonId, section_comment_type_id: sectionCommentTypeId};
        if (sectionCommentTypeId == SECTION_COMMENT_TYPE_USER_ID) {
            if (!me) return;

            filter.creation_user_id = me.id;
        }

        const sectionComments = await ViewSectionComment.list({filter: filter});

        for(const section of Array.from(sections)) {
            if (!document.body.contains(section)) {
                continue;
            }

            const sectionId = parseInt(section.id.replace("section-", ""));

            if (!sectionId) continue;

            

            const annotatorApp = new annotator.App();

            annotatorApp.include(annotatorMain, {window: document.defaultView, element: section, viewerExtensions: [creatorRenderer], annotationMode: annotationMode});
            
            annotatorApp.include(() => new AnnotationOkostankonyvStorage(section.innerHTML, sectionId, sectionCommentTypeId, sectionComments));

            if (annotationMode == AnnotationMode.LEKTOR_COMMENTING) {
                annotatorApp.include(() => new SpecialElementAnnotator(annotator, section));
            }

            await annotatorApp.start();
            annotatorApp.annotations.load();
            
            annotatorApps.push(annotatorApp);


            await timeoutAsync(50);
        }
    
    }

}


/**
 * Gettext-es fordítás hack, ezért a három szóért nem akartam felrakni az egész gettext.js-t, és a magyar .po-t
 */
class Gettext {
    note: "Annotator.js gettext translations";
    gettext(msgid: string) {
        switch(msgid) {
            case "Comments": return __("Megjegyzés");
            case "Cancel": return __("Mégse");
            case "Save": return __("Mentés");
            case "No comment": return "";
            case "Edit": return __("Szerkesztés");
            case "Delete": return __("Törlés");
        }
        return msgid;
    }
}
window["Gettext"] = Gettext;
