import * as React from 'react';
import { __ } from '@src/translation';

type PagerProps = {
    resultNum: number;
    elementPerPage: number;
    pageIndex: number;
    paging: () => void;
    setPageIndex: (i: number) => void;
}

type PagerState = {
    from: number;
    to: number;
    pageCount: number;
    pageIndex: number;
}

export class Pager extends React.Component<PagerProps, PagerState> {

    constructor(props: PagerProps) {
        super(props);

        this.state = {
            pageIndex: this.props.pageIndex,
            from: 1,
            to: 0,
            pageCount: 0
        }
    }

    componentDidMount() {
        this.setState({ pageCount: Math.ceil(this.props.resultNum / this.props.elementPerPage) }, () => this.setPageIndex(this.props.pageIndex));
    }
    componentDidUpdate(prevProps: PagerProps, prevState: PagerState) {

        if (prevProps.pageIndex !== this.props.pageIndex) {
            this.setState({ pageIndex: this.props.pageIndex }, () =>
                this.props.setPageIndex(this.props.pageIndex));

        }
        if (prevProps.resultNum !== this.props.resultNum) {
            this.setState({ pageCount: Math.ceil(this.props.resultNum / this.props.elementPerPage) }, () =>
                this.setPageIndex(this.props.pageIndex));
        }
        if (prevState.pageIndex !== this.state.pageIndex) {
            this.props.setPageIndex(this.state.pageIndex);
            this.props.paging();
        }
    }


    private setPageIndex = (pageIndex: number) => {
        let from = pageIndex > 3 ? pageIndex - 2 : 1;
        let to = pageIndex < 3 ? 5 : pageIndex + 4;

        if (pageIndex > this.state.pageCount - 3 && this.state.pageCount > 5) {
            from = this.state.pageCount - 4;
        }

        if (pageIndex > 2 && (pageIndex < this.state.pageCount - 2)) {
            to = pageIndex + 2;
        }
        if (pageIndex > this.state.pageCount - 3) {
            to = this.state.pageCount;
        }

        if (this.state.pageCount < 6) {
            from = 1;
            to = this.state.pageCount;
        }
        this.setState({ pageIndex: pageIndex, from: from, to: to });
    }

    render() {
        let pageIndents: JSX.Element[] = [];
        if (this.state.pageCount > 5) pageIndents.push(<><h2 key="p-000"><a key="pager-000" title={__("Első oldalra ugrás")} href="javascript:void(0)" onClick={this.setPageIndex.bind(this, 1)}>❮❮</a></h2><h2 key="p-00"><a key="pager-00" title={__("Előző oldal")} href="javascript:void(0)" onClick={this.setPageIndex.bind(this, this.state.pageIndex > 1 ? this.state.pageIndex - 1 : this.state.pageIndex)}>❮</a></h2></>);
        for (var i = this.state.from; i <= this.state.to; i++) {
            pageIndents.push(<h2 key={"p" + i}>
                <a href="javascript:void(0)" key={"pager-" + i} className={this.state.pageIndex == i ? "active" : undefined} onClick={this.setPageIndex.bind(null, i)} title={i + __(". oldal")}>
                    {i}
                </a>
            </h2>);
        }
        if (this.state.pageCount > 5 && this.state.pageIndex + 2 < this.state.pageCount && this.state.to < this.state.pageCount) {
            pageIndents.push(<h2 key={"p-more"}>...</h2>);
        }
        if (this.state.pageCount > 5 && this.state.pageCount > 1 && this.state.to < this.state.pageCount) {
            pageIndents.push(<h2 key={"p-" + this.state.pageCount + 1}>
                <a key={"pager-" + this.state.pageCount + 1} href="javascript:void(0)" className={this.state.pageIndex == this.state.pageCount ? "active" : undefined} onClick={this.setPageIndex.bind(null, this.state.pageCount)} title={this.state.pageCount + __(". oldal")}>
                    {this.state.pageCount}
                </a>
            </h2>);
        }
        if (this.state.pageCount > 5) pageIndents.push(<><h2 key="p-001"><a key="pager-001" title={__("Következő oldalra ugrás")} href="javascript:void(0)" onClick={this.setPageIndex.bind(this, this.state.pageIndex < this.state.pageCount ? this.state.pageIndex + 1 : this.state.pageIndex)}>❯</a></h2><h2 key="p-01"><a key="pager-01" title={__("Utolsó oldalra ugrás")} href="javascript:void(0)" onClick={this.setPageIndex.bind(this, this.state.pageCount)}>❯❯</a></h2></>);


        return <div className="pagination small-12 columns text-center" key="pages_bottom">
            {pageIndents}
        </div>
    }
}