import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISecPermOwnerRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Alkalmazás szintű felhasználó
     *
     * -> sys.sec_user ON DELETE cascade
     *
     */
    user_id?: number|null;
    /**
     * Felhasználói csoport
     *
     * -> sys.sec_group ON DELETE cascade
     *
     */
    group_id?: number|null;
}

/**
 *  sys.sec_perm_owner - Engedély birtokos
 */

export default class SecPermOwnerCrud extends Crud<ISecPermOwnerRecord> {
    public static TABLE_INFO_ID = 4085568760;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'sec_perm_owner'; }

    public static load: (id: number, aServer ?: Server) => Promise<SecPermOwnerCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISecPermOwnerRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISecPermOwnerRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISecPermOwnerRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISecPermOwnerRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISecPermOwnerRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SecPermOwnerCrudClassProxy extends CrudClassProxy<ISecPermOwnerRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const secPermOwnerCrudClassProxy = new SecPermOwnerCrudClassProxy(SecPermOwnerCrud);
registerCrudClassProxy(secPermOwnerCrudClassProxy);
