import * as React from 'react';
import { app } from '@src/index';
import ReactTable from 'react-table';
import { getReactTableLabels } from '@src/framework/i18n';
import { debounce } from 'lodash';
import { TFilterDict, StringSearchKind } from '@src/framework/crud/Crud';
import { BubbleLoader } from 'react-css-loaders';
import { __ } from '@src/translation';
import { Link } from "react-router-dom";
import { confirmDialog } from '../Dialog';
import { formatDate } from '@src/Util';
import SurveyCrud, { ISurveyRecord, surveyCrudClassProxy } from '@src/framework/crud/usr/SurveyCrud';
import { PATH_COURSE_ORGANIZER_SURVEY, PATH_SURVEY_EDIT, PATH_SURVEY_SUMMARY_PAGE } from '@src/Routes';
import { hasGroup, Groups, me } from '@src/framework/server/Auth';
import SurveyAnswerCrud from '@src/framework/crud/usr/SurveyAnswerCrud';


type SurveyListProps = {

}

type SurveyListState = {
    surveys: ISurveyRecord[];
    count: number;
    itemsPerPage: number;
    loading: boolean;
}

export class SurveyList extends React.Component<SurveyListProps, SurveyListState> {

    constructor(props: SurveyListProps) {
        super(props);

        this.state = {
            surveys: [],
            count: 0,
            itemsPerPage: 10,
            loading: false
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }
    // componentDidUpdate(prevProps: SurveyListProps) {
    //     if (prevProps.match.params.contentTypeId !== this.props.match.params.contentTypeId) {
    //         this.reloadContentType();
    //     }
    // }

    private async onDelete(id: number) {

        if (!await confirmDialog(__("Törlés"), __("Biztosan törölni akarja a kérdőívet a listából?"), __("Törlés"))) return;

        try {
            await surveyCrudClassProxy.deleteById(id);
            app.showSuccess(__("Kérdőív törlése"), __("Sikeres"))
            this.reloadAsync();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    //string---> { ans_id: [], value: "A válasz" }
    //number[] ---> { ans_id: [2,5,6] }
    async onTempSurveyAnswersConvert() {
        const surveyAnswers = await SurveyAnswerCrud.list({});
        console.log("Konvertálandó kérdőív válaszok száma: " , surveyAnswers);
        surveyAnswers.forEach(record => {
            let newObject = {};
            Object.keys(record.answers).forEach(function (key) {
                console.log(key, record.answers[key]);
                //Ha number[]
                if (Array.isArray(record.answers[key])) {
                    newObject[key] = {ans_id: record.answers[key]};
                }
                //Ha string
                else {
                    newObject[key] = {ans_id: [], value: record.answers[key]}
                }
            });
            let surveyAnswer = {id: record.id, answers: newObject}
            console.log("A konvertált objektum: ", surveyAnswer);
            const crud = new SurveyAnswerCrud(surveyAnswer);
            const response = crud.put();

        });
        console.log("Convert finished");
    }

    private debouncedSetTableState = debounce((tablestate) => this.reloadAsync(tablestate), 200);

    private async reloadAsync(tablestate?: { page: number, pageSize: number, sorted: any, filtered: any[] }) {

        this.setState({ loading: true });

        let page = tablestate ? tablestate.page : 0;
        let pageSize = tablestate ? tablestate.pageSize : this.state.itemsPerPage;

        try {
            const filter: TFilterDict = {
                is_active: true,
            };

            var orderBy: any = undefined;
            if (tablestate && tablestate.sorted.length > 0) {
                const userOrder = tablestate.sorted[0];

                if (userOrder.desc) {
                    orderBy = [{ name: userOrder.id, desc: true }];
                } else {
                    orderBy = userOrder.id
                }
            }

            if (!orderBy) orderBy = [{ name: "id", desc: true }];


            if (tablestate && tablestate.filtered.length > 0) {
                for (const userFilter of tablestate.filtered) {
                    filter[userFilter.id] = { kind: StringSearchKind.Contains, case_sensitive: false, expr: userFilter.value }

                }
            }

            const surveys = await SurveyCrud.list({
                filter,
                order_by: orderBy,
                offset: page * pageSize,
                limit: pageSize,
            });

            const count = await SurveyCrud.count({ filter });
            this.setState({
                surveys,
                count: count,
                itemsPerPage: pageSize,
                loading: false
            });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (!this.state.surveys) {
            return <BubbleLoader />
        }

        return <div className="content-wrapper">
            <div className="row">
                <div className="column small-12">
                    <Link className="button small" to={PATH_SURVEY_EDIT + "/"}><i className="fa fa-plus" /> {__("Új kérdőív létrehozása")}</Link>
                    <h4>Kérdőívek</h4>
                    <p>{this.state.count + " db"}</p>

                    <ReactTable
                        columns={[
                            {
                                Header: __("Id"), accessor: "id", sortable: true, width: 180, className: "action-cell",
                                Cell: (data: { original: ISurveyRecord }, column: any) => {
                                    return data.original.id || "";
                                }
                            },
                            {
                                Header: __("Cím"), accessor: "title", sortable: true, filterable: true, Cell: (data: { original: ISurveyRecord }, column: any) => {
                                    return <Link to={PATH_COURSE_ORGANIZER_SURVEY + "/?survey_id=" + data.original.id +"&isPreview=true"}>
                                        {data.original.title || ""}
                                    </Link>
                                }
                            },
                            {
                                Header: __("Készült"), accessor: "creation_time", sortable: true, width: 180, className: "action-cell",
                                Cell: (data: { original: ISurveyRecord }, column: any) => {
                                    return formatDate(data.original.creation_time || "")
                                }
                            },
                            {
                                Header: __("Műveletek"), filterable: false, sortable: false, width: 180, className: "action-cell",
                                Cell: (data: { original: ISurveyRecord }, column: any) => {
                                    return <div className="text-center">
                                        <Link className="button small" title={__("Szerkesztés")} to={PATH_SURVEY_EDIT + "/" + data.original.id}><i className="fa fa-edit"></i></Link>
                                        <Link className="button small" title={__("Kérdőív eredményei")} to={PATH_SURVEY_SUMMARY_PAGE + "/" + data.original.id}><i className="fa fa-chart-bar"></i></Link>
                                        <button className="button small alert" title={__("Törlés")} onClick={this.onDelete.bind(this, data.original.id)}><i className="fa fa-trash"></i></button>
                                    </div>
                                }
                            }
                        ]}
                        manual
                        filterable
                        data={this.state.surveys}
                        defaultPageSize={this.state.itemsPerPage}
                        pages={Math.ceil(this.state.count / this.state.itemsPerPage)}
                        onFetchData={this.debouncedSetTableState}
                        loading={this.state.loading}
                        {...getReactTableLabels()}
                    />
                </div>
            </div>
        </div>;
    }
}
