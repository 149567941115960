import * as React from "react";
import { __ } from "@src/translation";

export class AutoTextarea extends React.Component<{ text: string, buttons?: JSX.Element, autoFocus?: boolean, onTextChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void, onSubmit?: () => void }, {}> {

    constructor(props: any) {

        super(props);
        this.state = {

        }
    }

    public focus()
    {
        (this.refs.textarea as HTMLBaseElement).focus();
    }

    componentDidMount() {
        (this.refs.textarea as HTMLBaseElement).addEventListener('keydown', this.keydownHandler.bind(this));
        this.autosize();
    }

    componentDidUpdate()
    {
        this.autosize();
    }

    componentWillUnmount() {
        (this.refs.textarea as HTMLBaseElement).removeEventListener('keydown', this.autosize.bind(this));
    }

    keydownHandler(e:KeyboardEvent){
        if(e.keyCode===13 && e.ctrlKey && this.props.onSubmit) 
        {
            this.props.onSubmit();
        }
        this.autosize.bind(this);
    }

    autosize() {
        var el = (this.refs.textarea as HTMLBaseElement);
        setTimeout(function () {
            el.style.cssText = 'height:auto; padding:0';
            // for box-sizing other than "content-box" use:
            // el.style.cssText = '-moz-box-sizing:content-box';
            el.style.cssText = 'height:' + el.scrollHeight + 'px';
        }, 0);
    }

    render() {
        return <div className="message-textbox" onClick={()=>(this.refs.textarea as HTMLTextAreaElement).focus()}>
            <textarea
                autoFocus={this.props.autoFocus ? this.props.autoFocus : false }
                onFocus={(e)=> {
                    var val = e.target.value;
                    e.target.value = '';
                    e.target.value = val;
                  }}
                ref="textarea"
                rows={1}
                value={this.props.text || ""}
                onChange={this.props.onTextChange}></textarea>
            {
            this.props.onSubmit
            ?
            <button title={__("Elküldés")} onClick={this.props.onSubmit}><i className="fas fa-paper-plane"></i></button>
            : null }
            {this.props.buttons}
        </div>
    }
}
 