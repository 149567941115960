import * as React from 'react';
import { __ } from '@src/translation';
import { ICON_NOT_FOR_WEAK_KEY } from "@src/component/book/editor/SectionOptionsEditor";


/**
 * Csak erős idegzetűeknek elemek keresése, és layer hozzáadása
 */
export function searchNotForWeakSpansAndAddLayer(element: HTMLElement) {
    const elements = element.querySelectorAll("." + ICON_NOT_FOR_WEAK_KEY);
    
    for(const e of Array.from(elements)) {
        if (e.parentElement) {
            for(const videoElement of Array.from(e.parentElement.getElementsByTagName("video"))) {
                addNotForWeakLayer(videoElement);
            }
            for(const iframeElement of Array.from(e.parentElement.getElementsByTagName("iframe"))) {
                addNotForWeakLayer(iframeElement);
            }
        }
    }
}

/**
 * Csak erős idegzetűeknek layer
 */
export function addNotForWeakLayer(videoOrIframeElement: HTMLElement) {
    if (!videoOrIframeElement.parentElement) return;

    const layerDiv = videoOrIframeElement.ownerDocument!.createElement("div");
    layerDiv.classList.add("not-for-weak-layer");

    videoOrIframeElement.parentElement.appendChild(layerDiv);

    layerDiv.appendChild(videoOrIframeElement.ownerDocument!.createElement("p")).innerText = __("Figyelem! A videót csak erős idegzetűeknek ajánljuk!");

    const closeButton = videoOrIframeElement.ownerDocument!.createElement("button");
    closeButton.classList.add("button", "alert");
    closeButton.textContent = __("Megnézem!");
    closeButton.addEventListener('click', () => layerDiv.style.display = "none");

    layerDiv.appendChild(closeButton);

}

/**
 * Csak erős idegzetűeknek - 
 */
export class NotForWeak extends React.Component<{}, {hide: boolean}> {
    render() {
        return <div className="not-for-weak-layer" style={{display: (this.state && this.state.hide) ? "none" : undefined}}>
            <p>{__("Figyelem! A videót csak erős idegzetűeknek ajánljuk!")}</p>
            <button className="button alert" onClick={()=>this.setState({hide: true})}>{__("Megnézem!")}</button>
        </div>;
    }
}
