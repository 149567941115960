import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapCourseUserWfTransitionLogRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Folyamat
     */
    workflow_id?: number|null;
    /**
     * Átmenet ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre az átmenet létrehozásakor.
     */
    creation_time?: string /*timestamp?*/|null;
    /**
     * Ki végezte el
     *
     * Trigger állítja be automatikusan az átmenet létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number|null;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan az átmenet létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Folyamat átmenet típus
     *
     * A kezdő állapot létrehozásánál nincs töltve (de napló rekord arról is van).
     */
    transition_type_id?: number|null;
    /**
     * Start állomás
     *
     * A legelső napló rekordnál nincs töltve.
     */
    src_station_id?: number|null;
    /**
     * Cél állomás
     */
    dst_station_id?: number|null;
    /**
     * Automatikus
     *
     * Akkor van igazra állítva, ha az átmenet automatikus volt (nem közvetlenül felhasználó kezdeményezte).
     */
    is_auto?: boolean|null;
    /**
     * Indoklás
     */
    justification?: string|null;
    /**
     * Cél al-státusz
     */
    dst_substation_id?: number|null;
    /**
     * Szöveges állapot kiegészítés
     */
    status_text?: string|null;
    /**
     * Állapot név
     *
     * Állapot név
     */
    station_name?: string|null;
    /**
     * Állapot leírás
     *
     * Állapot leírás
     */
    station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Állapot stílus
     */
    station_style?: any|null;
    /**
     * Al-állapot név
     *
     * Al-állapot név
     */
    substation_name?: string|null;
    /**
     * Kurzus-Résztvevő azonosító
     *
     * Kurzus-Résztvevő azonosító
     */
    course_user_id?: number|null;
    /**
     * Kurzus azonosító
     *
     * Kurzus azonosító
     */
    course_id?: number|null;
    /**
     * Résztvevő felhasználó azonosító
     *
     * Résztvevő felhasználó azonosító
     */
    sec_user_id?: number|null;
    /**
     * Résztvevő személy azonosító
     *
     * Résztvevő személy azonosító
     */
    person_id?: number|null;
    /**
     * Résztvevő teljes név
     *
     * Résztvevő teljes név
     */
    fullname?: string|null;
}

/**
 *  kap.view_kap_course_user_wf_transition_log - Képzői aktivitás (résztvevő átmenetek)
 */

export default class ViewKapCourseUserWfTransitionLog extends View<IViewKapCourseUserWfTransitionLogRecord> {
    public static VIEW_INFO_ID = 2020052101;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_course_user_wf_transition_log'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapCourseUserWfTransitionLog>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapCourseUserWfTransitionLogRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapCourseUserWfTransitionLogClassProxy extends ViewClassProxy<IViewKapCourseUserWfTransitionLogRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapCourseUserWfTransitionLogClassProxy = new ViewKapCourseUserWfTransitionLogClassProxy(ViewKapCourseUserWfTransitionLog);
registerViewClassProxy(viewKapCourseUserWfTransitionLogClassProxy);

