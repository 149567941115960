import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ShuffleResult, shuffle, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import * as React from "react";
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import { AnswerTypes } from '@src/component/exercise/models/ExerciseBaseClass';
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type ItemToSetAnswerElement = {
    num_of_questions: number,
    answers: any[],
    odd_one_out: any[],
    set_bg_contain: boolean
}

export type ItemToSetAnswerType = {
    elements: [];
    header: {};
}

export class WMItemToSetConverter extends AExerciseTypeConverter {
    public hasTextAnswer = false;
    public hasImageAnswer = true;
    public hasTextImageAnswer = false;

    static MAX_LIMITED_QUESTION_NUM: number = 30;
    static MIN_SET_NUM: number = 1;
    static MAX_SET_NUM: number = 9;
    static MAX_ELEM_PER_SET: number = 30;
    static MAX_SET_NAME_LENGTH: number = 200;

    public convertToEditorAnswer(exercise: any): ItemToSetAnswerElement | undefined {
        if (exercise && exercise.sets && exercise.sets.length > 0) {
            var setList = [];
            var cur_exercise = exercise;
            var ooo_answers = [];
            if (cur_exercise.options) {
                ooo_answers = cur_exercise.options.slice();
            }

            for (let _i = 0; _i < cur_exercise.sets.length; _i++) {
                var setElements = [];
                if (cur_exercise.options)
                    for (var _j = 0; _j < cur_exercise.solution[_i].length; _j++) {
                        if (!cur_exercise.solution[_i] || cur_exercise.options.length < cur_exercise.solution[_i][_j]) continue;
                        var element = cur_exercise.options[cur_exercise.solution[_i][_j]];
                        if (!element) continue;
                        if (element.type == "image") {// && setList.map((x: any) => x.image).indexOf(element.image) == -1) {
                            setElements.push({ image: element.image, text: element.text, type: AnswerTypes.image });
                        } else if (element.type == "text") {// && setElements.map((x: any) => x.text).indexOf(element.text) == -1) {
                            setElements.push({ text: element.text, type: AnswerTypes.text });
                        }
                        else if (element.type == "sound") {// && setElements.map((x: any) => x.url).indexOf(element.url) == -1) {
                            setElements.push({ url: element.url, text: element.text, type: AnswerTypes.sound });
                        }

                        //deleting all iamge items from odd one outs that has been set as an element of a set
                        if (element.type == "image" && ooo_answers.map((x: any) => x.image).indexOf(element.image) > -1)
                            ooo_answers = ooo_answers.filter((x: any) => x.image != element.image);
                        else if (element.type == "sound" && ooo_answers.map((x: any) => x.url).indexOf(element.url) > -1)
                            ooo_answers = ooo_answers.filter((x: any) => x.url != element.url);
                        else if (ooo_answers.indexOf(element, 0) > -1)
                            ooo_answers = ooo_answers.filter((x: any) => x.text != element.text);
                    }
                setList.push({
                    header: { header_text: cur_exercise.sets[_i], header_bg: cur_exercise.setbackgrounds ? cur_exercise.setbackgrounds[_i] : "" },
                    elements: setElements
                });
            }

            let new_oo_answers = [];
            for (let _i = 0; _i < ooo_answers.length; _i++) {
                if (ooo_answers[_i].type == "image" && new_oo_answers.map((x: any) => x.image).indexOf(ooo_answers[_i].image) == -1) {
                    new_oo_answers.push({ image: ooo_answers[_i].image, text: ooo_answers[_i].text, type: AnswerTypes.image });
                } else if (ooo_answers[_i].type == "text" && new_oo_answers.indexOf(ooo_answers[_i].text) == -1) {
                    new_oo_answers.push({ image: "", text: ooo_answers[_i].text, type: AnswerTypes.text });
                }
                else if (ooo_answers[_i].type == "sound" && new_oo_answers.map((x: any) => x.url).indexOf(ooo_answers[_i].url) == -1) {
                    new_oo_answers.push({ url: ooo_answers[_i].url, text: ooo_answers[_i].text, type: AnswerTypes.sound });
                }
            }

            var answerElement: ItemToSetAnswerElement = {
                num_of_questions: exercise.num_of_questions,
                answers: setList,
                odd_one_out: new_oo_answers ? new_oo_answers : [],
                set_bg_contain: exercise.set_bg_contain ? exercise.set_bg_contain : false
            }
            return answerElement;
        }
        else return undefined;
    }

    public convertToJson(exerciseDetails: any, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: any): any | undefined {
        var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
        if (exerciseDetails) {
            var answerList = [];
            var solution = [];
            var headerList = [];
            let setBgs = [];
            if (exerciseDetails.answers) {
                for (let _i = 0; _i < exerciseDetails.answers.length; _i++) {
                    headerList.push(exerciseDetails.answers[_i].header.header_text);
                    setBgs.push(exerciseDetails.answers[_i].header.header_bg)
                    for (let _j = 0; _j < exerciseDetails.answers[_i].elements.length; _j++) {
                        if (exerciseDetails.answers[_i].elements[_j] &&
                            (exerciseDetails.answers[_i].elements[_j].text != ""
                                || exerciseDetails.answers[_i].elements[_j].url != ""
                                || exerciseDetails.answers[_i].elements[_j].image != ""))
                            answerList.push(exerciseDetails.answers[_i].elements[_j]);
                    }
                }
                // let arrayToShuffle:any = exerciseDetails.odd_one_out?answerList.concat(exerciseDetails.odd_one_out):answerList;
                var shuffleAnswers: ShuffleResult = shuffle(answerList, exerciseDetails.odd_one_out);
                let idx_in_answerList = -1;
                for (let i = 0; i < exerciseDetails.answers.length; i++) {
                    let curSolution: any = [];
                    for (let j = 0; j < exerciseDetails.answers[i].elements.length; j++) {
                        idx_in_answerList++;
                        let currOption = exerciseDetails.answers[i].elements[j];
                        if (currOption != undefined) {
                            for (let k = 0; k < shuffleAnswers.answers.length; k++) {

                                if (!shuffleAnswers.answers[k]) { continue };
                                if (currOption.type == AnswerTypes.text && currOption.text == shuffleAnswers.answers[k].text) {
                                    if (curSolution.indexOf(shuffleAnswers.indexes[idx_in_answerList]) == -1)
                                        curSolution.push(shuffleAnswers.indexes[idx_in_answerList]);
                                }
                                else if (currOption.type == AnswerTypes.image && currOption.image == shuffleAnswers.answers[k].image) {
                                    if (curSolution.indexOf(shuffleAnswers.indexes[idx_in_answerList]) == -1)
                                        curSolution.push(shuffleAnswers.indexes[idx_in_answerList]);
                                }
                                else if (currOption.type == AnswerTypes.sound && currOption.url == shuffleAnswers.answers[k].url) {
                                    if (curSolution.indexOf(shuffleAnswers.indexes[idx_in_answerList]) == -1)
                                        curSolution.push(shuffleAnswers.indexes[idx_in_answerList]);
                                }
                            }
                        }
                    }
                    solution.push(curSolution);
                }

                let newOptionsList = [];
                for (let _i = 0; _i < shuffleAnswers.answers.length; _i++) {
                    if (!shuffleAnswers.answers[_i]) { continue };
                    if (shuffleAnswers.answers[_i].type == AnswerTypes.image) {
                        newOptionsList.push({ type: "image", image: shuffleAnswers.answers[_i].image, text: shuffleAnswers.answers[_i].text });
                    } else if (shuffleAnswers.answers[_i].type == AnswerTypes.text) {
                        newOptionsList.push({ type: "text", text: shuffleAnswers.answers[_i].text, image: "" });
                    }
                    else if (shuffleAnswers.answers[_i].type == AnswerTypes.sound) {
                        newOptionsList.push({ type: "sound", url: shuffleAnswers.answers[_i].url, text: shuffleAnswers.answers[_i].text });
                    }
                }

                cur_exercise = {
                    ...cur_exercise,
                    num_of_questions: exerciseDetails.num_of_questions,
                    ordered: false,
                    sets: headerList,
                    setbackgrounds: setBgs,
                    options: newOptionsList,
                    solution: solution,
                    set_bg_contain: exerciseDetails.set_bg_contain ? exerciseDetails.set_bg_contain : false
                };
                return cur_exercise;
            }
            return cur_exercise;
        }
    }

    public convertOldNKPToJson(data: any): any | undefined {
        // todo: finish this!!!
        let newOptionsList = [];
        let sets = [];
        let solution = [];
        let isImage = false;

        if (data.Answers) {
            for (var _i = 0; _i < data.Answers.length; _i++) {
                //ans_list.push(data.Answers[_i].AnswerText);

                if (data.Answers[_i]["$id"]) {// a set is found
                    let cur_solList = [];
                    let cur_ans = ExerciseBaseTypes.convertOldNKPToAnswerElement(data.Answers[_i], data);

                    newOptionsList.push(cur_ans);// put the first answer in
                    if (cur_ans.type == "image") isImage = true;

                    if (data.Answers[_i].CorrectSet) {
                        sets.push(data.Answers[_i].CorrectSet.SetName);
                        cur_solList.push(newOptionsList.length - 1);
                        // go through the remaining elements of the set
                        for (let j = 0; j < data.Answers[_i].CorrectSet.CorrectAnswers.length; j++) {
                            let newAns = data.Answers[_i].CorrectSet.CorrectAnswers[j];

                            if (newAns["$id"]) {
                                cur_ans = ExerciseBaseTypes.convertOldNKPToAnswerElement(newAns, data);
                                if (cur_ans.type == "image") isImage = true;
                                cur_solList.push(newOptionsList.length);
                                newOptionsList.push(cur_ans);
                            }
                        }
                    }
                    solution.push(cur_solList);
                }

            }
        }

        let filteredSol = [];
        // array.filter() nem szűrte ki az üres tömböket a tömbön belül ?!
        for (let i = 0; i < solution.length; i++) {
            if (solution[i].length != 0) filteredSol.push(solution[i]);
        }

        let newExercise = {
            title: data.Title,
            description: data.QuestionText,
            backgroundStyle: { is_custom_background: false, backgroundImage: "", backgroundColor: "" },
            illustration: (ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image != "" && ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image != undefined) ?
                ExerciseBaseTypes.convertOldNKPToAnswerElement(data, data).image : "",
            level: data.QuestionDifficulty == 1 ? 1 : data.QuestionDifficulty == 2 ? 3 : 5,
            demo_path: "",
            imageanswers: isImage,
            imagebasepath: "/", //await EditorServer.getExerciseNewImageBasePath()
            comment: data.Description,
            ordered: false,
            sets: sets,
            setbackgrounds: [],
            options: newOptionsList,
            solution: filteredSol
        };
        return newExercise;
    }
    public validate(editorAnswer: ItemToSetAnswerElement, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData, validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        let errorMsg = "";
        let OFIErrors: string[] = [];
        if (!AExerciseTypeConverter.isOfiEditor && editorAnswer && editorAnswer.num_of_questions > WMItemToSetConverter.MAX_LIMITED_QUESTION_NUM) {
            errorMsg = __("A maximum megjelenített elemszám legfeljebb  {max} lehet!", {max: WMItemToSetConverter.MAX_LIMITED_QUESTION_NUM}); 
            if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
        }
        let rowsValid = this.makeValidBeforeSetState(null, editorAnswer);
        if (!rowsValid.valid) return rowsValid;
        let totalElements = 0;
        for (let i = 0; i < editorAnswer.answers.length; i++) {
            if (!editorAnswer.answers[i].elements) continue;
            /* if(editorAnswer.answers[i].elements.length > WMItemToSetConverter.MAX_ELEM_PER_SET)
             return { valid: false, message: "Egy halmazba maximum "+WMItemToSetConverter.MAX_ELEM_PER_SET+" db elem tartozhat!" }; */
            if (editorAnswer.answers[i].header.header_text.length < 1 || editorAnswer.answers[i].header.header_text.length > WMItemToSetConverter.MAX_SET_NAME_LENGTH) {
                errorMsg = __("A halmaz nevének karakterszáma minimum 1, maximum {max} lehet!", { max: WMItemToSetConverter.MAX_SET_NAME_LENGTH });
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                validationMap!.set("answers[" + i + "].header.header_text", errorMsg);
                if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
            }
            for (let j = 0; j < editorAnswer.answers[i].elements.length; j++) {
                totalElements++;
                let cur_elem = editorAnswer.answers[i].elements[j];
                if (cur_elem && cur_elem.text.length > WMItemToSetConverter.MAX_ALT_TEXT_LENGTH) {
                    errorMsg = __("A válaszelemek karakterszáma maximum {n} lehet!", { n: WMItemToSetConverter.MAX_ALT_TEXT_LENGTH });
                    if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                    validationMap!.set("answers[" + j + "].text", errorMsg);
                    if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
                }
            }
        }
        if (editorAnswer.odd_one_out && totalElements < editorAnswer.odd_one_out.length)
            return { valid: false, message: __("Nem lehet több kakukktojás elem, mint normál válaszelem!") }

        if (AExerciseTypeConverter.isOfiEditor) {
            return { valid: true, message: OFIErrors.join(' , ') }
        }
        return { valid: true }
    }


    public makeValidBeforeSetState(oldState: ItemToSetAnswerElement | null, newState: ItemToSetAnswerElement): ValidationResponse {
        if (AExerciseTypeConverter.isOfiEditor) return { valid: true };

        if (newState.answers && newState.answers.length > WMItemToSetConverter.MAX_SET_NUM) {
            newState.answers.splice(WMItemToSetConverter.MAX_SET_NUM, newState.answers.length - WMItemToSetConverter.MAX_SET_NUM);
            return { valid: false, message: __("A halmazok száma minimum {min}, maximum {max} lehet!", { min: WMItemToSetConverter.MIN_SET_NUM, max: WMItemToSetConverter.MAX_SET_NUM }) }
        }

        if (newState) {
            for (let i = 0; i < newState.answers.length; i++) {
                if (newState.answers[i].elements && newState.answers[i].elements.length > WMItemToSetConverter.MAX_ELEM_PER_SET) {
                    newState.answers[i].elements.splice(WMItemToSetConverter.MAX_ELEM_PER_SET, newState.answers[i].elements.length - WMItemToSetConverter.MAX_ELEM_PER_SET);
                    return { valid: false, message: __("Egy halmazba maximum {max} elem tartozhat!", { max: WMItemToSetConverter.MAX_ELEM_PER_SET }) }
                }
            }
        }
        return { valid: true }
    }

    public isSolutionAvailable(exerciseJson: ExerciseBaseTypes.ExerciseBaseClass): boolean {
        if (!exerciseJson.solution) { return false; }
        let hasSolution = false;
        for (let i = 0; i < exerciseJson.solution.length; i++) {
            if (exerciseJson.solution[i].length > 0) {
                hasSolution = true;
                break;
            }
        }
        return hasSolution;
    }

    render() {
        let curr_ex: ItemToSetAnswerElement = this.state.exercise as ItemToSetAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                <div className="row">
                    <div className="large-12 columns">
                        <label>{__("Megjelenített kérdések száma (0 = összes)")}
                            <input type="number" min="0" max="100" name="num_of_questions"
                                value={curr_ex.num_of_questions || 0}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                        </label>
                    </div>
                </div>
                <Panel header={__("Halmazok")} headingLevel={5}>
                    <div className="row">
                        <div className="large-12 columns">
                            <label ><input type="checkbox" name="set_bg_contain" checked={curr_ex.set_bg_contain || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Háttérkép belső illesztése")}</label>
                        </div>
                    </div>
                    <div>
                        {curr_ex.answers ? curr_ex.answers.map((curr_set: any, i) => {

                            let actions: IAccordionAction[];
                            actions = [
                                {
                                    title: __("Törlés"),
                                    type: AccordionActionType.Trash,
                                    action: this.removeElement.bind(this, "answers", i)
                                },
                                {
                                    title: __("Fel"),
                                    type: AccordionActionType.Up,
                                    action: this.moveUp.bind(this, "answers", i)
                                },
                                {
                                    title: __("Le"),
                                    type: AccordionActionType.Down,
                                    action: this.moveDown.bind(this, "answers", i)
                                }
                            ];

                            return (
                                <Accordion key={"answers_" + i}>
                                    <ActionAccordionItem defaultClosed={false} key={"answers_" + i} actions={actions} title={__("{n}. halmaz", { n: i + 1 })}>
                                        <Panel>
                                            <label>{__("Halmaz fejléce")} <span className="exe-editor-validation-msg">{this.props.validationMessages.get("answers[" + i + "].header.header_text")}</span>
                                                <input type="text" name={"answers#" + i + ".header.header_text"} data-parent-index={i} value={curr_set.header.header_text}
                                                    onBlur={this.onBlurEvent.bind(this)}
                                                    onChange={this.handleInputChange.bind(this)} />
                                            </label>
                                            <label className="exe-image-select-label">{__("Halmaz háttere")}
                                                {<ExerciseFileSelect
                                                    imagebasepath={this.props.imagebasepath}
                                                    value={curr_set.header.header_bg || ""}
                                                    onChange={this.handleImageChange.bind(this, "answers#" + i + ".header.header_bg")}
                                                    getFolderId={this.getFolderId.bind(this)}
                                                    fileType={ExerciseFileTypes.Image}
                                                />}
                                            </label>                                            
                                                {
                                                    curr_set.elements ? curr_set.elements.map((curr_element: any, j: any) => {
                                                        return (
                                                            <Panel key={"answers#" + i + ".elements#" + j}>
                                                                <legend>
                                                                    <label className="exe-editor-fieldlabel-1"> {__("{n}. Elem", { n: j + 1 })}</label>
                                                                    <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "answers#" + String(i) + ".elements", j)}><i className="fa fa-trash"></i></button>
                                                                    <span className="exe-editor-validation-msg">{this.props.validationMessages.get("answers[" + i + "].elements[" + j + "].type")}</span>
                                                                </legend>

                                                                <div className="row">
                                                                    <label className="columns small-12 medium-4">{__("Elem típusa")}
                                                                        <select value={curr_element.type} name={"answers#" + i + ".elements#" + j + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)} >
                                                                            <option value={AnswerTypes.text}>{__("Szöveg")}</option>
                                                                            <option value={AnswerTypes.image}>{__("Kép")}</option>
                                                                            <option value={AnswerTypes.sound}>{__("Hang")}</option>
                                                                        </select></label>

                                                                    <label className="columns small-12 medium-8">{__("Leírás")} <span className="exe-editor-validation-msg">{this.props.validationMessages.get("answers[" + j + "].text")}</span>
                                                                        <input type="text" name={"answers#" + i + ".elements#" + j + ".text"} data-parent-index={i} data-index={j} value={curr_element.text}
                                                                            onBlur={this.onBlurEvent.bind(this)}
                                                                            onChange={this.handleInputChange.bind(this)} />
                                                                    </label>
                                                                </div>

                                                                <div className="row">
                                                                    {curr_element.type != AnswerTypes.text ?
                                                                        <label className="exe-image-select-label columns large-12">{curr_element.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                                                            {<ExerciseFileSelect
                                                                                imagebasepath={this.props.imagebasepath}
                                                                                value={(curr_element.type == AnswerTypes.image ? curr_element.image : curr_element.url) || ""}
                                                                                onChange={this.handleImageChange.bind(this, "answers#" + i + ".elements#" + j + (curr_element.type == AnswerTypes.image ? ".image" : ".url"))}
                                                                                getFolderId={this.getFolderId.bind(this)}
                                                                                fileType={curr_element.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                                            />}
                                                                        </label> : ""}
                                                                </div>
                                                            </Panel>);
                                                    })
                                                        : ""
                                                }
                                                <div className="row">
                                                    <button className="button small" name={"set#" + i + ".btn-add-element"} onClick={this.onAddNewElem.bind(this, i)}><i className="fa fa-plus"></i> {__("Új elem")}</button>
                                                    <label className="exe-editor-label-description columns">{__("Egy halmazba maximum {max} elem tartozhat!", { max: WMItemToSetConverter.MAX_ELEM_PER_SET })}</label>
                                                </div>
                                        </Panel>
                                    </ActionAccordionItem>
                                </Accordion>

                            )
                        }) : ""}
                        <div className="row">
                            <button className="button small" onClick={this.onAddNewSet.bind(this)}><i className="fa fa-plus"></i> {__("Új halmaz")}</button>
                            <label className="exe-editor-label-description columns">{__("A halmazok száma minimum {min}, maximum {max} lehet!", { min: WMItemToSetConverter.MIN_SET_NUM, max: WMItemToSetConverter.MAX_SET_NUM })}</label>
                        </div>
                    </div>
                </Panel>
                <Panel header={__("Kakukktojás elemek")} headingLevel={5}>
                    <div>
                        {
                            curr_ex.odd_one_out ?
                                curr_ex.odd_one_out.map((curr_odd_one_out: any, _j: any) => {
                                    return (
                                        <Panel key={__("Elem") + _j}>
                                            <legend>
                                                <label className="exe-editor-fieldlabel-1">{__("{n}. Elem", { n: (_j + 1) })} </label>
                                                <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "odd_one_out", _j)}><i className="fa fa-trash"></i></button>
                                                <span className="exe-editor-validation-msg">{this.props.validationMessages.get("odd_one_out[" + _j + "].type")}</span>
                                            </legend>
                                            <div className="row">
                                                <label className="small-12 medium-4 columns">{__("Elem típusa")}
                                                    <select value={curr_odd_one_out.type} name={"odd_one_out#" + _j + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                        <option value={AnswerTypes.text}>{__("Szöveg")}</option>
                                                        <option value={AnswerTypes.image}>{__("Kép")}</option>
                                                        <option value={AnswerTypes.sound}>{__("Hang")}</option>
                                                    </select></label>
                                                <div className="small-12 medium-8 columns">
                                                    <label>{__("Leírás")}
                                                        <input type="text" name={"odd_one_out#" + _j + ".text"} data-parent-index={_j} data-index={_j} value={curr_odd_one_out.text}
                                                            onBlur={this.onBlurEvent.bind(this)}
                                                            onChange={this.handleInputChange.bind(this)} />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {curr_odd_one_out.type != AnswerTypes.text ?
                                                    <div className="small-12 columns">
                                                        <label className="exe-image-select-label">{curr_odd_one_out.type == AnswerTypes.image ? __("Kép") : __("Hang")}
                                                            {<ExerciseFileSelect
                                                                imagebasepath={this.props.imagebasepath}
                                                                value={(curr_odd_one_out.type == AnswerTypes.image ? curr_odd_one_out.image : curr_odd_one_out.url) || ""}
                                                                onChange={this.handleImageChange.bind(this, "odd_one_out#" + _j + (curr_odd_one_out.type == AnswerTypes.image ? ".image" : ".url"))}
                                                                getFolderId={this.getFolderId.bind(this)}
                                                                fileType={curr_odd_one_out.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                            />}
                                                        </label>
                                                    </div> : ""}

                                            </div>
                                        </Panel>
                                    );
                                })
                                : ""
                        }
                        <button className="button small" onClick={this.onAddNewOddOneOut.bind(this)}><i className="fa fa-plus"></i>{__("Új kakukktojás")}</button>
                    </div>
                </Panel>
            </Panel>
        )
    }

    onAddNewSet() {
        let temp_exercise = this.state.exercise as ItemToSetAnswerElement;
        if (!temp_exercise.answers)
            temp_exercise.answers = [];
        temp_exercise.answers.push(this.getNewSet());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    onAddNewElem(index: number) {
        let temp_exercise = this.state.exercise as ItemToSetAnswerElement;
        if (!temp_exercise.answers[index])
            temp_exercise.answers[index].elements = [];
        temp_exercise.answers[index].elements.push(this.getNewCheckableElem());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    onAddNewOddOneOut() {
        let temp_exercise = this.state.exercise as ItemToSetAnswerElement;
        if (!temp_exercise.odd_one_out)
            temp_exercise.odd_one_out = [];
        temp_exercise.odd_one_out.push(this.getNewCheckableElem());
        this.setState({ exercise: temp_exercise });
    }

    getNewSet(): ItemToSetAnswerType {
        let newElement: ItemToSetAnswerType = {
            elements: [],
            header: {},
        };
        return newElement;
    }

    getNewCheckableElem(): ExerciseBaseTypes.AnswerElement {
        let newElement: ExerciseBaseTypes.AnswerElement = {
            type: AnswerTypes.text,
            image: "",
            text: "",
            url: "",
        };
        return newElement;
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];

        let statisticsResponses: StatisticsResponse[] = [];
        statisticsResponses.push({ name: __("limit - halmazok száma: {n}", { n: WMItemToSetConverter.MAX_SET_NUM }), count: undefined });
        statisticsResponses.push({ name: __("limit - elemek száma: {n}", { n: WMItemToSetConverter.MAX_ELEM_PER_SET }), count: undefined });
        statisticsResponses.push({ name: __("limit - limitált elemszám: {n}", { n: WMItemToSetConverter.MAX_LIMITED_QUESTION_NUM }), count: undefined });

        let setStat, elementsStat, answerStatTotal, oddOneOutStat, limitedNumberStat: StatisticsResponse;
        setStat = { name: __("Halmazok száma"), count: new Map() }
        elementsStat = { name: __("A halmazban lévő elemek száma"), count: new Map() }
        answerStatTotal = { name: __("Elemek száma összesen"), count: new Map() }
        oddOneOutStat = { name: __("A kakukktojás elemek száma"), count: new Map() }
        limitedNumberStat = { name: __("Megjelenített kérdések száma"), count: new Map() }
        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const currentExc = exerciseList[i];
            let set_count = setStat.count.has(currentExc.answers.length) ? setStat.count.get(currentExc.answers.length) : 0;
            setStat.count.set(currentExc.answers.length, set_count! + 1);
            let ln_number = 0;
            if (currentExc.num_of_questions && currentExc.num_of_questions != undefined) {
                ln_number = currentExc.num_of_questions;
            }
            let lim_num_count = limitedNumberStat.count!.has(ln_number) ? limitedNumberStat.count!.get(ln_number) : 0;
            limitedNumberStat.count!.set(ln_number, lim_num_count! + 1);

            let counter = 0;
            for (let j = 0; j < currentExc.answers.length; j++) {
                const curr_answer = currentExc.answers[j];
                if (curr_answer.elements) {
                    counter += curr_answer.elements.length;
                    let answer_count = elementsStat.count.has(curr_answer.elements.length) ? elementsStat.count.get(curr_answer.elements.length) : 0;
                    elementsStat.count.set(curr_answer.elements.length, answer_count! + 1);
                }
            }
            for (let k = 0; k < currentExc.odd_one_out.length; k++) {
                const curr_odd_one_out = currentExc.odd_one_out;
                if (curr_odd_one_out) {
                    let ooo_count = oddOneOutStat.count.has(curr_odd_one_out.length) ? oddOneOutStat.count.get(curr_odd_one_out.length) : 0;
                    oddOneOutStat.count.set(curr_odd_one_out.length, ooo_count! + 1);
                }
            }
            let total_answer_count = answerStatTotal.count.has(counter) ? answerStatTotal.count.get(counter) : 0;
            answerStatTotal.count.set(counter, total_answer_count! + 1);
        }
        statisticsResponses.push(setStat, elementsStat, answerStatTotal, oddOneOutStat, limitedNumberStat);
        return statisticsResponses;
    }
}