import * as React from 'react';
import { Section } from '@src/server/EditorServer';
import LessonEditor, { FileToUrlPath } from '@src/component/book/editor/LessonEditor';

import SectionHTMLEditor from './SectionHTMLEditor';
import SectionTextEditor from './SectionTextEditor';
import SectionVideoEditor from './SectionVideoEditor';
import SectionIframeEditor from './SectionIframeEditor';
import SectionExerciseEditor from './SectionExerciseEditor';
import SectionExerciseSeriesEditor from './SectionExerciseSeriesEditor';
import { TemplateVariable } from '@src/template/SectionTemplate';
import { Link } from 'react-router-dom';
import { PATH_SECTION_HISTORY } from '@src/Routes';
import { SectionAudio } from './AudioEditor';
import { SectionComments } from './SectionComments';
import * as EditorServer from '@src/server/EditorServer';
import { __ } from '@src/translation';
import { ViewMode } from '../viewer/BookPage';
import SectionFileEditor from './SectionFileEditor';

//must match section names in the database DO NOT TRANSLATE!!!
const SPECIAL_SECTION_NAMES = [
  /* __(" DO NOT TRANSLATE THIS!!! */ "Videó",
  /* __(" DO NOT TRANSLATE THIS!!! */ "Beágyazott tartalom",
  /* __(" DO NOT TRANSLATE THIS!!! */ "Okosfeladat",
  /* __(" DO NOT TRANSLATE THIS!!! */ "Okosfeladatsor",
  /* __(" DO NOT TRANSLATE THIS!!! */ "Fájl"
];

type SectionEditorProps = {
    lessonEditor: LessonEditor;
    templates: EditorServer.BlockTemplate[];
    section: Section;
    rootFolderId: number;
    displayModeId: number;

    fileToUrlPath: FileToUrlPath;
    urlRoot: string;

    gradeId?: number;
    subjectId?: number;
    isSNIDisplay:boolean;
}
  
type SectionEditorState = {
    expanded: boolean;
    fullScreen: boolean;
}
  
export default class SectionEditor extends React.Component<SectionEditorProps, SectionEditorState> {
  
    constructor(props: SectionEditorProps) {
      super(props);
      
      this.state = {
        expanded: true,
        fullScreen: false        
      }
    }

    render() {
      const section = this.props.section;
      const lessonEditor = this.props.lessonEditor;
  
      const template = this.props.templates.find((t) => t.id == this.props.section.block_template_id);
  
      if (!template) {
  
        return <div style={{color: "red", fontSize: "large"}}>{__("Ismeretlen sablon!")} {this.props.section.block_template_id}</div>;
  
      }

      const hasVideo = template.template.variables.some((variable: TemplateVariable) => variable.type == "video");
  
      return <div className={"callout primary" + (this.state.fullScreen ? " fullscreen" : "")}>
        <div className="row expanded">
          
          <div className="shrink column">
            <button className="button small middle" 
                style={{cursor: "pointer"}} 
                onClick={()=>this.setState({expanded: !this.state.expanded})} >
              <i className={"fa " + (this.state.expanded ? "fa-minus-square" : "fa-plus-square")} />
            </button>
            <button className="button small middle" title={this.state.fullScreen ? __("Előző méret") : __("Teljes képernyő")} onClick={()=>this.setState({fullScreen: !this.state.fullScreen})}>
                {
                  this.state.fullScreen 
                  ? <i className="far fa-window-minimize"></i>
                  : <i className="far fa-window-maximize"></i>
                }
            </button>
          </div>
  
          <div className="column">
          
            <select onChange={lessonEditor.onTemplateChanged.bind(lessonEditor, section.id)} value={this.props.section.block_template_id}>
              <optgroup label={__("Szöveges tartalom")}>
                {
                  this.props.templates.sort((a, b) => a.name.localeCompare(b.name)).map((template) => {
                    if (SPECIAL_SECTION_NAMES.indexOf(template.name) !== -1) return;

                    return <option value={template.id} key={template.id}>
                        {template.name}
                      </option>
                  })
                }
              </optgroup>
              <optgroup label={__("Egyéb tartalom")}>
                {
                  this.props.templates.sort((a, b) => a.name.localeCompare(b.name)).map((template) => {
                    if (SPECIAL_SECTION_NAMES.indexOf(template.name) === -1) return;

                    return <option value={template.id} key={template.id}>
                        {template.name}
                      </option>
                  })
                }
              </optgroup>

            </select>
          </div>
  
          <div className="shrink column">
            <label className="middle">{__("Szekció elnevezése")}:</label>
          </div>
          <div className="column">
            <input type="text" value={section.name} onChange={lessonEditor.onSectionNameChange.bind(lessonEditor, section.id)} placeholder={__("A könyv menüjében jelenik meg")} />
          </div>

          <div className="shrink column">
            <button className="button small secondary middle" onClick={lessonEditor.onSectionUp.bind(lessonEditor, section.id)}>
              <i className="fa fa-arrow-up" />
            </button>

            <button className="button small secondary middle" onClick={lessonEditor.onSectionDown.bind(lessonEditor, section.id)}>
              <i className="fa fa-arrow-down" />
            </button>
          
            <Link className="button primary small middle" 
              title={__("Módosítási napló")} 
              to={`${PATH_SECTION_HISTORY}/${section.id}`}
            >
              <i className="fa fa-history" />
            </Link>
          
            <button className="button alert small middle" title={__("Töröl")} onClick={lessonEditor.onRemoveSection.bind(lessonEditor, section.id)}>
              <i className="fa fa-trash" />
            </button>           
          </div>               
  
          <div className="small-12 column" style={{display: this.state.expanded ? undefined : "none"}}>
            {
              template.template.variables.map((variable: TemplateVariable) => {
                if (variable.type == "text") {
  
                  return <div key={variable.id + " " + section.id} className="small-12 cell">
                    <label>{variable.title ? variable.title + ":" : " "}</label>
                    <SectionTextEditor lessonEditor={lessonEditor} section={section} templateVariableId={variable.id} templateName={template.name} />
                    </div>
  
                } else if (variable.type == "html") {
  
                  return <div key={variable.id} className="small-12 cell">
                    <label>{variable.title ? variable.title + ":" : " "}</label>
                    <SectionHTMLEditor 
                      lessonEditor={lessonEditor} 
                      section={section} 
                      templateVariableId={variable.id} 
                      fileToUrlPath={this.props.fileToUrlPath} 
                      urlRoot={this.props.urlRoot} 
                      defaultHeightPx={hasVideo ? 100 : 350}
                      isSNIDisplay={this.props.isSNIDisplay}
                      />
                    </div>
  
                } else if (variable.type == "iframe") {
  
                  return <div key={variable.id} className="small-12 cell">
                    <label>{variable.title ? variable.title + ":" : " "}</label>
                    <SectionIframeEditor lessonEditor={lessonEditor} section={section} templateVariableId={variable.id} rootFolderId={this.props.rootFolderId} fileToUrlPath={this.props.fileToUrlPath} urlRoot={this.props.urlRoot}/>
                    </div>
  
                } else if (variable.type == "video") {
  
                  return <div key={variable.id} className="small-12 cell">
                    <label>{variable.title ? variable.title + ":" : " "}</label>
                    <SectionVideoEditor lessonEditor={lessonEditor} section={section} templateVariableId={variable.id} rootFolderId={this.props.rootFolderId} fileToUrlPath={this.props.fileToUrlPath} urlRoot={this.props.urlRoot}/>
                    </div>
  
                } else if (variable.type == "file") {
  
                  return <div key={variable.id} className="small-12 cell">
                    <label>{variable.title ? variable.title + ":" : " "}</label>
                    <SectionFileEditor lessonEditor={lessonEditor} section={section} templateVariableId={variable.id} rootFolderId={this.props.rootFolderId} fileToUrlPath={this.props.fileToUrlPath} urlRoot={this.props.urlRoot}/>
                    </div>
  
                } else if (variable.type == "exercise") {
  
                  return <div key={variable.id} className="small-12 cell">
                    <label>{variable.title ? variable.title + ":" : " "}</label>
                    <SectionExerciseEditor lessonEditor={lessonEditor} section={section} templateVariableId={variable.id} gradeId={this.props.gradeId} subjectId={this.props.subjectId} />
                    </div>
  
                } else if (variable.type == "exercise-series") {
  
                  return <div key={variable.id} className="small-12 cell">
                    <label>{variable.title ? variable.title + ":" : " "}</label>
                    <SectionExerciseSeriesEditor lessonEditor={lessonEditor} section={section} templateVariableId={variable.id} gradeId={this.props.gradeId} subjectId={this.props.subjectId} />
                    </div>
  
                }
                if (variable.type == "link") {
  
                  return <div key={variable.id} className="small-12 cell">
                    <label>{variable.title ? variable.title + ":" : " "}</label>
                    <SectionTextEditor lessonEditor={lessonEditor} section={section} templateVariableId={variable.id} templateName={template.name} />
                    </div>
  
                } else {
                  return __("Ismeretlen típus:") + " " + variable.title;
                }
              })
            }
          </div>
          <div className="small-12 column" style={{display: this.state.expanded ? undefined : "none"}}>
            <div style={{marginTop: "0.5em", marginBottom: "0.5em"}}>
              <span>{__("Szekció azonosító:")} </span><span style={{fontWeight: "bold"}}>{section.id > 1 ? "#section-" + section.id : __("-- a szekció még nincs elmentve --")}</span>
            </div>
          </div>

          {
            this.props.displayModeId === ViewMode.SIMPLE &&
            <div className="small-12 column" style={{display: this.state.expanded ? undefined : "none"}}>
              <label>
                <input type="checkbox" checked={this.props.section.is_teachers_guide} onChange={e => this.props.lessonEditor.setIsTeachersGuide(this.props.section.id, e.target.checked)} />
                &nbsp; {__("Tanári segédanyagok között jelenjen meg")}
              </label>
            </div>  
          }

          <div className="small-12 column" style={{display: this.state.expanded ? undefined : "none"}}>
            <SectionAudio lessonEditor={lessonEditor} section={section} rootFolderId={this.props.rootFolderId} fileToUrlPath={this.props.fileToUrlPath} urlRoot={this.props.urlRoot} />
          </div>  

          <div className="small-12 column" style={{display: this.state.expanded ? undefined : "none"}}>
            <SectionComments sectionIds={[section.id]}  isActive={true}/>
          </div>

        </div>
  
      </div>;
    }
}
    