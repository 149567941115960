import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IDcFieldValueRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Táblázat infó
     *
     * NOT NULL -> meta.table_info ON DELETE noaction
     *
     *
     * Ez mondja meg, hogy a folyamat melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number;
    /**
     * Rekord azonosító
     *
     * Ez mondja meg, hogy a folyamat melyik rekordhoz tartozik.
     */
    record_id?: number;
    /**
     * Dublin Core mező típus
     *
     * NOT NULL -> meta.dc_field_type ON DELETE noaction
     *
     */
    dc_field_type_id?: number;
    /**
     * Érték
     */
    value?: string;
}

/**
 *  meta.dc_field_value - Dublin Core mező érték
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class DcFieldValueCrud extends Crud<IDcFieldValueRecord> {
    public static TABLE_INFO_ID = 1431156070;
    public static getSchemaNameForClass() { return 'meta'; }
    public static getTableNameForClass() { return 'dc_field_value'; }

    public static load: (id: number, aServer ?: Server) => Promise<DcFieldValueCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IDcFieldValueRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IDcFieldValueRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IDcFieldValueRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IDcFieldValueRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IDcFieldValueRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class DcFieldValueCrudClassProxy extends CrudClassProxy<IDcFieldValueRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const dcFieldValueCrudClassProxy = new DcFieldValueCrudClassProxy(DcFieldValueCrud);
registerCrudClassProxy(dcFieldValueCrudClassProxy);
