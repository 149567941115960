import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewCourseTrainerInvitationAnswerCommentRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * KSZR képző felkérésre adott válasz
     */
    course_trainer_invitation_answer_id?: number;
    /**
     * Üzenet
     */
    message?: string;
    /**
     * Beküldő bejelentkezési név
     *
     * Beküldő bejelentkezési név
     */
    login_name?: string|null;
    /**
     * Beküldő e-mail
     *
     * Beküldő e-mail
     */
    email?: string|null;
    /**
     * Beküldő személy azonosítója
     *
     * Beküldő személy azonosítója
     */
    person_id?: number|null;
    /**
     * Beküldő teljes neve
     *
     * Beküldő teljes neve
     */
    fullname?: string|null;
}

/**
 *  kap.view_course_trainer_invitation_answer_comment - KSZR képző felkérésre adott válaszhoz fűzött megjegyzés
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewCourseTrainerInvitationAnswerComment extends View<IViewCourseTrainerInvitationAnswerCommentRecord> {
    public static VIEW_INFO_ID = 2020011701;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_course_trainer_invitation_answer_comment'; }
    public static getTableNameForClass(): string|null { return 'course_trainer_invitation_answer_comment'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewCourseTrainerInvitationAnswerComment>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewCourseTrainerInvitationAnswerCommentRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewCourseTrainerInvitationAnswerCommentClassProxy extends ViewClassProxy<IViewCourseTrainerInvitationAnswerCommentRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewCourseTrainerInvitationAnswerCommentClassProxy = new ViewCourseTrainerInvitationAnswerCommentClassProxy(ViewCourseTrainerInvitationAnswerComment);
registerViewClassProxy(viewCourseTrainerInvitationAnswerCommentClassProxy);

