import * as React from 'react';

export class AttendanceAboutUsPage extends React.Component {

    render() {
        return <div className="row" style={{minHeight: "70vh"}}>
            <div className="column small-12">
                <br />
                <h4>Szakmai Támogatórendszer bemutatása</h4>
                <p>
                    A Szakmai Támogatórendszer (SZTR) a Komplex Alapprogram bevezetésének és sikeres iskolai megvalósulásának – ezen belül az alprogrami tartalmak és a DFHT tanítási-tanulási stratégia osztálytermi gyakorlattá válásának – érdekében kidolgozott komplex rendszer. A szakmai támogatás további célja a KAP pedagógus továbbképzéseken tanult új ismeretek, módszerek magas szintű gyakorlati alkalmazásában való segítségnyújtás a kidolgozott támogatási tevékenységeken keresztül.
                </p>
                <p>
                    A szakmai támogatás célcsoportjainak meghatározásában fontos szerepet kaptak az iskolai innováció kulcsszereplői egyéni és intézményi szinten egyaránt, így a rendszer egyéni célcsoportjai: a) intézményvezetők b) pedagógusok; intézményi szinten: a pedagógusok közössége, a tantestület.
                </p>
                <p>
                    A rendszer komplex, hiszen a kor követelményeinek megfelelő elektronikus kapcsolattartás mellet épít a már jól bevált személyes találkozásokon alapuló segítő munkaformákra is. A Komplex Alapprogram intézményi implementációjának segítését, nyomon követését a következő módokon és területeken támogatjuk:
                </p>

                <ul>
                    <li>
                        Szakmai anyagokhoz való hozzáférést biztosítunk a Komplex Alapprogram Tudástár elektronikus felületén keresztül. A Tudástár a program intézményi bevezetését támogató kiadványokat, szakmai anyagokat, óravázlatokat és segédleteket tartalmaz. A Tudástár tartalma folyamatosan bővül a projekt megvalósításának ideje alatt.
                        <br />
                        <img width={500} src="/img/jtr/jtr1.png" />
                    </li>
                    <li>
                        Elektronikus úton történő szakmai támogatást biztosításunk az e-SZTR virtuális felületén keresztül. A programban résztvevők az e-SZTR online felületén küldhetik el a Komplex Alapprogrammal kapcsolatos szakmai jellegű kérdéseiket szakmai támogatóink számára. A kérdésekre adott válasz pár napon belül szakszerű és ellenőrzött formában rendelkezésre áll a felületen.
                    </li>
                    <li>
                        Jelenléti támogatás során személyes találkozásokon alapuló segítségnyújtást biztosítunk a Komplex Alapprogramot alkalmazó intézmények vezetőinek, tantestületeinek, pedagógusainak. Több mint száz szakmai támogató valósítja meg az egyéni és csoportos formában megvalósuló jelenléti támogatásokat, akik a program kidolgozói, fejlesztői, gyakorlott pedagógusok. A jelenléti támogatások gördülékeny és hatékony megszervezésének érdekében kialakítottuk a Jelenléti Támogatórendszer (JTR) felületét, ahol az összes esemény elérhető és igényelhető. A jelenléti támogatás formáinak bővebb leírása az Esemény típusai menüpontban olvasható.
                    </li>
                    <li>
                        Programunk eredményes működéséhez kialakítottunk egy keretet, amely a programban résztvevő intézmények együttműködésének alapját képezi. Az együttműködés platformját a KAPOCS-iskolahálózat jelenti, amelynek célja, hogy összefogja a Komplex Alapprogramot megvalósító intézményeket, és szakmai fórumot (szakami napok, tematikus nyílt napok, alprogrami bemutató foglalkozások) biztosítson a program fenntartása érdekében. A szakmai fórumok biztosításával szeretnénk elősegíteni a tudásmegosztást, kapcsolatfelvételt az KAPOCS-partnerintézmények között. A KAPOCS-iskolahálózathoz a Komplex Alapprogramot bevezető intézmények csatlakozhatnak. Amennyiben megfelelnek a regisztrációs feltételeknek, a KAPOCS-iskolahálózat partnerintézményei lesznek. A KAPOCS-iskolahálózat partnerintézményei minden év május 1 – július 15. között pályázhatnak az Élménysuli címre. Az Élménysuli címet azon partnerintézmények nyerik el, akik a program elemeit mintaértékűen valósítják meg.
                        <br />
                        <img width={500} src="/img/jtr/jtr2.png" />
                    </li>
                </ul>

            </div>
        </div>
    }

}
