import * as React from 'react';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import { BookStructure, getBookStructureForId } from '@src/server/PublicServer';
import GradeCrud from '@src/framework/crud/doc/GradeCrud';
import { match } from 'react-router';
import { Link } from 'react-router-dom';
import { PATH_CONTENT_STORE, PATH_BOOK_VIEW_EDITING } from '@src/Routes';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import { __ } from '@src/translation';

type OFIBookHeadersPageProps = {
    match: match<{ bookId?: string }>
}

type OFIBookHeadersPageState = {
    books: IBookRecord[];
    bookStructures: { [key: number]: BookStructure };
}

export class OFIBookHeadersPage extends React.Component<OFIBookHeadersPageProps, OFIBookHeadersPageState> {

    constructor(props: OFIBookHeadersPageProps) {
        super(props);

        this.state = {
            books: [],
            bookStructures: {}
        }
    }

    async componentDidMount() {
        const grades = await GradeCrud.list({ filter: { is_low_grade: false } });
        const highGradeIds = grades.map(g => g.id);

        var books = await BookCrud.list({ filter: { is_active: true, library_id: LIBRARY_OFI_OFFICIAL_ID, is_sni: false }, order_by: "name" });

        books = books.filter(b => highGradeIds.includes(b.grade_id));

        books = books.sort((a, b) => a.name!.localeCompare(b.name!, undefined, { numeric: true, sensitivity: 'base' }));

        this.setState({ books });

        const bookStructures: any = {};

        for (const book of books) {
            const structure = await getBookStructureForId(book.id!);
            bookStructures[book.id!] = structure;
            this.setState({
                bookStructures
            });
        }
    }

    render() {
        const bookId = this.props.match.params.bookId;
        return <div className="row expanded">

            {
                this.props.match.params.bookId
                    ?
                    this.getBook(bookId ? parseInt(bookId) : 0)
                    :
                    this.getTable()
            }

        </div>;
    }

    private getTable() {
        var lessons = 0;
        var headers = 0;
        var pngHeaders = 0;
        var jpgHeaders = 0;

        for (const struct of Object.values(this.state.bookStructures)) {
            lessons += struct.lessons.length;
            headers += this.headerCount(struct);
            pngHeaders += this.pngHeaderCount(struct);
            jpgHeaders += this.jpgHeaderCount(struct);

        }

        return <div>
            <h3>
                {__("Összes lecke száma")}: {lessons} db,
                {__("fejlécek összesen (duplikáció nélkül, azaz ha van jpg és png is, nem számít kettőnek)")}: <span style={{ color: (headers == 0) ? undefined : "green" }}>{headers}</span> db,
                {__("hiányzó")}: <span style={{ color: (lessons - headers == 0) ? undefined : "red" }}>{lessons - headers}</span> db,
                {__("png fejlécek")}: <span style={{ color: (pngHeaders == 0) ? undefined : "green" }}>{pngHeaders}</span> db,
                {__("jpg fejlécek")}: <span style={{ color: (jpgHeaders == 0) ? undefined : "green" }}>{jpgHeaders}</span> db

                </h3>
            <table>
                <thead>
                    <tr>
                        <th> {__("Tankönyv")}</th>
                        <th> {__("Leckék száma")}</th>
                        <th> {__("Kész fejlécek")}</th>
                        <th> {__("PNG fejlécek")}</th>
                        <th> {__("JPG fejlécek")}</th>
                        <th> {__("Hiányzó fejlécek")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.books.map(book => {
                            const struct = this.state.bookStructures[book.id!];
                            const lessonCount = struct ? struct.lessons.length : 0;
                            const headerCount = this.headerCount(struct);
                            const pngHeaderCount = this.pngHeaderCount(struct);
                            const jpgHeaderCount = this.jpgHeaderCount(struct);

                            const link = PATH_CONTENT_STORE + "/fejlecek/" + book.id;

                            const missingheaders = (book.id == 16329523204 || book.id == 16329523203 || book.id == 16329523202 || book.id == 16329523201) ? 0 : lessonCount - headerCount;

                            return <tr key={book.id}>
                                <td>
                                    <Link to={PATH_BOOK_VIEW_EDITING + "/" + book.id + "/"}>{book.name}</Link>
                                </td>
                                <td>
                                    <Link to={link}>{lessonCount} db</Link>
                                </td>
                                <td>
                                    <Link to={link} style={{ color: (headerCount == 0) ? undefined : "green" }}>{headerCount} db</Link>
                                </td>
                                <td>
                                    <Link to={link} style={{ color: (pngHeaderCount == 0) ? undefined : "green" }}>{pngHeaderCount} db</Link>
                                </td>
                                <td>
                                    <Link to={link} style={{ color: (jpgHeaderCount == 0) ? undefined : "green" }}>{jpgHeaderCount} db</Link>
                                </td>
                                <td>
                                    <Link to={link} style={{ color: (missingheaders == 0) ? undefined : "red" }}>{missingheaders} db</Link>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    }

    private getBook(bookId: number) {
        const struct = this.state.bookStructures[bookId];
        if (!struct) return null;

        return <div>
            <h3>{struct.name}</h3>
            <table>
                <thead>
                    <tr>
                        <th> {__("Fejezet")}</th>
                        <th> {__("Lecke")}</th>
                        <th> {__("URL")}</th>
                        <th> {__("Kép")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        struct.lessons.map(l => {
                            const fileNameJPG = l.lesson_uri_segment + ".jpg";
                            const fileNamePNG = l.lesson_uri_segment + ".png";
                            const existsJPG = struct.headerFileNames && struct.headerFileNames.includes(fileNameJPG);
                            const existPNG = struct.headerFileNames && struct.headerFileNames.includes(fileNamePNG);

                            const link = PATH_BOOK_VIEW_EDITING + "/" + struct.id + "/" + l.lesson_uri_segment + "/";

                            let image;
                            if (existPNG) {
                                image = <img src={PATH_BOOK_VIEW_EDITING + "/" + struct.id + "/img/HEADER/" + fileNamePNG} />
                            } else if (existsJPG) {
                                image = <img src={PATH_BOOK_VIEW_EDITING + "/" + struct.id + "/img/HEADER/" + fileNameJPG} />
                            }
                            else {
                                image = <span style={{ color: "red" }}> {__("Hiányzó kép")}: img/HEADER/{fileNameJPG} VAGY img/HEADER/{fileNamePNG}</span>;
                            }


                            return <tr>
                                <td>{l.chapter_name}</td>
                                <td><Link to={link}>{l.lesson_name}</Link></td>
                                <td><Link to={link}>{l.lesson_uri_segment}</Link></td>
                                <td>
                                    {image}
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    }

    private headerCount(struct?: BookStructure) {
        if (!struct || !struct.headerFileNames) return 0;

        var ret = 0;

        for (const lesson of struct.lessons) {
            const filenameJPG = lesson.lesson_uri_segment + ".jpg";
            const filenamePNG = lesson.lesson_uri_segment + ".png";
            if (struct.headerFileNames && (struct.headerFileNames.includes(filenameJPG) || struct.headerFileNames.includes(filenamePNG))) {
                ret++;
            }
        }

        return ret;
    }

    private jpgHeaderCount(struct?: BookStructure) {
        if (!struct || !struct.headerFileNames) return 0;

        var ret = 0;

        for (const lesson of struct.lessons) {
            const filenameJPG = lesson.lesson_uri_segment + ".jpg";
            if (struct.headerFileNames && struct.headerFileNames.includes(filenameJPG)) {
                ret++;
            }
        }

        return ret;
    }

    private pngHeaderCount(struct?: BookStructure) {
        if (!struct || !struct.headerFileNames) return 0;

        var ret = 0;

        for (const lesson of struct.lessons) {
            const filenamePNG = lesson.lesson_uri_segment + ".png";
            if (struct.headerFileNames && struct.headerFileNames.includes(filenamePNG)) {
                ret++;
            }
        }

        return ret;
    }

}
