
import * as React from 'react';
import { __ } from '@src/translation';
import { elasticIndexers, IElasticIndexerInfo, search, IElasticIndexerTaskInfo } from '@src/server/PublicServer';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import { app } from '@src/index';
import ThreeStateRadioGroup from '@src/framework/forms/three_state_radio_group';

function multiSearch(text: string, searchWords: string[]) {
    for (var i = 0; i < searchWords.length; i++) {
        let word = searchWords[i].trim();
        if (word.startsWith("-")) {
            word = word.substr(1, word.length);
            if (text.indexOf(word) != -1) {
                return false;
            }
        } else {
            if (text.indexOf(word) == -1) {
                return false;
            }
        }
    }
    return true;

}

function extractSearchWords(searchExpr: string): string[] {
    const searchWords: string[] = [];

    searchExpr.split(" ").forEach((value: string) => {
        value = value.trim().toLocaleLowerCase();
        if (value) {
            if (!value.startsWith("-") || value.length > 1)
                searchWords.push(value);
        }
    });
    return searchWords;
}

export interface IElasticIndexersPageProps { }

@observer
export default class ElasticIndexersPage extends React.PureComponent<IElasticIndexersPageProps>{
    @observable private indexInfos: IElasticIndexerInfo[] | null = null;
    @observable private searchText: string = "";
    @observable private statusFilter: boolean | null = null;


    private asyncReload = async () => {
        try {
            let indexInfos = await elasticIndexers({ operation: "list" });
            runInAction(() => {
                this.indexInfos = indexInfos;
            })
            app.showSuccess(__("Sikeres lekérdezés"), __("Indexer infók újratöltve"));
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    componentDidMount() {
        this.asyncReload();
    }

    render() {
        let items = null;
        if (this.indexInfos) {
            items = [];
            let searchWords = extractSearchWords(this.searchText);

            this.indexInfos!.forEach((indexInfo: IElasticIndexerInfo) => {
                let accept: boolean = true;
                if (searchWords.length) {
                    let subject = indexInfo.baseConfigName + indexInfo.systemCheckName + indexInfo.systemCheckDescription +
                        indexInfo.queueTableName + indexInfo.class;
                    if (indexInfo.deleteQueueTableName) {
                        subject += indexInfo.deleteQueueTableName;
                    }
                    if (indexInfo.deleteKeyField) {
                        subject += indexInfo.deleteKeyField;
                    }
                    if (indexInfo.other && indexInfo.other.instance) {
                        subject += indexInfo.other.instance;
                    }
                    if (indexInfo.tasks) {
                        indexInfo.tasks.forEach((task: IElasticIndexerTaskInfo) => {
                            subject += task.indexName;
                            subject += task.indexType;
                            subject += task.schemaName;
                            subject += task.tableName;
                            subject += task.idPrefix;
                        })
                    }
                    accept = multiSearch(subject.toLocaleLowerCase(), searchWords);
                }

                const instance = indexInfo.other.instance;
                let localInstance = "none";
                let idx = instance.indexOf("/tomcat");
                if (idx > 0) {
                    localInstance = instance.substr(idx + 1, instance.length);
                }

                let statusLabel;
                let isEnabled: boolean;
                if (indexInfo.other.enabled) {
                    statusLabel = <label className="label success">Enabled on all instances</label>
                    isEnabled = true;
                } else {
                    if (instance.indexOf("/tomcat") == -1) {
                        statusLabel = <label className="label secondary">Disabled</label>;
                        isEnabled = false;
                    } else {
                        statusLabel = <label className="label primary">Enabled on {instance}</label>;
                        isEnabled = true;
                    }
                }
                if (this.statusFilter !== null) {
                    if (isEnabled !== this.statusFilter) {
                        accept = false;
                    }
                }
                if (accept) {
                    items.push(
                        <div className="row expanded">
                            <div className="column small-9">
                                <h2>{indexInfo.baseConfigName} {statusLabel}</h2>
                            </div>
                            <div className="column small-2">
                                <select value={localInstance}>
                                    <option value="tomcat01">tomcat01</option>
                                    <option value="tomcat02">tomcat02</option>
                                    <option value="none">none</option>
                                </select>
                            </div>
                            <div className="column small-1">
                                <button className="button primary">
                                    <i className="fa fa-save" />
                                </button>
                            </div>
                            <div className="column small-12">
                                <ul>
                                    <li><b>systemCheckName:</b> {indexInfo.systemCheckName}</li>
                                    <li><b>systemCheckDescription:</b> {indexInfo.systemCheckDescription}</li>
                                    <li><b>serviceUserId:</b> {indexInfo.serviceUserId}</li>
                                    <li><b>queueTableName:</b> {indexInfo.queueTableName}</li>
                                    <li><b>deleteQueueTableName:</b> {indexInfo.deleteQueueTableName}</li>
                                    <li><b>deleteKeyField:</b> {indexInfo.deleteKeyField}</li>
                                    <li><b>class:</b> {indexInfo.class}</li>
                                    <li><b>tasks:</b> {JSON.stringify(indexInfo.tasks)}</li>
                                    <li><b>timings:</b> {JSON.stringify(indexInfo.timings)}</li>
                                    <li><b>other:</b> {JSON.stringify(indexInfo.other)}</li>
                                </ul>
                            </div>
                        </div>
                    );
                }
            });
        }

        return <div className="column sm-12">
            <b>Keresés szavakra (tegyen kötőjelet a szó elé ha ki akarja zárni):</b>
            <input type="text" value={this.searchText}
                onChange={(e: any) => {
                    runInAction(() => {
                        this.searchText = e.target.value;
                    })
                }}
            />
            <ThreeStateRadioGroup
                name="statusFilter"
                value={this.statusFilter}
                yesvalue={true}
                novalue={false}
                thirdvalue={null}
                yeslabel={__("Enabled")}
                nolabel={__("Disabled")}
                thirdlabel={__("All")}
                onValueChange={(newValue: boolean | null) =>
                    runInAction(() => {
                        this.statusFilter = newValue;
                    })
                }

            />
            <button className="button primary"
                onClick={() => { this.asyncReload() }}>
                <i className="fa fa-refresh" />
                Re-fetch from server
                </button>
            <br />{items}</div>
    }
}