import * as React from 'react'

import './ImageSettings.css'

import TinyMCE from 'react-tinymce-input';
import { __ } from '@src/translation';

const FILL_CLASS = "w-100";

const FILL_H_CLASS = "h-100";

type ImageSettingsProps = {
  // Elérési út. Ez lesz behelyezve a tinyMCE-be
  filePath: string;

  // Előnézeti elérési út. Ez lesz megjelenítve. (Ha az elérési út relatív, akkor ezzel lehet megjeleníteni)
  previewFilePath?: string;

  // TinyMCE editor, ide lesz behelyezve
  tinyMCE: any;

  // Szerkesztésre van-e megnyitva. Szerkesztéskor kevesebb funkció elérhető, és más módon van módosítva a HTML kód
  editing?: boolean;

  // Bezáráskor hívódik meg
  onClose: () => void;

  // Megjelenítendő filenév, hogy a szerkesztők könnyebben megtalálják, honnan volt a kép
  showFileName?: string;

  isSimpleView?: boolean;
}

type ImageSettingsState = {
  align: "inline" | "left" | "right";
  floatRatio: "quarter" | "third" | "half";
  fill: boolean;
  fillH: boolean;
  wrapFloatInDiv: boolean;
  exampleText: string;
  caption: string;
  alt: string;
  lightbox: boolean;
  dataTitle: string;
  figureClass: string;
}

export default class ImageSettings extends React.Component<ImageSettingsProps, ImageSettingsState> {

  constructor(props: any) {
    super(props);

    var text = "";

    // User selected an image in editor, and then selected an image in image list

    if (this.props.editing) {

      var caption = "";
      var alt = this.props.tinyMCE.selection.getNode().getAttribute("alt") || "";
      var dataTitle = "";

      const parent: HTMLElement = this.props.tinyMCE.selection.getNode().parentElement;
      if (parent && parent.tagName == "A") {
        dataTitle = parent.getAttribute("data-title") || "";
      }

      var figureClass = "";

      var align: "inline" | "left" | "right" = "inline";
      var floatRatio: "quarter" | "third" | "half" = "quarter";
      var fill = false;
      var fillH = false;

      const figure = $(this.props.tinyMCE.selection.getNode()).closest("figure");
      if (figure.length == 1) {
        const figCaption = figure.find("figcaption");
        caption = figCaption.html();

        figureClass = figure.attr("class") || "";

        const floatClass = figure[0].classList;
        if (floatClass.contains("float-right")) {
          align = "right";
        } else if (floatClass.contains("float-left")) {
          align = "left";
        }

        if (floatClass.contains("quarter")) {
          floatRatio = "quarter";
        } else if (floatClass.contains("third")) {
          floatRatio = "third";
        } else if (floatClass.contains("half")) {
          floatRatio = "half";
        }
        if (floatClass.contains(FILL_CLASS)) {
          fill = true;
        }
        if (floatClass.contains(FILL_H_CLASS)) {
          fillH = true;
        }
      }

      /*
              const p = $(this.props.tinyMCE.selection.getNode()).closest("p");
              if (p.length > 0) {
                text = p.text();
                this.props.tinyMCE.selection.select(p[0]);
              }
      */
      /*
              const div = $(this.props.tinyMCE.selection.getNode()).closest("div.row");
              if (div.length > 0) {
                text = div.text();
                this.props.tinyMCE.selection.select(div[0]);
              }
      */

      this.state = {
        align: align,
        floatRatio: floatRatio,
        exampleText: text,
        caption: caption,
        alt: alt,
        dataTitle: dataTitle,
        lightbox: true,
        wrapFloatInDiv: true,
        figureClass: figureClass,
        fill: fill,
        fillH: fillH
      }

    } else {

      if (this.props.tinyMCE.selection
        && this.props.tinyMCE.selection.getNode().nodeName == 'IMG') {
        this.props.tinyMCE.insertContent(`<img src="${this.props.filePath}"/>`);
        this.props.onClose();
        return;
      }

      var wrapFloatInDiv = true;

      if (this.props.tinyMCE.selection.getNode().nodeName == "LI") {
        wrapFloatInDiv = false;
      }

      const selectedElements: HTMLElement[] = this.props.tinyMCE.selection.getSelectedBlocks();

      for (const selectedElement of selectedElements) {
        text += selectedElement.outerHTML;
      }

      this.state = {
        align: "inline",
        floatRatio: "quarter",
        exampleText: text,
        caption: "",
        alt: "",
        dataTitle: "",
        lightbox: true,
        wrapFloatInDiv: wrapFloatInDiv,
        figureClass: "",
        fill: false,
        fillH: false
      }
    }
  }

  onSave() {
    if (this.props.editing) {
      this.onEdit();
    } else {
      this.onInsert();
    }
  }

  private onEdit() {

    // alt tag update
    const imgElement: HTMLElement = this.props.tinyMCE.selection.getNode();
    var lightBoxAElement = imgElement.parentElement;
    if (lightBoxAElement && lightBoxAElement.nodeName != "A") lightBoxAElement = null;

    if (this.state.alt) {
      imgElement.setAttribute("alt", this.state.alt);
    } else {
      imgElement.removeAttribute("alt");
    }

    if (lightBoxAElement) {
      if (this.state.dataTitle) {
        lightBoxAElement.setAttribute("data-title", this.state.dataTitle);
      } else {
        lightBoxAElement.removeAttribute("data-title");
      }
      if(!this.state.lightbox)
      {
  
        var docFrag = document.createDocumentFragment();
        while (lightBoxAElement.firstChild) {
            var child = lightBoxAElement.removeChild(lightBoxAElement.firstChild);
            docFrag.appendChild(child);
        }
        lightBoxAElement.parentNode!.replaceChild(docFrag,lightBoxAElement);
      }
    }

    var figure = $(imgElement).closest("figure");

    if (figure.length == 0) {

      if (imgElement.parentElement && imgElement.parentElement.nodeName == "A") {
        $(imgElement.parentElement).wrap("<figure>");
      } else {
        $(imgElement).wrap("<figure>");
      }

    }
    figure = $(imgElement).closest("figure");

    const fillClass = this.state.fill ? FILL_CLASS : "";
    const fillHClass = this.state.fillH ? FILL_H_CLASS : "";

    // float update
    if (this.state.align == "right") {
      figure[0].setAttribute("class", "float-right " + this.state.floatRatio + " " + fillClass + " " + fillHClass);
    } else if (this.state.align == "left") {
      figure[0].setAttribute("class", "float-left " + this.state.floatRatio + " " + fillClass + " " + fillHClass);
    } else {
      if (this.state.fill) {
        figure[0].classList.add(FILL_CLASS);
      } else {
        figure[0].classList.remove(FILL_CLASS);
      }
      if (this.state.fillH) {
        figure[0].classList.add(FILL_H_CLASS);
      }
      else {
        figure[0].classList.remove(FILL_H_CLASS);
      }
    }

    // caption update
    var figCaption = figure[0].getElementsByTagName("figcaption");
    if (figCaption.length == 0) {
      figure.append("<figcaption></figcaption>");
    }
    figCaption = figure[0].getElementsByTagName("figcaption");

    if (this.state.caption) {
      figCaption[0].innerHTML = this.state.caption;
    } else {
      figCaption[0].remove();
    }

    // without this, react will change back from the old value to the tinymce content
    this.props.tinyMCE.fire("change");

    this.props.onClose();
  }

  private onInsert() {
    const contentToInsert = this.renderOutputHTML(false);

    if (this.state.align == "inline" || !this.state.wrapFloatInDiv) {

      let selected = (this.props.tinyMCE.selection.getNode() as HTMLElement);

      if (selected.nodeName == "FIGURE") {
        (selected as HTMLElement).insertAdjacentHTML('afterend', contentToInsert);
      }
      else if (selected.parentElement && (selected.parentElement as HTMLElement)!.nodeName == "FIGURE") {
        (selected.parentElement as HTMLElement).insertAdjacentHTML('afterend', contentToInsert);
      }
      else if (selected.nodeName != "BODY") {
        this.props.tinyMCE.insertContent(contentToInsert);
      }
      else {
        alert(__("A kép nem szúrható be! Kérem válasszon ki bekezdést!"));
      }

    } else {

      const selectedElements: HTMLElement[] = this.props.tinyMCE.selection.getSelectedBlocks();
      $(selectedElements.pop() as any).replaceWith(contentToInsert);

      $(selectedElements).remove();
    }

    // without this, react will change back from the old value to the tinymce content
    this.props.tinyMCE.fire("change");
    this.props.onClose();
  }

  onCaptionChanged(event: string) {
    const oldCaption = this.state.caption;

    if (this.state.alt == oldCaption && event.indexOf("<") === -1) {
      this.setState({ caption: event, alt: event });
    } else {
      this.setState({ caption: event });
    }

    if (this.state.dataTitle == oldCaption && event.indexOf("<") === -1) {
      this.setState({ caption: event, dataTitle: event });
    } else {
      this.setState({ caption: event });
    }
  }

  onAltChanged(event: any) {
    this.setState({ alt: event.target.value });
  }
  onDataTitleChanged(event: any) {
    this.setState({ dataTitle: event.target.value });
  }

  onLightboxChanged(event: any) {
    this.setState({ lightbox: event.target.checked });
  }

  renderOutputHTML(inPopup: boolean) {
    var text = this.state.exampleText;
    if (inPopup && !text) {
      text = __("Szöveg...");
    }

    let filePath = this.props.filePath;
    if (inPopup && this.props.previewFilePath) {
      filePath = this.props.previewFilePath;
    }

    const fillClass = this.state.fill ? FILL_CLASS : "";
    const fillHClass = this.state.fillH ? FILL_H_CLASS : "";

    var img;
    if (this.state.alt) {
      img = `<img src="${filePath}" alt="${this.state.alt.replace('"', '&quot;')}" />`;
    } else {
      img = `<img src="${filePath}" />`;
    }

    if (this.state.lightbox) {
      img = `<a ${inPopup ? "" : 'href="' + this.props.filePath + '"'} class="keptar-link" data-lightbox="keptar" data-title="${this.state.dataTitle.replace('"', '&quot;')}">${img}</a>`;
    }

    if (this.state.align == "inline") {
      return `<figure class="${this.state.figureClass} ${fillClass} ${fillHClass}">
            ${img}
            ${this.state.caption ? '<figcaption>' + this.state.caption + '</figcaption>' : ''}
          </figure>
        `;

    } else if (this.state.align == "left") {
      if (this.state.wrapFloatInDiv) {
        return `<div class="row column">
                  <figure class="float-left ${this.state.floatRatio} ${fillClass} ${fillHClass}">
                    ${img}
                    ${this.state.caption ? '<figcaption>' + this.state.caption + '</figcaption>' : ''}
                  </figure>
                  ${text}
                </div>
                &nbsp;`;
      } else {
        return `<figure class="float-left ${this.state.floatRatio} ${fillClass} ${fillHClass}">
                ${img}
                ${this.state.caption ? '<figcaption>' + this.state.caption + '</figcaption>' : ''}
              </figure>`;
      }

    } else if (this.state.align == "right") {
      if (this.state.wrapFloatInDiv) {
        return `<div class="row column">
                <figure class="float-right ${this.state.floatRatio} ${fillClass} ${fillHClass}">
                  ${img}
                  ${this.state.caption ? '<figcaption>' + this.state.caption + '</figcaption>' : ''}
                </figure>
                ${text}
              </div>
              &nbsp;`;
      } else {
        return `<figure class="float-right ${this.state.floatRatio} ${fillClass} ${fillHClass}">
                  ${img}
                  ${this.state.caption ? '<figcaption>' + this.state.caption + '</figcaption>' : ''}
                </figure>`;
      }

    } else {
      return __("Ismeretlen rendezés");
    }
  }

  componentDidMount() {
    this.setState({ fill: this.props.isSimpleView ? this.props.isSimpleView : false, fillH: this.props.isSimpleView ? this.props.isSimpleView : false });
  }

  render() {
    if (!this.state) return "";

    const outputHTML = this.renderOutputHTML(true);

    return <div>
      {
        this.props.showFileName
          ?
          <div className="row expanded">
            <div className="small-12 column">
              <span>{__("Fájlnév")}: {this.props.showFileName}</span>
            </div>
          </div>
          :
          ""
      }

      <br />

      { /* Edit módban egyelőre nem lehet változtatni a szerkezeten */
        !this.props.editing
          ?

          <div className="row expanded align-center align-middle">
            <div className="shrink column">
              <button className={"button " + (this.state.align == "inline" ? "" : "secondary")} onClick={() => this.setState({ align: "inline" })}>
                <i className="fa fa-align-justify" /> {__("Szöveggel egy sorba")}
              </button>
            </div>

            <div className="shrink column">
              <button className={"button " + (this.state.align == "left" ? "" : "secondary")} onClick={() => this.setState({ align: "left" })}>
                <i className="fa fa-align-left" /> {__("Körbefuttatás jobbról")}
              </button>
            </div>

            <div className="shrink column">
              <button className={"button " + (this.state.align == "right" ? "" : "secondary")} onClick={() => this.setState({ align: "right" })}>
                <i className="fa fa-align-right" /> {__("Körbefuttatás balról")}
              </button>
            </div>
          </div>
          :
          ""
      }

      <br />

      {
        this.state.align != "inline"
          ?
          <div className="row column expanded">
            {__("Körbefuttatás kép arány")}:

                  <label style={{ display: "inline-block", margin: "1em" }}>
              <input type="radio" value="half" checked={this.state.floatRatio == "half"} onChange={() => this.setState({ floatRatio: "half" })} />
              {__("Fél")}
            </label>
            <label style={{ display: "inline-block", margin: "1em" }}>
              <input type="radio" value="third" checked={this.state.floatRatio == "third"} onChange={() => this.setState({ floatRatio: "third" })} />
              {__("Harmad")}
            </label>
            <label style={{ display: "inline-block", margin: "1em" }}>
              <input type="radio" value="quarter" checked={this.state.floatRatio == "quarter"} onChange={() => this.setState({ floatRatio: "quarter" })} />
              {__("Negyed")}
            </label>

          </div>
          :
          ""
      }

      <div className="row expanded">
        <div className="small-6 column">
          <label>{__("Képaláírás")}:</label>
          <TinyMCE

            tinymceConfig={{
              plugins: [
                ''
              ],
              toolbar: 'bold italic',
              menubar: false,
              statusbar: false,

              entity_encoding: "raw",

              pollinterval: 250,

              forced_root_block: '',
            }}

            ignoreUpdatesWhenFocused /* Ha ez nincs, akkor levágja a szóközöket a <p> végén, amikor gépelsz! */
            pollInterval={100} /* Default 1000-nél ha gyorsan kikattintasz, eltűnik az utolsó 1 másodperc változtatása */

            value={this.state.caption || ""}
            onChange={this.onCaptionChanged.bind(this)}
          />

          <label style={{ marginTop: "1em" }}>
            <input type="checkbox" checked={this.state.fill} onChange={e => this.setState({ fill: e.target.checked })} />
            {__("A kép töltse ki a rendelkezésre álló teret vízszintesen")}
          </label>

          <label style={{ marginTop: "1em" }}>
            <input type="checkbox" checked={this.state.fillH} onChange={e => this.setState({ fillH: e.target.checked })} />
            {__("A kép töltse ki a rendelkezésre álló teret függőlegesen")}
          </label>

        </div>
        <div className="small-6 column">
          <label>{__("Szöveges alternatíva (alt attribútum)")}:
                  <input type="text" value={this.state.alt} onChange={this.onAltChanged.bind(this)} />
          </label>

          <label >
            <input type="checkbox" checked={this.state.lightbox} onChange={this.onLightboxChanged.bind(this)} />
            {__("Képnagyítás (lightbox)")}
          </label>

          <label>{__("Képnagyítás képaláírása (lightbox data-title)")} :
                  <input type="text" value={this.state.dataTitle} onChange={this.onDataTitleChanged.bind(this)} />
          </label>

        </div>
      </div>
      <br />
      <div className="row expanded align-right">
        <div className="shrink column">
          <button className="button success" onClick={this.onSave.bind(this)}><i className="fa fa-save" /> {this.props.editing ? __("Mentés") : __("Beillesztés")}</button>
        </div>
      </div>
      <br />
      <div className="row expanded imagesettings__preview">
        <div className="medium-12 column" dangerouslySetInnerHTML={{ __html: outputHTML }} />
      </div>

    </div>
  }
}
