export class WMItemToSetServer {


    static evaluateOnServer(exercise: any, correctSolution: any, userSolution: any): Evaluated { //check for 2 same elements selected in 1 set
        let success = true;
        let successPercent = 0;
        let total_points = 0;
        let total_wrong = 0;

        for (let i = 0; i < correctSolution.length; i++) {
            let placedWrong = false;
            //string array of correct answers
            let currCorrectTextSet = [];
            for (let j = 0; j < correctSolution[i].length; j++) {
                let currCorrectElem = exercise.exercise.options[correctSolution[i][j]];
                if (currCorrectElem) {
                    if (currCorrectElem.type == "text" && currCorrectElem.text != "") {
                        currCorrectTextSet.push(currCorrectElem.text);
                        total_points++;
                    }
                    else if (currCorrectElem.type == "image" && currCorrectElem.image != "") {
                        currCorrectTextSet.push(currCorrectElem.image);
                        total_points++;
                    }
                    else {
                        if (currCorrectElem.type == "sound" && currCorrectElem.url != "") {
                            currCorrectTextSet.push(currCorrectElem.url);
                            total_points++;
                        }
                    }
                }
            }
            let curSetLength = currCorrectTextSet.length;
            for (let k = 0; k < userSolution[i].length; k++) {
                let currUserElem = exercise.exercise.options[userSolution[i][k]];

                if (currUserElem.type == "text") {
                    if (currCorrectTextSet.indexOf(currUserElem.text) == -1) {
                        total_wrong++;
                        placedWrong = true;
                    }
                    if (currCorrectTextSet.indexOf(currUserElem.text) != -1) //remove checked elem from array
                        currCorrectTextSet.splice(currCorrectTextSet.indexOf(currUserElem.text), 1);
                }
                else if (currUserElem.type == "image") {
                    if (currCorrectTextSet.indexOf(currUserElem.image) == -1) {
                        total_wrong++;
                        placedWrong = true;
                    }
                    if (currCorrectTextSet.indexOf(currUserElem.image) != -1) //remove checked elem from array
                        currCorrectTextSet.splice(currCorrectTextSet.indexOf(currUserElem.image), 1);
                }
                else {
                    if (currCorrectTextSet.indexOf(currUserElem.url) == -1) {
                        total_wrong++;
                        placedWrong = true;
                    }
                    if (currCorrectTextSet.indexOf(currUserElem.url) != -1) //remove checked elem from array
                        currCorrectTextSet.splice(currCorrectTextSet.indexOf(currUserElem.url), 1);
                }
            }
            if (!placedWrong && userSolution[i].length > curSetLength) {
                total_wrong = total_wrong + (userSolution[i].length - curSetLength) / 2;
            }
            if (!placedWrong && userSolution[i].length < curSetLength) {
                total_wrong = total_wrong + (curSetLength - userSolution[i].length) / 2;
            }
        }
        successPercent = Number(((total_points - total_wrong) / total_points).toFixed(2));

        if (successPercent < 0) {
            successPercent = 0;
        }

        if (successPercent != 1) {
            success = false;
        }

        return { "success": success, "solution": correctSolution, "successPercent": successPercent };
    }

    static filterExerciseOnServer(exercise: any, solution: any): any {
        if (!exercise.num_of_questions || exercise.num_of_questions < 1)
            return exercise;

        let tempAnswers:any[];
        const minTrueElements = 1;
        let putTrueElement = 0;

        tempAnswers = JSON.parse(JSON.stringify(exercise.options));
        for (let ti = 0; ti < tempAnswers.length; ti++) {
            tempAnswers[ti].idx = ti;   // original index in the list         
        }

        let elementsToRemove = exercise.options.length - exercise.num_of_questions;
        let maxRun = 100;
        let ii = 0;
        for (let i = 0; i < elementsToRemove && ii<maxRun; i++) {
            
            let j = Math.floor(Math.random() * (tempAnswers.length));
            let ind = tempAnswers[j].idx;
            // making sure that there are at least one true nswer element included, 
            // not just odd ones.
            if (putTrueElement < minTrueElements) { 
                for (let s_i = 0; s_i < solution.length; s_i++) {
                    if (solution[s_i].indexOf(ind) > -1) {
                        putTrueElement++;
                        break;
                    }
                }
                if (putTrueElement < minTrueElements) {
                    i--;
                    ii++; // making sure we don't run for infinity
                    continue;
                }
            }

            exercise.options[ind] = { type: "", image: "", text: "" };
            (tempAnswers as any[]).splice(j, 1);
        }

        return exercise;
    }
}