import * as React from 'react';

import { IViewPersonRecord, ViewPersonClassProxy, viewPersonClassProxy } from '@src/framework/view/sys/ViewPerson';
import ListView from '@framework/forms/listview';

import '@framework/crud/sys/PersonCrud'; // Ezt ne távolítsd el mert akkor a hülye webpack kioptimizálja!
import { __ } from '@src/translation';
 
class PersonInner extends ListView<IViewPersonRecord> {

    public getViewClassProxy() : ViewPersonClassProxy { return viewPersonClassProxy; }

    protected getQuickFilterFieldNames(): string[] {
        return ['lastname', 'firstname'];
    }

 
}

export default function PersonListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <PersonInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "id", maxWidth: 100},
            {accessor: "lastname"},
            {accessor: "firstname"},
            {accessor: "status_name"},
            /*
            {accessor: "creation_time"},
            {accessor: "creator"},
            {accessor: "modification_time"},
            {accessor: "modifier"},
            */
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
