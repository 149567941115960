import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ICountryTranslationRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Ország (aminek a nevét fordítjuk)
     *
     * NOT NULL -> sys.country ON DELETE cascade
     *
     */
    country_id?: number;
    /**
     * Nyelv (amilyen nyelvre fordítjuk)
     *
     * NOT NULL -> sys.lang ON DELETE cascade
     *
     */
    lang_id?: number;
    /**
     * Név
     */
    name?: string;
}

/**
 *  sys.country_translation - Ország név fordítás
 *
 *  Az összes ország neve lefordítva az összes támogatott nyelvre.
 */

export default class CountryTranslationCrud extends Crud<ICountryTranslationRecord> {
    public static TABLE_INFO_ID = 1819570841;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'country_translation'; }

    public static load: (id: number, aServer ?: Server) => Promise<CountryTranslationCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ICountryTranslationRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ICountryTranslationRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ICountryTranslationRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ICountryTranslationRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ICountryTranslationRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class CountryTranslationCrudClassProxy extends CrudClassProxy<ICountryTranslationRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const countryTranslationCrudClassProxy = new CountryTranslationCrudClassProxy(CountryTranslationCrud);
registerCrudClassProxy(countryTranslationCrudClassProxy);
