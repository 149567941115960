import { IOauthClientRedirectUriRecord, oauthClientRedirectUriCrudClassProxy, OauthClientRedirectUriCrudClassProxy } from '@src/framework/crud/sys/OauthClientRedirectUriCrud';
import RecordEditor from '@framework/forms/recordeditor';
import { IFieldInfo } from '@src/framework/crud/Meta';
import { IFieldEditorProp } from '@src/framework/forms/recordeditor';


export default class OauthClientRedirectUriEditor extends RecordEditor<IOauthClientRedirectUriRecord> {

    public getCrudClassProxy() : OauthClientRedirectUriCrudClassProxy { return oauthClientRedirectUriCrudClassProxy;  }

    protected getFieldEditorProps(fieldInfo: IFieldInfo) : IFieldEditorProp {
        let result : IFieldEditorProp = super.getFieldEditorProps(fieldInfo);
        if (fieldInfo.field_name=="oauth_client_id")
            result.disabled = true;
        return result;
    }
}
