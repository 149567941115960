import * as React from 'react';
import * as ReactDOM from 'react-dom';

type TabsProps = {
    className?: string | "",
    tabGroup?: string,
    onChange?: (value:any)=>void
}
export class RadioTabs extends React.Component<TabsProps, any> {

    constructor(props: any) {
        super(props);

        this.state = {
        }
    }

    onChange(value:any)
    {
        this.setState({selectedValue : value});
        this.props.onChange ? this.props.onChange(value) : null;
    }
 
    render() {

        const childrenWithProps = React.Children.map(this.props.children, (child:any) =>

            (child && child.type == RadioTab)
            ?             
            React.cloneElement(child, { tabGroup: this.props.tabGroup, onChange: this.onChange.bind(this), selected: this.state.selectedValue ?  child.props.value == this.state.selectedValue : child.props.selected })   
            : child   
        );

        return <ul className={"eke-tabs" + (this.props.className ? this.props.className : "")}>
                   {childrenWithProps}
                </ul>
    }
}

type TabProps = {
    text?: string | undefined,
    className?: string | "",
    key?: number | undefined,
    tabGroup?: string,
    value?: any,
    onChange?: (value:any)=>void,
    selected?: boolean
}

type TabState = {
}
export class RadioTab extends React.Component<TabProps, TabState> {

    constructor(props: any) {
        super(props);

    }
    
    onChange(value: any)
    {
        this.props.onChange ? this.props.onChange(value) : null;
    }

 
    render() {
        return  <li key={this.props.key} className={"tab " + (this.props.selected ? "selected " : "")  + (this.props.className ? this.props.className : "")}>                        

                        <input id={"radio"+this.props.value} type="radio" value={this.props.value} name={this.props.tabGroup} checked={this.props.selected} onChange={this.onChange.bind(this, this.props.value)}/>

                        <label htmlFor={"radio"+this.props.value} ref="tabLabel">                            
                            {this.props.children}
                        </label>
                </li>
                    
    }
}
