import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISectionCommentRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Szekció
     *
     * NOT NULL -> doc.section ON DELETE cascade
     *
     */
    section_id?: number;
    /**
     * Eredeti kijelölt szöveg
     */
    quote?: string|null;
    /**
     * Eredeti HTML
     */
    original_html?: string|null;
    /**
     * Pozíciók
     */
    ranges?: any|null;
    /**
     * Megjegyzés
     */
    comment?: string;
    /**
     * Elfogadva
     */
    acknowledged?: string /*timestamp?*/|null;
    /**
     * Ki fogadta el
     *
     * -> sys.sec_user ON DELETE noaction
     *
     */
    acknowledged_by_id?: number|null;
    /**
     * Szekció megjegyzés típusa
     *
     * -> doc.section_comment_type ON DELETE noaction
     *
     */
    section_comment_type_id?: number|null;
    /**
     * Ki mondta, hogy nem megoldható
     *
     * -> sys.sec_user ON DELETE noaction
     *
     */
    unsolvable_by_id?: number|null;
    /**
     * Miért nem megoldható
     */
    unsolvable_reason?: string|null;
    /**
     * Ki mondta, hogy egyeztetés szükséges
     *
     * -> sys.sec_user ON DELETE noaction
     *
     */
    coordination_required_by_id?: number|null;
    /**
     * Miért szükséges egyeztetés
     */
    coordination_required_reason?: string|null;
}

/**
 *  doc.section_comment - Szekció megjegyzés
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class SectionCommentCrud extends Crud<ISectionCommentRecord> {
    public static TABLE_INFO_ID = 3480891217;
    public static getSchemaNameForClass() { return 'doc'; }
    public static getTableNameForClass() { return 'section_comment'; }

    public static load: (id: number, aServer ?: Server) => Promise<SectionCommentCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISectionCommentRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISectionCommentRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISectionCommentRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISectionCommentRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISectionCommentRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SectionCommentCrudClassProxy extends CrudClassProxy<ISectionCommentRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const sectionCommentCrudClassProxy = new SectionCommentCrudClassProxy(SectionCommentCrud);
registerCrudClassProxy(sectionCommentCrudClassProxy);
