import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { configure as mobxConfigure } from 'mobx';

mobxConfigure({enforceActions: "observed"});

import axios from 'axios';

import { Route, Router, Switch } from 'react-router-dom';
import { History } from 'history';
import * as NotificationSystem from 'react-notification-system';
import { debounce } from 'lodash';
import LocationUtil from '@src/framework/util/LocationUtil';
import { createBrowserHistory } from 'history';
import { onConfigResultArrived, onAuthArrived, config } from '@src/framework/server/Server';
import { getConfig } from '@src/framework/api/config/getConfig';
import { completeLogin } from '@src/framework/util/MainPageTemplate';
import { getLanguage, loadTranslations, getLanguageId } from '@src/translation';
import { me } from './framework/server/Auth';
import { doLogin } from './Routes';

export let history : History;

// Előnézet ablakban ne rendereljük ki a főlapot
export const PREVIEW_DISABLE_RENDER_NAME = "_preview_disable_render";

export const ROOT_DIV_ID = "eke-app";

export async function initSystem(callback?: ()=> void) {
    // TODO ez nélkül nem lehet bookmark-ból / url-ből betölteni lapot, mindig betölti ahol legutoljára voltunk, még ha az a lap hibás is!
    if (window.location.href.indexOf("?code=") == -1) {
        LocationUtil.save();
    }

    // https://github.com/ReactTraining/react-router/issues/4924
    /*

        I figured out the best way to do this using react-router 4. 
        BrowserRouter internally creates its own history object that 
        does not work as expected. That's what was causing the problem 
        for me. I solved it by avoiding BrowserRouter, using Router
        instead and creating my own history object:
    */
    history =  createBrowserHistory();


    onConfigResultArrived(await getConfig({}));

    const aAuth = await completeLogin(history, callback);
    onAuthArrived(aAuth);
    if (config.mainServer.protectFrontend) {
        if (!me) {
            doLogin();
        }
    }

    await loadTranslations(await getLanguageId());

    initTracker();
        
}

function initTracker() {
    const trackerUrl = config.mainServer.trackerUrl;
    const trackerSiteId = config.mainServer.trackerSiteId;
    
    if (trackerUrl && trackerSiteId) {
        var script = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        script.appendChild(document.createTextNode(`
            var _paq = window._paq || [];
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
                var u="${trackerUrl}";
                _paq.push(['setTrackerUrl', u+'matomo.php']);
                _paq.push(['setSiteId', '${trackerSiteId}']);
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
            })();
        `));
        document.body.appendChild(script);

        history.listen(() => {
            setTimeout(() => {
                //Megváruk, hogy megváltozzon a lap címe (okostankönyv lapokon letölti a leckét, és átállítja a címet, és azt küldjük el stat-ra)
                if (window["_paq"]) {
                    window["_paq"].push(['setCustomUrl', window.location.href]);
                    window["_paq"].push(['setDocumentTitle', document.title]);
                    window["_paq"].push(['trackPageView']);
                }
            }, 100);
        });
    }
}

export class app {

    static notificationSystem?: NotificationSystem.System = undefined;

    public static setNotificationSystem(notificationSystem: NotificationSystem.System) {
        app.notificationSystem = notificationSystem;
    }

    public static addNotification(notification: NotificationSystem.Notification): NotificationSystem.Notification | null {
        if (this.notificationSystem) {
            return this.notificationSystem.addNotification(notification);
        } else {
            console.log("Notification system is not set!");
            alert(notification.title + " " + notification.message);
            return null;
        }
    }

    public static showSuccess(title: string, message: any) {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message, title, level: "success", 
            });
        } else {
            console.error("Notification system is not set!");
            alert(title + " " + message);
        }
    }
    //end if mediafilechooser notifications
    public static showInfo(title: string, message: any) {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message, title, level: "info" 
            });
        } else {
            console.error("Notification system is not set!");
            alert(title + " " + message);
        }
    }    

    public static showWarning(title: string, message: any) {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message, title, level: "warning" 
            });
        } else {
            console.error("Notification system is not set!");
            alert(title + " " + message);
        }
    }   

    public static showError(title: string, messageOrError: string | Error) {
        var message;

        if (messageOrError instanceof Error) {
            const error = messageOrError as any;

            if (error.response && error.response.data && error.response.data.error_description) {
                message = error.response.data.error_description;
            } else if (error.response && error.response.data && error.response.data.error) {
                message = error.response.data.error;
            } else {
                console.error(error);
                message = "Általános hiba történt";
            }
        } else {
            message = messageOrError;
        }
    
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message, title, level: "error", 
            });
        } else {
            console.error("Notification system is not set!");
            alert(title + " " + message);
        }
    }

    public static showErrorDebounced = debounce(app.showError.bind(app), 100);

    /**
     * Ha egy Ajax hívás hibát adott vissza, ezzel jelezd ki.
     * Átadhatsz neki közvetlenül egy olyan axios error response-t is
     * amit .catch(error) -al elkapsz.
     */
    public static showErrorFromJsonResult(error: Object) {
        if (error["response"] && error["response"]["data"]) {
            error = error["response"]["data"];
        }
        console.error(error);

        var message: string = "";

        if (error["error_description"]) {
            message = error["error_description"];
        } else if (error["error"]) {
            message = error["error"];
        } else {
            message = error.toString();
        }

        // TODO on the server: It is not possible to know if the server message is for the user or the programmer
        if (message.toLowerCase().includes("error")) {
            this.showErrorDebounced("Hiba történt", "Kérjük próbálja újra");
        } else {
            this.showError("Hiba", message);
        }

    }
}
