import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapCourseOsapRecord {
    /**
     * Belső azonosító
     *
     * Belső azonosító
     */
    id?: number;
    /**
     * Kurzus belső azonosító
     *
     * Kurzus belső azonosító
     */
    course_id?: number|null;
    /**
     * Kurzus azonosító
     *
     * Kurzus azonosító
     */
    regno?: number|null;
    /**
     * Csoport neve
     *
     * Csoport neve
     */
    course_name?: string|null;
    /**
     * Képzési helyszín irányítószáma
     *
     * Képzési helyszín irányítószáma
     */
    zipcode?: string|null;
    /**
     * Első kontaktnap
     *
     * Első kontaktnap
     */
    start_date?: string /*date?*/|null;
    /**
     * Utolsó kontaktnap
     *
     * Utolsó kontaktnap
     */
    end_date?: string /*date?*/|null;
    /**
     * Csoport továbbképzési ideje (-tól) Szerződés szerinti első nap
     *
     * Csoport továbbképzési ideje (-tól) Szerződés szerinti első nap
     */
    training_from?: string /*date?*/|null;
    /**
     * Csoport továbbképzési ideje (-ig) Szerződés szerinti utolsó nap
     *
     * Csoport továbbképzési ideje (-ig) Szerződés szerinti utolsó nap
     */
    training_to?: string /*date?*/|null;
    /**
     * Résztvevő neve
     *
     * Résztvevő neve
     */
    fullname?: string|null;
    /**
     * Résztvevő neme
     *
     * Résztvevő neme
     */
    gender?: string|null;
    /**
     * Résztvevő születési évszáma
     *
     * Résztvevő születési évszáma
     */
    birth_year?: number /* float8 */|null;
    /**
     * Résztvevő irányítószáma
     *
     * Résztvevő irányítószáma
     */
    residence_zip?: string|null;
}

/**
 *  kap.view_kap_course_osap - KAP KSZR OSAP riport
 */

export default class ViewKapCourseOsap extends View<IViewKapCourseOsapRecord> {
    public static VIEW_INFO_ID = 2020010802;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_course_osap'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapCourseOsap>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapCourseOsapRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapCourseOsapClassProxy extends ViewClassProxy<IViewKapCourseOsapRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapCourseOsapClassProxy = new ViewKapCourseOsapClassProxy(ViewKapCourseOsap);
registerViewClassProxy(viewKapCourseOsapClassProxy);

