import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';
import OoFileCrud, { IOoFileRecord } from '@framework/crud/media//OoFileCrud';
import FileManagerPager from './FileManagerPager';

import { app } from '@src/index';
import { setKeywords } from '@src/component/meta/meta_keyword_assigner_plugin';
import { __ } from '@src/translation';

interface OFIQuickTaggerPageProps {
    /**
     * Ide megadhatsz egy tömböt, amiben felsorolod azokat a kiterjesztéseket, amiket a felhasználó
     * kiválaszthat. A nem kiválasztható állományok eltérően jelennek meg.
     */
    selectableExtensions?: string[];
    showBadges ?: boolean;
}

interface OFIQuickTaggerPageState {
    limit: number;
    offset: number;
    pageSize: number;

    files?: IOoFileRecord[];
    filter: string
    pendingFiles: IOoFileRecord[];

    isGreaticons: boolean;
    loading: boolean;

    uniquekeywords: string;
    uniquekeywordList: string[];
    pendingKeywords: string[];

}

export default class OFIQuickTaggerPage extends React.Component<OFIQuickTaggerPageProps, OFIQuickTaggerPageState> {
    constructor(props: OFIQuickTaggerPageProps) {
        super(props);
        this.state = {
            limit: 50,
            offset: 0,
            pageSize: 25,
            files: undefined,
            filter: '',
            pendingFiles: [],
            isGreaticons: false,
            loading: false,

            pendingKeywords: [],
            uniquekeywords: '',
            uniquekeywordList: [],
        };
    }

    async reloadQuickTaggerAsync() {
        this.onFilterKeyPress("Enter")
        try {
            this.setState({ files: undefined, loading: true });
            let files = await OoFileCrud.list(
                { filter: { is_active: true, keywords: null },
                order_by: "title",     
                limit: this.state.limit, 
                offset: this.state.offset 
            });
            this.setState({ files: files, loading: false });

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onFilterChange(e: any) {
        this.setState({ filter: e.target.value });
    }

    private onFilterKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.reloadQuickTaggerAsync();
        }
    }

   componentWillMount()
    {
        this.reloadQuickTaggerAsync();
    }

    private onRecordClick(e: IOoFileRecord, event: any) {
        var pendingImages = this.state.pendingFiles;
        let selectedPic = document.getElementById(e.id+"");
        if (selectedPic) {
            if (selectedPic.className.indexOf("image-selector__selected") > 0) {
                selectedPic.classList.remove("image-selector__selected");
                selectedPic.classList.add("image-selector__unselected");
                var pendingIndex = pendingImages.indexOf(e);
                pendingImages.splice(pendingIndex, 1);
            }
            else {
                selectedPic.classList.remove("image-selector__unselected");
                selectedPic.classList.add("image-selector__selected");
                pendingImages.push(e);
            }
        }
        this.setState({ pendingFiles: pendingImages });
    }
    saveComplete = () => {
        app.showSuccess(
            this.state.pendingKeywords.length + " " + __("Kulcsszó,") + " " +
                 this.state.pendingFiles.length + __("db képen"), 
                 __("Sikeresen elmentve")
        );
        this.setState({ pendingFiles: [] });
        this.reloadQuickTaggerAsync();
    }

    private onChangeLimit = (limit: number) => {
        this.setState({limit}, this.reloadQuickTaggerAsync);
    }    

    private onChangeOffset = (offset: number) => {
        this.setState({offset, pendingFiles:[]}, this.reloadQuickTaggerAsync);
    }    

    async SavePendingKeywords(e: any) {
        if (this.state.pendingKeywords.length<=0) {
            app.showError(__("Sikertelen mentés"), __("Válasszon ki egy vagy több kulcsszót a listából!"))
        }
        else if (this.state.pendingFiles.length <= 0) { 
            app.showError(__("Sikertelen mentés"), __("Válasszon ki egy vagy több képet!"))
        }
        else {
            for (var i = 0; i < this.state.pendingFiles.length; i++) {
                /*
                let crud = new OoFileCrud({ id: this.state.pendingFiles[i].id, keywords: newKeywords });
                await crud.put();
                */
                setKeywords(
                   OoFileCrud.TABLE_INFO_ID, 
                    this.state.pendingFiles[i].id!,
                    this.state.pendingKeywords
                );
            }
            this.saveComplete();
        }
    }

    private onUniqueFilterChange(e: any) {
        this.setState({ uniquekeywords: e.target.value });
    }

    private onUniqueFilterKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.addUniqueKeyword("");
        }
    }

    private addUniqueKeyword(event: any) {
        if (this.state.uniquekeywords != "") {
            this.state.pendingKeywords.push(this.state.uniquekeywords);
            this.state.uniquekeywordList.push(this.state.uniquekeywords);
            this.setState({ uniquekeywords: "", uniquekeywordList: this.state.uniquekeywordList })
        }
    }

    private removeUniqueKeyword(keyword: string) {
        var pendingarray = this.state.pendingKeywords;
        var pendingIndex = pendingarray.indexOf(keyword);
        pendingarray.splice(pendingIndex, 1);
        this.setState({ pendingKeywords: pendingarray });
        var uniquearray = this.state.uniquekeywordList;
        var uniqueIndex = uniquearray.indexOf(keyword);
        uniquearray.splice(uniqueIndex, 1);
        this.setState({ uniquekeywordList: uniquearray });
    }

    private checkingInorOut(e: any) {
        var array = this.state.pendingKeywords;
        if (e.target.checked) {
            array.push(e.target.name);
        }
        else {
            var index = array.indexOf(e.target.name);
            array.splice(index, 1);
        }
        this.setState({ pendingKeywords: array });
    }

    render() {
        const loading: boolean = this.state.loading;

        const header =
            <div className="row">
                <div className="shrink column">
                    <label className="middle" htmlFor="image-select-filter">{__("Keresés meta nélküli képek között")}:</label>
                </div>
                <div className="column">
                    <input value={this.state.filter} placeholder={__("Keresés fájlnévre")} type="text" id="quickTaggerImgSearch" onChange={this.onFilterChange.bind(this)} disabled={loading} onKeyPress={this.onFilterKeyPress.bind(this)} />
                </div>
                <div title={__("Keresés")} className="shrink column"><button className="button small middle" onClick={this.reloadQuickTaggerAsync.bind(this)} disabled={loading}>
                    <i className="fa fa-sync" /></button>
                </div>
                <div className="shrink column"><button title={this.state.isGreaticons ? __("Kisebb képek") : __("Nagyobb képek")} className="button small middle" onClick={() => this.setState({ isGreaticons: !this.state.isGreaticons })}>
                    {this.state.isGreaticons ? < i className="fa fa-list" /> : <i className="fa fa-th-large" />}
                </button>
                </div>
            </div>

        /*end quicktagger imgspart*/

        /*display inside untagged images*/
        var files = this.state.files;
        if (this.state.filter && files) {
            files = files.filter((file) => file.title!.indexOf(this.state.filter) != -1);
        }
        let fileList = null;
        if (this.state.loading) {
            fileList = <BubbleLoader />
        } else if (files) {

            fileList = files.map((file, index) => {
                const extension = file.title!.toLowerCase().split(".").pop() || "";
                var taggable = false;
                let keywordsnumUntagged = file.keywords ? file.keywords.split(",").length : 0;
                var selectable = true;
                var thumbnailColor = "green";

                if (this.props.selectableExtensions && !this.props.selectableExtensions.includes(extension)) {
                    selectable = false;
                    thumbnailColor = "lightgray";
                }

                var thumbnail = <span style={{ color: thumbnailColor }}><i className="fa fa-file fa-2x" /></span>

                if (file.has_thumb) {
                    let src = this.state.isGreaticons ? "/api/media/thumb/200/" + file.sha1 : "/api/media/thumb/100/" + file.sha1;
                    thumbnail = <img draggable={false} className="image-selector__thumbnail--notembedded" src={src} />

                } else if (extension == 'mp4') {

                    thumbnail = <span style={{ color: thumbnailColor }}><i className="fa fa-play fa-2x" /></span>

                } else if (extension == 'html' || extension == 'htm') {

                    thumbnail = <span style={{ color: thumbnailColor }}><i className="fa fa-code fa-2x" /></span>
                }
                if (extension == 'jpg' || extension == 'png' || extension == 'gif' || extension == 'psd' || extension == 'jpeg' || extension == 'svg') {
                    taggable = true;
                }

                if (taggable && (this.props.showBadges===undefined || this.props.showBadges)) {
                    return <span id={file.id+""} title={file.title} key={index} className={"image-selector__wrapper image-selector__wrapper--is-selectable" + (this.state.isGreaticons ? " image-selector__wrapper--is-large" : "")}
                        onClick={this.onRecordClick.bind(this, file, file.id)}>
                        {thumbnail}                      
                        <span className="image-selector__name">{file.title}</span>
                        <span className={"badge " + (keywordsnumUntagged ? "" : "alert")}>{keywordsnumUntagged}</span>
                    </span>
                }
                else {
                    return <span title={file.title} key={index} className={"image-selector__wrapper image-selector" + (this.state.isGreaticons ? " image-selector__wrapper--is-large" : "")}>
                        {thumbnail}
                        <span className="image-selector__name">{file.title}</span>
                    </span>
                }
            })
        }

        return <div>
            {header}
            <div className="row expanded">
            <div className="medium-3 column mfc-quicktagger-scroll">
                <div className="mfc-quicktagger-wrapper">
                    <div id="keywordContainer" className="large-12 medium-12 small-12 columns exerciselist-filterContainer row expanded">
                            <div className="column small-12 large-12 clearfix">

                                <div className="input-group large-12 small-12">
                                    <div className="eke-mfc-tooltip input-group-field">                                
                                        <span className="eke-mfc-tooltiptext">{__("Hozzáadás után pipálja be a használni kívánt kulcsszavakat.")}</span>
                                        <input value={this.state.uniquekeywords}
                                            onChange={this.onUniqueFilterChange.bind(this)}
                                            onKeyPress={this.onUniqueFilterKeyPress.bind(this)}
                                            id="keywordInput"
                                            placeholder={__("Hozzáadás a listához")}
                                            type="text"
                                            className="input-group-field"
                                        />                                   
                                    </div>
                                    <div className="input-group-button">
                                        <button onClick={this.addUniqueKeyword.bind(this)} className="button small middle"><i className="fa fa-plus"></i></button>
                                    </div>
                               </div> 
                            </div>
                            <div className="column small-12 large-12">
                                <label style={{ fontWeight: "bold" }} className="">{__("Jelenlegi kulcsszavak")}:</label>
                                {
                                    this.state.uniquekeywordList.map(keyword => {
                                        return <div className="row">
                                                    <div className="small-4 large-4 clearfix">
                                                        <label className=""><input name={keyword} defaultChecked onChange={this.checkingInorOut.bind(this)} className="mfc-keyword-checkbox" type="checkbox" /> {keyword}</label>
                                                    </div>
                                                    <div className="small-4 large-4 clearfix">
                                                        <button onClick={this.removeUniqueKeyword.bind(this, keyword)} className="button alert small middle btn-left-pad-mfc"><i className="fa fa-trash"></i></button>
                                                    </div>
                                                </div>
                                    })
                                }
                            </div>
                            <div className="column small-12 large-12 clearfix">
                                <button onClick={this.SavePendingKeywords.bind(this)} className="success button expanded">{__("Kulcsszavak mentése a képeken")}</button>
                            </div>
                        </div>          
                    <FileManagerPager
                        disabled={this.state.loading}
                        limit={this.state.limit}
                        offset={this.state.offset}
                        onChangeLimit={this.onChangeLimit}
                        onChangeOffset={this.onChangeOffset}
                    />            
                </div>
            </div>
            <div className="medium-9 column mfc-quicktagger-scroll">
                <div className="mfc-quicktagger-wrapper">
                    {fileList}
                </div>
            </div>
            </div>
        </div>;
    }
}
