import * as React from 'react';
import { Switch, Route, RouteProps, match } from 'react-router';
import { Link, matchPath } from 'react-router-dom';

import SearchComponent from '@src/component/search/SearchComponent';
import { BookSelectorPage, BookPage } from '@src/component/book/viewer/BookSelectorPage';
import DetailedSearch from '@src/component/search/DetailedSearch';
import NavaSearch from '@src/component/search/NavaSearchComponent';
import ExternalSearch from '@src/component/search/ExternalSearch';

import BookList from '@src/component/book/BookList';
import BookStructureEditor from '@src/component/book/BookStructureEditor';


import Error404Page from '@src/component/Error404Page';

import ExerciseListPublicComponent from '@src/component/exercise/Viewer/ExerciseListPublicComponent';
import ExerciseEngineSelect from '@src/component/exercise/Editor/ExerciseEngineSelect'
import { ExerciseEditorProtected } from '@src/component/exercise/Editor/ExerciseEditor'
import ExerciseShow from '@src/component/exercise/Viewer/ExerciseShow'

import ProfilePageComponent from '@src/component/profile/ProfilePageComponent'

import { WfRoutes } from '@src/component/wf/wf_routes';
import { SysRoutes } from '@src/component/sys/sys_routes';
import { MetaRoutes } from '@src/component/meta/meta_routes';
import { TicketRoutes } from '@src/component/ticket/ticket_routes';
import { UsrRoutes } from '@src/component/usr/usr_routes';
import InstituteGroupMemberList from '@src/component/institute/InstituteGroupMemberList';


import ExerciseSeriesEditor from '@src/component/exercise/ExerciseSeries/ExerciseSeriesEditor';
import { BookView } from '@src/component/book/viewer/BookView';
import ExerciseSeriesShow from '@src/component/exercise/ExerciseSeries/ExerciseSeriesShow';


import Sidebar, { SidebarMenuItem } from '@src/component/Sidebar';
import { AdminMenu } from '@src/component/SideMenu';
import SysAnnounce from '@src/component/SysAnnounce';
import SplitPane from 'react-split-pane';
import { MediaPage } from '@src/component/media/MediaPage';
import { SpellRoutes } from '@src/component/spell/spell_routes';
import { ArgoRoutes } from '@src/component/argo/argo_routes';
import { LessonEditorPageProtected } from '@src/component/book/editor/LessonEditorPage';
import { ContentPermissionPage } from '@src/component/filemanager/ContentPage';
import { ExerciseTestPage } from '@src/component/exercise/Viewer/ExerciseTestPage';

import SectionHistoryPageProtected from '@src/component/book/editor/SectionHistoryPage';
import AdaptiveSeriesEditor from '@src/component/exercise/ExerciseSeries/AdaptiveSeriesEditor';
import KnowledgeBaseList from './component/knowledgebase/KnowledgeBaseList';
import KnowledgeBaseEditPage from './component/knowledgebase/KnowledgeBaseEdit';
import KnowledgeBaseViewPage from './component/knowledgebase/KnowledgeBaseView';
import { BookCreate } from '@src/component/book/editor/BookCreate';
import { KbRoutes } from './component/kb/kb_routes';
import { DataImpRoutes } from './component/data_imp/data_imp_routes';
import { LearningPathEditorComponent } from './component/learningpath/LearningPathEditorComponent';
import { ViewSharedWithMePage } from './component/share/ViewSharedWithMePage';
import { RunSharedWithMe } from './component/share/RunSharedWithMe';
import ExerciseHelperLinks from './component/exercise/ExerciseHelperLinks';
import { ViewSharedByMePage } from './component/share/ViewSharedByMePage';
import { RunSharedByMe } from './component/share/RunSharedByMe';
import { LowGradeBookPage } from '@src/component/book/viewer/LowGradeBook';
import { LessonplanRoutes } from './project/dpmk/lessonplan/lessonplan_routes';
import { DownloadableMaterialPage } from './component/dl_material/DownloadableMaterialPage';
import ProgressionPage from './component/progression/ProgressionPage';
import { DownloadableMaterials } from '@src/component/book/viewer/DownloadableMaterials';
import { SysDay } from './component/sys/sys_day';
import { MyMunyiCalendarPage } from './component/calendar/MyMunyiCalendar';
import { SurveyPage } from './component/survey/Survey';
import { ApproveMunyiCalendarPage } from './component/calendar/ApproveMunyiCalendar';
import { OOEditor } from './component/filemanager/OOEditor';
import LocationUtil from './framework/util/LocationUtil';
import { startLogin } from './framework/util/MainPageTemplate';

import { history } from '@src/index';
import { onAuthArrived } from './framework/server/Server';
import { __ } from './translation';

import { config } from '@src/framework/server/Server';

import TicketCategoryEditor, { PATH_TICKET_CATEGORY_EDITOR } from '@src/component/ticket/htr_ticket_category_editor';
import Tickets from './component/ticket/htr_tickets';
import { CalendarPage } from './project/htr/CalendarPage';
import { TicketingPage } from './component/ticket/htr_ticket_message';
import { KAPErrorReport } from './component/KAPEmailSender/KAPEmailSenderComponent';
import ElasticIndexersPage from './component/elastic/ElasticIndexers';
import { getRoutes, ModuleRoute, getMenus, ModuleMenu } from './module/Module';
import { SurveyModule } from './component/survey/SurveyComponent';
import { SurveyList } from './component/survey/SurveyList';
import { SurveyEdit } from './component/survey/SurveyEdit';
import { SurveySummaryPage } from './component/survey/SurveySummaryPage';
import TemplatePreview from './component/book/template/TemplatePreview';
import { Component } from '@fullcalendar/core';
import { Impressum } from './project/nkp/Impressum';
import { LearningPathContainer } from './component/learningpath/LearningPathContainer';

export const PATH_MAIN = "/";
// Kérdőívek
export const PATH_COURSE_ORGANIZER_SURVEY = "/survey";
export const PATH_SURVEY = "/kerdoiv";
export const PATH_SURVEY_LIST = PATH_SURVEY + "/lista";
export const PATH_SURVEY_EDIT = PATH_SURVEY + "/szerkesztes";
export const PATH_SURVEY_SUMMARY_PAGE = PATH_SURVEY + "/summary";
/* Publikált tartalmak */
export const PATH_PUBLISHED_BOOK_SELECT = "/tankonyvek";
export const PATH_PUBLISHED_BOOKS = "/okostankonyvek";
export const PATH_PUBLISHED_BOOK = "/tankonyv";
export const PATH_BOOK_VIEW_EDITING = "/tananyag";
export const PATH_PUBLISHED_BOOK_LOWGRADE = "/alsostankonyv";
export const PATH_PUBLISHED_DOWNLOADABLE = "/letoltheto_tananyagok";

export const PATH_PUBLISHED_SEARCH = "/kereso";
export const PATH_PUBLISHED_DETAILED_SEARCH = "/reszletes";
export const PATH_PUBLISHED_EXTERNAL_SEARCH = "/kulso";

export const PATH_PUBLISHED_MEDIA = "/media";
export const PATH_PUBLISHED_MEDIA_SEARCH = PATH_PUBLISHED_MEDIA + "/kereso";
export const PATH_PUBLISHED_MEDIA_VIEW = PATH_PUBLISHED_MEDIA + "/megtekint";
export const PATH_PUBLISHED_MEDIA_LIST = PATH_PUBLISHED_MEDIA + "/lista";
export const PATH_PUBLISHED_MEDIA_HISTORY = PATH_PUBLISHED_MEDIA + "/elozmenyek";
export const PATH_PUBLISHED_MEDIA_POPULAR = PATH_PUBLISHED_MEDIA + "/nepszeruek";
export const PATH_PUBLISHED_MEDIA_FAVOURITE = PATH_PUBLISHED_MEDIA + "/kedvencek";

export const PATH_MEDIA_VIEW_EDITING = PATH_PUBLISHED_MEDIA + "/megjelenit";

/* new exercise paths */
export const PATH_EXERCISE = "/feladat";
export const PATH_EXERCISE_SERIES = "/feladatsor";

export const PATH_EXERCISE_PUB_LIST = PATH_EXERCISE + "/feladattar";
export const PATH_EXERCISE_PUB_VIEW = PATH_EXERCISE + "/megjelenites";

export const PATH_EXERCISE_MORE_HELP_PUB = PATH_EXERCISE_PUB_VIEW + "/tobbsegitseg";
export const PATH_EXERCISE_SERIES_PUB_VIEW = PATH_EXERCISE_SERIES + "/megjelenites";

/* Bejelentkezés utáni tartalmak */

export const PATH_DASHBOARD = "/iranyitopult";
export const PATH_EXERCISE_LIST = PATH_EXERCISE + "/szerkesztoiLista";
export const PATH_EXERCISE_ENGINE_SELECT = PATH_EXERCISE + "/valaszto";
export const PATH_EXERCISE_NEW = PATH_EXERCISE + "/uj";
export const PATH_EXERCISE_EDIT = PATH_EXERCISE + "/szerkesztes";
export const PATH_EXERCISE_VIEW = PATH_EXERCISE + "/megtekintes";
export const PATH_EXERCISE_MORE_HELP = PATH_EXERCISE_VIEW + "/tobbsegitseg";
export const PATH_EXERCISE_TEST = PATH_EXERCISE + "/exerciseTest";

export const PATH_EXERCISE_SERIES_LIST = PATH_EXERCISE_SERIES + "/szerkesztoiLista";
export const PATH_EXERCISE_SERIES_EDIT = PATH_EXERCISE_SERIES + "/szerkesztes";
export const PATH_EXERCISE_SERIES_CREATE = PATH_EXERCISE_SERIES + "/uj";
export const PATH_EXERCISE_SERIES_VIEW = PATH_EXERCISE_SERIES + "/megtekintes";

export const PATH_PROGRESSION_PAGE = "/elorehaladas";

export const PATH_KNOWLEDGE_BASE_LIST = "/tudasbazis";
export const PATH_KNOWLEDGE_BASE_EDIT = PATH_KNOWLEDGE_BASE_LIST + "/szerkeszt";
export const PATH_KNOWLEDGE_BASE_VIEW = PATH_KNOWLEDGE_BASE_LIST + "/megtekint";

export const PATH_ADAPTIVE_EXERCISE_SERIES_EDIT = PATH_EXERCISE_SERIES + "/adaptivSzerkesztes";
export const PATH_ADAPTIVE_EXERCISE_SERIES_CREATE = PATH_EXERCISE_SERIES + "/ujAdaptiv";

export const PATH_LEARNING_PATH = "/tanulasiut";
export const PATH_LEARNING_PATH_CREATE = PATH_LEARNING_PATH + "/uj";
export const PATH_LEARNING_PATH_EDIT = PATH_LEARNING_PATH + "/szerkeszt";
export const PATH_LEARNING_PATH_VIEW = PATH_LEARNING_PATH + "/megtekint";

export const PATH_CONTENT_STORE = "/tartalomtar";
export const PATH_CONTENT_STORE_SHARED_WITH_ME = PATH_CONTENT_STORE + "/megosztas";
export const PATH_CONTENT_STORE_SHARED_BY_ME = PATH_CONTENT_STORE + "/megosztottam";
export const PATH_CONTENT_STORE_FAVOURITE = PATH_CONTENT_STORE + "/kedvencek";
export const PATH_CONTENT_STORE_HISTORY = PATH_CONTENT_STORE + "/elozmenyek";
export const PATH_OO_EDITOR = "/oo_szerkeszt";

export const PATH_CONTENT = "/tartalom";
export const PATH_PROFILE = "/felhasznalo";
export const PATH_PROFILE_TRAINING = PATH_PROFILE + "/jelentkezes";
export const PATH_PROFILE_INSTITUTE_GROUPS = PATH_PROFILE + "/intezmenyicsoport"
export const ADMIN = "/admin";
export const SYS_ANNOUNCE = "/rendszerhirdetmeny";
export const SYS_DAY = "/munkanapok";
export const PATH_CALENDAR_MUNYI_ACTIVITY = "/munyi_aktivitas";
export const PATH_CALENDAR_MUNYI_APPROVE = "/munyi_jovahagyas";

export const PATH_EDIT_BOOK = "/konyvszerkeszto";

export const PATH_EDIT_BOOK_NEW = PATH_EDIT_BOOK + "/uj";
export const PATH_EDIT_BOOK_LIST = PATH_EDIT_BOOK + "/lista";
export const PATH_EDIT_BOOK_TABLE_OF_CONTENTS = PATH_EDIT_BOOK + "/tartalomjegyzek";
export const PATH_EDIT_BOOK_SECTIONS = PATH_EDIT_BOOK + "/szerkeszt";
export const PATH_SECTION_HISTORY = PATH_EDIT_BOOK + "/naplo";

export const PATH_SHARED = "/kiosztas";
export const PATH_SHARED_CONTENT_RUN_PLAY = PATH_SHARED + "/megold";
export const PATH_SHARED_CONTENT_WITH_ME = PATH_SHARED + "/megtekint";
export const PATH_SHARED_CONTENT_BY_ME = PATH_SHARED + "/ellenoriz";
export const PATH_SHARED_CONTENT_RUN_CHECK = PATH_SHARED + "/osztalyoz";
export const PATH_SHARED_CONTENT_RUN_CHECK_MINE = PATH_SHARED + "/eredmeny";

export const PATH_TEST_TANAR_TPWD = "/testTanarTpwd";

export const PATH_GUIDE_USER = "/tankonyv/felhasznaloi_kezikonyv/";
export const PATH_GUIDE_EDITOR = "/tankonyv/szerkesztoi_kezikonyv/";
export const PATH_PAGE_LEARNING_SOFTWARE = "/tankonyv/lapok/tanulasi_szoftverek";
export const PATH_IMPRESSUM = "/impresszum/";

// event
export const PATH_EVENT = "/rendezveny_szervezes";
export const PATH_EVENT_LIST = PATH_EVENT + "/lista";
export const PATH_EVENT_REGISTRATION = "/rendezveny"
//event

//KAP email sender
export const PATH_KAP_ERROR_REPORT = "/hibakuldes";
//KAP email sender

/* CMS */
export const PATH_CMS = "/cms";

export const PATH_CMS_MENU = PATH_CMS + "/menu";
export const PATH_MENU_EDITOR_LIST = PATH_CMS_MENU + "/lista";
export const PATH_MENU_EDIT = PATH_CMS_MENU + "/szerkeszt";

export const PATH_CMS_CONTENT = PATH_CMS + "/tartalom";
export const PATH_CMS_CONTENT_LIST = PATH_CMS_CONTENT + "/lista";
export const PATH_CMS_CONTENT_EDIT = PATH_CMS_CONTENT + "/szerkeszt/";
export const PATH_CMS_CONTENT_VIEW = PATH_CMS_CONTENT + "/megtekint";
export const PATH_CMS_CONTENT_ARTICLES = "/hirek";


export const PATH_SUBSITE_EDIT = "/aloldal/szerkeszt";
export const PATH_SUBSITE_LIST = "/aloldal/lista";
//export const PATH_PORTAL_ADMIN="/portaladmin";

/* Ticket */
export const PATH_TICKETS = "/jegyek";
export const PATH_TICKETING_PAGE = "/uzeno";
export const PATH_TICKETING_MESSAGES = PATH_TICKETING_PAGE + "/levelezes";
export const PATH_TICKETING_MESSAGE = PATH_TICKETING_PAGE + "/uzenet";

/* Fejlesztői lapok */

export const PATH_DEVELOPER_PAGES = "/fejlesztoi";

export const PATH_DL_MATERIAL_VIEW = "/letoltheto_tananyag";



{/*HTR ROUTES*/ }

export const PATH_HTR_CALENDAR = "/calendar";

{/*HTR ROUTES*/ }

export let sideBar: any;
export class Routes extends React.Component<{ routes?: any, header?: JSX.Element, footer: JSX.Element, renderPublicLayout?: (content: any) => any, renderAdminLayout?: (content: any) => any }, { routes?: any }> {

  constructor(props: any) {
    super(props);

    this.state = {
    };
  }

  async componentDidMount() {
    const routes = await getRoutes();
    this.setState({ routes });
  }

  renderPublicLayout(content: any) {
    return this.props.renderPublicLayout ? this.props.renderPublicLayout(content) : null
  }
  renderAdminLayout
    (content: any) {
    return this.props.renderAdminLayout ? this.props.renderAdminLayout(content) : null
  }

  render() {

    return <Switch >

      {this.props.children}

      <PublicRoute footer={this.props.footer} path={PATH_IMPRESSUM} component={Impressum} title={__("Impresszum")} />
      {/* Kérdőívek */}
      <Route path={PATH_COURSE_ORGANIZER_SURVEY + "/:survey_id?"} component={SurveyModule} />
      <Route path={PATH_SURVEY_SUMMARY_PAGE + "/:survey_id?"} component={SurveySummaryPage} />
      {/* Publikus tankönyv  */}
      <PublicRoute footer={this.props.footer} path={PATH_PUBLISHED_BOOK_SELECT} component={BookSelectorPage} title={__("Tankönyvek")} />
      <PublicRoute footer={this.props.footer} path={PATH_PUBLISHED_BOOKS + "/:subjectUriSegment?"} component={BookPage}/>

      {/* Publikus letölthető tananyagok  */}
      <PublicRoute footer={this.props.footer} path={PATH_PUBLISHED_DOWNLOADABLE} component={DownloadableMaterials} title={__("Letölthető tananyagok")}/>

      {/* Publikus tankönyv lap */}

      {
        config.mainServer.showBooks
          ?
          <BookRoute path={PATH_PUBLISHED_BOOK + "/:bookUriSegment/:lessonUriSegment?"} exact={true} component={BookView} />
          :
          <BookRoute path={PATH_PUBLISHED_BOOK + "/:bookUriSegment/:lessonUriSegment"} exact={true} component={BookView} />
      }
      <BookRoute path={PATH_PUBLISHED_BOOK_LOWGRADE + "/:bookUriSegment"} exact={true} component={LowGradeBookPage} />


      {/* Publikus kereső */}
      <PublicRoute footer={this.props.footer} path={PATH_PUBLISHED_SEARCH + "/:keywords?"} component={SearchComponent} title={__("Keresés")}/>
      <PublicRoute footer={this.props.footer} path={PATH_PUBLISHED_DETAILED_SEARCH + "/:keywords?"} component={DetailedSearch} title={__("Részletes keresés")}/>
      <PublicRoute footer={this.props.footer} path="/navaSearch/:keywords?" component={NavaSearch} /> {/* <= törölhető? */}
      <PublicRoute footer={this.props.footer} path={PATH_PUBLISHED_EXTERNAL_SEARCH + "/:keywords?"} component={ExternalSearch} />

      <PublicRoute footer={this.props.footer} path={"/layout/:styleId"} component={TemplatePreview} />

      {/* Publikus médiatár */}
      <PublicRoute footer={this.props.footer} path={PATH_PUBLISHED_MEDIA} component={MediaPage} title={__("Médiatár")}/>

      {/* Feladatmotorok */}
      <PublicRoute footer={this.props.footer} path={PATH_ADAPTIVE_EXERCISE_SERIES_EDIT + "/:exerciseSeriesId"} exact={true} component={AdaptiveSeriesEditor} />
      <PublicRoute footer={this.props.footer} path={PATH_ADAPTIVE_EXERCISE_SERIES_CREATE} exact={true} component={AdaptiveSeriesEditor} />


      {/* (publikus?) Feladatmotorok */}
      {/* <Route path={PATH_EXERCISE_LIST} exact={true} component={ContentStore} /> */}
      <PublicRoute footer={this.props.footer} path={PATH_EXERCISE_PUB_LIST + "/:keywords?"} exact={true} component={ExerciseListPublicComponent} title={__("Feladattár")}/>

      <PublicRoute footer={this.props.footer} path={PATH_EXERCISE_ENGINE_SELECT} exact={true} component={ExerciseEngineSelect} />

      <Route path={PATH_EXERCISE_NEW + "/:exerciseEngineId"} exact={true} component={ExerciseEditorProtected} />
      <Route path={PATH_EXERCISE_EDIT + "/:exerciseId"} exact={true} component={ExerciseEditorProtected} />
      <PublicRoute footer={this.props.footer} path={PATH_EXERCISE_VIEW + "/:exerciseId"} exact={true} component={ExerciseShow} />
      <PublicRoute footer={this.props.footer} path={PATH_EXERCISE_TEST} exact={true} component={ExerciseTestPage} />
      <PublicRoute footer={this.props.footer} path={PATH_EXERCISE_PUB_VIEW + "/:exerciseId"} exact={true} component={ExerciseShow} title={__("Feladat megtekintés")}/>

      <PublicRoute footer={this.props.footer} path={PATH_EXERCISE_MORE_HELP_PUB + "/:exerciseId"} exact={true} component={ExerciseHelperLinks} />
      <PublicRoute footer={this.props.footer} path={PATH_EXERCISE_MORE_HELP + "/:exerciseId"} exact={true} component={ExerciseHelperLinks} />

      <Route path={PATH_SURVEY_LIST} exact={true} component={SurveyList} />
      <Route path={PATH_SURVEY_EDIT + "/:surveyId?"} exact={true} component={SurveyEdit} />
      <PublicRoute footer={this.props.footer} path={PATH_EXERCISE_SERIES_EDIT + "/:exerciseSeriesId"} exact={true} component={ExerciseSeriesEditor} />
      <PublicRoute footer={this.props.footer} path={PATH_EXERCISE_SERIES_CREATE} exact={true} component={ExerciseSeriesEditor} />
      <PublicRoute footer={this.props.footer} path={PATH_EXERCISE_SERIES_VIEW + "/:exerciseSeriesId"} exact={true} component={ExerciseSeriesShow} />
      <PublicRoute footer={this.props.footer} path={PATH_EXERCISE_SERIES_PUB_VIEW + "/:exerciseSeriesId"} exact={true} component={ExerciseSeriesShow} />

      {/* <Route path={PATH_LEARNING_PATH} exact={true} component={ContentStore} /> */}

      <Route path={PATH_ADAPTIVE_EXERCISE_SERIES_EDIT + "/:exerciseSeriesId"} exact={true} component={AdaptiveSeriesEditor} />
      <Route path={PATH_ADAPTIVE_EXERCISE_SERIES_CREATE} exact={true} component={AdaptiveSeriesEditor} />

      <Route path={PATH_LEARNING_PATH_CREATE} exact={true} component={LearningPathEditorComponent} />
      <Route path={PATH_LEARNING_PATH_EDIT + "/:learningPathId"} exact={true} component={LearningPathEditorComponent} />
      <Route path={PATH_LEARNING_PATH_VIEW + "/:learningPathId"} exact={true} component={LearningPathContainer} />

      <PublicRoute footer={this.props.footer} path={PATH_DL_MATERIAL_VIEW + "/:id"} exact={true} component={DownloadableMaterialPage} />

      {/* Bejelentkezés után - mindenkinek */}
      <Route path={PATH_PROFILE} exact={true} render={() => <ProfilePageComponent />} />
      <Route path={PATH_PROFILE_TRAINING} exact={true} render={() => <ProfilePageComponent />} />
      <Route path={PATH_PROFILE_INSTITUTE_GROUPS + "/:instituteGroupId"} exact={true} component={InstituteGroupMemberList} />

      <Route path={PATH_CALENDAR_MUNYI_ACTIVITY} exact={true} component={MyMunyiCalendarPage} />
      <Route path={PATH_CALENDAR_MUNYI_APPROVE} exact={true} component={ApproveMunyiCalendarPage} />

      {/* <Route path={PATH_CONTENT_STORE} component={ContentStore} /> */}
      <Route path={PATH_CONTENT + "/:fileId"} component={ContentPermissionPage} />

      <Route path={PATH_OO_EDITOR + "/:fileId"} component={OOEditor} />

      <PublicRoute footer={this.props.footer} path={PATH_SHARED_CONTENT_WITH_ME + "/:sharedContentId"} component={ViewSharedWithMePage} />
      <PublicRoute footer={this.props.footer} path={PATH_SHARED_CONTENT_BY_ME + "/:sharedContentId"} component={ViewSharedByMePage} />
      <PublicRoute footer={this.props.footer} path={PATH_SHARED_CONTENT_RUN_PLAY + "/:sharedContentRunId"} component={RunSharedWithMe} />
      <Route path={PATH_SHARED_CONTENT_RUN_CHECK + "/:sharedContentRunId"} component={RunSharedByMe} />
      <Route path={PATH_SHARED_CONTENT_RUN_CHECK_MINE + "/:sharedContentRunId"} component={RunSharedByMe} />

      <BookRoute path={PATH_BOOK_VIEW_EDITING + "/:bookUriSegment/:lessonUriSegment?"} exact={true} component={BookView} />

      {/* Bejelentkezés után - tankönyv szerkesztőknek*/}
      <Route path={PATH_EDIT_BOOK_LIST} exact={true} component={BookList} />
      <Route path={PATH_EDIT_BOOK_NEW} exact={true} component={BookCreate} />

      <Route path={PATH_SURVEY} exact={true} component={SurveyPage} />

      <Route path={PATH_EDIT_BOOK_TABLE_OF_CONTENTS + "/:bookId"} component={BookStructureEditor} />
      <Route path={PATH_EDIT_BOOK_SECTIONS + "/:bookId/:lessonId?"} component={LessonEditorPageProtected} />
      <Route path={PATH_SECTION_HISTORY + "/:sectionId?"} component={SectionHistoryPageProtected} />

      {/* Bejelentkezés után - szerkesztőknek, fejlesztőknek*/}
      <Route path={PATH_KNOWLEDGE_BASE_LIST} exact={true} component={KnowledgeBaseList} title={__("Tudásbázis")}/>
      <Route path={PATH_KNOWLEDGE_BASE_EDIT + "/:kbItemId?"} exact={true} component={KnowledgeBaseEditPage} />
      <Route path={PATH_KNOWLEDGE_BASE_VIEW + "/:kbItemId?"} exact={true} component={KnowledgeBaseViewPage} />

      {/* Bejelentkezés után - szerkesztőknek, fejlesztőknek*/}
      <Route path={PATH_PROGRESSION_PAGE} exact={true} component={ProgressionPage} />

      {/* CMS */}
      {/* <Route path={PATH_MENU_EDITOR_LIST} exact={true} component={MenuEditorList} />
      <Route path={PATH_MENU_EDIT + "/:menuItem?"} exact={true} component={MenuItemEditor} />
      <Route path={PATH_CMS_CONTENT_LIST + "/:contentTypeId"} exact={true} component={ContentList} />
      <Route path={PATH_CMS_CONTENT_EDIT + "/:contentTypeId/:id?"} exact={true} component={ContentEdit} />
      <PublicRoute footer={this.props.footer} path={PATH_CMS_CONTENT_VIEW + "/:uriSegment?"} exact={true} component={ContentShow} />
      <PublicRoute footer={this.props.footer} path={PATH_CMS_CONTENT_ARTICLES} exact={true} component={ContentArticleListPage} />
      <Route path={PATH_SUBSITE_LIST} exact={true} component={SubsiteList} />
      <Route path={PATH_SUBSITE_EDIT + "/:id?"} exact={true} component={SubsiteEdit} /> */}

      {/* Ticket */}
      <Route path={PATH_TICKETS + "/:ticketCategoryId"} exact={true} component={Tickets} />
      <Route path={PATH_TICKETING_PAGE} component={TicketingPage} />

      <Route path={PATH_HTR_CALENDAR} exact={true} component={CalendarPage} />

      {/*Rendezvényszervezés*/}
      {/* <Route path={PATH_EVENT_LIST} exact={true} component={EventList} />
      <Route path={PATH_EVENT + "/:eventId?"} exact={true} component={EventEdit} />
      <Route path={PATH_EVENT_REGISTRATION + "/:eventUriSegment"} exact={true} component={EventRegistration} /> */}

      {/*KAP hiba bejelentés*/}
      <Route path={PATH_KAP_ERROR_REPORT} exact={true} component={KAPErrorReport} />

      {/* Bejelentkezés után - adminoknak*/}
      <AdminRoute path={ADMIN} exact={true} component={AdminMenu} key="admin" renderLayout={this.props.renderAdminLayout} />
      <AdminRoute path={SYS_ANNOUNCE} exact={true} component={SysAnnounce} key="SysAnnounce" renderLayout={this.props.renderAdminLayout} />
      <AdminRoute path={SYS_DAY} exact={true} component={SysDay} key="SysDay" renderLayout={this.props.renderAdminLayout} />
      <AdminRoute path={PATH_TICKET_CATEGORY_EDITOR} exact={true} component={TicketCategoryEditor} key="TicketCategoryEditor" renderLayout={this.props.renderAdminLayout} />
      {WfRoutes()}
      {SysRoutes()}
      {MetaRoutes()}
      {TicketRoutes()}
      {KbRoutes()}
      {DataImpRoutes()}
      {UsrRoutes()}
      {SpellRoutes()}
      {ArgoRoutes()}
      {LessonplanRoutes()}

      <AdminRoute path="/elastic/indexers" component={ElasticIndexersPage} key="ElasticIndexersPage" renderLayout={this.props.renderAdminLayout} />,

      <Route path="/login" exact={true} render={doLogin} />
      {/* MODULE ROUTES */}
      {
        this.props.routes
          ?
          Object.keys(this.props.routes).map((key, index: number) => {
            return renderRoute(this.props.routes[key]);
          })
          :
          Object.keys(getRoutes()).map((key, index: number) => {
            return renderRoute(getRoutes()[key]);
          })
      }


      {/* Publikus 404 */}
      <Route path="/" component={Error404Page} />

    </Switch>
  }
}

export function doLogin() {
  LocationUtil.save();
  startLogin(history)
    .then(async (authLocal) => {
      onAuthArrived(authLocal);
      history.push("/");
    })
    .catch(reason => {
      console.error(reason);
    });

  return <div>
    {__("Bejelentkezés")}
  </div>;
}


export function renderRoute<T>(route: ModuleRoute<T>, match?: match) {


 

  var Layout: any;
  if (route.layoutKey) {
    Layout = routeLayouts[route.layoutKey];
  }
  else if (routeLayouts['default']) {
    Layout = routeLayouts['default'];
  }


  if (route.hasSidebar && route.menuCode) {
    const moduleMenus = getMenus(route.menuCode);
    const matchName = route.path.indexOf(':') > -1 ? route.path.split(':')[1] : undefined;
    const menuItems: SidebarMenuItem[] | undefined = MapModuleMenuToSidebarMenu(moduleMenus);

    return <RouteWithSidebar sideBarSize={route.sideBarSize} matchName={matchName} key={route.path} sidebarHeader={route.sidebarHeader} sideMenuItems={menuItems!} path={route.path} component={route.component} render={route.render} exact={route.exact}
      renderLayout={(Layout ? (content) => <Layout>{content}</Layout> : undefined)}>
    </RouteWithSidebar>
  }
  else if (route.layoutKey) {

    const Component = route.component;
    return <RouteWithLayout  title={route.title} key={route.path} path={route.path} render={(props) => <Component {...props} siteId={route.props ? route.props.siteId : undefined} subSiteId={route.props ? route.props.subSiteId : undefined}></Component>} exact={route.exact}
      renderLayout={(Layout ? (content) => <Layout siteId={route.props ? route.props.siteId : undefined} subSiteId={route.props ? route.props.subSiteId : undefined}>{content}</Layout> : undefined)}> </RouteWithLayout>
  }
  else {
    return <Route path={route.path} component={route.component} exact={route.exact} key={route.path} />;
  }
}

function MapModuleMenuToSidebarMenu<T>(menu?: ModuleMenu<T>[]): SidebarMenuItem[] | undefined {
  if (menu) {
    return menu.filter(m=>m.enabled != false).map((m: ModuleMenu<T>) => {
      if (m.separator) {
        return { separator: true };
      }
      else {
        return { name: m.title!, iconClass: m.iconClass, linkTo: m.path, subItems: MapModuleMenuToSidebarMenu(m.subMenus), menuItemCode: m.menuItemCode };
      }
    })
  }
  else {
    return undefined;
  }
}

const getParams = (pathname: string, routePath: string) => {
  const match = matchPath(pathname, {
    path: routePath,
  });
  return (match && match.params) || {};
};
export class RouteWithSidebar extends Route<{ sidebarHeader: string | JSX.Element | undefined, sideBarSize?: number, sideMenuItems: SidebarMenuItem[], renderLayout?: (content: any) => any, matchName?: string } & RouteProps> {

  sidebarOpen =  window.screen.width < 800 ? false : true;
  sidebarToggle(isOpen: boolean) {
    this.sidebarOpen = isOpen;
    this.forceUpdate();
  }

  componentDidMount()
  {
    sideBar = (this.refs.sideBar as Sidebar);
  }

  render() {

    if (this.props.path && typeof (this.props.path) == "string") {
      const match = getParams(this.props.location!.pathname, this.props.path);
      var matchParam;
      if (this.props.matchName) {
        //matchParam = match[this.props.matchName!];
      }

    }

    const content = (<div className="eke-content-wrapper" style={{ position: "relative", height: "100%" }}>
      <SplitPane key="splitpane" allowResize={this.sidebarOpen ? true : false} split="vertical" minSize={50} maxSize={-150} size={this.sidebarOpen ? (this.props.sideBarSize ? this.props.sideBarSize : 350) : 50} defaultSize={this.sidebarOpen ? (this.props.sideBarSize ? this.props.sideBarSize : 350) : 50} style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", overflow: "hidden" }}>
        <Sidebar ref="sideBar" key="sidebar" defaultClose={!this.sidebarOpen} onToggle={this.sidebarToggle.bind(this)} closable={true} matchParam={matchParam} title={this.props.sidebarHeader} menuItems={this.props.sideMenuItems} >
        </Sidebar>

        <div className="content-wrapper">
          {super.render()}
        </div>
      </SplitPane>
    </div>)
    return (
      this.props.renderLayout ?
        this.props.renderLayout(content)
        : content
    )
  }
}
export class RouteWithLayout extends Route<{ footer?: JSX.Element, title?: string, renderLayout?: (content: any) => any } & RouteProps> {

  componentDidMount() {
    this.props.title ? document.title = this.props.title : "";
  }
  componentDidUpdate(prevprops: any) {
    if(this.props.title && document.title != this.props.title) {
      document.title = this.props.title;
    }
  }

  render() {
    const content = (
      <>
        {super.render()}
      </>
    )
    return (
      this.props.renderLayout ?
        this.props.renderLayout(content)
        : content
    )
  }
}
export default class AdminRoute extends Route<{ renderLayout?: (content: any) => any } & RouteProps> {

  render() {

    const content = (<div className="eke-content-wrapper admin">
      <SplitPane split="vertical" minSize={150} maxSize={-150} defaultSize={350} style={{ position: "relative", minHeight: "calc(100vh - 46px)", maxHeight: "calc(100vh - 46px)" }}>
        <Sidebar>
          <h4> <Link to={ADMIN}>Adminisztráció</Link></h4>
          <AdminMenu type="sidemenu" />
        </Sidebar>

        <div className="content-wrapper">
          {super.render()}
        </div>
      </SplitPane>
    </div>)

    return (

      this.props.renderLayout ?
        this.props.renderLayout(content)
        : content

    )
  }
}

export class PublicRoute extends Route<{ footer: JSX.Element, renderLayout?: (content: any) => any, title?: string } & RouteProps> {

  componentDidMount() {
    this.props.title ? document.title = this.props.title : "";
  }
  componentDidUpdate(prevprops: any) {
    if(this.props.title && document.title != this.props.title) {
      document.title = this.props.title;
    }
  }

  render() {
    const content = (
      <>
        <div className="eke-content-wrapper">
          {super.render()}
        </div>
        {this.props.footer}
      </>
    )
    return (
      this.props.renderLayout ?
        this.props.renderLayout(content)
        : content
    )
  }
}

class BookRoute extends Route {

  render() {
    return (
      <>
        {super.render()}
      </>
    )
  }
}

export class PublicLayout extends React.Component<{footer?: JSX.Element}> {
  render() {
    return (
      <>
        <div className="eke-content-wrapper">
          {this.props.children}
        </div>
        {this.props.footer}
      </>

    )
  }
}
export class AdminLayout extends React.Component {
  render() {
    return (
      <>
        {this.props.children}
      </>

    )
  }
}

export let routeLayouts: Record<string, any> = {
  "public": PublicLayout,
  "admin" : AdminLayout,
  "book": PublicLayout
};

export function getPath(path:object, module?: object)
{
  var name = Object.keys(path)[0];
  var value = path[name];

  if(module && module[name])
  {
    value = module[name];
  }
  return value;
}
