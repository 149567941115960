import * as React from 'react';
import { Link, match } from 'react-router-dom';
import 'react-table/react-table.css';
import { app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import ViewItem, { IViewItemRecord } from '@src/framework/view/kb/ViewItem';
import { fetchKeywords } from '../meta/meta_keyword_assigner_plugin';
import ItemCrud from '@src/framework/crud/kb/ItemCrud';
import { getFileThumbnailComponent } from '../filemanager/MediaFileChooser';
import { PATH_KNOWLEDGE_BASE_LIST } from '@src/Routes';
import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import ViewKbItemType, { IViewKbItemTypeRecord } from '@src/framework/view/kb/ViewKbItemType';
import { formatUserName, formatDate } from '@src/Util';
import { __ } from '@src/translation';

declare var MathJax:any;
class KnowledgeBaseViewState {
  itemTypes?: IViewKbItemTypeRecord[];
  knowledgeBaseRecord?: IViewItemRecord;
  keywords: string[];
}

export type KnowledgeBaseViewProps = {
  match: match<{ kbItemId?: string }>
}

export default class KnowledgeBaseViewPage extends React.Component<KnowledgeBaseViewProps, KnowledgeBaseViewState> {
  constructor(props: any) {
    super(props);
    this.state = {
      keywords: []
    };
  }

  private getKbItemId = (): number => {
    return parseInt(this.props.match.params.kbItemId!);
  }
  componentDidUpdate()
  {
    try {
      MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  } catch (e) {
      console.log(e);
  }
  }
  componentDidMount() {
    this.asyncReload();
  }
  private asyncReload = async () => {
    
    try {
      this.loadKeywords();
      var knowledgeBaseRecord = (await ViewItem.load(this.getKbItemId())).record;
      var itemTypes = await (ViewKbItemType.list({ filter: { is_active: true } }));

      this.setState({
        knowledgeBaseRecord,
        itemTypes
      });

    } catch (error) {
      app.showErrorFromJsonResult(error);
    }
  }

  private loadKeywords = async () => {
    try {
      const keywords = await fetchKeywords(ItemCrud.TABLE_INFO_ID, this.getKbItemId());
      this.setState({ keywords });
    } catch (error) {
      app.showErrorFromJsonResult(error);
    }
  }

  render() {

    if (this.state.knowledgeBaseRecord) {
      return (<div>
        <div className="row" style={{ minWidth: "1024px" }}>
          <div className="column small-12">
            <br />
            <p><Link to={PATH_KNOWLEDGE_BASE_LIST}>{__("Tudásbázis")}</Link> / {this.state.knowledgeBaseRecord.item_type_title} / {this.state.knowledgeBaseRecord.subject}</p>
            <h4>::{this.state.knowledgeBaseRecord.item_type_title}</h4>
            <h5>{this.state.knowledgeBaseRecord.subject}</h5>
            <div dangerouslySetInnerHTML={{ __html: this.state.knowledgeBaseRecord.description || "" }} />
            <FileList folderId={this.state.knowledgeBaseRecord.oo_folder_id || -1} needTitle={false} />

            <p style={{ textAlign: "right" }}>
              {
                this.state.knowledgeBaseRecord.modification_time
                  ?
                  formatUserName(this.state.knowledgeBaseRecord.modifier) + ", " + formatDate(this.state.knowledgeBaseRecord.modification_time)
                  :
                  formatUserName(this.state.knowledgeBaseRecord.creator) + ", " + formatDate(this.state.knowledgeBaseRecord.creation_time)
              }
            </p>
            <br /><br /><br /><br />
          </div>
        </div>
      </div>
      );

    } else {
      return <div><BubbleLoader /></div>
    }
  }
};
class FileList extends React.Component<{ folderId: number, needTitle: boolean }, { files: IOoFileRecord[] }> {

  constructor(props: any) {
    super(props);

    this.state = {
      files: [],
    }
  }

  async componentDidMount() {
    const files = await OoFileCrud.list({
      filter: {
        is_active: true,
        oo_folder_id: this.props.folderId
      }
    });
    this.setState({
      files
    })
  }

  render() {
    return <div>
      {this.props.needTitle && this.state.files && this.state.files.length > 0
        ? <div className="column small-12">
          <h5>
            {__("Mellékletek")}:
            </h5>
        </div>
        : null
      }
      <div className="small-12 column">
        <div className="lp-wrapper">
          {
            this.state.files.map((f, index) => {
              return <span title={f.title} key={index} className={"extension-unit-wrapper"}>
                <a data-lightbox={f.has_thumb ? true : undefined} download={f.title} href={"/api/media/file/" + f.sha1}>
                  {getFileThumbnailComponent(f.sha1!, f.has_thumb!, f.ext!, true, false, undefined, false)}
                </a>

                <span className="image-selector__name">{f.title}</span></span>
            })
          }
        </div>
      </div>
    </div>
      ;
  }
}