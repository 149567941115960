import { IViewTransitionTypeRecord } from '@framework/view/wf/ViewTransitionType';
import { IViewWfWorkflowRecord } from './../view/wf/ViewWfWorkflow';
import { Server } from '@framework/server/Server';

/**
 * A listPossibleTransitions() metódus visszatérési értéke tartalmazza ezt az egy plusz
 * oszlopot ami megmondja, hogy az aktuális felhasználónak van-e rá joga vagy
 * nincs.
 */
export interface IViewTransitionTypeRecordWithPerm extends IViewTransitionTypeRecord {
    has_perm: boolean;
}

/**
 * Workflow tennivaló lista típusa.
 * 
 * Ez egy összesítés típusonként a tennivalókról.
 * 
 */export interface IWorfklowTODOSummaryRecord {
    /* Ez a kettő a kulcs */
    wf_type_id: number;
    station_id: number;
    /* Ez a lényeg: hány ilyen van. */
    cnt: number;
    /* Ezek csak leíró jellegűek. */
    wf_type_code: string;
    wf_type_name: string;
    station_is_active: boolean;
    station_no: number;
    station_name: string;
    station_description: string;
    station_style: any; /* css style object */
    is_start: boolean;
    is_eligible: boolean;
    // is_closed itt nincs, mert ami le van zárva az nem lehet TODO-ban...
}

/* Indítás esemény típus */
export type WfStartEvent = (workflow: IViewWfWorkflowRecord) => void;
/* Átmenet esemény típus */
export type WfTransitionEvent = (
    workflowId: number,
    transitonTypeId: number,
    justification: string | null,
    workflowAfterTransition: IViewWfWorkflowRecord
) => void;
/* Felelős változtatás esemény típus */
export type WfAgentChangedEvent = (workflow: IViewWfWorkflowRecord, permOwnerId: number) => void;


const PERMISSION_CACHE_TTL: number = 30000; // Állapot alapú jogosultság cache max. tárolási ideje 30 sec.

/**
 * Állapot függő jogot reprezentál az aktuális felhasználóra.
 */
export type SecStationPerm = {
    can_start: boolean; // Elindíthat folyamatot ezzel az állapottal
    can_update_master: boolean; // Módosíthatja a fő rekrodot ebben az állapotban
    can_create_detail: boolean; // Tétel beszúrás
    can_update_detail: boolean; // Tétel módosítás
    can_delete_detail: boolean; // Tétel törlés
    can_comment: boolean;       // Megjegyzés hozzáadása
    can_update_substation: boolean; // Al-státusz módosítása
    can_update_status_text: boolean; // Szöveges állapot kiegészítés módosítása
    can_publish?: boolean; // Publikálás
    can_unpublish?: boolean; // Publikálás visszavonása

    station_id: number;
    station_name: string;
    station_no: number;
    station_is_active: boolean; // Az API listázza az inaktív állomások jogait is, ez csak egy kis segítség hogy ki tudd szűrni az aktívakat.
}

export type SecStationPermissions = { [station_id: number]: SecStationPerm };

type SecStationPermCacheItem = {
    created: number;
    value: SecStationPermissions;
}

type TSecStationPermCache = { [wf_type_id: number]: SecStationPermCacheItem };

export type WfPublicationHistoryRecord = {
    arch_id : number; // A publikáció archívjának belső azonosítója
    creation_time : string; /* timestamp */
    creation_user_id : number; // Publikáló felhasználó azonosítója
    creation_session_id: number; // A publikálást ebből a munkamenetből végezték
    arch_date : string; /* timestamp */
    login_name: string; // Publikáló felhasználó neve
    fullname: string; // Publikáló felhasználó teljes neve
}

export type WfPublicationInfo = {
    publication_supported : boolean; // Ha ez hamis, akkor ez a workflow nem támogatja a publikációt.
    is_published : boolean; // Ha ez igaz, akkor jelenleg éppen publikálva van.
    history ?: WfPublicationHistoryRecord[]; // Publikációs történet, időben visszafelé haladva
                                             // Csak akkor elérhető, ha publication_supported
}

/**
 * Workflow API
 * 
 */
export default class WfAPI {
    server: Server;
    private static secStationPermCache: TSecStationPermCache = {};

    private static startListeners: WfStartEvent[] = [];
    private static transitionListeners: WfTransitionEvent[] = [];
    private static agentAddedListeners: WfAgentChangedEvent[] = [];
    private static agentRemovedListeners: WfAgentChangedEvent[] = [];


    public static addStartListener = (listener: WfStartEvent) => {
        WfAPI.startListeners.push(listener);
    }

    public static removeStartListener = (listener: WfStartEvent) => {
        let index = WfAPI.startListeners.indexOf(listener);
        if (index >= 0) {
            WfAPI.startListeners.slice(index, 1);
        }
    }

    public static addTransitionListener = (listener: WfTransitionEvent) => {
        WfAPI.transitionListeners.push(listener);
    }

    public static removeTransitionListener = (listener: WfTransitionEvent) => {
        let index = WfAPI.transitionListeners.indexOf(listener);
        if (index >= 0) {
            WfAPI.transitionListeners.slice(index, 1);
        }
    }

    public static addAgentAddedListener = (listener: WfAgentChangedEvent) => {
        WfAPI.agentAddedListeners.push(listener);
    }

    public static removeAgentAddedListener = (listener: WfAgentChangedEvent) => {
        let index = WfAPI.agentAddedListeners.indexOf(listener);
        if (index >= 0) {
            WfAPI.agentAddedListeners.slice(index, 1);
        }
    }


    public static addAgentRemovedListener = (listener: WfAgentChangedEvent) => {
        WfAPI.agentRemovedListeners.push(listener);
    }

    public static removeAgentRemovedListener = (listener: WfAgentChangedEvent) => {
        let index = WfAPI.agentRemovedListeners.indexOf(listener);
        if (index >= 0) {
            WfAPI.agentRemovedListeners.slice(index, 1);
        }
    }

    public static notifyAgentAdded = (workflow: IViewWfWorkflowRecord, permOwnerId: number) => {
        WfAPI.agentAddedListeners.forEach((listener) => {
            listener(workflow, permOwnerId)
        });
    }

    public static notifyAgentRemoved = (workflow: IViewWfWorkflowRecord, permOwnerId: number) => {
        WfAPI.agentRemovedListeners.forEach((listener) => {
            listener(workflow, permOwnerId)
        });
    }

    constructor(server: Server) {
        this.server = server;
    }

    /**
     * Új workflow indítása azonosítók megadásával.
     * 
     * @param headTableId a fejtáblázat azonosítója
     * @param recordId a rekord azonosítója
     * @param stationId Keződállapot. Ha nincs megadva, akkor a workflow típushoz
     *  csak egyetlen egy aktív kezdőállapot lehet, és ez lesz használva.
     */
    public async startWorkflowRaw(headTableId: number, recordId: number, stationId?: number): Promise<IViewWfWorkflowRecord> {
        return this.server.post<IViewWfWorkflowRecord>(
            'wf',
            {
                operation: 'start',
                head_table_id: headTableId,
                rec_id: recordId,
                station_id: stationId
            }
        ).then(
            (workflow) => {
                WfAPI.startListeners.forEach((listener) => { listener(workflow) });
                return workflow;
            }
        );
    }

    /**
     * 
     * Új workflow indítása típuskód megadásával.
     * 
     * @param wfTypeCode A workflow típuskódja
     * @param tableInfoId Táblázat azonosítója (amihez a workflow kapcsolódik),
     *    például: BookCrud.TABLE_INFO_ID
     * @param recordId Rekord azonosítója (amihez a workflow kapcsolódik)
     * @param stationId Keződállapot. Ha nincs megadva, akkor a workflow típushoz
     *  csak egyetlen egy aktív kezdőállapot lehet, és ez lesz használva.
     */
    public async startWorkflow(wfTypeCode: string, tableInfoId: number, recordId: number, stationId?: number): Promise<IViewWfWorkflowRecord> {
        return this.server.post<IViewWfWorkflowRecord>(
            'wf',
            {
                operation: 'start',
                wf_type_code: wfTypeCode,
                table_info_id: tableInfoId,
                rec_id: recordId,
                station_id: stationId
            }
        ).then(
            (workflow) => {
                WfAPI.startListeners.forEach((listener) => { listener(workflow) });
                return workflow;
            }
        );
    }


    /**
     * Workflow átmenet elvégzése
     * 
     * @param workflowId A workflow azonosítója
     * @param transitonTypeId Az átmenet típus azonosítója
     * @param justification Az átmenet indoklása (ha van)
     */
    public async makeTransition(workflowId: number, transitonTypeId: number, justification?: string | null): Promise<IViewWfWorkflowRecord> {
        return this.server.post<IViewWfWorkflowRecord>(
            'wf',
            {
                operation: 'transition',
                workflow_id: workflowId,
                transition_type_id: transitonTypeId,
                justification: justification
            }
        ).then(
            (workflow) => {
                WfAPI.transitionListeners.forEach((listener) => { listener(workflowId, transitonTypeId, justification || null, workflow) });
                return workflow;
            }
        );
    }

    /**
     * Workflow megjegyzés hozzáadása
     * 
     * @param workflowId A workflow azonosítója
     * @param justification Az átmenet indoklása (ha van)
     */
    public async addComment(workflowId: number, justification?: string | null): Promise<IViewWfWorkflowRecord> {
        return this.server.post<IViewWfWorkflowRecord>(
            'wf',
            {
                operation: 'comment',
                workflow_id: workflowId,
                justification: justification
            }
        );
    }

    /**
     * Workflow szöveges állapot kiegészítés módosítása
     * 
     */
    public async updateStatusText(workflowId: number, statusText: string | null): Promise<IViewWfWorkflowRecord> {
        return this.server.post<IViewWfWorkflowRecord>(
            'wf',
            {
                operation: 'update_status_text',
                workflow_id: workflowId,
                status_text: statusText
            }
        );
    }

    /**
 * Workflow al-státusz módosítása
 * 
 */
    public async updateSubstation(workflowId: number, substationId: number | null): Promise<IViewWfWorkflowRecord> {
        return this.server.post<IViewWfWorkflowRecord>(
            'wf',
            {
                operation: 'update_substation',
                workflow_id: workflowId,
                substation_id: substationId
            }
        );
    }


    /* 
    
      Egy workflow-hoz tartozó lehetséges átmeneteket adja meg.
      A lehetséges átmenet nem feltétlenül hajtható végre az aktuális felhasználó által.
      A végrehajthatóságot a IViewTransitionTypeRecordWithPerm.has_perm határozza meg.
      

    */
    public async listPossibleTransitions(workflowId: number): Promise<IViewTransitionTypeRecordWithPerm[]> {
        return this.server.post<IViewTransitionTypeRecordWithPerm[]>(
            'wf',
            {
                operation: 'list_possible_transitions',
                workflow_id: workflowId,
            }
        );
    }

    /*

        Információkat ad a workflow publikációjáról.

    */
    public async getPublicationInfo(workflowId : number) : Promise<WfPublicationInfo> {
        return this.server.post<WfPublicationInfo>(
            'wf', { operation: "get_publication_info", workflow_id: workflowId }
        );        
    }

    /*
        Publikálja (vagy újrapublikálja) a tartalmat.
        A visszatérési értéke ugyan az, mint a getPublicationInfo-nak
        az (újra)publikálás elvégzése után.
    */
    public async publish(workflowId : number) : Promise<WfPublicationInfo> {
        return this.server.post<WfPublicationInfo>(
            'wf', { operation: "publish", workflow_id: workflowId }
        );        
    }

    /*
        Törli a tartalom publikációját.
        A visszatérési értéke ugyan az, mint a getPublicationInfo-nak
        az (újra)publikálás elvégzése után.
    */
   public async unpublish(workflowId : number) : Promise<WfPublicationInfo> {
    return this.server.post<WfPublicationInfo>(
        'wf', { operation: "unpublish", workflow_id: workflowId }
    );
   }

    public async forcePublishBook(workflowId : number) : Promise<WfPublicationInfo> {
        return this.server.post<WfPublicationInfo>(
            'wf', { operation: "force_publish_book", workflow_id: workflowId }
        );        
    }
         
    public async forcePublishExercise(workflowId : number) : Promise<WfPublicationInfo> {
        return this.server.post<WfPublicationInfo>(
            'wf', { operation: "force_publish_exercise", workflow_id: workflowId }
        );        
    }

    public async forcePublishExerciseSeries(workflowId : number) : Promise<WfPublicationInfo> {
        return this.server.post<WfPublicationInfo>(
            'wf', { operation: "force_publish_exercise_series", workflow_id: workflowId }
        );        
    }    

    /* 
    
      Az aktuálisan bejelentkezett felhasználó tennivalóinak összesítőjét adja meg.
      A header notification area-ban van használva.
      

    */
    public async listTODOSummary(): Promise<IWorfklowTODOSummaryRecord[]> {
        return this.server.post<IWorfklowTODOSummaryRecord[]>(
            'wf', { operation: 'list_todo_summary' });
    }

    /*
        Egy megadott workflow típushoz tartozó állapot alapú jogok lekérése
        az aktuálisan bejelentkezett felhasználóhoz. Az eredmény gyorsítótárazva
        van, ezért sokszor egymás után meg lehet hívni anélkül, hogy sokat kellene
        rá várakozni.

    */
    public async getStationPermissions(wfTypeId: number): Promise<SecStationPermissions> {
        try {
            let item = WfAPI.secStationPermCache[wfTypeId];
            if (item) {
                const now = (new Date).getTime();
                const expired = item.created + PERMISSION_CACHE_TTL < now;
                if (!expired) {
                    return Promise.resolve(item.value);
                }
            }
            const value = await WfAPI._fetchStationPermissions(this.server, wfTypeId);
            const created = (new Date).getTime();
            WfAPI.secStationPermCache[wfTypeId] = { created, value };
            return Promise.resolve(value);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    private static async  _fetchStationPermissions(server: Server, wfTypeId: number): Promise<SecStationPermissions> {
        let items = await server.post<SecStationPerm[]>(
            'wf', { operation: "get_station_permissions", wf_type_id: wfTypeId }
        );
        let result: SecStationPermissions = {};
        items.forEach((item: SecStationPerm) => result[item.station_id] = item);
        return result;
    }



}
