import * as React from 'react';
import { me, hasAnyGroup, Groups } from '@src/framework/server/Auth';
import { customDialog, DialogType } from '@src/component/Dialog';
import { isKSZREnabled } from '../KAPPermissions';
import { Link } from 'react-router-dom';
import { PATH_PROFILE_TRAINING } from '@src/Routes';
import { __ } from '@src/translation';
import { courseModule } from './courseModule';
import { app } from '@src/index';
import KszrQuestionnarieInCrud from '@src/framework/crud/kap/KszrQuestionnarieInCrud';

type CourseProps = {};

type CourseState = {
    error: boolean;
    errorMessage: string;
    showDownloadableContent: boolean;
    showRegistrationLink: boolean;
    showQuestionnarieIn?: boolean;
};

export class CourseOrganizer extends React.Component<CourseProps, CourseState> {
    constructor(props: CourseProps) {
        super(props);

        this.state = { 
            error: false, 
            errorMessage: "", 
            showDownloadableContent: false, 
            showRegistrationLink: true,
        };
    }
    async componentDidMount() {
        try {
            if (me) {
                //if (hasAnyGroup(me, [Groups.Developer, Groups.Admin, Groups.KapKszrCertifiedTeacher, Groups.KapKszrCourseCoordinator, Groups.KapKszrCourseTeacher, Groups.KapKszrParticipantTeacher, Groups.KapKszrOhInspector, Groups.KapKszrReporter])) {
                if(isKSZREnabled()){
                    
                    this.setState({ error: false, errorMessage: "",showRegistrationLink: false })

                    let questionarieList = await KszrQuestionnarieInCrud.list({ filter: { sec_user_id: me.id } });
                    let showQuestionnarieIn = ((hasAnyGroup(me, [Groups.KapKszrParticipantTeacher])) && questionarieList.length < 1);
        
                    this.setState({showQuestionnarieIn: showQuestionnarieIn })
                } else {
                    this.setState({ error: false, errorMessage: "", showRegistrationLink: true });
                }
            } else {
                var message: string = "";
                if (!me) message = "A képzésszervező rendszer használatához, először be kell jelentkeznie!"
                else message = "Önnek nincs jogosultsága a képzésszervező rendszerhez!"
                this.setState({ error: true, errorMessage: message, showRegistrationLink: false });
                customDialog("FIGYELEM", message, DialogType.Error, false, true, "300px");
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {

        if (!me || !me.id) return "";

        let style: React.CSSProperties = { textAlign: "justify" };
        
            return <div className="row"><div className="columns large-10 large-offset-1">
                <h3>{__("Képzésszervező rendszer")}</h3>
                {
                    this.state.showQuestionnarieIn !== undefined &&
                        !this.state.showRegistrationLink &&
                        hasAnyGroup(me, [Groups.KapKszrParticipantTeacher])
                    ?
                        this.state.showQuestionnarieIn
                        ?
                            <div className="row column"> Maradt egy kitöltendő kérdőíve:
                                <div className="small-12">
                                    <a style={{ color: "white" }} href={courseModule.PATH_COURSE_ORGANIZER_QUESTIONARIE_IN}>
                                        <div className="shrink button small secondary">
                                            <i className="fa fa-edit"></i>&nbsp;Kitöltés
                                    </div>
                                    </a>
                                </div>
                            </div>
                        :
                            <div className="row column"> Kitöltött regisztrációs kérdőív:
                                <div className="small-12">
                                    <a style={{ color: "white" }} href={"/api/kap/course?type=questionnaire&format=pdf&sec_user_id=" + me.id}>
                                        <div className="shrink button small secondary">
                                            <i className="fa fa-download"></i>&nbsp;Letöltés
                                    </div>
                                    </a>
                                </div>
                            </div>
                    :
                    null
                }

                {
                    this.state.showRegistrationLink &&
                    <div>
                        <p>A kurzusokra való jelentkezést előbb kérvényeznie kell. Az alábbi gombra kattintva megadhatja az ehhez szükséges adatokat.</p>
                        <Link to={PATH_PROFILE_TRAINING} className="button">Jelentkezéshez szükséges adatok kitöltése</Link>
                    </div>
                }
                <p style={style}>A Képzésszervezői rendszer az <em>EFOP-3.1.2-16-2016-00001, A köznevelés módszertani megújítása a végzettség nélküli iskolaelhagyás csökkentése céljából </em>&nbsp;című kiemelt projekt keretében készült el.</p>
                <p style={style}>A portál a Komplex Alapprogram (KAP) adatnyilvántartását és képzésszervezését támogató, valamint a Pedagógusok, Képzésszervezők, Trénerek és Képzők (továbbiakban felhasználók) munkáját az informatikai oldalról segítő rendszer.</p>
                <p style={style}>A felület célja, hogy az Eszterházy Károly Egyetem által nyújtott KAP akkreditált pedagógus-továbbképzésekről információt nyújtson, biztosítsa a képzésekre történő jelentkezést, a meghirdetett és induló továbbképzésekről tájékoztatást adjon.</p>
                <p style={style}>Célja továbbá a rendszernek a jelentkezőkkel, illetve résztvevőkkel kapcsolatos adminisztrációs folyamatok, képzések szervezésének, lebonyolításának támogatása és az eközben keletkezett adathalmazból statisztikák előállítása, melyek hasznos információval szolgálnak a Komplex Alapprogrami bevezetéséhez a köznevelési intézményekben.</p>
                <p style={style}>A Képzésszervező kizárólag az interneten keresztül elérhető nyilvános felület. Használatát a legnépszerűbb böngészőkkel – Google Chrome, Mozilla Firefox, MS Internet Explorer – készítettük el, így teljes funkcionalitású használatához ezeket – ebben a sorrendben – ajánljuk.</p>
                <p style={style}>A rendszer teljesen nyilvános, az abban tárolt továbbképzésekkel kapcsolatos adatokat a világhálón keresztül bárki elérheti, de csak regisztrált felhasználók tölthetnek fel adatokat, jelentkezhetnek képzésekre, vehetnek részt az akkreditált továbbképzések kurzusain.</p>
                <p style={style}>A regisztrált és bejelentkezettek részére kialakított publikus felületen a felhasználók saját profilt tölthetnek fel, továbbképzéseket listázhatnak, továbbképzésekre jelentkezhetnek, a képzésekre történő jelentkezéshez szükséges dokumentumokat tölthetnek fel.</p>
                <p style={style}>A Tréner és Képzői jogosultsággal rendelkezők a felületen keresztül érhetik el a hozzájuk rendelt képzéseket, az arra jelentkezettek listáját, résztvevők feltöltött dokumentumait, valamint képzés eredményeket rögzíthetnek, megtekinthetik és kitölthetik az elégedettségi kérdőívet.</p>
                <p style={style}>A kitöltés, feltöltés során felmerülő kérdéseiket, megjegyzéseiket, esetleges kiegészítési igényüket az <strong>kapkepzes@uni-eszterhazy.hu</strong> e-mail címen keresztül juttathatják el hozzánk.</p>
               
            </div></div>

    }

}

