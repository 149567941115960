import * as React from 'react';
import * as _ from 'lodash';
import { BubbleLoader } from 'react-css-loaders';

import { atou, utoa } from '@framework/util/StringUtils';
import { IRecord as IViewRecord, ViewClassProxy } from '@framework/view/View';
import { getCrudClassProxyById, CrudClassProxy, IRecord as ICrudRecord, TSpecFilterDict, TFilterDict, IListOrderBy } from '@framework/crud/Crud';
import ReactTable, { TableProps, Column, TableCellRenderer, RowInfo } from 'react-table'
import 'react-table/react-table.css'
import { getReactTableLabels } from '@framework/i18n';
import { app } from '@src/index';
import { Link } from 'react-router-dom';
import './listview.css';
import { IViewInfo, IViewColumnInfo, IViewColumnInfoTranslation } from '@framework/view/Meta';
import { history } from '@src/index';
import { getTableInfoByName, ITableInfo } from '@framework/crud/Meta';
import InplaceListEditor from '@src/framework/forms/inplace_listeditor';
import ViewFilter from './viewfilter';
import { __ } from '@src/translation';
import DatePicker from './datetimepicker';
import { Accordion, AccordionItem } from '@src/component/ui/Accordion';

const LANG_ID_HU = 3432150755;
const ASYNC_RELOAD_DEBOUNCE_MSEC = 1000;

export interface IMinMaxValue {
    minValue?: any;
    maxValue?: any;
}

export interface ListViewColumn {
    accessor: string;
    minWidth?: number;
    maxWidth?: number;
    style?: object;
    Header?: TableCellRenderer;
    Cell?: TableCellRenderer;
}

export interface ListViewProps extends TableProps {
    match?: any;
    filter?: TFilterDict;
    spec?: TSpecFilterDict;
    lang_id?: number;
    viewColumns?: ListViewColumn[];
    allowInsertNew?: boolean;
    allowDownloadExcel?: boolean;
    inplaceListEditor?: InplaceListEditor;
    title?: string;
    showRowDetails?:boolean;
}

export interface ListViewState<T extends IViewRecord | ICrudRecord> {
    items?: T[];
    struct?: IViewInfo;
    tableInfo?: ITableInfo | null,
    crudClassProxy?: CrudClassProxy<T> | null;
    others?: any; // This is a hack! See https://stackoverflow.com/questions/32866534/extending-react-components-in-typescript
    filterValues?: TSpecFilterDict;
    filterNullColumns: string[];
    filterNotNullColumns: string[];
    filterMinValues: TSpecFilterDict;
    filterMaxValues: TSpecFilterDict;
    spec?: TSpecFilterDict;
    showFilterPanel?: boolean;
    reloading: boolean;
    showPasswordModal: boolean;
    selectedItem:T|undefined;
}

export default abstract class ListView<TRecord extends IViewRecord>
    extends React.Component<Partial<ListViewProps>, ListViewState<TRecord>> {

    protected columnInfoByName: Map<String, IViewColumnInfo>;
    private debouncedAsyncReload: any = undefined;

    public abstract getViewClassProxy(): ViewClassProxy<TRecord>;

    constructor(props: any) {
        super(props);
        this.state = {
            filterValues: undefined, reloading: false, showPasswordModal: false,
            filterMinValues: {}, filterMaxValues: {}, filterNullColumns: [], filterNotNullColumns: [],
            selectedItem:undefined
        }
        this.debouncedAsyncReload = _.debounce(
            this.asyncReload, ASYNC_RELOAD_DEBOUNCE_MSEC
        )
        this.asyncInit();
    }

    private asyncInit = async () => {
        let struct = await this.queryStruct();
        let tableInfo: ITableInfo | null = null;
        let crudClassProxy: CrudClassProxy<TRecord> | null = null; // <>;
        if (struct && struct.table_name) {
            tableInfo = await getTableInfoByName(struct.schema_name, struct.table_name);
            crudClassProxy = getCrudClassProxyById(tableInfo.id) as CrudClassProxy<TRecord>;
            if (!crudClassProxy)
                console.log("ListView.asyncInit: crudClassProxy is undefined, did you force-import the CRUD class for this view?", struct);
        }
        let filterValues: TSpecFilterDict = this.extractFilters();
        let specValues = this.extractSpecial();
        this.setState({
            struct: struct,
            tableInfo: tableInfo,
            crudClassProxy: crudClassProxy,
            filterValues: filterValues,
            spec: specValues
        }, this.asyncReload);
    }

    private async queryStruct(): Promise<IViewInfo> {
        let struct = await this.getViewClassProxy().getViewInfoForClass();
        this.columnInfoByName = new Map<String, IViewColumnInfo>();
        let columns = struct.columns;
        for (let i = 0; i < columns.length; i++)
            this.columnInfoByName.set(
                columns[i].column_name,
                columns[i]
            );
        return struct;
    }

    protected findRefFields(): IViewColumnInfo[] {
        let cols: IViewColumnInfo[] = [];
        this.columnInfoByName.forEach((value, key, map) => {
            if (value.fk_table_info_id) {
                cols.push(value);
            }
        });
        return cols;
    }

    private extractParams = () => {
        let params = this.getMatchParam('params');
        params = params ? JSON.parse(atou(params)) : {};
        return params;
    }

    /** Extract filters from the route and apply them */
    private extractFilters = (): TSpecFilterDict => {
        let filterParams: any;
        if (this.props.filter) {
            filterParams = this.props.filter;
        } else {
            let params = this.extractParams();
            if (params && params['filter']) {
                filterParams = params['filter'];
            }
        }
        let filterValues = {};
        if (filterParams) {
            this.columnInfoByName.forEach((columnInfo, key, map) => {
                //if (columnInfo.fk_table_info_id) {
                const columnValue = filterParams[columnInfo.column_name];
                if (columnValue !== undefined) {
                    filterValues[columnInfo.column_name] = columnValue;
                }
                //}
            });
        }
        return filterValues;
    }

    /** Extract filters from the route and apply them */
    private extractSpecial = (): TSpecFilterDict => {
        let specParams: any;
        if (this.props.spec) {
            return this.props.spec;
        } else {
            let params = this.extractParams();
            if (params && params['spec']) {
                return params['spec'];
            } else {
                return {};
            }
        }
    }

    public asyncReload = async () => {
        return this.internalAsyncReload();
    }


    protected async internalAsyncReload() {
        this.getViewClassProxy().list({ filter: this.getFilter(), spec: this.getSpecFilter(), order_by: this.getOrderBy() })
            .then((items) => this.setState({ items: this.convertItems(items), reloading: false }))
            .catch((error) => app.showErrorFromJsonResult(error));
    }

    public reloadRow = async (rowIdx:number) => {
        try {
            let rec = await this.getViewClassProxy().load(this.state.items![rowIdx].id!);
            if (rec) {
                const items = this.state.items!.concat();
                items[rowIdx] = rec;
                this.setState({items});
            } else {
                app.showError("Hiba","A táblázat sort nem lehet újratölteni - a tétel már nem létezik.");
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }


    public getRecordRoute(rec_id: number | null): string {
        const rawDefaults = utoa(JSON.stringify({
            defaultValues: this.state.filterValues,
            spec: this.state.spec
        }));
        let info = this.state.struct!;
        return `/${info.schema_name}/${info.table_name}/edit/${rec_id || 'null'}/${rawDefaults}`;
    }


    /** Get default filter for this editor.
     * Also acts as a default value setter for createNewRecord.
     */
    protected getFilter(): TFilterDict {
        const struct = this.state.struct!;
        let result = ViewFilter.constructFilter(
            struct,
            this.state.filterValues || {},
            ViewFilter.constructMinValueFilter(struct, this.state.filterMinValues),
            ViewFilter.constructMaxValueFilter(struct, this.state.filterMaxValues),
            ViewFilter.constructNullValueFilter(struct, this.state.filterNullColumns),
            ViewFilter.constructNotNullValueFilter(struct, this.state.filterNotNullColumns),
            
        );
        //console.log("listview.getFilter", this.state.filterValues, "->", result);
        return result;
    }

    protected getSpecFilter(): any {
        return this.state.spec;
    }

    protected getOrderBy(): string |IListOrderBy[]| undefined {
        return undefined;
    }

    protected getLimit(): number | undefined {
        return 1000;
    }

    protected getLangId(): number {
        return this.props.lang_id || LANG_ID_HU;
    }

    protected getColumnInfo(columnName: string): IViewColumnInfo {
        return this.columnInfoByName.get(columnName)!;
    }

    protected getMatchParam(name: string): any {
        const match: any = this.props["match"];
        return (match && match.params) ? match.params[name] : null;
    }

    protected onLoad(record_id: number | null) {
        if (this.props.inplaceListEditor === undefined) {
            history.push(this.getRecordRoute(record_id));
        } else {
            this.props.inplaceListEditor.onEditRecord(record_id);
        }
    }

    protected async onUnDelete(record_id: number) {
        if (this.state.crudClassProxy) {
            this.state.crudClassProxy.unDeleteById(record_id)
                .then((crud) => this.asyncReload())
                .catch((error) => app.showErrorFromJsonResult(error));
        }
    }

    protected async onDelete(record_id: number): Promise<any> {
        if (this.state.crudClassProxy) {
            if (confirm(__("Biztosan törölni akarja ezt a tételt?"))) {
                this.state.crudClassProxy.deleteById(record_id)
                    .then((crud) => this.asyncReload())
                    .catch((error) => app.showErrorFromJsonResult(error));
            }
        }
    }


    protected getEditorButton(record: TRecord): JSX.Element {
        if (this.props.inplaceListEditor) {
            return <button key="editor-button" type="button" className="listview-button" title="Szerkesztés"
                onClick={() => this.onLoad(record.id!)}
            >
                <i className="fa fa-edit"></i>
            </button>
        } else {
            return (
                <Link
                    key="editor-button"
                    className="listview-button" title="Szerkesztés"
                    to={this.getRecordRoute(record.id!)}
                >
                    <i className="fa fa-edit"></i>
                </Link>);
        }
    }

    protected getDeleteUndeleteButton(record: TRecord): JSX.Element | null {
        let isLogDel: boolean = record["is_active"] !== undefined;
        const isDeleted: boolean = isLogDel && (record["is_active"] === false);
        let title;
        let cls = "listview-button";
        let iconClass = "fa";
        if (isDeleted) {
            title = 'Visszaállítás';
            cls += " listview-button-warning";
            iconClass += " fa-recycle";
        } else {
            title = 'Törlés';
            cls += " listview-button-alert";
            iconClass += " fa-trash";
        }
        const rec_id = record.id!;
        return (
            <button
                key="delete"
                className={cls} title={title}
                onClick={() => (
                    isDeleted
                        ? this.onUnDelete(rec_id)
                        : this.onDelete(rec_id))
                }
            ><i className={iconClass} /></button>);
    }


    protected getRecordButtons(record: TRecord): JSX.Element[] {
        let result = [this.getEditorButton(record)];
        let deleteButton = this.getDeleteUndeleteButton(record);
        if (deleteButton) result.push(deleteButton);



        return result;
    }

    protected convertRecord = (record: TRecord): TRecord => {
        let result: TRecord = Object.assign({}, record);
        result["link"] = <div key={"rec_" + record.id} className="listview-button-container">{this.getRecordButtons(record)}</div>;
        return result;
    }

    private convertItems = (items: TRecord[]): TRecord[] => {
        let result: TRecord[] = [];
        items.forEach((record, index) => {
            result.push(this.convertRecord(record))
        });
        return result;
    }

    protected getHeader(): JSX.Element | null {
        if (this.state.struct) {
            const displayName =
                this.props.title ?
                    this.props.title
                    : (this.state.struct.translations[this.getLangId()].display_name + "  - lista")
            return <div className="row expanded">
                <div id="listHeader" className="column small-12 large-12">
                    <h4>{displayName}</h4>
                </div>
            </div>;
        } else {
            return null;
        }
    }

    private resetFilterForColumn = (columnName: string, resetRanges: boolean) => {
        let result = {
            filterValues: { ...this.state.filterValues, [columnName]: undefined },
            filterNullColumns: this.state.filterNullColumns.filter(item => item != columnName),
            filterNotNullColumns: this.state.filterNullColumns.filter(item => item != columnName),
            reloading: true
        };
        if (resetRanges) {
            result["filterMinValues"] = { ...this.state.filterMinValues, [columnName]: undefined };
            result["filterMaxValues"] = { ...this.state.filterMinValues, [columnName]: undefined };
        }
        return result;
    }

    protected onFilterChange = (columnInfo: IViewColumnInfo, newValue: any) => {
        if (newValue === null) { newValue = undefined; }
        let newState = {
            ...this.resetFilterForColumn(columnInfo.column_name, true),
            filterValues: { ...this.state.filterValues, [columnInfo.column_name]: newValue }
        };
        this.setState(newState, this.debouncedAsyncReload);
    }

    protected onFilterMinValueChange = (columnInfo: IViewColumnInfo, newValue: any) => {
        if (newValue === null) { newValue = undefined; }
        let newState = {
            ...this.resetFilterForColumn(columnInfo.column_name, false),
            filterMinValues: { ...this.state.filterMinValues, [columnInfo.column_name]: newValue }
        };
        this.setState(newState, this.debouncedAsyncReload);
    }

    protected onFilterMaxValueChange = (columnInfo: IViewColumnInfo, newValue: any) => {
        if (newValue === null) { newValue = undefined; }
        let newState = {
            ...this.resetFilterForColumn(columnInfo.column_name, false),
            filterMaxValues: { ...this.state.filterMaxValues, [columnInfo.column_name]: newValue }
        };
        this.setState(newState, this.debouncedAsyncReload);
    }

    protected onFilterNullColumnChange = (columnInfo: IViewColumnInfo, newIsNull: boolean) => {
        let filterNullColumns = this.state.filterNullColumns;
        const cn = columnInfo.column_name;
        if (newIsNull) {
            if (!filterNullColumns.includes(cn)) {
                filterNullColumns.push(cn);
            }
        } else {
            filterNullColumns = filterNullColumns.filter(columName => columName != cn);
        }
        let newState = {...this.resetFilterForColumn(columnInfo.column_name, true),filterNullColumns};
        this.setState(newState, this.debouncedAsyncReload);
    }

    protected onFilterNotNullColumnChange = (columnInfo: IViewColumnInfo, newIsNotNull: boolean) => {
        let filterNotNullColumns = this.state.filterNotNullColumns;
        const cn = columnInfo.column_name;
        if (newIsNotNull) {
            if (!filterNotNullColumns.includes(cn)) {
                filterNotNullColumns.push(cn);
            }
        } else {
            filterNotNullColumns = filterNotNullColumns.filter(columName => columName != cn);
        }
        let newState = {...this.resetFilterForColumn(columnInfo.column_name, true),filterNotNullColumns};
        this.setState(newState, this.debouncedAsyncReload);
    }

    private currentFlag = (flag: any): boolean | null | undefined => {
        if (flag===true || flag===false || flag===null) {
            return flag;
        } else {
            return undefined;
        }
    }

    private nextFlag = (flag: boolean | undefined | null, cannotBeNull:boolean): boolean | null | undefined => {
        if (flag === undefined) {
            return true;
        } else if (flag === true) {
            return false;
        } else if (flag === false) {
            if (cannotBeNull) {
                return undefined;
            } else {
                return null;
            }
        } else {
            return undefined;
        }
    }

    private flagTitle = (flag: boolean | undefined | null ): string => {
        if (flag === undefined) {
            return 'Mindent';
        } else if (flag === true) {
            return "Igen/Igaz";
        } else if (flag === false) {
            return "Nem/Hamis";
        } else {
            return "Üres/töltetlen";
        }
    }

    private flagColorClass = (flag: boolean | null | undefined): string => {
        if (flag === undefined || flag === null) {
            return 'secondary';
        } else if (flag === true) {
            return "success";
        } else {
            return "alert";
        }
    }    


    protected quickFilter(columnName: string, className?: string): JSX.Element {
        let ci = this.columnInfoByName.get(columnName)!;
        const filterValues = this.state.filterValues!;
        const minValue = filterValues[columnName] as any || undefined;
        const maxValue = filterValues[columnName] as any || undefined;


        if (!["text", "date", "timestamp", "int8", "bool"].includes(ci.orig_type)) {
            return <div>quickFilter: {ci.orig_type} típus még nem támogatott </div>
        }

        let tr = ci.translations[this.getLangId()];
        let trExtra = null;
        const qf_name = "quickfilter_" + columnName;

        let input = null;

        if (ci.orig_type == "text") {
            const value = filterValues[columnName] as any || ""
            input = <input type="text"
                value={value}
                id={qf_name}
                onChange={(e: any) => {
                    this.onFilterChange(
                        ci, e.target.value
                    );
                }}
            />;
        }

        if (ci.orig_type == "date") {
            trExtra = __("Min/Max");
            // TODO: ezek férjenek ki egymás mellé?
            input = <div className="row">
                <div className="column small-12 medium-6">
                <input type="date"
                    value={minValue}
                    id={qf_name}
                    onChange={(e: any) => { this.onFilterMinValueChange(ci, e.target.value || undefined); }}
                />
                </div>
            <div className="small-12 medium-6 column">
                <input type="date"
                    value={maxValue}
                    id={qf_name}
                    onChange={(e: any) => {
                        this.onFilterMaxValueChange(ci, e.target.value || undefined);
                    }}
                />
            </div></div>;
        }

        if (ci.orig_type == "timestamp") {
            trExtra = __("Min/Max");
            input = <div>
                <DatePicker
                    value={minValue}
                    onChange={(value: string | null, event: React.FormEvent<HTMLInputElement>) => {
                        this.onFilterMinValueChange(ci, value || undefined);
                    }}
                />
                <DatePicker
                    value={maxValue}
                    onChange={(value: string | null, event: React.FormEvent<HTMLInputElement>) => {
                        this.onFilterMinValueChange(ci, value || undefined);
                    }}
                />
            </div>
        }


        if (ci.orig_type == "int8") {
            trExtra = __("Min/Max");
            input = <div className="row"><div className="column small-12 medium-6">
                <input type="number" step="1"
                    value={minValue}
                    id={qf_name}
                    onChange={(e: any) => { this.onFilterMinValueChange(ci, parseInt(e.target.value) || undefined); }}
                />
                </div>
            <div className="small-12 medium-6 column">
                <input type="number" step="1"
                    value={maxValue}
                    id={qf_name}
                    onChange={(e: any) => {
                        this.onFilterMaxValueChange(ci, parseInt(e.target.value) || undefined);
                    }}
                />
            </div></div>;
        }

        if (ci.orig_type == "bool") {
            const value = this.currentFlag(filterValues[columnName] as any);
            const cannotBeNull = false; // TODO: ha ki tudnánk találni hogy lehet-e null értéke. De nem tudjuk.
            input = <button
                id={qf_name}
                type="button"
                className={"button " + this.flagColorClass(value)}
                title={this.flagTitle(value)}
                onClick={() => { this.onFilterChange( ci, this.nextFlag(value, cannotBeNull));}}
            >
                {this.flagTitle(value)}
            </button>            

        }

        let trNullOp = <button onClick={() => { this.onFilterNullColumnChange(ci, true); }} style={{border: "1px solid", width: "25px", height: "14px", fontSize:"12px"}} title="Kattintás: szűrés az üres értékekre">null</button>;
        let trNotNullOp = <button onClick={() => { this.onFilterNotNullColumnChange(ci, true); }} style={{border: "1px solid", width: "25px", height: "14px", fontSize:"12px"}} title="Kattintás: szűrés a nemüres értékekre">val</button>;

        if (this.state.filterNullColumns.includes(ci.column_name)) {
            input = <div className="input-group">
                <input disabled className="input-group-field" type="text" value="Csak az üres értékek látszódnak"></input>
                <div className="input-group-button">
                <button className="button warning"
            title="Nyomja meg ezt a gombot az 'üres' szűrés törléséhez"
            onClick={() => { this.onFilterNullColumnChange(ci, false); }}
            >
               <i className="fa fa-times"></i> 
            </button></div></div>
        } else if (this.state.filterNotNullColumns.includes(ci.column_name)) {
            input = <div className="input-group">
            <input disabled className="input-group-field" type="text" value="Csak a nemüres értékek látszódnak"></input>
            <div className="input-group-button">
            <button className="button"
                title="Nyomja meg ezt a gombot a 'nemüres' szűrés törléséhez"
                onClick={() => { this.onFilterNotNullColumnChange(ci, false); }}
            >
            <i className="fa fa-times"></i> 
            </button>
            </div></div>
        }

        return <div key={"quickfilter_container_" + columnName} className={className || "column small-12 large-12"}>
            <label htmlFor={qf_name}>{tr.display_name} {trExtra} <i className="fa fa-filter" style={{fontSize: "11px"}}></i> {trNotNullOp} {trNullOp}</label>
            {input}
        </div>;
    }


    protected getQuickFilterFieldNames(): string[] {
        return [];
    }

    protected onShowFilterForColumn(column: IViewColumnInfo): boolean {
        return true;
    }


    protected getFilterControls(): JSX.Element | null {
        let filterPanel = <Accordion allowAllClosed={true}>
        <AccordionItem defaultClosed={true} title={<span><i className="fas fa-filter"></i> {__("Szűrők")}</span>}>
        <div className="row">
                            <ViewFilter
                                viewInfo={this.state.struct!}
                                filterByColumn={this.state.filterValues || {}}
                                onFilterByColumnChange={this.onFilterChange}
                                onMinFilterByColumnChange = {this.onFilterMinValueChange}
                                onMaxFilterByColumnChange = {this.onFilterMaxValueChange}
                                onShowColumn={this.onShowFilterForColumn}
                            />
                            <div className="row align-center">
                                <button className="button" onClick={this.asyncReload} >
                                    <i className="fa fa-filter"></i> &nbsp; Frissít </button>
                            </div>
                        </div>
                </AccordionItem>
                </Accordion>;
        //  if (this.state.showFilterPanel) {
        /*filterPanel =
            <ul className="accordion" data-accordion data-multi-expand="true" data-allow-all-closed="true">
                <li key="filter_accordion"  className="accordion-item" data-accordion-item>
                    <Link to="#" className="accordion-title exercise-editor-accordion-title"> Szűrők</Link>
                    <div key="filters-all" className="accordion-content" data-tab-content >
                        
                    </div>
                </li>
            </ul>;*/
        return filterPanel;

    }

    protected getFilterControlsWithQuickFilters(): JSX.Element | null {
        let fnames: string[] = this.getQuickFilterFieldNames();
        if (fnames.length) {
            return <div key="filter-container">
                {this.getFilterControls()}
                <fieldset className="large-12 row">
                    <legend>Gyors szűrés</legend>
                    {fnames.map((fname) => this.quickFilter(fname))}
                </fieldset>
            </div>
        } else {
            return this.getFilterControls()
        }
    }

    componentDidUpdate() {

        ($("#pnl_list_page") as any).foundation();
    }

    protected onCellClick(event: any, finalState: any, rowInfo: any, column?: Column, instance?: any) {
    }

    protected getViewColumns() {
        return this.props.viewColumns;
    }

    protected getExtraTableProps(): Partial<TableProps> {
        let result = {};
        const viewColumns = this.getViewColumns();
        if (!this.props.columns && viewColumns && this.state.struct) {
            const cols = [];
            for (let c = 0; c < viewColumns.length; c++) {
                const vc: ListViewColumn = viewColumns[c];
                const colName = vc.accessor;
                let header: TableCellRenderer;
                const vci: IViewColumnInfo = this.getColumnInfo(colName);
                if (vci) {
                    const translation: IViewColumnInfoTranslation =
                        vci.translations[this.getLangId()];
                    if (vc.Header) {
                        header = vc.Header;
                    } else if (translation) {
                        header = translation.display_name;
                    } else {
                        header = vc.accessor;
                    }
                } else {
                    header = vc.Header || vc.accessor;
                }
                const column: Column = {
                    Header: header,
                    accessor: vc.accessor,
                    minWidth: vc.minWidth,
                    maxWidth: vc.maxWidth,
                    Cell: vc.Cell,
                    style: vc.style
                };
                cols.push(column);
            }
            result["columns"] = cols;
        }

        const getTdProps = (finalState: any, rowInfo: any, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};
            if (column && rowInfo && rowInfo.row) {
                let row: any = rowInfo.row._original;
                if (row) {
                    if (column["id"] == "name") {
                        props["style"] = row.style;
                    } else {
                        let style = props["style"] || {};
                        if (row["is_active"] === false) {
                            style["textDecoration"] = "line-through";
                        }
                        props["style"] = style;
                    }
                }
            }
            props['onClick'] = (e: any, handleOriginal: any) => {
                this.onCellClick(e, finalState, rowInfo, column, instance);
            }
            return props;
        }
        result['getTdProps'] = getTdProps;
        return result;
    }

    protected getAllowInsertNew() : boolean {
        return (this.props.allowInsertNew === undefined) || this.props.allowInsertNew;
    }

    protected getAllowDownloadExcel() : boolean {
        return (this.props.allowDownloadExcel === undefined) || this.props.allowDownloadExcel;
    }

    protected getTopButtons(): JSX.Element[] {
        let result = [];
        const allowInsertNew = this.getAllowInsertNew();
        if (allowInsertNew) {
            result.push(
                <div className="small-4 medium-4 column" key="insert_new">
                    <button type="button" className="button"
                        title="Új" onClick={() => this.onLoad(null)}>
                        <i className="fa fa-edit"></i> &nbsp; Új
                    </button>
                </div>
            );
        }
        const allowDownloadExcel = this.getAllowDownloadExcel();
        if (allowDownloadExcel) {
            result.push(
                <div className="small-4 medium-4 column" key="download_excel">
                    <button type="button" className="button"
                        title="Új" onClick={() => {
                            const location = this.getViewClassProxy().getExcelDownloadUrl(
                                { filter: this.getFilter(), spec: this.getSpecFilter(), order_by: this.getOrderBy(), limit:this.getLimit() },
                                { filename: this.getViewClassProxy().getViewNameForClass() + ".xlsx" }
                            );
                            window.location.replace(location);
                        }}>
                        <i className="fa fa-file-excel"></i> &nbsp; Letöltés (excel)
                    </button>
                </div>
            );
        }
        return result;
    }

    protected getFooter(): JSX.Element[] {
        return [];
    }

    protected getRowDetails(row:RowInfo):JSX.Element{
        return <div></div>;
    }


    public render() {
        if (this.state.struct === undefined) {
            return <BubbleLoader />
        } else {
            let items;
            let header = null;
            let footer = this.getFooter();
            let rowDetails = undefined;
            if (this.state.items === undefined) {
                items = 'Betöltés...';
                header = <span />;
            } else if (this.state.items.length == 0) {
                items = 'Nincsenek fölvéve a feltételeknek megfelelő tételek.';
                header = this.getTopButtons();
            } else {
                let tableProps = Object.assign(
                    { className: "-striped -highlight " },
                    this.props,
                    getReactTableLabels(),
                    { data: this.state.items },
                    this.getExtraTableProps()
                );

                if(this.props.showRowDetails){
                    rowDetails = this.getRowDetails;
                }
                items = <ReactTable {...tableProps}
                SubComponent = {rowDetails}
                    style={{ opacity: this.state.reloading ? 0.5 : 1.0 }}
                />
                header = this.getTopButtons();
            }
            return <>
                {this.getHeader()}
                <div className="row expanded">
                    <div id="pnl_list_page" className="column">
                        {this.getFilterControlsWithQuickFilters()}
                        <div className="row align-center">
                            {header}
                        </div>
                        {items}
                        {footer}
                    </div>
                </div></>;
        }
    }
}
