import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IForumRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Mihez kapcsolódik - Táblázat infó
     *
     * -> meta.table_info ON DELETE noaction
     *
     *
     * Ez mondja meg, hogy a fórum melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number|null;
    /**
     * Mihez kapcsolódik - rekord azonosító
     *
     * Ez mondja meg, hogy a fórum melyik rekordhoz tartozik.
     */
    rec_id?: number|null;
    /**
     * Név
     */
    title?: string|null;
    /**
     * Alapértelmezett topic
     *
     * -> usr.forum_topic ON DELETE cascade
     *
     *
     * Ha ez ki van töltve, akkor a fórumnak ez az egy topic-ja van.
     */
    default_topic_id?: number|null;
}

/**
 *  usr.forum - Fórum
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ForumCrud extends Crud<IForumRecord> {
    public static TABLE_INFO_ID = 2075846867;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'forum'; }

    public static load: (id: number, aServer ?: Server) => Promise<ForumCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IForumRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IForumRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IForumRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IForumRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IForumRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ForumCrudClassProxy extends CrudClassProxy<IForumRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const forumCrudClassProxy = new ForumCrudClassProxy(ForumCrud);
registerCrudClassProxy(forumCrudClassProxy);
