import * as React from 'react';
import { Link } from 'react-router-dom';
import ViewDlMaterial, { IViewDlMaterialRecord } from '@src/framework/view/doc_pub/ViewDlMaterial';
import { PATH_DL_MATERIAL_VIEW } from '@src/Routes';
import CrudSelect from '@src/framework/forms/crudselect';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import { gradeCrudClassProxy } from '@src/framework/crud/doc/GradeCrud';
import { TSpecFilterDict } from '@src/framework/crud/Crud';
import { __ } from '@src/translation';
import { Pager } from '@src/component/Pager';
import { app } from '@src/index';
import { dlSubjectCrudClassProxy } from '@src/framework/crud/doc_pub/DlSubjectCrud';

type DownloadableMaterialsState = {
    loading: boolean;
    materials: IViewDlMaterialRecord[];
    selectedSubject: number | null;
    selectedGrade: number | null;
    pageIndex: number;
    count?: number;
}
export class DownloadableMaterials extends React.Component<{}, DownloadableMaterialsState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            loading: true,
            materials: [],
            selectedSubject: null,
            selectedGrade: null,
            pageIndex: 1,
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }


    private async reloadAsync() {

        let spec: TSpecFilterDict = {};
        if (this.state.selectedGrade) {
            spec.grade_id = this.state.selectedGrade;
        }
        if (this.state.selectedSubject) {
            spec.subject_id = this.state.selectedSubject;
        }

        try {
            const count = await ViewDlMaterial.count({ /* filter: { $notnull: "material_file_id", },*/ spec });

            const materials = await ViewDlMaterial.list({ /* filter: { $notnull: "material_file_id",  }, */
             order_by: "name", spec: spec, offset: (this.state.pageIndex - 1) * 12, limit: 12 });

            this.setState({
                loading: false,
                materials,
                count
            });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private onGradeSelect = (sender: CrudSelect, newGrade: number | null): void => {
        this.setState({ selectedGrade: newGrade }, this.reloadAsync);
    }

    private onSubjectSelect = (sender: CrudSelect, newSubject: number | null): void => {
        this.setState({ selectedSubject: newSubject }, this.reloadAsync);
    }

    render() {
        if (this.state.loading) {
            return null;
        }
    
        return <div className="section dl-list">

            <div className="row  align-center">

                <div className="column small-12">
                    <h3 className="title text-center">{__("Letölthető tananyagok")}</h3>
                </div>

                <div className="search-filters column small-12 medium-11 large-11">
                    <CrudSelect
                        value={this.state.selectedGrade}
                        onSelect={this.onGradeSelect}
                        displayFieldName="name"
                        orderByFieldName="id"
                        emptyTitle={__("Minden osztály")}
                        crudClassProxy={gradeCrudClassProxy}
                        filter={{ is_active: true }}
                        sortFunc={(a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })}
                        limit={1000}
                    />
                    <CrudSelect
                        value={this.state.selectedSubject}
                        onSelect={this.onSubjectSelect}
                        displayFieldName="title"
                        emptyTitle={__("Minden tantárgy")}
                        crudClassProxy={dlSubjectCrudClassProxy}
                        limit={1000}
                    />
                </div>
            </div>


            <div className="row">

                <div className="column small-12">
                    {

                        this.state.count! == 0
                            ?
                            <h4 className="title">{__("Nincs találat")}</h4>
                            :
                            null
                    }
                </div>
                {
                    this.state.materials.map(m => {

                        return <div key={m.id} className="column small-12 medium-4 large-3 align-self-bottom">
                            <div className="book-item inner">
                                <Link tabIndex={0} to={PATH_DL_MATERIAL_VIEW + "/" + m.id} >
                                    {m.thumbnail_file_id &&
                                        <figure className="text-center">
                                            <img src={"/api/media/thumb/300/" + m.thumbnail_file_sha1} />

                                        </figure>
                                    }
                                    <h5 className="item-title text-center">{m.name}</h5>
                                </Link>
                            </div>
                        </div>;
                    })
                }
                <Pager resultNum={this.state.count!} elementPerPage={12} pageIndex={this.state.pageIndex} paging={this.reloadAsync.bind(this)} setPageIndex={(i: number) => this.setState({ pageIndex: i })}></Pager>
            </div>


        </div>
    }

}