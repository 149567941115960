
import * as React from 'react';
import { match } from 'react-router';
import BlockTemplateCrud, { IBlockTemplateRecord } from '@src/framework/crud/doc/BlockTemplateCrud';
import { app } from '../../..';
import { BubbleLoader } from 'react-css-loaders';
import { __ } from '@src/translation';
import { scriptLoadAsync } from '@src/Util';
import StyleCrud from '@src/framework/crud/doc/StyleCrud';
import BlockTemplateTypeCrud, { IBlockTemplateTypeRecord, BlockTemplateTypeCrudClassProxy } from '@src/framework/crud/doc/BlockTemplateTypeCrud';
import { getSectionTemplateBody } from '@src/server/EditorServer';
import SectionCrud from '@src/framework/crud/doc/SectionCrud';


type TemplatePreviewProps = {
    match: match<{ styleId: string }>,
    history: History
}

type TemplatePreviewState = {
    templates: IBlockTemplateRecord[];
    templateTypes: IBlockTemplateTypeRecord[];
    cssLoaded: boolean;
    styleName: string;
    counts: number[];
}


const head = document.getElementsByTagName("head")[0];
export default class TemplatePreview extends React.Component<TemplatePreviewProps, TemplatePreviewState> {

    constructor(props: TemplatePreviewProps) {
        super(props);

        this.bookCommonCss.setAttribute("rel", "stylesheet");
        this.bookCommonCss.setAttribute("href", `/css/book_flex.css`);
        this.bookCustomCss.setAttribute("rel", "stylesheet");

        this.state = {
            templates: [], templateTypes: [],
            cssLoaded: false,
            styleName: "",
            counts: []
        };
    }

    private bookCommonCss = document.createElement("link");
    private bookCustomCss = document.createElement("link");

    componentDidMount() {
        this.reloadCssAsync();
        this.reloadAsync();
    }

    async reloadCssAsync() {
        if (!this.bookCommonCss.parentElement) {
            head.appendChild(this.bookCommonCss);
        }

        if (!this.bookCustomCss.parentElement) {
            head.appendChild(this.bookCustomCss);
        }

        const style = (await StyleCrud.load(Number(this.props.match.params.styleId))).record;

        const cssHref = style.css_path || "";
        if (this.bookCustomCss.getAttribute("href") != cssHref) {
            this.bookCustomCss.setAttribute("href", cssHref);

            this.setState({ cssLoaded: false });

            try {
                await scriptLoadAsync(this.bookCustomCss, 200);
            } catch (e) {/*css not yet loaded, render anyway*/ }
        }

        this.setState({ cssLoaded: true });
    }

    async asyncForEach(array: any, callback: any) {
        for (let i = 0; i < array.length; i++) {
            await callback(array[i], i, array);
        }
    }


    async reloadAsync() {
        try {
            const styleId = Number(this.props.match.params.styleId);

            const style = (await StyleCrud.load(styleId)).record;

            let templates = await BlockTemplateCrud.list({ filter: { is_active: true, style_id: styleId }, order_by: "name" });

            let filteredTemplates = templates.filter(temp => temp.name!.toLowerCase() != __("videó") &&
                temp.name!.toLowerCase() != __("beágyazott tartalom") &&
                temp.name!.toLowerCase() != __("okosfeladat"));

            const typeIds: number[] = [];
            const templateCounts: number[] = [];

            const count = async () => {
                await this.asyncForEach(filteredTemplates, async (template: any, num: any) => {
                    const templateId = template.id!;
                    const count = (await SectionCrud.list({ filter: { is_active: true, block_template_id: templateId } })).length;
                    const typeId = template.block_template_type_id!;

                    if (typeId && !typeIds.includes(typeId)) {
                        typeIds.push(typeId);

                    }
                    templateCounts.push(count);
                })
                this.setState({ counts: templateCounts });
            }

            count();
            const templateTypes = await BlockTemplateTypeCrud.list({ filter: { is_active: true } })

            this.setState({
                styleName: style.title || "", templates: filteredTemplates, templateTypes
            });

        } catch (e) {
            app.showErrorFromJsonResult(this.props.match.params.styleId + e);
        }
    }

    render() {
        if (!this.state.templates || !this.state.cssLoaded) {
            return <BubbleLoader />
        }
        return <div>

            <h1 className="row">{this.state.styleName}</h1>
            <br />
            {

                this.state.templates.map((template, index) => {
                    const body = getSectionTemplateBody(template, this.state.templateTypes);

                    const div = document.createElement("div");
                    div.innerHTML = body!;
                    div.querySelectorAll("x-html").forEach(x => x.innerHTML = `<div><a href="#">LINK Lorem</a> ipsum dolor sit amet, consectetur adipiscing elit. Nulla diam neque, interdum vitae accumsan in, laoreet id risus. Nullam turpis justo, egestas id iaculis scelerisque, rhoncus eu ligula. Integer a nisi eget lacus feugiat aliquet. Mauris pulvinar orci vitae sem sagittis finibus. Vivamus convallis elit at fermentum ornare. Cras sit amet quam eu lacus faucibus dictum. <span class="has-tip" title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla diam neque, interdum vitae accumsan in, laoreet id risus. Nullam turpis justo, egestas id iaculis scelerisque, rhoncus eu ligula. Integer a nisi eget lacus feugiat aliquet. Mauris pulvinar orci vitae sem sagittis finibus. Vivamus convallis elit at fermentum ornare. Cras sit amet quam eu lacus faucibus dictum. Vestibulum vel libero at nisi gravida sollicitudin vel a arcu." data-tooltip="">TOOLTIP VEZÉRLŐ Vestibulum</span> vel libero at nisi gravida sollicitudin vel a arcu.</div>
                        <div> </div>
                        <blockquote>
                        <div>
                        <div>IDÉZET Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla diam neque, interdum vitae accumsan in, laoreet id risus. Nullam turpis justo, egestas id iaculis scelerisque, rhoncus eu ligula. Integer a nisi eget lacus feugiat aliquet. Mauris pulvinar orci vitae sem sagittis finibus. Vivamus convallis elit at fermentum ornare. Cras sit amet quam eu lacus faucibus dictum. Vestibulum vel libero at nisi gravida sollicitudin vel a arcu.</div>
                        <div> </div>
                        </div>
                        <cite>CITE Lorem ipsum</cite></blockquote>
                        <div>
                        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla diam neque, interdum vitae accumsan in, laoreet id risus. Nullam turpis justo, egestas id iaculis scelerisque, rhoncus eu ligula. Integer a nisi eget lacus feugiat aliquet. Mauris pulvinar orci vitae sem sagittis finibus. Vivamus convallis elit at fermentum ornare. Cras sit amet quam eu lacus faucibus dictum. Vestibulum vel libero at nisi gravida sollicitudin vel a <span class="modal-control " data-modal-control="" data-open="">MODAL CONTROL arcu.</span></div>
                        </div>`);



                    return <div key={index}>

                        <section>
                            <div className="row column"><h4>{template.name}</h4>  <h5>{__("Használva {count} szekcióban", {count: this.state.counts[index]})}</h5></div>

                        </section>

                        <div className="sections" dangerouslySetInnerHTML={{ __html: div.outerHTML + "" }}>
                        </div>

                        <hr style={{ clear: "both", marginTop: "3em" }} />

                    </div>
                })
            }

        </div>;
    }

}
