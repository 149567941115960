import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ValidationResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import * as React from "react";
import ExerciseImageCoordinatePick, { Shapes } from "../../Editor/ExerciseImageCoordinatePick";
import { Panel } from "@src/component/ui/Panel";
import { __ } from "@src/translation";
import { UniversalData } from "./UniversalExerciseEngine";
import ExerciseFileSelect, { ExerciseFileTypes } from "../../Editor/ExerciseFileSelect";
import { IAccordionAction, AccordionActionType, Accordion, ActionAccordionItem } from "@src/component/ui/Accordion";
import MultiSelect from "@khanacademy/react-multi-select";
import { ExerciseEngineHelper } from "../ExerciseEngineHelper";
import './style.css';

export type UniversalExercise = {
    show_areas: boolean;
    show_draggables: boolean;
    illustration_task: string;
    illustration_background: string;
    areas: UniversalElement[],
    draggables: UniversalElement[]
}

export type UniversalElement = {
    shape: Shapes,
    alt: string,
    points?: string,
    x?: number,
    y?: number,
    radius?: number,
    answer_indexes?: number[]
    sel_type: UniversalSelection
}

export enum UniversalSelection {
    Area,
    Draggable
}

export type CanvasInfo = {
    arrName: string,
    index: number,
    cRef: string
}
export class UniversalEngineConverter extends AExerciseTypeConverter {

    private CANVAS_REF_AREA_BASE: string = "canvas_area_";
    private CANVAS_REF_DRAGGABLE_BASE: string = "canvas_draagable_";
    private DEF_AREA_ALT: string = "terület";
    private DEF_DRAGGABLE_ALT: string = "elem";
    static MAX_AREA_NUM:number = 15;
    static MAX_DRAGGABLE_NUM:number = 20;

    public convertToEditorAnswer(exercise: UniversalData): UniversalExercise | undefined {
        if (exercise) {
            let editorData: UniversalExercise = {
                show_areas: exercise.show_areas,
                show_draggables: exercise.show_draggables,
                illustration_task: exercise.illustration_task,
                illustration_background: exercise.illustration_background,
                areas: exercise.areas ? exercise.areas : [],
                draggables: exercise.draggables ? exercise.draggables : []
            }

            let solution = exercise.solution;

            for (let i = 0; i < solution.length; i++) {
                //From the solution we know which draggables are accepted to the areas
                editorData.areas[i].answer_indexes! = solution[i];
            }
            return editorData;
        }
        return undefined;
    }
    public convertToJson(editorData: UniversalData, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: any): any {
        var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
        if (editorData) {
            let solution: any = [];
            //We loop through the areas
            if (editorData.areas)
                for (let i = 0; i < editorData.areas.length; i++) {
                    let sol = [];
                    if (editorData.areas[i].answer_indexes) {
                        //We push the indexes of the accepted elements to the solution[i] array if there is any
                        for (let j = 0; j < editorData.areas[i].answer_indexes!.length; j++) {
                            sol.push(editorData.areas[i].answer_indexes![j]);
                        }
                        solution.push(sol);
                    }
                }
            let exerciseToSave = {
                ...cur_exercise,
                show_areas: editorData.show_areas,
                show_draggables: editorData.show_draggables,
                illustration_task: editorData.illustration_task,
                illustration_background: editorData.illustration_background,
                areas: editorData.areas,
                draggables: editorData.draggables,
                solution
            }
            //We dont want to save the accepted indexes to the DB, its already in the solution array
            //if (exerciseToSave.areas) exerciseToSave.areas.forEach((area: UniversalElement) => {
            //area.answer_indexes = [];
            //});
            return exerciseToSave;
        }
        return undefined;
    }

    async onCoordinateChange(coords: any[], canvasInfo: CanvasInfo) {
        await this.handleInputChangeAdditionalArray(coords);
        let array = this.state.exercise[canvasInfo.arrName];
        this.drawCanvas(array[canvasInfo.index], canvasInfo.cRef);
    }

    public makeValidBeforeSetState(oldState: UniversalExercise | null, newState: UniversalExercise): ValidationResponse {
        //if (AExerciseTypeConverter.isOfiEditor) return { valid: true };
        let errorMsg = "";
        if (newState.areas.length > UniversalEngineConverter.MAX_AREA_NUM) {
            newState.areas.splice(UniversalEngineConverter.MAX_AREA_NUM, newState.areas.length - UniversalEngineConverter.MAX_AREA_NUM);
            errorMsg = __("Maximum {max} terület vehető fel!", { max: UniversalEngineConverter.MAX_AREA_NUM });
            return { valid: false, message: errorMsg };
        }
        if (newState.draggables.length > UniversalEngineConverter.MAX_DRAGGABLE_NUM) {
            newState.draggables.splice(UniversalEngineConverter.MAX_DRAGGABLE_NUM, newState.draggables.length - UniversalEngineConverter.MAX_DRAGGABLE_NUM);
            errorMsg = __("Maximum {max} húzható elem vehető fel!", { max: UniversalEngineConverter.MAX_DRAGGABLE_NUM });
            return { valid: false, message: errorMsg };
        }
        return { valid: true }
    }

    public validate(editorAnswer: UniversalExercise, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData, validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;

        let errorMsg = "";
        let OFIErrors: string[] = [];

        if (!editorAnswer.illustration_task) {
            errorMsg = __("A feladat illusztráció megadása kötelező!");
            return { valid: false, message: errorMsg };
        }
        if (editorAnswer.areas.length > UniversalEngineConverter.MAX_AREA_NUM) {
            errorMsg = __("Maximum {max} terület vehető fel!", { max: UniversalEngineConverter.MAX_AREA_NUM });
            if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg };
        }
        if (editorAnswer.draggables.length > UniversalEngineConverter.MAX_DRAGGABLE_NUM) {
            errorMsg = __("Maximum {max} behúzható elem vehető fel!", { max: UniversalEngineConverter.MAX_DRAGGABLE_NUM });
            if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if (!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg };
        }
        if (AExerciseTypeConverter.isOfiEditor) {
            return { valid: true, message: OFIErrors.join(' , ') }
        }
        return { valid: true }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        let exercise = this.state.exercise;
        if (!this.props.exerciseEditor.state.isDataChanged || prevState.exercise.illustration_task != exercise.illustration_task) {
            this.drawAllCanvas();
        }
    }

    onMultiSelect(selected: any, index: number) {
        let exercise = this.state.exercise;
        exercise.areas[index].answer_indexes = selected;
        this.onBlurEvent();
        this.setState({ exercise });
    }

    onAddNewArea() {
        let exercise = this.state.exercise;
        let newArea: UniversalElement = {
            alt: this.DEF_AREA_ALT,
            answer_indexes: [],
            shape: Shapes.Circle,
            radius: 5,
            x: 0,
            y: 0,
            sel_type: UniversalSelection.Area
        }
        exercise.areas.push(newArea);
        if (this.makeValidBeforeSetState(this.state.exercise, exercise).valid)
        this.setState({ exercise });
    }

    onAddNewDraggable() {
        let exercise = this.state.exercise;
        let newIndex: number = exercise.draggables.length + 1;
        let newArea: UniversalElement = {
            alt: newIndex + ". " + this.DEF_DRAGGABLE_ALT,
            answer_indexes: [],
            shape: Shapes.Rectangle,
            sel_type: UniversalSelection.Draggable
        }
        exercise.draggables.push(newArea);
        if (this.makeValidBeforeSetState(this.state.exercise, exercise).valid)
        this.setState({ exercise });
    }

    removeDraggable(index: number) {
        let exercise = this.state.exercise;
        //Remove draggable
        exercise.draggables.splice(index, 1);
        for (let i = 0; i < exercise.areas.length; i++) {
            let ar = exercise.areas[i];
            let remove_i = ar.answer_indexes.indexOf(index);
            //If accepted indexes of areas contains this element, we have to remove it and adjust the indexes 
            if (remove_i != -1) ar.answer_indexes.splice(remove_i, 1);
            for (let j = 0; j < ar.answer_indexes.length; j++) {
                if (ar.answer_indexes[j] > index) ar.answer_indexes[j]--;
            }
        }
        this.onBlurEvent();
        this.drawAllCanvas();
        this.setState({ exercise });
    }

    drawAllCanvas() {
        let exercise = this.state.exercise;
        for (let i = 0; i < exercise.areas.length; i++) {
            this.drawCanvas(exercise.areas[i], this.CANVAS_REF_AREA_BASE + i);
        }
        for (let i = 0; i < exercise.draggables.length; i++) {
            this.drawCanvas(exercise.draggables[i], this.CANVAS_REF_DRAGGABLE_BASE + i);
        }
    }

    drawCanvas(curr_el: UniversalElement, _ref: string) {
        //Get the actual canvas from refs
        let canvas: any = this.refs[_ref];

        if (canvas && (curr_el.points || curr_el.x)) {

            const ctx = canvas.getContext('2d');
            const img = this.refs.image as HTMLImageElement;
            const imgWidth = img.naturalWidth;
            const imgHeight = img.naturalHeight;

            //If cut "Circle"
            if (curr_el.shape == Shapes.Circle) {
                let rad_pixel = imgWidth * (curr_el.radius! / 100);
                let sx = ((curr_el.x! / 100) * imgWidth) - rad_pixel;
                let sy = ((curr_el.y! / 100) * imgHeight) - rad_pixel;
                ctx.canvas.width = rad_pixel * 2;
                ctx.canvas.height = rad_pixel * 2;
                ctx.drawImage(img, sx, sy, rad_pixel * 2, rad_pixel * 2, 0, 0, rad_pixel * 2, rad_pixel * 2);
            }
            //If cut Polygon or Rectangle
            else {
                //We need to turn the percentages to pixel values
                let r = ExerciseEngineHelper.getPolyCoordsImagePixels(curr_el.points!, imgHeight, imgWidth);
                //Since points are stored in string format, we need to convert it to array
                let arr = ExerciseEngineHelper.getPolyCoordinatePairs(r);

                let min_x = arr[0][0];
                let min_y = arr[0][1];
                let max_x = arr[0][0];
                let max_y = arr[0][1];
                //We need to get the minimum x,y for starting point of the cut
                //We also need the maximum, to calculate the width and height of the cut
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i][0] < min_x) min_x = arr[i][0];
                    if (arr[i][1] < min_y) min_y = arr[i][1];
                    if (arr[i][0] > max_x) max_x = arr[i][0];
                    if (arr[i][1] > max_y) max_y = arr[i][1];
                }
                let cut_width = max_x - min_x;
                let cut_height = max_y - min_y;

                ctx.canvas.width = cut_width;
                ctx.canvas.height = cut_height;

                ctx.drawImage(img, min_x, min_y, cut_width, cut_height, 0, 0, cut_width, cut_height);
            }
        }
    }

    render() {
        let exercise: UniversalExercise = this.state.exercise as UniversalExercise;
        let multiselect_options: any[] = [];
        let all_elements: any[] = this.state.exercise.draggables.concat(this.state.exercise.areas);

        for (let i = 0; i < this.state.exercise.draggables.length; i++) {
            let dr = this.state.exercise.draggables[i];
            multiselect_options.push({ label: dr.alt, value: i });
        }

        return (<Panel header={__("Részletek")} headingLevel={4} >
            <img ref="image" className="hidden" onLoad={this.drawAllCanvas.bind(this)} /*style={{backgroundImage: `url(/${exercise.illustration_background})`}}*/ src={"/" + this.state.exercise.illustration_task} />
            <div className="row">
                <div className="large-12 columns">
                    <label ><input type="checkbox" name="show_areas" checked={exercise.show_areas || false}
                        onBlur={this.onBlurEvent.bind(this)}
                        onChange={this.handleInputChange.bind(this)} />
                        {__("Ejtési területek jelzése")}</label>
                </div>
            </div>
            <div className="row">
                <div className="large-12 columns">
                    <label ><input type="checkbox" name="show_draggables" checked={exercise.show_draggables || false}
                        onBlur={this.onBlurEvent.bind(this)}
                        onChange={this.handleInputChange.bind(this)} />
                        {__("Húzható elemek jelzése")}</label>
                </div>
            </div>
            <Panel header={__("Feladat képeinek megadása")} headingLevel={5}>
                <div className="row">
                    <div className="large-6 small-12 columns">
                        <label className="exe-image-select-label"> {__("Feladat kép") + " " + __("(kiindulási helyzet)")}
                            <ExerciseFileSelect
                                imagebasepath={this.props.imagebasepath}
                                value={exercise.illustration_task || ""}
                                onChange={this.handleImageChange.bind(this, "illustration_task")}
                                getFolderId={this.getFolderId.bind(this)}
                                fileType={ExerciseFileTypes.Image}
                            />
                        </label>
                    </div>
                    <div className="large-6 small-12 columns">
                        <label className="exe-image-select-label"> {__("Háttérkép") + " " + __("(opcionális)")}
                            <ExerciseFileSelect
                                imagebasepath={this.props.imagebasepath}
                                value={exercise.illustration_background || ""}
                                onChange={this.handleImageChange.bind(this, "illustration_background")}
                                getFolderId={this.getFolderId.bind(this)}
                                fileType={ExerciseFileTypes.Image}
                            />
                        </label>
                    </div>
                </div>
            </Panel>
            <Panel header={__("Területek")} headingLevel={5}>
                {
                    exercise.areas ?
                        exercise.areas.map((curr_area: any, index) => {
                            let selected: number[] = this.state.exercise.areas[index].answer_indexes;
                            let actions: IAccordionAction[];
                            actions = [
                                {
                                    title: __("Törlés"),
                                    type: AccordionActionType.Trash,
                                    action: this.removeElement.bind(this, "areas", index)
                                }
                            ];
                            let canvasRef: string = this.CANVAS_REF_AREA_BASE + index;
                            return (
                                <Accordion key={"areas_" + index}>
                                    <ActionAccordionItem defaultClosed key={"areas__" + index} actions={actions} title={(index + 1) + ". " + curr_area.alt}>
                                        <div className="row">
                                            <div className="large-6 medium-12 small-12 columns">
                                                <h5>Terület elnevezése</h5>
                                                <input type="text" name={"areas#" + index + ".alt"} data-index={index} value={curr_area.alt}
                                                    onBlur={this.onBlurEvent.bind(this)}
                                                    onChange={this.handleInputChange.bind(this)} />
                                            </div>
                                            <div className="large-6 medium-12 small-12 columns">
                                                <h5>Elfogadott elemek</h5>
                                                {exercise.draggables.length != 0 ? <MultiSelect
                                                    options={multiselect_options}
                                                    selected={selected}
                                                    overrideStrings={{
                                                        selectSomeItems: "Válassza ki az elemeket...",
                                                        allItemsAreSelected: "Az összes elem behúzható!",
                                                        selectAll: "Összes elem behúzható!",
                                                        search: "Keresés",
                                                    }}
                                                    onSelectedChanged={(selected: number[]) => this.onMultiSelect(selected, index)}
                                                />
                                                    : "Még nincs felvéve behúzandó elem!"}

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="large-6 small-12 columns">
                                                <canvas ref={canvasRef} className="preview" />
                                            </div>
                                            <div className="large-3 small-12 columns">
                                                <label>{__("Típus")}</label>
                                                <select value={curr_area.shape} name={"areas#" + index + ".shape"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)} onSelect={this.handleInputChange.bind(this)}>
                                                    <option value={Shapes.Circle}>{__("Kör")}</option>
                                                    <option value={Shapes.Polygon}>{__("Alakzat")}</option>
                                                    <option value={Shapes.Rectangle}>{__("Téglalap")}</option>
                                                </select>
                                            </div>
                                            <div className="large-3 small-12 columns">
                                                <label>{__("Terület kijelölése")}</label>
                                                <ExerciseImageCoordinatePick
                                                    imagesrc={"/" + exercise.illustration_task}
                                                    ill_bg={"/" + exercise.illustration_background}
                                                    x={curr_area.x}
                                                    y={curr_area.y}
                                                    radius={curr_area.radius}
                                                    shape={curr_area.shape}
                                                    points={curr_area.points}
                                                    canvasInfo={{ arrName: "areas", index: index, cRef: canvasRef }}
                                                    onChange={this.onCoordinateChange.bind(this)}
                                                    taskValues={
                                                        curr_area.shape == Shapes.Circle ?
                                                            [
                                                                { key: "areas#" + index + ".x", value: curr_area.x ? curr_area.x : 0 },
                                                                { key: "areas#" + index + ".y", value: curr_area.y ? curr_area.y : 0 },
                                                                { key: "areas#" + index + ".radius", value: curr_area.radius ? curr_area.radius : 0 }
                                                            ]
                                                            : [{ key: "areas#" + index + ".points", value: curr_area.points ? curr_area.points : null }]
                                                    }
                                                    otherCircles={all_elements.filter((item: any) => (item.points == undefined && (item.x != curr_area.x || item.y != curr_area.y)))}
                                                    otherPolygons={all_elements.filter((item: any) => (item.points != undefined && item.points != curr_area.points))}
                                                />
                                            </div>
                                        </div>

                                    </ActionAccordionItem>
                                </Accordion>)
                        }
                        )
                        : ""
                }
                <button className="button small" onClick={this.onAddNewArea.bind(this)}><i className="fa fa-plus"></i>{__("Új terület")}</button>
            </Panel>
            <Panel header={__("Behúzandó elemek")} headingLevel={5}>
                {
                    exercise.draggables ?
                        exercise.draggables.map((curr_draggable: any, index) => {
                            let actions: IAccordionAction[];
                            actions = [
                                {
                                    title: __("Törlés"),
                                    type: AccordionActionType.Trash,
                                    action: this.removeDraggable.bind(this, index)
                                }
                            ];
                            let canvasRef: string = this.CANVAS_REF_DRAGGABLE_BASE + index;
                            return (
                                <Accordion key={"draggables" + index}>
                                    <ActionAccordionItem key={"draggables" + index} actions={actions} title={curr_draggable.alt}>
                                        <div className="row">
                                            <div className="large-4 small-12 columns">
                                                <canvas ref={canvasRef} className="preview" />
                                            </div>
                                            <div className="large-3 small-12 columns">
                                                <label className="exe-image-select-label">{__("Elnevezés")}
                                                    <input type="text" name={"draggables#" + index + ".alt"} data-index={index} value={curr_draggable.alt}
                                                        onBlur={this.onBlurEvent.bind(this)}
                                                        onChange={this.handleInputChange.bind(this)} />
                                                </label></div>
                                            <div className="large-2 small-12 columns">
                                                <label>{__("Típus")}</label>
                                                <select disabled value={Shapes.Rectangle} name={"draggables#" + index + ".shape"}>
                                                    <option value={Shapes.Circle}>{__("Kör")}</option>
                                                    <option value={Shapes.Polygon}>{__("Alakzat")}</option>
                                                    <option value={Shapes.Rectangle}>{__("Téglalap")}</option>
                                                </select>
                                            </div>
                                            <div className="large-3 small-12 columns">
                                                <label>{__("Elem kijelölése")}</label>
                                                <ExerciseImageCoordinatePick
                                                    imagesrc={"/" + exercise.illustration_task}
                                                    ill_bg={"/" + exercise.illustration_background}
                                                    x={curr_draggable.x}
                                                    y={curr_draggable.y}
                                                    radius={curr_draggable.radius}
                                                    shape={Shapes.Rectangle}
                                                    points={curr_draggable.points}
                                                    onChange={this.onCoordinateChange.bind(this)}
                                                    taskValues={
                                                        curr_draggable.shape == Shapes.Circle ?
                                                            [
                                                                { key: "draggables#" + index + ".x", value: curr_draggable.x ? curr_draggable.x : 0 },
                                                                { key: "draggables#" + index + ".y", value: curr_draggable.y ? curr_draggable.y : 0 },
                                                                { key: "draggables#" + index + ".radius", value: curr_draggable.radius ? curr_draggable.radius : 0 }
                                                            ]
                                                            : [{ key: "draggables#" + index + ".points", value: curr_draggable.points ? curr_draggable.points : null }]
                                                    }
                                                    canvasInfo={{ arrName: "draggables", index: index, cRef: canvasRef }}
                                                    otherCircles={all_elements.filter((item: any) => (item.points == undefined && (item.x != curr_draggable.x || item.y != curr_draggable.y)))}
                                                    otherPolygons={all_elements.filter((item: any) => (item.points != undefined && item.points != curr_draggable.points))}
                                                />
                                            </div>
                                        </div>
                                    </ActionAccordionItem>
                                </Accordion>
                            )
                        }
                        )
                        : ""
                }
                <button className="button small" onClick={this.onAddNewDraggable.bind(this)}><i className="fa fa-plus"></i>{__("Új behúzható elem hozzáadása")}</button>
            </Panel>
        </Panel>);
    }
}