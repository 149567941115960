import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ITableCategoryTypeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Táblázat információ
     *
     * -> meta.table_info ON DELETE noaction
     *
     */
    table_info_id?: number|null;
    /**
     * Metaadat kategória típus
     *
     * NOT NULL -> meta.category_type ON DELETE cascade
     *
     */
    category_type_id?: number;
}

/**
 *  meta.table_category_type - Táblázat - metakategória típus
 *
 *  Azt mondja meg, hogy melyik táblázatnak milyen típusú metakategóriái lehetnek
 */

export default class TableCategoryTypeCrud extends Crud<ITableCategoryTypeRecord> {
    public static TABLE_INFO_ID = 1023896404;
    public static getSchemaNameForClass() { return 'meta'; }
    public static getTableNameForClass() { return 'table_category_type'; }

    public static load: (id: number, aServer ?: Server) => Promise<TableCategoryTypeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ITableCategoryTypeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ITableCategoryTypeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ITableCategoryTypeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ITableCategoryTypeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ITableCategoryTypeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class TableCategoryTypeCrudClassProxy extends CrudClassProxy<ITableCategoryTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const tableCategoryTypeCrudClassProxy = new TableCategoryTypeCrudClassProxy(TableCategoryTypeCrud);
registerCrudClassProxy(tableCategoryTypeCrudClassProxy);
