import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, StatisticsResponse, ValidationResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { SudokuData } from "@src/component/exercise/engine/EKESudokuExerciseEngine/EKESudokuExerciseEngine";
import { AnswerElement } from '@src/component/exercise/models/ExerciseBaseClass';
import * as React from "react";
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type SudokuAnswerElement = {
    infinite_elements: boolean,
    strongline: number,
    show_drop_area?: boolean,
    answers: any[],
    rows: any[],
    sudoku_mode: string,
    imageanswers: boolean
}

enum SudokuMode {
    Chess = "chess",
    Coordinate = "coordinate",
    Normal = "normal",
}
export class EKESudokuConverter extends AExerciseTypeConverter {

    public hasTextAnswer = true;
    public hasImageAnswer = true;
    public hasTextImageAnswer = false;

    static MAX_ANSWER_NUM: number = 25;
    static MAX_ANSWER_CHAR: number = 3;

    render() {
        let curr_ex: SudokuAnswerElement = this.state.exercise as SudokuAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="imageanswers" checked={curr_ex.imageanswers || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Kép válaszlehetőségek")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="infinite_elements" checked={curr_ex.infinite_elements || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Végtelenszer behelyettesíthető válaszelemek")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="strongline" checked={!!curr_ex.strongline || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Vastag vonal")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="show_drop_area" checked={curr_ex.show_drop_area || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("Ejtési terület jelzése")}</label>
                    </div>
                </div>
                <div className="row">
                    <label>{__("Sudoku típusa")}
                        <select value={curr_ex.sudoku_mode} name={"sudoku_mode"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                            <option value={SudokuMode.Normal}>{__("Normál mód")}</option>
                            <option value={SudokuMode.Coordinate}>{__("Koordináta mód")}</option>
                            <option value={SudokuMode.Chess}>{__("Sakk mód")}</option>
                        </select></label>
                </div>
                <Panel header={__("Behúzandó elemek")} headingLevel={5}>
                    <div className="row">
                        {curr_ex.answers ?
                            curr_ex.answers.map((curr_ans: any, i) => {
                                return (
                                    <div className={(curr_ex.imageanswers ? "small-12 large-6 medium-12 columns" : "small-12 large-4 medium-6 columns")+" exe-editor-input-tile"} key={"curr_ans#" + i}>
                                        <legend><label className="exe-editor-fieldlabel-2">{__("Elem")} {i + 1}</label>
                                            <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "answers", i)}><i className="fa fa-trash"></i></button>
                                        </legend>
                                        {curr_ex.imageanswers ?
                                            <ExerciseFileSelect
                                                imagebasepath={this.props.imagebasepath}
                                                value={curr_ans.image || ""}
                                                onChange={this.handleImageChange.bind(this, "answers#" + i + ".image")}
                                                getFolderId={this.getFolderId.bind(this)}
                                                fileType={ExerciseFileTypes.Image}
                                            /> : ""
                                        }
                                        <label className="exe-image-select-label">{curr_ex.imageanswers ? __("Képaláírás") : __("Érték")}
                                        <span className="exe-editor-validation-msg">{this.props.validationMessages.get("answers[" + i + "].alt")}</span>
                                            <input type="text" name={"answers#" + i + ".alt"} data-index={i} value={curr_ans.alt}
                                                onBlur={this.onBlurEvent.bind(this)}
                                                onChange={this.handleInputChange.bind(this)} />
                                        </label>
                                    </div>
                                );
                            })
                            : ""
                        }
                    </div>
                    <div className="button-group">
                        <button className="button small" onClick={this.onAddNewElement.bind(this)}><i className="fa fa-plus"></i>{__("Új elem")}</button>
                        <button className="button small" disabled={curr_ex.imageanswers} onClick={this.onGenerateOneToNine.bind(this)}><i className="fa fa-plus"></i>{__("Generálás 1-9")}</button>
                    </div>
                    <div className="row">
                        <label className="exe-editor-label-description columns">{__("Minimum {min}, maximum {max} elem megengedett!", {max: EKESudokuConverter.MAX_ANSWER_NUM, min: EKESudokuConverter.QUIZ_MIN_ANS_NUMBER})}</label>
                    </div>
                </Panel>
                <Panel header={__("Sorok")} headingLevel={5}>
                    {
                        curr_ex.rows ?
                            curr_ex.rows.map((curr_row: any, index) => {

                                let actions: IAccordionAction[];
                                actions = [
                                    {
                                        title: __("Törlés"),
                                        type: AccordionActionType.Trash,
                                        action: this.removeElement.bind(this, "rows", index)
                                    },
                                    {
                                        title: __("Fel"),
                                        type: AccordionActionType.Up,
                                        action: this.moveUp.bind(this, "rows", index)
                                    },
                                    {
                                        title: __("Le"),
                                        type: AccordionActionType.Down,
                                        action: this.moveDown.bind(this, "rows", index)
                                    }
                                ];
                                return (
                                    <Accordion key={"rows_" + index}>
                                        <ActionAccordionItem defaultClosed key={"rows_" + index} actions={actions} title={(index + 1) + ". sor"}>
                                            {
                                                curr_row ?
                                                    curr_row.map((curr_element: any, index_j: any) => {
                                                        return (
                                                            <Panel key={"rows#" + index + "#" + index_j}>
                                                                <legend>
                                                                    <label className="exe-editor-fieldlabel-2">{__("Elem")} {index_j + 1}</label>
                                                                    <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "rows#" + index, index_j)}><i className="fa fa-trash"></i></button>
                                                                </legend>
                                                                <div className="row">
                                                                    <div className="large-4 small-12 columns">
                                                                        <label ><input type="checkbox" name={"rows#" + index + "#" + index_j + ".is_answer"} checked={curr_element.is_answer || false}
                                                                            onBlur={this.onBlurEvent.bind(this)}
                                                                            onChange={this.handleInputChange.bind(this)} />
                                                                            {__("Kitöltendő")}</label>
                                                                    </div>

                                                                    <div className="large-8 small-12 columns">
                                                                        <label className="exe-image-select-label">{__("Válasz elem")}
                                                                    <select value={curr_element.answer} name={"rows#" + index + "#" + index_j + ".answer"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                                                {this.createSelectItems()}
                                                                            </select>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Panel>
                                                        );
                                                    })
                                                    : ""
                                            }
                                            <div className="row">
                                                <button className="button small" name={"line#" + index + ".btn-add-cell"} onClick={this.onAddNewLineCell.bind(this, index)}><i className="fa fa-plus"></i>{__("Új cella")}</button>
                                                <label className="exe-editor-label-description columns">{__("Maximum {max}, minimum {min} elem soronként!", {max: EKESudokuConverter.FILLTABLE_MAX_COLUMN_NUM, min: EKESudokuConverter.FILLTABLE_MIN_COLUMN_NUM})}</label>
                                            </div>
                                        </ActionAccordionItem>
                                    </Accordion>)
                            })
                            : ""}
                    <div className="row">
                        <button className="button small" onClick={this.onAddNewLine.bind(this)}><i className="fa fa-plus"></i>{__("Új sor")}</button>
                        <label className="exe-editor-label-description columns"> {__("A sorok száma minimum {min}, maximum {max} lehet!",{min:EKESudokuConverter.FILLTABLE_MIN_ROWS_NUM, max:EKESudokuConverter.FILLTABLE_MAX_ROWS_NUM})} </label>
                    </div>
                </Panel>
            </Panel>
        );
    }

    createSelectItems() {
        let answers = this.state.exercise.answers;
        let items = [];
        for (let i = 0; i < answers.length; i++) {
            if (this.state.exercise.imageanswers) items.push(<option key={i} value={answers[i].image}>{answers[i].alt ? answers[i].alt : answers[i].image}</option>);
            else items.push(<option key={i} value={answers[i].alt}>{answers[i].alt}</option>);
        }
        return items;
    }

    onAddNewElement() {
        let temp_exercise = this.state.exercise as SudokuAnswerElement;
        temp_exercise.answers.push({ image: "", alt: "" });
        if(this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    onGenerateOneToNine() {
        let temp_exercise = this.state.exercise as SudokuAnswerElement;
        temp_exercise.answers = [];
        for (let i = 1; i < 10; i++) {
            temp_exercise.answers.push({image: "", alt: i.toString()});
        }
        if(this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid){
            this.setState({ exercise: temp_exercise });
            this.onBlurEvent();
        }
    }

    onAddNewLineCell(i: number) {
        let temp_exercise = this.state.exercise as SudokuAnswerElement;
        temp_exercise.rows[i].push({ is_answer: false, answer: temp_exercise.answers[0].alt ? temp_exercise.answers[0].alt : "" });
        if(this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    onAddNewLine() {
        let temp_exercise = this.state.exercise as SudokuAnswerElement;
        temp_exercise.rows.push([]);
        if(this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    public convertToEditorAnswer(exercise: any): SudokuAnswerElement | undefined {
        if (exercise) {
            var rowList = [];
            var answerList: any[] = [];
            if (exercise.options) {
                for (let i = 0; i < exercise.options.length; i++) {
                    let tempValue = exercise.imageanswers ? exercise.options[i].image : exercise.options[i].text;
                    if (!tempValue.replace(/\s/g, '').length) {
                        exercise.imageanswers ? exercise.options[i].image = "#ÜRES#" : exercise.options[i].text = "#ÜRES#";
                    }
                    if (exercise.imageanswers && !answerList.includes(exercise.options[i].image)) {
                        answerList.push({ image: exercise.options[i].image, alt: exercise.options[i].text });
                    } else if (!exercise.imageanswers && !answerList.includes(exercise.options[i].text)) {
                        answerList.push({image: "", alt: exercise.options[i].text});
                    }
                }
                var sol_count = 0;
                if (exercise.fix_elements) {
                    for (let i = 0; i < exercise.fix_elements.length; i++) {
                        var rowCellList = [];
                        if (exercise.fix_elements[i]) {
                            for (let k = 0; k < exercise.fix_elements[i].length; k++) {
                                var curr_ans;
                                var tempValue = exercise.imageanswers ? exercise.fix_elements[i][k].image : exercise.fix_elements[i][k].text;
                                if (tempValue == "") {
                                    let temp_el = answerList[exercise.solution[sol_count]];
                                    let temp_ans = temp_el ? (temp_el.image ? temp_el.image.toString() : temp_el.alt.toString()) : "";
                                    curr_ans = {
                                        is_answer: true,
                                        answer: temp_ans
                                    };
                                    sol_count++;
                                }
                                else {
                                    curr_ans = {
                                        is_answer: false,
                                        answer: exercise.imageanswers ? exercise.fix_elements[i][k].image.toString() : exercise.fix_elements[i][k].text.toString()
                                    }
                                }
                                rowCellList.push(curr_ans);
                            }
                        }
                        rowList.push(rowCellList);
                    }
                }
            }
            var response = {
                strongline: exercise.stronglinevertical,
                infinite_elements: exercise.infinite_elements,
                show_drop_area: exercise.show_drop_area,
                rows: rowList,
                answers: answerList,
                sudoku_mode: exercise.sudoku_mode,
                imageanswers: exercise.imageanswers
            };
            return response;
        }
        else return undefined;
    }

    public convertToJson(answerList: any, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: SudokuAnswerElement): SudokuData | undefined {
        if (answerList) {
            var element_list = [];
            var ans_list = [];
            var sol_list = [];

            if (answerList.rows) {
                for (let i = 0; i < answerList.rows.length; i++) {
                    var row_list = [];
                    if (answerList.rows[i]) {
                        for (let j = 0; j < answerList.rows[i].length; j++) {
                            if(!answerList.rows[i][j].answer){
                                answerList.rows[i][j].answer = answerList.imageanswers ? answerList.answers[0].image.toString() : answerList.answers[0].alt.toString();
                            }
                            if (answerList.rows[i][j].is_answer) {
                                for (let k = 0; k < answerList.answers.length; k++) {
                                    let tempEl = answerList.imageanswers ? answerList.answers[k].image.toString() : answerList.answers[k].alt.toString();
                                   
                                   /* if(i == 3 && j==3){
                                        console.log("tempEl", answerList.answers[k], answerList.rows[i][j].answer, "is same", tempEl == answerList.rows[i][j].answer  );
                                    }*/
                                    if (tempEl == answerList.rows[i][j].answer.toString()) {
                                        sol_list.push(k);
                                        break;
                                    }
                                }
                                let newAns: AnswerElement = {
                                    type: answerList.imageanswers ? "image" : "text",
                                    text: answerList.imageanswers ? answerList.rows[i][j].alt : "",
                                    image: ""
                                };
                                row_list.push(newAns);
                            }
                            else {
                                let newAns: AnswerElement = {
                                    type: answerList.imageanswers ? "image" : "text",
                                    text: answerList.imageanswers ? answerList.rows[i][j].alt : answerList.rows[i][j].answer,
                                    image: answerList.imageanswers ? answerList.rows[i][j].answer : ""
                                };
                                row_list.push(newAns);
                            }
                        }
                    }
                    element_list.push(row_list);
                }
            }

            for (let i = 0; i < answerList.answers.length; i++) {
                let newAns: AnswerElement = {
                    type: answerList.imageanswers ? "image" : "text",
                    text: answerList.answers[i].alt,
                    image: answerList.imageanswers ? answerList.answers[i].image : ""
                };
                ans_list.push(newAns);
            }

            var strongline = 0;
            if (answerList.strongline && answerList.strongline == true) {
                strongline = Math.sqrt(ans_list.length)
            }

            var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
            cur_exercise = {
                ...cur_exercise,
                stronglinevertical: strongline,
                stronglinehorizontal: strongline,
                fix_elements: element_list,
                options: ans_list,
                solution: sol_list,
                infinite_elements: answerList.infinite_elements,
                show_drop_area: answerList.show_drop_area,
                sudoku_mode: answerList.sudoku_mode,
                imageanswers: answerList.imageanswers
            };
            return cur_exercise;
        }
        else return undefined;
    }

    public validate(editorAnswer: SudokuAnswerElement, baseData: any, validationMap?:Map<string,string>, is_accessible?:boolean|null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData,validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        let errorMsg="";
        let OFIErrors: string[] = [];
        if (editorAnswer.answers.length < EKESudokuConverter.QUIZ_MIN_ANS_NUMBER || (editorAnswer.answers.length > EKESudokuConverter.MAX_ANSWER_NUM)){
            errorMsg = __("Lehetséges válaszok száma {min} és {max} között lehet.", {min: EKESudokuConverter.QUIZ_MIN_ANS_NUMBER, max: EKESudokuConverter.MAX_ANSWER_NUM});
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg}
        }

        let rowsValid = this.makeValidBeforeSetState(null, editorAnswer);
        if(!rowsValid.valid) return rowsValid;

        for (let j = 0; j < editorAnswer.answers.length; j++) {
            let curr_ans = editorAnswer.answers[j];
            if (curr_ans.image != "" && curr_ans.alt.length > EKESudokuConverter.MAX_ALT_TEXT_LENGTH){
                errorMsg = __("A képleírás max.") + EKESudokuConverter.MAX_ALT_TEXT_LENGTH + " " + __("karakter lehet!")
                if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                validationMap!.set("answers[" + j + "].alt", errorMsg);
                if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg }
            }
            if (curr_ans.image == "" && curr_ans.alt.length > EKESudokuConverter.MAX_ANSWER_CHAR && curr_ans.alt != "#ÜRES#"){
                errorMsg = __("A sudoku elem hossza max. {max} karakter lehet!", {max: EKESudokuConverter.MAX_ANSWER_CHAR});
                if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                validationMap!.set("answers[" + j + "].alt", errorMsg);
                if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg }
            }
        }
        if (AExerciseTypeConverter.isOfiEditor){
            return { valid: true, message: OFIErrors.join(' , ') }
        }
        
        return {valid: true};
    }
    
    public makeValidBeforeSetState(oldState: SudokuAnswerElement|null, newState: SudokuAnswerElement): ValidationResponse {
        if(AExerciseTypeConverter.isOfiEditor ) return {valid:true};
        if (newState.answers.length > EKESudokuConverter.MAX_ANSWER_NUM){
            newState.answers.splice(EKESudokuConverter.MAX_ANSWER_NUM, newState.answers.length - EKESudokuConverter.MAX_ANSWER_NUM);
            return { valid: false, message: __("Lehetséges válaszok száma {min} és {max} között lehet.", {min: EKESudokuConverter.QUIZ_MIN_ANS_NUMBER, max: EKESudokuConverter.MAX_ANSWER_NUM})};
        }

        if (newState.rows.length > EKESudokuConverter.FILLTABLE_MAX_ROWS_NUM){
            newState.rows.splice(EKESudokuConverter.FILLTABLE_MAX_ROWS_NUM, newState.rows.length - EKESudokuConverter.FILLTABLE_MAX_ROWS_NUM);
            return { valid: false, message: __("A sorok száma {min} és {max} között lehet.", {min: EKESudokuConverter.FILLTABLE_MIN_ROWS_NUM, max: EKESudokuConverter.FILLTABLE_MAX_ROWS_NUM}) }
        }

        for (let i = 0; i < newState.rows.length; i++) {
            if (newState.rows[i].length > EKESudokuConverter.FILLTABLE_MAX_COLUMN_NUM){
                newState.rows[i].splice(EKESudokuConverter.FILLTABLE_MAX_COLUMN_NUM, newState.rows[i].length - EKESudokuConverter.FILLTABLE_MAX_COLUMN_NUM);
                return { valid: false, message: __("Soron belül az elemek száma {min} és {max} között lehet.", {min: EKESudokuConverter.FILLTABLE_MIN_COLUMN_NUM, max: EKESudokuConverter.FILLTABLE_MAX_COLUMN_NUM}) }
            }
        }
        return { valid: true }
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        statisticsResponses.push({name:__("limit - max. elem karakterszám: {n}", {n: EKESudokuConverter.MAX_ANSWER_CHAR}), count:undefined});
        statisticsResponses.push({name:__("limit - max. elemszám: {n}", {n: EKESudokuConverter.MAX_ANSWER_NUM}), count:undefined});
        statisticsResponses.push({name:__("limit - min. elemszám: {n}", {n: EKESudokuConverter.QUIZ_MIN_ANS_NUMBER}), count:undefined});
        let answersStat, rowsStat, rowElemStat, answersTextStat: StatisticsResponse;
        answersStat = { name: __("Lehetséges elemek száma"), count: new Map() }
        answersTextStat = { name: __("Válaszok hossza"), count: new Map() }
        rowsStat = { name: __("Sorok száma"), count: new Map() }
        rowElemStat = { name: __("Elemek száma a sorban"), count: new Map() }

        for (let i = 0; i < exerciseList.length; i++) {
            const currExc = exerciseList[i];
            let answer_count = answersStat.count.has(currExc.answers.length) ? answersStat.count.get(currExc.answers.length) : 0;
            answersStat.count.set(currExc.answers.length, answer_count! + 1);

            let rows_count = rowsStat.count.has(currExc.rows.length) ? rowsStat.count.get(currExc.rows.length) : 0;
            rowsStat.count.set(currExc.rows.length, rows_count! + 1);
            if (currExc.answers)
                for (let k = 0; k < currExc.answers.length; k++) {
                    const curr_answer = currExc.answers[k];
                    if (curr_answer){
                        let answer_text_count = answersTextStat.count!.has(curr_answer.alt.length) ? answersTextStat.count!.get(curr_answer.alt.length) : 0;
                        answersTextStat.count!.set(curr_answer.alt.length, answer_text_count! + 1);
                    }
                }
            if (currExc.rows)
                for (let j = 0; j < currExc.rows.length; j++) {
                    const curr_row = currExc.rows[j];
                    if (curr_row){
                        let elem_count = rowElemStat.count.has(curr_row.length) ? rowElemStat.count.get(curr_row.length) : 0;
                        rowElemStat.count.set(curr_row.length, elem_count! + 1);
                    }
                }
        }
        statisticsResponses.push(answersStat, answersTextStat, rowsStat, rowElemStat);
        return statisticsResponses;
    }
}