import './style.css';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { ExerciseBaseClass } from '@src/component/exercise/models/ExerciseBaseClass';
import { ExerciseEngineHelper } from '@src/component/exercise/engine/ExerciseEngineHelper';
import { element } from 'prop-types';
import { PuzzleGameServer } from './PuzzleGameServer';
import { List } from 'lodash';

export interface PuzzleGameData extends ExerciseBaseClass {
    illustration: string,
    hard_mode: boolean,
    num_of_rows: number,
    num_of_columns: number,
    alternative_image?: string
}

export class PuzzleGame extends AExerciseEngine {

    private answerDivList: HTMLElement[] = [];
    private answerContainer: HTMLElement;
    private polySVG: any;
    private answerItemCircleRadius: number = 10;
    private rows: number;
    private columns: number;
    private areaWidth: number;
    private areaHeight: number;
    private index: number = 0;
    private lastRootHeight: number;
    private lastRootWidth: number;
    private imageDropArea: HTMLImageElement;
    private illustrationPath: string = "";

    initExercise(params: ExerciseParams): void {
        //setting up the game basics
        super.initExercise(params);
        let exercise: PuzzleGameData = params.exercise;
        this.root.classList.add("puzzle-image-area");

        this.rows = exercise.num_of_rows;
        this.columns = exercise.num_of_columns;

        if (!exercise || !exercise.illustration || exercise.illustration == "") return;

        let originalSrc: string = exercise.imagebasepath + exercise.illustration
        this.illustrationPath = originalSrc.replace("/api/media/file/", "/api/media/max_width/2048/");

        //makeing the base grid
        let gridDiv: HTMLElement = this.root.appendChild(document.createElement("div"));
        gridDiv.classList.add("exercise-wrapper");

        let imageWrap: HTMLElement = gridDiv.appendChild(document.createElement("div"));
        imageWrap.classList.add("area-wrap");

        //makeing the puzzle holder
        let imageContainer: HTMLElement = imageWrap.appendChild(document.createElement("div"));
        imageContainer.classList.add("image-container");

        //inserting puzzle background
        let divDropArea: HTMLElement = imageContainer.appendChild(document.createElement("div"));
        divDropArea.classList.add("image-drop-area-div");
        this.imageDropArea = divDropArea.appendChild(document.createElement("img"));
        if (exercise.alternative_image) {
            let imageDropAreaSrc = ExerciseEngineHelper.getMediaMaxWidthUrl(exercise.imagebasepath + exercise.alternative_image);
            this.imageDropArea.setAttribute("src", imageDropAreaSrc);
        } else {
            this.imageDropArea.setAttribute("src", this.illustrationPath);
        }
        this.imageDropArea.classList.add("drop-div", "exe-img-no-zoom");
        this.imageDropArea.setAttribute("for", "img" + 1);
        this.imageDropArea.style.opacity = !exercise.hard_mode ? "0.5" : "0";
        this.imageDropArea.onload = this.onIllustrationLoaded.bind(this);

        window.onresize = this.resize.bind(this, this.imageDropArea);

        //answer grid
        let answerwrapper: HTMLElement = gridDiv.appendChild(document.createElement('div'));
        answerwrapper.classList.add('answer-wrap');
        this.answerContainer = answerwrapper.appendChild(document.createElement("div"));
        this.answerContainer.classList.add("answer-container");
        this.answerContainer.setAttribute("id", "answer-container");
    }

    onIllustrationLoaded(ev:any) {
        const svgNS = "http://www.w3.org/2000/svg";
        this.answerDivList = [];
        this.answerContainer.innerHTML = "";
        this.index = 0;

        //getting the image pieces dimensions
        const piecesWidth = this.imageDropArea.naturalWidth / this.columns;
        const piecesHeight = this.imageDropArea.naturalHeight / this.rows;

        //createing the base are for polygons
        this.createBaseDisplayArea(this.imageDropArea.parentElement!, this.imageDropArea);
        this.areaWidth = this.polySVG.clientWidth / this.columns;
        this.areaHeight = this.polySVG.clientHeight / this.rows;

        const heightInPercent: number = (10 / this.rows) * 10;

        let x = 0;
        let polyarea = this.root.getElementsByClassName("polygon-area") ? this.root.getElementsByClassName("polygon-area")[0] : null;
        //creating the pieces and the polygons
        for (let i = 0; i < this.rows; i++) {
            for (let j = 0; j < this.columns; j++) {
                let piece: HTMLDivElement = this.answerContainer.appendChild(document.createElement("div"));
                piece.classList.add("puzzle", "answer-img", "answer-div", "cell", "answer-div-fit");
                piece.style.maxHeight = heightInPercent + "%";
                piece.style.height = this.areaHeight + "px";
                piece.style.width = this.areaWidth + "px";

                this.createCircleWithSVG(piece, this.answerItemCircleRadius, "green", svgNS, this.index, "answerCircle");

                let innerDiv: HTMLDivElement = piece.appendChild(document.createElement("div"));
                innerDiv.classList.add('exe-image-wrap');

                let image: HTMLCanvasElement = innerDiv.appendChild(document.createElement("canvas"));
                let context = image!.getContext('2d');
                image.width = piecesWidth;
                image.height = piecesHeight;
                let base_image = new Image();
                base_image.src = this.illustrationPath;
                base_image.onload = function () { context!.drawImage(base_image, -j * piecesWidth, -i * piecesHeight); }
                image.style.position = "relative";
                //image.style.display = "inline-block";
                image.classList.add("exe-image-core");
                piece.setAttribute("data-index", this.index + "");
                //piece.setAttribute("id", this.index + "");
                this.answerDivList.push(piece);

                piece.onclick = this.click.bind(this);

                if (!this.isReplay) {
                    ($(piece) as any).draggable({
                        start: this.drag.bind(this),
                        stop: this.drop.bind(this),
                        containment: this.root,
                        scroll: true
                    });
                }

                if (polyarea) {
                    this.displayAreas(i, j, this.areaWidth, this.areaHeight, polyarea.childNodes[x]);
                } else {
                    this.displayAreas(i, j, this.areaWidth, this.areaHeight, null);
                }

                this.index++;
                x++;
            }

        }

        //shuffle
        if (!this.exercise.ordered_answers)
            for (var i = this.answerContainer.children.length; i >= 0; i--) {
                this.answerContainer.appendChild(this.answerContainer.children[Math.random() * i | 0]);
            }

        /* Click-to-click simulation */
        if (!this.isReplay) {
            AExerciseEngine.simulateDrag({
                draggableItems: this.answerDivList,
                clickArea: this.root,
                excludedItemClasses: ['icon-shrinkgrow']
            });
        }

    }
    getUserSolution() {
        let correct: boolean = true;
        let resultArray: Array<boolean> = [];
        const divDropArea = (this.root.getElementsByClassName("image-drop-area-div")[0]);
        const polygons = this.root.getElementsByTagName("polygon");
        for (let i = 0; i < this.index; i++) {
            let currentChild = divDropArea.querySelector(".image-dropped[data-index='" + i + "']");
            //If we don't have the puzzle piece dropped, we just push false and continue
            if(!currentChild) {
                resultArray.push(false);
                continue;
            }
            //If we have it, we check if it's in the good place
            AExerciseEngine.removeEvalStyle(<HTMLElement>currentChild);
            let polyBound: DOMRect = <DOMRect>polygons[i].getBoundingClientRect();
            let pieceBound: DOMRect = <DOMRect>currentChild.getBoundingClientRect();
            if (Math.abs(polyBound.left - pieceBound.left) < 1 && Math.abs(polyBound.top - pieceBound.top) < 1) {
                resultArray.push(true);
            }
            else {
                resultArray.push(false);
                correct = false;
            }
        }
        let solution = { answer: resultArray, fullmatch: correct };
        return solution;
    }

    receiveEvaluation(evaluated: Evaluated): void {
        let answCont: HTMLDivElement = <HTMLDivElement>this.root.querySelector(".answer-container");
        let userSolution = this.getUserSolution().answer;
        for (let i = 0; i < this.index; i++) {
            let element = this.root.querySelector(".image-dropped[data-index='" + i + "']") as HTMLElement;
            //If we haven't dropped that element, we just continue
            if(!element) continue;
            //In SNI mode, we just drop back the element, if it's on the wrong place
            if (this.isSNIexc) {
                if (!userSolution[i]) AExerciseEngine.moveElementBack(this.RemoveStylesBeforeMoveElementBack(element), answCont!, this.root);
            }
            //In normal mode we just color the piece
            else {
                userSolution[i] ? element.classList.add("exe-engine-check-correct") : element.classList.add("exe-engine-check-wrong");
            }
        }
    }

    showCorrectSolution(solution: any) {
        
        this.imageDropArea.onload = null;       
        const divDropArea:HTMLDivElement|null = this.root.querySelector(".image-drop-area-div");
        if(!divDropArea) return;
        
        let answerDivs:any = this.root.querySelectorAll(".answer-div");

        //We need to append every puzzle piece div to the illustration, before we position them
        answerDivs.forEach((d:HTMLDivElement) => {
            divDropArea.appendChild(d);
            d.classList.add('image-dropped');
            d.style.position = 'absolute';
         });

        const dviDropAreaBounds: DOMRect = <DOMRect>divDropArea.getBoundingClientRect();
        const polygons = this.root.getElementsByTagName("polygon");
        for (let i = 0; i < this.index; i++) {
            let currentChild: HTMLDivElement | null = this.root.querySelector("div[data-index='" + i + "']");
            const currentPolyDim: DOMRect = <DOMRect>polygons[i].getBoundingClientRect();

            let evL: number = currentPolyDim.left - dviDropAreaBounds.left;
            let evT: number = currentPolyDim.top - dviDropAreaBounds.top;
            let evR: number = dviDropAreaBounds.right - currentPolyDim.right;
            let evB: number = dviDropAreaBounds.bottom - currentPolyDim.bottom;

            evL = evL / dviDropAreaBounds.width * 100;
            evT = evT / dviDropAreaBounds.height * 100;
            evR = evR / dviDropAreaBounds.width * 100;
            evB = evB / dviDropAreaBounds.height * 100;
            currentChild!.style.left = evL + "%";
            currentChild!.style.top = evT + "%";
            currentChild!.style.right = evR + "%";
            currentChild!.style.bottom = evB + "%";
            currentChild!.classList.remove("exe-engine-check-wrong");
            currentChild!.classList.add("exe-engine-check-correct");
        }
    }

    isUserReady(): boolean {
        return true;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }


    removeFitMode(droppedItem: HTMLElement) {
        droppedItem.style.left = '';
        droppedItem.style.top = '';
        droppedItem.style.right = '';
        droppedItem.style.bottom = '';
        droppedItem.classList.remove('answer-div-fit');
    }

    /* Set element to fit the target area */
    setFitMode(droppedItem: HTMLElement, areaContainer: HTMLElement) {
        let targetNodes = areaContainer.querySelectorAll('svg polygon');
        let targetPolys = Array.from(targetNodes) as HTMLDivElement[];

        let dimCont: DOMRect = <DOMRect>areaContainer.getBoundingClientRect();
        let droppedItemSVG: any = droppedItem.querySelector('svg');

        /* Check which polygon is hit*/
        let hitCheck: any = AExerciseEngine.hitTestOld(droppedItemSVG as HTMLDivElement, targetPolys);

        if (hitCheck != false) {
            let hitParent = areaContainer.querySelector('polygon[data-index="' + hitCheck.id + '"]');

            if (hitParent) {
                let hitParentDim: DOMRect = <DOMRect>hitParent.getBoundingClientRect();

                let evL: number = hitParentDim.left - dimCont.left;
                let evT: number = hitParentDim.top - dimCont.top;
                let evR: number = dimCont.right - hitParentDim.right;
                let evB: number = dimCont.bottom - hitParentDim.bottom;

                evL = evL / dimCont.width * 100;
                evT = evT / dimCont.height * 100;
                evR = evR / dimCont.width * 100;
                evB = evB / dimCont.height * 100;

                droppedItem.style.left = evL + "%";
                droppedItem.style.top = evT + "%";
                droppedItem.style.right = evR + "%";
                droppedItem.style.bottom = evB + "%";
                droppedItem.classList.add('answer-div-fit');
            }
        }
    }




    drag(ev: any) {
        let currElement: HTMLElement = ev.target as HTMLElement;
        currElement.children[1].children[0].classList.remove("dropped-piece");

        let parentDiv: HTMLElement = (ev.target.parentElement as HTMLElement);
        if (parentDiv.classList.contains("answer-container")) {
            this.cloneAnswerElement(ev.target,parentDiv)
        }

        /* Remove FitMode properties on drag start */
        this.removeFitMode(currElement);
        let circle: any = $(ev.target).find("circle")[0];
        circle.style.visibility = "visible";

        this.answerDivList.forEach(element => {
            element.style.opacity = "0.3";
            element.style.zIndex = "1";
        });
        currElement.style.zIndex = "10";
        currElement.style.opacity = "0.6";
    }

    drop(ev: any) {
        this.answerDivList.forEach(element => {
            element.style.opacity = "1";
        });
        AExerciseEngine.removeEvalStyle(ev.target);
        ev.target.style.zIndex = 1;

        /* Check if dropped outside the areas */
        let answCont: HTMLDivElement | null = this.root.querySelector(".answer-container");
        let imageArea: HTMLDivElement | null = this.root.querySelector(".image-drop-area-div");

        let hasArea: any = (answCont && imageArea ? AExerciseEngine.hitTest(ev, [answCont, imageArea]) : null);
        if (!hasArea || !hasArea.overlap) {
            hasArea.id = 0;
        }

        if (ev.target.getAttribute("data-index").length != 0) {
            let circle: any = $(ev.target).find("circle")[0];
            circle.style.visibility = "hidden";
            if (hasArea.id == 0) {
                // Drop back to answer container
                let dragIndex: number = Number(ev.target.getAttribute('data-index'));
                if (typeof dragIndex == 'number') {
                    ev.target.classList.remove('image-dropped');
                    ev.target.style.position = 'relative';
                    ev.target.style.left = '';
                    ev.target.style.top = '';
                    ev.target.setAttribute('data-dropped', '0');
                    ev.target.removeAttribute('data-x');
                    ev.target.removeAttribute('data-y');
                    this.removeFitMode(ev.target);
                    AExerciseEngine.moveElementBack(ev.target, answCont!, this.root);
                }
                //}
            } else {
                /*
                set dropX and dropY according to their children. That is an svg which contains a circle, but the property getBoundingClientRect has to be shifted 
                by the value of width and height og the circle
                */

                let dropX: number = ev.target.children[0].getBoundingClientRect().left + ev.target.children[0].width.animVal.value / 2;
                let dropY: number = ev.target.children[0].getBoundingClientRect().top + ev.target.children[0].width.animVal.value / 2;

                ev.target.setAttribute('data-x', String(dropX));
                ev.target.setAttribute('data-y', String(dropY));
                ev.target.setAttribute('data-dropped', '1');
                ev.target.children[1].children[0].classList.add("dropped-piece");

                this.afterDrop(ev);
            }
        }
    }

    afterDrop(ev: any) {
        let elem: HTMLElement = ev.target;
        let elemSVG: any =  elem.querySelector('svg');
        let answCont: HTMLDivElement = <HTMLDivElement>this.root.querySelector(".answer-container");
        let imgCont: HTMLDivElement = <HTMLDivElement>this.root.querySelector('.image-drop-area-div');

        /* Check if there is already an element, if yes, move that back */
        let targetNodes:NodeList = <NodeList>imgCont.querySelectorAll('svg polygon');
        let targetPolys = Array.from(targetNodes) as HTMLDivElement[];

        /* Current elem check, where*/
        let elemHitCheck: any = AExerciseEngine.hitTest(ev, targetPolys);

        /* Previously dropped elements */
        let droppedNodes:NodeList = <NodeList>imgCont.querySelectorAll('[data-dropped="1"]');
        let droppedArray = Array.from(droppedNodes) as HTMLDivElement[];

        let droppedHitCheck: any = AExerciseEngine.hitTestOld(elemSVG as HTMLDivElement, droppedArray);

        imgCont.querySelectorAll('[data-dropped="1"]').forEach((dropped: HTMLElement) => {
            if (dropped != elem) {
                let droppedSVG: any =  dropped.querySelector('svg');
                let droppedHitCheck: any = AExerciseEngine.hitTestOld(droppedSVG as HTMLDivElement, targetPolys);
                /* If the same cell */
                if (droppedHitCheck.id == elemHitCheck.id) {
                    /* Move back previous */
                    dropped.classList.remove('image-dropped');
                    dropped.style.position = 'relative';
                    AExerciseEngine.removeEvalStyle(dropped);
                    this.removeFitMode(dropped);
                    dropped.setAttribute('data-dropped', '0');
                    dropped.removeAttribute('data-x');
                    dropped.removeAttribute('data-y');
                    AExerciseEngine.moveElementBack(dropped, answCont!, this.root);
                }
            }
        });

        let dimElem: DOMRect = <DOMRect>elem.getBoundingClientRect();
        let elemX: number = dimElem.left;
        let elemY: number = dimElem.top;
        let halfWidth: number = dimElem.width / 2;
        let halfHeight: number = dimElem.height / 2;
        let mrgnX: number = Number(elem.style.marginLeft);
        let mrgnY: number = Number(elem.style.marginTop);

        let dimCont: DOMRect = <DOMRect>imgCont.getBoundingClientRect();
        imgCont.appendChild(elem);
        /*
        let distX: string = "calc(" + ((elemX - dimCont.left - mrgnX + halfWidth) / dimCont.width * 100) + "% - " + halfWidth + "px)";
        let distY: string = "calc(" + ((elemY - dimCont.top - mrgnY + halfHeight) / dimCont.height * 100) + "% - " + halfHeight + "px)";
        */
        let distX: string = ((elemX - dimCont.left - mrgnX) / dimCont.width * 100) + "%";
        let distY: string = ((elemY - dimCont.top - mrgnY) / dimCont.height * 100) + "%";

        elem.style.position = 'absolute';
        elem.style.left = distX;
        elem.style.top = distY;

        var x = getComputedStyle(elem).left;
        var elemWidthHalved = elem.getBoundingClientRect().width / 2;

        //the elem moved beyond the picture, maximize/minimize its position

        if (parseInt(x!, 10) > dimCont.width - elemWidthHalved)
            elem.style.left = dimCont.width - elemWidthHalved + "px";
        else if (parseInt(x!, 10) < (elemWidthHalved * -1))
            elem.style.left = (elemWidthHalved * -1) + "px";

        elem.classList.add('image-dropped');

        //let areaCont = this.root.querySelector('.image-drop-area-div') as HTMLElement;
        if (this.lastRootWidth != this.root.getBoundingClientRect().width || this.lastRootHeight != this.root.getBoundingClientRect().height) {
            this.resize(document.getElementsByClassName("drop-div exe-img-no-zoom")[0] as HTMLImageElement);
            this.lastRootHeight = this.root.getBoundingClientRect().height;
            this.lastRootWidth = this.root.getBoundingClientRect().width;
        }

        /* Unhide placeholder item */
        answCont!.childNodes.forEach((element: HTMLElement) => {
            element.classList.remove("item-hidden"); });

        /* Fit element to the nearest area */
        this.setFitMode(elem, imgCont);
        /* On the fly checking */
        if (this.isSNIexc) this.SNIEvaluation(PuzzleGameServer);
    }

    createBaseDisplayArea(divDropArea: HTMLElement, imageDropArea: HTMLElement) {
        if (this.root.getElementsByClassName("polygon-area").length > 0) return;
        this.polySVG = divDropArea.appendChild(document.createElementNS("http://www.w3.org/2000/svg", "svg"));
        this.polySVG.setAttribute("height", String(imageDropArea.clientHeight));
        this.polySVG.setAttribute("width", String(imageDropArea.clientWidth));
        this.polySVG.setAttribute("align", "left");
        this.polySVG.setAttribute("z-index", "10");
        this.polySVG.classList.add("polygon-area");
        this.polySVG.style.position = "absolute";
        this.polySVG.style.left = String(imageDropArea.offsetLeft);
        this.polySVG.style.top = String(imageDropArea.offsetTop);
    }

    // displaying drop areas.
    displayAreas(i: number, j: number, width: number, height: number, existingPolyArea: any) {
        var polyArea;
        if (!existingPolyArea) {
            polyArea = this.polySVG.appendChild(document.createElementNS("http://www.w3.org/2000/svg", "polygon"));
            polyArea.setAttribute("id", "poly-" + this.index);
            polyArea.setAttribute("data-index", String(this.index));
        } else { polyArea = existingPolyArea; }

        let topLeft: any = [j * width, i * height];
        let topRight: any = [(j + 1) * width, i * height];

        let bottomLeft: any = [j * width, (i + 1) * height];
        let bottomRight: any = [(j + 1) * width, (i + 1) * height];

        let cornersToString: string = topLeft[0] + " " + topLeft[1] + "," + topRight[0] + " " + topRight[1] + "," + bottomRight[0] + " " + bottomRight[1] + "," + bottomLeft[0] + " " + bottomLeft[1];

        polyArea.setAttribute("points", cornersToString);
        polyArea.classList.add("poly");
    }

    click(ev: any) {
        let data = ev.target.getAttribute('data-index') != null ? Number(ev.target.getAttribute('data-index')) : Number(ev.target.closest('.answer-div').getAttribute('data-index'));

        for (let index = 0; index < this.answerDivList.length; index++) {
            if (Number(this.answerDivList[index].getAttribute('data-index')) == data)
                this.answerDivList[index].style.zIndex = "10";
            else
                this.answerDivList[index].style.zIndex = "1";
        }
    }

    containerResized() {
        this.resize(this.imageDropArea);
    }

    resize(illustrationImage: HTMLImageElement) {
        //Changing the size of the polygon area svg dynamically according to the illustration image
        let imgWidth = illustrationImage.clientWidth;
        let imgHeight = illustrationImage.clientHeight;

        let polyArea = this.root.getElementsByClassName("polygon-area")[0] as HTMLElement;
        polyArea.setAttribute("height", String(imgHeight));
        polyArea.setAttribute("width", String(imgWidth));
        this.areaWidth = this.polySVG.clientWidth / this.columns;
        this.areaHeight = this.polySVG.clientHeight / this.rows;
        let x = 0;
        for (let i = 0; i < this.rows; i++) {
            for (let j = 0; j < this.columns; j++) {
                this.displayAreas(i, j, this.areaWidth, this.areaHeight, polyArea.childNodes[x] as HTMLElement);
                x++;
            }
        }

        /* Correct dropped item */
        let dropArea = this.root.querySelector('.image-container');
        if (dropArea) {
            let droppedItemRect = dropArea.getBoundingClientRect();
            let x = droppedItemRect.left + droppedItemRect.width / 2;
            let y = droppedItemRect.top + droppedItemRect.height / 2;

            dropArea.setAttribute('data-x', x + '');
            dropArea.setAttribute('data-y', y + '');
        }

        let answerItems = this.root.querySelectorAll(".answer-img");
        for (let index = 0; index < answerItems.length; index++) {
            (answerItems[index] as HTMLElement).style.width = this.areaWidth + "px";
            (answerItems[index] as HTMLElement).style.height = this.areaHeight + "px";
        }

    }

    
    createCircleWithSVG(parentDiv: HTMLElement, circleRadius: number, color: string, svgNS: string, index: number, idString: string) {
        let imgCont: HTMLDivElement = <HTMLDivElement>document.getElementsByClassName('puzzle-image-area')[0].getElementsByClassName('image-drop-area-div')[0];
        let dimCont: DOMRect = <DOMRect>imgCont.getBoundingClientRect();
        let contWidth: number = dimCont.width;

        var radius = circleRadius;
        var isAnswer = idString.includes('answer');

        if (!isAnswer)
            radius = radius / 100 * contWidth;

        var circleSVG = parentDiv.appendChild(document.createElementNS("http://www.w3.org/2000/svg", "svg")); //svg for wrapping the circle
        circleSVG.setAttribute("height", String(radius * 2));                                               //width and height is the diameter of the circle
        circleSVG.setAttribute("width", String(radius * 2));
        circleSVG.setAttribute("align", "left");
        circleSVG.setAttribute("z-index", "10");
        //in case of the dropdivs /which are not answer elements/ we have to correct the margin according to the proper placing of the circle
        if (!isAnswer) {
            circleSVG.style.marginTop = String((radius * -1) + 3) + "px"
            circleSVG.style.marginLeft = String((radius * -1) + 3) + "px"
        } else {
            circleSVG.style.position = "absolute";
            circleSVG.style.left = "50%";
            circleSVG.style.top = "50%";
            circleSVG.style.marginTop = String((radius * -1)) + "px";
            circleSVG.style.marginLeft = String(radius * -1) + "px";
            circleSVG.style.zIndex = "10";
        }
        var answerItemCircle = circleSVG.appendChild(document.createElementNS(svgNS, "circle")); //to create a circle. for rectangle use "rectangle"
        answerItemCircle.setAttribute("id", idString + index);
        answerItemCircle.setAttribute("cx", String(radius));
        answerItemCircle.setAttribute("cy", String(radius));
        answerItemCircle.setAttribute("r", String(radius));
        answerItemCircle.setAttribute("position", "relative");
        answerItemCircle.setAttribute("fill", color);

        if (isAnswer) {
            answerItemCircle.setAttribute("fill-opacity", "0.8")
            answerItemCircle.setAttribute("stroke", "black");
            answerItemCircle.setAttribute("stroke-width", "2");
            answerItemCircle.setAttribute("visibility", "hidden");
        }
        else {
            answerItemCircle.setAttribute("fill-opacity", "0.2");
            answerItemCircle.setAttribute("stroke", "black");
            answerItemCircle.setAttribute("stroke-width", "1");
            answerItemCircle.setAttribute("stroke-dasharray", "10,10");
            answerItemCircle.classList.add("radius-circle");
        }
        answerItemCircle.setAttribute("align", "center");
    }

    RemoveStylesBeforeMoveElementBack(element: HTMLElement)
    {
        this.removeFitMode(element);
        element.classList.remove('image-dropped');
        element.style.position = 'relative';
        element.style.left = '';
        element.style.top = '';
        element.setAttribute('data-dropped', '0');
        element.removeAttribute('data-x');
        element.removeAttribute('data-y');
        return element;
    }
}
