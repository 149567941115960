import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IPartnerStatusRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Címke
     */
    label?: string;
}

/**
 *  sys.partner_status - Állapot (partner)
 */

export default class PartnerStatusCrud extends Crud<IPartnerStatusRecord> {
    public static TABLE_INFO_ID = 1168070761;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'partner_status'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<PartnerStatusCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IPartnerStatusRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IPartnerStatusRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IPartnerStatusRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IPartnerStatusRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IPartnerStatusRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class PartnerStatusCrudClassProxy extends CrudClassProxy<IPartnerStatusRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const partnerStatusCrudClassProxy = new PartnerStatusCrudClassProxy(PartnerStatusCrud);
registerCrudClassProxy(partnerStatusCrudClassProxy);
