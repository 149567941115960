import './style.css';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { ExerciseEngineHelper } from '@src/component/exercise/engine/ExerciseEngineHelper';
import { ExerciseBaseClass, AnswerElement, AnswerTypes } from '../../models/ExerciseBaseClass';
import { shuffle, ShuffleResult } from '../../models/AExerciseTypeConverter';
import { MemoryCardGameServer } from './MemoryCardGameServer';

export interface MemoryCardGameData extends ExerciseBaseClass {
    matchDifferent: boolean,
    elements: AnswerElement[],
    differentElements: AnswerElement[],
    cardBackground: string
}

export type MemoryCardAnswers = {
    element: AnswerElement,
    solutionIndex: number
}
export class MemoryCardGame extends AExerciseEngine { 

    private firstCard:HTMLElement | null;
    private secondCard:HTMLElement | null;
    private lockBoard:boolean;
    private hasFlippedCard:boolean;
    private matchCounter:number;
    private solutionLength:number;

    initExercise(params: ExerciseParams): void {
        if (!params.element || !params.exercise) return;

        super.initExercise(params);
        let exercise: MemoryCardGameData = params.exercise;
        this.setIllustration(exercise,this.root);

        let exeContainer = this.root.appendChild(document.createElement("section"));
        exeContainer.setAttribute("id", "exerciseContainer");
        exeContainer.classList.add("memory-game");
        
        this.lockBoard = false;
        this.hasFlippedCard = false;
        this.matchCounter = 0;
        this.solutionLength = exercise.elements.length;
        
        let answers: MemoryCardAnswers[] = [];
        for (let i = 0; i < exercise.elements.length; i++) {
            const element = exercise.elements[i];
            answers.push({ element: element, solutionIndex: i });
        }

        let concattedElems;
        if (exercise.matchDifferent){
            let diffAnswers = [];
            for (let i = 0; i < exercise.differentElements.length; i++) {
                const diff_element = exercise.differentElements[i];
                diffAnswers.push({ element: diff_element, solutionIndex: i });
            }
            concattedElems = answers.concat(diffAnswers);
        }
        else {
            concattedElems = answers.concat(answers);
        }

        let elements: ShuffleResult = shuffle(concattedElems);

        for (let i = 0; i < elements.answers.length; i++) {
            let curr_elem = elements.answers[i].element;
            let cardElem = exeContainer.appendChild(document.createElement("div"));
            cardElem.classList.add("memory-game-memory-card");
            cardElem.setAttribute("data-index", elements.answers[i].solutionIndex.toString());

            if (!this.isReplay) {
                cardElem.addEventListener('click', this.flipCard.bind(this, cardElem), false);
            }

            let cardFace = cardElem.appendChild(document.createElement("div"));
            cardFace.classList.add("memory-game-front-face");

            /* If media type is image, limit max width */
            if (curr_elem.type == 'image') {
                curr_elem.image = ExerciseEngineHelper.getMediaMaxWidthUrl(curr_elem.image);
            }
            AExerciseEngine.displayAnswer(cardFace, curr_elem, this.is_accessible, ["exe-img-no-zoom"], this);
            let cardBack = cardElem.appendChild(document.createElement("div"));
            cardBack.classList.add("memory-game-back-face");
            if (exercise.cardBackground && exercise.cardBackground != "") {
                cardBack.style.backgroundImage = "url(" + exercise.imagebasepath + "" + exercise.cardBackground + ")";
                cardBack.style.backgroundSize = "cover";
                cardBack.style.backgroundPosition = "center";
            }else{
                cardBack.style.backgroundColor = "rgb(126, 176, 252)";
            }
        }
    }


    getUserSolution() {
        let solution = { answer: [], fullmatch: true };
        return solution;
    }

    receiveEvaluation(evaluated: Evaluated): void {
    }

    showCorrectSolution(solution: any) {
        this.flipAll();
    }

    isUserReady(): boolean {
        return true;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

    flipAll(){
        let allCards = document.getElementsByClassName("memory-game-memory-card");
        if (allCards)
            for (let i = 0; i < allCards.length; i++) {
                let currCard = allCards[i];
                if (!currCard.classList.contains("flip"))
                    currCard.classList.add("flip");
            }
    }

    flipCard(cardElem:HTMLElement){
        if (this.lockBoard) return;

        if (cardElem.classList.contains("card-correct") || (this.firstCard && this.firstCard.classList.contains("card-correct"))) return;
        if (cardElem === this.firstCard) return;
        new Audio('/audio/exercise/card_flip.wav').play();
        cardElem.classList.add('flip');

        if (!this.hasFlippedCard) {
            this.hasFlippedCard = true;
            this.firstCard = cardElem;
            return;
        }

        this.secondCard = cardElem;
        this.checkForMatch();
    }

    checkForMatch() {
        if (this.firstCard != null && this.secondCard != null) {
            let isMatch = this.firstCard.getAttribute("data-index") === this.secondCard.getAttribute("data-index");
            if(isMatch) {
            //Good answer
                this.matchCounter++;
                let audio = new Audio('/audio/exercise/good_answer.wav');
                if(this.matchCounter == this.solutionLength) {
                    //If we solved the whole memory game
                    audio.src = '/audio/exercise/clapping.wav';
                    this.SNIEvaluation(MemoryCardGameServer);
                }
                audio.play();
                this.disableCards();
            } else {
                //Wrong answer
                new Audio('/audio/exercise/wrong_answer.wav').play();
                this.unflipCards();
            }
        }
    }

    disableCards() {
        if (this.firstCard != null && this.secondCard != null) {
            this.firstCard.removeEventListener('click', this.flipCard.bind(this, this.firstCard));
            this.secondCard.removeEventListener('click', this.flipCard.bind(this, this.secondCard));
            this.firstCard.classList.add("card-correct");
            this.secondCard.classList.add("card-correct");
            this.evaluateCards();
            this.resetBoard();
        }
    }
    
    unflipCards() {
        this.lockBoard = true;   
        this.evaluateCards();
        setTimeout(() => {
            if (this.firstCard != null && this.secondCard != null) {
                this.firstCard.classList.remove('flip');
                this.secondCard.classList.remove('flip');
                this.removeEvalStyles();
                this.resetBoard();
            }
        }, 1500);
    }

    resetBoard() {
        this.hasFlippedCard = false;
        this.lockBoard = false;
        this.firstCard = null;
        this.secondCard = null;
    }

    evaluateCards() {
        if (this.firstCard)
            if (this.firstCard.classList.contains("card-correct"))
                this.firstCard.classList.add("exe-engine-check-correct");
            else
                this.firstCard.classList.add("exe-engine-check-wrong");

        if (this.secondCard)
            if (this.secondCard.classList.contains("card-correct"))
                this.secondCard.classList.add("exe-engine-check-correct");
            else
                this.secondCard.classList.add("exe-engine-check-wrong");
    }

    removeEvalStyles(){
        if (this.firstCard)
            this.firstCard.classList.remove('exe-engine-check-correct', 'exe-engine-check-wrong');
        if (this.secondCard)
            this.secondCard.classList.remove('exe-engine-check-correct', 'exe-engine-check-wrong');
    }
}
