import * as React from 'react';
import { formatDateWithoutTime } from '@src/Util';
import { IDayItem, DAY_OF_WEEK_LONG_NAMES, DAY_TYPE_NAMES } from './CalendarAPI';
import { app } from '@src/index';
import ActivityLogCrud, { IActivityLogRecord } from '@src/framework/crud/usr/ActivityLogCrud';
import { me } from '@src/framework/server/Auth';
import { debounce } from 'lodash';
import ActivityMonthCrud, { IActivityMonthRecord } from '@src/framework/crud/usr/ActivityMonthCrud';
import { BubbleLoader } from 'react-css-loaders';
import { activityTypeCrudClassProxy } from '@src/framework/crud/usr/ActivityTypeCrud';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import { confirmDialog, alertDialog } from '../Dialog';
import { observable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { appStore } from '@src/store/AppStore';
import { __ } from '@src/translation';

export interface IDayActivityProps {
    item: IDayItem | null;
    onItemSaved: (item: IActivityLogRecord) => void;
    onItemDeleted: (item: IActivityLogRecord) => void;
}

@observer
export default class DayActivity extends React.PureComponent<IDayActivityProps> {
    private _debouncedAsyncReload: () => void;
    @observable private loading: boolean;
    @observable private saving: boolean;
    @observable private activityMonth: IActivityMonthRecord | null;
    @observable private origActivityLogs: IActivityLogRecord[];
    @observable private activityLogs: IActivityLogRecord[];
    @observable private newLogItem: IActivityLogRecord;

    constructor(props: IDayActivityProps) {
        super(props);
        this._debouncedAsyncReload = debounce(this.asyncReload, 100);
        this.loading = false;
        this.saving = false;
        this.activityMonth = null;
        this.origActivityLogs = [];
        this.activityLogs = [];
        this.newLogItem = {};
    }

    @action.bound private setLoading() { this.loading = true; }
    @action.bound private clearLoading() { this.loading = true; }
    @action.bound private setSaving() { this.saving = true; }
    @action.bound private clearSaving() { this.saving = false; }

    public componentDidUpdate(prevProps: IDayActivityProps) {
        const item1 = prevProps.item;
        const item2 = this.props.item;
        if ((item1 === null) !== (item2 === null)) {
            this.debouncedAsyncReload();
        } else if (item1 && item2) {
            if (item1.date.getTime() != item2.date.getTime()) {
                this.debouncedAsyncReload();
            }
        }
    }

    private debouncedAsyncReload = async () => {
        this.setLoading();
        this._debouncedAsyncReload();
    }

    public componentDidMount() {
        this.debouncedAsyncReload();
    }

    private asyncReload = async () => {
        this.setLoading();
        try {
            const item = this.props.item;
            let activityMonth :IActivityMonthRecord|null = null;
            let activityLogs: IActivityLogRecord[] = [];
            if (item) {
                const year = item.date.getFullYear();
                const month = item.date.getMonth() + 1;
                const mc = await ActivityMonthCrud.list({
                    filter: {
                        year, month, sec_user_id: me!.id
                    }
                });
                activityMonth = (mc && mc.length) ? mc[0] : null;
                if (activityMonth) {
                    activityLogs = await ActivityLogCrud.list({
                        filter: {
                            is_active: true,
                            activity_month_id: activityMonth.id!,
                            day: item.date.getTime()
                        }
                    });
                }
            }
            runInAction(() => {
                this.loading = false;
                this.activityMonth = activityMonth;
                this.activityLogs = activityLogs;
                this.origActivityLogs = activityLogs.concat([]) ;
            });
        } catch (error) {
            this.clearLoading();
            app.showErrorFromJsonResult(error);
        }
    }

    @action.bound private setLogItemFieldValue(fieldName: string, fieldValue: any, index: number) {
        if (index >= 0) {
            const activityLogs = this.activityLogs;
            const log = activityLogs[index];
            log[fieldName] = fieldValue;
            log["changed"] = true;
            // kell ez? this.activityLogs = activityLogs
        } else {
            const newLogItem = this.newLogItem;
            newLogItem[fieldName] = fieldValue;
            newLogItem["changed"] = true;
            // kell ez? this.newLogItem = newLogItem;
            appStore.myMunyiCalendar.hasChange = true;
        }
        this.updateHasChange();
    }

    private cancelChanges = async (index: number) => {
        if (await confirmDialog(__("Megerősítés"), __('Biztos benne, hogy elveti a módosításokat?'))) {
            runInAction(() => {
                if (index >= 0) {
                    let activityLogs = this.activityLogs;
                    activityLogs[index] = this.origActivityLogs[index];
                } else {
                    this.newLogItem = {};
                }
                this.updateHasChange();
            })
        }
    }

    private updateHasChange = () => {
        let changed = false;
        const logs = this.activityLogs;
        for (let i = 0; i < logs.length; i++) {
            if (logs[i]["changed"]) {
                changed = true;
                break;
            }
        }
        if (this.newLogItem["changed"]) {
            changed = true;
        }
        appStore.myMunyiCalendar.hasChange = changed;
    }

    private async checkRecord(record: IActivityLogRecord): Promise<boolean> {
        const hours = parseInt("" + record.hours);
        if (!hours || hours <= 0 || hours > 24) {
            await alertDialog(
                __("Hiba"),
                __("Az eltöltött idő csak 0-nál nagyobb, 24-nél kisebb szám lehet."));
            return false;
        }
        if (!record.activity_type_id && !record.description) {
            await alertDialog(
                __("Hiba"),
                __("Az aktivitás típus és a leírása közül legalább az egyiket meg kell adni."));
            return false;
        }
        if (record.activity_type_id == 100 && !record.activity_type) {
            await alertDialog(
                __("Hiba"),
                __("Az 'Egyéb' aktivitás típushoz kötelező megadni a szöveges típus leírást."));
            return false;
        }
        return true;
    }

    private saveChanges = async (index: number) => {
        this.setSaving();
        try {
            // Ha még nincs aktivitás erre a hónapra...
            let activityMonth = this.activityMonth;
            const item = this.props.item!;
            if (!activityMonth) {
                activityMonth = (await new ActivityMonthCrud({
                    sec_user_id: me!.id,
                    year: item.date.getFullYear(),
                    month: item.date.getMonth() + 1,

                }).put()).record;
            }
            if (!activityMonth.is_active || !this.activityMonth) {
                runInAction(() => {
                    this.activityMonth = activityMonth;
                    this.saving = false;
                });
                alertDialog(__("Hiba"), __("Ez a hónap már le van zárva, nem módosíthat benne."));
                return;
            }
            const isNew: boolean = index < 0;
            if (isNew) {
                let record = { ... this.newLogItem };
                if (!await this.checkRecord(record)) {
                    this.clearSaving();
                    return;
                }
                record.activity_month_id = this.activityMonth!.id;
                delete record["changed"];
                record.day = item.day!.day;
                record.hours = parseInt("" + record.hours);
                let saved = (await new ActivityLogCrud(record).put()).record;
                let activityLogs = this.activityLogs.concat([saved]);
                let newLogItem = {};
                runInAction(() => {
                    this.saving = false;
                    this.activityLogs = activityLogs;
                    this.newLogItem = newLogItem;                    
                });
                this.updateHasChange();
                this.props.onItemSaved(saved);
                app.showSuccess(__("Sikeres művelet"), __("Új aktivitás rögzítve."));
            } else {
                const activityLogs = this.activityLogs;
                const record = { ...activityLogs[index] };
                if (!await this.checkRecord(record)) {
                    this.clearSaving();
                    return;
                }
                delete record["changed"];
                delete record["creation_time"];
                delete record["creation_user_id"];
                delete record["creation_session_id"];
                delete record["modification_time"];
                delete record["modification_user_id"];
                delete record["modification_session_id"];
                delete record["is_active"];
                record.day = item.day!.day;
                record.hours = parseInt("" + record.hours);
                let saved = (await new ActivityLogCrud(record).put()).record;
                runInAction(() => {
                    this.activityLogs[index] = saved;
                    this.saving = false;
                })
                this.updateHasChange();
                this.props.onItemSaved(saved);
                app.showSuccess(__("Sikeres művelet"), __("Aktivitás módosítva."));
            }

        } catch (error) {
            this.clearSaving();
            app.showErrorFromJsonResult(error);
        }
    }

    private deleteRecord = async (index: number) => {
        const activityMonth = this.activityMonth!;
        if (!activityMonth.is_active) {
            this.clearLoading();
            alertDialog(__("Hiba"), __("Ez a hónap már le van zárva, nem módosíthat benne."));
            return;
        }

        if (await confirmDialog(
            __("Megerősítés"), __('Biztos benne, hogy törli ezt a bejegyzést?'))) {
            this.setSaving();
            try {
                const item = this.activityLogs[index]!;
                const newItem = await ActivityLogCrud.deleteById(item.id!);
                runInAction(()=> {
                    this.activityLogs[index] = newItem;
                    this.saving = false;
                });
                this.updateHasChange();
                this.props.onItemDeleted(newItem);
                app.showWarning(__("Törlés sikeres"), __("Az aktivitás törölve lett."));
            } catch (error) {
                this.clearSaving()
                app.showErrorFromJsonResult(error);
            }
        }
    }

    private logItemEditor = (log: IActivityLogRecord, index: number) => {
        if (log.id && !log.is_active) {
            return null;
        }
        const changed: boolean = log["changed"] || false;
        let className = "row expanded eke-calendar-activity-log-editor" + (changed ? "-changed" : "");
        return <div className={className} key={"log-editor-" || log.id ? log.id : index}>
            <div className="column small-12 medium-8">
                <label>
                    { __("Aktivitás típusa") }
                <CrudSelectComponent
                        crudClassProxy={activityTypeCrudClassProxy}
                        value={log.activity_type_id || null}
                        onSelect={
                            (sender: CrudSelectComponent, newValue: number | null) => {
                                this.setLogItemFieldValue("activity_type_id", newValue, index)
                            }
                        }
                        displayFieldName="title"
                        orderByFieldName="no"
                    />
                </label>
            </div>
            <div className="column small-12 medium-4">
                <label>
                    { __("Eltöltött idő (óra)") }
                <input type="number"
                        value={log.hours || ""}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.setLogItemFieldValue(
                                "hours", event.target.value, index)
                        }}

                    />
                </label>
            </div>
            {log.activity_type_id === 100 ?
                <div className="column small-12">
                    <label>
                        { __("Típus szöveges leírása") }
                            <input type="text" placeholder={ __("A tevékenység típus szöveges leírása") }
                            value={log.activity_type || ""}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setLogItemFieldValue(
                                    "activity_type", event.target.value, index)
                            }}
                        />
                    </label>
                </div>

                : null}
            <div className="column small-12">
                <label>
                    { __("Leírás (opcionális)") }
                <textarea placeholder={ __("A tevékenység szöveges leírása") }
                        value={log.description || ""}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                            this.setLogItemFieldValue(
                                "description", event.target.value, index)
                        }}
                    />
                </label>
            </div>
            <div className="column small-12">
                <button className="button secondary"
                    disabled={!changed || this.saving}
                    onClick={() => this.cancelChanges(index)}
                >
                    <i className="fa fa-times" /> { __("Mégse") }
            </button>
                <button className="button alert"
                    disabled={index < 0 || this.saving}
                    onClick={() => this.deleteRecord(index)}
                >
                    <i className="fa fa-trash" /> { __("Törlés") }
            </button>
                <button className="button primary"
                    disabled={!changed || this.saving}
                    onClick={() => this.saveChanges(index)}
                >
                    <i className="fa fa-save" /> { __("Mentés") }
            </button>
            </div>
        </div>
    }


    private logItemsEditor = () => {
        return <>
            {this.activityLogs.map((log, index) => this.logItemEditor(log, index))}
            {this.logItemEditor(this.newLogItem, -1)}
        </>;
    }

    public render() {
        const item = this.props.item;
        if (!item) { return null; }
        const dow = item.date.getDay();
        return <div className="row expanded">
            <div className="column small-12">
                <h3>
                    <span className="date">{formatDateWithoutTime(item.date)}</span> 
                    <span className="day">{DAY_OF_WEEK_LONG_NAMES[dow]}</span>
                </h3>
                <h4><span className={"eke-calendar-day-type-" + item.day.day_type_id}>
                    {DAY_TYPE_NAMES[item.day.day_type_id]}
                </span></h4>
            </div>
            {item.day.description ?
                <div className="column small-12">
                    <div className="callout">{item.day.description}</div>
                </div>
                : null}
            {this.loading ? <BubbleLoader /> :
                <div className="column small-12">
                    {this.logItemsEditor()}
                </div>}
        </div>
    }

}


