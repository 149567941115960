export class FillGapTextServer {
    static evaluateOnServer(exercise: any, correctSolution: any[], userSolution: any[]): Evaluated {
        const num_of_questions = correctSolution.length;
        let correct = 0;
        for (let i = 0; i < num_of_questions; i++) {
            for (let j = 0; j < correctSolution[i].length; j++) {
                const userSol = exercise.exercise.case_sensitive ? userSolution[i] : userSolution[i].toUpperCase();
                const corrSol = exercise.exercise.case_sensitive ? correctSolution[i][j] : correctSolution[i][j].toUpperCase();
                if(userSol == corrSol) {
                    correct++;  
                    break;
                }              
            }
        }
        return { "success": correct == num_of_questions, "solution": correctSolution, "successPercent": Number((correct / num_of_questions).toFixed(2)) }
    }
}