import * as React from 'react';
import 'react-table/react-table.css'
import { BubbleLoader } from 'react-css-loaders';

import { app } from '@src/index';
import { me } from '@src/framework/server/Auth';

import InstituteSiteTeacherCrud from '@src/framework/crud/sys/InstituteSiteTeacherCrud';
import { InstituteSiteTeacherStates } from '../institute/sys_institute_site_teacher_listview';
import ViewInstituteSiteTeacher, { IViewInstituteSiteTeacherRecord } from '@src/framework/view/sys/ViewInstituteSiteTeacher';


import { HeaderNotificationStore } from '../HeaderNotification';
import ReactTable from 'react-table';
import { getReactTableLabels } from '@src/framework/i18n';
import { __ } from '@src/translation';

export type TeacherInvitationsLoadedEvent = (invitations: IViewInstituteSiteTeacherRecord[]) => void;

export interface IMyInstituteTeacherInvitationsState {
    loading: boolean;
    saving: boolean;      
}

export interface IMyInstituteTeacherInvitationsProps {
    invitations: IViewInstituteSiteTeacherRecord[];
    onTeacherInvitationsLoaded: TeacherInvitationsLoadedEvent;
    allowSelfAssign : boolean;
}

export default class MyInstituteTeacherInvitations extends React.Component<IMyInstituteTeacherInvitationsProps, IMyInstituteTeacherInvitationsState> {
    constructor(props: IMyInstituteTeacherInvitationsProps) {
        super(props);
        this.state = { loading: false, saving: false };
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        this.setState({ loading: true, saving: false });
        try {
            let institutesiteteachers: IViewInstituteSiteTeacherRecord[] = [];
            if (me && me.id) {
                institutesiteteachers = await ViewInstituteSiteTeacher.list({
                    filter: { sec_user_id: me.id },
                    order_by: "institute_site_name"
                });
            }
            this.props.onTeacherInvitationsLoaded(institutesiteteachers);
            this.setState({ loading: false, saving: false });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        };
    }


    private _getConfirmTitle = (row: IViewInstituteSiteTeacherRecord): string => {
        return row.om_identifier! + ' ' + row.institute_name! + '\n' +
            row.site_identifier! + ' ' + row.institute_site_name! + '\n\n';
    }



    private _onChangeTeacherInvitationState = async (
        row: IViewInstituteSiteTeacherRecord,
        newStateId: number,
        confirmMessage: string
    ) => {
        if (confirm(this._getConfirmTitle(row) + confirmMessage)) {
            try {
                const rec = await new InstituteSiteTeacherCrud({
                    id: row.id,
                    status_id: newStateId
                }).put();
                const invitations = await ViewInstituteSiteTeacher.list({
                    filter: { sec_user_id: me!.id },
                    order_by: "institute_site_name"
                });
                HeaderNotificationStore.changed();
                this.props.onTeacherInvitationsLoaded(invitations);
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    private onAcceptTeacherInvitation = (row: IViewInstituteSiteTeacherRecord) => {
        this._onChangeTeacherInvitationState(row, InstituteSiteTeacherStates.ACTIVE_ID,
            __('Belép a hely tanárai közé?')
        );
    }

    private onRefuseTeacherInvitation = (row: IViewInstituteSiteTeacherRecord) => {
        this._onChangeTeacherInvitationState(row, InstituteSiteTeacherStates.INVITATION_REFUSED_ID,
            __('Elutasítja a meghívást?')
        );
    }

    private onLeaveInstituteSiteTeacher = (row: IViewInstituteSiteTeacherRecord) => {
        this._onChangeTeacherInvitationState(row, InstituteSiteTeacherStates.LEFT_ID,
            __('Kijelentkezik a hely tanárai közül?'));
    }


    render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        }
        if (!me) {
            return <div className="callout alert">{ __("Ez a funkció csak bejelentkezett felhasználók számára érhető el.") }</div>;
        }
        
        const data = this.props.invitations.filter((inv:IViewInstituteSiteTeacherRecord) => inv.status_id !== InstituteSiteTeacherStates.LEFT_ID);
        
        const tableProps = {
            columns: [
                /*
                { Header: __("OM azonosító"), accessor: "om_identifier", style: { margin: "auto 0" } },
                { Header: __("Hely azonosító"), accessor: "site_identifier", style: { margin: "auto 0" } },
                */
                {
                    Header: __("Ellátási hely"), accessor: "institute_site_name", style: { margin: "auto 0" },
                    Cell: (data: any, column: any) => {
                        const row: IViewInstituteSiteTeacherRecord = data.original;
                        return <span>
                            <small>{row.institute_name}</small><br/>
                            {row.institute_site_name}
                        </span>;
                    }
                },
                {
                    Header: __("Állapot"), accessor: "status_title", width: 180, style: { margin: "auto 0" },
                    Cell: (data: any, column: any) => {
                        const row: IViewInstituteSiteTeacherRecord = data.original;{/*TODO Translation */}
                        const log = __("Létrehozva: {creator} @ {creation_time}", {creator: row.creator, creation_time:row.creation_time }) + '\n' +
                            __('Módosítva: {details}', {details: (row.modifier ? (row.modifier + " @ " + row.modification_time) : __("Még nem volt módosítva"))});                                   
                        return <span>
                            {row.status_title}
                            &nbsp;
                            <i className="fa fa-info-circle"
                                style={{ cursor: "pointer" }}
                                title={log}
                                onClick={() => { alert(log) }}
                            />
                        </span>
                    }
                },
                {
                    Header: __("Művelet"), accessor: "link", filterable: false, sortable: false, minWidth: 180, maxWidth: 180, className: "action-cell",
                    Cell: (data: any, column: any) => {
                        const row: IViewInstituteSiteTeacherRecord = data.original;
                        const status_id: number = row.status_id!;
                        if (status_id == InstituteSiteTeacherStates.INVITATION_SENT_ID) {
                            return <>
                                <button className="button small success"
                                    onClick={() => { this.onAcceptTeacherInvitation(row) }}
                                >
                                    <i className="fa fa-user-check" />{" " + __("Elfogad")} </button>
                                <button className="button small alert"
                                    onClick={() => { this.onRefuseTeacherInvitation(row) }}
                                >
                                    <i className="fa fa-user-times" /> {__("Elutasít")}</button>
                            </>;
                        } else if (status_id == InstituteSiteTeacherStates.INVITATION_REVOKED_ID
                            || status_id == InstituteSiteTeacherStates.INVITATION_REFUSED_ID
                        ) {
                            return <button className="button small secondary disabled" disabled={true}>
                                <i className="fa fa-user-check" />{ __("Elfogad") }</button>
                        } else if (status_id == InstituteSiteTeacherStates.ACTIVE_ID) {
                            return <button className="button small alert"
                                onClick={() => { this.onLeaveInstituteSiteTeacher(row) }}
                            >
                                <i className="fa fa-user-minus" />{ __("Kilép") }</button>;
                        } else if (status_id == InstituteSiteTeacherStates.LEFT_ID && this.props.allowSelfAssign) {
                            return <button className="button small primary"
                                onClick={() => { this.onAcceptTeacherInvitation(row) }}
                            >
                                <i className="fa fa-user-plus" />{ __("Újra belép") }</button>;
                        } else {
                            return <span></span>;
                        }
                    }
                }
            ],
            data,
            filterable: false,
            defaultPageSize: 5,
            className: "-striped -highlight",
        }


        return <ReactTable {...tableProps} style={{ width: "100%" }} {...getReactTableLabels()} />;
    }
}