import './style.css';
import { ExerciseEngineHelper } from '@src/component/exercise/engine/ExerciseEngineHelper';
import { ExerciseBaseClass } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { EKEPairingServer } from '../EKEPairingExerciseEngine/EKEPairingServer';
import { PairingSolutionAssignment, EKEPairingDnDServer } from './EKEPairingDnDServer';
export interface PairingData extends ExerciseBaseClass {
    pair1: string[];
    pair2: string[];
    options: any;
    pair1images: boolean;
    pair2images: boolean;
    keywords: string;
    is_horizontal: boolean;
    infinite_elements: boolean;
    num_of_questions: number;
}

type PairingSolution = {
    key: number;
    value: number;
}
type PairingUserSolution = {
    fullmatch: boolean;
    answer: PairingSolution[];
}



export class EKEPairingDnDExerciseEngine extends AExerciseEngine {

    private divList: HTMLElement[] = [];
    private dropDivList: HTMLDivElement[] = [];
    private pair1values: string[] = [];
    private pair2values: string[] = [];
    private isHorizontal: boolean = false;
    private rowCont: HTMLElement;
    private infinite_elements: boolean = false;
    private addRowCont: HTMLElement;

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        let exercise: PairingData = params.exercise;

        if (!exercise || !exercise.options || exercise.options.length == 0) return;
        this.divList = [];
        this.dropDivList = [];
        this.pair1values = [];
        this.pair2values = [];
        this.exercise = exercise;
        if (exercise) this.exercise = exercise;

        this.root.classList.add("eke-pairing-drag-and-drop");
        let isSimpleStyle = document.getElementsByClassName("exe-container-simple").length > 0;
        window.onresize = this.resize.bind(this);
        this.isHorizontal = exercise.is_horizontal;

        if (exercise.is_horizontal) {
            this.root.setAttribute('data-horizontal','');
        }


        let gridDiv = this.root.appendChild(document.createElement("div"));
        gridDiv.classList.add("row");
        gridDiv.classList.add("exercise-wrapper");

        this.setIllustration(exercise, gridDiv);

        /* TGY: Mobilnezetben scrollozas */
        /* Kontenerek */
        let pair1HasImage = exercise.options.pair1.find((el: any) => {
            return el.type == "image";
        });

        let pair2HasImage = exercise.options.pair2.find((el: any) => {
            return el.type == "image";
        });

        let areaWrap = gridDiv.appendChild(document.createElement("div"));
        areaWrap.classList.add("area-wrap");

        let areaControls = areaWrap.appendChild(document.createElement("div"));
        areaControls.classList.add("area-controls");

        let answerWrap = gridDiv.appendChild(document.createElement("div"));
        answerWrap.classList.add("answer-wrap");

        let answerControls = answerWrap.appendChild(document.createElement("div"));
        answerControls.classList.add("answer-controls");

        let rowcontainer = areaWrap.appendChild(document.createElement("div"));
        this.rowCont = rowcontainer;
        rowcontainer.classList.add("row", "align-middle", "area-container");

        let additionalrowcontainer = areaWrap.appendChild(document.createElement("div"));
        this.addRowCont = additionalrowcontainer;
        additionalrowcontainer.classList.add("row", "align-middle", "area-container");
        additionalrowcontainer.style.display = "none";
        if (exercise.is_horizontal && window.innerWidth >= 1024) {
            rowcontainer.classList.add("vertical-row");
            if (isSimpleStyle) {
                additionalrowcontainer.classList.add("vertical-row");
                additionalrowcontainer.style.display = "block";
            }
        }

        let answerContainer = answerWrap.appendChild(document.createElement("div"));
        answerContainer.setAttribute("id", "answer-container");
        answerContainer.classList.add("answer-container");
        let realNumOfPair2s = exercise.num_of_questions ? exercise.options.pair2.length - (exercise.options.pair2.length - exercise.num_of_questions) : exercise.options.pair2.length;
        if (realNumOfPair2s <= 6 && window.innerWidth >= 1024 && pair1HasImage && isSimpleStyle) answerContainer.classList.add("centered-bigger-elements");
        for (let i = 0; i < exercise.options.pair1.length; i++) {
            if (exercise.options.pair1[i].type == "image") this.pair1values.push(exercise.options.pair1[i].image);
            else if (exercise.options.pair1[i].type == "sound") this.pair1values.push(exercise.options.pair1[i].url);
            else if (exercise.options.pair1[i].type == "text" && exercise.options.pair1[i].text && exercise.options.pair1[i].text != "") this.pair1values.push(exercise.options.pair1[i].text);
            else this.pair1values.push("");
        }
        for (let i = 0; i < exercise.options.pair2.length; i++) {
            if (exercise.options.pair2[i].type == "image") this.pair2values.push(exercise.options.pair2[i].image);
            else if (exercise.options.pair2[i].type == "sound") this.pair2values.push(exercise.options.pair2[i].url);
            else if (exercise.options.pair2[i].type == "text") this.pair2values.push(exercise.options.pair2[i].text);

        }

        let realElementCount = 0;
        let realNumOfPair1s = exercise.num_of_questions ? exercise.options.pair1.length - (exercise.options.pair1.length - exercise.num_of_questions) : exercise.options.pair1.length;
        for (let index = 0; index < this.pair1values.length; index++) {
            if (this.pair1values[index] == "") continue;
            let parentDiv;
            if (exercise.is_horizontal && window.innerWidth >= 1024 && realElementCount + 1 > Math.round(realNumOfPair1s / 2) && isSimpleStyle)
                parentDiv = additionalrowcontainer.appendChild(document.createElement("div"));
            else
                parentDiv = rowcontainer.appendChild(document.createElement("div"));

            parentDiv.classList.add("pairParentElement");
            if (realNumOfPair1s <= 6 && window.innerWidth >= 1024 && pair1HasImage && isSimpleStyle) parentDiv.classList.add("pair-bigger-image");

            let dropdiv = document.createElement("div");
            let dropDivId = "drop-div" + index;
            dropdiv.setAttribute("id", dropDivId);
            dropdiv.classList.add("drop-div");
            if (exercise.is_horizontal && window.innerWidth >= 1024) dropdiv.classList.add("vertical-flex");
            dropdiv.addEventListener('click', this.click.bind(this), false);
            ($(dropdiv) as any).droppable({
                accept: ".answer-div",
                classes: {
                    "ui-droppable-active": "ui-state-active",
                    "ui-droppable-hover": "ui-state-hover"
                },
                drop: function (event: any, ui: any) {
                    $(this)
                        .addClass("ui-state-highlight")
                },
                tolerance: "pointer"
            });

            this.dropDivList.push(dropdiv);
            let pair1Div = parentDiv.appendChild(document.createElement("div"));
            if (exercise.is_horizontal) pair1Div.classList.add("pair1Div-horizontal");
            let elementClassList: string[] = [];
            //képes válasz
            if (exercise.options.pair1[index].type == "image") {
                pair1Div.classList.add("pairing-img")
                if (isSimpleStyle)
                    elementClassList = ["bigger-image-div"];
                else
                    elementClassList = ["image-div"];
                
                //if (exercise.is_horizontal && window.innerWidth >= 1024) parentDiv.classList.add("vertical-image-div", "image-wrapper-div");    
            } else {
                elementClassList.push("string-inner-div");
                // if (exercise.is_horizontal && window.innerWidth >= 1024) parentDiv.classList.add("string-div-vertical","vertical-flex");                
            }
            if (exercise.is_horizontal && window.innerWidth >= 1024) parentDiv.classList.add("string-div-vertical", "vertical-flex");
            if (exercise.options.pair1[index].type == "sound") {
                elementClassList.push("sound-label");
            }

            AExerciseEngine.displayAnswer(pair1Div, exercise.options.pair1[index], this.is_accessible, elementClassList, this);
            parentDiv.appendChild(dropdiv);
            dropdiv.setAttribute("data-evindex", String(index));
            dropdiv.setAttribute("for", "img" + index);
            realElementCount++;
        }
        for (let index = 0; index < exercise.options.pair2.length; index++) {
            if (exercise.options.pair2[index].type == "" || (exercise.options.pair2[index].image == "" && exercise.options.pair2[index].text == "")) continue;
            //let answerdiv: HTMLElement = answerContainer.appendChild(document.createElement("div"));
            let answerdiv: HTMLElement = document.createElement("div");
            // hang válasz
            let elementClassList: string[] = [];
            if (exercise.options.pair2[index].type == "sound") {
                elementClassList.push("sound-label");
            } else if (exercise.options.pair2[index].type == "image") {
                elementClassList.push("answer-img");
            }
            AExerciseEngine.displayAnswer(answerdiv, exercise.options.pair2[index], this.is_accessible, elementClassList, this);
            answerdiv.classList.add("answer-div");
            answerdiv.setAttribute("data-evindex", String(index));

            answerdiv.setAttribute("draggable", "true");
            let new_id = "ans-div" + index;
            answerdiv.setAttribute("id", new_id);
            // answerdiv.addEventListener('dragstart', this.drag, false);

            /* Create draggable elements */
            if (!this.isReplay) {
                ($(answerdiv) as any).draggable({
                    start: this.drag.bind(this),
                    stop: this.drop.bind(this),
                    containment: this.root,
                    scroll: true,
                    helper: "clone"
                });
            }

            this.divList.push(answerdiv);
        }

        /* Click-to-click simulation */
        if (!this.isReplay) {
            AExerciseEngine.simulateDrag({
                draggableItems: this.divList,
                clickArea: this.root,
                excludedItemClasses: ['icon-shrinkgrow']
            });
        }

        let shuffledElements = exercise.ordered_answers ? this.divList : AExerciseEngine.shuffle(this.divList);
        for (let index = 0; index < shuffledElements.length; index++) {
            answerContainer.appendChild(shuffledElements[index]);
            let dataIndex = shuffledElements[index].getAttribute('data-evindex');
            if (exercise.options.pair2[dataIndex].type == "text") AExerciseEngine.shrinkAndGrow(shuffledElements[index]);
            shuffledElements[index].setAttribute('data-index', index + "");
        }

        /* TGY: Scrollozo */
        /* Gombok */
        let areaLeftButton = areaControls.appendChild(document.createElement("button"));
        areaLeftButton.classList.add("control", "area-control-left");

        let areaRightButton = areaControls.appendChild(document.createElement("button"));
        areaRightButton.classList.add("control", "area-control-right");

        let answerLeftButton = answerControls.appendChild(document.createElement("button"));
        answerLeftButton.classList.add("control", "answer-control-left");

        let answerRightButton = answerControls.appendChild(document.createElement("button"));
        answerRightButton.classList.add("control", "answer-control-right");

        /* Gomb esemenyek */
        areaRightButton.addEventListener("click", AExerciseEngine.xMove.bind(this, rowcontainer, 'right'), false);
        areaLeftButton.addEventListener("click", AExerciseEngine.xMove.bind(this, rowcontainer, 'left'), false);
        answerRightButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answerContainer, 'right'), false);
        answerLeftButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answerContainer, 'left'), false);

        this.dropDivList.push(answerContainer);

        /* TGY */

    }

    getUserSolution(): PairingUserSolution {
        let result: any[] = [];
        let res;
        for (let index = 0; index < this.dropDivList.length - 1; index++) {
            let curr_key = Number(this.dropDivList[index].getAttribute('data-evindex'));
            if (this.dropDivList[index].childNodes.length > 0 && (this.dropDivList[index].childNodes[0])) {
                let val = Number((this.dropDivList[index].childNodes[0] as HTMLElement).getAttribute('data-evindex')); //.attributes.getNamedItem("id").value);
                res = { key: curr_key, value: val };
            } else {
                res = { key: curr_key, value: null };
            }
            result.push(res);
        }
        let solution = { answer: result, fullmatch: true };
        return solution;
    }

    receiveEvaluation(evaluated: Evaluated): void {
        if (evaluated.success) {
            for (let index = 0; index < this.divList.length; index++) {
                if (this.divList[index].classList.contains('answer-container'))
                    continue;
                let htmlelement = this.divList[index] as HTMLElement;
                if (htmlelement) {
                    htmlelement.classList.remove('exe-engine-check-wrong');
                    htmlelement.classList.add("exe-engine-check-correct");
                }
            }
        } else {
            if (evaluated.solution != null) {
                let userSolution = this.getUserSolution().answer;
                let assignment: PairingSolutionAssignment[] = EKEPairingDnDServer.getPairAssignmentList(this.exercise, evaluated.solution);
                this.divList.forEach(el => {
                    if (!this.isSNIexc) el.classList.add("exe-engine-check-wrong");
                });

                if (!this.exercise) return;
                for (let i = 0; i < evaluated.solution.length; i++) {
                    if (!this.exercise.options.pair1[evaluated.solution[i].key].text && !this.exercise.options.pair1[evaluated.solution[i].key].image && !this.exercise.options.pair1[evaluated.solution[i].key].url) { // || !exercise.exercise.pair2[correctSolution[i].value]) {
                        let element = this.divList.find(function (element: HTMLElement) {
                            return element.getAttribute("data-evindex") == evaluated.solution[evaluated.solution[i].key].value.toString()
                        });
                        if (element) {
                            AExerciseEngine.removeEvalStyle(element);
                            if (element.classList.contains("dropped")) {
                                //user submitted odd one out answer
                                if (!this.isSNIexc) element.classList.add("exe-engine-check-wrong");
                            } else {
                                //user didn't submit odd one out answer
                                if (!this.isSNIexc) element.classList.add("exe-engine-check-correct");
                            }
                        }
                        continue;
                    }
                    if (!this.exercise.options.pair2[evaluated.solution[i].value].text && !this.exercise.options.pair2[evaluated.solution[i].value].image && !this.exercise.options.pair2[evaluated.solution[i].value].url) { // || !exercise.exercise.pair2[correctSolution[i].value]) {
                        if (userSolution[evaluated.solution[i].key].value != null) {
                            //user submited sg to odd one out box
                            let element = this.divList.find(function (element: HTMLElement) {
                                return element.getAttribute("data-evindex") == userSolution[evaluated.solution[i].key].value.toString()
                            });
                            AExerciseEngine.removeEvalStyle(element!);
                            if (!this.isSNIexc) element!.classList.add("exe-engine-check-wrong");
                        }
                        continue;
                    }
                    let curr_indx = evaluated.solution[i].key;
                    let found = userSolution.find(function (element: any) {
                        return element.key == curr_indx;
                    });

                    if (!found
                        || ((found.value == null || found.value == undefined) && this.exercise.options.pair2[evaluated.solution[i].value])) {
                        continue;
                    }

                    let foundPair1: any;
                    let foundPair2: any;

                    if (this.exercise.options.pair1[curr_indx].type == "image") foundPair1 = this.exercise.options.pair1[curr_indx].image
                    else if (this.exercise.options.pair1[curr_indx].type == "text") foundPair1 = this.exercise.options.pair1[curr_indx].text;
                    else if (this.exercise.options.pair1[curr_indx].type == "sound") foundPair1 = this.exercise.options.pair1[curr_indx].url;

                    if (this.exercise.options.pair2[found.value].type == "image") foundPair2 = this.exercise.options.pair2[found.value].image
                    else if (this.exercise.options.pair2[found.value].type == "text") foundPair2 = this.exercise.options.pair2[found.value].text;
                    else if (this.exercise.options.pair2[found.value].type == "sound") foundPair2 = this.exercise.options.pair2[found.value].url;

                    let correctPair2Found = assignment.find(function (element: PairingSolutionAssignment) {
                        return element.key == foundPair1 && element.value.indexOf(foundPair2) > -1
                    });

                    let element = this.dropDivList.find(function (element: HTMLElement) {
                        return element.getAttribute("data-evindex") == found!.key.toString()
                    });

                    let elementDropped = element!.childNodes[0] as HTMLElement;
                    AExerciseEngine.removeEvalStyle(elementDropped);

                    if (!correctPair2Found) {
                        //wrong solution to normal box
                        if (!this.isSNIexc) elementDropped.classList.add("exe-engine-check-wrong");
                        continue;
                    } else {
                        //good answer to normal box
                        elementDropped.classList.add("exe-engine-check-correct");
                    }
                }
            }
        }
        this.placeAnswersBackToStart();
    }

    xMove(div_container: HTMLDivElement, move_direction: string) {

        let container = div_container!;
        let direction = move_direction;
        let childs = container.childElementCount;
        let iwidth = container.scrollWidth / childs / 2;
        let styleLeft = container.style.left;
        if (styleLeft == '') {
            styleLeft = '0px';
        }
        let maxLeft = (container.parentNode! as any).offsetWidth - container.scrollWidth;
        if (maxLeft > 0) {
            maxLeft = 0;
        }
        let mathLeft = parseInt(styleLeft!, 10);

        switch (direction) {
            case 'left':
                mathLeft = mathLeft + iwidth;
                if (mathLeft > 0) {
                    mathLeft = 0;
                }
                break;
            case 'right':
                mathLeft = mathLeft - iwidth;
                if (mathLeft < maxLeft) {
                    mathLeft = maxLeft;
                }
                break;
            default:
                break;
        }
        container.style.left = mathLeft + 'px';
    }




    showCorrectSolution(solution: any): void {
        this.removePlaceHolders(this.root);
        for (let index = 0; index < solution.length; index++) {
            if (!this.dropDivList[index]) continue;
            if (this.dropDivList[index].classList.contains("answer-container"))
                continue;
            let true_index = Number(this.dropDivList[index].getAttribute("data-evindex"));
            let curr_solution = solution.find((element: any) => element.key == true_index);
            let element_to_add = this.divList.find(function (element: any) {
                return Number(element.getAttribute("data-evindex")) == curr_solution.value;
            });
            if (!element_to_add) continue;

            AExerciseEngine.removeEvalStyle(element_to_add);
            $(element_to_add).css("top", 0);
            $(element_to_add).css("left", 0);
            this.dropDivList[index].appendChild(element_to_add);
            element_to_add.classList.add("dropped-div");
            element_to_add.classList.add("dropped");
            if (!this.isReplay) {
                this.dropDivList[index].classList.add("eke-engine-show-correct-bg");
                this.divList.forEach(element => {
                    element.classList.remove("exe-engine-check-wrong");
                    if (!element.classList.contains("dropped")) element.classList.add("eke-engine-show-correct-highlight");
                });
            }
        }
    }

    isUserReady(): boolean {

        for (let index = 0; index < this.dropDivList.length - 1; index++) {
            if (this.dropDivList[index].innerHTML.length > 0) return true;
        }
        return false;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

    resize() {
        if (!this.isHorizontal) return;
        let wWidth = window.innerWidth;
        if (wWidth < 1024) {
            //We remove all of the vertical classes
            let verticalElements = this.root.querySelectorAll('.vertical-row, .vertical-flex, .vertical-image-div, .string-div-vertical, .image-wrapper-div');
            verticalElements.forEach(element => {
                element.classList.remove("vertical-row", "vertical-flex", "vertical-image-div", "string-div-vertical", "image-wrapper-div");
            });
            this.addRowCont.style.display = "none";
        }
        //If the window is more than 1024px wide we add back the classes
        else {
            this.rowCont.classList.add("vertical-row");
            this.addRowCont.classList.add("vertical-row");
            let parentDivs = this.rowCont.children;
            for (let i = 0; i < parentDivs.length; i++) {
                if (this.exercise!.options.pair1[i].image == "") {
                    parentDivs[i].classList.add("string-div-vertical");
                    parentDivs[i].children[0].classList.add("vertical-flex");
                }
                else {
                    parentDivs[i].classList.add("vertical-image-div");
                    parentDivs[i].children[0].classList.add("image-wrapper-div");
                }
                parentDivs[i].children[1].classList.add("vertical-flex");
            }

            let addParentDivs = this.addRowCont.children;
            for (let i = 0; i < addParentDivs.length; i++) {
                if (this.exercise!.options.pair1[i].image == "") {
                    addParentDivs[i].classList.add("string-div-vertical");
                    addParentDivs[i].children[0].classList.add("vertical-flex");
                }
                else {
                    addParentDivs[i].classList.add("vertical-image-div");
                    addParentDivs[i].children[0].classList.add("image-wrapper-div");
                }
                addParentDivs[i].children[1].classList.add("vertical-flex");
            }
        }
    }

    click(ev: any) {
        let curr_element = ev.target.parentNode;
        if (curr_element.parentNode.classList.contains("drop-div")) {
            if (curr_element.classList.contains("exe-engine-correct-bg")) curr_element.classList.remove("exe-engine-correct-bg");
            let tempElement = this.root.querySelector("#answer-container");
            if (tempElement && !ev.target.classList.contains("icon-shrinkgrow")) {
                curr_element.parentNode.innerText = "";
                curr_element.classList.remove("dropped-div");
                curr_element.classList.remove('dropped');
                curr_element.classList.remove('ui-draggable-dragging');
                tempElement.appendChild(curr_element);
                this.root.setAttribute('data-sim-dragging', 'false');
            }

        }

    }

    drop(ev: any) {
        /* Remove process class when dropped */
        this.root.querySelectorAll('.answer-div').forEach(element => {
            element.classList.remove('answer-div-processing','answer-div-selected');
        });

        let elementMovedBack = AExerciseEngine.drop(ev, this.dropDivList, this.infinite_elements, this);
        if (this.isSNIexc && !elementMovedBack) this.SNIEvaluation(EKEPairingDnDServer);
    }


    allowDrop(ev: any) {
        // ev.preventDefault();
    }

    drag(ev: any) {
        /* Add process class while dragging */
        ev.target.classList.add('answer-div-selected');
        this.divList.forEach(element => {
           element.classList.add('answer-div-processing');
        });
        // ev.dataTransfer.setData("text", ev.target.id);
        let parentDiv: HTMLElement = (ev.target.parentElement as HTMLElement);
        if (parentDiv.classList.contains("answer-container")) {
            if (this.simple_style || this.isSNIexc) {
                this.cloneAnswerElement(ev.target, parentDiv);
            }
        }
    }

    placeAnswersBackToStart() {
        if (this.isReplay) return;
        this.divList.forEach(element => {
            if (element.classList.contains("exe-engine-check-wrong") || (this.isSNIexc && !element.classList.contains("exe-engine-check-correct"))) {
                let tempElement: HTMLElement | null = this.root.querySelector("#answer-container");
                if (element.classList.contains("dropped") || element.classList.contains("dropped-div"))
                    AExerciseEngine.moveElementBack(element!, tempElement!, this.root);
                element.classList.remove("dropped", "dropped-div");
            }
            if (element.classList.contains("answer-div") && !element.classList.contains("dropped")) {
                //$(element).css("top", 0);
                //$(element).css("left", 0);
            }
        });
        return;
    }
}