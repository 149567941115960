import * as React from 'react';
import  { IViewKbItemTypeRecord, ViewKbItemTypeClassProxy, viewKbItemTypeClassProxy } from '@src/framework/view/kb/ViewKbItemType';
import ListView from '@framework/forms/listview';
import '@framework/crud/kb/ItemTypeCrud'; // Ezt ne távolítsd el mert akkor a hülye webpack kioptimizálja!
import { __ } from '@src/translation';

class KbItemTypeListViewInner extends ListView<IViewKbItemTypeRecord> {
    public getViewClassProxy() : ViewKbItemTypeClassProxy { return viewKbItemTypeClassProxy; }
}

export default function KbItemTypeListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <KbItemTypeListViewInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "id", maxWidth: 100},
            {accessor: "title"},
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
