import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ValidationResponse, StatisticsResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import { AnswerTypes } from '@src/component/exercise/models/ExerciseBaseClass';
import { OddOneOutData } from "@src/component/exercise/engine/EKEOddOneOutExerciseEngine/EKEOddOneOutExerciseEngine";
import * as React from "react";
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type OddOneOutAnswerElement = {
    number: number,
    orientation: boolean,
    tasks: ExerciseBaseTypes.QuestionAnswerElement[],
    ordered_answers: boolean,
    ordered_questions: boolean
}

export class EKEOddOneOutAnswerConverter extends AExerciseTypeConverter {
    public hasTextAnswer = true;
    public hasImageAnswer = true;
    public hasTextImageAnswer = false;
    static MAX_ANSWER_NUMBER: number = 6;
    static MAX_TASK_NUMBER: number = 30;
    static MAX_LIMITED_NUM: number = 10;

    public convertToEditorAnswer(exercise: OddOneOutData): OddOneOutAnswerElement | undefined {
        if (exercise) {
            var taskList: ExerciseBaseTypes.QuestionAnswerElement[] = [];
            var cur_exercise = exercise;
            if (cur_exercise.options) {
                for (var i = 0; i < cur_exercise.options.length; i++) {
                    if (cur_exercise.options[i].values) {
                        var answerList = [];
                        let exists_sol = false;
                        for (var j = 0; j < cur_exercise.options[i].values.length; j++) {
                            var is_answer = false;

                            if (cur_exercise.solution.length < i || cur_exercise.solution[i].value == j) {
                                is_answer = true;
                                exists_sol = true;
                            }

                            //if (cur_exercise.imageanswers) {
                            if (!cur_exercise.options[i].values[j].type || cur_exercise.options[i].values[j].type == "text") {
                                let curr_ans = {
                                    type: AnswerTypes.text,
                                    url: cur_exercise.options[i].values[j].image,
                                    text: cur_exercise.options[i].values[j].text,
                                    is_answer: is_answer
                                };
                                answerList.push(curr_ans);
                            }
                            else if (cur_exercise.options[i].values[j].type == "image" || (!cur_exercise.options[i].values[j].type && cur_exercise.options[i].values[j].image != "")) {
                                let curr_ans = {
                                    type: AnswerTypes.image,
                                    url: cur_exercise.options[i].values[j].image,
                                    text: cur_exercise.options[i].values[j].text,
                                    is_answer: is_answer
                                };
                                answerList.push(curr_ans);
                            }
                            else if (cur_exercise.options[i].values[j].type == "sound") {
                                let curr_ans = {
                                    type: AnswerTypes.sound,
                                    text: cur_exercise.options[i].values[j].text,
                                    url: cur_exercise.options[i].values[j].url,
                                    is_answer: is_answer,
                                };
                                answerList.push(curr_ans);
                            }
                            /*} else {
                                let curr_ans = {
                                    text: cur_exercise.options[i].values[j].text,
                                    is_answer: is_answer
                                };
                                answerList.push(curr_ans);
                            }*/
                        }
                        if (!exists_sol && answerList[0]) {
                            answerList[0].is_answer = true;
                        }
                        var curr_task: ExerciseBaseTypes.QuestionAnswerElement = {
                            question: cur_exercise.options[i].key,
                            answers: answerList
                        };

                        taskList.push(curr_task);

                    }
                }

                var response = {
                    number: exercise.number,
                    orientation: exercise.orientation,
                    tasks: taskList,
                    ordered_answers: exercise.ordered_answers,
                    ordered_questions: exercise.ordered_questions
                };
                return response;
            }
            else return undefined;
        }
        return undefined;
    }

    public convertToJson(answerList: OddOneOutAnswerElement, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: OddOneOutAnswerElement): OddOneOutData | undefined {
        if (answerList) {
            var que_list = [];
            var sol_list = [];
            if (answerList.tasks) {
                for (var _i = 0; _i < answerList.tasks.length; _i++) {
                    var ans_list = [];
                    var sol_index;
                    var modified = false;
                    for (var _j = 0; _j < answerList.tasks[_i].answers.length; _j++) {
                        //if there is a previous state we check if the solution was there false and now it has changed to true then this is the new solution
                        if (prevJSON != undefined && prevJSON.tasks != undefined && prevJSON.tasks[_i].answers[_j] != undefined &&
                            !prevJSON.tasks[_i].answers[_j].is_answer && answerList.tasks[_i].answers[_j].is_answer) {
                            sol_index = _j;
                            modified = true;
                            for (let index = 0; index < _j; index++) {
                                answerList.tasks[_i].answers[index].is_answer = false;
                            }
                        }
                        else if (!modified && answerList.tasks[_i].answers[_j].is_answer) {
                            sol_index = _j;
                        }
                        else if (modified && answerList.tasks[_i].answers[_j].is_answer) {
                            answerList.tasks[_i].answers[_j].is_answer = false;
                        }
                        let newAns: ExerciseBaseTypes.AnswerElement;
                        if (!answerList.tasks[_i].answers[_j].type || answerList.tasks[_i].answers[_j].type == AnswerTypes.text) {
                            newAns = {
                                type: "text",
                                text: answerList.tasks[_i].answers[_j].text ? answerList.tasks[_i].answers[_j].text! : "",
                                image: answerList.tasks[_i].answers[_j].url ? answerList.tasks[_i].answers[_j].url! : "",
                            }
                            ans_list.push(newAns);
                        }
                        else if (answerList.tasks[_i].answers[_j].type && answerList.tasks[_i].answers[_j].type == AnswerTypes.image) {
                            newAns = {
                                type: "image",
                                text: answerList.tasks[_i].answers[_j].text ? answerList.tasks[_i].answers[_j].text! : "",
                                image: answerList.tasks[_i].answers[_j].url ? answerList.tasks[_i].answers[_j].url! : "",
                            }
                            ans_list.push(newAns);
                        }
                        else if (answerList.tasks[_i].answers[_j].type && answerList.tasks[_i].answers[_j].type == AnswerTypes.sound) {
                            newAns = {
                                type: "sound",
                                text: answerList.tasks[_i].answers[_j].text ? answerList.tasks[_i].answers[_j].text! : "",
                                image: "",
                                url: answerList.tasks[_i].answers[_j].url ? answerList.tasks[_i].answers[_j].url! : "",
                            }
                            ans_list.push(newAns);
                        }
                    }

                    var solution = {
                        key: answerList.tasks[_i].question,
                        value: sol_index
                    };
                    sol_list.push(solution);

                    var answer = {
                        key: answerList.tasks[_i].question,
                        values: ans_list
                    };

                    que_list.push(answer);
                }
            }
            var number = answerList.number;
            if (answerList.number > que_list.length || answerList.number <= 0)
                number = que_list.length;


            var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
            cur_exercise = {
                ...cur_exercise,
                answers: que_list,
                options: que_list,
                solution: sol_list,
                number: number,
                orientation: answerList.orientation,
                ordered_answers: answerList.ordered_answers,
                ordered_questions: answerList.ordered_questions
            };
            return cur_exercise;
        }
        else
            return undefined;
    }

    public makeValidBeforeSetState(oldState: OddOneOutAnswerElement, newState: OddOneOutAnswerElement): ValidationResponse {
        if (newState.tasks) {
            if (newState.tasks.length > EKEOddOneOutAnswerConverter.MAX_TASK_NUMBER) {
                newState.tasks.splice(EKEOddOneOutAnswerConverter.MAX_TASK_NUMBER, newState.tasks.length - EKEOddOneOutAnswerConverter.MAX_TASK_NUMBER);
                return { valid: false, message: __("A kérdések száma maximum {MAX_TASK_NUMBER} lehet!", {MAX_TASK_NUMBER:EKEOddOneOutAnswerConverter.MAX_TASK_NUMBER}) }
            }
            for (var _i = 0; _i < newState.tasks.length; _i++) {
                var modified = false;
                for (var _j = 0; _j < newState.tasks[_i].answers.length; _j++) {
                    //if there is a previous state we check if the solution was there false and now it has changed to true then this is the new solution
                    if (oldState != undefined && oldState.tasks != undefined && oldState.tasks[_i].answers[_j] != undefined &&
                        !oldState.tasks[_i].answers[_j].is_answer && newState.tasks[_i].answers[_j].is_answer) {
                        for (let index = 0; index < newState.tasks[_i].answers.length; index++) {
                            if (index != _j) newState.tasks[_i].answers[index].is_answer = false;
                        }
                        modified = true;
                        break;
                    }
                    if (modified) break;
                    //if number of answers is not in range of 2 to 6 we add or remove answers
                    if (newState.tasks[_i].answers) {
                        let max_answ_num = EKEOddOneOutAnswerConverter.MAX_ANSWER_NUMBER;
                        if (newState.tasks[_i].answers.length > max_answ_num) {
                            newState.tasks[_i].answers.splice(max_answ_num, newState.tasks[_i].answers.length - max_answ_num);
                            return { valid: false, message: __("Egy kérdéshez minimum {min}, maximum {max} válaszelem tartozhat!", {min:EKEOddOneOutAnswerConverter.QUIZ_MIN_ANS_NUMBER, max:max_answ_num})}
                        }
                    }
                }
            }
        }
        return { valid: true }
    }


    public validate(editorAnswer: OddOneOutAnswerElement, baseData: any, validationMap?:Map<string,string>, is_accessible?:boolean|null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData,validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        let errorMsg = "";
        let curr_ans_list = editorAnswer.tasks;
        let OFIErrors: string[] = [];

        if (curr_ans_list && curr_ans_list.length > EKEOddOneOutAnswerConverter.MAX_TASK_NUMBER) {
            errorMsg = __("Maximum {MAX_TASK_NUMBER} kérdés adható meg!", {MAX_TASK_NUMBER:EKEOddOneOutAnswerConverter.MAX_TASK_NUMBER})
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg}
        }

        let is_multiple_ans = false;
        for (let i = 0; i < curr_ans_list.length; i++) {
            let is_ans = false;
            let has_ans = false;
            if (curr_ans_list[i].question == "" || curr_ans_list[i].answers.length == 0) {
                errorMsg = __("Töltse ki a kérdés és a válasz mezőket.");
                validationMap!.set("tasks[" + i + "]", errorMsg);
                if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                return { valid: false, message: errorMsg };
            }
            if (curr_ans_list[i].answers.length < EKEOddOneOutAnswerConverter.QUIZ_MIN_ANS_NUMBER || (!AExerciseTypeConverter.isOfiEditor  && curr_ans_list[i].answers.length > EKEOddOneOutAnswerConverter.MAX_ANSWER_NUMBER)) {
                return { valid: false, message: __("Egy kérdéshez minimum {min}, maximum {max} válaszelem tartozhat!", {min:EKEOddOneOutAnswerConverter.QUIZ_MIN_ANS_NUMBER, max:EKEOddOneOutAnswerConverter.MAX_ANSWER_NUMBER}) }
            }
            if (curr_ans_list[i].question.length < 1 || curr_ans_list[i].question.length > EKEOddOneOutAnswerConverter.GLOBAL_QUESTION_TEXT_LENGHT) {
                errorMsg = __("A kérdések karakterszáma minimum 1, maximum {GLOBAL_QUESTION_TEXT_LENGHT} lehet!", {GLOBAL_QUESTION_TEXT_LENGHT:EKEOddOneOutAnswerConverter.GLOBAL_QUESTION_TEXT_LENGHT});
                validationMap!.set("tasks[" + i + "].question", errorMsg);
                if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg }
            }
            for (let j = 0; j < curr_ans_list[i].answers.length; j++) {
                if (curr_ans_list[i].answers[j].url == null && curr_ans_list[i].answers[j].text == null) {
                    errorMsg = __("Legalább két válaszlehetőséget adjon meg!"); 
                    if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                    return { valid: false, message: errorMsg };
                }
                if (curr_ans_list[i].answers[j].is_answer) {
                    has_ans = true;
                    if (is_ans == false) {
                        is_ans = true;
                    } else {
                        is_multiple_ans = true;
                        break;
                    }
                }
                if (curr_ans_list[i].answers[j].text) {
                    let cur_text = curr_ans_list[i].answers[j].text || "";
                    if (cur_text.length > EKEOddOneOutAnswerConverter.MAX_ALT_TEXT_LENGTH) {
                        errorMsg = __("A válaszelemek karakterszáma maximum {MAX_ALT_TEXT_LENGTH} lehet!",{MAX_ALT_TEXT_LENGTH:EKEOddOneOutAnswerConverter.MAX_ALT_TEXT_LENGTH});
                        validationMap!.set("answers[" + j + "].text", errorMsg);
                        if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                        if(!AExerciseTypeConverter.isOfiEditor ) return { valid: false, message: errorMsg }
                    }
                }
            }
            if (!has_ans)
                return { valid: false, message: __("Adjon meg egy választ!") };
            if (is_multiple_ans) break;
        }
        if (editorAnswer.number && editorAnswer.number > EKEOddOneOutAnswerConverter.MAX_LIMITED_NUM) {
            errorMsg = __("A maximum megjelenített elemszám legfeljebb {max} lehet!", {max:EKEOddOneOutAnswerConverter.MAX_LIMITED_NUM});
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            if(!AExerciseTypeConverter.isOfiEditor) return { valid: false, message: errorMsg }
        }
        if (is_multiple_ans) {
            errorMsg = __("Egy kérdésnek csak egy megoldása lehet!");
            if(!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
            return { valid: false, message: errorMsg };
        }

        if (AExerciseTypeConverter.isOfiEditor){
            return { valid: true, message: OFIErrors.join(' , ') }
        }

        return { valid: true };
        //return super.validate(editorAnswer, baseData, engineName);
    }

    public isSolutionAvailable(exerciseJson: OddOneOutData): boolean {
        if (!super.isSolutionAvailable(exerciseJson))
            return false;

        if (exerciseJson.options.length < 1) return false;
        for (let index = 0; index < exerciseJson.options.length; index++) {
            if (exerciseJson.options[index].values.length < 2) return false;
        }
        var missingSolution = true;
        let curr_solution = exerciseJson.solution;

        for (let index = 0; index < curr_solution.length; index++) {
            missingSolution = false;
            if (curr_solution[index].value == undefined) return false;
        }
        return !missingSolution;
    }


    render() {
        let curr_ex: OddOneOutAnswerElement = this.state.exercise as OddOneOutAnswerElement;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                <div>
                    <div className="row">
                        <div className="large-12 columns">
                            <label>{ __("Megjelenített kérdések száma (0 = összes)") }</label>
                            <input type="number" min="0" max={curr_ex.tasks ? curr_ex.tasks.length : 0} name="number"
                                value={curr_ex.number || 0}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="large-12 columns">
                            <label ><input type="checkbox" name="ordered_questions" checked={curr_ex.ordered_questions || false}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                                { __("A kérdések sorrendje fix") }</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="large-12 columns">
                            <label ><input type="checkbox" name="ordered_answers" checked={curr_ex.ordered_answers || false}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                                { __("A válaszok sorrendje fix") }</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="large-12 columns">
                            <label ><input type="checkbox" name="orientation" checked={curr_ex.orientation || false}
                                onBlur={this.onBlurEvent.bind(this)}
                                onChange={this.handleInputChange.bind(this)} />
                                { __("Horizontális megjelenítés") }</label>
                        </div>
                    </div>

                    <Panel header={__("Kérdések")} headingLevel={5}>
                        <div>
                            {
                                curr_ex.tasks ?
                                    curr_ex.tasks.map((curr_task: ExerciseBaseTypes.QuestionAnswerElement, index) => {
                                        ;
                                        let actions: IAccordionAction[];
                                        actions = [
                                            {
                                                title: __("Törlés"),
                                                type: AccordionActionType.Trash,
                                                action: this.removeElement.bind(this, "tasks", index)
                                            },
                                            {
                                                title: __("Fel"),
                                                type: AccordionActionType.Up,
                                                action: this.moveUp.bind(this, "tasks", index)
                                            },
                                            {
                                                title: __("Le"),
                                                type: AccordionActionType.Down,
                                                action: this.moveDown.bind(this, "tasks", index)
                                            }
                                        ];
                                        return (
                                            <Accordion key={"task_" + index}>
                                                <ActionAccordionItem defaultClosed key={"tasks_" + index} actions={actions} title={__("{n}. kérdés", {n: index + 1})}>
                                                    <Panel>
                                                        <div className="row">
                                                            <div className="large-12 columns">
                                                                <label>{ __("Kérdés") } <span className="exe-editor-validation-msg">{this.props.validationMessages.get("tasks[" + index + "]")}</span> 
                                                                <span className="exe-editor-validation-msg">{this.props.validationMessages.get("tasks[" + index + "].question")}</span>
                                                                    <input type="text" name={"tasks#" + index + ".question"} data-parent-index={index} value={curr_task.question}
                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                </label>


                                                                <legend><h4>{ __("Válaszok") }</h4></legend>
                                                                {
                                                                    curr_task.answers ?
                                                                        curr_task.answers.map((curr_ans: ExerciseBaseTypes.CheckableMultiTypeAnswerItem, index_j) => {
                                                                            return (
                                                                                <Panel> 
                                                                                    <legend>
                                                                                    <label className="exe-editor-fieldlabel-2">{ __("Válasz") } {index_j + 1}</label>
                                                                                    <button className="button small alert exercise-series-small-btn" title={__("Törlés")} onClick={this.removeElement.bind(this, "tasks#" + index + ".answers", index_j)}><i className="fa fa-trash"></i></button>
                                                                                    <button className="button small exercise-series-small-btn" title="Fel" onClick={this.moveUp.bind(this, "tasks#" + index + ".answers", index_j)} ><i className="fa fa-arrow-up"></i></button>
                                                                                    <button className="button small exercise-series-small-btn" title="Le" onClick={this.moveDown.bind(this, "tasks#" + index + ".answers", index_j)}><i className="fa fa-arrow-down"></i></button>
                                                                                    </legend>
                                                                                <span className="exe-editor-validation-msg">{this.props.validationMessages.get("tasks[" + index + "].answers[" + index_j + "].type")}</span>
                                                                                    <div className="row">
                                                                                        <div className="large-12 small-12 columns">
                                                                                            <label ><input type="checkbox" name={"tasks#" + index + ".answers#" + index_j + ".is_answer"} checked={curr_ans.is_answer || false}
                                                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                                                onChange={this.handleInputChange.bind(this)} />
                                                                                                { __("Helyes?") }
                                                                                                </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="large-4 small-12 columns">
                                                                                            <label> { __("Válasz típusa") }
                                                                                            <select value={curr_ans.type} name={"tasks#" + index + ".answers#" + index_j + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                                                                    <option value={AnswerTypes.text}>{ __("Szöveg") }</option>
                                                                                                    <option value={AnswerTypes.image}>{ __("Kép") }</option>
                                                                                                    <option value={AnswerTypes.sound}>{ __("Hang") }</option>
                                                                                                </select></label>
                                                                                        </div>
                                                                                        <div className="large-8 small-12 columns">
                                                                                            <label>{curr_ans.type == AnswerTypes.text ? __("Válasz") : __("Leírás")} <span className="exe-editor-validation-msg">{this.props.validationMessages.get("answers[" + index_j + "].text")}</span>
                                                                                                <input type="text" name={"tasks#" + index + ".answers#" + index_j + ".text"} data-parent-index={index} data-index={index} value={curr_ans.text}
                                                                                                    onBlur={this.onBlurEvent.bind(this)}
                                                                                                    onChange={this.handleInputChange.bind(this)} />
                                                                                            </label>
                                                                                        </div>
                                                                                        </div>
                                                                                    <div className="row">
                                                                                        {curr_ans.type != AnswerTypes.text ? <div className="large-8 small-12 columns">
                                                                                            <label className="exe-image-select-label">{curr_ans.type == AnswerTypes.image ? __("Kép (opcionális)") : __("Hang (opcionális)")}
                                                                                                {<ExerciseFileSelect
                                                                                                    imagebasepath={this.props.imagebasepath}
                                                                                                    value={(curr_ans.type == AnswerTypes.image ? curr_ans.url : curr_ans.url) || ""}
                                                                                                    onChange={(this.handleImageChange.bind(this, "tasks#" + index + ".answers#" + index_j + ".url"))}
                                                                                                    getFolderId={this.getFolderId.bind(this)}
                                                                                                    fileType={curr_ans.type == AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                                                                />}
                                                                                            </label>
                                                                                        </div> : ""}                                                                                        
                                                                                    </div>
                                                                                </Panel>);
                                                                        })
                                                                        : ""
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <button className="button small columns" name={"tasks#" + index + ".btn-add-answer"} onClick={this.onAddNewQuestion.bind(this, index)}><i className="fa fa-plus"></i> { __("Válasz hozzáadása") }</button>
                                                            <label className="exe-editor-label-description columns">{ __("Minimum {min}, maximum {max} elem adható meg!", {min:EKEOddOneOutAnswerConverter.QUIZ_MIN_ANS_NUMBER, max:EKEOddOneOutAnswerConverter.MAX_ANSWER_NUMBER}) }</label>
                                                        </div>

                                                    </Panel>
                                                </ActionAccordionItem>
                                            </Accordion>)
                                    })
                                    : ""
                            }
                            <div className="row">
                                <button className="button small" onClick={this.onAddNewTask.bind(this)}><i className="fa fa-plus"></i> { __("Kérdés hozzáadása") }</button>
                                <label className="exe-editor-label-description columns">{ __("Maximum {MAX_TASK_NUMBER} kérdés megengedett!", {MAX_TASK_NUMBER:EKEOddOneOutAnswerConverter.MAX_TASK_NUMBER}) }</label>
                            </div>
                        </div>
                    </Panel>

                </div>
            </Panel>
        );
    }

    onAddNewTask() {
        let temp_exercise = this.state.exercise as OddOneOutAnswerElement;
        if (!temp_exercise.tasks) temp_exercise.tasks = [];
        temp_exercise.tasks.push(this.getNewTask());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    onAddNewQuestion(index: number) {
        let temp_exercise = this.state.exercise as OddOneOutAnswerElement;
        if (!temp_exercise.tasks[index].answers) temp_exercise.tasks[index].answers = [];
        temp_exercise.tasks[index].answers.push(this.getNewCheckableAnswer());
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid)
            this.setState({ exercise: temp_exercise });
    }

    getNewTask(): ExerciseBaseTypes.QuestionAnswerElement {
        let newElement: ExerciseBaseTypes.QuestionAnswerElement = {
            answers: [],
            question: "",
        };
        return newElement;
    }

    getNewCheckableAnswer(): ExerciseBaseTypes.CheckableMultiTypeAnswerItem {
        let newElement: ExerciseBaseTypes.CheckableMultiTypeAnswerItem = {
            is_answer: false,
            type: AnswerTypes.text,
            text: ""
        };
        return newElement;
    }

    public getExerciseStatistics(exerciseList: any): StatisticsResponse[] {
        if (!exerciseList || exerciseList.length == 0) return [];
        let statisticsResponses: StatisticsResponse[] = [];
        statisticsResponses.push({ name: __("limit - kérdések száma: {n}", {n: EKEOddOneOutAnswerConverter.MAX_TASK_NUMBER}), count: undefined });
        statisticsResponses.push({ name: __("limit - válaszok száma: {n}", {n: EKEOddOneOutAnswerConverter.MAX_ANSWER_NUMBER}), count: undefined });
        statisticsResponses.push({ name: __("limit - limitált elemszám: {n}", {n: EKEOddOneOutAnswerConverter.MAX_LIMITED_NUM}), count: undefined });

        let taskStat, answersStat, answerStatTotal, limitedNumberStat: StatisticsResponse;
        taskStat = { name: __("Feladatok száma"), count: new Map() }
        answersStat = { name: __("Válaszok száma"), count: new Map() }
        answerStatTotal = { name: __("A válaszok teljes száma"), count: new Map() }
        limitedNumberStat = { name: __("Megjelenített kérdések száma"), count: new Map() }
        for (let i = 0; i < exerciseList.length; i++) {
            if (!exerciseList[i]) continue;
            const currentExc = exerciseList[i];
            let task_count = taskStat.count.has(currentExc.tasks.length) ? taskStat.count.get(currentExc.tasks.length) : 0;
            taskStat.count.set(currentExc.tasks.length, task_count! + 1);

            let ln_number = 0;
            if (currentExc.number && currentExc.number != undefined) {
                ln_number = currentExc.number;
            }
            let lim_num_count = limitedNumberStat.count!.has(ln_number) ? limitedNumberStat.count!.get(ln_number) : 0;
            limitedNumberStat.count!.set(ln_number, lim_num_count! + 1);

            let counter = 0;
            for (let j = 0; j < currentExc.tasks.length; j++) {
                const curr_answer = currentExc.tasks[j];
                if (curr_answer.answers) {
                    counter += curr_answer.answers.length;
                    let answer_count = answersStat.count.has(curr_answer.answers.length) ? answersStat.count.get(curr_answer.answers.length) : 0;
                    answersStat.count.set(curr_answer.answers.length, answer_count! + 1);
                }
            }
            let total_answer_count = answerStatTotal.count.has(counter) ? answerStatTotal.count.get(counter) : 0;
            answerStatTotal.count.set(counter, total_answer_count! + 1);
        }
        statisticsResponses.push(taskStat, answersStat, answerStatTotal, limitedNumberStat);
        return statisticsResponses;
    }


}