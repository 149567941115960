import * as React from 'react';
import './LessonEditor.css';
import * as EditorServer from '@src/server/EditorServer';
import * as PublicServer from '@src/server/PublicServer';
import { Section } from '@src/server/EditorServer';
import { FormEvent } from 'react';

import { app } from '@src/index';

import SectionEditor from '@src/component/book/editor/SectionEditor';

import Sticky from 'react-stickynode'
import SectionHTMLEditor from '@src/component/book/editor/SectionHTMLEditor';
import { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import OoFileCrud, { IOoFileRecord } from '@src/framework/crud/media/OoFileCrud';
import OoFolderCrud from '@src/framework/crud/media/OoFolderCrud';
import { BubbleLoader } from 'react-css-loaders';

import { IChapterRecord } from '@src/framework/crud/doc/ChapterCrud';
import LessonCrud, { ILessonRecord } from '@src/framework/crud/doc/LessonCrud';
import SupplementaryMaterialCrud, { ISupplementaryMaterialRecord } from '@src/framework/crud/doc/SupplementaryMaterialCrud';
import { Dialog, confirmDialog, alertDialog } from '@src/component/Dialog';
import ImageSettings from '@src/component/book/editor/ImageSettings';
import { LessonPreviewWindow } from '@src/component/book/editor/LessonPreview';
import SectionCrud from '@src/framework/crud/doc/SectionCrud';
import { Z_INDEX_EDITOR_STICKY } from '@src/Const';
import { PATH_BOOK_VIEW_EDITING } from '@src/Routes';
import { SectionComments } from './SectionComments';
import ViewLesson from '@src/framework/view/doc/ViewLesson';
import { WorkflowDialog } from '@src/component/wf/WorkflowDialog';
import ButtonGroup, { ButtonSize, ButtonAlign } from '@src/component/ui/ButtonGroup';
import ReactResizeDetector from 'react-resize-detector';
import StickyBox from "react-sticky-box";
import { __ } from '@src/translation';
import { ViewMode } from '../viewer/BookPage';

export type FileToUrlPath = (file: IOoFileRecord) => string;

type LessonEditorProps = {
  book: IBookRecord;
  chapter: IChapterRecord;
  lesson: ILessonRecord;
}

type LessonEditorState = {
  // Státusz
  loading: boolean;
  
  // Felhasználható szekció sablonok, adott tankönyvhöz tartoznak
  templates: EditorServer.BlockTemplate[];

  // A tényleges adat, amit lehet szerkeszteni
  sections: Section[];
  SupplementaryMaterials: ISupplementaryMaterialRecord[];
  
  //Inaktív szekció megjegyzések
  inactiveSectionIds: number[],

  // Új kiegészítő anyag hozzáadása
  newSupplementaryMaterialName: string;
  newSupplementaryMaterialUrl: string;

  // File kiválasztásnál mi legyen az URL, amikor bekerül egy szekció
  fileToUrlPath: FileToUrlPath;

  // Kiválasztott kép szerkesztése felugró ablakban
  showImageDialog: boolean;
  selectedImagePath: string;
  
  // Mentés gomb aktiválásához
  sectionsBeforeSave: Section[];
  externalLinksBeforeSave: ISupplementaryMaterialRecord[];

  // Előnézet ablak látszódik
  showPreview: boolean;

  workflowDialogOpen: boolean;
  workflowStateName?: string;
}

class LessonEditor extends React.Component<LessonEditorProps, LessonEditorState> {

  private idSeq: number;

  private tinyMCE: any;

  constructor(prop: LessonEditorProps) {
    super(prop);

    this.idSeq = -1;

    this.state = {
      templates: [],
      SupplementaryMaterials: [],
      newSupplementaryMaterialName: "",
      newSupplementaryMaterialUrl: "",
      externalLinksBeforeSave: [],
      inactiveSectionIds: [],
      sections: [],
      sectionsBeforeSave: [],
      showImageDialog: false,
      selectedImagePath: "",
      fileToUrlPath: () => "",
      loading: true,
      showPreview: false,
      workflowDialogOpen: false,
    }

    this.reloadAsync();
  }

  private async reloadAsync() {
    var sections = await EditorServer.getSections(this.props.lesson.id!);

    var rootFolder = "";
    if (this.props.book.oo_folder_id) {
      rootFolder = (await OoFolderCrud.load(this.props.book.oo_folder_id)).record.relpath!;
    }

    const fileToUrlPath:FileToUrlPath = (file) => {
      return file.relpath!.replace(rootFolder + "/", ""); 
    }

    var templates = await EditorServer.getSectionTemplates(this.props.book.style_id!);

    const externalLinks = await SupplementaryMaterialCrud.list({ filter: { is_active: true, lesson_id: this.props.lesson.id }, order_by: "no" });

    let workflowStateName: string | undefined = undefined;
    
    if (this.props.lesson.id) {
      const lessonView = (await ViewLesson.load(this.props.lesson.id)).record;
      workflowStateName = lessonView.wf_station_name || undefined;
    }

    this.setState({
      SupplementaryMaterials: externalLinks,
      templates: templates,
      sections: sections,
      sectionsBeforeSave: JSON.parse(JSON.stringify(sections)),
      externalLinksBeforeSave: JSON.parse(JSON.stringify(externalLinks)),
      fileToUrlPath: fileToUrlPath,
      loading: false,
      workflowStateName
    });


    const inactiveSections = await SectionCrud.list({filter: {lesson_id: this.props.lesson.id, is_active: false}, order_by: "no"});

    this.setState({
      inactiveSectionIds: inactiveSections.map(s => s.id!)
    });

  }

  private onAddSection(index: number | null) {
    if (this.state.templates.length == 0) {
      app.showError(__("Hiba"), __("A könyvhöz hiányoznak szekció sablonok!"));
      return;
    }

    var templateId;

    var defaultTextTemplate = this.state.templates.find(t => t.name == __("Szöveg"));
    if (defaultTextTemplate) {
      templateId = defaultTextTemplate.id;
    }

    if (!templateId) {
      templateId = this.state.templates[0].id;
    }

    const newSection = {
      id: this.idSeq,
      name: "",
      block_template_id: templateId,
      values: { content: "" },
      exercise_is_hidden_by_default: false,
      is_teachers_guide: false
    };

    if (index === null) {
      this.state.sections.push(newSection);
    } else {
      this.state.sections.splice(index, 0, newSection);
    }

    this.setState({
      sections: this.state.sections
    })

    this.idSeq--;
  }

  async onRemoveSection(sectionKeyToDelete: number) {
    if (!await confirmDialog(__("Törlés"), __("Tényleg törli a szekciót?"), __("Törlés"))) return;

    const filtered = this.state.sections.filter((section) => section.id != sectionKeyToDelete);

    this.setState({ sections: filtered });
  }


  onSectionNameChange(sectionId: number, event: FormEvent<HTMLInputElement>) {
    for (var section of this.state.sections) {
      if (section.id == sectionId) {
        section.name = event.currentTarget.value;
      }
      this.setState({ sections: this.state.sections });
    }
  }

  onSectionTitleLoad(sectionId: number, value: string, templateVariableId: string) {
    for (var section of this.state.sections) {
      if (section.id == sectionId && section.name == 'title') {
 
        section.values[templateVariableId] = value;
      }
      
      this.setState({ sections: this.state.sections });
    }
  }
  setExerciseHiddenByDefault(sectionId: number, exerciseIsHiddenByDefault: boolean) {
    const section = this.state.sections.find(s => s.id == sectionId);
    if (section) {
      section.exercise_is_hidden_by_default = exerciseIsHiddenByDefault;
      this.setState({sections: this.state.sections});
    }
  }

  setIsTeachersGuide(sectionId: number, isTeachersGuide: boolean) {
    const section = this.state.sections.find(s => s.id == sectionId);
    if (section) {
      section.is_teachers_guide = isTeachersGuide;
      this.setState({sections: this.state.sections});
    }
  }

  handleEditorChange(sectionId: number, templateVariableId: string, event: any | FormEvent<HTMLSelectElement>, exerciseId: number | null | undefined = null, exerciseSeriesId: number | null | undefined = null) {
    for (var section of this.state.sections) {
      if (section.id == sectionId) {
        if (typeof event == 'string') {
          section.values[templateVariableId] = event;
        } else if (event.currentTarget) {
          section.values[templateVariableId] = event.currentTarget.value;
        } else {
          section.values[templateVariableId] = event;
        }

        if (exerciseId !== 0) {
          section.exercise_id = exerciseId;
        }
        if (exerciseSeriesId !== 0) {
          section.exercise_series_id = exerciseSeriesId;
        }
      }
    }
    this.setState({ sections: this.state.sections });
  }

  onTemplateChanged(sectionId: number, e: FormEvent<HTMLSelectElement>) {
    for (var section of this.state.sections) {
      if (section.id == sectionId) {
        
        const templateBefore = this.state.templates.find((t) => t.id == section.block_template_id);
        
        section.block_template_id = parseInt(e.currentTarget.value);

        const templateAfter = this.state.templates.find((t) => t.id == section.block_template_id);

        if (templateBefore && templateAfter) {
          
          for(const variableBefore of templateBefore.template.variables) {

            const foundAfter = templateAfter.template.variables.find(v => v.id == variableBefore.id);

            if (!foundAfter) {
              delete(section.values[variableBefore.id]);
            }
          }
        }
      }
    }
    this.setState({ sections: this.state.sections });
  }

  onSectionUp(sectionId: number) {
    const index = this.state.sections.findIndex(s => s.id == sectionId);
    if (index > 0) {
      const tmp = this.state.sections[index];
      this.state.sections[index] = this.state.sections[index - 1];
      this.state.sections[index - 1] = tmp;
    }
    this.setState({ sections: this.state.sections });
  }

  onSectionDown(sectionId: number) {
    const index = this.state.sections.findIndex(s => s.id == sectionId);
    if (index < this.state.sections.length - 1) {
      const tmp = this.state.sections[index];
      this.state.sections[index] = this.state.sections[index + 1];
      this.state.sections[index + 1] = tmp;
    }
    this.setState({ sections: this.state.sections });
  }

  onOpenPreviewWindow() {
    this.setState({showPreview: true});
  }

  setActiveEditor(sectionHTMLEditor: SectionHTMLEditor) {
    this.tinyMCE = sectionHTMLEditor.tinyMCE;
  }
  private async onSave() {
    try {
      var lessonId = this.props.lesson.id!;

      for (var i = 0; i < this.state.sections.length; i++) {
        this.state.sections[i].no = i + 1;
      }

      await PublicServer.putSections(lessonId, this.state.sections, this.state.SupplementaryMaterials);
      app.showSuccess(__("Lecke Sikeresen lementve"), __("{name} sikeresen lementve", {name:this.props.lesson.name}));

      this.reloadAsync();
    } catch (e) {
      app.showErrorFromJsonResult(e);
    }
  }

  async onFileSelected(fileId: number) {
    const file = (await OoFileCrud.load(fileId)).record;
    const path = this.state.fileToUrlPath(file);

    if (this.tinyMCE) {

      if (path.endsWith(".mp3")) {
        
        this.tinyMCE.insertContent(`<audio controls src="${path}" style="width: 100%"/>`);

      } else {

        this.setState({ showImageDialog: true, selectedImagePath: path });

      }

    } else {
      await alertDialog(__("Nincs kiválasztva szerkesztő mező!"), __("Kérem kattintson a szerkesztőbe, ahova el szeretné elhelyezni a képet."));
    }
  }

  async onTextSelected(text: string) {
    if (this.tinyMCE) {
      this.tinyMCE.insertContent(text);
    } else {
      await alertDialog(__("Nincs kiválasztva szerkesztő mező!"), __("Kérem kattintson a szerkesztőbe, ahova el szeretné elhelyezni a szöveget."));
    }
  }

  render() {
    const bookPath = PATH_BOOK_VIEW_EDITING + "/" + this.props.book.id;

    if (this.state.loading) {
      return <div className="grid-container">
        <BubbleLoader />
      </div>;
    }

    const sectionsModified = this.sectionsModified();

    var previewFilePath = this.state.selectedImagePath;
    if (previewFilePath && !previewFilePath.startsWith("/")) {
      previewFilePath = bookPath + "/" + previewFilePath;
    }
    
    return (
      
      <div ref="editor" >
        <WorkflowDialog 
            open={this.state.workflowDialogOpen} 
            displayName="Lecke" 
            tableInfoId={LessonCrud.TABLE_INFO_ID} 
            recId={this.props.lesson.id!}
            onClose={() => this.setState({workflowDialogOpen: false})} 
            onTransition={this.reloadAsync.bind(this)}
            autoPublishOnClosedStation={true}
        />
        {          
          this.state.showImageDialog 
          ?
          <Dialog title={__("Kép beillesztése")} width={1000} height={800} onClose={() => this.setState({ showImageDialog: false })}>
            <ImageSettings isSimpleView={this.props.book.display_mode_id == ViewMode.SIMPLE} filePath={this.state.selectedImagePath} previewFilePath={previewFilePath} tinyMCE={this.tinyMCE} onClose={() => this.setState({ showImageDialog: false })} />
          </Dialog>
          :
          ""
        } 
        {
          this.state.showPreview 
          ?
          <LessonPreviewWindow 
            onClose={() => this.setState({showPreview: false})}
            sections={this.state.sections}
            book={this.props.book}
            chapter={this.props.chapter}
            lesson={this.props.lesson}
            templates={this.state.templates}
            supplementaryMaterials={this.state.SupplementaryMaterials}
            bookPath={bookPath}
          />
          :
          ""
        } 
        <ReactResizeDetector handleWidth handleHeight>

        {(width:any, height:any) => 

            <StickyBox offsetTop={0} style={{zIndex:Z_INDEX_EDITOR_STICKY, backgroundColor: "#fff"}}>
              <div className="row expanded" style={{ backgroundColor: "white", padding: "1em" }}>

                <div className="column small-12">
                  <ButtonGroup size={ButtonSize.Normal} align={ButtonAlign.Expanded}>
                    {
                        this.state.workflowStateName &&
                        <>
                          <label className="label" style={{alignSelf: "center", marginRight: "0.5rem"}}>{__("Állapot")}: {this.state.workflowStateName}</label>
                          <button className="button" onClick={() => this.setState({workflowDialogOpen: !this.state.workflowDialogOpen})}>
                              <i className="fa fa-project-diagram"/>&nbsp; {__("Folyamat...")}
                          </button>
                        </>
                    }
                    
                    <button onClick={this.onOpenPreviewWindow.bind(this)} className="button"><i className="fa fa-eye" /> {__("Előnézet")}</button>

                    <button
                      type="button"
                      id="lessonEditorSaveBtN"
                      className="button success"
                      onClick={this.onSave.bind(this)}
                      disabled={!sectionsModified}
                    >
                      <i className="fa fa-save" /> {__("Mentés")}
                    </button>
                  </ButtonGroup>

                </div>
              </div>
            </StickyBox>
          }
          </ReactResizeDetector>


          <div className="row expanded">

            <div className="column small-12">
              <div className="callout secondary">
                <a target="_new" href={"/layout/" + this.props.book.style_id}>
                  <i className="fa fa-exclamation-circle" /> {__("A felhasználható szekciók vizuális listájáért kattints ide (új lapon nyílik meg)")}.
                </a>
              </div>

              {
                this.state.sections.map((section, index) => {
                  return <React.Fragment key={index}>
                    { index !== 0 ? 
                      <button className="button tiny expanded" onClick={this.onAddSection.bind(this, index)}>
                        <i className="fa fa-plus" /> {__("Szekció beszúrása")}
                      </button> : null
                    }
                    <SectionEditor
                      lessonEditor={this}
                      templates={this.state.templates}
                      section={section}
                      rootFolderId={this.props.book.oo_folder_id!}
                      displayModeId={this.props.book.display_mode_id!}

                      fileToUrlPath={this.state.fileToUrlPath}
                      urlRoot={bookPath}
                      
                      gradeId={this.props.book ? this.props.book.grade_id : undefined}
                      subjectId={this.props.book ? this.props.book.subject_id : undefined}
                      isSNIDisplay={this.props.book ? this.props.book.display_mode_id == ViewMode.SIMPLE : false}
                    />
                  </React.Fragment>
                })
              }

              {
                this.state.sections.length == 0
                  ? <div className="callout alert"><i className="fa fa-arrow-down" /> {__("A lap üres, kérem adjon hozzá új szekciót")}</div>
                  : ""
              }

              {
                this.state.templates.length == 0
                  ? <div className="callout alert">{__("A könyvhöz hiányoznak szekció sablonok!")}</div>
                  :
                  <button className="button expanded" onClick={this.onAddSection.bind(this, null)}>
                    <i className="fa fa-plus" /> {__("Szekció hozzáadása")}
                  </button>
              }

              <hr />
              <h4>{__("Kiegészítő anyagok a menüben")}</h4>
              <table>
                <tbody>
                  <tr>
                    <th>{__("Név")}</th>
                    <th>{__("URL")}</th>
                    <th></th>
                  </tr>
                  {
                    this.state.SupplementaryMaterials.map((externalLink, index) => {
                      return <tr key={index}>
                        <td style={{width: "50%"}}><input type="text" value={externalLink.name} onChange={this.onChangeSupplementaryMaterialName.bind(this, index)} /></td>
                        <td style={{width: "50%"}}><input type="text" value={externalLink.url} onChange={this.onChangeSupplementaryMaterialUrl.bind(this, index)} /></td>

                        <td><button className="button small secondary" onClick={this.onSupplementaryMaterialUp.bind(this, index)} ><i className="fa fa-arrow-up" /></button></td>
                        <td><button className="button small secondary" onClick={this.onSupplementaryMaterialDown.bind(this, index)} ><i className="fa fa-arrow-down" /></button></td>

                        <td><button className="button small alert" onClick={this.onRemoveSupplementaryMaterial.bind(this, index)} ><i className="fa fa-trash" /></button></td>
                      </tr>
                    })
                  }

                  <tr>
                    <td><input type="text" value={this.state.newSupplementaryMaterialName} onChange={this.onChangeSupplementaryMaterialName.bind(this, null)} /></td>
                    <td><input type="text" value={this.state.newSupplementaryMaterialUrl} onChange={this.onChangeSupplementaryMaterialUrl.bind(this, null)} /></td>
                    <td colSpan={3}><button className="button small" onClick={this.onAddSupplementaryMaterial.bind(this)} ><i className="fa fa-plus" /> {__("Hozzáadás")}</button></td>
                  </tr>
                </tbody>
              </table>

              <SectionComments sectionIds={this.state.inactiveSectionIds} isActive={undefined}/>

              <br /><br /><br /><br />

            </div>
          </div>
        </div>
    );
  }

  onChangeSupplementaryMaterialName(index: number | null, event: any) {
    if (index != null) {
      this.state.SupplementaryMaterials[index].name = event.target.value;
      this.setState({ SupplementaryMaterials: this.state.SupplementaryMaterials });
    } else {
      this.setState({ newSupplementaryMaterialName: event.target.value });
    }
  }

  onChangeSupplementaryMaterialUrl(index: number | null, event: any) {
    if (index != null) {
      this.state.SupplementaryMaterials[index].url = event.target.value;
      this.setState({ SupplementaryMaterials: this.state.SupplementaryMaterials });
    } else {
      this.setState({ newSupplementaryMaterialUrl: event.target.value });
    }
  }

  onAddSupplementaryMaterial() {
    this.state.SupplementaryMaterials.push({
      name: this.state.newSupplementaryMaterialName,
      url: this.state.newSupplementaryMaterialUrl,
    })
    this.setState({
      SupplementaryMaterials: this.state.SupplementaryMaterials,
      newSupplementaryMaterialName: "",
      newSupplementaryMaterialUrl: ""
    });
  }

  onSupplementaryMaterialUp(index: number) {
    if (index > 0) {
      const tmp = this.state.SupplementaryMaterials[index];
      this.state.SupplementaryMaterials[index] = this.state.SupplementaryMaterials[index - 1];
      this.state.SupplementaryMaterials[index - 1] = tmp;

      this.setState({SupplementaryMaterials: this.state.SupplementaryMaterials});
    }
  }

  onSupplementaryMaterialDown(index: number) {
    if (index < this.state.SupplementaryMaterials.length - 1) {
      const tmp = this.state.SupplementaryMaterials[index];
      this.state.SupplementaryMaterials[index] = this.state.SupplementaryMaterials[index + 1];
      this.state.SupplementaryMaterials[index + 1] = tmp;
      this.setState({SupplementaryMaterials: this.state.SupplementaryMaterials});
    }
  }

  onRemoveSupplementaryMaterial(index: number) {
    this.state.SupplementaryMaterials.splice(index, 1);
    this.setState({ SupplementaryMaterials: this.state.SupplementaryMaterials });
  }

  private sectionsModified() {
    return JSON.stringify(this.state.sections) != JSON.stringify(this.state.sectionsBeforeSave)
      || JSON.stringify(this.state.SupplementaryMaterials) != JSON.stringify(this.state.externalLinksBeforeSave)
  }

  componentDidMount()
  {
    
  }

  componentDidUpdate() {

    if (this.sectionsModified()) {
      window.onbeforeunload = function () {
        return __("A változtatott adatok nincsenek elmentve! Biztos, hogy el akarja hagyni a lapot?");
      }
    } else {
      window.onbeforeunload = null;
    }
  }

  

}


export default LessonEditor;
