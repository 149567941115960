import * as React from 'react';
import { api, IUserInfo, hasAnyGroup, Groups } from '@framework/server/Auth';
import { me } from '@framework/server/Auth';

import * as Scrollbar from 'react-smooth-scrollbar';

import DateTile from "@src/component/dashboard/DateTile";
import NewBooksTile from "@src/component/dashboard/NewBooksTile";
import FavouritesTile from "@src/component/dashboard/FavouritesTile";
import FavouriteVideosTile from "@src/component/dashboard/FavouriteVideosTile";
import SystemCheckTile from "@src/component/dashboard/SystemCheckTile";
import HistoryTile from "@src/component/dashboard/HistoryTile";
import PopularVideosTile from "@src/component/dashboard/PopularVideosTile";
import SmileTile from "@src/component/dashboard/SmileTile";
import NotificationTile from "@src/component/dashboard/NotificationTile";
import BookOfferTile from "@src/component/dashboard/BookOfferTile";
import SharedWithMeTile from "@src/component/dashboard/SharedWithMeTile";
import SharedByMeTile from "@src/component/dashboard/SharedByMeTile";
import DistributedContentTile from "@src/component/dashboard/DistributedContentTile";
import StickyTile from "@src/component/dashboard/StickyTile";
import ProgressionTile from "@src/component/dashboard/ProgressionTile";
import SearchHistoryTile from "@src/component/dashboard/SearchHistoryTile";

import FunctionsMenu from "@src/component/FunctionsMenu";

import { GithubPicker } from 'react-color';
import UserConfigStore from '@src/component/usr/usr_config_store_api';
import PermissionPage from '@src/framework/PermissionPage';
import { Link } from 'react-router-dom';
import { PATH_CONTENT_STORE_FAVOURITE, PATH_CONTENT_STORE_HISTORY, PATH_CONTENT_STORE_SHARED_BY_ME, PATH_CONTENT_STORE_SHARED_WITH_ME, PATH_PROGRESSION_PAGE } from '@src/Routes';

import { Dialog, DialogType } from '@src/component/Dialog';
import { __ } from '@src/translation';
import { MenuAction } from './Header';
import { app } from '..';

enum TileSize {
    auto,
    full,
    half,
    third,
    quarter
}
export class DashboardPermissionPage extends React.Component<DashboardProps, any> {
    render() {
        return <PermissionPage requirements={{
            
        }}>
            <Dashboard {...this.props}/>
        </PermissionPage>
    }
}
interface IDasboardConfig {
    tiles : any[];
}

type DashboardProps = {
    functionsMenuActions: MenuAction[];
}

type DashboardState = {
    currentUser: IUserInfo | null,
    tileCatalog: any[],
    tiles: any[]
}

export default class Dashboard extends React.Component<DashboardProps, DashboardState> {

    constructor(props: DashboardProps) {
        super(props);

        this.state = {
            currentUser: (api && api.getCurrentUser()) || null,
            tiles: [
            ],

            tileCatalog: [ 
                //{ name: 'NewBooksTile', title: "Legújabb Okoskönyvek", tile: NewBooksTile, group: [Groups.Everyone], settings: {limit: 10} }, 
                { name: 'FavouritesTile', title: __("Kedvencek"), tile: FavouritesTile, group: [Groups.Everyone], settings: {limit: 10}, url: PATH_CONTENT_STORE_FAVOURITE }, 
                { name: 'FavouriteVideosTile', title: __("Kedvenc Videók"), tile: FavouriteVideosTile, group: [Groups.Everyone], settings: {limit: 10} },
                { name: 'PopularVideosTile', title: __("Ajánlott Videók"), tile: PopularVideosTile, group: [Groups.Everyone], settings: {limit: 10} },
                { name: 'SystemCheckTile', title:__("Háttér feladatok"), tile: SystemCheckTile, group: [Groups.Admin], settings: {} },
                { name: 'HistoryTile', title: __("Előzmények"), tile: HistoryTile, group: [Groups.Everyone], settings: {limit: 10}, url: PATH_CONTENT_STORE_HISTORY },
                { name: 'DateTile', title: __("Idő"), tile: DateTile, group: [Groups.Everyone], settings: {hasHeader: false} },
                { name: 'SmileTile', title: __("Smile"), tile: SmileTile, group: [Groups.Everyone], settings: {hasHeader: false} },
                //{ name: 'NotificationTile', title: __("Értesítések"), tile: NotificationTile, group: [Groups.Everyone], settings: {hasHeader: false} },
                //{ name: 'BookOfferTile', title: "Tankönyvajánló", tile: BookOfferTile, group: [Groups.Everyone], settings: {hasHeader: true, limit: 10} },
                { name: 'SharedWithMeTile', title: __("Velem megosztott tartalom"), tile: SharedWithMeTile, group: [Groups.Everyone], settings: {hasHeader: true}, url: PATH_CONTENT_STORE_SHARED_WITH_ME },
                { name: 'SharedByMeTile', title:__("Általam megosztott tartalom"), tile: SharedByMeTile, group: [Groups.Everyone], settings: {hasHeader: true}, url: PATH_CONTENT_STORE_SHARED_BY_ME },
                { name: 'DistributedContentTile', title:__("Közelgő tennivalók"), tile: DistributedContentTile, group: [Groups.Everyone], settings: {hasHeader: true}},
                { name: 'StickyTile', title: __("Jegyzet"), tile: StickyTile, group: [Groups.Everyone], settings: {hasHeader: false, tileLimit: 5} },
                { name: 'ProgressionTile', title: __("Előrehaladás"), tile: ProgressionTile, group: [Groups.Everyone], settings: {hasHeader: true, multiple: false}, url: PATH_PROGRESSION_PAGE },
                { name: 'SearchHistoryTile', title: __("Keresési előzmények"), tile: SearchHistoryTile, group: [Groups.Everyone], settings: {limit: 10}},
            ]
        }

        this.loadTiles();
    }


    // gets a tile content by name
    getTileContent(name: string, data: any, settings: any, tileKey: number, onLoad?: (callback: () => void) => void) {

        var arr = this.state.tileCatalog;

        for (var i = 0; i < arr.length; i++) {

            if (arr[i].name == name) {

                return React.createElement(arr[i].tile, { data: data, settings: settings, ref: "tileContent", onLoad: onLoad, tileKey: tileKey  })

            }

        }

        return null;
    }

    // adds a tile to the dashboard
    addTile(tileType: any){
        console.log(tileType);
        var tiles = this.state.tiles;//, key = this.state.key + 1;

        var key: number = 0;
        this.state.tiles.forEach((value,i) => {

            if(value.key > key)
            {
                key = value.key;
            }
        });

        key++;

        tiles.unshift({ name: tileType.name, title: tileType.title,  key: key, settings: tileType.settings });
    
        this.setState({ tiles: tiles }, () => this.storeTiles());
        (this.refs.newTileDialog as Dialog).onClose();
        app.showSuccess("", "Új csempe sikeresen hozzáadva!")

    }
    removeTile(tileIndex: number) {

        var tiles = this.state.tiles.filter((item, index) => {
            return index != tileIndex;
        });

        this.setState({ tiles: tiles }, () => this.storeTiles() );
             
    }

    settingsChanged(tileKey: any, newSettings: any){

        var tiles = this.state.tiles.filter((item, index) => {
            return item.key == tileKey;
        })[0].settings = newSettings;

        this.setState({ tiles: this.state.tiles }, ()=>this.storeTiles());
        
    }

    componentDidMount() {
        // enable tile drag/drop
        var panel = document.querySelector('.dashboard .tile-container');
        this.enableItemReorder(panel);

        //($("#newTileModal")as any).foundation();

    }

    componentWillUnmount() {

        var panel = document.querySelector('.dashboard .tile-container') as HTMLElement;
        panel.removeEventListener('dragstart', this.dragstartListener);
        panel.removeEventListener('dragover', this.dragoverListener);
        panel.removeEventListener('drop', this.dropListener);
        panel.removeEventListener('drag', this.dragListener); 
        panel.removeEventListener('dragend', this.dragEndListener); 
    }

    dragstartListener: any;
    dragoverListener: any;
    dropListener: any;
    dragListener: any;
    dragEndListener: any;
    enableItemReorder(panel: any) {
        var dragSource: any = null,
            dropTarget: any = null;
        var clientY:number;
        panel.addEventListener('dragstart', this.dragstartListener= (e: DragEvent) => {
            clientY=e.clientY;
            dragSource = null;
            var testTarget = (((e.target as HTMLLinkElement).parentElement as HTMLDivElement).parentElement as HTMLDivElement);    
           
            if(!(testTarget as HTMLDivElement).classList.contains('tile-header')){
                testTarget = (e.target as HTMLDivElement);
            }else{
                e.dataTransfer!.setDragImage(testTarget, 0, 0);
            }            

            if((!(testTarget as HTMLElement).dataset.dragtarget) || ((testTarget as HTMLElement).dataset.dragtarget == 'false')) return;
            var target = testTarget.closest('.tile');

            if (target && target.parentElement == panel) {
                dragSource = target;

                if (dragSource) (dragSource as HTMLElement)!.classList.add('drag-source');

                var dt = e.dataTransfer!;
                dt.effectAllowed = 'move';
                dt.setData('text', dragSource.innerHTML);

            }

        });

        
        panel.addEventListener('dragover', this.dragoverListener= (e: any) => {
          
            if (dragSource) {

                var tile = e.target.closest('.tile');
                var tileAfter = e.target.closest('.tile-after');

                if(tile != null && tileAfter == null){
                    if (tile == dragSource) {
                        tile = null;
                    }
    
                    if (dragSource && tile && tile != dragSource) {
    
                        e.preventDefault();
    
                        e.dataTransfer.dropEffect = 'move';
                    }
                    
                    if (dropTarget != tile) {

                        if (dropTarget) 
                        {
                            (dropTarget as HTMLElement)!.classList.remove('drag-over');
                        }
    
                        dropTarget = tile;
    
                        if (dropTarget) 
                        {
                            (dropTarget as HTMLElement)!.classList.add('drag-over');
                        }
    
                    }
                }
                else{
                    if (dragSource && tileAfter && tileAfter != dragSource) {

                        e.preventDefault();
    
                        e.dataTransfer.dropEffect = 'move';
                    }
    
                    if (dropTarget != tileAfter) {
    
                        if (dropTarget) 
                        {
                            (dropTarget as HTMLElement)!.classList.remove('drag-over');
                        }
    
                        dropTarget = tileAfter;
    
                        if (dropTarget) 
                        {
                            (dropTarget as HTMLElement)!.classList.add('drag-over');
                        }
    
                    }
                }
            }
        });
        
        panel.addEventListener('drop', this.dropListener = (e: DragEvent) => {

            if (dragSource && dropTarget) {

                // finish drag/drop
                e.stopPropagation();

                e.stopImmediatePropagation();
                e.preventDefault();

                var tiles = this.state.tiles.slice(),
                    srcIndex = getIndex(dragSource),
                    dstIndex = getIndex(dropTarget);
                
                //insert tile before dropTarget
                if(dropTarget.classList.contains("tile-after")){
                    const tmp = dropTarget;
                    dropTarget = (dropTarget as HTMLDivElement).nextSibling;
                    dstIndex = getIndex(dropTarget);
                    (this.refs.tileContainer as HTMLDivElement).removeChild(tmp);
                }

                if((this.refs.tileContainer as HTMLDivElement).lastChild != dragSource && (dragSource.nextSibling as HTMLDivElement).classList.contains("tile-after")){
                    (this.refs.tileContainer as HTMLDivElement).removeChild(dragSource.nextSibling);
                }
                
                if(srcIndex < dstIndex){
                    tiles.splice(dstIndex+1,0,this.state.tiles[srcIndex]);
                    tiles.splice(srcIndex,1);
                }else{
                    tiles.splice(dstIndex,0,this.state.tiles[srcIndex]);
                    tiles.splice(srcIndex+1,1);
                }
               
                // focus and view on the tile that was dragged
                dragSource.focus();

                // update state

                this.setState({ tiles: tiles });

                this.storeTiles();

            }

        });

        panel.addEventListener('dragend', this.dragEndListener=(e: any) => {

            if (dragSource) dragSource.classList.remove('drag-source');
            if (dropTarget) dropTarget.classList.remove('drag-over');

            if((dragSource as HTMLDivElement).getBoundingClientRect().top <= window.scrollY )
            {
                window.scrollTo(window.scrollY-10, window.scrollX);
            }

            dragSource = dropTarget = null;            

        });
        
        panel.addEventListener('drag', this.dragListener=(e: any) => {
            var tile = e.target.closest('.tile');
            var tileContainer = document.querySelector(".tile-container");

            if(e.clientY <= 80 && clientY >= e.clientY)
            {
                window.scrollTo(window.scrollX, window.scrollY-10);
            }
            else if(e.clientY >= window.innerHeight-80 && clientY <= e.clientY)
            {
                window.scrollTo(window.screenX, window.scrollY+10);
            }
            setTimeout(()=>clientY=e.clientY,10);
        });

        function getIndex(e: any) {
            var p = e.parentElement;
            var x =0;
            for (var i = 0; i < p.children.length; i++) {
                if(((p.children[i] as HTMLDivElement).classList.contains("tile-after"))){
                    x--;
                }
                if (p.children[i] == e) return x;
                x++;
            }

            return -1;
        }

    }

    private loadTiles = async () => {
        try {
            let value : IDasboardConfig|null = await UserConfigStore.get<IDasboardConfig>("dashboard.tiles");
            if (value===null) {
                //app.showError("Nincs ilyen konfig", "Nem lehet beltölteni, mert nincs ilyen eltárolva");
                const defaultTiles =  [{
                    name: "SmileTile",
                    title: __("Hangulatom"),
                    key: 1,
                    settings: {
                        size: 2,
                        hasHeader: false
                    }
                },
                {
                    name: "DateTile",
                    title: __("Idő"),
                    key: 2,
                    settings: {
                        size: 2,
                        hasHeader: false
                    }
                },
                {
                    name: "PopularVideosTile",
                    title: __("Ajánlott Videók"),
                    key: 3,
                    settings: {
                        size: 1,
                        limit: 10
                    }
                },
                {
                    name: "SharedWithMeTile",
                    title: __("Velem megosztott tartalom"),
                    key: 5,
                    settings: {
                        size: 1                 
                    }
                },
                {
                    name: "SharedByMeTile",
                    title: __("Általam megosztott tartalom"),
                    key: 6,
                    settings: {
                        size: 1                
                    }
                },
                {
                    name: "DistributedContentTile",
                    title: __("Közelgő tennivalók"),
                    key: 7,
                    settings: {
                        size: 1                
                    }
                }];

                this.setState({tiles: defaultTiles});
            } 
            else {
                var _tiles = [];
                _tiles = value.tiles.filter(item=>this.state.tileCatalog.filter(tile=>tile.name == item.name).length > 0)     
                this.setState({tiles: _tiles ? _tiles : []}); 
                this.storeTiles();
                //app.showSuccess("Siker", "betöltötted");
            }
        } catch (error) {
            //app.showErrorFromJsonResult(error);
        }
    }

    private storeTiles = async () => {
        let valueToStore : IDasboardConfig = {tiles:this.state.tiles};
        try {
            await UserConfigStore.put("dashboard.tiles", valueToStore);
            //app.showSuccess("Siker", "beállítottad");
        } catch (error) {
            //app.showErrorFromJsonResult(error);
        }

        var removes = document.getElementsByClassName('tile-after');

        while(removes[0]) {
            var el = removes[0];
            el!.parentNode!.removeChild(removes[0]);
        }​

        const padding :number = 12;
        //for future calcuation eg: auto width selection for tiles if inserted into tile-after location
        /*let base :number= (this.refs.tileContainer as HTMLDivElement).clientWidth;
        const quarter = (base * 0.25)-padding;
        const third = (base * 0.33)-padding;
        const Half = (base * 0.50)-padding;*/

        let tileCount =1;
        const containerRectRight = (this.refs.tileContainer as HTMLDivElement).getBoundingClientRect().right;

        (this.refs.tileContainer as HTMLDivElement).childNodes.forEach((el => {
            let offset = (el as HTMLDivElement).offsetTop;
            let siblingOffset = (el.nextSibling as HTMLDivElement) != null ? (el.nextSibling as HTMLDivElement).offsetTop : 0;
            if(offset == siblingOffset){
                tileCount++;
            }else{
                if(!(el as HTMLDivElement).classList.contains("tile-after") 
                && (((el as HTMLDivElement).getBoundingClientRect().right) + tileCount * padding) < containerRectRight){
                    let x =(containerRectRight - (el as HTMLDivElement).getBoundingClientRect().right ) - (padding/2);
                    var div = document.createElement("div");
                    div.className = "tile tile-after";
                    div.innerHTML = "<div class='tile-inner'></div>";
                    div.style.width = x.toString()+"px";
                    (this.refs.tileContainer as HTMLDivElement).insertBefore(div, (el as HTMLDivElement).nextSibling);
                }
                tileCount = 1;
            }
        }));
    }

    private tileToSize(tileSize: TileSize)
    {
        var tiles = this.state.tiles;

        tiles.forEach((value,i) => {

            if(!value.settings)
            {
                value.settings = {}
            }
            value.settings.size = tileSize;

        })
        this.setState({ tiles: tiles });
        
        this.storeTiles();
    }

    onLoad(callback: () => void)
    {
        callback();
    }

    openDialog(dialogRef: string)
    {
        (this.refs[dialogRef] as Dialog).Open();
    }

    render() {

       

       return <div>

            <div className="header">

            </div>
            <div>

                <div className="dashboard">

                    <div className="tile--no-min-height">
                        <FunctionsMenu actions={this.props.functionsMenuActions}/>
                    </div>

                    <div className="resize-menu">
                        <button><i className="fa fa-plus" title={__("Csempe hozzáadása")} onClick={this.openDialog.bind(this,'newTileDialog')}></i></button>
                        <button><i title={__("1 oszlop")} className="fas fa-bars" onClick={()=>this.tileToSize(TileSize.full)}></i></button>                        
                        <button><i title={__("2 oszlop")} className="fas fa-th-large"  onClick={()=>this.tileToSize(TileSize.half)}></i></button>
                        <button><i title={__("3 oszlop")} className="fas fa-th"  onClick={()=>this.tileToSize(TileSize.third)}></i></button>
                    </div>

                    <div className="row align-center">
                        <button className="button new-tile" title={__("Csempe hozzáadása")} onClick={this.openDialog.bind(this,'newTileDialog')}><i className="fa fa-plus"></i>&nbsp;{__("Új csempe")}</button>
                    </div>

                    <div ref="tileContainer" className="tile-container">
                    { 
                                              
                        this.state.tiles.map((item: any, index: number) => { 

                            const content = this.state.tileCatalog.find(citem => citem.name == item.name) ? this.getTileContent(item.name, item.data, item.settings, item.key, this.onLoad) : null;
                            if (!content) {
                                //here
                                console.log(__("Csempe nem található: ") + item.name);                                
                                return;
                            }
                            else
                            {
                                var url = undefined;
                                const tile = this.state.tileCatalog.find(t => t.name == item.name);
                                if (tile) url = tile.url;
                                return <>
                              
                                <Tile
                                    ref={item.key}
                                    key={item.key}
                                    header={item.title}
                                    content={content}
                                    remove={this.removeTile.bind(this)}
                                    index={index}
                                    tileKey={item.key}                                
                                    settings={item.settings}
                                    url={url}
                                    settingsChanged={this.settingsChanged.bind(this)}
                                />
                                </>
                                }
                        })

                    }
                    </div>
                    <div className="row align-center">                        
                        <button className="button new-tile" title={__("Csempe hozzáadása")} onClick={this.openDialog.bind(this,'newTileDialog')}><i className="fa fa-plus"></i>&nbsp;{__("Új csempe")}</button>
                    </div>

                    
                    <Dialog ref="newTileDialog" open={false} title={__("Csempe hozzáadása")} width={800} onClose={() => void(0)}>
                        <div className="row" style={{width: "100%"}}>
                            
                            
                            {
                                this.state.tileCatalog.map((item: any,index: number) => {

                                    if(hasAnyGroup(me!, item.group) && 
                                    (item.settings.multiple || 
                                    this.state.tiles.filter((tile:any) => { return tile.name == item.name}).length < 1 ||
                                    (item.settings.tileLimit && this.state.tiles.filter((tile:any) => { return tile.name == item.name}).length < item.settings.tileLimit)))
                                    {
                                        var _tile=  JSON.parse(JSON.stringify(item));
                                        return <div className="column small-6 medium-6 large-6">                                                             
                                                <button key={index} onClick={this.addTile.bind(this,_tile)} className="button expanded">{_tile.title}</button>
                                            </div>
                                    }
                                    return null;
                                    
                                })
                            }
                            
     
                        </div>


                    </Dialog>  
                
                </div>

            </div>

        </div>

    }

}

type TileProps = {
    header: any,
    content: any,
    remove: (index: number) => void,
    index: number,
    tileKey: any,
    settings: any,
    settingsChanged: (key:any, settings: any) => void;
    url?: string;
}
class Tile extends React.Component<TileProps, any> {

    private tinycolor = require("tinycolor2");
    private colors = {

        default: { 
            dark: ['#b80000', '#db3e00', '#fccb00', '#008b02', '#006b76', '#1273de', /*'#004dcf',*/ '#5300eb', '#203138', '#000000'],
            light: ['#eb9694', '#fad0c3', '#fef3bd', '#c1e1c5', '#bedadc', '#c4def6', /*'#bed3f3',*/ '#d4c4fb', '#cbd8de', '#ffffff'],
            colors: () => {                
                return this.colors.default.dark.concat(this.colors.default.light);
            }
        }
        
    };

    constructor(props: TileProps) {
        super(props);
        this.state = {
            settings: { size: TileSize.auto, color: null, bgcolor: null  }
        };
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    private settings : HTMLDivElement;

    componentWillReceiveProps(newProps: any) {

        if(newProps.settings)
        {
            this.setState({settings: newProps.settings});
        }
        
    }

    remove() {
        this.props.remove(this.props.index)
    }
    openSettings() {
        (this.refs.settingsDialog as Dialog).Open();
    }

    componentDidMount()
    {


        this.setState({
            settings: this.props.settings != undefined ? this.props.settings : { size: TileSize.auto, color: null, bgcolor: null  },
          });

        (this.refs.tileScrollbar as Scrollbar).scrollbar.contentEl.style.height = "100%";
        ((this.refs.tileScrollbar as Scrollbar).scrollbar.contentEl.firstElementChild! as HTMLElement).style.height = "100%";

        (this.refs.tileScrollbar as Scrollbar).scrollbar.track.yAxis.element.addEventListener("mousedown", (e)=> {
            (this.refs.tileInner as HTMLElement).setAttribute("draggable", "false");
        });
        (this.refs.tileScrollbar as Scrollbar).scrollbar.track.yAxis.element.addEventListener("mouseleave", (e)=> {
            (this.refs.tileInner as HTMLElement).setAttribute("draggable", "true");
        });
        (this.refs.tileScrollbar as Scrollbar).scrollbar.track.xAxis.element.addEventListener("mousedown", (e)=> {
            (this.refs.tileInner as HTMLElement).setAttribute("draggable", "false");
        });
        (this.refs.tileScrollbar as Scrollbar).scrollbar.track.xAxis.element.addEventListener("mouseleave", (e)=> {
            (this.refs.tileInner as HTMLElement).setAttribute("draggable", "true");
        });
          
        ($(this.refs.settings) as any).foundation();
    }

    handleOptionChange(changeEvent:React.FormEvent<HTMLInputElement>) {
        // this.setState({
        //   size: changeEvent.currentTarget.value
        // });
        const _settings = this.state.settings;
        _settings.size = changeEvent.currentTarget.value;
        this.setState({
            settings: _settings
          }, () => this.props.settingsChanged(this.props.tileKey, this.state.settings) );

           
    }

    handleMouseEnter() {
        (this.refs.tileScrollbar as Scrollbar).scrollbar.containerEl.querySelector('.scrollbar-track-x')!.classList.add('show');
        (this.refs.tileScrollbar as Scrollbar).scrollbar.containerEl.querySelector('.scrollbar-track-y')!.classList.add('show');
    }
    handleMouseLeave() {
        (this.refs.tileScrollbar as Scrollbar).scrollbar.containerEl.querySelector('.scrollbar-track-x')!.classList.remove('show');
        (this.refs.tileScrollbar as Scrollbar).scrollbar.containerEl.querySelector('.scrollbar-track-y')!.classList.remove('show');
    }

    render() {
        const tileColorStyle= { backgroundColor: this.state.settings.bgcolor, color: this.state.settings.color };

        return (
            <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className={"tile " + (this.state.settings.size ? TileSize[this.state.settings.size] : TileSize.auto)}>

        <div ref="tileInner" className={"tile-inner"} draggable={true} key={this.props.tileKey} data-dragtarget={this.state.settings.hasHeader == false ? true : false} style={this.state.settings.hasHeader == false ? tileColorStyle : undefined}>
           {
               this.state.settings.hasHeader != false ?
               
            <div className="tile-header" style={tileColorStyle} draggable={true} data-dragtarget={true}>
                <div style={{ flexGrow: 1, display: "flex", alignItems: "center", paddingTop: "0.25rem" }}>
                    {
                        this.props.url ? <Link to={this.props.url}>{this.props.header}</Link> : this.props.header
                    }                    
                 </div>
                <div className="buttons">      
                    <button onClick={this.openSettings.bind(this)}><i className="fa fa-cog" style={{color: tileColorStyle.color}}></i></button>
                    <button onClick={this.remove.bind(this)}><i className="fa fa-times" style={{color: tileColorStyle.color}}></i></button>
                </div>
            </div>
            : <div className="buttons">      
                    <button onClick={this.openSettings.bind(this)}><i className="fa fa-cog" style={{color: tileColorStyle.color}}></i></button>
                    <button onClick={this.remove.bind(this)}><i className="fa fa-times" style={{color: tileColorStyle.color}}></i></button>
                </div>
           }
            <div className="tile-body">
               <Scrollbar ref="tileScrollbar" alwaysShowTracks={false} style={{height: "100%", maxWidth: "100%", width: "100%"}}>            
                    <div style={{padding: "0.5rem 1rem", height: "100%", width: "100%", maxWidth: "100%"}}>
                        {this.props.content}
                    </div>
     
                </Scrollbar>       
            </div>
           
            <Dialog ref="settingsDialog" key={"tileModal" + this.props.tileKey}  open={false} title={__("Csempe beállítások")} width={800} onClose={() => void(0)}>
              <div className="row" style={{width: "100%"}}>  
                <fieldset className="column small-12 medium-6 large-6">
                    <legend>{__("Csempe méret:")}</legend>
                    
                    <label><input type="radio" value={TileSize.auto} checked={this.state.settings.size == TileSize.auto} onChange={this.handleOptionChange.bind(this)} required/>{__("Automatikus")}</label>
                    <label><input type="radio" value={TileSize.quarter} checked={this.state.settings.size == TileSize.quarter} onChange={this.handleOptionChange.bind(this)}/>{__("Negyed")}</label>
                    <label><input type="radio" value={TileSize.third} checked={this.state.settings.size == TileSize.third} onChange={this.handleOptionChange.bind(this)}/>{__("Harmad")}</label>
                    <label><input type="radio" value={TileSize.half} checked={this.state.settings.size == TileSize.half} onChange={this.handleOptionChange.bind(this)}/>{__("Fél")}</label>
                    <label><input type="radio" value={TileSize.full} checked={this.state.settings.size == TileSize.full} onChange={this.handleOptionChange.bind(this)}/>{__("Egész")}</label>                    
                </fieldset>

                <fieldset className="column small-12 medium-6 large-6">
                    <legend>{__("Csempe szín:")}</legend>

                        <GithubPicker width="237px"
                        color={ this.state.settings.bgcolor || "#ffffff" }
                        onChangeComplete={(color) => 
                            {
                                const _color = color.hex;                              
                                this.setState((prevState:any) => {
                                const newState = prevState;
                                newState.settings.bgcolor = _color;

                                if(this.tinycolor(_color).isLight())
                                {
                                    newState.settings.color = "#000000";
                                }
                                else{
                                    newState.settings.color = "#ffffff";
                                }

                                return newState;
                            }, () => this.props.settingsChanged(this.props.tileKey, this.state.settings))}}
                            colors={this.colors.default.colors()}
                            />
                    <legend>{__("Betű szín:")}</legend>

                    <GithubPicker width="237px"
                        color={ this.state.settings.color || "#ffffff" }
                        onChangeComplete={(color) => 
                            {
                                var _color = color.hex;                              
                                this.setState((prevState:any) => {
                                const newState = prevState;

                                if(_color == this.state.settings.bgcolor)
                                {
                                    _color = this.state.settings.color;
                                }
                                newState.settings.color = _color;

                                
                                return newState;
                            }, () => this.props.settingsChanged(this.props.tileKey, this.state.settings))}}
                            colors={this.colors.default.colors()}
                            />
                </fieldset>


                {
                    this.state.settings && this.state.settings.limit ? 
                        <fieldset className="column small-12 medium-6 large-6">
                            <legend>Limit:</legend>
                            <input className="tiny" min="1" max="50" type="number" value={this.state.settings.limit} onChange={(limit) => 
                            {                                  
                                const _limit = limit.target.value;   
                                if(Number(_limit) > 0)
                                {
                                    this.setState((prevState:any) => {
                                        const newState = prevState;
                                        newState.settings.limit = _limit;
                                        return newState;
                                    },
                                    () => this.props.settingsChanged(this.props.tileKey, this.state.settings))
                                }                           
                                
                            }}/>
                        </fieldset> 
                : ""
                }

                </div>
            </Dialog>
        </div>
        </div>
        );
    }

}

