import * as React from 'react';

import TinyMCE from 'react-tinymce-input';
import { Section } from '@src/server/EditorServer';

import LessonEditor, { FileToUrlPath } from './LessonEditor';
import { Dialog } from '@src/component/Dialog';
import { __ } from '@src/translation';
import ImageSettings from './ImageSettings';

type SectionHTMLEditorProps = {
  lessonEditor: LessonEditor;
  section: Section;
  templateVariableId: string;
  urlRoot: string;
  fileToUrlPath: FileToUrlPath;
  defaultHeightPx: number;
  isSNIDisplay: boolean;
}

export default class SectionHTMLEditor extends React.Component<SectionHTMLEditorProps, { showSettingsDialog: boolean, isSNIDisplay: boolean }> {

  tinyMCE: any;

  lastSelectedImagePath: string = "";

  constructor(props: SectionHTMLEditorProps) {
    super(props);

    this.state = {
      showSettingsDialog: false,
      isSNIDisplay: this.props.isSNIDisplay,
    }
  }

  setupEditor() {
    const sectionHTMLEditor = this;

    return (
      (editor: any) => {
        this.tinyMCE = editor;

        editor.addContextToolbar('img', 'image-edit');

        editor.on("dblClick", (event: any) => {
          if (event.target.nodeName == "IMG") {
            sectionHTMLEditor.setState({ showSettingsDialog: true });
          }
        });

        editor.addButton("image-edit", {
          text: __("Kép beállítások"),
          tooltip: __("Kép beállítások"),
          onclick: function () {
            sectionHTMLEditor.setState({ showSettingsDialog: true });
          }
        });

        editor.addButton("add_paragraph", {
          text: __("Bekezdés beszúrása a szövegmező végére"),
          tooltip: __("div-es grid-ből való kilépéshez a szöveg alján"),
          onclick: function () {
            editor.setContent(editor.getContent() + "<p></p>")
          }
        });


        editor.addButton("latex_menu", {
          type: "listbox",
          text: "LaTeX",
          onselect: function (value: any) {
            const content = editor.getContent().replace(/([^$])([+-]?\b[\d,.]+)\b/g, '$1$$$2$$'); // "1 a 23 bcc 3were rer54 fds 6 -1,2" -> "$1$ a $23$ bcc 3were rer54 fds $6$ $-1,2"
            editor.setContent(content);
          },
          values: [
            { text: __("Számok LaTeX-elése ($ jelek közé helyezése)"), value: 1 },
          ]
        });

        editor.addButton("wrap_box_div", {
          type: "listbox",
          text: __("Bekezdés kiemelés"),
          onselect: function (e: any) {
            const selectedClass = e.control.state.data.value;

            if (!editor.selection.getRng() ||
              !editor.selection.getRng().startContainer ||
              editor.selection.getRng().startContainer.nodeName == "BODY") {
              alert(__("Kérem válassza ki a bekezdést"));
              return;
            }

            //const div: HTMLElement = editor.selection.getNode().closest("div[data-wrap-box]");
            const div: HTMLElement = editor.selection.getNode().closest("div.box, div.speech-bubble");

            if (div) {

              if (div.getAttribute("class") == selectedClass) {
                $(div).replaceWith(div.innerHTML);
              } else {
                div.setAttribute("class", selectedClass);
              }

            } else {
              $(editor.selection.getSelectedBlocks()).wrapAll(`<div class="${selectedClass}">`);
            }

            editor.fire("change");
          },
          values: [
            { text: __(`Kék kiemelés`), value: "box blue" },
            { text: __(`Sárga kiemelés`), value: "box yellow" },
            { text: __(`Zöld kiemelés`), value: "box green" },
            { text: __(`Barna kiemelés`), value: "box brown" },
            { text: __(`Piros kiemelés`), value: "box red" },
            { text: __(`Szürke kiemelés`), value: "box gray" },
            {
              text: __(`Szakiskola`), menu: [
                { text: __(`Szövegbuborék fiú`), value: "box bubble-boy" },
                { text: __(`Szövegbuborék lány`), value: "box bubble-girl" }
              ]
            },
            {
              text: __(`Etika`), menu: [
                { text: __(`Szürke kiemelés`), value: "box light-gray" },
                { text: __(`Búzavirágkék kiemelés`), value: "box pale-cornflower-blue" },
                { text: __(`Banánsárga kiemelés`), value: "box banana-mania" },
                { text: __(`Télizöld kiemelés`), value: "box periwinkle" },
                { text: __(`Mohazöld kiemelés`), value: "box yellow-green" },
                { text: __(`Játékos feladat olvasmány`), value: "box game-content" },
                { text: __(`Olvasmány kérdések`), value: "box questions-content" }
              ]
            },
            {
              text: __(`Történelem 6`), menu: [
                { text: __(`Vonalas kiegészítés`), value: "box line-supplement" }
              ]
            },
            {
              text: __(`Magyar nyelv`), menu: [
                { text: __(`11. Nyelv és élet`), value: "box lime" },
                { text: __(`11. Hogy is volt?`), value: "box turquoise" },
                { text: __(`11. Haladóknak`), value: "box light-orange" },
                { text: __(`12. Nyelv és élet`), value: "box orange" },
                { text: __(`12. Hogy is volt?`), value: "box light-green" },
                { text: __(`12. Haladóknak`), value: "box dark-yellow" }
              ]
            },
            {
              text: __(`SNI Informatika`), menu: [
                { text: __(`Bekezdés nyíllal, sötétebb kék kerettel`), value: "box arrow-white" },
                { text: __(`Bekezdés nyíl nélkül, sötétebb kék kerettel`), value: "box blue-border-white" },
                { text: __(`Bekezdés nyíllal, világosabb kék kerettel`), value: "box arrow-blue" },
                { text: __(`Bekezdés nyíl nélkül, világosabb kék kerettel`), value: "box blue-border-blue" }
              ]
            },
            {
              text: __(`SNI Informatika 9`), menu: [
                { text: __(`Bekezdés nyíllal, kék kerettel`), value: "box arrow-lightblue-border" },
                { text: __(`Bekezdés kék kerettel, nyíl nélkül`), value: "box lightblue-border" }
              ]
            },
            {
              text: __(`Angol A1-A2`), menu: [
                { text: __(`E-mail`), value: "box e-mail" },
                { text: __(`Help`), value: "box help" },
                { text: __(`Fontos`), value: "box fontos" }
              ]
            },
            {
              text: __(`SNI Technika, életvitel és gyakorlat 5.`), menu: [
                { text: __(`Bekezdés nyíllal`), value: "box arrow-keszitsd-el" },
                { text: __(`Bekezdés nyíl nélkül`), value: "box keszitsd-el" },
                { text: __(`Balesetveszély`), value: "box balesetveszely" },
                { text: __(`Szorgalmi`), value: "box szorgalmi" }
              ]
            },
          ]
        });

        editor.addButton('bubble_modal_control', {
          icon: "bubble",
          tooltip: __("Buborék vezérlő"),
          onclick: function () {

            function changeIllustration(modal:any, type:string) {
              //console.log(modal);
              modal.find('#bubble_type')[0].value(type);
              let modalCont = modal.$el[0];

              let bubbleOptions = modalCont.querySelectorAll('[data-speech-bubble-type]');
              bubbleOptions.forEach(function(item:any){
                item.style.display = 'none';
              });

              let bubbleButtons = modal.find('button');

              for (let i=0;i<bubbleButtons.length;i++) {
                bubbleButtons[i].$el[0].style.background = '';
              }

              let currentImage = modalCont.querySelector('[data-speech-bubble-type="' + type + '"]');
              currentImage.style.display = 'block';

              let currentButtonId = '#' + currentImage.getAttribute('data-speech-bubble-button');
              modal.find(currentButtonId)[0].$el[0].style.background = 'rgba(0,0,0,0.1)';

            }


            var $selectedElement = $(editor.selection.getNode());

            // Open window
            var bubbleModal = editor.windowManager.open({
              title: __('Buborék beállítások'),
              name: 'bubble-modal',
              body: [
                {
                  type: "listbox",
                  text: __("Buborék típusa"),
                  name: 'bubble_type',
                  hidden: true,
                  values: [
                    { text: __('Fent-bal'), value: 'speech-bubble top left' },
                    { text: __('Fent-közép'), value: 'speech-bubble top center' },
                    { text: __('Fent-jobb'), value: 'speech-bubble top right' },
                    { text: __('Közép-bal'), value: 'speech-bubble middle left' },
                    { text: __('Közép-jobb'), value: 'speech-bubble middle right' },
                    { text: __('Lent-bal'), value: 'speech-bubble bottom left' },
                    { text: __('Lent-közép'), value: 'speech-bubble bottom center' },
                    { text: __('Lent-jobb'), value: 'speech-bubble bottom right' },
                  ],

                },
                { type: 'checkbox', name: 'bubble_column', label: __('Buborékok vertikális igazítása a cellán belül:'), text: '' },
                { type: 'container', name: 'bubble_column_info', html: __('Figyelem! A cellában lévő a szövegbuborékon kívüli egyéb tartalomra is hatással van!'), style: 'background: rgba(255,0,0,0.1);padding: 5px 5px 0 5px;margin: -5px 0 5px 0;' },
                { type: 'container', name: 'bubble_column_border', html: '', style: 'border-bottom: 2px solid #000000;' },
                {
                  type: 'container',
                  name: 'bubble-position-top',
                  layout: 'flex',
                  pack: 'justify',
                  direction: 'row',
                  items: [
                    {
                      type: 'button', name: 'bubble-top-left', text: __('Nyíl: fent-bal'), style: 'background: rgba(0,0,0,0.1);', onclick: function (e:any) {
                      changeIllustration(bubbleModal, 'speech-bubble top left');
                    }
                    },
                    { type: 'button', name: 'bubble-top-center', text: __('Nyíl: fent-közép'), onclick: function (e:any) {
                      changeIllustration(bubbleModal, 'speech-bubble top center');
                    }
                    },
                    { type: 'button', name: 'bubble-top-right', text: __('Nyíl: fent-jobb'), onclick: function (e:any) {
                      changeIllustration(bubbleModal, 'speech-bubble top right');
                    }
                    },
                  ]
                },
                {
                  type: 'container',
                  name: 'bubble-position-middle',
                  layout: 'flex',
                  direction: 'row',
                  align: 'center',
                  items: [
                    { type: 'button', name: 'bubble-middle-left', text: __('Nyíl: közép-bal'), onclick: function (e:any) {
                      changeIllustration(bubbleModal, 'speech-bubble middle left');
                    }
                    },
                    {
                      type: 'container',
                      name: 'bubble_illustration',
                      html: '<div style="width: 222px; height: 100px;">' +
                      '<img data-speech-bubble-button="bubble-top-left" data-speech-bubble-type="speech-bubble top left" src="/img/app/speech-bubbles/speech-bubble-top-left.png" alt="Fent-bal" style="width: 222px; height: 100px;"/>' +
                      '<img data-speech-bubble-button="bubble-top-center" data-speech-bubble-type="speech-bubble top center" src="/img/app/speech-bubbles/speech-bubble-top-center.png" alt="Fent-közép" style="width: 222px; height: 100px;display: none;"/>' +
                      '<img data-speech-bubble-button="bubble-top-right" data-speech-bubble-type="speech-bubble top right" src="/img/app/speech-bubbles/speech-bubble-top-right.png" alt="Fent-jobb" style="width: 222px; height: 100px;display: none;"/>' +
                      '<img data-speech-bubble-button="bubble-middle-left" data-speech-bubble-type="speech-bubble middle left" src="/img/app/speech-bubbles/speech-bubble-middle-left.png" alt="Közép-bal" style="width: 222px; height: 100px;display: none;"/>' +
                      '<img data-speech-bubble-button="bubble-middle-right" data-speech-bubble-type="speech-bubble middle right" src="/img/app/speech-bubbles/speech-bubble-middle-right.png" alt="Közép-jobb" style="width: 222px; height: 100px;display: none;"/>' +
                      '<img data-speech-bubble-button="bubble-bottom-left" data-speech-bubble-type="speech-bubble bottom left" src="/img/app/speech-bubbles/speech-bubble-bottom-left.png" alt="Lent-bal" style="width: 222px; height: 100px;display: none;"/>' +
                      '<img data-speech-bubble-button="bubble-bottom-center" data-speech-bubble-type="speech-bubble bottom center" src="/img/app/speech-bubbles/speech-bubble-bottom-center.png" alt="Lent-közép" style="width: 222px; height: 100px;display: none;"/>' +
                      '<img data-speech-bubble-button="bubble-bottom-right" data-speech-bubble-type="speech-bubble bottom right" src="/img/app/speech-bubbles/speech-bubble-bottom-right.png" alt="Lent-jobb" style="width: 222px; height: 100px;display: none;"/>' +
                      '</div>'
                    },
                    { type: 'button', name: 'bubble-middle-right', text: __('Nyíl: közép-jobb'), onclick: function (e:any) {
                      changeIllustration(bubbleModal, 'speech-bubble middle right');
                    }
                    },
                  ]
                },
                {
                  type: 'container',
                  name: 'bubble-position-bottom',
                  layout: 'flex',
                  pack: 'justify',
                  direction: 'row',
                  items: [
                    { type: 'button', name: 'bubble-bottom-left', text: __('Nyíl: lent-bal'), onclick: function (e:any) {
                      changeIllustration(bubbleModal, 'speech-bubble bottom left');
                    }
                    },
                    { type: 'button', name: 'bubble-bottom-center', text: __('Nyíl: lent-közép'), onclick: function (e:any) {
                      changeIllustration(bubbleModal, 'speech-bubble bottom center');
                    }
                    },
                    { type: 'button', name: 'bubble-bottom-right', text: __('Nyíl: lent-jobb'), onclick: function (e:any) {
                      changeIllustration(bubbleModal, 'speech-bubble bottom right');
                    }
                    },
                  ]
                }
              ],
              onsubmit: function (e: any) {
                /* Bubble column */
                const bubbleColumn = e.data.bubble_column;
                let columnElement = editor.selection.getNode().closest('.column');

                if (bubbleColumn) {
                  if (!columnElement) {
                    alert(__('Kérem válasszon ki egy oszlopot'));
                    return;
                  } else {
                    columnElement.classList.add('bubble-column');
                  }
                } else {
                  columnElement.classList.remove('bubble-column');
                }

                /* Bubble paragraph */
                const selectedClass = e.data.bubble_type;

                if (!editor.selection.getRng() ||
                    !editor.selection.getRng().startContainer ||
                    editor.selection.getRng().startContainer.nodeName == "BODY") {
                  alert(__("Kérem válassza ki a bekezdést"));
                  return;
                }

                //const div: HTMLElement = editor.selection.getNode().closest("div[data-wrap-box]");
                const div: HTMLElement = editor.selection.getNode().closest("div.box, div.speech-bubble");

                if (div) {
                  if (div.getAttribute("class") != selectedClass) {
                    div.setAttribute("class", selectedClass);
                  }
                } else {
                  $(editor.selection.getSelectedBlocks()).wrapAll(`<div class="${selectedClass}">`);
                }



                editor.fire("change");

              },
              onPostRender: function(el:any) {
                //console.log(el);
                //console.log(this.$el[0]);
                let modalWindow = this.$el[0];
                let selectedE = editor.selection.getNode();
                let closestColumn = selectedE.closest('.column');
                let closestBubble = selectedE.closest('.speech-bubble');

                if ( closestColumn && closestColumn.classList.contains('bubble-column') ) {
                  this.find('#bubble_column')[0].value(true);
                }

                if ( closestBubble) {
                  let className = closestBubble.className;
                  changeIllustration(this, className);
                }

              }
            });


          }

        });

        editor.addButton("level-icon", {
          type: "listbox",
          text: __("Nehézségi szint"),
          icon: false,
          onselect: (e: any) => {
            editor.insertContent(e.control.state.data.value);
          },
          values: [
            //TODO: kép elérések
            { text: __('Könnyű'), value: '<img class="level-icon" src="/img/app/NEHEZSEGI_SZINT_01_mini.svg" alt="Könnyű" />' },
            { text: __('Közepes'), value: '<img class="level-icon" src="/img/app/NEHEZSEGI_SZINT_02_mini.svg" alt="Közepes" />' },
            { text: __('Nehéz'), value: '<img class="level-icon" src="/img/app/NEHEZSEGI_SZINT_03_mini.svg" alt="Nehéz" />' },
            { text: __('Kompetencia'), value: '<img class="level-icon" src="/img/app/NEHEZSEGI_SZINT_K_mini.png" alt="Nehéz" />' }
          ],
        });

        editor.addButton("quote-characters", {
          type: "listbox",
          text: __("Idézőjelek"),
          icon: false,
          onselect: (e: any) => {
            editor.insertContent(e.control.state.data.value);
          },
          values: [
            { text: __('Kezdő'), value: '„' },
            { text: __('Záró'), value: '”' }
          ],
        });

        editor.addButton("sni-icon", {
          type: "listbox",
          text: __("SNI ikonok"),
          icon: false,
          onselect: (e: any) => {
            if (editor.getContent().includes("sni-icon-wrapper")) {
              let div = editor.getBody().getElementsByClassName("sni-icon-wrapper")[0];
              div.innerHTML = div.innerHTML + e.control.state.data.value;
            } else {
              editor.insertContent('<div class="sni-icon-wrapper">' + e.control.state.data.value + '</div>')
            }
          },
          values: [
            { text: __('Paint ikon'), value: '<img class="sni-icon" src="/img/app/paint.png" alt="Paint ikon" />' },
            { text: __('Mappa ikon'), value: '<img class="sni-icon" src="/img/app/folder.png" alt="Mappa ikon" />' },
            { text: __('Word ikon'), value: '<img class="sni-icon" src="/img/app/word.png" alt="Word ikon" />' },
            { text: __('Jegyzettömb ikon'), value: '<img class="sni-icon" src="/img/app/notepad.png" alt="Jegyzettömb ikon" />' },
            { text: __('Internet ikon'), value: '<img class="sni-icon" src="/img/app/internet-icon.png" alt="Internet ikon" />' },
            { text: __('Könyvtár ikon'), value: '<img class="sni-icon" src="/img/app/library.png" alt="Könyvtár ikon" />' },
            { text: __('Imagine ikon'), value: '<img class="sni-icon" src="/img/app/imagine.png" alt="Imagine ikon" />' },
            { text: __('Excel ikon'), value: '<img class="sni-icon" src="/img/app/excel.png" alt="Excel ikon" />' }
          ],
        });


        editor.addButton('set_tooltip', {
          text: __("Szövegmagyarázat"),
          tooltip: __("Szövegmagyarázat (title / tooltip)"),
          onclick: function () {

            var $selectedElement = $(editor.selection.getNode());

            var currentTitle = $selectedElement.attr("title");

            if (!$selectedElement.attr("title") && !editor.selection.getContent()) {
              alert(__('Kérem válasszon ki egy szövegrészletet'));
              return;
            }

            editor.windowManager.open({
              title: __('Szövegmagyarázat'),
              body: [
                { type: 'textbox', name: 'description', label: __('Szövegmagyarázat (tooltip / title)'), value: currentTitle }
              ],
              onsubmit: function (e: any) {

                if ($selectedElement.attr("title")) {
                  $selectedElement.attr("title", e.data.description);

                  if (e.data.description) {
                    $selectedElement.addClass("has-tip");
                    $selectedElement.attr("data-tooltip");
                  } else {
                    $selectedElement.removeClass("has-tip");
                    $selectedElement.removeAttr("data-tooltip");
                  }

                } else {
                  editor.selection.setContent(`<span class="has-tip" data-tooltip title="${e.data.description}">` + editor.selection.getContent() + '</span>');
                }
              }
            });

          }

        });


        editor.addButton('insert_figcaption', {
          text: __("Képaláírás"),
          tooltip: __("Képaláírás"),
          onclick: function () {

            var $selectedElement = $(editor.selection.getNode());

            if ($selectedElement.is('img')) {
              // Open window
              editor.windowManager.open({
                title: __('Képaláírás szövege'),
                body: [
                  { type: 'textbox', name: 'description', label: __('Szöveg') }
                ],
                onsubmit: function (e: any) {

                  if ($selectedElement.parents('figure').length > 0) {
                    $selectedElement.parents('figure').append('<figcaption>' + e.data.description + '</figcaption>');
                  }
                  else {
                    $selectedElement.wrap('<figure></figure>')
                    $selectedElement.parents('figure').append('<figcaption>' + e.data.description + '</figcaption>');
                  }
                }
              });
            } else {
              alert(__('A kijelölt tartalom nem kép!'));
            }

          }

        });

        editor.addButton('add_lightbox', {
          text: __("Képnagyítás"),
          tooltip: __("Képnagyítás"),
          onclick: function () {

            var $selectedElement = $(editor.selection.getNode());

            if ($selectedElement.is('img')) {

              if ($selectedElement.parents('a').length > 0 && $selectedElement.parents('a')[0].hasAttribute('data-lightbox')) {
                alert(__('A kép már nagyítható!'));
              }
              else {

                editor.windowManager.open({
                  title: __('Nagyítás'),
                  body: [
                    { type: 'textbox', name: 'description', label: __('Kép címe') }
                  ],
                  onsubmit: function (e: any) {

                    var $kepLink = $('<a class="keptar-link" data-lightbox="keptar" data-title="' + e.data.description + '"></a>');
                    $kepLink.attr('href', $selectedElement.attr('src') || '');

                    $selectedElement.wrap($kepLink)
                  }
                });


              }

            } else {
              alert(__('A kijelölt tartalom nem kép!'));
            }

          }

        });

        editor.addButton('insert_modal_control', {
          text: __("Modális ablak vezérlő"),
          tooltip: __("Modális ablak vezérlő"),
          onclick: function () {

            var $selectedElement = $(editor.selection.getNode());

            // Open window
            editor.windowManager.open({
              title: __('Modális ablak vezérlő beillesztése'),
              body: [
                {
                  type: 'container',
                  html: "<p>" + __('Adja meg annak a szekciónak(modális ablak) az azonosítóját, amelyet vezérelni akar.') + "</p>"
                },
                { type: 'textbox', name: 'section_id', label: __('Szekció azonosító') },
                !editor.selection.getContent() && { type: 'textbox', name: 'title', label: __('Szöveg') },
                { type: 'checkbox', name: 'is_exercise', label: __('Okosfeladat (ikon elhelyezése a hivatkozásban)') }
              ],
              onsubmit: function (e: any) {
                var link_class = "";
                if (e.data.is_exercise) {
                  link_class = "exercise-modal";
                }

                const content = e.data.title || editor.selection.getContent();
                editor.selection.setContent('<span data-modal-control class="modal-control ' + link_class + '" data-open="' + e.data.section_id.replace("#", "") + '">' + content + '</span>');

              }
            });


          }

        });

        /*
        editor.addButton('open-image-select', {
          text: "Kép kiválasztása",
          tooltip: "OnlyOffice kép kiválasztása",
          onclick: function() {
            this.focus();
            sectionHTMLEditor.props.chapterEditor.setActiveEditor(sectionHTMLEditor);
            sectionHTMLEditor.props.chapterEditor.openImageSelectDialog();
          }
        });
        */

      }
    );
  }

  onBlur() {
    /* react-tinymce-input-ban (lásd alul) be van állítva, hogy ignoreUpdatesWhenFocused
       De ez viszont elrontja a kiválasztást, ami kell a képek / nyers szöveg beillesztéséhez.
       Ezért itt visszaállítjuk a kiválasztást.
     */
    const bookmark = this.tinyMCE.selection.getBookmark(2, true);
    setTimeout(() => {
      this.tinyMCE.selection.moveToBookmark(bookmark);
    }, 1);
  }

  componentDidMount()
  {
    window.addEventListener("resize", this.onResize.bind(this));
    if(this.state.isSNIDisplay)
    {
      this.stretchSection();
    
    }
  }

  componentWillUnmount()
  {
    window.removeEventListener("resize", this.onResize.bind(this));
  }

  onResize()
    {
        if(this.state.isSNIDisplay)
        {
            this.stretchSection();
        }
    }

  stretchSection()
  {
      if(this.refs.editor)
      {
          const sectionWidth = 1170;
          var iframe = ((this.refs.editor as HTMLElement).querySelector('iframe') as HTMLElement);
          var editArea = ((this.refs.editor as HTMLElement).querySelector('.mce-edit-area') as HTMLElement);


          if(iframe)
          {
              var ratio = editArea.clientWidth / sectionWidth ;
              iframe.style.transform = "scale(" + ratio + ")";
          }
      }
      
  }

  render() {
    const section = this.props.section;
    const lessonEditor = this.props.lessonEditor;


    if (this.tinyMCE && this.tinyMCE.selection && this.tinyMCE.selection.getNode().getAttribute("src")) {

      this.lastSelectedImagePath = this.tinyMCE.selection.getNode().getAttribute("src");
      if (this.lastSelectedImagePath && !this.lastSelectedImagePath.startsWith("/")) {
        this.lastSelectedImagePath = this.props.urlRoot + "/" + this.lastSelectedImagePath;
      }

    }

    return <div className={this.state.isSNIDisplay ? "sni-view" : ""}>
    <div className="editor-wrapper">
      <div className="editor" ref="editor">
      {
        this.state.showSettingsDialog
          ?
          <Dialog title={__("Kép beállítások")} width={1000} height={800} onClose={() => this.setState({ showSettingsDialog: false })}>
            <ImageSettings
              filePath={this.lastSelectedImagePath}
              tinyMCE={this.tinyMCE}
              onClose={() => this.setState({ showSettingsDialog: false })}
              editing={true}

              showFileName={this.lastSelectedImagePath.replace(this.props.urlRoot, "").replace("./", "")}
            />
          </Dialog>
          :
          ""
      }

      <TinyMCE
        tinymceConfig={{
          plugins: 'link code image lists template table textcolor visualblocks advlist noneditable paste', // autoresize nem működik jól, mindig maxra áll
          toolbar1: 'formatselect styleselect wrap_box_div | undo redo | bold italic | alignleft aligncenter alignright alignjustify blockquote | bullist numlist | forecolor backcolor | link table | visualblocks code', // image
          toolbar2: 'template | bubble_modal_control | quote-characters | sni-icon | level-icon | set_tooltip | add_paragraph | latex_menu | insert_modal_control', // insert_figcaption add_lightbox
          image_caption: true,
          noneditable_leave_contenteditable: true,

          language: 'hu_HU',

          menubar: false, // no menu bar
          //statusbar: false, // status bar is needed so the user can resize the textarea
          height: this.state.isSNIDisplay ? "100%" : this.props.defaultHeightPx + "px",
         // max_height: this.state.isSNI ? "100%" : null,
          //autoresize_bottom_margin: 20, // for autoresize plugin
          //autoresize_min_height: "10px", // without this the autoresize plugin will give 0 height sometimes when the section is collapsed / expanded
          //autoresize_max_height: "500px", // without this you cannot format long texts (the format buttons scroll out of the window)
          //autoresize_max_height: this.state.isSNI ? "100%" : null,
          templates: !this.props.isSNIDisplay ? [ // for template plugin          
            { title: __('Két oszlop (1/2 - 1/2)'), description: __('Két oszlopos elrendezés'), url: '/templates/2_oszlop_fel_fel.html' },
            { title: __('Két oszlop (1/4 - 3/4)'), description: __('Két oszlopos elrendezés'), url: '/templates/2_oszlop_negyed_haromnegyed.html' },
            { title: __('Két oszlop (3/4 - 1/4)'), description: __('Két oszlopos elrendezés'), url: '/templates/2_oszlop_haromnegyed_negyed.html' },
            { title: __('Két oszlop (1/3 - 2/3)'), description: __('Két oszlopos elrendezés'), url: '/templates/2_oszlop_harmad_ketharmad.html' },
            { title: __('Két oszlop (2/3 - 1/3)'), description: __('Két oszlopos elrendezés'), url: '/templates/2_oszlop_ketharmad_harmad.html' },
            { title: __('Három oszlop (1/3 - 1/3 - 1/3)'), description: __('Három oszlopos elrendezés'), url: '/templates/3_oszlop_harmadok.html' },
            { title: __('Három oszlop (1/2 - 1/4 - 1/4)'), description: __('Három oszlopos elrendezés'), url: '/templates/3_oszlop_fel_negyed_negyed.html' },
            { title: __('Három oszlop (1/4 - 1/2 - 1/4)'), description: __('Három oszlopos elrendezés'), url: '/templates/3_oszlop_negyed_fel_negyed.html' },
            { title: __('Három oszlop (1/4 - 1/4 - 1/2)'), description: __('Három oszlopos elrendezés'), url: '/templates/3_oszlop_negyed_negyed_fel.html' },
            { title: __('Négy oszlop'), description: __('Négy oszlopos elrendezés'), url: '/templates/4_oszlop_negyedek.html' },
            { title: __('Negyed kép jobbra, körbefutó szöveg'), description: __('Negyed kép jobbra igazítása, körbefutó szöveggel'), url: '/templates/negyed_kep_jobbra_float.html' },
            { title: __('Negyed kép balra, körbefutó szöveg'), description: __('Negyed kép balra igazítása, körbefutó szöveggel'), url: '/templates/negyed_kep_balra_float.html' },
            { title: __('Harmad kép jobbra, körbefutó szöveg'), description: __('Harmad kép jobbra igazítása, körbefutó szöveggel'), url: '/templates/harmad_kep_jobbra_float.html' },
            { title: __('Harmad kép balra, körbefutó szöveg'), description: __('Harmad kép balra igazítása, körbefutó szöveggel'), url: '/templates/harmad_kep_balra_float.html' },
            { title: __('Fél kép jobbra, körbefutó szöveg'), description: __('Fél kép jobbra igazítása, körbefutó szöveggel'), url: '/templates/fel_kep_jobbra_float.html' },
            { title: __('Fél kép balra, körbefutó szöveg'), description: __('Fél kép balra igazítása, körbefutó szöveggel'), url: '/templates/fel_kep_balra_float.html' },
            { title: __('Számozott feladatok'), description: __('Számozott feladatok'), url: '/templates/szamozott_feladatok.html' }
          ]
            :
            [{ title: __('SNI 1x2'), description: __('SNI 1x2'), url: '/templates/SNI_1x2.html' }, 
            { title: __('SNI 1x3'), description: __('SNI 1x3'), url: '/templates/SNI_1x3.html' }, 
            { title: __('SNI 1x4'), description: __('SNI 1x4'), url: '/templates/SNI_1x4.html' },  
            { title: __('SNI 1x5'), description: __('SNI 1x5'), url: '/templates/SNI_1x5.html' },  
            { title: __('SNI 2x1'), description: __('SNI 2x1'), url: '/templates/SNI_2x1.html' }, 
            { title: __('SNI 2x2'), description: __('SNI 2x2'), url: '/templates/SNI_2x2.html' },            
            { title: __('SNI 2x3'), description: __('SNI 2x3'), url: '/templates/SNI_2x3.html' },
            { title: __('SNI 3x3'), description: __('SNI 3x3'), url: '/templates/SNI_3x3.html' },
            { title: __('SNI 1/3 2/3 oszlop'), description: __('SNI 1/3 2/3 oszlop'), url: '/templates/SNI_egyharmad_ketharmad_oszlop.html' },
            { title: __('SNI 2/3 1/3 oszlop'), description: __('SNI 2/3 1/3 oszlop'), url: '/templates/SNI_ketharmad_egyharmad_oszlop.html' },
            { title: __('SNI 1/3 2/3 sor'), description: __('SNI 1/3 2/3 sor'), url: '/templates/SNI_egyharmad_ketharmad_sor.html' },
            { title: __('SNI 2/3 1/3 sor'), description: __('SNI 2/3 1/3 sor'), url: '/templates/SNI_ketharmad_egyharmad_sor.html' },

            { title: __('SNI ikon oszlop'), description: __('SNI ikon oszlop'), url: '/templates/SNI_ikon_oszlop.html' },
            
          ],
          entity_encoding: "raw", // or else hungarian characters will be converted to entities: á -> &aacute;

          convert_urls: false, // or else the image links are broken

          document_base_url: this.props.urlRoot + "/", // Proxied in package.json

          content_css: [
            "/lib/foundation-6.5.1/foundation.min.css",
            "/css/book_flex.css",
          ],

          content_style: `
          body {padding:5px;}
          ol, ul { padding-left: 1rem;}
          .row {border: 1px dashed green;}
          
          .small-2, .small-3, .small-4, .small-5, .small-6, .small-7, .small-8, .small-9, .small-10, .small-11 {border: 1px dashed brown;} 
          .medium-2, .medium-3, .medium-4, .medium-5, .medium-6, .medium-7, .medium-8, .medium-9, .medium-10, .medium-11 {border: 1px dashed brown;} 
          figure:empty{padding:30px !important;}
          figure.float-right, figure.float-left {border: 1px dashed purple;}

          div.mxgraph:before {border: 3px dashed red; color: red; content: 'draw.io vektorgrafikus kép';}

          .grid .cel {border: 1px dashed brown;}

            body:after {content: ''; display: block; clear: both;} /* or else the float elements will overflow vertically, because the autoresize plugin*/
          `
          + (this.props.isSNIDisplay ? `
          html, body, .mceTmpl {height: 100%; max-height: 100%;}
          ` : '')
          ,


          style_formats: [
            {
              title: __('Karakterek'), items: [
                {
                  title: __("Félkövér (strong)"),
                  inline: "strong",
                },
                {
                  title: __("Dőlt (em)"),
                  inline: "em",
                },
                {
                  title: __("Aláhúzott"),
                  inline: "span",
                  styles: { 'text-decoration': 'underline' },
                },
                {
                  title: __("Felső index (sup)"),
                  inline: "sup",
                },
                {
                  title: __("Alsó index (sub)"),
                  inline: "sub",
                },
                {
                  title: __("Idézet hivatkozás (cite)"),
                  block: "cite",
                },
              ]
            },
            {
              title: __('Szó kiemelés'), items: [
                {
                  title: __("Kék kiemelés"),
                  inline: "span",
                  classes: 'blue label',
                },
                {
                  title: __("Sárga kiemelés"),
                  inline: "span",
                  classes: 'label warning',
                },
                {
                  title: __("Zöld kiemelés"),
                  inline: "span",
                  classes: 'green label',
                },
                {
                  title: __("Piros kiemelés"),
                  inline: "span",
                  classes: 'red label',
                },
                {
                  title: __("Barna kiemelés"),
                  inline: "span",
                  classes: 'brown label',
                },
                {
                  title: __("Szürke kiemelés"),
                  inline: "span",
                  classes: 'grey label',
                },
                {
                  title: __("Homoksárga kiemelés"),
                  inline: "span",
                  classes: 'golden-sand label',
                },
                {
                  title: __("Krém kiemelés"),
                  inline: "span",
                  classes: 'cream-can label',
                },
                {
                  title: __("Türkiz kiemelés"),
                  inline: "span",
                  classes: 'turquoise-blue label',
                },
                {
                  title: __("Tujazöld kiemelés"),
                  inline: "span",
                  classes: 'conifer label',
                },
                {
                  title: __("Texas rózsa kiemelés"),
                  inline: "span",
                  classes: 'texas-rose label',
                },
                {
                  title: __("Kunkor lila kiemelés"),
                  inline: "span",
                  classes: 'heliotrope label',
                },
                {
                  title: __("Geraldine kiemelés"),
                  inline: "span",
                  classes: 'geraldine label',
                },
                {
                  title: __("Lila kiemelés"),
                  inline: "span",
                  classes: 'wistful label',
                },
                {
                  title: __("Informatika parancs, kiírás"),
                  inline: "span",
                  classes: 'code label',
                }
              ]
            },
            {
              title: __('Mondatelemzés'), items: [
                {
                  title: __("Alany"),
                  inline: "span",
                  classes: 'border-line',
                },
                {
                  title: __("Állítmány"),
                  inline: "span",
                  classes: 'border-double',
                },
                {
                  title: __("Tárgy"),
                  inline: "span",
                  classes: 'border-dashed',
                },
                {
                  title: __("Határozó"),
                  inline: "span",
                  classes: 'border-wavy',
                },
                {
                  title: __("Jelző"),
                  inline: "span",
                  classes: 'border-dotted',
                },
              ]
            },

          ],

          style_formats_autohide: true,
          style_formats_merge: false,

        }}

        ignoreUpdatesWhenFocused /* Ha ez nincs, akkor levágja a szóközöket a <p> végén, amikor gépelsz! Lásd még: onBlur()! */

        pollInterval={100} /* Default 1000-nél ha gyorsan kikattintasz, eltűnik az utolsó 1 másodperc változtatása */

        //initialValue={section.text}
        value={this.props.section.values[this.props.templateVariableId] || ""}
        onChange={lessonEditor.handleEditorChange.bind(lessonEditor, section.id, this.props.templateVariableId)}
        onFocus={lessonEditor.setActiveEditor.bind(lessonEditor, this)}
        onBlur={this.onBlur.bind(this)}
        onSetupEditor={this.setupEditor()}

      />
      </div>
      </div>
    </div>;
  }
}