import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewUsrFavouriteFileRecord {
    id?: number;
    sec_user_id?: number|null;
    table_info_id?: number|null;
    oo_file_id?: number|null;
    creation_time?: string /*timestamp?*/|null;
    /**
     * Felhasználó bejelentkezési neve
     *
     * Felhasználó bejelentkezési neve
     */
    login_name?: string|null;
    /**
     * Felhasználó teljes neve
     *
     * Felhasználó teljes neve
     */
    fullname?: string|null;
    /**
     * Kedvenc
     *
     * A hivatkozott kedvenc tartalom egysoros szöveges reprezentációja
     */
    displayvalue?: string|null;
    /**
     * A hivatkozott kedvenc táblázat sémája
     *
     * A hivatkozott kedvenc táblázat sémája
     */
    schema_name?: string|null;
    /**
     * A hivatkozott kedvenc táblázat neve
     *
     * A hivatkozott kedvenc táblázat neve
     */
    table_name?: string|null;
    /**
     * A hivatkozott kedvenc táblázat magyar nyelvű neve
     *
     * A hivatkozott kedvenc táblázat magyar nyelvű neve
     */
    table_display_name?: string|null;
    /**
     * A hivatkozott kedvenc táblázat magyar nyelvű leírása
     *
     * A hivatkozott kedvenc táblázat magyar nyelvű leírása
     */
    table_description?: string|null;
    /**
     * OnlyOffice file azonosító
     *
     * OnlyOffice file azonosító
     */
    oo_fileid?: string|null;
    /**
     * Állománynév
     *
     * Állománynév
     */
    title?: string|null;
    /**
     * Kiterjesztés
     *
     * Kiterjesztés
     */
    ext?: string|null;
    /**
     * Állomány megjelenített neve
     *
     * Állomány megjelenített neve
     */
    displayname?: string|null;
    /**
     * Content-Type
     *
     * Content-Type
     */
    content_type?: string|null;
    /**
     * Relatív útvonal
     *
     * Relatív útvonal
     */
    relpath?: string|null;
    /**
     * Kulcsszavak
     *
     * Kulcsszavak
     */
    keywords?: string|null;
    /**
     * Fizikai méret
     *
     * Fizikai méret
     */
    size?: number|null;
    /**
     * Szélesség képpontban
     *
     * Szélesség képpontban
     */
    width_px?: number|null;
    /**
     * Magasság képpontban
     *
     * Magasság képpontban
     */
    height_px?: number|null;
    /**
     * SHA1
     *
     * SHA1
     */
    sha1?: string|null;
    /**
     * Melyik mappában van
     *
     * Melyik mappában van
     */
    oo_folder_id?: number|null;
    /**
     * Van bélyegkép
     *
     * Van bélyegkép
     */
    has_thumb?: boolean|null;
    /**
     * Média típus
     *
     * Média típus
     */
    media_type_id?: number|null;
    /**
     * Vimeo azonosító
     *
     * Vimeo azonosító
     */
    vimeo_id?: number|null;
    /**
     * Lejátszások száma Vimeo-n
     *
     * Lejátszások száma Vimeo-n
     */
    vimeo_plays?: number|null;
}

/**
 *  usr.view_usr_favourite_file - Kedvenc állomány
 *
 *  A felhasználók által kedvelt állományok
 */

export default class ViewUsrFavouriteFile extends View<IViewUsrFavouriteFileRecord> {
    public static VIEW_INFO_ID = 2018061402;

    public static getSchemaNameForClass(): string { return 'usr'; }
    public static getViewNameForClass(): string { return 'view_usr_favourite_file'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewUsrFavouriteFile>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewUsrFavouriteFileRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewUsrFavouriteFileClassProxy extends ViewClassProxy<IViewUsrFavouriteFileRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewUsrFavouriteFileClassProxy = new ViewUsrFavouriteFileClassProxy(ViewUsrFavouriteFile);
registerViewClassProxy(viewUsrFavouriteFileClassProxy);

