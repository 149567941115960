import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ICollectionItemRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Gyűjtemény
     *
     * NOT NULL -> usr.collection ON DELETE cascade
     *
     */
    collection_id?: number;
    /**
     * Amit kedvel - Táblázat infó
     *
     * NOT NULL -> meta.table_info ON DELETE noaction
     *
     *
     * Ez mondja meg, hogy a gyűjtemény elem melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number;
    /**
     * Amit kedvel - rekord azonosító
     *
     * Ez mondja meg, hogy a gyűjtemény elem melyik rekordhoz tartozik.
     */
    rec_id?: number;
}

/**
 *  usr.collection_item - Gyűjtemény elem
 *
 *  Ez egy heterogén gyűjtő, amibe több különböző típusú tartalmat lehet adott sorrendben belepakolni.
 */

export default class CollectionItemCrud extends Crud<ICollectionItemRecord> {
    public static TABLE_INFO_ID = 3123213756;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'collection_item'; }

    public static load: (id: number, aServer ?: Server) => Promise<CollectionItemCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ICollectionItemRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ICollectionItemRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ICollectionItemRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ICollectionItemRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ICollectionItemRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class CollectionItemCrudClassProxy extends CrudClassProxy<ICollectionItemRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const collectionItemCrudClassProxy = new CollectionItemCrudClassProxy(CollectionItemCrud);
registerCrudClassProxy(collectionItemCrudClassProxy);
