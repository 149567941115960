import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewWfHeadTableRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Folyamat típus
     */
    wf_type_id?: number;
    /**
     * Fej táblázat
     */
    table_id?: number;
    /**
     * Automatikus indítás
     *
     * Ha ez be van álíltva, akkor a folyamat fej táblázatba való beszúráskor ez a típusú folyamat automatikusan elindul.
     */
    auto_create?: boolean;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Típuskód
     *
     * A folyamat típus kódja
     */
    wf_type_code?: string|null;
    /**
     * Típusnév
     *
     * A folyamat típus neve
     */
    wf_type_name?: string|null;
    /**
     * Séma neve
     *
     * A fejtábla sémájnak neve
     */
    schema_name?: string|null;
    /**
     * Tábla neve
     *
     * A fejtábla neve
     */
    table_name?: string|null;
    /**
     * Tábla címe
     *
     * A fejtábla (emberi) neve
     */
    table_display_name?: string|null;
    /**
     * Tábla leírása
     *
     * A tábla leírása
     */
    table_description?: string|null;
}

/**
 *  wf.view_wf_head_table - Folyamat fej táblázat
 *
 *  Ez a táblázat határroza meg, hogy melyik folyamat típus melyik (fej) táblázathoz tartozik. Például a könyv (publikálási) folyamat típus a könyv (book) táblázathoz. Megjegyzés: egy folyamat típust több fejhez is hozzá lehet kapcsolni.
 */

export default class ViewWfHeadTable extends View<IViewWfHeadTableRecord> {
    public static VIEW_INFO_ID = 2018040507;

    public static getSchemaNameForClass(): string { return 'wf'; }
    public static getViewNameForClass(): string { return 'view_wf_head_table'; }
    public static getTableNameForClass(): string|null { return 'head_table'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewWfHeadTable>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewWfHeadTableRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewWfHeadTableClassProxy extends ViewClassProxy<IViewWfHeadTableRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewWfHeadTableClassProxy = new ViewWfHeadTableClassProxy(ViewWfHeadTable);
registerViewClassProxy(viewWfHeadTableClassProxy);

