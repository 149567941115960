import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IPartnerTypeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Címke
     */
    label?: string;
}

/**
 *  sys.partner_type - Partner típus
 */

export default class PartnerTypeCrud extends Crud<IPartnerTypeRecord> {
    public static TABLE_INFO_ID = 311620344;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'partner_type'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<PartnerTypeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IPartnerTypeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IPartnerTypeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IPartnerTypeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IPartnerTypeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IPartnerTypeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class PartnerTypeCrudClassProxy extends CrudClassProxy<IPartnerTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const partnerTypeCrudClassProxy = new PartnerTypeCrudClassProxy(PartnerTypeCrud);
registerCrudClassProxy(partnerTypeCrudClassProxy);
