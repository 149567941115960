import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IKipDraftRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Könyvtár
     *
     * NOT NULL -> doc.library ON DELETE cascade
     *
     */
    library_id?: number;
    /**
     * Tulajdonos/felelős
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     */
    owner_id?: number;
    /**
     * Szerzők
     */
    authors?: string;
    /**
     * Műveltségi terület
     *
     * NOT NULL -> doc.literacy_field ON DELETE noaction
     *
     */
    literacy_field_id?: number;
    /**
     * Tantárgy
     *
     * NOT NULL -> doc.subject ON DELETE noaction
     *
     */
    subject_id?: number;
    /**
     * Évfolyam
     *
     * -> doc.grade ON DELETE noaction
     *
     */
    grade_id?: number|null;
    /**
     * Csoport/osztály típusa
     *
     * -> kap.group_type ON DELETE noaction
     *
     */
    group_type_id?: number|null;
    /**
     * Csoport/Osztály típusa (egyéb)
     */
    group_type_other?: string|null;
    /**
     * Tematikus egység
     */
    thematic_unit?: string|null;
    /**
     * Az óra témája
     */
    topic?: string;
    /**
     * Az óra cél- és feladatrendszere
     */
    goal?: string;
    /**
     * Óra típusa
     *
     * NOT NULL -> kap.lesson_type ON DELETE noaction
     *
     */
    lesson_type_id?: number;
    /**
     * Az óra típusa (egyéb)
     */
    lesson_type_other?: string|null;
    /**
     * Kereszttantervi kapcsolatok
     */
    cross_curriculum_connections?: string|null;
    /**
     * Taneszközök
     */
    tools?: string;
    /**
     * Felhasznált források
     */
    used_sources?: string|null;
    /**
     * A foglalkozásterv gyökér könyvtára.
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number;
    /**
     * Nagy gondolat
     */
    big_tought_html?: string;
    /**
     * Ráhangolás, motiváció felkeltése, motivációs eszközök bemutatása
     */
    introduction_html?: string|null;
    /**
     * Mellékletek
     */
    html_body?: string|null;
    /**
     * DFHT KIP óraterv
     *
     * -> kap.kip_draft ON DELETE noaction
     *
     */
    copied_from_id?: number|null;
    /**
     * DFHT KIP óraterv
     *
     * -> kap.kip_draft ON DELETE noaction
     *
     */
    last_copy_id?: number|null;
    /**
     * Publikáláshoz hozzájárult
     */
    consent_publication?: string /*timestamp?*/|null;
    /**
     * Kiválónak jelölve
     */
    marked_excellent?: string /*timestamp?*/|null;
}

/**
 *  kap.kip_draft - DFHT KIP óraterv
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class KipDraftCrud extends Crud<IKipDraftRecord> {
    public static TABLE_INFO_ID = 2054409697;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'kip_draft'; }

    public static load: (id: number, aServer ?: Server) => Promise<KipDraftCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IKipDraftRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IKipDraftRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IKipDraftRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IKipDraftRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IKipDraftRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class KipDraftCrudClassProxy extends CrudClassProxy<IKipDraftRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const kipDraftCrudClassProxy = new KipDraftCrudClassProxy(KipDraftCrud);
registerCrudClassProxy(kipDraftCrudClassProxy);
