import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewHtrTicketMessageRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * HTR bejelentés
     */
    htr_ticket_id?: number;
    /**
     * Üzenet
     *
     * Üzenet HTML formában. Képek használhatók a bejelentés mappjából.
     */
    message_html?: string;
    /**
     * Küldő e-mail
     *
     * Küldő e-mail
     */
    email?: string|null;
    /**
     * Küldő személy azonosító
     *
     * Küldő személy azonosító
     */
    person_id?: number|null;
    /**
     * Küldő teljes neve
     *
     * Küldő teljes neve
     */
    fullname?: string|null;
}

/**
 *  ticket.view_htr_ticket_message - HTR bejelentés üzenet
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewHtrTicketMessage extends View<IViewHtrTicketMessageRecord> {
    public static VIEW_INFO_ID = 2019101403;

    public static getSchemaNameForClass(): string { return 'ticket'; }
    public static getViewNameForClass(): string { return 'view_htr_ticket_message'; }
    public static getTableNameForClass(): string|null { return 'htr_ticket_message'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewHtrTicketMessage>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewHtrTicketMessageRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewHtrTicketMessageClassProxy extends ViewClassProxy<IViewHtrTicketMessageRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewHtrTicketMessageClassProxy = new ViewHtrTicketMessageClassProxy(ViewHtrTicketMessage);
registerViewClassProxy(viewHtrTicketMessageClassProxy);

