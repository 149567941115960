import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IGenderRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Név
     */
    name?: string;
}

/**
 *  sys.gender - Nem (nő/férfi)
 *
 *  Ez összesen két értéket vehet föl (1=férfi, 2=nő).
 */

export default class GenderCrud extends Crud<IGenderRecord> {
    public static TABLE_INFO_ID = 1764514864;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'gender'; }

    public static load: (id: number, aServer ?: Server) => Promise<GenderCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IGenderRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IGenderRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IGenderRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IGenderRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IGenderRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class GenderCrudClassProxy extends CrudClassProxy<IGenderRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const genderCrudClassProxy = new GenderCrudClassProxy(GenderCrud);
registerCrudClassProxy(genderCrudClassProxy);
