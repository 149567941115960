import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ITaskRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Importálási feladat típus
     *
     * NOT NULL -> data_imp.task_type ON DELETE noaction
     *
     */
    task_type_id?: number;
    /**
     * Importálási feladat állapota
     *
     * NOT NULL -> data_imp.task_status ON DELETE noaction
     *
     */
    task_status_id?: number;
    /**
     * Az importálási feladat gyökér könyvtára.
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number;
    /**
     * Cím
     */
    title?: string|null;
    /**
     * Elindítva
     *
     * Az importálási feladat ekkor lett elindítva
     */
    started?: string /*timestamp?*/|null;
    /**
     * Befejezve
     *
     * Az importálási feladat ekkor lett befejezve (csak ha sikeres volt).
     */
    completed?: string /*timestamp?*/|null;
    /**
     * Sikertelen
     *
     * Az importálási feladat ekkor futott hibára (csak ha sikertelen volt).
     */
    failed?: string /*timestamp?*/|null;
}

/**
 *  data_imp.task - Importálási feladat
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class TaskCrud extends Crud<ITaskRecord> {
    public static TABLE_INFO_ID = 2362448029;
    public static getSchemaNameForClass() { return 'data_imp'; }
    public static getTableNameForClass() { return 'task'; }

    public static load: (id: number, aServer ?: Server) => Promise<TaskCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ITaskRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ITaskRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ITaskRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ITaskRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ITaskRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class TaskCrudClassProxy extends CrudClassProxy<ITaskRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const taskCrudClassProxy = new TaskCrudClassProxy(TaskCrud);
registerCrudClassProxy(taskCrudClassProxy);
