export class EKEFillGapDropDownServer{

    static evaluateOnServer(exercise: any, correctSolution: any[], userSolution: any[]): Evaluated {
        let success = true;
        let successPercent = 0;
        let correctAnsNum = 0;
        let numOfQuestions = correctSolution.length;

        for (let i = 0; i < correctSolution.length; i++) {
            if (JSON.stringify(correctSolution[i]) == JSON.stringify(userSolution[i])) {
                correctAnsNum++;
            }
            else {
                success = false;
            }
        }

        if (correctAnsNum > 0)
            successPercent = Number((correctAnsNum / numOfQuestions).toFixed(2));
        return { "success": success, "solution": correctSolution, "successPercent": successPercent }
    }
}