import obtainServer, { config } from './../framework/server/Server';
import { IViewLessonRecord } from './../framework/view/doc/ViewLesson';
import { IViewChapterRecord } from './../framework/view/doc/ViewChapter';
import axios from 'axios'
import * as superagent from 'superagent';
import { app } from '@src/index';
import { api } from '@framework/server/Auth';
import { Section } from '@src/server/EditorServer';
import { ISupplementaryMaterialRecord } from '@src/framework/crud/doc/SupplementaryMaterialCrud';
import * as queryString from 'query-string';
import { IViewMediaOoFileRecord } from '@src/framework/view/media/ViewMediaOoFile';
import { objectToQueryParams } from '@framework/util/UrlUtils';
import { IViewExcExerciseRecord } from '@src/framework/view/exc/ViewExcExercise';
import { IViewExcSeriesRecord } from './../framework/view/exc/ViewExcSeries';
import { TimeStamp, ExerciseRunDetails } from '@src/component/exercise/models/ExerciseRunDetails';
import { parseTimestampStr } from '@src/framework/forms/datetimepicker';
import { number } from 'prop-types';
import { IEventRecord } from '@src/framework/crud/calendar/EventCrud';

/** ==================================================
 *                  Tankönyv adatok
 *  ==================================================
 */
export type ChapterJoinLesson = {
    chapter_id: number,
    chapter_name: string,
    chapter_headno: number,
    lesson_id: number,
    lesson_name: string,
    lesson_headno: number,
    lesson_uri_segment: string,
    viewed?:boolean
}

export type BookStructure = {
    id: number,
    name: string,
    library_id: number,
    is_sni: boolean,
    lang_id: number,
    display_mode_id: number,
    slogan: string,
    uri_segment: string,
    subject_id: number,
    subject_name: string,
    grade_id: number,
    grade_name: string,
    lessons: ChapterJoinLesson[],
    headerFileNames?: string[],
    css_path: string,
    downloadAsZipFolderId?: number,
}


export function getBookStructureForUri(bookUriSegment: string) {
    return obtainServer().get<BookStructure>("get_book_structure?book_uri_segment=" + encodeURIComponent(bookUriSegment));
}

export async function getBookStructureForId(id: number): Promise<BookStructure> {
    return obtainServer().get<BookStructure>("get_book_structure?id=" + id);
}


export type ChapterLessons = IViewChapterRecord & {
    lessons: IViewLessonRecord[]
}

export type RenderedSection = {
    id: number;
    html: string;
    name: string;    
    blockTemplateName: string;
    exerciseIsHiddenByDefault?: boolean;
    exerciseInPopup: boolean;
    isTeachersGuide: boolean;
    attachedAudios?: { src: string, title: string }[];
    title?: string;
}


export type BookLessonContent = {
    lessonId: number;
    renderedSections: RenderedSection[];
    supplementaryMaterials: ISupplementaryMaterialRecord[];
    assignedCategories: { category_description: string, category_type_title: string }[]
}

export async function getBookLessoncontent(lessonId: number, published: boolean): Promise<BookLessonContent> {
    const response = await obtainServer().get<BookLessonContent>("get_book_lesson_content?id=" + lessonId + "&published=" + (published ? "true" : "false"));
    response.lessonId = lessonId;
    return response;
}

export async function getChapterLessons(bookId: number): Promise<ChapterLessons[]> {
    return await obtainServer().get<ChapterLessons[]>("getChapterLessons?book_id=" + bookId);
}

export type MainPageStats = {
    userCount: number,
    exerciseCount: number,
    animationCount: number,
    videoCount: number
}

export async function getMainPageStats() {
    return await obtainServer().get<MainPageStats>("get_mainpage_stats");
}

export type ProgressionElements = {
    exercises: any,
    exerciseSeries: any,
    exerciseCount: number,
    exerciseSeriesCount: number

}

export async function getProgressionStats(bookId?: number) {
    let url = "get_progression";
    if (bookId) url += "?book_id=" + bookId
    return await obtainServer().get<ProgressionElements>(url);
}

// https://kb.itk.uni-eszterhazy.hu/project/38/task/1915

export interface IGetUserBooksExerciseSolutionsResultExerciseItem {
    exercise_id : number;
    book_id : number;
    chapter_id: number;
    lesson_id: number;
    uri_segment : string;
    chapter_no: number;
    lesson_no: number;
}
interface IGetUserBooksExerciseSolutionsResultRunItem {
    _id: string; /* ObjectId */
    createdAt: string; /* timestamp */
    exerciseId : number;
    exercises: ExerciseRunDetails[];
    finished: string; /* timestamp */
    published ?: boolean;
    userId: number;
}
export interface IGetUserBooksExerciseSolutionsResult {
    exercises : IGetUserBooksExerciseSolutionsResultExerciseItem[];
    runs: { [exerciseId:number] : IGetUserBooksExerciseSolutionsResultRunItem } 
}

export async function getUserBooksExerciseSolutions(bookIds: number[], userId: number, fromDate?: Date, toDate?: Date): Promise<IGetUserBooksExerciseSolutionsResult> {
    return obtainServer().post<IGetUserBooksExerciseSolutionsResult>(
        'getUserBooksExerciseSolutions',
        {
            bookIds,
            userId,
            fromDate:fromDate?fromDate.getTime():undefined,
            toDate:toDate?toDate.getTime():undefined,
        }
    );
}



export type Sha1AndDurationElement = {
    sha1: string,
    duration: string
    plays: number
}
export async function getDurationAndSha1(shas: string[]) {
    return await obtainServer().get<Sha1AndDurationElement[]>("media/get_video_duration?sha_list=" + shas.join(","));

}
/** ==================================================
 *                  Tankönyv szerkesztő
 *  ==================================================
 */

export async function getChapterLessonsWithInactive(bookId: number): Promise<ChapterLessons[]> {
    return await obtainServer().get<ChapterLessons[]>("getChapterLessons?book_id=" + bookId + "&get_inactive=true");
}

export async function putSections(lessonId: number, sections: Section[], externalLinks: ISupplementaryMaterialRecord[]): Promise<Section[]> {
    return await obtainServer().put<Section[]>("putSections?lesson_id=" + lessonId + "&supplementaryMaterials=true", { sections: sections, supplementaryMaterials: externalLinks });
}

export type Chapter = {
    id: number;
    name: string;
    book_id: number;
    no: number;
    is_active: boolean;
}

export type Lesson = {
    id: number;
    name: string;
    chapter_id: number;
    no: number;
    is_active: boolean;
}

export type FileUsage = {
    id: number;
    oo_folder_id: number;
    relpath: string;
    ref_count: number;
    title: string;
    displayname: string | null;
    media_type_id: number | null;
    content_type: string | null;
    ext: string | null;
    sha1: string;
}

export type FileUsageCount = {
    media_type_id: number;
    count: number;
}

export async function getFileUsage(book_id: number, media_type_id: number | null | undefined, min_ref_count: number, max_ref_count: number, limit: number, offset: number): Promise<FileUsage[]> {
    return await obtainServer().get<FileUsage[]>("book_file_usage?" + objectToQueryParams({
        book_id, media_type_id, min_ref_count, max_ref_count, limit, offset, operation: "list"
    }));
}

export async function countFileUsage(book_id: number, media_type_id: number | null | undefined, min_ref_count: number, max_ref_count: number): Promise<FileUsageCount[]> {
    return await obtainServer().get<FileUsageCount[]>("book_file_usage?" + objectToQueryParams({
        book_id, media_type_id, min_ref_count, max_ref_count, operation: "count"
    }));
}

/** ==================================================
 *                      Régi NKP
 *  ==================================================
 */
export async function getPlayData(id: string) {
    return await obtainServer().get<any>("getPlayData?id=" + id);
}

export async function getPDFPartSlide(pdfAssetVersionId: number) {
    return await obtainServer().get<any>("getPDFPartSlide?id=" + pdfAssetVersionId);
}


/** ==================================================
 *                      Profil
 *  ==================================================
 */
interface ChangeSecretPasswordResult {
    successful: boolean;
    error_code: string;
    error_message: string;
}

export async function changePassword(oldPassword: string, newPassword: string) {
    return await obtainServer().post<ChangeSecretPasswordResult>("changePassword?oldPassword=" + encodeURIComponent(oldPassword) + "&newPassword=" + encodeURI(newPassword), {});
}

export async function changePasswordOfUser(userId: number, newPassword: string) {
    return await obtainServer().post<ChangeSecretPasswordResult>("changePasswordOfUser?userId=" + userId + "&newPassword=" + encodeURI(newPassword), {});
}


/** ==================================================
 *                      Feladatok
 *  ==================================================
 */

export type ExerciseWithMeta = {
    exerciseCount: number;
    currentExerciseIndex: number;
    exerciseEngineName: string;
    exercise: any;
    userSolution: any;
    totalPoints: number;
    successPercent?: number;
    evaluationTimeStamp?: TimeStamp
}

/** 
 * 
 * Egy publikált feldatból készít egy másolatot.
 * Ha library_id = Const.LIBRARY_PERSONAL_ID akkor bárki le tudja futtatni,
 * akinek van joga feladatot létrehozni. A többi library-ra csak szerkesztőségi
 * tagok tudják végrehajtani.
 * 
 */
export async function copyPublishedExercise(publishedExerciseId: number, destLibraryId: number): Promise<IViewExcExerciseRecord> {
    return await obtainServer().post<IViewExcExerciseRecord>(
        "copy_published",
        {
            operation: "copy_published_exercise",
            exercise_id: publishedExerciseId,
            library_id: destLibraryId
        }
    );
}

/** 
 * 
 * Egy publikált feldatsorból készít egy másolatot.
 * Ha library_id = Const.LIBRARY_PERSONAL_ID akkor bárki le tudja futtatni,
 * akinek van joga feladatot létrehozni. A többi library-ra csak szerkesztőségi
 * tagok tudják végrehajtani.
 * 
 */
export async function copyPublishedExerciseSeries(publishedExerciseSeriesId: number, destLibraryId: number): Promise<IViewExcSeriesRecord> {
    return await obtainServer().post<IViewExcSeriesRecord>(
        "copy_published",
        {
            operation: "copy_published_exercise_series",
            exercise_series_id: publishedExerciseSeriesId,
            library_id: destLibraryId
        }
    );
}


/** 
 * 
 * Egy publikált könyvből készít egy másolatot.
 * Ha library_id = Const.LIBRARY_PERSONAL_ID akkor bárki le tudja futtatni,
 * akinek van joga feladatot létrehozni. A többi library-ra csak szerkesztőségi
 * tagok tudják végrehajtani.
 * 
 */
export async function copyPublishedBook(publishedBookId: number, destLibraryId: number): Promise<IViewExcSeriesRecord> {
    return await obtainServer().post<IViewExcSeriesRecord>(
        "copy_published",
        {
            operation: "copy_published_book",
            book_id: publishedBookId,
            library_id: destLibraryId
        }
    );
}





/** ==================================================
 *                      Kereső
 *  ==================================================
 */


export async function search(searchParams: SearchParameters): Promise<SearchResult[]> {
    return elasticSearch(searchParams);
}
export async function kapSearch(searchParams: { keyword: string, exactmatch?: boolean, main_type?: string, sub_type?: string, subject_id?: number, grade_id?: number, subprogram_id?: number,category_ids?:number, from?: number, maxhits?: number, countonly?: boolean }): Promise<KapSearchResult[]> {
    return elasticKapSearch(searchParams);
}

export async function searchExercise(exercise_id: number, isPublished: boolean): Promise<SearchResult[]> {
    return elasticExerciseSearch(exercise_id, isPublished);
}

export async function searchAutoComplete(prefix: string): Promise<AutoCompleteSearchResult[]> {
    return elasticAutoCompleteSearch(prefix);
}
export async function searchAutoCompleteKAP(prefix: string): Promise<AutoCompleteSearchResult[]> {
    return elasticAutoCompleteSearchKAP(prefix);
}

export async function searchSimilar(id: number, isPublished: boolean, maxHits: number, type?: string): Promise<SearchResult[]> {
    return elasticSearchSimilar(id, isPublished, maxHits, type);
}

export type AutoCompleteSearchResult = {
    text: string;
}

export type SynonymResult = {
    id: number,
    parent_id: number;
    literal: string;
    parent_literal: string;
}

export type KapSearchResult = {
    id: number;
    category_titles: string[];
    category_ids: number[];

    type: "file_kap" | "kip_draft" | "lesson_draft" | "occupation_draft" | "links";

    subject_id?: number;
    grade_id?: number;
    subprogram_id?: number;

    topic?: string;
    require_time_mins: number[];
    block_type_titles: string[];
    authors?: string;
    subject_name: string;
    grade_name: string;

    title?: string;
    content_type?: string;
    keywords?: string;
    sha1?: string;
    displayname?: string;
    count?: number;

    url?: string;
}

export interface SearchResult {
    id: number;
    _id: string;
    type: string; // | "video";
    score: number;
    text: string;
    highlight?: string;
    displayname?: string;
    path: string;
    subject: string;
    sha1?: string; // thumbnail!    
    book_uri_segment?: string;
    lesson_uri_segment?: string;
    chapter_name?: string;
    lesson_name?: string;
    subject_name?: string;
    subject_uri_segment?: string;
    grade_name?: string;
    section_name?: string;
    section_id?: string;
    title?: string;
    title_text?:string
    exercise_title?: string;
    exercise_name?: string;
    exercise_description?: string;
    exercise_json?: string;
    exercise_level?: number;
    engine_id?: number;
    engine_name?: string;
    duration?: string;
    media_type_id?: number;
    alt_text?: string;
    html: string;
    table_info_id?: number;
    likes?: number;
    count?: number;
}
export type ImageSearchResult = {
    id: string;
    type: string;
    score: number;
    keywords: string;
    size: number;
    sha1: string;
    title: string;
    /*
    id: string;
    type: ImageBitmap; // | "video";
    score: number;
    text: string;
    path: string;
    tag: string; // html tag
    subject: string;
    grade?: string; // "1", "2"...
    chapter?: string; // "1", "2"...
    lesson?: string; // "1", "2"...
    etype?: string;

    imgsrc?: string; // thumbnail!

    chapterTitle?: string,
    lessonTitle?: string,
  
    duration?: string
    */
}

export interface SearchParameters {
    keyword?: string,
    is_adaptive?: boolean | undefined,
    subject?: string | undefined,
    grade?: string | undefined,
    type?: string | undefined,
    book?: string | undefined,
    maxhits?: string | undefined,
    notin?: string | undefined,
    wildcard?: string | undefined,
    exactmatch?: string | undefined,
    subject_id?: string | undefined,
    subject_ids?: number[] | undefined,
    grade_id?: string | undefined,
    institute_type_id?: string | undefined,
    engine_id?: string | undefined,
    exercise_level?: number | undefined,
    user?: string,
    extendedList?: string,
    is_accessible?: boolean | null;
    is_sni?:boolean;
    countonly?: boolean | undefined;
    from?: number |undefined;
    lang_id?: string | undefined;
    difficulity?: number | undefined;
    method?: string | undefined;
    competence?: string | undefined;
    time_frame?: string | undefined,
}

export async function elasticExerciseSearch(exercise_id: number, isPublished: boolean, maxHits?: number): Promise<SearchResult[]> {

    var filterParams = {
        exercise_id: JSON.stringify(exercise_id),
        ispublished: JSON.stringify(isPublished),
        maxhits: maxHits
    }

    queryString.stringify(filterParams);
    var url = config!.searchServer!.apiBaseUrl + "searchExercise?" + queryString.stringify(filterParams);
    try {

        let response;

        if (api)
            response = await axios.get(url, { headers: api!.getAuthorizationHeader() });
        else
            response = await axios.get(url);

        if (!response.data) {
            return [];
        }
        return Promise.resolve(response.data);
    } catch (error) {
        app.showErrorFromJsonResult(error);
        return Promise.reject(error);
    }
}

export async function elasticSearchSimilar(id: number, isPublished: boolean, maxHits: number, type?: string): Promise<SearchResult[]> {
    var url = config!.searchServer!.apiBaseUrl + "searchSimilar?id=" + id + "&ispublished=" + isPublished + "&maxhits=" + maxHits;

    if (type)
        url += "&type=" + type;

    let response;

    if (api)
        response = await axios.get(url, { headers: api!.getAuthorizationHeader() });
    else
        response = await axios.get(url);

    if (!response.data) {
        return [];
    }
    return Promise.resolve(response.data);
}

export async function elasticAutoCompleteSearch(prefix: string): Promise<AutoCompleteSearchResult[]> {
    var url = config!.searchServer!.apiBaseUrl + "autoCompleteNgram?prefix=" + encodeURIComponent(prefix);
    try {

        let response;

        if (api)
            response = await axios.get(url, { headers: api!.getAuthorizationHeader() });
        else
            response = await axios.get(url);

        if (!response.data) {
            return [];
        }
        return Promise.resolve(response.data);
    } catch (error) {
        app.showErrorFromJsonResult(error);
        return Promise.reject(error);
    }

}

export async function elasticAutoCompleteSearchKAP(prefix: string): Promise<AutoCompleteSearchResult[]> {
    var url = config!.searchServer!.apiBaseUrl + "autoCompleteNgramKAP?prefix=" + encodeURIComponent(prefix);
    try {

        let response;

        if (api)
            response = await axios.get(url, { headers: api!.getAuthorizationHeader() });
        else
            response = await axios.get(url);

        if (!response.data) {
            return [];
        }
        return Promise.resolve(response.data);
    } catch (error) {
        app.showErrorFromJsonResult(error);
        return Promise.reject(error);
    }

}

export async function elasticKapSearch(searchParams: { keyword: string, exactmatch?: boolean }): Promise<KapSearchResult[]> {
    var url = config!.searchServer!.apiBaseUrl + "searchDraft?";
    url += queryString.stringify(searchParams);

    const response = await axios.get(url, { headers: api ? api.getAuthorizationHeader() : null });
    if (!response.data || (response.data.id && response.data.id == "-1")) {
        return [];
    }
    return Promise.resolve(response.data);
}

export interface IElasticIndexerTaskInfo {
    indexName : string;
    indexType: string;
    schemaName : string;
    tableName: string;
    idPrefix : string;
}

export interface IElasticIndexerTimingInfo {
    waitMSecOnError: number;
    waitMsecWhileDisabled: number;
    waitMsecOnEnabled: number;
    waitMsecOnIdle: number;
    waitMsecOnProcess: number;
}

export interface IElasticIndexerOtherInfo {
    enabled : boolean;
    instance: string;
    max_batch_size : number;
}

export interface IElasticIndexerInfo {
    baseConfigName: string;
    systemCheckName: string;
    systemCheckDescription : string;
    serviceUserId: number;
    queueTableName: string;
    deleteQueueTableName: string|null;
    deleteKeyField: string|null;
    class: string;
    tasks: IElasticIndexerTaskInfo[]|null;
    timings : IElasticIndexerTimingInfo;
    other : IElasticIndexerOtherInfo;
}


export async function elasticIndexers(params: any): Promise<IElasticIndexerInfo[]> {
    var url = config!.searchServer!.apiBaseUrl + "indexers";

    const response = await axios.post(url, params, { headers: api ? api.getAuthorizationHeader() : null });
    if (!response.data || (response.data.id && response.data.id == "-1")) {
        return [];
    }
    return Promise.resolve(response.data);
}


export async function elasticSearch(searchParams: SearchParameters): Promise<SearchResult[]> {
    var url = config!.searchServer!.apiBaseUrl + "search?";
    url += queryString.stringify(searchParams);

    const response = await axios.get(url, { headers: api ? api.getAuthorizationHeader() : null });
    if (!response.data || (response.data.id && response.data.id == "-1")) {
        return [];
    }
    return Promise.resolve(response.data);
}

export async function searchHistory(): Promise<SearchResult[]> {
    var url = config!.searchServer!.apiBaseUrl + "getSearches?";

    const response = await axios.get(url, { headers: api ? api.getAuthorizationHeader() : null });
    if (!response.data || (response.data.id && response.data.id == "-1")) {
        return [];
    }
    return Promise.resolve(response.data);
}

export async function onSearchHit(result: SearchResult): Promise<any> {
    var url = config!.searchServer!.apiBaseUrl + "logSearchHit?";

    url += "_id=" + encodeURIComponent(result._id);
    url += "&type=" + encodeURIComponent(result.type);

    await axios.get(url);
}

export async function elasticImageSearch(keywords: string, limit: string): Promise<ImageSearchResult[]> {
    var url = config!.searchServer!.apiBaseUrl + "search?";

    url += "keyword=" + encodeURIComponent(keywords) + "&maxhits=" + limit;
    const response = await axios.get(url);
    if (!response.data || (response.data.id && response.data.id == "-1")) {
        return [];
    }
    return Promise.resolve(response.data);
}
export async function elasticImageSearchForTagger(keywords: string, limit: string, subject_id: number | null): Promise<ImageSearchResult[]> {
    const params = {
        keyword: keywords,
        maxhits: limit,
        user: "tech",
        type: "image",
        ispublished: "false"
    };
    if (subject_id) params["subject_id"] = subject_id

    var url = config!.searchServer!.apiBaseUrl + "search?" + queryString.stringify(params);

    const response = await axios.get(url);
    if (!response.data || (response.data.id && response.data.id == "-1")) {
        return [];
    }
    return Promise.resolve(response.data);
}


export async function getSynonyms(word: string, dictionaryId?: number): Promise<SynonymResult[]> {
    let params = { operation: "get_synonyms_with_parents", word };
    if (dictionaryId) {
        params["dictionary_id"] = dictionaryId;
    }
    var url = config!.searchServer!.apiBaseUrl + "synonyms?" + objectToQueryParams(params);
    try {
        if (api)
            return (await axios.get<SynonymResult[]>(url, { headers: api!.getAuthorizationHeader() })).data;
        else
            return (await axios.get<SynonymResult[]>(url)).data;
    } catch (error) {
        app.showErrorFromJsonResult(error);
        return Promise.reject(error);
    }

}

export type htrSearchResult = {
    content_uri_segment: string | null,
    html_body: string,
    id: number,
    is_active: boolean,
    score: number,
    section_title: string | null,
    site_id: number,
    subsite_id: number,
    subsite_uri_segment: string | null,
    teaser: null,
    title: string | null,
    type: string,
    visible_from: string,
    _id: string,
    color: string,
    content_type_id: number
    oo_file_id: number |null,
    sha1: string,
    shortname: string,
    start_date: string | null,
    end_date: string | null
    subsite_title: string,
}

export async function htrSearch(searchParams: { keyword: string | undefined, content_type_id: number | undefined }): Promise<htrSearchResult[]> {
    var url = config!.searchServer!.apiBaseUrl + "searchCMS?site_id=2&";
    url += queryString.stringify(searchParams);

    const response = await axios.get(url, { headers: api ? api.getAuthorizationHeader() : null });
    if (!response.data || (response.data.id && response.data.id == "-1")) {
        return [];
    }
    return Promise.resolve(response.data);
}



/** ==================================================
 *                  Fájl feltöltés
 *  ==================================================
 */

export interface IUploadFilesRequest {
    oo_folder_id: number; // Ide töltse föl
    files: File[]; // Ezeket töltse föl
}

/**
 * File feltöltése OnlyOffice mappába.
 * 
 * Amikor megjön az eredmény, akkor az állományok feltöltődtek a szerverre,
 * de még nem lehet őket használni. Használni csak akkor lehet, ha feldolgozásra
 * kerültek. A feldolgozás státuszát a IUploadFilesResponse.queue_ids -ben levő
 * queue_id értékkel lehet ellenőrizni.
 * 
 */
export const uploadFilesToOoFolder = (uploadRequest: IUploadFilesRequest, onProgress?: (event: superagent.ProgressEvent) => void): Promise<IViewMediaOoFileRecord[]> => {
    const dest_folder_id = uploadRequest.oo_folder_id;
    return new Promise((resolve, reject) => {
        let req = superagent.post('/api/media/file');
        req
            .set('Authorization', "Bearer " + api!.accessToken.access_token)
            .field('oo_folder_id', dest_folder_id);

        for (let i: number = 0; i < uploadRequest.files.length; i++) {
            let f: File = uploadRequest.files[i];
            req.attach('file' + i, f);
        }
        if (onProgress) {
            req.on("progress", onProgress);
        }
        req.end((err, res) => {
            if (err && err.response) {
                reject(JSON.parse(err.response.text));
            } else {
                resolve(res.body as IViewMediaOoFileRecord[]);
            }
        });
    });
}

/** ==================================================
 *                   eseménykezelés
 *  ==================================================
 */
export type UserViewEvent = {
    eventDate?:string,
    siteId?:number,
    tableInfoId: number;
    recordId: number;
    name: string;
    data?: IViewEventData;
}

export interface IViewEventData {
    eventTarget:UserEventTargets,
    eventType:UserEventTypes,
    modulType?:ModulTypes,
    exerciseRecord?:any,
    sha1?:string,
    mediaTypeId?:number,
    newValue?:string,
    courseId?:number
    /** depreated */
    event?:string,
    /** depreated */
    lesson_event?:string,
}

export enum UserEventTargets{
    EXERCISE = "exercise",
    EXERCISE_SERIES = "exercise_series",
    SOUND = "sound",
    VIMEO = "vimeo",
    IMAGE = "image",
    LESSON = "lesson",
    STUDENT = "student",
    TEACHER = "teacher",
    MESSAGE = "message"
}

export enum ModulTypes{
    COURSE_ORGANIZER = "course_organizer",
    E_LEARNING = "e_learning",
    CONTENT_STORE = "content_store",
    SEARCH = "search",
    CLASS_ROOM = "class_room"
}

export enum UserEventTypes{
    EVALUATE = "evaluate",
    START = "start",
    PLAY = "play",
    ENDED = "ended",
    VIEWED = "view",
    REQUESTED = "get", 
    GET_CORRECT_SOLUTION="getcorrectsolution",
    STUDENT_DATA_CHECK = "student_data_checked",
    STUDENT_PROGRESS_CHECK = "student_progress_checked",
    FORUM_ENTRY="forum_entry",
    MILESTONE_CHECK="milestone_check",
}

export async function sendUserViewEvent(event: UserViewEvent) : Promise<string> {
    let new_event = {...event, siteId:config.mainServer.siteId!};
    return obtainServer().post<string>("event/userViewEvent", {
        operation: "add",
        ...new_event,
    });
}

export async function touchUserViewEvent(userEventId: string) {
    return obtainServer().post<string>("event/userViewEvent", {
        operation: "touch",
        eventId: userEventId
    });    
}

export interface ILessonViewSummaryRecord {
    _id: {  tableInfoId: number, recordId: number },
    viewCount: number,
    viewTime: number
}

export interface ILessonViewSummariesRecord {
    _id: {  userId: number, tableInfoId: number, recordId: number },
    viewCount: number,
    viewTime: number
}

export type ViewTimesByUserId = { [sec_user_id : string ] : string }
// https://kb.itk.uni-eszterhazy.hu/project/38/task/1951
export async function getUserEvents(userId: number, tableInfoId ?: number, limit ?: number, fromDate ?: Date, toDate ?: Date) : Promise<UserViewEvent[]> {
    const params = { operation: "get_events", userId, tableInfoId, limit };
    if (fromDate !== undefined) {
        params["from_date"] = fromDate.getTime();
    }
    if (toDate !== undefined) {
        params["to_date"] = toDate.getTime();
    }
    return obtainServer().post<UserViewEvent[]>("event/userViewEvent", params);    
}

export async function getLessonViewSummary(userId: number, bookId: number, limit ?: number, fromDate ?: Date, toDate ?: Date) : Promise<ILessonViewSummaryRecord[]> {
    const params = {
        operation: "lesson_view_summary",
        userId, bookId, limit        
    };
    if (fromDate !== undefined) {
        params["from_date"] = fromDate.getTime();
    }
    if (toDate !== undefined) {
        params["to_date"] = toDate.getTime();
    }
    return obtainServer().post<ILessonViewSummaryRecord[]>("event/userViewEvent", params);    
}

// A limit paraméter user-book párokra értendő!
export async function getLessonViewSummaries(userIds: number[], bookIds: number[], limit ?: number, fromDate ?: Date, toDate ?: Date) : Promise<ILessonViewSummariesRecord[]> {
    const params = {
        operation: "lesson_view_summaries",
        userIds, bookIds, limit        
    };
    if (fromDate !== undefined) {
        params["from_date"] = fromDate.getTime();
    }
    if (toDate !== undefined) {
        params["to_date"] = toDate.getTime();
    }
    return obtainServer().post<ILessonViewSummariesRecord[]>("event/userViewEvent", params);    
}

export async function getLessonViewSummariesFormatted(userIds: number[], bookIds: number[] ) : Promise<ViewTimesByUserId> {
    const result : ViewTimesByUserId = {};
    const summaries : ILessonViewSummariesRecord[] = await getLessonViewSummaries(userIds, bookIds);
    for (let index = 0; index < userIds.length; index++) {
        const summaryForUSer = summaries.filter((value) => value._id.userId===userIds[index] );
        let viewTime : string;
        if (!summaryForUSer || summaryForUSer.length<1) {
            viewTime = "00:00";
        } else {                
            const timeinms = summaryForUSer.reduce((a, b) => a + b.viewTime, 0);
            let minutes = Math.floor((timeinms / (1000 * 60)) % 60);
            let hours = Math.floor((timeinms / (1000 * 60 * 60)));
            viewTime = ((hours < 10) ? "0" + hours : hours) + ":" + ((minutes < 10) ? "0" + minutes : minutes);        
        }
        result[userIds[index]] = viewTime;
    }    
    return result;
}


/** ==================================================
 *                  Collabora
 *  ==================================================
 */

/*
export type CollaboraUrl = {
   urls: {[ext: string]: string}[];
   WOPISrc: string;
}

export async function getCollaboraUrl() {
   return await obtainServer().get<null | CollaboraUrl>("wopi/discovery");
}
*/


/** ==================================================
 *                       KAP
 *  ==================================================
 */

 
export async function setDraftComment(id: string, comment: any) {
    const eventString = queryString.stringify({id: id});
    await obtainServer().post("kap/comment/post?" + eventString, comment);
}


export async function getDraftComment(id: string) {
    const eventString = queryString.stringify({id: id});
    return await obtainServer().get("kap/comment/get?" + eventString);
}

export async function sendErrorReport(subject: string, SenderName: string,emailAddress:string,ProblemDescription:any) {
    const errorReportString = queryString.stringify({subject,SenderName,emailAddress});
    await obtainServer().post("kap/email/post?" + errorReportString,
        {"error_description": ProblemDescription}
    );
}


export interface ICourseMassDownloadParams {
    course_ids : number[],
    document_type_ids : number[]
}

export function courseMassDocumentDownloadUrl(params : ICourseMassDownloadParams) : string {
    return "/api/kap/course/mass_document_download?" + queryString.stringify({params: JSON.stringify(params)});
}


/** ==================================================
 *                       Image Tagger
 *  ==================================================
 */

export type KeywordsAiResult = {
    keywords_ai?: {original: string, score:number}[];
    sentences_ai?: {original: string, score:number}[];
    loading?: boolean;
}

export async function getKeywordsAi(sha1: string): Promise<KeywordsAiResult> {
    const eventString = queryString.stringify({sha1: sha1});
    return await obtainServer().get("image_tagger?" + eventString);
}
