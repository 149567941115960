import { utoa } from "./StringUtils";

export function objectToQueryParams(obj: object, prefix?: string): string {
  const str = [];
  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + '[' + p + ']' : p;
      const v = obj[p];
      if (v!== undefined) {
        str.push((v !== null && typeof v === 'object') ?
          objectToQueryParams(v, k) :
          encodeURIComponent(k) + '=' + (v===null?"":encodeURIComponent(v)));
      }
    }
  }
  return str.join('&');
}


export function objectToQueryJsonParams(obj: object): string {
  return utoa(JSON.stringify(obj));
}
