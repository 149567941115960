import * as React from 'react';
import { observer } from 'mobx-react';
import { ForumContainerStore, MemberListStore, forumStore } from '../forumStore';
import { __ } from '@src/translation';
import { computed } from 'mobx';


export interface IForumMembers {
    storeId: string;
}

/**
 * Ez a komponens az aktuálisan kiválasztott fórum tagjait jeleníti meg.
 * 
 * TODO: ha nagyon sok a tag, akkor talán nem kellene az összeset.
 * Lehet ebből egy olyan verziót is készíteni, ami csak az utolsó legaktívabb
 * tagokat mutatja, és egyébként név alapján lehet tagot keresni benne.
 */
@observer
export default class ForumMembers extends React.Component<IForumMembers, {}>{
    public get containerStore(): ForumContainerStore {
        return forumStore.getContainerStore(this.props.storeId);
    }

    public get forumId(): number | null {
        return this.containerStore.forumId;
    }


    public get memberStore(): MemberListStore | null {
        if (this.forumId === null) {
            return null;
        } else {
            return forumStore.getMemberListStore(this.forumId);
        }
    }

    /** Tudok-e tagot törölni? */
    @computed private get canRemove() : boolean {
        const memberStore  = this.memberStore;
        return memberStore?memberStore.canRemoveMember:false;
    }

    private removeMember = () => {
        // this.memberStore.removeMember();???
        alert('TODO');
    }

    constructor(props: IForumMembers) {
        super(props);
        this.state = {};
    }

    public componentDidMount() {
        const memberStore = this.memberStore;
        if (memberStore != null) {
            if (!memberStore.loaded) {
                memberStore.reload();
            }
        }
    }

    render() {
        const forumId = this.forumId;
        if (forumId == null) {
            // Ilyen nem nagyon lesz...
            return <div>Nincs kiválasztva fórum.</div>;
        }
        const memberStore = this.memberStore!;
        return <div className="forum-members">
            <div className="header">

                <h4>Fórum tag lista</h4>
                <div className="actions">
                    <button className=""
                        onClick={() => memberStore.reload()}
                        disabled={memberStore.loading}
                        title={memberStore.loading ? __("Betöltés...") : ("Tag lista frissítése")}
                    >
                        <i className={"fas fa-sync-alt post-refresh-btn" + (memberStore.loading?" sync":"")} /> 
                    </button>
                </div>
            </div>            
            <ul className="list">
                {memberStore.members.map((member, index) =>
                    <li key={member.id || index}>
                        <button className="">
                            {member.fullname}
                        </button>
                        {
                            this.canRemove
                            ?
                            <button className="button tiny alert"
                                onClick={this.removeMember}
                                disabled={!this.canRemove}
                            ><i className="fa fa-trash" />
                            </button>  
                            : null                      
                        }
                    </li>
                )}
            </ul>
        </div>
    }

}