import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISecUserRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Bejelentkezési név
     */
    login_name?: string;
    /**
     * Jelszó
     *
     * Ha nincs kitöltve, akkor nem tud bejelentkezni helyi jelszavas azonosítással.
     */
    password_hash?: string|null;
    /**
     * Elsődleges e-mail
     */
    email?: string;
    /**
     * Másodlagos e-mail
     */
    email2?: string|null;
    /**
     * Felettes
     *
     * -> sys.sec_user ON DELETE cascade
     *
     */
    reports_to_id?: number|null;
    /**
     * Személy
     *
     * NOT NULL -> sys.person ON DELETE cascade
     *
     *
     * Az a természetes személy, akihez a felhasználó tartozik
     */
    person_id?: number;
    /**
     * Bejelentkezési kísérletek száma
     */
    login_attempt?: number /*integer*/|null;
    /**
     * Mikortól kezdve tud bejelentkezni
     */
    valid_from?: string /*timestamp?*/|null;
    /**
     * Meddig tud bejelentkezni
     */
    valid_until?: string /*timestamp?*/|null;
    /**
     * Kötelező jelszót változtatnia
     *
     * A következő bejelentkezéskor kötelező megváltoztatni a jelszavát. Ha ez nem sikerül, akkor a bejelentkezése sem sikerül.
     */
    force_pwd_change?: boolean;
    /**
     * Bejelentkezési csoport
     *
     * -> sys.sec_group ON DELETE setnull
     *
     */
    default_group_id?: number|null;
    /**
     * Technikai
     */
    is_technical?: boolean;
    /**
     * Kitiltás indoklása
     */
    login_disabled_reason?: string|null;
    /**
     * Munkamenet lejárati ideje.
     */
    session_timeout_minutes?: number /*integer*/|null;
    /**
     * Utoljára látva
     */
    ping_time?: string /*timestamp?*/|null;
    /**
     * Saját mappa
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     *
     * A felhasználó saját személyes mappája
     */
    oo_folder_id?: number;
    /**
     * Azonosító NKP 1.0 rendszerben
     */
    nkp1_id?: number|null;
    /**
     * Jelszó igénylő kód
     */
    password_reset_code?: string|null;
    /**
     * Jelszó igénylő e-mail kiküldésének időpontja
     */
    password_reset_code_sent?: string /*timestamp?*/|null;
    /**
     * E-mail cím megerősítő kód
     */
    email_confirm_code?: string|null;
    /**
     * E-mail cím megerősítő kiküldés kérelem időpontja
     */
    email_confirm_code_requested?: string /*timestamp?*/|null;
    /**
     * E-mail cím megerősítő kód kiküldésének időpontja
     */
    email_confirm_code_sent?: string /*timestamp?*/|null;
    /**
     * Másodlagos e-mail cím megerősítő kód
     */
    email2_confirm_code?: string|null;
    /**
     * Másodlagos e-mail cím megerősítő kiküldés kérelem időpontja
     */
    email2_confirm_code_requested?: string /*timestamp?*/|null;
    /**
     * Másodlagos e-mail cím megerősítő kód kiküldésének időpontja
     */
    email2_confirm_code_sent?: string /*timestamp?*/|null;
    /**
     * E-mail cím megerősítve
     */
    email_confirmed?: string /*timestamp?*/|null;
    /**
     * Másodlagos e-mail cím megerősítve
     */
    email2_confirmed?: string /*timestamp?*/|null;
    /**
     * Felhasználó elérhetősége
     *
     * NOT NULL -> sys.user_availibility ON DELETE cascade
     *
     */
    user_availibility_id?: number;
    /**
     * Kvóta
     */
    soft_quota?: number|null;
    /**
     * Kvóta (hard limit)
     */
    hard_quota?: number|null;
    /**
     * Felhasznált hely
     */
    disk_space_used?: number;
    /**
     * TOTP kulcs
     */
    totp?: string|null;
    /**
     * TOTP megerősítve
     */
    totp_confirmed?: string /*timestamp?*/|null;
    /**
     * Szakmentor
     *
     * -> sys.sec_user ON DELETE setnull
     *
     */
    prof_mentor_id?: number|null;
    /**
     * Neptun kód
     */
    neptun_code?: string|null;
    /**
     * Elsődleges ellátási hely
     *
     * -> sys.institute_site ON DELETE noaction
     *
     *
     * A felhasználó elsődleges feladatellátási helye, tanárként.
     */
    primary_institute_site_id?: number|null;
}

/**
 *  sys.sec_user - Alkalmazás szintű felhasználó
 *
 *  Az akalmazás szintű felhasználók az azonosítás eszközei.
 */

export default class SecUserCrud extends Crud<ISecUserRecord> {
    public static TABLE_INFO_ID = 2575714109;
    public static getSchemaNameForClass() { return 'sys'; }
    public static getTableNameForClass() { return 'sec_user'; }

    public static load: (id: number, aServer ?: Server) => Promise<SecUserCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISecUserRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISecUserRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISecUserRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISecUserRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISecUserRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SecUserCrudClassProxy extends CrudClassProxy<ISecUserRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const secUserCrudClassProxy = new SecUserCrudClassProxy(SecUserCrud);
registerCrudClassProxy(secUserCrudClassProxy);
