import * as React from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { BubbleLoader } from 'react-css-loaders';


import ViewMediaOoFile, { IViewMediaOoFileRecord } from '@src/framework/view/media/ViewMediaOoFile';
import { app } from '@src/index';
import { hasGroup, me, Groups } from '@src/framework/server/Auth';
import LookupEdit from '@src/framework/forms/lookupedit';
import SecUserCrud from '@src/framework/crud/sys/SecUserCrud';
import OoFileCrud from '@src/framework/crud/media/OoFileCrud';
import VfsPermissionAPI from '@src/component/vfs/vfs_permisson_api';
import OoFilePermTable from '@src/component/filemanager/perm/OOFilePermTable';
import OOFileAuditPermTool from '@src/component/filemanager/perm/OOFileAuditPermTool';
import { __ } from '@src/translation';

export interface IFilePermsDialogProps {
    ooFileId: number;
    onClose: () => void;
}
interface IFilePermsDialogState {
    file?: IViewMediaOoFileRecord;
    loading: boolean;
    changed: boolean;
    owner_id_changed: boolean;

}

export default class FilePermsDialog extends React.Component<IFilePermsDialogProps, IFilePermsDialogState> {
    constructor(props: IFilePermsDialogProps) {
        super(props);
        this.state = { loading: true, changed: false, owner_id_changed: false };
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        ViewMediaOoFile.load(this.props.ooFileId)
            .then((value) => this.setState({ file: value.record, loading: false, changed: false }))
            .catch((error) => app.showErrorFromJsonResult(error));
    }

    private isAdmin = (): boolean => {
        return hasGroup(me!, Groups.Admin);
    }


    private onOwnerIdChange = (newOwnerId: any) => {
        if ((typeof newOwnerId === 'string'))
            if (newOwnerId.trim() === '')
                newOwnerId = '';
        if (newOwnerId === '')
            newOwnerId = null;
        let file = this.state.file!;
        file.owner_id = newOwnerId;
        this.setState({ file, changed: true, owner_id_changed: true });
    }

    private onInheritPermsToggle = () => {
        let file = this.state.file!;
        file.inherit_perms = file.inherit_perms ? false : true;
        this.setState({ file, changed: true });
    }

    private onTakeOwnership = async () => {
        try {
            const file = this.state.file!;
            // TODO: akarunk ide rekurzív/nem rekurzív opciót?
            this.setState({ loading: true });
            await VfsPermissionAPI.takeFileOwnership(file.id!);
            // Szépséghiba hogy az egyéb pending változásokat ez eltünteti...
            this.asyncReload();
            app.showInfo(__("Sikeres művelet"), __(`A(z) {file_title} állomány saját tulajdonba vétele sikeres volt.`, {file_title:file.title}));
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

        // this.setState({???, changed: true});
    }

    private onCloseDialog = async () => {
        this.props.onClose();
    }

    private onSave = async () => {
        const file = this.state.file!;
        let rec = {
            id: file.id!,
            inherit_perms: file.inherit_perms ? true : false,
        }
        if (this.state.owner_id_changed) {
            rec["owner_id"] = file.owner_id!
        }
        try {
            await new OoFileCrud(rec).put();
            app.showInfo(__("Sikeres művelet"),__(`A(z) {file_title} állomány jogosultságok beállítása sikeres volt.`, {file_title:file.title}));
            this.props.onClose();
        } catch (error) {
            app.showErrorFromJsonResult(error);
            this.setState({ loading: true }, this.asyncReload);   
        }

    }

    public render() {

        if (this.state.loading) {
            return <BubbleLoader />;
        }

        const file = this.state.file!;
        const oo_file_id = file.id!;
        let ownerSelector;
        if (this.isAdmin()) {
            ownerSelector = <LookupEdit
                key={"ownerId" + (file.owner_id || 'null')}
                name="ownerId"
                fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                clearable={false}
                value={file.owner_id!}
                onChange={(newValue) => this.onOwnerIdChange(newValue)}
            />
        } else {
            ownerSelector = file.owner;
        }

        return <Tabs>
            <TabList>
                <Tab>{__("Alapadatok")}</Tab>
                {file.inherit_perms?"":<Tab>{__("Kiosztott jogok")}</Tab>}
                {this.isAdmin() ?<Tab>Audit</Tab> : ""}
            </TabList>
            <TabPanel>
                <div className="row expanded">
                    <div className="small-12 column">
                        <label>{__("Elérési út:")}</label>
                        {file.relpath}
                    </div>
                    <div className="small-12 column">
                        <label>{__("Tulajdonos:")}</label>
                        <span>
                            {ownerSelector}
                        </span>
                    </div>

                    <div className="small-12 column" />
                    <div className="small-2 column">
                        <div className="switch">
                            <input className="switch-input" id="inherit_perms" type="checkbox"
                                checked={file.inherit_perms ? true : false} onChange={this.onInheritPermsToggle}
                            />
                            <label className="switch-paddle" htmlFor="inherit_perms">
                                <span className="show-for-sr">{__("Jogosultságok öröklése a tartalmazó mappától")}</span>
                            </label>
                        </div>
                    </div>
                    <div className="small-10 column">
                        {__("Jogosultságok öröklése a tartalmazó mappától")}
                    </div>

                    <div className="large-12 small-12 columns">
                        <a onClick={() => this.onTakeOwnership()} className="button warning"><i className="fa fa-times" /> &nbsp;{__("Saját tulajdonba vétel")}</a>
                    </div>


                    <div className="large-6 small-12 columns">
                        <a onClick={() => this.onCloseDialog()} className="button alert"><i className="fa fa-times" /> &nbsp;{__("Mégse")}</a>
                    </div>
                    <div className="large-6 small-12 columns text-right">
                        <a onClick={() => { if (this.state.changed) { this.onSave(); } }}
                            className={"button success " + (this.state.changed ? "" : "disabled")}>
                            <i className="fa fa-save" /> &nbsp;{__("Mentés")}</a>
                    </div>
                </div>
            </TabPanel>
            { file.inherit_perms?"":
            <TabPanel key="perms_table">
                <OoFilePermTable ooFileId={oo_file_id} onClose={this.onCloseDialog} />
            </TabPanel>
            }
            {this.isAdmin() ?
                <TabPanel key="perms_audit">
                    <OOFileAuditPermTool ooFileId={oo_file_id} onClose={this.onCloseDialog} />
                </TabPanel>
            :""}

        </Tabs>;
    }
}
