import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewWfTypeRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Kód
     *
     * A folyamat egyedi azonosítójának generálásához van használva.
     */
    code?: string;
    /**
     * Név
     */
    name?: string;
    /**
     * Leírás
     */
    description?: string;
    /**
     * Gráfos folyamat átmenet diagram adatai.
     */
    graph?: any|null;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
}

/**
 *  wf.view_wf_type - Folyamat típus
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewWfType extends View<IViewWfTypeRecord> {
    public static VIEW_INFO_ID = 2018040502;

    public static getSchemaNameForClass(): string { return 'wf'; }
    public static getViewNameForClass(): string { return 'view_wf_type'; }
    public static getTableNameForClass(): string|null { return 'wf_type'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewWfType>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewWfTypeRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewWfTypeClassProxy extends ViewClassProxy<IViewWfTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewWfTypeClassProxy = new ViewWfTypeClassProxy(ViewWfType);
registerViewClassProxy(viewWfTypeClassProxy);

