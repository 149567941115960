import * as React from 'react';
import { __ } from '@src/translation';

type BookAudioProps = {
    audios: { src: string }[];
}

type BookAudioState = {
    act?: HTMLAudioElement | null;
}

export class BookAudio extends React.Component<BookAudioProps, BookAudioState> {

    constructor(props: any) {
        super(props);

        this.state = {
            act: null,
        }
    }

    playOrStopAudio(index: number) {

        document.querySelectorAll('.audio-img.play').forEach((audio) => {
            audio.classList.remove('play')
        });
        var audio = this.refs["bookAudio" + index] as HTMLAudioElement;

        this.setState({act: audio}, this.stopAllAudio);
        if (audio.paused) {
            audio.play();
            (this.refs["audioButton" + index] as HTMLAudioElement).classList.add('play');
        } else {
            audio.pause();
            audio.currentTime = 0;
        }
    }

    stopAllAudio() {
        let allaudio = document.querySelectorAll('.book-audio-player');        
        allaudio.forEach((audio) => {
            if((audio as HTMLAudioElement) != this.state.act) {
                (audio as HTMLAudioElement).pause();
                (audio as HTMLAudioElement).currentTime = 0;
            } else return;
        })
    }

    audioEnded(index: number) {
        var audioButton = this.refs["audioButton" + index] as HTMLAudioElement;
        audioButton.classList.remove('play');
        
    }

    render() {
        return <div>
            {                
                this.props.audios.map((audio, i) => {
                    return <div key={i} className="book-audio">
                        <button ref={"audioButton" + i}  className={"audio-img"} title={__("Hanganyag lejátszása")} onClick={this.playOrStopAudio.bind(this, i)}>
                            <label className="show-for-sr">{__("Hanganyag lejátszása")}</label>
                        </button>
                        <audio className="book-audio-player" ref={"bookAudio" + i} src={audio.src} onEnded={this.audioEnded.bind(this, i)}/>
                    </div>;
                })
            }
        </div>
    }
}
