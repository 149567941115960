import * as React from 'react';
import { Dialog } from '@src/component/Dialog';
import { Popover } from '@src/component/ui/Popover';
import CrudSelectComponent from '@src/framework/forms/crudselect';
import { subjectCrudClassProxy } from '@src/framework/crud/doc/SubjectCrud';
import LookupEdit from '@src/framework/forms/lookupedit';
import InstituteCrud from '@src/framework/crud/sys/InstituteCrud';
import { __ } from '@src/translation';

type DialogsExampleState = {
    dialogOpen: boolean;
    popoverOpen: boolean;
}

export class DialogsExample extends React.Component<any, DialogsExampleState> {

    constructor(props: any) {
        super(props);

        this.state = {
            dialogOpen: false,
            popoverOpen: false,
        }
    }

    render() {
        return <div>
                <button className="button" onClick={() => this.setState({dialogOpen: true})}>
                    {__("Dialógusablak kinyitása")}
                </button>

                <Dialog open={this.state.dialogOpen} title={__("Dialógusablak teszt")} onClose={() => this.setState({dialogOpen: false})}>
                    <MyFormExample />
                </Dialog>

                <button ref="contextParent" className="button" onClick={() => this.setState({popoverOpen: true})}>
                    {__("Kontextmenü kinyitása")}
                </button>
                <Popover 
                        open={this.state.popoverOpen} 
                        parentElement={() => this.refs.contextParent as HTMLElement} 
                        onClose={() => this.setState({popoverOpen: false})}
                        parentAlignHorizontal="center"
                        parentAlignVertical="bottom"
                        >
                    <div style={{border: "1px solid black"}}>
                        {__("Menü elemek...")}
                    </div>
                </Popover>
        </div>;
    }
}

type MyFormExampleState = {
    text: string;
    date: string;
    subjectId?: number;
    instituteId?: number;
}

class MyFormExample extends React.Component<any, MyFormExampleState> {
    constructor(props: any) {
        super(props);

        this.state = {
            text: "",
            date: "",
        }
    }
    
    render() {
        return <div>
                <label>
                    {__("Kérem válasszon intézményt (kezdjen el gépelni):")}
                    <LookupEdit
                        key={"" + this.state.instituteId}
                        fk_table_info_id={InstituteCrud.TABLE_INFO_ID}
                        clearable={true}
                        value={this.state.instituteId}
                        onChange={id => this.setState({instituteId: (id as any)})}
                        
                    />
                </label>
                <label>
                    {__("Kérem írja ide a szöveget:")}
                    <input type="text" value={this.state.text} onChange={e => this.setState({text: e.target.value})} />
                </label>
                <label>
                    {__("Kérem válasszon dátumot:")}
                    <input type="date" value={this.state.date} onChange={e => this.setState({date: e.target.value})} />
                </label>
                <label>
                    {__("Kérem válasszon tantárgyat:")}
                    <CrudSelectComponent 
                        crudClassProxy={subjectCrudClassProxy}
                        displayFieldName="name"
                        orderByFieldName="name"
                        value={this.state.subjectId || null}
                        filter={{is_active: true}}
                        onSelect={(_, id) => this.setState({subjectId: id || undefined})}
                    />
                </label>

            </div>
    }
}
