import {ReactElement} from "react";
import {EventInput} from "@fullcalendar/core";
import {EventApi} from '@fullcalendar/core';
import {DateInput} from '@fullcalendar/core/datelib/env';
import View from "@fullcalendar/core/View";

export type EventTypeStyleType = { //Egy adott típusú esemény stílusát határozza meg. Mivel minden egyes stílushoz hozzá lehet rendelni, 
        //hogy milyen tulajdonság alapján történik a stílus hozzárendelése, így több tulajdonság alapján is lehet az események stílusát állítani.
    typeName?: string; //Az esemény típusát tartalmazó tulajdonság neve (extendedProps tulajdonságoon belül), amennyiben nincs megadva, akkor ez az érték a "type"
    typeValue: string; //Az esemény típusának az értéke, amely esetben a beállításoknak érvényesülni kell
    backgroundColor: string; //Az esemény háttérszíne
    textColor: string;  //Az esemény kiíirt szövegének a színe
    textBold?: boolean; //Amennyiben az értéke true, akkor a kiírás vastagított betűvel történik
}
export type EventStyleType = { //Az események megjelenítlését szabályozó típus
    eventStyle?: { //Minden eseményehez rendelt stílus
        fontSize?: number; //Az esemény kiíratásának betűmérete
        eventHeight?: number; //Az esemény kiíratásának magassága
    };
    eventTypeStyle?: EventTypeStyleType[]; //Adott típusú eseményhez rendelt stílus, annyi eleme van, ahány különböző típusú eseményhez szeretnék stílust rendelni
}

export type EventIconType = {
    statusValue: number; //A tulajdonság ezen megadott értékénél kell megjeleníteni az ikont
    iconName: string; //Az ikon neve, amelyet megjelenít az alap komponens. A fontawesome ikonja vannak használva. Csak az ikon konkrét nevét kell megadni,
        //a bevezető tagokat "fa fa-" nem.
}

export type MenuStyleType = { //A menü megjelenítését szabályozó típus. Megoldandó a :hover stílusának paraméterben való átadása
    backgroundColor?: string; //A menü hátterének színe
    textColor?: string; //A menüpont szövegének színe
    textSize?: number; //A menüpont szövegének betűmérete
}

export type LoadEventFunctionType = (start: string, end: string) => Promise<EventInput[]>;
export type EventRendelType  = (arg:{isMirror:boolean; isStart:boolean; isEnd:boolean; event:EventApi; el:HTMLElement; view:View}) => JSX.Element;
export type TooltipGeneralType = (arg: {el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View}) => ReactElement;
export type DayClickMenuVisibledType = (date:Date, id:string, index:number) => boolean;
export type eventConflictVerifyType = (event: EventApi, revert:() => void) => void;
export type eventDropResizeWrityType = (id: string, start: Date | null, end: Date | null) => boolean;

export type DayClickCallback = (arg:{date:Date; dateStr:string; allDay:boolean; resource:any; dayEl:HTMLElement; jsEvent:MouseEvent; view:View;}) => MenuParametersType[] | undefined;
export type EventClickCallback = (arg: {el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View}) => MenuParametersType[] | undefined;

export type CallbackFunctionType = { //Azok callback függvények, amelyekkel felül lehet írni az alap komponens függvényeit 
    loadEvent?: LoadEventFunctionType; //Saját betöltő eljárás, egy eseményeket tartalmazó tömböt kell visszadnia 
    eventRender?: EventRendelType; //Amennyiben az alap komponensbe beépített esemény megjelenítő formázása nem megfelelő,
        //akkor lehetőségünk van egy külső függvényt megadni, amely visszaadja az esemény megjelenítését és az alap komponens
        //azt fogja renderelni a naptárba.
    tooltipGeneral?: TooltipGeneralType; //Amennyiben az alap komponensben levő tooltip összeállítását végző függvényt nem lehet úgy paraméterezni,
        //hogy jó legyen, ezért lehetőség van egy külső függvény megadására, amely összerakja a tooltip-et és visszaadja az alap komponensnek.
    dayClickMenuVisibled?: DayClickMenuVisibledType; //Mivel az eventDay click eseményen való kattintáskor, az ahhoz tartozó
        //menű összeállításához nem állnak rendelkezésre egyéb adatok, mint pl. az eventClick eseménynél, ezért megadható
        //ez a függvény típus, amely minden egyes menüpont hozzádása előtt meghívódik, és eldönthető, hogy a menüpont szerepelje e vagy sem.
    eventConflictVerify?: eventConflictVerifyType; //Amennyiben az alap komponens beépített ütközés ellenőrzőjét nem lehet a feladatnak
        //megfelelően paraméterezni, akkor megadható ez a függvény, amelyben le lehet programozni az ütközés ellenőrzését.
    eventDropResizeWrity?: eventDropResizeWrityType; //Amennyiben meg van adva, akkor az esemény mozgatását vagy átméretezését
        //követően ez a függvény hívódik meg, hogy az adatbázisban is rögzítse a változásokat. 
        //Erősebb hatású mint a "EventWriteURL" paraméter

    onDayClick?: DayClickCallback;

    onEventClick?: EventClickCallback;
}

export type nameType = { //Egy egyszerű típus, amely segítségével egy egyedi azonosítóhoz egy szöveges elnevezést tudunk rendelni
    id: string; //Valaminek az egyedi azonosítója
    name: string; //Az egyedi azonosítóhoz tartozó szöveges elnevezés
}

export type TooltipParametersType = { //Az alap komponens által megjelenített tooltip összetételét befolyásoló paraméter típusa. 
        //Amennyiben callback függvényként adunk meg tooltipGeneral függvényt, akkor nincs jelentősége
    typeName: nameType[]; //Ez a tömb tartalmazza a típusok elnevezését, csak akkor van értelme, 
        //ha az események kiterjesztett tulajdonsága (extendedProps) rendelkezik type tulajdonsággal. 
        //A tömb megadása esetén az alapkomponens által összerakott tooltip tartalmazni fogja a típus nevét.
    statusName: nameType[]; //Ez a tömb tartalmazza a státuszok elnevezését, csak akkor van értelme, 
        //ha az események kiterjesztett tulajdonsága (extendedProps) rendelkezik status tulajdonsággal. 
        //A tömb megadása esetén az alapkomponens által összerakott tooltip tartalmazni fogja a státusz nevét.
    enabled?: boolean; //Ha false értéket adunk meg, akkor nem jelenik meg a tooltip, amennyiben nem adjuk meg 
        //ezt a tulajdonságok, akkor az olyan mintha true értéket adtunk volna meg.
    width?: number; //Ezzel a tuljadonsággal tudjuk a tooltip ablak szélességét befolyásolni, alap esetben 400px
}

export type MenuParametersType = { //a megjelenítendő menüpontokat tartalmazó tömb alapját képező típus (amennyiben eseményen történt a kattintás és a propertyName vagy a propertyValue nincs megadva, akkor a menüpont megjelenik és aktívan, mivel az enabled tömb sem értelmezhető)
    id: string; //menüpont egyedi azonosítója
    name: string; //a menüpont szövege
    icon?: string; //az ikon, amelyet a menüpont előtt meg kell jeleníteni
    onClick: () => void;
    propertyName?: string; //az esemény kiterjesztett tulajdonságai közt szereplő (extendedProps) tulajdonság neve, amely értékéhez viszonyítani kell az adott menüpont megjelenítését
    propertyValue?: string[]; //egy a megfelelő értékeket tartalmazó tömb, amelyek között szerepel az esemény megfelelő tulajdonságának az értéke, akkor a menüpont megjelenításre kerül 
    dayClick?: boolean; //Amennyiben az értéke igaz, akkor nem az esemény értéke alapján dől el, hogy megjelenítésre kerül e, hanem, hogy napon és nem eseményen történt a kattintás. Ilyenkor még meghívódik egy függvény ha megadásra kerül, amely még letilthatja a menüpontot
    enabled?: boolean[]; //Az eseményen kattintáskor, pl. a propertyValue tömb 3. eleme alapján kerül megjelenítésre a menüpont, akkor ennek a tömbnak a 3. eleme dönti el, hogy engedélyezett vagy tiltott lesz a menüpont.
}

export enum EventConflictVerifyEnum {
    personDay, //egy személy egy napon csak egy eseményen szerepelhet
    personTimePeriod, //egy személy egy adott pillanatban csak egy eseményen szerepelhet
    personPlaceDay, //egy személy, egy napon csak egy helyszinen szereplő eseményen vehet részt, egy helyszinen egy napon több eseményen is szerepelhet
    placeDay, //egy helyszínen egy nap csak egy esemény lehet
    placeTimePeriod, //egy helyszinen egy adott pillanatban csak egy esemény lehet
    noMoving, //az esemény nem móódosítható
    noResizing, //az esemény nem méretezhető
}

export enum EventConflictOperationTypeEnum {
    conflictAlert, //Amennyiben valamelyik tevékenységhez ez a tipus van rendelve, akkor a tevékenység elfogadott, csak információ jelenik meg
    conflictConfirm, //Ez a tipus esetén a tevékenység elfogadása jóváhagyáshoz kötött 
    conflictError //Ez a tipus esetén megjelenik az üzenet, de a tevékenység elutasításra kerül
}

export type EventConflictVerifyElemType = { //Esemény ütközésének elenőrzésére szolgáló típus
    name: EventConflictVerifyEnum; //Az ütközés ellenörzésének módja
    type: EventConflictOperationTypeEnum; //Ütközés esetén mi a teendő
    propertyName?: string; //Amennyiben olyan az ellenőrzés, hogy valamilyen érték alapján kell tíltani a mozgatást/méretezést, 
        //akkor annak a tulajdonságnak a neve, amely az értéket tartalmazza.
    propertyValue?: string; //Amennyiben olyan az ellenőrzés, hogy valamilyen érték alapján kell tíltani a mozgatást/méretezést, 
        //akkor azt az értéket tartalmazza.
    message: string; //A kiíratandó üzenet
}

export type ConflictVerifyFunctionType = (event1: EventApi, event2: EventApi) => boolean;

export type EventConflictVerifyType = {
    Elem: EventConflictVerifyElemType[]; //Több ütközési ellenőrzést is megtudunk határozni, ezeknek az elenőrzéseknek a típusát tartalmazó tömb
    personIdName?: string; //A személy egyedi azonosítóját tartalmazó tulajdonság neve (extendedProps tulajdonságoon belül), ha nnincs megadva akkor a personID tulajdonságot keresi
    personIdEmptyValue?: string; //Előfordul, hogy a személy egyedi azonosítója tartalmaz bizonyos értéket, de az pont azt jelenti, hogy nincs megadva. pl. "-1", itt lehet megadni, hogy mi ez az üres érték, hogy az ütközés ellenörzéskor ne legyen figyelembe véve
    placeIdName?: string; //A helyszin egyedi azonosítóját tartalmazó tulajdonság neve (extendedProps tulajdonságoon belül), ha nnincs megadva akkor a placeID tulajdonságot keresi
    placeIdEmptyValue?: string; //Előfordul, hogy a helyszín egyedi azonosítója tartalmaz bizonyos értéket, de az pont azt jelenti, hogy nincs megadva. pl. "-1", itt lehet megadni, hogy mi ez az üres érték, hogy az ütközés ellenörzéskor ne legyen figyelembe véve
    conflictVerifyFunction?: ConflictVerifyFunctionType; //Amennyiben nem lehet pontosan az ellenőrzést leparaméterezni az alap komponens számára, itt megadhatunk egy függvényt, amely minden egyes összehasonlításkor meghívódik a két összehasonlítandó eseménnyel.
}

export type EventCalendarPropsType = {
    eventLimit?: boolean;
    defaultDate?: DateInput;
    eventLimitText?: string;
}

export type CoordinateType = {X: number;  Y: number;}
export enum DTSMode {full, fullNoSeconds, dateFull, monthDay,
    timeFull, hourMinute, dayName, dayNameMonthDay}

export const GetScreenCordinates = (Element: any) => {
    let Position: CoordinateType = {X:Element.offsetLeft-Element.scrollLeft, Y:Element.offsetTop-Element.scrollTop};
    while (Element.offsetParent) {
        Position.X+=(Element.offsetParent.offsetLeft-Element.offsetParent.scrollLeft);
        Position.Y+=(Element.offsetParent.offsetTop-Element.offsetParent.scrollTop);
        if (Element.offsetParent===document.getElementsByTagName("body")[0]) break
        else Element = Element.offsetParent;
    }
    Position.X-=window.scrollX; Position.Y-=window.scrollY;
    return Position;
}

export const DateToString = (date: Date|null, mode:DTSMode, separator:string) => {
    if (date===null){return "";} 
    else {
        const year=("0"+date.getFullYear()).slice(-4); 
        const month=("0"+(date.getMonth()+1)).slice(-2); 
        const day=("0" + date.getDate()).slice(-2);
        const hour=("0"+(date.getHours())).slice(-2);
        const minute=("0"+(date.getMinutes())).slice(-2);
        const seconds=("0"+(date.getSeconds)).slice(-2);      
        const dayName = ['Vasárnap','Hétfő','Kedd','Szerda','Csütörtök','Péntek','Szombat'];  
        if (mode===DTSMode.full) return year+separator+month+separator+day+" "+hour+":"+minute+":"+seconds;
        else if (mode===DTSMode.fullNoSeconds) return year+separator+month+separator+day+" "+hour+":"+minute;
        else if (mode===DTSMode.dateFull) return year+separator+month+separator+day;
        else if (mode===DTSMode.monthDay) return month+separator+day;
        else if (mode===DTSMode.timeFull) return hour+":"+minute+":"+seconds;
        else if (mode===DTSMode.hourMinute) return hour+":"+minute;
        else if (mode===DTSMode.dayName) return dayName[date.getDay()];
        else if (mode===DTSMode.dayNameMonthDay) return dayName[date.getDay()]+ " ("+month+separator+day+")"
        else return "";    
    }
}
export const DateInputToString = (date: DateInput, mode:DTSMode, separator:string) => {
    const datum = new Date(date.toString());
    return DateToString(datum, mode, separator);
}
    
    