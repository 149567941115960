import * as React from 'react';

import { IViewInquiryCategoryUserRecord, ViewInquiryCategoryUserClassProxy, viewInquiryCategoryUserClassProxy } from '@src/framework/view/ticket/ViewInquiryCategoryUser';
import ListView from '@framework/forms/listview';
import { Groups, me } from '@src/framework/server/Auth';

import { app } from '@src/index';

import LookupEdit from '@framework/forms/lookupedit';
import SecUserCrud from '@src/framework/crud/sys/SecUserCrud';
import InquiryCategoryCrud from '@src/framework/crud/ticket/InquiryCategoryCrud';
import InquiryCategoryUserCrud from '@src/framework/crud/ticket/InquiryCategoryUserCrud';
import { __ } from '@src/translation';


class InquiryCategoryUserInner extends ListView<IViewInquiryCategoryUserRecord> {
    public getViewClassProxy(): ViewInquiryCategoryUserClassProxy { return viewInquiryCategoryUserClassProxy; }
    protected getFilterControls(): JSX.Element | null { return null; }
    protected getRecordButtons(record: IViewInquiryCategoryUserRecord): JSX.Element[] {
        let result: JSX.Element[] = [];
        // Saját maga csoportjait senki nem szerkesztheti, ezzel ki tudná
        // zárni magát a rendszerből.
        if (record.sec_user_id != me!.id) {
            result.push(this.getDeleteUndeleteButton(record)!);
        }
        return result;
    }
}

export interface IInquiryCategoryUserListViewForUserProps {
    sec_user_id: number;
}

export interface IInquiryCategoryUserListViewForUserState {
    groupIdToAdd: number | null;
    refreshKey: number
}

export class InquiryCategoryUserListViewForUser extends React.Component<IInquiryCategoryUserListViewForUserProps, IInquiryCategoryUserListViewForUserState> {

    constructor(props: IInquiryCategoryUserListViewForUserProps) {
        super(props);
        this.state = { groupIdToAdd: null, refreshKey: 0 };
    }

    private onGroupIdChange = (newValue: any) => {
        if ((typeof newValue === 'string'))
            if (newValue.trim() === '')
                newValue = '';
        if (newValue === '')
            newValue = null;
        let newState = {
            groupIdToAdd: newValue
        };
        this.setState(newState);
    }

    private onAddGroup = () => {
        new InquiryCategoryUserCrud({
            sec_user_id: this.props.sec_user_id,
            inquiry_category_id: this.state.groupIdToAdd!
        }).put()
            .then((crud) => {
                this.setState({
                    groupIdToAdd: null, refreshKey: this.state.refreshKey + 1
                }, this.forceUpdate)
            })
            .catch((error) => { app.showErrorFromJsonResult(error) })
    }


    public render() {
        const value: any = this.state.groupIdToAdd;
        return (
            <div>
                <div className="row">
                    <div className="column small-8 medium-8">
                        <LookupEdit
                            key={"groupIdToAdd_" + (this.state.groupIdToAdd || 'null')}
                            name="groupIdToAdd"
                            fk_table_info_id={InquiryCategoryCrud.TABLE_INFO_ID}
                            clearable={true}
                            value={value}
                            onChange={(newValue) => this.onGroupIdChange(newValue)}
                        />
                    </div>
                    <div className="column small-4 medium-4">
                        <button type="button" className="button primary"
                            disabled={!this.state.groupIdToAdd}
                            onClick={this.onAddGroup}
                        >
                            <i className="fa fa-plus" />
                            {__("Hozzáad")}
                    </button>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 medium-12">
                        <InquiryCategoryUserInner
                            key={this.state.refreshKey}
                            allowInsertNew={false}
                            defaultPageSize={10}
                            filter={{ sec_user_id: this.props.sec_user_id }}
                            viewColumns={[
                                { accessor: "id", maxWidth: 100 },
                                { accessor: "inquiry_category_id", maxWidth: 100 },
                                { accessor: "group_title" },
                                { accessor: "group_type_title" },
                                /*
                                {accessor: "creation_time"},
                                {accessor: "creator"},
                                {accessor: "modification_time"},
                                {accessor: "modifier"},
                                */
                                { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
                            ]} />
                    </div>
                </div>
            </div>);
    }

}



export interface IInquiryCategoryUserListViewForInquiryCategoryProps {
    inquiry_category_id: number;
}

export interface IInquiryCategoryUserListViewForInquiryCategoryState {
    leaderIdToAdd: number | null;
    refreshKey: number
}

export class InquiryCategoryUserListViewForInquiryCategory extends React.Component<IInquiryCategoryUserListViewForInquiryCategoryProps, IInquiryCategoryUserListViewForInquiryCategoryState> {

    constructor(props: IInquiryCategoryUserListViewForInquiryCategoryProps) {
        super(props);
        this.state = { leaderIdToAdd: null, refreshKey: 0 };
    }

    private onUserIdChange = (newValue: any) => {
        if ((typeof newValue === 'string'))
            if (newValue.trim() === '')
                newValue = '';
        if (newValue === '')
            newValue = null;
        let newState = {
            leaderIdToAdd: newValue
        };
        this.setState(newState);
    }

    private onAddUser = () => {
        new InquiryCategoryUserCrud({
            inquiry_category_id: this.props.inquiry_category_id,
            sec_user_id: this.state.leaderIdToAdd!
        }).put()
            .then((crud) => {
                this.setState({
                    leaderIdToAdd: null, refreshKey: this.state.refreshKey + 1
                }, this.forceUpdate)
            })
            .catch((error) => { app.showErrorFromJsonResult(error) })
    }


    public render() {
        const value: any = this.state.leaderIdToAdd;
        return (
            <div>
                <div className="row">
                    <div className="column small-8 medium-8">
                        <LookupEdit
                            key={"leaderIdToAdd" + (this.state.leaderIdToAdd || 'null')}
                            name="leaderIdToAdd"
                            fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                            clearable={true}
                            value={value}
                            onChange={(newValue) => this.onUserIdChange(newValue)}
                        />
                    </div>
                    <div className="column small-4 medium-4">
                        <button type="button" className="button primary"
                            disabled={!this.state.leaderIdToAdd}
                            onClick={this.onAddUser}
                        >
                            <i className="fa fa-plus" />
                            {__("Hozzáad")}
                    </button>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 medium-12">
                        <InquiryCategoryUserInner
                            key={this.state.refreshKey}
                            allowInsertNew={false}
                            defaultPageSize={10}
                            filter={{ inquiry_category_id: this.props.inquiry_category_id }}
                            viewColumns={[
                                { accessor: "id", maxWidth: 100 },
                                { accessor: "sec_user_id", maxWidth: 100 },
                                { accessor: "login_name" },
                                { accessor: "fullname" },
                                /*
                                {accessor: "creation_time"},
                                {accessor: "creator"},
                                {accessor: "modification_time"},
                                {accessor: "modifier"},
                                */
                                { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
                            ]} />
                    </div>
                </div>
            </div>);
    }

}
