import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';

import { FormInput } from './forminput';

import { IRecord, CrudClassProxy } from '@src/framework/crud/Crud';
import { app } from '@src/index';
import { ITableInfo, IFieldInfo, ITableInfoTranslation } from '@src/framework/crud/Meta';

import { history } from '@src/index';
import { atou } from '@src/framework/util/StringUtils';

import InplaceListEditor from '@framework/forms/inplace_listeditor';


const LANG_ID_HU = 3432150755;

type TDefaultValues = { [ fieldName : string ] : any  };

interface RecordEditorProps {
    lang_id?: number;
    /**
     * A match-et akkor add meg, ha ez nem egy beágyazott editor, hanem egy külön lap,
     * ami a paramétereket az URL-ből veszi.
     */
    match: any;
    /** Ezeket alább csak akkor add meg, ha ez egy beágyazott editor ami egy lapon
     * belül jelenik meg, és nem változtat URL-t.
     */
    record_id ?: number|null;
    inplaceListEditor?: InplaceListEditor;
    defaultValues ?: TDefaultValues;
    spec?: { [name:string] : any };
}

interface RecordEditorState<T extends IRecord> {
    rec_id?: number | null;
    rec?: T;
    missingValues?: string[];
    struct?: ITableInfo;
    defaultValues?: T;
    spec ?: any;
    others?: any; // This is a hack! See https://stackoverflow.com/questions/32866534/extending-react-components-in-typescript    
}

export interface IFieldEditorProp {
    fieldName: string;
    className?: string;
    disabled?: boolean;
    forceCrudSelect ?: boolean;
    displayFieldName ?: string; // Csak akkor, ha  forceCrudSelect igaz
}

export default abstract class RecordEditor<TRecord extends IRecord>
    extends React.Component<Partial<RecordEditorProps>, RecordEditorState<TRecord>> {

    public abstract getCrudClassProxy(): CrudClassProxy<TRecord>;

    private _rawDefaultValues?: TRecord;

    public getEditUrl(record: TRecord): string {
        return this.getCrudClassProxy().getEditUrl(
            record.id || null, this.state.defaultValues);
    }

    public getListUrl(): string {        
        return this.getCrudClassProxy().getListUrl(
            this.state.defaultValues,
            this.state.spec
        );
    }

    protected struct: ITableInfo;
    protected fieldInfoByName: Map<String, IFieldInfo>;

    protected getLangId(): number {
        return this.props.lang_id || LANG_ID_HU;
    }

    protected getFieldInfo(fieldName: string): IFieldInfo {
        return this.fieldInfoByName.get(fieldName)!;
    }

    protected getMatchParam(name: string): any {
        const match: any = this.props.match;
        return (match && match.params) ? match.params[name] : null;
    }

    constructor(props: any) {
        super(props);
        this.state = { rec_id: undefined, rec: undefined, defaultValues: undefined };
        this.initAsync();

    }

    private async queryStruct(): Promise<ITableInfo> {
        let struct = await this.getCrudClassProxy().getTableInfoForClass();
        this.fieldInfoByName = new Map<String, IFieldInfo>();
        let fields = struct.fields;
        for (let i = 0; i < fields.length; i++)
            this.fieldInfoByName.set(
                fields[i].field_name,
                fields[i]
            );
        return struct;
    }


    private initAsync = async () => {
        let struct = await this.queryStruct();;
        let defaultValues = this.extractDefaultValues(struct);
        let spec = this.extractSpecial();

        /* Setup initial state */
        const rec_id = this.props.record_id || this.getMatchParam("recId") || null;
        let state: RecordEditorState<TRecord>;
        if (!rec_id || rec_id == "null" || rec_id == "new") {
            this._rawDefaultValues = defaultValues as TRecord;
            let rec = this.getNewRecordDefaults();
            state = { rec_id: null, rec, defaultValues, spec, struct } as RecordEditorState<TRecord>;
        } else {
            state = { rec_id: parseInt(rec_id), rec: undefined, defaultValues, spec, struct } as RecordEditorState<TRecord>;
        }
        this.setState(state, this.asyncReload);
    }

    /*
    private extractDefaultValues = (struct: ITableInfo) => {

        let defaultParams : any;
        if (this.props.defaultValues) {
            defaultParams = this.props.defaultValues;
        } else {
            let rawDefaults = this.getMatchParam('defaultValues');
            defaultParams = rawDefaults?JSON.parse(atou(rawDefaults)):{};    
        }
        let defaultValues = {};
        if (defaultParams) {
            struct.fields.forEach((fieldInfo, index) => {
                const defaultValue = defaultParams[fieldInfo.field_name];
                if (defaultValue) {
                    defaultValues[fieldInfo.field_name] = defaultValue;
                }
            });
        }
        return defaultValues;     
    }
    */

    private extractParams = () => {
        let params = this.getMatchParam('params');
        return params ? JSON.parse(atou(params)) : {};
    }

    /** Extract filters from the route and apply them */
    private extractDefaultValues = (struct: ITableInfo) : TRecord => {
        let defaultParams: any;
        if (this.props.defaultValues) {
            defaultParams = this.props.defaultValues;
        } else {
            let params = this.extractParams();
            if (params && params['defaultValues']) {
                defaultParams = params['defaultValues'];
            }
        }
        let defaultValues = {};
        if (defaultParams) {
            struct.fields.forEach((fieldInfo, index) => {
                const defaultValue = defaultParams[fieldInfo.field_name];
                if (defaultValue) {
                    defaultValues[fieldInfo.field_name] = defaultValue;
                }
            });
        }
        return defaultValues as TRecord; 
    }

    /** Extract filters from the route and apply them */
    private extractSpecial = () => {
        let specParams: any;
        if (this.props.spec) {
            return this.props.spec;
        } else {
            let params = this.extractParams();
            if (params && params['spec']) {
                return params['spec'];
            } else {
                return {};
            }
        }
    }    

    protected getFieldEditor(fProps: IFieldEditorProp): JSX.Element | null {
        const fieldInfo = this.getFieldInfo(fProps.fieldName);
        let record = this.state.rec!;
        let value = record[fProps.fieldName];
        if (value === undefined) value = null;
        return <FormInput
            key={fieldInfo.field_name}
            value={value}
            fieldInfo={fieldInfo}
            isNewRecord={!(this.state.rec_id)}
            className={fProps.className}
            onFieldChange={this.onFieldChange}
            disabled={fProps.disabled}
            forceCrudSelect={fProps.forceCrudSelect}
            displayFieldName={fProps.displayFieldName}
        />
    }

    protected getFieldEditors(fieldEditorProps: IFieldEditorProp[]): JSX.Element[] {
        let result: JSX.Element[] = [];
        for (let i = 0; i < fieldEditorProps.length; i++) {
            let fieldEditor: JSX.Element | null = this.getFieldEditor(fieldEditorProps[i]);
            if (fieldEditor !== null)
                result.push(fieldEditor);
        }
        return result;
    }

    protected getTopButtons(): JSX.Element[] {
        return [
            <div className="small-4 medium-4 column" key="recedit_list">
                <button className="button" onClick={this.onList}>
                    <i className="fa fa-step-backward" />&nbsp;Lista</button>
            </div>,            
            <div className="small-4 medium-4 column" key="recedit_new">
                <button className="button" onClick={this.onReset}>
                <i className="fa fa-pencil" />&nbsp;Új</button>
            </div>,
        ];
    }


    protected getBottomButtons(): JSX.Element[] {
        const missingValue = this.state.missingValues;
        const saveEnabled = (missingValue && missingValue.length == 0);
        let result = [
            <div className="small-4 medium-4 column"  key="recedit_save">
                <button className="button" disabled={!saveEnabled} onClick={this.onSave}>Mentés</button>
            </div>,
        ];
        return result;
    }


    protected getFieldEditorProps(fieldInfo: IFieldInfo): IFieldEditorProp {
        return { fieldName: fieldInfo.field_name };
    }

    /**
     * Ez hozza létre a formot ami a rekordot szerkeszti.
     */
    protected getEditorForm(): JSX.Element {
        let fProps: IFieldEditorProp[] = [];
        let struct = this.state.struct;
        if (struct) {
            for (let i = 0; i < struct.fields.length; i++) {
                let fieldInfo = struct.fields[i];
                if (!fieldInfo.is_technical) {
                    fProps.push(this.getFieldEditorProps(fieldInfo));
                }
            }
        }
        return <div className="row" key="recedit_form">
            {this.getTopButtons()}
            {this.getFieldEditors(fProps)}
            {this.getBottomButtons()}
        </div>;
    }

    /**
     * A rekord szerkesztő alatt további elemeket lehet vele hozzáadni.
     */
    protected getDetailPanels(): any[] {
        return [];
    }


    // Be VERY careful when overriding this!
    protected asyncReload = async () => {
        this.doLoadCurrentRec();
    }

    private doLoadCurrentRec = async () => {
        if (this.state.rec_id) {
            this.getCrudClassProxy().load(this.state.rec_id)
                .then((crud) => this.setState({ rec: crud }, () => {
                    this.checkRequiredFields();
                }))
                .catch((error) => {
                    app.showErrorFromJsonResult(error);
                    this.onReset();
                });
        }
    }

    protected async doGetCurrentRec(): Promise<Partial<TRecord>> {
        let rec = this.state.rec!;
        let toSave: Partial<TRecord> = {};
        if (this.state.rec_id) {
            toSave["id"] = this.state.rec_id;
        }
        let struct = this.state.struct!;
        for (let i = 0; i < struct.fields.length; i++) {
            let fi = struct.fields[i];
            const canChange =
                (!this.state.rec_id || !fi.is_immutable) && !fi.is_technical;
            if (canChange) {
                toSave[fi.field_name] = rec[fi.field_name];
            }
        }
        return Promise.resolve(toSave);
    }

    protected doSaveCurrentRec = async (): Promise<void> => {
        const toSave = await this.doGetCurrentRec();
        this.getCrudClassProxy().put(toSave)
            .then((rec) => {
                app.showSuccess("Sikeres", "A tétel mentése sikeres volt.");
                if (this.state.rec_id == rec.id) {
                    this.asyncReload();
                } else {
                    if (this.props.inplaceListEditor) {
                        this.props.inplaceListEditor.onList();
                    } else {
                        history.push(this.getEditUrl(rec));
                    }
                    this.setState({ rec_id: rec.id }, this.asyncReload);
                }
            })
            .catch((error) => {
                app.showErrorFromJsonResult(error);
                this.asyncReload();
            });
    }

    /** Ellenőrzi a kötelező mezőket. */
    protected checkRequiredFields = async (): Promise<boolean> => {
        let rec = this.state.rec!;
        let result: boolean = true;
        let missingValues: string[] = [];
        let struct = this.state.struct!;
        for (let i = 0; i < struct.fields.length; i++) {
            let fieldInfo = struct.fields[i];
            if (!fieldInfo.is_technical && fieldInfo.not_null  && !this.ignoreRequired(fieldInfo)) {
                let value = rec[fieldInfo.field_name];
                if (value === null || value === undefined) {
                    missingValues.push(fieldInfo.field_name);
                    result = false;
                }
            }
        }
        this.setState({ missingValues: missingValues });
        return Promise.resolve(result);
    }

    /**
     * Ezzel felül tudod bírálni a not null constraint-eket.
     * Ha pl. nem akarod beküldeni, mert tudod hogy egy trigger fog
     * neki értéket adni.
     * 
     */
    protected ignoreRequired(fieldInfo: IFieldInfo) : boolean {
        return false;
    }

    protected validateRecord = async (): Promise<boolean> => {
        let result = await this.checkRequiredFields();
        if (!result)
            app.showError("Hiba", "Kérem töltse ki a kötelező mezőket!");
        return Promise.resolve(result);
    }

    protected onSave = async () => {
        const rec = this.state.rec!;
        if (await this.validateRecord()) {
            this.doSaveCurrentRec();
        }
    }

    /**
     * 
     * Új rekord létrehozásakor ("új" gomb megnyomása) ezek lesznek
     * a rekord alapértelmezett értéke. Az alapértelemzett viselkedés
     * szerint azok az értékek másolódnak be, amik a szűrésben is
     * benne voltak. Ezen felül, ha van "is_active" akkor az igazra
     * állítódik.
     * 
     */
    protected getNewRecordDefaults(): TRecord {
        let result = {};
        if (this.fieldInfoByName.get("is_active")) {
            result["is_active"] = true;
        }
        return Object.assign(result, this._rawDefaultValues || {}) as TRecord;
    }

    protected onReset = async () => {
        let rec = this.getNewRecordDefaults();
        this.setState({
            rec_id: null,
            rec: rec,
        }, () => {
            this.forceUpdate();
            this.checkRequiredFields();
        });
    }

    protected onLoad = async (rec_id: number) => {
        if (rec_id) {
            this.setState({
                rec_id: rec_id,
                rec: undefined,
            }, this.doLoadCurrentRec);
        } else {
            this.onReset();
        }

    }

    protected onList = () => {
        if (this.props.inplaceListEditor) {
            this.props.inplaceListEditor.onList();
        } else {
            history.push(this.getListUrl());
        }
    }


    protected onFieldChange = (fieldName: string, value: string|number|null) => {
        //console.log("onFieldChange", fieldName, value);
        let rec = Object.assign({}, this.state.rec);
        rec[fieldName] = value;
        this.setState({ rec: rec }, () => this.checkRequiredFields());
    }

    public render() {
        const rec = this.state.rec;
        if (rec === undefined) {
            return <BubbleLoader />
        } else {
            let formTitle;
            let editor;
            let formDescription;
            let detailPanels;
            if (rec === undefined || !this.state.struct) {
                formTitle = 'Tétel betöltése...';
                formDescription = '';
                editor = null;
                detailPanels = null;
            } else {
                const translation: ITableInfoTranslation = this.state.struct.translations[this.getLangId()];
                formTitle = this.state.rec_id ? 'Módosítás' : 'Új ' + translation.display_name;
                formDescription = translation.description || '';
                editor = this.getEditorForm();
                detailPanels = this.getDetailPanels();
            }

            const key = "recordeditor_" + this.getCrudClassProxy().getTableInfoIdForClass() + "_" + (this.props.record_id||'_null');
            return <div key={key}>
                <h4 key="recedit_title">{formTitle}</h4>
                <p key="recedit_descr">{formDescription}</p>
                {editor}
                {detailPanels}
            </div>;
        }
    }
}