import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewSectionCommentRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Szekció
     */
    section_id?: number;
    /**
     * Eredeti kijelölt szöveg
     */
    quote?: string|null;
    /**
     * Eredeti HTML
     */
    original_html?: string|null;
    /**
     * Pozíciók
     */
    ranges?: any|null;
    /**
     * Megjegyzés
     */
    comment?: string;
    /**
     * Elfogadva
     */
    acknowledged?: string /*timestamp?*/|null;
    /**
     * Ki fogadta el
     */
    acknowledged_by_id?: number|null;
    /**
     * Szekció megjegyzés típusa
     */
    section_comment_type_id?: number|null;
    /**
     * Ki mondta, hogy nem megoldható
     */
    unsolvable_by_id?: number|null;
    /**
     * Miért nem megoldható
     */
    unsolvable_reason?: string|null;
    /**
     * Ki mondta, hogy egyeztetés szükséges
     */
    coordination_required_by_id?: number|null;
    /**
     * Miért szükséges egyeztetés
     */
    coordination_required_reason?: string|null;
    /**
     * Lecke azonosító
     *
     * Lecke azonosító
     */
    lesson_id?: number|null;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creation_fullname?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modification_fullname?: string|null;
    /**
     * Jóváhagyó
     *
     * Felhasználó, aki utoljára jóváhagyta
     */
    acknowledged_fullname?: string|null;
    /**
     * Ki állítja, hogy megoldhatatlan
     *
     * Ki állítja, hogy megoldhatatlan
     */
    unsolvable_by_fullname?: string|null;
    /**
     * Ki állítja, hogy egyeztetés szükséges
     *
     * Ki állítja, hogy egyeztetés szükséges
     */
    coordination_required_fullname?: string|null;
}

/**
 *  doc.view_section_comment - Szekció megjegyzés
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewSectionComment extends View<IViewSectionCommentRecord> {
    public static VIEW_INFO_ID = 2018050301;

    public static getSchemaNameForClass(): string { return 'doc'; }
    public static getViewNameForClass(): string { return 'view_section_comment'; }
    public static getTableNameForClass(): string|null { return 'section_comment'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewSectionComment>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewSectionCommentRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewSectionCommentClassProxy extends ViewClassProxy<IViewSectionCommentRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewSectionCommentClassProxy = new ViewSectionCommentClassProxy(ViewSectionComment);
registerViewClassProxy(viewSectionCommentClassProxy);

