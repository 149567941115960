import { callApiThrowOnError } from "@src/framework/api/Api";

export type GetCorrectSolutionParams = {
    id: number,
    published: boolean,
};

export type GetCorrectSolutionResult = any;

export async function getCorrectSolution(parameters: GetCorrectSolutionParams): Promise<GetCorrectSolutionResult> {
    return callApiThrowOnError<GetCorrectSolutionParams, GetCorrectSolutionResult>("/exercise/getCorrectSolution", parameters);
}
