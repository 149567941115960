import * as React from 'react';

import { IViewTicketClaimRecord, ViewTicketClaimClassProxy, viewTicketClaimClassProxy } from '@src/framework/view/ticket/ViewTicketClaim';
import ListView from '@framework/forms/listview';
import '@framework/crud/ticket/ClaimCrud'; // Ezt ne távolítsd el mert akkor a hülye webpack kioptimizálja!
import { __ } from '@src/translation';
class ClaimListViewInner extends ListView<IViewTicketClaimRecord> {

    public getViewClassProxy() : ViewTicketClaimClassProxy { return viewTicketClaimClassProxy; }


    protected getRecordButtons(record: IViewTicketClaimRecord) : JSX.Element[] {
        /* TODO: automatikus detail list menüt ide? */
        return super.getRecordButtons(record)
        ;
    }
}

export default function ClaimListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <ClaimListViewInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "id", maxWidth: 100},
            {accessor: "title"},
            {accessor: "claim_type_name"},
            {accessor: "creation_time"},
            {accessor: "creator"},
            /*
            {accessor: "modification_time"},
            {accessor: "modifier"},
            */
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
