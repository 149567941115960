import * as React from 'react';

import { IViewSecGroupLeaderRecord, ViewSecGroupLeaderClassProxy, viewSecGroupLeaderClassProxy } from '@src/framework/view/sys/ViewSecGroupLeader';
import ListView from '@framework/forms/listview';
import { Groups, me } from '@src/framework/server/Auth';

import { app } from '@src/index';

import LookupEdit from '@framework/forms/lookupedit';
import SecUserCrud from '@src/framework/crud/sys/SecUserCrud';
import SecGroupCrud from '@src/framework/crud/sys/SecGroupCrud';
import SecGroupLeaderCrud from '@src/framework/crud/sys/SecGroupLeaderCrud';
import { __ } from '@src/translation';


class SecGroupLeaderInner extends ListView<IViewSecGroupLeaderRecord> {
    public getViewClassProxy(): ViewSecGroupLeaderClassProxy { return viewSecGroupLeaderClassProxy; }
    protected getFilterControls(): JSX.Element | null { return null; }
    protected getRecordButtons(record: IViewSecGroupLeaderRecord): JSX.Element[] {
        let result: JSX.Element[] = [];
        // Saját maga csoportjait senki nem szerkesztheti, ezzel ki tudná
        // zárni magát a rendszerből.
        if (record.leader_id != me!.id) {
            // A mindenki csoportnak nincs vezetője.
            if (record.group_id != Groups.Everyone) {
                result.push(this.getDeleteUndeleteButton(record)!);
            }
        }
        return result;
    }
}

export interface ISecGroupLeaderListViewForLeaderProps {
    leader_id: number;
}

export interface ISecGroupLeaderListViewForLeaderState {
    groupIdToAdd: number | null;
    refreshKey: number
}

export class SecGroupLeaderListViewForLeader extends React.Component<ISecGroupLeaderListViewForLeaderProps, ISecGroupLeaderListViewForLeaderState> {

    constructor(props: ISecGroupLeaderListViewForLeaderProps) {
        super(props);
        this.state = { groupIdToAdd: null, refreshKey: 0 };
    }

    private onGroupIdChange = (newValue: any) => {
        if ((typeof newValue === 'string'))
            if (newValue.trim() === '')
                newValue = '';
        if (newValue === '')
            newValue = null;
        let newState = {
            groupIdToAdd: newValue
        };
        this.setState(newState);
    }

    private onAddGroup = () => {
        new SecGroupLeaderCrud({
            leader_id: this.props.leader_id,
            group_id: this.state.groupIdToAdd!
        }).put()
            .then((crud) => {
                this.setState({
                    groupIdToAdd: null, refreshKey: this.state.refreshKey + 1
                }, this.forceUpdate)
            })
            .catch((error) => { app.showErrorFromJsonResult(error) })
    }


    public render() {
        const value: any = this.state.groupIdToAdd;
        return (
            <div>
                <div className="row">
                    <div className="column small-8 medium-8">
                        <LookupEdit
                            key={"groupIdToAdd_" + (this.state.groupIdToAdd || 'null')}
                            name="groupIdToAdd"
                            fk_table_info_id={SecGroupCrud.TABLE_INFO_ID}
                            clearable={true}
                            value={value}
                            onChange={(newValue) => this.onGroupIdChange(newValue)}
                        />
                    </div>
                    <div className="column small-4 medium-4">
                        <button type="button" className="button primary"
                            disabled={!this.state.groupIdToAdd}
                            onClick={this.onAddGroup}
                        >
                            <i className="fa fa-plus" />
                            {__("Hozzáad")}
                    </button>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 medium-12">
                        <SecGroupLeaderInner
                            key={this.state.refreshKey}
                            allowInsertNew={false}
                            defaultPageSize={10}
                            filter={{ leader_id: this.props.leader_id }}
                            viewColumns={[
                                { accessor: "id", maxWidth: 100 },
                                { accessor: "group_id", maxWidth: 100 },
                                { accessor: "group_title" },
                                { accessor: "group_type_title" },
                                /*
                                {accessor: "creation_time"},
                                {accessor: "creator"},
                                {accessor: "modification_time"},
                                {accessor: "modifier"},
                                */
                                { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
                            ]} />
                    </div>
                </div>
            </div>);
    }

}



export interface ISecGroupLeaderListViewForGroupProps {
    group_id: number;
}

export interface ISecGroupLeaderListViewForGroupState {
    leaderIdToAdd: number | null;
    refreshKey: number
}

export class SecGroupLeaderListViewForGroup extends React.Component<ISecGroupLeaderListViewForGroupProps, ISecGroupLeaderListViewForGroupState> {

    constructor(props: ISecGroupLeaderListViewForGroupProps) {
        super(props);
        this.state = { leaderIdToAdd: null, refreshKey: 0 };
    }

    private onUserIdChange = (newValue: any) => {
        if ((typeof newValue === 'string'))
            if (newValue.trim() === '')
                newValue = '';
        if (newValue === '')
            newValue = null;
        let newState = {
            leaderIdToAdd: newValue
        };
        this.setState(newState);
    }

    private onAddUser = () => {
        new SecGroupLeaderCrud({
            group_id: this.props.group_id,
            leader_id: this.state.leaderIdToAdd!
        }).put()
            .then((crud) => {
                this.setState({
                    leaderIdToAdd: null, refreshKey: this.state.refreshKey + 1
                }, this.forceUpdate)
            })
            .catch((error) => { app.showErrorFromJsonResult(error) })
    }


    public render() {
        const value: any = this.state.leaderIdToAdd;
        return (
            <div>
                <div className="row">
                    <div className="column small-8 medium-8">
                        <LookupEdit
                            key={"leaderIdToAdd" + (this.state.leaderIdToAdd || 'null')}
                            name="leaderIdToAdd"
                            fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                            clearable={true}
                            value={value}
                            onChange={(newValue) => this.onUserIdChange(newValue)}
                        />
                    </div>
                    <div className="column small-4 medium-4">
                        <button type="button" className="button primary"
                            disabled={!this.state.leaderIdToAdd}
                            onClick={this.onAddUser}
                        >
                            <i className="fa fa-plus" />
                            {__("Hozzáad")}
                    </button>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 medium-12">
                        <SecGroupLeaderInner
                            key={this.state.refreshKey}
                            allowInsertNew={false}
                            defaultPageSize={10}
                            filter={{ group_id: this.props.group_id }}
                            viewColumns={[
                                { accessor: "id", maxWidth: 100 },
                                { accessor: "leader_id", maxWidth: 100 },
                                { accessor: "login_name" },
                                { accessor: "fullname" },
                                /*
                                {accessor: "creation_time"},
                                {accessor: "creator"},
                                {accessor: "modification_time"},
                                {accessor: "modifier"},
                                */
                                { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
                            ]} />
                    </div>
                </div>
            </div>);
    }

}
