import * as React from 'react';

import { IViewMetaViewInfoRecord, ViewMetaViewInfoClassProxy, viewMetaViewInfoClassProxy } from '@src/framework/view/meta/ViewMetaViewInfo';
import ListView from '@framework/forms/listview';
import { __ } from '@src/translation';

class ViewInfoInner extends ListView<IViewMetaViewInfoRecord> {
    //protected getHeader = () : JSX.Element|null => { return <SideMenu /> }
    public getViewClassProxy() : ViewMetaViewInfoClassProxy { return viewMetaViewInfoClassProxy; }

    protected getDeleteUndeleteButton(record: IViewMetaViewInfoRecord): JSX.Element | null { return null; }    

    protected getQuickFilterFieldNames(): string[] {
        return ['schema_name', 'view_name', 'display_name'];
    }

}

export default function ViewInfoListView(props: any) {
    return <ViewInfoInner 
        defaultPageSize={10}
        match={props.match}
        viewColumns={[
            {accessor: "id"},
            {accessor: "schema_name"},
            {accessor: "view_name"},
            {accessor: "display_name"},
            {accessor: "description"},
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
