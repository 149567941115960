import * as React from 'react';
declare var MathJax: any;
export class  MathJaxText extends React.Component {
    componentDidMount() {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, this.refs.element]);
    }
    componentDidUpdate() {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, this.refs.element]);
    }
    render() {
        return <span ref="element">{this.props.children}</span>;
    }
}
