import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewGoodWordGlobalRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Szó
     */
    word?: string;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Módosította
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
}

/**
 *  spell.view_good_word_global - Jó szavak
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewGoodWordGlobal extends View<IViewGoodWordGlobalRecord> {
    public static VIEW_INFO_ID = 2018072002;

    public static getSchemaNameForClass(): string { return 'spell'; }
    public static getViewNameForClass(): string { return 'view_good_word_global'; }
    public static getTableNameForClass(): string|null { return 'good_word_global'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewGoodWordGlobal>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewGoodWordGlobalRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewGoodWordGlobalClassProxy extends ViewClassProxy<IViewGoodWordGlobalRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewGoodWordGlobalClassProxy = new ViewGoodWordGlobalClassProxy(ViewGoodWordGlobal);
registerViewClassProxy(viewGoodWordGlobalClassProxy);

