import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewOccupationVisitSubprogramStatsRecord {
    /**
     * Foglalkozás látogatás azonosító
     *
     * Foglalkozás látogatás azonosító
     */
    id?: number;
    /**
     * Látogatás kezdete
     *
     * Látogatás kezdete
     */
    min_start_date?: string /*timestamp?*/|null;
    /**
     * Feladat ellátási hely neve
     *
     * Feladat ellátási hely neve
     */
    institute_site_name?: string|null;
    /**
     * Ellátási hely azonosító
     *
     * Ellátási hely azonosító
     */
    site_identifier?: string|null;
    /**
     * Ellátási hely irányítószám
     *
     * Ellátási hely irányítószám
     */
    institute_site_zipcode?: string|null;
    /**
     * Ellátási hely címsor
     *
     * Ellátási hely címsor
     */
    institute_site_address?: string|null;
    /**
     * Ellátási hely település azonosító
     *
     * Ellátási hely település azonosító
     */
    city_id?: number|null;
    /**
     * Ellátási hely település név
     *
     * Ellátási hely település név
     */
    city_name?: string|null;
    /**
     * Intézmény azonosító
     *
     * Intézmény azonosító
     */
    institute_id?: number|null;
    /**
     * Intézmény OM azonosító
     *
     * Intézmény OM azonosító
     */
    om_identifier?: string|null;
    /**
     * Intézmény neve
     *
     * Intézmény neve
     */
    institute_name?: string|null;
    /**
     * Tanár felhasználó azonosító
     *
     * Tanár felhasználó azonosító
     */
    teacher_id?: number|null;
    /**
     * Tanár személy azonosító
     *
     * Tanár személy azonosító
     */
    teacher_person_id?: number|null;
    /**
     * Tanár e-mail cím
     *
     * Tanár e-mail cím
     */
    teacher_email?: string|null;
    /**
     * Tanár teljes neve
     *
     * Tanár teljes neve
     */
    teacher_fullname?: string|null;
    /**
     * Támogató felhasználó azonosító
     *
     * Támogató felhasználó azonosító
     */
    kap_supporter_id?: number|null;
    /**
     * Támogató személy azonosító
     *
     * Támogató személy azonosító
     */
    supporter_person_id?: number|null;
    /**
     * Támogató e-mail cím
     *
     * Támogató e-mail cím
     */
    supporter_email?: string|null;
    /**
     * Támogató teljes neve
     *
     * Támogató teljes neve
     */
    supporter_fullname?: string|null;
    /**
     * Min. létszám
     *
     * Min. létszám
     */
    min_visitors?: number /*int4*/|null;
    /**
     * Max. létszám
     *
     * Max. létszám
     */
    max_visitors?: number /*int4*/|null;
    /**
     * Jelentkezések száma
     *
     * Jelentkezések száma
     */
    applied_visitors?: number|null;
    /**
     * Feltöltött állományok száma
     *
     * Feltöltött állományok száma
     */
    file_count?: number|null;
    /**
     * Szempontsor kitöltve
     *
     * Szempontsor kitöltve
     */
    has_subprogram_aspect_answer?: boolean|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Folyamat állapot név
     *
     * Folyamat állapot név
     */
    wf_station_name?: string|null;
}

/**
 *  kap.view_occupation_visit_subprogram_stats - Foglalkozásterv - alprogram statisztika
 *
 *  Foglalkozásterv - alprogram statisztika
 */

export default class ViewOccupationVisitSubprogramStats extends View<IViewOccupationVisitSubprogramStatsRecord> {
    public static VIEW_INFO_ID = 2019122302;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_occupation_visit_subprogram_stats'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewOccupationVisitSubprogramStats>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewOccupationVisitSubprogramStatsRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewOccupationVisitSubprogramStatsClassProxy extends ViewClassProxy<IViewOccupationVisitSubprogramStatsRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewOccupationVisitSubprogramStatsClassProxy = new ViewOccupationVisitSubprogramStatsClassProxy(ViewOccupationVisitSubprogramStats);
registerViewClassProxy(viewOccupationVisitSubprogramStatsClassProxy);

