import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ILessonDraftBlockSubprogramRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * DFHT (alap és komplex) óraterv blokk
     *
     * NOT NULL -> kap.lesson_draft_block ON DELETE cascade
     *
     */
    lesson_draft_block_id?: number;
    /**
     * KAP alprogram
     *
     * NOT NULL -> kap.subprogram ON DELETE noaction
     *
     */
    subprogram_id?: number;
}

/**
 *  kap.lesson_draft_block_subprogram - DFHT (alap és komplex) óraterv blokk alprogram kapcsolat
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class LessonDraftBlockSubprogramCrud extends Crud<ILessonDraftBlockSubprogramRecord> {
    public static TABLE_INFO_ID = 1079056745;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'lesson_draft_block_subprogram'; }

    public static load: (id: number, aServer ?: Server) => Promise<LessonDraftBlockSubprogramCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ILessonDraftBlockSubprogramRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ILessonDraftBlockSubprogramRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ILessonDraftBlockSubprogramRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ILessonDraftBlockSubprogramRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ILessonDraftBlockSubprogramRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class LessonDraftBlockSubprogramCrudClassProxy extends CrudClassProxy<ILessonDraftBlockSubprogramRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const lessonDraftBlockSubprogramCrudClassProxy = new LessonDraftBlockSubprogramCrudClassProxy(LessonDraftBlockSubprogramCrud);
registerCrudClassProxy(lessonDraftBlockSubprogramCrudClassProxy);
