import * as React from 'react';
import { Toggle, ToggleControl } from './Panel';
import { __ } from '@src/translation';

export type videotype = {
    src: string,
    title: string
}

type videoProps = {
    videos: videotype[],
    id: string
}

type videoState = {
    title: string,
    isMuted: boolean,
    isPlaying: boolean,
    currTime: any,
    durTime: any,
    max: string,
    actVideo: number,
    intervalId: any,
    toggleIsOpened: boolean
}

export class VideoPlayer extends React.Component<videoProps, videoState> {
    static defaultProps = {
        id: "tesztid"
    }

    defaultTime = "00:00";

    constructor(props: any) {
        super(props);

        this.state = {
            title: "",
            isMuted: false,
            isPlaying: false,
            currTime: this.defaultTime,
            durTime: this.defaultTime,
            max: "0",
            actVideo: 0,
            intervalId: null,
            toggleIsOpened: false
        }
    }

    videoElement: HTMLVideoElement;

    componentDidMount() {
        this.videoElement = this.refs.video as HTMLVideoElement;
        this.videoElement.src = this.props.videos[this.state.actVideo].src;
        this.setState({ title: this.props.videos[this.state.actVideo].title, actVideo: 0 });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    LoadVideo(act: number) {
        this.videoElement.src = this.props.videos[act].src;
        (this.refs.videoslider as any).value = 0; 
    }

    Play() {
        if (this.state.isPlaying) {
            this.setState({ isPlaying: false });
            this.videoElement.pause();
            clearInterval(this.state.intervalId);
        } else {
            this.videoElement.play();
            var interval = setInterval(this.UpdateVideoSlider.bind(this), 1000);
            this.setState({ isPlaying: true, intervalId: interval });
        }
    }

    Stop() {
        this.setState({ isPlaying: false, currTime: this.defaultTime });
        this.videoElement.pause();
        this.videoElement.currentTime = 0;
        (this.refs.videoslider as any).value = 0;

        clearInterval(this.state.intervalId);
    }

    SeekVideo() {
        this.videoElement.currentTime = (this.refs.videoslider as any).value;
        this.setState({ currTime: this.ConvertTime((this.refs.videoslider as any).value) });
    }

    SetVolume() {
        let value = (this.refs.volumeslider! as any).value;
        this.videoElement.volume = value;
        this.videoElement.muted = false;
        this.setState({ isMuted: false });
    }

    Mute() {
        if (this.videoElement.muted) {
            this.videoElement.muted = false;
            this.setState({ isMuted: false });
        } else {
            this.videoElement.muted = true;
            this.setState({ isMuted: true });
        }
    }

    Previous() {
        let actNum = this.state.actVideo;
        if (actNum > 0) {
            this.setState({ actVideo: actNum - 1, title: this.props.videos[actNum - 1].title }); this.LoadVideo(actNum - 1);
        }
        else if (actNum == 0) {
            this.setState({ actVideo: (this.props.videos.length - 1), title: this.props.videos[this.props.videos.length - 1].title });
            this.LoadVideo(this.props.videos.length - 1);
        }

        if (this.state.isPlaying) {
            this.videoElement!.play();
        }
        this.setState({ currTime: this.defaultTime });
    }

    Next() {
        let actNum = this.state.actVideo
        if (actNum < this.props.videos.length - 1) {
            this.setState({ actVideo: actNum + 1, title: this.props.videos[actNum + 1].title });
            this.LoadVideo(actNum + 1);
        }
        else if (actNum == this.props.videos.length - 1) {
            this.setState({ actVideo: 0, title: this.props.videos[0].title });
            this.LoadVideo(0);
        }
        if (this.state.isPlaying) {
            this.videoElement!.play();
        }
        this.setState({ currTime: this.defaultTime });
    }

    UpdateVideoSlider() {
        let time = Math.round(this.videoElement.currentTime);
        (this.refs.videoslider as any).value = time;
        this.setState({ currTime: this.ConvertTime(time)});
    }

    ConvertTime(secs: any) {
        let min = Math.floor(secs / 60);
        let sec = secs % 60;
        let mintext = min < 10 ? "0" + min : min;
        let sectext = sec < 10 ? "0" + sec : sec;
        return (mintext + ":" + sectext);
    }

    LoadedData() {
        this.setState({ durTime: this.ConvertTime(Math.round(this.videoElement.duration)), max: Math.round(this.videoElement.duration).toString() });
    }

    Fullscreen(){
        this.videoElement.requestFullscreen();
    }

    selectVideo(num: number) {
        this.LoadVideo(num);
        this.setState({ actVideo: num, title: this.props.videos[num].title, currTime: this.defaultTime });
        if (this.state.isPlaying) {
            this.videoElement.play();
        }
    }

    Toggle() {
        this.state.toggleIsOpened ? this.setState({ toggleIsOpened: false }) : this.setState({ toggleIsOpened: true });
    }

    render() {
        let multipleVideos = (this.props.videos.length > 1);
        return <div className="video-container">
            <span className="show-for-sr">{__("Videolejátszó")}</span>
            <div className="title">
                <h3 className="video-title">{this.state.title}</h3>
                {multipleVideos ? <div className="nums">
                    <span>{this.state.actVideo + 1 + "/" + this.props.videos.length}</span>
                    <ToggleControl id={this.props.id}><i className="fas fa-list-ul" onClick={() => this.Toggle()}></i>{
                        this.state.toggleIsOpened ? <span className="show-for-sr">{__("Kattints a lejátszási lista összecsukásához!")}</span> :
                            <span className="show-for-sr">{__("Kattints a lejátszási lista lenyitásához!")}</span>
                    }</ToggleControl>
                </div> : ""}

            </div>

            {multipleVideos ? <Toggle id={this.props.id} className="video-list-toggle">
                <ol>
                    {
                        this.props.videos.map((item, num) => {
                            return <a onClick={() => this.selectVideo(num)}><li key={"i-" + num}>{item.title ? item.title : __("Nincs cím megadva")}</li></a>
                        })
                    }
                </ol>
            </Toggle> : ""}

            <div className="main-video">
                <button className={(!this.state.isPlaying) ? "hower-play" :"hidden"}>
                        <i className="fa fa-play fa-5x"></i>
                </button>
                <video ref="video" onLoadedData ={()=> this.LoadedData()} onClick = {() => this.Play()}></video> 
            </div>
            
            <div className="video-slider-container">
                <span className="current-time" title={__("Videoanyag aktuális ideje")}>{this.state.currTime}</span>
                <input ref="videoslider" className="video-slider" type="range" min="0" max={this.state.max} step="1" defaultValue="0" title={__("Videoanyag")} onChange={() => this.SeekVideo()}></input>
                <span className="duration-time" title={__("Videoanyag hossza")}>{this.state.durTime}</span>
            </div>                  

            <div className="controller-panel">
                <div className="button-container">
                    {multipleVideos ? <button className="previous" onClick={() => this.Previous()}><i className="fas fa-backward" /><label className="show-for-sr">{__("Előző videoanyag")}</label></button> : ""}
                    <button className="play" onClick={() => this.Play()}><i className={"fa " + (!this.state.isPlaying ? "fa-play" : "fa-pause")} />{!this.state.isPlaying ? <label className="show-for-sr">{__("Lejátszás")}</label> : <label className="show-for-sr">{__("Szüneteltetés")}</label>}</button>
                    <button className="stop" onClick={() => this.Stop()}><i className="fa fa-stop" /><label className="show-for-sr">{__("Megállítás")}</label></button>
                    {multipleVideos ? <button className="next" onClick={() => this.Next()}><i className="fas fa-forward" /><label className="show-for-sr">{__("Következő videoanyag")}</label></button> : ""}
                </div>
                <div className="volume-slider-container">
                    <button className="audio-icon" onClick={() => this.Mute()}><i className={"fas " + (this.state.isMuted ? "fa-volume-mute" : "fa-volume-up")} />{this.state.isMuted ? <label className="show-for-sr">{__("Némítva. Hang bekapcsolása")}</label> : <label className="show-for-sr">{__("Némítás")}</label>}</button>
                    <input ref="volumeslider" className="video-volume-slider" type="range" min="0" max="1" step="0.01" defaultValue="0.5" onChange={this.SetVolume.bind(this)} title={__("Hangerő szabályzó")}></input>
                    <button className="fullscreen-icon" onClick={() => this.Fullscreen()}><i className="fas fa-expand"/></button>
                </div>
            </div>
            <span className="show-for-sr">{__("Videolejátszó vége")}</span>
        </div>
    }
}