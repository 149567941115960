import * as React from 'react';
import MetaMenu from './meta_menu';
import MetaCategoryAssignerPlugin from './meta_category_assigner_plugin';
import { fetchKeywords, MetaKeywordAssignerPlugin, MetaKeywordImmediateAssignerPlugin, setKeywords } from './meta_keyword_assigner_plugin';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import { getTableInfoByName, ITableInfo } from '@src/framework/crud/Meta';
import PermissionPage from '@src/framework/PermissionPage';
import { CrudPermissionType } from '@src/framework/security/PermissionCache';

import { app } from '@src/index';
import { MetaCategorization, setCategoryIds } from '@src/component/meta/meta_category_assigner_api';

import UserConfigStore from '@src/component/usr/usr_config_store_api';
import { __ } from '@src/translation';

interface IMetaTesztState {
    book?: IBookRecord;
    tableInfo?: ITableInfo;
    keywords: string[];
    categorization: MetaCategorization;
    subject_ids: { [category_type_id: string]: number | null };

    v : string;
}

interface ITesztConfig {
    v : string;
}
export default class MetaTeszt extends React.Component<any, IMetaTesztState> {
    constructor(props: any) {
        super(props);
        this.state = {
            categorization: {},
            subject_ids: {},
            keywords: [],
            v: '',
        };
        getTableInfoByName("doc", "book").then(
            (tableInfo) => this.setState({ tableInfo })
        );
        this.asyncInit();
    }

    private asyncInit = async () => {
        try {
            const books = await BookCrud.list({ filter: { is_active: true }, limit: 1 });
            const book = books[0];
            this.setState({ book }, this.loadKeywords);
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    private loadKeywords = async () => {
        try {
            if (this.state.book) {
                const keywords = await fetchKeywords(BookCrud.TABLE_INFO_ID, this.state.book.id!);
                this.setState({ keywords });
                app.showInfo(__("Siker!"), __("Betölttöted a kulcsszavakat!"));
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private saveKeywords = async () => {
        try {
            if (this.state.book) {
                const keywords = await setKeywords(
                    BookCrud.TABLE_INFO_ID, this.state.book.id!,
                    this.state.keywords!
                );
                app.showInfo(__("Siker!"), __("Elmentetted a kulcsszavakat!"));
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private onAppendKeywords = async (keywords: string[]) => {
        this.setState({ keywords: this.state.keywords!.concat(keywords) });
    }

    private onDeleteKeyword = async (index: number) => {
        this.setState({ keywords: this.state.keywords!.splice(index, 1) });
    }

    private onSubjectSelected = (category_type_id: number, subject_id: number | null) => {
        this.setState({
            subject_ids: Object.assign(
                {},
                this.state.subject_ids,
                { [category_type_id.toString()]: subject_id })
        });
    }

    private onCategoryIdsLoaded = (category_type_id: number, category_ids: number[]) => {
        this.onSetCategoryIds(category_type_id, category_ids);
    }

    private onSetCategoryIds = (category_type_id: number, category_ids: number[]) => {
        let categorization = Object.assign(
            {},
            this.state.categorization,
            { [category_type_id.toString()]: category_ids }
        );
        this.setState({ categorization });
    }

    private onRemoveCategoryIds = (category_type_id: number, remove_category_ids: number[]) => {
        let category_ids = (this.state.categorization[category_type_id] || []).filter(
            (category_id: number) => { return remove_category_ids.indexOf(category_id) < 0 }
        );
        this.setState({
            categorization:
                Object.assign(
                    {},
                    this.state.categorization,
                    { [category_type_id]: category_ids }
                )
        });
    }

    private reloadCategories = () => {
        this.setState({ categorization: {} }, this.forceUpdate);
    }

    private saveCategories = async () => {
        try {
            for (let category_type_id in this.state.categorization) {
                const category_ids = this.state.categorization[category_type_id];
                await setCategoryIds(
                    BookCrud.TABLE_INFO_ID, this.state.book!.id!,
                    category_ids!,
                    parseFloat(category_type_id)
                );
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
        app.showSuccess(__("Siker!"), __("Kategóriák mentése sikeres"));
    }

    private vChange = (event: any) => {
        this.setState({v: event.target.value});
    }

    private storeV = async () => {
        let valueToStore : ITesztConfig = {v:this.state.v};
        try {
            await UserConfigStore.put(__("teszt.kulcs"), valueToStore);
            app.showSuccess(__("Siker"), __("beállítottad"));
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }
    private loadV = async () => {
        try {
            let value : ITesztConfig|null = await UserConfigStore.get<ITesztConfig>(__("teszt.kulcs"));
            if (value===null) {
                app.showError(__("Nincs ilyen konfig"), __("Nem lehet beltölteni, mert nincs ilyen eltárolva"));
            } else {
                this.setState({v: value.v});                
                app.showSuccess(__("Siker"), __("betöltötted"));
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }
    private delV = async () => {
        try {
            await UserConfigStore.remove(__("teszt.kulcs"));
            app.showSuccess(__("Siker"), __("törölted"));
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }


    render() {
        const book = this.state.book;
        const tableInfo = this.state.tableInfo;
        if (book && tableInfo) {
            return (
                <PermissionPage requirements={{
                    crud: { tableInfoId: BookCrud.TABLE_INFO_ID, permissions: CrudPermissionType.U }
                }}>

                    <div>
                        <MetaMenu />
                        <h2>{ __("Ez itt egy teszt lap") }</h2>
                        <p>{ __("Ezen keresztül lehet megnézni, hogy milyen is az, amikor") +
                            __("egy metaadat struktúra kapcsolódik egy könyvhöz. Vagy bármi máshoz.") }
                    </p>
                        <h3>{book.name} ,id={book.id}</h3>
                        <h4>{ __("Kategória hozzzárendelő plugin") }</h4>
                        <MetaCategoryAssignerPlugin
                            tableInfoId={BookCrud.TABLE_INFO_ID}
                            recId={book.id || null}
                            categorization={this.state.categorization}
                            subject_ids={this.state.subject_ids}
                            onSubjectSelected={this.onSubjectSelected}
                            onCategoryIdsLoaded={this.onCategoryIdsLoaded}
                            onSetCategoryIds={this.onSetCategoryIds}
                            onRemoveCategoryIds={this.onRemoveCategoryIds}
                        />
                        <button className="button reload primary"
                            onClick={() => this.reloadCategories()}>
                            { __("Újra betölt") }
                    </button>
                        <button className="button save primary"
                            onClick={() => this.saveCategories()}>
                            { __("Elment") }
                    </button>

                        <h4>{ __("Kulcsszó szerkesztő plugin mentés gombbal") }</h4>
                        <MetaKeywordAssignerPlugin
                            keywords={this.state.keywords}
                            onAppendKeywords={this.onAppendKeywords}
                            onDeleteKeyword={this.onDeleteKeyword}
                        />
                        <button className="button reload primary"
                            onClick={() => this.loadKeywords()}>
                            { __("Újra betölt") }
                    </button>
                        <button className="button save primary"
                            onClick={() => this.saveKeywords()}>
                            { __("Elment") }
                    </button>
                        <h4>{ __("Kulcsszó szerkesztő plugin azonnali mentéssel") }</h4>
                        <MetaKeywordImmediateAssignerPlugin
                            tableInfoId={BookCrud.TABLE_INFO_ID}
                            recordId={book.id || null}
                        />
                    </div>

                    <input type="text" value={this.state.v} onChange={this.vChange}/>
                    <button className="button" onClick={this.storeV} >Store</button>
                    <button className="button" onClick={this.loadV} >Load</button>
                    <button className="button" onClick={this.delV} >Remove</button>
                    
                </PermissionPage>);
        } else {
            return <span>{ __("kérem várjon...") }</span>;
        }
    }
}