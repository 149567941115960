import { callApiThrowOnError } from "@src/framework/api/Api";
import obtainServer from "@src/framework/server/Server";

export type GetExerciseParams = {
    exerciseIndex: number,
    runId?: string,
    shareContentRunId?: number,
};

export type GetExerciseResult = {
    allExercisesCompleted: boolean,
    canSkipExercise: boolean,
    canUserEvaluate: boolean,
    earnedPoints: number,
    exerciseCount: number,
    exerciseDetails: {
        evaluationTimeStamp: {
            cdate: {
                cachedFixedDateJan1: number,
                cachedFixedDateNextJan1: number,
                cachedYear: number,
            } | null,
            defaultCenturyStart: number,
            fastTime: number,
            gcal: {
                $assertionsDisabled: boolean,
                ACCUMULATED_DAYS_IN_MONTH: number[] | null,
                ACCUMULATED_DAYS_IN_MONTH_LEAP: number[] | null,
                APRIL: number,
                AUGUST: number,
                BASE_YEAR: number,
                DAYS_IN_MONTH: number[] | null,
                DECEMBER: number,
                FEBRUARY: number,
                FIXED_DATES: number[] | null,
                FRIDAY: number,
                JANUARY: number,
                JULY: number,
                JUNE: number,
                MARCH: number,
                MAY: number,
                MONDAY: number,
                NOVEMBER: number,
                OCTOBER: number,
                SATURDAY: number,
                SEPTEMBER: number,
                SUNDAY: number,
                THURSDAY: number,
                TUESDAY: number,
                WEDNESDAY: number,
            } | null,
            jcal: {
                $assertionsDisabled: boolean,
                ACCUMULATED_DAYS_IN_MONTH: number[] | null,
                ACCUMULATED_DAYS_IN_MONTH_LEAP: number[] | null,
                APRIL: number,
                AUGUST: number,
                BASE_YEAR: number,
                DAYS_IN_MONTH: number[] | null,
                DECEMBER: number,
                FEBRUARY: number,
                FIXED_DATES: number[] | null,
                FRIDAY: number,
                JANUARY: number,
                JULY: number,
                JUNE: number,
                MARCH: number,
                MAY: number,
                MONDAY: number,
                NOVEMBER: number,
                OCTOBER: number,
                SATURDAY: number,
                SEPTEMBER: number,
                SUNDAY: number,
                THURSDAY: number,
                TUESDAY: number,
                WEDNESDAY: number,
            } | null,
            serialVersionUID: number,
            ttb: number[] | null,
            wtb: string[] | null,
        } | null,
        exerciseEngineName: string | null,
        exerciseIndex: number,
        exerciseRecord: any | null,
        filteredCorrectSolutionJSON: any | null,
        filteredExerciseJSON: any | null,
        successPercent: number | null,
        totalPoints: number,
        userSolutionJSON: any | null,
    } | null,
    exerciseSeriesRecord: any | null,
    isFinalExercise: boolean,
    runId: string | null,
    sharedContentRunId: number | null,
    successCount: number,
    totalExerciseCount: number | null,
    totalPoints: number,
};

export async function getExercise(parameters: GetExerciseParams): Promise<GetExerciseResult> {
    return callApiThrowOnError<GetExerciseParams, GetExerciseResult>("/exercise/getExercise", parameters);
}

export async function createFolderForOpenEndedExercise(exerciseId:number):Promise<number>{
    return obtainServer().post<number>('open_ended_question', {operation: "create_folder", id: exerciseId });
}

export async function createFolderForSubmittedSolution(exerciseId:number):Promise<number>{
    return obtainServer().post<number>('submitted_solution', {operation: "create_folder", id: exerciseId });
}
