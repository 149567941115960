import './style.css';
import { ExerciseBaseClass, AnswerElement } from '@src/component/exercise/models/ExerciseBaseClass';
import { ShuffleResult } from '../../models/AExerciseTypeConverter';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { EKESortingServer } from './EKESortingServer';
import { __ } from '@src/translation';

export interface SortingData extends ExerciseBaseClass {
    horizontal: boolean;
    answers: string[];
    options: AnswerElement[];
    keywords: string;
    original_indexes: number[];
}

type SortingUserSolution = {
    answer: number[];
    fullmatch: boolean;
}

export class EKESortingExerciseEngine extends AExerciseEngine {

    private sortableItemsList: HTMLElement[] = [];
    private originalIndexes: number[] = [];
    private unorderedList: HTMLUListElement = document.createElement("ul");
    private gotcorrectsolution: boolean = false;
    private horizontalSorting: boolean;
    private userInteraction: boolean = false;

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        this.gotcorrectsolution = false;
        let exercise: SortingData = params.exercise;
        this.horizontalSorting = exercise.horizontal;
        this.sortableItemsList = [];
        this.exercise = exercise;
        this.root.classList.add("eke-sorting-drag-and-drop");
        this.setIllustration(exercise, this.root);

        var exerciseContainer = this.root.appendChild(document.createElement("div"));
        let accessibilityHelperText = exerciseContainer.appendChild(document.createElement('p'));
        accessibilityHelperText.innerText =
            __(`A sorbarendezés úgy oldható meg képernyőolvasót használók számára, hogy először Enterrel 
            vagy Szóközzel ki kell választani azt az elemet, amelyet át szeretnénk helyezni, majd navigáljunk
            ahhoz az elemhez, amelynek a helyére tenni szeretnénk, majd ismét nyomjunk Entert, vagy Szóközt.
            Ekkor a két elem megcserélődik.`);
        accessibilityHelperText.classList.add("show-for-sr");
        let answersRow = exerciseContainer.appendChild(document.createElement("div"));
        this.unorderedList = answersRow.appendChild(document.createElement("ul"));

        this.unorderedList.classList.add("row");
        if (exercise.horizontal) {
            this.unorderedList.classList.add("eke-sortable-list");
        }
        else {
            answersRow.classList.add("eke-sortable-centerizedContainer");
            this.unorderedList.classList.add("eke-sortable-vertical-list");
        }

        if (!exercise.options || exercise.options.length < 2) return;

        //let shufRes: ShuffleResult = AExerciseEngine.shuffleWithIndexes(exercise.options);
        //let array = shufRes.answers;
        this.originalIndexes = AExerciseEngine.getRealIdx(exercise.original_indexes);
        this.generateList(exercise.options, this.unorderedList);
        this.putNumberingOnList(false);

        if (!this.isReplay) {
            let sortable = ($(this.unorderedList) as any).sortable(/*{ scroll: false }*/
                {
                    helper: 'clone',
                    update: this.putNumberingOnList.bind(this, true),
                    tolerance: "pointer",
                    scrollSensitivity: 80,
                    containment: this.root
                }
            );

            ($(this.unorderedList) as any).disableSelection();

            let sortableItems = sortable[0]['childNodes'];
            this.simulateSort(sortableItems, 'ui-sortable-sorting');
        }
    }

    putNumberingOnList(onUpdate: boolean) {
        var indexDiv = this.root.getElementsByClassName("sortable-number");
        var accNums = this.root.getElementsByClassName("accessibility-number");
        for (let i = 0; i < indexDiv.length; i++) {
            indexDiv[i].innerHTML = (i + 1).toString();
            accNums[i].innerHTML = (i + 1).toString();
        }
        this.userInteraction = onUpdate;
        //we only do the eval if this method is called from onupdate and not from init
        if (onUpdate && this.isSNIexc) this.SNIEvaluation(EKESortingServer);
    }

    getUserSolution(): SortingUserSolution {

        var result: any[] = [];
        var sortedListElements = this.root.querySelectorAll(".eke-sortingElementItem");
        for (var index = 0; index < sortedListElements.length; index++) {
            var val = +sortedListElements[index].attributes.getNamedItem("data-index")!.value;
            result.push(val);
        }
        let solution = { answer: result, fullmatch: true };
        return solution;
    }

    receiveEvaluation(evaluated: Evaluated): void {
        $(".is-correct-helper-node").remove();
        var sortedListElements = this.root.querySelectorAll(".eke-sortingElementItem");

        for (var index = 0; index < sortedListElements.length; index++) {
            let isCorrectHelperNode = document.createElement("span");
            isCorrectHelperNode.classList.add("show-for-sr", "is-correct-helper-node");
            isCorrectHelperNode.setAttribute("role", "text");
            var containedDiv = (sortedListElements[index] as HTMLElement);
            this.removeStyles(containedDiv);

            if (evaluated.success) {
                containedDiv.classList.add("exe-engine-check-correct");
                isCorrectHelperNode.innerHTML = __("Helyes megoldás");
                containedDiv.parentElement!.appendChild(isCorrectHelperNode);
            }
            else {
                if (evaluated.solution == null) {
                    containedDiv.classList.add("exe-engine-check-wrong");
                    isCorrectHelperNode.innerHTML = __("Helytelen megoldás");
                    containedDiv.parentElement!.appendChild(isCorrectHelperNode);
                }
                else {
                    if (+sortedListElements[index].attributes.getNamedItem("data-index")!.value == evaluated.solution[index]) {
                        containedDiv.classList.add("exe-engine-check-correct");
                        isCorrectHelperNode.innerHTML = __("Helyes megoldás");
                        containedDiv.parentElement!.appendChild(isCorrectHelperNode);
                    } else {
                        if (!this.isSNIexc) containedDiv.classList.add("exe-engine-check-wrong");
                        isCorrectHelperNode.innerHTML = __("Helytelen megoldás");
                        containedDiv.parentElement!.appendChild(isCorrectHelperNode);
                    }
                }
            }
        }
    }

    showCorrectSolution(solution: any): void {

        this.gotcorrectsolution = true;
        var sortedListElements = this.root.querySelectorAll(".eke-sortable-list-li");

        let temp = sortedListElements;//this.unorderedList.children;
        for (let index = 0; index < solution.length; index++) {
            for (let j = 0; j < temp.length; j++) {
                let currEl = sortedListElements[j].querySelector(".eke-sortingElementItem");
                if (!currEl) continue;
                let curr_idx = currEl.getAttribute("data-index");
                if (Number(curr_idx) == solution[index]) {
                    //AExerciseEngine.removeEvalStyle(temp[j]);
                    currEl.classList.remove("exe-engine-check-correct", "exe-engine-check-wrong")
                    currEl.classList.add("eke-engine-show-correct-bg");
                    this.unorderedList.appendChild(sortedListElements[j]);
                    break;
                }
            }
        }
        this.putNumberingOnList(true);
    }

    isUserReady(): boolean {
        var sortedListElements = this.root.querySelectorAll(".eke-sortingElementItem");
        for (var index = 0; index < sortedListElements.length; index++) {
            if (this.userInteraction && !this.gotcorrectsolution)
                return true
        }
        return false;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }
    removeStyles(element: HTMLElement): void {
        element.classList.remove("exe-engine-correct-bg", "exe-engine-wrong-bg", "eke-engine-show-correct-bg", "eke-engine-checked-bg", "exe-engine-check-correct", "exe-engine-check-wrong");
    }

    onClicked(ev: any) {
        var curr_element = ev.target as HTMLElement;
        this.removeStyles(curr_element);
    }
    generateList(list: any[], parent: HTMLUListElement) {
        var randomgroup = Math.floor(Math.random() * 1000);
        for (var index = 0; index < list.length; index++) {

            var currentLiElement = parent.appendChild(document.createElement("li"));
            currentLiElement.classList.add("eke-sortable-list-li");
            currentLiElement.setAttribute("tabindex", "0");
            if (this.horizontalSorting) {
                currentLiElement.classList.add("eke-sortable-list-li", "ui-sortable-handle");
            }
            var counterdiv = currentLiElement.appendChild(document.createElement("div"));
            counterdiv.classList.add("sortable-number");
            counterdiv.setAttribute("aria-hidden", "true");
            counterdiv.innerText = (index + 1).toString();
            var answerdiv = currentLiElement.appendChild(document.createElement("div"));
            answerdiv.classList.add("eke-sortingElementItem");
            let currIdx = this.originalIndexes[index];
            answerdiv.setAttribute("id", currIdx + "_" + randomgroup);
            answerdiv.setAttribute("data-index", currIdx.toString());
            let classList:string[] = [];

            let elementClassList: string[] = ["answer-div"];
            if (list[index].type == "image") {
                /*answerdiv.classList.add("image-answer-div", "answer-div");
                answerdiv.classList.add("cell", "columns");*/
                elementClassList.push("eke-sorting-drag-and-drop-image-answer");
            }
            else {
                elementClassList.push("string-answer-div");
            }
            let accessibilityNumber = document.createElement('span');
            accessibilityNumber.classList.add("accessibility-number", "show-for-sr");
            accessibilityNumber.innerHTML = (index + 1).toString();
            answerdiv.appendChild(accessibilityNumber);
            AExerciseEngine.displayAnswer(answerdiv, list[index], this.is_accessible, elementClassList, this);
            this.sortableItemsList.push(currentLiElement);
            answerdiv.addEventListener('click', this.onClicked.bind(this), false);
        }
    }

    /**
     * This function is responsible for the simulate sorting of elements with click and click.
     * We can click on the selected element and click on the target.
     * @param data An object with list of parameters:
     * sortingClass: active element class - string
     * sortableItems: array of sortable elements - elements
     */
    simulateSort(sortableItems: NodeList, sortingClass: string = 'ui-sortable-sorting') {
        let rootFunc = this;
        sortableItems.forEach(function (sortableItem: HTMLElement) {

            /* Add click event to all sortable item */
            sortableItem.addEventListener('click', function (this: HTMLElement, event: MouseEvent) {
                let eventTarget = event.target as HTMLElement;
                if (eventTarget.classList.contains('sortable-number')) eventTarget = (eventTarget.nextSibling! as any);
                let elementNumber = (eventTarget.previousSibling! as any).innerHTML; //kijelölt elem aktuális sorszáma
                let alertText = null;

                if (!eventTarget.classList.contains('exe-zoom-icon')) {
                    /* Add dragging class if not exists */
                    if (!this.classList.contains(sortingClass)) {
                        let currentItem = this;
                        let targetItem = rootFunc.root.querySelector('.' + sortingClass) as HTMLElement;

                        /* Remove all draggingClass */
                        sortableItems.forEach(function (element: HTMLElement) {
                            element.classList.remove(sortingClass);
                        });

                        alertText = elementNumber + __(". elem kijelölve");
                        /* Swarp item or select */
                        if (currentItem && targetItem) {
                            $('.sorting-alert-accessibility-div').remove();
                            let targetNumber = (targetItem.firstChild as any).innerText;
                            let currentNumber = (currentItem.firstChild as any).innerText;
                            rootFunc.swapElements(currentItem, targetItem);
                            rootFunc.putNumberingOnList(true);
                            alertText = __("{target}. és {current}. elem felcserélve", {target: targetNumber, current: currentNumber});
                            let changeAlertDiv = document.createElement("div");
                            let changeTextNode = document.createTextNode(alertText);
                            changeAlertDiv.appendChild(changeTextNode);
                            // changeAlertDiv.setAttribute("role", "alert");
                            changeAlertDiv.classList.add("show-for-sr", "change-accessibility-div");
                            targetItem.appendChild(changeAlertDiv);
                            changeAlertDiv.tabIndex = 2;
                            changeAlertDiv.focus();

                            setTimeout(function () { $('.change-accessibility-div').remove(); }, 200);

                        } else {
                            this.classList.add(sortingClass);
                            let alertDiv = document.createElement("div");
                            let textNode = document.createTextNode(alertText);
                            alertDiv.appendChild(textNode);
                            // alertDiv.setAttribute("role", "alert");
                            alertDiv.classList.add("show-for-sr", "sorting-alert-accessibility-div");
                            eventTarget.appendChild(alertDiv);
                            alertDiv.tabIndex = 0;
                            alertDiv.focus();
                        }
                    } else {
                        this.classList.remove(sortingClass);
                        $('.sorting-alert-accessibility-div').remove();
                        let div = document.createElement("div");
                        let text = document.createTextNode(elementNumber + __(". elem kijelölése megszűnt."));
                        div.appendChild(text);
                        div.classList.add("show-for-sr", "helperdiv");
                        eventTarget.appendChild(div);
                        div.tabIndex = 2;
                        div.focus();
                        setTimeout(function () { $('.helperdiv').remove(); }, 200);
                    }
                }
            });
        });
    }

    swapElements(obj1: HTMLElement, obj2: HTMLElement) {
        // create marker element
        let temp = document.createElement("div");
        if (obj1.parentNode && obj2.parentNode) {

            // insert temp where obj1 is
            obj1.parentNode.insertBefore(temp, obj1);
            // move obj1 to right before obj2
            obj2.parentNode.insertBefore(obj1, obj2);
        }

        if (temp.parentNode) {
            // move obj2 to right before where obj1 used to be
            temp.parentNode.insertBefore(obj2, temp);

            // remove temporary marker node
            temp.parentNode.removeChild(temp);
        }
    }

}