import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapCourseUserRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Képzés
     */
    course_id?: number;
    /**
     * Alkalmazás szintű felhasználó
     */
    sec_user_id?: number;
    /**
     * Sikeres
     */
    passed?: boolean|null;
    /**
     * Tanúsítvány sorszám
     *
     * Ez nem a teljes tanúsítvány szám, csak az éven és a típuson belüli szám.
     */
    cert_serial?: number /*int4*/|null;
    /**
     * Tanúsítvány szám
     *
     * Ez a teljes tanúsítvány szám.
     */
    cert_num?: string|null;
    /**
     * OnlyOffice mappa
     */
    oo_folder_id?: number;
    /**
     * Beadott DFHT KIP óraterv
     */
    kip_draft_id?: number|null;
    /**
     * Beadott DFHT alap/komplex óraterv
     */
    lesson_draft_id?: number|null;
    /**
     * Beadott foglalkozásterv
     */
    occupation_draft_id?: number|null;
    /**
     * Igazolás sorszám
     *
     * Ez nem a teljes igazolás szám, csak az éven és a típuson belüli szám.
     */
    ver_serial?: number /*int4*/|null;
    /**
     * Igazolás szám
     *
     * Ez a teljes igazolás szám.
     */
    ver_num?: string|null;
    /**
     * Egyéb értékek
     *
     * 'Melyik kontaktnapon volt jelen' és hasonlók
     */
    other_jsonb?: any|null;
    /**
     * Első kontaktnap
     *
     * Első kontaktnap
     */
    start_date?: string /*date?*/|null;
    /**
     * Utolsó kontaktnap
     *
     * Utolsó kontaktnap
     */
    end_date?: string /*date?*/|null;
    /**
     * Képzés kezdete
     *
     * Képzés kezdete
     */
    training_from?: string /*date?*/|null;
    /**
     * Képzés vége
     *
     * Képzés vége
     */
    training_to?: string /*date?*/|null;
    /**
     * Iktatószám
     *
     * Iktatószám
     */
    regno?: number|null;
    /**
     * Legkorábbi jelentkezés intézményen belülről
     *
     * Legkorábbi jelentkezés intézményen belülről
     */
    apply_after_internal?: string /*timestamp?*/|null;
    /**
     * Legkorábbi jelentkezés intézményen kívülről
     *
     * Legkorábbi jelentkezés intézményen kívülről
     */
    apply_after_external?: string /*timestamp?*/|null;
    /**
     * Legkésőbbi jelentkezés
     *
     * Legkésőbbi jelentkezés
     */
    application_deadline?: string /*timestamp?*/|null;
    /**
     * Résztvevők max. száma
     *
     * Résztvevők max. száma
     */
    max_participants?: number /*int4*/|null;
    /**
     * Megjegyzés résztvevőknek
     *
     * Megjegyzés résztvevőknek
     */
    participants_comment_html?: string|null;
    /**
     * Cím
     *
     * Cím
     */
    address?: string|null;
    /**
     * Képzés típus kódja
     *
     * Képzés típus kódja
     */
    training_type_code?: string|null;
    /**
     * Engedélyszám
     *
     * Engedélyszám
     */
    license_number?: string|null;
    /**
     * Alprogram azonosító
     *
     * Alprogram azonosító
     */
    subprogram_id?: number|null;
    /**
     * Alprogram kód
     *
     * Alprogram kód
     */
    subprogram_code?: string|null;
    /**
     * Alprogram név
     *
     * Alprogram név
     */
    subprogram_title?: string|null;
    /**
     * Képző bejelentkezési név
     *
     * Képző bejelentkezési név
     */
    teacher_login_name?: string|null;
    /**
     * Képző e-mail cím
     *
     * Képző e-mail cím
     */
    teacher_email?: string|null;
    /**
     * Képző teljes név
     *
     * Képző teljes név
     */
    teacher_fullname?: string|null;
    /**
     * OM azonosító
     *
     * OM azonosító
     */
    om_identifier?: string|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    institute_name?: string|null;
    /**
     * Feladatellátási hely név
     *
     * Feladatellátási hely név
     */
    institute_site_name?: string|null;
    /**
     * Feladatellátási hely száma
     *
     * Feladatellátási hely száma
     */
    site_identifier?: string|null;
    /**
     * Résztvevő bejelentkezési neve
     *
     * Résztvevő bejelentkezési neve
     */
    participant_login_name?: string|null;
    /**
     * Résztvevő e-mail címe
     *
     * Résztvevő e-mail címe
     */
    participant_email?: string|null;
    /**
     * Résztvevő teljes neve
     *
     * Résztvevő teljes neve
     */
    participant_fullname?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    wf_workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Állapot
     *
     * Folyamat állapot neve
     */
    wf_station_name?: string|null;
    /**
     * Folyamat állapot leírása
     *
     * Folyamat állapot leírása
     */
    wf_station_description?: string|null;
    /**
     * Állapot stílus
     *
     * Folyamat állapot stílusa, jsonb/css
     */
    wf_station_style?: any|null;
    /**
     * Folyamat al-státusz azonosító
     *
     * Folyamat al-státusz azonosító
     */
    wf_substation_id?: number|null;
    /**
     * Folyamat al-státusz
     *
     * Folyamat al-státusz
     */
    wf_substation_name?: string|null;
    /**
     * Folyamat al-státusz leírása
     *
     * Folyamat al-státusz leírása
     */
    wf_substation_description?: string|null;
    /**
     * Folyamat szöveges állapot kiegészítés
     *
     * Folyamat szöveges állapot kiegészítés
     */
    wf_status_text?: string|null;
    /**
     * Utolsó átmenet
     *
     * Utolsó átmenet
     */
    last_transition?: string /*timestamp?*/|null;
    /**
     * KSZR képzési engedély azonosító
     *
     * KSZR képzési engedély azonosító
     */
    training_permit_id?: number|null;
    /**
     * KSZR képzési engedély folyamat azonosító
     *
     * KSZR képzési engedély folyamat azonosító
     */
    training_permit_workflow_id?: number|null;
    /**
     * KSZR képzési engedély folyamat állapot
     *
     * KSZR képzési engedély folyamat állapot
     */
    training_permit_station_id?: number|null;
    /**
     * KSZR képzési engedély folyamat név
     *
     * KSZR képzési engedély folyamat név
     */
    training_permit_station_name?: string|null;
    /**
     * Résztvevő ellátási helye
     *
     * Résztvevő ellátási helye
     */
    participant_institute_site?: string|null;
}

/**
 *  kap.view_kap_course_user - Képzés részvevő
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewKapCourseUser extends View<IViewKapCourseUserRecord> {
    public static VIEW_INFO_ID = 2019111902;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_course_user'; }
    public static getTableNameForClass(): string|null { return 'course_user'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapCourseUser>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapCourseUserRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapCourseUserClassProxy extends ViewClassProxy<IViewKapCourseUserRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapCourseUserClassProxy = new ViewKapCourseUserClassProxy(ViewKapCourseUser);
registerViewClassProxy(viewKapCourseUserClassProxy);

