import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewTaskLogRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Importálási feladat
     */
    task_id?: number;
    /**
     * Importálási napló bejegyzés szintje
     */
    severity_id?: number;
    /**
     * Pozíció
     */
    position?: any|null;
    /**
     * Üzenet
     */
    message?: string;
    /**
     * Létrehozva
     *
     * A napló bejegyzés időpontja
     */
    created?: string /*timestamp?*/;
    /**
     * Szint
     *
     * Szint
     */
    severity_name?: string|null;
    /**
     * Szint stílus
     *
     * Szint stílus
     */
    severity_style?: any|null;
}

/**
 *  data_imp.view_task_log - Importálási feladat napló
 *
 *  A feladat újraindításakor kitörlődik!
 */

export default class ViewTaskLog extends View<IViewTaskLogRecord> {
    public static VIEW_INFO_ID = 2019022002;

    public static getSchemaNameForClass(): string { return 'data_imp'; }
    public static getViewNameForClass(): string { return 'view_task_log'; }
    public static getTableNameForClass(): string|null { return 'task_log'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewTaskLog>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewTaskLogRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewTaskLogClassProxy extends ViewClassProxy<IViewTaskLogRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewTaskLogClassProxy = new ViewTaskLogClassProxy(ViewTaskLog);
registerViewClassProxy(viewTaskLogClassProxy);

