import { Server } from '@framework/server/Server';
/**
 * A kimenő üzenetek létrehozásához (pushMessage metódus) használt típus.
 */
export interface IOutgoingMessage {
    subject: string;
    /** Melyik üzenetre válaszolt. Ez kizárólag olyan üzenet lehet, amit ő küldött vagy kapott. */
    reply_to_id: null | number;
    /** sec_user_id értékek tömbje, nem lehet üres. */
    recipient_ids: number[];
    body_html: string;
}

/**
 * Az IViewUserMessage.recipients ilyeneknek a tömbje.
 */
export interface IRecipient {
    sec_user_id: number;
    email: string;
    login_name: string;
    fullname: string;
    seen: string; /* timestamp */
}

export interface IMessengerNotificationInfo {
    // Nem olvasott üzenetek száma.
    unseen_cnt: number;
    // Az utolsó nem olvasott üzenet azonosítója.
    last_unseen_message_id: number | null;
}

export interface IMessengerSummaryInfo {
    // Kapott üzenet száma
    received: {  [sec_user_id: string] : {sec_user_id: number, received_count: number } };
    // Elküldött üzenetek száma
    sent: {  [creation_user_id: string] : {creation_user_id: number, sent_count: number } };
}

export interface IMessageTreeNode {
    id: number; // usr.message.id
    is_active : boolean; // usr.message.is_active
    subject : string;
    creation_time: string; // usr.message.creation_time
    creation_user_id: string; // usr.message.creation_user_id
    email : string|null; // sys.sec_user.email, when the user allowed to see it
    login_name : string|null; // sys.sec_user.login_name, when the user allowed to see it
    fullname: string; // sys.person.fullname
    replies : IMessageTreeNode[];
    level? : number;
    body_html?: string;
}
export interface IMessageOperationResult {
    message_ids: number[];
}


export interface ITrainerReceivedMessage {
    time:string;
    courseId:number;
    sender: string; // a küldő teljes neve    
}

export interface ITrainerSentMessage {
    time:string;
    courseId:number;
    recipients: string[]; // a címzettek teljes nevei
}

export interface ITrainerMessageEvents {
    courses: { [courseId : number] : string  }; // Kurzus nevek, view_kap_course.displayvalue
    received:  ITrainerReceivedMessage[];
    sent:  ITrainerSentMessage[];
}

/**
 * Low level messenger API.
 * 
 * Please use messengerModule instead!
 */
export default class MessengerAPI {
    server: Server;

    constructor(server: Server) {
        this.server = server;
    }

    /**
     * Üzenet küldése
     * 
     * @param tableInfoId melyik táblázathoz
     * @param recordId melyik rekordhoz
     * @param message a kimenő üzenet
     * @returns Az az új bejövő üzenet, amit a kimenő üzenet mentése után a getMessages() visszaadna ehhez a küldéshez.
     * 
     * TODO: any helyett itt IViewUsrMessage kell!
     */
    public async pushMessage(tableInfoId: number | null, recordId: number | null, replyToId: number | null, message: IOutgoingMessage): Promise<any> {
        return this.server.post<any>(
            'messenger',
            {
                operation: 'push_message',
                table_info_id: tableInfoId,
                rec_id: recordId,
                reply_to_id: replyToId,
                message
            }
        );
    }

    public async setSeen(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.server.post<IMessageOperationResult>(
            'messenger',
            {
                operation: 'set_seen',
                message_ids: messageIds
            }
        );
    }

    public async clearSeen(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.server.post<IMessageOperationResult>(
            'messenger',
            {
                operation: 'clear_seen',
                message_ids: messageIds
            }
        );
    }

    public async setDeleted(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.server.post<IMessageOperationResult>(
            'messenger',
            {
                operation: 'set_deleted',
                message_ids: messageIds
            }
        );
    }

    public async clearDeleted(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.server.post<IMessageOperationResult>(
            'messenger',
            {
                operation: 'clear_deleted',
                message_ids: messageIds
            }
        );
    }

    public async setPurged(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.server.post<IMessageOperationResult>(
            'messenger',
            {
                operation: 'set_purged',
                message_ids: messageIds
            }
        );
    }

    /* Küldő oldalon */

    public async setSenderSeen(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.server.post<IMessageOperationResult>(
            'messenger',
            {
                operation: 'set_sender_seen',
                message_ids: messageIds
            }
        );
    }

    public async clearSenderSeen(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.server.post<IMessageOperationResult>(
            'messenger',
            {
                operation: 'clear_sender_seen',
                message_ids: messageIds
            }
        );
    }

    public async setSenderDeleted(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.server.post<IMessageOperationResult>(
            'messenger',
            {
                operation: 'set_sender_deleted',
                message_ids: messageIds
            }
        );
    }

    public async clearSenderDeleted(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.server.post<IMessageOperationResult>(
            'messenger',
            {
                operation: 'clear_sender_deleted',
                message_ids: messageIds
            }
        );
    }

    public async setSenderPurged(messageIds: number[]): Promise<IMessageOperationResult> {
        return this.server.post<IMessageOperationResult>(
            'messenger',
            {
                operation: 'set_sender_purged',
                message_ids: messageIds
            }
        );
    }

    public async getMessageTree(messageId: number, includeBody: boolean, minCreationTime ?: Date|number|null, maxCreationTime ?: Date|number|null): Promise<IMessageTreeNode> {
        if (minCreationTime && typeof minCreationTime == "object") {
            minCreationTime = minCreationTime.getTime();
        }
        if (maxCreationTime && typeof maxCreationTime == "object") {
            maxCreationTime = maxCreationTime.getTime();
        }
        return this.server.post<any>(
            'messenger',
            {
                operation: 'get_message_tree',
                message_id: messageId,
                include_body: includeBody,
                minCreationTime,
                maxCreationTime
            }
        );
    }

    public async getMessagesSummary(tableInfoId: number, recordId: number): Promise<IMessengerSummaryInfo> {
        return this.server.post<any>(
            'messenger',
            {
                operation: 'get_messages_summary',
                table_info_id: tableInfoId,
                rec_id: recordId
            }
        );
    }

    public async getNotificationInfo(siteId?: number): Promise<IMessengerNotificationInfo> {
        return this.server.post<any>(
            'messenger',
            {
                operation: 'get_notification_info',
                site_id: siteId,
            }
        );
    }


    public async getTrainerMessageEvents(userId: number, courseIds ?: number[], messageFromDate ?: Date, messageToDate ?: Date) : Promise<ITrainerMessageEvents> {
        const params = {
            operation: "get_trainer_message_events",
            userId, courseIds
        };
        if (messageFromDate !== undefined) {
            params["message_from_date"] = messageFromDate.getTime();
        }
        if (messageToDate !== undefined) {
            params["message_to_date"] = messageToDate.getTime();
        }
        return this.server.post<ITrainerMessageEvents>("messenger", params);    
    }    


}
