import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CollectionMenu } from '@src/component/collection/CollectionMenu';

type SectionCollectionMenuProps = {
    element: string | HTMLElement;
    recId: number;
    tableInfoId: number;
    embedded: boolean;
}

type SectionCollectionMenuState = {
    mouseOverSection: boolean;
    mouseOverMenu: boolean;
    top?: number;
    bottom?: number;
    right: number;
}

export class SectionCollectionMenu extends React.Component<SectionCollectionMenuProps, SectionCollectionMenuState> {

    element: HTMLElement | null;

    enterListener: any;
    moveListener: any;
    leaveListener: any;

    hideTimeout: any;

    state: SectionCollectionMenuState = {
        mouseOverSection: false,
        mouseOverMenu: false,
        top: 0,
        right: 0,
    }

    componentDidMount() {
        if (typeof this.props.element === "string") {
            this.element = document.getElementById(this.props.element);
        } else {
            this.element = this.props.element;
        }

        if (this.element) {
            this.enterListener = this.element.addEventListener("mouseenter", this.onMouseEnter.bind(this, { mouseOverSection: true }));
            this.moveListener = this.element.addEventListener("mousemove", this.onMouseMove.bind(this, { mouseOverSection: true }));
            this.leaveListener = this.element.addEventListener("mouseleave", this.onMouseLeave.bind(this, { mouseOverSection: false }));
        }
        this.forceUpdate();
    }

    componentWillUnmount() {
        if (this.element) {
            if (this.enterListener) this.element.removeEventListener("mouseenter", this.enterListener);
            if (this.moveListener) this.element.removeEventListener("mousemove", this.moveListener);
            if (this.leaveListener) this.element.removeEventListener("mouseleave", this.leaveListener);
        }

        this.setState({ mouseOverMenu: false, mouseOverSection: false });
    }

    private onMouseEnter(stateProps: any, e: MouseEvent) {
        if (this.element) {
            if (this.hideTimeout) clearTimeout(this.hideTimeout);

            const firstChild = this.element.querySelector(".row.column") ? this.element.querySelector(".row.column")!.firstElementChild || this.element : null;

            if (firstChild) {
                const rect = firstChild.getBoundingClientRect();
                const style = window.getComputedStyle(firstChild);

                var paddingRight = 0;
                if (style.paddingRight) paddingRight = Number(style.paddingRight.replace("px", ""));

                this.setState({
                    ...stateProps,
                    top: 0,//rect.bottom + window.pageYOffset,//(e.clientY-25)+window.pageYOffset,//
                    right: (window.innerWidth - rect.right) + window.pageXOffset - paddingRight - 45
                });
            }
        }
    }
    private onMouseMove(stateProps: any, e: MouseEvent) {
        if (this.element) {
            if (this.hideTimeout) clearTimeout(this.hideTimeout);

            const firstChild = this.element.querySelector(".row.column") ? this.element.querySelector(".row.column")!.firstElementChild || this.element : null;

            if (firstChild) {
                const rect = firstChild.getBoundingClientRect();
                const style = window.getComputedStyle(firstChild);

                var paddingRight = 0;
                if (style.paddingRight) paddingRight = Number(style.paddingRight.replace("px", ""));


                var centerY = (rect.top + ((rect.bottom - rect.top) / 2)) + window.pageYOffset

                if (e.clientY + window.pageYOffset <= centerY || (rect.bottom - rect.top) < 500) {
                    this.setState({
                        ...stateProps,
                        top: 15,//rect.top + window.pageYOffset,
                        bottom: null,
                        right: (window.innerWidth - rect.right) + window.pageXOffset - paddingRight - 45
                    });
                }
                else if (e.clientY + window.pageYOffset > centerY) {
                    this.setState({
                        ...stateProps,
                        top: null,
                        bottom: 15,//rect.bottom,//(rect.bottom + window.pageYOffset) - 45,
                        right: (window.innerWidth - rect.right) + window.pageXOffset - paddingRight - 45
                    });
                }

            }
        }
    }

    private onMouseLeave(stateProps: any, e: React.MouseEvent | MouseEvent) {

        if (this.element) {
            
            const rect = this.element.getBoundingClientRect();
            if ((e.clientY + window.pageYOffset) < (rect.top + window.pageYOffset) || (e.clientY + window.pageYOffset) > (rect.bottom + window.pageYOffset)) {
                if (this.hideTimeout) clearTimeout(this.hideTimeout);

                this.setState(stateProps);
                this.hideTimeout = setTimeout(() => {

                }, 300);
            }
        }
    }

    render() {

        return this.element ? ReactDOM.createPortal(
            //   <div onMouseEnter={this.onMouseEnter.bind(this, {mouseOverMenu: true})} 
            <div
                onMouseLeave={this.onMouseLeave.bind(this, { mouseOverMenu: false })}
                className="section-collection-menu"
                style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "5px",//"0px 50% 50% 0%",
                    //boxShadow: "-1px 1px 1px 1px rgba(0,0,0,0.5)",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: this.state.top ? this.state.top + "px" : "",
                    bottom: this.state.bottom ? this.state.bottom + "px": "",
                    right: this.state.right + "px",
                    zIndex: 799,
                    transition: "0.3s opacity",
                    opacity: this.state.mouseOverSection || this.state.mouseOverMenu ? 1 : 0,
                }}>

                <CollectionMenu tableInfoId={this.props.tableInfoId} recId={this.props.recId} isOnBook={true} />
            </div>, this.element//document.body
        ):null
    }

}
