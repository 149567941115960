import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapKszrTrainerRecord {
    /**
     * Felhasználó azonosítója
     *
     * Felhasználó azonosítója
     */
    id?: number;
    /**
     * Bejelentkezési név
     *
     * Bejelentkezési név
     */
    login_name?: string|null;
    /**
     * E-mail cím
     *
     * E-mail cím
     */
    email?: string|null;
    /**
     * Aktív
     *
     * Aktív
     */
    is_active?: boolean|null;
    /**
     * Személy azonosító
     *
     * Személy azonosító
     */
    person_id?: number|null;
    /**
     * Teljes név
     *
     * Teljes név
     */
    fullname?: string|null;
    /**
     * Képzés típus kódok
     *
     * Képzés típus kódok
     */
    assigned_training_type_codes?: string|null;
}

/**
 *  kap.view_kap_kszr_trainer - KAP KSZR Képző
 */

export default class ViewKapKszrTrainer extends View<IViewKapKszrTrainerRecord> {
    public static VIEW_INFO_ID = 2019112503;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_kszr_trainer'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapKszrTrainer>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapKszrTrainerRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapKszrTrainerClassProxy extends ViewClassProxy<IViewKapKszrTrainerRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapKszrTrainerClassProxy = new ViewKapKszrTrainerClassProxy(ViewKapKszrTrainer);
registerViewClassProxy(viewKapKszrTrainerClassProxy);

