import * as React from 'react';
import CollectionCrud, { ICollectionRecord } from '@src/framework/crud/usr/CollectionCrud';

import { app } from '@src/index';
import { me } from '@framework/server/Auth';
import { PATH_CONTENT_STORE } from '@src/Routes';
import { Link } from 'react-router-dom';
import { ListItemImage, ListItem, ListItemText, ListItemMenu, List } from '../ui/List';
import { formatDate } from '@src/Util';
import { confirmDialog, promptDialog } from '../Dialog';
import { BubbleLoader } from 'react-css-loaders';
import { __ } from '@src/translation';

export interface ICollectionListProps {

}

interface ICollectionListState {
    loading : boolean;
    collections: ICollectionRecord[];
    item: ICollectionRecord;
}

const EMPTY_ITEM : ICollectionRecord = {
    
}

export default class CollectionList extends React.Component<ICollectionListProps, ICollectionListState> {
    constructor (props: ICollectionListProps) {
        super(props);
        this.state = {
            loading: false,
            collections: [],
            item: {...EMPTY_ITEM},
        };
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        this.setState({loading:true});
        try {
            let items :ICollectionRecord[] = [];
            if (me && me.id) {
                items = await CollectionCrud.list({
                    filter:{
                        is_active: true,
                        owner_id: me.id
                    },
                    order_by: "title"
                });
            }
            this.setState({loading:false, collections: items});
        } catch (error) {
            this.setState({loading:false});
            app.showErrorFromJsonResult(error);
        }
    }
    
    private onDeleteCollection = async (itemIndex : number) => {
        const item = this.state.collections[itemIndex];
        if (await confirmDialog(item.title || "", __('Biztos benne, hogy törölni akarja ezt a gyűjteményt?'), __("Törlés"))) {
            this.setState({loading:true})
            try {
                await CollectionCrud.deleteById(item.id!);
                this.setState(
                    {item: {...EMPTY_ITEM}}, 
                    this.asyncReload
                );
            } catch (error) {
                this.setState({loading:false});
                app.showErrorFromJsonResult(error);
            }
        }

    }
    
    private onRenameCollection = async (itemIndex : number) => {
        const item = this.state.collections[itemIndex];

        const newTitle = await promptDialog(__("Gyűjtemény átnevezése"), "", item.title);
        if (newTitle) {
            try {
                await new CollectionCrud({id: item.id, title: newTitle}).put();
                this.asyncReload();
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }

    }

    private onAddNewCollection = async() => {
        const newTitle = await promptDialog( __("Új gyűjtemény létrehozása"), __("Új gyűjtemény neve:"));
        if (newTitle) {
            try {
                await new CollectionCrud({title: newTitle}).put();
                this.asyncReload();
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />
        }
        return <><div className="column">
                <button className="button small" onClick={this.onAddNewCollection}>
                    <i className="fa fa-plus"/>&nbsp; {__("Új gyűjtemény hozzáadása")}
                </button>
                <h4>{__("Gyűjteményeim")}</h4>
                {
                    this.state.collections.length === 0 && 
                    <div className="callout">
                        <h5>{__("Jelenleg nincsenek gyűjteményeid.")}</h5>
                        <p>
                           {__("Gyűjteményt létrehozni a fentebb található \"Új gyűjtemény létrehozása\" gombbal lehet.")} 
                        </p>
                        <p>
                           {__("Gyűjteményhez elemeket hozzáadni a (hozzáadás) gombra kattintva lehet a könyvekben, feladattárban, médiatárban.")} 
                        </p>
                    </div>
                }
                <List>
                    {
                        this.state.collections.map((item, index) => 
                            <ListItem key={item.id}>
                                <Link to={PATH_CONTENT_STORE + "/gyujtemeny/" + item.id}>
                                    <ListItemImage src={"/img/IKON_SET/FILE/collection.svg"}/>
                                </Link>
                                <ListItemText>
                                    <Link to={PATH_CONTENT_STORE + "/gyujtemeny/" + item.id}>
                                        <strong>{item.title}</strong>
                                    </Link>
                                    <br />
                                    <span className="content-item-list__small">{__("Létrehozva")}: {formatDate(item.creation_time)}</span>
                                </ListItemText>

                                <ListItemMenu mainItems={[
                                    {
                                        icon: <i className="fa fa-fw fa-font" />,
                                        type: "secondary",
                                        name: __("Átnevezés"),
                                        onClick: () => this.onRenameCollection(index)
                                    },
                                    {
                                        icon: <i className="fa fa-fw fa-trash" />,
                                        type: "alert",
                                        name: __("Törlés"),
                                        onClick: () => this.onDeleteCollection(index)
                                    },
                                ]}
                                >
                                
                                </ListItemMenu>

                            </ListItem>
                        )
                    }
                </List>
                
            </div>

        </>
    }
}
