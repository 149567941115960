import { IAuthConfig, OAUTH_SERVER_REVOKE_PATH } from '@src/framework/server/Auth';
import obtainServer from '@framework/server/Server';

const URL = 'userSessions';

export interface IServerAccessToken {
    access_token : string;
    client_id : string;
    created_at : string;
    expires_at : string;
    last_seen: string;
    only_office_access_token : string;
    refresh_token : string;
    scopes : string[];
    sid : string;
    token_type : string;
    user_id : number;
    remote_ip : string|null;
    user_agent : string|null;
}

export interface ISessionUserInfo {
    id : number;
    login_name: string;
    email : string;
    fullname: string;
    school_no : number|null;
    school_title : string|null;
}

export interface ISchoolSummary {
    id : number|null;
    title: number|null;
    tanar: number;
    diak: number;
    total: number;
}

export interface ISessionsResult {
    tokens: IServerAccessToken[];
    users: { [userId:number] : ISessionUserInfo };
    schoolSummary : ISchoolSummary[];
}

export default class UserSessionsAPI
 {  
    public static async getUserSessions(userId: number) : Promise<ISessionsResult> {
        return obtainServer().get<ISessionsResult>(URL+'/'+userId);
    }

    public static async getLastSeenSessions() : Promise<ISessionsResult> {
        return obtainServer().get<ISessionsResult>(URL+'/lastSeen');
    }

    public static async revokeTokenLocal(accessToken: string) : Promise<any> {
        return obtainServer().post<IServerAccessToken[]>(URL, 
            {operation:"revoke_token", access_token: accessToken});
    }

    public static async revokeTokenGlobal(accessToken: string, authConfig: IAuthConfig) : Promise<any> {
        const params = {
            'token': accessToken,
            'token_type_hint': 'access_token',
            'global': true
        };
        const postParams = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');
        const response = await fetch(
            authConfig.oauth_server_root_url + OAUTH_SERVER_REVOKE_PATH, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: postParams
            }
        );
        return await response.json();
    }

}
