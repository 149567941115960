import { MEDIA_TYPE_IMAGE, MEDIA_TYPE_SOUND, MEDIA_TYPE_VIDEO, MEDIA_TYPE_IMAGE_MAP } from "@src/framework/file/FileActions";
export function  GetMediaTypeFromId (media_type_id?:number|null ) {
   
    if(media_type_id&& media_type_id!=undefined&&media_type_id!=null)
    {
        let type="";
        if(media_type_id && media_type_id==MEDIA_TYPE_IMAGE) type="image";
        if(media_type_id && media_type_id==MEDIA_TYPE_SOUND) type="audio";
        if(media_type_id && media_type_id==MEDIA_TYPE_IMAGE_MAP) type="map";
        if(media_type_id && media_type_id==MEDIA_TYPE_VIDEO) type="video";
        return type;
    }
    else return "";
    }