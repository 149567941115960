//import { log } from "util";

import './style.css';
import { ExerciseBaseClass } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { EKEFillGapDnDServer } from './EKEFillGapDnDServer';

export interface FillGapData extends ExerciseBaseClass {
    gap_text: string;
    answers: string[];
    keywords: string;
    infinite_elements: boolean;
}

type FillGapUserSolution = {
    answer: number[];
}

export class EKEFillGapDnDExerciseEngine extends AExerciseEngine {

    private dndAreaList: HTMLDivElement[] = [];
    private answerList: any[] = [];
    private answerDivList: any[] = [];
    private infinite_elements: boolean = false;


    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        this.dndAreaList = [];
        this.answerList = [];
        this.answerDivList = [];
        let exercise: FillGapData = params.exercise;
        this.infinite_elements = exercise.infinite_elements;

        this.root.classList.add("eke-fillgap-dnd");
        this.root.classList.add("eke-fillgap-dnd-bigp");

        let gridDiv = this.root.appendChild(document.createElement("div"));
        gridDiv.classList.add("exercise-wrapper");

        
        this.setIllustration(exercise,gridDiv); 

        if (!exercise.gap_text)
            return;

        let pattern = /#\d+#/g;
        let text = exercise.gap_text.replace(/\r/g, "");
        text = text.replace(/\n/g, "<br>");
        let modelIndex: number = 0;
        let result = null;

        while ((result = pattern.exec(text))) {
            modelIndex = +result[0].split('#')[1];
            let curr_dnd_area = document.createElement("div");
            curr_dnd_area.classList.add("eke-fillgap-dnd-area");
            curr_dnd_area.setAttribute("id", "fill_gap_area_" + modelIndex);
            text = this.replaceBetween(result.index, result.index + result[0].length, curr_dnd_area, text);
        }

        /*
        let exeEngine = this.root.closest('.exe-engine') as HTMLElement;
        let engineRGBColor = '';
        if (exeEngine && exeEngine.style.backgroundColor) {
            engineRGBColor = exeEngine.style.backgroundColor.replace('rgb(','').replace(')','');
        }
        */

        let engineHexColor = this.exercise.backgroundStyle.backgroundColor;

        let areaWrap = gridDiv.appendChild(document.createElement("div"));
        areaWrap.classList.add('area-wrap');

        let areaShadowA = areaWrap.appendChild(document.createElement("div"));
        areaShadowA.classList.add("area-shadow");
        areaShadowA.setAttribute('data-shadow-a', '');

        let areaShadowB = areaWrap.appendChild(document.createElement("div"));
        areaShadowB.classList.add("area-shadow");
        areaShadowB.setAttribute('data-shadow-b', '');

        let engineRGBColor = this.hexToRgb(engineHexColor);
        if (engineRGBColor) {
            let engineRGBColorString = engineRGBColor.r + ',' + engineRGBColor.g + ',' + engineRGBColor.b;
            areaShadowA.style.background = 'linear-gradient(to bottom, rgba(' + engineRGBColorString + ',1) 0%,rgba(' + engineRGBColorString + ',0) 100%)';
            areaShadowB.style.background = 'linear-gradient(to top, rgba(' + engineRGBColorString + ',1) 0%,rgba(' + engineRGBColorString + ',0) 100%)';
        }

        let areaContainer = areaWrap.appendChild(document.createElement("div"));
        areaContainer.classList.add("area-container");

        // append the text to a div eke-fillgap-dnd-p
        let gap_text_container = document.createElement("p");
        gap_text_container.classList.add("eke-fillgap-dnd-p");
        gap_text_container.id = "eke-fillgap-dnd-p";
        areaContainer.appendChild(gap_text_container).innerHTML = text;

        let curr_area_list = Array.from(this.root.querySelectorAll('#eke-fillgap-dnd-p .eke-fillgap-dnd-area'));
        curr_area_list.forEach((s: any) => this.dndAreaList.push(s as HTMLDivElement));
        curr_area_list.forEach((s: any) => {
            ($(s) as any).droppable({
                accept: ".answer-div",
                classes: {
                    "ui-droppable-active": "ui-state-active",
                    "ui-droppable-hover": "ui-state-hover"
                },
                drop: function (event: any, ui: any) {
                    $(this)
                        .addClass("ui-state-highlight")
                },
                tolerance: "pointer"
            });
        });



        let answerWrap = gridDiv.appendChild(document.createElement("div"));
        answerWrap.classList.add('answer-wrap');
        let answerControls = answerWrap.appendChild(document.createElement("div"));
        answerControls.classList.add("answer-controls");

        let answersdiv = answerWrap.appendChild(document.createElement("div"));
        answersdiv.classList.add("answer-container");
        answersdiv.setAttribute("id", "fill-gap-answer-list");

        for (let index = 0; index < exercise.answers.length; index++) {
            let answerdiv = answersdiv.appendChild(document.createElement("div"));
            answerdiv.classList.add("answer-div");
            answerdiv.setAttribute("draggable", "true");
            answerdiv.setAttribute("id", exercise.answers[index] + String(index));
            answerdiv.setAttribute("data-index", index + "");
            answerdiv.innerText = exercise.answers[index];
            // answerdiv.addEventListener('dragstart', this.drag, false);
            //answerdiv.ondblclick = this.onClick.bind(this); //Touchpunch lib valamiért fire-el egy clicke eventet drop után, amivel azonnal vissza dobja a kezdeti helyre a behúzott válaszokat.
            this.answerList.push(exercise.answers[index]);
            this.answerDivList.push(answerdiv);

            /* Create draggable elements */
            if (!this.isReplay) {
                ($(answerdiv) as any).draggable({
                    start: this.drag.bind(this),
                    stop: this.drop.bind(this),
                    containment: this.root,
                    helper: 'clone',
                    appendTo: this.root
                });
            }

        }

        /* Click-to-click simulation */
        if (!this.isReplay) {
            AExerciseEngine.simulateDrag({
                draggableItems: this.answerDivList,
                clickArea: this.root
            });
        }

        this.dndAreaList.push(answersdiv);

        let answerLeftButton = answerControls.appendChild(document.createElement("button"));
        answerLeftButton.classList.add("control", "answer-control-left");

        let answerRightButton = answerControls.appendChild(document.createElement("button"));
        answerRightButton.classList.add("control", "answer-control-right");


        answerRightButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answersdiv, 'right'), false);
        answerLeftButton.addEventListener("click", AExerciseEngine.xMove.bind(this, answersdiv, 'left'), false);
    }

    getUserSolution() {
        let result: any[] = [];
        var answerdivListCopy: any[] = [];
        //making a copy of the srting values
        for (let index = 0; index < this.answerDivList.length; index++) {
            answerdivListCopy[index] = this.answerDivList[index].innerText;
        }

        for (let index = 0; index < this.dndAreaList.length - 1; index++) {
            let element = this.dndAreaList[index].childNodes[0] as HTMLDivElement;
            if (element != null) {
                for (let ndx = 0; ndx < answerdivListCopy.length; ndx++) {
                    if (answerdivListCopy[ndx] == element.innerText) { //comparing values
                        result.push(ndx);
                        if (!this.infinite_elements) {   //we need this because of duplications. when match detected, change the value to get the next string with the same value
                            answerdivListCopy[ndx] = "#EMPTY ITEM#";
                            break;
                        }

                    }
                }
            } else {
                result.push(-1);
            }
        }
        let solution = { answer: result, fullmatch: true };
        return solution;
    }


    receiveEvaluation(evaluated: Evaluated): void {
        //this.answerDivList.forEach((d:any) => AExerciseEngine.removeEvalStyle(d as HTMLElement));
        let answerContainer:HTMLElement|null = this.root.querySelector(".answer-container");
        if (evaluated.success) { //if success, apply correct class on all element
            for (let index = 0; index < this.answerDivList.length; index++) {
                let htmlelement = this.answerDivList[index] as HTMLElement;
                AExerciseEngine.removeEvalStyle(htmlelement);
                htmlelement.classList.add("exe-engine-check-correct");
            }
        } else {
            if (evaluated.solution != null) {
                for (let index = 0; index < evaluated.solution.length; index++) {
                    if (this.dndAreaList[index].childNodes.length > 0) {
                        let mathjaxmatch: boolean = false;
                        //Check if we have a latex mathematical expression
                        var splittedAnswer = this.answerList[evaluated.solution[index]];
                        if (splittedAnswer.charAt(0) == "$" && splittedAnswer.slice(-1) == "$") {
                            splittedAnswer = splittedAnswer.slice(1, -1);
                            var splittedContent = this.dndAreaList[index].textContent;
                            if (splittedContent) splittedContent = splittedContent.slice(-splittedAnswer.length);
                            if (splittedAnswer == splittedContent) mathjaxmatch = true;
                        }
                        //apply correct class
                        if ((this.dndAreaList[index].textContent == this.answerList[evaluated.solution[index]]) || mathjaxmatch) {
                            let htmlelement = this.dndAreaList[index].childNodes[0] as HTMLElement;
                            AExerciseEngine.removeEvalStyle(htmlelement);
                            htmlelement.classList.add("exe-engine-check-correct");
                        }
                        else if (this.isReplay) //if its replay we dont drop it
                        {
                            let htmlelement = this.dndAreaList[index].childNodes[0] as HTMLElement;
                            AExerciseEngine.removeEvalStyle(htmlelement);
                            if (!this.isSNIexc)
                                htmlelement.classList.add("exe-engine-check-wrong");
                        }
                        else { //not correct, drop the element back
                            let htmlelement = this.dndAreaList[index].childNodes[0] as HTMLElement;
                            htmlelement.classList.remove("dropped");
                            this.dndAreaList[index].innerText = "";
                            AExerciseEngine.removeEvalStyle(htmlelement);
                            if (!this.isSNIexc)
                                htmlelement.classList.add("exe-engine-check-wrong");

                            if (this.infinite_elements)
                                htmlelement.remove();
                            else
                                //(this.root.querySelector("#fill-gap-answer-list") as HTMLElement).appendChild(htmlelement);
                                AExerciseEngine.moveElementBack(htmlelement, answerContainer!, this.root);
                        }
                    }
                }
            }
        }
    }

    showCorrectSolution(solution: any): void {
        this.removePlaceHolders(this.root);
        if (this.infinite_elements) {
            for (const area of this.dndAreaList) {
                if (area.children.length > 0) area.children[0].remove();
            }
        }

        for (let index = 0; index < solution.length; index++) {
            if (this.dndAreaList[index].innerText == "") {
                let element = this.answerDivList[solution[index]];

                if (element.classList.contains("exe-engine-correct-bg")) {
                    element = this.answerDivList.find(x => x.innerText == this.answerDivList[solution[index]].innerText);

                }

                if (this.infinite_elements) {
                    //element = element.cloneNode(true);
                    element = document.createElement("div");
                    element.classList.add("answer-div");
                    element.setAttribute("draggable", "true");
                    element.innerText = this.answerList[solution[index]];

                }
                this.dndAreaList[index].appendChild(element);
                AExerciseEngine.removeEvalStyle(element as HTMLElement);
                element.classList.add("dropped");
                element.classList.add("eke-engine-show-correct-bg");

                (this.dndAreaList[index].childNodes[0] as HTMLElement).classList.add("eke-engine-show-correct-bg");
            }
        }
    }

    isUserReady(): boolean {
        var result: any = this.getUserSolution();
        for (let index = 0; index < result.answer.length; index++) {
            if (result.answer[index] != -1)
                return true;
        }

        return false;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

    replaceBetween(start: number, end: number, what: HTMLElement, str: string): string {
        return str.substring(0, start) + what.outerHTML + str.substring(end);
    }

    allowDrop(ev: any) {
        ev.preventDefault();
    }

    drop(ev: any) {
        AExerciseEngine.drop(ev, this.dndAreaList, this.infinite_elements, this);
        if (this.isSNIexc) this.SNIEvaluation(EKEFillGapDnDServer);
    }


    drag(ev: any) {
        // ev.dataTransfer.setData("text", ev.target.id);
        let parentDiv: HTMLElement = (ev.target.parentElement as HTMLElement);
        if (parentDiv.classList.contains("answer-container")) {
            if ((this.simple_style || this.isSNIexc) && !this.infinite_elements) {
                this.cloneAnswerElement(ev.target, parentDiv);
            }

        }
    }

    dropElementsBack(): void {
        for (let index = 0; index < this.dndAreaList.length; index++) {
            if (this.dndAreaList[index].childNodes.length > 0) {
                let htmlelement = this.dndAreaList[index].childNodes[0] as HTMLElement;
                htmlelement.classList.remove("dropped");
                AExerciseEngine.removeEvalStyle(htmlelement);
                this.dndAreaList[index].innerText = "";
                (this.root.querySelector("#fill-gap-answer-list") as HTMLElement).appendChild(htmlelement);
            }
        }

    }

    hexToRgb(hex: string) {
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }
}