import * as React from "react";
import ViewKapCourseTrainerInvitationAnswer, { IViewKapCourseTrainerInvitationAnswerRecord } from "@src/framework/view/kap/ViewKapCourseTrainerInvitationAnswer";
import ReactTable, { RowInfo, Column, TableProps } from "react-table";
import { __ } from "@src/translation";
import { Link } from "react-router-dom";
import { getWfStateCell } from "@src/component/wf/wf_util";
import { getReactTableLabels } from "@src/framework/i18n";
import PermissionPage from "@src/framework/PermissionPage";
import { ViewPermissionType } from "@src/framework/security/PermissionCache";
import { Accordion, AccordionItem } from "@src/component/ui/Accordion";
import { formatDateWithoutTime, formatDate } from "@src/Util";
import { app } from "@src/index";
import { debounce } from 'lodash'
import { StringSearchKind } from "@src/framework/crud/Crud";
import { me } from "@src/framework/server/Auth";
import { Dialog } from "@src/component/Dialog";
import CourseTrainerInvitationAnswerCrud from "@src/framework/crud/kap/CourseTrainerInvitationAnswerCrud";
import CourseTrainerInvitationAnswerCommentCrud, { ICourseTrainerInvitationAnswerCommentRecord } from "@src/framework/crud/kap/CourseTrainerInvitationAnswerCommentCrud";
import ViewCourseTrainerInvitationAnswerComment, { IViewCourseTrainerInvitationAnswerCommentRecord } from "@src/framework/view/kap/ViewCourseTrainerInvitationAnswerComment";
import CrudSelectComponent from "@src/framework/forms/crudselect";
import { trainingTypeCrudClassProxy } from "@src/framework/crud/kap/TrainingTypeCrud";
import { stationCrudClassProxy } from "@src/framework/crud/wf/StationCrud";
import { WF_STATION_KSZR_TRAINER_INVITE_WORKFLOW_ID } from "@src/Const";
import { Breadcrumbs } from "@src/component/Breadcrumbs";
import { courseModule } from "../courseModule";

const CLOSED_WF_STATION_ID = 25408797515;
type CourseInvitationsState = {
    searchParams: IInvitationSeachParams,
    invitationList: IViewKapCourseTrainerInvitationAnswerRecord[],
    selectedWorkflowId: number | null,
    loading: boolean,
    selectedTrainingTypeId: number | null,
    selectedSite?: number | null,
    showClosed?: boolean,
    selectedInvitationId?: number,
    response?: boolean,
    response_message?: string,
    showResponseDialog: boolean,
    showCommentsForAnswerId: number | null,
    comments: IViewCourseTrainerInvitationAnswerCommentRecord[],
    showCommentsDialog: boolean
}

interface IInvitationSeachParams {
    filters: any[],
    sorted: any[],
    pageSize: number,
    page: number,
}

var saved: CourseInvitationsState = {
    searchParams: {
        page: 1,
        sorted: [],
        pageSize: 20,
        filters: [],
    },
    invitationList: [],
    loading: true,
    selectedTrainingTypeId: null,
    selectedSite: undefined,
    showClosed: false,
    showResponseDialog: false,
    showCommentsForAnswerId: null,
    comments: [],
    showCommentsDialog: false,
    selectedWorkflowId: null
}


export default class CourseInvitations extends React.Component<any, CourseInvitationsState> {

    constructor(props: any) {
        super(props);

        this.state = {
            searchParams: saved.searchParams || {},
            invitationList: saved.invitationList || [],
            loading: true,
            selectedTrainingTypeId: saved.selectedTrainingTypeId,
            selectedSite: saved.selectedSite,
            showClosed: saved.showClosed,
            showResponseDialog: saved.showResponseDialog,
            selectedWorkflowId: saved.selectedWorkflowId,
            showCommentsForAnswerId: null,
            comments: [],
            showCommentsDialog: false
        }
    }

    componentDidMount() {
        this.asyncReload();
    }

    private fetchCommentsFor = async (trainerInvitationAnswerId: number | null) => {
        try {
            let comments: IViewCourseTrainerInvitationAnswerCommentRecord[] = [];
            if (trainerInvitationAnswerId) {
                comments = await ViewCourseTrainerInvitationAnswerComment.list({
                    filter: { course_trainer_invitation_answer_id: trainerInvitationAnswerId },
                    order_by: "id"
                });
            } else {
                comments = [];
            }
            this.setState({
                showCommentsForAnswerId: trainerInvitationAnswerId,
                comments: comments,
                showCommentsDialog: true
            });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private asyncReload = async () => {
        this.setState({ loading: true });
        if (!me) return;
        try {
            let items: IViewKapCourseTrainerInvitationAnswerRecord[] = [];
            let filter: any = { is_active: true };

            filter.trainer_id = me.id;
            if (this.state.selectedWorkflowId) {
                filter.wf_station_id = this.state.selectedWorkflowId;
            }
            if (this.state.selectedTrainingTypeId) {
                filter.training_type_id = this.state.selectedTrainingTypeId;
            }

            for (const userFilter of this.state.searchParams.filters) {
                if (userFilter.id == "name") {
                    filter.$text = {
                        fieldNames: ["training_type_title", "course_address", "institute_name"],
                        expression: {
                            kind: StringSearchKind.WordSearch,
                            case_sensitive: false,
                            expr: userFilter.value
                        }
                    }
                } else {
                    filter[userFilter.id] = { kind: StringSearchKind.WordSearch, case_sensitive: false, expr: userFilter.value }
                }
            }

            var orderBy: any = undefined;
            if (this.state.searchParams.sorted.length > 0) {
                const userOrder = this.state.searchParams.sorted[0];
                if (userOrder.desc) {
                    orderBy = [{ name: userOrder.id, desc: true }];
                } else {
                    orderBy = userOrder.id
                }
            }

            if (!orderBy) { orderBy = [{ name: "answer_deadline", desc: true }]; }
            items = await ViewKapCourseTrainerInvitationAnswer.list({
                //todo: filter based on url:only own courses, or all available
                filter: filter,
                order_by: orderBy,
                limit: 100
            });

            this.setState({ loading: false, invitationList: items });
        } catch (error) {
            this.setState({ loading: false });
            app.showErrorFromJsonResult(error);
        }
    }

    showResponseDialog() {

    }

    async giveResponse() {
        try {
            if (this.state.selectedInvitationId) {
                let invitation = (await CourseTrainerInvitationAnswerCrud.load(this.state.selectedInvitationId)).record;
                invitation.accept = this.state.response || false;
                invitation.is_active = undefined;
                invitation.course_trainer_invitation_id = undefined;
                invitation.trainer_id = undefined;
                let crud = new CourseTrainerInvitationAnswerCrud(invitation);
                await crud.put();

                let newComment: ICourseTrainerInvitationAnswerCommentRecord = {
                    course_trainer_invitation_answer_id: crud.record.id,
                    message: this.state.response_message ? this.state.response_message : ""
                }

                let commentCrud = await (new CourseTrainerInvitationAnswerCommentCrud(newComment)).put()

                app.showSuccess(__("Siker"), __("Sikeres visszajelzés!"));

                this.setState({ showResponseDialog: false, selectedInvitationId: undefined, response: undefined, response_message: undefined },
                    this.asyncReload);

            }

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    onFetchData(tablestate: any) {
        if (this.state.searchParams.filters != tablestate.filtered) {
            this.debouncedSetTableState(tablestate);
        } else {
            this.setTableState(tablestate);
        }
    }

    debouncedSetTableState = debounce((tablestate) => this.setTableState(tablestate), 300);

    private setTableState(tablestate: any) {

        saved.searchParams.page = tablestate.page;
        saved.searchParams.pageSize = tablestate.pageSize;
        saved.searchParams.sorted = tablestate.sorted;
        saved.searchParams.filters = tablestate.filtered;

        this.setState({
            searchParams: {
                ...this.state.searchParams,
                page: tablestate.page,
                pageSize: tablestate.pageSize,
                sorted: tablestate.sorted,
                filters: tablestate.filtered
            }
        }, this.asyncReload);
    }

    render() {

        var filteredRecords = this.state.invitationList;
        const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};

            if (column && rowInfo && rowInfo.row) {
                let row = rowInfo.row._original;
                if (row && column.id == "wf_station_name") {
                    props.style = row.wf_station_style || {};
                }
            }
            return props;
        }

        let tableProps = {

            columns: [
                {
                    Header: <div>
                        <b>{__("Képzés típus - Intézmény")}</b><br />
                        <small>{__("Cím")}</small>
                    </div>,
                    accessor: "institute_name",
                    minWidth: 60,
                    Cell: (data: any, column: any) => {
                        const row: IViewKapCourseTrainerInvitationAnswerRecord = data.original;
                        return <Link to={courseModule.PATH_COURSE_ORGANIZER_COURSE + `/${row.course_id}/`}> <p>
                            <b>{row.training_type_code}</b> - {row.institute_name}<br />
                            <small>{row.course_address}</small>
                        </p></Link>;
                    }
                },
                {
                    Header: <div>
                        {__("Képzés időtartam")}
                    </div>,
                    accessor: "start_date",
                    minWidth: 30,
                    filterable: false,
                    Cell: (data: any, column: any) => {
                        const row: IViewKapCourseTrainerInvitationAnswerRecord = data.original;
                        return <p>{formatDateWithoutTime(row.start_date)}-<br />{formatDateWithoutTime(row.end_date)}
                        </p>;
                    }
                },
                {
                    Header: <div>
                        {__("Visszajelzés határideje")}
                    </div>,
                    accessor: "answer_deadline",
                    minWidth: 30,
                    filterable: false,
                    Cell: (data: any, column: any) => {
                        const row: IViewKapCourseTrainerInvitationAnswerRecord = data.original;
                        return <p>{formatDate(row.answer_deadline)}<br /> {getWfStateCell(data, column)} </p>;
                    }
                },
                {
                    Header: __("Válasz"), accessor: "accept", filterable: false, minWidth: 120,
                    Cell: (data: any, column: any) => {
                        const row: IViewKapCourseTrainerInvitationAnswerRecord = data.original;

                        let message;
                        if (row.accept) {
                            if (!row.teacher_id) {
                                message = <span style={{ color: "#348034" }}>{__("Időpont megfelelő (képző kiválasztása folyamatban)")}</span>
                            } else if (me && row.teacher_id === me.id) {
                                message = <span>{__("A képzést ön tartja")}</span>
                            } else {
                                message = <span>{__("A képzést nem ön tartja")}</span>
                            }

                        } else if (row.accept == false) {
                            message = <span style={{ color: "#666" }}>{__("Elutasítva")}</span>;
                        } else {
                            message = <span style={{ color: "#b30000" }}>{__("Válaszra vár")}</span>;
                        }

                        return <div>{message}</div>
                    }
                    , maxWidth: 140, style: { justifyContent: "left" }
                },
                {
                    Header: __("Visszajelzés"),
                    accessor: "link",
                    filterable: false,
                    sortable: false,
                    minWidth: 180,
                    maxWidth: 180,
                    className: "action-cell",
                    Cell: (data: any, column: any) => {
                        const row: IViewKapCourseTrainerInvitationAnswerRecord = data.original;
                        const commentsButton = <button className="button secondary"
                            onClick={(e) => { this.fetchCommentsFor(row.id!) }}
                        >
                            <i className="fa fa-comments" /> {row.message_cnt}
                        </button>
                        if (row.wf_station_id != CLOSED_WF_STATION_ID) {
                            return <div className="exercise-list-page-button-container">
                                <button className="button small success" disabled={row.accept == true} title={__("Elfogad")} onClick={() => { this.setState({ showResponseDialog: true, selectedInvitationId: row.id, response: true }) }}><i className="fas fa-check"></i></button>
                                <button className="button small alert" disabled={row.accept == false} title={__("Elutasít")} onClick={() => { this.setState({ showResponseDialog: true, selectedInvitationId: row.id, response: false }) }}><i className="fas fa-times"></i></button>
                                {commentsButton}
                            </div>
                        }
                        return commentsButton;
                    }
                },
            ],

            data: filteredRecords,
            defaultPageSize: this.state.searchParams.pageSize,
            defaultFiltered: this.state.searchParams.filters,
            defaultSorted: this.state.searchParams.sorted,
            pages: Math.ceil(filteredRecords.length / this.state.searchParams.pageSize),
            filterable: true,
            className: "-striped -highlight",
            ...getReactTableLabels(),
            onFetchData: this.onFetchData.bind(this),
            loading: this.state.loading,
            getTdProps,
            manual: true
        };

        return (
            <PermissionPage
                requirements={{
                    view: [{
                        viewInfoId: ViewKapCourseTrainerInvitationAnswer.VIEW_INFO_ID,
                        permissions: [ViewPermissionType.R]
                    }]
                }}
            >

                <div className="">
                    {this.state.showResponseDialog &&

                        <Dialog title={__("Visszajelzés")} onClose={() => { this.setState({ showResponseDialog: false, selectedInvitationId: undefined, response_message: undefined, response: undefined }) }}>
                            <div>
                                <span>{this.state.response == true ? "Biztosan elfogadja a meghívást a kurzusra?" : "Biztosan nem fogadja el a meghívást a kurzusra?"}</span>
                                <label>{__("Indoklás/Megjegyzés")}
                                    <textarea value={this.state.response_message} onBlur={(ev) => { this.setState({ response_message: ev.target.value }) }} />
                                </label>
                            </div>
                            <button className="button success" onClick={this.giveResponse.bind(this)} >{__("Visszajelzés küldése")}</button>
                        </Dialog>
                    }

                    {this.state.showCommentsDialog &&

                        <Dialog title={__("Visszajelzések")}
                            onClose={() => { this.setState({ showCommentsDialog: false }) }}>
                            <div className="trainer-invitation-response-div">
                                {this.state.comments.map((comment: IViewCourseTrainerInvitationAnswerCommentRecord) => {
                                    return <div>
                                        <span><strong>{comment.fullname}</strong></span> <span className="answer-comment-time" >{comment.creation_time!.substr(0, 16)}</span>
                                        <p>{comment.message}</p>
                                    </div>
                                })}
                            </div>
                            <button className="button primary" onClick={() => { this.setState({ showCommentsDialog: false }) }} >{__("Bezár")}</button>
                        </Dialog>
                    }
                    <div className="">
                        <Breadcrumbs links={[
                            { name: __("KSZR"), path: courseModule.PATH_COURSE_ORGANIZER },
                            { name: __("Kurzus meghívások") },

                        ]} />
                    </div>
                    <Accordion>
                        <AccordionItem defaultClosed key={"filter_accordion"} title={__("Szűrők")}>
                            <div className="medium-12 row">
                                <div className="small-12 medium-6 large-6 column">
                                    <label className="lms-pages-typeLabel">{__("Képzés típus")}</label>
                                    <CrudSelectComponent
                                        value={this.state.selectedTrainingTypeId}
                                        onSelect={this.onTrainingTypeSelect}
                                        displayFieldName="code"
                                        orderByFieldName="id"
                                        key="id"
                                        emptyTitle={__("Minden típus")}
                                        clearable={true}
                                        crudClassProxy={trainingTypeCrudClassProxy}
                                        filter={{ is_active: true }}
                                        sortFunc={(a, b) => a.code.localeCompare(b.code, undefined, { numeric: true, sensitivity: 'base' })}
                                    />
                                </div>
                                <div className="small-12 medium-6 large-6 column">
                                    <label className="lms-pages-typeLabel">{__("Státusz")}</label>
                                    <CrudSelectComponent
                                        value={this.state.selectedWorkflowId}
                                        onSelect={this.onWfStationSelect}
                                        displayFieldName="name"
                                        key="id"
                                        emptyTitle={__("Minden állapot")}
                                        clearable={true}
                                        orderByFieldName={"name"}
                                        crudClassProxy={stationCrudClassProxy}
                                        filter={{ wf_type_id: WF_STATION_KSZR_TRAINER_INVITE_WORKFLOW_ID }}

                                    />
                                </div>
                            </div>
                        </AccordionItem>
                    </Accordion>

                    <div className="small-12 medium-12">
                        <label className="result-count">&nbsp;{__("{count} találat", { count: this.state.invitationList.length })}</label>

                        <ReactTable {...tableProps} />

                    </div>
                </div>
            </PermissionPage>);

    }
    private onWfStationSelect = (sender: CrudSelectComponent, workflowId: number | null) => {
        this.setState({ selectedWorkflowId: workflowId, loading: true }, () => {
            this.asyncReload();
        });
    }
    private onTrainingTypeSelect = (sender: CrudSelectComponent, trainingTypeId: number | null) => {
        this.setState({ selectedTrainingTypeId: trainingTypeId, loading: true }, () => {
            this.asyncReload();
        });
    }

}