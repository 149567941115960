import * as React from 'react';
import  { IViewTaskRecord, ViewTaskClassProxy, viewTaskClassProxy } from '@src/framework/view/data_imp/ViewTask';
import ListView from '@framework/forms/listview';
import '@framework/crud/kb/ItemTypeCrud'; // Ezt ne távolítsd el mert akkor a hülye webpack kioptimizálja!
import { formatDate } from '@src/Util';
import { __ } from '@src/translation';

class TaskListViewInner extends ListView<IViewTaskRecord> {
    public getViewClassProxy() : ViewTaskClassProxy { return viewTaskClassProxy; }
}

export enum TaskStatus {
    TASK_STATUS_ELOKESZITES_ID = 1,
    TASK_STATUS_SORBAN_ALL_ID = 2,
    TASK_STATUS_FELDOLGOZAS_ALATT_ID = 3,
    TASK_STATUS_FELDOLGOZVA_ID = 4,
    TASK_STATUS_FIGYELMEZTETES_ID = 5,
    TASK_STATUS_SIKERTELEN_ID = 100,
}

export const TASK_STATUS_STYLES  = {
    [TaskStatus.TASK_STATUS_ELOKESZITES_ID]: {backgroundColor:"gray"},
    [TaskStatus.TASK_STATUS_FELDOLGOZAS_ALATT_ID]: {backgroundColor:"blue"},
    [TaskStatus.TASK_STATUS_FELDOLGOZVA_ID]: {backgroundColor:"green"},
    [TaskStatus.TASK_STATUS_FIGYELMEZTETES_ID]: {backgroundColor:"yellow"},
    [TaskStatus.TASK_STATUS_SIKERTELEN_ID]: {backgroundColor:"red", color:"white"}
};

export default function TaskListView(props: any) {
    return <TaskListViewInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "title"},
            {accessor: "task_status_title",
                Cell: (data: any, column: any) => {
                const row: IViewTaskRecord = data.original;
                return <span style={TASK_STATUS_STYLES[row.task_status_id!]}>
                    {row.task_status_title}
                </span>;
              }                
            },
            {accessor: "creator",
                Cell: (data: any, column: any) => {
                const row: IViewTaskRecord = data.original;
                return <small>
                    {formatDate(row.creation_time)}
                    {row.creator}
                </small>;
              }                
            },
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
