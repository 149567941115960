import * as React from 'react';
import { hasAnyGroup, me, Groups } from '@src/framework/server/Auth';
import ViewMediaOoFile, { IViewMediaOoFileRecord } from '@src/framework/view/media/ViewMediaOoFile';
import { app } from '@src/index';
import { formatDate } from '@src/Util';
import { TimedBubbleLoader } from '@src/component/ui/TimedBubbleLoader';
import { match } from 'react-router';
import { Breadcrumbs } from '@src/component/Breadcrumbs';
import { __ } from '@src/translation';
import { courseModule } from './courseModule';
import MediaFileChooser, { ALL_ACTIONS } from '@src/component/filemanager/MediaFileChooser';
import { ItemStyle } from '@src/component/filemanager/ContentItem';


//Folder constants KSZR Live
const KEPZOI_KEZIKONYVEK = 206;
const RESZTVEVOI_KEZIKONYVEK = 207;
const UTMUTATOK = 25539886653;

type CourseOrganizerDictionariesProps = {
    match: match
}
interface CourseOrganizerDictionariesState {
    loading: boolean;
    teacherDictionaries: IViewMediaOoFileRecord[],
    participantDictionaries: IViewMediaOoFileRecord[],
    kszrGuides:IViewMediaOoFileRecord[],

    currentTeacherFolderId: number | null;
}

export class CourseOrganizerDictionariesPage extends React.Component<CourseOrganizerDictionariesProps, CourseOrganizerDictionariesState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            teacherDictionaries: [],
            participantDictionaries: [],
            kszrGuides:[],
            currentTeacherFolderId: null
        };
    }

    componentDidMount() {
        this.reloadAsync();
        ($(document) as any).foundation();
    }
    componentDidUpdate(prevProps: CourseOrganizerDictionariesProps) {
        if (prevProps.match.path !== this.props.match.path) {
            this.reloadAsync();
        }
    }
    private async reloadAsync() {
        try {

            this.setState({ loading: true });
            let teacherDictionaries: IViewMediaOoFileRecord[] = [];
            let participantDictionaries: IViewMediaOoFileRecord[] = [];
            let kszrGuides : IViewMediaOoFileRecord[] = [];

             if (me&&(hasAnyGroup(me, [Groups.Developer, Groups.KapKszrCourseCoordinator, Groups.KapKszrCourseTeacher,Groups.KapKszrOhInspector])))
                teacherDictionaries = await ViewMediaOoFile.list({ filter: { is_active: true, oo_folder_id: KEPZOI_KEZIKONYVEK },order_by:[{name:"title"}] });

            if(me &&(hasAnyGroup(me, [Groups.Developer, Groups.KapKszrParticipantTeacher, Groups.KapKszrCourseTeacher,Groups.KapKszrCourseCoordinator,Groups.KapKszrOhInspector])))
                participantDictionaries = await ViewMediaOoFile.list({ filter: { is_active: true, oo_folder_id: RESZTVEVOI_KEZIKONYVEK },order_by:[{name:"title"}] });

                if(me &&(hasAnyGroup(me, [Groups.Developer, Groups.KapKszrOhInspector,Groups.KapKszrCourseCoordinator])))
                kszrGuides = await ViewMediaOoFile.list({ filter: { is_active: true, oo_folder_id: UTMUTATOK },order_by:[{name:"title"}] });

            this.setState({ teacherDictionaries:teacherDictionaries &&teacherDictionaries.length>0?teacherDictionaries:[],
                 participantDictionaries:participantDictionaries &&participantDictionaries.length>0?participantDictionaries:[],
                 kszrGuides:kszrGuides &&kszrGuides.length>0?kszrGuides:[],
                 currentTeacherFolderId: KEPZOI_KEZIKONYVEK,
                loading: false })
        }
        catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {
        if (this.state.loading) {
            return <TimedBubbleLoader />
        }

        const canWriteTeacherFiles = me &&(hasAnyGroup(me, [Groups.Developer, Groups.KapKszrCourseTeacher,Groups.KapKszrCourseCoordinator]));
        const canReadTeacherFiles = me &&(hasAnyGroup(me, [Groups.KapKszrOhInspector]));

        const showTeacherfilesPart = canWriteTeacherFiles || canReadTeacherFiles;
        
        return <div  style={{ minHeight: "70vh" }}>
            <div>
                <Breadcrumbs links={[
                            {name: __("KSZR"), path: courseModule.PATH_COURSE_ORGANIZER },
                                {name: __("Útmutatók és kézikönyvek")},
                            ]} />
                {
                    showTeacherfilesPart
                        ?
                        <div className="row expanded">
                          
                            <div className="small-12 column">
                            <h4>Képzői kézikönyvek</h4>

                            {
                                canWriteTeacherFiles
                                ?
                                <MediaFileChooser 
                                    rootFolderId={KEPZOI_KEZIKONYVEK}
                                    currentFolderId={this.state.currentTeacherFolderId}
                                    showLocation={false}
                                    showFilter={false}
                                    enabledActions={ALL_ACTIONS}
                                    defaultStyle={ItemStyle.LIST}
                                />
                                :
                                this.state.teacherDictionaries.map((file: IViewMediaOoFileRecord, index: number) => {
                                    return <div key={index} className="small-12 large-12 column">
    
                                        <a style={{paddingLeft:"2em"}}  href={"/api/media/file/" + file.sha1} target="_blank" download={file.title}>
                                            <i className="fa fa-download" /> &nbsp;
                                        {file.title ? file.title : file.displayname}
                                        </a>
                                        <small>{" (" + formatDate(file.creation_time) + ")"}</small>
                                    </div>
                                }
                                )
                            }
                    </div>
                        </div>
                        : ""
                }
                {
                    (this.state.participantDictionaries && this.state.participantDictionaries.length > 0)
                        ?
                        <div className="row expanded">
                      
                            <div className="small-12 column">
                            <h4>Résztvevői kézikönyvek</h4>
                            {this.state.participantDictionaries.map((file: IViewMediaOoFileRecord, index: number) => {
                                return <div key={index} className="small-12 large-12 column">

                                    <a style={{paddingLeft:"2em"}} href={"/api/media/file/" + file.sha1} target="_blank" download={file.title}>
                                        <i className="fa fa-download" /> &nbsp;
                                        {file.title ? file.title : file.displayname}
                                    </a>
                                    <small>{" (" + formatDate(file.creation_time) + ")"}</small>
                                </div>
                            }
                            )}
                                </div>
                        </div>
                        : ""
                }
                 {
                    (this.state.kszrGuides && this.state.kszrGuides.length > 0)
                        ?
                        <div className="row expanded">
                         
                            <div className="small-12 column">
                            <h4>OH monitoring kézikönyvek</h4>
                            {this.state.kszrGuides.map((file: IViewMediaOoFileRecord, index: number) => {
                                return <div key={index} className="small-12 large-12 column">

                                    <a style={{paddingLeft:"2em"}} href={"/api/media/file/" + file.sha1} target="_blank" download={file.title}>
                                        <i className="fa fa-download" /> &nbsp;
                                        {file.title ? file.title : file.displayname}
                                    </a>
                                    <small>{" (" + formatDate(file.creation_time) + ")"}</small>
                                </div>
                            }
                            )}
                                </div>
                        </div>
                        : ""
                }
            </div>
        </div>
    }
}
