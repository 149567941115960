import * as React from 'react';

import AdminRoute from '@src/Routes'
import KbItemTypeListView from './kb_item_type_listview';
import { viewKbItemTypeClassProxy } from '@src/framework/view/kb/ViewKbItemType';
import ItemTypeEditor from './kb_item_type_editor';

export function KbRoutes() {
    return [
        <AdminRoute path={viewKbItemTypeClassProxy.getDefaultListRoute()} component={KbItemTypeListView} key="KbItemTypeListView"/>,
        <AdminRoute path={viewKbItemTypeClassProxy.getDefaultEditRoute()} component={ItemTypeEditor} key="ViewKbItemTypeEditor"/>,
    ];
}