import * as React from 'react'
import NavaSearchComponent from './NavaSearchComponent';
import MuseumDigitalSearchComponent from '@src/component/search/MuseumDigitarSearchComponent';
import IWitnessSearchComponent from  '@src/component/search/IWitnessSearchComponent';
import { __ } from '@src/translation';

interface ExternalSearchState {
    radioValue: string;
    radioTypeValue: string
    keywords: string;
    prevKeywords: string;
}

export default class ExternalSearch extends React.Component<any, ExternalSearchState> {

    constructor(prop: any) {
        super(prop);
        this.state = {
            radioValue: "museum", 
            radioTypeValue: "activity",
            keywords: '',
            prevKeywords: ''
        }
    }   
    
    onRadioChange = (event: any) => {
        this.setState({
            radioValue: event.target.value,
            prevKeywords: this.state.keywords
        });
    }

    onRadioTypeChange = (event: any) => {
        this.setState({
            radioTypeValue: event.target.value
        }, () => {
            this.onSearch();
        });
    }    

    private onKeywordChange = (event: any) => {
       this.setState({
        keywords: event.target.value
        });
    }

    private onKeypress = (event: any) => {
        if (event.charCode == 13) {
            this.onSearch();
        }
    }

    private onSearch = () => {
        if (this.state.radioValue) 
        {
        this.setState({prevKeywords: this.state.keywords});
        } 
    }
   
    private onSwitchChange = (event:any) => {
        let valueType="";
        if(event.target.id=="external-radio-switch")
        {
            valueType=event.target.value=="clip"?"activity":"clip";
        }
        else
        {
            valueType=event.target.id=="clip"?"clip":"activity";
        }  
        this.setState({
            radioTypeValue: valueType
        }, () => {
            this.onSearch();
        });
    }

    hasSearchAlert()
    {    
        if(this.state.keywords === "") 
        {
            return true;
        }
        if (this.state.radioValue === "")
        {
            return true;
        }  
        if((this.state.radioValue === "iwitness") && (this.state.radioTypeValue === "")) 
        {
            return true;
        } 
   

        return false;
    }

    componentDidMount()
    {
        ($(this.refs.searchAlert) as any).foundation();
    }
    componentDidUpdate()
    {
        ($(this.refs.searchAlert) as any).foundation();
    }

    render() {

        let navaResults: any;
        let museumDigitalResults: any;
        let iWitnessResults: any;        
        let iWitnessType: any;

        iWitnessType = <>
            <div className="row expanded">
            <div id="clip" className="shrink column"style={{cursor:"pointer"}} onClick={this.onSwitchChange.bind(this)}>{__("Videók")}</div>
                        <div className="switch small shrink column" style={{padding: 0}}>
                            <input className="switch-input" id="external-radio-switch" type="checkbox" value={this.state.radioTypeValue==="clip"?"clip":"activity"}
                                checked={this.state.radioTypeValue==="activity"} onChange={this.onSwitchChange.bind(this)}
                            />
                             <label className="switch-paddle" htmlFor="external-radio-switch" style={{background:"#2BB472"}}>
                            </label>
                        </div>
                     
            <div id="activity" className="shrink column"style={{cursor:"pointer"}} onClick={this.onSwitchChange.bind(this)}>{__("Feladatsorok")}</div>     
            </div>    
        </>

        if ((this.state.radioValue === "nava") && (this.state.prevKeywords === this.state.keywords) && (this.state.prevKeywords !== "")) 
            navaResults =  <NavaSearchComponent
                            keyword={this.state.prevKeywords}
                        />

        if ((this.state.radioValue === "museum") && (this.state.prevKeywords === this.state.keywords) && (this.state.prevKeywords !== "")) 
            museumDigitalResults =  <MuseumDigitalSearchComponent
                        keyword={this.state.prevKeywords}
                    />            

        if (this.state.radioValue === "iwitness") {
            if ((this.state.prevKeywords === this.state.keywords) && (this.state.radioTypeValue !== ""))     
                iWitnessResults =  <IWitnessSearchComponent
                            keyword={this.state.prevKeywords}
                            type={this.state.radioTypeValue}
                        />                   
        } else { iWitnessType = ""; }

        return <div className="row">
                <div className="small-12 column">
                    <div className="row">
                        <div className="columns">
                            <h4>{__("Keresés a külső tartalmakban")}</h4>
                        </div>
                    </div> 

                    <div className="row align-center">            
                        <div className="small-12 medium-10 large-10 columns">
                        {__("Kulcsszó")}:
                        </div>                     
                    </div>
                    <div className="row align-center"> 
                        <div className="column small-12 medium-10 large-10">   
                            <div className="input-group eke-search-field">
                                <input className="input-group-field eke-search__input" name="textKeyword" value={this.state.keywords} onChange={this.onKeywordChange} onKeyPress={this.onKeypress} />
                                <div className="input-group-button">
                                <button onClick={this.onSearch.bind(this)} className="shrink column button eke-search__button">{__("Keresés")}</button>
                                </div>
                            </div> 
                        </div>
                    </div>

                    <div className="row  align-center">
                        <div className="column small-12 medium-10 large-10">  
                            <label tabIndex={0}  className={"button primary hollow eke-search__filter-button " + (this.state.radioValue === "museum" ? "active" : "")}>
                                <input tabIndex={0} className="external-radio" type="radio" name="radioMuseumDigital" value="museum" checked={this.state.radioValue === "museum"} onChange={this.onRadioChange.bind(this)}/>
                                {__("MúzeumDigitár")}                               
                            </label> 
                            <label tabIndex={0}  className={"button primary hollow eke-search__filter-button " + (this.state.radioValue === "nava" ? "active" : "")}>
                                <input tabIndex={0} className="external-radio" type="radio" name="radioNava" value="nava" checked={this.state.radioValue === "nava"} onChange={this.onRadioChange.bind(this)}/>
                                {__("NAVA")}                            
                            </label>                            
                            <label tabIndex={0} className={"button primary hollow eke-search__filter-button " + (this.state.radioValue === "iwitness" ? "active" : "")}>
                                <input tabIndex={0} className="external-radio" type="radio" name="iWitness" value="iwitness" checked={this.state.radioValue === "iwitness"} onChange={this.onRadioChange.bind(this)}/>
                                {__("IWitness")}                                
                            </label>
                            {iWitnessType}  

                            {/* <div ref="searchAlert" className="callout warning" data-closable="" style={{display: "none"}}>
                                {
                                    (this.state.keywords === "") 
                                    ? <p>Kulcsszó megadása kötelező!</p>
                                    : ""
                                    
                                } 
                                {
                                    ((this.state.radioValue === "iwitness") && (this.state.radioTypeValue === ""))  
                                    ? <p>Az egyik IWitness típust ki kell választani a kereséshez!</p> 
                                    : ""
                                }
                                {
                                    ((this.state.radioValue === "")) 
                                    ? <p>Az egyik keresőmotort ki kell választani a kereséshez!</p>
                                    : ""
                                }

                                <button className="close-button" aria-label="Figylemeztetés bezárása" type="button" data-close="">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}

                            {
                                this.state.radioValue ?
                                <div className="callout">
                                    {
                                        this.state.radioValue === "nava" ?
                                        <p>{__("A Nemzeti Audiovizuális Archívum (NAVA) 2006 óta digitálisan rögzíti, feldolgozza és online hozzáférhetővé teszi a magyarországi közszolgálati csatornák és a legnagyobb lefedettségű kereskedelmi televíziók magyar gyártású műsorait törvényi szabályozás keretében. Gyűjteményei - Európában szinte egyedülállóan - kutatás és oktatás céljából szabadon megtekinthetők úgynevezett NAVA-pontokon. Ajánlott: természetismeret, biológia, földrajz, fizika, kémia, történelem, irodalom, etika tantárgyakhoz.")}</p>
                                        : 
                                        this.state.radioValue === "iwitness" ? 
                                        <p>{__("Az IWitness a Dél-kaliforniai Egyetem (USC) Soá Alapítvány digitális oktatási platformja. Több mint 3000 teljes videóinterjú mellett magyar nyelvű multimédiás, digitális tananyagokat tartalmaz, melyek mindegyike megfelel a tantervi előírásoknak és követelményeknek. A felületen több mint 30  magyar nyelvű tananyag, 200 magyar nyelvű interjú és közel 50 tematikus klip érhető el. A feladatok videóinterjúkra épülnek, fejlesztik a diákok digitális műveltségét, kritikai gondolkodását.")}</p>
                                        :
                                        this.state.radioValue === "museum" ? <>
                                        <p>{__("A MúzeumDigitár egy felhőalapú, gyűjteménykezelő és publikációs szoftver, amely 68 magyar közgyűjtemény 205.000 műtárgyának képi és szöveges adatait tartalmazza.")} </p>
                                        <p>{__("Ajánlott: irodalom, etika, történelem tantárgyakhoz.")}</p>
                                        </>
                                        : null
                                    }
                                </div>
                                :
                                null
                            }
                            
                        </div>
                                   
                    </div>  
                        

                    <div className="row">
                        <div className="columns text-center">
                            {navaResults}
                        </div>
                    </div>     

                    <div className="row">
                        <div className="columns">
                            {museumDigitalResults}
                        </div>
                    </div>       

                    <div className="row">
                        <div className="columns">
                            {iWitnessResults}
                        </div>
                    </div>                                     
                </div>
            </div>
    }
}