import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewMetaTableCategoryTypeRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Táblázat információ
     */
    table_info_id?: number|null;
    /**
     * Metaadat kategória típus
     */
    category_type_id?: number;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Módosította
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Táblázat kijelzett neve
     *
     * Táblázat kijelzett neve
     */
    table_display_name?: string|null;
    /**
     * Táblázat leírása
     *
     * Táblázat leírása
     */
    table_description?: string|null;
    /**
     * Metakategória típus neve
     *
     * Metakategória típus neve
     */
    category_type_title?: string|null;
    /**
     * Metakategória típus leírása
     *
     * Metakategória típus leírása
     */
    category_type_description?: string|null;
    /**
     * Kijelzett név
     *
     * Egysoros szöveges reprezentáció
     */
    displayvalue?: string|null;
}

/**
 *  meta.view_meta_table_category_type - Táblázat - metakategória típus
 *
 *  Azt mondja meg, hogy melyik táblázatnak milyen típusú metakategóriái lehetnek
 */

export default class ViewMetaTableCategoryType extends View<IViewMetaTableCategoryTypeRecord> {
    public static VIEW_INFO_ID = 2018061101;

    public static getSchemaNameForClass(): string { return 'meta'; }
    public static getViewNameForClass(): string { return 'view_meta_table_category_type'; }
    public static getTableNameForClass(): string|null { return 'table_category_type'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewMetaTableCategoryType>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewMetaTableCategoryTypeRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewMetaTableCategoryTypeClassProxy extends ViewClassProxy<IViewMetaTableCategoryTypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewMetaTableCategoryTypeClassProxy = new ViewMetaTableCategoryTypeClassProxy(ViewMetaTableCategoryType);
registerViewClassProxy(viewMetaTableCategoryTypeClassProxy);

