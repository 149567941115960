import * as ExerciseBaseTypes from "@src/component/exercise/models/ExerciseBaseClass";
import { AExerciseTypeConverter, ValidationResponse } from "@src/component/exercise/models/AExerciseTypeConverter";
import * as React from "react";
import ExerciseFileSelect, { ExerciseFileTypes } from "@src/component/exercise/Editor/ExerciseFileSelect";
import { Accordion, ActionAccordionItem, AccordionActionType, IAccordionAction } from '@src/component/ui/Accordion';
import { Panel } from '@src/component/ui/Panel';
import { __ } from '@src/translation';

export type MemoryExercise = {
    matchDifferent: boolean,
    elements: ExerciseBaseTypes.AnswerElement[],
    differentElements: ExerciseBaseTypes.AnswerElement[],
    cardBackground: string
}

export class MemoryCardGameConverter extends AExerciseTypeConverter {

    public hasTextAnswer = false;
    public hasImageAnswer = true;
    public hasTextImageAnswer = false;
    public isGame = true;

    static MIN_CARD_NUM: number = 2;
    static MAX_CARD_NUM: number = 18; //in case of matchDifferent = false, use MAX_CARD_NUM / 2

    public convertToEditorAnswer(exercise: MemoryExercise): MemoryExercise | undefined {
        if (exercise) {
            let exe_elems: ExerciseBaseTypes.AnswerElement[] = [];
            let diff_exe_elems: ExerciseBaseTypes.AnswerElement[] = [];
            if (exercise.elements) {
                for (let i = 0; i < exercise.elements.length; i++) {
                    let curr_elem = exercise.elements[i];
                    if (curr_elem.type == "text") {
                        exe_elems.push({ type: ExerciseBaseTypes.AnswerTypes.text, text: curr_elem.text, image: curr_elem.image });
                    }
                    else if (curr_elem.type == "sound") {
                        exe_elems.push({ type: ExerciseBaseTypes.AnswerTypes.sound, text: curr_elem.text, url: curr_elem.url, image: "" });
                    }
                    else if (curr_elem.type == "image") {
                        exe_elems.push({ type: ExerciseBaseTypes.AnswerTypes.image, text: curr_elem.text, image: curr_elem.image, url: curr_elem.image });
                    }
                }
            }

            if (exercise.differentElements) {
                for (let i = 0; i < exercise.differentElements.length; i++) {
                    let curr_elem = exercise.differentElements[i];
                    if (curr_elem.type == "text") {
                        diff_exe_elems.push({ type: ExerciseBaseTypes.AnswerTypes.text, text: curr_elem.text, image: curr_elem.image });
                    }
                    else if (curr_elem.type == "sound") {
                        diff_exe_elems.push({ type: ExerciseBaseTypes.AnswerTypes.sound, text: curr_elem.text, url: curr_elem.url, image: "" });
                    }
                    else if (curr_elem.type == "image") {
                        diff_exe_elems.push({ type: ExerciseBaseTypes.AnswerTypes.image, text: curr_elem.text, image: curr_elem.image, url: curr_elem.image });
                    }
                }
            }

            let answer: MemoryExercise = {
                matchDifferent: exercise.matchDifferent,
                elements: exe_elems,
                differentElements: diff_exe_elems,
                cardBackground: exercise.cardBackground ? exercise.cardBackground : "" 
            };
            return answer;
        }
        return undefined;
    }

    public convertToJson(exerciseDetails: MemoryExercise, baseData: ExerciseBaseTypes.ExerciseBaseClass, prevJSON?: MemoryExercise): MemoryExercise | undefined {
        var cur_exercise = ExerciseBaseTypes.convertToBaseJson(baseData);
        if (exerciseDetails) {
            let exe_elems = [];
            //if matchDiferent is false and differentElements is not empty, we epmty it
            if (exerciseDetails.matchDifferent == false && exerciseDetails.differentElements)
                exerciseDetails.differentElements = [];
            //if matchDiff is true but diffElems is empty, we fill it up with empty elems
            else if (exerciseDetails.matchDifferent == true && (!exerciseDetails.differentElements || exerciseDetails.differentElements == [] || exerciseDetails.differentElements.length == 0)) {
                exerciseDetails.differentElements = this.fillUpDifferentElems(exerciseDetails.elements.length);
            }

            if (exerciseDetails.elements) {
                for (let i = 0; i < exerciseDetails.elements.length; i++) {
                    let curr_elem = exerciseDetails.elements[i];
                    if (curr_elem.type == ExerciseBaseTypes.AnswerTypes.text) {
                        exe_elems.push({ type: "text", text: curr_elem.text, image: "" });
                    }
                    else if (curr_elem.type == ExerciseBaseTypes.AnswerTypes.image) {
                        exe_elems.push({ type: "image", text: curr_elem.text, image: curr_elem.url });
                    }
                    else if (curr_elem.type == ExerciseBaseTypes.AnswerTypes.sound) {
                        exe_elems.push({ type: "sound", text: curr_elem.text, url: curr_elem.url });
                    }
                }
            }

            let diff_exe_elems = [];
            if (exerciseDetails.differentElements) {
                for (let i = 0; i < exerciseDetails.differentElements.length; i++) {
                    let curr_elem = exerciseDetails.differentElements[i];
                    if (curr_elem.type == ExerciseBaseTypes.AnswerTypes.text) {
                        diff_exe_elems.push({ type: "text", text: curr_elem.text, image: "" });
                    }
                    else if (curr_elem.type == ExerciseBaseTypes.AnswerTypes.image) {
                        diff_exe_elems.push({ type: "image", text: curr_elem.text, image: curr_elem.url, url: curr_elem.url });
                    }
                    else if (curr_elem.type == ExerciseBaseTypes.AnswerTypes.sound) {
                        diff_exe_elems.push({ type: "sound", text: curr_elem.text, url: curr_elem.url });
                    }
                }
            }
            var answer = {
                ...cur_exercise,
                matchDifferent: exerciseDetails.matchDifferent,
                elements: exe_elems,
                differentElements: diff_exe_elems,
                cardBackground: exerciseDetails.cardBackground ? exerciseDetails.cardBackground : ""
            }
            return answer;
        }
        return cur_exercise;
    }
    render() {
        let curr_ex: MemoryExercise = this.state.exercise as MemoryExercise;
        return (
            <Panel header={__("Részletek")} headingLevel={4}>
                <div className="row">
                    <div className="large-12 columns">
                        <label ><input type="checkbox" name="matchDifferent" checked={curr_ex.matchDifferent || false}
                            onBlur={this.onBlurEvent.bind(this)}
                            onChange={this.handleInputChange.bind(this)} />
                            {__("A párok elemei különbözőek")}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <label>{__("Kártyák háttérképe")}</label>
                        {<ExerciseFileSelect
                            imagebasepath={this.props.imagebasepath}
                            value={curr_ex.cardBackground || ""}
                            onChange={this.handleImageChange.bind(this, "cardBackground")}
                            getFolderId={this.getFolderId.bind(this)}
                            fileType={ExerciseFileTypes.Image}
                        />}
                    </div>
                </div>
                <Panel header={__("Kártyák")} headingLevel={5}>
                    {
                        curr_ex.elements ?
                            curr_ex.elements.map((curr_card: ExerciseBaseTypes.AnswerElement, index) => {
                                let actions: IAccordionAction[];
                                actions = [
                                    {
                                        title: __("Törlés"),
                                        type: AccordionActionType.Trash,
                                        action: this.removeElementByIndex.bind(this, index)
                                    }
                                ];
                                let curr_diff_card;
                                curr_ex.differentElements ? curr_diff_card = curr_ex.differentElements[index] : curr_diff_card = null;
                                return (
                                    <Accordion key={"elements_" + index}>
                                        <ActionAccordionItem defaultClosed key={"elements_" + index} actions={actions} title={__("Kártya {n}", {n: index + 1})}>
                                            <Panel>
                                                <legend>
                                                    <label>{__("Első tag")} </label>
                                                    <span className="exe-editor-validation-msg">{this.props.validationMessages.get("elements[" + index + "].type")}</span>
                                                    <span className="exe-editor-validation-msg">{this.props.validationMessages.get("elements[" + index + "].text")}</span>
                                                    <span className="exe-editor-validation-msg">{this.props.validationMessages.get("elements[" + index + "].url")}</span>
                                                </legend>
                                                <div className="row">
                                                    <div className="large-6 columns">
                                                        <label> {__("Típus")}
                                                            <select value={curr_card.type} name={"elements#" + index + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                                <option value={ExerciseBaseTypes.AnswerTypes.text}>{__("Szöveg")}</option>
                                                                <option value={ExerciseBaseTypes.AnswerTypes.image}>{__("Kép")}</option>
                                                                <option value={ExerciseBaseTypes.AnswerTypes.sound}>{__("Hang")}</option>
                                                            </select></label>
                                                    </div>
                                                    <div className="large-6 columns">
                                                        <label>{curr_card.type == ExerciseBaseTypes.AnswerTypes.text ? __("Válasz") : __("Leírás")}
                                                            <input type="text" name={"elements#" + index + ".text"} data-parent-index={index} data-index={index} value={curr_card.text}
                                                                onBlur={this.onBlurEvent.bind(this)}
                                                                onChange={this.handleInputChange.bind(this)} />
                                                        </label>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="large-12 columns">
                                                        {curr_card.type != ExerciseBaseTypes.AnswerTypes.text ?
                                                            <label className="exe-image-select-label">{curr_card.type == ExerciseBaseTypes.AnswerTypes.image ? __("Kép") : __("Hang")}
                                                                {<ExerciseFileSelect
                                                                    imagebasepath={this.props.imagebasepath}
                                                                    value={curr_card.url || ""}
                                                                    onChange={this.handleImageChange.bind(this, "elements#" + index + ".url")}
                                                                    getFolderId={this.getFolderId.bind(this)}
                                                                    fileType={curr_card.type == ExerciseBaseTypes.AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                                />}
                                                            </label>
                                                            : ""}
                                                    </div>
                                                </div>
                                            </Panel>
                                            {/* In case of different pairs */}
                                            {
                                                curr_ex.matchDifferent && curr_diff_card ?
                                                    <Panel>
                                                        <legend>
                                                            <label>{__("Második tag")}</label>
                                                            <span className="exe-editor-validation-msg">{this.props.validationMessages.get("differentElements[" + index + "].type")}</span>
                                                            <span className="exe-editor-validation-msg">{this.props.validationMessages.get("differentElements[" + index + "].text")}</span>
                                                            <span className="exe-editor-validation-msg">{this.props.validationMessages.get("differentElements[" + index + "].url")}</span>
                                                        </legend>
                                                        <div className="row">
                                                            <div className="large-6 columns">
                                                                <label> {__("Típus")}
                                                                    <select value={curr_diff_card.type} name={"differentElements#" + index + ".type"} onChange={this.handleInputChange.bind(this)} onBlur={this.onBlurEvent.bind(this)}>
                                                                        <option value={ExerciseBaseTypes.AnswerTypes.text}>{__("Szöveg")}</option>
                                                                        <option value={ExerciseBaseTypes.AnswerTypes.image}>{__("Kép")}</option>
                                                                        <option value={ExerciseBaseTypes.AnswerTypes.sound}>{__("Hang")}</option>
                                                                    </select></label>
                                                            </div>


                                                            <div className="large-6 columns">
                                                                <label>{curr_diff_card.type == ExerciseBaseTypes.AnswerTypes.text ? __("Válasz") : __("Leírás")}
                                                                    <input type="text" name={"differentElements#" + index + ".text"} data-parent-index={index} data-index={index} value={curr_diff_card.text}
                                                                        onBlur={this.onBlurEvent.bind(this)}
                                                                        onChange={this.handleInputChange.bind(this)} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="large-12 columns">
                                                                {curr_diff_card.type != ExerciseBaseTypes.AnswerTypes.text ?
                                                                    <label className="exe-image-select-label">{curr_diff_card.type == ExerciseBaseTypes.AnswerTypes.image ? __("Kép") : __("Hang")}
                                                                        {<ExerciseFileSelect
                                                                            imagebasepath={this.props.imagebasepath}
                                                                            value={curr_diff_card.url || ""}
                                                                            onChange={this.handleImageChange.bind(this, "differentElements#" + index + ".url")}
                                                                            getFolderId={this.getFolderId.bind(this)}
                                                                            fileType={curr_diff_card.type == ExerciseBaseTypes.AnswerTypes.image ? ExerciseFileTypes.Image : ExerciseFileTypes.Sound}
                                                                        />}
                                                                    </label>
                                                                    : ""}
                                                            </div>
                                                        </div>

                                                    </Panel>
                                                    : ""}
                                        </ActionAccordionItem>
                                    </Accordion>
                                );
                            }) : ""
                    }
                    <div className="row">
                        <button className="button small" onClick={this.onAddNewCard.bind(this)}><i className="fa fa-plus"></i> {__("Új kártya")}</button>
                        {
                            curr_ex.matchDifferent ?
                                <label className="exe-editor-label-description columns">{__("Különböző párok esetén maximum {MAX_CARD_NUM} db kártya megengedett.", { MAX_CARD_NUM: MemoryCardGameConverter.MAX_CARD_NUM })}</label>
                                :
                                <label className="exe-editor-label-description columns">{__("Azonos párok esetén maximum {MAX_CARD_NUM} db kártya megengedett.", { MAX_CARD_NUM: MemoryCardGameConverter.MAX_CARD_NUM / 2 })}</label>
                        }

                    </div>
                </Panel>
            </Panel>
        );
    }

    onAddNewCard() {
        let temp_exercise = this.state.exercise as MemoryExercise;
        if (!temp_exercise.elements) temp_exercise.elements = [];
        temp_exercise.elements.push(this.getNewCard());
        if (temp_exercise.matchDifferent) {
            if (temp_exercise.differentElements) {
                temp_exercise.differentElements.push(this.getNewCard());
            }
            else {
                temp_exercise.differentElements = [];
                temp_exercise.differentElements.push(this.getNewCard());
            }
        }
        if (this.makeValidBeforeSetState(this.state.exercise, temp_exercise).valid) {
            this.setState({ exercise: temp_exercise });
        }
    }

    getNewCard(): ExerciseBaseTypes.AnswerElement {
        let card: ExerciseBaseTypes.AnswerElement = {
            image: "",
            text: "",
            type: ExerciseBaseTypes.AnswerTypes.text
        };
        return card;
    }

    fillUpDifferentElems(length: number): ExerciseBaseTypes.AnswerElement[] {
        let temp_differentElements: ExerciseBaseTypes.AnswerElement[] = [];
        for (let i = 0; i < length; i++) {
            temp_differentElements.push(this.getNewCard());
        }
        return temp_differentElements;
    }

    removeElementByIndex(index: number) {
        let curr_exe: MemoryExercise = this.state.exercise;
        if (curr_exe) {
            if (curr_exe.matchDifferent && curr_exe.differentElements[index]) {
                curr_exe.differentElements.splice(index, 1);
            }
            if (curr_exe.elements[index])
                curr_exe.elements.splice(index, 1);
        }
        this.setState({ exercise: curr_exe });
    }

    public validate(editorAnswer: MemoryExercise, baseData: any, validationMap?: Map<string, string>, is_accessible?: boolean | null): ValidationResponse {
        let superAnswer: ValidationResponse = super.validate(editorAnswer, baseData,validationMap, is_accessible);
        if (!superAnswer.valid) return superAnswer;
        let errorMsg="";
        let OFIErrors: string[] = [];
        let curr_elements_list = editorAnswer.elements;
        if (curr_elements_list) {

            if (curr_elements_list && (curr_elements_list.length < MemoryCardGameConverter.MIN_CARD_NUM || (curr_elements_list.length > MemoryCardGameConverter.MAX_CARD_NUM))) {
                errorMsg = __("Minimum {min}, maximum {max} elem adható meg!", { min: MemoryCardGameConverter.MIN_CARD_NUM, max: MemoryCardGameConverter.MAX_CARD_NUM });
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                return { valid: false, message: errorMsg }
            }

            for (let i = 0; i < curr_elements_list.length; i++) {
                let curr_elem = curr_elements_list[i];               
                if (curr_elem.text.length > AExerciseTypeConverter.MAX_ALT_TEXT_LENGTH) {
                    errorMsg = __("A válaszelemek karakterszáma maximum {n} lehet!", { n: AExerciseTypeConverter.MAX_ALT_TEXT_LENGTH });
                    validationMap!.set("elements[" + i + "].text", errorMsg);
                    return { valid: false, message: errorMsg };
                }
            }
        }
        if (editorAnswer.matchDifferent && editorAnswer.differentElements) {
            let curr_diff_elements_list = editorAnswer.differentElements;           
            if (curr_diff_elements_list) {

                if (curr_diff_elements_list.length < MemoryCardGameConverter.MIN_CARD_NUM || (!AExerciseTypeConverter.isOfiEditor && curr_diff_elements_list.length > MemoryCardGameConverter.MAX_CARD_NUM)) {
                    errorMsg = __("Minimum {min}, maximum {max} elem megengedett!", { min: MemoryCardGameConverter.MIN_CARD_NUM, max: MemoryCardGameConverter.MAX_CARD_NUM });
                    if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                    return { valid: false, message: errorMsg }
                }

                for (let i = 0; i < curr_diff_elements_list.length; i++) {
                    let curr_elem = curr_diff_elements_list[i];
                    if (curr_elem.text.length > AExerciseTypeConverter.MAX_ALT_TEXT_LENGTH) {
                        errorMsg = __("A válaszelemek karakterszáma maximum {n} lehet!", { n: AExerciseTypeConverter.MAX_ALT_TEXT_LENGTH });
                        validationMap!.set("differentElements[" + i + "].text", errorMsg);
                        return { valid: false, message: errorMsg };
                    }
                }
            }
        }

        if (!editorAnswer.matchDifferent) {
            if (curr_elements_list && (curr_elements_list.length < MemoryCardGameConverter.MIN_CARD_NUM / 2 || (!AExerciseTypeConverter.isOfiEditor && curr_elements_list.length > MemoryCardGameConverter.MAX_CARD_NUM / 2))) {
                errorMsg = __("Minimum {min}, maximum {max} elem megengedett!", { min: MemoryCardGameConverter.MIN_CARD_NUM / 2, max: MemoryCardGameConverter.MAX_CARD_NUM / 2 });
                if (!OFIErrors.includes(errorMsg, 0)) OFIErrors.push(errorMsg);
                return { valid: false, message: errorMsg }
            }
        }

        if (AExerciseTypeConverter.isOfiEditor){
            return { valid: true, message: OFIErrors.join(' , ') }
        }

        return { valid: true }
    }

    public makeValidBeforeSetState(oldState: MemoryExercise | null, newState: MemoryExercise): ValidationResponse {
        if (AExerciseTypeConverter.isOfiEditor) return { valid: true }
        if (!newState.matchDifferent && newState.elements && newState.elements.length > MemoryCardGameConverter.MAX_CARD_NUM / 2) {
            newState.elements.splice(MemoryCardGameConverter.MAX_CARD_NUM / 2, newState.elements.length - (MemoryCardGameConverter.MAX_CARD_NUM / 2));
            return { valid: false, message: __("Maximum {MAX_CARD_NUM} elem megendegett azonos párok esetén.", { MAX_CARD_NUM: MemoryCardGameConverter.MAX_CARD_NUM / 2 }) }
        }
        if (newState.matchDifferent && newState.elements && newState.differentElements) {
            if (newState.elements.length > MemoryCardGameConverter.MAX_CARD_NUM && newState.differentElements.length > MemoryCardGameConverter.MAX_CARD_NUM) {
                newState.elements.splice(MemoryCardGameConverter.MAX_CARD_NUM, newState.elements.length - MemoryCardGameConverter.MAX_CARD_NUM);
                newState.differentElements.splice(MemoryCardGameConverter.MAX_CARD_NUM, newState.differentElements.length - MemoryCardGameConverter.MAX_CARD_NUM);
                return { valid: false, message: __("Maximum {MAX_CARD_NUM} elem megendegett különböző párok esetén.", { MAX_CARD_NUM: MemoryCardGameConverter.MAX_CARD_NUM }) }
            }
        }
        return { valid: true }
    }
}
