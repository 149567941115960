import * as React from 'react'
import { NavLink, Link, match } from 'react-router-dom';
import { BooleanTypeAnnotation, JSXElement } from '@babel/types';
import * as Scrollbar from 'react-smooth-scrollbar';
import SplitPane from 'react-split-pane';

export type SidebarMenuItem = {
    name?: string;
    iconClass?: string;
    linkTo?: string;
    subItems?: SidebarMenuItem[];
    extra?: JSX.Element;
    separator?: boolean;
    matchName?: string;
    menuItemCode?: string;
    target?: "_blank" | "_self";
}

type SidebarState = {
    isOpen?: boolean;
    menuItems?: SidebarMenuItem[];
}
type SidebarProps = {
    menuItems?: SidebarMenuItem[];
    onToggle?: (isOpen:boolean)=>void;
    closable?: boolean;
    title?: string | JSX.Element;
    className?: string;
    defaultClose?: boolean;
    matchParam?: string;
}

export default class Sidebar extends React.Component<SidebarProps, SidebarState> {

    constructor(props: any) {
        super(props);

        this.state = { 
            isOpen : this.props.defaultClose ? false : true,
            menuItems : this.props.menuItems
        };
    }

    componentDidMount() {
        ($(this.refs.root) as any).foundation();
    }

    toggleMenubar()
    {
        this.setState({isOpen: !this.state.isOpen});
        if(this.props.onToggle)
        {
            this.props.onToggle(!this.state.isOpen);
        }  
        ($(this.refs.adminMenu)as any).foundation('hideAll');      
    }
    openMenubar()
    {
        this.setState({isOpen: true});
        if(this.props.onToggle)
        {
            this.props.onToggle(true);
        } 
    }

    public pushMenu(menuItems: SidebarMenuItem[], parentCode?: string)
    {
        const stateMenuItems = this.state.menuItems;

        stateMenuItems!.forEach(m=>{
            if(parentCode == m.menuItemCode)
            {
                m.subItems = m.subItems ? m.subItems.concat(menuItems) : menuItems;
            }

        });
        this.setState({menuItems: stateMenuItems});
    }

    render() {
        return <div ref="root" className={"eke-sidebar " + (this.props.className ? this.props.className : "") + (this.state.isOpen ? "" : " closed")}  data-sticky-container>
                
                {
                    this.props.closable || this.props.title                  
                    ?
                    <div className="eke-sidebar-header">
                        <div className="text">
                        {
                            this.props.title && typeof(this.props.title) === "string"
                            ?
                            <h4>
                                {this.props.title}                                
                            </h4> 
                            :
                            <>
                            {this.props.title}                            
                            </>                            
                        }
                        </div>
                        {
                            this.props.closable 
                            ? 
                            <i style={{padding:"5px", flex: "0 1 50px", textAlign: "center"}} tabIndex={0} onClick={() => this.toggleMenubar()} className="fa fa-times close-btn"></i>
                            : null
                        }
                    </div>
                    : null
                }   
                 <Scrollbar ref="sidebarScroll" alwaysShowTracks={false} style={{height: "100%", maxWidth: "100%", width:"100%"}}>                                             
                
                <ul ref="adminMenu" className="vertical menu accordion-menu" data-accordion-menu>
                    {
                        this.state.menuItems &&
                        this.state.menuItems.map((item, index) => {
                            if(item.separator)
                            {
                                return <li className="separator"></li>
                            }

                            var linkTo = item.linkTo ? item.linkTo : "#";
                            linkTo = this.props.matchParam ? linkTo + "/" + this.props.matchParam : linkTo;
                            return <li key={index}>

                                <NavLink target={item.target} activeClassName="active" className="menu-group" to={linkTo} onClick={() => this.openMenubar()}>{item.iconClass && <i className={item.iconClass} />}<span className="text">{item.name}</span></NavLink>
                                {
                                    (item.subItems || item.extra) &&
                                    <ul className="menu vertical nested">
                                        {
                                            item.subItems && item.subItems.map((subItem, index2) => {
                                                return <li key={index2} className="menu-item">
                                                    <NavLink target={subItem.target || undefined} activeClassName="active" to={
                                                        this.props.matchParam ? (subItem.linkTo || "") + "/"+ this.props.matchParam : (subItem.linkTo || "")
                                                        }>
                                                        {subItem.name}
                                                    </NavLink>
                                                </li>
                                            })
                                        }
                                        {item.extra}
                                    </ul>
                                }
                            </li>
                        })
                    }
                </ul>
                {this.props.children}
                </Scrollbar>
            </div>
    }
}

type SplitPaneWithSidebarProps = {
    sideBarSize?: number; 
    sidebarProps: SidebarProps;
}

export class SplitPaneWithSidebar extends React.Component<SplitPaneWithSidebarProps, {sidebarOpen: boolean}> {

    constructor(props: SplitPaneWithSidebarProps) {
        super(props);

        this.state = { 
            sidebarOpen: window.screen.width < 800 ? false : true
        }
    }

    render() {
        return <div className="eke-content-wrapper" style={{ position: "relative", height: "100%" }}>
            <SplitPane allowResize={this.state.sidebarOpen ? true : false} 
                    split="vertical" 
                    minSize={50} 
                    maxSize={-150} 
                    size={this.state.sidebarOpen ? (this.props.sideBarSize ? this.props.sideBarSize : 350) : 50} 
                    defaultSize={this.state.sidebarOpen ? (this.props.sideBarSize ? this.props.sideBarSize : 350) : 50} 
                    style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", overflow: "hidden" }}>

                <Sidebar defaultClose={!this.state.sidebarOpen} 
                    onToggle={isOpen => this.setState({sidebarOpen: isOpen})} 
                    {...this.props.sidebarProps}/>

                <div className="content-wrapper">
                    {this.props.children}
                </div>
            </SplitPane>
        </div>
    }

}
