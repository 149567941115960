import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISubjectRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * URL rész
     *
     * URL path (útvonal) szegmense
     */
    uri_segment?: string;
}

/**
 *  doc.subject - Tantárgy
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class SubjectCrud extends Crud<ISubjectRecord> {
    public static TABLE_INFO_ID = 1154885546;
    public static getSchemaNameForClass() { return 'doc'; }
    public static getTableNameForClass() { return 'subject'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<SubjectCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISubjectRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISubjectRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISubjectRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISubjectRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISubjectRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SubjectCrudClassProxy extends CrudClassProxy<ISubjectRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const subjectCrudClassProxy = new SubjectCrudClassProxy(SubjectCrud);
registerCrudClassProxy(subjectCrudClassProxy);
