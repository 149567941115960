import './style.css';
import { ExerciseBaseClass } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { WMNumberPyramidServer } from './WMNumberPyramidServer';

export interface WMDPiramidData extends ExerciseBaseClass {
    sets: string[];
    elements: string[];
    keywords: string;
    ordered: boolean;
}

type WMPESolution = {
    key: number;
    value: number[];
}

type WMDPiramidSolution = {
    fullmatch: boolean;
    answer: WMPESolution[];
}

export class WMNumberPyramidExerciseEngine extends AExerciseEngine {
    private divList: any[] = [];
    private interaction: boolean = false;
    public parityKeyPressData: string = "";

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        let exercise: WMDPiramidData = params.exercise;
        if (!exercise || !exercise.elements)
            return;
       
        this.divList = [];
        this.changeInteraction(false);       

        this.root.classList.add("wm-math-number-pyramid");

        let contentWrapper = this.root.appendChild(document.createElement("div"));
        //conetntWrapper.classList.add("content-wrapper");

        this.setIllustration(exercise,contentWrapper);

        let gridDiv = contentWrapper.appendChild(document.createElement("div"));
        gridDiv.classList.add("row");
        gridDiv.classList.add("scroll");

        //Játéktér
        let imageContainer = gridDiv.appendChild(document.createElement("div"));
        imageContainer.classList.add("small-12");
        imageContainer.classList.add("cell");
        // imageContainer.classList.add("grid-x");
        imageContainer.classList.add("align-middle");
        imageContainer.classList.add("area-container");
        imageContainer.classList.add("align-vertical-center-middle");

        let answerList = exercise.elements;
        let k = 0;
        for (let answer_column_id = 0; answer_column_id < answerList.length; answer_column_id++) {
            let answer_row: any = answerList[answer_column_id];

            //answer_row = answer_row.reverse();
            this.divList[answer_column_id] = [];
            for (let answer_row_id = 0; answer_row.length > answer_row_id; answer_row_id++) {
                if (answer_row[answer_row_id].toString() != "") { //Megadott elemek
                    let spanElement = imageContainer.appendChild(document.createElement("span"));
                    spanElement.classList.add("piramid-answer-item", "piramid-answer-fix");
                    spanElement.innerText = answer_row[answer_row_id].toString();
                    ($(spanElement) as any).fitText(0.7);
                    if (answer_column_id == answer_row.length - 1)
                        k += spanElement.getBoundingClientRect().width + 10;
                }
                else { //Input elemek
                    let inputElement: HTMLInputElement;
                    inputElement = imageContainer.appendChild(document.createElement("input"));
                    inputElement.classList.add("piramid-answer-item");
                    inputElement.setAttribute('placeholder', '?');
                    inputElement.setAttribute("required", "true");
                    inputElement.classList.add("question");
                    if (this.isSNIexc) inputElement.addEventListener('blur', this.SNIEvaluation.bind(this, WMNumberPyramidServer));

                    if (this.isReplay) {
                        inputElement.setAttribute('disabled','disabled');
                    }

                    inputElement.onkeydown = function () {
                        // @ts-ignore
                        this.parityKeyPressData = $(inputElement).val() + "".toString();
                    }.bind(this);
                    inputElement.onkeyup = function () {
                        // @ts-ignore
                        if (this.parityKeyPressData != $(inputElement).val() + "".toString()) {
                            // @ts-ignore
                            this.changeInteraction(true);
                        }
                    }.bind(this);

                    this.divList[answer_column_id].push(inputElement);
                    // if (answer_column_id == 2) {

                    // }
                    if (answer_column_id == answer_row.length - 1)
                        k += inputElement.getBoundingClientRect().width + 10;
                }

            }
            //Sor render után törjük a sort
            imageContainer.appendChild(document.createElement("br"));
        }
        contentWrapper.style.minWidth = k.toString();

        // '-'-os végződések "kiszűrése"
        let tmpArray = [];
        for (let i = 0; i < this.divList.length; i++) {
            tmpArray[i] = this.divList[i];
        }
        this.divList = tmpArray;
    }

    public changeInteraction(bool: boolean) {
        this.interaction = bool;
    }

    getUserSolution(): WMDPiramidSolution {
        let result: any = [];
        for (let outerIndex = 0; outerIndex < this.divList.length; outerIndex++) {
            result[outerIndex] = [];
            for (let innerIndex = 0; innerIndex < this.divList[outerIndex].length; innerIndex++) {
                result[outerIndex][innerIndex] = /*parseInt*/($(this.divList[outerIndex][innerIndex]).val() + "").toString(); //previously it was parsing value to integer, thus it was not possible to hava non-integer solution
            }
        }
        return { answer: result, fullmatch: true };
    }


    receiveEvaluation(evaluated: Evaluated): void {
        if (evaluated.success) {
            for (let index = 0; index < this.divList.length; index++) {
                for (let innerIndex = 0; innerIndex < this.divList[index].length; innerIndex++) {
                    let item = (this.divList[index][innerIndex]);
                    AExerciseEngine.removeEvalStyle(item);
                    item.classList.add("exe-engine-check-correct");
                }
            }
        }
        if (!evaluated.success) {

            if (evaluated.solution != null) {
                let userSolution: any[] = this.getUserSolution().answer;

                let correctSolution = evaluated.solution;

                for (let index = 0; index < this.divList.length; index++) {
                    for (let innerIndex = 0; innerIndex < this.divList[index].length; innerIndex++) {
                        let item = (this.divList[index][innerIndex] as HTMLElement);
                        AExerciseEngine.removeEvalStyle(item);

                        if (userSolution[index][innerIndex] != correctSolution[index][innerIndex]) {
                            if (!this.isSNIexc) item.classList.add("exe-engine-check-wrong");
                        }
                        else {
                            item.classList.add("exe-engine-check-correct");
                        }
                    }
                }
            }
        }
    }

    showCorrectSolution(solution: any): void {
        this.changeInteraction(false);
        for (let index = 0; index < solution.length; index++) {
            for (let sol_indx = 0; sol_indx < solution[index].length; sol_indx++) {
                let $answer_box = $(this.divList[index][sol_indx]);
                $answer_box.val(solution[index][sol_indx]);
                AExerciseEngine.removeEvalStyle(this.divList[index][sol_indx]);
                //$answer_box.removeClass("exe-engine-wrong-bg");
                $answer_box.addClass("exe-engine-correct-bg");
            }
            // $image.addClass("exe-engine-correct-bg");
        }
    }

    isUserReady(): boolean {
        return this.interaction
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

}