import { callApiThrowOnError } from "@src/framework/api/Api";

export type EvaluateParams = {
    exerciseIndex: number,
    runId?: string,
    shareContentRunId?: number,
    solution?: any,
};

export type EvaluateResult = {
    solution: any | null,
    state: {
        allExercisesCompleted: boolean,
        canSkipExercise: boolean,
        canUserEvaluate: boolean,
        earnedPoints: number,
        exerciseCount: number,
        exerciseDetails: {
            evaluationTimeStamp: {
                cdate: {
                    cachedFixedDateJan1: number,
                    cachedFixedDateNextJan1: number,
                    cachedYear: number,
                } | null,
                defaultCenturyStart: number,
                fastTime: number,
                gcal: {
                    $assertionsDisabled: boolean,
                    ACCUMULATED_DAYS_IN_MONTH: number[] | null,
                    ACCUMULATED_DAYS_IN_MONTH_LEAP: number[] | null,
                    APRIL: number,
                    AUGUST: number,
                    BASE_YEAR: number,
                    DAYS_IN_MONTH: number[] | null,
                    DECEMBER: number,
                    FEBRUARY: number,
                    FIXED_DATES: number[] | null,
                    FRIDAY: number,
                    JANUARY: number,
                    JULY: number,
                    JUNE: number,
                    MARCH: number,
                    MAY: number,
                    MONDAY: number,
                    NOVEMBER: number,
                    OCTOBER: number,
                    SATURDAY: number,
                    SEPTEMBER: number,
                    SUNDAY: number,
                    THURSDAY: number,
                    TUESDAY: number,
                    WEDNESDAY: number,
                } | null,
                jcal: {
                    $assertionsDisabled: boolean,
                    ACCUMULATED_DAYS_IN_MONTH: number[] | null,
                    ACCUMULATED_DAYS_IN_MONTH_LEAP: number[] | null,
                    APRIL: number,
                    AUGUST: number,
                    BASE_YEAR: number,
                    DAYS_IN_MONTH: number[] | null,
                    DECEMBER: number,
                    FEBRUARY: number,
                    FIXED_DATES: number[] | null,
                    FRIDAY: number,
                    JANUARY: number,
                    JULY: number,
                    JUNE: number,
                    MARCH: number,
                    MAY: number,
                    MONDAY: number,
                    NOVEMBER: number,
                    OCTOBER: number,
                    SATURDAY: number,
                    SEPTEMBER: number,
                    SUNDAY: number,
                    THURSDAY: number,
                    TUESDAY: number,
                    WEDNESDAY: number,
                } | null,
                serialVersionUID: number,
                ttb: number[] | null,
                wtb: string[] | null,
            } | null,
            exerciseEngineName: string | null,
            exerciseIndex: number,
            exerciseRecord: any | null,
            filteredCorrectSolutionJSON: any | null,
            filteredExerciseJSON: any | null,
            successPercent: number | null,
            totalPoints: number,
            userSolutionJSON: any | null,
        } | null,
        exerciseSeriesRecord: any | null,
        isFinalExercise: boolean,
        runId: string | null,
        sharedContentRunId: number | null,
        successCount: number,
        totalExerciseCount: number | null,
        totalPoints: number,
    } | null,
    success: boolean,
    successPercent: number | null,
};

export async function evaluate(parameters: EvaluateParams): Promise<EvaluateResult> {
    return callApiThrowOnError<EvaluateParams, EvaluateResult>("/exercise/evaluate", parameters);
}
