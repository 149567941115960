import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';

import { IHeadTableRecord, HeadTableCrudClassProxy, headTableCrudClassProxy } from '@src/framework/crud/wf/HeadTableCrud';
import RecordEditor from '@framework/forms/recordeditor';
import { IFieldInfo } from '@src/framework/crud/Meta';
import { IFieldEditorProp } from '@src/framework/forms/recordeditor';
import ViewWfHeadDetailTable, { IViewWfHeadDetailTableRecord } from '@src/framework/view/wf/ViewWfHeadDetailTable';

import { app } from '@src/index';
import DetailTableCrud from '@src/framework/crud/wf/DetailTableCrud';
import { __ } from '@src/translation';


export default class HeadTableEditor extends RecordEditor<IHeadTableRecord> {
    public getCrudClassProxy(): HeadTableCrudClassProxy { return headTableCrudClassProxy; }
    protected getFieldEditorProps(fieldInfo: IFieldInfo): IFieldEditorProp {
        let result: IFieldEditorProp = super.getFieldEditorProps(fieldInfo);
        if (fieldInfo.field_name == "wf_type_id")
            result.disabled = true;
        return result;
    }


    protected getDetailPanels(): any[] {
        let result = super.getDetailPanels();
        if (this.state.rec_id) {
            result = result.concat([
                <h4 key="head_detail_checklist">{__("Tétel táblák kiválasztása")}</h4>,
                <DetailTableCheckList key="data_detail_checklist" headTableId={this.state.rec_id} />
            ]);
        }
        return result;
    }
}

interface IDetailTableCheckListProps {
    headTableId: number;
}
interface IDetailTableCheckListState {
    items?: IViewWfHeadDetailTableRecord[];
    loading: boolean;
}

class DetailTableCheckList extends React.Component<IDetailTableCheckListProps, IDetailTableCheckListState> {
    constructor(props: any) {
        super(props);
        this.state = { loading: true };
    }

    componentWillMount() {
        this.asyncInit();
    }

    private asyncInit = async () => {
        try {
            const items = await ViewWfHeadDetailTable.list({
                filter: { head_table_id: this.props.headTableId },
                order_by: [{ name: 'schema_name' }, { name: 'table_name' }]
            });
            this.setState({ items, loading: false })
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private setCheckbox = async (tableInfoId: number, fieldInfoId: number, checked: boolean) => {
        this.setState({ loading: true })
        try {
            const crud = new DetailTableCrud({
                head_id: this.props.headTableId,
                table_id: tableInfoId,
                ref_field_id: fieldInfoId,
                is_active: checked
            })
            await crud.upsert(['head_id', 'table_id', 'ref_field_id']);
            this.asyncInit();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {
        if (this.state.items === undefined || this.state.loading) {
            return <BubbleLoader />;
        } else {
            return <div className="row">
                <div className="column small-12">
                    <p className="callout warning">
                        <b>{__("Csak szakértőknek!")}</b>
                        &nbsp;
                        <span className="label alert">
                        {__("A kattintás azonnal módosít!")}
                        </span>
                        &nbsp;
                        {__("Egy kis segítség: ha egy kapcsolódási pont törlési szabálya nem cascade, akkor az valószínűleg nem tétel táblázat, mert a folyamat fő rekordja nem birtokolja...")}
                    </p>
                </div>
                <div className="column small-12">
                    <table>
                        <thead>
                            <tr>
                                <th>{__("Engedélyezve")}</th>
                                <th>{__("Táblázat")}</th>
                                <th>{__("Hivatkozó mező")}</th>
                                <th>{__("Törlési szabály")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.items.map((item) =>
                                <tr key={
                                    item.schema_name + "." +
                                    item.table_name + "." +
                                    item.field_name}
                                    title={item.modifier
                                        ? (item.modifier + " @ " + item.modification_time)
                                        : (item.creator + " @ " + item.creation_time)
                                    }
                                >
                                    <td>
                                        <input type="checkbox"
                                            checked={(item.id && item.is_active) || false}
                                            onChange={() => {
                                                this.setCheckbox(
                                                    item.key_table_id!,
                                                    item.key_field_id!,
                                                    (item.id && item.is_active) ? false : true
                                                )
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <span className="label primary">{item.schema_name}.{item.table_name}</span>
                                    </td>
                                    <td>
                                        <span className="label secondary">{item.field_name}</span>
                                    </td>
                                    <td>
                                        <span className={
                                            "label " + (item.fk_delete_rule == "cascade" ? "success" : "alert")
                                        }>
                                            {item.fk_delete_rule}
                                        </span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

        }
    }
}