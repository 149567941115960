import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IOccupationVisitRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Szakmai támogató
     *
     * -> sys.sec_user ON DELETE noaction
     *
     */
    kap_supporter_id?: number|null;
    /**
     * Legkorábbi kezdő időpont
     *
     * Ezt a szakmai támogató adja meg. Ez a legkorábbi időpont ahonnan igénybe lehet venni.
     */
    min_start_date?: string /*timestamp?*/;
    /**
     * Legkésőbbi befejező időpont
     *
     * Ezt a szakmai támogató adja meg. Ez a legkésőbbi időpont ahonnan igénybe lehet venni.
     */
    max_end_date?: string /*timestamp?*/;
    /**
     * Alprogram
     *
     * NOT NULL -> kap.subprogram ON DELETE noaction
     *
     */
    subprogram_id?: number;
    /**
     * Intézmény feladat ellátási hely
     *
     * -> sys.institute_site ON DELETE noaction
     *
     */
    institute_site_id?: number|null;
    /**
     * A foglalkozás látogatás gyökér könyvtára.
     *
     * NOT NULL -> media.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number;
    /**
     * Belső
     *
     * A belső foglalkozásokra csak intézményen belüli pedagógusok jelentkezhetnek
     */
    is_internal?: boolean;
    /**
     * Terem szám
     */
    room_no?: string|null;
    /**
     * Kezdő időpont
     *
     * Ez a feladatellátási hellyel közösen egyeztetett kezdési időpont.
     */
    start_date?: string /*timestamp?*/|null;
    /**
     * Befejező időpont
     *
     * Ez a feladatellátási hellyel közösen egyeztetett kezdési időpont.
     */
    end_date?: string /*timestamp?*/|null;
    /**
     * Óratartó pedagógus
     *
     * -> sys.sec_user ON DELETE noaction
     *
     */
    teacher_id?: number|null;
    /**
     * Jelentkezés megnyitása
     *
     * Ettől az időponttól kezdve lehet jelentkezni
     */
    reserve_after?: string /*timestamp?*/|null;
    /**
     * Jelentkezés lezárása
     *
     * Eddig az időpontig lehet jelentkezni
     */
    reserve_before?: string /*timestamp?*/|null;
    /**
     * Látogatók min. száma
     */
    min_visitors?: number /*integer*/|null;
    /**
     * Látogatók max. száma
     */
    max_visitors?: number /*integer*/|null;
    /**
     * A foglalkozás célja
     */
    goal?: string|null;
    /**
     * Csoport/osztály típusa
     *
     * -> kap.group_type ON DELETE noaction
     *
     */
    group_type_id?: number|null;
    /**
     * Csoport/Osztály típusa (egyéb)
     */
    group_type_other?: string|null;
    /**
     * Tematikus egység
     */
    thematic_unit?: string|null;
    /**
     * Témakör
     */
    topic?: string|null;
    /**
     * Évfolyam
     *
     * -> doc.grade ON DELETE noaction
     *
     */
    grade_id?: number|null;
    /**
     * Támogató jóváhagyta
     *
     * Az az időpont, amikor a támogató jóváhagyta az időpontot és a feladatellátási helyet.
     */
    supporter_approved?: string /*timestamp?*/|null;
    /**
     * Kapcsolattartó jóváhagyta
     *
     * Az az időpont, amikor a feladatellátási hely kapcsolattartója jóváhagyta az időpontot és a szakmai támogatót.
     */
    site_approved?: string /*timestamp?*/|null;
    /**
     * Foglalkozásterv
     *
     * -> kap.occupation_draft ON DELETE noaction
     *
     */
    occupation_draft_id?: number|null;
}

/**
 *  kap.occupation_visit - Alprogrami csoport szintű foglalkozás látogatás
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class OccupationVisitCrud extends Crud<IOccupationVisitRecord> {
    public static TABLE_INFO_ID = 2870603602;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'occupation_visit'; }

    public static load: (id: number, aServer ?: Server) => Promise<OccupationVisitCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IOccupationVisitRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IOccupationVisitRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IOccupationVisitRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IOccupationVisitRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IOccupationVisitRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class OccupationVisitCrudClassProxy extends CrudClassProxy<IOccupationVisitRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const occupationVisitCrudClassProxy = new OccupationVisitCrudClassProxy(OccupationVisitCrud);
registerCrudClassProxy(occupationVisitCrudClassProxy);
