import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewCmsContentPostRecord {
    id?: number;
    /**
     * Létrehozás ideje
     *
     * Létrehozás ideje
     */
    creation_time?: string /*timestamp?*/|null;
    /**
     * Site azonosító
     *
     * Site azonosító
     */
    site_id?: number|null;
    /**
     * Tartalom típus azonosító
     *
     * Tartalom típus azonosító
     */
    content_type_id?: number|null;
    /**
     * Mikortól látható
     *
     * Mikortól látható
     */
    visible_from?: string /*timestamp?*/|null;
    /**
     * Látható
     *
     * Látható
     */
    is_visible?: boolean|null;
    /**
     * Al-site
     *
     * Al-site
     */
    subsite_id?: number|null;
    /**
     * Kezdõ idõpont
     *
     * Kezdõ idõpont
     */
    start_date?: string /*timestamp?*/|null;
    /**
     * Záró idõpont
     *
     * Záró idõpont
     */
    end_date?: string /*timestamp?*/|null;
    /**
     * Teljes napok
     *
     * Teljes napok
     */
    whole_days?: boolean|null;
    /**
     * Publikus
     *
     * Publikus
     */
    is_public?: boolean|null;
    /**
     * Nyelv azonosító
     *
     * Nyelv azonosító
     */
    lang_id?: number|null;
    /**
     * Ízelítő
     *
     * Ízelítő
     */
    teaser?: string|null;
    /**
     * Cím
     *
     * Cím
     */
    title?: string|null;
    /**
     * URI szegmens
     *
     * URI szegmens
     */
    uri_segment?: string|null;
    /**
     * SHA1
     *
     * SHA1
     */
    sha1?: string|null;
    /**
     * Szöveg
     *
     * Szöveg
     */
    html_body?: string|null;
    /**
     * Tartalom aktív
     *
     * Tartalom aktív
     */
    content_is_active?: boolean|null;
    /**
     * Aktív
     *
     * Tartalom fordítás aktív
     */
    is_active?: boolean|null;
    /**
     * Aloldal név
     *
     * Aloldal név
     */
    subsite_title?: string|null;
    /**
     * Aloldal rövid név
     *
     * Aloldal rövid név
     */
    subsite_shortname?: string|null;
    /**
     * Aloldal színe
     *
     * Aloldal színe
     */
    subsite_color?: string|null;
    /**
     * Beküldő
     *
     * Beküldő
     */
    creator?: string|null;
    /**
     * Utolsó módosító
     *
     * Utolsó módosító
     */
    modifier?: string|null;
}

/**
 *  cms.view_cms_content_post - CMS post
 *
 *  CMS post
 */

export default class ViewCmsContentPost extends View<IViewCmsContentPostRecord> {
    public static VIEW_INFO_ID = 2019100101;

    public static getSchemaNameForClass(): string { return 'cms'; }
    public static getViewNameForClass(): string { return 'view_cms_content_post'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewCmsContentPost>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewCmsContentPostRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewCmsContentPostClassProxy extends ViewClassProxy<IViewCmsContentPostRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewCmsContentPostClassProxy = new ViewCmsContentPostClassProxy(ViewCmsContentPost);
registerViewClassProxy(viewCmsContentPostClassProxy);

