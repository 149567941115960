import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewOauthClientRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Név
     */
    name?: string;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Client id
     */
    client_id?: string;
    /**
     * Titkos kulcs
     */
    secret?: string;
    /**
     * A kliens root URL-je, záró perjel nélkül.
     *
     * Ez az ami általában úgy kezdődik hogy https:// és a context root-ra mutat.
     */
    root_url?: string;
    /**
     * Megbízható
     *
     * A megbízható kliensek közvetlenül hozzáférnek az adatbázishoz, ezért nem kell scope-ra engedélyt osztani.
     */
    is_trusted?: boolean;
    /**
     * Nyilvános
     */
    is_public?: boolean;
    /**
     * 2FA
     *
     * Két faktoros azonosítást igényel.
     */
    need_2fa?: boolean;
    /**
     * Létrehozta
     *
     * Felhasználó
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
}

/**
 *  sys.view_oauth_client - Kliens (oauth2)
 *
 *  Azok a külső vagy belső weblapok, amik az nkp oauth2 szervert használják.
 */

export default class ViewOauthClient extends View<IViewOauthClientRecord> {
    public static VIEW_INFO_ID = 201804171119;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_oauth_client'; }
    public static getTableNameForClass(): string|null { return 'oauth_client'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewOauthClient>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewOauthClientRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewOauthClientClassProxy extends ViewClassProxy<IViewOauthClientRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewOauthClientClassProxy = new ViewOauthClientClassProxy(ViewOauthClient);
registerViewClassProxy(viewOauthClientClassProxy);

