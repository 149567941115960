
import { ModalTypes } from '@src/component/exercise/Editor/ExerciseEditor';
import { EKECrossWordExerciseEngine } from '../engine/EKECrossWordExerciseEngine/EKECrossWordExerciseEngine';
import { EKEFillGapDnDExerciseEngine } from '../engine/EKEFillGapDnDExerciseEngine/EKEFillGapDnDExerciseEngine';
import { EKEFillGapDropDownExerciseEngine } from '../engine/EKEFillGapDropDownExerciseEngine/EKEFillGapDropDownExerciseEngine';
import { EKEFillTableDnDExerciseEngine } from '../engine/EKEFillTableDnDExerciseEngine/EKEFillTableDnDExerciseEngine';
import { EKEMultiTextAnswerExerciseEngine } from '../engine/EKEMultiTextAnswerExerciseEngine/EKEMultiTextAnswerExerciseEngine';
import { EKEOddOneOutExerciseEngine } from '../engine/EKEOddOneOutExerciseEngine/EKEOddOneOutExerciseEngine';
import { EKEPairingDnDExerciseEngine } from '../engine/EKEPairingDnDExerciseEngine/EKEPairingDnDExerciseEngine';
import { EKEPairingExerciseEngine } from '../engine/EKEPairingExerciseEngine/EKEPairingExerciseEngine';
import { EKEQuizExerciseEngine } from '../engine/EKEQuizExerciseEngine/EKEQuizExerciseEngine';
import { EKEQuizSeriesExerciseEngine } from '../engine/EKEQuizSeriesExerciseEngine/EKEQuizSeriesExerciseEngine';
import { EKESetsWithIntersectionExerciseEngine } from '../engine/EKESetsWithIntersectionExerciseEngine/EKESetsWithIntersectionExerciseEngine';
import { EKESortingExerciseEngine } from '../engine/EKESortingExerciseEngine/EKESortingExerciseEngine';
import { EKESudokuExerciseEngine } from '../engine/EKESudokuExerciseEngine/EKESudokuExerciseEngine';
import { WMAssignPointToQuestionExerciseEngine } from '../engine/WMAssignPointToQuestionExerciseEngine/WMAssignPointToQuestionExerciseEngine';
import { WMFillTableTextExerciseEngine } from '../engine/WMFillTableTextExerciseEngine/WMFillTableTextExerciseEngine';
import { WMItemLinkLineExerciseEngine } from '../engine/WMItemLinkLineExerciseEngine/WMItemLinkLineExerciseEngine';
import { WMItemToImageDnDExerciseEngine } from '../engine/WMItemToImageDnDExerciseEngine/WMItemToImageDnDExerciseEngine';
import { WMItemToSetDnDExerciseEngine } from '../engine/WMItemToSetDnDExerciseEngine/WMItemToSetDnDExerciseEngine';
import { WMNumberLineExerciseEngine } from '../engine/WMNumberLineExerciseEngine/WMNumberLineExerciseEngine';
import { WMNumberPyramidExerciseEngine } from '../engine/WMNumberPyramidExerciseEngine/WMNumberPyramidExerciseEngine';
import { UniversalExerciseEngine } from '../engine/UniversalEngine/UniversalExerciseEngine';
import { PuzzleGame } from '../engine/PuzzleGame/PuzzleGame';
import { MemoryCardGame } from '../engine/MemoryCardGame/MemoryCardGame';
import { WordFinderGame } from '../engine/WordFinderGame/WordFinderGame';
import { ColoringGame } from '../engine/ColoringGame/ColoringGame';
import { FillGapTextExerciseEngine } from '../engine/FillGapTextExerciseEngine/FillGapTextExerciseEngine';

import { __ } from '@src/translation';

export interface ExerciseBaseClass {
    title: string; // A feladat neve
    internal_code:string; // OFI belső azonosító
    description: string;
    imagebasepath: string;
    backgroundStyle: { is_custom_background: boolean, backgroundImage: string, backgroundColor: string };
    illustration: string;
    illustration_alt: string;
    sound:string;
    // keywords: string;  A kulcsszavak a javasolt helyes megfejtéshez
    level: number;
    demo_path: string;
    //imageanswers: boolean;
    imagequestion: boolean;
    comment: string;
    solution: any[];
    questions:any;
    options:any;
    show_search_results:boolean;
    is_accessible:boolean | null;
    all_correct: boolean;
    ordered_answers: boolean;
}

export type MessageBoxContent = {
    title?: string;
    content?: any;
    type?: ModalTypes;
}

export enum NavigationDirections{
    Prevoius=-1,
    Current=0,
    Next=1
}

// AExerciseConverter típusok

export type AnswerObject = {
    is_answer: boolean,
    answer: string | null
}

export type AnswerList = {
    answers: AnswerObject[]
}

export type AnswerElement = {
    type: any,
    text: string,
    image: string,
    url?: string
}

export type CheckableAnswerElement = {
    is_answer: boolean,
    text?: string,
    image?: string,
}

export type CheckableMultiTypeAnswerItem = {
    is_answer: boolean,
    text?: string,
    url?: string,
    type: AnswerTypes
}

export enum AnswerTypes{
    text,
    image,
    sound
}

/*export enum AnswerTypeStrings{
    text="text",
    image="image",
    sound="sound"
}*/

export type QuestionAnswerElement = {
    question: string,
    answers: CheckableMultiTypeAnswerItem[],
    question_illustration?: string,
    multiple_answers?: boolean
}

// Feladatmotor típusok

export enum ExerciseEngineTypes {
    Quiz = "EKEQuizExerciseEngine",
    QuizSeries = "EKEQuizSeriesExerciseEngine",
    CrossWord = "EKECrossWordExerciseEngine",
    Sorting = "EKESortingExerciseEngine",
    Sudoku = "EKESudokuExerciseEngine",
    MultiTextAnswer = "EKEMultiTextAnswerExerciseEngine",
    FillTableDnD = "EKEFillTableDnDExerciseEngine",
    PairingDnD = "EKEPairingDnDExerciseEngine",
    OddOneOut = "EKEOddOneOutExerciseEngine",
    PairingQuiz = "EKEPairingExerciseEngine",
    CalculationGenerator = "EKECalculationGeneratorEngine",
    FillGapDropDown = "EKEFillGapDropDownExerciseEngine",
    FillGapDnD = "EKEFillGapDnDExerciseEngine",
    ItemToSet = "WMItemToSetDnDExerciseEngine",
    AssignPointToQuestion = "WMAssignPointToQuestionExerciseEngine",
    FillTableText = "WMFillTableTextExerciseEngine",
    NumberPyramid = "WMNumberPyramidExerciseEngine",
    ItemToImage = "WMItemToImageDnDExerciseEngine",
    NumberLine = "WMNumberLineExerciseEngine",
    LinkLine = "WMItemLinkLineExerciseEngine",
    Universal = "UniversalExerciseEngine",
    Series = "Series",
    Puzzle = "PuzzleGame",
    Memory = "MemoryCardGame",
    WordFinder = "WordFinderGame",
    Coloring = "ColoringGame",
    FillGapText = "FillGapTextExerciseEngine"
}

/**
 * Feladatmotor osztály lista, a szerveroldal használja
 */
export const exerciseEngineClasses = {
    EKECrossWordExerciseEngine,
    EKEFillGapDnDExerciseEngine,
    EKEFillGapDropDownExerciseEngine,
    EKEFillTableDnDExerciseEngine,
    EKEMultiTextAnswerExerciseEngine,
    EKEOddOneOutExerciseEngine,
    EKEPairingDnDExerciseEngine,
    EKEPairingExerciseEngine,
    EKEQuizExerciseEngine,
    EKEQuizSeriesExerciseEngine,
    EKESetsWithIntersectionExerciseEngine,
    EKESortingExerciseEngine,
    EKESudokuExerciseEngine,
    WMAssignPointToQuestionExerciseEngine,
    WMFillTableTextExerciseEngine,
    WMItemLinkLineExerciseEngine,
    WMItemToImageDnDExerciseEngine,
    WMItemToSetDnDExerciseEngine,
    WMNumberLineExerciseEngine,
    WMNumberPyramidExerciseEngine,
    UniversalExerciseEngine,
    PuzzleGame,
    MemoryCardGame,
    WordFinderGame,
    ColoringGame,
    FillGapTextExerciseEngine
}

export function convertToBaseClass(exercise: any): ExerciseBaseClass | undefined {

    if (exercise) {

        let bg_img = exercise.backgroundImage;
        if (exercise.backgroundStyle && exercise.backgroundStyle.backgroundImage) {
            bg_img = exercise.backgroundStyle.backgroundImage;

            if (bg_img.indexOf('url("') > -1) {
                let imageBaseURL = window.location.hostname == "" ? "" : "/";
                var newPath = String(exercise.imagebasepath);
                let re = 'url("' + imageBaseURL + newPath.substring(1, newPath.length);
                bg_img = bg_img.replace(re, "");
                re = '")';
                bg_img = bg_img.replace(re, "");
            }
        }

        var baseData: ExerciseBaseClass = {
            title: String(exercise.title),
            internal_code:exercise.internal_code ? String(exercise.internal_code) : "",
            description: String(exercise.description),
            backgroundStyle: Object({
                is_custom_background: (exercise.backgroundStyle ? exercise.backgroundStyle.is_custom_background : (exercise.backgroundImage ? true : false)),
                backgroundImage: (exercise.backgroundStyle ? exercise.backgroundStyle.backgroundImage : exercise.backgroundImage),
                backgroundColor: (exercise.backgroundStyle ? exercise.backgroundStyle.backgroundColor : null)
            }),
            imagebasepath: exercise.imagebasepath? String(exercise.imagebasepath):window.location.hostname == "" ? "" : "/",
            illustration: String(exercise.illustration),
            illustration_alt: String(exercise.illustration_alt),
            sound:exercise.sound?String(exercise.sound):"",
            demo_path: String(exercise.demo_path),
            //imageanswers: Boolean(exercise.imageanswers),
            imagequestion: Boolean(exercise.imagequestion),
            //keywords:String(exercise.keywords),
            level: Number(exercise.level),
            show_search_results: (exercise.show_search_results === true || exercise.show_search_results === undefined),
            comment: (exercise.comment && exercise.comment != "undefined") ? String(exercise.comment) : "",
            solution: [],
            questions:undefined,
            options:undefined,
            is_accessible: exercise.is_accessible,
            all_correct: exercise.all_correct,
            ordered_answers: exercise.ordered_answers
        };
        return baseData;

    } else
        return undefined;
}

export function convertToBaseJson(exercise: ExerciseBaseClass): any {
    if (exercise) {

        const imageBaseURL = window.location.hostname == "" ? "" : "/";
        var backgroundImage = null;
        if (exercise && exercise.backgroundStyle && exercise.backgroundStyle.backgroundImage && exercise.backgroundStyle.backgroundImage != "undefined") {
            var newPath = String(exercise.imagebasepath);
            // backgroundImage = 'url("' + imageBaseURL + newPath.substring(1, newPath.length) + exercise.backgroundStyle.backgroundImage + '")';
            backgroundImage = exercise.backgroundStyle.backgroundImage;
        }

        var baseData: any = {
            title: exercise.title,
            internal_code:exercise.internal_code,
            description: exercise.description,
            /*backgroundStyle: Object({
                is_custom_background: exercise.backgroundStyle.is_custom_background,
                backgroundImage: backgroundImage,
                backgroundColor: exercise.backgroundStyle.backgroundColor
            }),*/
            imagebasepath: exercise.imagebasepath,
            illustration: exercise.illustration,
            illustration_alt: exercise.illustration_alt,
            sound:exercise.sound,
            demo_path: exercise.demo_path,
            //imageanswers: exercise.imageanswers,
            imagequestion: exercise.imagequestion,
            show_search_results:Boolean(exercise.show_search_results),
            //keywords:String(exercise.keywords),
            level: exercise.level,
            comment: exercise.comment,
            is_accessible: exercise.is_accessible,
            all_correct: exercise.all_correct,
            ordered_answers: exercise.ordered_answers
        };

        /*if (exercise.backgroundStyle.is_custom_background == false) {
            baseData.backgroundStyle = { backgroundImage: null, backgroundColor: null };
        }*/

        return baseData;

    } else
        return undefined;
}

export function baseJSONToString(exercise: any): string {
    var baseData =
        (exercise.title ? exercise.title + ", " : "") +
        (exercise.description ? exercise.description + ", " : "") +
        (exercise.illustration_alt ? exercise.illustration_alt + ", " : ""); // todo: illustration image alt tag      

    /*if (exercise.comment && exercise.comment != "undefined")
        baseData += exercise.comment;*/

    return baseData;
}

export function convertOldNKPToAnswerElement(oldNKPObject: any, fullNKPJson: any): any {
    let newAns = { type: "", text: "", image: "" };
    if (oldNKPObject.AssetVersion || oldNKPObject.Asset || oldNKPObject.Picture) {
        var refId: any;
        if (oldNKPObject.AssetVersion)
            refId = oldNKPObject.AssetVersion["$ref"];
        else if (oldNKPObject.Asset)
            refId = oldNKPObject.Asset["$ref"];
        else if (oldNKPObject.Picture)
            refId = oldNKPObject.Picture["$ref"];

        let cur_asset = fullNKPJson.ContentVersionReferences.find(function (element: any) {
            return element["$id"] == refId;
        });
        newAns = {
            type: "image",
            text: oldNKPObject.AnswerText ? oldNKPObject.AnswerText : cur_asset.Title,
            image: cur_asset.ThumbnailUrl.substring(1)
        };

    } else {
        newAns = {
            type: "text",
            text: oldNKPObject.AnswerText,
            image: ""
        };
    }

    return newAns;
}