import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IKszrQuestionnarieInRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Alkalmazás szintű felhasználó
     *
     * NOT NULL -> sys.sec_user ON DELETE cascade
     *
     */
    sec_user_id?: number;
    /**
     * Nem (nő/férfi)
     *
     * -> sys.gender ON DELETE cascade
     *
     */
    gender_id?: number|null;
    /**
     * Név
     */
    name?: string|null;
    /**
     * Tartózkodási hely
     */
    current_address?: string|null;
    /**
     * Állampolgárság
     */
    nationality?: string|null;
    /**
     * Értesítési cím
     */
    notification_address?: string|null;
    /**
     * Telefon
     */
    tel?: string|null;
    /**
     * E-mail
     */
    email?: string|null;
    /**
     * Születési név
     */
    birth_name?: string|null;
    /**
     * Születési hely
     */
    birth_place?: string|null;
    /**
     * Születési dátum
     */
    birth_date?: string /*date?*/|null;
    /**
     * Anyja neve
     */
    mother_birth_name?: string|null;
    /**
     * TAJ szám
     */
    taj_num?: string|null;
    /**
     * Állandó lakcím
     */
    permanent_address?: string|null;
    /**
     * Alkalmazotti státusz
     */
    work_question?: string|null;
    /**
     * Közfoglalkoztatott vagyok.
     */
    is_public_employees?: string|null;
    /**
     * Határozott idejű munkaszerződéssel rendelkezem.
     */
    has_fixed_contract?: string|null;
    /**
     * Nappali tagozatos tanulói vagy hallgatói jogviszonnyal rendelkezem.
     */
    is_full_time_school?: string|null;
    /**
     * Regisztrált munkanélküli
     */
    registered_unemployed?: string|null;
    /**
     * Mióta munkanélküli
     */
    unemployed_from?: string|null;
    /**
     * Részesül-e valamilyen ellátásban
     */
    supply?: string|null;
    /**
     * Legmagasabb iskolai végzettsége
     */
    school_grade?: string|null;
    /**
     * Jelenleg oktatásban vagy képzésben részt veszek
     */
    is_student?: string|null;
    /**
     * Nemzetiség
     */
    nationality_feel?: string|null;
    /**
     * Hajléktalan
     */
    homeless?: string|null;
    /**
     * Megváltozott munkaképességű
     */
    handicapped?: string|null;
    /**
     * Fogyatékossággal élő
     */
    handicapped2?: string|null;
    /**
     * Nem tudom hogy mi ez bocsi
     */
    consort_work?: string|null;
    /**
     * Van eltartott gyerek a háztartásban
     */
    has_dependent_child?: string|null;
    /**
     * Csak egy felnőtt és legalább egy eltartott gyermek
     */
    one_adult_and_has_dependent_child?: string|null;
    /**
     * Mióta nem dolgozik
     */
    work_question_no_work?: string|null;
}

/**
 *  kap.kszr_questionnarie_in - KSZR bemeneti kérdőív
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class KszrQuestionnarieInCrud extends Crud<IKszrQuestionnarieInRecord> {
    public static TABLE_INFO_ID = 1130464229;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'kszr_questionnarie_in'; }

    public static load: (id: number, aServer ?: Server) => Promise<KszrQuestionnarieInCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IKszrQuestionnarieInRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IKszrQuestionnarieInRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IKszrQuestionnarieInRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IKszrQuestionnarieInRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IKszrQuestionnarieInRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class KszrQuestionnarieInCrudClassProxy extends CrudClassProxy<IKszrQuestionnarieInRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const kszrQuestionnarieInCrudClassProxy = new KszrQuestionnarieInCrudClassProxy(KszrQuestionnarieInCrud);
registerCrudClassProxy(kszrQuestionnarieInCrudClassProxy);
