import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IMessageRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Amihez tartozik - Táblázat infó
     *
     * -> meta.table_info ON DELETE noaction
     *
     *
     * Ez mondja meg, hogy az üzenet melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number|null;
    /**
     * Amihez tartozik - rekord azonosító
     *
     * Ez mondja meg, hogy az üzenet melyik rekordhoz tartozik.
     */
    rec_id?: number|null;
    /**
     * Tárgy
     */
    subject?: string;
    /**
     * Üzenet szövege
     */
    body_html?: string;
    /**
     * Feldolgozva
     */
    processed?: string /*timestamp?*/|null;
    /**
     * Válasz erre
     *
     * -> usr.message ON DELETE setnull
     *
     */
    reply_to_id?: number|null;
    /**
     * Site, ahol az üzenetet föladták
     *
     * NOT NULL -> cms.site ON DELETE noaction
     *
     */
    site_id?: number;
    /**
     * Látta
     */
    sender_seen?: string /*timestamp?*/|null;
    /**
     * Törölve
     */
    sender_deleted?: string /*timestamp?*/|null;
    /**
     * Véglegesen törölve
     */
    sender_purged?: string /*timestamp?*/|null;
}

/**
 *  usr.message - Üzenet
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class MessageCrud extends Crud<IMessageRecord> {
    public static TABLE_INFO_ID = 3774088688;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'message'; }

    public static load: (id: number, aServer ?: Server) => Promise<MessageCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IMessageRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IMessageRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IMessageRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IMessageRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IMessageRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class MessageCrudClassProxy extends CrudClassProxy<IMessageRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const messageCrudClassProxy = new MessageCrudClassProxy(MessageCrud);
registerCrudClassProxy(messageCrudClassProxy);
