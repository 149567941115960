import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IBookRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Könyvtár
     *
     * NOT NULL -> doc.library ON DELETE cascade
     *
     */
    library_id?: number;
    /**
     * Évfolyam
     *
     * NOT NULL -> doc.grade ON DELETE cascade
     *
     */
    grade_id?: number;
    /**
     * Tantárgy
     *
     * NOT NULL -> doc.subject ON DELETE cascade
     *
     */
    subject_id?: number;
    /**
     * A könyv gyökér könyvtára.
     *
     * -> media_pub.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number|null;
    /**
     * URL rész
     *
     * URL path (útvonal) szegmense
     */
    uri_segment?: string;
    /**
     * Szlogen
     *
     * A könyv szlogenje
     */
    slogan?: string|null;
    /**
     * Műveltségi terület
     *
     * -> doc.literacy_field ON DELETE noaction
     *
     */
    literacy_field_id?: number|null;
    /**
     * SNI
     *
     * Sajátos nevelési igényűek számára készített könyv
     */
    is_sni?: boolean;
    /**
     * Nyelv
     *
     * NOT NULL -> sys.lang ON DELETE cascade
     *
     *
     * A könyv nyelve
     */
    lang_id?: number;
    /**
     * Könyv megjelenítési mód
     *
     * NOT NULL -> doc.display_mode ON DELETE noaction
     *
     */
    display_mode_id?: number;
    /**
     * Site
     *
     * NOT NULL -> cms.site ON DELETE noaction
     *
     */
    site_id?: number;
    /**
     * Intézmény típus
     *
     * -> sys.institute_type ON DELETE noaction
     *
     */
    institute_type_id?: number|null;
}

/**
 *  doc_pub.book - Könyv
 */

export default class BookCrud extends Crud<IBookRecord> {
    public static TABLE_INFO_ID = 3355111223;
    public static getSchemaNameForClass() { return 'doc_pub'; }
    public static getTableNameForClass() { return 'book'; }

    public static canCache(): boolean {
        return true;
    }

    public static getDefaultMaxAge() : number|undefined {
        return 3600;
    }

    public static load: (id: number, aServer ?: Server) => Promise<BookCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IBookRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IBookRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IBookRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IBookRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IBookRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class BookCrudClassProxy extends CrudClassProxy<IBookRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const bookCrudClassProxy = new BookCrudClassProxy(BookCrud);
registerCrudClassProxy(bookCrudClassProxy);
