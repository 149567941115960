import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewUsrInstituteGroupNewsfeedRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Tanulócsoport
     */
    institute_group_id?: number|null;
    /**
     * Törzs
     *
     * Az üzenet törzse
     */
    body_html?: string;
    /**
     * Csoport neve
     *
     * A tancsoport neve
     */
    institute_group_name?: string|null;
    /**
     * Küldő bejelentkezési neve
     *
     * Küldő bejelentkezési neve
     */
    sender_login_name?: string|null;
    /**
     * Küldő e-mail címe
     *
     * Küldő e-mail címe
     */
    sender_email?: string|null;
    /**
     * Küldő teljes neve
     *
     * Küldő teljes neve
     */
    sender_fullname?: string|null;
}

/**
 *  usr.view_usr_institute_group_newsfeed - Tanulócsoport hírfolyam
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewUsrInstituteGroupNewsfeed extends View<IViewUsrInstituteGroupNewsfeedRecord> {
    public static VIEW_INFO_ID = 2020031801;

    public static getSchemaNameForClass(): string { return 'usr'; }
    public static getViewNameForClass(): string { return 'view_usr_institute_group_newsfeed'; }
    public static getTableNameForClass(): string|null { return 'institute_group_newsfeed'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewUsrInstituteGroupNewsfeed>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewUsrInstituteGroupNewsfeedRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewUsrInstituteGroupNewsfeedClassProxy extends ViewClassProxy<IViewUsrInstituteGroupNewsfeedRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewUsrInstituteGroupNewsfeedClassProxy = new ViewUsrInstituteGroupNewsfeedClassProxy(ViewUsrInstituteGroupNewsfeed);
registerViewClassProxy(viewUsrInstituteGroupNewsfeedClassProxy);

