import * as React from 'react';
import ViewMemberName, { IViewMemberNameRecord } from '../view/sys/ViewMemberName';
import { app } from '@src/index';
import { me } from '@framework/server/Auth';


/**
 * Ez egy felhasználó rekordot konvertál normál szövegbe. Ez a felhasználó "ember által olvasható" szöveges reprezentációja.
 * Azokon a helyeken használd, ahol csak sima szöveget tudsz kiírni.
 * Ahol HTML-t is tudsz, ott használd a MemberCard-ot vagy a MemberCardFromId -t.
 * 
 */
export const memberToString = (item: IViewMemberNameRecord | null): string => {
    let result: string = "";
    if (item && item.id) {
        if (item.fullname) {
            result = item.fullname;
        }
        if (item.email) {
            result = (result + " <" + item.email + ">").trim();
        }
        if (item.login_name) {
            result = (result + " login=" + item.login_name).trim();
        }
        if (!item.email && !item.login_name) {
            if (item.fullname) {
                result += " id=" + item.id!;
            } else {
                result = "<Ismeretlen felhasználó>, id=" + item.id!;
            }
        }
    }
    return result;
}

export interface IMemberCardExtraProps {
    memberFieldNamePrefix?: string; // Mezőnév prefix az átadott rekordhoz
    /**
     * Ha a short={true} be van állítva, akkor nem írja ki az összes létező
     * ismert mező értéket, hanem csak annyit amennyi szükséges a felhasználó
     * azonosításához. Ez van például használva az értesítési területen,
     * mivel ott nincs túl sok hely.
     * 
     * Ha short={false} (alapértelmezés), akkor minden mező értéket kiír amit
     * csak tud. Ez van például használva a felhasználó válsztó komponensnél.
     * Ott nincs hely probléma, de ott nagyon fontos hogy a felhasználó beazonosítása
     * minden kétséget kizáróan megtörténjen.
     * 
     */
    short?: boolean;
    medium?: boolean;
}

/**
 * Ez a komponens egy felhasználót tud megjeleníteni egy "kártyán".
 * 
 * A későbbiekben ehhez fogjuk hozzáadni az Avatar-t is.
 * Ahol felhasználót kellene megjeleníteni, ott ezt kellene használni.
 * 
 */
export default class MemberCard extends React.Component<IViewMemberNameRecord & IMemberCardExtraProps, {}> {
    constructor(props: IViewMemberNameRecord) {
        super(props);
    }
    render() {
        const prefix = this.props.memberFieldNamePrefix || "";
        const member_id = this.props[prefix + 'id'];
        const fullname = this.props[prefix + 'fullname'];
        const email = this.props[prefix + 'email'];
        const login_name = this.props[prefix + 'login_name'];

        if (this.props.short) {
            const title = memberToString({ id: member_id, fullname, email, login_name });
            const content =
                fullname || email || login_name || member_id || '<Ismeretlen felhasználó>';
            return <span className="short-member" title={title}>{content}</span>;
        } else if (this.props.medium) {
            return <>
                {(!fullname && !email && !login_name) ?
                    <>
                        &lt;<span className="secuserselector-item-noname">Ismeretlen nevű felhasználó</span>&gt;
                    </>
                    : null}
                {fullname ?
                    <span className="secuserselector-item-fullname">{fullname}</span>
                    : null}
                {email ?
                    <>
                        &nbsp;<i className="fa fa-at" /> <span className="secuserselector-item-email">{email}</span>
                    </>
                    : null}
            </>
        } else {
            return <>
                {fullname ?
                    <span className="secuserselector-item-fullname">{fullname}</span>
                    : null}
                {(!fullname && !email && !login_name) ?
                    <>
                        &lt;<span className="secuserselector-item-noname">Ismeretlen nevű felhasználó</span>&gt;
                    </>
                    : null}
                {email ?
                    <>
                        &nbsp;<i className="fa fa-at" /> <span className="secuserselector-item-email">{email}</span>
                    </>
                    : null}
                {login_name ?
                    <>
                        &nbsp;<i className="fa fa-user-tag" /> <span className="secuserselector-item-login">{login_name}</span>
                    </>
                    : null}
                {(!email && !login_name) ?
                    <>
                        &nbsp;<i className="fa fa-key" /> <span className="secuserselector-item-id">{member_id}</span>
                    </>
                    : null}
            </>;
        }
    }
}

/**
 * Ez nagyon hasonló a MemberCard-hoz, de egy konkrét user id-hez tölti be a kinézetét.
 * Ahol sok felhasználót kell megjeleníteni ott ne ezt használd, hanem a <MemberCard-ot!
 */
interface IMemberCardFromIdProps {
    memberId?: number | null;
    short?: boolean;
    medium?:boolean;
}
interface IMemberCardFromIdState {
    loading: boolean;
    member: IViewMemberNameRecord | null;
}


const MEMBER_CACHE_TTL: number = 60000;

class MemberNameCache {
    private _cache: { [userId: number]: { value: IViewMemberNameRecord, created: number } };
    private _ttl_msec: number;

    constructor(ttl: number) {
        this._ttl_msec = ttl;
        this._cache = {};
    }

    public get = async (userId: number): Promise<IViewMemberNameRecord> => {
        // Empty permission requirements -> return true.
        let item = this._cache[userId];
        if (item) {
            const now = (new Date).getTime();
            const expired = item.created + this._ttl_msec < now;
            if (!expired) {
                return Promise.resolve(item.value);
            }
        }
        try {
            const viewer_id = me!.id!;
            const members = await ViewMemberName.list({ filter: { id: userId, viewer_id } });
            let value: IViewMemberNameRecord;
            if (members.length) {
                value = members[0];
            } else {
                value = { id: userId, viewer_id };
            }
            const created = (new Date).getTime();
            this._cache[userId] = { created, value };
            return Promise.resolve(value);
        } catch (error) {
            return Promise.reject(error);
        }

    }
}
export const memberNameCache = new MemberNameCache(MEMBER_CACHE_TTL);

export class MemberCardFromId extends React.Component<IMemberCardFromIdProps, IMemberCardFromIdState> {
    constructor(props: IMemberCardFromIdProps) {
        super(props);
        this.state = { loading: true, member: null };
    }

    componentDidMount() {
        this.reload();
    }

    private reload = async () => {
        try {
            if (this.props.memberId) {
                const member = await memberNameCache.get(this.props.memberId);
                this.setState({ member, loading: false });
            } else {
                this.setState({ member: null, loading: false });
            }
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {
        if (!this.state.loading && this.state.member) {
            return <MemberCard {...this.state.member} short={this.props.short} medium={this.props.medium} />
        } else {
            return null;
        }
    }
}
