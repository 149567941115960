import * as React from 'react';

import { me, Groups, hasGroup, hasAnyGroup } from '@src/framework/server/Auth';

import { IViewInstituteSiteRecord, ViewInstituteSiteClassProxy, viewInstituteSiteClassProxy } from '@src/framework/view/sys/ViewInstituteSite';
import { viewInstituteGroupClassProxy } from '@src/framework/view/sys/ViewInstituteGroup';
import { viewInstituteSiteAdminClassProxy } from '@src/framework/view/sys/ViewInstituteSiteAdmin';
import { viewInstituteSiteTeacherClassProxy } from '@src/framework/view/sys/ViewInstituteSiteTeacher';
import ListView, { ListViewColumn } from '@framework/forms/listview';
import { Link } from 'react-router-dom';
import { __ } from '@src/translation';
import { observer } from 'mobx-react';
import { runInAction, observable, IObservableValue, reaction } from 'mobx';
import InstituteSiteCrud, { IInstituteSiteRecord } from '@src/framework/crud/sys/InstituteSiteCrud';
import { app } from '@src/index';
import { BubbleLoader } from 'react-css-loaders';
import { formatDateWithoutTime } from '@src/Util';
import LookupEdit from '@src/framework/forms/lookupedit';
import SecUserCrud from '@src/framework/crud/sys/SecUserCrud';
import { viewKapJtrSupporterClassProxy } from '@src/framework/view/kap/ViewKapJtrSupporter';
import { viewUserSite1FirstLoginClassProxy } from '@src/framework/view/sys/ViewUserSite1FirstLogin';


@observer
export default class InstituteSiteListView extends ListView<IViewInstituteSiteRecord> {
    private editedSiteRowIdx: IObservableValue<number | null> = observable.box(null);
    private editedSiteId: IObservableValue<number | null> = observable.box(null);

    public getViewClassProxy(): ViewInstituteSiteClassProxy { return viewInstituteSiteClassProxy; }

    protected async internalAsyncReload() {
        super.internalAsyncReload().then((result) => {
            runInAction(() => {
                this.editedSiteId.set(null);
                this.editedSiteRowIdx.set(null);
            })
            return result;
        })
    }


    protected getInstituteGroupListButton(record: IViewInstituteSiteRecord): JSX.Element {
        // Ebből kétféle is van, a másik az InstituteGroups.tsx -re mutat.
        return (
            <Link
                className="listview-button small" title={__("Intézményi csoportok")}
                to={viewInstituteGroupClassProxy.getListUrl(
                    { institute_site_id: record.id! }
                )}>
                <i className="fa fa-users"></i>
            </Link>);
    }


    protected getInstituteSiteTeacherListButton(record: IViewInstituteSiteRecord): JSX.Element {
        return (
            <Link
                className="listview-button small" title={__("Tanárok")}
                to={viewInstituteSiteTeacherClassProxy.getListUrl(
                    { institute_site_id: record.id! }
                )}>
                <i className="fa fa-chalkboard-teacher"></i>
            </Link>);
    }

    protected getInstituteSiteAdminListButton(record: IViewInstituteSiteRecord): JSX.Element {
        return (
            <Link
                className="listview-button small" title={__("Ellátási hely adminisztrátorai")}
                to={viewInstituteSiteAdminClassProxy.getListUrl(
                    { institute_site_id: record.id! }
                )}>
                <i className="fa fa-user-cog"></i>
            </Link>);
    }

    protected getRecordButtons(record: IViewInstituteSiteRecord): JSX.Element[] {
        if (hasAnyGroup(me!, [Groups.Admin, Groups.NkpAdmin])) {
            return super.getRecordButtons(record)
                .concat([
                    this.getInstituteGroupListButton(record),
                    this.getInstituteSiteTeacherListButton(record),
                    this.getInstituteSiteAdminListButton(record),
                ]);
        } else if (hasGroup(me!, Groups.InstituteSiteAdmin)) {
            return [
                this.getInstituteGroupListButton(record),
                this.getInstituteSiteTeacherListButton(record),
                this.getInstituteGroupListButton(record),
            ];
        } else {
            return [
                this.getInstituteGroupListButton(record),
            ];
        }
    }

    protected getQuickFilterFieldNames(): string[] {
        let filters: string[] = ['om_identifier', 'site_identifier', 'name', 'city', 'address', "in_kir"];
        if (hasGroup(me!, Groups.KapJtrCoordinator)) {
            filters.push("kap_introduction_date");
            filters.push("kap_supporter_name");
        }
        return filters;
    }

    protected getViewColumns() {
        let viewColumns: ListViewColumn[] = [
            {
                Header: <div>
                    <b>{__("Feladatellátási hely neve")}</b><br />
                    <small style={{ fontFamily: "monospace", color: "green" }}>{__("Azonosító")}</small> {__("Cím")}
                </div>,
                accessor: "name",
                Cell: (data: any, column: any) => {
                    const row: IViewInstituteSiteRecord = data.original;
                    return <div>
                        <b>{row.name}</b><br />
                        <span style={{ fontFamily: "monospace", color: "green" }}>{row.site_identifier}/{row.om_identifier}</span>
                        &nbsp; {row.zipcode} {row.city}{row.city && row.address ? "," : ""} {row.address}
                    </div>;
                }
            },
        ];
        if (hasGroup(me!, Groups.KapJtrCoordinator)) {
            viewColumns.push({
                Header: <b>{__("KAP bevezetés dátuma")}</b>,
                accessor: "kap_introduction_date",
                Cell: (data: any, column: any) => {
                    const rowIdx = data.index;
                    const row: IViewInstituteSiteRecord = data.original;
                    let btnClassName = "button primary";
                    if (rowIdx == this.editedSiteRowIdx.get()) {
                        btnClassName = "button secondary";
                    }
                    return <div>
                        <button className={btnClassName}
                            title="Bevezetés dátumának módosítása"
                            onClick={() => {
                                runInAction(() => {
                                    this.editedSiteRowIdx.set(rowIdx);
                                    this.editedSiteId.set(row.id!);
                                })
                            }}
                        >
                            <i className="fa fa-edit" />
                        </button>
                        &nbsp;
                        <b>{formatDateWithoutTime(row.kap_introduction_date)}</b>
                    </div>;
                }
            });
            viewColumns.push({
                Header: <b>{__("DFHT támogató")}</b>,
                accessor: "kap_supporter_name",
                Cell: (data: any, column: any) => {
                    const rowIdx = data.index;
                    const row: IViewInstituteSiteRecord = data.original;
                    let btnClassName = "button primary";
                    if (rowIdx == this.editedSiteRowIdx.get()) {
                        btnClassName = "button secondary";
                    }
                    return <div>
                        <button className={btnClassName}
                            title="DFHT támogató módosítása"
                            onClick={() => {
                                runInAction(() => {
                                    this.editedSiteRowIdx.set(rowIdx);
                                    this.editedSiteId.set(row.id!);
                                })
                            }}
                        >
                            <i className="fa fa-edit" />
                        </button>
                        &nbsp;
                        <b>{row.kap_supporter_name}</b>
                    </div>;
                }
            });
        }
        viewColumns.push({ accessor: "link", Header: __("Műveletek"), maxWidth: 300 });
        return viewColumns;
    }


    protected getFooter(): JSX.Element[] {
        let editor: any = null;
        if (this.editedSiteId.get()) {
            editor = <div className="column sm-12">
                <KapIntroductionEditor
                    siteId={this.editedSiteId.get()!}
                    onSaved={() => {
                        this.reloadRow(this.editedSiteRowIdx.get()!)
                    }}
                />
            </div>
        }

        return editor;


    }

}

interface IKapIntroductionEditorProps {
    siteId: number;
    onSaved: () => void;
}

@observer
class KapIntroductionEditor extends React.PureComponent<IKapIntroductionEditorProps> {
    @observable private changed: boolean = false;
    @observable private loading: boolean = false;
    @observable private record: IInstituteSiteRecord | null = null;

    constructor(props: IKapIntroductionEditorProps) {
        super(props);
        this.asyncReload();
    }

    componentDidUpdate(prevProps: IKapIntroductionEditorProps) {
        if (prevProps.siteId !== this.props.siteId) {
            this.asyncReload();
        }
    }

    asyncReload = async () => {
        try {
            let result = await InstituteSiteCrud.load(this.props.siteId);
            runInAction(() => {
                this.record = result.record; this.changed = false;
            });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    saveRecord = async () => {
        runInAction(() => { this.loading = true; })
        try {
            let crud = new InstituteSiteCrud({
                id: this.props.siteId,
                kap_introduction_date: this.record!.kap_introduction_date,
                kap_supporter_id: this.record!.kap_supporter_id,
            });
            let newRec = await crud.put();
            runInAction(() => { this.loading = false; this.changed = false; this.record = newRec.record; });
            app.showSuccess("Sikeres mentés", "");
            this.props.onSaved();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {
        if (this.record) {
            let value = this.record.kap_introduction_date || "";
            if (value) {
                value = value.substring(0, 10);
            }
            console.log("displaying record", value);
            return <div className="row form">

                <div className="column small-12">
                    <span>{this.record.name}</span>
                </div>
                <div className="column small-4">
                    <label htmlFor="edit_kap_introduction_date" >
                        Bevezetés dátuma
                    </label>
                    <input
                        id="edit_kap_introduction_date"
                        type="date"
                        value={value}
                        onChange={(e) => {
                            runInAction(() => {
                                this.record!.kap_introduction_date = e.target.value;
                                this.changed = true;
                            })
                        }}
                    />
                </div>
                <div className="column small-6">
                    <label htmlFor="edit_kap_supporter_id" >
                        DFHT szakmai támogató
                </label>
                    <LookupEdit
                        fk_table_info_id={SecUserCrud.TABLE_INFO_ID}
                        emptyLoad={true}
                        viewClassProxy={viewUserSite1FirstLoginClassProxy}
                        searchColumnNames={["fullname", "email"]}
                        selectColumnNames={["id", "fullname", "email"]}
                        displayColumnNames={["fullname", "email"]}
                        orderByColumnNames={[{ name: "fullname" }]}
                        valueColumn={"id"}

                        clearable={true}
                        value={this.record!.kap_supporter_id}
                        onChange={(newValue: any) => {
                            if ((typeof newValue === 'string'))
                                if (newValue.trim() === '')
                                    newValue = '';
                            if (newValue === '')
                                newValue = null;
                            runInAction(() => {
                                this.record!.kap_supporter_id = newValue;
                                this.changed = true;
                            })
                        }}

                        placeholder="Kérem válasszon szakmai támogatót..."
                        styles={{ singleValue: base => ({ ...base, whiteSpace: "pre-wrap" }), menuPortal: base => ({ ...base, zIndex: 19999 }) }}
                        noOptionsMessage={() => "Nincs választható szakmai támogató"}
                    />
                </div>
                <div className="column small-2">
                    <button className="button primary" title={__("Mentés")} disabled={!this.changed}
                        onClick={this.saveRecord}
                    >
                        <i className={this.loading ? "fa fa-hourglass" : "fa fa-save"} />
                    </button>

                </div>

            </div>
        } else {
            return <BubbleLoader />;
        }

    }
}

/*
export default class InstituteSiteListView extends InstituteSiteInner {


}
*/

// export default function InstituteSiteListView(props: any) {}
