import * as React from 'react';
import { __ } from '@src/translation';
import { action, observable, runInAction, computed } from 'mobx';
import { observer } from 'mobx-react';
import { forumStore, ForumContainerStore, MessageListStore, MemberListStore } from '../forumStore';
import { AutoTextarea } from '@src/component/ui/AutoTextarea';
import { EmojiPicker } from '@src/component/ui/emojiPicker/EmojiPicker';
import { Popover } from '@src/component/ui/Popover';
import { whiteSpaceChecker } from '@src/component/WhiteSpaceChecker';
import { app } from '@src/index';

export interface IForumNewMessageProps {
    storeId: string;
}

/**
 * Egy adott topic-ba új üzenetet küldeni tudó komponens.
 */
@observer
export class ForumNewMessage extends React.Component<IForumNewMessageProps, {}> {
    public get containerStore(): ForumContainerStore {
        return forumStore.getContainerStore(this.props.storeId);
    }

    public get messageListStore(): MessageListStore {
        const forumId = this.containerStore.forumId!;
        const topicId = this.containerStore.topicId!;
        return forumStore.getMessageListStore(forumId,topicId);
    }

    @computed private get memberStore() : MemberListStore|null {
        const forumId = this.containerStore.forumId;
        if (forumId) {
            return forumStore.getMemberListStore(forumId);
        } else {
            return null;
        }
    }

    constructor(props: IForumNewMessageProps) {
        super(props);
        this.state = {};
    }

    @action.bound sendMessage() {
        if(whiteSpaceChecker(this.containerStore.message))
        {
            this.messageListStore.sendMessage(this.containerStore.message, this.containerStore.replyToId, this.containerStore.data);
            this.containerStore.message = "";
            this.containerStore.replyToId = null;
        } else {
            app.showError(__("Hiba"), __("Az üzenet szövege kötelező!"));
        }
    }

    @action.bound onSetMessage(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.containerStore.message = e.target.value;
    }

    @action.bound reloadAll() {
        this.messageListStore.reload();
    }

    @action.bound emojiSelect(emoji:string) {
        this.containerStore.message+=emoji;
    }

    @observable emojiPopoverOpen = false;

    @action.bound private showEmojiPopover(open:boolean) {
        runInAction(() => { 
        this.emojiPopoverOpen = open;
        });
    }
    _emojiRef:HTMLElement|null = null;
    _textareaRef:AutoTextarea|null = null;

    @action.bound private clearReplyTo() {
        this.containerStore.replyToId = null;
    }

    private renderReplyToQuote() {
        const replyToId = this.containerStore.replyToId;
        if (!replyToId) {
            return null;
        }
        const message = this.messageListStore.messageById.get(replyToId);
        if (message) {
            this._textareaRef!.focus();
            return <div className="reply-to-quote">
                <span>{__("Válasz küldése erre:")}</span>
                <button className="reply-to-close" title={__("Mégse")}
                    onClick={this.clearReplyTo}
                >
                    <i className="fas fa-times"/>
                </button>
                <div>
                    {message.message}
                </div>
            </div>;
        } else {
            return <div>{__("Válasz küldése...")}</div>;
        }

    }

    private renderSendLine() {

        var emojipicker = <>
            <button onClick={()=>this.showEmojiPopover(true)} ref={(ref) => this._emojiRef = ref}><i className="far fa-smile"></i></button>
            <Popover childAlignVertical="bottom" childAlignHorizontal="center" open={this.emojiPopoverOpen} parentElement={() => this._emojiRef as HTMLElement} onClose={()=>this.showEmojiPopover(false) }>
                <EmojiPicker onSelect={this.emojiSelect} ></EmojiPicker>
            </Popover></>

        return  <AutoTextarea
                        ref={(ref) => this._textareaRef = ref}
                        text={this.containerStore.message || ""} onTextChange={this.onSetMessage} onSubmit={() => this.sendMessage()}
                        buttons={emojipicker}
                        >
                </AutoTextarea>
    }

    render() {
        if (this.containerStore.topicId === null) {
            return null;
        }
        if (this.memberStore && this.memberStore.canSendMessage) {
            return <div className="messagebox-area">
                <b>{__("Üzenetküldő")}</b><br />
                {this.renderReplyToQuote()}
                {this.renderSendLine()}
            </div>;
        } else {
            return null;
        }
    }
}
