import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewMetaViewInfoRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Séma név
     */
    schema_name?: string;
    /**
     * Nézettábla név (adatbázisban)
     */
    view_name?: string;
    /**
     * Elsődleges táblázat neve (adatbázisban)
     */
    table_name?: string|null;
    /**
     * Kijelzett név
     *
     * A nézettábla magyar neve magyar nyelven
     */
    display_name?: string|null;
    /**
     * Leírás
     *
     * A nézettábla leírása magyar nyelven
     */
    description?: string|null;
    /**
     * Teljes név (egysoros rep.)
     *
     * A nézettábla egysoros szöveges reprezentációja
     */
    displayvalue?: string|null;
}

/**
 *  meta.view_meta_view_info - Nézettábla információ
 */

export default class ViewMetaViewInfo extends View<IViewMetaViewInfoRecord> {
    public static VIEW_INFO_ID = 2018052801;

    public static getSchemaNameForClass(): string { return 'meta'; }
    public static getViewNameForClass(): string { return 'view_meta_view_info'; }
    public static getTableNameForClass(): string|null { return 'view_info'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewMetaViewInfo>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewMetaViewInfoRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewMetaViewInfoClassProxy extends ViewClassProxy<IViewMetaViewInfoRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewMetaViewInfoClassProxy = new ViewMetaViewInfoClassProxy(ViewMetaViewInfo);
registerViewClassProxy(viewMetaViewInfoClassProxy);

