import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IViewInfoRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Séma név
     */
    schema_name?: string;
    /**
     * Nézettábla név (adatbázisban)
     */
    view_name?: string;
    /**
     * Elsődleges táblázat neve (adatbázisban)
     */
    table_name?: string|null;
}

/**
 *  meta.view_info - Nézettábla információ
 */

export default class ViewInfoCrud extends Crud<IViewInfoRecord> {
    public static TABLE_INFO_ID = 3455632973;
    public static getSchemaNameForClass() { return 'meta'; }
    public static getTableNameForClass() { return 'view_info'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewInfoCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IViewInfoRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewInfoRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IViewInfoRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IViewInfoRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IViewInfoRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewInfoCrudClassProxy extends CrudClassProxy<IViewInfoRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewInfoCrudClassProxy = new ViewInfoCrudClassProxy(ViewInfoCrud);
registerCrudClassProxy(viewInfoCrudClassProxy);
