import * as React from 'react';
import Downshift, { ControllerStateAndHelpers } from 'downshift';
import { debounce } from 'lodash';



import './SecUserSelector.css';

import { app } from '@src/index';
import { me, hasAnyGroup } from '@framework/server/Auth';
import SecUserSelectorAPI from './SecUserSelectorAPI';
import ViewMemberName, { IViewMemberNameRecord } from '../view/sys/ViewMemberName';
import { StringSearchKind, TFilterDict } from '../crud/Crud';
import MemberCard, { memberToString } from './MemberCard';
import ViewSecUserGroup from '../view/sys/ViewSecUserGroup';
import * as ReactDOM from 'react-dom';

export type SecUserSelectorChangedEvent = (secUserId: number | null) => void;

const ASYNC_RELOAD_DEBOUNCE_MSEC = 600;


const DEFAULT_PLACEHOLDER: string = "Válasszon egy felhasználót...";
const DEFAULT_TOOLTIP: string = 'Keressen az ismerősei között, vagy írjon be egy teljes azonosítót (e-mail címet vagy bejelentkezési nevet) és nyomjon ENTER-t.'

export interface ISecUserSelectorProps {
    clearable: boolean;    // törölhető-e az értéke null-ra
    value: number | null;  // a kiválasztott sec_user.id értéke
    disabled?: boolean;    // lehet-e választani másik értéket
    placeholder?: string;
    onChange: SecUserSelectorChangedEvent; // amikor kiválaszt valamit, itt módosítsd a value értékét ha akarod
    groupId?: number;
    short?: boolean;
    medium?: boolean;
    resultContainer?:string;
}

export interface ISecUserSelectorState {
    loading: boolean;                    // Ha ez igaz, akkor az aktuális értékhez tartozó nevet még nem töltötte be.
    searchTextChanged: boolean;         // Ha ez igaz, akkor a legutoljára beírt szövegre még nem keresett rá.
    items: IViewMemberNameRecord[];      // Ezek a találatok...
    currentUser?: IViewMemberNameRecord; // Ez a kiválasztott értékhez tartozó felhasználói név. Ezt jelzi ki a komponens, és ezt tölti be a loadCurrentUser    
}

export default class SecUserSelector extends React.Component<ISecUserSelectorProps, ISecUserSelectorState> {
    protected debouncedDownshiftInputValueChange: any = undefined;
    protected willUnmount: boolean;

    constructor(props: ISecUserSelectorProps) {
        super(props);
        this.state = { loading: this.props.value ? true : false, searchTextChanged: false, items: [] };
        // Ez csak arra kell, hogy ne küldjön új request-et minden egyes billentyű leütéshez. 
        this.debouncedDownshiftInputValueChange = debounce(this.onDoSearch, ASYNC_RELOAD_DEBOUNCE_MSEC)
        this.willUnmount = true;
    }

    componentDidMount() {
        this.willUnmount = false;
        this.loadCurrentUser();
    }

    componentWillMount() {
        this.willUnmount = true;
    }

    componentDidUpdate(prevProps: ISecUserSelectorProps, prevState: ISecUserSelectorState, snapshot: any) {
        if (this.props.value !== prevProps.value) {
            this.setState({ loading: true, searchTextChanged: false }, this.loadCurrentUser);
        }
    }

    // A value -hez tartozó displayText meghatározása.
    protected loadCurrentUser = async () => {
        try {
            let currentUser: IViewMemberNameRecord = { id: undefined, fullname: null };
            if (this.props.value) {

                const members: IViewMemberNameRecord[] = (await ViewMemberName.list(
                    { filter: { viewer_id: me!.id, id: this.props.value } }));
                if (members.length == 1) {
                    currentUser = members[0];
                } else {
                    currentUser = { id: this.props.value };
                }
            }
            this.setState({ loading: false, searchTextChanged: false, currentUser });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    // value törlése
    protected onClearValue = () => {
        this.props.onChange(null);
    }

    // Akkor hívódik meg, amikor a találati listából kiválaszt egy elemet.
    protected ondDownshiftSelect = (selectedItem: IViewMemberNameRecord, stateAndHelpers: ControllerStateAndHelpers<string>) => {
        this.props.onChange(selectedItem.id || null);
    }

    // Ez a beírt kereső kifejezésre keres. Akkor hívódik meg, ha a felhasználó abbahagyta a keresőkifejezés beírását
    // legalább ASYNC_RELOAD_DEBOUNCE_MSEC msec -el ezelőtt.
    protected onDoSearch = async (inputValue: string, stateAndHelpers: ControllerStateAndHelpers<string>) => {
        if (!this.willUnmount    // Ez megakadályozza, hogy a debounced függvény olyan komponensre hívódjon meg, ami már nincs mount-olva.
            && !this.state.loading // Ez kikapcsolja a szöveges keresést arra az időre, amíg az új kiválasztott értéket beállítjuk.
        ) {
            try {
                let items: IViewMemberNameRecord[] = [];
                if (inputValue && inputValue.trim() != "") {
                    // Keresés teljes e-mail címre vagy felhasználói névre.
                    const userId: number | null = await SecUserSelectorAPI.getIdByEmailOrLoginName(inputValue);

                    let uIds: number[] = [];
                    if (this.props.groupId) {
                        let userGroups = await ViewSecUserGroup.list({
                            filter: { group_id: this.props.groupId }, columns: ["user_id"]
                        });
                        userGroups.forEach(element => {
                            uIds.push(element.user_id!);
                        });
                    }

                    if (userId) {

                        let members: IViewMemberNameRecord[] = [];
                        // Megtaláltuk. Megpróbáljuk lekérdezni a nevét.
                        members = await ViewMemberName.list({ filter: { viewer_id: me!.id, id: userId } });

                        if (members && members.length) {
                            // Sikeres -> beírjuk legelső találatként.                            
                            items = members;
                        } else {
                            // Sikertelen -> beírjuk legelső találatként mint "ismeretlen nevű felhasználó"
                            items = [{
                                id: userId,
                                fullname: inputValue + " (ismeretlen nevű felhasználó)"
                            }];
                        }
                    }
                    // Keresés az ismerősök között, teljes név alapján.
                    let filter: TFilterDict = {
                        viewer_id: me!.id,
                        fullname: {
                            kind: StringSearchKind.WordSearch,
                            case_sensitive: false,
                            expr: inputValue
                        }
                    };
                    if (this.props.groupId) {
                        filter.id = uIds;
                    }

                    items = items.concat(await ViewMemberName.list({
                        filter: filter,
                        order_by: "fullname",
                        limit: 25
                    }));

                }
                this.setState({ searchTextChanged: false, items });
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    // Kereső kifejezés beírásakor hívódik meg, debounce-oljuk.
    protected onDownshiftInputValueChange = async (inputValue: string, stateAndHelpers: ControllerStateAndHelpers<string>) => {
        if (!this.willUnmount    // Ez megakadályozza, hogy a debounced függvény olyan komponensre hívódjon meg, ami már nincs mount-olva.
            && !this.state.loading  // Ez kikapcsolja a szöveges keresést arra az időre, amíg az új kiválasztott értéket beállítjuk.
        ) {
            this.setState({ searchTextChanged: true }, () => { this.debouncedDownshiftInputValueChange(inputValue, stateAndHelpers) });
        }
    }

    render() {
        let inner = null;

        let clear: JSX.Element | null = null;
        let status: JSX.Element | null = null;

        if (!this.state.loading || !this.state.searchTextChanged) {
            let labelIconClass = "fa ";
            let labelTitle = "";
            if (this.props.value) {
                if (this.props.value) {
                    labelIconClass += "  fa-user-check";
                    labelTitle = "Érvényes felhasználó kiválasztva.";
                } else {
                    labelIconClass += " fa-user-slash";
                    labelTitle = "Nincs kiválasztva felhasználó.";
                }
            } else {
                labelIconClass += " fa-times";
                labelTitle = "Írjon be valamit, mielőtt keresne";
            }
            status = <div className="input-group-label">
                <i className={labelIconClass} title={labelTitle} />
            </div>;
            if (this.props.value && this.props.clearable) {
                clear = <div className="input-group-button">
                    <button
                        className="button alert"
                        disabled={this.props.disabled || this.state.loading}
                        onClick={this.onClearValue}
                        title="Töröl"
                    >
                        <i className="fa fa-times" />
                    </button>
                </div>;
            }
        }

        if (this.state.loading) {
            // Ha még nem töltöttük le a value-hez tartozó displayText-et, akkor várakoztatjuk.
            inner = <input
                className="input input-group-field secuserselector-input-loading"
                type="text"
                disabled={true}
                value=""
                placeholder="Betöltés folyamatban, kérem várjon..."
            />;
        } else {
            const currentUser = this.state.currentUser;
            const key = this.props.value ? this.props.value.toString() : "null";
            inner = <Downshift
                key={key}
                defaultSelectedItem={this.state.currentUser}
                defaultHighlightedIndex={0}
                itemToString={memberToString}
                onSelect={this.ondDownshiftSelect}
                onInputValueChange={this.onDownshiftInputValueChange}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getLabelProps,
                    getMenuProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                    selectedItem,
                }) => {
                    const items = this.state.items;
                    let tooltip = DEFAULT_TOOLTIP;

                    if (currentUser && currentUser.id) {
                        tooltip = currentUser.fullname + " id=" + currentUser.id + "\n " + tooltip;
                    }
                    // Ez azért kell, mert közvetlenül kiválasztás után a getInputProps() a kiválasztott értékhez tartozó value-t írja bele, és ez jelenik meg az input-ban is.
                    // De ez nekünk nem jó, mert a kiválasztás után a külső eseménykezelő dönti el hogy beállítja- e az új értéket; és nekünk annak a felhasználónak a nevét
                    // kell kiírnunk, amit a külső eseménykezelő beállított új props-ban. Egész konkrétan, ha a külső onChange nem változtatta meg a userId prop-ot, akkor
                    // az input-ba üres value-t kell tenni, és NEM azt amit a user kiválasztott.
                    let inputProps = getInputProps();
                    if (!isOpen && inputProps) {
                        inputProps.value = memberToString(currentUser || null);
                    }
                    return (
                        <div style={{ marginBottom: "1em" }}>
                            <div className="input-group" style={{ marginBottom: 0 }}>
                                <input
                                    className="input-group-field"
                                    {...inputProps}
                                    type="text"
                                    onClick={e => e.currentTarget.select()}
                                    placeholder={this.props.placeholder || DEFAULT_PLACEHOLDER} title={tooltip} />
                                {status}
                                {clear}
                            </div>                           
                            <ul {...getMenuProps()} className="secuserselector">
                                {this.state.searchTextChanged ?
                                    <li {...getItemProps({
                                        key: "searching_text",
                                        index: -1,
                                        item: "",
                                        disabled: true,
                                        
                                    })}>
                                        Keresés...
                                            </li> : null}
                                {isOpen
                                    ? items.map((item, index) =>
                                        <li
                                            {...getItemProps({
                                                key: "" + index,
                                                index,
                                                item,
                                                style: {
                                                    backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',                                       
                                                },
                                            })}
                                        ><MemberCard {...item} medium={this.props.medium} short={this.props.short}/></li>) : null}
                            </ul>
                        </div>
                    );
                }
                }
            </Downshift>

        }

        return inner;
    }
}
export class SecUserInput extends SecUserSelector {

    constructor(props: ISecUserSelectorProps) {
        super(props);
        this.state = { loading: this.props.value ? true : false, searchTextChanged: false, items: [] };
        // Ez csak arra kell, hogy ne küldjön új request-et minden egyes billentyű leütéshez. 
        this.debouncedDownshiftInputValueChange = debounce(this.onDoSearch, ASYNC_RELOAD_DEBOUNCE_MSEC)
        this.willUnmount = true;
    }

    // A value -hez tartozó displayText meghatározása.
    protected loadCurrentUser = async () => {
        try {
            let currentUser: IViewMemberNameRecord = { id: undefined, fullname: null };
            if (this.props.value) {

                const members: IViewMemberNameRecord[] = (await ViewMemberName.list(
                    { filter: { viewer_id: me!.id, id: this.props.value } }));
                if (members.length == 1) {
                    currentUser = members[0];
                } else {
                    currentUser = { id: this.props.value };
                }
            }
            this.setState({ loading: false, searchTextChanged: false });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    // Akkor hívódik meg, amikor a találati listából kiválaszt egy elemet.
    protected ondDownshiftSelect = (selectedItem: IViewMemberNameRecord, stateAndHelpers: ControllerStateAndHelpers<string>) => {
        this.props.onChange(selectedItem.id || null);
        this.setState({currentUser: selectedItem});
    }
    protected onDoSearch = async (inputValue: string, stateAndHelpers: ControllerStateAndHelpers<string>) => {
        if (!this.willUnmount    // Ez megakadályozza, hogy a debounced függvény olyan komponensre hívódjon meg, ami már nincs mount-olva.
            && !this.state.loading // Ez kikapcsolja a szöveges keresést arra az időre, amíg az új kiválasztott értéket beállítjuk.
        ) {
            try {
                let items: IViewMemberNameRecord[] = [];
                if (inputValue && inputValue.trim() != "") {
                    // Keresés teljes e-mail címre vagy felhasználói névre.
                    const userId: number | null = await SecUserSelectorAPI.getIdByEmailOrLoginName(inputValue);

                    let uIds: number[] = [];
                    if (this.props.groupId) {
                        let userGroups = await ViewSecUserGroup.list({
                            filter: { group_id: this.props.groupId }, columns: ["user_id"]
                        });
                        userGroups.forEach(element => {
                            uIds.push(element.user_id!);
                        });
                    }
 
                    if (userId) {

                        let members: IViewMemberNameRecord[] = [];
                        // Megtaláltuk. Megpróbáljuk lekérdezni a nevét.
                        members = await ViewMemberName.list({ filter: { viewer_id: me!.id, id: userId } });

                        if (members && members.length) {
                            // Sikeres -> beírjuk legelső találatként.                            
                            items = members;
                        } else {
                            // Sikertelen -> beírjuk legelső találatként mint "ismeretlen nevű felhasználó"
                            items = [{
                                id: userId,
                                fullname: inputValue
                            }];
                        }
                    }

                    // Keresés az ismerősök között, teljes név alapján.
                    let filter: TFilterDict = {
                        viewer_id: me!.id,
                        fullname: {
                            kind: StringSearchKind.WordSearch,
                            case_sensitive: false,
                            expr: inputValue
                        }
                    };
                    if (this.props.groupId) {
                        filter.id = uIds;
                    }

                    items = items.concat(await ViewMemberName.list({
                        filter: filter,
                        order_by: "fullname",
                        limit: 25
                    }));

                }
                this.setState({ searchTextChanged: false, items });
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    memberToString = (item: IViewMemberNameRecord | null): string => {
        let result: string = "";
        if (item && item.id) {
            if (item.fullname) {
                result = item.fullname;
            }
            if (item.email) {
                result = (result + " <" + item.email + ">").trim();
            }
        }
        return result;
    }

    render() {
        let inner = null;

        if (this.state.loading) {
            // Ha még nem töltöttük le a value-hez tartozó displayText-et, akkor várakoztatjuk.
            inner = <input
                className="input input-group-field secuserselector-input-loading"
                type="text"
                disabled={true}
                value=""
                placeholder="Betöltés folyamatban, kérem várjon..."
            />;
        } else {
            const currentUser = this.state.currentUser;
            const key = this.props.value ? this.props.value.toString() : "null";
            inner = <Downshift
                key={key}
                defaultSelectedItem={this.state.currentUser}
                defaultHighlightedIndex={0}
                itemToString={memberToString}
                onSelect={this.ondDownshiftSelect}
                onInputValueChange={this.onDownshiftInputValueChange}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getLabelProps,
                    getMenuProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                    selectedItem,
                }) => {
                    const items = this.state.items;
                    let tooltip = DEFAULT_TOOLTIP;

                    if (currentUser && currentUser.id) {
                        tooltip = currentUser.fullname + "\n " + tooltip;
                    }
                    // Ez azért kell, mert közvetlenül kiválasztás után a getInputProps() a kiválasztott értékhez tartozó value-t írja bele, és ez jelenik meg az input-ban is.
                    // De ez nekünk nem jó, mert a kiválasztás után a külső eseménykezelő dönti el hogy beállítja- e az új értéket; és nekünk annak a felhasználónak a nevét
                    // kell kiírnunk, amit a külső eseménykezelő beállított új props-ban. Egész konkrétan, ha a külső onChange nem változtatta meg a userId prop-ot, akkor
                    // az input-ba üres value-t kell tenni, és NEM azt amit a user kiválasztott.
                    let inputProps = getInputProps();
   
                    if (!isOpen && inputProps) {
                        inputProps.value =  (currentUser && currentUser.fullname ? currentUser.fullname : '') + (currentUser && currentUser.email ? "<" +currentUser.email+ ">" : "");
                    }

                    const result = <div className="sec-userinput-results">
                               
                    <ul {...getMenuProps()} className="sec-userinput-list">
                        {this.state.searchTextChanged ?
                            <li {...getItemProps({
                                key: "searching_text",
                                index: -1,
                                item: "",
                                disabled: true,
                                
                            })}>
                                Keresés...
                                    </li> : null}
                        {isOpen
                            ? 
                            items.length 
                                ? items.map((item, index) =>
                                    <li
                                        {...getItemProps({
                                            key: "" + index,
                                            index,
                                            item,
                                            style: {
                                                backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',                                       
                                            },
                                        })}
                                    >
                                        <MemberCard {...item} medium={this.props.medium} short={this.props.short}/>
                                    </li>) 
                                : inputValue && !this.state.searchTextChanged ? <li>Nincs találat. Próbálja meg teljes, e-mail cím megadásával vagy ellenőrizze le a megadott értéket.</li> : null
                            : null
                            }
                    </ul>
                    </div>;

                    return (
                        <div style={{ marginBottom: "1em" }}>
                            <div className="input-group" style={{ marginBottom: 0 }}>
                                <input
                                    className="input-group-field"
                                    {...inputProps}
                                    type="text"
                                    onClick={e => e.currentTarget.select()}
                                    placeholder={this.props.placeholder || DEFAULT_PLACEHOLDER} title={tooltip} />
                            </div>
                            {isOpen 
                            ?
                            this.props.resultContainer 
                                    ?
                                    ReactDOM.createPortal(result, document.getElementById(this.props.resultContainer)!)
                                    : result
                        :null}
                        </div>
                    );
                }
                }
            </Downshift>

        }

        return inner;
    }

}