import * as React from 'react';

import ViewInstituteSiteTeacher, { IViewInstituteSiteTeacherRecord, ViewInstituteSiteTeacherClassProxy, viewInstituteSiteTeacherClassProxy } from '@src/framework/view/sys/ViewInstituteSiteTeacher';
import ListView from '@framework/forms/listview';
import InstituteSiteTeacherCrud from '@src/framework/crud/sys/InstituteSiteTeacherCrud';
import { __ } from '@src/translation';

import { app } from '@src/index';
import { me } from '@framework/server/Auth';

export enum InstituteSiteTeacherStates {
    INVITATION_SENT_ID = 1, // Meghívó kiküldve
    ACTIVE_ID = 2, //	Aktív
    LEFT_ID = 3,   // Önként távozott, visszatérhet
    TILTED_ID = 4, //	Kitiltva, nem térhet vissza
    INVITATION_REVOKED_ID = 5, //	Meghívó visszavonva
    INVITATION_REFUSED_ID = 6, //	Meghívó visszautasítva    
}


export class InstituteSiteTeacherListViewInner extends ListView<IViewInstituteSiteTeacherRecord> {

    public getViewClassProxy() : ViewInstituteSiteTeacherClassProxy { return viewInstituteSiteTeacherClassProxy; }



    private _getConfirmTitle = (row: IViewInstituteSiteTeacherRecord): string => {
        return (
            __('Intézmény: {identifier} {institute}', {identifier: row.om_identifier!, institute: row.institute_name!}) + '\n' +
            __('Feladatellátási hely: {identifier} {sitename}', {identifier: row.site_identifier!, sitename: row.institute_site_name!}) + '\n' +
            __('Felhasználó: {teacher}', {teacher: row.teacher_displayname!}) + '\n\n'
        );
    }

    private _onChangeTeacherInvitationState = async (
        row: IViewInstituteSiteTeacherRecord,
        newStateId: number,
        confirmMessage: string
    ) => {
        if (confirm(this._getConfirmTitle(row) + confirmMessage
            /* 'Elfogadja a meghívást ebbe az intézménye?' */
        )) {
            try {
                const rec = await new InstituteSiteTeacherCrud({
                    id: row.id,
                    status_id: newStateId /* InstituteSiteTeacherStates.ACTIVE_ID */
                }).put();
                await ViewInstituteSiteTeacher.list({
                    filter: { sec_user_id: me!.id },
                    order_by: "institute_site_name"
                });
                this.asyncReload();
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    private onReinvite = (row: IViewInstituteSiteTeacherRecord) => {
        this._onChangeTeacherInvitationState(row, InstituteSiteTeacherStates.INVITATION_SENT_ID,
            __('Újra meghívja a hely tanárai közé?')
        );
    }


    protected getReinviteButton(record:IViewInstituteSiteTeacherRecord) : JSX.Element {
        if (
                record.status_id==InstituteSiteTeacherStates.LEFT_ID
                || record.status_id==InstituteSiteTeacherStates.TILTED_ID
                || record.status_id==InstituteSiteTeacherStates.INVITATION_REVOKED_ID
                || record.status_id==InstituteSiteTeacherStates.INVITATION_REFUSED_ID
            ) {
            return (
                <button className="listview-button small" 
                        title={__("Újra meghív")} 
                        onClick={() => {this.onReinvite(record) }} 
                        >
                        <i className="fa fa-user-plus" />
                </button>);
        } else {
            return <></>;
        }
    }


    private onRevoke = (row: IViewInstituteSiteTeacherRecord) => {
        this._onChangeTeacherInvitationState(row, InstituteSiteTeacherStates.INVITATION_REVOKED_ID,
            __('Visszavonja a meghívást?')
        );
    }    

    protected getRevokeButton(record:IViewInstituteSiteTeacherRecord) : JSX.Element {
        if (record.status_id==InstituteSiteTeacherStates.INVITATION_SENT_ID) {
            return (
                <button className="listview-button listview-button-warning small" 
                        title={__("Meghívó visszavonása")} 
                        onClick={() => {this.onRevoke(record) }} 
                        >
                        <i className="fa fa-user-minus" />
                </button>);
        } else {
            return <></>;
        }
    }    


    private onTilt = (row: IViewInstituteSiteTeacherRecord) => {
        this._onChangeTeacherInvitationState(row, InstituteSiteTeacherStates.TILTED_ID,
            'Kitiltja?'
        );
    }    

    protected getTiltButton(record:IViewInstituteSiteTeacherRecord) : JSX.Element {
        if (record.status_id==InstituteSiteTeacherStates.ACTIVE_ID) {
            return (
                <button className="listview-button listview-button-alert small" 
                        title={__("Kitiltás")} 
                        onClick={() => {this.onTilt(record) }} 
                >
                        <i className="fa fa-user-times" />
                </button>);
        } else {
            return <></>;
        }
    }        
    
    protected getRecordButtons(record: IViewInstituteSiteTeacherRecord) : JSX.Element[] {
        return /*super.getRecordButtons(record).concat(*/ [
                this.getReinviteButton(record),
                this.getRevokeButton(record),
                this.getTiltButton(record),
            ];
    }

}

export default function InstituteSiteTeacherListView(props: any) {
    return <InstituteSiteTeacherListViewInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[      
            {accessor: "teacher_displayname"},
            {accessor: "status_title"},  
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
