import * as React from 'react';
import { Dialog, DialogContent } from '@src/component/Dialog';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MediaFileChooser, { ContentActionType, MediaFileSelectedEvent } from '@component/filemanager/MediaFileChooser';
import { me, hasGroup, Groups } from '@src/framework/server/Auth';
import { __ } from '@src/translation';

type ExerciseFileSelectDialogProps = {
    open: boolean;
    exerciseFolderId?: number;
    onFileSelected: MediaFileSelectedEvent;
    onClose: () => void;
}

type ExerciseFileSelectDialogState = {
    currentFolderId: number | null;
}

export class ExerciseFileSelectDialog extends React.Component<ExerciseFileSelectDialogProps, ExerciseFileSelectDialogState> {

    state = {
        currentFolderId: null
    }

    render() {
        return <Dialog width={800} height={600} open={this.props.open} title={ __("Kérem válasszon képet!") } onClose={this.props.onClose}>
            <DialogContent>
                <Tabs>
                    <TabList>
                        {
                            this.props.exerciseFolderId
                            ?
                            <Tab>{ __("Feladat saját fájljai") }</Tab>
                            :
                            null
                        }
                        {
                            me && hasGroup(me, Groups.OFIEditor) &&
                            <Tab>OFI { __("Közös fájlok") }</Tab>
                        }
                    </TabList>
                    {
                        this.props.exerciseFolderId
                        ?
                        <TabPanel>
                            <MediaFileChooser 
                                rootFolderId={this.props.exerciseFolderId}
                                currentFolderId={this.props.exerciseFolderId}
                                onFileSelected={this.props.onFileSelected}
                                enabledActions={[ContentActionType.FILE_UPLOAD, ContentActionType.FILE_DELETE, ContentActionType.FILE_MODIFY]}
                            />
                        </TabPanel>
                        :
                        null
                    }
                    {
                        me && hasGroup(me, Groups.OFIEditor) &&
                        <TabPanel>
                            <MediaFileChooser 
                                rootFolderId={null}
                                currentFolderId={this.state.currentFolderId}
                                onFileSelected={this.props.onFileSelected}
                                onFolderSelected={(folderId) => this.setState({currentFolderId: folderId})}
                                enabledActions={[]}
                            />
                        </TabPanel>
                    }
                </Tabs>
            </DialogContent>
        </Dialog>
    }

}
