import { confirmDialog } from '@src/component/Dialog';
import { observable, action, runInAction, computed } from 'mobx';
import { debounce } from 'lodash';
import { startOfWeek, endOfWeek, addDays, subDays } from 'date-fns';
import CalendarAPI, { IDay, IDayItem } from '../component/calendar/CalendarAPI';
import { isSameDate } from '@src/Util';
import { app } from '@src/index';

interface IDateRange {
    startDate: Date;
    endDate: Date;
}

export class CalendarSource {
    private _debouncedAsyncReload: () => void;
    private _neverLoaded: boolean;

    @observable public year: number;
    @observable public month: number;
    @observable public startDate: Date;
    @observable public endDate: Date;
    @observable public today: Date;
    @observable public items: IDayItem[];
    @observable public _selectedItem: IDayItem | null;
    @observable public loading: boolean;
    @observable public hasChange: boolean; // Use this to indicate that you have pending changes that may need saving.

    public constructor(year?: number, month?: number) {
        this._debouncedAsyncReload = debounce(this.asyncReload, 100);
        if (year === undefined || month === undefined) {
            this.year = new Date().getFullYear();
            this.month = new Date().getMonth();
        } else {
            this.year = year;
            this.month = month;
        }
        const dateRange = CalendarSource.calcDateRange(this.year, this.month);
        this.startDate = dateRange.startDate;
        this.endDate = dateRange.endDate;
        this.loading = false;
        this.items = []
        this._selectedItem = null;
        this.today = new Date()
        this._neverLoaded = true;
    }

    private static calcDateRange(year: number, month: number): IDateRange {
        let startDate = new Date(year, month, 1);
        let endDate = (month == 11) ? new Date(year + 1, 0, 1) : subDays(new Date(year, month + 1, 1),1);
        startDate = startOfWeek(startDate, { weekStartsOn: 1 });
        endDate = endOfWeek(endDate, { weekStartsOn: 1 });
        return { startDate, endDate };
    }

    @computed get weekStartDate () : Date {
        if (this._selectedItem===null) {
            return this.startDate;
        } else {
            return startOfWeek( this._selectedItem.date, { weekStartsOn: 1 } );
        }
    }

    @computed get weekEndDate () : Date {
        if (this._selectedItem===null) {
            return endOfWeek( this.startDate, { weekStartsOn: 1 } );
        } else {
            return endOfWeek( this._selectedItem.date, { weekStartsOn: 1 } );
        }
    }

    @action.bound setLoading() {
        this.loading = true;
    }

    @action.bound clearLoading() {
        this.loading = false;
    }

    /** Get the currently selected item. */
    @computed public get selectedItem(): IDayItem | null {
        return this._selectedItem;
    }

    /** 
     * Change the current selection. 
     * This is an async method that MAY wait for the user to confirm
     * abandoning the pending changes.
     * 
     */
    public async selectItem(item: IDayItem | null): Promise<boolean> {
        try {
            if (!await this.onConfirmNavigation()) {
                return Promise.resolve(false);
            } else {
                runInAction(() => {                    
                    this._selectedItem = item;
                });
                return Promise.resolve(true);
            }
        } catch (error) {
            app.showErrorFromJsonResult(error)
            return Promise.reject(error);
        }
    }


    /** Ask the user to abandon changes. It will only display a dialog when there are pending changes. */
    private onConfirmNavigation = async (): Promise<boolean> => {
        if (this.hasChange) {
            const answer = await confirmDialog(
                "Megerősítés",
                "A változtatásai nincsenek mentve. Biztos benne, hogy mentés nélkül napot vált?"
            );
            if (answer) {
                this.hasChange = false;
                return Promise.resolve(true);
            } else {
                return Promise.resolve(false);
            }
        } else {
            return Promise.resolve(true);
        }
    }


    public reload = async () : Promise<boolean> => {
        if (!await this.onConfirmNavigation()) {
            return Promise.resolve(false);
        } else {
            this.setLoading();
            this._debouncedAsyncReload();
            return Promise.resolve(true);
        }
    }

    @action.bound public firstLoad(): Promise<void> {
        if (this._neverLoaded) {
            this._neverLoaded = false;
            return this.asyncReload();
        } else {
            return Promise.resolve();
        }
    }


    private asyncReload = async (): Promise<void> => {
        //console.log("reload between", this.state.startDate, this.state.endDate);
        this.setLoading();
        try {
            let prevSelected = this._selectedItem ? this._selectedItem.date : null;
            const days = await CalendarAPI.getDays(this.startDate, this.endDate);
            const items: IDayItem[] = [];
            let date = this.startDate;
            days.forEach((day: IDay) => {
                const item = { date, day };
                items.push(item);
                date = addDays(date, 1);
            });
            let selectedItem: IDayItem | null = null;
            // Try to select previously selected day.
            items.forEach(item => {
                if (selectedItem === null && isSameDate(prevSelected, item.day.day)) {
                    selectedItem = item;
                }
            });
            // Try to select today
            if (selectedItem == null) {
                items.forEach(item => {
                    if (selectedItem === null && isSameDate(this.today, item.day.day)) {
                        selectedItem = item;
                    }
                });
            }
            // Fallback to first day of month.
            if (selectedItem == null) {
                const first = new Date(this.year, this.month, 1);
                items.forEach(item => {
                    if (selectedItem === null && isSameDate(first, item.day.day)) {
                        selectedItem = item;
                    }
                });
            }
            runInAction(() => {
                this.loading = false;
                this.items = items;
                this._selectedItem = selectedItem;
            })
            // await this.state.onLoaded(this);
        } catch (error) {
            runInAction(() => {
                this.loading = false;
                this._selectedItem = null;
            });
            app.showErrorFromJsonResult(error);
        }
    }


    // TODO: we might want to add a date to be selected?
    /** Select a given year + month and display it. */
    public async selectYearAndMonth(year: number, month: number): Promise<boolean> {
        try {
            if (!await this.onConfirmNavigation()) {
                return Promise.resolve(false);
            }
            runInAction(() => {
                this.year = year;
                this.month = month;
                const dateRange = CalendarSource.calcDateRange(this.year, this.month);
                this.startDate = dateRange.startDate;
                this.endDate = dateRange.endDate;
                this._selectedItem = null;
                this.reload();
            });
            return Promise.resolve(true);
        } catch (error) {
            app.showErrorFromJsonResult(error);
            return Promise.resolve(false);
        }
    }

    public selectYear(year: number): Promise<boolean> {
        return this.selectYearAndMonth(year, this.month);
    }
    public selectMonth(month: number): Promise<boolean> {
        return this.selectYearAndMonth(this.year, month);
    }

    /** Navigate to previous month */
    @action.bound public goPrevMonth = () => {
        let year = this.year;
        let month = this.month;
        if (month == 0) {
            month = 11;
            year -= 1;
        } else {
            month -= 1;
        }
        this.selectYearAndMonth(year, month);
    }


    /** Navigate to next month */
    @action.bound goNextMonth = () => {
        let year = this.year;
        let month = this.month;
        if (month < 11) {
            month += 1;
        } else {
            month = 0;
            year += 1;
        }
        this.selectYearAndMonth(year, month);
    }



}
