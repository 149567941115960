import * as React from "react";
import { SplitPaneWithSidebar, SidebarMenuItem } from "@src/component/Sidebar";
import { CoursePageHeader } from "./CoursePageHeader";
import { Route, match } from "react-router";
import { app } from "@src/index";
import ViewKapCourse, { IViewKapCourseRecord } from "@src/framework/view/kap/ViewKapCourse";
import { BubbleLoader } from 'react-css-loaders';
import { courseModule } from "../../courseModule";
import { PATH_PUBLISHED_BOOK } from "@src/Routes";
import ViewKapTrainingType, { IViewKapTrainingTypeRecord } from "@src/framework/view/kap/ViewKapTrainingType";
import { CourseDetailsDataPage } from "./CourseDetailsDataPage";
import { me, hasAnyGroup, Groups, hasGroup } from "@src/framework/server/Auth";
import ViewKapCourseUser, { IViewKapCourseUserRecord } from "@src/framework/view/kap/ViewKapCourseUser";
import ViewKapCourseTrainerInvitation, { IViewKapCourseTrainerInvitationRecord } from "@src/framework/view/kap/ViewKapCourseTrainerInvitation";
import WfAPI, { IViewTransitionTypeRecordWithPerm } from "@src/framework/wf/WfAPI";
import obtainServer from "@src/framework/server/Server";
import ViewMediaOoFile, { IViewMediaOoFileRecord } from "@src/framework/view/media/ViewMediaOoFile";
import CourseContactDayCrud, { ICourseContactDayRecord } from "@src/framework/crud/kap/CourseContactDayCrud";
import { IViewKapCourseInstituteSiteRecord } from "@src/framework/view/kap/ViewKapCourseInstituteSite";
import CourseStudentList from "../../CourseStudentList";
import CourseDocumentComponent from "../../CourseDocumentsComponent";
import { CourseDetailsTaskPage } from "./CourseDetailsTaskPage";
import { Link } from "react-router-dom";
import { __ } from "@src/translation";
import { CourseDetailsStatsPage } from "./CourseDetailsStatsPage";
import { CourseDetailsUsersPage } from "./CourseDetailsUsersPage";
import TrainingTypeMilestoneCrud, { ITrainingTypeMilestoneRecord } from "@src/framework/crud/kap/TrainingTypeMilestoneCrud";
import { ForumModule } from "@src/module/forumModule/forumModule";
import { CourseDetailsForumPage } from "./CourseDetailsForumPage";
import BookCrud, { IBookRecord } from "@src/framework/crud/doc_pub/BookCrud";
import CourseCrud from "@src/framework/crud/kap/CourseCrud";
import { SurveyComponent } from "@src/component/survey/SurveyComponent";
import { WF_COURSE_STATION_INACTIVE_ID, WF_COURSE_STATION_ACTIVE_ID } from "../../CourseDetails";
import { SurveyStartComponent } from "@src/component/survey/SurveyStartComponent";
import { SurveySummaryComponent } from "@src/component/survey/SurveySummaryComponent";

type CourseDetailsMainProps = {
    match: match<{courseId: string}>
}

type CourseDetailsMainState = {
    courseRecord?: IViewKapCourseRecord;
    trainingType?: IViewKapTrainingTypeRecord;
    courseUser: IViewKapCourseUserRecord | null;
    contactDates: ICourseContactDayRecord[];
    publishedBook?: IBookRecord;
    isTeacher: boolean;
    isInspector: boolean;
    possibleCourseUserTransitions: IViewTransitionTypeRecordWithPerm[];
    trainingTypeFiles: IViewMediaOoFileRecord[];
    sites: IViewKapCourseInstituteSiteRecord[];
    milestones: ITrainingTypeMilestoneRecord[];
    lastInvitationDeadline?: string;
    otherJsonB:any;
}

const SURVEY_USER_BLENDED_ID = 6;
const SURVEY_USER_ONLINE_ID = 7;
const SURVEY_TEACHER_ID = 8;

export class CourseDetailsMain extends React.Component<CourseDetailsMainProps, CourseDetailsMainState> {
    
    static wfAPI: WfAPI = new WfAPI(obtainServer());

    constructor(props: CourseDetailsMainProps) {
        super(props);

        this.state = {
            courseUser: null,
            isTeacher: false,
            isInspector: false,
            possibleCourseUserTransitions: [],
            trainingTypeFiles: [],
            sites: [],
            contactDates: [],
            milestones: [],
            otherJsonB:{}
        }
    }

    componentDidMount() {
        this.reloadAsync();
    }

    private async reloadAsync() {
        try {
            
            const courseId = Number(this.props.match.params.courseId);
            
            const courseRecord = (await ViewKapCourse.load(courseId)).record;
            const contactDates = (await CourseContactDayCrud.list({ filter: { is_active: true, course_id: Number(courseId) } }));
            const trainingType = (await ViewKapTrainingType.load(courseRecord.training_type_id!)).record;
            const trainingTypeFiles = await ViewMediaOoFile.list({ filter: { is_active: true, oo_folder_id: trainingType.oo_folder_id }, order_by: [{ name: "title" }] });
            const milestones = await TrainingTypeMilestoneCrud.list({filter: {training_type_id: trainingType.id, is_active: true}, order_by: "no"});
            const tmp = (await CourseCrud.load(courseId)).record;
            let otherJsonB={};
            if(tmp) otherJsonB=tmp.other_jsonb;            

            let publishedBook = undefined;
            try {
                publishedBook = trainingType.book_id ? (await BookCrud.load(trainingType.book_id)).record : undefined;
            } catch(e) {}

            let courseUser = null;
            let isTeacher = false;
            let isInspector = false;
            let possibleCourseUserTransitions: IViewTransitionTypeRecordWithPerm[] = [];
            let lastInvitationDeadline = "";
            let trainerInvitations: IViewKapCourseTrainerInvitationRecord[] = [];

            if (me && trainingType) {
                if (me.id == courseRecord.teacher_id) {
                    isTeacher = true;
                } else if (me.id == courseRecord.oh_inspector_id) {
                    isInspector = true;
                } else {
                    let courseUsers: IViewKapCourseUserRecord[] = (await ViewKapCourseUser.list({ filter: { course_id: courseRecord.id, sec_user_id: me.id } }));
                    if (courseUsers.length) {
                        courseUser = courseUsers[0];
                        const workflowId = courseUser.wf_workflow_id!;
                        possibleCourseUserTransitions = await CourseDetailsMain.wfAPI.listPossibleTransitions(workflowId);
                    }
                }

                if (isTeacher) {
                    trainerInvitations = (await ViewKapCourseTrainerInvitation.list({ filter: { course_id: courseId }, order_by: [{ name: "answer_deadline", desc: true }] }));
                    lastInvitationDeadline = trainerInvitations[0] && trainerInvitations[0].answer_deadline ? trainerInvitations[0].answer_deadline : "";
                }
            }
            
            this.setState({
                courseRecord,
                trainingType,
                courseUser,
                isTeacher,
                isInspector,
                lastInvitationDeadline,
                possibleCourseUserTransitions,
                contactDates,
                milestones,
                publishedBook,
                otherJsonB,
                trainingTypeFiles
            });

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    renderUsersPage() {
        return <CourseDetailsUsersPage courseRecord={this.state.courseRecord!}/>;
    }

    renderStudentsList() {
        return <CourseStudentList currentCourse={this.state.courseRecord} editable={false} isInspector={this.state.isInspector} contactDays={this.state.contactDates} milestones={this.state.milestones} />;
    }

    render() {
        if (!this.state.courseRecord || !this.state.trainingType) {
            return <BubbleLoader />;
        }

        const courseId = this.state.courseRecord.id!;

        const showAdminData = me && hasAnyGroup(me, [Groups.KapKszrCourseCoordinator, Groups.Developer]);
        
        const PATH_ROOT = courseModule.PATH_COURSE_ORGANIZER_COURSE + "/" + courseId;
        const PATH_DATA = PATH_ROOT + "/adatok";
        const PATH_MEMBERS = PATH_ROOT + "/tagok";
        const PATH_BOOK = this.state.trainingType.book_id?PATH_PUBLISHED_BOOK + "/" + this.state.trainingType.book_uri_segment + "/":"";//todo: use this.state.trainingType.material_url
        const PATH_TASK = PATH_ROOT + "/beadando";
        const PATH_DOCUMENTS = PATH_ROOT + "/dokumentumok";
        const PATH_STATS = PATH_ROOT + "/elorehaladas";
        const PATH_FORUM = PATH_ROOT + "/forum";
        const PATH_SURVEY = PATH_ROOT + "/kerdoiv";
        const PATH_SURVEY_STATS = PATH_ROOT + "/kerdoiv_statisztika";

        const menuItems:SidebarMenuItem[] = [];

        menuItems.push({name: "Alapadatok", linkTo: PATH_DATA, iconClass: "fas fa-info"});

        if (showAdminData || this.state.isTeacher || this.state.isInspector || this.state.courseUser) {
            menuItems.push({name: "Résztvevők", linkTo: PATH_MEMBERS, iconClass: "fas fa-users"});
        }

        if (this.state.publishedBook) {
            menuItems.push({name: "Tananyag", linkTo: PATH_BOOK, iconClass: "fas fa-book", target: "_blank"});
        }

        if (this.state.courseUser) {
            menuItems.push({name: "Beadandó", linkTo: PATH_TASK, iconClass: "fas fa-file-export"});
        }

        if (showAdminData || this.state.isTeacher) {
            menuItems.push({name: "Dokumentumok", linkTo: PATH_DOCUMENTS, iconClass: "fas fa-folder"});
        }
        if(showAdminData || this.state.isTeacher || this.state.isInspector || this.state.courseUser) {
            menuItems.push({name: "Előrehaladás", linkTo: PATH_STATS, iconClass: "fas fa-road"});
        }

        if (me && ForumModule.isInitialized && (showAdminData || this.state.isTeacher || this.state.courseUser || this.state.isInspector)) {
            menuItems.push({name: "Fórum", linkTo: PATH_FORUM, iconClass: "fas fa-comments"});
        }

        const courseIsFinished = ![WF_COURSE_STATION_INACTIVE_ID, WF_COURSE_STATION_ACTIVE_ID].includes(this.state.courseRecord.wf_station_id!);

        let surveyId: number | null = null;
        if (this.state.courseUser && !this.state.courseRecord.is_online) surveyId = SURVEY_USER_BLENDED_ID;
        if (this.state.courseUser && this.state.courseRecord.is_online) surveyId = SURVEY_USER_ONLINE_ID;
        if (this.state.isTeacher) surveyId = SURVEY_TEACHER_ID;

        if (surveyId && courseIsFinished) {
            menuItems.push({name: "Kérdőív", linkTo: PATH_SURVEY, iconClass: "fas fa-question"});
        }

        if (courseIsFinished && (showAdminData || this.state.isTeacher)) {
            menuItems.push({name: "Kérdőív statisztika", linkTo: PATH_SURVEY_STATS, iconClass: "fas fa-question"});
        }

        const mainComponent = <CourseDetailsDataPage 
            courseUser={this.state.courseUser}
            trainingType={this.state.trainingType}
            courseRecord={this.state.courseRecord}
            isTeacher={this.state.isTeacher}
            isInspector={this.state.isInspector}
            possibleCourseUserTransitions={this.state.possibleCourseUserTransitions}
            trainingTypeFiles={this.state.trainingTypeFiles}
            sites={this.state.sites}
            contactDates={this.state.contactDates}
            onReload={this.reloadAsync.bind(this)}
            otherJsonB={this.state.otherJsonB}
            />;

        return <SplitPaneWithSidebar 
                sideBarSize={200} 
                sidebarProps={{
                    menuItems: menuItems,
                    closable: true,
                    title: <div><Link to={courseModule.PATH_COURSE_ORGANIZER_COURSE_PUBLIC}><i className="fas fa-arrow-left" style={{ marginRight: "10px" }}></i>{__("KSZR")}</Link></div>
                }} >
            <div className="course-details-page">
                <CoursePageHeader 
                    courseRecord={this.state.courseRecord}
                    trainingType={this.state.trainingType}
                />
                <div className="course-content">
                    <Route path={PATH_ROOT} exact render={() => mainComponent} />
                    <Route path={PATH_DATA} exact render={() => mainComponent} />
                    {/*Kurzus résztvevő oldal: Hallgató csak egyszerű nézetet lát, szervező ill. OH ellenőr a táblázatosat */}
                    <Route path={PATH_MEMBERS} exact render={() => this.state.courseUser ? this.renderUsersPage() : this.renderStudentsList()} />
                    <Route path={PATH_TASK} exact render={() => this.state.courseUser && <CourseDetailsTaskPage trainingType={this.state.trainingType!} courseRecord={this.state.courseRecord!} milestones={this.state.milestones} courseUser={this.state.courseUser} isTeacher={this.state.isTeacher} onReload={this.reloadAsync.bind(this)} /> } />
                    <Route path={PATH_DOCUMENTS} exact render={() => <CourseDocumentComponent currentCourse={this.state.courseRecord} contactDates={this.state.contactDates} />} />
                    <Route path={PATH_STATS} exact render={() => <CourseDetailsStatsPage courseRecord={this.state.courseRecord!} isStudent={this.state.courseUser !== null} trainingType={this.state.trainingType!} />} />
                    <Route path={PATH_FORUM} exact render={() => <CourseDetailsForumPage courseRecord={this.state.courseRecord!} trainingType={this.state.trainingType!} />} />

                    {
                        surveyId &&
                        <Route path={PATH_SURVEY} exact render={() => <SurveyComponent table_info_id={CourseCrud.TABLE_INFO_ID} recId={this.state.courseRecord!.id} survey_id={surveyId!} onSurveyFinished={() => {}} enableSkip={false} />} />
                    }

                    <Route path={PATH_SURVEY_STATS} exact render={() => 
                        <>
                            <SurveySummaryComponent
                                table_info_id={CourseCrud.TABLE_INFO_ID} 
                                recId={this.state.courseRecord!.id} 
                                survey_id={this.state.courseRecord!.is_online ? SURVEY_USER_ONLINE_ID : SURVEY_USER_BLENDED_ID}
                            />
                            {
                                showAdminData &&
                                <SurveySummaryComponent
                                    table_info_id={CourseCrud.TABLE_INFO_ID} 
                                    recId={this.state.courseRecord!.id} 
                                    survey_id={SURVEY_TEACHER_ID}
                                />
                            }
                        </>
                        } 
                    />
                    
                </div>

            </div>

        </SplitPaneWithSidebar>;
    }

}
