import * as React from 'react';

type BackdropProps = {
    onClick: () => void;
    transparent: boolean;
}

export class Backdrop extends React.Component<BackdropProps, {}> {

    render() {
        return <div style={{position: "fixed", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: this.props.transparent ? undefined : "rgba(0, 0, 0, 0.5)"}} 
            onClick={(e) => {this.props.onClick(); e.stopPropagation();}}>
        
        </div>;
    }

}
