import * as React from 'react';

import { viewTableInfoClassProxy } from '@src/framework/view/meta/ViewTableInfo';
import TableInfoListView from '@src/component/meta/meta_table_info_listview';
import TableInfoEditor from '@src/component/meta/meta_table_info_editor';

import { viewMetaCategoryTypeClassProxy } from '@src/framework/view/meta/ViewMetaCategoryType';
import CategoryTypeListView from '@src/component/meta/category_type_listview';
import CategoryTypeEditor from '@src/component/meta/category_type_editor';

import { viewMetaTableCategoryTypeClassProxy } from '@src/framework/view/meta/ViewMetaTableCategoryType';
import TableCategoryTypeListView from '@src/component/meta/table_category_type_listview';
import TableCategoryTypeEditor from '@src/component/meta/table_category_type_editor';

import { viewMetaViewInfoClassProxy } from '@framework/view/meta/ViewMetaViewInfo';
import ViewInfoListView from '@src/component/meta/meta_view_info_listview';
import ViewInfoEditor from '@src/component/meta/meta_view_info_editor';
import MetaTeszt from '@src/component/meta/meta_teszt';
import { BookLessonMatcher, BookLessonMatcherList } from '@src/component/meta/BookLessonMatcher';

import AdminRoute from '@src/Routes'

export function MetaRoutes() {
   
    return [
        
        <AdminRoute path={viewTableInfoClassProxy.getDefaultListRoute()} component={TableInfoListView} key="TableInfoListView"/>,
        <AdminRoute path={viewTableInfoClassProxy.getDefaultEditRoute()} component={TableInfoEditor} key="TableInfoEditor"/>,

        <AdminRoute path={viewMetaViewInfoClassProxy.getDefaultListRoute()} component={ViewInfoListView} key="ViewInfoListView"/>,
        <AdminRoute path={viewMetaViewInfoClassProxy.getDefaultEditRoute()} component={ViewInfoEditor} key="ViewInfoEditor"/>,

        <AdminRoute path={viewMetaCategoryTypeClassProxy.getDefaultListRoute()} component={CategoryTypeListView} key="CategoryTypeListView"/>,
        <AdminRoute path={viewMetaCategoryTypeClassProxy.getDefaultEditRoute()} component={CategoryTypeEditor} key="CategoryTypeEditor"/>,

        <AdminRoute path={viewMetaTableCategoryTypeClassProxy.getDefaultListRoute()} component={TableCategoryTypeListView} key="TableCategoryTypeListView"/>,
        <AdminRoute path={viewMetaTableCategoryTypeClassProxy.getDefaultEditRoute()} component={TableCategoryTypeEditor} key="TableCategoryTypeEditor"/>,
 
        <AdminRoute path="/meta/teszt" component={MetaTeszt} key="MetaTeszt"/>,

        
        <AdminRoute path="/meta/match" exact={true} component={BookLessonMatcherList} key="BookLessonMatcherList" />,
        <AdminRoute path="/meta/match/:bookId" exact={true} component={BookLessonMatcher} key="BookLessonMatcher" />


    ];
}

