import * as React from "react";
import { app } from '@src/index';
import CourseCrud, { ICourseRecord } from "@src/framework/crud/kap/CourseCrud";
import ViewKapCourseUser, { IViewKapCourseUserRecord } from "@src/framework/view/kap/ViewKapCourseUser";
import { hasAnyGroup, me, Groups, hasGroup, } from "@src/framework/server/Auth";
import { WorkflowDialog } from "@src/component/wf/WorkflowDialog";
import { __ } from "@src/translation";
import CourseUserCrud, { ICourseUserRecord } from "@src/framework/crud/kap/CourseUserCrud";
import SecUserSelector from "@src/framework/forms/SecUserSelector";
import ReactTable, { RowInfo, Column, TableProps } from "react-table";
import { getWfStateCell } from "@src/component/wf/wf_util";
import { Link } from "react-router-dom";
import { viewKapTrainingPermitClassProxy } from "@src/framework/view/kap/ViewKapTrainingPermit";
import { DownloadAsPdf } from "../draft/DownloadAdPdf";
import { WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID, WF_COURSE_USER_STATION_APPLICATION_CORRECTION_ID } from './CourseDetails';
import TrainingPermitCrud, { ITrainingPermitRecord } from '@src/framework/crud/kap/TrainingPermitCrud';
import { Dialog, DialogContent } from "@src/component/Dialog";
import { getReactTableLabels } from "@src/framework/i18n";
import { IListParams } from "@src/framework/crud/Crud";
import { formatDate, addToDate, formatDateTime } from '@src/Util';
import KszrQuestionnarieInCrud, { IKszrQuestionnarieInRecord } from "@src/framework/crud/kap/KszrQuestionnarieInCrud";
import { ICourseContactDayRecord } from "@src/framework/crud/kap/CourseContactDayCrud";
import { ViewTimesByUserId, getLessonViewSummariesFormatted, UserEventTargets, UserEventTypes } from "@src/server/PublicServer";
import TrainingTypeCrud from "@src/framework/crud/kap/TrainingTypeCrud";
import NewMessagePopUp from "@src/module/messengerModule/components/NewMessagePopUp";
import { messengerModule } from "@src/module/messengerModule/messengerModule";
import { IMessengerSummaryInfo } from "@src/module/messengerModule/messengerAPI";
import { ITrainingTypeMilestoneRecord } from "@src/framework/crud/kap/TrainingTypeMilestoneCrud";
import { DateToString, DTSMode } from "@src/component/dashboard/eventcalendar/EventCalendarAPI";
import { FileLinkListComponent } from "@src/component/ui/FileLinkListComponent";
import { BubbleLoader } from 'react-css-loaders';
import CourseUserMilestoneCrud, { ICourseUserMilestoneRecord } from "@src/framework/crud/kap/CourseUserMilestoneCrud";
import WfAPI from "@src/framework/wf/WfAPI";
import obtainServer from "@src/framework/server/Server";
import { transitionTypeCrudClassProxy } from "@src/framework/crud/wf/TransitionTypeCrud";
import { WF_STATION_KSZR_COURSE_USER_STATION_KEPZES_ENGEDLYEZVE_ID } from "@src/Const";
import * as PublicServer from '@src/server/PublicServer';
import UserTracer from "@src/component/book/UserTracer";

type StudentListProps = {
    currentCourse?: ICourseRecord,
    editable: boolean,
    isInspector?: boolean
    contactDays?: ICourseContactDayRecord[],
    milestones?: ITrainingTypeMilestoneRecord[]
}

type StudentListState = {
    courseUsers: IViewKapCourseUserRecord[],
    loading: boolean,
    userinfoLoading: boolean,
    workflowDialogOpen: boolean,
    userInfoDialogOpen: boolean,
    checkListDialogOpen: boolean,
    selectedUser?: IViewKapCourseUserRecord,
    selectedUserId?: number | null
    userIdsWithQuestionaries: number[];
    currentViewTimes: ViewTimesByUserId;
    checked: number[];
    messageOpen: boolean,
    messageSummary: IMessengerSummaryInfo | null;
    mileStoneDeadlines: Map<number, string>,
}

const WF_STATION_SUCCESSFUL_ASSIGNMENT_ID = 25406740440;
const checklist = {
    0: "Mappa gerinc",
    1: "ID szám",
    2: "Képzés dátuma",
    3: "Akk. szám",
    4: "Dátum egyezőség",
    5: "Gerinc",
    6: "Jelenléti ív",
    7: "Haladási napló",
    8: "Szerződések",
    9: "Résztvevői tájékoztató",
    10: "Jegyzőkönyv",
    11: "Tanusítvány és átvételi",
    12: "Továbbképzési napló",
    13: "Résztvevői tájékoztató",
    14: "Létszám egyezőség",
    15: "Jelentkezési lap",
    16: "Szerződések",
    17: "Diploma másolat - KAK",
    18: "Nyilatkozat tudástárhoz - KAK",
    19: "Jegyzőkönyv",
    20: "Indok az eltéréstől",
    21: "Tanusítvány másolat",
    22: "Tanusítvány átvételi",
    23: "Helyszín bejelentő",
    24: "OSAP",
    25: "Fotódokumentáció"
}
export default class CourseStudentList extends React.Component<StudentListProps, StudentListState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            userinfoLoading: false,
            workflowDialogOpen: false,
            userInfoDialogOpen: false,
            checkListDialogOpen: false,
            courseUsers: [],
            userIdsWithQuestionaries: [],
            currentViewTimes: {},
            messageSummary: null,
            checked: [],
            messageOpen: false,
            mileStoneDeadlines: new Map<number, string>(),
        };
    }

    componentDidMount() {
        this.asyncReload();
    }

    public async saveContactDays() {
        for (const user of this.state.courseUsers) {
            await new CourseUserCrud({ id: user.id, other_jsonb: user.other_jsonb }).put();
        }
    }

    private asyncReload = async () => {
        this.setState({ loading: true });
        let courseUsers: IViewKapCourseUserRecord[] = [];
        let mileStoneDeadlines = new Map<number, string>();
        try {
            let messageSummary: IMessengerSummaryInfo | null = null;
            if (this.props.currentCourse && this.props.currentCourse.id) {
                courseUsers = (await ViewKapCourseUser.list({ filter: { course_id: this.props.currentCourse.id, is_active: true }, order_by: "participant_fullname" }));
                // Csak az OH monitoringos kérdezheti le az üzenet summary-t!
                if (me && this.props.currentCourse.oh_inspector_id === me.id!) {
                    messageSummary = await messengerModule.getMessagesSummary(CourseCrud.TABLE_INFO_ID, this.props.currentCourse.id);
                }
            }
            if (this.props.milestones && this.props.currentCourse && this.props.currentCourse.training_from) {
                const startDate = this.props.currentCourse.training_from;
                this.props.milestones.forEach(element => {
                    if (element.deadline_days) {
                        //const sDate= Date.parse(startDate);
                        const newD = DateToString(addToDate(startDate, 0, 0, element.deadline_days), DTSMode.dateFull, "-");
                        mileStoneDeadlines.set(element.id!, newD);
                    }
                });
            }

            const questionaries = await KszrQuestionnarieInCrud.list({
                filter: {
                    sec_user_id: courseUsers.map(u => u.sec_user_id!)
                }
            });

            let currentViewTimes: ViewTimesByUserId = {};
            if (this.props.currentCourse) {
                const trainingType = (await TrainingTypeCrud.load(this.props.currentCourse.training_type_id!)).record;
                const userIds: number[] = courseUsers.map((courseUser) => courseUser.sec_user_id!);
                currentViewTimes = await getLessonViewSummariesFormatted(userIds, [trainingType.book_id!]);
            }

            this.setState({
                loading: false, courseUsers,
                userIdsWithQuestionaries: questionaries.map(q => q.sec_user_id!),
                currentViewTimes,
                messageSummary,
                mileStoneDeadlines
            });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        };
    }

    private showWFDialog(selectedParticipant: IViewKapCourseUserRecord) {
        if (selectedParticipant) {
            this.setState({ selectedUser: selectedParticipant, workflowDialogOpen: true });
        }
    }

    private onDialogShowChanged(selectedParticipant: IViewKapCourseUserRecord) {
        if (selectedParticipant) {
            this.setState({ selectedUser: selectedParticipant, userInfoDialogOpen: true });
        }
    }

    private onCheckContactDay(row: IViewKapCourseUserRecord, cday: ICourseContactDayRecord, event: any) {
        if (!row.other_jsonb) row.other_jsonb = {};
        if (!row.other_jsonb.contact_day) row.other_jsonb.contact_day = [];
        if (event.target.checked) row.other_jsonb.contact_day.push(cday.id)
        else {
            let index = row.other_jsonb.contact_day.indexOf(cday.id);
            if (index !== -1) row.other_jsonb.contact_day.splice(index, 1);
        }
        this.setState({ courseUsers: this.state.courseUsers });
    }

    private async saveChanges() {
        if (!this.props.currentCourse || !this.props.currentCourse.id) {
            app.showError(__("Hiba"), __("Hallgató hozzáadásához először el kell mentenie a kurzust"));
        }
        try {
            this.setState({ loading: true });

            this.state.courseUsers.forEach(element => {

            });

            for (let index = 0; index < this.state.courseUsers.length; index++) {
                const element = this.state.courseUsers[index];

                let courseUser = (await new CourseUserCrud({
                    id: element.id,
                    other_jsonb: element.other_jsonb
                }).put()).record;
            }
            app.showSuccess(__("Mentés"), __("Sikeres mentés!"));
            this.setState({ loading: false, selectedUserId: null }, () => this.asyncReload());
        } catch (error) {
            this.setState({ loading: false });
            app.showErrorFromJsonResult(error);
        }

    }

    private addSelectedParticipant = async () => {
        if (!this.props.currentCourse || !this.props.currentCourse.id) {
            app.showError(__("Hiba"), __("Hallgató hozzáadásához először el kell mentenie a kurzust"));
        }
        try {
            this.setState({ loading: true });
            let newRecord: ICourseUserRecord = {
                course_id: this.props.currentCourse!.id,
                sec_user_id: this.state.selectedUserId!
            };
            let users = await ViewKapCourseUser.list({ filter: { sec_user_id: this.state.selectedUserId, course_id: this.props.currentCourse!.id } });
            let courseUsers = this.state.courseUsers;

            if (users.length != 0) {
                let user = users[0];
                if (user.wf_station_id == WF_STATION_KSZR_COURSE_USER_STATION_KEPZES_ENGEDLYEZVE_ID) {
                    app.showError(__("Figyelmeztetés"), __("Ez a hallgató már fel van véve!"));
                    this.setState({ loading: false, selectedUserId: null });
                    return;
                }
                let wfApi = new WfAPI(obtainServer());
                const transitionTypes = await transitionTypeCrudClassProxy.list({
                    filter: {
                        is_active: true,
                        src_station_id: user.wf_station_id,
                        dst_station_id: WF_STATION_KSZR_COURSE_USER_STATION_KEPZES_ENGEDLYEZVE_ID
                    }
                });
                if (transitionTypes && transitionTypes.length == 1) {
                    const transitionTypeId: number = transitionTypes[0].id!;
                    wfApi.makeTransition(user.wf_workflow_id!, transitionTypeId, "Hallgató újra felvéve");
                    const updatedRec = (await ViewKapCourseUser.load(user.id!)).record;
                    courseUsers.push(updatedRec);
                }

            } else {
                let rec = (await new CourseUserCrud(newRecord).put()).record;
                let vCourseUser = (await ViewKapCourseUser.load(rec.id!)).record;
                courseUsers.push(vCourseUser);
            }
            app.showSuccess(__("Mentés"), __("Hallgató hozzáadása sikeres!"));
            this.setState({ loading: false, selectedUserId: null, courseUsers });

        } catch (error) {
            this.setState({ loading: false });
            app.showErrorFromJsonResult(error);
        }
    }

    private toggleChecked = (recordID: number): void => {
        let checked = this.state.checked;
        let idx = checked.findIndex((el) => { return el == recordID })
        if (idx > -1) {
            checked.splice(idx, 1);
        } else {
            checked.push(recordID);
        }
        this.setState({ checked });
    }

    private checkAll = () => {
        if (this.state.checked.length < this.state.courseUsers!.length) {
            const allIds = this.state.courseUsers.map(rec => rec.sec_user_id!);
            this.setState({ checked: allIds });
        } else {
            this.setState({ checked: [] });
        }
    }

    private showMessageBox() {
        if (this.state.checked.length < 1) {
            app.showError(__("Hiba"), __("Legalább egy címzett megadása kötelező"));
            return;
        }
        this.setState({ messageOpen: true });
    }

    public closeMessageBox() {
        this.setState({ messageOpen: false });
    }

    handleChecklistChange(key: number, event: any) {
        if (this.state.selectedUser) {
            if (!this.state.selectedUser.other_jsonb) this.state.selectedUser.other_jsonb = {};
            if (!this.state.selectedUser.other_jsonb.checklist) this.state.selectedUser.other_jsonb.checklist = [];
            if (event.target.checked) this.state.selectedUser.other_jsonb.checklist.push(key)
            else {
                let index = this.state.selectedUser.other_jsonb.checklist.indexOf(key);
                if (index !== -1) this.state.selectedUser.other_jsonb.checklist.splice(index, 1);
            }
            this.setState({ courseUsers: this.state.courseUsers });
        }

    }

    render() {
        if (!me || (!hasAnyGroup(me, [Groups.KapKszrCourseCoordinator, Groups.KapKszrCourseTeacher, Groups.Developer, Groups.Admin, Groups.KapKszrOhInspector]))) {
            return "";
        }

        const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};

            if (column && rowInfo && rowInfo.row) {
                let row = rowInfo.row._original;
                if (row && column.id == "wf_station_name") {
                    props.style = row.wf_station_style || {};
                }
            }
            return props;
        }


        let subComponent = undefined;
        if (!this.props.isInspector) {
            subComponent = (row: RowInfo) => {
                const curr_part: IViewKapCourseUserRecord = row.original;
                const sec_user_id: number = curr_part.sec_user_id!;
                let viewTime = this.state.currentViewTimes[sec_user_id] || "00:00";
                const qFilled: boolean | undefined = (curr_part.sec_user_id != undefined) && this.state.userIdsWithQuestionaries.includes(sec_user_id);
                const isTeacher: boolean | null = me && this.props.currentCourse!.teacher_id === me.id;

                return (
                    <div className="student-list-subcomponent">
                        <UserTracer
                            event={{
                                tableInfoId: CourseUserCrud.TABLE_INFO_ID,
                                recordId: curr_part.id!,
                                name: curr_part.participant_fullname || "",
                                data: {
                                    eventTarget: UserEventTargets.TEACHER,
                                    eventType: UserEventTypes.STUDENT_DATA_CHECK,
                                    courseId: this.props.currentCourse!.id!
                                }
                            }}
                        />
                        <div>
                            <span>{__("Tananyaggal eltöltött idő (óra:perc):")} {viewTime}</span>
                        </div>

                        <StudentFilesComponent courseUser={curr_part}
                            isQuestionareFilled={qFilled} isTeacher={isTeacher}
                            milestones={this.props.milestones}
                            mileStoneDeadlines={this.state.mileStoneDeadlines} />

                    </div>);
            };
        } else {
            subComponent = (row: RowInfo) => {
                const curr_part: IViewKapCourseUserRecord = row.original;
                const sec_user_id: number = curr_part.sec_user_id!;
                let viewTime = this.state.currentViewTimes[sec_user_id] || "00:00";

                return (
                    <div className="student-list-subcomponent">
                        <div>
                            <span>{__("Tananyaggal eltöltött idő (óra:perc):")} {viewTime}</span>
                        </div>
                        <StudentFilesComponent courseUser={curr_part}
                            isQuestionareFilled={false} isTeacher={false}
                            isOHInspector={true}
                            milestones={this.props.milestones}
                            mileStoneDeadlines={this.state.mileStoneDeadlines} />

                    </div>);
            };

        }

        let columns: Column[] = [
            {
                Header: <div>
                    <button
                        title={__("Mindet kijelöl")}
                        className="button secondary"
                        onClick={this.checkAll}
                        disabled={!this.state.courseUsers || !(this.state.courseUsers!.length)}
                        style={{
                            padding: 3,
                            margin: 0
                        }}
                    >
                        <i className="fa fa-check-double" />
                    </button>
                </div>,
                accessor: "name",
                filterable: false,
                sortable: false,
                minWidth: 30,
                show: !this.props.isInspector,

                Cell: (data: any, column: any) => {
                    const row: IViewKapCourseUserRecord = data.original;
                    return row.id
                        ? <input type="checkbox"
                            value="1"
                            checked={this.state.checked.findIndex((r) => { return r == row.sec_user_id! }) > -1}
                            onChange={() => this.toggleChecked(row.sec_user_id!)}
                        />
                        : null;
                }
            },
            {
                Header: __("Teljes név"), accessor: "participant_fullname", filterable: false, sortable: true,
                Cell: (data: any, column: any) => {
                    const row: IViewKapCourseUserRecord = data.original;
                    return (<p>
                        <b>{row.participant_fullname}</b> <br />
                        <span style={{ fontFamily: "monospace" }}>{row.participant_email}</span><br></br>
                        <span style={{ fontFamily: "monospace" }}>{row.participant_institute_site}</span>
                    </p>);
                }, style: { justifyContent: "left" }
            },
            {
                Header: __("Képzési engedély"), accessor: "training_permit_station_name", filterable: false, sortable: true,
                Cell: (data: any, column: any) => {
                    const row: IViewKapCourseUserRecord = data.original;
                    let newObj = {
                        original: {
                            id: row.training_permit_id,
                            wf_workflow_id: row.training_permit_workflow_id,
                            wf_station_id: row.training_permit_station_id,
                            wf_station_name: row.training_permit_station_name
                        }
                    };
                    return getWfStateCell(newObj, column)
                }
                , style: { justifyContent: "left" }
            },
            {
                Header: __("Állapot"), accessor: "wf_station_name", filterable: false, sortable: true, maxWidth: 150,
                Cell: (data: any, column: any) => {
                    return getWfStateCell(data, column)
                }
                , style: { justifyContent: "left" }
            }
        ];

        if (!this.props.isInspector) {
            columns.push({
                Header: __("Művelet"), accessor: "id", filterable: false, sortable: true, maxWidth: 150,
                Cell: (data: any, column: any) => {
                    const row: IViewKapCourseUserRecord = data.original;
                    if (!me) return "";
                    return <div><button className="button small primary exercise-series-small-btn" title={__("Folyamat")} onClick={this.showWFDialog.bind(this, row)}><i className="fa fa-project-diagram"></i></button>
                        {(hasAnyGroup(me, [Groups.KapKszrCourseCoordinator, Groups.Developer])) && <Link to={viewKapTrainingPermitClassProxy.getEditUrl(row.training_permit_id!, 0)} className="button small primary exercise-series-small-btn" title={__(row.participant_fullname + " képzési engedélye")} ><i className="fa fa-graduation-cap"></i></Link>}
                        {(hasAnyGroup(me, [Groups.KapKszrCourseCoordinator, Groups.Developer]))
                            ? <button className="button small primary exercise-series-small-btn" title={__(row.participant_fullname + " összes fölvett kurzusa")} onClick={this.onDialogShowChanged.bind(this, row)}><i className="fa fa-info"></i></button>
                            : ""
                        }
                        {this.props.contactDays && hasAnyGroup(me, [Groups.Developer, Groups.KapKszrCourseCoordinator, Groups.KapKszrCourseTeacher]) ? this.props.contactDays.map((cday: ICourseContactDayRecord, i: number) => {
                            let checked: boolean = (row.other_jsonb && row.other_jsonb.contact_day && row.other_jsonb.contact_day.includes(cday.id));
                            return (<label><input type="checkbox" checked={checked} onChange={this.onCheckContactDay.bind(this, row, cday)} />{i + 1 + __(". napon megjelent")}</label>)
                        }
                        ) : ""}
                        {hasAnyGroup(me, [Groups.Developer, Groups.KapKszrCourseCoordinator, Groups.KapKszrCourseTeacher]) && <a onClick={() => this.setState({ checkListDialogOpen: true, selectedUser: row })}>{__("Csekklista")}</a>}
                    </div>;
                }
                , style: { justifyContent: "left" }
            });
        } else {
            columns.push({
                Header: <div>{__("Online aktivitás")}<br />{__("(óra:perc)")}</div>,
                accessor: "id", filterable: false, sortable: true, maxWidth: 120,
                Cell: (data: any, column: any) => {
                    const row: IViewKapCourseUserRecord = data.original;
                    return <span>{this.state.currentViewTimes[row.sec_user_id!] || "00:00"}</span>;
                }
                , style: { justifyContent: "left" }
            });

            columns.push({
                Header: __("Üzenetek"), accessor: "id", filterable: false, sortable: true, maxWidth: 120,
                Cell: (data: any, column: any) => {
                    const row: IViewKapCourseUserRecord = data.original;
                    const summary = this.state.messageSummary;
                    const rec = summary && summary.received[row.sec_user_id!] ? summary.received[row.sec_user_id!].received_count : 0;
                    const sent = summary && summary.sent[row.sec_user_id!] ? summary.sent[row.sec_user_id!].sent_count : 0;
                    return <div>
                        <span>{__("Küldött: ") + sent}</span><br />
                        <span>{__("Bejövő: ") + rec}</span>
                    </div>
                }
                , style: { justifyContent: "left" }
            });

        }

        const isCordinatoor = hasAnyGroup(me, [Groups.KapKszrCourseCoordinator, Groups.Developer]);
        const isTeacher = hasGroup(me, Groups.KapKszrCourseTeacher);
        let checkboxes = Object.keys(checklist).map(key => { return <div><input type="checkbox" checked={this.state.selectedUser && this.state.selectedUser.other_jsonb && this.state.selectedUser.other_jsonb.checklist && this.state.selectedUser.other_jsonb.checklist.includes(Number(key))} onChange={this.handleChecklistChange.bind(this, Number(key))}></input> {__(checklist[key])}</div> })
        return (<>
            {this.state.selectedUser && this.state.workflowDialogOpen ?
                <WorkflowDialog
                    open={this.state.workflowDialogOpen}
                    displayName={__("Hallgató:" + " " + (this.state.selectedUser ? this.state.selectedUser.participant_fullname! : ""))}
                    tableInfoId={CourseUserCrud.TABLE_INFO_ID}
                    recId={this.state.selectedUser.id!}
                    onClose={() => this.setState({ workflowDialogOpen: false, selectedUser: undefined })}
                    onTransition={this.asyncReload.bind(this)}
                    autoPublishOnClosedStation={true}
                /> : ""
            }
            <div className="large-12 columns">
                <ReactTable data={this.state.courseUsers}
                    columns={columns}
                    SubComponent={subComponent}
                    /*resizable={false}*/
                    pageSize={10}
                    getTdProps={getTdProps}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    showPageSizeOptions={false} />
            </div>
            {(this.props.editable || isTeacher || isCordinatoor) &&
                <>
                    {isCordinatoor &&
                        <>
                            <div className="large-11 medium-10 small-8 columns">
                                <SecUserSelector
                                    value={this.state.selectedUserId!}
                                    clearable={true}
                                    placeholder={__("Résztvevő hozzáadása...")}
                                    onChange={(selectedUserId: number | null) => { this.setState({ selectedUserId }); }}
                                />
                            </div>
                            <div className="large-1  medium-2 small-4 columns">
                                <button className="button small"
                                    disabled={!this.state.selectedUserId || this.state.loading}
                                    onClick={this.addSelectedParticipant.bind(this)}><i className="fa fa-save"></i></button>
                            </div>
                        </>}
                    <div className="large-12 columns">
                        {(isCordinatoor || isTeacher) && <button className="button success" onClick={this.saveChanges.bind(this)} disabled={this.state.loading}>{__("Módosítások mentése")}</button>}
                        <button className="button" onClick={this.showMessageBox.bind(this)} disabled={this.state.loading || this.state.checked.length <= 0}><i className="fa fa-paperplane" /> {__("Üzenet küldése a kijelölteknek ({count})", { count: this.state.checked.length })}</button>
                    </div>
                    <NewMessagePopUp recipientIds={this.state.checked} isOpen={this.state.messageOpen} tableInfoId={CourseCrud.TABLE_INFO_ID} recordId={this.props.currentCourse!.id} onClose={this.closeMessageBox.bind(this)} />
                </>
            }
            {
                this.state.userInfoDialogOpen && this.state.selectedUser != undefined &&
                <UserInfoTableComponent selectedSecUserId={this.state.selectedUser!.sec_user_id!} showAsPopup={true} onClose={() => this.setState({ userInfoDialogOpen: false })} />
            }
            {
                this.state.checkListDialogOpen && this.state.selectedUser != undefined &&
                <Dialog title={this.state.selectedUser.participant_fullname + " " + __("képzési csekklista")} onClose={() => this.setState({ checkListDialogOpen: false })}>
                    <DialogContent>
                        {checkboxes}
                        <button className="button success" onClick={() => this.setState({ checkListDialogOpen: false }, this.saveChanges.bind(this))}>{__("Mentés")}</button>
                    </DialogContent>
                </Dialog>
            }
        </>);
    }
}

type UserInfoTableComponentProps = {
    selectedSecUserId?: number;
    onClose: () => void;
    showAsPopup: boolean;
};

type UserInfoTableComponentState = {
    count: number;
    userInfoData: IViewKapCourseUserRecord[];
    userinfoLoading: boolean;
};

export class UserInfoTableComponent extends React.Component<UserInfoTableComponentProps, UserInfoTableComponentState> {
    constructor(props: UserInfoTableComponentProps) {
        super(props);
        this.state = {
            userInfoData: [],
            count: 0,
            userinfoLoading: false,
        };
    }
    componentDidMount() {
        this.reloadAsync();
    }


    private reloadAsync = async () => {

        this.setState({
            userinfoLoading: true,
        })

        try {
            if (this.props.selectedSecUserId) {
                let userInfoData: IViewKapCourseUserRecord[];
                const params: IListParams = {
                    filter: {
                        sec_user_id: this.props.selectedSecUserId
                    },
                    order_by: [{ name: "training_from", desc: true }],

                };

                userInfoData = await ViewKapCourseUser.list(params);
                const count = await ViewKapCourseUser.count(params);
                this.setState({
                    userInfoData: userInfoData,
                    userinfoLoading: false,
                    count
                });
            }
        }
        catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (!this.props.selectedSecUserId) return "";

        const getUserInfoTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};

            if (column && rowInfo && rowInfo.row) {
                let row = rowInfo.row._original;
                if (row && column.id == "wf_station_name") {
                    props.style = row.wf_station_style || {};
                }
            }
            return props;
        }
        const userInfotableProps = {
            columns: [
                { Header: "Képzés típusa", accessor: "training_type_code", filterable: false, maxWidth: 120 },
                { Header: "Iktatószám", accessor: "regno", filterable: false, maxWidth: 120 },
                {
                    Header: __("Időpontja"), accessor: "training_from", filterable: false, style: { justifyContent: "center" },
                    Cell: (data: any, column: any) => {
                        const row: IViewKapCourseUserRecord = data.original;
                        return row.start_date ? formatDate(row.start_date) : "";
                    }
                },

                { Header: __("Ellátási hely"), accessor: "institute_site_name", filterable: false, },
                {
                    Header: __("Állapot"), accessor: "wf_station_name", filterable: false, sortable: true,
                    Cell: (data: any, column: any) => {
                        return getWfStateCell(data, column)
                    }
                    , style: { justifyContent: "left" }
                },
                {
                    Header: __("Tanúsítvány sorszám / igazolás sorszám"), accessor: "cert_serial", filterable: false, sortable: false,
                    Cell: (data: any, column: any) => {
                        const row: IViewKapCourseUserRecord = data.original;
                        return <><span style={{ color: "green" }}>{row.cert_num}</span>{row.ver_num ? ("/" + <span style={{ color: "darkorange" }}>{row.ver_num}</span>) : ""}</>
                    }
                    , style: { justifyContent: "left" }
                }
            ],

            data: this.state.userInfoData,
            defaultPageSize: 10,
            getTdProps: getUserInfoTdProps,
            filterable: true,
            className: "-striped -highlight ",
            ...getReactTableLabels(),

            loading: this.state.userinfoLoading,
            manual: true
        }

        let mainContent = <div>
            <div>
                <label className="result-count">&nbsp;{this.state.count ? __("{n} találat", { n: this.state.count }) : ""}</label>
            </div>
            <div>
                <ReactTable {...userInfotableProps} />
            </div>
        </div>;

        if (this.props.showAsPopup) {
            return <Dialog title={__(this.state.userInfoData.length > 0 ? this.state.userInfoData[0].participant_fullname + " képzési adatai" : "")} onClose={this.props.onClose} width={1000} height={700}  >
                {mainContent}
            </Dialog>;
        }
        else {
            return mainContent;
        }
    }
}

type StudentFilesComponentState = {
    trainingPermitFolder?: number,
    finalAssignmentFolder?: number,
    userMilestones: ICourseUserMilestoneRecord[],
    loading: boolean
}

type StudentFilesComponentProps = {
    courseUser: IViewKapCourseUserRecord,
    isTeacher?: boolean | null,
    isOHInspector?: boolean,
    milestones?: ITrainingTypeMilestoneRecord[],
    mileStoneDeadlines?: Map<number, string>,
    isQuestionareFilled: boolean
}
export class StudentFilesComponent extends React.Component<StudentFilesComponentProps, StudentFilesComponentState> {

    constructor(props: StudentFilesComponentProps) {
        super(props);
        this.state = {
            loading: false,
            userMilestones: []
        };
    }
    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {
        try {
            this.setState({ loading: true });

            let trainingPermit;
            if (this.props.courseUser.training_permit_id && me && hasAnyGroup(me, [Groups.Developer, Groups.KapKszrCourseCoordinator])) {
                trainingPermit = (await TrainingPermitCrud.load(this.props.courseUser.training_permit_id)).record;
            }
            let userMilestones: ICourseUserMilestoneRecord[] = [];

            if (this.props.milestones) {
                const filter = {
                    is_active: true,
                    training_type_milestone_id: this.props.milestones.map(m => m.id!),
                    course_user_id: this.props.courseUser.id
                };
                userMilestones = await CourseUserMilestoneCrud.list({ filter });
            }

            this.setState({
                trainingPermitFolder: trainingPermit && trainingPermit.oo_folder_id,
                userMilestones,
                loading: false
            })

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    async checkMilestone(userMilestone: ICourseUserMilestoneRecord) {

        try {
            if (!userMilestone) {
                this.reloadAsync();
                return;
            }
            let ums = { id: userMilestone.id, succeeded: userMilestone.succeeded };
            if (ums.succeeded) {
                ums.succeeded = null;
            } else {
                ums.succeeded = "now";
            }

            const rec = (await (new CourseUserMilestoneCrud(ums).put())).record;
            let data: PublicServer.IViewEventData = {
                eventTarget: PublicServer.UserEventTargets.TEACHER,
                eventType: PublicServer.UserEventTypes.MILESTONE_CHECK,
                newValue: userMilestone.succeeded ? __("Teljesítette") : __("Nem teljesítette"),
                courseId: this.props.courseUser.course_id!
            }
            /*data.event = "vimeo";
            data.vimeo_event = "play";
            data.sha1 = sha1;
            data.mediaTypeId=mediaTypeId;*/
            PublicServer.sendUserViewEvent({
                tableInfoId: CourseUserMilestoneCrud.TABLE_INFO_ID,
                recordId: rec.id!,
                name: this.props.courseUser.participant_fullname!,
                data: data
            });
            app.showSuccess(__("Mentés"), __("Sikeres mérföldkő változtatás."));
            this.reloadAsync();

        } catch (error) {
            app.showErrorFromJsonResult(error);
        }

    }

    render() {
        if (this.state.loading) return <BubbleLoader />

        const curr_part: IViewKapCourseUserRecord = this.props.courseUser;
        const sec_user_id: number = curr_part.sec_user_id!;
        let isfinished = curr_part.wf_station_id == WF_STATION_SUCCESSFUL_ASSIGNMENT_ID;
        if (this.props.isOHInspector) {
            return <>
                {
                    !this.props.milestones ? "" :
                        this.props.milestones.map((currMs, index) => {
                            const currUMS = this.state.userMilestones.find((el) => el.training_type_milestone_id == currMs.id);
                            const checked = (currUMS && currUMS.succeeded != null && currUMS.succeeded != undefined);
                            return <>
                                <div key={"milestone_" + currMs.id} className="row expanded">
                                    <div className="large-12 columns">
                                        <label>{__("{i}. mérföldkő:", { i: (index + 1) }) + " "}<strong>{currMs.title}</strong>
                                            <span style={{ fontWeight: "normal" }}> ({__("Határidő:") + (this.props.mileStoneDeadlines && this.props.mileStoneDeadlines.get(currMs.id!))}) </span>
                                            <label>{checked ? __("Teljesítette") : __("Nem teljesítette")}</label>
                                        </label>
                                    </div>
                                </div>
                            </>
                        })
                }
            </>
        }

        return <>
            <div className="row expanded">
                <div className="large-12 columns">
                    <label className="profile_form_separator">{__("Végzettséget igazoló dokumentumok")}</label>
                </div>
            </div>
            {me && hasAnyGroup(me, [Groups.Developer, Groups.KapKszrCourseCoordinator]) && this.state.trainingPermitFolder &&
                <FileLinkListComponent oo_folder_id={this.state.trainingPermitFolder} />
            }

            <div className="row expanded">
                <div className="large-12 columns">
                    <label className="profile_form_separator">{__("Szerződések")}</label>
                </div>
            </div>
            <div className="kap_course_download_div">
                <a href={"/api/kap/course?type=pedag_jelentkezesi_lap&format=pdf&course_user_id=" + curr_part.id} className="kap-course-downloadable">{__("Jelentkezési lap")}</a>
                <a href={"/api/kap/course?type=pedag_tovabbkepzesi_szerzodes&format=pdf&course_user_id=" + curr_part.id} className="kap-course-downloadable">{__("Pedagógus-továbbképzési szerződés")}</a>

                <a href={"/api/kap/course?type=pedag_tanusitvany_kiadas_nyilvantartas&format=pdf&course_user_id=" + curr_part.id} className="kap-course-downloadable">{__("Tanusítvány átvétel nyilvántartás")}</a>

                <a href={"/api/kap/course?type=pedag_jelentkezesi_lap_regi&format=pdf&course_user_id=" + curr_part.id} className="kap-course-downloadable">{__("Jelentkezési lap (Régi)")}</a>
                <a href={"/api/kap/course?type=pedag_tovabbkepzesi_szerzodes_regi&format=pdf&course_user_id=" + curr_part.id} className="kap-course-downloadable">{__("Pedagógus-továbbképzési szerződés (Régi)")}</a>
                <a href={"/api/kap/course?type=pedag_tovabbkepzesi_szerzodes_regi2&format=pdf&course_user_id=" + curr_part.id} className="kap-course-downloadable">{__("Pedagógus-továbbképzési szerződés (Régi 2)")}</a>

            </div>

            <div className="row expanded">
                <div className="large-12 columns">
                    <label className="profile_form_separator">{__("Kérdőívek, végzettség")}</label>
                </div>
            </div>
            <div className="kap_course_download_div">
                {
                    curr_part.sec_user_id && this.props.isQuestionareFilled// this.state.userIdsWithQuestionaries.includes(sec_user_id)
                        ?
                        <a href={"/api/kap/course?type=questionnaire&format=pdf&course_user_id=" + curr_part.id} className="kap-course-downloadable">{__("Bemeneti kérdőív")}</a>
                        :
                        __("Bemeneti kérdőív nincs kitöltve")
                }
                {isfinished && curr_part.cert_num &&
                    <a href={"/api/kap/course?type=certificate&format=pdf&course_user_id=" + curr_part.id} className="kap-course-downloadable">{__("Tanúsítvány")}</a>
                }
                {isfinished && curr_part.cert_num &&
                    <a href={"/api/kap/course?type=pedag_tanusitvany_kiadas_nyilvantartas&format=pdf&course_user_id=" + curr_part.id} className="kap-course-downloadable">{__("Tanúsítvány kiadásának és átvételének nyilvántartása")}</a>
                }
                {isfinished && curr_part.ver_num &&
                    <a href={"/api/kap/course?type=certificate&format=pdf&course_user_id=" + curr_part.id} className="kap-course-downloadable">{__("Igazolás")}</a>
                }
                {isfinished && curr_part.ver_num &&
                    <a href={"/api/kap/course?type=pedag_tanusitvany_kiadas_nyilvantartas&format=pdf&course_user_id=" + curr_part.id} className="kap-course-downloadable">{__("Igazolás kiadásának és átvételének nyilvántartása")}</a>
                }
                {/* Aláírt oklevél ne látszódjon képzésszervezőknek se!
                        isfinished && curr_part.cert_num && me && hasGroup(me, Groups.KapKszrCourseCoordinator) &&
                            <a href={"/api/kap/course?type=certificate_signed&format=pdf&course_user_id=" + curr_part.id} className="kap-course-downloadable">{__("Aláírt tanúsítvány")}</a>
                        */}

            </div>
            {
                !this.props.milestones ? "" :
                    this.props.milestones.map((currMs, index) => {
                        const currUMS = this.state.userMilestones.find((el) => el.training_type_milestone_id == currMs.id);
                        const checked = (currUMS && currUMS.succeeded != null && currUMS.succeeded != undefined);
                        return <>
                            <div key={"milestone_" + currMs.id} className="row expanded">
                                <div className="large-12 columns">
                                    <label className="profile_form_separator">{__("{i}. mérföldkő:", { i: (index + 1) }) + currMs.title}
                                        <span style={{ fontWeight: "normal" }}> ({__("Határidő:") + (this.props.mileStoneDeadlines && this.props.mileStoneDeadlines.get(currMs.id!))}) </span>
                                        <label className="float-right"><input type="checkbox" checked={checked} disabled={currUMS == undefined || currUMS == null} onChange={this.checkMilestone.bind(this, currUMS)} />{__("Teljesítette")}</label>
                                    </label>

                                </div>
                            </div>
                            <div className="kap_course_download_div">
                                {currUMS && currUMS.oo_folder_id && <FileLinkListComponent oo_folder_id={currUMS.oo_folder_id} />}
                            </div>
                        </>
                    })
            }

            <div className="row expanded">
                <div className="large-12 columns">
                    <label className="profile_form_separator">{__("Beadandó")}</label>
                </div>
            </div>
            <div className="kap_course_download_div">
                {
                    (curr_part.wf_station_id == WF_COURSE_USER_STATION_ACTIVE_STUDENT_ID || curr_part.wf_station_id == WF_COURSE_USER_STATION_APPLICATION_CORRECTION_ID)
                        && (me && this.props.isTeacher) ?
                        <span style={{ color: "red" }}>{__("A résztvevő még nem adta be a feladatot, bármikor megváltoztathatja!")}</span>
                        : null

                }
                <>
                    {curr_part.lesson_draft_id && <DownloadAsPdf id={curr_part.lesson_draft_id} type="lesson_draft" published={false}>{__("Beadott óraterv letöltése")}</DownloadAsPdf>}
                    {curr_part.kip_draft_id && <DownloadAsPdf id={curr_part.kip_draft_id} type="kip_draft" published={false}>{__("Beadott KIP óraterv letöltése")}</DownloadAsPdf>}
                    {curr_part.occupation_draft_id && <DownloadAsPdf id={curr_part.occupation_draft_id} type="occupation_draft" published={false}>{__("Beadott foglalkozásterv letöltése")}</DownloadAsPdf>}
                </>
            </div>

            <FileLinkListComponent oo_folder_id={curr_part.oo_folder_id!} />

            {/*<MediaFileChooser
                rootFolderId={curr_part.oo_folder_id!}
                currentFolderId={curr_part.oo_folder_id!}
                showHoverPreview={false}
                showLocation={false}
                showFilter={false}
                enabledActions={filePermits}
            />*/}
        </>;
    }
}