import * as React from 'react';
import { questions as QuestionsForStudents } from './QuestionsForStudents';
import { questions as QuestionsForTeachers } from './QuestionsForTeachers';
import { app } from '@src/index';
import { me, Groups, hasGroup } from '@src/framework/server/Auth';
import { BubbleLoader } from 'react-css-loaders';
import SurveyAnswerCrud from '@src/framework/crud/usr/SurveyAnswerCrud';
import { alertDialog } from '../Dialog';
import MyProfileAPI from '../usr/usr_profile_api';
import { differenceInDays } from 'date-fns';
import UserConfigStore from '../usr/usr_config_store_api';

import { history } from '@src/index';

import { __ } from '@src/translation';
import { SurveyInputType, Question } from './SurveyConstants';

const SURVEY_SKIP_COUNTER = "survey_skip_counter";
const MAX_SKIP_COUNT = 10;
const SURVEY_STUDENT_ID = 1;
const SURVEY_TEACHER_ID = 2;

export class SurveyPage extends React.Component<SurveyProps, SurveyState> {

    private onSurveyFinished() {
        history.push("/");
    }

    render() {
        return <div>
            <Survey onSurveyFinished={this.onSurveyFinished.bind(this)} />
        </div>;
    }
}

export type AnswerType = {
    [key: number]: {ans_id: number[], value?: string};
}

type SurveyProps =
    {
        onSurveyFinished: () => void;
    }
type SurveyState = {
    loading: boolean,
    skippedCounter: number,
    showSurvey: boolean,
    unSkippable: boolean,
    answers: AnswerType,
    personalQuestions?: Question[]
}
export class Survey extends React.Component<SurveyProps, SurveyState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            showSurvey: false,
            unSkippable: false,
            skippedCounter: 0,
            answers: {},
            personalQuestions: undefined
        }
    }

    static DAY_DELAY:number = 7;
    async componentDidMount() {
        try {
            if (me) {

                const skippedObject = await UserConfigStore.get<{ skippedCounter: number }>(SURVEY_SKIP_COUNTER);
                const skippedCounter = skippedObject ? skippedObject.skippedCounter : 0;

                const firstLogin = await MyProfileAPI.getFirstLogin();

                var difference = 0;
                if (firstLogin && firstLogin.event_date) {
                    difference = differenceInDays(Date.now(), firstLogin.event_date);
                }
                let personalQuestions = difference > Survey.DAY_DELAY ? this.getQuestions() : [];

                const surveys = await SurveyAnswerCrud.list({ filter: { sec_user_id: me.id, survey_id: this.getSurveyId(), is_active: true } });
                if (surveys && surveys.length > 0) {
                    personalQuestions = personalQuestions.filter(q => !surveys[0].answers[q.id] || surveys[0].answers.length == 0);
                }
                if (personalQuestions && skippedCounter >= MAX_SKIP_COUNT) {

                    if (personalQuestions.length > 0) personalQuestions = [personalQuestions[0]];
                    this.setState({ showSurvey: true, unSkippable: true })
                }

                if (surveys.length === 1 && surveys[0].answers && personalQuestions && personalQuestions.length > 0) {
                    this.setState({
                        answers: surveys[0].answers,
                    })
                }

                this.setState({
                    personalQuestions,
                    skippedCounter
                })

                if (!personalQuestions || personalQuestions.length === 0) {
                    this.props.onSurveyFinished();
                }
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
            this.props.onSurveyFinished();
        }

        this.setState({
            loading: false
        })
    }

    private getSurveyId() {
        if (me && hasGroup(me, Groups.TestTeacher)) {
            return SURVEY_TEACHER_ID;
        }
        return SURVEY_STUDENT_ID;
    }

    private getQuestions(): Question[] {
        if (!me) return [];

        if (hasGroup(me, Groups.TestStudent)) {
            return QuestionsForStudents;
        }
        else if (hasGroup(me, Groups.TestTeacher) || hasGroup(me, Groups.Admin)) {
            return QuestionsForTeachers;
        }
        return [];
    }

    private onChange(questionId: number, answerId: number, event: React.ChangeEvent<HTMLInputElement>) {
        let question = this.getQuestions().find(q => q.id == questionId);

        if (!question) return;

        const answers = this.state.answers;

        if (question.type === SurveyInputType.CHECKBOX) {
            if (!answers[question.id].ans_id) answers[question.id].ans_id = [];

            const currentAnswer = answers[question.id];

            if (currentAnswer.ans_id) {
                if (event.target.checked) {
                    currentAnswer.ans_id.push(answerId);
                } else {
                    let temp = currentAnswer.ans_id.indexOf(answerId);
                    currentAnswer.ans_id.splice(temp, 1);
                }
            }

        } else if (question.type === SurveyInputType.RADIO || question.type === SurveyInputType.RADIO_SMILEY) {
            answers[question.id].ans_id = [answerId];
        }
        this.setState({
            answers
        })
    }

    private async onSave() {
        if (!this.state.personalQuestions) {
            return;
        }

        for (const personalQ of this.state.personalQuestions) {
            if (!this.state.answers[personalQ.id] || !this.state.answers[personalQ.id].ans_id || this.state.answers[personalQ.id].ans_id!.length == 0) {
                await alertDialog(__("Kötelező kitölteni a kérdőívet!"), __("Kérem válaszoljon a kérdésekre!"));
                return;
            }
        }
        try {
            await new SurveyAnswerCrud({
                sec_user_id: me!.id,
                survey_id: this.getSurveyId(),
                answers: this.state.answers,
                is_active: true
            }).upsert(["sec_user_id", "survey_id", "table_info_id", "rec_id"]);

            app.showSuccess(__("Sikeres mentés"), __("Kérdőív sikeresen elmentve"));
            this.props.onSurveyFinished();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }
    private async onSkip() {
        await UserConfigStore.put(SURVEY_SKIP_COUNTER, { skippedCounter: this.state.skippedCounter + 1 });
        this.props.onSurveyFinished();
    }

    render() {

        if (this.state.loading) {
            return <BubbleLoader />
        }

        const questions = this.state.personalQuestions;

        if (!questions || questions.length === 0) {
            return null;
        }
        let enableSave = true;
        for (const personalQ of questions) {
            if (!this.state.answers[personalQ.id] || !this.state.answers[personalQ.id].ans_id || this.state.answers[personalQ.id].ans_id!.length == 0) {
                enableSave = false;
            }
        }

        if (!this.state.showSurvey && me) {
            return <div style={{ marginTop: "1em", marginBottom: "1em" }} className="row">
                <div className="column small-12">

                    {(hasGroup(me, Groups.TestTeacher) || hasGroup(me, Groups.Admin))

                        ? <div><img style={{ height: "128px" }} src="/img/IKON_SET/FLAT/survey.svg" />
                            <h3>{__("Kedves")} {(me.person && me.person.full_name) || __("Felhasználó")}!</h3>
                            <p><strong>{__("Kíváncsiak vagyunk a véleményére!")}</strong><br />
                                {__("Kérjük, töltse ki a maximum 10 percet igénylő kérdőívünket, amellyel hozzájárulhat a portál jobbá tételéhez!  ")}<br />
                                {__("A diákok véleményére is kíváncsiak vagyunk. Kérjük segítse a kitöltést azzal, hogy órája keretében időt ad diákjainak, hogy véleményüket nyugodt körülmények között, a kérdéseket alaposan átolvasva nyilváníthassák ki! ")}<br />
                                <em>{__("Szerencsésebbnek tartjuk ha a diákok az iskolában, egy időben töltik ki a kérdőívet, mint otthon egyénileg.")}</em>
                            </p>
                        </div>
                        : ""
                    }
                    {(hasGroup(me, Groups.TestStudent))
                        ? <div>
                            <img style={{ height: "128px" }} src="/img/IKON_SET/FLAT/survey.svg" />
                            <h3>{__("Kedves ")}{(me && me.person && me.person.full_name) || __("Felhasználó")}!</h3>
                            <p>{__("Kíváncsiak vagyunk a véleményedre! ")}<br />
                                {__("Kérjük, töltsd ki a maximum 10 percet igénybe vevő kérdőívünket, amellyel hozzájárulhatsz a portál jobbá tételéhez!")}<br />
                                {__("Töltsd ki együtt a tanároddal és az osztállyal órán, vagy egyénileg otthon!")}
                            </p>
                        </div>
                        : ""
                    }
                    <button onClick={() => this.setState({ showSurvey: true })} className="button">{__("Kitöltöm most")}</button>
                    <button onClick={this.onSkip.bind(this)} style={{ float: "right" }} className="button">{__("Kitöltöm később")}</button>
                    <br />
                    <small style={{ float: "right" }}>{__("Még {available} halasztás lehetséges. {max} halasztás után a kérdéssor automatikusan elindul.", {available: MAX_SKIP_COUNT - this.state.skippedCounter, max: MAX_SKIP_COUNT})}</small>
                </div>
            </div>
        }
        return <div className="row">
            <div className="column small-12">
                <h3>{__("Kérdőív")}</h3>
                {me && (hasGroup(me, Groups.TestTeacher) || hasGroup(me, Groups.Admin))
                    ? <p>{__("Kérjük töltse ki a kérdőívet a tapasztalatainak, saját véleményének megfelelően! ")}<br />
                        {__("A skála balról jobbra: „Egyáltalán nem”, „Kevésbé”, „Átlagosan”, „Eléggé”, „Teljes mértékben”")}
                    </p>
                    : ""
                }

                {me && (hasGroup(me, Groups.TestStudent))
                    ? <p>{__("Kérjük válaszold meg a kérdéseket a saját tapasztalataid alapján!")}<br />
                        {__("Válaszd ki a véleményednek megfelelő arcot! Az arcok jelentése balról jobbra: „Egyáltalán nem”, „Kevésbé”, „Átlagosan”, „Eléggé”, „Teljes mértékben”")}
                    </p>
                    : ""
                }

            </div>
            <div className="column small-12">
                {
                    questions.map((question, index) => {

                        return <div key={index} style={{ marginTop: "2em", marginBottom: "1em" }}>
                            <div className="row">
                                <div className="column small-12 medium-12 large-12">
                                    <p>{index + 1 + "."} {question.name}</p>
                                </div>
                                <div style={{ display: question.type === "radio" ? "block" : "flex", justifyContent: "space-around" }} className="column small-12 medium-6 large-6">

                                    {
                                        question.answers.map((answer: any, index2: number) => {
                                            var checked = false;

                                            const currentAnswer = this.state.answers[question.id];

                                            if (currentAnswer.ans_id) {
                                                if (question.type === "checkbox") {
                                                    checked = currentAnswer && currentAnswer.ans_id.indexOf(answer.id) !== -1;
                                                } else if (question.type === "radio" || question.type === "radio-smiley") {
                                                    checked = currentAnswer && currentAnswer.ans_id.length == 1 && currentAnswer[0] == answer.id;
                                                }
                                            }

                                            if (question.type === "radio-smiley") {
                                                const normalStyle = {
                                                    maxHeight: "40px",
                                                    opacity: 0.65,
                                                    cursor: "pointer",
                                                    transition: "200ms"
                                                }
                                                const checkedStyle = {
                                                    ...normalStyle,
                                                    opacity: 1,
                                                    transform: "scale(1.3)",
                                                    boxShadow: "1px 2px 2px #000000a6",
                                                    borderRadius: "50%",
                                                }
                                                return <label key={index2}>
                                                    <input
                                                        style={{ visibility: "hidden", width: "0", height: "0" }}
                                                        type={"radio"}
                                                        checked={checked || false}
                                                        onChange={this.onChange.bind(this, question.id, answer.id)}
                                                    />
                                                    <img style={checked ? checkedStyle : normalStyle} title={answer.name} src={"/img/IKON_SET/SURVEY/" + (index2 + 1) + ".png"} />
                                                </label>
                                            } else {
                                                return <div className="row column small-12 large-12 medium 12" style={{ marginLeft: "1em" }}>
                                                    <label key={index2}>
                                                        <input
                                                            type={question.type}
                                                            checked={checked || false}
                                                            onChange={this.onChange.bind(this, question.id, answer.id)}
                                                        />
                                                        {answer.name}
                                                    </label>
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    })
                }

                {
                    !enableSave &&
                    <p><strong>{__("Kérem válaszoljon az összes kérdésre!")}</strong></p>
                }

                <button disabled={!enableSave} className="button" onClick={this.onSave.bind(this)}>
                    {__("Elküldés")}
                </button>


                {!enableSave && !this.state.unSkippable
                    ? <button onClick={this.onSkip.bind(this)} style={{ float: "right" }} className="button">{__("Kitöltöm később")}</button>
                    : ""
                }
            </div>
        </div>
    }
}
