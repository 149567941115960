import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapKszrCourseUserStatsRecord {
    /**
     * Dummy id
     *
     * Dummy id
     */
    id?: number;
    /**
     * Képzés azonosító
     *
     * Képzés azonosító
     */
    kepzes_azon?: number|null;
    /**
     * Képzés neve
     *
     * Képzés neve
     */
    kepzes_nev?: string|null;
    /**
     * Képzés helye
     *
     * Képzés helye
     */
    kepzes_hely?: string|null;
    /**
     * Továbbképzés -tól
     *
     * Továbbképzés -tól
     */
    tovabbkezes_ideje_tol?: string /*date?*/|null;
    /**
     * Továbbképzés -ig
     *
     * Továbbképzés -ig
     */
    tovabbkezes_ideje_ig?: string /*date?*/|null;
    /**
     * Első kontaktnap (Képzés dátuma)
     *
     * Első kontaktnap (Képzés dátuma)
     */
    elso_kontaktnap?: string /*date?*/|null;
    /**
     * Utolsó kontaktnap
     *
     * Utolsó kontaktnap
     */
    utolso_kontaktnap?: string /*date?*/|null;
    /**
     * Lezárás dátuma
     *
     * Lezárás dátuma
     */
    lezaras_datuma?: string /*timestamp?*/|null;
    /**
     * Képző neve
     *
     * Képző neve
     */
    kepzo_nev?: string|null;
    /**
     * Képző e-mail
     *
     * Képző e-mail
     */
    kepzo_email?: string|null;
    /**
     * Résztvevő neve
     *
     * Résztvevő neve
     */
    resztvevo_neve?: string|null;
    /**
     * Résztvevő anyja neve
     *
     * Résztvevő anyja neve
     */
    resztvevo_anyja_neve?: string|null;
    /**
     * Résztvevő e-mail
     *
     * Résztvevő e-mail
     */
    resztvevo_email?: string|null;
    /**
     * Résztvevő születési dátum
     *
     * Résztvevő születési dátum
     */
    resztvevo_szuletesi_datum?: string /*date?*/|null;
    /**
     * Résztvevő irányítószám
     *
     * Résztvevő irányítószám
     */
    resztvevo_iranyitoszam?: string|null;
    /**
     * Résztvevő intézmény
     *
     * Résztvevő intézmény
     */
    resztvevo_intezmenye?: string|null;
    /**
     * Résztvevő ellátási hely megye
     *
     * Résztvevő ellátási hely megye
     */
    resztvevo_intezmeny_megye?: string|null;
    /**
     * Résztvevő ellátási hely város
     *
     * Résztvevő ellátási hely város
     */
    resztvevo_intezmeny_varos?: string|null;
    /**
     * Résztvevő ellátási hely név
     *
     * Résztvevő ellátási hely név
     */
    resztvevo_telephely?: string|null;
    /**
     * OM azonosító
     *
     * OM azonosító
     */
    om_azon?: string|null;
    /**
     * Tagintézmény azonosító
     *
     * Tagintézmény azonosító
     */
    place_id?: string|null;
    /**
     * Képzés típusa
     *
     * Képzés típusa
     */
    kepzes_tipusa?: string|null;
    /**
     * Elvégezte-e
     *
     * Elvégezte-e
     */
    elvegezte_e?: string|null;
    /**
     * Tanústívány/Igazolás kelte
     *
     * Tanústívány/Igazolás kelte
     */
    tanusitvany_kelt?: string /*date?*/|null;
    /**
     * Tanúsítvány sorszám
     *
     * Tanúsítvány sorszám
     */
    tanusitvany_szam?: string|null;
    /**
     * Igazolás sorszám
     *
     * Igazolás sorszám
     */
    igazolas_szam?: string|null;
    /**
     * Jelentkezés dátuma
     *
     * Jelentkezés dátuma
     */
    jelentkezes_datuma?: string /*timestamp?*/|null;
}

/**
 *  kap.view_kap_kszr_course_user_stats - KSZR kurzus-hallgató statisztika
 *
 *  KSZR kurzus-hallgató statisztika
 */

export default class ViewKapKszrCourseUserStats extends View<IViewKapKszrCourseUserStatsRecord> {
    public static VIEW_INFO_ID = 2020020401;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_kszr_course_user_stats'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapKszrCourseUserStats>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapKszrCourseUserStatsRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapKszrCourseUserStatsClassProxy extends ViewClassProxy<IViewKapKszrCourseUserStatsRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapKszrCourseUserStatsClassProxy = new ViewKapKszrCourseUserStatsClassProxy(ViewKapKszrCourseUserStats);
registerViewClassProxy(viewKapKszrCourseUserStatsClassProxy);

