import * as React from 'react';
import { Dialog } from '@src/component/Dialog';
import { me, Groups, hasGroup } from '@src/framework/server/Auth';
import { __ } from '@src/translation';
import { PostEdit } from '@src/component/cms/PostEdit';
import ContentResult  from './ContentResult';

type DateInterval = {
    from?: string;
    to?: string;
}

type PostProps = {
    subsiteName?: string;
    id?: number;
    title?: string;
    post: string;
    icon?: string;
    class?: string;
    date: string;
    dateInterval?: DateInterval;
    img?: string;
    publishDate?: string;
    subsiteId?: number;
    subsiteShortName?: string;
    subsiteColor?: string;
    contentType?: number; 
    onRefresh?: () => void;
}

type PostState = {
    isOpen: boolean;
    settingsIsVisible: boolean;
    modifyPost: boolean;
    moreButton?: any;
    cuttedPost?: string;
    dialog?: any;
}

export default class Post extends ContentResult {

    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
            settingsIsVisible: false,
            modify: false,
            cuttedPost: "",
            moreButton: null,
            dialog: null
        };
    }

    cutPost(text: string): any {
        let temptext = text;
        let lineNum = temptext.split('\n').length;

        if (text.length < 300 && lineNum < 5) {
            this.setState({ cuttedPost: text, moreButton: null, dialog: null });
        } else if (text.length > 300 && lineNum < 5) {
            let cuttedPost = text.substring(0, 300);
            let regexp = new RegExp('[</>]$');
            while (regexp.test(cuttedPost)) {
                cuttedPost = cuttedPost.slice(0, -1);
            }

            this.setState({ cuttedPost: cuttedPost + "...", moreButton: <><a onClick={() => this.setState({ isOpen: true })}>{__("Tovább")}</a></> });
        } else {
            let cutted = "";
            let splitted = temptext.split('\n');
            for (let i = 0; i < 5; i++) {
                cutted += splitted[i];
                cutted += '\n';
            }
            this.setState({ cuttedPost: cutted + "...", moreButton: <><a onClick={() => this.setState({ isOpen: true })}>{__("Tovább")}</a></> });
        }
    }

    // componentDidMount() {
    //     this.cutPost(this.props.post);
    // }

    // settingsVisible() {
    //     this.setState({ settingsIsVisible: true });
    // }

    // settingsInvisible() {
    //     this.setState({ settingsIsVisible: false });

    // }

    // modifyPost() {
    //     this.setState({ modifyPost: true });
    // }

    // updatePost() {
    //     this.setState({ modifyPost: false });
    // }

   
}