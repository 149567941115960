import * as React from 'react';
import { Link } from 'react-router-dom';
import { IViewMetaCategoryTypeRecord, ViewMetaCategoryTypeClassProxy, viewMetaCategoryTypeClassProxy } from '@src/framework/view/meta/ViewMetaCategoryType';
import { viewMetaTableCategoryTypeClassProxy } from '@src/framework/view/meta/ViewMetaTableCategoryType';
import ListView from '@framework/forms/listview';
import '@framework/crud/meta/CategoryTypeCrud'; // Ezt ne távolítsd el mert akkor a hülye webpack kioptimizálja!
import { __ } from '@src/translation';

class CategoryTypeListViewInner extends ListView<IViewMetaCategoryTypeRecord> {
    //protected getHeader = () : JSX.Element|null => { return <SideMenu /> }
    public getViewClassProxy() : ViewMetaCategoryTypeClassProxy { return viewMetaCategoryTypeClassProxy; }

    protected getTypeAssignmentsListButton(record:IViewMetaCategoryTypeRecord) : JSX.Element {
        return (
            <Link 
                className="listview-button small" title={ __("Kategóriák ehhez a típushoz" ) }
                to={ viewMetaTableCategoryTypeClassProxy.getListUrl(
                    {category_type_id: record.id!}
            )}>
                <i className="fa fa-link"></i>
            </Link>);
    }           

    protected getRecordButtons(record: IViewMetaCategoryTypeRecord) : JSX.Element[] {
        /* TODO: automatikus detail list menüt ide? */
        return super.getRecordButtons(record)
            .concat(this.getTypeAssignmentsListButton(record))
        ;
    }
}

export default function CategoryTypeListView(props: any) {
    // TODO: hozzáadni a person-os oszlopokat a view-hoz hogy látszódjon a 
    // rendes nevük!
    return <CategoryTypeListViewInner 
        defaultPageSize={10}
        match={props.match}        
        viewColumns={[
            {accessor: "id", maxWidth: 100},
            {accessor: "title"},
            {accessor: "creation_time"},
            {accessor: "creator"},
            /*
            {accessor: "modification_time"},
            {accessor: "modifier"},
            */
            {accessor: "link", Header:__("Műveletek"), maxWidth:200},
        ]}
    />
}
