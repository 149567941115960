import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapInstituteSiteContactRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Intézmény feladat ellátási hely
     */
    institute_site_id?: number;
    /**
     * Természetes személy
     */
    person_id?: number;
    /**
     * Kapolattartó név
     *
     * Kapolattartó név
     */
    fullname?: string|null;
    /**
     * Kapcsolattartó aktív
     *
     * Kapcsolattartó aktív
     */
    person_is_active?: boolean|null;
    /**
     * Felhasználó azonosító
     *
     * Felhasználó azonosító
     */
    sec_user_id?: number|null;
    /**
     * Felhasználó e-mail
     *
     * Felhasználó e-mail
     */
    email?: string|null;
    /**
     * Felhasználó aktív
     *
     * Felhasználó aktív
     */
    user_is_active?: boolean|null;
    /**
     * Feladatellátási hely azonosító
     *
     * Feladatellátási hely azonosító
     */
    site_identifier?: string|null;
    /**
     * Feladatellátási hely név
     *
     * Feladatellátási hely név
     */
    institute_site_name?: string|null;
    /**
     * Feladatellátási hely település
     *
     * Feladatellátási hely település
     */
    city?: string|null;
    /**
     * Feladatellátási hely cím
     *
     * Feladatellátási hely cím
     */
    address?: string|null;
    /**
     * Feladatellátási hely irányítószám
     *
     * Feladatellátási hely irányítószám
     */
    zipcode?: string|null;
    /**
     * Feladatellátási hely aktív
     *
     * Feladatellátási hely aktív
     */
    institute_site_is_active?: boolean|null;
    /**
     * Intézmény azonosító
     *
     * Intézmény azonosító
     */
    institute_id?: number|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    institute_name?: string|null;
    /**
     * Intézmény OM azonosító
     *
     * Intézmény OM azonosító
     */
    om_identifier?: string|null;
}

/**
 *  sys.view_kap_institute_site_contact - Feladatellátási hely kapcsolattartója
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewKapInstituteSiteContact extends View<IViewKapInstituteSiteContactRecord> {
    public static VIEW_INFO_ID = 2019100901;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_kap_institute_site_contact'; }
    public static getTableNameForClass(): string|null { return 'institute_site_contact'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapInstituteSiteContact>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapInstituteSiteContactRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapInstituteSiteContactClassProxy extends ViewClassProxy<IViewKapInstituteSiteContactRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapInstituteSiteContactClassProxy = new ViewKapInstituteSiteContactClassProxy(ViewKapInstituteSiteContact);
registerViewClassProxy(viewKapInstituteSiteContactClassProxy);

