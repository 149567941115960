import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewMetaAssignedCategoryRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Táblázat infó
     *
     * Ez mondja meg, hogy a folyamat melyik táblázat rekordjához tartozik.
     */
    table_info_id?: number;
    /**
     * Rekord azonosító
     *
     * Ez mondja meg, hogy a folyamat melyik rekordhoz tartozik.
     */
    record_id?: number;
    /**
     * Meta kategória
     */
    category_id?: number;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Módosította
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
    /**
     * Kategória út
     *
     * A kategória elérési útja
     */
    category_path?: string|null;
    /**
     * Kategória név
     *
     * A kategória neve
     */
    category_title?: string|null;
    /**
     * Kategória típus azonosító
     *
     * Kategória típus azonosító
     */
    category_type_id?: number|null;
    /**
     * Kategória típus név
     *
     * A kategória típus neve
     */
    category_type_title?: string|null;
}

/**
 *  meta.view_meta_assigned_category - Hozzárendelt meta kategória
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewMetaAssignedCategory extends View<IViewMetaAssignedCategoryRecord> {
    public static VIEW_INFO_ID = 2018061102;

    public static getSchemaNameForClass(): string { return 'meta'; }
    public static getViewNameForClass(): string { return 'view_meta_assigned_category'; }
    public static getTableNameForClass(): string|null { return 'assigned_category'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewMetaAssignedCategory>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewMetaAssignedCategoryRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewMetaAssignedCategoryClassProxy extends ViewClassProxy<IViewMetaAssignedCategoryRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewMetaAssignedCategoryClassProxy = new ViewMetaAssignedCategoryClassProxy(ViewMetaAssignedCategory);
registerViewClassProxy(viewMetaAssignedCategoryClassProxy);

