import * as React from 'react';
import WfPlugin from './wf_plugin';
import BookCrud, { IBookRecord } from '@src/framework/crud/doc/BookCrud';
import { getTableInfoByName, ITableInfo } from '@src/framework/crud/Meta';
import { __ } from '@src/translation';


interface IWfTesztState {
    book ?: IBookRecord;
    tableInfo ?: ITableInfo;
}

export default class WfTeszt extends React.Component<any,any> {
    constructor(props: any) {
        super(props);
        this.state = {}
        getTableInfoByName("doc","book").then((tableInfo) => this.setState({tableInfo}));
        BookCrud.list({filter:{is_active: true}}).then(
            (books) => {this.setState({book: books[0]})}
        )
    }

    render() {
        const book = this.state.book;
        const tableInfo = this.state.tableInfo;
        if (book && tableInfo) {
            return <div>
            <h2>{__("Ez itt egy teszt lap")}</h2>
            <p>{__("Ezen keresztül lehet megnézni, hogy milyen is az, amikor egy folyamat kapcsolódik egy könyvhöz. Vagy bármi máshoz.")}</p>
            <h3>{book.name} ,id={book.id}</h3>
            <WfPlugin 
                tableInfo={tableInfo}
                recId={book.id||null}
            />
        </div>
        } else {
            return <span>{__("kérem várjon...")}</span>;
        }
    }
}