import * as React from 'react';
import { __ } from "@src/translation";

export default class DeveloperPages extends React.Component<any, any> {

    render() {

        return  <div>
                    <h3 style={{textAlign: "center"}}>{__("Fejlesztői lapok")}</h3>
                </div>
    }

}
