import * as React from 'react';

import  { IViewWfHeadTableRecord, ViewWfHeadTableClassProxy, viewWfHeadTableClassProxy } from '@framework/view/wf/ViewWfHeadTable';
import  { viewWfTypeClassProxy } from '@framework/view/wf/ViewWfType';
import ListView from '@framework/forms/listview';
import { Link } from 'react-router-dom';
import { __ } from '@src/translation';

class HeadTableInner extends ListView<IViewWfHeadTableRecord> {
    //protected getHeader = (): JSX.Element | null => { return <WfMenu /> }
    public getViewClassProxy(): ViewWfHeadTableClassProxy { return viewWfHeadTableClassProxy; }

    protected getTopButtons(): JSX.Element[] {
        let result = super.getTopButtons();
        const wf_type_id = this.state.spec ? this.state.spec.wf_type_id : undefined;
        result.unshift(
            <div className="small-4 medium-4 column" key="back_list">
                <Link className="button" to={viewWfTypeClassProxy.getListUrl()}>
                    <i className="fa fa-step-backward" />&nbsp;{__("Vissza a típusokhoz")}
                </Link>
            </div>
        );
        return result;
    }        
}

export default function HeadTableListView(props: any) {
    return <HeadTableInner
        defaultPageSize={10}
        match={props.match}
        viewColumns={[
            { accessor: "wf_type_code", maxWidth: 150 },
            { accessor: "wf_type_name", maxWidth: 150 },
            { accessor: "table_name", maxWidth: 150 },
            { accessor: "table_display_name", maxWidth: 150 },
            { accessor: "creation_time" },
            { accessor: "creator" },
            { accessor: "modification_time" },
            { accessor: "modifier" },
            { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
        ]}
    />
}
