import * as React from "react";
import { ViewKapTrainingPermitClassProxy, IViewKapTrainingPermitRecord, viewKapTrainingPermitClassProxy } from "@src/framework/view/kap/ViewKapTrainingPermit";
import { __ } from "@src/translation";
import ListView from "@src/framework/forms/listview";
import { IViewColumnInfo } from "@src/framework/view/Meta";
import { TableProps, Column, RowInfo } from "react-table";
import { ENROLL_PREPARATION_WF_TYPE_ID } from "@src/component/profile/ProfilePageComponent";
import { TFilterDict, IListOrderBy } from "@src/framework/crud/Crud";
import MediaFileChooser, { ContentActionType } from "@src/component/filemanager/MediaFileChooser";
import { WorkflowDialog } from "@src/component/wf/WorkflowDialog";
import TrainingPermitCrud, { TrainingPermitCrudClassProxy } from "@src/framework/crud/kap/TrainingPermitCrud";


export class TrainingPermissionListInner extends ListView<IViewKapTrainingPermitRecord> {

    public getViewClassProxy(): ViewKapTrainingPermitClassProxy { return viewKapTrainingPermitClassProxy; }

    protected getAllowInsertNew(): boolean {
        return false;
    }

    protected onShowFilterForColumn(column: IViewColumnInfo): boolean {
        switch (column.column_name) {
            case "is_active":
                return true;
            case "fullname":
                return true;
            case "email":
                return true;
            case "wf_station_name":
                return true;
            case "wf_substation_name":
                return true;
            case "last_transition":
                return true;
            default:
                return false;
        }
    }

    protected getExtraTableProps(): Partial<TableProps> {
        let oldProps = super.getExtraTableProps();
        const getTdProps = (finalState: any, rowInfo: RowInfo, column?: Column, instance?: any) => {
            let props: Partial<TableProps> = {};

            if (column && rowInfo && rowInfo.row) {
                let row = rowInfo.row._original;
                if (row && column.id == "wf_station_name") {
                    props.style = row.wf_station_style || {};
                }
            }
            return props;
        }
        return { ...oldProps, getTdProps };
    }

    protected getRowDetails(row: RowInfo): JSX.Element {

        const curr_part = row.original;
        return (
            <div>
                <MediaFileChooser
                    rootFolderId={curr_part.oo_folder_id!}
                    currentFolderId={curr_part.oo_folder_id!}
                    showHoverPreview={false}
                    showLocation={false}
                    showFilter={false}
                    enabledActions={[ContentActionType.FILE_DOWNLOAD]}
                />
            </div>);
    }

    /*protected getQuickFilterFieldNames(): string[] {
        return ['fullname'];
    }*/

    protected getRecordButtons(record: IViewKapTrainingPermitRecord): JSX.Element[] {
        /* TODO: automatikus detail list menüt ide? */
        let buttons = super.getRecordButtons(record);

        let wf_button = <button key="wf-button" type="button" className="listview-button" title="Workflow"
            onClick={() => { this.setState({ selectedItem: record }) }}>
            <i className="fa fa-project-diagram"></i>
        </button>;
        buttons.push(wf_button);
        return buttons;
    }

    protected getFooter(): JSX.Element[] {
        if (this.state.selectedItem && this.state.crudClassProxy)
            return [<WorkflowDialog
                open={true}
                displayName={__("Képzési engedély") + " - " + this.state.selectedItem.fullname}
                tableInfoId={TrainingPermitCrud.TABLE_INFO_ID}
                recId={this.state.selectedItem.id!}
                onClose={() => this.setState({ selectedItem: undefined })}
                onTransition={this.asyncReload.bind(this)}
                autoPublishOnClosedStation={true}
            />];

        return [];

    }

    /** Get default filter for this editor.
  * Also acts as a default value setter for createNewRecord.
  */
    protected getFilter(): TFilterDict {
        let result = super.getFilter();
        result = { ...result, $not: { wf_station_id: ENROLL_PREPARATION_WF_TYPE_ID } };
        return result;
    }

    protected getOrderBy(): string | IListOrderBy[] | undefined {
        return [{ name: "last_transition", desc: true }];
    }

    protected getLimit(): number | undefined {
        return 2000;
    }

}



export default function TrainingPermissionList(props: any) {

    return <TrainingPermissionListInner
        defaultPageSize={10}
        match={props.match}
        showRowDetails={true}
        viewColumns={[
            { accessor: "fullname" },
            { accessor: "wf_station_name" },
            { accessor: "last_transition" },
            { accessor: "link", Header: __("Műveletek"), maxWidth: 200 },
        ]}
    />
}
