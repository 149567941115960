import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface ISubmittedSolutionRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Megosztott tartalom futtatása
     *
     * NOT NULL -> usr.shared_content_run ON DELETE noaction
     *
     */
    shared_content_run_id?: number;
    /**
     * Mit oldott meg- Táblázat infó
     *
     * NOT NULL -> meta.table_info ON DELETE noaction
     *
     *
     * Ez mondja meg, hogy a megoldott tartalom melyik táblázat rekordjához tartozik. Ez általában exc.exercise lesz.
     */
    table_info_id?: number;
    /**
     * Mit oldott meg - rekord azonosító
     *
     * Ez mondja meg, hogy a megoldott tartalom melyik rekordhoz tartozik. Ez általában exc.exercise lesz.
     */
    rec_id?: number;
    /**
     * A feladat adatai
     */
    exercise?: any|null;
    /**
     * A feladat helyes megoldása
     */
    good_solution?: any|null;
    /**
     * A feladat beküldött megoldása
     */
    submitted_solution?: any;
    /**
     * Eredmény
     */
    success_percent?: number /* double precision */|null;
    /**
     * Maximum elérhető pontok
     */
    max_points?: number /*integer*/|null;
    /**
     * OnlyOffice mappa
     *
     * -> media.oo_folder ON DELETE noaction
     *
     */
    oo_folder_id?: number|null;
}

/**
 *  usr.submitted_solution - Beküldött megoldás
 *
 *  Egy megosztott feladat vagy feladatsor futtatása során a felhasználó által beküldött (rész) megoldás.
 */

export default class SubmittedSolutionCrud extends Crud<ISubmittedSolutionRecord> {
    public static TABLE_INFO_ID = 2390410814;
    public static getSchemaNameForClass() { return 'usr'; }
    public static getTableNameForClass() { return 'submitted_solution'; }

    public static load: (id: number, aServer ?: Server) => Promise<SubmittedSolutionCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<ISubmittedSolutionRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<ISubmittedSolutionRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<ISubmittedSolutionRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<ISubmittedSolutionRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<ISubmittedSolutionRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class SubmittedSolutionCrudClassProxy extends CrudClassProxy<ISubmittedSolutionRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const submittedSolutionCrudClassProxy = new SubmittedSolutionCrudClassProxy(SubmittedSolutionCrud);
registerCrudClassProxy(submittedSolutionCrudClassProxy);
