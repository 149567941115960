import * as React from 'react';
import { app } from '@src/index';
import { me, api } from '@framework/server/Auth';
import { BubbleLoader } from 'react-css-loaders';

import ViewMetaAssignedKeyword from '@framework/view/meta/ViewMetaAssignedKeyword';

import { WithContext as ReactTags } from 'react-tag-input'

import './meta_keyword_assigner_plugin.css';

import obtainServer from '@src/framework/server/Server';

import { __ } from '@src/translation';

/**
 * Visszaadja egy rekordhoz hozzárendelt összes kulcsszót.
 */
export const fetchKeywords = async (tableInfoId: number, recordId: number): Promise<string[]> => {
    try {
        let items = await ViewMetaAssignedKeyword.list({
            filter: {
                table_info_id: tableInfoId,
                record_id: recordId
            }
        });
        if (items && items.length) {
            return Promise.resolve(items.map((item) => { return item.value! }));
        } else {
            return Promise.resolve([]);
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

/**
 * Beállítja (felülírja) egy rekordhoz hozzárendelt összes kulcsszót.
 */
export const setKeywords = async (tableInfoId: number, recordId: number, keywords: string[]): Promise<void> => {
    return _callMetaKeywords(tableInfoId, recordId, "set", keywords);
}

/**
 * Hozzáad extra kulcsszavakat egy rekordhoz
 */
export const appendKeywords = async (tableInfoId: number, recordId: number, keywords: string[]): Promise<void> => {
    return _callMetaKeywords(tableInfoId, recordId, "append", keywords);
}

/**
 * Töröl kulcsszavakat egy rekordból
 */
export const removeKeywords = async (tableInfoId: number, recordId: number, keywords: string[]): Promise<void> => {
    return _callMetaKeywords(tableInfoId, recordId, "remove", keywords);
}

const _callMetaKeywords = async (tableInfoId: number, recordId: number, operation: "append" | "remove" | "set", keywords: string[]): Promise<void> => {
    if (api == null) {
        throw new Error(__("Nincs bejelentkezve?"));
    } else {
        await obtainServer().post<boolean>(
            'metakeywords',
            {
                operation,
                params: {
                    table_info_id: tableInfoId,
                    record_id: recordId,
                    keywords
                }
            }
        );
        return Promise.resolve();
    }
}


export interface IMetaKeywordAssignerPluginProps {
    keywords: string[];
    /** @default: true */
    saveOnBlur?: boolean;

    onAppendKeywords?: (keywords: string[]) => void;
    onDeleteKeyword?: (index: number) => void;
}

interface IMetaKeywordAssignerPluginState { }

export interface IMetaKeywordImmediateAssignerPluginProps {
    tableInfoId: number;
    recordId: number | null;
    /** @default: true */
    saveOnBlur?: boolean;
}

interface IMetaKeywordImmediateAssignerPluginState {
    keywords?: string[];
    loading: boolean;
}


/**
 * Ez a plugin a kulcsszavakat a props-ban tárolja.
 * A mentést manuálisan a setKeywords fügvénnyel
 * kívülről kell megoldani.
 */
export class MetaKeywordAssignerPlugin extends React.Component<IMetaKeywordAssignerPluginProps, IMetaKeywordAssignerPluginState> {
    constructor(props: IMetaKeywordAssignerPluginProps) {
        super(props);
        this.state = {};
    }

    private _onDeleteKeyword = (index: number) => {
        if (this.props.onDeleteKeyword) {
            this.props.onDeleteKeyword(index);
        }
    }

    private _onAppendKeywords = (keyword: string) => {
        let keywords: string[] = [];
        keyword.split(",").map((item) => {
            const word = item.trim();
            if (word && this.props.keywords.indexOf(word) < 0 && keywords.indexOf(word) < 0) {
                keywords.push(word);
            }
        })
        if (keywords.length) {
            if (this.props.onAppendKeywords) {
                this.props.onAppendKeywords(keywords);
            }
        }
    }

    private _onBlur = (keyword: string) => {
        if (this.props.saveOnBlur === undefined || this.props.saveOnBlur) {
            this._onAppendKeywords(keyword);
        }
    }

    render() {
        if (!me) {
            return <div className="callout alert">
                {__("Ez a funkció csak bejelentkezett felhasználók számára elérhető.")}
            </div>;
        } else if (this.props.keywords === undefined) {
            // Betöltés folyamatban
            return <BubbleLoader />
        } else {
            // Betöltve!
            const tags = this.props.keywords.map((text, id) => {
                return { id, text };
            });
            const tagKey: string = (this.props.keywords.map((keyword) => "" + keyword)).join("-");
            return <div>
                <ReactTags
                    placeholder={__("Kulcsszavak")}
                    handleDelete={this._onDeleteKeyword}
                    handleAddition={this._onAppendKeywords}
                    handleInputBlur={this._onBlur as any}
                    key={tagKey}
                    tags={tags}
                    autofocus={false}
                />
            </div>;
        }
    }
}

/**
 * Ez a plugin a kulcsszavakat megjeleníti, a hozzárendelést azonnal elvégzi.
 */
export class MetaKeywordImmediateAssignerPlugin extends React.Component<IMetaKeywordImmediateAssignerPluginProps, IMetaKeywordImmediateAssignerPluginState> {
    constructor(props: IMetaKeywordImmediateAssignerPluginProps) {
        super(props);
        this.state = { loading: true };
    }

    componentDidMount() {
        this.asyncReload();
    }

    componentDidUpdate() {
    }

    private asyncReload = async () => {
        // Ide kellene: a rating és like és favourite adatok betöltése.
        if (me && me.id) {
            try {
                const keywords = await fetchKeywords(
                    this.props.tableInfoId, this.props.recordId!
                );
                this.setState({ loading: false, keywords });
            } catch (error) {
                app.showErrorFromJsonResult(error)
            }
        }
    }

    private onDeleteKeyword = (index: number) => {
        let keyword: string = this.state.keywords![index]!;
        this.setState({ loading: true });
        _callMetaKeywords(
            this.props.tableInfoId,
            this.props.recordId!,
            "remove",
            [keyword]
        )
            .then(() => this.asyncReload())
            .catch((error) => { app.showErrorFromJsonResult(error) })
    }

    private onAppendKeywords = (keywords: string[]) => {
        if (keywords.length) {
            this.setState({ loading: true });
            _callMetaKeywords(
                this.props.tableInfoId,
                this.props.recordId!,
                "append",
                keywords
            )
                .then(() => this.asyncReload())
                .catch((error) => { app.showErrorFromJsonResult(error) })
        }
    }


    render() {
        if (this.state.keywords) {
            const key: string = "kw-" + this.state.keywords.join("|");
            return <MetaKeywordAssignerPlugin
                onDeleteKeyword={this.onDeleteKeyword}
                onAppendKeywords={this.onAppendKeywords}
                saveOnBlur={this.props.saveOnBlur}
                key={key}
                keywords={this.state.keywords}
            />
        } else {
            return null;
        }
    }
}