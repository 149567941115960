import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IOoPermtypeRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Név
     */
    title?: string;
    /**
     * Leírás
     */
    description?: string|null;
}

/**
 *  media.oo_permtype - Média jogosultság típus
 */

export default class OoPermtypeCrud extends Crud<IOoPermtypeRecord> {
    public static TABLE_INFO_ID = 2452084881;
    public static getSchemaNameForClass() { return 'media'; }
    public static getTableNameForClass() { return 'oo_permtype'; }

    public static load: (id: number, aServer ?: Server) => Promise<OoPermtypeCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IOoPermtypeRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IOoPermtypeRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IOoPermtypeRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IOoPermtypeRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IOoPermtypeRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class OoPermtypeCrudClassProxy extends CrudClassProxy<IOoPermtypeRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const ooPermtypeCrudClassProxy = new OoPermtypeCrudClassProxy(OoPermtypeCrud);
registerCrudClassProxy(ooPermtypeCrudClassProxy);
