import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';

import AccessDeniedPage from '@framework/pages/AccessDeniedPage';
import { IPermissionRequirements, permissionCache } from '@framework/security/PermissionCache';

import { app } from '@src/index';

export interface RenderIfHasPermissionProps {
    requirements: IPermissionRequirements;
    showBubbleLoaderOnLoad?: boolean;
}

export interface RenderIfHasPermissionState {
    loading: boolean;
    hasPermission?: boolean;
}


/**
 * Ha a felhasználónak nincsen elegendő jogosultsága, akkor egy "hozzáférés megtagadva"
 * lapot jelenít meg. Egyébként meg azt, ami "benne van".
 * 
 */
export default class RenderIfHasPermission extends React.Component<RenderIfHasPermissionProps, RenderIfHasPermissionState> {
    constructor(props: RenderIfHasPermissionProps) {
        super(props);
        this.state = { loading: true };
        this.asyncReload();
    }

    private asyncReload = async () => {
        permissionCache.hasPermission(this.props.requirements)
            .then((hasPermission) => this.setState({ hasPermission, loading: false }))
            .catch((error) => { app.showErrorFromJsonResult(error); })
    }

    render() {
        if (this.state.loading) {
            if (this.props.showBubbleLoaderOnLoad) {
                return <BubbleLoader />;
            } else {
                return null;
            }
        } else if (this.state.hasPermission) {
            if (React.Children.count(this.props.children) == 1) {
                return this.props.children;
            } else {
                return <div>{this.props.children}</div>;
            }
        } else {
            return null;
        }
    }

}