import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewClassVisitDfhtAspectAnswerRecord {
    /**
     * Intézmény látogatás azonosító
     *
     * Intézmény látogatás azonosító
     */
    id?: number;
    /**
     * Látogatás kezdete
     *
     * Látogatás kezdete
     */
    min_start_date?: string /*timestamp?*/|null;
    /**
     * Ellátási hely azonosító
     *
     * Ellátási hely azonosító
     */
    institute_site_id?: number|null;
    /**
     * Feladat ellátási hely neve
     *
     * Feladat ellátási hely neve
     */
    institute_site_name?: string|null;
    /**
     * Ellátási hely azonosító
     *
     * Ellátási hely azonosító
     */
    site_identifier?: string|null;
    /**
     * Ellátási hely irányítószám
     *
     * Ellátási hely irányítószám
     */
    institute_site_zipcode?: string|null;
    /**
     * Ellátási hely címsor
     *
     * Ellátási hely címsor
     */
    institute_site_address?: string|null;
    /**
     * Ellátási hely település azonosító
     *
     * Ellátási hely település azonosító
     */
    city_id?: number|null;
    /**
     * Ellátási hely település név
     *
     * Ellátási hely település név
     */
    city_name?: string|null;
    /**
     * Intézmény azonosító
     *
     * Intézmény azonosító
     */
    institute_id?: number|null;
    /**
     * Intézmény OM azonosító
     *
     * Intézmény OM azonosító
     */
    om_identifier?: string|null;
    /**
     * Intézmény neve
     *
     * Intézmény neve
     */
    institute_name?: string|null;
    /**
     * Tanár felhasználó azonosító
     *
     * Tanár felhasználó azonosító
     */
    teacher_id?: number|null;
    /**
     * Tanár személy azonosító
     *
     * Tanár személy azonosító
     */
    teacher_person_id?: number|null;
    /**
     * Tanár e-mail cím
     *
     * Tanár e-mail cím
     */
    teacher_email?: string|null;
    /**
     * Tanár teljes neve
     *
     * Tanár teljes neve
     */
    teacher_fullname?: string|null;
    /**
     * Támogató felhasználó azonosító
     *
     * Támogató felhasználó azonosító
     */
    kap_supporter_id?: number|null;
    /**
     * Támogató személy azonosító
     *
     * Támogató személy azonosító
     */
    supporter_person_id?: number|null;
    /**
     * Támogató e-mail cím
     *
     * Támogató e-mail cím
     */
    supporter_email?: string|null;
    /**
     * Támogató teljes neve
     *
     * Támogató teljes neve
     */
    supporter_fullname?: string|null;
    /**
     * Tantárgy azonosító
     *
     * Tantárgy azonosító
     */
    subject_id?: number|null;
    /**
     * Tantárgy név
     *
     * Tantárgy név
     */
    subject_name?: string|null;
    /**
     * Óralátogatás azonosító
     *
     * Óralátogatás azonosító
     */
    class_visit_id?: number|null;
    /**
     * Óralátogatás osztály neve
     *
     * Óralátogatás osztály neve
     */
    class_visit_class_name?: string|null;
    /**
     * Óralátogatás téma
     *
     * Óralátogatás téma
     */
    class_visit_topic?: string|null;
    /**
     * Sablon azonosítója
     *
     * Sablon azonosítója
     */
    kip_aspect_id?: number|null;
    /**
     * Sablon neve
     *
     * Sablon neve
     */
    kip_aspect_title?: string|null;
    /**
     * Szempont típus azonosító
     *
     * Szempont típus azonosító
     */
    kip_aspect_type_id?: number|null;
    /**
     * Válasz azonosító
     *
     * Válasz azonosító
     */
    kip_aspect_answer_id?: number|null;
    /**
     * Szöveges válasz
     *
     * Szöveges válasz
     */
    text_value?: string|null;
    /**
     * Logikai válasz
     *
     * Logikai válasz
     */
    boolean_value?: boolean|null;
    /**
     * Válasz
     *
     * Válasz
     */
    kat_value?: string|null;
    /**
     * Folyamat azonosító
     *
     * Folyamat azonosító
     */
    workflow_id?: number|null;
    /**
     * Folyamat állapot azonosító
     *
     * Folyamat állapot azonosító
     */
    wf_station_id?: number|null;
    /**
     * Folyamat állapot név
     *
     * Folyamat állapot név
     */
    wf_station_name?: string|null;
}

/**
 *  kap.view_class_visit_dfht_aspect_answer - DFHT szempontsorokra adott válaszok
 *
 *  DFHT szempontsorokra adott válaszok
 */

export default class ViewClassVisitDfhtAspectAnswer extends View<IViewClassVisitDfhtAspectAnswerRecord> {
    public static VIEW_INFO_ID = 2019122303;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_class_visit_dfht_aspect_answer'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewClassVisitDfhtAspectAnswer>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewClassVisitDfhtAspectAnswerRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewClassVisitDfhtAspectAnswerClassProxy extends ViewClassProxy<IViewClassVisitDfhtAspectAnswerRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewClassVisitDfhtAspectAnswerClassProxy = new ViewClassVisitDfhtAspectAnswerClassProxy(ViewClassVisitDfhtAspectAnswer);
registerViewClassProxy(viewClassVisitDfhtAspectAnswerClassProxy);

