import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IExcSelectionModeTranslationRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Nyelv
     *
     * NOT NULL -> sys.lang ON DELETE cascade
     *
     */
    lang_id?: number;
    /**
     * Név
     */
    name?: string;
    /**
     * Leírás
     */
    description?: string|null;
    /**
     * Feladat kiválasztás módja
     *
     * NOT NULL -> exc.exc_selection_mode ON DELETE cascade
     *
     */
    exc_selection_mode_id?: number;
}

/**
 *  exc.exc_selection_mode_translation - Feladat kiválasztás módja fordítás
 */

export default class ExcSelectionModeTranslationCrud extends Crud<IExcSelectionModeTranslationRecord> {
    public static TABLE_INFO_ID = 3140088479;
    public static getSchemaNameForClass() { return 'exc'; }
    public static getTableNameForClass() { return 'exc_selection_mode_translation'; }

    public static load: (id: number, aServer ?: Server) => Promise<ExcSelectionModeTranslationCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IExcSelectionModeTranslationRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IExcSelectionModeTranslationRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IExcSelectionModeTranslationRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IExcSelectionModeTranslationRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IExcSelectionModeTranslationRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ExcSelectionModeTranslationCrudClassProxy extends CrudClassProxy<IExcSelectionModeTranslationRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const excSelectionModeTranslationCrudClassProxy = new ExcSelectionModeTranslationCrudClassProxy(ExcSelectionModeTranslationCrud);
registerCrudClassProxy(excSelectionModeTranslationCrudClassProxy);
