import * as React from 'react';
import { ITableInfo, getTableInfoById } from '@src/framework/crud/Meta';
import ViewWfHeadTable, { IViewWfHeadTableRecord } from '@src/framework/view/wf/ViewWfHeadTable';
import WfHeadPlugin, { WfHeadLoadedEvent, WfHeadAfterTransitionEvent, DisplayMode } from './wf_head_plugin';
import { app } from '../..';
import obtainServer, { Server } from '@src/framework/server/Server';
import WfAPI from '@src/framework/wf/WfAPI';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ViewWfWorkflow, { IViewWfWorkflowRecord } from '@src/framework/view/wf/ViewWfWorkflow';
import { IViewTransitionTypeRecord } from '@src/framework/view/wf/ViewTransitionType';
import { __ } from '@src/translation';

export interface IWfPluginProps {
    tableInfo?: ITableInfo;
    tableInfoId?: number;
    recId: number | null;
    onHeadLoaded?: WfHeadLoadedEvent;
    afterTransition?: WfHeadAfterTransitionEvent;

    server?: Server;
    wfApi?: WfAPI;

    displayMode?: DisplayMode;

    showInactiveWorkflows?: boolean;
}

interface IWfPluginState {
    headTables?: IViewWfHeadTableRecord[];
    tableInfo?: ITableInfo;
}

/**
 * Megjeleníti és manipulálja egy konkrét rekordhoz tartozó ÖSSZES folyamatot.
 * Minden típushoz egy külön WfHeadPlugin-t használ.
 */
export default class WfPlugin extends React.Component<IWfPluginProps, IWfPluginState> {
    private server: Server;
    private wfAPI: WfAPI;

    constructor(props: IWfPluginProps) {
        super(props);
        if (!props.tableInfo && !props.tableInfoId) {
            throw new Error(__("tableInfo vagy tableInfoId megadása kötelező!"));
        }
        if (props.tableInfo && props.tableInfoId) {
            throw new Error(__("tableInfo és tableInfoId közül pontosan egyet kell megadni!"));
        }

        this.server = props.server || obtainServer();
        this.wfAPI = props.wfApi || new WfAPI(this.server);
        this.state = { tableInfo: props.tableInfo };
    }

    private asyncInit = async () => {
        getTableInfoById(this.props.tableInfoId!)
            .then((tableInfo) => { this.setState({ tableInfo }, this.asyncReload) })
            .catch((error) => { app.showErrorFromJsonResult(error) });
    }

    /** Event emitter */
    private _onHeadLoaded = (sender: WfHeadPlugin) => {
        if (this.props.onHeadLoaded) {
            this.props.onHeadLoaded(sender);
        }
    }

    private _afterTransition = (sender: WfHeadPlugin, workflow: IViewWfWorkflowRecord, transitionType: IViewTransitionTypeRecord) => {
        if (this.props.afterTransition) {
            this.props.afterTransition(sender, workflow, transitionType);
        }
    }


    componentDidMount() {
        if (this.props.tableInfoId) {
            this.asyncInit().catch(app.showErrorFromJsonResult)
        }
    }

    private asyncReload = async () => {
        try {
            let headTables = await ViewWfHeadTable.list({
                filter: {
                    table_id: this.state.tableInfo!.id,
                    is_active: true,
                }
            });

            if (!this.props.showInactiveWorkflows) {
                const workflows = await ViewWfWorkflow.list({
                    filter: { head_table_id: headTables.map(h => h.id!), rec_id: this.props.recId }
                });
                const activeHeadTableIds = workflows.map(w => w.head_table_id);

                headTables = headTables.filter(h => activeHeadTableIds.includes(h.id));
            }

            this.setState({ headTables });

        } catch(e) {
            app.showErrorFromJsonResult(e);
        }
    }

    public getMyUniqueId(): string {
        return `wf_plugin_${this.state.tableInfo!.id}_${this.props.recId || 0}`;
    }

    render() {
        if (!this.props.recId) {
            return <div className="callout warning">
                {__("A folyamatadatok megjelenítéséhez először vegye fel a tételt!")}
                </div>;
        }

        if (this.state.headTables === undefined || this.state.tableInfo === undefined) {
            return <div>{__("Kérem várjon...")}</div>;
        }
        let headTables: IViewWfHeadTableRecord[] = this.state.headTables;
        const myId = this.getMyUniqueId();
        if (headTables.length > 1) {
            return <Tabs>
                <TabList>
                    {
                        headTables.map((item, index) => {
                            return <Tab key={item.id!}>{item.wf_type_name}</Tab>
                        })
                    }
                </TabList>            
                {
                    headTables.map((item, index) => {
                        return (
                            <TabPanel key={item.id!}>
                                <WfHeadPlugin   
                                    {...this.props} head={item} 
                                        server={this.server} 
                                        wfApi={this.wfAPI} 
                                        onLoaded={this._onHeadLoaded} 
                                        afterTransition={this._afterTransition}
                                        displayMode={this.props.displayMode ? this.props.displayMode : DisplayMode.All}
                                />
                        </TabPanel> );
                    })
                }
            </Tabs>;
        } else if (headTables.length == 1) {
            return <WfHeadPlugin   {...this.props} head={headTables[0]} onLoaded={this._onHeadLoaded} displayMode={this.props.displayMode ? this.props.displayMode : DisplayMode.All}/>
        } else {
            return <div className="callout secondary">
                {__("Ehhez a típushoz nincsenek folyamat típusok rendelve.")}
                </div>
        }
    }
}