import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapDfhtSupporterRecord {
    /**
     * Feladat ellátási hely azonosító
     *
     * Feladat ellátási hely azonosító
     */
    id?: number;
    /**
     * Feladat ellátási hely név
     *
     * Feladat ellátási hely név
     */
    institute_site_name?: string|null;
    /**
     * Intézmény azonosító
     *
     * Intézmény azonosító
     */
    institute_id?: number|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    institute_name?: string|null;
    /**
     * Aktív (feladat ellátási hely)
     *
     * Aktív (feladat ellátási hely)
     */
    is_active?: boolean|null;
    /**
     * KAP bevezetés dátuma
     *
     * KAP bevezetés dátuma
     */
    kap_introduction_date?: string /*date?*/|null;
    /**
     * Város azonosító
     *
     * Város azonosító
     */
    city_id?: number|null;
    /**
     * Város név
     *
     * Város név
     */
    city_name?: string|null;
    /**
     * Irányítószám
     *
     * Irányítószám
     */
    zipcode?: string|null;
    /**
     * Cím
     *
     * Cím
     */
    address?: string|null;
    /**
     * Külföldi cím
     *
     * Külföldi cím
     */
    foreign_address?: string|null;
    /**
     * Külföldi
     *
     * Külföldi
     */
    is_foreign?: boolean|null;
    /**
     * DFHT szakmai támogató felhasználó azonosító
     *
     * DFHT szakmai támogató felhasználó azonosító
     */
    kap_supporter_id?: number|null;
    /**
     * DFHT szakmai támogató bejelentkezési név
     *
     * DFHT szakmai támogató bejelentkezési név
     */
    login_name?: string|null;
    /**
     * DFHT szakmai támogató e-mail
     *
     * DFHT szakmai támogató e-mail
     */
    email?: string|null;
    /**
     * DFHT szakmai támogató személy azonosító
     *
     * DFHT szakmai támogató személy azonosító
     */
    person_id?: number|null;
    /**
     * DFHT szakmai támogató teljes név
     *
     * DFHT szakmai támogató teljes név
     */
    fullname?: string|null;
    /**
     * DFHT szakmai támogató felhasználó aktív
     *
     * DFHT szakmai támogató felhasználó aktív
     */
    user_is_active?: boolean|null;
}

/**
 *  kap.view_kap_dfht_supporter - KAP feladatellátási helyek DFHT szakmai támogatói
 *
 *  KAP feladatellátási helyek DFHT szakmai támogatói
 */

export default class ViewKapDfhtSupporter extends View<IViewKapDfhtSupporterRecord> {
    public static VIEW_INFO_ID = 2019083001;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_dfht_supporter'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapDfhtSupporter>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapDfhtSupporterRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapDfhtSupporterClassProxy extends ViewClassProxy<IViewKapDfhtSupporterRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapDfhtSupporterClassProxy = new ViewKapDfhtSupporterClassProxy(ViewKapDfhtSupporter);
registerViewClassProxy(viewKapDfhtSupporterClassProxy);

