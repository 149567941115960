import './style.css';
import { ExerciseBaseClass } from '@src/component/exercise/models/ExerciseBaseClass';
import { AExerciseEngine } from '../../models/AExerciseEngine';
import { __ } from '@src/translation';

export interface FillGapTextData extends ExerciseBaseClass {
    gap_text: string;
    case_sensitive: boolean;
    textbox_width: number;
}
export class FillGapTextExerciseEngine extends AExerciseEngine {

    exercise: FillGapTextData;

    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        const exercise: FillGapTextData = params.exercise;

        if (!exercise.gap_text) return;
        this.exercise = exercise;
        this.root.classList.add("eke-fillgap-text");
        this.setIllustration(exercise, this.root);

        const pattern = /#\d+#/g;
        let text = exercise.gap_text.replace(/\r/g, "").replace(/\n/g, "<br>");
        let textBoxIndex: number = 0;
        let result = null;

        // Szöveg feldolgozása, #...# cseréje HTML input textekre

        while ((result = pattern.exec(text))) {
            let textbox = document.createElement("input");
            textbox.setAttribute("type", "text");
            textbox.setAttribute("select-indx", textBoxIndex.toString());
            textbox.classList.add("eke-fillgap-text-inputtext");
            textbox.style.width = (exercise.textbox_width+5).toString() + "em";
            text = this.replaceBetween(result.index, result.index + result[0].length, textbox, text);
            textBoxIndex++;
        }

        const gap_text_container = document.createElement("nav");
        gap_text_container.classList.add("eke-fillgap-text-p");
        this.root.appendChild(gap_text_container).innerHTML = text;

    }

    replaceBetween(start: number, end: number, what: HTMLElement, str: string): string {
        return str.substring(0, start) + what.outerHTML + str.substring(end);
    }

    getUserSolution() {
        return { answer: this.getUserAnswers(), fullmatch: true };
    }

    getUserAnswers():string[] {
        const userAnswers:string[] = [];
        this.getInputs().forEach(inp => userAnswers.push(inp.value) );
        return userAnswers;
    }

    getInputs() {
        return this.root.querySelectorAll('input');
    }

    receiveEvaluation(evaluated: Evaluated): void {
        const caseSensitive = this.exercise.case_sensitive;
        this.getInputs().forEach((inp, ind:number) => {
            AExerciseEngine.removeEvalStyle(inp);
            const solution = caseSensitive ? evaluated.solution : this.getUpperCasedSolution(evaluated.solution);
            const userValue = caseSensitive ? inp.value : inp.value.toUpperCase();
            // Tökéletes megoldás
            if (evaluated.success) inp.classList.add("exe-engine-check-correct");
            // Nem tökéletes megoldás
            else {
                // Jó válasz
                if(solution[ind].includes(userValue)) {
                    inp.classList.add("exe-engine-check-correct");
                }
                // Rossz válasz
                else {
                    if(!this.isSNIexc) inp.classList.add("exe-engine-check-wrong");
                }
            }
        })
    }

    getUpperCasedSolution(solution:string[][]) {
        let upperCased:string[][] = [];
        solution.forEach((sArr:string[]) => {
            let solArr:string[] = [];
            sArr.forEach(ans => {
                solArr.push(ans.toUpperCase());
            });
            upperCased.push(solArr);
        })
        return upperCased;
    }

    showCorrectSolution(solution: any): void {
        this.getInputs().forEach((inp, ind:number) => {
            inp.value = solution[ind];
            AExerciseEngine.removeEvalStyle(inp);
            if (!this.isReplay) inp.classList.add("eke-engine-show-correct-bg");
        });
    }

    isUserReady(): boolean {
        return this.getUserAnswers().some(ua => ua != "");
        // Ha mindet töltse ki, akkor "some" helyett "every"
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }
}