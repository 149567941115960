import './style.css';
import { ExerciseBaseClass, AnswerElement, NavigationDirections } from '@src/component/exercise/models/ExerciseBaseClass';
import { ExerciseEngineSubSeries, AExerciseEngine } from '../../models/AExerciseEngine';
import { EKEQuizSeriesServer } from './EKEQuizSeriesServer';

type QuizSeriesQuestionData = {
    task: string;
    answers: string[];
    options: AnswerElement[];
    multiple_answers: boolean;
    question_illustration: string;
    question_illustration_alt: string;
    question_sound: string;
}

export interface QuizSeriesData extends ExerciseBaseClass {
    title: string;
    description: string;
    imagebasepath: string;
    illustration: string;
    illustration_alt: string;
    imageanswers: boolean;
    questions: QuizSeriesQuestionData[];
    keywords: string;
    num_of_questions: number;
    ordered: boolean;
    ordered_answers: boolean;
    vertical_display: boolean;
    isExam: boolean;
}

type QuizSeriesSolution = {
    key: number;
    value: number[];
}
type QuizSeriesUserSolution = {
    fullmatch: boolean;
    answer: QuizSeriesSolution[];

}

export class EKEQuizSeriesExerciseEngine extends ExerciseEngineSubSeries {

    private radiobuttonList: HTMLInputElement[] = [];
    private labels: HTMLElement[] = [];
    private questionContainerElement: HTMLElement = document.createElement("div");
    private questionElement: HTMLElement = document.createElement("p");
    private answersdiv: HTMLElement = document.createElement("div");
    private illustrationElement: HTMLElement = document.createElement("div");
    private soundElement: HTMLElement = document.createElement("div");

    private questionOrderList: number[] = [];
    private shuffledIndexesFromOriginal: any[] = [];
    private actualQuestionIndex: number = 0;
    private userSolutions: any[] = [];
    // private evalutedFromServer: any[] = [];
    private shuffledAnswerElements: any[] = [];   //shuffling answer elements in the init part, and reading this array during shownextquestion
    private originalAnswerElements: any[] = [];   //storing the original positions as well

    private clearWrongAnsTimeout: any;

    answerindexes: number[] = [];

    shuffleQuestions(array: any[]) {
        var exerciseQuestion = array.slice();            //storing the exercise questions
        array = array.filter(x => x.options.length > 0);        //removing empty elements after filter
        var originalQuestion = array.slice();           //stroe the original questions but empty elements removed
        var currentIndex = array.length, temporaryValue, randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        for (let index = 0; index < array.length; index++) {
            //creating question orderlist
            this.questionOrderList.push(array.indexOf(originalQuestion[index]));
        }

        for (let index = 0; index < array.length; index++) {
            //storing how the questions were shuffled for further evaluation
            this.shuffledIndexesFromOriginal.push(exerciseQuestion.indexOf(array[index]));
        }

        return array;
    }


    initExercise(params: ExerciseParams): void {
        super.initExercise(params);
        let exercise: QuizSeriesData = params.exercise;
        this.questionOrderList = [];
        this.userReadyWithSeriesFunc = params.userReadyWithSubSeries;
        //this.reloadResourceFunc = params.reloadResources;

        if (!exercise.questions)
            return;

        if (exercise.num_of_questions == 0 || !exercise.num_of_questions || exercise.num_of_questions == undefined)
            exercise.num_of_questions = exercise.questions.length;

        if (!exercise.ordered && this.questionOrderList) {          //shuffling questions ---- questionOredList and ShuffledIndexes preapared here
            exercise.questions = this.shuffleQuestions(exercise.questions)
        }
        else if (exercise.ordered && this.questionOrderList) {      //if questions demanded at given order, then manually fill the two arrays from 0 to num_of_question

            for (let index = 0; index < exercise.num_of_questions; index++) {
                this.questionOrderList.push(index);
                this.shuffledIndexesFromOriginal.push(index);
            }
            exercise.questions = exercise.questions.filter(x => x.options.length > 0);      //remove the tasks that has been emptied during filtering
        }

        this.exercise = exercise;

        this.answerindexes = [];

        if (!exercise.questions || exercise.questions.length == 0 || !exercise.questions[this.questionOrderList[this.actualQuestionIndex]].options)
            return;
        for (let i = 0; i < exercise.questions[this.questionOrderList[this.actualQuestionIndex]].options.length; i++) {
            this.answerindexes.push(i);
        }

        this.radiobuttonList = [];
        this.labels = [];

        this.root.classList.add("eke-quiz-series");

        this.setIllustration(exercise, this.root);

        this.questionContainerElement = this.root.appendChild(document.createElement("div"));
        this.questionContainerElement.classList.add("exe-question-container");

        this.illustrationElement = this.root.appendChild(document.createElement("div"));
        this.soundElement = this.root.appendChild(document.createElement("div"));

        this.answersdiv = this.root.appendChild(document.createElement("div"));
        this.answersdiv.classList.add("answer-container-flex");

        for (let index = 0; index < exercise.questions.length; index++) {
            this.originalAnswerElements.push(exercise.questions[index].options.slice());                //storing original answer elements
            if (!exercise.ordered_answers)
                this.shuffledAnswerElements.push(AExerciseEngine.shuffle(exercise.questions[index].options.slice()))   //then shuffle them
            else
                this.shuffledAnswerElements.push(exercise.questions[index].options.slice())                 //or not according to the checkbox 
        }

        this.actualQuestionIndex = -1;
        this.showNextQuestion(1);
    }

    public getNextSubQuestion(direction: NavigationDirections): SubSeriesQuestionData {
        if (this.actualQuestionIndex + direction == this.exercise.num_of_questions
            || this.actualQuestionIndex + direction < 0)
            return { is_next_available: false, number_of_questions: this.exercise.num_of_questions, current_question_index: this.actualQuestionIndex };

        this.showNextQuestion(direction);

        return { is_next_available: true, number_of_questions: this.exercise.num_of_questions, current_question_index: this.actualQuestionIndex };
    }

    showNextQuestion(direction: NavigationDirections) {
        if (this.questionOrderList.length < 1) {
            return;
        }
        this.actualQuestionIndex += direction;

        var cur_label = this.root.querySelector('[id=\"processLabel\"]');
        if (cur_label)
            cur_label.textContent = " " + (Number(this.actualQuestionIndex) + 1);
        this.radiobuttonList = [];
        this.labels = [];

        let accNumberElement = document.createElement("span");
        accNumberElement.classList.add("show-for-sr");
        accNumberElement.innerText = this.actualQuestionIndex + 1 + "/" + this.exercise.num_of_questions;
        let actualQuestion = document.createTextNode(this.exercise.questions[this.actualQuestionIndex].task);
        this.questionContainerElement.innerHTML = "";
        if (this.exercise.questions[this.actualQuestionIndex].task != "") {
            this.questionElement = this.questionContainerElement.appendChild(document.createElement("nav"));
            this.questionElement.classList.add("exe-quiz-question", "exe-description");
            //this.questionElement.innerHTML = "";
            this.questionElement.appendChild(accNumberElement);
            this.questionElement.appendChild(actualQuestion);
        }

        this.illustrationElement.innerText = "";
        if (this.exercise.questions[this.actualQuestionIndex].question_illustration != null
            && this.exercise.questions[this.actualQuestionIndex].question_illustration != "") {
            var img = this.illustrationElement.appendChild(document.createElement("img"));
            img.setAttribute("alt", this.exercise.questions[this.actualQuestionIndex].question_illustration_alt);
            img.setAttribute("title", this.exercise.questions[this.actualQuestionIndex].question_illustration_alt);
            img.setAttribute("src", this.exercise.imagebasepath + this.exercise.questions[this.actualQuestionIndex].question_illustration);
            img.classList.add("exe-engine-illustration");
        }
        this.soundElement.innerText = "";
        if (this.exercise.questions[this.actualQuestionIndex].question_sound != null
            && this.exercise.questions[this.actualQuestionIndex].question_sound != "") {
            var sound = this.soundElement.appendChild(document.createElement("audio"));
            sound.setAttribute("src", this.exercise.imagebasepath + this.exercise.questions[this.actualQuestionIndex].question_sound);
            sound.setAttribute("controls", "true")
            sound.classList.add("exe-engine-subquestion-sound");
        }

        var shuffledAnswers: any[];
        shuffledAnswers = [];
        this.shuffledAnswerElements[this.actualQuestionIndex].forEach((element: any) => {
            shuffledAnswers.push(element);
        });

        let randomId = Math.floor(Math.random() * 1000);
        this.answersdiv.innerText = "";
        //Válaszok feldolgozása
        for (var answer of shuffledAnswers) {

            var answerdiv = this.answersdiv.appendChild(document.createElement("div"));
            answerdiv.classList.add("small-12", "medium-5", "large-3", "answer-item");
            if (this.exercise.vertical_display) answerdiv.classList.add("medium-12", "large-8");
            answerdiv.classList.add("cell");
            answerdiv.classList.add("columns");

            var checkableItem = answerdiv.appendChild(document.createElement("input"));

            if (this.exercise.questions[this.actualQuestionIndex].multiple_answers) {
                checkableItem.setAttribute("type", "checkbox");
            } else {
                checkableItem.setAttribute("type", "radio");
                checkableItem.setAttribute("name", "answer");
            }
            let ans_id = "ans_" + randomId + this.originalAnswerElements[this.actualQuestionIndex].indexOf(answer);
            checkableItem.setAttribute("id", ans_id);

            if (this.isReplay) {
                checkableItem.setAttribute('disabled', 'disabled');
            }

            var label2 = answerdiv.appendChild(document.createElement("label"));
            label2.setAttribute("for", ans_id);
            label2.classList.add("button");
            label2.classList.add("clear");
            if (answer.type == "image") {
                var answerimage = label2.appendChild(document.createElement("img"));
                answerimage.setAttribute("src", this.exercise.imagebasepath + answer.image);
                answerimage.setAttribute("alt", answer.text);
                answerimage.setAttribute("title", answer.text);
                answerimage.classList.add("exe-large-img");
            }
            else if (answer.type == "sound") {
                let soundTag = label2.appendChild(document.createElement("AUDIO"));
                soundTag.setAttribute("src", this.exercise.imagebasepath + answer.url);
                let playIcon = label2.appendChild(document.createElement("i"));
                playIcon.classList.add("fa", "fa-play", "fa-2x", "quiz-series-play-sound");
                playIcon.addEventListener('click', AExerciseEngine.playAnswerSound.bind(this, label2, this, "quiz-series-play-sound"));
                let containerDiv = label2.appendChild(document.createElement("span"));
                containerDiv.innerText = answer.text;
            }
            else {
                // let containerDiv = label2.appendChild(document.createElement("span"));
                label2.innerHTML = answer.text;
            }

            let resizeSettings = { minFontSize: '10px', maxFontSize: '17px' };
            if (this.exercise.vertical_display) {
                ($(label2) as any).fitText(2.65, resizeSettings);
            } else {
                ($(label2) as any).fitText(resizeSettings);
            }
            this.radiobuttonList.push(checkableItem);
            this.labels.push(label2);
            //We need to evaulate every selection in case of SNI mode
            if (this.isSNIexc) checkableItem.addEventListener('change', this.onChange.bind(this), false);

            answerdiv.setAttribute("data-index", this.originalAnswerElements[this.actualQuestionIndex].indexOf(answer));  //give the data index according to the originial position of the element           
        }

        // if the user did not try to solve this question we automatically save the empty answer
        if (this.userSolutions.length == this.actualQuestionIndex) {
            this.userSolutions.push({ key: this.shuffledIndexesFromOriginal[this.actualQuestionIndex], value: [] });
        } else {
            // todo: if the bidirection navigion will work, we need to implement this branch as well
        }

        if (this.reloadResources) this.reloadResources();
    }

    onChange() {
        //We evaluate the exercise in SNI mode at every step
        this.SNIEvaluation(EKEQuizSeriesServer);
    }

    getUserSolution(): QuizSeriesUserSolution {
        var selectedRadio = [];
        for (var index = 0; index < this.radiobuttonList.length; index++) {
            var radiobutton = this.radiobuttonList[index];
            if (radiobutton.checked) {
                selectedRadio.push(+this.radiobuttonList[index].parentElement!.getAttribute("data-index")!);
            }
        }
        var sol = {
            key: this.shuffledIndexesFromOriginal[this.actualQuestionIndex],
            value: selectedRadio
        };

        if (this.isExamMode && (this.actualQuestionIndex + 1) == this.exercise.num_of_questions) {
            this.userReadyWithSeriesFunc(true);
        }

        this.userSolutions[this.actualQuestionIndex] = sol;

        let solution = { answer: this.userSolutions, fullmatch: false };

        return solution;
    }

    receiveEvaluation(evaluated: Evaluated): void {
        // this.evalutedFromServer.push({ ...evaluated, key: this.shuffledIndexesFromOriginal[this.actualQuestionIndex] });
        // var multipleCorrectAnswer = true;

        /* delete Clear Wrong Answers timeout */
        clearTimeout(this.clearWrongAnsTimeout);

        let actualIndex = this.shuffledIndexesFromOriginal[this.actualQuestionIndex];
        let userSol = this.getUserSolution();
        let userSolValue: any;
        for (let j = 0; j < userSol.answer.length; j++) {
            if (userSol.answer[j].key == actualIndex) {
                userSolValue = userSol.answer[j].value;
                break;
            }
        }
        for (let i = 0; i < this.radiobuttonList.length; i++) {
            let curr_dataIndx = this.radiobuttonList[i].parentElement!.getAttribute('data-index');
            let label = this.labels[i];
            this.removeStyles(label);
            let currentSolution = evaluated.solution[actualIndex].value;

            if (currentSolution.includes(Number(curr_dataIndx))) {
                if (userSolValue.includes(Number(curr_dataIndx))) {
                    label.classList.add("exe-engine-check-correct");
                }
            }
            else {
                if (userSolValue.includes(Number(curr_dataIndx))) {
                    //if(!this.isSNIexc)label.classList.add("exe-engine-check-wrong");
                    label.classList.add("exe-engine-check-wrong");
                    let checkBox = label.parentNode!.querySelector("input");
                    checkBox!.checked = false;
                }
            }
        }

        if ((this.actualQuestionIndex + 1) == this.exercise.num_of_questions) {
            this.userReadyWithSeriesFunc(evaluated.success);
        }

        /* IF SNI, clear all wrong answers with delay */
        if (this.isSNIexc) {
            this.clearWrongAnsTimeout = setTimeout(() => {
                for (var index = 0; index < this.radiobuttonList.length; index++) {
                    if (this.labels[index].classList.contains("exe-engine-check-wrong")) {
                        this.removeStyles(this.labels[index]);
                        this.radiobuttonList[index].checked = false;
                    }


                }
            },
                2000);
        }
    }

    removeStyles(element: HTMLElement): void {
        element.classList.remove(
            "exe-engine-correct-bg",
            "exe-engine-wrong-bg",
            "exe-engine-check-correct",
            "exe-engine-check-wrong"
        );
    }

    showCorrectSolution(solution: any[]): void {
        var templist: any[] = [];
        for (let index = 0; index < this.shuffledIndexesFromOriginal.length; index++) {     //shuffle correct solution according to question shuffling
            templist.push(solution[this.shuffledIndexesFromOriginal[index]])
        }
        solution = templist;

        this.labels.forEach(lbl => {
            if (lbl && !lbl.classList.contains("exe-engine-correct-bg")) {
                this.removeStyles(lbl);
            }
        });

        if ((Number(this.actualQuestionIndex)) < this.exercise.num_of_questions) {
            for (var index = 0; index < solution.length; index++) {
                if (solution[index].key == this.shuffledIndexesFromOriginal[this.actualQuestionIndex]) {
                    for (var i = 0; i < solution[index].value.length; i++) {
                        let label_idx = 0
                        let label = undefined;
                        for (; label_idx < this.labels.length; label_idx++) {
                            //this.radiobuttonList[index].parentElement!.getAttribute("data-index")
                            if (Number(this.labels[label_idx].parentElement!.getAttribute("data-index")) == solution[index].value[i]) {
                                label = this.labels[label_idx];
                                break;
                            }
                        }

                        //this.labels[solution[index].value[i]];
                        this.radiobuttonList[label_idx].checked = true;
                        if (label) label.classList.add("exe-engine-correct-bg");
                    }
                }
            }
        }
    }

    isUserReady(): boolean {
        for (var index = 0; index < this.radiobuttonList.length; index++) {
            var radiobutton = this.radiobuttonList[index];
            if (radiobutton.checked) {
                return true;
            }
        }
        return false;
    }

    showHelp(solution: any): void {
        this.showCorrectSolution(solution);
    }

}

