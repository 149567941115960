import { Server } from '../../server/Server';
import Crud , { registerCrudClassProxy, IAsTextResult, IListParams, CrudClassProxy, ISearchTextParams, ISearchTextResult } from '../Crud';

export interface IOccupationDraftBlockRecord {
    /**
     * Globálisan egyedi azonosító.
     * 
     * Ha új rekordot akarsz létrehozni put() metódussal, akkor hagyd üresen.
     * upsert() hívásnál figyelmen kívül van hagyva az értéke.
     *
     */
    id ?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * NOT NULL -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * -> sys.sec_user ON DELETE noaction
     *
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * Sorrend
     */
    no?: number /*integer*/|null;
    /**
     * Foglalkozásterv
     *
     * NOT NULL -> kap.occupation_draft ON DELETE cascade
     *
     */
    occupation_draft_id?: number;
    /**
     * Időkeret (perc)
     */
    require_time_min?: number /*integer*/;
    /**
     * Pedagógus tevékenysége
     */
    teacher_activity?: string;
    /**
     * Tanuló tevékenysége
     */
    student_activity?: string;
    /**
     * Egyéb módszer
     */
    dfht_method_other?: string|null;
    /**
     * Egyéb munkaforma
     */
    dfht_work_form_other?: string|null;
    /**
     * Eszközök
     */
    tools?: string|null;
}

/**
 *  kap.occupation_draft_block - KAP Foglalkozásterv blokk
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class OccupationDraftBlockCrud extends Crud<IOccupationDraftBlockRecord> {
    public static TABLE_INFO_ID = 1746884502;
    public static getSchemaNameForClass() { return 'kap'; }
    public static getTableNameForClass() { return 'occupation_draft_block'; }

    public static load: (id: number, aServer ?: Server) => Promise<OccupationDraftBlockCrud>;
    public static asText: (id: number, includeRecord : boolean, aServer ?: Server) => Promise<IAsTextResult<IOccupationDraftBlockRecord>>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IOccupationDraftBlockRecord[]>;
    public static searchText: (params: ISearchTextParams, aServer ?: Server) => Promise<ISearchTextResult<IOccupationDraftBlockRecord>[]>;
    public static deleteById: (id: number, aServer ?: Server) => Promise<IOccupationDraftBlockRecord>;
    public static unDeleteById: (id: number, aServer ?: Server) => Promise<IOccupationDraftBlockRecord>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class OccupationDraftBlockCrudClassProxy extends CrudClassProxy<IOccupationDraftBlockRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const occupationDraftBlockCrudClassProxy = new OccupationDraftBlockCrudClassProxy(OccupationDraftBlockCrud);
registerCrudClassProxy(occupationDraftBlockCrudClassProxy);
