import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewKapClassVisitRecord {
    /**
     * Azonosító
     *
     * Azonosító (pótkulcs)
     */
    id?: number;
    /**
     * Rekord létrehozás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel létrehozásakor.
     */
    creation_time?: string /*timestamp?*/;
    /**
     * Ki hozta létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozó felhasználóra hivatkozik.
     */
    creation_user_id?: number;
    /**
     * Melyik session-ből jött létre
     *
     * Trigger állítja be automatikusan a tétel létrehozásakor, a létrehozáshoz használt munkamenetre hivatkozik.
     */
    creation_session_id?: number|null;
    /**
     * Utolsó módosítás ideje
     *
     * Trigger állítja be automatikusan a rendszeridőre a tétel módosításakor.
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Ki módosította utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosító felhasználóra hivatkozik.
     */
    modification_user_id?: number|null;
    /**
     * Melyik session-ből módosították utoljára
     *
     * Trigger állítja be automatikusan a tétel módosításakor, a módosításhoz használt munkamenetre hivatkozik.
     */
    modification_session_id?: number|null;
    /**
     * Aktív
     *
     * Logikai törléshez használt mező.
     */
    is_active?: boolean;
    /**
     * KIP/Komplex intézmény látogatás
     */
    dfht_visit_id?: number;
    /**
     * Óraszám
     */
    lesson_no?: number /*int4*/|null;
    /**
     * Kezdő időpont
     */
    start_date?: string /*timestamp?*/;
    /**
     * Befejező időpont
     */
    end_date?: string /*timestamp?*/;
    /**
     * Intézmény feladat ellátási hely
     */
    institute_site_id?: number|null;
    /**
     * Terem szám
     */
    room_no?: string|null;
    /**
     * Óra látogatás típusa
     */
    class_visit_type_id?: number;
    /**
     * KIP/Komplex óra látogatás gyökér könyvtára.
     */
    oo_folder_id?: number;
    /**
     * Óratartó pedagógus
     */
    teacher_id?: number;
    /**
     * Témakör
     */
    topic?: string|null;
    /**
     * Osztály
     *
     * Az osztály neve, szövegesen. Pl. '2/a'
     */
    class_name?: string|null;
    /**
     * Tantárgy
     */
    subject_id?: number|null;
    /**
     * DFHT (alap és komplex) óraterv
     */
    lesson_draft_id?: number|null;
    /**
     * DFHT KIP óraterv
     */
    kip_draft_id?: number|null;
    /**
     * Feladatellátási hely név
     *
     * Feladatellátási hely név
     */
    institute_site_name?: string|null;
    /**
     * Bevezetés dátuma
     *
     * Bevezetés dátuma
     */
    kap_introduction_date?: string /*date?*/|null;
    /**
     * Város azonosító
     *
     * Város azonosító
     */
    city_id?: number|null;
    /**
     * Feladatellátási hely város név
     *
     * Feladatellátási hely város név
     */
    city_name?: string|null;
    /**
     * Feladatellátási hely cím
     *
     * Feladatellátási hely cím
     */
    institute_site_address?: string|null;
    /**
     * Feladatellátási hely külföldi cím
     *
     * Feladatellátási hely külföldi cím
     */
    institute_site_foreign_address?: string|null;
    /**
     * Feladatellátási hely  külföldi
     *
     * Feladatellátási hely  külföldi
     */
    institute_site_is_foreign?: boolean|null;
    /**
     * Feladatellátási hely azonosító
     *
     * Feladatellátási hely azonosító
     */
    institute_site_identifier?: string|null;
    /**
     * Intézmény OM azonosító
     *
     * Intézmény OM azonosító
     */
    institute_om_identifier?: string|null;
    /**
     * Intézmény név
     *
     * Intézmény név
     */
    institute_name?: string|null;
    /**
     * Óralátogatás típusa
     *
     * Óralátogatás típusa
     */
    class_visit_type_title?: string|null;
    /**
     * Óraadó tanár bejelentkezési neve
     *
     * Óraadó tanár bejelentkezési neve
     */
    teacher_login_name?: string|null;
    /**
     * Óraadó tanár E-mail címe
     *
     * Óraadó tanár E-mail címe
     */
    teacher_email?: string|null;
    /**
     * Óraadó tanár  feljes neve
     *
     * Óraadó tanár  feljes neve
     */
    teacher_full_name?: string|null;
    /**
     * Tantárgy neve
     *
     * Tantárgy neve
     */
    subject_name?: string|null;
    /**
     * Létrehozta
     *
     * Felhasználó, aki létrehozta
     */
    creator?: string|null;
    /**
     * Módosító
     *
     * Felhasználó, aki utoljára módosította
     */
    modifier?: string|null;
}

/**
 *  kap.view_kap_class_visit - KIP/Komplex óra látogatás
 *
 *  Idősoros táblázat, ami az összes tétel összes korábbi verzióját eltárolja.
 */

export default class ViewKapClassVisit extends View<IViewKapClassVisitRecord> {
    public static VIEW_INFO_ID = 2019091001;

    public static getSchemaNameForClass(): string { return 'kap'; }
    public static getViewNameForClass(): string { return 'view_kap_class_visit'; }
    public static getTableNameForClass(): string|null { return 'class_visit'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewKapClassVisit>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewKapClassVisitRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewKapClassVisitClassProxy extends ViewClassProxy<IViewKapClassVisitRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewKapClassVisitClassProxy = new ViewKapClassVisitClassProxy(ViewKapClassVisit);
registerViewClassProxy(viewKapClassVisitClassProxy);

