import * as React from 'react';
import { BubbleLoader } from 'react-css-loaders';

import './OoFolderPermTable.css';


import OoPermtypeCrud, { IOoPermtypeRecord } from '@framework/crud/media/OoPermtypeCrud';
import ViewMediaOoFolderPerm, { IViewMediaOoFolderPermRecord } from '@src/framework/view/media/ViewMediaOoFolderPerm';

import { app } from '@src/index';
import LookupEdit from '@src/framework/forms/lookupedit';
import SecPermOwnerCrud from '@src/framework/crud/sys/SecPermOwnerCrud';
import VfsPermissionAPI, { IPermsForSecPermOwner } from '@src/component/vfs/vfs_permisson_api';
import { __ } from '@src/translation';


interface ISecPermOwner {
    sec_perm_owner_id: number,
    title: string,
    oo_permtype_ids: number[],
    perms: { [oo_permtype_id: number]: IViewMediaOoFolderPermRecord }
}

type PermsMatrix = { [sec_perm_owner_id: number]: ISecPermOwner };

interface IOoPermTableProps {
    ooFolderId: number;
    onClose: () => void;
}

interface IOoPermTableState {
    perms?: IViewMediaOoFolderPermRecord[],
    loading: boolean;
    changed: number[];
    owner_id: number | null;
    perms_matrix: { [sec_perm_owner_id: number]: ISecPermOwner }
}



let permtypes: IOoPermtypeRecord[];
const fetchPermTypes = async (): Promise<IOoPermtypeRecord[]> => {
    if (!permtypes) {
        try {
            permtypes = await OoPermtypeCrud.list({ order_by: 'id' });
        } catch (error) {
            Promise.reject(error);
        }
    }
    return permtypes;
}

export default class OoFolderPermTable extends React.Component<IOoPermTableProps, IOoPermTableState> {
    constructor(props: IOoPermTableProps) {
        super(props);
        this.state = { changed: [], loading: true, owner_id: null, perms_matrix: {} };
    }


    componentDidMount() {
        this.asyncReload();
    }

    private buildPermsMatrix = (perms: IViewMediaOoFolderPermRecord[]): PermsMatrix => {
        // Group permissions by their owner            
        let result: PermsMatrix = {};
        perms.forEach((perm) => {
            const owner_id = perm.sec_perm_owner_id!;
            if (!result[owner_id]) {
                result[owner_id] = {
                    sec_perm_owner_id: owner_id,
                    title: perm.user_title || perm.group_title!,
                    oo_permtype_ids: [],
                    perms: {}
                }                
            }
            result[owner_id].oo_permtype_ids.push(perm.oo_permtype_id!);
            result[owner_id].perms[perm.oo_permtype_id!] = perm;
        });
        return result;
    }

    private asyncReload = async () => {
        try {
            const perms = await ViewMediaOoFolderPerm.list({
                filter: { oo_folder_id: this.props.ooFolderId }
            });
            await fetchPermTypes(); // Csak hogy biztosan tudjuk renderelni.            
            this.setState({ perms, perms_matrix: this.buildPermsMatrix(perms), loading: false, changed: [] });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private getSecPermOwnerTitle = async (secPermOwnerId: number): Promise<string> => {
        return (await SecPermOwnerCrud.asText(secPermOwnerId, false)).text;
    }

    private onOwnerIdChange = async (newOwnerId: any) => {
        try {
            if ((typeof newOwnerId === 'string'))
                if (newOwnerId.trim() === '')
                    newOwnerId = '';
            if (newOwnerId === '')
                newOwnerId = null;
            let newState = { owner_id: newOwnerId };
            if (!this.state.perms_matrix[newOwnerId]) {
                this.state.perms_matrix[newOwnerId] = {
                    sec_perm_owner_id: newOwnerId,
                    title: await this.getSecPermOwnerTitle(newOwnerId),
                    oo_permtype_ids: [],
                    perms: {}
                }
                newState["perms_matrix"] = this.state.perms_matrix;
            }
            this.setState(newState);
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private includeChanged = (sec_perm_owner_id: number): number[] => {
        let changed = this.state.changed;
        if (changed.indexOf(sec_perm_owner_id) < 0) {
            changed.push(sec_perm_owner_id);
        }
        return changed;
    }

    private onTogglePerm = async (sec_perm_owner_id: number, permtype_id: number) => {
        try {
            if (!this.state.perms_matrix[sec_perm_owner_id]) {
                this.state.perms_matrix[sec_perm_owner_id] = {
                    sec_perm_owner_id: sec_perm_owner_id,
                    title: await this.getSecPermOwnerTitle(sec_perm_owner_id),
                    oo_permtype_ids: [],
                    perms : {}
                }
            }
            let perms = this.state.perms_matrix[sec_perm_owner_id].oo_permtype_ids;
            const idx = perms.indexOf(permtype_id);
            if (idx >= 0) {
                perms.splice(idx, 1);
            } else {
                perms.push(permtype_id);
            }
            this.setState({
                perms_matrix: this.state.perms_matrix,
                changed: this.includeChanged(sec_perm_owner_id)
            });
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    private toggleAllPerm = (sec_perm_owner_id: number) => {
        if (this.state.perms_matrix[sec_perm_owner_id]) {
            let spo: ISecPermOwner = this.state.perms_matrix[sec_perm_owner_id];
            if (spo.oo_permtype_ids.length < permtypes.length) {
                // Nincs mindegyik pipálva
                spo.oo_permtype_ids = permtypes.map(rec => rec.id!);
            } else {
                spo.oo_permtype_ids = [];
            }
            this.setState({
                perms_matrix: this.state.perms_matrix,
                changed: this.includeChanged(sec_perm_owner_id)
            });
        }
    }

    private onCloseDialog = async () => {
        this.props.onClose();
    }

    private onSave = async () => {
        try {
            this.setState({ loading: true });
            // Ez csak arra kell, hogy title értékeket ne küldje el.
            // Azt fölösleges lenne....
            let perms: IPermsForSecPermOwner[] = [];
            console.log("this.state.perms_matrix", this.state.perms_matrix);
            Object.values(this.state.perms_matrix).map((item: ISecPermOwner) => {
                perms.push({
                    sec_perm_owner_id: item.sec_perm_owner_id,
                    oo_permtype_ids: item.oo_permtype_ids
                });
            });

            await VfsPermissionAPI.setFolderPerms(this.props.ooFolderId, perms);
            this.asyncReload();
        } catch (error) {
            app.showErrorFromJsonResult(error);
        }
    }

    render() {
        if (!permtypes || this.state.loading) {
            return <BubbleLoader />;
        } else {
            let perm_headers: JSX.Element[] = [
            ];
            permtypes!.forEach((permtype) => {
                let className = "oo-folder-perm-table-rotate ";
                if (permtype.id! < 200) {
                    className += "oo-folder-permtype-folder"
                } else if (permtype.id! < 300) {
                    className += "oo-folder-permtype-contained"
                } else {
                    className += "oo-folder-permtype-file"
                }
                perm_headers.push(
                    <th key={permtype.id!}
                        className={className}
                        title={permtype.description || ''}
                    >
                        {permtype.title!}
                    </th>
                )
            })
            // Sort owners by name
            let owners: ISecPermOwner[] = [];
            Object.keys(this.state.perms_matrix).forEach((sec_perm_owner_id: string) => {
                owners.push(this.state.perms_matrix[sec_perm_owner_id]);
            });
            owners.sort((a: ISecPermOwner, b: ISecPermOwner) => {
                if (a.title < b.title) {
                    return -1;
                } else if (a.title > b.title) {
                    return 1;
                } else {
                    return 0;
                }
            })
            let owner_rows: JSX.Element[] = [];
            owners.forEach((owner: ISecPermOwner) => {
                let boxes: JSX.Element[] = [];

                permtypes!.forEach((permtype) => {
                    let className = "oo-folder-permtype-checkbox-cell ";
                    if (permtype.id! < 200) {
                        className += "oo-folder-permtype-folder"
                    } else if (permtype.id! < 300) {
                        className += "oo-folder-permtype-contained"
                    } else {
                        className += "oo-folder-permtype-file"
                    }
                    let title : string = ''; // permtype.description || '';
                    let checked : boolean = false;
                    if (owner.oo_permtype_ids.indexOf(permtype.id!) >= 0) {
                        checked = true;
                        const permRec = owner.perms[permtype.id!];
                        if (permRec) {
                            title = 
                                __('Kiosztotta: {creator} @{time} - {title}', {creator: permRec.creator, time: permRec.creation_time, title: title});
                        } else {
                            title = __('<Még nincs mentve>');
                        }
                    }
                    boxes.push(
                        <td key={permtype.id!}
                            className={className}
                            title={title}
                        >
                            <input type="checkbox"
                                checked={checked}
                                onClick={() => { this.onTogglePerm(owner.sec_perm_owner_id, permtype.id!) }}
                            />
                        </td>
                    )
                })
                let ownerClassName = "";
                let changedIcon =  null;
                if (this.state.changed.indexOf(owner.sec_perm_owner_id)>=0) {
                    ownerClassName = "oo-folder-permtype-owner-changed";
                    changedIcon = <i className="fa fa-exclamation oo-folder-permtype-owner-changed-icon" title={__("Megváltozott")}/>;
                }
                
                owner_rows.push(
                    <tr>
                        <td className={ownerClassName}>{changedIcon}{owner.title}</td>
                        <td><i className="fa fa-check-square oo-folder-permtype-check-all-perms"
                            title={__("Összes jog megadása/eltávolítása ehhez a tulajdonoshoz")}
                            onClick={() => this.toggleAllPerm(owner.sec_perm_owner_id)}
                        /></td>
                        {boxes}
                    </tr>
                );
            })


            return <div className="row expanded">
                <div className="large-4 small-12 column">
                    Új jogosult:
                </div>
                <div className="large-8 small-12 column">
                    <span>
                        <LookupEdit
                            key={"ownerId" + (this.state.owner_id || 'null')}
                            name="ownerId"
                            fk_table_info_id={SecPermOwnerCrud.TABLE_INFO_ID}
                            clearable={true}
                            value={this.state.owner_id!}
                            onChange={(newValue) => this.onOwnerIdChange(newValue)}
                        />
                    </span>
                </div>
                <div className="large-12 column">
                    <table>
                        <thead>
                            <tr className="oo-folder-perm-table-head-row">
                                <th className="oo-folder-permtype-owner">{__("Jogosult")}</th>
                                <th className="oo-folder-permtype-owner" />
                                {perm_headers}
                            </tr>
                        </thead>
                        <tbody>
                            {owner_rows}
                        </tbody>
                    </table>
                </div>
                <div className="large-6 small-12 columns">
                    <a onClick={() => this.onCloseDialog()} className="button alert"><i className="fa fa-times" /> &nbsp;{__("Mégse")}</a>
                </div>
                <div className="large-6 small-12 columns text-right">
                    <a onClick={() => { if (this.state.changed.length) { this.onSave(); } }}
                        className={"button success " + (this.state.changed.length ? "" : "disabled")}>
                        <i className="fa fa-save" /> &nbsp;{__("Mentés")}</a>
                </div>
            </div>
        }
    }
}