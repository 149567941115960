import { Server } from '../../server/Server';
import View , { registerViewClassProxy, ViewClassProxy } from '../View';
import { IListParams } from '@framework/crud/Crud';

export interface IViewTanarXxTpwdRecord {
    /**
     * Felhasználó azonosító
     *
     * Felhasználó azonosító
     */
    id?: number;
    /**
     * Bejelentkezési név
     *
     * Bejelentkezési név
     */
    login_name?: string|null;
    /**
     * Utoljára módosítva
     *
     * Utoljára módosítva
     */
    modification_time?: string /*timestamp?*/|null;
    /**
     * Jelszó
     *
     * Jelszó
     */
    pwd?: string|null;
}

/**
 *  sys.view_tanar_xx_tpwd - Test Tanár jelszó
 *
 *  Naponta változó teszt tanár jelszavak
 */

export default class ViewTanarXxTpwd extends View<IViewTanarXxTpwdRecord> {
    public static VIEW_INFO_ID = 2018092701;

    public static getSchemaNameForClass(): string { return 'sys'; }
    public static getViewNameForClass(): string { return 'view_tanar_xx_tpwd'; }
    public static getTableNameForClass(): string|null { return 'null'; }

    public static load: (id: number, aServer ?: Server) => Promise<ViewTanarXxTpwd>;
    public static list: (params: IListParams, aServer ?: Server) => Promise<IViewTanarXxTpwdRecord[]>;
}

/**
 * Class for a class proxy object that can emulate virtual class methods.
 */
export class ViewTanarXxTpwdClassProxy extends ViewClassProxy<IViewTanarXxTpwdRecord> {
}
/**
 * A singleton that emulates virtual classmethods of the corresponding Crud class.
 */
export const viewTanarXxTpwdClassProxy = new ViewTanarXxTpwdClassProxy(ViewTanarXxTpwd);
registerViewClassProxy(viewTanarXxTpwdClassProxy);

