import * as React from 'react';

import { app } from '@src/index';
import ViewSharedWithMe, { IViewSharedWithMeRecord } from '@framework/view/usr/ViewSharedWithMe';
import { PATH_SHARED_CONTENT_WITH_ME } from '@src/Routes';
import MemberCard from '@src/framework/forms/MemberCard';
import { SHARE_MEGOSZTAS_ID } from '@src/Const';
import { ListItemImage, List, ListItem, ListItemText } from '../ui/List';
import { Link } from 'react-router-dom';
import { formatDate } from '@src/Util';
import { getContentDetailsList, ContentDetails } from './ContentUtils';
import { BubbleLoader } from 'react-css-loaders';
import { __ } from '@src/translation';
import ViewInstituteGroupMember from '@src/framework/view/sys/ViewInstituteGroupMember';
import { me } from '@src/framework/server/Auth';

interface ISharedWithMePageProps {

}

interface ISharedWithMePageState {
    loading: boolean;
    items: IViewSharedWithMeRecord[];
    details: (ContentDetails | undefined)[];
}

export default class SharedWithMePage extends React.Component<ISharedWithMePageProps, ISharedWithMePageState> {
    constructor(props: ISharedWithMePageProps) {
        super(props);
        this.state = { items: [], details: [], loading: true };
    }

    componentDidMount() {
        this.reloadAsync();
    }

    async reloadAsync() {
        if (!me) return;
        this.setState({ loading: true });
        try {
            const groups = await ViewInstituteGroupMember.list({
                filter: {
                    sec_user_id: me!.id,
                    is_active: true,
                    is_admin: true
                }
            });
            const groupIds = groups.map((el) => el.institute_group_id!);
            // not showing the items that are shared in a classroom, where i'm a teacher
            const items = await ViewSharedWithMe.list({
                filter: {
                    is_active: true,
                    "$or": { shared_from_institute_group_id: null, "$not": { shared_from_institute_group_id: groupIds } }
                },
                limit: 100, order_by: [{ name: "creation_time", desc: true }]
            });

            const details = await getContentDetailsList(items.map(i => ({ tableOrViewId: i.table_info_id!, recordId: i.rec_id! })));
            this.setState({ items, details, loading: false });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />;
        }

        return <>
            <h4>{__("Velem megosztott tartalmak")}:</h4>
            {
                this.state.items.length === 0 &&
                <div className="callout">
                    <h5>{__("Jelenleg nincsenek veled megosztott tartalmak.")}</h5>
                    <p>
                        {__("Megosztani a saját feladat / saját feladatsor / saját fájl listákban lehet a megosztás")} (<i className="fa fa-share-alt" />) {__("gombra kattintva.")}
                    </p>
                </div>
            }
            <List>
                {
                    this.state.items.map((item, index) => {

                        var url = (item.share_mode_id == SHARE_MEGOSZTAS_ID) ? (this.state.details[index] && this.state.details[index]!.viewUrl) : PATH_SHARED_CONTENT_WITH_ME + "/" + item.id;
                        var name = (item.share_mode_id == SHARE_MEGOSZTAS_ID) ? __("Megosztó") : __("Kiosztó");
                        var title = "" + ((this.state.details[index] && this.state.details[index]!.type ? (this.state.details[index]!.type == "Nyílt végű feladat" ? "Szöveges feladat " : (this.state.details[index]!.type + " ")) : "") + (this.state.details[index] && this.state.details[index]!.type ? ("("+ item.share_mode_title!.toLowerCase()+")" )  : item.share_mode_title!) + ": ");
                        var exeTitle = item.title;
                        return <ListItem key={item.id}>
                            <ListItemImage src={this.state.details[index] && this.state.details[index]!.thumbnailUrl} />

                            <ListItemText>
                                <Link to={url || ""} title={title + item.title}>
                                         {title}<strong>{exeTitle}</strong>
                                </Link>
                                <div>{this.state.details[index] && this.state.details[index]!.displayName}</div>
                                {name}: <MemberCard {...item} memberFieldNamePrefix="shared_by_" />
                            </ListItemText>

                            <ListItemText>
                                <label className="label secondary" title={__("Kiosztás ideje")}>
                                    {__("Megosztás ideje")}: {formatDate(item.start_date)} {item.end_date && " - " + formatDate(item.end_date)}
                                </label>
                                <br />
                                {item.due_date && <label className="label warning">{__("Leadás határideje")}: {formatDate(item.due_date)}</label>}
                            </ListItemText>

                        </ListItem>
                    })
                }
            </List>
        </>

    }


}

