import * as React from 'react';
import { Link } from 'react-router-dom';

import FavouriteCrud from '@framework/crud/usr/FavouriteCrud';
import { app } from '@src/index';
import ViewUsrFavourite, { IViewUsrFavouriteRecord } from '@framework/view/usr/ViewUsrFavourite';
import { getFavouriteDetails, ContentDetails, ContentType } from '@src/component/filemanager/ContentUtils';
import { me } from '@src/framework/server/Auth';
import { List, ListItem, ListItemImage, ListItemText, ListItemMenu } from '../ui/List';
import { BubbleLoader } from 'react-css-loaders';
import { confirmDialog, Dialog, DialogContent } from '../Dialog';
import TypeIcon from "@src/component/ui/TypeIcon";
import { __ } from '@src/translation';
import UsrLikePlugin from '../usr/usr_like_plugin';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import * as PublicViewExerciseSection from '@framework/view/exc_pub/ViewExerciseSection';
import { PATH_PUBLISHED_BOOK } from '@src/Routes';
import ViewDocSectionFileRef, { IViewDocSectionFileRefRecord } from '@src/framework/view/doc/ViewDocSectionFileRef';
import ViewExerciseSeriesSection from '@src/framework/view/exc/ViewExerciseSeriesSection';
import * as PublicViewExerciseSeriesItem from '@framework/view/exc_pub/ViewExerciseSeriesItem';

type FavouritesProps = {
    limit?: number;
    hideHeader?: boolean;
}

type FavouritesState = {
    loading: boolean;
    favourites: IViewUsrFavouriteRecord[];
}

export class FavoriteList extends React.Component<FavouritesProps, FavouritesState> {
    constructor(props: FavouritesProps) {
        super(props);
        this.state = {
            loading: true,
            favourites: []
        };
    }

    componentDidMount() {
        this.asyncReload();
    }

    private asyncReload = async () => {
        if (me && me.id) {
            try {
                const favourites = await ViewUsrFavourite.list({
                    filter: {
                        sec_user_id: me.id
                    },
                    order_by: [
                        { name: "id", desc: true }
                    ],
                    limit: this.props.limit ? Number(this.props.limit) : 20
                });

                this.setState({
                    favourites,
                    loading: false
                });
            } catch (error) {
                app.showErrorFromJsonResult(error);
            }
        }
    }

    refresh() {
        this.asyncReload();
    }

    render() {
        if (this.state.loading) {
            return <BubbleLoader />
        }

        return <>
            {!this.props.hideHeader && <h4>{__("Kedvenceim")}:</h4>}
            {
                this.state.favourites.length === 0 &&
                <div className="callout">
                    <h5>{__("Jelenleg nincsenek kedvenceid.")}</h5>
                    <p>
                        {__("Kedvencekhez elemeket hozzáadni a")} (<i className="far fa-star" />) {__("gombra kattintva lehet a könyvekben, feladattárban, médiatárban.")}
                    </p>
                </div>
            }
            <List>
                {
                    this.state.favourites.map((item, i) => {
                        return <ContentFavouriteListItem key={i} record={item} refresh={() => this.refresh()} />
                    })

                }
            </List>
        </>
    }

}


type ContentFavouriteListItemProps = {
    record: IViewUsrFavouriteRecord;
    refresh?: () => void
}

type ContentFavouriteListItemState = {
    sections: PublicViewExerciseSection.IViewExerciseSectionRecord[] | IViewDocSectionFileRefRecord[];
    dialogOpen: boolean;
    dialogContent: JSX.Element[];
}

export class ContentFavouriteListItem extends React.Component<ContentFavouriteListItemProps, ContentFavouriteListItemState> {

    constructor(props: ContentFavouriteListItemProps) {
        super(props);

        this.state = {
            sections: [],
            dialogOpen: false,
            dialogContent: []
        }
        this.getSections(this.props.record.rec_id!)
    }

    async remove() {
        if (!await confirmDialog(__("Eltávolítás"), __("Biztosan eltávolítja a kedvencekből?"), __("Eltávolítás"))) {
            return;
        }

        try {
            await new FavouriteCrud({ id: this.props.record.id }).del()
            app.showSuccess(__("Kedvenceim közül törölve"), "");
            if (this.props.refresh) this.props.refresh();
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    setDialogContent(sections: any[], contentType: ContentType) {
        let dialogContent: JSX.Element[] = [];
        sections.length > 0 ? sections.map(s => {
            let url = PATH_PUBLISHED_BOOK + "/" + s.book_uri_segment + "/" + s.lesson_uri_segment + "#section-" + s.section_id;
            dialogContent.push(<div style={{ marginBottom: "-1px", paddingTop: "15px", paddingBottom: "15px", borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray" }}>
                <a target="_blank" href={url}>{s.book_name + "/" + s.chapter_name + "/" + s.lesson_name}</a>
            </div>)
        }) : dialogContent.push(<>{"Ez a " + contentType.toLowerCase() + " nem található meg tankönyvben"}</>);

        this.setState({ dialogContent: dialogContent });
    }

    async getSections(id: number) {
        try {
            const details = getFavouriteDetails(this.props.record);

            let sections: any[] = []; //HA ez itt nem any, sajnos nem működik a map-elés és kódot kellene sokszorosítani.
            if (details.type == "Feladat") {
                sections = await PublicViewExerciseSection.default.list({ filter: { id: id, library_id: LIBRARY_OFI_OFFICIAL_ID } });
                let series: PublicViewExerciseSeriesItem.IViewExerciseSeriesItemRecord[] = await PublicViewExerciseSeriesItem.default.list({ filter: { exercise_id: id } });

                if (series.length > 0) {
                    series.map(async r => {
                        let seriessections = await ViewExerciseSeriesSection.list({ filter: { id: r.exercise_series_id, library_id: LIBRARY_OFI_OFFICIAL_ID } });
                        seriessections.map(e => {
                            sections.push(e);
                        })
                        this.setDialogContent(sections, details.type);
                    });
                } else {
                    this.setDialogContent(sections, details.type);
                }

            } else if (details.type == "Feladatsor") {
                sections = await ViewExerciseSeriesSection.list({ filter: { id: id, library_id: LIBRARY_OFI_OFFICIAL_ID } });
                this.setDialogContent(sections, details.type);
            } else {
                sections = await ViewDocSectionFileRef.list({ filter: { oo_file_id: id } });
                this.setDialogContent(sections, details.type);
            }
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {
        this.getSections(this.props.record.rec_id!);
    }

    render() {
        const details = getFavouriteDetails(this.props.record);
        return (
            <>
                <ListItem>
                    <ListItemImage src={details && details.thumbnailUrl} />
                    <ListItemText>
                        <Link to={details.viewUrl}>
                            <span className="title">
                                <TypeIcon contentType={details.type} />
                                {details.displayName}
                            </span>
                        </Link>
                        <small className="desc">
                            {details.created}
                        </small>
                        <Link to="#" onClick={() => this.setState({ dialogOpen: true })}>Előfordulási helyek</Link>
                    </ListItemText>
                    <ListItemMenu mainItems={[
                        {
                            icon: <i className="fa fa-fw fa-trash" />,
                            type: "alert",
                            onClick: this.remove.bind(this)
                        }
                    ]}
                    >
                        {this.props.record.rec_id ?
                            <UsrLikePlugin
                                tableInfoId={this.props.record.table_info_id!}
                                recId={this.props.record.rec_id!}
                            />
                            : ""}
                    </ListItemMenu>
                </ListItem>
                <Dialog title="Előfordulási helyek" open={this.state.dialogOpen} onClose={() => this.setState({ dialogOpen: false })}>
                    <DialogContent>
                        {this.state.dialogContent.map(c => {
                            return c;
                        })}
                    </DialogContent>

                </Dialog>
            </>
        );
    }
}
