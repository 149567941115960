import {htmlToElement, createElement, htmlEscape, subtractInnerElHeight, View, ViewProps,
  ScrollComponent, DateMarker, addDays, startOfDay, createFormatter, DateRange, intersectRanges,
  DateProfileGenerator, DateProfile, buildGotoAnchorHtml, ComponentContext, ViewSpec, EventUiHash,
  EventRenderRange, sliceEventStore, EventStore, memoize, MemoizedRendering,
  memoizeRendering, Seg, createPlugin} from '@fullcalendar/core'
import VisitListEvent from './VisitListEvent'
import {OpenDateRange} from '@fullcalendar/core/datelib/date-range';
import './VisitListView.css' 

export class VisitListView extends View {
  scroller: ScrollComponent;
  contentEl: HTMLElement;
  dayDates: DateMarker[];
  private computeDateVars = memoize(computeDateVars);
  private eventStoreToSegs = memoize(this._eventStoreToSegs);
  private renderContent: MemoizedRendering<[Seg[]]>;
  constructor(context: ComponentContext, viewSpec: ViewSpec, dateProfileGenerator: DateProfileGenerator, parentEl: HTMLElement) {
    super(context, viewSpec, dateProfileGenerator, parentEl);
    let eventRenderer = this.eventRenderer = new VisitListEvent(this);
    this.renderContent = memoizeRendering(eventRenderer.renderSegs.bind(eventRenderer), eventRenderer.unrender.bind(eventRenderer));
    this.el.classList.add('fc-visit-list-view');
    let visitListViewClassNames = (this.theme.getClass('visitListView') || '').split(' ');
    for (let visitListViewClassName of visitListViewClassNames) {
      if (visitListViewClassName) this.el.classList.add(visitListViewClassName);
    }
    this.scroller = new ScrollComponent('hidden', 'auto');
    this.el.appendChild(this.scroller.el);
    this.contentEl = this.scroller.el;
    context.calendar.registerInteractiveComponent(this, {el: this.el});
  }

  render(props: ViewProps) {
    let { dayDates, dayRanges } = this.computeDateVars(props.dateProfile);
    this.dayDates = dayDates;
    this.renderContent(this.eventStoreToSegs(props.eventStore, props.eventUiBases, dayRanges));
  }

  destroy() {
    super.destroy();
    this.renderContent.unrender();
    this.scroller.destroy();
    this.calendar.unregisterInteractiveComponent(this);
  }

  updateSize(isResize: boolean, viewHeight: number, isAuto: boolean) {
    super.updateSize(isResize, viewHeight, isAuto);
    this.eventRenderer.computeSizes(isResize);
    this.eventRenderer.assignSizes(isResize);
    this.scroller.clear();
    if (!isAuto) this.scroller.setHeight(this.computeScrollerHeight(viewHeight));
  }

  computeScrollerHeight(viewHeight: number) {
    return viewHeight - subtractInnerElHeight(this.el, this.scroller.el);
  }

  _eventStoreToSegs(eventStore: EventStore, eventUiBases: EventUiHash, dayRanges: DateRange[]): Seg[] {
    return this.eventRangesToSegs(sliceEventStore(eventStore, eventUiBases,
        (this.props !== null ? this.props.dateProfile.activeRange : dayRanges[0]),
        this.nextDayThreshold).fg, dayRanges);
  }

  eventRangesToSegs(eventRanges: EventRenderRange[], dayRanges: DateRange[]) {
    let segs = [];
    for (let eventRange of eventRanges) {
      segs.push(...this.eventRangeToSegs(eventRange, dayRanges));
    }
    return segs;
  }

  eventRangeToSegs(eventRange: EventRenderRange, dayRanges: DateRange[]) {
    let { dateEnv, nextDayThreshold } = this;
    let range: DateRange = eventRange.range;
    let allDay: boolean = eventRange.def.allDay;
    let dayIndex: number, segRange: OpenDateRange;
    let seg: Seg, segs: Seg[] = [];
    for (dayIndex = 0; dayIndex < dayRanges.length; dayIndex++) {
      segRange = intersectRanges(range, dayRanges[dayIndex]);
      if (segRange) {
        seg = {component: this, eventRange: eventRange, start: segRange.start, end: segRange.end,
          isStart: eventRange.isStart && segRange.start !== null && segRange.start.valueOf() === range.start.valueOf(),
          isEnd: eventRange.isEnd && segRange.end!==null && segRange.end.valueOf() === range.end.valueOf(),
          dayIndex: dayIndex
        };
        segs.push(seg);
        if (!seg.isEnd && !allDay && dayIndex + 1 < dayRanges.length && range.end < dateEnv.add(dayRanges[dayIndex + 1].start, nextDayThreshold)) {
          seg.end = range.end;
          seg.isEnd = true;
          break;
        }
      }
    }
    return segs;
  }

  renderEmptyMessage() {
    this.contentEl.innerHTML = '<div class="fc-visit-list-empty">' + htmlEscape(this.opt('noEventsMessage')) + '</div>';
  }

  renderSegList(allSegs: Seg[]) {
    let segsByDay: [Seg[]] = this.groupSegsByDay(allSegs);
    let dayIndex, i: number, daySegs: Seg[], seg: Seg, node: Node | undefined;
    let tableEl = htmlToElement('<table class="fc-visit-list-table ' + this.calendar.theme.getClass('tableList') + '"><tbody></tbody></table>');
    let tbodyEl = tableEl.querySelector('tbody');
    for (dayIndex = 0; dayIndex < segsByDay.length; dayIndex++) {
      daySegs = segsByDay[dayIndex];
      if (daySegs) {
        daySegs = this.eventRenderer.sortEventSegs(daySegs);
        if (tbodyEl !== null && daySegs.length>0) tbodyEl.appendChild(this.buildDayHeaderRow(this.dayDates[dayIndex]));
        for (i = 0; i < daySegs.length; i++) {
          seg=daySegs[i];
          node=seg.el;
          if (tbodyEl !== null && node !== undefined) tbodyEl.appendChild(node);
        }
      }
    }
    this.contentEl.innerHTML = '';
    this.contentEl.appendChild(tableEl);
  }

  groupSegsByDay(segs: Seg[]) {
    let segsByDay: [Seg[]] = [[]], i: number, seg: Seg;
    for (i = 0; i < segs.length; i++) {
      seg = segs[i];
      (segsByDay[seg.dayIndex] || (segsByDay[seg.dayIndex] = [])).push(seg);
    }
    return segsByDay;
  }

  buildDayHeaderRow(dayDate: any) {
    let { dateEnv } = this;
    let dateFormat = createFormatter({month: 'long', day: 'numeric', year: 'numeric'});
    let dayFormat = createFormatter({weekday: 'long'});
    return createElement('tr', {className: 'fc-visit-list-heading', 'data-date': dateEnv.formatIso(dayDate, { omitTime: true })}, 
      '<td class="fc-visit-list-heading-col ' + (this.calendar.theme.getClass('tableListHeading') || this.calendar.theme.getClass('widgetHeader')) + '" colspan="3">' + 
        '<div class="fc-visit-list-heading-main">' +
            buildGotoAnchorHtml(this, dayDate, {'class': 'fc-visit-list-heading-link'}, htmlEscape(dateEnv.format(dayDate, dateFormat)+' - '+dateEnv.format(dayDate, dayFormat))) +
        '</div>' +
      '</td>') as HTMLTableRowElement;
  }
}

VisitListView.prototype.fgSegSelector = '.fc-visit-list-item';

function computeDateVars(dateProfile: DateProfile) {
  let dayStart = startOfDay(dateProfile.renderRange.start);
  let viewEnd = dateProfile.renderRange.end;
  let dayDates: DateMarker[] = [];
  let dayRanges: DateRange[] = [];
  while (dayStart < viewEnd) {
    dayDates.push(dayStart);
    dayRanges.push({start: dayStart, end: addDays(dayStart, 1)});
    dayStart = addDays(dayStart, 1);
  }
  return {dayDates, dayRanges};
}

export default createPlugin({
  views: {
    visitList: {class: VisitListView, buttonTextKey: 'visitlist'},
    visitListDay: {type: 'visitList', buttonTextKey: 'visitlistDay', duration: {days: 1}},
    visitlistWeek: {type: 'visitList', buttonTextKey: 'visitlistWeek', duration: {weeks: 1}},
    visitlistMonth: {type: 'visitList', buttonTextKey: 'visitlistMonth', duration: {month: 1}},
    visitlistYear: {type: 'visitList', buttonTextKey: 'visitlistYear', duration: {year: 1}}
  }
})

