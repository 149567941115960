import * as React from 'react';

import { match } from 'react-router';
import { History } from 'history';
import { BookPage, ViewMode, RenderSectionPage } from '@src/component/book/viewer/BookPage';
import { BookPresentation } from '@src/component/book/viewer/BookPresentation2';
import { getBookStructureForUri, BookLessonContent, getBookLessoncontent, BookStructure, getBookStructureForId, ILessonViewSummaryRecord, getLessonViewSummary } from '@src/server/PublicServer';
import { __ } from '@src/translation';
import { config } from '@src/framework/server/Server';
import { LIBRARY_OFI_OFFICIAL_ID } from '@src/Const';
import * as queryString from 'query-string';

import { app } from '@src/index';
import { PATH_PUBLISHED_BOOK_SELECT, PATH_PUBLISHED_BOOK, getPath } from '@src/Routes';
import { me } from '@src/framework/server/Auth';
import { bookModule } from '@src/module/bookModule/bookModule';
type BookViewProps = {
    match: match<{ bookUriSegment: string, lessonUriSegment?: string, sectionId?: string }>;
    history: History;
}

type BookViewState = {
    viewMode: ViewMode;
    
    lessonData?: BookLessonContent;
    prevLessonData?: BookLessonContent;
    bookStructure?: BookStructure;
}

var cachedLessons: {[key: number]: BookLessonContent} = {};

export class BookView extends React.Component<BookViewProps, BookViewState> {

    constructor(props: any) {
        super(props);

        var viewMode = ViewMode.NORMAL;
        this.state = {
            viewMode
        };
    }

    async componentDidMount() {
        if(!this.props.match.params.lessonUriSegment && !this.props.history.location.pathname.endsWith("/"))
        {
            location.replace(this.props.history.location.pathname+"/");
        }
        else if(this.props.match.params.lessonUriSegment&& this.props.history.location.pathname.endsWith("/"))
        {
            location.replace(this.props.history.location.pathname.substring(0,this.props.history.location.pathname.length-1));
        }
         else {
            if (!await this.reloadStructureAsync()) {
                return;
            }
            await this.reloadLessonDataAsync();
        }
    }

    async componentDidUpdate(prevProps: BookViewProps) {
        
        if (this.props.match.params.bookUriSegment != prevProps.match.params.bookUriSegment) {
            if (!await this.reloadStructureAsync()) {
                return;
            }
        }

        if (this.props.match.params.bookUriSegment != prevProps.match.params.bookUriSegment
            || this.props.match.params.lessonUriSegment != prevProps.match.params.lessonUriSegment) {
            await this.reloadLessonDataAsync();
        }
    }

    async reloadLessonDataAsync() {

        try {

            if (!this.state.bookStructure) {
                throw new Error(__("Tankönyv nincs betöltve!"));
            }

            var lesson = this.getLesson();
            if (!lesson) {
                lesson = this.state.bookStructure.lessons[0];
            }
            const lessonId = lesson.lesson_id;

            var lessonData = cachedLessons[lessonId];
            if (!lessonData) {
                lessonData = await getBookLessoncontent(lessonId, this.isPublished());
                cachedLessons[lessonId] = lessonData;
            }

            let bookStructure = this.state.bookStructure;
            const lessons = bookStructure.lessons;
            var index = lessons.findIndex(x => x.lesson_uri_segment == lesson!.lesson_uri_segment);

            if(me){
                const result: ILessonViewSummaryRecord[] = (await getLessonViewSummary(me.id!, bookStructure.id!));
                for (let index = 0; index < bookStructure.lessons.length; index++) {
                    let element = result.find((el:ILessonViewSummaryRecord) => {return el._id.recordId == bookStructure.lessons[index].lesson_id })
                    if(element) bookStructure.lessons[index].viewed = true;                    
                }
            }

            var prevLessonData;
            if(index > 0)
            {
                const prevLessonId = lessons[index - 1].lesson_id;
                prevLessonData = cachedLessons[prevLessonId];
            
                if (!prevLessonData) {
                    prevLessonData = await getBookLessoncontent(prevLessonId, this.isPublished());
                }
            }
            
            if (!this.getLesson() || lessonId != this.getLesson()!.lesson_id) {
                return;
            }

            this.setState({ 
                lessonData,
                prevLessonData,
                bookStructure
            });

            this.cachePreviousNextLesson();

        } catch (e) {
            app.showErrorFromJsonResult(e);
        }

    }

    private async cachePreviousNextLesson() {
        if (this.state.bookStructure) {
            const lessons = this.state.bookStructure.lessons;
            const lesson = this.getLesson();

            if (lesson) {
                var index = lessons.findIndex(x => x.lesson_uri_segment == lesson.lesson_uri_segment);
                if (index == -1) index = 0;
    
                if (index > 0) {
                    const lessonId = lessons[index - 1].lesson_id;
                    if (!cachedLessons[lessonId]) {
                        cachedLessons[lessonId] = await getBookLessoncontent(lessonId, this.isPublished());
                    }
                }

                if (index < lessons.length - 1) {
                    const lessonId = lessons[index + 1].lesson_id;
                    if (!cachedLessons[lessonId]) {
                        cachedLessons[lessonId] = await getBookLessoncontent(lessonId, this.isPublished());
                    }
                }
            }

        }
    }

    private isPublished() {
        return this.props.match.path.startsWith(getPath({PATH_PUBLISHED_BOOK}, bookModule));
    }

    async reloadStructureAsync() {
        try {
            
            let bookStructure:BookStructure;
            if (this.props.match.path.startsWith(getPath({PATH_PUBLISHED_BOOK}, bookModule))) {
                bookStructure = await getBookStructureForUri(this.props.match.params.bookUriSegment);
            } else {
                bookStructure = await getBookStructureForId(Number(this.props.match.params.bookUriSegment));
            }

            cachedLessons = {};

            if(me){
                const result: ILessonViewSummaryRecord[] = (await getLessonViewSummary(me.id!, bookStructure.id!));
                for (let index = 0; index < bookStructure.lessons.length; index++) {
                    let element = result.find((el:ILessonViewSummaryRecord) => {return el._id.recordId == bookStructure.lessons[index].lesson_id })
                    if(element) bookStructure.lessons[index].viewed = true;                    
                }
            }

            this.setState({ 
                bookStructure,
                viewMode: bookStructure.display_mode_id
            });
            return true;
        } catch (e) {
            this.props.history.push(getPath({PATH_PUBLISHED_BOOK_SELECT}, bookModule));
            app.showErrorFromJsonResult(e);
        }
        return false;
    }

    getLesson() {
        if (!this.state.bookStructure) {
            return null;
        }

        var lesson = this.state.bookStructure.lessons.find(x => x.lesson_uri_segment == this.props.match.params.lessonUriSegment);
        if (!lesson) {
            lesson = this.state.bookStructure.lessons[0];
        }
        return lesson;
    }

    render() {        

        const parts = this.props.match.url.split("/");
        const path = "/" + parts[1] + "/" + parts[2] + "/";

        const lesson = this.getLesson();


        var lessonData = this.state.lessonData;

        if (lessonData && lesson && lessonData.lessonId != lesson.lesson_id) {
            lessonData = undefined; // Új lap jön, reloadLessonDataAsync fogja betölteni fentebb
        }

        const values = queryString.parse(location.search);
        if(values.section)// this.props.match.params.sectionId)
        {
            return <RenderSectionPage 
            key="bookPageView"
            bookStructure={this.state.bookStructure}
            lesson={lesson}
            lessonData={lessonData}
            sectionId={values.section}           
            bookPath={path}
            viewMode={ViewMode.SIMPLE}
            ></RenderSectionPage>
        }

        
        const isPublished = path.startsWith(getPath({PATH_PUBLISHED_BOOK}, bookModule));
        let viewMode = this.state.viewMode;
        if(!config.mainServer.showBooks && isPublished && this.state.bookStructure && (this.state.bookStructure!.library_id == LIBRARY_OFI_OFFICIAL_ID))
        {
            viewMode = ViewMode.CONTENT;
           // this.setState({viewMode: ViewMode.CONTENT, sectionTypes: [SectionType.TEXT]});
        }        

        return this.state.viewMode == ViewMode.NORMAL  || this.state.viewMode == ViewMode.SIMPLE || this.state.viewMode == ViewMode.CONTENT || this.state.viewMode == ViewMode.ORDERED|| this.state.viewMode == ViewMode.BOOK
        ? 
        <BookPage 
            key="bookPageView"
            bookStructure={this.state.bookStructure}
            lesson={lesson}
            lessonData={lessonData}
            prevLessonData={this.state.prevLessonData}
            bookPath={path}
            onViewModeChange={(viewMode) => this.setState({viewMode})}
            viewMode={viewMode}
            /> 
        :
        <BookPresentation 
            key="bookPresentationView"
            bookStructure={this.state.bookStructure}
            lesson={lesson}
            lessonData={lessonData}
            bookPath={path}
            onViewModeChange={(viewMode) => this.setState({viewMode})}
            viewMode={this.state.viewMode}/>;

    }
}
