import * as React from 'react';
import { match } from 'react-router';
import { IInstituteGroupNewsfeedRecord } from '@src/framework/crud/usr/InstituteGroupNewsfeedCrud';

import Feed from './component/Feed';
import { app } from '@src/index';
import { FeedEdit } from './component/FeedEdit';
import ViewUsrInstituteGroupNewsfeed, { IViewUsrInstituteGroupNewsfeedRecord } from '@src/framework/view/usr/ViewUsrInstituteGroupNewsfeed';
import { me } from '@src/framework/server/Auth';
//import VisibilitySensor from 'react-visibility-sensor';
import ViewInstituteGroupMember, { IViewInstituteGroupMemberRecord } from '@src/framework/view/sys/ViewInstituteGroupMember';
import { IListParams, TFilterDict } from '@src/framework/crud/Crud';
import { __ } from '@src/translation';
import { formatDate } from '@src/Util';


const BUCKET_SIZE: number = 20;

// Mennyi időnként ellenőrizzük, hogy van-e új üzenet?
const WAIT_TIMES_SEC = [
    5,  // Ha nincs, akkor 5 sec
    7,  // Ha nincs, akkor 7 sec stb.
    12, 19, 21, 40 // 40 fölé nem megyünk!
];

enum MergeMode {
    PREPEND = 1,
    APPEND = 2
}

interface IClassroomNewsPageProps {
    match: match<{ instituteGroupId: string }>;
}

interface IClassroomNewsPageState {
    instituteGroupId: number;
    members : IViewInstituteGroupMemberRecord[];
    adminMemberIds : number[];
    feeds: IViewUsrInstituteGroupNewsfeedRecord[];
    isAdmin: boolean;
    startPost: boolean;
    minId: number | null; // A legkorábbi (legrégebbi) üzenet id-je
    maxId: number | null; // A legkésőbbi (legfrissebb) üzenet id-je
    myNewIds: number[];  // Az általam elküldött Post-ok azonosítói
    mayHaveOlder: boolean; // Azt jelzi, hogy van-e arra esély, hogy vannak régebbi nem betöltött üzenetek.
    loading: boolean;      // Azt jelzi, hogy van-e betöltés alatt feed lista.
    elapsed : number;  // Mennyi idő telt el az utolsó frissítés óta
    waitIndex: number; // Melyik wait time-ban vagyunk
}

export class ClassroomNewsPage extends React.Component<IClassroomNewsPageProps, IClassroomNewsPageState> {
    private _timer : any;


    constructor(props: IClassroomNewsPageProps) {
        super(props);
        this._timer = null;

        this.state = {
            instituteGroupId: Number(this.props.match.params.instituteGroupId),
            members: [],
            adminMemberIds: [],
            feeds: [],
            isAdmin: false,
            minId: null,
            maxId: null,
            myNewIds: [],
            mayHaveOlder: true,
            loading: true,
            waitIndex: 0,
            elapsed: 0,
            startPost: false
        }
    }

    componentDidMount() {
        this._timer = setInterval(this.tick.bind(this), 1000);
        this.reloadAll();
    }

    componentWillUnmount() {
        if (this._timer) {
            clearTimeout(this._timer);
        }
    }

    async componentDidUpdate(prevProps: IClassroomNewsPageProps, prevState: IClassroomNewsPageState) {
        const instituteGroupId = Number(this.props.match.params.instituteGroupId);
        if (instituteGroupId !== this.state.instituteGroupId) {
            this.reloadAll();
        }
    }

    private async reloadAll() {
        try {
            await this.reset();
            await this.loadAsync(MergeMode.PREPEND, {}, true, true);
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    private getSecondsUntilNextRefresh(elapsed: number) : number {
        const waitIndex = this.state.waitIndex;
        const maxElapsed = waitIndex>=WAIT_TIMES_SEC.length?WAIT_TIMES_SEC[WAIT_TIMES_SEC.length-1]:WAIT_TIMES_SEC[waitIndex];
        return maxElapsed-elapsed;
    }

    /** Mounted állapotban minden másodpercben meghívódik. */
    private tick() {
        // Eltelt egy másodperc.
        const elapsed = this.state.elapsed + 1;
        this.setState({elapsed});

        // Kell már frissíteni?
        const remaining = this.getSecondsUntilNextRefresh(elapsed);
        if (remaining<=0) {
            this.fetchNew();
        }
    }

    /** Helper: await-able setstate */
    private setStateAsync(state: Partial<IClassroomNewsPageState>): Promise<void> {
        return new Promise((resolve) => {
            this.setState(state as IClassroomNewsPageState, resolve)
        });
    }

    /** Alapállapotba hozza az üzenőfalat: nincs letöltve üzenet, és meghatározza hogy admin vagyok-e */
    private async reset() {
        try {
            let isAdmin: boolean = false;
            let adminMemberIds : number[] = [];
            if (this.state.instituteGroupId) {
                const members = await ViewInstituteGroupMember.list({
                    filter: {
                        institute_group_id: this.state.instituteGroupId,
                        is_active: true
                    },
                    max_age: 0
                });
                adminMemberIds = members.filter ( member => member.is_admin ).map(member => member.sec_user_id!);
                isAdmin = adminMemberIds.includes(me!.id!);
            }
            await this.setStateAsync({ isAdmin, adminMemberIds, minId: null, maxId: null, myNewIds:[], feeds: [], mayHaveOlder: true, loading: true });
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    /** Akkor igaz, ha az üzenetet admin (tanár) küldte. */
    private isAdminFeed(feed: IViewUsrInstituteGroupNewsfeedRecord) : boolean {
        return this.state.adminMemberIds.includes(feed.creation_user_id!);
    }

    /** 
     * 
     * Letölt egy adag üzenetet, és összefésüli a már meglevő letöltött üzenetekkel. 
     * 
     * @param extraFilter: Extra szűrési feltételek
     * @param noMoreWhenLess: Ha ez igaz, és a lekérdezés eredménye kevesebb mint BUCKET_SIZE, 
     *      akkor hamisra állítja mayHaveOlder-t, ezzel jelezve hogy nincs több régebbi üzenet.
     * @param mayResetElapsed: Ha ez igazra van állítva, akkor két dolog történhet:
     *      - ha érkezett be új feed, akkor reset-eli a waitIndex-et nullára és az elapsed-et nullára
     *      - ha nem érkezett be új feed, akkor növeli a waitIndex-et és reset-eli az elapsed-et
     * 
     * */
    private async loadAsync(mergeMode: MergeMode, extraFilter: TFilterDict, noMoreWhenLess: boolean, mayResetElapsed:boolean) {
        try {
            if (this.state.instituteGroupId) {
                const filter: TFilterDict = {
                    institute_group_id: this.state.instituteGroupId,
                    is_active: true,
                    ...extraFilter
                }
                const params: IListParams = {
                    filter,
                    limit: BUCKET_SIZE,
                    order_by: [{ name: 'id', desc: true }],
                    max_age: 0
                };
                await this.setStateAsync({ loading: true });
                const feeds = (await ViewUsrInstituteGroupNewsfeed.list(params)).filter(
                    feed => !this.state.myNewIds.includes(feed.id!)
                );
                this.mergeFeeds(mergeMode, feeds, noMoreWhenLess, mayResetElapsed);
                await this.setStateAsync({ loading: false });
            }
        } catch (e) {
            app.showErrorFromJsonResult(e);
        }
    }

    /**
     * 
     * Beérkezett üzeneteket összefésüli a már letöltött üzenetekkel. 
     * 
     * Csak akkor működik jól, ha a beérkező új feed-ek az eddig letöltött
     * feed-ek ELŐTT vagy UTÁN vannak. Olyan fajta összefésülést nem tud,
     * hogy az össze vissza sorrendben levő üzeneteket időben a helyükre
     * szúrja be.
     * 
     * @param noMoreWhenLess: Ha ez igaz, és a lekérdezés eredménye kevesebb mint BUCKET_SIZE, 
     *      akkor hamisra állítja mayHaveOlder-t, ezzel jelezve hogy nincs több régebbi üzenet.
     * @param mayResetElapsed: Ha ez igazra van állítva, akkor két dolog történhet:
     *      - ha érkezett be új feed, akkor reset-eli a waitIndex-et nullára és az elapsed-et nullára
     *      - ha nem érkezett be új feed, akkor növeli a waitIndex-et és reset-eli az elapsed-et
     * 
     *
     * */
    private mergeFeeds(mode: MergeMode, newFeeds: IViewUsrInstituteGroupNewsfeedRecord[], noMoreWhenLess: boolean, mayResetElapsed: boolean) {
        let oldFeeds = this.state.feeds;
        let feeds: IViewUsrInstituteGroupNewsfeedRecord[];
        let minId: number | null;
        let maxId: number | null;
        let changes : Partial<IClassroomNewsPageState> = {};
        if (newFeeds.length == 0) {
            // Beérkezett a nagy semmi, nem csinálunk vele semmit.
            if (noMoreWhenLess) {
                changes.mayHaveOlder = false;
            }
            if (mayResetElapsed) {
                // Nem jött új üzenet, ugrunk a következő WAIT_TIME intervallumra.
                changes.elapsed = 0;
                if (this.state.waitIndex < WAIT_TIMES_SEC.length-1) {
                    changes.waitIndex = this.state.waitIndex + 1;
                }
            }
        } else {
            // Jött új üzenet.
            if (oldFeeds.length == 0) {
                // Még nem volt egyetlen egy feed sem, ezért ami most bejött, az az összes.
                feeds = newFeeds;
                minId = feeds[feeds.length-1].id!;
                maxId = feeds[0].id!;
                changes = { feeds, minId, maxId };
            } else {
                // Egyik se üres, elé vagy utána kell tenni.
                let oldMinId = oldFeeds[oldFeeds.length-1].id!;
                let oldMaxId = oldFeeds[0].id!; // A legfrissebb van elöl, order by id desc
                let newMinId = newFeeds[newFeeds.length-1].id!;
                let newMaxId = newFeeds[0].id!; // A legfrissebb van elöl, order by id desc

                // Melyik volt hamarabb?
                if (mode==MergeMode.PREPEND) {
                    // Az újak frissebbek, az elejére kell tenni.
                    feeds = newFeeds.concat(oldFeeds);
                } else {
                    // A régiek frissebbek, a végére kell tenni.
                    feeds = oldFeeds.concat(newFeeds);
                }
                minId = (oldMinId < newMinId) ? oldMinId: newMinId;
                maxId = (oldMaxId > newMaxId) ? oldMaxId: newMaxId;                
            }
            changes = { feeds, minId, maxId };
            if (mayResetElapsed) {
                // Jött új üzenet, ugrunt az első WAIT_TIMES interallumra.
                changes.elapsed = 0;
                changes.waitIndex = 0;
            }
        }
        if (noMoreWhenLess && newFeeds.length < BUCKET_SIZE) {
            changes["mayHaveOlder"] = false;
        }
        this.setState(changes as IClassroomNewsPageState);
    }

    /** 
     * 
     * Egy új üzenet lementése után berakja a módosítást a megjelenő feed listába is. 
     * 
     * Nem csinál újabb network request-et!
     * 
     */
    private onSave(changedRecord: IInstituteGroupNewsfeedRecord, rowIndex: number) {
        const feedList = this.state.feeds;
        if (rowIndex >= 0) {
            const feed = feedList[rowIndex];
            feed.body_html = changedRecord.body_html;
            feed.modification_time = changedRecord.modification_time;
            feedList[rowIndex] = feed;
            // Itt a szöveget módosította.
            this.setState({ feeds: feedList });
        } else {
            const feed: IViewUsrInstituteGroupNewsfeedRecord = {
                ...changedRecord,
                institute_group_name: "", // Ez persze nem jó, de erre nincs szükség a megjelenítéshez.
                sender_login_name: me!.login_name,
                sender_email: me!.email,
                sender_fullname: me!.person!.full_name!
            }
            feedList.unshift(feed);
            // Itt újat hozott létre.
            const myNewIds = this.state.myNewIds;
            myNewIds.push(feed.id!);
            this.setState({ feeds: feedList, myNewIds });
            // Ilyenkor általában elvárják, hogy mások beírásai is megjelenjenek...
            this.fetchNewAndResetWait();
        }
    }

    /**  
     * 
     * Egy üzenet törlése (moderálása) után törli azt a megjelenő feed listából is.
     * 
     * Nem csinál újabb network request-et!
     * 
     */
    private onDelete(deletedRecord: IInstituteGroupNewsfeedRecord, rowIndex: number) {
        const feedList = this.state.feeds;
        feedList.splice(rowIndex, 1);
        this.setState({ feeds: feedList });
    }

    /**
     * Régebbi üzenetek betöltése.
     * 
     * Ez a beszélgetés eddig be nem töltött régebbi, legfeljebb BUCKET_SIZE üzenetét tölti be
     * a feeds végére.
     * 
     */
    private fetchMore() {
        if (!this.state.loading) {
            let minId = this.state.minId;
            if (minId) {
                this.loadAsync(MergeMode.APPEND, { "<": { id: minId } }, true, false)
            } else {
                this.setState({ mayHaveOlder: false });
            }
        }
    }

    /**
     * Új üzenetek betöltése.
     * 
     * Ez a beszélgetés eddig be nem töltött, újabb legfeljebb BUCKET_SIZE üzenetét tölti be
     * a feeds elejére.
     * 
     */
    private fetchNew() {
        if (!this.state.loading) {
            let maxId = this.state.maxId;
            if (maxId) {
                this.loadAsync(MergeMode.PREPEND, { ">": { id: maxId } }, false, true)
            } else {
                this.loadAsync(MergeMode.PREPEND, {}, false, true);
            }
        }
    }

    private fetchNewAndResetWait() {
        this.setState({elapsed: 0, waitIndex: 0}, this.fetchNew.bind(this));
    }

    render() {
        return <>
            <div className="row">
                <div className="column small-12">

                    {
                        this.state.startPost 
                        ?
                        <FeedEdit instituteGroupId={this.state.instituteGroupId}
                            onSave={this.onSave.bind(this)}
                            onDelete={this.onDelete.bind(this)}
                            rowIndex={-1}
                        />
                        :
                        <div className="start-post-area" onClick={()=>this.setState({startPost: true})}>
                            {__("Megosztás a csoportban... ")}
                        </div>
                    }

                    
                    

                    {/* <button
                        className="button primary"
                        disabled={this.state.loading}
                        onClick={this.fetchNewAndResetWait.bind(this)}
                    >
                        {this.state.loading
                            ? <><i className="fa fa-hourglass-start" />{__("Betöltés, kérem várjon...")}</>
                            : <><i className="fa fa-cloud-download-alt" />{
                                __("Új üzenetek betöltése") + " (" + this.getSecondsUntilNextRefresh(this.state.elapsed) + ")"
                            }</>
                        }
                    </button> */}
                    <div style={{textAlign: "right"}}>
                        <i title={__("Új üzenetek betöltése")} className={"fas fa-sync-alt post-refresh-btn" + (this.state.loading?" sync":"")} onClick={this.fetchNewAndResetWait.bind(this)}></i>
                    </div>

                    {
                        this.state.feeds.map((feed, index: number) => {

                            return <Feed
                                isAdmin={this.state.isAdmin}
                                sentByAdmin={this.isAdminFeed(feed)}
                                key={feed.id}
                                onSave={this.onSave.bind(this)}
                                onDelete={this.onDelete.bind(this)}
                                feedRecord={feed}
                                rowIndex={index}
                            >
                            </Feed>

                        })

                    }

                    {this.state.mayHaveOlder ?
                        <button 
                            style={{marginRight: "auto", marginLeft: "auto", marginBottom: "1rem",  display: "block"}}
                            className="button primary"
                            disabled={this.state.loading}
                            onClick={this.fetchMore.bind(this)}
                        >
                            {this.state.loading
                                ? <><i className="fa fa-hourglass-start" />{__("Betöltés, kérem várjon...")}</>
                                : <><i className="fa fa-cloud-download-alt" />{__("Régebbiek betöltése")}</>
                            }
                        </button>
                        : this.state.feeds.length > 0 ?
                            <button className="button secondary" disabled={true}>
                                <i className="fa fa-clock" />{
                                    __("A beszélgetés kezdete: ") +
                                    formatDate(this.state.feeds[this.state.feeds.length - 1].creation_time)
                                }
                            </button>
                            : null
                    }
                </div>
            </div>

        </>
    }

}
